const FR = {
  title: 'Inviter un utilisateur d’une clinique',
  acceptTitle: 'Joindre une clinique',
  emailLabelText: 'Courriel :',
  firstNameLabelText: 'Prénom',
  lastNameLabelText: 'Nom de famille',
  roleLabelText: 'Fonction :',
  roleValidationMessage: 'Veuillez choisir la fonction de l’utilisation :',
  firstNameValidationMessage: 'Le prénom est requis',
  lastNameValidationMessage: 'Le nom est obligatoire',
  roleNoOptionText: 'Veuillez choisir',
  roleClinicianOptionText: 'Clinicien',
  selectRoleOptionText: 'Veuillez choisir',
  submitButtonText: 'Inviter',
  clinicUserInviteSuccessMessage: 'Invitation envoyée ! Un courriel avec un lien d’enregistrement a été envoyé à l’adresse {0}',
  clinicUserRoleAddedSuccessMessage: '{0} a été ajouté comme {1} utilisation de cette clinique'
};
 
export default FR;
