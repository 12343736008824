const RegistrationPaths = {
  index: {
    route: 'register',
    link: '/register'
  },
  passwordReset: {
    route: 'password/reset/:token',
    link: '/register/password/reset/'
  },
  player: {
    index: {
      route: 'individual',
      link: '/register/individual'
    },
    about: {
      route: 'about',
      link: '/register/individual/about'
    },
    contact: {
      route: 'contact',
      link: '/register/individual/contact'
    },
    physician: {
      route: 'physician',
      link: '/register/individual/physician'
    },
    sports: {
      route: 'sports',
      link: '/register/individual/sports'
    }
  },
  guardian: {
    index: {
      route: 'family',
      link: '/register/family'
    },
    about: {
      route: 'about',
      link: '/register/family/about'
    },
    children: {
      route: 'members',
      link: '/register/family/members'
    },
    child: {
      index: {
        route: ':child',
        link: '/register/family/members/:child'
      },
      contact: {
        route: 'contact',
        link: '/register/family/members/:child/contact'
      },
      physician: {
        route: 'physician',
        link: '/register/family/members/:child/physician'
      },
      sports: {
        route: 'sports',
        link: '/register/family/members/:child/sports'
      }
    }
  },
  confirm: {
    index: {
      route: 'confirm',
      link: '/register/confirm'
    },
    token: {
      route: 'confirm/:token',
      link: '/register/confirm/'
    }
  },
  about: {
    route: 'about',
    link: '/register/about'
  },
  finish: {
    route: 'finish',
    link: '/register/finish'
  }
};

export default RegistrationPaths;
