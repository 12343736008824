import React, { useCallback } from 'react';
import { If, Then } from 'react-if';
import classNames from 'classnames';
import { HelpModalLink } from '../help-modal';
import Strings from './lang';
import Icon from '../icon';

const HELP_DOCUMENT_NAME = 'baseline-expiry';

const SortKeys = {
  lastName: 'person.last_name',
  account: 'account',
  baselineDate: 'baseline_date',
  injuryDate: 'ongoing_injury_date',
  danaBaselineCount: 'dana_baseline_count'
};

const PatientsTableHeader = ({
  sortKey,
  viewNames = false,
  showDanaBaseline = true,
  showInjuryStage = false,
  onUpdateSortKey
}) => {
  const sortByLastName = useCallback(() => {
    onUpdateSortKey(SortKeys.lastName);
  }, [onUpdateSortKey]);

  const sortByAccount = useCallback(() => {
    onUpdateSortKey(SortKeys.account);
  }, [onUpdateSortKey]);

  const sortByBaselineDate = useCallback(() => {
    onUpdateSortKey(SortKeys.baselineDate);
  }, [onUpdateSortKey]);

  const sortByDanaBaselineCount = useCallback(() => {
    onUpdateSortKey(SortKeys.danaBaselineCount);
  }, [onUpdateSortKey]);

  const sortByInjuryDate = useCallback(() => {
    onUpdateSortKey(SortKeys.injuryDate);
  }, [onUpdateSortKey]);

  return (
    <thead>
      <tr>
        <If condition={viewNames}>
          <Then>
            <th
              className={classNames({ highlighted: sortKey === SortKeys.lastName })}
              onClick={sortByLastName}
            >
              {Strings.nameHeadingText}
            </th>
          </Then>
        </If>
        <th
          className={classNames({ highlighted: sortKey === SortKeys.account })}
          onClick={sortByAccount}
        >
          {Strings.accountHeadingText}
        </th>
        <th
          className={classNames({ highlighted: sortKey === SortKeys.baselineDate })}
          onClick={sortByBaselineDate}
        >
          {Strings.baselineExpiryHeadingText}&nbsp;&nbsp;
          <HelpModalLink
            className="help-button"
            documentName={HELP_DOCUMENT_NAME}
            onClick={(e) => e.stopPropagation()}
          >
            <Icon name="circle-info" />
          </HelpModalLink>
        </th>
        {showDanaBaseline && (
          <th
            className={classNames({ highlighted: sortKey === SortKeys.danaBaselineCount })}
            onClick={sortByDanaBaselineCount}
          >
            DANA
          </th>
        )}
        <If condition={showInjuryStage === true}>
          <Then>
            <th
              className={classNames({ highlighted: sortKey === SortKeys.injuryDate })}
              onClick={sortByInjuryDate}
            >
              {Strings.injuryStageHeadingText}
            </th>
          </Then>
        </If>
      </tr>
    </thead>
  );
};

export default PatientsTableHeader;
