import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import DualTaskTrialWalkthroughModal from './trial-walkthrough';
import { FAILURE_REASON_STRINGS, getTrialResponseRate } from './utils';
import Icon from '../../../icon';
import EditTrialModal from './edit-trial';
import Strings from './lang';

const FailedReasonItem = ({ failed, reasons }) => {
  if (failed === null) {
    return null;
  }

  return (
    <div style={{ width: '100%' }}>
      <Icon name={failed ? 'xmark' : 'check'} className={failed ? 'red' : 'green'} />&nbsp;
      {
        !failed
          ? Strings.passedText
          : reasons.map(key => FAILURE_REASON_STRINGS[key]).join(', ')
      }
    </div>
  );
};

export const HeadingRow = () => {
  return (
    <div className="trial-grid-row">
      <div className="grid-item grid-label">{Strings.tableColumnTrial}</div>
      <div className="grid-item grid-label">{Strings.tableColumnStartNumber}</div>
      <div className="grid-item grid-label">{Strings.tableColumnPassFail}</div>
      <div className="grid-item grid-label">{Strings.tableColumnAttempted}</div>
      <div className="grid-item grid-label">{Strings.tableColumnCorrect}</div>
      <div className="grid-item grid-label">{Strings.tableColumnErrors}</div>
      <div className="grid-item grid-label">{Strings.tableColumnTime}</div>
      <div className="grid-item grid-label">{Strings.responseRateLabel}</div>
    </div>
  );
};

export const TrialRow = ({
  step,
  trial,
  number,
  onTrialChange
}) => {
  const [startModalOpen, setStartModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const completed = !!trial.responses?.length;
  const completeTrials = useMemo(() => {
    return trial.responses?.filter(r => r.correct !== null);
  }, [trial.responses]);

  const handleTrialChange = useCallback((attributes) => {
    setStartModalOpen(false);
    setEditModalOpen(false);
    onTrialChange(attributes);
  }, [onTrialChange]);

  const handleStartClick = useCallback(() => {
    if (!completed) {
      setStartModalOpen(true);
    } else {
      setEditModalOpen(true);
    }
  }, [completed]);

  return (
    <>
      <div className="trial-grid-row">
        <div className="grid-item action">
          <span className="item-label">
            {Strings.formatString(Strings.trialLabel, number)}
          </span>
          <button
            type="button"
            className={classNames('btn btn-sm', {
              'btn-success': !completed,
              'btn-warning': completed
            })}
            onClick={handleStartClick}
          >
            {!completed ? Strings.startTrialButton : Strings.editTrialButton}
          </button>
        </div>
        <div className="grid-item">{trial.start}</div>
        <div className="grid-item start">
          <FailedReasonItem
            failed={trial.failed}
            reasons={trial.failed_reasons}
          />
        </div>
        <div className="grid-item">
          {completed ? completeTrials?.length : null}
        </div>
        <div className="grid-item">
          {completed && ((completeTrials?.length ?? 0) - trial.errors)}
        </div>
        <div className="grid-item">
          {completed && trial.errors}
        </div>
        <div className="grid-item">
          {completed && (trial.duration / 1000).toFixed(2)}
        </div>
        <div className="grid-item">
          {completed && getTrialResponseRate(trial).toFixed(2)}
        </div>
      </div>
      {completed ? (
        <EditTrialModal
          trial={trial}
          step={step}
          number={number}
          isOpen={editModalOpen}
          onClose={() => setEditModalOpen(false)}
          onUpdate={handleTrialChange}
        />
      ) : (
        <DualTaskTrialWalkthroughModal
          title={Strings.formatString(Strings.trialLabel, number)}
          start={trial.start}
          step={step}
          isOpen={startModalOpen}
          onClose={() => setStartModalOpen(false)}
          onDone={handleTrialChange}
        />
      )}
    </>
  );
};
