import React from 'react';
import Strings from './lang';

const VOMSTestFormHeader = () => (
  <tr>
    <th className="no-action">{Strings.symptomHeaderText}</th>
    <th className="no-action">{Strings.notTestedHeaderText}</th>
    <th className="no-action">{Strings.headacheHeaderText}<br />{Strings.scale}</th>
    <th className="no-action">{Strings.dizzinessHeaderText}<br />{Strings.scale}</th>
    <th className="no-action">{Strings.nauseaHeaderText}<br />{Strings.scale}</th>
    <th className="no-action">{Strings.fogginessHeaderText}<br />{Strings.scale}</th>
    <th className="no-action">{Strings.distanceHeaderText}<br />{Strings.cm}</th>
    <th className="no-action">{Strings.abnormalityHeaderText}?</th>
    <th className="no-action">{Strings.commentsHeaderText}</th>
  </tr>
);

export default VOMSTestFormHeader;
