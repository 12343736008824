import React from 'react';
import {
  PatientProfileInfo,
  PatientSportsInfo,
  PatientMedicalInfo
} from '@/components/patient-profile';
import DanaTests from '@/components/dana-tests';
import BalanceTests from '@/components/balance-tests';
import PatientInjuriesRoutes from './injuries';
import { Authorization } from '../authorization';
import { AdminRoleDescriptions, RoleDescriptions } from '@/utilities/user-roles';
import { InjuryReportView, InjuryReports } from '@/components/injury-reports';
import { ArchivedBaselinesList, UserBaselineDetail, UserBaselinesList } from '@/components/baseline';
import PageNotFound from '../not-found-page';

const routeConfig = [
  {
    path: 'profile',
    element: (
      <Authorization
        allowRoles={[
          RoleDescriptions.ClinicOwner,
          RoleDescriptions.Clinician,
          RoleDescriptions.BaselineTester,
          RoleDescriptions.Specialist,
          RoleDescriptions.ClinicFrontDesk
        ]}
      >
        <PatientProfileInfo />
      </Authorization>
    )
  },
  {
    path: 'sports',
    element: (
      <Authorization
        allowRoles={[
          RoleDescriptions.ClinicOwner,
          RoleDescriptions.Clinician,
          RoleDescriptions.Specialist,
          RoleDescriptions.BaselineTester,
          RoleDescriptions.ClinicFrontDesk
        ]}
      >
        <PatientSportsInfo />
      </Authorization>
    )
  },
  {
    path: 'medical',
    element: (
      <Authorization
        allowRoles={[
          RoleDescriptions.Clinician,
          RoleDescriptions.Specialist,
          RoleDescriptions.BaselineTester,
          RoleDescriptions.ClinicStaff
        ]}
      >
        <PatientMedicalInfo />
      </Authorization>
    )
  },
  {
    path: 'baselines',
    element: (
      <Authorization
        allowRoles={[
          RoleDescriptions.SuperAdmin,
          RoleDescriptions.SalesAdmin,
          RoleDescriptions.ClinicFrontDesk,
          RoleDescriptions.Clinician,
          RoleDescriptions.Specialist,
          RoleDescriptions.BaselineTester,
          RoleDescriptions.ClinicStaff
        ]}
      />
    ),
    children: [
      {
        index: true,
        element: <UserBaselinesList />
      },
      {
        path: 'archived',
        element: (
          <Authorization
            allowRoles={[
              RoleDescriptions.Clinician,
              RoleDescriptions.BaselineTester
            ]}
          >
            <ArchivedBaselinesList />
          </Authorization>
        )
      },
      {
        path: ':testId',
        element: <UserBaselineDetail />
      },
      {
        path: '*',
        element: <PageNotFound />
      }
    ]
  },
  {
    path: 'dana-tests',
    element: (
      <Authorization
        allowRoles={[
          RoleDescriptions.Clinician,
          RoleDescriptions.Specialist
        ]}
      >
        <DanaTests />
      </Authorization>
    )
  },
  {
    path: 'balance',
    element: (
      <Authorization
        allowRoles={[
          RoleDescriptions.Clinician,
          RoleDescriptions.Specialist
        ]}
      >
        <BalanceTests />
      </Authorization>
    )
  },
  {
    path: 'injuries/*',
    element: (
      <Authorization
        allowRoles={[
          RoleDescriptions.SuperAdmin,
          RoleDescriptions.SalesAdmin,
          RoleDescriptions.ClinicFrontDesk,
          RoleDescriptions.Clinician,
          RoleDescriptions.Specialist,
          RoleDescriptions.ClinicStaff
        ]}
      />
    ),
    children: PatientInjuriesRoutes
  },
  {
    path: 'reported/*',
    element: (
      <Authorization
        allowRoles={[
          ...AdminRoleDescriptions,
          RoleDescriptions.Clinician,
          RoleDescriptions.Specialist,
          RoleDescriptions.ClinicFrontDesk
        ]}
      />
    ),
    children: [
      {
        index: true,
        element: <InjuryReports />
      },
      {
        path: ':reportId',
        element: <InjuryReportView />
      },
      {
        path: '*',
        element: <PageNotFound />
      }
    ]
  },
  {
    path: '*',
    element: <PageNotFound />
  }
];

export default routeConfig;
