import React, { useCallback, useEffect, useState } from 'react';
import { AddressFormInputs } from '../address-form-inputs';
import { FormComponent } from '@/forms';
import convertAddressKeys from '@/utilities/convert-address-keys';
import sanitizeAddress from '@/utilities/sanitize-address';
import validateAddress from '@/utilities/validate-address';
import Strings from './lang';

const getAddressState = (propsAddress) => {
  const address = sanitizeAddress(propsAddress);
  return {
    address,
    addressValid: validateAddress(address)
  };
};

const EditAddressInfoCardForm = ({
  address: propsAddress,
  onSubmit
}) => {
  const [state, setState] = useState(() => getAddressState(propsAddress));
  const [submitted, setSubmitted] = useState(false);

  const onUpdateAddress = useCallback((address, addressValid) => {
    setState({ address, addressValid });
  }, []);

  const handleSubmit = useCallback(() => {
    setSubmitted(true);

    if (!state.addressValid) {
      return;
    }

    const attributes = {
      ...convertAddressKeys(state.address)
    };

    if (propsAddress?.id) {
      attributes.id = propsAddress.id;
    }

    if (onSubmit) {
      onSubmit(attributes);
    }
  }, [onSubmit, propsAddress?.id, state]);

  useEffect(() => {
    setState(getAddressState(propsAddress));
  }, [propsAddress]);

  return (
    <FormComponent className="card card-form" onSubmit={handleSubmit}>
      <AddressFormInputs
        address={state.address}
        onUpdate={onUpdateAddress}
        required
        touched={submitted}
      />

      <div className="form-footer">
        <button className="btn btn-primary" type="submit">
          {Strings.submitButtonText}
        </button>
      </div>
    </FormComponent>
  );
};

export default EditAddressInfoCardForm;
