const FR = {
  archiveModalTitle: 'Supprimer le test de référence',
  archiveModalMessage: 'La suppression de ce test de référence supprimera le test de l\'historique de référence du patient.Si vous souhaitez continuer, veuillez indiquer la raison pour laquelle vous supprimez ce test de référence.',
  unarchiveModalTitle: 'Restaurer le test de référence',
  unarchiveModalMessage: 'La restauration de ce test de référence rendra le test visible dans l\'historique de référence du patient.Voulez - vous vraiment restaurer ce test de référence?',
  reasonLabel: 'Raison',
  reasonErrorMessage: 'Veuillez indiquer la raison pour laquelle vous supprimez ce test de référence',
  confirmUnarchiveButton: 'Restaurer',
  confirmArchiveButton: 'Effacer',
  cancelButton: 'Annuler'
};
 
export default FR;
