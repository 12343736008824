import React from 'react';
import Strings from './lang';

const OrthostaticVitalSignsTestTable = ({ test = {} }) => {
  return (
    <table className="table">
      <thead>
        <tr>
          <th className="no-action">{Strings.positionLabel}</th>
          <th className="no-action">{Strings.heartRateInputLabel} (BPM)</th>
          <th className="no-action">{Strings.bpInputLabel} (mmHg)</th>
        </tr>
      </thead>
      <tbody>
        {['supine', 'seated', 'standing'].map(position => (
          <tr key={position}>
            <td><strong>{Strings[`${position}Label`]}</strong></td>
            <td>{test[`${position}_hr`]}</td>
            <td>{test[`${position}_bp_systolic`]}/{test[`${position}_bp_diastolic`]}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default OrthostaticVitalSignsTestTable;
