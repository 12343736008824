const FR = {
  convertToFamilyAccountBannerMessage: 'Besoin d\'ajouter plusieurs membres de la famille à ce compte?',
  convertToFamilyAccountBannerAction: 'Convertissez votre compte en compte familial',
  convertToFamilyAccountConfirmTitle: 'Convertir votre compte en compte familial?',
  convertToFamilyAccountConfirmMessage: 'En confirmant, ce compte deviendra un membre de la famille sous votre nouveau compte Famille. Vous pourrez alors ajouter d\'autres membres de la famille. Voulez vous procéder?',
  accountConvertedSuccessBannerTitle: 'Votre compte a été converti avec succès en compte familial!',
  accountConvertFailedMessage: 'Échec de la conversion de votre compte en compte familial: {0}'
};

export default FR;
