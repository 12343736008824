const defaultBodyImpact = {
  front: false,
  rear: false,
  right_side: false,
  left_side: false
};

const defaultHeadImpact = {
  right_frontal: false,
  left_frontal: false,
  right_temporal: false,
  left_temporal: false,
  right_parietal: false,
  left_parietal: false,
  right_occipital: false,
  left_occipital: false,
  crown: false
};

const defaultAmnesia = {
  none: false,
  retrograde: false,
  anterograde: false,
  minutes_retrograde: '',
  minutes_anterograde: ''
};

const defaultInitialSymptoms = {
  headache: false,
  head_pressure: false,
  neck_pain: false,
  nausea: false,
  dizziness: false,
  blurred_vision: false,
  balance_problems: false,
  light_sensitivity: false,
  noise_sensitivity: false,
  feeling_slowed_down: false,
  feeling_in_a_fog: false,
  dont_feel_right: false,
  difficulty_concentrating: false,
  difficulty_remembering: false,
  fatigue: false,
  confusion: false,
  drowsiness: false,
  trouble_falling_asleep: false,
  more_emotional: false,
  irritability: false,
  sadness: false,
  nervous: false,
  other: ''
};

const defaultTimeline = {
  multiple_concussions: '',
  notes: ''
};

export {
  defaultBodyImpact,
  defaultHeadImpact,
  defaultAmnesia,
  defaultInitialSymptoms,
  defaultTimeline
};
