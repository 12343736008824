const FR = {
  nameHeadingText: 'Nom',
  statusHeadingText: 'Statut',
  renewalHeadingText: 'Renouvellement',
  accountHeadingText: 'No de compte',
  baselineExpiryHeadingText: 'Expiration du test de référence',
  injuryStageHeadingText: 'Étape de la blessure (jours)',
  injuryStageChronicText: 'Chronique ({0})',
  injuryStageSubacuteText: 'Subaigu ({0})',
  injuryStageAcuteText: 'Aigu ({0})'
};
 
export default FR;
