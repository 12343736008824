import React, { useMemo } from 'react';
import { getTaxRegistrationText } from './utilities';

const InvoicePrintHeader = ({ clinic }) => {
  const taxRegistration = useMemo(() => getTaxRegistrationText(clinic), [clinic]);
  return (
    <div className="invoice-print-header">
      <div className="">
        <div className="bold">Complete Concussion Management Inc.</div>
        <div>5155 Spectrum Way #37</div>
        <div>Mississauga ON  L4W 5A1</div>
        {taxRegistration && (
          <div>
            {taxRegistration}
          </div>
        )}
      </div>
      <div>
        <img 
          alt="Complete Concussion Management" 
          src="/images/logo/color-full.svg" 
          style={{ height: 80 }} 
        />
      </div>
    </div>
  );
};

export default InvoicePrintHeader;
