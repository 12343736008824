import React, { 
  useCallback, 
  useEffect, 
  useMemo, 
  useState 
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { confirmation } from '../confirmation';
import { Page } from '../page';
import { ErrorBanner } from '../errors';
import Activity from '../activity';
import { updateTeamLeader } from '@/redux/actions/team-leaders';
import { getTeamLeadersAsync } from '@/redux/thunks/team-leaders';
import { activateUserAsync } from '@/redux/thunks/users';
import { sortOnKeyPath } from '@/utilities/sort-keypath';
import Filterable from '../filterable';
import {
  TeamLeadersTable,
  getTeamLeadersTableSortKeyPath,
  setTeamLeadersTableSortKeyPath
} from '../team-leaders-table';
import Strings from './lang';

const filterLeaders = (filter, leader) => {
  const { person, teams } = leader;
  const name = person && `${person.first_name} ${person.last_name}`;
  const matchName = name && name.toLowerCase().includes(filter.toLowerCase());
  const matchGroups = teams
    .map(group => group.name.toLowerCase())
    .join(';')
    .includes(filter.toLowerCase());
  return matchName || matchGroups;
};

const TeamLeaders = () => {
  const dispatch = useDispatch();
  const [activity, setActivity] = useState(false);
  const [error, setError] = useState(null);
  const [sortKeyPath, setSortKeyPath] = useState(getTeamLeadersTableSortKeyPath);
  const teamLeaders = useSelector(state => state.teamLeaders);
  const sortedTeamLeaders = useMemo(() => (
    sortOnKeyPath(teamLeaders, sortKeyPath)
  ), [teamLeaders, sortKeyPath]);

  const updateSortKeyPath = useCallback((keyPath) => {
    setTeamLeadersTableSortKeyPath(keyPath);
    setSortKeyPath(keyPath);
  }, []);

  const activateUser = useCallback((user, active) => {
    setActivity(true);
    setError(null);
  
    dispatch(activateUserAsync(user.id, { active })).then(leader => {
      const { id, active } = leader;
      dispatch(updateTeamLeader(id, {
        ...user,
        active
      }));
      setActivity(false);
    }).catch(error => {
      setActivity(false);
      setError(error.message);
    });
  }, [dispatch]);

  const confirmActivateUser = useCallback((user, active) => {
    const person = user.person || {};
    const message = Strings.formatString(
      (active)
        ? Strings.activateUserModalMessage
        : Strings.deactivateUserModalMessage,
      `${person.first_name} ${person.last_name}`
    );

    const title = active
      ? Strings.activateUserModalTitle
      : Strings.deactivateUserModalTitle;

    const confirmButtonTitle = active
      ? Strings.activateUserModalConfirmButtonTitle
      : Strings.deactivateUserModalConfirmButtonTitle;

    confirmation(message, {
      title,
      confirmButtonTitle,
      onConfirm: () => {
        activateUser(user, active);
      }
    });
  }, [activateUser]);

  const getTeamLeaders = useCallback(() => {
    setActivity(true);
    setError(null);

    dispatch(getTeamLeadersAsync()).then(() => {
      setActivity(false);
    }).catch(error => {
      setActivity(false);
      setError(error.message);
    });
  }, [dispatch]);

  useEffect(() => {
    if (!teamLeaders.length) {
      getTeamLeaders();
    }
  }, [getTeamLeaders, teamLeaders.length]);

  return (
    <Page className="team-leaders">
      <div className="row">
        <div className="col-md-12">
          <h1 className="display">{Strings.title}</h1>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <ErrorBanner error={error} />
          <Activity active={activity} static={sortedTeamLeaders.length === 0}>
            <Filterable
              items={sortedTeamLeaders}
              filter={filterLeaders}
            >
              {(filteredResults = []) => (
                <TeamLeadersTable
                  teamLeaders={filteredResults}
                  sortKeyPath={sortKeyPath}
                  onUpdateSortKeyPath={updateSortKeyPath}
                  onActivateUser={confirmActivateUser}
                />
              )}
            </Filterable>
          </Activity>
        </div>
      </div>
    </Page>
  );
};

export default TeamLeaders;
