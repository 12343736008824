const EN = {
  clinicReferralsTitle: 'Referrals',
  referralTitle: 'Referral',
  injuryReferralsTitle: 'Referrals for injury dated {0}',
  createReferralError: 'Error creating referral: {0}',
  pendingTabLabel: 'Pending',
  acceptedTabLabel: 'Accepted',
  resolvedTabLabel: 'Resolved',
  rejectedTabLabel: 'Rejected',
  cancelledTabLabel: 'Cancelled',
  emptyTabContent: 'No {0} referrals',
  referralAcceptedAlert: 'Referral Accepted',
  referralRejectedAlert: 'Referral Rejected',
  referralCancelledAlert: 'Referral Cancelled',
  acceptReferralButton: 'Accept',
  rejectReferralButton: 'Reject',
  rejectModalTitle: 'Reject Referral',
  rejectModalMessage: 'Please provide a reason for rejecting this referral. This message will only be seen by the referring clinician/physician.',
  acceptModalTitle: 'Accept Referral',
  acceptModalMessage: 'Please provide any details or instructions for the patient, such as date and time of appointment, items to bring, etc.',
  cancelButtonText: 'Cancel',
  professionRequestText: 'Referral is for a {0}, please book as appropriate.',
  orSeparatorText: 'or',
  referredByText: 'Referred by',
  fromSeparatorText: 'from',
  viewProfileLinkText: 'View Profile',
  referrerNoteLabel: 'Note from Referrer',
  hideText: 'Hide',
  showText: 'Show',
  cancelReferralButtonText: 'Cancel Referral',
  cancelModalTitle: 'Cancel Referral',
  cancelModalMessage: 'Are you sure you want to cancel this referral?',
  pendingStatusLabel: 'Pending Review',
  acceptedStatusLabel: 'Accepted',
  rejectedStatusLabel: 'Rejected',
  cancelledStatusLabel: 'Cancelled',
  resolvedStatusLabel: 'Resolved',
  switchClinicsMessage: 'You need to be signed in under {0} in order to accept/reject the referral.',
  switchClinicsButton: 'Switch Clinics',
  patientLabel: 'Patient:'
};

export default EN;
