import React, { useMemo, useState } from 'react';
import BalanceLineChart from './line-chart';
import ChartSelector from './chart-selector';

const ALL_POSE_METRICS = ['feet_together_score', 'tandem_right_score', 'tandem_left_score', 'single_right_score', 'single_left_score'];

const BalanceTestChart = ({ tests, baseline }) => {
  const [chartKey, setChartKey] = useState('score');

  const metrics = useMemo(() => (
    chartKey === 'all_poses' ? ALL_POSE_METRICS : [chartKey]
  ), [chartKey]);

  return (
    <div>
      <div className="balance-filters-row">
        <ChartSelector
          value={chartKey}
          onChange={setChartKey}
        />
      </div>
      <BalanceLineChart
        tests={tests}
        metrics={metrics}
        baseline={baseline}
      />
    </div>
  );
};

export default BalanceTestChart;
