import React from 'react';
import Strings from './lang';

const DEFAULT_VALUE = '--';

const BalanceStats = ({ stats }) => {
  const {
    balances = {},
    unused_tokens,
    invoices
  } = stats || {};
  const { open = 0, closed = 0, overdue = 0 } = invoices || {};

  return (
    <div className="row">
      <div className="col-md-4">
        <div className="clinics-stat">
          <div className="stat-label">
            {Strings.totalBalanceTitleLabel}
          </div>
          <div className="stat-value-group">
            {Object.keys(balances).map(key => (
              <div key={key} className="stat-value">
                {balances[key]} <span className="currency">{key.toUpperCase()}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <div className="clinics-stat">
          <div className="stat-label">
            {Strings.creditsRemainingTitle}
          </div>
          <div className="stat-value">
            {unused_tokens ?? DEFAULT_VALUE}
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <div className="clinics-stat">
          <div className="stat-label">
            {Strings.invoicesTitleLabel}
          </div>
          <div className="stat-value">
            {open + closed + overdue}
          </div>
          {overdue > 0 && (
            <small className="error bold">
              {Strings.formatString(Strings.overdueInvoicesLabel, overdue)}
            </small>
          )}

        </div>
      </div>
    </div>
  );
};

export default BalanceStats;
