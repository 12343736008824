const FR = {
  rightHandLabel: 'Main droite',
  leftHandLabel: 'Main gauche',
  numberInputErrorMessage: 'Veuillez écrire une valeur entre 0 et 100',
  trialLabel: 'Essai no {0}',
  rightHandAverageLabel: 'Moyenne pour la main droite :',
  leftHandAverageLabel: 'Moyenne pour la main gauche :',
  defaultOption: 'Choisir une option'
};
 
export default FR;
