import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { If, Then, Else } from 'react-if';
import Tabs from '../tabs';
import { ObjectiveNotesForm } from './note-forms';
import Strings from './lang';
import { mergeRepeatBaselines } from '@/utilities/injury-helpers';
import { BuffaloTest, BuffaloTestView } from '../buffalo-test';
import { BlackhawksTest, BlackhawksTestView } from '../blackhawks-test';
import { VOMSTest, VOMSTestView } from '../voms';
import { PostInjuryTest, PostInjuryTestDisplay } from '../baseline';
import { OrthostaticVitalSignsTest, OrthostaticVitalSignsTestView } from '../ovs-tests';
import { TestButton } from '../test-start-buttons';
import Icon from '../icon';

const SCROLL_OFFSET = 80;

const getTestsStateFromProps = (soap, injury) => {
  const { 
    buffalo_tests,
    blackhawks_tests,
    voms_tests,
    orthostatic_vital_signs_tests,
    baselines 
  } = soap;
  const postInjuryTests = mergeRepeatBaselines(injury);

  return {
    buffalo: !!(buffalo_tests || [])[0],
    blackhawks: !!(blackhawks_tests || [])[0],
    voms: !!(voms_tests || [])[0],
    ovs: !!(orthostatic_vital_signs_tests || [])[0],
    postInjury: !!(baselines || [])[0],
    postInjuryTests
  };
};

const TestTabLabel = ({ label, completed = false }) => {
  return (
    <span>
      {label}&nbsp;
      <If condition={completed}>
        <Then>
          <Icon name="check" fixedWidth className="success" />
        </Then>
        <Else>
          <Icon name="circle-exclamation" fixedWidth className="warning" />
        </Else>
      </If>
    </span>
  );
};

const testButtonKeys = [
  'buffalo',
  'blackhawks',
  'voms',
  'ovs',
  'postInjury'
];

class SoapObjectiveSection extends Component {
  constructor(props) {
    super(props);

    const { soap = {}, injury = {} } = props;

    this.testTabs = React.createRef();

    this.state = {
      ...getTestsStateFromProps(soap, injury),
      selectedKey: 'notes',
      editing: {
        buffaloTest: false,
        blackhawksTest: false,
        vomsTest: false,
        ovsTest: false,
        postInjuryTest: false
      }
    };
  }

  componentDidMount() {
    const { soap = {}, injury = {} } = this.props;
    this.setState({
      ...getTestsStateFromProps(soap, injury)
    });
  }

  render() {
    const { soap = {}, baseline = {} } = this.props;
    const { editing } = this.state;

    const buffaloTest = (soap.buffalo_tests || [])[0];
    const vomsTest = (soap.voms_tests || [])[0];
    const postInjuryTest = (soap.baselines || [])[0];
    const ovsTest = (soap.orthostatic_vital_signs_tests || [])[0];
    const blackhawksTest = (soap.blackhawks_tests || [])[0];

    return (
      <div>
        <Tabs
          ref={this.testTabs}
          className="objective-tabs"
          items={[{
            key: 'notes',
            label: Strings.notesSectionTitle,
            component: (
              <ObjectiveNotesForm
                notes={soap.notes}
                onSubmit={this.props.onSaveNote}
                testButtons={this.renderTestButtons()}
              />
            )
          }, {
            key: 'buffalo',
            label: <TestTabLabel label={Strings.buffalo} completed={buffaloTest !== undefined} />,
            hide: !this.state.buffalo,
            component: (
              <If condition={buffaloTest !== undefined && !editing.buffaloTest}>
                <Then>
                  <BuffaloTestView
                    user={this.props.user}
                    injury={this.props.injury}
                    currentUser={this.props.currentUser}
                    currentClinic={this.props.currentClinic}
                    onEditClick={() => this.onEditTest('buffaloTest')}
                    test={buffaloTest}
                    error={null}
                  />
                </Then>
                <Else>
                  <BuffaloTest
                    user={this.props.user}
                    injury={this.props.injury}
                    currentUser={this.props.currentUser}
                    currentClinic={this.props.currentClinic}
                    soapId={soap.id}
                    buffaloId={((soap.buffalo_tests || [])[0] || {}).id}
                    onSave={() => this.onTestSaved('buffaloTest')}
                  />
                </Else>
              </If>
            )
          }, {
            key: 'blackhawks',
            label: (
              <TestTabLabel 
                label={Strings.blackhawks} 
                completed={blackhawksTest !== undefined} 
              />
            ),
            hide: !this.state.blackhawks,
            component: (
              <If condition={blackhawksTest !== undefined && !editing.blackhawksTest}>
                <Then>
                  <BlackhawksTestView
                    user={this.props.user}
                    injury={this.props.injury}
                    currentUser={this.props.currentUser}
                    currentClinic={this.props.currentClinic}
                    test={blackhawksTest}
                    onEditClick={() => this.onEditTest('blackhawksTest')}
                    error={null}
                  />
                </Then>
                <Else>
                  <BlackhawksTest
                    className="blackhawks-test"
                    user={this.props.user}
                    injury={this.props.injury}
                    currentUser={this.props.currentUser}
                    currentClinic={this.props.currentClinic}
                    soapId={soap.id}
                    blackhawksId={((soap.blackhawks_tests || [])[0] || {}).id}
                    onSave={() => this.onTestSaved('blackhawksTest')}
                  />
                </Else>
              </If>
            )
          }, {
            key: 'voms',
            label: <TestTabLabel label={Strings.voms} completed={vomsTest !== undefined} />,
            hide: !this.state.voms,
            component: (
              <If condition={vomsTest !== undefined && !editing.vomsTest}>
                <Then>
                  <VOMSTestView
                    user={this.props.user}
                    injury={this.props.injury}
                    currentUser={this.props.currentUser}
                    currentClinic={this.props.currentClinic}
                    onEditClick={() => this.onEditTest('vomsTest')}
                    test={vomsTest}
                    error={null}
                  />
                </Then>
                <Else>
                  <VOMSTest
                    user={this.props.user}
                    injury={this.props.injury}
                    currentUser={this.props.currentUser}
                    currentClinic={this.props.currentClinic}
                    soapId={soap.id}
                    vomsId={vomsTest?.id}
                    onSave={() => this.onTestSaved('vomsTest')}
                  />
                </Else>
              </If>
            )
          }, {
            key: 'ovs',
            label: <TestTabLabel label={Strings.ovs} completed={ovsTest !== undefined} />,
            hide: !this.state.ovs,
            component: (
              <If condition={ovsTest !== undefined && !editing.ovsTest}>
                <Then>
                  <OrthostaticVitalSignsTestView
                    user={this.props.user}
                    injury={this.props.injury}
                    currentUser={this.props.currentUser}
                    currentClinic={this.props.currentClinic}
                    onEditClick={() => this.onEditTest('ovsTest')}
                    test={ovsTest}
                    error={null}
                  />
                </Then>
                <Else>
                  <OrthostaticVitalSignsTest
                    user={this.props.user}
                    injury={this.props.injury}
                    currentUser={this.props.currentUser}
                    currentClinic={this.props.currentClinic}
                    soapId={soap.id}
                    testId={ovsTest?.id}
                    onSave={() => this.onTestSaved('ovsTest')}
                  />
                </Else>
              </If>
            )
          }, {
            key: 'postInjury',
            label: (
              <TestTabLabel 
                label={Strings.postInjury} 
                completed={postInjuryTest !== undefined} 
              />
            ),
            hide: !this.state.postInjury,
            component: (
              <If condition={postInjuryTest !== undefined && !editing.postInjuryTest}>
                <Then>
                  <PostInjuryTestDisplay
                    user={this.props.user}
                    injury={this.props.injury}
                    test={postInjuryTest}
                    baseline={baseline || {}}
                    testNumber={this.getPostInjuryTestNumber(postInjuryTest)}
                    showHeader
                    showSelector={false}
                  />
                </Then>
                <Else>
                  <If condition={this.state.postInjury}>
                    <Then>
                      <PostInjuryTest
                        user={this.props.user}
                        currentClinic={this.props.currentClinic}
                        userBaseline={baseline}
                        importableSymptoms={Object.keys(soap.symptoms_scores || {}).length > 0 
                          && (() => {
                            return Object.keys(soap.symptoms_scores || {}).reduce((acc, cur) => {
                              return {
                                ...acc,
                                [cur]: {
                                  level: soap.symptoms_scores[cur],
                                  notes: ''
                                }
                              };
                            }, {});
                          })}
                        soapId={soap.id}
                        onSave={() => this.onTestSaved('postInjuryTest')}
                      />
                    </Then>
                  </If>
                </Else>
              </If>
            )
          }]}
          selectedKey={this.state.selectedKey}
        />
      </div>
    );
  }

  renderTestButtons() {
    const { currentClinic = {} } = this.props;
    return (
      <div className="btn-group btn-group-sm soap-test-btn-group">
        {testButtonKeys.map((key, index) => {
          return (
            <TestButton
              key={`${key}_${index}`}
              type={key}
              clinicId={currentClinic.id}
              className="btn-danger"
              disabled={this.state[key]}
              onClick={() => this.onTestClick(key)}
            />
          );
        })}
      </div>
    );
  }

  onEditTest(testType) {
    const { editing } = this.state;

    this.setState({
      editing: {
        ...editing,
        [testType]: true
      }
    });
  }

  onTestSaved(testType) {
    const { editing } = this.state;

    this.setState({
      editing: {
        ...editing,
        [testType]: false
      }
    });

    if (typeof this.props.onTestSaved === 'function') {
      this.props.onTestSaved();
    }

    this.scrollTabsIntoView();
  }

  scrollTabsIntoView() {
    const rect = this.testTabs.current.getBoundingClientRect();

    if (rect.top < 0) {
      const scroll = rect.top + window.pageYOffset;
      window.scrollTo(0, scroll - SCROLL_OFFSET);
    }
  }

  onTestClick(key) {
    if (!this.state[key]) {
      this.setState({
        [key]: true,
        selectedKey: key
      });
    }
  }

  getPostInjuryTestNumber(test = {}) {
    const { postInjuryTests = [] } = this.state;
    let index = postInjuryTests.findIndex(piTest => piTest.id === test.id) + 1;

    if (index <= 0) {
      index = postInjuryTests.length + 1;
    } 

    return index;
  }
}

SoapObjectiveSection.propTypes = {
  soap: PropTypes.object,
  user: PropTypes.object,
  injury: PropTypes.object,
  currentUser: PropTypes.object,
  currentClinic: PropTypes.object,
  onSaveNote: PropTypes.func,
  onTestSaved: PropTypes.func
};

export default SoapObjectiveSection;
