import React, { useRef } from 'react';
import classnames from 'classnames';
import { useMount } from '@/hooks';

const DIALOG_FOCUS_DELAY = 10;

const Dialog = ({ 
  full = false,
  animation = 'slide-bottom',
  children 
}) => {
  const ref = useRef();

  useMount(() => {
    setTimeout(() => {
      if (ref.current) {
        ref.current.focus();
      }
    }, DIALOG_FOCUS_DELAY);
  });

  return (
    <div 
      ref={ref} 
      tabIndex={0} 
      className={classnames('dialog', animation, { full })}
    >
      {children}
    </div>
  );
};

export default Dialog;
