import React from 'react';
import classnames from 'classnames';
import Icon from '../icon';

export const ViewTypes = {
  List: 'list',
  Chart: 'chart'
};

const ViewSelector = ({
  value,
  onSelect = () => { }
}) => {
  return (
    <div className="btn-group view-selector">
      <button
        type="button"
        className={classnames('btn btn-sm btn-light', { selected: value === ViewTypes.Chart })}
        onClick={() => onSelect('chart')}
      >
        <Icon name="chart-line" />
      </button>
      <button
        type="button"
        className={classnames('btn btn-sm btn-light', { selected: value === ViewTypes.List })}
        onClick={() => onSelect('list')}
      >
        <Icon name="rectangle-list" />
      </button>
    </div>
  );
};

export default ViewSelector;
