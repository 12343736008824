const FR = {
  title: 'Antécédents médicaux',
  concussionHistoryHeadingText: 'Antécédents Concussion',
  concussionDiagnosedLabelText: 'Avant l\'inscription à CCMI, avez-vous déjà reçu un diagnostic de commotion cérébrale ?',
  concussionCountLabelText: 'Combien de commotions cérébrales avez-vous eues avant de vous enregistrer chez CCMI ?',
  concussionDateLabelText: 'Quelle est la dernière commotion cérébrale que vous avez eue ?',
  concussionDateErrorText: 'Veuillez entrer la date de la dernière commotion cérébrale avant CCMI.',
  concussionCountErrorText: 'Le nombre de commotions cérébrales doit être égal ou plus grand que 1',
  concussionRecoveryLabelText: 'Combien de temps a duré la récupération ?',
  concussionRecoveryErrorText: 'La récupération complète doit être au moins d’une journée',
  concussionSymptomsLabelText: 'Souffrez-vous encore de symptômes ?',
  concussionSymptomsStrengthLabelText: 'Est-ce que les commotions cérébrales arrivées après sont arrivées avec moins de force ?',
  concussionNotesLabelText: 'Remarques précédentes sur les commotions cérébrales',
  medicalPsychHistoryHeadingText: 'Antécédents médicaux/psychiatriques',
  medicalConditionsLabelText: 'État de santé',
  currentMedicalConditionsLabel: 'État de santé actuel',
  previousMedicalConditionsLabel: 'État de santé précédent',
  conditionsTableHeadingText: 'État',
  medicationsLabelText: 'Médication',
  currentMedicationsLabel: 'Médication actuelle',
  previousMedicationsLabel: 'Médication précédente',
  behaviouralDisorderLabelText: 'Avez-vous déjà reçu un diagnostic de trouble du comportement ?',
  noneText: 'Aucun',
  behaviouralDisorderOptionADD: 'TDA',
  behaviouralDisorderOptionADHD: 'TDAH',
  learningDisabilityLabelText: 'Avez-vous déjà reçu un diagnostic de problème d’apprentissage ?',
  headacheHistoryLabelText: 'Avez-vous des antécédents de maux de tête ?',
  headacheNotesLabelText: 'Remarques sur les maux de tête',
  psychiatricHxLabelText: 'Avez-vous déjà reçu un diagnostic de :',
  anxietyLabelText: 'Anxiété',
  depressionLabelText: 'Dépression',
  sleepDisorderLabelText: 'Problème de sommeil',
  otherConditionLabelText: 'Autre trouble de santé mentale',
  otherConditionNotesLabelText: 'Spécifier le trouble',
  otherConditionNotesErrorText: 'Veuillez spécifier le trouble',
  radioOptionYes: 'Oui',
  radioOptionNo: 'Non',
  radioOptionNA: 'S./O.',
  durationOptionDays: 'Jours',
  durationOptionWeeks: 'Semaines',
  durationOptionMonths: 'Mois',
  durationOptionYears: 'Ans',
  submitButtonText: 'Sauvegarder',
  yesNoOptionErrorMessage: 'Veuillez choisir Oui ou Non',
  yesNoNaOptionErrorMessage: 'Veuillez choisir Oui, Non ou S./O.',
  selectOptionErrorMessage: 'Veuillez choisir une option',
  selectOneOptionText: 'Veuillez choisir...',
  medicalListErrorMessage: 'Objet déjà choisi',
  medicalListAddedText: 'ajouté {0}',
  previousMedicalConditionsTableHeading: 'État de santé précédent',
  currentMedicalConditionsListHeading: 'État de santé actuel',
  medicalConditionsInputPlaceholder: 'Ajouter un détail sur l’état de santé (appuyez sur Entrée pour ajouter)',
  previousMedicationsTableHeading: 'Médication précédente',
  currentMedicationsListHeading: 'Médication actuelle',
  medicationsInputPlaceholder: 'Ajouter un détail sur la médication (appuyez sur Entrée pour ajouter)',
  undoButtonText: 'Annuler la dernière action',
  removedByTableHeading: 'Enlevé par',
  removedAtTableHeading: 'Enlevé à',
  clinicTableHeading: 'Clinique',
  dateTableHeading: 'Date',
  addedByTableHeading: 'Ajouté par',
  actionTableHeading: 'Action',
  removeButtonText: 'Enlever'
};
 
export default FR;
