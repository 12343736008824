import React, { useMemo } from 'react';
import VOMSTestFormHeader from './voms-test-form-header';
import VOMSTestTableRow from './voms-test-table-row';
import {
  getVOMSTestState,
  symptomHasDistance,
  symptomHelpDocuments
} from './voms-data';
import Strings from './lang';

const VOMSTestTable = ({ test }) => {
  const { symptoms } = useMemo(() => getVOMSTestState({ test }), [test]);

  return (
    <table className="table voms-test-table">
      <thead>
        <VOMSTestFormHeader />
      </thead>
      <tbody>
        {Object.keys(symptoms).map(symptom => (
          <VOMSTestTableRow
            key={symptom}
            symptomName={Strings[`symptoms_${symptom}`]}
            symptomState={symptoms[symptom] || {}}
            helpDocumentName={symptomHelpDocuments[symptom]}
            hasDistance={symptomHasDistance(symptom)}
          />
        ))}
      </tbody>
    </table>
  );
};

export default VOMSTestTable;
