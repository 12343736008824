import { createContext, useContext } from 'react';

const DropdownContext = createContext({
  close: () => {}
});

const useDropdown = () => useContext(DropdownContext);
const DropdownProvider = DropdownContext.Provider;

export {
  useDropdown,
  DropdownProvider
};
