import {
  getLocalStorageItem,
  setLocalStorageItem,
  removeLocalStorageItem
} from '@/utilities/local-storage';

const CURRENT_USER_ID_KEY = 'com.ccmi.users.current.id';
const AUTH_TOKEN_KEY = 'com.ccmi.sessions.token';
const CURRENT_CLINIC_ID_KEY = 'com.ccmi.clinics.current.id';
const ACTIVITY_STORAGE_KEY = 'com.ccmi.activity';

/*******************/
/* Current User ID */
/*******************/

const removeCurrentUserId = () => removeLocalStorageItem(CURRENT_USER_ID_KEY);

/**************/
/* Auth Token */
/**************/

const getAuthToken = () => getLocalStorageItem(AUTH_TOKEN_KEY);
const setAuthToken = (token) => setLocalStorageItem(AUTH_TOKEN_KEY, token);
const removeAuthToken = () => removeLocalStorageItem(AUTH_TOKEN_KEY);

/*********************/
/* Current Clinic ID */
/*********************/

const getCurrentClinicId = () => getLocalStorageItem(CURRENT_CLINIC_ID_KEY);
const setCurrentClinicId = (id) => setLocalStorageItem(CURRENT_CLINIC_ID_KEY, id);
const removeCurrentClinicId = () => removeLocalStorageItem(CURRENT_CLINIC_ID_KEY);

const updateCurrentClinicForUser = (user) => {
  const clinics = user.clinics || [];
  const clinicIds = clinics.filter(Boolean).map(clinic => clinic.id);
  const currentClinicId = getCurrentClinicId();

  // only set the initial current clinic id if there isn't
  // one or if the users clinics do not contain the current clinic id.
  if (!currentClinicId || clinicIds.indexOf(currentClinicId) === -1) {
    if (clinicIds.length) {
      setCurrentClinicId(clinicIds[0]);
    } else {
      removeCurrentClinicId();
    }
  }
};

/*************/
/* Auth Data */
/*************/

const removeAuthData = () => {
  return removeCurrentUserId()
    && removeAuthToken();
};

const isAuthenticated = () => {
  try {
    return getAuthToken().length > 0;
  /* eslint-disable no-unused-vars */
  } catch (e) {
  /* eslint-enable no-unused-vars */
    return false;
  }
};

const getActivity = () => {
  const activity = getLocalStorageItem(ACTIVITY_STORAGE_KEY);
  if (!activity) return null;
  const activityParsed = parseInt(activity, 10);
  return !isNaN(activityParsed) ? activityParsed : null;
};

const setActivity = (time) => setLocalStorageItem(ACTIVITY_STORAGE_KEY, time);

export {
  removeCurrentUserId,
  getAuthToken,
  setAuthToken,
  removeAuthToken,
  getCurrentClinicId,
  setCurrentClinicId,
  removeCurrentClinicId,
  updateCurrentClinicForUser,
  removeAuthData,
  isAuthenticated,
  getActivity,
  setActivity,
  ACTIVITY_STORAGE_KEY,
  AUTH_TOKEN_KEY
};
