import React, { useMemo } from 'react';
import Icon from '../../icon';
import { ReportsTable, collectClaimReportInfo } from '../reports';
import Strings from '../lang';

const ReportsTab = ({ claim }) => {
  const reports = useMemo(() => collectClaimReportInfo(claim), [claim]);

  return (
    <section className="claim-section">
      <div className="claim-section-header">
        <Icon name="file-pen" />
        <div className="claim-section-title">{Strings.reportsSectionTitle}</div>
      </div>
      <div className="claim-section-content">
        <ReportsTable reports={reports} />
      </div>
    </section>
  );
};

export default ReportsTab;
