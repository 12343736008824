import React from 'react';
import PropTypes from 'prop-types';
import SoapNoteSectionDisplay from './soap-note-section-display';
import SoapSectionSymptomDisplay from './soap-section-symptom-display';
import SoapNoteRecoveryStageDisplay from './soap-note-recovery-stage-display';
import { BuffaloTestView } from '../buffalo-test';
import { BlackhawksTestView } from '../blackhawks-test';
import { VOMSTestView } from '../voms';
import { PostInjuryTestDisplay } from '../baseline';
import { OrthostaticVitalSignsTestView } from '../ovs-tests';

const SoapNotesFormDisplay = ({
  soap = {},
  userId,
  clinicId
}) => {
  const { 
    recovery_protocol_stage,
    notes = {},
    symptoms_scores = {},
    blackhawks_tests = [],
    buffalo_tests = [],
    voms_tests = [],
    baselines = [], 
    orthostatic_vital_signs_tests = [],
    injury_id,
    diagnosis = {}
  } = soap;
  const blackhawks = blackhawks_tests[0];
  const buffalo = buffalo_tests[0];
  const voms = voms_tests[0];
  const ovs = orthostatic_vital_signs_tests[0];
  const postInjury = baselines[0];

  return (
    <div className="soap-form-display">
      <SoapSectionSymptomDisplay symptoms_scores={symptoms_scores} />

      <SoapNoteSectionDisplay 
        notes={notes}
        tests={{
          blackhawks,
          buffalo,
          voms,
          ovs,
          postInjury
        }}
        injuryId={injury_id}
        userId={userId}
        diagnosis={diagnosis || {}}
        clinicId={clinicId} 
      />

      <SoapNoteRecoveryStageDisplay stages={recovery_protocol_stage} />

      {!!buffalo && (
        <div className="row">
          <div className="col-md-12">
            <hr />
            <BuffaloTestView
              showTestHeader={false}
              test={buffalo}
            />
          </div>
        </div>
      )}

      {!!blackhawks && (
        <div className="row soap-note-test">
          <div className="col-md-12">
            <hr />
            <BlackhawksTestView
              showTestHeader={false}
              test={blackhawks}
            />
          </div>
        </div>
      )}

      {!!voms && (
        <div className="row soap-note-test">
          <div className="col-md-12">
            <hr />
            <VOMSTestView 
              showTestHeader={false}
              test={voms}
            />
          </div>
        </div>
      )}

      {!!ovs && (
        <div className="row soap-note-test">
          <div className="col-md-12">
            <hr />
            <OrthostaticVitalSignsTestView
              showTestHeader={false}
              showResultsCalculations={false}
              test={ovs}
            />
          </div>
        </div>
      )}

      {!!postInjury && (
        <div className="row soap-note-test">
          <div className="col-md-12">
            <hr />
            <PostInjuryTestDisplay
              nestedDisplay
              showTestNumber={false}
              showHeader={false}
              showSelector={false}
              test={postInjury}
              baseline={postInjury.baseline_comparison || {}}
            />
          </div>
        </div>
      )}
    </div>
  );
};

SoapNotesFormDisplay.propTypes = {
  soap: PropTypes.object,
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default SoapNotesFormDisplay;
