import { 
  RoleDescriptions, 
  RoleNames, 
  RoleResourceTypes, 
  makeRoleDescription,
  userHasRoleMatchingOneOfDescriptions
} from '@/utilities/user-roles';

import replaceLinkParams from '@/utilities/replace-link-params';
import { ClinicPaths } from '@/paths';
import Strings from './lang';

const Permissions = {
  makePlayerProfileEditorRoles: (clinicId) => ([
    makeRoleDescription(RoleNames.ClinicOwner, RoleResourceTypes.Clinic, clinicId),
    makeRoleDescription(RoleNames.Clinician, RoleResourceTypes.Clinic, clinicId),
    makeRoleDescription(RoleNames.Specialist, RoleResourceTypes.Clinic, clinicId),
    makeRoleDescription(RoleNames.BaselineTester, RoleResourceTypes.Clinic, clinicId),
    makeRoleDescription(RoleNames.ClinicFrontDesk, RoleResourceTypes.Clinic, clinicId)
  ]),
  makePlayerSportsViewerRoles: (clinicId) => ([
    makeRoleDescription(RoleNames.ClinicOwner, RoleResourceTypes.Clinic, clinicId),
    makeRoleDescription(RoleNames.Clinician, RoleResourceTypes.Clinic, clinicId),
    makeRoleDescription(RoleNames.Specialist, RoleResourceTypes.Clinic, clinicId),
    makeRoleDescription(RoleNames.BaselineTester, RoleResourceTypes.Clinic, clinicId),
    makeRoleDescription(RoleNames.ClinicFrontDesk, RoleResourceTypes.Clinic, clinicId)
  ]),
  makePlayerSportsEditorRoles: (clinicId) => ([
    makeRoleDescription(RoleNames.ClinicOwner, RoleResourceTypes.Clinic, clinicId),
    makeRoleDescription(RoleNames.Clinician, RoleResourceTypes.Clinic, clinicId)
  ]),
  makePlayerMedicalViewerRoles: (clinicId) => ([
    makeRoleDescription(RoleNames.Clinician, RoleResourceTypes.Clinic, clinicId),
    makeRoleDescription(RoleNames.Specialist, RoleResourceTypes.Clinic, clinicId),
    makeRoleDescription(RoleNames.BaselineTester, RoleResourceTypes.Clinic, clinicId),
    makeRoleDescription(RoleNames.ClinicStaff, RoleResourceTypes.Clinic, clinicId)
  ]),
  makePlayerMedicalEditorRoles: (clinicId) => ([
    makeRoleDescription(RoleNames.Clinician, RoleResourceTypes.Clinic, clinicId),
    makeRoleDescription(RoleNames.Specialist, RoleResourceTypes.Clinic, clinicId),
    makeRoleDescription(RoleNames.ClinicStaff, RoleResourceTypes.Clinic, clinicId)
  ]),
  makeBagTagEditorRoles: (clinicId) => ([
    makeRoleDescription(RoleNames.Clinician, RoleResourceTypes.Clinic, clinicId),
    makeRoleDescription(RoleNames.ClinicFrontDesk, RoleResourceTypes.Clinic, clinicId)
  ]),
  makePlayerBaselinesViewerRoles: (clinicId) => ([
    RoleDescriptions.SuperAdmin,
    RoleDescriptions.SalesAdmin,
    makeRoleDescription(RoleNames.Clinician, RoleResourceTypes.Clinic, clinicId),
    makeRoleDescription(RoleNames.Specialist, RoleResourceTypes.Clinic, clinicId),
    makeRoleDescription(RoleNames.BaselineTester, RoleResourceTypes.Clinic, clinicId),
    makeRoleDescription(RoleNames.ClinicFrontDesk, RoleResourceTypes.Clinic, clinicId),
    makeRoleDescription(RoleNames.ClinicStaff, RoleResourceTypes.Clinic, clinicId)
  ]),
  makePlayerBaselinesEditorRoles: (clinicId) => ([
    makeRoleDescription(RoleNames.Clinician, RoleResourceTypes.Clinic, clinicId),
    makeRoleDescription(RoleNames.BaselineTester, RoleResourceTypes.Clinic, clinicId),
    makeRoleDescription(RoleNames.ClinicFrontDesk, RoleResourceTypes.Clinic, clinicId),
    makeRoleDescription(RoleNames.ClinicStaff, RoleResourceTypes.Clinic, clinicId)
  ]),
  makePlayerInjuriesViewerRoles: (clinicId) => ([
    RoleDescriptions.SuperAdmin,
    RoleDescriptions.SalesAdmin,
    makeRoleDescription(RoleNames.Clinician, RoleResourceTypes.Clinic, clinicId),
    makeRoleDescription(RoleNames.Specialist, RoleResourceTypes.Clinic, clinicId),
    makeRoleDescription(RoleNames.ClinicFrontDesk, RoleResourceTypes.Clinic, clinicId)
  ]),
  makePlayerActiveInjuryViewerRoles: (clinicId) => ([
    makeRoleDescription(RoleNames.ClinicStaff, RoleResourceTypes.Clinic, clinicId)
  ]),
  makePlayerDanaTestsViewerRoles: (clinicId) => ([
    makeRoleDescription(RoleNames.Clinician, RoleResourceTypes.Clinic, clinicId),
    makeRoleDescription(RoleNames.Specialist, RoleResourceTypes.Clinic, clinicId)
  ]),
  makePlayerBalanceTestsViewerRoles: (clinicId) => ([
    makeRoleDescription(RoleNames.Clinician, RoleResourceTypes.Clinic, clinicId),
    makeRoleDescription(RoleNames.Specialist, RoleResourceTypes.Clinic, clinicId)
  ]),
  makePlayerInjuryReportsViewerRoles: (clinicId) => ([
    RoleDescriptions.SuperAdmin,
    RoleDescriptions.SalesAdmin,
    makeRoleDescription(RoleNames.Clinician, RoleResourceTypes.Clinic, clinicId),
    makeRoleDescription(RoleNames.Specialist, RoleResourceTypes.Clinic, clinicId),
    makeRoleDescription(RoleNames.ClinicFrontDesk, RoleResourceTypes.Clinic, clinicId)
  ]),
  makeTestViewerRoles: (clinicId) => ([
    makeRoleDescription(RoleNames.Clinician, RoleResourceTypes.Clinic, clinicId),
    makeRoleDescription(RoleNames.Specialist, RoleResourceTypes.Clinic, clinicId),
    makeRoleDescription(RoleNames.ClinicFrontDesk, RoleResourceTypes.Clinic, clinicId)
  ]),
  makeStartInjuryRoles: (clinicId) => ([
    makeRoleDescription(RoleNames.Clinician, RoleResourceTypes.Clinic, clinicId),
    makeRoleDescription(RoleNames.Specialist, RoleResourceTypes.Clinic, clinicId),
  ])
};

const makeTabMeta = (userId, clinicId) => {
  return {
    profile: {
      label: Strings.profileTabLabel,
      key: 'profile',
      route: replaceLinkParams(ClinicPaths.patientProfile.profile.link, {
        clinicId,
        userId
      })
    },
    sports: {
      label: Strings.sportsTabLabel,
      key: 'sports',
      route: replaceLinkParams(ClinicPaths.patientProfile.sports.link, {
        clinicId,
        userId
      })
    },
    medical: {
      label: Strings.medicalInfoTabLabel,
      key: 'medical',
      route: replaceLinkParams(ClinicPaths.patientProfile.medical.link, {
        clinicId,
        userId
      })
    },
    baselines: {
      label: Strings.baselineHistoryTabLabel,
      key: 'baselines',
      route: replaceLinkParams(ClinicPaths.patientProfile.baselines.index.link, {
        clinicId,
        userId
      })
    },  
    danaTests: {
      label: Strings.danaTestTabLabel,
      key: 'dana',
      route: replaceLinkParams(ClinicPaths.patientProfile.danaTests.link, {
        clinicId,
        userId
      })
    },
    balanceTests: {
      label: 'Balance Tests',
      key: 'balance',
      route: replaceLinkParams(ClinicPaths.patientProfile.balanceTests.link, {
        clinicId,
        userId
      })
    },
    injuries: {
      label: Strings.injuryHistoryTabLabel,
      key: 'injuries',
      route: replaceLinkParams(ClinicPaths.patientProfile.injuries.index.link, {
        clinicId,
        userId
      })
    },
    reported: {
      label: Strings.injuryReportsTabLabel,
      key: 'reported',
      route: replaceLinkParams(ClinicPaths.patientProfile.reported.index.link, {
        clinicId,
        userId
      })
    }
  };
};

const buildTabsFromRoles = (userId, clinicId, currentUser) => {
  const tabs = [];
  const tabMeta = makeTabMeta(userId, clinicId);

  if (
    userHasRoleMatchingOneOfDescriptions(
      currentUser, 
      Permissions.makePlayerProfileEditorRoles(clinicId)
    )
  ) {
    tabs.push(tabMeta.profile);
  }

  if (
    userHasRoleMatchingOneOfDescriptions(
      currentUser, 
      Permissions.makePlayerSportsViewerRoles(clinicId)
    )
  ) {
    tabs.push(tabMeta.sports);
  }

  if (
    userHasRoleMatchingOneOfDescriptions(
      currentUser, 
      Permissions.makePlayerMedicalViewerRoles(clinicId)
    )
  ) {
    tabs.push(tabMeta.medical);
  }

  if (
    userHasRoleMatchingOneOfDescriptions(
      currentUser, 
      Permissions.makePlayerBaselinesViewerRoles(clinicId)
    )
  ) {
    tabs.push(tabMeta.baselines);
  }

  if (
    userHasRoleMatchingOneOfDescriptions(
      currentUser,
      Permissions.makePlayerDanaTestsViewerRoles(clinicId)
    )
  ) {
    tabs.push(tabMeta.danaTests);
  }

  if (
    userHasRoleMatchingOneOfDescriptions(
      currentUser,
      Permissions.makePlayerBalanceTestsViewerRoles(clinicId)
    )
  ) {
    tabs.push(tabMeta.balanceTests);
  }

  if (
    userHasRoleMatchingOneOfDescriptions(
      currentUser, 
      Permissions.makePlayerInjuriesViewerRoles(clinicId)
    ) || userHasRoleMatchingOneOfDescriptions(
      currentUser,
      Permissions.makePlayerActiveInjuryViewerRoles(clinicId)
    )
  ) {
    tabs.push(tabMeta.injuries);
  }

  if (
    userHasRoleMatchingOneOfDescriptions(
      currentUser, 
      Permissions.makePlayerInjuryReportsViewerRoles(clinicId)
    )
  ) {
    tabs.push(tabMeta.reported);
  }

  return tabs;
};

export {
  Permissions,
  buildTabsFromRoles,
  makeTabMeta
};
