import React, { useCallback, useState } from 'react';
import dayjs from 'dayjs';
import MedicalListGroup from './medical-list-group';
import Strings from './lang';
import MedicalListTable from './medical-list-table';

const MedicalList = ({
  label,
  items = [],
  nameLabel,
  placeholder,
  currentUser,
  currentClinic,
  onChange
}) => {
  const [error, setError] = useState(false);
  const [text, setText] = useState('');

  const handleChange = useCallback((e) => {
    setText(e.target.value);
    setError(false);
  }, []);

  const onItemAdded = useCallback(() => {
    if (text.length === 0) {
      return;
    }

    const match = items?.find(item => item.name.toLowerCase() === text.toLowerCase());

    if (match) {
      setError(true);
      return;
    }

    const newItems = [
      ...items,
      {
        added_at: dayjs().format('YYYY-MM-DD HH:mm:ss'),
        clinician: currentUser,
        clinic: currentClinic,
        name: text
      }
    ];

    setText('');

    onChange(newItems);
  }, [currentClinic, currentUser, items, onChange, text]);

  const onRemoveItem = useCallback((index) => {
    const newItems = [...items];
    const removed = newItems.splice(index, 1);

    onChange(newItems, removed[0]);
  }, [items, onChange]);

  return (
    <MedicalListGroup
      label={label}
      inputProps={{
        value: text,
        placeholder,
        onChange: handleChange
      }}
      error={error}
      message={Strings.medicalListErrorMessage}
      onItemAdded={onItemAdded}
    >
      <MedicalListTable
        items={items}
        removable
        nameLabel={nameLabel}
        onRemove={onRemoveItem}
      />
    </MedicalListGroup>
  );
};

export default MedicalList;
