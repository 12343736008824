import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { If, Then } from 'react-if';
import Strings from './lang';

const BlackhawksTimerDisplay = ({
  duration,
  isRunning,
  onToggle,
  onReset
}) => (
  <div className="blackhawks-timer">
    <span className="timer-display">{dayjs.duration(duration).format('H:mm:ss.SSS')}</span>
    <If condition={!isRunning && duration === 0}>
      <Then>
        <button type="button" className="btn btn-success" onClick={onToggle}>
          {Strings.testTimeStartButtonText}
        </button>
      </Then>
    </If>

    <If condition={isRunning}>
      <Then>
        <button type="button" className="btn btn-default" onClick={onToggle}>
          {Strings.testTimeStopButtonText}
        </button>
      </Then>
    </If>

    <If condition={!isRunning && duration > 0}>
      <Then>
        <div>
          <button type="button" className="btn btn-success" onClick={onToggle}>
            {Strings.testTimeResumeButtonText}
          </button>
          <button type="button" className="btn btn-warning" onClick={onReset}>
            {Strings.testTimeResetButtonText}
          </button>
        </div>
      </Then>
    </If>
  </div>
);

BlackhawksTimerDisplay.propTypes = {
  duration: PropTypes.number,
  isRunning: PropTypes.bool,
  onToggle: PropTypes.func,
  onReset: PropTypes.func
};

export default BlackhawksTimerDisplay;
