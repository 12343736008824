const UPDATE_PROGRESS_REPORTS = 'UPDATE_PROGRESS_REPORTS';

const updateProgressReports = (userId, injuryId, progressReportsList) => ({
  type: UPDATE_PROGRESS_REPORTS,
  userId,
  injuryId,
  progressReportsList
});

export {
  UPDATE_PROGRESS_REPORTS,
  updateProgressReports
};
