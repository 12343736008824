const EN = {
  claimsTitle: 'Axia Health IDT Claims',
  dischargedClaims: 'Discharged Claims',
  activeClaims: 'Active Claims',
  newClaims: 'New Claims',
  noClaimsFoundMessage: 'No new claims have been assigned to your clinic yet',
  noDischargedClaims: 'No discharged claims found',
  noActiveClaims: 'No active claims found',
  claimNumberColumn: 'Claim Number',
  workerNameColumn: 'Name',
  nextAppointmentColumn: 'Next Appointment',
  nextReportDueColumn: 'Next Report Due',
  dischargedDateColumn: 'Discharged Date',
  referalDateColumn: 'Referral Date',
  initialReportTitle: 'Initial',
  midPointReportTitle: 'Mid-Point',
  careOutcomesReportTitle: 'Care & Outcomes',
  supplementalReportTitle: 'Supplemental',
  claimDetailsTitle: 'Claim Details',
  workerDetailsTitle: 'Worker',
  dobLabel: 'Date of Birth',
  phoneNumberLabel: 'Phone Number',
  interpreterLabel: 'Interpreter',
  documentsTitle: 'Documents',
  emptyDocumentsMessage: 'No attached documents for this claim',
  initialApptBannerTitle: 'Book Initial Appointment',
  initialApptBannerMessage: 'To initiate this claim, please add the booking information for the initial appointment.',
  setInitialApptButton: 'Set Appointment',
  addInitialApptHeader: 'Add Initial Appointment',
  addInitialApptSuccessMessage: 'Initial appointment has been set and the claim has been initiated successfully!',
  documentsTabLabel: 'Documents',
  treatmentTabLabel: 'Treatment',
  claimStatusNotStarted: 'Not Started',
  claimStatusActive: 'Active',
  claimStatusDischarged: 'Discharged',
  claimTitle: 'IDT Claim - {0}',
  appointmentInputLabel: 'Appointment Date & Time',
  appointmentInputBetweenError: 'Please enter a valid date between {0} and {1}',
  appointmentInputAfterError: 'Please enter a valid date on or after {0}',
  appointmentInputBeforeError: 'Please enter a valid date on or before {0}',
  appointmentConfirmationMessage: 'Appointment date & time',
  treatmentTypeLabel: 'Treatment Type',
  treatmentTypeErrorMessage: 'Please select a treatment type',
  selectTreatmentType: 'Select Treatment Type',
  treatmentTypeInitialAssessment: 'Initial Visit',
  treatmentTypeFunctionalTesting: 'Functional Testing',
  treatmentTypePhysicalTherapy: 'Active Rehab',
  treatmentTypeOccupationalTherapy: 'Occupational Therapy',
  treatmentTypeWorkHardening: 'Work Hardening',
  virtualApptCheckboxLabel: 'This is a virtual appointment',
  virtualApptLabel: 'Virtual',
  submitButton: 'Submit',
  providerListTitle: 'Appointment Providers',
  addProviderButton: 'Add Another Provider',
  providerNameLabel: 'Provider Name',
  providerNameError: 'Please enter the name of the provider',
  providerDesignationLabel: 'Designation',
  providerDesignationError: 'Please select a designation',
  selectDesignationLabel: 'Select Designation',
  appointmentsTabLabel: 'Appointments',
  addAppointmentText: 'Add Appointment',
  appointmentAddedSuccessMessage: 'Appointment Added Successfully',
  noAppointmentsAddedMessage: 'No appointments have been added to this block',
  completedText: 'Completed',
  blockRequestedLabel: 'Block Requested',
  initialApptRequiredMessage: 'Initial Appointment must be added to initiate this claim',
  requiredAppointmentsLabel: 'Required Appointments',
  idtPocBlockLabel: 'IDT POC Block',
  idtPocWhBlockLabel: 'IDT POC with Work Hardening Block',
  apptStatusBooked: 'Booked',
  apptStatusArrived: 'Arrived',
  apptStatusCancelled: 'Cancelled',
  apptStatusNoShowed: 'No Showed',
  apptStatusRescheduled: 'Rescheduled',
  reportsTab: 'Reports',
  reportsSectionTitle: 'IDT Reports',
  startReportButton: 'Start Report',
  continueReportButton: 'Continue Report',
  viewReportButton: 'View Report',
  startReportErrorMessage: 'Unable to start report: {0}',
  reportTypeColumnLabel: 'Report Type',
  blockTypeColumnLabel: 'Block Type',
  dueDateColumnLabel: 'Due Date',
  reportStatusColumnLabel: 'Status',
  reportStatusNotStarted: 'Not Started',
  reportStatusInProgress: 'In Progress',
  reportStatusCompleted: 'Completed',
  reportStatusPendingApproval: 'Pending Final Approval',
  reportStatusApproved: 'Approved',
  reportStatusSubmitted: 'Submitted',
  updateApptStatusErrorMessage: 'Unable to update appointment status: {0}',
  updateApptStatusModalTitle: 'Update Appointment Status',
  updateApptStatusModalMessage: 'You are about to change the status of the appointment to <strong>{0}</strong>. Please enter any notes pertaining to the this status change below, such as reason for cancellation, date of reschedule, etc...',
  notesLabel: 'Notes',
  notesErrorMessage: 'Notes are required',
  updateApptStatusModalButton: 'Update Status',
  startReportConfirmationTitle: 'Confirm Start Report',
  startReportConfirmationMessage: 'You are about to start a report from a treatment block that has not started yet. Do you wish to continue?',
  confirmButtonLabel: 'Confirm',
  finalReportTableColumn: 'Final Report PDF',
  approveReportButton: 'Approve Report',
  approveReportSuccessMessage: 'Final report has been approved',
  approveReportConsentLabel: 'I verify that the contents of this document are complete and accurate. I authorize Axia Health to submit this document to WSIB on my behalf.',
  uploadDocumentLabel: 'Upload Documents',
  uploadingFilesLabel: 'Uploading Files ...',
  uploadedFilesLabel: 'Files Uploaded',
  closeButtonLabel: 'Close',
  dropzoneLabel: 'Drag PDF files here or click to select files',
  fileNameColumnHeader: 'File Name',
  fileSizeColumnHeader: 'File Size',
  updatedAtColumnHeader: 'Uploaded',
  pleaseWaitUploadingLabel: 'Please Wait ...',
  idtResourcesLabel: 'IDT Resources',
  idtPoliciesHeader: 'IDT Policy & Consents',
  outcomeMeasuresHeader: 'Outcome Measures',
  functionalTestingHeader: 'Functional Testing',
  notProvidedLabel: 'Not Provided',
  viewFinalReportPdf: 'View Report PDF',
  otApptsIndicatorLabel: 'OT Appointments'
};

export default EN;
