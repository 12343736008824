const FR = {
  typeLabel: 'Type',
  dateLabel: 'Date',
  paidByLabel: 'Payé par :',
  totalLabel: 'Total :',
  paymentApprovedTitle: 'Confirmation de paiement',
  paymentApprovedEmailText: 'Merci d’avoir fait votre paiement. Un courriel avec les détails de confirmation vous sera envoyé.',
  paymentApprovedInvoiceText: 'Détails de la facture',
  paymentApprovedBackButtonText: 'Revenir au tableau de bord',
  paymentApprovedConfirmationNumText: 'No de confirmation',
  paymentFailTitle: 'Désolé, le paiement n’a pas fonctionné.',
  paymentInvoiceNumberLabel: 'No de facture',
  paymentCardHolderLabel: 'Détenteur de la carte',
  paymentCardLabel: 'Carte',
  backToDashboardBtnText: 'Revenir au tableau de bord',
  backToCurrentBalanceBtnText: 'Revenir au solde actuel',
  expiredCardErrorMessage: 'Votre paiement a été refusé, car votre carte est expirée. Veuillez utiliser une autre carte.',
  cardCancelledErrorMessage: 'La transaction par débit a été refusée. Veuillez utiliser une autre carte.',
  exceedsRefundErrorMessage: 'Le montant de cette transaction est plus grand que votre limite de remboursement. Veuillez utiliser une autre carte.',
  invalidCardErrorMessage: 'Votre paiement a été refusé. Veuillez utiliser un autre mode de paiement.',
  cardNotSupportedErrorMessage: 'Votre carte n’est pas reconnue. Veuillez utiliser un autre mode de paiement.',
  paymentAmountLabel: 'Montant du paiement',
  fullPaymentOption: 'Montant total',
  minimumPaymentOption: 'Montant minimal',
  customPaymentOption: 'Montant personnalisé',
  customPaymentLabel: 'Entre {0} et {1}',
  amountLabel: 'Montant',
  customPaymentErrorMessage: 'Veuillez indiquer un nombre entre {0} et {1}',
  updateAmountButtonText: 'Mettre à jour',
  payNowButtonText: 'Payer maintenant',
  continueButtonText: 'Continuer',
  paymentDrawerTitle: 'Effectuer un paiement',
  selectPaymentOptionLabel: 'Sélectionner une option',
  balanceText: 'Équilibre',
  paymentsTableTitle: 'Paiements',
  orderIdColumnHeader: 'Numéro de commande',
  paymentAdjustmentText: 'Ajustement de Paiement',
  normalPaymentText: 'Paiement',
  recievePaymentDrawerTitle: 'Paiement reçu',
  recievePaymentAmountError: 'Le montant doit être inférieur à {0} et supérieur à 0',
  noteLabel: 'Remarque',
  submitButtonText: 'Soumettre',
  currentBalanceLabel: 'Solde actuel',
  paymentsPopoverTitle: 'Paiements reçus',
  paymentIdLabel: 'ID de paiement',
  editAmountLabel: 'Modifier le montant',
  paymentFailedErrorMessage: 'Paiement échoué: {0}',
  retryPaymentButtonText: 'Réessayer',
  paymentSuccessEmailMessage: 'Un reçu a été envoyé par courriel à l\'adresse électronique que nous avons en dossier pour vous. Si vous n\'avez pas reçu de reçu et souhaitez en obtenir un, veuillez contacter <strong>Pamela Gonzalez-Silva</strong> à <a href="mailto:pamela@completeconcussions.com">pamela@completeconcussions.com</a>',
  paymentSuccessTitle: 'Paiement approuvé!',
  paymentSuccessFooterMessage: 'Merci pour votre entreprise!',
  dashboardLinkText: 'Dashboard',
  invoicesLinkText: 'Invoices',
  creditCardInputLabel: 'Carte de crédit',
  billingNameInputLabel: 'Nom sur la carte',
  billingNameErrorMessage: 'Veuillez saisir le nom au recto de la carte',
  payAmountButtonText: 'Payez {0} {1}'
};
 
export default FR;
