import React, { useMemo } from 'react';
import {
  ResponsiveContainer,
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Dot
} from 'recharts';
import { getYAxisReferenceLines } from './reference-lines';
import TestTooltip from './tooltip';
import Icon from '../../icon';
import {
  getYAxisDomainMax,
  getYAxisDomainMin,
  getYAxisTicks,
  getBaselineMetricStats
} from './utils';
import { TEST_COLORS, Metric } from '../helpers';
import Strings from '../lang';

const legendFormatter = (value, data) => {
  const { iteration = 1 } = data.payload.data[0] || {};
  return `${Strings[`${value.toLowerCase()}Label`] || value}${iteration > 1 ? ` (${iteration})` : ''}`;
};

const ICON_SIZE = 14;
const upperMetrics = [Metric.ReactionTime, Metric.SurveyScore];

const isSuspectTest = (value, metric, mean, stdev) => {
  const lower = (mean - (stdev * 2));
  const upper = (mean + (stdev * 2));

  if (upperMetrics.includes(metric)) {
    return !isNaN(upper) && value > upper;
  }

  return !isNaN(lower) && value < lower;
};

const LineIcon = ({
  cx, 
  cy, 
  payload,
  metric,
  mean, 
  stdev,
  value,
  ...rest
}) => {
  const isSuspect = useMemo(() => (
    isSuspectTest(value, metric, mean, stdev)
  ), [mean, metric, stdev, value]);
  
  if (payload.post_injury) {
    return (
      <Icon
        prefix="fas"
        name={isSuspect ? 'triangle-exclamation' : 'bolt'}
        x={cx - (ICON_SIZE / 2)}
        y={cy - (ICON_SIZE / 2)}
        color="#DB231F"
        height={ICON_SIZE}
        width={ICON_SIZE}
        style={{ zIndex: 10 }}
      />
    );
  }
  
  return (
    <Dot
      value={value}
      cx={cx}
      cy={cy}
      payload={payload}
      {...rest}
    />
  );
};

const getLines = (tests, metric, baseline, onDotClick) => {
  const stats = getBaselineMetricStats(baseline, metric);
  return tests?.map((data) => {
    const { iteration = 1, test } = data[0] || {};

    return (
      <Line
        connectNulls
        key={`${test}:${iteration}`}
        type="monotone"
        dataKey={metric}
        data={data}
        name={test}
        isAnimationActive={false}
        dot={<LineIcon {...stats} metric={metric} />}
        activeDot={{
          r: 7,
          cursor: 'pointer',
          onClick: onDotClick
        }}
        stroke={TEST_COLORS[test]?.[iteration - 1] || '#0C1826'}
      />
    );
  });
};

const DanaTestLineChart = ({
  metric,
  series,
  baseline,
  tests,
  onDotClick
}) => {
  const yAxisTicks = useMemo(() => getYAxisTicks(series), [series]);
  const yAxisMaxDomain = useMemo(() => (
    getYAxisDomainMax(series, metric, baseline)
  ), [baseline, metric, series]);
  const yAxisMinDomain = useMemo(() => (
    getYAxisDomainMin(metric, baseline)
  ), [baseline, metric]);

  return (
    <ResponsiveContainer width="100%" height={450}>
      <LineChart>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="created_at"
          type="category"
          allowDuplicatedCategory={false}
          style={{ fontSize: 12 }}
        />
        <YAxis
          dataKey={metric}
          type="number"
          ticks={yAxisTicks}
          domain={[yAxisMinDomain, yAxisMaxDomain]}
          tickFormatter={(value) => Math.round(value)}
        />
        {getYAxisReferenceLines(baseline, metric)}
        <Tooltip content={<TestTooltip />} />
        <Legend formatter={legendFormatter} />
        {getLines(tests, metric, baseline, onDotClick)}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default DanaTestLineChart;
