import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { If, Then, Else } from 'react-if';
import RawString from '../raw-string';
import { FormInput, NumberValidator } from '@/forms';

const BlackhawksStationaryBikeStageTableRow = ({
  index,
  time,
  tension,
  rpm,
  isDisplayText,
  heartRate,
  touched,
  symptoms,
  onChangeHeartRate,
  onChangeSymptoms
}) => {
  const handleHeartRateChange = useCallback((e) => {
    if (typeof onChangeHeartRate === 'function') {
      onChangeHeartRate(index, e);
    }
  }, [onChangeHeartRate, index]);

  const handleSymptomsChange = useCallback((e) => {
    if (typeof onChangeSymptoms === 'function') {
      onChangeSymptoms(index, e);
    }
  }, [onChangeSymptoms, index]);

  return (
    <tr>
      <td>{time}</td>
      <td><RawString content={tension} /></td>
      <td>{rpm}</td>
      <td>
        <If condition={isDisplayText === true}>
          <Then>
            <span>{heartRate}</span>
          </Then>
          <Else>
            <FormInput
              className={classNames('form-control', {
                invalid: touched && heartRate.length > 0 && !NumberValidator.test(heartRate)
              })}
              type="number"
              inputProps={{
                min: 0,
                max: 300,
                required: heartRate.length > 0,
                value: heartRate,
                onChange: handleHeartRateChange
              }}
            />
          </Else>
        </If>
      </td>
      <td>
        <If condition={isDisplayText === true}>
          <Then>
            <span>{symptoms}</span>
          </Then>
          <Else>
            <FormInput
              className="form-control"
              type="textarea"
              inputProps={{
                value: symptoms,
                onChange: handleSymptomsChange
              }}
            />
          </Else>
        </If>
      </td>
    </tr>
  );
};

BlackhawksStationaryBikeStageTableRow.propTypes = {
  index: PropTypes.number.isRequired,
  time: PropTypes.string.isRequired,
  tension: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  rpm: PropTypes.string.isRequired,
  heartRate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  symptoms: PropTypes.string.isRequired,
  onChangeHeartRate: PropTypes.func,
  onChangeSymptoms: PropTypes.func,
  isDisplayText: PropTypes.bool,
  touched: PropTypes.bool
};

export default BlackhawksStationaryBikeStageTableRow;
