/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
/* eslint-enable no-unused-vars */

import BaselineSection from './baseline-section';
import Strings from './lang';

const calculateTrialsAverage = (trials) => {
  const total = trials.reduce((sum, value) => {
    return sum + parseFloat(value);
  }, 0);
  return total / trials.length;
};

const renderTrialsRow = (trials, count, title) => (
  <tr>
    <td><strong>{title}</strong></td>
    {
      Array.from(Array(count)).map((nan, index) => {
        const value = trials[index];
        return (
          <td key={index}>
            {(value !== undefined) ? value : ''}
          </td>
        );
      })
    }
    <td className="total">
      <strong>{calculateTrialsAverage(trials).toFixed(2)}</strong>
    </td>
  </tr>
);

const BaselineGripStrength = ({
  data = {}
}) => {
  const { 
    left_hand = [], right_hand = [], notes = '', not_done = false 
  } = data;
  const count = Math.max(left_hand.length, right_hand.length);

  return (
    <BaselineSection
      className="baseline-reaction"
      title={Strings.gripStrengthTitle}
      notes={notes}
      sectionCompleted={Object.keys(data || {}).length > 0 && !not_done}
    >

      <table className="table">
        <thead>
          <tr>
            <th className="no-action">{Strings.handLabelText}</th>
            {
              Array.from(Array(count)).map((nan, index) => {
                return (
                  <th key={index} className="no-action">
                    {Strings.formatString(Strings.trialLabelText, index + 1)}
                  </th>
                );
              })
            }
            <th className="no-action">
              <strong>{Strings.averageLabelText}</strong>
            </th>
          </tr>
        </thead>
        <tbody>
          {renderTrialsRow(left_hand, count, Strings.leftLabelText)}
          {renderTrialsRow(right_hand, count, Strings.rightLabelText)}
        </tbody>
      </table>

    </BaselineSection>
  );
};

BaselineGripStrength.propTypes = {
  data: PropTypes.object.isRequired
};

export default BaselineGripStrength;
