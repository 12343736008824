import { createSelector } from 'reselect';

const getTaxRates = (state) => state.taxes;

const getGroupedTaxRates = createSelector(
  [getTaxRates],
  (taxRates = {}) => {
    return Object.values(taxRates).reduce((acc, item) => ({
      ...acc,
      [item.country]: [
        ...(acc[item.country] || []),
        item
      ]
    }), {});
  }
);

const filterUnique = (value, index, arr) => {
  return arr.findIndex(tax => (
    value.rate === tax.rate && value.code === tax.code
  )) === index;
};

const getUniqueGroupTaxRates = createSelector(
  [getGroupedTaxRates],
  (taxes) => {
    return Object.keys(taxes).reduce((acc, key) => {
      return {
        ...acc,
        [key]: taxes[key].filter(filterUnique)
      };
    }, {});
  }
);

export default {
  getGroupedTaxRates,
  getUniqueGroupTaxRates
};
