const INPUT_DATE_FORMAT = 'YYYY-MM-DD';
const INPUT_DATE_TIME_FORMAT = `${INPUT_DATE_FORMAT}[T]HH:mm:ssZ`;
const DISPLAY_DATE_FORMAT = 'MMMM Do, YYYY';
const DISPLAY_DATE_TIME_FORMAT = 'MMMM Do, YYYY h:mm A';

export {
  INPUT_DATE_FORMAT,
  INPUT_DATE_TIME_FORMAT,
  DISPLAY_DATE_FORMAT,
  DISPLAY_DATE_TIME_FORMAT
};
