import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ImageCropper, ImageCropperOptions } from '../image-cropper';
import { AddressFormInputs } from '../address-form-inputs';
import { ClinicServicesInput } from '../clinic-services';
import { ClinicTypeCategorySelect } from '../clinic-type-category';
import convertAddressKeys from '@/utilities/convert-address-keys';
import {
  FormComponent,
  FormInputGroup,
  EmailInputGroup,
  UrlInputGroup,
  CheckboxInputGroup
} from '@/forms';
import { ClinicHoursInputTable, getInitialHours } from '../clinic-hours';
import log from '@/utilities/log';
import Strings from './lang';

class ClinicRegistrationForm extends Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
    this.onUpdateClinicName = this.onUpdateInput.bind(this, 'clinicName');
    this.onUpdateImpactCode = this.onUpdateInput.bind(this, 'impactCode');
    this.onUpdatePhone = this.onUpdateInput.bind(this, 'phone');
    this.onUpdateFax = this.onUpdateInput.bind(this, 'fax');
    this.onUpdateBio = this.onUpdateInput.bind(this, 'bio');
    this.onUpdateCategory = this.onUpdateCategory.bind(this);
    this.onUpdateAddress = this.onUpdateAddress.bind(this);
    this.onUpdateSearchable = this.onUpdateSearchable.bind(this);
    this.onUpdateServices = this.onUpdateServices.bind(this);
    this.onUpdateIdtProvider = this.onUpdateIdtProvider.bind(this);
    this.onUpdateResources = this.onUpdateResources.bind(this);

    this.state = {
      searchable: true,
      submitted: false,
      idtProvider: false,
      resources: true,
      clinicName: '',
      impactCode: '',
      clinicUrl: '',
      category: '',
      clinicUrlValid: false,
      email: '',
      emailValid: false,
      phone: '',
      fax: '',
      bio: '',
      address: {},
      addressValid: false,
      imageFile: {},
      imageDataURI: '',
      services: [],
      hours: getInitialHours()
    };
  }

  render() {
    return (
      <FormComponent className="clinic-registration-form" onSubmit={this.onSubmit}>

        <ImageCropper 
          className="avatar-image-cropper"
          inputName="avatar-image"
          chooseButtonClassName="btn btn-default"
          cropButtonClassName="btn btn-primary"
          cropperOptions={{
            ...ImageCropperOptions,
            aspectRatio: null /** will default to 'free' aspect ratio */
          }}
          onCrop={(imageFile, imageDataURI) => {
            this.setState({ imageFile, imageDataURI });
          }}
          onFileTypeError={(type, validTypes) => {
            log('Invalid file type', type, validTypes);
          }}
        />

        <FormInputGroup
          className="form-group clinic-name-input-group"
          labelText={Strings.clinicNameLabelText}
          inputType="text"
          inputProps={{
            className: 'form-control',
            value: this.state.clinicName,
            onBlur: this.onUpdateClinicName,
            onChange: this.onUpdateClinicName
          }}
          inputValid={this.state.clinicName.length > 0}
          messageText={Strings.clinicNameErrorText}
          messageClassName="alert alert-danger"
          required
          touched={this.state.submitted}
        />

        <ClinicTypeCategorySelect
          required
          className="form-group"
          category={this.state.category}
          touched={this.props.touched}
          onChange={this.onUpdateCategory}
        />

        <FormInputGroup
          inputValid
          required={false}
          className="form-group"
          labelText={Strings.bioLabelText}
          inputType="textarea"
          inputProps={{
            rows: 5,
            className: 'form-control',
            value: this.state.bio,
            onChange: this.onUpdateBio
          }}
        />

        <div>
          <div><small>{Strings.clinicFeaturesLabel}</small></div>
          <div className="row">
            <div className="col-md-4">
              <CheckboxInputGroup
                inputValid
                className="form-group"
                labelText={Strings.searchableLabelText}
                inputProps={{
                  className: 'form-control',
                  checked: this.state.searchable,
                  onChange: this.onUpdateSearchable
                }}
              />
            </div>
            <div className="col-md-4">
              <CheckboxInputGroup
                inputValid
                className="form-group"
                labelText={Strings.axiaIdtProviderLabel}
                inputProps={{
                  className: 'form-control',
                  checked: this.state.idtProvider,
                  onChange: this.onUpdateIdtProvider
                }}
              />
            </div>
            <div className="col-md-4">
              <CheckboxInputGroup
                inputValid
                className="form-group"
                labelText={Strings.resourcesLabel}
                inputProps={{
                  className: 'form-control',
                  checked: this.state.resources,
                  onChange: this.onUpdateResources
                }}
              />
            </div>
          </div>
        </div>

        <ClinicServicesInput 
          services={this.state.services}
          onUpdate={this.onUpdateServices}
        />

        <FormInputGroup
          className="form-group clinic-impact-input-group"
          labelText={Strings.clinicImpactCodeLabelText}
          inputType="text"
          inputProps={{
            className: 'form-control',
            value: this.state.impactCode,
            onBlur: this.onUpdateImpactCode,
            onChange: this.onUpdateImpactCode
          }}
          inputValid
          touched={this.state.submitted}
        />

        <UrlInputGroup
          className="form-group clinic-url-input-group"
          labelText={Strings.clinicUrlLabelText}
          inputProps={{
            className: 'form-control',
            value: this.state.clinicUrl
          }}
          onUpdate={(clinicUrl, clinicUrlValid) => {
            this.setState({ clinicUrl, clinicUrlValid });
          }}
          messageClassName="alert alert-danger"
          required
          touched={this.state.submitted}
        />

        <EmailInputGroup
          className="form-group"
          labelText={Strings.emailLabelText}
          inputProps={{
            className: 'form-control'
          }}
          onUpdate={(email, emailValid) => {
            this.setState({ email, emailValid });
          }}
          messageClassName="alert alert-danger"
          required
          touched={this.state.submitted}
        />

        <FormInputGroup
          className="form-group phone-input-group"
          labelText={Strings.phoneLabelText}
          type="tel"
          inputProps={{
            className: 'form-control',
            value: this.state.phone,
            onBlur: this.onUpdatePhone,
            onChange: this.onUpdatePhone
          }}
          inputValid={this.state.phone.length > 0}
          messageText={Strings.phoneErrorText}
          messageClassName="alert alert-danger"
          required
          touched={this.state.submitted}
        />

        <FormInputGroup
          className="form-group fax-input-group"
          labelText={Strings.faxLabelText}
          type="tel"
          inputProps={{
            className: 'form-control',
            value: this.state.fax,
            onBlur: this.onUpdateFax,
            onChange: this.onUpdateFax
          }}
          inputValid
          messageText={Strings.faxErrorText}
          messageClassName="alert alert-danger"
          required={false}
          touched={this.state.submitted}
        />

        <AddressFormInputs
          onUpdate={this.onUpdateAddress}
          required
          touched={this.state.submitted}
        />

        <h4 className="semi-bold">
          {Strings.clinicHoursLabel}&nbsp;&nbsp;
          <button 
            type="button" 
            className={classnames('btn btn-sm', this.state.hours ? 'btn-danger' : 'btn-primary')}
            onClick={() => {
              this.setState((prev) => ({ 
                hours: prev.hours ? null : getInitialHours()
              }));
            }}
          >
            {this.state.hours ? Strings.removeText : Strings.addText}
          </button>
        </h4>
        {this.state.hours && (
          <ClinicHoursInputTable 
            hours={this.state.hours}
            onUpdate={(hours) => this.setState({ hours })}
          />
        )}
        
        <div className="form-footer">
          <button className="btn btn-primary" type="submit">
            {Strings.submitButtonText}
          </button>
        </div>
      </FormComponent>
    );
  }

  isValid() {
    return this.state.clinicName.length
      && this.state.emailValid
      && this.state.category
      && this.state.phone.length
      && this.state.addressValid
      && this.state.clinicUrlValid;
  }

  onUpdateServices(services) {
    this.setState({ services });
  }

  onUpdateCategory(category) {
    this.setState({ category });
  }

  onUpdateSearchable(e) {
    const { checked } = e.target;
    this.setState({
      searchable: checked
    });
  }

  onUpdateIdtProvider(e) {
    const { checked } = e.target;
    this.setState({
      idtProvider: checked
    });
  }

  onUpdateResources(e) {
    const { checked } = e.target;
    this.setState({
      resources: checked
    });
  }

  onUpdateInput(key, e) {
    const { value } = e.target;
    this.setState({
      [key]: value
    });
  }

  onUpdateAddress(address, addressValid) {
    this.setState({
      address,
      addressValid
    });
  }

  onSubmit() {
    this.setState({ submitted: true });

    if (!this.isValid()) {
      return;
    }

    const {
      clinicName,
      category,
      impactCode,
      clinicUrl,
      clinicUrlValid,
      email,
      emailValid,
      phone,
      fax,
      address = {},
      imageFile,
      imageDataURI,
      searchable,
      idtProvider,
      services,
      bio,
      hours,
      resources
    } = this.state;

    const attributes = {
      name: clinicName,
      category,
      impact_code: impactCode,
      website: (clinicUrlValid) ? clinicUrl : '',
      email: (emailValid) ? email : '',
      searchable,
      phone,
      fax,
      bio,
      services,
      resources_enabled: resources,
      idt_provider: idtProvider,
      photo_base64: imageDataURI || '',
      photo_filename: imageFile.name || '',
      address_attributes: convertAddressKeys(address),
      business_hours: {}
    };

    if (hours) {
      attributes.business_hours = { regular: hours };
    }

    if (typeof this.props.onSubmit === 'function') {
      this.props.onSubmit(attributes);
    }
  }
}

ClinicRegistrationForm.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default ClinicRegistrationForm;
