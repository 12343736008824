import React, { useMemo, useState } from 'react';
import classnames from 'classnames';
import Dropdown, { MenuItem } from '../../dropdown';
import DataTable from '../../data-table';
import Icon from '../../icon';
import {
  getMostImprovedTableData,
  getMostIncreasedTableData,
  getTopSymptomsTableData
} from './symptom-table-data';
import Strings from '../lang';

const MAX_SYMPTOM_ROWS = 10;

const Metrics = {
  Top: 'top',
  Improved: 'improved',
  Increased: 'increased'
};

const MetricStrings = (key) => ({
  [Metrics.Top]: Strings.topSymptomsMetricLabel,
  [Metrics.Improved]: Strings.mostImprovedMetricLabel,
  [Metrics.Increased]: Strings.mostIncreasedMetricLabel
}[key]);

const EmptyStrings = (key) => ({
  [Metrics.Top]: Strings.topSymptomsEmptyLabel,
  [Metrics.Improved]: Strings.mostImprovedEmptyLabel,
  [Metrics.Increased]: Strings.mostIncreasedEmptyLabel
}[key]);

const MetricMenuItem = ({ selected, metric, onSelect }) => {
  return (
    <MenuItem
      selected={selected}
      onSelect={() => onSelect(metric)}
    >
      {MetricStrings(metric)}
    </MenuItem>
  );
};

const MetricSelector = ({ metric, onChange }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="symptoms-stats-metric">
      <Dropdown
        isOpen={open}
        className="symptoms-stats-metric-dropdown"
        trigger={(
          <button 
            type="button" 
            className={classnames('stats-metric-selector', { selected: open })}
            onClick={() => setOpen(prev => !prev)}
          >
            <h2>
              <span>{MetricStrings(metric)}</span>
              <Icon name="chevron-down" />
            </h2>
          </button>
        )}
        onClose={() => setOpen(false)}
      >
        <MetricMenuItem
          metric={Metrics.Top}
          selected={metric === Metrics.Top}
          onSelect={onChange}
        />
        <MetricMenuItem
          metric={Metrics.Improved}
          selected={metric === Metrics.Improved}
          onSelect={onChange}
        />
        <MetricMenuItem
          metric={Metrics.Increased}
          selected={metric === Metrics.Increased}
          onSelect={onChange}
        />
      </Dropdown>
    </div>
  );
};

const getTableData = (metric, symptoms, max) => {
  switch (metric) {
    case Metrics.Top:
      return getTopSymptomsTableData(symptoms, max);
    case Metrics.Improved:
      return getMostImprovedTableData(symptoms, max);
    case Metrics.Increased:
      return getMostIncreasedTableData(symptoms, max);
    default:
      return null;
  }
};

const Table = ({ 
  metric, 
  symptoms, 
  selected, 
  onSelectSymptom 
}) => {
  const { columns, data } = useMemo(() => (
    getTableData(metric, symptoms, MAX_SYMPTOM_ROWS)
  ), [metric, symptoms]);

  if (!data?.length) {
    return (
      <p className="text-center">
        {EmptyStrings(metric)}
      </p>
    );
  }

  return (
    <DataTable 
      keyExtractor={(item) => item.symptom}
      data={data}
      selected={selected}
      columns={columns}
      onSelect={(item) => onSelectSymptom(item.symptom)}
    />
  );
};

const SymptomStatisticsTable = ({
  symptoms,
  selected,
  onSelectSymptom
}) => {
  const [metric, setMetric] = useState(Metrics.Top);

  return (
    <div className="symptoms-statistics-table">
      <MetricSelector 
        metric={metric} 
        onChange={setMetric}
      />
      <Table
        metric={metric}
        selected={selected}
        symptoms={symptoms}
        onSelectSymptom={onSelectSymptom}
      />
    </div>
  );
};

export default SymptomStatisticsTable;
