import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { MarketplacePaths } from '@/paths';
import replaceLinkParams from '@/utilities/replace-link-params';
import Popover from '../../popover';
import Strings from '../lang';

const PartnerPopoverContent = ({ partner }) => {
  return (
    <div className="partner-popover-content">
      <div className="flex-row align_center" style={{ marginBottom: 10 }}>
        <div className="partner-image">
          <img src={partner.logo_url} alt={partner.name} />
        </div>
        <div className="partner-details">
          <div className="name">{partner.name}</div>
          <div className="partner-url">
            <a 
              href={partner.website_url} 
              rel="noopener" 
              target="_blank" 
              onClick={(e) => e.stopPropagation()}
            >
              {partner.website_url}
            </a>
          </div>
        </div>
      </div>
      <Link 
        className="btn btn-sm btn-primary btn-block"
        to={replaceLinkParams(MarketplacePaths.partners.show.link, { partnerId: partner.slug })}
        onClick={(e) => e.stopPropagation()}
      >
        {Strings.viewProfileLabel}
      </Link>
    </div>
  );
};

const PartnerTag = ({
  portal = false,
  partner = {},
  className
}) => {
  const [popoverOpen, setPopoverOpen] = useState(false);

  const onHover = useCallback(() => {
    setPopoverOpen(true);
  }, []);

  const onHoverExit = useCallback(() => {
    setPopoverOpen(false);
  }, []);

  if (!partner?.id) return null;

  return (
    <div 
      className={classnames('partner-tag-wrapper', className)}
      onMouseEnter={onHover}
      onMouseLeave={onHoverExit}
    >
      <Popover
        isOpen={popoverOpen}
        portal={portal}
        offset={[0, 0]}
        content={(
          <PartnerPopoverContent partner={partner} />
        )}
      >
        <div className="partner-tag">
          {partner.name}
        </div>
      </Popover>
    </div>
  );
};

export default PartnerTag;
