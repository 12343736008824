const EN = {
  title: 'Search Patients',
  searchLabelText: 'Search',
  searchInputPlaceholder: 'Name, Account No., or ID Card No.',
  searchButtonText: 'Search',
  clearButtonText: 'Clear',
  searchResultsHeadingText: 'Results',
  searchResultsEmptyText: 'No results were found for your search.'
};

export default EN;
