const FR = {
  rtsProtocolTitle: 'Retour au sport ou à une activité',
  rtlProtocolTitle: 'Retour à l\'école',
  rtwProtocolTitle: 'Retour au travail',
  rtlStage1Label: 'Activité cognitive limitée par les symptômes',
  rtlStage1Description: 'Il est conseillé au patient de se reposer modérément et d\'éviter les activités physiques et cognitives qui augmentent notablement ses symptômes.',
  rtlStage2Label: 'Activité cognitive légère',
  rtlStage2Description: 'Le patient est encouragé à effectuer davantage d\'activités cognitives (p. ex., lecture, devoirs).',
  rtlStage3Label: 'Demi-journée à l\'école - avec restrictions',
  rtlStage3Description: 'Le patient est maintenant autorisé à retourner à l\'école pendant une demi-journée au maximum (matin ou après-midi) avec certaines restrictions.',
  rtlStage4Label: 'Journée entière à l\'école - avec restrictions',
  rtlStage4Description: 'Le patient est maintenant autorisé à passer une journée complète à l\'école, avec certaines restrictions.',
  rtlStage5Label: 'Journée entière à l\'école - sans restrictions',
  rtlStage5Description: 'Le patient est maintenant en mesure de reprendre entièrement toutes ses activités scolaires, sans adaptation ni restrictions.',
  rtwStage1Label: 'Activité cognitive limitée par les symptômes',
  rtwStage1Description: 'Il est conseillé au patient de se reposer modérément et d\'éviter les activités physiques et cognitives qui augmentent notablement ses symptômes.',
  rtwStage2Label: 'Activité cognitive légère',
  rtwStage2Description: 'Le patient est encouragé à effectuer davantage d\'activités cognitives (p. ex., lecture, travail à domicile).',
  rtwStage3Label: 'Demi-journée de travail - avec restrictions',
  rtwStage3Description: 'Le patient est maintenant autorisé à retourner au travail pendant une demi-journée au maximum (matin ou après-midi) avec certaines restrictions.',
  rtwStage4Label: 'Journée entière de travail - avec restrictions',
  rtwStage4Description: 'Le patient est maintenant autorisé à passer une journée complète au travail, avec certaines restrictions.',
  rtwStage5Label: 'Journée entière de travail - sans restrictions',
  rtwStage5Description: 'Le patient est maintenant en mesure de reprendre entièrement toutes ses activités scolaires ou professionnelles, sans adaptation ni restrictions.',
  rtsStage1Label: 'Activité physique limitée par les symptômes',
  rtsStage1Description: 'Il est conseillé au patient de se reposer modérément et d\'éviter les activités physiques et cognitives qui augmentent notablement ses symptômes.',
  rtsStage2Label: 'Activité physique légère',
  rtsStage2Description: 'Pendant cette étape, le patient doit consulter son clinicien traitant de CCMI afin d\'effectuer une épreuve d\'effort sur tapis roulant (Buffalo Concussion Treadmill Test).',
  rtsStage3Label: 'Activité propre au sport pratiqué',
  rtsStage3Description: 'Le patient a réussi la première épreuve d\'effort et peut maintenant reprendre une activité sans contact',
  rtsStage4Label: 'Entraînement sans contact',
  rtsStage4Description: 'Cette étape vise à augmenter la fréquence cardiaque de l\'athlète et à permettre des activités plus compétitives et stimulantes, à condition qu\'elles soient SANS CONTACT.',
  rtsStage5Label: 'Pratique de contact complet',
  rtsStage5Description: 'L\'athlète a été autorisé à reprendre l\'entraînement avec contact complet.',
  rtsStage6Label: 'Retour complet au contact/Congé',
  rtsStage6Description: 'Le patient est maintenant en mesure de reprendre entièrement toutes les activités physiques qu\'il pratiquait avant sa blessure, sans adaptation ni restrictions.',
  readMoreButtonText: 'Lisez la suite',
  stageSelectLabel: 'Étape',
  stageSelectErrorMessage: 'Veuillez sélectionner une étape',
  selectStageDefaultOption: 'Sélectionnez une étape',
  previousStageLabel: 'Étape précédente',
  stageNotSetLabel: 'Pas encore défini',
  dischargeMessage: 'Cela entraînera la décharge automatique de cette blessure',
  protocolToggleDisableTitle: 'Désactiver le protocole',
  protocolToggleEnableTitle: 'Activer le protocole',
  viewRestrictionsLabel: 'Afficher les restrictions',
  viewSportRestrictionLabel: 'Voir les restrictions sportives',
  sportSelectorLabel: 'Restrictions sportives',
  sportSelectorPlaceholder: 'Sélectionnez un sport',
  protocolDisabledText: 'Ce protocole de récupération a été désactivé.'
};

export default FR;
