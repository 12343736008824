import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../icon';

const _noop = () => {};

const RememberedUsersList = ({
  onRemoveUser = _noop, 
  onUserSelected = _noop, 
  rememberedUsers = []
}) => (
  <div className="remembered-user-list">
    <div className="user-list-group">
      {rememberedUsers.map(user => (
        <div key={user.email} className="user-list-item">
          <div 
            className="remove-list-item" 
            aria-describedby="Remove this remembered login" 
            onClick={() => onRemoveUser(user)}
          >
            <Icon name="xmark" />
          </div>
          <div 
            className="user-list-content" 
            onClick={() => onUserSelected(user)}
          >
            <div className="user-avatar">
              <img 
                className="img-responsive" 
                src={user.photo_url} 
                alt={`${user.first_name} ${user.last_name}`}
              />
            </div>
            <div className="user-login-info">
              <div>{user.first_name} {user.last_name}</div>
              <small>{user.email}</small>
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
);

RememberedUsersList.propTypes = {
  rememberedUsers: PropTypes.arrayOf(PropTypes.object)
};

export default RememberedUsersList;
