import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import TextareaInput from './textarea-input';
import GoogleAddressSearchInput from './google-address-search-input';
import Strings from './lang';

const FormInput = forwardRef((props, ref) => {
  const { 
    type = 'text', 
    className: propClassName, 
    inputProps, 
    children 
  } = props;
  const {
    countryWhitelist, 
    value, 
    className: inputClassName, 
    ...otherInputProps
  } = inputProps || {};

  const className = classNames('form-input', propClassName);

  switch (type.toLowerCase()) {
    case 'textarea':
      return (
        <TextareaInput ref={ref} className={className} {...inputProps} />
      );
    case 'select':
      return (
        <select ref={ref} className={className} {...inputProps}>
          {children}
        </select>
      );
    case 'address':
      return (
        <GoogleAddressSearchInput ref={ref} className={className} {...inputProps} />
      );
    case 'country':
      return (
        <CountryDropdown
          ref={ref}
          key={value}
          value={value}
          classes={classNames(className, inputClassName)}
          valueType="short"
          whitelist={countryWhitelist}
          defaultOptionLabel={Strings.countrySelectDefaultOptionText}
          {...otherInputProps}
        />
      );
    case 'region':
      return (
        <RegionDropdown
          ref={ref}
          key={value}
          value={value}
          classes={classNames(className, inputClassName)}
          valueType="short"
          countryValueType="short"
          defaultOptionLabel={Strings.regionSelectDefaultOptionText}
          {...otherInputProps}
        />
      );
    default:
      return (
        <input ref={ref} className={className} type={type} {...inputProps} />
      );
  }
});

FormInput.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  inputProps: PropTypes.object,
  inputMin: PropTypes.string
};

export default FormInput;
