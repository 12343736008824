import React, { useState, useMemo } from 'react';
import Modal from '../modal';
import Markdown from '../markdown';
import { getCurrentUserLanguage } from '@/utilities/localization';
import Strings from './lang';

const getPath = (name) => {
  const lang = getCurrentUserLanguage();
  const time = Date.now();
  const rootPath = `/documents/help/${name}/${lang}`;

  return `${rootPath}/index.markdown?${time}`;
};

const DocumentContent = ({
  documentName = null,
  component: Component = null
}) => {
  const path = useMemo(() => getPath(documentName), [documentName]);

  if (Component) {
    return <Component />;
  }

  return (
    <Markdown.Remote path={path} />
  );
};

const HelpModal = ({
  isOpen = false,
  title = null,
  documentName = null,
  component,
  onClose = () => { },
  children
}) => {
  return (
    <Modal
      blur
      isOpen={isOpen}
      className="help-modal"
      onClose={onClose}
    >
      {title && (
        <Modal.Header>
          <h1>{title}</h1>
        </Modal.Header>
      )}
      <Modal.Body>
        <DocumentContent
          component={component}
          documentName={documentName}
        />
        {children}
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-sm btn-primary"
          onClick={onClose}
        >
          {Strings.doneButtonTitle}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export const HelpModalLink = ({
  className,
  disableFocus,
  disabled = false,
  title,
  component,
  documentName,
  extraContent = null,
  onClick = () => { },
  children
}) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <button
        type="button"
        tabIndex={disableFocus ? -1 : undefined}
        className={className}
        disabled={disabled}
        onClick={(e) => {
          onClick(e);
          if (!e.defaultPrevented) {
            setOpen(true);
          }
        }}
      >
        {children}
      </button>
      <HelpModal
        isOpen={open}
        title={title}
        component={component}
        documentName={documentName}
        onClose={() => setOpen(false)}
      >
        {extraContent}
      </HelpModal>
    </>
  );
};

export default HelpModal;
