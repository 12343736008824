const FR = {
  marketplaceTitle: 'Marché Complete Concussions',
  adminProductsTitle: 'Produits du marché',
  createProductLabel: 'Créer un produit',
  productCreatedMessage: 'Produit créé avec succès !',
  productUpdatedMessage: 'Produit mis à jour avec succès !',
  editProductLabel: 'Modifier le produit',
  noProductsFoundLabel: 'Aucun produit trouvé',
  searchProductNameLabel: 'Rechercher le nom du produit',
  nameTableColumnLabel: 'Nom',
  partnerTableColumnLabel: 'Partenaire',
  statusTableColumnLabel: 'Statut',
  publishedLabel: 'Publié',
  notPublishedLabel: 'Non publié',
  partnerNameLabel: 'Nom du partenaire',
  partnerNameErrorMessage: 'Veuillez entrer le nom du partenaire',
  partnerWebsiteUrlLabel: 'URL de site web',
  partnerDescriptionLabel: 'Description du partenariat',
  descriptionPlaceholder: 'La description',
  descriptionSectionTitle: 'La description',
  submitButtonLabel: 'Soumettre',
  editDetailsButton: 'Modifier les détails',
  editPartnershipModalTitle: 'Mettre à jour le partenariat',
  partnerUpdatedSuccessMessage: 'Partenariat mis à jour avec succès !',
  productsTabLabel: 'Des produits',
  detailsTabLabel: 'Détails',
  viewProfileLabel: 'Voir le profil',
  createPartnerModalTitle: 'Créer un nouveau partenariat',
  addPartnershipLabel: 'Ajouter un partenariat',
  productInformationSectionTitle: 'Information produit',
  productNameLabel: 'Nom du produit',
  productNameErrorMessage: 'Veuillez entrer le nom du produit',
  productCategoriesLabel: 'Catégories de produits',
  productCategoriesPlaceholder: 'Sélectionnez les catégories de produits',
  productUrlLabel: 'URL du produit/lien d\'affiliation',
  productDescriptionLabel: 'Description du produit',
  pricingSectionTitle: 'Information sur les prix',
  discountLabel: 'Remise CCMI (%)',
  discountPlaceholder: 'Pourcentage de remise',
  couponCodeLabel: 'Code promo',
  productVideoSectionTitle: 'Vidéo du produit',
  productVideoPlaceholder: 'URL de la vidéo (Vimeo, Youtube ou Facebook)',
  productVideoErrorMessage: 'Veuillez saisir une URL de vidéo valide',
  productImagesSectionTitle: 'Images du produit',
  addImagesButton: 'Ajouter des images',
  imagesDropAreaMessage: 'Faites glisser et déposez des images dans cette zone ou utilisez le bouton ci-dessous.',
  productImagesHintMessage: 'Au moins 1 image de produit est requise',
  publishProductLabel: 'Publier le produit',
  publishProductModalMessage: 'La publication d\'un produit rendra l\'article visible sur le marché. Êtes-vous sûr de vouloir publier ce produit ?',
  publishProductModalButton: 'Publier',
  publishedSuccessMessage: 'Produit publié avec succès !',
  unpublishProductLabel: 'Dépublié le produit',
  unpublishProductModalMessage: 'Dépublié d\'un produit supprimera l\'article du marché. Voulez-vous vraiment annuler la publication de ce produit ?',
  unpublishProductModalButton: 'Dépublié la produit',
  unpublishedSuccessMessage: 'Le produit a été dépublié avec succès',
  partnershipSelectLabel: 'Partenariat',
  partnershipSelectPlaceholder: 'Sélectionnez ou créez un partenariat',
  retailPriceLabel: 'Prix en détail',
  retailPriceErrorMessage: 'Veuillez entrer le prix du produit',
  adminProductOptionsButton: 'Choix',
  couponCodeHintMessage: 'Utilisez le code de coupon ci-dessous pour réclamer votre remise sur le réseau CCMI.',
  couponCodeCopiedMessage: 'Code promo copié !',
  copyToClipboardHintMessage: 'Copier dans le presse-papier',
  changeImageSlideLabel: 'Passer au point {0}',
  productLinkButton: 'Obtenir le produit',
  productNotFoundTitle: 'Produit non trouvé',
  productNotFoundMessage: 'Nous sommes désolés, le produit que vous recherchez n\'existe pas ou a été supprimé du Marché Complete Concussions',
  productNotFoundBackButton: 'Retour à la place de marché',
  productPriceDiscountPercentLabel: 'Économisez {0}%',
  productPriceSaveLabel: 'enregistrer {0}',
  networkDiscountPriceLabel: 'Remise sur le réseau CCMI',
  productFiltersTitle: 'Filtres de produits',
  productResultsCountLabel: '{0} Résultat',
  productResultsPluralCountLabel: '{0} Résultats',
  viewResultsButtonLabel: 'Voir les résultats',
  filtersLabel: 'Filtres',
  categoriesFilterLabel: 'Catégories',
  allCategoryLabel: 'Tout',
  allProductsTitle: 'Tous les produits',
  brandsFilterLabel: 'Marques',
  productsEmptyPageTitle: 'Aucun produit disponible pour le moment !',
  productsEmptyPageMessage: 'Revenez bientôt pour obtenir des offres exclusives sur les produits de nos partenaires du réseau CCMI. Cette page sera mise à jour une fois que les produits seront disponibles.',
  assessmentToolsCategory: 'Instruments d\'évaluation',
  rehabToolsCategory: 'Outils de réadaptation',
  visionTherapyCategory: 'Thérapie visuelle',
  vestibularTherapyCategory: 'Thérapie vestibulaire',
  vrHeadsetCategory: 'Casques de réalité virtuelle',
  medicalSuppliesCategory: 'Materiel médical',
  neckRehabCategory: 'Rééducation du cou',
  clinicalSoftwareCategory: 'Logiciel clinique',
  sportsEquipementCategory: 'Équipement sportif',
  miscellaneousCategory: 'Divers',
  heartRateMonitorsCategory: 'Moniteurs de fréquence cardiaque',
  fitnessEquipmentCategory: 'Équipement de fitness'
};
 
export default FR;
