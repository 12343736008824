const UI_HOST = process.env.CCMI_UI_HOST;
const CONFIRMATION_URL = `${UI_HOST}/register/confirm/`;
const INVITATION_URL = `${UI_HOST}/invite/accept/`;
const PASSWORD_RESET_URL = `${UI_HOST}/password/reset`;
const REGISTRATION_PASSWORD_RESET_URL = `${UI_HOST}/register/password/reset`;
const SERVICE_REQUEST_FORM_URL = 'https://ccmi.typeform.com/to/lIFmLz';
const IMPACT_LOGIN_URLS = {
  CA: 'https://www.impacttestonline.ca/htmlcc/Pages/Login/login.html',
  US: 'https://www.impacttestonline.com/htmlcc/Pages/Login/login.html',
  EU: 'https://europe.impacttestonline.com/htmlcc/Pages/Login/login.html',
  INTL: 'https://intl.impacttestonline.com/htmlcc/Pages/Login/login.html'
};

export {
  CONFIRMATION_URL,
  INVITATION_URL,
  PASSWORD_RESET_URL,
  REGISTRATION_PASSWORD_RESET_URL,
  SERVICE_REQUEST_FORM_URL,
  IMPACT_LOGIN_URLS
};
