import React from 'react';
import PropTypes from 'prop-types';

const RawString = ({ content }) => (
  <span dangerouslySetInnerHTML={{ __html: content }} />
);

RawString.propTypes = {
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired
};

export default RawString;
