import { UPDATE_PRODUCT, UPDATE_PRODUCTS, REMOVE_PRODUCT } from '../actions/products';

const updateProductReducer = (state = {}, action = {}) => {
  const { product = {} } = action;
  const { id = 0 } = product;
  const { [id]: productState = {} } = state;

  return id ? {
    ...state,
    [id]: {
      ...productState,
      ...product
    }
  } : state;
};

const updateProductsReducer = (state = {}, action = {}) => {
  const { products = [] } = action;

  return products.reduce((productState, product) => {
    return updateProductReducer(productState, { product });
  }, state);
};

const removeProductReducer = (state = {}, action = {}) => {
  const { productId } = action || {};
  const newState = { ...state };

  if (newState[productId]) {
    delete newState[productId];
  }

  return newState;
};

const products = (state = {}, action = {}) => {
  switch (action.type) {
    case UPDATE_PRODUCT:
      return updateProductReducer(state, action);
    case UPDATE_PRODUCTS:
      return updateProductsReducer(state, action);
    case REMOVE_PRODUCT:
      return removeProductReducer(state, action);
    default:
      return state;
  }
};

export default products;
