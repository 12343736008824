import React, { 
  useCallback, 
  useEffect, 
  useMemo, 
  useState 
} from 'react';
import dayjs from 'dayjs';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getInjuryAssessmentAsync } from '@/redux/thunks/injury-assessments';
import { getBaselineBeforeDateAsync } from '@/redux/thunks/baseline-tests';
import { BaselineSelector, InjurySelector, UserSelector } from '@/redux/selectors';
import replaceLinkParams from '@/utilities/replace-link-params';
import { UserPaths } from '@/paths';
import { daysSince } from '@/utilities/dates';
import Activity from '../../activity';
import { ErrorBanner } from '../../errors';
import { TestHeader } from '../../test-header';
import Icon from '../../icon';
import PostInjuryAssessmentView from './post-injury-assessment-view';
import LastEditedView from '../../last-edited-view';
import { RoleDescriptions, userHasRoleMatchingDescription } from '@/utilities/user-roles';
import { getAdjustedAssessmentDate } from '@/utilities/injury-assessment';
import Strings from './lang';

const MAX_ACUTE_DAYS = 14;

const getAssessmentType = (injury, assessment) => {
  return daysSince(injury.injured_at || dayjs(), assessment.created_at) > MAX_ACUTE_DAYS 
    ? 'chronic' 
    : 'acute';
};

const assessmentSelector = (userId, assessmentId) => state => {
  const userAssessments = state.injuryAssessments[userId] || {};
  return userAssessments[assessmentId] || {}; 
};

const PostInjuryAssessmentDisplay = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const [activity, setActivity] = useState(false);
  const [error, setError] = useState(null);
  const [assessmentError, setAssessmentError] = useState(null);

  const currentUser = useSelector(UserSelector.getCurrentUser);
  const user = useSelector(state => UserSelector.getUser(state, { userId: params.userId }));
  const injury = useSelector(state => (
    InjurySelector.getUserInjury(state, { userId: params.userId, injuryId: params.injuryId })
  ));
  const assessment = useSelector(assessmentSelector(params.userId, params.assessmentId));
  const baselineDate = useMemo(() => (
    assessment?.baselines?.[0]?.created_at
      || assessment?.created_at
  ), [assessment?.baselines, assessment?.created_at]);
  const baseline = useSelector(state => (
    BaselineSelector.getUserBaselinesBeforeDate(
      state, 
      { userId: params.userId, date: baselineDate }
    )[0] || {}
  ));
  const canEdit = useMemo(() => (
    userHasRoleMatchingDescription(currentUser, RoleDescriptions.Clinician)
      || (userHasRoleMatchingDescription(currentUser, RoleDescriptions.Specialist)
        && String(currentUser.id) === String(assessment?.clinic_user?.id))
  ), [assessment?.clinic_user?.id, currentUser]);
  const assessmentType = useMemo(() => getAssessmentType(injury, assessment), [assessment, injury]);
  const getAssessmentTypeString = useMemo(() => {
    return assessmentType === 'acute'
      ? Strings.acuteConcussionAssessmentTitle
      : Strings.chronicConcussionAssessmentTitle;
  }, [assessmentType]);

  const getBaseline = useCallback((userId, date) => {
    setActivity(true);

    dispatch(getBaselineBeforeDateAsync(userId, date)).then(() => {
      setActivity(false);
    }).catch(error => {
      setError(error.message);
      setActivity(false);
    });
  }, [dispatch]);

  const getAssessment = useCallback((userId, injuryId, assessmentId) => {
    setActivity(true);
    setAssessmentError(null);

    dispatch(getInjuryAssessmentAsync(userId, injuryId, assessmentId)).then(() => {
      setActivity(false);
    }).catch(error => {
      setActivity(false);
      setAssessmentError(error.message);
    });
  }, [dispatch]);

  useEffect(() => {
    if (!assessment?.id && params.userId && params.injuryId && params.assessmentId) {
      getAssessment(params.userId, params.injuryId, params.assessmentId);
    }
  }, [assessment?.id, getAssessment, params.assessmentId, params.injuryId, params.userId]);

  useEffect(() => {
    if (!baseline?.id && baselineDate) {
      getBaseline(params.userId, baselineDate);
    }
  }, [baseline?.id, baselineDate, getBaseline, params.userId]);

  if (assessmentError) {
    return (
      <div>
        <h1 className="display">
          {Strings.title}
        </h1>
        <ErrorBanner error={assessmentError} />
      </div>
    );
  }
  
  return (
    <Activity active={activity} static={!assessment?.id}>
      <div className="post-injury-assessment-display">
        <div className="row">
          <div className="col-md-12">
            <h1 className="display flex-row justify_space-between align_center">
              {Strings.title}
              {canEdit && (
                <Link
                  to={replaceLinkParams(UserPaths.injuries.fullAssessment.link, {
                    userId: user.id,
                    injuryId: assessment?.injury_id || injury.id,
                    assessmentId: assessment?.id
                  })}
                  className="btn btn-danger btn-sm no-print"
                >
                  <Icon name="pen-to-square" />&nbsp;&nbsp;
                  {Strings.editText}
                </Link>
              )}
            </h1>
            <h2>{assessmentType.length > 0 && getAssessmentTypeString}</h2>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <ErrorBanner error={error} />
            <div className="post-injury-assessment-content">

              <div className="row">
                <div className="col-md-12">
                  <TestHeader
                    user={user}
                    injury={injury}
                    className="post-injury-assessment-header"
                    testDate={getAdjustedAssessmentDate(assessment.created_at) || dayjs()}
                    tester={assessment.clinic_user}
                    clinic={assessment.clinic}
                  />
                </div>
              </div>

              <LastEditedView item={assessment} />

              <PostInjuryAssessmentView
                user={user}
                injury={injury}
                baseline={baseline}
                assessment={assessment}
                type={assessmentType}
              />

            </div>
          </div>
        </div>
      </div>
    </Activity>
  );
};

export default PostInjuryAssessmentDisplay;
