import React from 'react';
import PropTypes from 'prop-types';
import { If, Then } from 'react-if';
import { HelpModalLink } from '../help-modal';
import { symptomDistanceValue } from './voms-data';
import Icon from '../icon';

const VOMSTestTableRow = ({
  symptomName, 
  symptomState, 
  helpDocumentName = '',
  hasDistance
}) => (
  <tr>
    <td>
      <span>{symptomName}</span>&nbsp;
      <If condition={helpDocumentName.length > 0}>
        <Then>
          <HelpModalLink className="help-button" documentName={helpDocumentName}>
            <Icon name="circle-info" />
          </HelpModalLink>
        </Then>
      </If>
    </td>
    <td>
      {symptomState.not_tested && <Icon name="check" />}
    </td>
    <td>
      {symptomState.headache}
    </td>
    <td>
      {symptomState.dizziness}
    </td>
    <td>
      {symptomState.nausea}
    </td>
    <td>
      {symptomState.fogginess}
    </td>
    <td>
      {(hasDistance) ? symptomDistanceValue(symptomState.distance) : '\u2014'}
    </td>
    <td className="capitalize">
      {symptomState.abnormality}
    </td>
    <td>
      {symptomState.comments}
    </td>
  </tr>
);

VOMSTestTableRow.propTypes = {
  symptomName: PropTypes.string.isRequired,
  symptomState: PropTypes.object.isRequired,
  hasDistance: PropTypes.bool,
  helpDocumentName: PropTypes.string
};

export default VOMSTestTableRow;
