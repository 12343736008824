import React from 'react';
import { stageOptions } from './blackhawks-data';
import Strings from './lang';

const BlackhawksCooldownResultView = ({
  result, 
  failPoint = {}, 
  notes
}) => (
  <div className="blackhawks-cooldown-result">
    <h2>{Strings.blackhawksTestOutcomes}</h2>
    <div className="row">
      <div className="col-md-6">
        <div className="blackhawks-cooldown-result-row">
          <label>{Strings.passFailLabelText}</label>
          <span>{result === 'pass' ? Strings.cooldownPassText : Strings.cooldownFailText}</span>
        </div>
      </div>
      {result === 'fail' && (
        <div className="col-md-6">
          <div className="blackhawks-cooldown-result-row">
            <label>{Strings.heartRateAtFailLabel}:</label>
            <span>{failPoint.heart_rate} {Strings.heartrateUnit}</span>
          </div>
          <div className="blackhawks-cooldown-result-row">
            <label>{Strings.symptomsAtFailLabel}:</label>
            <span>{failPoint.symptoms}</span>
          </div>
          {failPoint.stage && failPoint.stage.length > 0 && (
            <div className="blackhawks-cooldown-result-row">
              <label>{Strings.stageAtFailLabel}:</label>
              <span>
                {stageOptions.plyometrics[failPoint.stage] 
                  || stageOptions.stationary[failPoint.stage]}
              </span>
            </div>
          )}
        </div>
      )}
    </div>
    {notes && notes.length > 0 && (
      <div className="row">
        <div className="col-md-12">
          <label>{Strings.overallNotesLabelText}:</label>&nbsp;&nbsp;
          <span>{notes}</span>
        </div>
      </div>  
    )}
  </div>
);

export default BlackhawksCooldownResultView;
