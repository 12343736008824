const en = {
  5: {
    1: ['Suggest', 'Downtown', 'Hopeful', 'Robber', 'Ocean'],
    2: ['Puma', 'Olive', 'Circle', 'Elbow', 'Goddess'],
    3: ['Savior', 'Apple', 'Knowledge', 'Portrait', 'Sunburn'],
    4: ['Piano', 'Garden', 'Letter', 'Window', 'Bubble'],
    5: ['Preview', 'Carpet', 'Pudding', 'Saddle', 'Leader'],
    6: ['Lobster', 'Hillside', 'Candle', 'Uncle', 'Flower'],
    7: ['Phantom', 'Sugar', 'Army', 'Lemon', 'Baby'],
    8: ['Sandwich', 'Hurdle', 'College', 'Woman', 'Singer'],
    9: ['Mercy', 'Coffee', 'Building', 'Profile', 'Speaker'],
    10: ['Insect', 'Painter', 'Franchise', 'Wagon', 'Christmas'],
    11: ['Penny', 'Daylight', 'Mountain', 'Reflex', 'Ticket'],
    12: ['Blanket', 'Traction', 'Monkey', 'Ankle', 'Locker'],
    13: ['Paper', 'Vacuum', 'Author', 'Nephew', 'Fortune'],
    14: ['Iron', 'Tweezers', 'Costume', 'Jury', 'Beaver'],
    15: ['Sadness', 'Justice', 'Excuse', 'Glacier', 'Mother'],
    16: ['Castle', 'Facebook', 'Dinner', 'Sailboat', 'Horses'],
    17: ['Princess', 'Youtube', 'Supper', 'Country', 'Airplane'],
    18: ['Lipstick', 'Breakfast', 'Person', 'Painful', 'Attach'],
    19: ['Arctic', 'Beside', 'Cheetah', 'Fiddle', 'Present'],
    20: ['Award', 'Cottage', 'Eardrops', 'Forest', 'Ankle'],
    21: ['Breezy', 'Ketchup', 'Echo', 'Fairy', 'Freezer'],
    22: ['Cooler', 'Jersey', 'Children', 'Mango', 'Nervous'],
    23: ['Hobbit', 'Japan', 'Shoelace', 'Mister', 'Napkin'],
    24: ['Insect', 'Jujube', 'Ladder', 'Mirror', 'Nosebleed'],
    25: ['Juggle', 'Otter', 'Zipper', 'Cheesecake', 'Relax'],
    26: ['Jungle', 'Outfield', 'Pepper', 'Reading', 'Shovel'],
    27: ['Necklace', 'Photo', 'Dancer', 'Rattle', 'Sadness'],
    28: ['Tower', 'Under', 'Wooden', 'Purple', 'Bagel'],
    29: ['Tonight', 'Wrestler', 'Yogurt', 'Zebra', 'Anchor'],
    30: ['Trophy', 'Waiter', 'Yellow', 'Apple', 'July']
  },
  10: {
    1: ['Finger', 'Candle', 'Penny', 'Paper', 'Blanket', 'Sugar', 'Lemon', 'Sandwich', 'Insect', 'Wagon'],
    2: ['Baby', 'Elbow', 'Monkey', 'Apple', 'Perfume', 'Carpet', 'Sunset', 'Saddle', 'Iron', 'Bubble'],
    3: ['Jacket', 'Dollar', 'Arrow', 'Honey', 'Pepper', 'Mirror', 'Cotton', 'Saddle', 'Movie', 'Anchor'],
    4: ['Suggest', 'Downtown', 'Hopeful', 'Robber', 'Ocean', 'Puma', 'Olive', 'Circle', 'Elbow', 'Goddess'],
    5: ['Savior', 'Apple', 'Knowledge', 'Portrait', 'Sunburn', 'Piano', 'Garden', 'Letter', 'Window', 'Bubble'],
    6: ['Preview', 'Carpet', 'Pudding', 'Saddle', 'Leader', 'Lobster', 'Hillside', 'Candle', 'Uncle', 'Flower'],
    7: ['Phantom', 'Sugar', 'Army', 'Lemon', 'Baby', 'Sandwich', 'Hurdle', 'College', 'Woman', 'Singer'],
    8: ['Mercy', 'Coffee', 'Building', 'Profile', 'Speaker', 'Insect', 'Painter', 'Franchise', 'Wagon', 'Christmas'],
    9: ['Penny', 'Daylight', 'Mountain', 'Reflex', 'Ticket', 'Blanket', 'Traction', 'Monkey', 'Ankle', 'Locker'],
    10: ['Paper', 'Vacuum', 'Author', 'Nephew', 'Fortune', 'Iron', 'Tweezers', 'Costume', 'Jury', 'Beaver'],
    11: ['Sadness', 'Justice', 'Excuse', 'Glacier', 'Mother', 'Castle', 'Facebook', 'Dinner', 'Sailboat', 'Horses'],
    12: ['Princess', 'Youtube', 'Supper', 'Country', 'Airplane', 'Lipstick', 'Breakfast', 'Person', 'Painful', 'Attach'],
    13: ['Arctic', 'Beside', 'Cheetah', 'Fiddle', 'Present', 'Award', 'Cottage', 'Eardrops', 'Forest', 'Ankle'],
    14: ['Breezy', 'Ketchup', 'Echo', 'Fairy', 'Freezer', 'Cooler', 'Jersey', 'Children', 'Mango', 'Nervous'],
    15: ['Hobbit', 'Japan', 'Shoelace', 'Mister', 'Napkin', 'Insect', 'Jujube', 'Ladder', 'Mirror', 'Nosebleed'],
    16: ['Juggle', 'Otter', 'Zipper', 'Cheesecake', 'Relax', 'Jungle', 'Outfield', 'Pepper', 'Reading', 'Shovel'],
    17: ['Necklace', 'Photo', 'Dancer', 'Rattle', 'Sadness', 'Tower', 'Under', 'Wooden', 'Purple', 'Bagel'],
    18: ['Tonight', 'Wrestler', 'Yogurt', 'Zebra', 'Anchor', 'Trophy', 'Waiter', 'Yellow', 'Apple', 'July']
  }
};

const fr = {
  5: {
    1: ['Poisson', 'Billet', 'Légume', 'Casquette', 'Courir'],
    2: ['Cheval', 'Jardin', 'Fermé', 'Lunette', 'Raquette'],
    3: ['Poulet', 'Maison', 'Content', 'Bijou', 'Histoire'],
    4: ['Oiseau', 'Banane', 'Petit', 'Sandale', 'Princesse'],
    5: ['Lapin', 'Gâteau', 'Bruyant', 'Chemise', 'Masque'],
    6: ['Coude', 'Pomme', 'Tapis', 'Siège', 'Ballon'],
    7: ['Bougie', 'Papier', 'Sucre', 'Sandwich', 'Wagon'],
    8: ['Girafe', 'Fromage', 'Étoile', 'Garçon', 'Sentir'],
    9: ['Souris', 'Beignet', 'Soleil', 'Enfant', 'Parler'],
    10: ['Cochon', 'Ognion', 'École', 'Croissant', 'Écrire'],
    11: ['Chameau', 'Carotte', 'Tranquille', 'Danser', 'Manger'],
    12: ['Dauphin', 'Biscuit', 'Malade', 'Écoute', 'Arrête'],
    13: ['Bébé', 'Singe', 'Parfum', 'Soleil', 'Metal'],
    14: ['Index', 'Monnaie', 'Couette', 'Citron', 'Insecte'],
    15: ['Tristesse', 'Justice', 'Excuse', 'Glacier', 'Mère'],
    16: ['Château', 'Facebook', 'Dîner', 'Voilier', 'Chevaux'],
    17: ['Princesse', 'Youtube', 'Souper', 'Pays', 'Avion'],
    18: ['Rouge à lèvres', 'Petit-déjeuner', 'Personne', 'Douloureux', 'Joindre'],
    19: ['Arctique', 'À côté', 'Guépard', 'Violon', 'Présent'],
    20: ['Prix​​', 'Chalet', 'Gouttes otiques', 'Forêt', 'Cheville'],
    21: ['Vent', 'Sauce', 'Écho', 'Fée', 'Congélateur'],
    22: ['Coolèr', 'Jersey', 'Enfants', 'Mangue', 'Nerveux'],
    23: ['Hobbit', 'Japon', 'Lacet', 'Monsieur', 'Serviette'],
    24: ['Insecte', 'Jujube', 'Échelle', 'Miroir', 'Chemise'],
    25: ['Pitre', 'Nez', 'Chat', 'Travail', 'Vert'],
    26: ['Jungle', 'Champ', 'Poivre', 'Les cours', 'Pelle'],
    27: ['Collier', 'Photo', 'Danseur', 'Le hochet', 'Tristesse'],
    28: ['Tour', 'Sous', 'Bois', 'Violet', 'Pain'],
    29: ['Ce soir', 'Lutteur', 'Yogourt', 'Zèbre', 'Ancre'],
    30: ['Trophée', 'Garçon', 'Jaune', 'Pomme', 'Juillet']
  },
  10: { 
    1: ['Doigt', 'Bougie', 'Penny', 'Papier', 'Couverture', 'Sucre', 'Citron', 'Sandwich', 'Insecte', 'Wagon'],
    2: ['Bébé', 'Coude', 'Singe', 'Pomme', 'Parfum', 'Tapis', 'Soleil', 'Selle', 'Fer', 'Bulle'],
    3: ['Veste', 'Dollar', 'Flèche', 'Miel', 'Poivre', 'Miroir', 'Coton', 'Selle', 'Film', 'Ancre'],
    4: ['Poisson', 'Billet', 'Légume', 'Casquette', 'Courir', 'Cheval', 'Jardin', 'Fermé', 'Lunette', 'Raquette'],
    5: ['Poulet', 'Maison', 'Content', 'Bijou', 'Histoire', 'Oiseau', 'Banane', 'Petit', 'Sandale', 'Princesse'],
    6: ['Lapin', 'Gâteau', 'Bruyant', 'Chemise', 'Masque', 'Coude', 'Pomme', 'Tapis', 'Siège', 'Ballon'],
    7: ['Bougie', 'Papier', 'Sucre', 'Sandwich', 'Wagon', 'Girafe', 'Fromage', 'Étoile', 'Garçon', 'Sentir'],
    8: ['Souris', 'Beignet', 'Soleil', 'Enfant', 'Parler', 'Cochon', 'Ognion', 'École', 'Croissant', 'Écrire'],
    9: ['Chameau', 'Carotte', 'Tranquille', 'Danser', 'Manger', 'Dauphin', 'Biscuit', 'Malade', 'Écoute', 'Arrête'],
    10: ['Bébé', 'Singe', 'Parfum', 'Soleil', 'Metal', 'Index', 'Monnaie', 'Couette', 'Citron', 'Insecte'],
    11: ['Tristesse', 'Justice', 'Excuse', 'Glacier', 'Mère', 'Château', 'Facebook', 'Dîner', 'Voilier', 'Chevaux'],
    12: ['Princesse', 'Youtube', 'Souper', 'Pays', 'Avion', 'Rouge à lèvres', 'Petit-déjeuner', 'Personne', 'Douloureux', 'Joindre'],
    13: ['Arctique', 'À côté', 'Guépard', 'Violon', 'Présent', 'Prix​​', 'Chalet', 'Gouttes otiques', 'Forêt', 'Cheville'],
    14: ['Vent', 'Sauce', 'Écho', 'Fée', 'Congélateur', 'Coolèr', 'Jersey', 'Enfants', 'Mangue', 'Nerveux'],
    15: ['Hobbit', 'Japon', 'Lacet', 'Monsieur', 'Serviette', 'Insecte', 'Jujube', 'Échelle', 'Miroir', 'Chemise'],
    16: ['Pitre', 'Nez', 'Chat', 'Travail', 'Vert', 'Jungle', 'Champ', 'Poivre', 'Les cours', 'Pelle'],
    17: ['Collier', 'Photo', 'Danseur', 'Le hochet', 'Tristesse', 'Tour', 'Sous', 'Bois', 'Violet', 'Pain'],
    18: ['Ce soir', 'Lutteur', 'Yogourt', 'Zèbre', 'Ancre', 'Trophée', 'Garçon', 'Jaune', 'Pomme', 'Juillet']
  }
};

const WordLists = {
  en,
  fr
};

export default WordLists;
