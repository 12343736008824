import React, { useMemo } from 'react';
import dayjs from 'dayjs';
import { DateInputGroup } from '@/forms';
import { DISPLAY_DATE_TIME_FORMAT, DISPLAY_DATE_FORMAT } from '@/utilities/dates/formats';
import Strings from '../lang';

const getMessageText = (minDate = null, maxDate = null) => {
  const min = dayjs(minDate);
  const max = dayjs(maxDate);

  if (min.isValid() && max.isValid()) {
    return Strings.formatString(
      Strings.appointmentInputBetweenError,
      min.format(DISPLAY_DATE_FORMAT),
      max.format(DISPLAY_DATE_FORMAT)
    );
  }

  if (min.isValid()) {
    return Strings.formatString(
      Strings.appointmentInputAfterError,
      min.format(DISPLAY_DATE_FORMAT)
    );
  }

  if (max.isValid()) {
    return Strings.formatString(
      Strings.appointmentInputBeforeError,
      max.format(DISPLAY_DATE_FORMAT)
    );
  }

  return undefined;
};

const AppointmentDatetimeInput = ({
  appt,
  touched,
  minDate = null,
  maxDate = null,
  disabled,
  onChange
}) => {
  const messageText = useMemo(() => getMessageText(minDate, maxDate), [maxDate, minDate]);
  const apptDate = dayjs(appt.value);

  return (
    <>
      <DateInputGroup
        required
        time
        className="form-group"
        labelText={Strings.appointmentInputLabel}
        inputType="date"
        textInputProps={{
          disabled,
          className: 'form-control',
          placeholder: `e.g. today at 3pm, next thursday at 4:30 pm, ${dayjs().format(DISPLAY_DATE_TIME_FORMAT)}`
        }}
        dateInputProps={{
          className: 'form-control'
        }}
        initialValue={appt.value}
        languageDateFormat={DISPLAY_DATE_TIME_FORMAT}
        messageText={messageText}
        minDate={minDate ? dayjs(minDate) : undefined}
        maxDate={maxDate ? dayjs(maxDate) : undefined}
        touched={touched}
        onUpdate={(value, valid) => {
          onChange({ value, valid });
        }}
        messageClassName="alert alert-danger"
      />
      {!!appt.value && apptDate.isValid() && (
        <p className="appt-confirmation text-center alert alert-info">
          {Strings.appointmentInputLabel} - {apptDate.format(DISPLAY_DATE_TIME_FORMAT)}
        </p>
      )}
    </>
  );
};

export default AppointmentDatetimeInput;
