import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormComponent, PasswordInputGroup } from '@/forms';
import Strings from './lang';

class InviteAcceptForm extends Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      submitted: false,
      passwordValue: '',
      passwordValid: false
    };
  }

  render() {
    return (
      <FormComponent className="clinic-invite-user-accept-form" onSubmit={this.onSubmit}>

        <PasswordInputGroup
          className="form-group"
          showPasswordScore
          showConfirmInput
          labelText={Strings.passwordLabelText}
          inputProps={{
            className: 'form-control',
            placeholder: Strings.passwordInputPlaceholder
          }}
          confirmLabelText={Strings.confirmPasswordLabelText}
          confirmInputProps={{
            className: 'form-control',
            name: 'confirm-password',
            placeholder: Strings.confirmPasswordInputPlaceholder
          }}
          onUpdate={(passwordValue, passwordValid) => {
            this.setState({ passwordValue, passwordValid });
          }}
          messageClassName="alert alert-danger"
          touched={this.state.submitted}
        />

        <div className="form-footer">
          <button className="btn btn-primary" type="submit">
            {Strings.submitButtonText}
          </button>
        </div>
      </FormComponent>
    );
  }

  onSubmit() {
    this.setState({ submitted: true });

    if (!this.state.passwordValid) {
      return;
    }

    const { token, onSubmit } = this.props;
    const { passwordValue } = this.state;

    const attributes = {
      password: passwordValue,
      invitation_token: token
    };

    if (typeof onSubmit === 'function') {
      onSubmit(attributes);
    }
  }
}

InviteAcceptForm.propTypes = {
  token: PropTypes.string,
  onSubmit: PropTypes.func
};

export default InviteAcceptForm;
