const EN = {
  title: 'Parent/Guardian Personal Information',
  guardiansEditBtn: 'Edit',
  guardiansAddBtn: 'Add Another Family Member',
  guardiansDoneBtn: 'Finish',
  guardiansDecoupleBtn: 'Decouple Account',
  guardianDecoupleInfoTitle: 'Removes the child from under the guardians care and makes them their own account',
  guardiansRemoveBtn: 'Remove',
  guardianNextBtn: 'Next',
  guardianDropTitle: 'Register',
  guardianLabelText: 'Are you a guardian?',
  guardianErrorText: 'Please select a section',
  firstNameLabelText: 'First Name',
  guardianFirstNameLabel: 'Parent/Guardian First Name',
  firstNameErrorText: 'Please enter your first name.',
  lastNameLabelText: 'Last Name',
  guardianLastNameLabel: 'Parent/Guardian Last Name',
  lastNameErrorText: 'Please enter your last name.',
  phoneLabelText: 'Phone',
  guardianPhoneLabel: 'Parent/Guardian Phone',
  phoneErrorText: 'Please enter a valid phone number.',
  alternatePhoneLabelText: 'Alternate Phone',
  genderLabelText: 'Gender',
  genderValidationMessage: 'Please select your gender.',
  genderOtherLabelText: 'What is your gender?',
  genderOtherValidationMessage: 'Please enter your gender',
  languageLabelText: 'Language',
  languageValidationMessage: 'Please select your language preference.',
  birthdateLabelText: 'Date of Birth',
  birthdateConfirmationMessage: '{2}. By continuing you confirm that this patient is {0} {1} old.',
  year: 'year',
  years: 'years',
  under1: 'under 1',
  birthdateValidationMessage: 'Please enter a valid date of birth.',
  isGuardianConfirmationText: 'Are you the Parent/Guardian of this player?',
  addressLabelText: 'Address',
  bioLabelText: 'Bio',
  credentialsLabelText: 'Credentials',
  submitButtonText: 'Save',
  relationLabelText: 'Relation',
  childAccountTitle: 'Add a Family Member',
  guardianListTitle: 'Family Members',
  guardianAboutSubTitle: 'Enter your personal information',
  guardianReturningSubTitle: 'Update your personal information',
  decoupleChildModalTitle: 'Decouple Account',
  decoupleChildModalDescription: 'You are about to decouple {0} from your account. This means that {0} will be removed from your account and given an Individual Account. Please enter a new email address for {0} below.',
  emailLabelText: 'Email',
  cancelButtonText: 'Cancel',
  decoupleSubmitButtonText: 'Submit',
  addExistingAccountButtonText: 'Add an Existing Account',
  addExistingAccountModalTitle: 'Add Existing Account',
  accountNumberLabel: 'Account Number',
  accountPasswordLabel: 'Account Password',
  addExisitingAccountInfoText: 'Would you like to add an existing Individual Account as a family member?',
  addExisitingAccountModalAddButton: 'Add',
  addExisitingAccountModalMessage: 'To add an existing account to this family account, please enter the account number and password of the <strong>account you are trying to add.</strong> Please note that only existing INDIVIDUAL accounts can be added (i.e., accounts that are associated with an existing FAMILY account cannot be added to this family)',
  accountNumberErrorMessage: 'Please enter a valid account number',
  addAccountNotFoundErrorMessage: 'Cannot find an Individual Account with the account number {0}.',
  addAccountUnauthorizedErrorMessage: 'Incorrect password for account number {0}.',
  emailTakenErrorMessage: 'This email address has been taken. Please use a different email address'
};

export default EN;
