const EN = {
  none: 'No Follow-up Needed',
  clinician: 'Clinician in-house monitoring/management',
  nextAppointmentDateLabel: 'Date of Next Appt',
  referral: 'Referral',
  referralToLabel: 'Referral To Whom',
  actionLabel: 'Follow-up Action',
  invalidDateMessage: 'Please enter a valid date greater than today\'s date',
  invalidReferralToMessage: 'Please enter who and where you are referring to.'
};

export default EN;
