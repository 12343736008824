const FR = {
  startTitle: 'Commencer le test des Blackhawks',
  testTitle: 'Test des Blackhawks',
  startButtonText: 'Commencer le test des Blackhawks',
  saveButtonText: 'Envoyer le test des Blackhawks',
  testTypeHeadingText: 'Quel test voudriez-vous effectuer?',
  testTypeLabelText: 'Type de test',
  bikeTypeHeadingText: 'Quel type de vélo employez-vous pour ce test?',
  bikeTypeLabelText: 'Type de vélo',
  testTypeErrorText: 'Veuillez choisir le type de test que vous effectuez',
  bikeTypeErrorText: 'Veuillez choisir le type de vélo que vous utilisez',
  fullTestLabelText: 'Test complet des Blackhawks \u2014 pour les athlètes d’élite',
  modifiedTestLabelText: 'Test des Blackhawks modifié \u2014 pour les athlètes juniors/non-élites',
  monarkBikeLabelText: 'Vélo Monark',
  lifecycleBikeLabelText: 'LifeCycle/LifeFitness ou tout vélo de niveau 20',
  stationaryBikeHeadingText: 'Étape 1',
  stationaryBikeSubheadingText: 'Vélo fixe',
  plyometricsHeadingText: 'Étape 2',
  plyometricsSubheadingText: 'Pliométrie complète',
  testDurationLabelText: 'Durée du test',
  testTimeLabelText: 'Durée (secondes)',
  testTimeStartButtonText: 'Commencer',
  testTimeStopButtonText: 'Arrêter',
  testTimeResumeButtonText: 'Reprendre',
  testTimeResetButtonText: 'Réinitialiser',
  restingHeartRateLabelText: 'Fréquence cardiaque au repos',
  stageTimeHeaderText: 'Étape/durée',
  tensionLevelsHeaderText: 'Niveaux de tension',
  suggestedRpmHeaderText: 'TPM suggérés',
  heartRateHeaderText: 'Fréquence cardiaque',
  heartRateLabelText: 'Fréquence cardiaque :',
  symptomsHeaderText: 'Symptômes/remarques',
  symptomsLabelText: 'Symptômes/remarques :',
  stationaryBikeStageLabelText: 'Étape {0}',
  stationaryBikeRecoveryLabelText: 'Récupération après l’étape {0}',
  stationaryBikeStageWarmUpRpm: 'Augmenter graduellement jusqu’à {0}',
  stationaryBikeStageMaxTension: 'Aussi haut que possible {0}',
  stationaryBikeStageMaxRpm: 'Maximum (100 ou plus)',
  stationaryBikeStageStopped: 'Arrêté',
  stationaryBikeRestHeadingText: 'Repos (débarquer et marcher autour du vélo) 2:00',
  stationaryBikeRestTimeText: '2:00',
  plyometricsHurdleHopsStageTitleText: 'Direction',
  plyometricsHurdleHopsStageLabelText: 'Étape 1 : Sauts latéraux à obstacles (6\u2033\u201310\u2033)',
  plyometricsHurdleHopsStageDirectionsText: '0:10 - Effectuer des sauts latéraux sur 2 pieds par-dessus 3 obstacles en allant et en revenant de façon continue\n0:10 S’asseoir sur 16\u2033\u201320\u2033 boîte',
  plyometricsHurdleStepsStageDirectionsText: '0:10 - Effectuer des sauts latéraux par-dessus 3 obstacles en allant et en revenant de façon continue\n0:10 S’asseoir sur 16\u2033\u201320\u2033 boîte',
  plyometricsHurdleHopsStageRepeatText: 'Répétez 4 fois',
  plyometricsHurdleHopsStageRestText: '0:40 - Repos',
  plyometricsBurpeesStageLabelText: 'Étape 2 : Appuis faciaux',
  plyometricsBurpeesStageDirectionsText: '0:10 - Appuis faciaux continus (suivre les mains des yeux) avec répulsion complète \n0:10 - Rester debout en position neutre',
  plyometricsBurpeesStageModifiedDirectionsText: '0:10 - Appuis faciaux continus (suivre les mains des yeux)\n0:10 - Rester debout en position neutre',
  plyometricsBurpeesStageRepeatText: 'Répétez 3 fois',
  plyometricsBurpeesStageRestText: '1:00 - Repos',
  plyometricsBoxJumpsStageLabelText: 'Étape 3 : Sauts latéraux sur une boîte (16\u2033\u201320\u2033)',
  plyometricsBoxJumpsStageDirectionsText: '0:15 - Sauter latéralement avec les 2 pieds sur la boîte, en bas de l’autre côté, puis revenir sur le dessus, le tout de façon continue\n0:15 - S\'asseoir sur la boîte',
  plyometricsBoxJumpsModifiedStageDirectionsText: '0:15 - Sauter latéralement sur la boîte, en bas de l’autre côté, puis revenir sur le dessus, le tout de façon continue\n0:15 - S\'asseoir sur la boîte',
  plyometricsBoxJumpsStageRepeatText: 'Répétez 2 fois',
  plyometricsBoxJumpsStageRestText: 'Repos pendant 1:00',
  plyometricsRotaryJumpsStageLabelText: 'Étape 4 : Sauts rotatifs sur 180°',
  plyometricsRotaryJumpsStageDirectionsText: '0:10 - Sauter de façon continue en pivotant vers la droite, puis en revenant vers la gauche (sur 180°) \u2014 La tête doit bouger dans le même sens que le corps\n0:20 - Rester debout en position neutre \n0:10 - Sauter de façon continue et pivoter vers la gauche puis revenir vers la droite\n0:20 - Rester debout en position neutre',
  plyometricsRotaryJumpsStageRepeatText: 'Répétez 2 fois (pour chaque direction)',
  plyometricsRotaryJumpsModifiedStageRepeatText: 'Répétez 2 fois',
  plyometricsRotaryJumpsStageRestDirectionText: '',
  cooldownHeadingText: 'Repos de \u2014 5 min \u2014 ',
  cooldownPassText: 'Réussite',
  cooldownFailText: 'Échec',
  cooldownErrorText: 'Veuillez indiquer si le patient a échoué ou réussi au test.',
  passFailLabelText: 'Réussite/échec :',
  failStageOptGroup_stationary: 'Étape 1 - Vélo fixe',
  failStageOptGroup_plyometrics: 'Étape 2 - Pliométrie',
  failStageOptionStationaryStage1: 'Vélo fixe - Étape 1',
  failStageOptionStationaryStage2: 'Vélo fixe - Étape 2',
  failStageOptionStationaryStage3: 'Vélo fixe - Étape 3',
  failStageOptionStationaryStageRest: 'Vélo fixe - Repos',
  failStageOptionHurdleHops: 'Pliométrie - Étape 1 - Sauts à obstacles latéraux',
  failStageOptionBurpees: 'Pliométrie - Étape 2 - Appuis faciaux',
  failStageOptionBoxJumps: 'Pliométrie - Étape 3 - Sauts latéraux sur une boîte',
  failStageOptionRotaryJumps: 'Pliométrie - Étape 4 - Sauts pivotants sur 180°',
  failWithStageText: 'Échec - Fréquence cardiaque de {0} avec symptômes de {1} à {2}',
  failWithoutStageText: 'Échec - Fréquence cardiaque de {0} avec symptômes de {1}',
  heartrateUnit: 'BPM',
  heartRateAtFailLabel: 'Échec pour la fréquence cardiaque',
  symptomsAtFailLabel: 'Échec pour les symptômes',
  stageAtFailLabel: 'Échec pour l’étape',
  blackhawksTestOutcomes: 'Résultat du test des Blackhawks',
  overallNotesLabelText: 'Notes générales',
  customBikeLabel: 'Autre',
  customBikeMaxTensionLabel: 'Combien de niveaux de tension votre vélo a-t-il?',
  customBikeMaxTensionErrorMessage: 'Veuillez entrer une valeur de tension maximale du vélo supérieure à 1',
  customBikeHeaderLabel: 'Autre (vélo de niveau {0})'
};
 
export default FR;
