const FR = {
  title: 'Enregistrer',
  guardianSubTitle: 'Mise à jour des informations personnelles de {0}',
  returningSubTitle: 'Mettez à jour vos informations personnelles',
  firstNameLabelText: 'Prénom',
  firstNameErrorText: 'Veuillez indiquer votre prénom.',
  lastNameLabelText: 'Nom de famille',
  lastNameErrorText: 'Veuillez indiquer votre nom de famille.',
  phoneLabelText: 'Numéro de téléphone',
  phoneErrorText: 'Veuillez écrire un numéro de téléphone valide',
  alternatePhoneLabelText: 'Autre téléphone',
  genderLabelText: 'Sexe',
  genderValidationMessage: 'Veuillez choisir votre sexe.',
  genderOtherLabelText: 'Quel est votre sexe ?',
  genderOtherValidationMessage: 'Veuillez indiquer votre sexe.',
  languageLabelText: 'Langue',
  languageValidationMessage: 'Veuillez choisir votre langue de préférence.',
  birthdateLabelText: 'Date de naissance :',
  birthdateConfirmationMessage: '{2}. En continuant, vous confirmez que ce patient est âgé de {0} {1}.',
  year: 'an',
  years: 'ans',
  under1: 'moins de 1',
  birthdateValidationMessage: 'Veuillez écrire une date de naissance valide.',
  isGuardianConfirmationText: 'Êtes-vous le parent/tuteur de ce joueur ?',
  addressLabelText: 'Adresse',
  bioLabelText: 'Biographie',
  credentialsLabelText: 'Qualifications (appuyez sur Entrée pour ajouter une qualification)',
  addCredentialInputPlaceholder: '(par exemple, docteur en médecine) Appuyez sur Entrée pour ajouter une qualification',
  submitButtonText: 'Sauvegarder'
};
 
export default FR;
