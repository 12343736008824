import dayjs from 'dayjs';

// Returns true if the date is the same as or later than the date created by
// subtracting the specified duration from the current date.

const checkExpiry = (date, durationValue, durationUnit) => {
  const expiry = dayjs(date);
  const minimumDate = dayjs().subtract(durationValue, durationUnit);
  return expiry.isValid() && expiry.isSameOrAfter(minimumDate);
};

export default checkExpiry;
