import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { If, Then, Else } from 'react-if';
import { mergeRepeatBaselines } from '@/utilities/injury-helpers';
import { TestButton } from '../../../test-start-buttons';
import { BuffaloTest, BuffaloTestView } from '../../../buffalo-test';
import { VOMSTest, VOMSTestView } from '../../../voms';
import { OrthostaticVitalSignsTest, OrthostaticVitalSignsTestView } from '../../../ovs-tests';
import { PostInjuryTest, PostInjuryTestDisplay } from '../../../baseline';
import Strings from './lang';

class SpecialTests extends Component {
  constructor(props) {
    super(props);

    const { injury = {} } = this.props;
    const postInjuryTests = mergeRepeatBaselines(injury);

    this.buffaloRef = React.createRef();
    this.vomsRef = React.createRef();
    this.ovsRef = React.createRef();
    this.postInjuryRef = React.createRef();

    this.state = {
      selectedTest: '',
      editing: {
        voms: false,
        buffalo: false,
        ovs: false
      },
      postInjuryTests
    };
  }

  render() {
    const { 
      clinicId = 0, 
      assessment = {}, 
      user = {}, 
      injury = {},
      currentClinic = {},
      currentUser = {},
      baseline = {}
    } = this.props;
    const { editing } = this.state;

    const buffaloTest = (assessment.buffalo_tests || [])[0];
    const vomsTest = (assessment.voms_tests || [])[0];
    const postInjuryTest = (assessment.baselines || [])[0];
    const ovsTest = (assessment.orthostatic_vital_signs_tests || [])[0];

    return (
      <div className="injury-assessment-tests">
        <div className="row">
          <div className="col-md-12">
            <p className="alert alert-info text-center">
              {Strings.formatString(Strings.beginTestInfoText, Strings.nextSectionButtonText)}
            </p>
          </div>
        </div>
        <div className="injury-assessment-test-buttons">
          <div className="row">
            <div className="col-md-10">
              <div className="btn-group btn-group-md">
                <TestButton
                  type="buffalo"
                  clinicId={clinicId}
                  className={buffaloTest ? 'btn-success' : 'btn-primary'}
                  onClick={() => this.onTestClick('buffalo')}
                />
                <TestButton
                  type="voms"
                  clinicId={clinicId}
                  className={vomsTest ? 'btn-success' : 'btn-primary'}
                  onClick={() => this.onTestClick('voms')}
                />
                <TestButton
                  type="postInjury"
                  clinicId={clinicId}
                  className={postInjuryTest ? 'btn-success' : 'btn-primary'}
                  onClick={() => this.onTestClick('postInjury')}
                />
                <TestButton
                  type="ovs"
                  clinicId={clinicId}
                  className={ovsTest ? 'btn-success' : 'btn-primary'}
                  onClick={() => this.onTestClick('ovs')}
                />
              </div>
            </div>
            <div className="col-md-2">
              <button type="button" className="btn btn-primary" onClick={this.props.onContinue}>
                {Strings.nextSectionButtonText}
              </button>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div ref={this.buffaloRef} className={this.state.selectedTest !== 'buffalo' ? 'test-hidden' : 'test-active'}>
              <If condition={buffaloTest !== undefined && !editing.buffalo}>
                <Then>
                  <BuffaloTestView
                    user={user}
                    injury={injury}
                    currentUser={currentUser}
                    currentClinic={currentClinic}
                    test={buffaloTest}
                    showPrintButton={false}
                    error={null}
                    onEditClick={() => this.onEditTest('buffalo')}
                  />
                </Then>
                <Else>
                  <BuffaloTest
                    user={user}
                    injury={injury}
                    currentUser={currentUser}
                    currentClinic={currentClinic}
                    assessmentId={assessment.id}
                    buffaloId={((assessment.buffalo_tests || [])[0] || {}).id}
                    onSave={() => this.onTestSaved('buffalo')}
                  />
                </Else>
              </If>
            </div>
            <div ref={this.vomsRef} className={this.state.selectedTest !== 'voms' ? 'test-hidden' : 'test-active'}>
              <If condition={vomsTest !== undefined && !editing.voms}>
                <Then>
                  <VOMSTestView
                    user={user}
                    injury={injury}
                    currentUser={currentUser}
                    currentClinic={currentClinic}
                    test={vomsTest}
                    showPrintButton={false}
                    error={null}
                    onEditClick={() => this.onEditTest('voms')}
                  />
                </Then>
                <Else>
                  <VOMSTest
                    user={user}
                    injury={injury}
                    currentUser={currentUser}
                    currentClinic={currentClinic}
                    assessmentId={assessment.id}
                    vomsId={((assessment.voms_tests || [])[0] || {}).id}
                    onSave={() => this.onTestSaved('voms')}
                  />
                </Else>
              </If>
            </div>
            <div ref={this.postInjuryRef} className={this.state.selectedTest !== 'postInjury' ? 'test-hidden' : 'test-active'}>
              <If condition={postInjuryTest !== undefined}>
                <Then>
                  <PostInjuryTestDisplay
                    user={user}
                    injury={injury}
                    test={postInjuryTest}
                    baseline={baseline}
                    testNumber={this.getPostInjuryTestNumber(postInjuryTest)}
                    showHeader
                    showSelector={false}
                  />
                </Then>
                <Else>
                  <If condition={this.state.selectedTest === 'postInjury'}>
                    <Then>
                      <PostInjuryTest
                        user={user}
                        userBaseline={baseline}
                        currentClinic={currentClinic}
                        importableSymptoms={assessment.current_rated_symptoms}
                        assessmentId={assessment.id}
                        showPrintButton={false}
                        onSave={this.props.onTestSaved}
                      />
                    </Then>
                  </If>
                </Else>
              </If>
            </div>
            <div ref={this.ovsRef} className={this.state.selectedTest !== 'ovs' ? 'test-hidden' : 'test-active'}>
              <If condition={ovsTest !== undefined && !editing.ovs}>
                <Then>
                  <OrthostaticVitalSignsTestView
                    user={user}
                    injury={injury}
                    currentUser={currentUser}
                    currentClinic={currentClinic}
                    test={ovsTest}
                    error={null}
                    onEditClick={() => this.onEditTest('ovs')}
                  />
                </Then>
                <Else>
                  <OrthostaticVitalSignsTest
                    user={user}
                    injury={injury}
                    currentUser={currentUser}
                    currentClinic={currentClinic}
                    assessmentId={assessment.id}
                    testId={ovsTest?.id}
                    onSave={() => this.onTestSaved('ovs')}
                  />
                </Else>
              </If>
            </div>
          </div>
        </div>
      </div>
    );
  }

  onTestClick(key) {
    if (key !== this.state.selectedTest) {
      this.setState({
        selectedTest: key
      }, () => {
        this[`${key}Ref`].current.scrollIntoView({ behavior: 'smooth' });
      }); 
    }
  }

  onEditTest(testType) {
    this.setState(prev => ({
      editing: {
        ...Object.keys(prev.editing).reduce((acc, key) => ({ ...acc, [key]: false }), prev.editing),
        [testType]: true
      }
    }));
  }

  onTestSaved(testType) {
    this.setState(prev => ({
      editing: {
        ...prev.editing,
        [testType]: false
      }
    }));

    if (typeof this.props.onTestSaved === 'function') {
      this.props.onTestSaved();
    }

    this[`${testType}Ref`].current.scrollIntoView();
  }

  getPostInjuryTestNumber(test = {}) {
    const { postInjuryTests = [] } = this.state;
    let index = postInjuryTests.findIndex(piTest => piTest.id === test.id) + 1;

    if (index <= 0) {
      index = postInjuryTests.length + 1;
    } 

    return index;
  }
}

SpecialTests.propTypes = {
  assessment: PropTypes.object,
  user: PropTypes.object,
  injury: PropTypes.object,
  currentUser: PropTypes.object,
  currentClinic: PropTypes.object,
  clinicId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  onTestSaved: PropTypes.func,
  onContinue: PropTypes.func
};

export default SpecialTests;
