const EN = {
  baselineTestCardTitleText: 'Baseline Test',
  baselineTestCardSubtitleText: 'Only use for non-injured patients.',
  clinicSearchCardTitleText: 'Search Patients',
  clinicPatientsCardTitleText: 'Manage Patients',
  teamLeadersCardTitleText: 'Manage Team Leaders',
  resourcesCardTitleText: 'Resources',
  findClinicCardTitleText: 'Find a CCMI Clinic',
  cardButtonTitle: 'View',
  startCardButtonTitle: 'Start',
  postInjuryAssessmentCardTitleText: 'Post Injury Assessment',
  postInjuryAssessmentCardSubtitleText: '',
  clinicsCardTitleText: 'Manage Clinics',
  clinicsStatsCardTitleText: 'Clinic Statistics',
  accountBalanceTitleText: 'Account Balance',
  accountBalanceCreditsText: 'Credits: {0}',
  accountBalanceBalanceOwingText: 'Balance Owing: {0}',
  manageBagTagsTitleText: 'Manage ID Cards',
  continueBaselineCardTitle: 'Continue Baseline Test',
  frontDeskCheckInText: 'Check-In Patient',
  taxRatesCardTitle: 'Tax Rates',
  overdueInvoiceLabel: 'Overdue Invoice(s)',
  resumeTestButtonText: 'Resume Test',
  dashboardPreviewContinueBaseline: 'Continue Baseline',
  dashboardPreviewPostInjury: 'Post-Injury Assess.',
  dashboardPreviewHomeText: 'Dashboard'
};

export default EN;
