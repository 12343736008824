import React, { 
  useRef, 
  useEffect, 
  useState, 
  useMemo 
} from 'react';
import { useSelector } from 'react-redux';
import { ClinicSelector } from '@/redux/selectors';
import Icon from '../icon';
import Countries from '@/utilities/country-regions';

const REFERRAL_URL = `${process.env.CCMI_MAPS_HOST}/embed/v1/referrals`;

const getMessageData = (message) => {
  try {
    return JSON.parse(message);
  } catch (e) {
    return {};
  }
};

const getLocationText = (city, state, country) => {
  const countryName = Countries[country]?.name;
  return `${city} ${state}${countryName ? `, ${countryName}` : ''}`;
};

const getReferralUrl = (clinic) => {
  const { address } = clinic || {};
  const { 
    city, state, country, latitude, longitude 
  } = address || {};

  if (latitude && longitude) {
    const text = getLocationText(city, state, country);
    return encodeURI(`${REFERRAL_URL}?lat=${latitude}&lng=${longitude}&s=${text}`);
  }

  return REFERRAL_URL;
};

const ReferralMap = ({
  onReferral = () => {}
}) => {
  const ref = useRef();
  const [loading, setLoading] = useState(true);
  const currentClinic = useSelector(ClinicSelector.getCurrentClinic);
  const url = useMemo(() => getReferralUrl(currentClinic), [currentClinic]);

  useEffect(() => {
    const listener = (event) => {
      if (event.origin === process.env.CCMI_MAPS_HOST) {
        const { type, data } = getMessageData(event.data) || {};
        if (type === 'referral') {
          onReferral(data);
        }
      }
    };

    window.addEventListener('message', listener);

    return () => {
      window.removeEventListener('message', listener);
    };
  }, [onReferral]);

  return (
    <>
      <iframe
        ref={ref}
        title="Referral Map"
        src={url}
        width="100%"
        height="100%"
        onLoad={() => {
          setLoading(false);
        }}
      />
      {loading && (
        <div className="loading-overlay">
          <Icon prefix="fad" name="loader" spinPulse />
        </div>
      )}
    </>
  );
};

export default ReferralMap;
