import React, {
  useCallback,
  useState
} from 'react';
import SymptomsChart from './symptoms-chart';
import ChartMetricSelector from './chart-metric-selector';
import RecentSymptomComparisonTable from './recent-comparison-table';
import TopSymptomCategories from './top-symptom-categories';
import SymptomStatisticsTable from './symptom-statistics-table';
import { OrderedSymptomKeys } from '@/utilities/symptom-data';
import { METRIC_KEYS } from './utils';
import Strings from '../lang';

const SymptomsInsights = ({ symptoms }) => {
  const [metric, setMetric] = useState(METRIC_KEYS.Severity);

  const onSelectMetric = useCallback((type) => {
    setMetric(type === METRIC_KEYS.Individual ? OrderedSymptomKeys[0] : type);
  }, []);

  return (
    <div className="symptoms-insights">
      <TopSymptomCategories symptoms={symptoms.injury?.symptoms} />
      <div className="symptoms-container">
        <h2>{Strings.symptomsChartTitle}</h2>
        <div className="chart-metric-container">
          <ChartMetricSelector 
            metric={metric}
            onChange={onSelectMetric}
          />
        </div>
        <div className="chart-container">
          <SymptomsChart
            metric={metric}
            symptoms={symptoms.injury?.symptoms}
            baseline={symptoms.baseline}
          />
          <SymptomStatisticsTable
            selected={metric}
            symptoms={symptoms.injury?.symptoms}
            onSelectSymptom={onSelectMetric}
          />
        </div>
      </div>
      <RecentSymptomComparisonTable
        baseline={symptoms.baseline}
        symptoms={symptoms.injury?.symptoms}
      />
    </div>
  );
};

export default SymptomsInsights;
