import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormComponent, CheckboxInputGroup } from '@/forms';
import Strings from './lang';
import Icon from '../../icon';

/**
 * This component handle the selection of tests for the user to choose from.
 * PropTypes:
 * - tests: {Object} with the shape { key: label }
 * - manditoryTests: {Array} which tests are checked on and disabled from unchecking. 
 *                   has to match one of the keys from the tests prop
 * - linkedTests: {Array[Array]} any tests which are linked together, meaning that if
 *                one test gets unchecked, all tests in its link get unchecked and vice versa.
 * - onConfirmTests: {Function} callback once the form has been submitted. Callback is passed
 *                   an array of keys that match the keys from the tests prop. The array contains
 *                   only the keys of the tests selected, however, the keys may be in random order.
 */

class TestComponentSelection extends Component {
  constructor(props) {
    super(props);

    const { tests = {}, initialSteps } = props;

    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      selectedTests: [...(initialSteps || Object.keys(tests))]
    };
  }

  render() {
    const showInitiallyUncheckedMessage = !!this.props.initialSteps?.length
      && this.props.initialSteps.length < Object.keys(this.props.tests || {}).length;

    return (
      <FormComponent onSubmit={this.onSubmit}>
        {showInitiallyUncheckedMessage && (
          <p className="alert alert-info">{Strings.uncheckedBoxesMessage}</p>
        )}
        <div className="row">
          {this.renderTestCheckboxes()}
        </div>
        <div className="form-footer">
          <button type="submit" className="btn btn-primary">{Strings.continueToTest}</button>
        </div>
      </FormComponent>
    );
  }

  renderTestCheckboxes() {
    const { tests = {}, manditoryTests = [], initialSteps } = this.props;
    
    return Object.keys(tests).map((key, index) => {
      return (
        <div key={index} className="col-md-4">
          <CheckboxInputGroup
            inputValid
            className="form-group"
            labelText={(
              <>
                <span>{tests[key]}</span>
                {!!initialSteps?.length && !initialSteps.includes(key) && (
                  <Icon 
                    name="xmark"
                    className="error"
                    title={Strings.baselineTestNotCompletedTitle}
                    style={{ marginLeft: 5 }}
                  />
                )}
              </>
            )}
            inputProps={{
              className: 'form-control',
              disabled: manditoryTests.indexOf(key) >= 0,
              checked: this.state.selectedTests.indexOf(key) >= 0,
              onChange: (e) => this.onChange(key, e)
            }}
          />
        </div>
      );
    });
  }

  onChange(key, e) {
    const { checked } = e.target;
    const { selectedTests } = this.state;

    if (checked && selectedTests.indexOf(key) < 0) {
      this.addTestToSelected(key);
    } else {
      this.removeTestFromSelected(key);
    }
  }

  addTestToSelected(key) {
    const { linkedTests = [] } = this.props;
    const tests = [...this.state.selectedTests];

    const foundTest = linkedTests.find(arr => {
      return arr.indexOf(key) >= 0;
    });

    if (foundTest) {
      foundTest.forEach((item) => {
        if (tests.indexOf(item) < 0) {
          tests.push(item);
        }
      });
    } else {
      tests.push(key);
    }

    this.setState({
      selectedTests: [...tests]
    });
  }

  removeTestFromSelected(key) {
    const { linkedTests = [] } = this.props;
    const tests = [...this.state.selectedTests];

    const foundTest = linkedTests.find(arr => {
      return arr.indexOf(key) >= 0;
    });

    if (foundTest) {
      foundTest.forEach(item => {
        const i = tests.indexOf(item);
        if (i >= 0) {
          tests.splice(i, 1);
        }
      });
    } else {
      tests.splice(tests.indexOf(key), 1);
    }

    this.setState({
      selectedTests: [...tests]
    });
  }

  onSubmit() {
    this.props.onConfirmTests(this.state.selectedTests);
  }
}

TestComponentSelection.propTypes = {
  tests: PropTypes.object,
  manditoryTests: PropTypes.array,
  linkedTests: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  onConfirmTests: PropTypes.func
};

export default TestComponentSelection;
