const LineItemTypes = {
  Impact: 'ImpactCode',
  Credit: 'Token',
  BulkImpact: 'BulkImpactBaseline',
  MapleConsult: 'MapleConsult',
  OverduePaymentFee: 'OverduePaymentFee',
  Custom: 'CustomCharge',
  PostInjuryImpact: 'PostInjuryImpactCode'
};

export default LineItemTypes;
