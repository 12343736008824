import React from 'react';
import { ClinicPatientsTable } from '../clinic-patients-table';

const PatientsTable = ({
  patients,
  clinicId,
  showDanaBaseline = true,
  showInjuryStage = false,
  emptyMessage = null,
  viewNames = false
}) => {
  if (!patients?.length) {
    return emptyMessage ? (
      <p className="text-muted text-center">
        <i>{emptyMessage}</i>
      </p>
    ) : null;
  }

  return (
    <ClinicPatientsTable
      users={patients || []}
      clinicId={clinicId}
      showInjuryStage={showInjuryStage}
      showDanaBaseline={showDanaBaseline}
      nameViewPermission={viewNames}
    />
  );
};

export default PatientsTable;
