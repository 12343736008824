import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../icon';
import Strings from './lang';

const EditButton = ({
  editing,
  disabled,
  onClick
}) => (
  <button type="button" className="edit-button no-print" onClick={onClick} disabled={disabled}>
    <Icon name={editing ? 'xmark' : 'pen-to-square'} />
    <span>{editing ? Strings.cancelButtonText : Strings.editButtonText}</span>
  </button>
);

EditButton.propTypes = {
  disabled: PropTypes.bool,
  editing: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
};

export default EditButton;
