const FR = {
  title: 'Test post-blessure',
  baselineTitle: 'Test de référence \u2014 {0}',
  postInjuryTestTitle: 'Test post-blessure {0} \u2014 {1}',
  noActiveBaseline: 'Ce patient n\'a pas de test de référence actif d\'avant {0} pour faire la comparaison',
  selectTestsInfoText: 'Veuillez choisir les tests que vous aimeriez effectuer. Si le patient a déjà terminé un test post-blessure, veuillez DÉSÉLECTIONNER tous les tests déjà réussis (c\'est-à-dire déjà terminés) du premier coup.  Remarque : ne choisissez que les tests que vous voulez effectuer.',
  continueToTest: 'Continuer',
  uncheckedBoxesMessage: 'Toutes les cases initialement décochées indiquent que le test n\'a pas été effectué dans le cadre de la référence du patient.',
  baselineTestNotCompletedTitle: 'Non complété dans le cadre du test de référence du patient'
};
 
export default FR;
