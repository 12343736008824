import React, { Component } from 'react';
import PropTypes from 'prop-types';
import OrientationQuestionGroup from './orientation-question-group';
import { questionAnswers } from './questions';
import ScoreView from '../../../score-view';
import Strings from './lang';

class Orientation extends Component {
  constructor(props) {
    super(props);
    const { initialState = {} } = props;
    const { 
      score, what_month, what_date, what_day, what_year, what_time 
    } = initialState;

    this.onQuestionUpdate = this.onQuestionUpdate.bind(this);

    this.state = {
      questions: {
        month: {
          text: Strings.monthQuestion,
          value: what_month
        }, 
        date: {
          text: Strings.dateQuestion,
          value: what_date
        }, 
        dayOfWeek: {
          text: Strings.dayOfWeekQuestion,
          value: what_day
        }, 
        year: {
          text: Strings.yearQuestion,
          value: what_year
        },
        time: {
          text: Strings.timeQuestion,
          value: what_time
        }
      },
      score
    };
  }
  
  render() {
    const { questions, score } = this.state;
    const questionsLength = Object.keys(questions).length;
    return (        
      <div className="baseline-card-container">
        <OrientationQuestionGroup
          questions={questions}
          answers={questionAnswers}
          onUpdate={this.onQuestionUpdate}
          touched={this.props.touched}
        />
        <div className="baseline-card-footer">
          <ScoreView
            label={Strings.scoreTitle}
            score={score}
            outOf={questionsLength}
          />
        </div>
      </div>
    );
  }

  onQuestionUpdate(questionKey, value) {
    const { questions } = this.state;
    const { onUpdate = () => {} } = this.props;
    questions[questionKey].value = value;
    const score = Object.keys(questions).reduce((acc, key) => {
      return questions[key].value !== '' 
        ? acc + parseInt(questions[key].value, 10)
        : acc;
    }, 0);

    this.setState({
      questions,
      score
    });

    onUpdate({ 
      score,
      what_month: questions.month.value,
      what_date: questions.date.value,
      what_day: questions.dayOfWeek.value,
      what_year: questions.year.value,
      what_time: questions.time.value
    });
  }
}

Orientation.propTypes = {
  onUpdate: PropTypes.func,
  initialState: PropTypes.shape({
    score: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    what_month: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), 
    what_date: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    what_day: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), 
    what_year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), 
    what_time: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }),
  touched: PropTypes.bool
};

export default Orientation;
