import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Strings from './lang';
import { ImageCropper, ImageCropperOptions } from '../image-cropper';
import {
  FormComponent,
} from '@/forms';
import log from '@/utilities/log';

class EditClinicianPhotoCardForm extends Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      imageFile: {},
      imageDataURI: ''
    };
  }

  render() {
    return (
      <FormComponent className="card card-form" onSubmit={this.onSubmit}>
        <ImageCropper 
          className="avatar-image-cropper"
          inputName="avatar-image"
          chooseButtonClassName="btn btn-default"
          cropButtonClassName="btn btn-primary"
          cropperOptions={ImageCropperOptions}
          onCrop={(imageFile, imageDataURI) => {
            this.setState({ imageFile, imageDataURI });
          }}
          onFileTypeError={(type, validTypes) => {
            log('Invalid file type', type, validTypes);
          }}
        />

        <div className="form-footer">
          <button className="btn btn-primary" type="submit">
            {Strings.submitButtonText}
          </button>
        </div>
      </FormComponent>
    );
  }

  onSubmit() {
    const {
      imageFile,
      imageDataURI
    } = this.state;

    const attributes = {
      person_attributes: {
        photo_base64: imageDataURI || '',
        photo_filename: imageFile.name || ''
      }
    };

    this.props.onSubmit(attributes);
  }
}

EditClinicianPhotoCardForm.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default EditClinicianPhotoCardForm;
