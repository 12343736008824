import React from 'react';
import FileLink from './file-link';
import FileView from './file-view';

const FileList = ({
  title,
  files
}) => {
  if (!files.length) return null;

  return (
    <div className="resources">
      <div className="resource-header">{title}</div>
      <div className="resource-list">
        {files.map(file => (
          <div key={file.id} className="resource">
            <FileLink file={file}>
              <FileView 
                mimeType={file.shortcutDetails?.targetMimeType || file.mimeType}
                thumbnail={file.thumbnailLink}
                icon={file.iconLink}
                fileName={file.name}
              />
            </FileLink>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FileList;
