import React, { useMemo } from 'react';
import BlackhawksStationaryBikeStage from './blackhawks-stationary-bike-stage';
import BlackhawksPlyometricsStageDisplay from './blackhawks-plyometrics-stage-display';
import BlackhawksCooldownResultView from './blackhawks-cooldown-result-view';
import Strings from './lang';

import {
  BLACKHAWKS_STATIONARY_BIKE_KEYS,
  BLACKHAWKS_PLYOMETRICS_KEYS,
  getBlackhawksTestState
} from './blackhawks-data';

const BlackhawksTestContent = ({ blackhawks }) => {
  const testState = useMemo(() => getBlackhawksTestState({ blackhawks }), [blackhawks]);
  
  return (
    <div className="blackhawks-test-content">

      <div className="row">
        <div className="col-md-12">
          <BlackhawksCooldownResultView
            notes={testState.notes}
            result={testState.cooldown_result}
            failPoint={testState.failPoint}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <h2>
            {Strings.stationaryBikeHeadingText} &mdash; {Strings.stationaryBikeSubheadingText}
          </h2>
          {BLACKHAWKS_STATIONARY_BIKE_KEYS.map((key, index) => {
            const stageData = testState[key] || [];
            let additionalRows = [];

            // Append additional rows for the rest stage to the third stage:
            if (key === 'stationary_bike_stage_3') {
              additionalRows = [
                <tr key="stationary_bike_rest_row_1">
                  <td colSpan="5">
                    <strong>
                      {Strings.stationaryBikeRestHeadingText}
                    </strong>
                  </td>
                </tr>,
                <tr key="stationary_bike_rest_row_2">
                  <td>{Strings.stationaryBikeRestTimeText}</td>
                  <td />
                  <td />
                  <td>{testState.stationary_bike_rest.heart_rate}</td>
                  <td>{testState.stationary_bike_rest.symptoms}</td>
                </tr>
              ];
            }

            return (
              <BlackhawksStationaryBikeStage
                key={key}
                identifier={key}
                stageTitle={Strings.formatString(
                  Strings.stationaryBikeStageLabelText,
                  index + 1
                )}
                recoveryTitle={Strings.formatString(
                  Strings.stationaryBikeRecoveryLabelText,
                  index + 1
                )}
                stageData={stageData}
                isBlackhawksStationaryBikeDisplay
                additionalRows={additionalRows}
              />
            );
          })}
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <h2>{Strings.plyometricsHeadingText} &mdash; {Strings.plyometricsSubheadingText}</h2>
          {
            BLACKHAWKS_PLYOMETRICS_KEYS.map((key, index) => {
              const stage = testState.full_plyometrics[key];
              return (
                <BlackhawksPlyometricsStageDisplay
                  key={key}
                  identifier={key}
                  className={`stage-${index + 1}`}
                  stageTitle={stage.title}
                  heartRate={stage.heart_rate}
                  symptoms={stage.symptoms}
                  directions={stage.directions}
                  rest={stage.rest}
                />
              );
            })
          }
        </div>
      </div>

    </div>
  );
};

export default BlackhawksTestContent;
