import React, { useState } from 'react';
import Icon from '../../icon';
import DocumentsUploadModal from './documents-upload-modal';
import Strings from '../lang';

const DocumentsUploadButton = ({ claimId }) => {
  const [opened, setOpened] = useState(false);

  return (
    <>
      <button type="button" className="btn btn-sm btn-primary" onClick={() => setOpened(true)}>
        <Icon name="plus-circle" />&nbsp;
        {Strings.uploadDocumentLabel}
      </button>
      <DocumentsUploadModal
        claimId={claimId}
        isOpen={opened}
        onClose={() => setOpened(false)}
      />
    </>
  );
};

export default DocumentsUploadButton;
