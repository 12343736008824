import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { If, Then, Else } from 'react-if';
import { getCurrentClinicId } from '@/tokens';
import { ErrorBanner } from '../errors';
import Activity from '../activity';
import PlayerMedicalInfoCard from './player-medical-info-card';
import PlayerMedicalInfoForm from './player-medical-info-form';
import {
  createMedicalInfoAsync,
  updateMedicalInfoAsync
} from '@/redux/thunks/medical-info';
import { UserSelector } from '@/redux/selectors';

const MedicalInfo = ({
  medical = {},
  user = {},
  show,
  className,
  cardClassName,
  formClassName,
  submitButtonText,
  onError,
  onSubmit
}) => {
  const dispatch = useDispatch();
  const [activity, setActivity] = useState(false);
  const [error, setError] = useState(null);

  const currentUser = useSelector(UserSelector.getCurrentUser);
  const currentClinic = useMemo(() => {
    const currentClinicId = getCurrentClinicId();
    return (currentUser.clinics || []).find(clinic => (
      clinic.id === currentClinicId
    )) || {};
  }, [currentUser.clinics]);

  const updateMedicalInfo = useCallback((medicalId, attributes) => {
    setActivity(true);
    setError(null);

    dispatch(updateMedicalInfoAsync(user.id, medicalId, attributes)).then(info => {
      setActivity(false);

      if (onSubmit) {
        onSubmit(info);
      }
    }).catch(error => {
      setActivity(false);
      setError(error.message);
    });
  }, [dispatch, onSubmit, user.id]);

  const createMedicalInfo = useCallback((attributes) => {
    setActivity(true);
    setError(null);

    dispatch(createMedicalInfoAsync(user.id, attributes)).then(info => {
      setActivity(false);

      if (onSubmit) {
        onSubmit(info);
      }
    }).catch(error => {
      setActivity(false);
      setError(error.message);
    });
  }, [dispatch, onSubmit, user.id]);

  const onSubmitMedicalInfo = useCallback((attributes) => {
    if (medical.id) {
      updateMedicalInfo(medical.id, attributes);
    } else {
      createMedicalInfo(attributes);
    }
  }, [createMedicalInfo, medical.id, updateMedicalInfo]);

  return (
    <div className={classNames('medical-history', className)}>
      <ErrorBanner error={error} />
      <Activity active={activity} static={medical.id === undefined}>
        <If condition={show === true}>
          <Then>
            <PlayerMedicalInfoCard
              className={cardClassName}
              user={user}
              medicalInfo={medical}
            />
          </Then>
          <Else>
            <PlayerMedicalInfoForm
              className={formClassName}
              user={user}
              currentUser={currentUser}
              currentClinic={currentClinic}
              medicalInfo={medical}
              submitButtonText={submitButtonText}
              onError={onError}
              onSubmit={onSubmitMedicalInfo}
            />
          </Else>
        </If>
      </Activity>
    </div>
  );
};

export default MedicalInfo;
