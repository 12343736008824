import React from 'react';
import classNames from 'classnames';
import { ReportStatusStrings } from '../utils';

const StatusClasses = {
  not_started: 'label-default',
  in_progress: 'label-primary',
  completed: 'label-success',
  pending_approval: 'label-default inverted',
  approved: 'label-success inverted',
  submitted: 'label-success',
};

const StatusBadge = ({ status }) => {
  return (
    <div className={classNames('label', StatusClasses[status])}>
      {ReportStatusStrings[status]}
    </div>
  );
};

export default StatusBadge;
