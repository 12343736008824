import React from 'react';
import PropTypes from 'prop-types';
import { getSport } from '@/utilities/sports';
import Strings from './lang';
import InjuryInformation from './injury-information';

const getAnswerLabel = (answer) => {
  switch (answer || '') {
    case 'yes':
      return Strings.yesAnswer;
    case 'no':
      return Strings.noAnswer;
    default: 
      return Strings.notProvidedLabel;
  }
};

const getLOCDurationUnits = (unit) => {
  switch (unit || '') {
    case 'minutes':
      return Strings.minutesText;
    case 'seconds':
      return Strings.secondsText;
    default:
      return unit || Strings.unknownLOCDurationUnit;
  }
};

const headHitStrings = {
  direct_hit_to_head_front: Strings.hitToHeadFront,
  direct_hit_to_head_right: Strings.hitToHeadRight,
  direct_hit_to_head_left: Strings.hitToHeadLeft,
  direct_hit_to_head_back: Strings.hitToHeadBack,
  direct_hit_to_head_top: Strings.hitToHeadTop
};

const getHeadHitLocations = (headHit) => {
  const filteredStrings = Object.keys(headHit || {})
    .filter(key => key !== 'direct_hit_to_head' && headHit[key] === 'yes')
    .map(key => headHitStrings[key]) || [];

  return filteredStrings.length > 0 ? filteredStrings.join(', ') : Strings.noHeadHitLocationSelected;
};

const InjuryReportInformationSection = ({
  injuryInformation = {}
}) => {
  const {
    at_school,
    sports_related,
    loss_of_consciousness,
    loss_of_consciousness_units,
    loss_of_consciousness_duration,
    school_name,
    sport,
    association,
    injury_description,
    ...hitToHead
  } = injuryInformation || {};

  const schoolDisplay = at_school === 'yes';
  const sportsDisplay = sports_related === 'yes';
  const concussionDisplay = loss_of_consciousness === 'yes';
  const hitToHeadDisplay = hitToHead.direct_hit_to_head === 'yes';

  return (
    <InjuryInformation>

      <InjuryInformation.Row>
        <InjuryInformation.Item 
          label={Strings.sportRelatedLabel}
          value={getAnswerLabel(sports_related)} 
          childCondition={sportsDisplay}
        >
          <InjuryInformation.Item label={Strings.sportLabel} value={getSport(sport || '').name} />
          <InjuryInformation.Item label={`${Strings.associationLabel}:`} value={association} />
        </InjuryInformation.Item>

        <InjuryInformation.Item
          label={Strings.schoolNameQuestionLabel} 
          value={getAnswerLabel(at_school)} 
          childCondition={schoolDisplay}
        >
          <InjuryInformation.Item label={`${Strings.schoolNameLabel}:`} value={school_name} />
        </InjuryInformation.Item>
      </InjuryInformation.Row>

      <InjuryInformation.Row>
        <InjuryInformation.Item 
          label={Strings.lossConsciousnessLabel}
          value={getAnswerLabel(loss_of_consciousness)} 
          childCondition={concussionDisplay}
        >
          <InjuryInformation.Item 
            label={`${Strings.lossConsciousnessDurationLabel}:`} 
            value={`${loss_of_consciousness_duration} ${getLOCDurationUnits(loss_of_consciousness_units)}`} 
          />
        </InjuryInformation.Item>

        <InjuryInformation.Item
          label={Strings.hitToHeadLabelText} 
          value={getAnswerLabel(hitToHead.direct_hit_to_head)} 
          childCondition={hitToHeadDisplay}
        >
          <InjuryInformation.Item label={`${Strings.hitToHeadLocationLabel}:`} value={getHeadHitLocations(hitToHead)} />
        </InjuryInformation.Item>
      </InjuryInformation.Row>

      <InjuryInformation.Row>
        <InjuryInformation.Item 
          label={`${Strings.injuryDescription}:`} 
          value={injury_description || Strings.noInjuryDescription} 
          displayValueIn="p"
        />
      </InjuryInformation.Row>
    </InjuryInformation>
  );
};

InjuryReportInformationSection.propTypes = {
  injuryInformation: PropTypes.object
};

export default InjuryReportInformationSection;
