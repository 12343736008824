import React from 'react';
import Strings from './lang';

const DeactivatedAlert = () => (
  <div className="alert alert-info alert-deactivated">
    <p>{Strings.message}</p>
  </div>
);

export default DeactivatedAlert;
