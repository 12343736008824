import React from 'react';
import dayjs from 'dayjs';
import { sortAscending } from '@/utilities/sort';
import Strings from './lang';

const STANDARD_TIME_FORMAT = 'h:mm A';
const MILITARY_TIME_FORMAT = 'HH:mm';
const MAX_HOURS = 24;
const HOUR_SPLIT_INCREMENT = 30;

const StatusKeys = {
  Open: 'open',
  Closed: 'closed',
  Appointment: 'appointment'
};

const getStatusStrings = () => ({
  [StatusKeys.Open]: Strings.openStatus,
  [StatusKeys.Closed]: Strings.closedStatus,
  [StatusKeys.Appointment]: Strings.appointmentOnlyStatus
});

const DayHours = [...new Array(MAX_HOURS)].reduce((acc, _, index) => {
  acc.push(dayjs().hour(index).minute(0).format(STANDARD_TIME_FORMAT));
  acc.push(dayjs().hour(index).minute(HOUR_SPLIT_INCREMENT).format(STANDARD_TIME_FORMAT));
  return acc;
}, []);

/* eslint-disable object-curly-newline */
const defaultHours = [
  { day: 0, status: 'closed', open_at: null, close_at: null },
  { day: 1, status: 'closed', open_at: null, close_at: null },
  { day: 2, status: 'closed', open_at: null, close_at: null },
  { day: 3, status: 'closed', open_at: null, close_at: null },
  { day: 4, status: 'closed', open_at: null, close_at: null },
  { day: 5, status: 'closed', open_at: null, close_at: null },
  { day: 6, status: 'closed', open_at: null, close_at: null },
];
/* eslint-enable object-curly-newline */

const getInitialHours = (initial) => {
  return (initial || defaultHours).sort(sortAscending.bind(null, 'day'));
};

const toFormat = (time, format) => {
  return dayjs(time, [STANDARD_TIME_FORMAT, MILITARY_TIME_FORMAT], true)
    .format(format);
};

const to12HourTime = (time) => {
  return toFormat(time, STANDARD_TIME_FORMAT);
};

const to24HourTime = (time) => {
  return toFormat(time, MILITARY_TIME_FORMAT);
};

const makeTimeOptions = (other = null, after = true) => {
  return DayHours.map((time, index) => {
    const milTime = to24HourTime(time);
    const isDisabled = after ? other >= milTime : other <= milTime;

    return (
      <option key={index} disabled={isDisabled} value={milTime}>{time}</option>
    );
  });
};

export {
  getInitialHours,
  to12HourTime,
  to24HourTime,
  makeTimeOptions,
  getStatusStrings,
  StatusKeys
};
