const EN = {
  clinician: 'Clinician',
  clinic_staff: 'Clinic Staff',
  front_desk: 'Front Desk',
  leader: 'Team Leader',
  player: 'Patient',
  baseline_tester: 'Baseline Tester',
  clinic_owner: 'Clinic Owner',
  specialist: 'Physician/Specialist',
  idt_clinician: 'IDT Clinician',
  idt_front_desk: 'IDT Front Desk'
};

export default EN;
