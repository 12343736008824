import React from 'react';
import PropTypes from 'prop-types';
import { DiagnosisView } from '../../diagnosis';
import Strings from './lang';

const PostInjuryDiagnosis = ({
  diagnosis = {}
}) => (
  <div className="post-injury-section post-injury-diagnosis">
    <div className="row">
      <div className="col-md-12">
        <h2 className="bold">{Strings.diagnosisTitle}</h2>
      </div>
    </div>
    <DiagnosisView
      className="post-injury-group"
      diagnosis={diagnosis}
    />
  </div>
);

PostInjuryDiagnosis.propTypes = {
  diagnosis: PropTypes.object.isRequired
};

export default PostInjuryDiagnosis;
