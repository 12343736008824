import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { If, Then, Else } from 'react-if';
import { HelpModalLink } from '../help-modal';
import { FormInput, RadioInputGroup } from '@/forms';
import { symptomDistanceValue } from './voms-data';
import Strings from './lang';
import Icon from '../icon';

const MAX_SCALE = 11;

const ScaleOptions = ({
  total,
  offset
}) => (
  <>
    {Array.from(Array(total), (nan, index) => (
      <option key={index} value={index + offset}>{index + offset}</option>
    ))}
  </>
);

const ScaleSelect = ({
  total,
  offset,
  value,
  onChange
}) => (
  <FormInput type="select" className="form-control" inputProps={{ value, onChange }}> 
    <ScaleOptions 
      total={total}
      offset={offset}
    />
  </FormInput>
);

class VOMSTestFormRow extends Component {
  render() {
    const { 
      symptomName, 
      symptomState, 
      helpDocumentName = ''
    } = this.props;

    return (
      <tr>
        <td>
          <span>{symptomName}</span>&nbsp;
          <If condition={helpDocumentName.length > 0}>
            <Then>
              <HelpModalLink className="help-button" documentName={helpDocumentName}>
                <Icon name="circle-info" />
              </HelpModalLink>
            </Then>
          </If>
        </td>
        <td>
          <FormInput 
            type="checkbox" 
            className="form-control" 
            inputProps={{
              checked: symptomState.not_tested || false,
              onChange: this.onChangeInput.bind(this, 'not_tested', 'checked')
            }} 
          />
        </td>
        <td>
          <ScaleSelect 
            value={symptomState.headache}
            onChange={(value) => this.onChangeInput('headache', 'value', value)}
            total={MAX_SCALE}
            offset={0}
          />
        </td>
        <td>
          <ScaleSelect 
            value={symptomState.dizziness}
            onChange={(value) => this.onChangeInput('dizziness', 'value', value)}
            total={MAX_SCALE}
            offset={0}
          />
        </td>
        <td>
          <ScaleSelect 
            value={symptomState.nausea}
            onChange={(value) => this.onChangeInput('nausea', 'value', value)}
            total={MAX_SCALE}
            offset={0}
          />
        </td>
        <td>
          <ScaleSelect 
            value={symptomState.fogginess}
            onChange={(value) => this.onChangeInput('fogginess', 'value', value)}
            total={MAX_SCALE}
            offset={0}
          />
        </td>
        <td>
          <If condition={this.props.hasDistance === true}>
            <Then>
              <FormInput 
                type="number" 
                className="form-control" 
                inputProps={{
                  min: 0,
                  value: symptomDistanceValue(symptomState.distance),
                  onChange: this.onChangeInput.bind(this, 'distance', 'value')
                }} 
              />
            </Then>
            <Else>
              <span>&mdash;</span>
            </Else>
          </If>
        </td>
        <td>
          <RadioInputGroup
            radioLabels={[Strings.no, Strings.yes]}
            radioValues={['no', 'yes']}
            initialValue={symptomState.abnormality}
            inputValid={!symptomState.not_tested ? symptomState.abnormality !== '' : true}
            onUpdate={(value) => this.onChangeRadioInput('abnormality', value)}
            touched={this.props.submitted}
            messageClassName="alert alert-danger"
            messageText={Strings.abnormalityErrorMessage}
          />
        </td>
        <td>
          <FormInput 
            type="textarea" 
            className="form-control" 
            inputProps={{
              value: symptomState.comments || '',
              onChange: this.onChangeInput.bind(this, 'comments', 'value')
            }} 
          />
        </td>
      </tr>
    );
  }

  onChangeInput(field, property, event) {
    const value = event.target[property];
    this.props.onUpdate(field, value);
  }

  onChangeRadioInput(field, value) {
    this.props.onUpdate(field, value);
  }
}

VOMSTestFormRow.propTypes = {
  symptomName: PropTypes.string.isRequired,
  symptomState: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  hasDistance: PropTypes.bool,
  helpDocumentName: PropTypes.string,
  submitted: PropTypes.bool
};

export default VOMSTestFormRow;
