const EN = {
  openStatus: 'Open',
  closedStatus: 'Closed',
  appointmentOnlyStatus: 'Appointment Only',
  hoursDisplayTitle: 'Hours',
  hoursModalTitle: 'Clinic Hours',
  saveButtonText: 'Save',
  dayTableHeading: 'Day',
  statusTableHeading: 'Status',
  openTableHeading: 'Open Time',
  closeTableHeading: 'Close Time',
  noHoursListText: 'No Hours',
  copyRowTitleText: 'Copy to row below'
};

export default EN;
