import { UPDATE_SOAP_NOTE, UPDATE_SOAP_NOTES } from '../actions/soap-notes';

const updateSoapNoteReducer = (state = {}, action = {}) => {
  const { userId, injuryId, soap = {} } = action;
  const { id } = soap;
  const userState = state[userId] || {};
  const nextUserState = (id) ? { ...userState, [id]: { ...soap, injuryId } } : userState;
  return (userId) ? { ...state, [userId]: nextUserState } : state;
};

const updateSoapNotesReducer = (state = {}, action = {}) => {
  const { userId, injuryId, soapNotes = [] } = action;
  const nextUserState = soapNotes.reduce((userState, soap) => {
    const { id = 0 } = soap;
    return (id) ? { ...userState, [id]: { ...soap, injuryId } } : userState;
  }, state[userId] || {});

  return (userId) ? { ...state, [userId]: nextUserState } : state;
};

const soapNotes = (state = {}, action = {}) => {
  switch (action.type) {
    case UPDATE_SOAP_NOTE:
      return updateSoapNoteReducer(state, action);
    case UPDATE_SOAP_NOTES:
      return updateSoapNotesReducer(state, action);
    default:
      return state;
  }
};

export default soapNotes;
