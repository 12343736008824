import React, { useCallback } from 'react';
import SearchForm from './search-form';
import ActiveSelector from './active-selector';
import PerPageSelector from './per-page-selector';

const SEARCH_DEBOUNCE_TIMEOUT = 500;

const ResultsCount = ({ pagination }) => {
  const { page, per_page, total_count } = pagination;
  const start = ((page - 1) * per_page) + 1;
  let end = page * per_page;

  if (end > total_count) {
    end = total_count;
  }

  if (!total_count) {
    return null;
  }

  return (
    <div className="results-count text-muted">
      <i>Showing <strong>{start} - {end}</strong> of <strong>{total_count}</strong> Clinics</i>
    </div>
  );
};

const ClinicSearchFilters = ({
  filters = {},
  pagination,
  onUpdate
}) => {
  const onSearchChange = useCallback((name) => {
    onUpdate({ name });
  }, [onUpdate]);

  const onActiveChange = useCallback((active) => {
    if (active !== filters.active) {
      onUpdate({ active });
    }
  }, [filters.active, onUpdate]);

  const onPerPageChange = useCallback((value) => {
    if (value !== filters.per_page) {
      onUpdate({ page: 1, per_page: value });
    }
  }, [filters.per_page, onUpdate]);

  return (
    <div className="clinics-search-filters">
      <div className="name-search-filter">
        <SearchForm
          value={filters.name || ''}
          delay={SEARCH_DEBOUNCE_TIMEOUT}
          onChange={onSearchChange}
        />
        <ResultsCount pagination={pagination} />
      </div>
      <div>
        {!filters.name && (
          <ActiveSelector
            value={filters.active}
            onSelect={onActiveChange}
          />
        )}
        <PerPageSelector
          value={filters.per_page}
          onSelect={onPerPageChange}
        />
      </div>
    </div>
  );
};

export default ClinicSearchFilters;
