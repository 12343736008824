const FR = {
  emptySportsMessageText: 'Vous n’avez pas encore ajouté d’équipe.',
  teamSearchFormTitle: 'Ajouter une équipe',
  selectTeamFormTitle: 'Choisir l’équipe et les positions',
  sportLabelText: 'Sport',
  sportErrorText: 'Veuillez choisir votre sport',
  otherSportLabelText: 'Autre sport',
  otherSportErrorText: 'Veuillez écrire un sport valide (lettres et espaces seulement).',
  countryLabelText: 'Pays',
  countryErrorText: 'Veuillez indiquer votre pays.',
  regionLabelText: 'État/ province',
  regionErrorText: 'Veuillez indiquer votre État ou votre province.',
  cityLabelText: 'Ville/municipalité',
  cityErrorText: 'Veuillez indiquer votre ville.',
  teamLabelText: 'Équipe',
  teamErrorText: 'Veuillez indiquer votre équipe.',
  newTeamNameLabelText: 'Nom d’équipe',
  newTeamNameErrorText: 'Veuillez indiquer le nom de votre équipe.',
  newTeamOrganizationLabelText: 'Organisation/Association (aucun acronyme)',
  newTeamLevelLabelText: 'Niveau de l’équipe (par exemple, AA)',
  newTeamLevelErrorText: 'Veuillez indiquer le niveau de votre équipe.',
  primaryPositionLabelText: 'Position principale',
  otherPrimaryPositionLabelText: 'Autre position principale',
  secondaryPositionLabelText: 'Position secondaire',
  otherSecondaryPositionLabelText: 'Autre position secondaire',
  positionErrorText: 'Veuillez choisir votre position principale',
  otherPositionErrorText: 'Veuillez écrire une position valide (lettres et espaces seulement).',
  searchButtonText: 'Trouver des équipes',
  saveButtonText: 'Sauvegarder',
  deleteButtonText: 'Quitter l’équipe',
  cancelButtonText: 'Annuler',
  sportSelectOptionText: 'Choisir un sport',
  teamSelectOptionText: 'Choisir une équipe',
  positionSelectOptionText: 'Choisir une position',
  addTeamOptionText: 'Ajouter une nouvelle équipe',
  otherOptionText: 'Autre',
  userTeamsListHeadingText: 'Équipes',
  suggestedTeamsHeadingText: 'Équipes suggérées',
  suggestedTeamsMessageText: 'Nous avons trouvé des équipes semblables à celles que vous cherchez. Est-ce que vous cherchiez l’une de ces équipes ?',
  ignoreSuggestionHeadingText: 'Non merci, je vais utiliser l’équipe que j’ai écrite :',
  confirmLeaveTeamMessageText: 'Êtes-vous certain de vouloir quitter l’équipe {0} ?',
  invalidOrganizationMessage: 'Veuillez écrire le nom complet de l’organisation/l’association. Les acronymes sont interdits.',
  requestedTeamJoinMessage: 'Votre demande pour rejoindre cette équipe ({0}) a été envoyée au chef d’équipe pour son approbation.',
  noOganizationDefaultLabel: 'Aucune organisation',
  organizationPlaceholder: 'Veuillez entrer le nom complet pas d\'acronymes'
};
 
export default FR;
