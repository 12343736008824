import React, { useState } from 'react';
import NotesHistoryDrawer from '../note-history-drawer';
import Strings from './lang';

const SoapNotesHistoryButton = ({
  user,
  injury,
  soapId,
  clinicId
}) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <button type="button" className="btn btn-primary" onClick={() => setOpen(true)}>
        {Strings.soapNoteHistoryTitle}
      </button>
      <NotesHistoryDrawer 
        isOpen={open}
        user={user}
        injury={injury}
        noteType="soap"
        noteId={soapId}
        clinicId={clinicId}
        onClose={() => setOpen(false)}
      />
    </>
  );
};

export default SoapNotesHistoryButton;
