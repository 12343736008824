import React from 'react';
import { If, Then, Else } from 'react-if';
import { NotesModalButton } from '../notes-modal';
import Icon from '../icon';
import Strings from './lang';

const AddNoteButton = ({
  title,
  required,
  note,
  editable,
  noteLabel,
  errorText,
  submitButtonTitle,
  cancelButtonTitle,
  onSave,
}) => {
  const hasNote = !!note;

  return (
    <NotesModalButton
      note={note}
      title={title}
      required={required}
      editable={editable}
      noteLabel={noteLabel}
      errorText={errorText}
      submitButtonTitle={submitButtonTitle}
      cancelButtonTitle={cancelButtonTitle}
      className="edit-button"
      onSave={onSave}
    >
      <If condition={hasNote}>
        <Then>
          <span><Icon name="comment" /> {Strings.editNoteText}</span>
        </Then>
        <Else>
          <span><Icon name="pen-to-square" /> {Strings.addNoteText}</span>
        </Else>
      </If>
    </NotesModalButton>
  );
};

export default AddNoteButton;
