import Strings from './lang';

export const TestKeyLabels = {
  score: Strings.overallScoreLabel,
  feet_together_score: Strings.feetTogetherPoseLabel,
  tandem_right_score: Strings.tandemRightPoseLabel,
  tandem_left_score: Strings.tandemLeftPoseLabel,
  single_right_score: Strings.singleLegRightPoseLabel,
  single_left_score: Strings.singleLegLeftPoseLabel
};

export const CHART_SELECTOR_OPTIONS = [
  { value: 'score', label: TestKeyLabels.score },
  { value: 'all_poses', label: Strings.allPosesSelectorLabel },
  { value: 'feet_together_score', label: TestKeyLabels.feet_together_score },
  { value: 'tandem_right_score', label: TestKeyLabels.tandem_right_score },
  { value: 'tandem_left_score', label: TestKeyLabels.tandem_left_score },
  { value: 'single_right_score', label: TestKeyLabels.single_right_score },
  { value: 'single_left_score', label: TestKeyLabels.single_left_score }
];
