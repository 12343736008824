const FR = {
  claimsTitle: 'Références IDT d\'Axia Health',
  dischargedClaims: 'Références libérées',
  activeClaims: 'Références actives',
  newClaims: 'Nouvelles références',
  noClaimsFoundMessage: 'Aucune référence n\'a encore été attribuée à votre clinique',
  noDischargedClaims: 'Aucune référence libérée trouvée',
  noActiveClaims: 'Aucune référence active trouvée',
  claimNumberColumn: 'Numéro de réclamation',
  workerNameColumn: 'Nom',
  nextAppointmentColumn: 'Prochain rendez-vous',
  nextReportDueColumn: 'Prochain rapport dû',
  dischargedDateColumn: 'Date de sortie',
  referalDateColumn: 'Date de référence',
  initialReportTitle: 'Initial',
  midPointReportTitle: 'Point médian',
  careOutcomesReportTitle: 'Soins et résultats',
  supplementalReportTitle: 'Supplémentaire',
  claimDetailsTitle: 'Détails de la réclamation',
  workerDetailsTitle: 'Ouvrier',
  dobLabel: 'Date de naissance',
  phoneNumberLabel: 'No. de téléphone',
  interpreterLabel: 'Interprète',
  documentsTitle: 'Documents',
  emptyDocumentsMessage: 'Aucun document joint pour cette référence',
  initialApptBannerTitle: 'Prendre un premier rendez-vous',
  initialApptBannerMessage: 'Pour lancer cette référence, veuillez ajouter les informations de réservation pour le rendez-vous initial.',
  setInitialApptButton: 'Fixer un rendez-vous',
  addInitialApptHeader: 'Ajouter un rendez-vous initial',
  addInitialApptSuccessMessage: 'Le premier rendez-vous a été fixé et la référence a été initiée avec succès!',
  documentsTabLabel: 'Documents',
  treatmentTabLabel: 'Traitement',
  claimStatusNotStarted: 'Pas commencé',
  claimStatusActive: 'Actif',
  claimStatusDischarged: 'Déchargé',
  claimTitle: 'Référence IDT - {0}',
  appointmentInputLabel: 'Date et heure du rendez-vous',
  appointmentInputBetweenError: 'Veuillez saisir une date valide entre {0} et {1}',
  appointmentInputAfterError: 'Veuillez saisir une date valide à partir du {0}',
  appointmentInputBeforeError: 'Veuillez saisir une date valide au plus tard le {0}',
  appointmentConfirmationMessage: 'Date et heure du rendez-vous',
  treatmentTypeLabel: 'Type de traitement',
  treatmentTypeErrorMessage: 'Veuillez sélectionner un type de traitement',
  selectTreatmentType: 'Sélectionnez le type de traitement',
  treatmentTypeInitialAssessment: 'Visite initiale',
  treatmentTypeFunctionalTesting: 'Test fonctionel',
  treatmentTypePhysicalTherapy: 'Réhabilitation active',
  treatmentTypeOccupationalTherapy: 'Ergothérapie',
  treatmentTypeWorkHardening: 'Travail d\'écrouissage',
  virtualApptCheckboxLabel: 'Ceci est un rendez-vous virtuel',
  virtualApptLabel: 'Virtuel',
  submitButton: 'Soumettre',
  providerListTitle: 'Fournisseurs de rendez-vous',
  addProviderButton: 'Ajouter un autre fournisseur',
  providerNameLabel: 'Nom du fournisseur',
  providerNameError: 'Veuillez entrer le nom du fournisseur',
  providerDesignationLabel: 'Désignation',
  providerDesignationError: 'Veuillez sélectionner une désignation',
  selectDesignationLabel: 'Sélectionnez la désignation',
  appointmentsTabLabel: 'Rendez-vous',
  addAppointmentText: 'Ajouter un rendez-vous',
  appointmentAddedSuccessMessage: 'Rendez-vous ajouté avec succès',
  noAppointmentsAddedMessage: 'Aucun rendez-vous n\'a été ajouté à ce bloc',
  completedText: 'Complété',
  blockRequestedLabel: 'Bloc demandé',
  initialApptRequiredMessage: 'Un rendez-vous initial doit être ajouté pour lancer cette réclamation.',
  requiredAppointmentsLabel: 'Rendez-vous requis',
  idtPocBlockLabel: 'Bloc IDT POC',
  idtPocWhBlockLabel: 'Bloc IDT POC with Work Hardening',
  apptStatusBooked: 'Réservé',
  apptStatusArrived: 'Arrivé',
  apptStatusCancelled: 'Annulé',
  apptStatusNoShowed: 'Non affiché',
  apptStatusRescheduled: 'Reprogrammé',
  reportsTab: 'Rapports',
  reportsSectionTitle: 'Rapports IDT',
  startReportButton: 'Démarrer le rapport',
  continueReportButton: 'Continuer le rapport',
  viewReportButton: 'Voir le rapport',
  startReportErrorMessage: 'Impossible de démarrer le rapport: {0}',
  reportTypeColumnLabel: 'Type de rapport',
  blockTypeColumnLabel: 'Type de bloc',
  dueDateColumnLabel: 'Date d\'échéance',
  reportStatusColumnLabel: 'Statut',
  reportStatusNotStarted: 'Pas commencé',
  reportStatusInProgress: 'En cours',
  reportStatusCompleted: 'Complété',
  reportStatusPendingApproval: 'En attente d\'approbation finale',
  reportStatusApproved: 'Approuvé',
  reportStatusSubmitted: 'Soumis',
  updateApptStatusErrorMessage: 'Impossible de mettre à jour le statut du rendez-vous: {0}',
  updateApptStatusModalTitle: 'Mettre à jour le statut du rendez-vous',
  updateApptStatusModalMessage: 'Vous êtes sur le point de modifier le statut du rendez-vous en <strong>{0}</strong>. Veuillez saisir ci-dessous toutes les notes relatives à ce changement de statut, telles que le motif de l\'annulation, la date du report, etc...',
  notesLabel: 'Remarques',
  notesErrorMessage: 'Des notes sont requises',
  updateApptStatusModalButton: 'État de mise à jour',
  startReportConfirmationTitle: 'Confirmer le démarrage du rapport',
  startReportConfirmationMessage: 'Vous êtes sur le point de démarrer un rapport pour un bloc de traitement qui n\'a pas encore commencé.Souhaitez - vous continuer?',
  confirmButtonLabel: 'Confirmer',
  finalReportTableColumn: 'Rapport final PDF',
  approveReportButton: 'Approuver le rapport',
  approveReportSuccessMessage: 'Le rapport final a été approuvé',
  approveReportConsentLabel: 'Je vérifie que le contenu de ce document est complet et exact. J\'autorise Axia Health à soumettre ce document à la WSIB en mon nom.',
  uploadDocumentLabel: 'Télécharger des documents',
  uploadingFilesLabel: 'Téléchargement de fichiers ...',
  uploadedFilesLabel: 'Fichiers téléchargés',
  closeButtonLabel: 'Fermer',
  dropzoneLabel: 'Faites glisser les fichiers PDF ici ou cliquez pour sélectionner des fichiers',
  fileNameColumnHeader: 'Nom de fichier',
  fileSizeColumnHeader: 'Taille du fichier',
  updatedAtColumnHeader: 'Téléchargé',
  pleaseWaitUploadingLabel: 'Attendez s\'il vous plaît ...',
  idtResourcesLabel: 'Ressources IDT',
  idtPoliciesHeader: 'Politique et consentements IDT',
  outcomeMeasuresHeader: 'Mesures des résultats',
  functionalTestingHeader: 'Test fonctionel',
  notProvidedLabel: 'Non fourni',
  viewFinalReportPdf: 'Voir le rapport en PDF',
  otApptsIndicatorLabel: 'Rendez-vous d\'ergothérapie'
};
 
export default FR;
