const FR = {
  symptomLevelsInfo: 'Demandez à l\'athlète d’évaluer ses symptômes sur une échelle de 0 à 6. Demandez des détails sur la nature de tous les symptômes nommés et voyez si ceux-ci sont habituels pour la personne',
  orientationInfoText: 'Chaque bonne réponse vaut 1 point, et chaque mauvaise réponse vaut 0.',
  immediateMemoryStep1InfoText: '« Je vais vous faire un test de mémoire. Je vais lire une série de mots et lorsque j’aurai terminé, répétez autant de mots que vous le pouvez, peu importe leur ordre ».',
  immediateMemoryStep2InfoText: '« Je vais répéter la même série de mots. Répétez autant de mots que vous le pouvez, même si vous avez déjà dit le mot ».',
  step1LabelText: 'Étape 1',
  step2LabelText: 'Étapes 2 et 3',
  kingDevickDoneInfoText: 'Choisissez « Terminé » lorsque vous avez terminé le décompte et que vous êtes prêts à passer au décompte suivant.',
  saveButtonText: 'Sauvegarder',
  abStractNotesTitle: '{0} Remarques :',
  kingDevickInfoText: 'Le patient doit lire chaque carte aussi vite que possible et sans faire d\'erreur. S’il fait une erreur, arrêtez, remettez le compteur à zéro et recommencez. Écrivez la durée d’un essai réussi (c’est-à-dire sans erreur), ainsi que le nombre de fois où le test a dû être recommencé pour chaque carte.',
  balanceTestingInfoText: 'Dites au patient d\'enlever ses chaussures et ses bas, de relever le bord de ses pantalons au-dessus des chevilles (si la situation s’applique) et enlevez tout ruban sur les chevilles (si la situation s’applique) Ce test comprend trois (3) tests de vingt (20) secondes chacun, avec trois différentes postures.',
  balanceTestingErrorsInfoText: 'Cliquez ici pour voir des erreurs possibles.',
  reactionTimeInfoText: 'Faites un test avec la main non dominante du patient. Faites trois (3) essais de pratique puis faites cinq (5) essais réussis et écrivez le résultat se rapprochant le plus de 1/2 cm.',
  forcePlateInfoText: 'À l\'aide du scanneur de démarche Footmaxx, mettez-vous sur un seul pied au milieu du scanneur. Choisissez « Ajouter un scan » et cliquez sur « Scan de la posture ». Ensuite, choisissez « Pied testé », puis réglez la « Durée » à 25 secondes et choisissez « Ignorer les 5 premières secondes ». Lorsque le patient est prêt, avec ses mains sur ses hanches et ses yeux ouverts, faites-le lever sa jambe ne supportant pas son poids et faites le scan.',
  gripStrengthInfoText: 'Veuillez mettre les mesures en kg!',
  immediateMemoryPatientLanguageText: 'Ce patient a choisi {0}{1}{2} comme langue de préférence',
  addNoteButtonText: 'Ajouter une remarque',
  usedWordListsFetchError: 'Aucune liste de mots déjà utilisée',
  timedTandemGaitInfo: 'Placez une ligne de 38 mm de large et de 3 mètres de long sur le sol/une surface ferme avec du ruban adhésif athlétique. La tâche est chronométrée. Veuillez terminer les 3 essais.',
  timedTandemGaitInstructions: 'Demandez au patient de se tenir pieds joints à une extrémité de la ligne. Le patient doit alors marcher rapidement du talon aux orteils jusqu\'au bout de la bande, faire demi-tour et revenir aussi vite qu\'il le peut sans séparer ses pieds ni sortir de la ligne. Le patient <strong>échoue à l\'essai</strong> s\'il commet l\'une des erreurs suivantes: <strong>sort de la ligne</strong>, <strong>a une séparation entre le talon et l\'orteil</strong>, ou <strong>s\'ils touchent ou attrapent l\'examinateur ou un objet</strong>. Si le patient échoue à l\'essai, arrêtez le chronomètre, marquez l\'essai comme ayant échoué et passez à l\'essai suivant.',
  dualTaskSubmitError: 'Veuillez terminer tous les essais. Si le patient ne peut pas terminer le test, cochez l\'option « Patient incapable de terminer » ci-dessus.',
  tandemGaitSubmitError: 'Veuillez terminer tous les essais. Si le patient ne peut pas terminer un essai, marquez-le comme ayant échoué.',
  wessBalanceInfo: 'Ce test comprendra 6 conditions de 20 secondes chacune: 3 positions (assis, disque d\'équilibre et wheelie), chacune avec 2 entrées visuelles(yeux ouverts et yeux fermés).',
  bessTitle: 'Système de notation des erreurs d\'équilibre',
  wessTitle: 'Système de notation des erreurs en fauteuil roulan',
  balanceTestType: 'Type de test d\'équilibre'
};
 
export default FR;
