import SymptomStrings from './lang';
import SymptomKeys from './keys';
import OrderedSymptomKeys from './ordered-keys';
import calculateSymptomsTotals from './symptoms-totals';
import { SymptomCategoryStrings, SymptomCategorySymptoms, SymptomCategories } from './categories';
import { getRtlRiskSymptomCategories } from './rtl-risk';

const MAX_SYMPTOM_SEVERITY = 6;

export {
  SymptomStrings,
  SymptomKeys,
  OrderedSymptomKeys,
  calculateSymptomsTotals,
  MAX_SYMPTOM_SEVERITY,
  SymptomCategories,
  SymptomCategoryStrings,
  SymptomCategorySymptoms,
  getRtlRiskSymptomCategories
};
