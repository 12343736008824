import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PolicyScrollBox from './policy-scroll-box';
import policyUrlForRoleName from '@/utilities/policy-url';
import { getCurrentUserLanguage } from '@/utilities/localization';
import { CheckboxInputGroup } from '@/forms';
import { HelpModalLink } from '../help-modal';
import Strings from './lang';
import Icon from '../icon';

class PlayerPrivacyConsents extends Component {
  constructor(props) {
    super(props);

    this.state = {
      consents: [false, false, false]
    };
  }

  render() {
    const currentLanguage = getCurrentUserLanguage();
    return (
      <PolicyScrollBox
        policyUrl={policyUrlForRoleName('player', currentLanguage)}
        preventCache
        footerMessage={Strings.scrollMessageText}
        footerMessageClassName="alert alert-info"
      >
        {(disabled) => (
          <div>
            <p>{Strings.playerConsentHeadingText}</p>
            {this.state.consents.map((accepted, index) => {
              const policyHelpName = `privacy-consent-item-${index + 1}`;
              return (
                <div key={index} className="privacy-consent-item">
                  <CheckboxInputGroup
                    className="form-group terms-input-group"
                    labelText={Strings[`playerConsentText_${index + 1}`]}
                    inputProps={{
                      id: `acceptTerms_${index + 1}`,
                      className: this.props.inputClassName,
                      checked: accepted,
                      disabled,
                      onChange: this.onConsentChange.bind(this, index)
                    }}
                    inputValid={accepted}
                    messageText={Strings.consentErrorMessage}
                    messageClassName="alert alert-danger consent-error"
                    required
                    touched={this.props.touched}
                  />
                  <HelpModalLink 
                    className="help-button" 
                    documentName={policyHelpName}
                  >
                    <Icon name="circle-info" />&nbsp;
                    {Strings.playerConsentMoreInfoButtonText}
                  </HelpModalLink>
                </div>
              );
            })}
          </div>
        )}
      </PolicyScrollBox>
    );
  }

  onConsentChange(index, e) {
    const { checked } = e.target;
    const { consents } = this.state;
    consents[index] = checked;

    this.setState({
      consents
    });

    const isAccepted = consents.filter(consent => !consent).length === 0;

    this.props.onConsentChange(isAccepted);
  }
}

PlayerPrivacyConsents.propTypes = {

  onConsentChange: PropTypes.func
};

export default PlayerPrivacyConsents;
