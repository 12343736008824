import Markdown from 'markdown-it';

const URL_SCHEME_PATTERN = /^https?:\/\//;

const parseMarkdown = (text, options = {}) => {
  const md = new Markdown({ html: true, ...options });
  return md.render(text);
};

const parseMarkdownWithRootPath = (text, rootPath, options = {}) => {
  const md = new Markdown({ html: true, ...options });
  md.normalizeLink = (url) => ((URL_SCHEME_PATTERN.test(url)) ? url : `${rootPath}/${url}`);
  return md.render(text);
};

export {
  parseMarkdown,
  parseMarkdownWithRootPath
};
