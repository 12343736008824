import React from 'react';
import PropTypes from 'prop-types';
import BaselineSection from './baseline-section';
import Fraction from '../../fraction';
import Strings from './lang';

const numberScoreMax = 4;
const monthsScoreMax = 1;

const BaselineConcentration = ({
  data = {}
}) => {
  const { 
    score = 0,
    months_score = 0,
    notes = '',
    not_done = false,
    months_duration = null
  } = data || {};
  const totalScore = parseInt(score, 10) + parseInt(months_score, 10);

  return (
    <BaselineSection
      className="baseline-concentration"
      title={Strings.concentrationTitle}
      notes={notes}
      sectionCompleted={Object.keys(data || {}).length > 0 && !not_done}
    >
      <table className="table">
        <thead>
          <tr>
            <th className="no-action">{Strings.numbersScoreLabelText}</th>
            <th className="no-action">{Strings.monthsScoreLabelText}</th>
            <th className="no-action"><strong>{Strings.totalLabelText}</strong></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><Fraction numerator={score} denominator={numberScoreMax} /></td>
            <td>
              <Fraction numerator={months_score} denominator={monthsScoreMax} />
              {months_score > 0 && months_duration !== null && (
                <span style={{ marginLeft: 5 }}>
                  ({((months_duration ?? 0) / 1000).toFixed(2)}s)
                </span>
              )}
            </td>
            <td className="total"><Fraction numerator={totalScore} denominator={monthsScoreMax + numberScoreMax} /></td>
          </tr>
        </tbody>
      </table>
    </BaselineSection>
  );
};

BaselineConcentration.propTypes = {
  data: PropTypes.object.isRequired
};

export default BaselineConcentration;
