/*
* Converts address keys from the format used by the API 
* to the format used by the AddressFormInputs component.
* Ensures that all values are defaulted with empty strings.
*/
const sanitizeAddress = (address = {}) => ({
  street1: address.street_1 || '',
  street2: address.street_2 || '',
  city: address.city || '',
  state: address.state || '',
  postalCode: address.postal_code || '',
  country: address.country || ''
});

export default sanitizeAddress;
