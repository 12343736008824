const FR = {
  title: 'Remarques',
  notesLabelText: 'Remarques',
  notesErrorText: 'Veuillez écrire vos remarques',
  closeButtonTitle: 'Proche',
  cancelButtonTitle: 'Annuler',
  submitButtonTitle: 'Sauvegarder'
};
 
export default FR;
