const FR = {
  injuryCharacteristicsHeader: 'Caractéristiques de la blessure',
  injuryDescriptionLabelText: 'Description de la blessure',
  injuryDescriptionPlaceHolderText: 'Décrivez le déroulement de la blessure',
  directHitHeadLabelText: 'Coup direct à la tête',
  yesLabel: 'Oui',
  noLabel: 'Non',
  unknownLabel: 'Inconnu',
  unsureLabel: 'Incertain',
  locationOfImpactLabelText: 'Emplacement du coup',
  loiBodyLabelText: 'Corps',
  loiFrontLabel: 'Avant',
  loiBackLabel: 'Arrière',
  loiRightSideLabel: 'Côté droit',
  loiLeftSideLabel: 'Côté gauche',
  loiHeadLabelText: 'Tête',
  loiRightFrontalLabel: 'Frontal droit',
  loiLeftFrontalLabel: 'Frontal gauche',
  loiRightTemporalLabel: 'Temporal droit',
  loiLeftTemporalLabel: 'Temporal gauche',
  loiRightParietalLabel: 'Pariétal droit',
  loiLeftParietalLabel: 'Pariétal gauche',
  loiRightOccipitalLabel: 'Occipital droit',
  loiLeftOccipitalLabel: 'Occipital gauche',
  loiCrownLabel: 'Couronne',
  lossOfConsciousnessLabelText: 'Perte de conscience',
  timeInMinutesLabelText: 'Durée (en minutes)',
  notesLabel: 'Remarques',
  seizuresLabelText: 'Attaques',
  causeOfInjuryLabelText: 'Cause de la blessure',
  causeSportLabel: 'Sport',
  causeMVALabel: 'MVA',
  causeFallLabel: 'Chute',
  causeAssaultLabel: 'Coup',
  causeOtherLabel: 'Autre',
  otherSportLabel: 'Autre sport',
  otherCauseOfInjuryLabel: 'Autre cause de blessure',
  sportHockey: 'Hockey',
  sportSoccer: 'Soccer',
  sportFootball: 'Football',
  defaultSport: 'Choisir une option',
  sportSelectLabel: 'Choisir un sport',
  amnesiaOfEventLabel: 'Amnésie de l’événement',
  noneLabel: 'Aucun',
  retrogradeAmnesiaLabel: 'Amnésie avant la blessure (rétrograde)',
  anterogradeAmnesiaLabel: 'Amnésie après la blessure (antérograde)',
  retrogradeLabel: 'Rétrograde',
  anterogradeLabel: 'Antérograde',
  timelineLabelText: 'Délai : Est-ce que plusieurs commotions cérébrales sont survenues à l’intérieur d’un même court délai ?',
  initialSymptomsLabel: 'Symptômes initiaux au moment de la blessure',
  noValueSelectedErrorMessage: 'Veuillez choisir une valeur',
  descriptionErrorMessage: 'Veuillez décrire la blessure',
  invalidNumberErrorMessage: 'Veuillez écrire un nombre valide',
  requiredTextError: 'Ce champ est requis'
};
 
export default FR;
