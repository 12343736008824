import { jsonApiUpdate, jsonApiRead } from '@/lib/requests/jsonapi';
import { updateNotification, updateNotifications } from '../actions/notifications';
import { updateUser } from '../actions/users';

const updateNotificationAsync = (userId, notificationId, attributes) => {
  const options = { path: `users/${userId}/notifications/${notificationId}` };
  return (dispatch, getState) => {
    return jsonApiUpdate({ type: 'notification', id: notificationId, attributes }, options).then(results => {
      const { users = {} } = getState();
      const user = users[userId];
      if (user) {
        user.unread_notification_count -= user.unread_notification_count > 0 ? 1 : 0;
        dispatch(updateUser(user));
      }
      dispatch(updateNotification(userId, results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const getNotificationsAsync = userId => {
  const options = { path: `users/${userId}/notifications` };
  return (dispatch, getState) => {
    return jsonApiRead({ type: 'notification' }, options).then(results => {
      const { users = {} } = getState();
      const user = users[userId];
      if (user) {
        user.unread_notification_count = results.length;
        dispatch(updateUser(user));
      }

      dispatch(updateNotifications(userId, results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

export {
  updateNotificationAsync,
  getNotificationsAsync
};
