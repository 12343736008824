import React, { useCallback, useMemo, useState } from 'react';
import { If, Then, Else } from 'react-if';
import DanaRTDifferenceChart from './rt-diff-chart';
import DanaTestLineChart from './test-line-chart';
import TestSelector from './test-selector';
import MetricSelector from './metric-selector';
import DanaBatteryDetailsModal from './battery-modal';
import { getYAxisDescription } from './utils';
import {
  getTestMetrics,
  Metric,
  PSYCH,
  PSYCH_METRICS,
  COGNITIVE_METRICS,
  COGNITIVE
} from '../helpers';

const getSelectedTests = (tests, type) => {
  if (type === 'cognitive') {
    return COGNITIVE.reduce((acc, key) => [...acc, ...(tests[key] || [])], []);
  }

  return tests[type];
};

const DanaTestChart = ({
  tests = {},
  testType,
  userId,
  baseline = {},
  onTestTypeChange = () => { }
}) => {
  const [selected, setSelected] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [metric, setMetric] = useState(Metric.CognitiveEfficiency);
  const selectedTests = useMemo(() => getSelectedTests(tests, testType), [tests, testType]);

  const onSelectTest = useCallback((key) => {
    onTestTypeChange(key);
    if (PSYCH.includes(key)) {
      setMetric(PSYCH_METRICS[0]);
    } else if (!COGNITIVE_METRICS.includes(metric)) {
      setMetric(COGNITIVE_METRICS[1]);
    }
  }, [metric, onTestTypeChange]);

  const handleDotClick = useCallback((_, item) => {
    const { payload } = item || {};
    const { dana_battery_id } = payload || {};

    if (dana_battery_id) {
      setSelected(dana_battery_id);
      setModalOpen(true);
    }
  }, []);

  return (
    <div style={{ marginBottom: 20 }}>
      <div className="dana-filters-row">
        <TestSelector
          value={testType}
          onSelect={onSelectTest}
        />
        <MetricSelector
          value={metric}
          metrics={getTestMetrics(testType)}
          onSelect={setMetric}
        />
      </div>
      <div className="text-muted text-center">
        <small>
          <i>{getYAxisDescription(testType)}</i>
        </small>
      </div>
      <If condition={metric === Metric.ReactionTimeDiff}>
        <Then>
          <DanaRTDifferenceChart
            series={testType}
            baseline={baseline[testType]}
            tests={selectedTests}
            onDotClick={handleDotClick}
          />
        </Then>
        <Else>
          <DanaTestLineChart
            metric={metric}
            series={testType}
            tests={selectedTests}
            baseline={baseline[testType]}
            onDotClick={handleDotClick}
          />
        </Else>
      </If>
      <DanaBatteryDetailsModal
        isOpen={modalOpen}
        batteryId={selected}
        userId={userId}
        onClose={() => setModalOpen(false)}
      />
    </div>
  );
};

export default DanaTestChart;
