import Strings from './lang';

const FieldHockeyPositions = [
  { key: 'fullback', name: Strings.fieldhockey_fullback },
  { key: 'halfback', name: Strings.fieldhockey_halfback },
  { key: 'sweeper', name: Strings.fieldhockey_sweeper },
  { key: 'goalie', name: Strings.fieldhockey_goalie },
  { key: 'midfield', name: Strings.fieldhockey_midfield }
];

export default FieldHockeyPositions;
