import Strings from './lang';

const AustralianFootballPositions = [
  { key: 'leftbackpocket', name: Strings.aussierules_leftbackpocket },
  { key: 'fullback', name: Strings.aussierules_fullback },
  { key: 'rightbackpocket', name: Strings.aussierules_rightbackpocket },
  { key: 'lefthalfback', name: Strings.aussierules_lefthalfback },
  { key: 'centerhalfback', name: Strings.aussierules_centerhalfback },
  { key: 'righthalfback', name: Strings.aussierules_righthalfback },
  { key: 'leftwing', name: Strings.aussierules_leftwing },
  { key: 'center', name: Strings.aussierules_center },
  { key: 'rightwing', name: Strings.aussierules_rightwing },
  { key: 'lefthalfforward', name: Strings.aussierules_lefthalfforward },
  { key: 'centerhalfforward', name: Strings.aussierules_centerhalfforward },
  { key: 'righthalfforward', name: Strings.aussierules_righthalfforward },
  { key: 'rightforwardpocket', name: Strings.aussierules_rightforwardpocket },
  { key: 'fullforward', name: Strings.aussierules_fullforward },
  { key: 'leftfowardpocket', name: Strings.aussierules_leftfowardpocket },
  { key: 'ruckman', name: Strings.aussierules_ruckman },
  { key: 'ruckrover', name: Strings.aussierules_ruckrover },
  { key: 'rover', name: Strings.aussierules_rover },
];

export default AustralianFootballPositions;
