const BagTagPaths = {
  index: {
    route: 'bagtags',
    link: '/bagtags'
  },
  batch: {
    route: ':batchId',
    link: '/bagtags/:batchId'
  }
};

export default BagTagPaths;
