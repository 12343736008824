import React, { useState, useCallback, useRef } from 'react';
import classnames from 'classnames';
import { useClickOutside } from '@/hooks';
import Icon from '../../icon';

const languages = {
  en: 'English',
  fr: 'Français'
};

const LanguageSelector = ({
  value = 'en',
  onSelect = () => {}
}) => {
  const [open, setOpen] = useState(false);
  const ref = useRef();
  const handleClickOutside = useCallback(() => {
    setOpen(false);
  }, []);

  const handleSelect = useCallback((newValue) => {
    if (value !== newValue) {
      onSelect(newValue);
    }
    
    setOpen(false);
  }, [value, onSelect]);

  const handleTriggerClick = useCallback(e => {
    e.preventDefault();
    e.stopPropagation();
    setOpen(prev => !prev);
  }, []);

  useClickOutside(ref, handleClickOutside, open);

  return (
    <div className="language-selector">
      <button
        type="button"
        className="language-trigger"
        onClick={handleTriggerClick}
      >
        <Icon name="language" />
        <span>{languages[value]}</span>
        <Icon name="chevron-down" />
      </button>
      {open && (
        <ul ref={ref} className="language-menu">
          {Object.keys(languages).map(lang => (
            <li 
              key={lang}
              className={classnames({ selected: value === lang })}
              onClick={() => handleSelect(lang)}
            >
              {languages[lang]}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default LanguageSelector;
