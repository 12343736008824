import { jsonApiRead } from '@/lib/requests/jsonapi';
import { updateUserBaselineTests } from '../actions/user-baseline-tests';

const getUserBaselineTestsAsync = (userId) => {
  const options = { path: `users/${userId}/baselines` };
  return (dispatch) => {
    return jsonApiRead({ type: 'baselines' }, options).then(results => {
      dispatch(updateUserBaselineTests(userId, results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

export {
  getUserBaselineTestsAsync
};
