import { UPDATE_CLINIC_PATIENTS } from '../actions/clinic-patients';

const updateClinicPatientsReducer = (state = {}, action = {}) => {
  const { clinicId = 0, users = [] } = action;
  return (clinicId) ? { ...state, [clinicId]: users } : state;
};

const clinicPatients = (state = {}, action = {}) => {
  switch (action.type) {
    case UPDATE_CLINIC_PATIENTS:
      return updateClinicPatientsReducer(state, action);
    default:
      return state;
  }
};

export default clinicPatients;
