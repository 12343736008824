import { createUserLocalizedStrings } from '../../localization';
import EN from './en';
import FR from './fr';

const Strings = createUserLocalizedStrings({
  en: EN,
  fr: FR
});

export default Strings;
