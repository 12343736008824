import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { createClinicAsync } from '@/redux/thunks/clinics';
import { createBulkCreditsAsync } from '@/redux/thunks/clinic-invoices';
import replaceLinkParams from '@/utilities/replace-link-params';
import { DashboardPaths, ClinicPaths } from '@/paths';
import { Page } from '../page';
import { ErrorBanner } from '../errors';
import Activity from '../activity';
import Icon from '../icon';
import ClinicRegistrationForm from './clinic-registration-form';
import { AddBulkCreditsForm } from '../bulk-credits';
import Strings from './lang';

const steps = [
  'register',
  'add-credits',
  'finish'
];

const RegisterStep = (props) => {
  return (
    <div className="col-md-6 col-md-offset-3">
      <h1 className="display">{Strings.title}</h1>
      <ErrorBanner error={props.error} />
      <Activity active={props.activity}>
        <ClinicRegistrationForm onSubmit={props.onSubmit} />
      </Activity>
    </div>
  );
};

const AddCreditsStep = ({
  clinic,
  error,
  activity,
  onSubmit,
  onSkipStep
}) => {
  return (
    <div className="col-md-4 col-md-offset-4">
      <h1 className="display">{Strings.addCreditsStepTitle}</h1>
      <p className="alert alert-success">
        {Strings.formatString(Strings.successMessageText, clinic.name)}
      </p>
      <p>
        {Strings.formatString(
          Strings.addCreditsStepMessage,
          clinic.name
        )}
      </p>
      <ErrorBanner error={error} />
      <Activity active={activity}>
        <div>
          <AddBulkCreditsForm 
            clinic={clinic}
            onSubmit={onSubmit} 
          />
          <hr />
          <button type="button" className="btn btn-default btn-wide" onClick={onSkipStep}>
            {Strings.skipStepButtonText} &gt;&gt;
          </button>
        </div>
      </Activity>
    </div>
  );
};

const FinishedStep = (props) => {
  return (
    <div className="col-md-6 col-md-offset-3">
      <h1 className="display text-center">
        <Icon name="circle-check" className="success" />&nbsp;&nbsp;
        {Strings.registrationCompleteTitle}
      </h1>
      <Link 
        to={replaceLinkParams(ClinicPaths.profile.link, {
          clinicId: props.clinic.id
        })} 
        className="btn btn-primary btn-wide"
      >
        {Strings.formatString(Strings.clinicProfielButtonText, props.clinic.name)}
      </Link>
      <hr />
      <ul className="list-unstyled">
        <li>
          <a 
            href="#" 
            onClick={props.onAddClinic}
          >
            {Strings.addClinicLinkText}
          </a>
        </li>
        <li>
          <Link to={DashboardPaths.index.link}>
            {Strings.homeLinkText}
          </Link>
        </li>
      </ul>
    </div>
  );
};

const ClinicRegistration = () => {
  const dispatch = useDispatch();
  const [clinic, setClinic] = useState({});
  const [step, setStep] = useState(steps[0]);
  const [activity, setActivity] = useState(false);
  const [error, setError] = useState(null);

  const onAddAnotherClinic = useCallback(() => {
    setClinic({});
    setStep(steps[0]);
    setActivity(false);
    setError(null);
  }, []);

  const onRegisterClinic = useCallback((attributes) => {
    setActivity(true);
    setError(null);

    dispatch(createClinicAsync(attributes)).then(clinic => {
      setClinic(clinic);
      setActivity(false);
      setStep(steps[1]);
    }).catch(error => {
      setError(error.message);
      setActivity(false);
    });
  }, [dispatch]);

  const onSkipAddCredits = useCallback(() => {
    setStep(steps[2]);
    setError(null);
  }, []);

  const onAddCredits = useCallback((attributes) => {
    setActivity(true);
    setError(null);

    dispatch(createBulkCreditsAsync(clinic.id, attributes)).then(() => {
      setActivity(false);
      setStep(steps[2]);
    }).catch(error => {
      setError(error.message);
      setActivity(false);
    });
  }, [clinic.id, dispatch]);

  return (
    <Page className="clinic-registration">
      <div className="row">
        {step === steps[0] && (
          <RegisterStep
            error={error}
            activity={activity}
            onSubmit={onRegisterClinic}
          />
        )}
        {step === steps[1] && (
          <AddCreditsStep
            clinic={clinic}
            error={error}
            activity={activity}
            onSkipStep={onSkipAddCredits}
            onSubmit={onAddCredits}
          />
        )}
        {step === steps[2] && (
          <FinishedStep
            clinic={clinic}
            onAddClinic={onAddAnotherClinic}
          />
        )}
      </div>
    </Page>
  );
};

export default ClinicRegistration;
