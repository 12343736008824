const AuthenticationPaths = {
  index: {
    route: 'login',
    link: '/login'
  },
  clinics: {
    route: 'clinics',
    link: '/login/clinics'
  }
};

export default AuthenticationPaths;
