/* eslint-disable no-unused-vars */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
/* eslint-enable no-unused-vars */
import { RadioInputGroup } from '@/forms';
import Strings from './lang';

const OrientationQuestion = ({
  value, 
  questionText, 
  questionKey, 
  answerProps, 
  onUpdate = () => {}, 
  touched = false
}) => {
  const updateCallback = useCallback((value) => {
    onUpdate(questionKey, value);
  }, [onUpdate, questionKey]);

  return (
    <div className="baseline-card-row">
      <RadioInputGroup
        titleLabelProps={{
          className: 'baseline-row-label'
        }}
        titleLabelText={questionText}
        radioValues={answerProps.values}
        radioLabels={answerProps.labels}
        initialValue={value.toString()}
        className="baseline-radio-input"
        onUpdate={updateCallback}
        inputValid={value !== ''}
        touched={touched}
        messageText={Strings.requiredError}
        messageClassName="alert alert-danger"
      />
    </div>
  );
};

OrientationQuestion.propTypes = {
  questionText: PropTypes.string.isRequired,
  onUpdate: PropTypes.func,
  questionKey: PropTypes.string.isRequired,
  answerProps: PropTypes.shape({
    labels: PropTypes.array,
    values: PropTypes.array
  }).isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
};

export default OrientationQuestion;
