import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { DISPLAY_DATE_FORMAT } from '@/utilities/dates/formats';
import { age } from '@/utilities/dates';
import GenderStrings from '@/utilities/gender';
import Strings from './lang';

const PatientInfoHeader = ({
  user = {}
}) => {
  const { person = {} } = user;

  return (
    <section className="test-header">

      <div className="row">
        <div className="col-md-12">
          <h2>{person.first_name || ''} {person.last_name || ''}</h2>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <div className="test-header-row">
            <label>{Strings.accountLabelText}:</label>
            <span>{user.account}</span>
          </div>
        </div>
        <div className="col-md-6">
          <div className="test-header-row">
            <label>{Strings.bagtagLabelText}:</label>
            <span>{user.bag_tag}</span>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <div className="test-header-row">
            <label>{Strings.birthdateLabelText}:</label>
            <span>{dayjs(person.birthday).format(DISPLAY_DATE_FORMAT)}</span>
          </div>
        </div>
        <div className="col-md-6">
          <div className="test-header-row">
            <label>{Strings.ageLabelText}:</label>
            <span>{age(person.birthday)}</span>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <div className="test-header-row">
            <label>{Strings.genderLabelText}:</label>
            <span>{GenderStrings[person.gender]}</span>
          </div>
        </div>
      </div>
    </section>
  );
};

PatientInfoHeader.propTypes = {
  user: PropTypes.object
};

export default PatientInfoHeader;
