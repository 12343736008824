import React, { useCallback, useState, useMemo } from 'react';
import SelectAmountModal from './select-amount-modal';
import { Money } from '@/utilities/currency';
import Strings from './lang';
import Icon from '../icon';

const MIN_AMOUNT = 500;

const PaymentAmount = ({
  amount,
  balance,
  currency,
  onUpdateAmount = () => {}
}) => {
  const [selectAmountOpen, setSelectAmountOpen] = useState(false);
  const amountFormatted = useMemo(() => Money.format(amount, currency), [amount, currency]);

  const onChange = useCallback((amount) => {
    onUpdateAmount(amount);
    setSelectAmountOpen(false);
  }, [onUpdateAmount]);

  return (
    <div className="payment-amount-label">
      <span>{Strings.amountLabel}</span>
      <div className="payment-amount-value">
        <span>{amountFormatted} {currency}</span>
        {balance > MIN_AMOUNT && (
          <>
            <button type="button" className="edit-button" onClick={() => setSelectAmountOpen(true)}>
              <Icon name="pen-to-square" />
              {Strings.editAmountLabel}
            </button>
            <SelectAmountModal 
              isOpen={selectAmountOpen}
              balance={balance}
              min={MIN_AMOUNT}
              amount={amount}
              onClose={() => setSelectAmountOpen(false)}
              onUpdateAmount={onChange}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default PaymentAmount;
