import React, { useCallback, useState } from 'react';
import Icon from '../../../../icon';
import FailureInputItems from '../failure-input-items';
import { FormComponent } from '@/forms';
import Strings from '../lang';

const TrialComplete = ({
  isStopped = false,
  completed = 0,
  errors = 0,
  duration = 0,
  totalTrials = 0,
  responseRate,
  onReset,
  onDone
}) => {
  const [failureReasons, setFailureReasons] = useState([]);

  const onUpdateFailureReasons = useCallback((key, checked) => {
    setFailureReasons(prev => {
      const newKeys = [...prev];
      const index = newKeys.findIndex(p => p === key);

      if (checked && index < 0) {
        return [...newKeys, key];
      }
      
      if (!checked && index >= 0) {
        newKeys.splice(index, 1);
        return newKeys;
      }

      return prev;
    });
  }, []);

  const handleDone = useCallback(() => {
    onDone({
      failed: failureReasons.length > 0,
      failed_reasons: failureReasons
    });
  }, [failureReasons, onDone]);

  return (
    <div className="trial-complete-container">
      <div className="trial-complete">
        <div className="completion-title">
          {isStopped ? Strings.trialStoppedTitle : Strings.trialCompleteTitle}
        </div>
        <div className="reset-container">
          <button type="button" className="btn btn-warning btn-sm" onClick={onReset}>
            <Icon name="repeat" />&nbsp;&nbsp;
            {Strings.resetTrialButton}
          </button>
        </div>
        <div className="trail-results">
          <div className="results-row">
            <div className="result-label">{Strings.tableColumnAttempted}</div>
            <div className="result-item">{completed}/{totalTrials}</div>
          </div>
          <div className="results-row">
            <div className="result-label">{Strings.tableColumnErrors}</div>
            <div className="result-item">{errors}</div>
          </div>
          <div className="results-row">
            <div className="result-label">{Strings.durationLabel}</div>
            <div className="result-item">{(duration / 1000).toFixed(2)}s</div>
          </div>
          <div className="results-row">
            <div className="result-label">{Strings.responseRateLabel}</div>
            <div className="result-item">{responseRate?.toFixed(2)}</div>
          </div>
        </div>
        <FormComponent>
          <FailureInputItems
            reasons={failureReasons}
            onReasonsChange={onUpdateFailureReasons}
          />
        </FormComponent>
        <div className="done-button">
          <button
            type="button"
            className="btn btn-primary btn-block"
            onClick={handleDone}
          >
            {Strings.doneButton}
          </button>
        </div>
      </div>
    </div>
  );
};

export default TrialComplete;
