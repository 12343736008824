import React, { useState, useMemo, useCallback } from 'react';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import AccessControl from '../access-control';
import LastEditedView from '../last-edited-view';
import SoapNoteTestBadges from './soap-note-test-badges';
import Dropdown, { MenuItem } from '../dropdown';
import { UserPaths } from '@/paths';
import { DISPLAY_DATE_FORMAT, INPUT_DATE_FORMAT } from '@/utilities/dates/formats';
import replaceLinkParams from '@/utilities/replace-link-params';
import { RoleDescriptions } from '@/utilities/user-roles';
import Strings from './lang';
import Icon from '../icon';

const CreatedByView = ({ creator = {} }) => {
  const { person } = creator;
  const { first_name, last_name } = person || {};
  const name = person ? `${first_name} ${last_name}` : Strings.unknownNameLabel;
  return (
    <div className="no-print">
      <small><i>{Strings.formatString(Strings.createdByLabel, name)}</i></small>
    </div>
  );
};

const ArchivedByView = ({ soap }) => {
  const { archived_by, archived_at } = soap || {};
  return (
    <div className="no-print">
      <small>
        <i>
          {Strings.formatString(
            Strings.archivedByMessage, 
            archived_by,
            dayjs(archived_at, INPUT_DATE_FORMAT).format(DISPLAY_DATE_FORMAT)
          )}
        </i>
      </small>
    </div>
  );
};

const SoapHeaderDetails = ({ soap = {} }) => {
  return soap.archived_at ? (
    <ArchivedByView soap={soap} />
  ) : (
    <>
      <CreatedByView creator={soap.clinic_user} />
      <LastEditedView inline item={soap} showPrint={false} />
    </>
  );
};

const ContinueLink = ({ link }) => {
  return (
    <AccessControl roles={[RoleDescriptions.Clinician, RoleDescriptions.ClinicStaff]}>
      <Link 
        to={link}
        className="edit-button no-print" 
        onClick={(e) => {
          /* prevent the event from bubbling to the panel heading and opening the panel */
          e.stopPropagation();
        }}
      >
        {Strings.continueNoteText}
      </Link>
    </AccessControl>
  );
};

const ArchiveButton = ({ onClick }) => {
  return (
    <AccessControl roles={[RoleDescriptions.Clinician]}>
      <>
        <div className="action-separator" />
        <button 
          type="button"
          className="edit-button danger no-print" 
          onClick={(e) => {
            /* prevent the event from bubbling to the panel heading and opening the panel */
            e.stopPropagation();
            onClick();
          }}
        >
          <Icon name="box-archive" />
          {Strings.archiveText}
        </button>
      </>
    </AccessControl>
  );
};

const UnarchiveButton = ({ onClick }) => {
  return (
    <AccessControl roles={[RoleDescriptions.Clinician]}>
      <button 
        type="button" 
        className="edit-button warning no-print" 
        onClick={onClick}
      >
        <Icon name="undo" />
        {Strings.unarchiveText}
      </button>
    </AccessControl>
  );
};

const SoapNoteActions = ({ editLink, onArchive }) => {
  const [open, setOpen] = useState(false);
  return (
    <AccessControl roles={[RoleDescriptions.Clinician]}>
      <Dropdown
        portal
        isOpen={open}
        placement="bottom-start"
        onClose={() => setOpen(false)}
        trigger={(
          <button 
            type="button" 
            className={classnames(
              'soap-note-action-button', 
              'no-print', 
              { selected: open }
            )}
            onClick={(e) => {
              // prevent soap note header from opening
              e.preventDefault();
              setOpen(!open);
            }}
          >
            <Icon name="ellipsis-h" />
          </button>
        )}
      >
        <MenuItem
          link={editLink} 
          type="primary"
          icon="pen-to-square" 
        >
          {Strings.editText}
        </MenuItem>
        <MenuItem 
          type="danger"
          icon="box-archive"
          onSelect={onArchive}
        >
          {Strings.archiveText}
        </MenuItem>
      </Dropdown>
    </AccessControl>
  );
};

const SoapPanelHeader = ({
  soap = {}, 
  actionable = true,
  itemNumber, 
  userId,
  injuryId,
  currentUser,
  onArchive,
  onUnarchive
}) => {
  const editLink = useMemo(() => replaceLinkParams(UserPaths.injuries.soapNote.link, {
    userId, 
    injuryId,
    soapId: soap.id
  }), [injuryId, soap.id, userId]);
  const isCurrentUser = String(currentUser.id) === (soap.clinic_user?.id || 0);

  const handleArchive = useCallback(() => {
    if (onArchive) onArchive(soap);
  }, [onArchive, soap]);

  const handleUnarchive = useCallback((e) => {
    e.stopPropagation();
    if (onUnarchive) onUnarchive(soap);
  }, [onUnarchive, soap]);

  return (
    <div style={{ width: '100%' }}>
      <div className="soap-panel-header-container">
        <div className="soap-panel-header-row">
          {isCurrentUser && (
            <div className="soap-creator-indicator" title={Strings.noteCreatorIndicatorText}>
              <Icon name="user-doctor" className="primary" />
            </div>
          )}
          <h2 className="soap-panel-header">
            {Strings.formatString(
              Strings.soapPanelHeaderText,
              itemNumber,
              dayjs(soap.created_at).format(DISPLAY_DATE_FORMAT)
            )}&nbsp;&nbsp;
            
            {soap.closed === false && <span className="label label-danger">{Strings.incompleteText}</span>}
          </h2>
          <SoapNoteTestBadges soap={soap} />
        </div>
        {actionable && !soap.archived_at && (
          !soap.closed ? (
            <>
              <ContinueLink link={editLink} />
              <ArchiveButton onClick={handleArchive} />
            </>
          ) : (
            <SoapNoteActions 
              editLink={editLink}
              onArchive={handleArchive}
            />
          )
        )}
        {actionable && !!soap.archived_at && onUnarchive && (
          <UnarchiveButton onClick={handleUnarchive} />
        )}
      </div>
      <SoapHeaderDetails soap={soap} />
    </div>
  );
};

export default SoapPanelHeader;
