import React, { useMemo } from 'react';
import CognitiveTestDetails from './cognitive-details';
import PsychTestDetails from './psych-details';
import { COGNITIVE } from '../helpers';

const splitTests = (tests) => {
  return tests.reduce((acc, test) => {
    const isCog = COGNITIVE.includes(test.test);
    return {
      cog: isCog ? [...acc.cog, test] : acc.cog,
      psych: !isCog ? [...acc.psych, test] : acc.psych,
    };
  }, { cog: [], psych: [] });
};

const DanaBatteryDetails = ({ tests = [] }) => {
  const { cog, psych } = useMemo(() => splitTests(tests), [tests]);

  return (
    <div>
      <CognitiveTestDetails tests={cog} />
      <PsychTestDetails tests={psych} />
    </div>
  );
};

export default DanaBatteryDetails;
