import React, { useEffect } from 'react';

const insertTagManagerScript = () => {
  if (!process.env.CCMI_GTM_ID) return;

  const script = document.createElement('script');
  script.async = true;
  script.setAttribute('data-ga', 'true');
  script.innerHTML = `
    (function(w,d,s,l,i){w[l] = w[l] || [];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.setAttribute("data-ga", "true");
      j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${process.env.CCMI_GTM_ID}');
  `;

  document.head.appendChild(script);
};

const removeTagManager = () => {
  const scripts = document.head.querySelectorAll('script[data-ga]');
  scripts.forEach(script => {
    document.head.removeChild(script);
  });
};

export const withGoogleTagManager = (Component) => {
  const Wrapper = (props) => {
    useEffect(() => {
      insertTagManagerScript();

      return () => {
        removeTagManager();
      };
    }, []);

    return (
      <Component {...props} gtmAvailable={!!process.env.CCMI_GTM_ID} />
    );
  };

  return Wrapper;
};
