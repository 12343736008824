import React, {
  useCallback,
  useMemo
} from 'react';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { DISPLAY_DATE_FORMAT } from '@/utilities/dates/formats';
import LastEditedView from '../../last-edited-view';
import BaselineActions from './baseline-action';
import AccessControl from '../../access-control';
import { isBaselineContinuable } from '../baseline-utilities';
import {
  makeRoleDescription,
  RoleDescriptions,
  RoleNames,
  RoleResourceTypes
} from '@/utilities/user-roles';
import checkExpiry from '@/utilities/check-expiry';
import Icon from '../../icon';
import Strings from './lang';

const makeBaselineActionRoles = (clinicId) => {
  return [
    RoleDescriptions.Clinician,
    makeRoleDescription(RoleNames.BaselineTester, RoleResourceTypes.Clinic, clinicId),
    makeRoleDescription(RoleNames.ClinicStaff, RoleResourceTypes.Clinic, clinicId),
    makeRoleDescription(RoleNames.ClinicFrontDesk, RoleResourceTypes.Clinic, clinicId)
  ];
};

const StatusLabel = ({
  createdAt,
  complete,
  continuable
}) => {
  const isExpired = useMemo(() => !checkExpiry(createdAt, 1, 'year'), [createdAt]);

  if (isExpired) {
    return (
      <div className="label label-danger">
        {Strings.expiredLabel}
      </div>
    );
  }

  if (continuable) {
    return (
      <div className="label label-danger">
        <Icon name="xmark" />&nbsp;&nbsp;{Strings.incompleteLabel}
      </div>
    );
  }

  if (complete) {
    return (
      <div className="label label-success">
        <Icon name="check" />&nbsp;&nbsp;{Strings.completedLabel}
      </div>
    );
  }

  return null;
};

const BaselineListItem = ({
  number,
  baseline,
  link,
  onArchive,
  onUnarchive
}) => {
  const {
    archived_at,
    archived_by,
    archived_reason,
    clinic,
    clinic_user
  } = baseline;

  const continuable = useMemo(() => isBaselineContinuable(baseline), [baseline]);

  const handleArchive = useCallback((reason) => {
    onArchive(baseline.id, reason);
  }, [baseline.id, onArchive]);

  const handleUnarchive = useCallback(() => {
    onUnarchive(baseline.id);
  }, [baseline.id, onUnarchive]);

  return (
    <div className="baseline-list-item">
      <div className="baseline-details">
        <div className="baseline-header">
          <h3>
            <Link
              to={link}
            >
              {Strings.formatString(
                Strings.baselineListItemTitle,
                number,
                dayjs(baseline.created_at).format(DISPLAY_DATE_FORMAT)
              )}
            </Link>
          </h3>
          {!archived_at && (
            <StatusLabel
              complete={baseline.complete}
              continuable={continuable}
              createdAt={baseline.createdAt}
            />
          )}
          {!!archived_at && (
            <div className="label label-danger">
              <Icon name="box-archive" />&nbsp;&nbsp;
              {Strings.archivedLabel}
            </div>
          )}
        </div>
        <AccessControl roles={makeBaselineActionRoles(baseline.clinic?.id)}>
          <BaselineActions
            isContinuable={continuable}
            baselineId={baseline.id}
            isArchived={!!archived_at}
            userId={baseline.user_id}
            onArchive={handleArchive}
            onUnarchive={handleUnarchive}
          />
        </AccessControl>
      </div>
      {!!clinic?.id && !!clinic_user?.person && (
        <div className="performed-by">
          {Strings.formatString(
            Strings.performedByMessage,
            clinic?.name,
            clinic_user?.person?.first_name,
            clinic_user?.person?.last_name
          )}
        </div>
      )}
      {!archived_at && (
        <LastEditedView inline item={baseline} />
      )}
      {!!archived_at && (
        <div className="archived-reason">
          <div>
            <strong>
              {Strings.formatString(
                Strings.archivedByMessage, 
                dayjs(archived_at).format(DISPLAY_DATE_FORMAT),
                archived_by
              )}:
            </strong>
          </div>
          <div className="reason">{archived_reason}</div>
        </div>
      )}
    </div>
  );
};

export default BaselineListItem;
