const FR = {
  title: 'Antécédents de test de référence',
  emptyMessageText: 'Ce patient n’a jamais subi de test de référence.',
  baselineListItemTitle: 'Test de référence {0} - {1}',
  performedByMessage: 'Effectué à {0} par {1} {2}',
  archivedByMessage: 'Supprimé le {0} par {1} pour la raison suivante',
  archivedTestsTitle: 'Tests de référence supprimés',
  archivedListEmptyMessage: 'Aucun test de référence n\'a été supprimé',
  unarchivedSuccessMessage: 'Le test de référence a été restauré avec succès!',
  continueTest: 'Continuer',
  editBaseline: 'Éditer',
  archiveBaselineAction: 'Effacer',
  unarchiveBaselineAction: 'Restaurer',
  expiredLabel: 'Expiré',
  incompleteLabel: 'Incomplet',
  completedLabel: 'Complété',
  archivedLabel: 'Supprimé',
  archivedSuccessMessage: 'Le test de référence a été supprimé avec succès!',
  archivedBaselinesText: 'Essais supprimés'
};
 
export default FR;
