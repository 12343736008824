import React, { Component } from 'react';
import classnames from 'classnames';
import { 
  FormComponent, 
  SelectInputGroup, 
  RadioInputGroup, 
  FormInputGroup 
} from '@/forms';
import BuffaloTestV2FormHeader from './buffalo-test-form-header';
import BuffaloTestV2FormRow from './buffalo-test-form-row';
import Icon from '../../icon';
import { 
  getBuffaloTestV2State,
  MINUTE_INTERVAL_OFFSET,
  defaultV2Results
} from './buffalo-data';
import { 
  MIN_HEART_RATE, 
  MAX_HEART_RATE, 
  heartRateValidator, 
  timeValidator 
} from '../helpers';
import { getBorgScaleOptions, getSymptomScoreOptions, createBuffaloTestTable } from './helpers';
import { BorgScaleHelpModalLink, SymptomScoreHelpModalLink } from './buffalo-test-help-modal-links';
import Strings from '../lang';

const getCompleteRowValue = (intervals) => {
  return intervals.reduceRight((acc, curr, index) => {
    if (curr.heart_rate !== '' || curr.symptoms !== '' || curr.borg_scale !== '') {
      acc.push({
        time: index + MINUTE_INTERVAL_OFFSET,
        heart_rate: curr.heart_rate,
        borg_scale: curr.borg_scale,
        symptoms: curr.symptoms
      });
    }

    return acc;
  }, []);
};

const TimeOptions = ({
  amount,
  offset
}) => (
  <>
    {[...new Array(amount)].map((_, index) => (
      <option key={index} value={index + offset}>{index + offset}</option>
    ))}
  </>
);

class BuffaloTestV2Form extends Component {
  constructor(props) {
    super(props);

    this.onAddNewRow = this.onAddNewRow.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSymptomExacerbationChange = this.onSymptomExacerbationChange.bind(this);
    this.onUpdateRestingHeartRate = this.onUpdateInput.bind(this, 'resting_heart_rate');
    this.onUpdateRestingBorgScale = this.onUpdateInput.bind(this, 'resting_borg_scale');
    this.onUpdateRestingSymptomScore = this.onUpdateInput.bind(this, 'resting_symptom_score');
    this.onUpdateExacerbatedHeartRate = this.onUpdateInput.bind(this, 'exacerbated_symptoms_heart_rate');
    this.onUpdateExacerbatedTime = this.onUpdateInput.bind(this, 'exacerbated_symptoms_time');
    this.onUpdateExacerbatedBorgScale = this.onUpdateInput.bind(this, 'exacerbated_symptoms_borg_scale');
    this.onUpdateExacerbatedSymptoms = this.onUpdateInput.bind(this, 'exacerbated_symptoms_reported');
    this.onUpdateNotes = this.onUpdateInput.bind(this, 'notes');

    this.state = {
      ...getBuffaloTestV2State(props),
      submitted: false,
      flashRow: false
    };
  }

  render() {
    const { buffalo } = this.state;
    const {
      resting_heart_rate,
      resting_borg_scale,
      resting_symptom_score,
      symptom_exacerbation,
      exacerbated_symptoms_heart_rate,
      exacerbated_symptoms_borg_scale,
      exacerbated_symptoms_time,
      exacerbated_symptoms_reported,
      results,
      notes
    } = buffalo;
    const { minute_intervals = [] } = results;
    const preTestValid = this.isPreTestScoresValid;

    return (
      <div>
        <div className="row buffalo-test-instructions-quote">
          <div className="col-md-12">
            <p className="text-muted">{Strings.buffaloV2InfoText}</p>
          </div>
        </div>
        <FormComponent className="buffalo-test-v2-form" onSubmit={this.onSubmit}>
          <div>
            <div 
              className={classnames('pre-test-scores', {
                complete: preTestValid
              })}
            >
              <p className="bold text-center">{Strings.restingInputInfoText}</p>
              <div className="row">
                <div className="col-md-4">
                  <FormInputGroup
                    required
                    className="form-group"
                    labelText={Strings.restingHeartRateLabel}
                    inputType="text"
                    inputProps={{
                      className: 'form-control',
                      value: resting_heart_rate || '',
                      onChange: this.onUpdateRestingHeartRate
                    }}
                    messageClassName="alert alert-danger"
                    messageText={Strings.formatString(
                      Strings.invalidHeartRateMessage, 
                      MIN_HEART_RATE, 
                      MAX_HEART_RATE
                    )}
                    inputValid={heartRateValidator(resting_heart_rate)}
                  />
                </div>
                <div className="col-md-4">
                  <SelectInputGroup
                    required
                    className="form-group"
                    labelText={Strings.restingBorgScaleInputLabel}
                    labelAccessory={<BorgScaleHelpModalLink inverted={!preTestValid} />}
                    inputProps={{
                      className: 'form-control',
                      value: resting_borg_scale
                    }}
                    messageClassName="alert alert-danger"
                    valid={resting_borg_scale !== ''}
                    onUpdate={this.onUpdateRestingBorgScale}
                  >
                    <option value="">--</option>
                    {getBorgScaleOptions()}
                  </SelectInputGroup>
                </div>
                <div className="col-md-4">
                  <SelectInputGroup
                    required
                    className="form-group"
                    labelText={Strings.restingSymptomWellBeingLabel}
                    labelAccessory={<SymptomScoreHelpModalLink inverted={!preTestValid} />}
                    inputProps={{
                      className: 'form-control',
                      value: resting_symptom_score
                    }}
                    messageClassName="alert alert-danger"
                    valid={resting_symptom_score !== ''}
                    onUpdate={this.onUpdateRestingSymptomScore}
                  >
                    <option value="">--</option>
                    {getSymptomScoreOptions()}
                  </SelectInputGroup>
                </div>
              </div>
            </div>

            {preTestValid && (
              <div>
                <div className="table-responsive-md">
                  <table className="table buffalo-table-v2 sticky-header with-nav-bar">
                    <thead>
                      <BuffaloTestV2FormHeader />
                    </thead>
                    <tbody>
                      {this.renderTestRows()}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colSpan={7}>
                          <button type="button" className="edit-button" onClick={this.onAddNewRow}>
                            <Icon name="plus" />
                            {Strings.buffaloTableNewRowButtonText}
                          </button>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
                
                <div className="row symptom-exacerbation">

                  {/* Symptom Exacerbation */}
                  <div className="col-md-4">
                    <RadioInputGroup
                      titleLabelText={
                        <span>{Strings.symptomExacerbationLabel} <span className="required">*</span></span>
                      }
                      radioLabels={[Strings.yesLabel, Strings.noLabel]}
                      radioValues={['true', 'false']}
                      initialValue={String(symptom_exacerbation)}
                      inputValid={this.state.submitted === true ? symptom_exacerbation !== '' : true}
                      messageClassName="alert alert-danger"
                      messageText={Strings.symptomExacerbationErrorMessage}
                      onUpdate={this.onSymptomExacerbationChange}
                      touched={this.state.submitted}
                    />
                  </div>
                  {/* Hidden fields. Only visible when symptom exacerbation is true */}
                  <div className="col-md-12">
                    {String(symptom_exacerbation) === 'true' && (
                      <div className="row symptom-exacerbation-inputs">

                        {/* Symptom Exacerbation Heart Rate */}
                        <div className="col-md-3">
                          <FormInputGroup
                            className="form-group"
                            labelText={Strings.heartRateLabel}
                            inputType="text"
                            inputProps={{
                              className: 'form-control',
                              value: exacerbated_symptoms_heart_rate,
                              onChange: this.onUpdateExacerbatedHeartRate
                            }}
                            required
                            messageClassName="alert alert-danger"
                            messageText={Strings.formatString(
                              Strings.invalidHeartRateMessage, 
                              MIN_HEART_RATE, 
                              MAX_HEART_RATE
                            )}
                            inputValid={heartRateValidator(exacerbated_symptoms_heart_rate)}
                            touched={this.state.submitted}
                          />
                        </div>

                        {/* SymptomExacerbation Time */}
                        <div className="col-md-3">
                          <SelectInputGroup
                            required
                            className="form-group"
                            labelText={Strings.timeLabel}
                            inputProps={{
                              className: 'form-control',
                              value: exacerbated_symptoms_time
                            }}
                            messageClassName="alert alert-danger"
                            valid={exacerbated_symptoms_time !== ''}
                            onUpdate={this.onUpdateExacerbatedTime}
                            touched={this.state.submitted}
                          >
                            <option value="">--</option>
                            <TimeOptions 
                              amount={minute_intervals.length}
                              offset={MINUTE_INTERVAL_OFFSET}
                            />
                          </SelectInputGroup>
                        </div>

                        {/* SymptomExacerbation Borg Scale */}
                        <div className="col-md-3">
                          <SelectInputGroup
                            required
                            className="form-group"
                            labelText={Strings.borgScaleLabel}
                            inputProps={{
                              className: 'form-control',
                              value: exacerbated_symptoms_borg_scale
                            }}
                            messageClassName="alert alert-danger"
                            valid={exacerbated_symptoms_borg_scale !== ''}
                            onUpdate={this.onUpdateExacerbatedBorgScale}
                            touched={this.state.submitted}
                          >
                            <option value="">--</option>
                            {getBorgScaleOptions()}
                          </SelectInputGroup>
                        </div>

                        {/* Symptom Exacerbation symptoms */}
                        <div className="col-md-3">
                          <FormInputGroup
                            required
                            className="form-group"
                            labelText={Strings.symptomsLabel}
                            inputType="text"
                            inputProps={{
                              className: 'form-control',
                              value: exacerbated_symptoms_reported,
                              onChange: this.onUpdateExacerbatedSymptoms
                            }}
                            messageClassName="alert alert-danger"
                            messageText={Strings.exacerbationSymptomsErrorMessage}
                            inputValid={exacerbated_symptoms_reported !== ''}
                            touched={this.state.submitted}
                          />
                        </div>

                      </div>
                    )}
                  </div>
                </div>
                {/* Overall notes */}
                <FormInputGroup
                  required
                  inputValid
                  className="form-group"
                  labelText={Strings.overallNotesLabel}
                  inputType="textarea"
                  inputProps={{
                    rows: 10,
                    className: 'form-control',
                    value: notes,
                    onChange: this.onUpdateNotes
                  }}
                />

                <button type="submit" className="btn btn-primary">{Strings.saveButtonText}</button>
              </div>
            )}
          </div>
        </FormComponent>
      </div>
    );
  }

  get isPreTestScoresValid() {
    const {
      resting_heart_rate,
      resting_borg_scale,
      resting_symptom_score,
    } = this.state.buffalo;

    return heartRateValidator(resting_heart_rate)
      && !!resting_borg_scale 
      && !!resting_symptom_score;
  }

  get isValid() {
    const {
      symptom_exacerbation,
      exacerbated_symptoms_heart_rate,
      exacerbated_symptoms_time,
      exacerbated_symptoms_borg_scale,
      exacerbated_symptoms_reported,
      resting_heart_rate,
      results
    } = this.state.buffalo;
    const { minute_intervals } = results;
    const maxMinuteInterval = minute_intervals.length + MINUTE_INTERVAL_OFFSET;
    const invalidIntervals = minute_intervals.filter(item => {
      return item.heart_rate !== '' ? !heartRateValidator(item.heart_rate) : false;
    });

    if (invalidIntervals.length > 0) {
      return false;
    }

    if (symptom_exacerbation === '') {
      return false;
    }

    if (!heartRateValidator(resting_heart_rate)) {
      return false;
    }

    if (String(symptom_exacerbation) === 'true' 
      && (
        !heartRateValidator(exacerbated_symptoms_heart_rate) 
        || !timeValidator(exacerbated_symptoms_time, maxMinuteInterval) 
        || exacerbated_symptoms_borg_scale === '' 
        || exacerbated_symptoms_reported === ''
      )
    ) {
      return false;
    }

    return true;
  }

  renderTestRows() {
    const { results } = this.state.buffalo;
    const { minute_intervals } = results;

    return createBuffaloTestTable(minute_intervals, ({ 
      index, 
      minute, 
      speed, 
      incline, 
      item 
    }) => (
      <BuffaloTestV2FormRow
        key={index}
        minute={minute}
        speed={speed}
        incline={incline}
        highlighted={(index + 1) === minute_intervals.length && this.state.flashRow}
        symptoms={item.symptoms}
        heartRate={item.heart_rate}
        borgScale={item.borg_scale}
        symptomScore={item.symptom_score}
        heartRateErrorMessage={Strings.formatString(
          Strings.invalidHeartRateMessage, 
          MIN_HEART_RATE, 
          MAX_HEART_RATE
        )}
        submitted={this.state.submitted}
        heartRateValid={item.heart_rate !== '' ? heartRateValidator(item.heart_rate) : true}
        onHeartRateChange={(e) => this.onChange('heart_rate', index, e)}
        onBorgScaleChange={(e) => this.onChange('borg_scale', index, e)}
        onSymptomScoreChange={(e) => this.onChange('symptom_score', index, e)}
        onSymptomChange={(e) => this.onChange('symptoms', index, e)}
      />
    ));
  }

  onAddNewRow() {
    const { buffalo } = this.state;
    const { results } = buffalo;
    const newMinuteIntervals = [...results.minute_intervals];
    newMinuteIntervals.push({
      ...defaultV2Results
    });

    this.setState({
      buffalo: {
        ...buffalo,
        results: {
          ...results,
          minute_intervals: newMinuteIntervals
        }
      },
      flashRow: true
    });
  }

  onChange(key, index, e) {
    const { buffalo } = this.state;
    const { value } = e.target;
    const { results } = buffalo;
    results.minute_intervals[index][key] = value;

    this.setState({
      buffalo: {
        ...buffalo,
        results
      }
    });
  }

  onUpdateInput(key, e) {
    const { buffalo } = this.state;
    const value = e && e.target ? e.target.value : e;
    
    this.setState({
      buffalo: {
        ...buffalo,
        [key]: value
      }
    });
  }

  onSymptomExacerbationChange(value) {
    const { buffalo } = this.state;
    const { results } = buffalo;
    let {
      symptom_exacerbation,
      exacerbated_symptoms_heart_rate,
      exacerbated_symptoms_borg_scale,
      exacerbated_symptoms_time,
      exacerbated_symptoms_reported
    } = buffalo;
    let matchedItems = [];

    symptom_exacerbation = value;

    /** convert boolean to string */
    if (String(symptom_exacerbation) === 'true') {
      matchedItems = getCompleteRowValue(results.minute_intervals);
    } else {
      exacerbated_symptoms_heart_rate = '';
      exacerbated_symptoms_time = '';
      exacerbated_symptoms_borg_scale = '';
      exacerbated_symptoms_reported = '';
    }

    if (matchedItems.length > 0) {
      const lastItem = matchedItems[0];
      exacerbated_symptoms_heart_rate = lastItem.heart_rate;
      exacerbated_symptoms_reported = lastItem.symptoms;
      exacerbated_symptoms_borg_scale = lastItem.borg_scale;
      exacerbated_symptoms_time = lastItem.time;
    }

    this.setState({
      buffalo: {
        ...buffalo,
        symptom_exacerbation,
        exacerbated_symptoms_heart_rate,
        exacerbated_symptoms_time,
        exacerbated_symptoms_borg_scale,
        exacerbated_symptoms_reported
      }
    });
  }

  onSubmit() {
    this.setState({ submitted: true });

    if (!this.isValid) {
      return;
    }

    const attributes = {
      ...this.state.buffalo,
      buffalo_version: 2
    };

    this.props.onSubmit(attributes);
  }
}

export default BuffaloTestV2Form;
