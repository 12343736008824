import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormInputGroup } from '@/forms';
import NeuroExamTest from './neuro-exam-test';
import AssessmentSectionForm from '../assessment-section-form';
import Strings from './lang';

const defaultExamState = {
  status: 'not_done',
  notes: ''
};

const defaultVisualTrackingState = {
  ...defaultExamState,
  accomodation_distance_cm: '',
  convergence_distance_cm: ''
};

class NeuroExamsForm extends Component {
  constructor(props) {
    super(props);

    const { neuroExam = {} } = props;
    const {
      visual_tracking = { ...defaultVisualTrackingState },
      cranial_nerve_screen = { ...defaultExamState },
      pronator_drift_testing = { ...defaultExamState },
      cerebellar_testing = { ...defaultExamState },
      upper_limb_neurological_testing = { ...defaultExamState },
      lower_limb_neurological_testing = { ...defaultExamState },
      cervical_spine_exam = { ...defaultExamState }
    } = neuroExam;

    this.onSubmit = this.onSubmit.bind(this);
    this.onUpdateVisualTrackingExam = this.onUpdateExam.bind(this, 'visual_tracking');
    this.onUpdateCranialNerveExam = this.onUpdateExam.bind(this, 'cranial_nerve_screen');
    this.onUpdatePronatorDriftExam = this.onUpdateExam.bind(this, 'pronator_drift_testing');
    this.onUpdateCerebellarExam = this.onUpdateExam.bind(this, 'cerebellar_testing');
    this.onUpdateUpperLimbExam = this.onUpdateExam.bind(this, 'upper_limb_neurological_testing');
    this.onUpdateLowerLimbExam = this.onUpdateExam.bind(this, 'lower_limb_neurological_testing');
    this.onUpdateCervicalSpineExam = this.onUpdateExam.bind(this, 'cervical_spine_exam');

    this.state = {
      visual_tracking,
      cranial_nerve_screen,
      pronator_drift_testing,
      cerebellar_testing,
      upper_limb_neurological_testing,
      lower_limb_neurological_testing,
      cervical_spine_exam
    };
  }

  componentDidMount() {
    this._callOnUpdate();
  }

  render() {
    const { 
      visual_tracking,
      cranial_nerve_screen,
      pronator_drift_testing,
      cerebellar_testing,
      upper_limb_neurological_testing,
      lower_limb_neurological_testing,
      cervical_spine_exam
    } = this.state;

    return (
      <AssessmentSectionForm title={Strings.neuroExamsHeader} onSubmit={this.onSubmit}>
        <NeuroExamTest
          title={Strings.visualTrackingTitle}
          status={visual_tracking.status}
          notes={visual_tracking.notes}
          onUpdate={this.onUpdateVisualTrackingExam}
          additionalNode={(
            <div className="row">
              <div className="col-md-6">
                <FormInputGroup 
                  className="form-group"
                  labelText={Strings.accomadationLabel} 
                  inputType="text"
                  inputProps={{
                    className: 'form-control',
                    value: visual_tracking.accomodation_distance_cm,
                    onBlur: this.onUpdateVisualTracking.bind(this, 'accomodation_distance_cm'),
                    onChange: this.onUpdateVisualTracking.bind(this, 'accomodation_distance_cm')
                  }}
                  inputValid
                  required={false}
                />
                <p>
                  <small>{Strings.accomadationNormal}</small>
                </p>

                <FormInputGroup 
                  className="form-group"
                  labelText={Strings.convergenceLabel} 
                  inputType="text"
                  inputProps={{
                    className: 'form-control',
                    value: visual_tracking.convergence_distance_cm,
                    onBlur: this.onUpdateVisualTracking.bind(this, 'convergence_distance_cm'),
                    onChange: this.onUpdateVisualTracking.bind(this, 'convergence_distance_cm')
                  }}
                  inputValid
                  required={false}
                />
                <p>
                  <small>{Strings.converganceNormal}</small>
                </p>
              </div>
            </div>
          )}
        />

        <NeuroExamTest
          title={Strings.cranialNerveScreenTitle}
          status={cranial_nerve_screen.status}
          notes={cranial_nerve_screen.notes}
          onUpdate={this.onUpdateCranialNerveExam}
        />

        <NeuroExamTest
          title={Strings.pronatorDriftTitle}
          status={pronator_drift_testing.status}
          notes={pronator_drift_testing.notes}
          onUpdate={this.onUpdatePronatorDriftExam}
        />

        <NeuroExamTest
          title={Strings.cerebellarTitle}
          status={cerebellar_testing.status}
          notes={cerebellar_testing.notes}
          onUpdate={this.onUpdateCerebellarExam}
        />

        <NeuroExamTest
          title={Strings.upperLimbNeuroTitle}
          status={upper_limb_neurological_testing.status}
          notes={upper_limb_neurological_testing.notes}
          onUpdate={this.onUpdateUpperLimbExam}
        />

        <NeuroExamTest
          title={Strings.lowerLimbNeuroTitle}
          status={lower_limb_neurological_testing.status}
          notes={lower_limb_neurological_testing.notes}
          onUpdate={this.onUpdateLowerLimbExam}
        />

        <NeuroExamTest
          title={Strings.cervicalSpineTitle}
          status={cervical_spine_exam.status}
          notes={cervical_spine_exam.notes}
          onUpdate={this.onUpdateCervicalSpineExam}
        />
      </AssessmentSectionForm>
    );
  }

  getAttributes() {
    const {
      visual_tracking,
      cranial_nerve_screen,
      pronator_drift_testing,
      cerebellar_testing,
      upper_limb_neurological_testing,
      lower_limb_neurological_testing,
      cervical_spine_exam
    } = this.state;

    return {
      visual_tracking,
      cranial_nerve_screen,
      pronator_drift_testing,
      cerebellar_testing,
      upper_limb_neurological_testing,
      lower_limb_neurological_testing,
      cervical_spine_exam
    };
  }

  onSubmit() {
    const attributes = {
      neurological_examination: {
        ...this.getAttributes()
      }
    };

    this.props.onSubmit(attributes);
  }

  onUpdateVisualTracking(key, e) {
    const { value } = e.target;
    const { visual_tracking } = this.state;

    visual_tracking[key] = value;

    this.setState({
      visual_tracking
    }, this._callOnUpdate.bind(this));
  }

  onUpdateExam(key, attr, value) {
    const { [key]: exam } = this.state;
    this.setState({
      [key]: {
        ...exam,
        [attr]: value
      }
    }, this._callOnUpdate.bind(this));
  }

  _callOnUpdate() {
    if (typeof this.props.onUpdate === 'function') {
      this.props.onUpdate(this.getAttributes(), true);
    }
  }
}

NeuroExamsForm.propTypes = {
  neuroExam: PropTypes.object,
  onUpdate: PropTypes.func,
  onSubmit: PropTypes.func.isRequired
};

export default NeuroExamsForm;
