const EN = {
  title: 'Injury History',
  injuryHeadingText: 'Injury {0} \u2014 {1}',
  injuryStatusLabelText: 'Status: ',
  injuryStateOngoingLabelText: 'On Day {0} of Recovery',
  injuryStateDischargedLabelText: 'Discharged on {0} ({1} Days Total Recovery)',
  injuryStateLostLabelInfo: 'Lost to Follow-up on {0} ({1} - Unknown Days Total Recovery)',
  injuryStateLostLabelText: 'Reason why this patient was lost to follow-up: {0}',
  injuryStateLostLabelDefault: 'Lost to Follow-up (Unknown Days Total Recovery)',
  injuryDurationLabelText: 'Duration of injury:',
  soapNotesLinkText: 'SOAP Notes',
  clinicalNotesLinkText: 'Clinical Notes',
  specialistNotesLinkText: 'Physician/Specialist Notes',
  symptomUpdatesLinkText: 'Player Submitted Symptoms',
  testLinkText: '{0} {1} \u2014 {2}',
  assessmentTestName: 'Injury Assessment',
  blackhawksTestName: 'Blackhawks Test',
  buffaloTestName: 'Buffalo Test',
  ovsTestName: 'Orthostatic Vital Signs Test',
  vomsTestName: 'VOMS Test',
  baselineTestName: 'Post Injury Test',
  emptyMessageText: 'This patient has not reported any injuries.',
  numberOfIncompleteNotes: '{0} incomplete note(s)',
  day: '1 day',
  days: '{0} days',
  inCompleteAssessmentText: 'Assessment is incomplete.',
  continueAssessmentText: 'Continue Assessment',
  incompleteAssessmentModalTitle: 'Initial Assessment Incomplete',
  okayButtonText: 'Okay',
  incompleteAssessmentModalInfo: 'The initial assessment for this injury is still incomplete. Please complete the assessment before performing any SOAP Notes.',
  totalConcussionLabel: 'Total Concussions',
  totalConcussionsDescription: '{0} previously reported, {1} with CCMI',
  progressReportsLinkText: 'Progress Reports',
  newInjuryLinkText: 'New Injury',
  referralButtonText: 'Refer Patient',
  viewReferralsLink: 'View Referrals',
  referralSuccessMessage: 'Referral Successfully Created',
  enableReferralMessage: 'Complete the initial injury assessment in order to enable patient referrals'
};

export default EN;
