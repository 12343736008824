const EN = {
  title: 'Vestibular Ocular Motor Screen (VOMS) Test',
  directionsText: 'Using the CCMI VOMS scoring sheet, assess symptoms of headache, dizziness, nausea, fogginess (scale of 1\u201310) prior to administering this assessment (Baseline), as well as after each testing component.',
  symptomHeaderText: 'Test/Symptoms',
  notTestedHeaderText: 'Not Tested',
  headacheHeaderText: 'Headache',
  dizzinessHeaderText: 'Dizziness',
  nauseaHeaderText: 'Nausea',
  fogginessHeaderText: 'Fogginess',
  distanceHeaderText: 'Distance',
  abnormalityHeaderText: 'Detected Abnormality',
  commentsHeaderText: 'Comments',
  scale: '(0\u201310)',
  cm: '(cm)',
  yes: 'Yes',
  no: 'No',
  saveButtonText: 'Save',
  symptoms_baseline: 'Baseline (Pre-VOMS) Symptoms',
  symptoms_smoothPursuits: 'Smooth Pursuits',
  symptoms_saccadesHorizontal: 'Saccades \u2014 Horizontal',
  symptoms_saccadesVertical: 'Saccades \u2014 Vertical',
  symptoms_vorHorizontal: 'VOR \u2014 Horizontal',
  symptoms_vorVertical: 'VOR \u2014 Vertical',
  symptoms_visualMotorSensitivity: 'Visual Motion Sensitivity Test',
  symptoms_accomodationLeft: 'Accommodation \u2014 Left',
  symptoms_accomodationRight: 'Accommodation \u2014 Right',
  symptoms_convergence: 'Convergence',
  abnormalityErrorMessage: 'Select Yes or No'
};

export default EN;
