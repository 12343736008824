import React from 'react';
import classNames from 'classnames';
import SelectInputGroup from './select-input-group';
import Strings from './lang';

const GuardianInputGroup = (props) => (
  <SelectInputGroup
    {...props}
    className={classNames('guardian-input-group', props.className)}
    labelText={props.labelText || Strings.guardianLabelText}
    messageText={props.messageText || Strings.guardianErrorText}
  >
    <option value="">--</option>
    <option value="yes">{Strings.guardianYesLabel}</option>
    <option value="no">{Strings.guardianNoLabel}</option>
  </SelectInputGroup>
);

GuardianInputGroup.propTypes = SelectInputGroup.propTypes;

export default GuardianInputGroup;
