const FR = {
  title: 'Rechercher des patients',
  searchLabelText: 'Rechercher',
  searchInputPlaceholder: 'Nom, numéro de compte ou numéro de carte d’identité',
  searchButtonText: 'Rechercher',
  clearButtonText: 'Effacer',
  searchResultsHeadingText: 'Résultats',
  searchResultsEmptyText: 'Votre recherche n’a donné aucun résultat'
};
 
export default FR;
