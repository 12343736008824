import React, { useEffect, useState } from 'react';
import NumberSlider from './number-slider';
import Icon from '../../../../icon';
import Strings from '../lang';

const NumberSelector = ({
  value,
  disabled,
  onStop,
  onSelect
}) => {
  const [number, setNumber] = useState(value);

  useEffect(() => {
    setNumber(value);
  }, [value]);

  useEffect(() => {
    if (!disabled) {
      const onKeyPress = (e) => {
        if (e.key === 'ArrowDown') {
          e.preventDefault();
          setNumber(prev => prev - 1);
        } else if (e.key === 'ArrowUp') {
          e.preventDefault();
          setNumber(prev => prev + 1);
        }
      };

      window.addEventListener('keydown', onKeyPress);

      return () => {
        window.removeEventListener('keydown', onKeyPress);
      };
    }

    return undefined;
  }, [disabled]);

  useEffect(() => {
    if (!disabled) {
      const onKeyPress = (e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
          onSelect(number);
        }
      };

      window.addEventListener('keypress', onKeyPress);

      return () => {
        window.removeEventListener('keypress', onKeyPress);
      };
    }

    return undefined;
  }, [disabled, number, onSelect]);

  return (
    <div className="dual-task-number-selector">
      <NumberSlider
        number={number}
        onNumberChange={setNumber}
      />
      <button
        type="button"
        disabled={disabled}
        className="btn btn-primary btn-block"
        onClick={() => onSelect(number)}
      >
        {Strings.nextButton}
      </button>
      <button
        type="button"
        disabled={disabled}
        className="btn btn-danger btn-block btn-stop"
        onClick={onStop}
      >
        <Icon name="stop" />&nbsp;&nbsp;
        {Strings.stopButton}
      </button>
    </div>
  );
};

export default NumberSelector;
