import RecoveryProtocols from './protocols';

export const willDischarge = (stages) => {
  return Object.keys(stages).reduce((acc, key) => {
    const protocolStages = RecoveryProtocols[key]?.stages;

    if ((stages[key] ?? null) === null || !protocolStages) return acc;

    const lastStage = protocolStages[protocolStages.length - 1].value;
    const isLastStage = stages[key] === lastStage;

    return [...acc, isLastStage];
  }, []).every(Boolean);
};
