import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import durationPlugin from 'dayjs/plugin/duration';
import utcPlugin from 'dayjs/plugin/utc';
import weekdayPlugin from 'dayjs/plugin/weekday';
import localeDataPlugin from 'dayjs/plugin/localeData';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import relativeTime from 'dayjs/plugin/relativeTime';

import 'dayjs/locale/fr';

dayjs.extend(advancedFormat);
dayjs.extend(durationPlugin);
dayjs.extend(utcPlugin);
dayjs.extend(weekdayPlugin);
dayjs.extend(localeDataPlugin);
dayjs.extend(customParseFormat);
dayjs.extend(isSameOrAfter);
dayjs.extend(relativeTime);
