import React, { useState, useRef, useEffect } from 'react';
import Modal from '../../modal';
import Strings from '../lang';
import Icon from '../../icon';

const INPUT_FOCUS_DELAY = 300;

const ModalContent = ({
  title,
  details,
  confirmButtonText,
  confirmButtonType = 'primary',
  onConfirm = () => { },
  onCancel
}) => {
  const inputRef = useRef();
  const [message, setMessage] = useState('');

  useEffect(() => {
    setTimeout(() => {
      inputRef.current?.focus();
    }, INPUT_FOCUS_DELAY);
  }, []);

  return (
    <>
      <Modal.Header>
        <h1>{title}</h1>
      </Modal.Header>
      <Modal.Body>
        {details && (
          <div>{details}</div>
        )}
        <textarea
          rows={5}
          value={message}
          className="form-control"
          onChange={(e) => setMessage(e.target.value)}
        />
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-default"
          onClick={onCancel}
        >
          {Strings.cancelButtonText}
        </button>
        <button
          type="button"
          className={`btn btn-${confirmButtonType}`}
          disabled={message.trim().length <= 0}
          onClick={() => onConfirm(message)}
        >
          {confirmButtonText}
        </button>
      </Modal.Footer>
    </>
  );
};

const AcceptReferralModal = ({ opened = false, onAccept, onClose }) => {
  return (
    <Modal
      blur
      className="referral-message-modal"
      isOpen={opened}
      onClose={onClose}
    >
      <ModalContent
        title={Strings.acceptModalTitle}
        details={Strings.acceptModalMessage}
        confirmButtonText={Strings.acceptModalTitle}
        confirmButtonType="success"
        onConfirm={onAccept}
        onCancel={onClose}
      />
    </Modal>
  );
};

const RejectReferralModal = ({ opened = false, onReject, onClose }) => {
  return (
    <Modal
      blur
      className="referral-message-modal"
      isOpen={opened}
      onClose={onClose}
    >
      <ModalContent
        title={Strings.rejectModalTitle}
        details={Strings.rejectModalMessage}
        confirmButtonText={Strings.rejectModalTitle}
        confirmButtonType="danger"
        onConfirm={onReject}
        onCancel={onClose}
      />
    </Modal>
  );
};

const ReferralActions = ({ status, onAccept, onReject }) => {
  const [acceptModalOpen, setAcceptModalOpen] = useState(false);
  const [rejectModalOpen, setRejectModalOpen] = useState(false);

  if (status !== 'pending') {
    return null;
  }

  return (
    <>
      <div className="referral-actions">
        <button
          type="button"
          className="btn btn-sm btn-success"
          onClick={() => setAcceptModalOpen(true)}
        >
          <Icon name="check" />&nbsp;
          {Strings.acceptReferralButton}
        </button>
        <button
          type="button"
          className="btn btn-sm btn-danger"
          onClick={() => setRejectModalOpen(true)}
        >
          <Icon name="ban" />&nbsp;
          {Strings.rejectReferralButton}
        </button>
      </div>
      <AcceptReferralModal
        opened={acceptModalOpen}
        onAccept={onAccept}
        onClose={() => setAcceptModalOpen(false)}
      />
      <RejectReferralModal
        opened={rejectModalOpen}
        onReject={onReject}
        onClose={() => setRejectModalOpen(false)}
      />
    </>
  );
};

export default ReferralActions;
