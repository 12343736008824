const FR = {
  injuryDateLabelText: 'Date de la blessure',
  assessmentDateLabelText: 'Date d’évaluation',
  practitionerLabelText: 'Praticien',
  clinicLabelText: 'Clinique',
  accountLabelText: 'Compte',
  bagtagLabelText: 'Numéro de carte d’identité',
  birthdateLabelText: 'Date de naissance :',
  ageLabelText: 'Âge',
  genderLabelText: 'Sexe',
  daysSinceInjuryLabelText: 'Jours depuis la blessure',
  cityLabelText: 'Ville/municipalité',
  regionLabelText: 'Province ou État',
  phoneLabelText: 'Numéro de téléphone',
  faxLabelText: 'Numéro de fax',
  noAssessments: 'Aucune évaluation préliminaire trouvée',
  notApplicable: 'S./O.',
  testDateLabel: 'Date de l’évaluation',
  languageLabelText: 'Langue',
  notSpecifiedText: 'Non spécifiée'
};
 
export default FR;
