import React, {
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { If, Then } from 'react-if';
import classNames from 'classnames';
import FormInput from './form-input';
import FormInputMessage from './form-input-message';

const defaultClickHandler = (e) => e.preventDefault();

const LabelContent = ({
  visible = true,
  required,
  text,
  accessory = null
}) => {
  return visible ? (
    <span>
      {text}
      <If condition={required === true}>
        <Then>
          <span className="required">*</span>
        </Then>
      </If>
      {accessory}
    </span>
  ) : null;
};

const FormInputGroup = React.forwardRef(({
  required,
  touched: _propTouched,
  className,
  noLabel = false,
  labelProps,
  labelAccessory,
  labelText,
  inputProps,
  inputClassName,
  labelAfterInput = false,
  inputType,
  inputMin,
  inputValid,
  messageClassName,
  messageText,
  children
}, ref) => {
  const [touched, setTouched] = useState(_propTouched);

  const onBlur = useCallback((e) => {
    setTouched(true);

    if (typeof inputProps?.onBlur === 'function') {
      inputProps.onBlur(e);
    }
  }, [inputProps]);

  const onChange = useCallback((e) => {
    if (typeof inputProps?.onChange === 'function') {
      inputProps.onChange(e);
    }
  }, [inputProps]);

  const mergedInputProps = useMemo(() => {
    const controlledProps = { onBlur, onChange };

    if (labelAccessory) {
      controlledProps.onClick = defaultClickHandler;
    }

    return { ...(inputProps || {}), ...controlledProps };
  }, [onBlur, onChange, labelAccessory, inputProps]);

  useEffect(() => {
    setTouched(_propTouched);
  }, [_propTouched]);

  return (
    <div
      className={classNames('form-input-group', className, {
        invalid: touched && !inputValid
      })}
    >
      <label {...labelProps}>
        <If condition={!labelAfterInput}>
          <Then>
            <LabelContent
              required={required}
              visible={!noLabel}
              text={labelText}
              accessory={labelAccessory}
            />
          </Then>
        </If>
        <FormInput
          ref={ref}
          className={inputClassName}
          type={inputType}
          inputProps={mergedInputProps}
          inputMin={inputMin}
        >
          {children}
        </FormInput>
        <If condition={labelAfterInput === true}>
          <Then>
            <LabelContent
              required={required}
              visible={!noLabel}
              text={labelText}
              accessory={labelAccessory}
            />
          </Then>
        </If>
      </label>
      <FormInputMessage
        className={messageClassName}
        visible={touched && !inputValid}
        text={messageText}
      />
    </div>
  );
});

export default FormInputGroup;
