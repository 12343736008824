import React from 'react';
import { PopupButton } from '@typeform/embed-react';
import Strings from './lang';

const SUPPORT_TYPEFORM_ID = 'lIFmLz';
const SANDBOX = process.env.NODE_ENV === 'development';

const SupportNavigationItem = () => {
  return (
    <li>
      <PopupButton 
        id={SUPPORT_TYPEFORM_ID}
        enableSandbox={SANDBOX}
      >
        {Strings.supportLinkText}
      </PopupButton>
    </li>
  );
};

export default SupportNavigationItem;
