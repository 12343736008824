const EN = {
  neuroExamsHeader: 'Physical Examination',
  unremarkableLabel: 'Unremarkable',
  remarkableLabel: 'Remarkable',
  notDoneLabel: 'Not Done',
  notesLabel: 'Notes',
  visualTrackingTitle: 'Visual Tracking',
  vomsButtonText: 'Perform VOMS Test',
  accomadationLabel: 'Accommodation Distance',
  accomadationNormal: 'Normal = less than 10 cm',
  distanceErrorMessage: 'Please enter a valid number',
  convergenceLabel: 'Convergence Distance',
  converganceNormal: 'Normal = less than 5 cm',
  cranialNerveScreenTitle: 'Cranial Nerve Screen',
  pronatorDriftTitle: 'Pronator Drift Testing',
  cerebellarTitle: 'Cerebellar Testing',
  upperLimbNeuroTitle: 'Upper Limb Neurological Testing',
  lowerLimbNeuroTitle: 'Lower Limb Neurological Testing',
  cervicalSpineTitle: 'Cervical Spine Exam',
  saveButtonText: 'Save'
};

export default EN;
