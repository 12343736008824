const FR = {
  openStatus: 'Ouvert',
  closedStatus: 'Fermé',
  appointmentOnlyStatus: 'Rendez-vous seulement',
  hoursDisplayTitle: 'Heures',
  hoursModalTitle: 'Heures de la clinique',
  saveButtonText: 'Sauver',
  dayTableHeading: 'Journée',
  statusTableHeading: 'Statut',
  openTableHeading: 'Ouvert',
  closeTableHeading: 'Fermer',
  noHoursListText: 'Pas d\'heures',
  copyRowTitleText: 'Copier dans la ligne ci-dessous'
};
 
export default FR;
