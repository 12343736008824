import classNames from 'classnames';
import React, { useMemo } from 'react';

const SelectedTab = ({
  selected,
  items = []
}) => {
  const item = useMemo(() => (
    items.find(item => item.key === selected) || { component: null }
  ), [items, selected]);

  return (
    <div className="tabs-content">
      <div className="tab-component selected">
        {item.component}
      </div>
    </div>
  );
};

const TabContent = ({
  items = [],
  selected,
  renderOne = false,
}) => {
  if (renderOne) {
    return (
      <SelectedTab items={items} selected={selected} />
    );
  }

  return (
    <div className="tabs-content">
      {items.map((item, index) => {
        if (item.hide !== true) {
          return (
            <div
              key={index}
              className={
                classNames('tab-component', {
                  selected: item.key === selected
                })
              }
            >
              {item.component}
            </div>
          );
        }

        return null;
      })}
    </div>
  );
};

export default TabContent;
