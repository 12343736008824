const EN = {
  title: 'Assign Concussion ID Card (i.e., Bag-Tag)',
  bagTagLabelText: 'Please enter the concussion ID card number that you would like to assign to this patient below:',
  bagTagErrorText: 'Please enter the concussion ID card that you would like to assign to this patient.',
  confirmBagTagLabelText: 'Re-enter the concussion ID card number that you would like to assign:',
  confirmBagTagErrorText: 'Please re-enter the ID card that you would like to assign to this patient.',
  reasonLabelText: 'Reason for assigning new ID card:',
  reasonErrorText: 'Please select a reason for assigning a new concussion ID card to this patient.',
  reasonOptionLost: 'Lost',
  reasonOptionStolen: 'Stolen',
  reasonOptionDamaged: 'Damaged',
  reasonOptionOther: 'Other',
  assignLabelText: 'Are you sure you would like to assign a new concussion ID card to this patient?',
  assignErrorText: 'Please confirm that you would like to assign a new concussion ID to this patient.',
  termsLabelText: 'I accept the terms of assigning a new concussion ID card.',
  termsErrorText: 'You must accept the terms of assigning a new concussion ID card.',
  terms: 'Agreeing to the terms of assigning a new concussion ID card acknowledges that doing so is permanent. It also acknowledges that this will replace the current ID card number if there is one, then that card number can no longer be used.',
  cancelButtonTitle: 'Cancel',
  assignButtonTitle: 'Assign ID Card',
  skipAssignButtonTitle: 'Skip'
};

export default EN;
