const EN = {
  startTitle: 'Start Blackhawks Test',
  testTitle: 'Blackhawks Test',
  startButtonText: 'Start Blackhawks Test',
  saveButtonText: 'Submit Blackhawks Test',
  testTypeHeadingText: 'What test would you like to perform?',
  testTypeLabelText: 'Test Type',
  bikeTypeHeadingText: 'What type of bike are you using for this test?',
  bikeTypeLabelText: 'Bike Type',
  testTypeErrorText: 'Select the type of test that you are conducting.',
  bikeTypeErrorText: 'Select the type of bike that you are using.',
  fullTestLabelText: 'Full Blackhawks Test \u2014 For Elite Athletes',
  modifiedTestLabelText: 'Modified Blackhawks Test \u2014 For Younger/Non-Elite Athletes',
  monarkBikeLabelText: 'Monark Bike',
  lifecycleBikeLabelText: 'LifeCycle/LifeFitness or 20-Level Bike',
  stationaryBikeHeadingText: 'Phase 1',
  stationaryBikeSubheadingText: 'Stationary Bike',
  plyometricsHeadingText: 'Phase 2',
  plyometricsSubheadingText: 'Full Plyometrics',
  testDurationLabelText: 'Test Duration',
  testTimeLabelText: 'Time (in seconds)',
  testTimeStartButtonText: 'Start',
  testTimeStopButtonText: 'Stop',
  testTimeResumeButtonText: 'Resume',
  testTimeResetButtonText: 'Reset',
  restingHeartRateLabelText: 'Resting Heart Rate',
  stageTimeHeaderText: 'Stage/Time',
  tensionLevelsHeaderText: 'Tension Levels',
  suggestedRpmHeaderText: 'Suggested RPM',
  heartRateHeaderText: 'Heart Rate',
  heartRateLabelText: 'Heart Rate:',
  symptomsHeaderText: 'Symptoms/Notes',
  symptomsLabelText: 'Symptoms/Notes:',
  stationaryBikeStageLabelText: 'Stage {0}',
  stationaryBikeRecoveryLabelText: 'Stage {0} Recovery',
  stationaryBikeStageWarmUpRpm: 'Gradually increase up to {0}',
  stationaryBikeStageMaxTension: 'High as Possible ({0})',
  stationaryBikeStageMaxRpm: 'Max (100+)',
  stationaryBikeStageStopped: 'Stopped',
  stationaryBikeRestHeadingText: 'Rest (walking around off bike) 2:00',
  stationaryBikeRestTimeText: '2:00',
  plyometricsHurdleHopsStageTitleText: 'Direction',
  plyometricsHurdleHopsStageLabelText: 'Stage 1: Lateral Hurdle Hops (6\u2033\u201310\u2033)',
  plyometricsHurdleHopsStageDirectionsText: '0:10 Laterally 2 foot hop over 3 hurdles and back continuously\n0:10 Sit down on 16\u2033\u201320\u2033 box',
  plyometricsHurdleStepsStageDirectionsText: '0:10 Laterally step over 3 hurdles and back continuously\n0:10 Sit down on 16\u2033\u201320\u2033 box',
  plyometricsHurdleHopsStageRepeatText: 'Repeat 4x',
  plyometricsHurdleHopsStageRestText: '0:40 sec Rest',
  plyometricsBurpeesStageLabelText: 'Stage 2: Burpees',
  plyometricsBurpeesStageDirectionsText: '0:10 Continuous Burpees (follow hands with eyes) w/ full pushup\n0:10 Standing in neutral position',
  plyometricsBurpeesStageModifiedDirectionsText: '0:10 Continuous Burpees (follow hands with eyes)\n0:10 Standing in neutral position',
  plyometricsBurpeesStageRepeatText: 'Repeat 3x',
  plyometricsBurpeesStageRestText: '1:00 min Rest',
  plyometricsBoxJumpsStageLabelText: 'Stage 3: Lateral Box Jumps (16\u2033\u201320\u2033)',
  plyometricsBoxJumpsStageDirectionsText: '0:15 Laterally 2 foot jump onto box, down on other side and back over continuously\n0:15 Sit down on box',
  plyometricsBoxJumpsModifiedStageDirectionsText: '0:15 Laterally step up onto box, down on other side and back over continuously\n0:15 Sit down on box',
  plyometricsBoxJumpsStageRepeatText: 'Repeat 2x',
  plyometricsBoxJumpsStageRestText: '1:00 min Rest',
  plyometricsRotaryJumpsStageLabelText: 'Stage 4: Rotary 180 Degree Jumps',
  plyometricsRotaryJumpsStageDirectionsText: '0:10 Continuously jump and rotate to the right and then back to left (180 degrees) \u2014 Head must move in conjunction with the body\n0:20 Standing in neutral position\n0:10 Continuously jump and rotate to Left and back to Right\n0:20 Standing in neutral position',
  plyometricsRotaryJumpsStageRepeatText: 'Repeat 2x (in each direction)',
  plyometricsRotaryJumpsModifiedStageRepeatText: 'Repeat 2x',
  plyometricsRotaryJumpsStageRestDirectionText: '',
  cooldownHeadingText: 'Cool Down \u2014 5 mins \u2014 Bring HR down resting levels',
  cooldownPassText: 'Pass',
  cooldownFailText: 'Fail',
  cooldownErrorText: 'Indicate whether the patient passed or failed.',
  passFailLabelText: 'Pass/Fail:',
  failStageOptGroup_stationary: 'Phase 1 - Stationary Bike',
  failStageOptGroup_plyometrics: 'Phase 2 - Plyometrics',
  failStageOptionStationaryStage1: 'Stationary Bike - Stage 1',
  failStageOptionStationaryStage2: 'Stationary Bike - Stage 2',
  failStageOptionStationaryStage3: 'Stationary Bike - Stage 3',
  failStageOptionStationaryStageRest: 'Stationary Bike - Rest',
  failStageOptionHurdleHops: 'Plyometrics - Stage 1 - Lateral Hurdle Hops',
  failStageOptionBurpees: 'Plyometrics - Stage 2 - Burpees',
  failStageOptionBoxJumps: 'Plyometrics - Stage 3 - Lateral Box Jumps',
  failStageOptionRotaryJumps: 'Plyometrics - Stage 4 - Rotary 180 Degree Jumps',
  failWithStageText: 'Fail - Heart rate of {0} with symptoms of {1} at {2}',
  failWithoutStageText: 'Fail - Heart rate of {0} with symptoms of {1}',
  heartrateUnit: 'BPM',
  heartRateAtFailLabel: 'Heart Rate at Fail',
  symptomsAtFailLabel: 'Symptoms at Fail',
  stageAtFailLabel: 'Stage at Fail',
  blackhawksTestOutcomes: 'Blackhawks Test Outcome',
  overallNotesLabelText: 'Overall Notes',
  customBikeLabel: 'Other',
  customBikeMaxTensionLabel: 'How many tension levels does your bike have?',
  customBikeMaxTensionErrorMessage: 'Please enter a maximum bike tension value greater than 1',
  customBikeHeaderLabel: 'Other ({0} Level Bike)'
};

export default EN;
