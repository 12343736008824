import React, { useEffect, useState, useMemo } from 'react';
import classnames from 'classnames';
import DanaBatteryDetails from '../battery-details';
import BatteryListItem from './list-item';
import Strings from '../lang';

const Filters = {
  All: 'all',
  Valid: 'valid',
  Invalid: 'invalid'
};

const FilterStrings = {
  All: Strings.allTestsSelectorLabel,
  Valid: Strings.validTestTabLabel,
  Invalid: Strings.invalidTestTabLabel
};

const FilterKeys = Object.keys(Filters);

const filterBatteries = (key, batteries = []) => {
  if (key === Filters.All) return batteries;
  return batteries.filter(battery => battery.is_valid === (key === Filters.Valid));
};

const TabBar = ({ selected, onSelect }) => {
  return (
    <div className="tabs">
      <div className="tabs-bar">
        {FilterKeys.map(key => (
          <button
            key={key}
            type="button"
            className={classnames('tab-button', {
              selected: Filters[key] === selected
            })}
            onClick={() => onSelect(Filters[key])}
          >
            {FilterStrings[key]}
          </button>
        ))}
      </div>
    </div>
  );
};

const DanaBatteryList = ({
  batteries = [],
  userId
}) => {
  const [filterKey, setFilterKey] = useState('all');
  const [selected, setSelected] = useState(() => (batteries[0] || {}).id || null);
  const filtered = useMemo(() => filterBatteries(filterKey, batteries), [batteries, filterKey]);

  useEffect(() => {
    if (!filtered.length) {
      setSelected(null);
    }
  }, [filtered.length]);

  return (
    <div className="dana-test-list-container">
      <div className="dana-test-list">
        <TabBar 
          selected={filterKey}
          onSelect={setFilterKey}
        />
        <ul className="list-unstyled">
          {filtered.map((battery) => (
            <BatteryListItem
              key={battery.id}
              battery={battery}
              selected={selected === battery.id}
              onClick={() => setSelected(battery.id)}
            />
          ))}
        </ul>
      </div>
      <div className="dana-test-list-details">
        {selected ? (
          <DanaBatteryDetails
            batteryId={selected}
            userId={userId}
          />
        ) : (
          <div className="select-test-view">
            <i className="text-muted">
              {Strings.selectListTestMessage}
            </i>
          </div>
        )}
      </div>
    </div>
  );
};

export default DanaBatteryList;
