const MIN_INTERVAL = 0;
const MAX_INTERVAL = 16;
const COOL_DOWN = MAX_INTERVAL - 1;
const MIN_HEART_RATE = 0;
const MAX_HEART_RATE = 240;

const defaultResults = {
  heart_rate: '',
  symptoms: ''
};

const getBuffaloTestState = (props) => {
  const { buffalo = {} } = props;
  const {
    symptom_exacerbation = '',
    exacerbated_symptoms_heart_rate = '',
    exacerbated_symptoms_time = '',
    exacerbated_symptoms_reported = '',
    resting_heart_rate = '',
    notes = '',
    results = {}
  } = buffalo;

  return {
    buffalo: {
      symptom_exacerbation,
      exacerbated_symptoms_heart_rate,
      exacerbated_symptoms_time,
      exacerbated_symptoms_reported,
      resting_heart_rate,
      notes,
      results: {
        minute_intervals: [...getDefaultResults(results)]
      }
    }
  };
};

const getDefaultResults = (propResults) => {
  const { minute_intervals = [] } = propResults;
  return [...new Array(MAX_INTERVAL + 1)].map((na, index) => {
    return {
      ...defaultResults, ...minute_intervals[index]
    };
  });
};

export {
  MAX_INTERVAL,
  MIN_INTERVAL,
  COOL_DOWN,
  MIN_HEART_RATE,
  MAX_HEART_RATE,
  getBuffaloTestState
};
