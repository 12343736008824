const EN = {
  instructions: 'Place a 3-metre-long line on the floor/firm surface with athletic tape. The task will be timed.',
  practiceTitle: 'Dual Task Practice',
  promptStartText: 'Say',
  practicePrompt: 'Now, I will ask you to count backwards out loud by 7s. For example, if we started at 100, you would say - 100, 93, 86, 79. Let\'s practice counting. Starting with 93, count backwards by sevens until I say "stop".',
  practiceNote: 'Note that this practice only involves counting backwards.',
  practice7CountingTrouble: 'If the patient has trouble counting backwards by sevens, change the subtraction value above to "3" and try again.',
  practice3CountingTrouble: 'If the patient has trouble counting backwards by threes, mark the test as "Unable to complete" and move on.',
  trialsTitle: 'Dual Task Cognitive Performance',
  trialsPrompt: 'Good. Now I will ask you to walk heel-to-toe while counting backwards out loud at the same time. Are you ready? The number to start with is {0}. Go!',
  startTrialButton: 'Start Trial',
  editTrialButton: 'Edit Trial',
  tableColumnTrial: 'Trial',
  tableColumnStartNumber: 'Start Integer',
  tableColumnPassFail: 'Pass/Fail (reason)',
  tableColumnAttempted: 'Attempted',
  tableColumnErrors: 'Errors',
  tableColumnCorrect: 'Correct',
  tableColumnTime: 'Time (s)',
  durationLabel: 'Duration',
  trialLabel: 'Trial {0}',
  passedText: 'Passed',
  failureQuestion: 'Did the patient perform any of the following walking errors?',
  failureStepOffLine: 'Stepped off line',
  failureSeparation: 'Separation between heel and toe',
  failureTouchEnvironment: 'Touched the examiner/environment',
  updateButton: 'Update',
  editModalTitle: 'Edit Trial {0}',
  correctNumberColumnLabel: 'Correct Number',
  responseNumberColumnLabel: 'Response',
  nextButton: 'Next',
  stopButton: 'Stop',
  startButton: 'Start',
  doneButton: 'Done',
  resetTrialButton: 'Reset Trial',
  trialCompleteTitle: 'Trial Complete',
  trialStoppedTitle: 'Trial Stopped',
  unableToCompleteLabel: 'Patient is unable to complete',
  totalErrors: 'Total Errors',
  avgTime: 'Avg. Time (s)',
  fastestTime: 'Fastest Time (s)',
  subtractionValueLabel: 'Subtraction Value',
  confirmStepChangeTitle: 'Confirm Subtraction Value Change',
  confirmStepChangeMessage: 'Changing this value will reset all trials. Do you want to continue?',
  confirmStepChangeButton: 'Confirm',
  keyControlsTitle: 'Keyboard Controls',
  keyArrowUpControlText: '(Up Arrow) - Increment Number.',
  keyArrowDownControlText: '(Down Arrow) - Decrement Number.',
  keyEnterControlText: '(Enter) - Select current number and move on to the next number.',
  tandemGaitErrorsMessage: 'Have the patient stand feet together at one end of the line. The patient must then walk heel-to-toe quickly to the end of the tape, turn around and come back as fast as they can without separating their feet or stepping off the line. The patient <strong>fails the trial</strong> if they perform any of the following errors: <strong>steps off the line</strong>, <strong>has separation between their heel and toe</strong>, or <strong>if they touch of grab the examiner or an object</strong>. If the patient fails the trial, stop the timer, mark the trial as failed and move to the next trial.',
  cognitiveAccuracyLabel: 'Cognitive Accuracy',
  averageResponseRateLabel: 'Avg. Response Rate',
  responseRateLabel: 'Response Rate'
};

export default EN;
