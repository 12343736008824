import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { UserPaths } from '@/paths';
import replaceLinkParams from '@/utilities/replace-link-params';
import Strings from './lang';

const reduceUserTeamNames = (user) => {
  const teams = user.teams || [];
  return teams.map(team => team.name || '').join(', ');
};

const TeamLeadersTable = ({
  sortKeyPath,
  teamLeaders = [],
  onActivateUser,
  onUpdateSortKeyPath
}) => {
  const updateSortKeyPath = useCallback((keyPath) => {
    if (onUpdateSortKeyPath) {
      onUpdateSortKeyPath(keyPath);
    }
  }, [onUpdateSortKeyPath]);

  const activateUser = useCallback((user, active) => {
    if (onActivateUser) {
      onActivateUser(user, active);
    }
  }, [onActivateUser]);

  return (
    <table className="table users-table team-leaders-table">
      <thead>
        <tr>
          <th
            tabIndex={0}
            className={classNames({ highlighted: sortKeyPath === 'person.last_name' })}
            onClick={() => updateSortKeyPath('person.last_name')}
          >
            {Strings.nameHeadingText}
          </th>
          <th className="no-action">
            {Strings.teamsHeadingText}
          </th>
          <th
            tabIndex={0}
            className={classNames({ highlighted: sortKeyPath === 'active' })}
            onClick={() => updateSortKeyPath('active')}
          >
            {Strings.statusHeadingText}
          </th>
          <th className="no-action">
            {Strings.actionHeadingText}
          </th>
        </tr>
      </thead>
      <tbody>
        {teamLeaders.map(user => {
          const { person, active = false } = user;

          if (person) {
            return (
              <tr key={user.id}>
                <td>
                  <Link
                    to={replaceLinkParams(UserPaths.leaderProfile.link, {
                      leaderId: user.id
                    })}
                  >
                    {person.first_name} {person.last_name}
                  </Link>
                </td>
                <td>
                  {reduceUserTeamNames(user)}
                </td>
                <td>
                  {(active) ? Strings.activatedStatusText : Strings.deactivatedStatusText}
                </td>
                <td>
                  <button 
                    type="button" 
                    className="edit-button" 
                    onClick={() => activateUser(user, !active)}
                  >
                    {(active) ? Strings.deactivateActionText : Strings.activateActionText}
                  </button>
                </td>
              </tr>
            );
          }

          return null;
        })}
      </tbody>
    </table>
  );
};

export default TeamLeadersTable;
