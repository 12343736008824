import React, { useMemo } from 'react';
import Markdown from '../markdown';
import { buildDiagnosisString } from '../diagnosis/diagnosis-utilities';
import { ovsResultTypeString } from '../ovs-tests';
import Strings from './lang';
import Icon from '../icon';

const BuffaloTestLabel = ({ buffalo }) => {
  let outcomeText = Strings.testPassedText;

  if (buffalo.symptom_exacerbation) {
    if (buffalo.exacerbated_symptoms_heart_rate !== null) {
      outcomeText = Strings.formatString(
        Strings.failedWithHeartRateText, 
        buffalo.exacerbated_symptoms_heart_rate
      );
    } else {
      outcomeText = Strings.testFailedText;
    }
  }

  return (
    <div>
      <span className="bold">{Strings.buffalo}</span> ({Strings.seeBelowText}) - {outcomeText}
    </div>
  );
};

const BlackhawksTestLabel = ({ blackhawks }) => {
  let outcomeText = blackhawks.cooldown_result ? Strings.testPassedText : Strings.testFailedText;

  if (!blackhawks.cooldown_result && !!blackhawks.test_data.fail_point) {
    outcomeText = Strings.formatString(
      Strings.failedWithHeartRateText,
      blackhawks.test_data.fail_point.heart_rate
    );
  }

  return (
    <div>
      <span className="bold">{Strings.blackhawks}</span> ({Strings.seeBelowText}) - {outcomeText}
    </div>
  );
};

const OVSTestLabel = ({ ovs }) => {
  return (
    <div>
      <span className="bold">{Strings.ovs}</span> ({Strings.seeBelowText}) - {ovsResultTypeString[ovs.result]}
    </div>
  );
};

const SoapNoteSection = ({
  notes = {}, 
  tests = {}, 
  diagnosis = {}
}) => {
  const { buffalo, blackhawks } = tests;
  const hasDiagnosis = useMemo(() => Object.keys(diagnosis || {}).length > 0, [diagnosis]);
  
  return (
    <div className="row note-section-display">
      <div className="col-md-12">
        <h2>{Strings.notesSectionTitle}</h2>
      </div>
      <div className="col-md-6">
        <label className="bold">
          <span>{Strings.subjectiveLabel}:</span>
        </label>
        <div className="notes markdown">
          <Markdown html={false}>
            {notes.s}
          </Markdown>
        </div>
      </div>

      <div className="col-md-6">
        <label className="bold">
          <span>{Strings.objectiveLabel}:</span>
        </label>
        <div className="notes markdown">
          <Markdown html={false}>
            {notes.o}
          </Markdown>
        </div>
        {!!buffalo && (
          <BuffaloTestLabel buffalo={buffalo} />
        )}
        {tests.voms && (
          <div>
            <span className="bold">{Strings.voms}</span> ({Strings.seeBelowText})
          </div>
        )}
        {!!tests.ovs && (
          <OVSTestLabel ovs={tests.ovs} /> 
        )}
        {tests.blackhawks && (
          <BlackhawksTestLabel blackhawks={blackhawks} />
        )}
        {tests.postInjury && (
          <div>
            <span className="bold">{Strings.postInjury}</span> ({Strings.seeBelowText})
          </div>
        )}
      </div>

      <div className="col-md-12">
        <label className="bold">
          <span>{Strings.assessmentLabel}:</span>
        </label>
        <div className="notes markdown">
          <Markdown html={false}>
            {notes.a}
          </Markdown>
          {hasDiagnosis && (
            <div>{Strings.diagnosisChangedText}: {buildDiagnosisString(diagnosis || {})}</div>
          )}
        </div>
      </div>
      <div className="col-md-12 plan-display-section">
        <label className="bold">
          <span>{Strings.planLabel}:</span>
        </label>
        {Object.keys(notes.p || {}).reduce((acc, cur) => {
          if (notes.p[cur].selected) {
            acc.push(
              <div key={cur} className="row plan-item checked">
                <div className="col-md-5">
                  <div className="form-input-group">
                    <label>
                      <span>
                        <Icon name="check" />
                        {Strings[cur]}:
                      </span>
                    </label>
                  </div>
                </div>
                <div className="col-md-7 multiline-text-display">
                  {notes.p[cur].notes}
                </div>
              </div>
            );
          }

          return acc;
        }, [])}
      </div>
    </div>
  );
};

export default SoapNoteSection;
