import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CheckboxInputGroup, FormInputGroup } from '@/forms';
import symptomLabels from './symptom-labels';
import Strings from './lang';

class SymptomCheckBoxes extends Component {
  render() {
    const { symptomsChecked = {}, onUpdate = () => {} } = this.props;
    return (
      <div className={classNames('column-checkbox-group', this.props.className)}>
        {this.renderCheckboxes()}
        <FormInputGroup 
          className="form-group"
          labelText={Strings.otherSymptomsLabel} 
          inputType="text"
          inputProps={{
            className: 'form-control',
            value: symptomsChecked.other,
            onBlur: onUpdate.bind(null, 'other'),
            onChange: onUpdate.bind(null, 'other')
          }}
          inputValid
          required={false}
        />
      </div>
    );
  }

  renderCheckboxes() {
    const { symptomsChecked = {}, onUpdate = () => {} } = this.props;
    return Object.keys(symptomsChecked).map((key, index) => {
      if (key !== 'other') {
        return (
          <CheckboxInputGroup
            key={index}
            className="form-group"
            labelText={symptomLabels[key]}
            inputProps={{
              className: 'form-control',
              checked: symptomsChecked[key],
              onChange: onUpdate.bind(null, key)
            }}
            inputValid
          />
        );
      }

      return null;
    });
  }
}

SymptomCheckBoxes.propTypes = {
  symptomsChecked: PropTypes.object,
  onUpdate: PropTypes.func
};

export default SymptomCheckBoxes;
