const EN = {
  addressSearchLabelText: 'Address Search',
  streetLabelText: 'Street',
  streetErrorText: 'Please enter the street address.',
  cityLabelText: 'City / Town',
  cityErrorText: 'Please enter the city or town.',
  stateLabelText: 'State / Province',
  stateErrorText: 'Please enter the state or province.',
  postalCodeLabelText: 'Zip / Postal Code',
  postalCodeErrorText: 'Please enter the zip or postal code.',
  countryLabelText: 'Country',
  countryErrorText: 'Please enter the country.',
  showFieldsLinkText: 'Show all fields',
  hideFieldsLinkText: 'Hide all fields'
};

export default EN;
