import Strings from './lang';

const BaseballPositions = [
  { key: 'firstbase', name: Strings.baseball_firstbase },
  { key: 'secondbase', name: Strings.baseball_secondbase },
  { key: 'thirdbase', name: Strings.baseball_thirdbase },
  { key: 'catcher', name: Strings.baseball_catcher },
  { key: 'shortstop', name: Strings.baseball_shortstop },
  { key: 'leftfield', name: Strings.baseball_leftfield },
  { key: 'centerfield', name: Strings.baseball_centerfield },
  { key: 'rightfield', name: Strings.baseball_rightfield },
  { key: 'pitcher', name: Strings.baseball_pitcher },
];

export default BaseballPositions;
