import setHeaders from './headers';
import query from './query';
import deserialize from './deserializer';
import Logger from './logger';
import HTTPStatusCodes from '@/utilities/http-status-codes';
import { removeAuthData, removeCurrentClinicId } from '@/tokens';

const mergePaths = (...paths) => {
  return paths.filter(Boolean).join('/');
};

const basePath = mergePaths(
  process.env.CCMI_API_ENDPOINT_HOST,
  process.env.CCMI_API_VERSION
);

const isNotAuthenticatedError = (error) => {
  if (error.status === HTTPStatusCodes.Unauthorized) {
    const { errors = [] } = error;

    return errors?.some(e => (
      ['not authenticated', 'token expired'].includes(e.title?.toLowerCase())
    )) ?? false;
  }

  return false;
};

const jsonApiUrlForResource = (resource, options = {}) => {
  const url = mergePaths(basePath, options.path || resource.type);
  return (resource.id && !options.path) ? mergePaths(url, resource.id) : url;
};

const reduceErrorMessages = (errors = []) => {
  return errors.reduce((accumulator, error) => {
    const { title = 'Unknown Error' } = error;
    return `${accumulator}${title}\n`;
  }, '');
};

// Manually parse json in order to handle empty
// response body where the status is 204 (no content)
const parseJson = (response) => {
  return response.text().then(text => {
    // trim the text as an empty response body will
    // result in a string with 1 space (i.e. ' ') which
    // is not a falsy value;
    const jsonText = text.trim() || '{}';
    return Promise.resolve(JSON.parse(jsonText));
  });
};

const jsonApiRequest = (method, resource, options) => {
  let url = jsonApiUrlForResource(resource, options);
  let queryString = '';

  const fetchOptions = {
    method: method || 'GET',
    headers: setHeaders(options.headers)
  };

  if (resource.data instanceof FormData) {
    delete fetchOptions.headers['Content-Type'];
    fetchOptions.body = resource.data;
  } else if (fetchOptions.method !== 'GET' && fetchOptions.method !== 'HEAD') {
    fetchOptions.body = JSON.stringify({ data: resource });
    queryString = query.serialize(options.queryParams);
  } else {
    const { attributes = {} } = resource;
    const { queryParams = {} } = options;
    queryString = query.serialize({ ...attributes, ...queryParams });
  }

  if (queryString.length) {
    url = `${url}?${queryString}`;
  }

  const logger = new Logger();

  logger.log('Request:', {
    url,
    resource,
    options,
    ...fetchOptions,
  });

  return fetch(url, fetchOptions).then(response => {
    logger.log('Response:', {
      headers: response.headers, 
      url: response.url, 
      status: response.status, 
      statusText: response.statusText
    });

    // check for successful response with no content (status: 204)
    if (response.status === HTTPStatusCodes.NoContent) {
      return Promise.resolve();
    }

    return parseJson(response).then(json => {
      logger.log('JSONAPI Response:', json);

      if (response.ok) {
        const { deserializeResponse = true } = options;

        if (deserializeResponse) {
          const { data, meta } = deserialize(json);
          const response = meta ? { data, meta } : data;

          logger.log('Deserialized:', response);

          return Promise.resolve(response);
        }

        return Promise.resolve(json);
      }

      const { errors } = json;
      if (errors && errors.length) {
        return Promise.reject({
          status: response.status,
          message: reduceErrorMessages(errors),
          errors
        });
      }

      return Promise.reject({
        status: response.status,
        message: response.statusText,
        errors: []
      });
    });
  }).then(data => {
    logger.flush(true, `%c ${fetchOptions.method}`, 'color: #3CBE3F', url);

    return Promise.resolve(data);
  }).catch(error => {
    logger.log('Error:', error);
    logger.flush(false, `%c ${fetchOptions.method}`, 'color: #DB231F', url);

    if (isNotAuthenticatedError(error) && options.redirectNotAuthenticated !== false) {
      removeAuthData();
      removeCurrentClinicId();
      const replacePath = window.location.pathname.startsWith('/register')
        ? '/register'
        : '/login';

      window.location.href = replacePath;
    }

    return Promise.reject(error);
  });
};

const jsonApiCreate = (resource, options = {}) => {
  return jsonApiRequest('POST', resource, options);
};

const jsonApiRead = (resource, options = {}) => {
  return jsonApiRequest('GET', resource, options);
};

const jsonApiUpdate = (resource, options = {}) => {
  return jsonApiRequest('PATCH', resource, options);
};

const jsonApiDelete = (resource, options = {}) => {
  return jsonApiRequest('DELETE', resource, options);
};

const multipartFileUpload = (path, data) => {
  return jsonApiCreate({ data }, { path });
};

export {
  jsonApiRequest,
  jsonApiCreate,
  jsonApiRead,
  jsonApiUpdate,
  jsonApiDelete,
  multipartFileUpload
};
