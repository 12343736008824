import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import LanguageStrings from '@/utilities/languages';
import { DISPLAY_DATE_FORMAT } from '@/utilities/dates/formats';
import { RoleDescriptions } from '@/utilities/user-roles';
import AccessControl from '../access-control';
import Strings from './lang';

const formatTrainingDate = (date) => {
  const trainingDate = dayjs(date);
  return trainingDate.isValid() ? trainingDate.format(DISPLAY_DATE_FORMAT) : 'No Training Date';
};

const TeamLeaderPersonalCard = ({
  user = {}
}) => {
  const { person = {}, trained_at } = user;
  const { first_name, last_name, language } = person;

  return (
    <div className="card card-show">
      <div className="card-row">
        <label>{Strings.nameLabelText}:</label>
        <span>{`${first_name} ${last_name}`}</span>
      </div>

      <div className="card-row">
        <label>{Strings.languageLabelText}:</label>
        <span>{LanguageStrings[language || 'en']}</span>
      </div>

      <AccessControl roles={[RoleDescriptions.SuperAdmin, RoleDescriptions.SalesAdmin]}>
        <div className="card-row">
          <label>{Strings.trainingDateLabelText}:</label>
          <span>{formatTrainingDate(trained_at)}</span>
        </div>
      </AccessControl>
    </div>
  );
};

TeamLeaderPersonalCard.propTypes = {
  user: PropTypes.object
};

export default TeamLeaderPersonalCard;
