import React from 'react';
import classNames from 'classnames';
import FormInputGroup from './form-input-group';

const CheckboxInputGroup = ({
  className,
  ...props
}) => (
  <FormInputGroup
    {...props}
    className={classNames('checkbox-input-group', className)}
    inputType="checkbox"
    labelAfterInput
  />
);

CheckboxInputGroup.propTypes = FormInputGroup.propTypes;

export default CheckboxInputGroup;
