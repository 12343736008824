import React, { useCallback, useState } from 'react';
import { If, Then, Else } from 'react-if';
import { useDispatch } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { getUserAsync } from '@/redux/thunks/users';
import Confirmation from '../../confirmation';
import Activity from '../../activity';
import { 
  getCurrentRegistrationSession, 
  getRegistrationCurrentUserSession, 
  removeRegistrationSessionData 
} from '@/utilities/registration-session';
import { useMount } from '@/hooks';

const getUserRequests = (dispatch) => {
  const currentUserSession = getRegistrationCurrentUserSession() || {};
  const currentSession = getCurrentRegistrationSession() || {};
  const options = { headers: { Authorization: currentUserSession.token } };
  const requests = [];

  if (currentUserSession.id) {
    requests.push(dispatch(getUserAsync(currentUserSession.id, options)));
  }

  if (currentUserSession.id && currentSession.id && currentUserSession.id !== currentSession.id) {
    requests.push(dispatch(getUserAsync(currentSession.id, options)));
  }

  return requests;
};

const PlayerRegistrationWrapper = () => {
  const dispatch = useDispatch();
  const [initialized, setInitialized] = useState(false);

  const loadCurrentUser = useCallback(() => {
    const requests = getUserRequests(dispatch);
    if (requests.length) {
      Promise.all(requests).then(() => {
        setInitialized(true);
      }).catch(e => {
        // Force reauthentication on API error:
        if (e.status) {
          removeRegistrationSessionData();
        }

        setInitialized(true);
      });
    } else {
      setInitialized(true);
    }
  }, [dispatch]);

  useMount(() => {
    loadCurrentUser();
  });

  return (
    <If condition={initialized}>
      <Then>
        <main className="container registration">
          <Confirmation />
          <nav className="header-small">
            <div className="navigation-brand">
              <img src="/images/logo/color-full-animated.svg" alt="CCMI Logo" />
            </div>
          </nav>
          <div className="row">
            <div className="col-md-10 col-md-offset-1">
              <div className="col-md-4 section-sticky">
                <div className="col-md-12">
                  <div className="registration-brand">
                    <img className="img-responsive" src="/images/logo/color-animated.svg" alt="CCMI Logo" />
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                <Outlet />
              </div>
            </div>
          </div>
        </main>
      </Then>
      <Else>
        <Activity active static />
      </Else>
    </If>
  );
};

export default PlayerRegistrationWrapper;
