import { OrderedSymptomKeys } from '@/utilities/symptom-data';

/** an object of validators to validate each of the soap note sections */
const Validators = {
  symptoms_scores: (scores) => {
    return Object.keys(scores).every(key => {
      const val = parseInt(scores[key].level, 10);
      return !isNaN(val);
    });
  },
  recovery_stage: (stage) => {
    return stage !== '';
  },
  notes: () => {
    return true; /** do not think anything is required here */
  }
};

const defaultSoapPlan = {
  selected: false,
  notes: ''
};

const getSeverity = (symptoms) => {
  return Object.keys(symptoms).reduce((acc, cur) => {
    return acc + (!isNaN(parseInt(symptoms[cur], 10)) ? parseInt(symptoms[cur], 10) : 0);
  }, 0);
};

const getTotals = (symptoms) => {
  return Object.keys(symptoms).reduce((acc, cur) => {
    const val = (!isNaN(parseInt(symptoms[cur], 10)) ? parseInt(symptoms[cur], 10) : 0);
    if (val > 0) {
      return acc + 1;
    }

    return acc;
  }, 0);
};

const MAX_SEVERITY = 6;

const buildSymptomScores = (symptomScores) => {
  return OrderedSymptomKeys.reduce((acc, curr) => {
    acc[curr] = {
      level: symptomScores[curr] || 0
    };

    return acc;
  }, {});
};

const buildNotes = (notes) => {
  const {
    s,
    o,
    a,
    p = {}
  } = notes;

  return {
    s: s || '',
    o: o || '',
    a: a || '',
    p: {
      ...buildSoapPlan(p)
    }
  };
};

const buildSoapPlan = (plan) => {
  const {
    cervical_soft_tissue = {},
    cervical_manipulation = {},
    cervical_mobilization = {},
    vestibular_balance_rehab = {},
    visual_rehab = {},
    education_reassurance = {},
    home_exercise = {},
    other_treatment = {}
  } = plan || {};

  return {
    cervical_soft_tissue: { ...mergePlanWithDefault(cervical_soft_tissue) },
    cervical_manipulation: { ...mergePlanWithDefault(cervical_manipulation) },
    cervical_mobilization: { ...mergePlanWithDefault(cervical_mobilization) },
    vestibular_balance_rehab: { ...mergePlanWithDefault(vestibular_balance_rehab) },
    visual_rehab: { ...mergePlanWithDefault(visual_rehab) },
    home_exercise: { ...mergePlanWithDefault(home_exercise) },
    education_reassurance: { ...mergePlanWithDefault(education_reassurance) },
    other_treatment: { ...mergePlanWithDefault(other_treatment) }
  };
};

const mergePlanWithDefault = (planAction) => {
  return { ...defaultSoapPlan, ...planAction };
};

/** retrieves the state to the soap from props and merges with defaults */
const defaultSoapState = (soap) => {
  const { 
    recovery_protocol_stage,
    symptoms_scores = {},
    notes = {}
  } = soap;

  return {
    recovery_protocol_stage: recovery_protocol_stage || {},
    symptoms_scores: {
      ...buildSymptomScores(symptoms_scores)
    },
    notes: {
      ...buildNotes(notes)
    }
  };
};

const getOpenSoapNotes = (soaps = []) => {
  return (soaps || []).filter(soap => soap.closed === false);
};

const getClinicianOpenNotes = (soaps = [], clinicianId = null) => {
  return (soaps || []).reduce((acc, soap, index) => {
    if (soap.closed === false && String(soap.clinic_user?.id ?? 0) === String(clinicianId)) {
      return [
        ...acc,
        {
          index,
          soap
        }
      ];
    }

    return acc;
  }, []);
};

export {
  defaultSoapState,
  getSeverity,
  getTotals,
  MAX_SEVERITY,
  buildSymptomScores,
  buildNotes,
  Validators,
  buildSoapPlan,
  getOpenSoapNotes,
  getClinicianOpenNotes
};
