import PropTypes from 'prop-types';

/*!
 * Shared proptypes between SymptomInput types
 */
const symptomInputPropTypes = {
  maxSeverity: PropTypes.number,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onUpdate: PropTypes.func,
  notes: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  values: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default symptomInputPropTypes;
