import React, { useMemo } from 'react';
import classnames from 'classnames';
import RecoveryProtocols from './protocols';
import Tooltip from '../tooltip';

const ProgressBar = ({
  stages,
  index,
  className
}) => {
  return (
    <div className="stage-progress-bar">
      {stages.map((s, i) => {
        const active = index >= i;

        return (
          <Tooltip
            portal
            key={s.value}
            // eslint-disable-next-line no-magic-numbers
            offset={[0, 1]}
            enabled={active}
            placement="bottom"
            content={(
              <span style={{ fontSize: 12 }}>
                {s.label}
              </span>
            )}
          >
            <div
              className={classnames('dash', {
                active,
                default: !active,
                [className]: active,
              })}
            />
          </Tooltip>
        );
      })}
    </div>
  );
};

const getStageData = (stages, value) => {
  const index = stages.findIndex(s => s.value === value);
  const stage = index >= 0 ? stages[index] : null;

  return {
    index,
    stage
  };
};

const ProgressIndicator = ({
  label,
  stages = [],
  stage: value
}) => {
  const { index, stage } = useMemo(() => (
    getStageData(stages, value)
  ), [stages, value]);

  return (
    <div className="stage-progress-indicator">
      <div className="stage-progress-label">{label}</div>
      <ProgressBar
        className={stage?.bgClass}
        stages={stages}
        index={index}
      />
    </div>
  );
};

const RTLProgressIndicator = ({ 
  stage
}) => {
  const { stages, label } = RecoveryProtocols.rtl;

  return (
    <ProgressIndicator 
      label={label}
      stages={stages}
      stage={stage}
    />
  );
};

const RTSProgressIndicator = ({
  stage
}) => {
  const { stages, label } = RecoveryProtocols.rts;

  return (
    <ProgressIndicator
      label={label}
      stages={stages}
      stage={stage}
    />
  );
};

const RTWProgressIndicator = ({
  stage
}) => {
  const { stages, label } = RecoveryProtocols.rtw;

  return (
    <ProgressIndicator
      label={label}
      stages={stages}
      stage={stage}
    />
  );
};

export {
  RTLProgressIndicator,
  RTSProgressIndicator,
  RTWProgressIndicator
};
