import { UPDATE_REGISTRATION_ROLE, UPDATE_REGISTRATION_STEP } from '../actions/registration';

const updateRegistrationRoleReducer = (state, action) => {
  const { role } = action;
  return role ? { ...state, role } : state;
};

const updateRegistrationStepReducer = (state, action) => {
  const { step } = action;
  return step ? { ...state, step } : state;
}; 

const registration = (state = {}, action = {}) => {
  switch (action.type) {
    case UPDATE_REGISTRATION_ROLE:
      return updateRegistrationRoleReducer(state, action);
    case UPDATE_REGISTRATION_STEP: 
      return updateRegistrationStepReducer(state, action);
    default:
      return state;
  }
};

export default registration;
