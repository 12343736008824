import {
  DanaTests,
  Metric,
  MAX_ISI_SCORE,
  MAX_PHQ8_SCORE,
  MAX_PHQ9_SCORE,
  MAX_GAD7_SCORE,
  ISIThresholds,
  PHQ8Thresholds,
  PHQ9Thresholds,
  GAD7Thresholds
} from '../helpers';
import Strings from '../lang';

export const getYAxisDescription = (test) => {
  if (test === DanaTests.InsomniaSeverityIndex) {
    return `
      0-7: ${Strings.isiStage1Description}, 
      8-14: ${Strings.isiStage2Description},
      15-21: ${Strings.isiStage3Description},
      22-28: ${Strings.isiStage4Description},
    `;
  }

  if (test === DanaTests.PatientHealthQuestionnaire8) {
    return `
      0-4: ${Strings.phqStage1Description}, 
      5-9: ${Strings.phqStage2Description}, 
      10-14: ${Strings.phqStage3Description}, 
      15-19: ${Strings.phqStage4Description},  
      20-24: ${Strings.phqStage5Description}, 
    `;
  }

  if (test === DanaTests.PatientHealthQuestionnaire9) {
    return `
      0-4: ${Strings.phqStage1Description}, 
      5-9: ${Strings.phqStage2Description}, 
      10-14: ${Strings.phqStage3Description}, 
      15-19: ${Strings.phqStage4Description},  
      20-27: ${Strings.phqStage5Description}, 
    `;
  }

  return null;
};

export const getYAxisTicks = (test) => {
  if (test === DanaTests.InsomniaSeverityIndex) {
    return [
      ...Object.values(ISIThresholds).map(({ min }) => min),
      MAX_ISI_SCORE
    ];
  }

  if (test === DanaTests.PatientHealthQuestionnaire8) {
    return [
      ...Object.values(PHQ8Thresholds).map(({ min }) => min),
      MAX_PHQ8_SCORE
    ];
  }

  if (test === DanaTests.PatientHealthQuestionnaire9) {
    return [
      ...Object.values(PHQ9Thresholds).map(({ min }) => min),
      MAX_PHQ9_SCORE
    ];
  }

  if (test === DanaTests.GeneralizedAnxietyDisorder7) {
    return [
      ...Object.values(GAD7Thresholds).map(({ min }) => min),
      MAX_GAD7_SCORE
    ];
  }

  return undefined;
};

export const DATA_PADDING = 15;

export const getBaselineMetricStats = (baseline, metric) => {
  const iteration = baseline?.length > 1 ? 1 : 0;
  const {
    [`stdev_${metric}`]: stdev,
    [metric]: mean
  } = baseline?.[iteration] || {};

  return {
    mean,
    stdev
  };
};

const yAxisDomainMaxFn = (metric, baseline) => (max) => {
  const defaultMax = max + DATA_PADDING;
  if (!baseline) return defaultMax;

  const { mean, stdev } = getBaselineMetricStats(baseline, metric);

  return Math.max(
    (((stdev * 2) + mean) + DATA_PADDING) || 0,
    defaultMax
  );
};

const yAxisDomainMinFn = (metric, baseline) => (min) => {
  const defaultMin = min - DATA_PADDING;
  if (!baseline) return defaultMin;

  const { mean, stdev } = getBaselineMetricStats(baseline, metric);

  return Math.min(
    ((mean - (stdev * 2)) - DATA_PADDING) || 0,
    defaultMin
  );
};

export const getYAxisDomainMax = (key, metric, stat = {}) => {
  switch (metric) {
    case Metric.PercentCorrect:
      return 100;
    case Metric.SurveyScore:
      switch (key) {
        case DanaTests.PatientHealthQuestionnaire8:
          return MAX_PHQ8_SCORE;
        case DanaTests.PatientHealthQuestionnaire9:
          return MAX_PHQ9_SCORE;
        case DanaTests.InsomniaSeverityIndex:
          return MAX_ISI_SCORE;
        case DanaTests.GeneralizedAnxietyDisorder7:
          return MAX_GAD7_SCORE;
        default:
          return 100;
      }
    case Metric.ReactionTime:
    case Metric.CognitiveEfficiency:
    default:
      return yAxisDomainMaxFn(metric, stat);
  }
};

export const getYAxisDomainMin = (metric, stat) => {
  switch (metric) {
    case Metric.PercentCorrect:
    case Metric.SurveyScore:
      return 0;
    case Metric.ReactionTime:
    case Metric.CognitiveEfficiency:
    default:
      return yAxisDomainMinFn(metric, stat);
  }
};
