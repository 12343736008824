import React, { useMemo } from 'react';
import {
  ResponsiveContainer,
  LineChart,
  Line,
  ReferenceLine,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend
} from 'recharts';
import dayjs from 'dayjs';
import { MAX_SYMPTOM_SEVERITY, OrderedSymptomKeys, SymptomStrings } from '@/utilities/symptom-data';
import { getSeverity, getTotals } from '../../symptoms';
import { DISPLAY_DATE_FORMAT, INPUT_DATE_FORMAT } from '@/utilities/dates/formats';
import { METRIC_KEYS } from './utils';
import { TEST_TYPE_LABEL } from '../utils';
import Strings from '../lang';

const MAX_TOTAL_SEVERITY_TICKS = 13;
const MAX_TOTALS_TICKS = 22;
const MAX_SEVERITY_TICKS = MAX_SYMPTOM_SEVERITY;

const TooltipContent = ({ 
  active,
  payload,
  label
}) => {
  if (active && payload && payload.length) {
    return (
      <div className="symptom-insight-chart-tooltip">
        <div className="heading">{dayjs(label).format(DISPLAY_DATE_FORMAT)}</div>
        <div className="content-container">
          {payload.map((data, index) => (
            <div
              key={index}
              className="content"
              style={{ color: data.color }}
            >
              <div className="label label-primary rounded">
                {TEST_TYPE_LABEL(data.payload.type)}
              </div>
              <div className="metric">{data.name}: {data.payload[data.dataKey]}</div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return null;
};

const getBaselineReferenceLine = (baseline, dataKey) => {
  if (!baseline) return null;

  let value = 0;

  switch (dataKey) {
    case METRIC_KEYS.Totals:
      value = getTotals(baseline || {});
      break;
    case METRIC_KEYS.Severity:
      value = getSeverity(baseline || {});
      break;
    default:
      value = baseline?.[dataKey] ?? 0;
      break;
  }

  return (
    <ReferenceLine
      y={value}
      stroke="#308f5d"
      strokeDasharray="10 5"
      opacity={0.9}
      label={{
        position: 'insideBottomLeft',
        fontSize: 10,
        value: Strings.formatString(Strings.baselineReferenceLabel, value)
      }}
    />
  );
};

const getSymptomData = (symptoms, key) => {
  if (key === METRIC_KEYS.Severity) {
    return { 
      key, 
      name: Strings.symptomSeverityLabel,
      symptoms: symptoms.map(obj => {
        return {
          type: obj.type,
          created_at: obj.created_at,
          level: getSeverity(obj.symptoms)
        };
      })
    };
  }

  if (key === METRIC_KEYS.Totals) {
    return {
      key,
      name: Strings.symptomTotalLabel,
      symptoms: symptoms.map(obj => {
        return {
          type: obj.type,
          created_at: obj.created_at,
          level: getTotals(obj.symptoms)
        };
      })
    };
  }

  return {
    key,
    name: SymptomStrings[key],
    symptoms: symptoms.map(obj => {
      return {
        type: obj.type,
        created_at: obj.created_at,
        level: obj.symptoms[key]
      };
    })
  };
};

const getMaxYTick = (metric) => {
  switch (metric) {
    case METRIC_KEYS.Severity:
      return (MAX_SYMPTOM_SEVERITY * OrderedSymptomKeys.length);
    case METRIC_KEYS.Totals:
      return OrderedSymptomKeys.length;
    default:
      return MAX_SYMPTOM_SEVERITY;
  }
};

const getYTickCount = (metric) => {
  switch (metric) {
    case METRIC_KEYS.Severity:
      return MAX_TOTAL_SEVERITY_TICKS;
    case METRIC_KEYS.Totals:
      return MAX_TOTALS_TICKS;
    default:
      return MAX_SEVERITY_TICKS;
  }
};

const SymptomsChart = ({ 
  metric,
  baseline,
  symptoms = {}
}) => {
  const data = useMemo(() => getSymptomData(symptoms, metric), [symptoms, metric]);

  return (
    <div className="symptoms-chart">
      <ResponsiveContainer width="100%" height={450}>
        <LineChart data={data.symptoms}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="created_at"
            padding={{ left: 0, right: 0 }}
            allowDuplicatedCategory={false}
            tickFormatter={(value) => dayjs(value).format(INPUT_DATE_FORMAT)}
            style={{ fontSize: 12 }}
          />
          <YAxis
            dataKey="level"
            type="number"
            tickCount={getYTickCount(metric)}
            domain={[0, getMaxYTick(metric)]}
            tickFormatter={(value) => Math.round(value)}
          />
          <Line
            connectNulls
            type="monotone"
            dataKey="level"
            name={data.name}
            activeDot={{ r: 7 }}
            stroke="#C03A59"
          />
          {getBaselineReferenceLine(baseline?.symptoms, metric)}
          <Tooltip content={<TooltipContent />} />
          <Legend />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default SymptomsChart;
