import React from 'react';
import { ProductCategories } from '../utils';

const CategoryTags = ({ categories = [] }) => {
  if (!categories?.length) return null;

  return (
    <div className="category-tags">
      {categories.map(category => {
        if (!ProductCategories[category]) return null;

        return (
          <span key={category} className="label label-light">
            {ProductCategories[category]}
          </span>
        );
      })}
    </div>
  );
};

export default CategoryTags;
