import React from 'react';
import { ProfessionStrings, getClinicalServiceStrings } from '@/utilities/clinical-info';
import Strings from './lang';

const ClinicianClinicalInfoCard = ({
  user = {}
}) => {
  const clinicalInfo = user.clinical_info || {};

  return (
    <div className="card card-show">
      <div className="card-row">
        <label>{Strings.professionLabelText}:</label>
        <span>{ProfessionStrings[clinicalInfo.profession] || null}</span>
      </div>

      <div className="card-row">
        <label>{Strings.servicesLabelText}:</label>
        <span>{getClinicalServiceStrings(clinicalInfo.services || [])}</span>
      </div>  
    </div>
  );
};

export default ClinicianClinicalInfoCard;
