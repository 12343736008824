import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormComponent } from '@/forms';
import VOMSTestFormHeader from './voms-test-form-header';
import VOMSTestFormRow from './voms-test-form-row';
import Strings from './lang';
import {
  getVOMSTestState,
  symptomHasDistance,
  symptomHelpDocuments
} from './voms-data';

class VOMSTestForm extends Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      ...getVOMSTestState(props),
      submitted: false
    };
  }

  render() {
    const { symptoms } = this.state;

    return (
      <FormComponent className="voms-test-form" onSubmit={this.onSubmit}>
        <table className="table sticky-header with-nav-bar">
          <thead>
            <VOMSTestFormHeader />
          </thead>
          <tbody>
            {Object.keys(symptoms).map(symptom => (
              <VOMSTestFormRow 
                key={symptom}
                symptomName={Strings[`symptoms_${symptom}`]}
                symptomState={symptoms[symptom] || {}}
                helpDocumentName={symptomHelpDocuments[symptom]}
                onUpdate={(field, value) => this.onUpdateInput(symptom, field, value)}
                hasDistance={symptomHasDistance(symptom)}
                submitted={this.state.submitted}
              />
            ))}
          </tbody>
        </table>

        <div className="form-footer">
          <button type="submit" className="btn btn-primary">
            {Strings.saveButtonText}
          </button>
        </div>
      </FormComponent>
    );
  }

  onUpdateInput(symptom, field, value) {
    this.setState(({ symptoms }) => {
      const newSymptoms = { ...symptoms };
      const state = { ...newSymptoms[symptom], [field]: value };

      newSymptoms[symptom] = state;

      return {
        symptoms: newSymptoms
      };
    });
  }

  isValid() {
    const { symptoms } = this.state;

    return Object.keys(symptoms).every(key => {
      const symptom = symptoms[key];
      return !(!symptom.not_tested && symptom.abnormality === '');
    });
  }

  onSubmit() {
    this.setState({
      submitted: true
    });

    if (!this.isValid()) {
      return;
    }

    const { symptoms } = this.state;

    const {
      baseline,
      smoothPursuits,
      saccadesHorizontal,
      saccadesVertical,
      vorHorizontal,
      vorVertical,
      visualMotorSensitivity,
      accomodationLeft,
      accomodationRight,
      convergence
    } = symptoms;

    const attributes = {
      baseline_symptoms: baseline,
      smooth_pursuits: smoothPursuits,
      saccades_horizontal: saccadesHorizontal,
      saccades_vertical: saccadesVertical,
      vor_horizontal: vorHorizontal,
      vor_vertical: vorVertical,
      visual_motor_sensitivity_test: visualMotorSensitivity,
      accomodation_left: accomodationLeft,
      accomodation_right: accomodationRight,
      convergence
    };

    this.props.onSubmit(attributes);
  }
}

/* eslint-disable react/no-unused-prop-types */
VOMSTestForm.propTypes = {
  test: PropTypes.object,
  onSubmit: PropTypes.func.isRequired
};

export default VOMSTestForm;
