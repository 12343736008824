import React from 'react';
import PropTypes from 'prop-types';
import { RadioInputGroup } from '@/forms';
import { getShortFormMonthsArray } from '@/utilities/dates';
import Strings from './lang';

const months = getShortFormMonthsArray();

const MonthsInReverse = ({
  score,
  touched,
  onScoreChange
}) => (
  <div className="months-in-reverse">
    <div className="months-view">
      <h4>{months.join(' \u2013 ')}</h4>
    </div>
    <div className="months-answer-input">
      <RadioInputGroup
        titleLabelText=""
        radioValues={['0', '1']}
        radioLabels={[Strings.incorrectAnswer, Strings.correctAnswer]}
        initialValue={score.toString()}
        className="baseline-radio-input"
        onUpdate={onScoreChange}
        inputValid={score !== ''}
        touched={touched}
        messageText={Strings.requiredError}
        messageClassName="alert alert-danger"
      />
    </div>
  </div>
);

MonthsInReverse.propTypes = {
  onScoreChange: PropTypes.func,
  score: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  touched: PropTypes.bool
};

export default MonthsInReverse;
