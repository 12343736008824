import SymptomKeys from './keys';
import OrderedSymptomKeys from './ordered-keys';
import { getSymptomLevel } from './symptoms-totals';

const RTLRiskBuckets = {
  physical: [
    SymptomKeys.Headache,
    SymptomKeys.Nausea,
    SymptomKeys.Vomiting,
    SymptomKeys.BalanceProblems,
    SymptomKeys.Dizziness,
    SymptomKeys.BlurredVision,
    SymptomKeys.Fatigue,
    SymptomKeys.LightSensitivity,
    SymptomKeys.NoiseSensitivity
  ],
  cognitive: [
    SymptomKeys.FeelingInAFog,
    SymptomKeys.FeelingSlowedDown,
    SymptomKeys.DifficultyConcentrating,
    SymptomKeys.DifficultyRemembering
  ],
  emotional: [
    SymptomKeys.Irritability,
    SymptomKeys.Sadness,
    SymptomKeys.MoreEmotional,
    SymptomKeys.Nervous
  ],
  sleep: [
    SymptomKeys.Drowsiness,
    SymptomKeys.TroubleFallingAsleep
  ]
};

const MAX_LOW_CATEGORIES = 3;

const getRiskLevel = (count, emotionalCount) => {
  if (count <= MAX_LOW_CATEGORIES) {
    if (emotionalCount >= 2) {
      return 'moderate';
    }

    return 'low';
  }

  return 'high';
};

const getIncludedValue = (type, key) => {
  return RTLRiskBuckets[type].includes(key) ? 1 : 0;
};

export const getRtlRiskSymptomCategories = (symptoms = {}) => {
  const buckets = OrderedSymptomKeys.reduce((acc, key) => {
    const symptom = getSymptomLevel(symptoms[key]);

    return symptom > 0 ? {
      physical: acc.physical + getIncludedValue('physical', key),
      cognitive: acc.cognitive + getIncludedValue('cognitive', key),
      emotional: acc.emotional + getIncludedValue('emotional', key),
      sleep: acc.sleep + getIncludedValue('sleep', key),
    } : acc;
  }, {
    physical: 0,
    cognitive: 0,
    emotional: 0,
    sleep: 0
  });

  const categories = Object.keys(buckets).filter(key => buckets[key] > 0);
  const risk = getRiskLevel(categories.length, buckets.emotional);

  return {
    categories,
    risk
  };
};
