import React from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import registerIcons from './registry';

registerIcons();

const Icon = ({
  name,
  prefix = 'far',
  className,
  ...rest
}) => (
  <FontAwesomeIcon
    icon={[prefix, name]}
    className={classNames('fa', className)}
    {...rest}
  />
);

export default Icon;
