const UPDATE_BASELINE_TEST = 'UPDATE_BASELINE_TEST';

const updateBaselineTest = (baseline) => ({
  type: UPDATE_BASELINE_TEST,
  baseline
});

export {
  UPDATE_BASELINE_TEST,
  updateBaselineTest
};
