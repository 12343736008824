import React from 'react';
import { Page } from '@/components/page';
import { Error404Page } from '@/components/errors';

const PageNotFound = () => (
  <Page>
    <Error404Page />
  </Page>
);

export default PageNotFound;
