import React, { useState } from 'react';
import Dropdown, { MenuItem } from '../dropdown';
import BookingLinkModal from './booking-link-modal';
import DeleteBookingLinkModal from './delete-booking-link-modal';
import Strings from './lang';
import Icon from '../icon';

const BookingLinkActions = ({
  userId,
  link = {},
  clinics = []
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const { id, url, clinic = {} } = link || {};

  return (
    <>
      <Dropdown
        isOpen={dropdownOpen}
        placement="right-start"
        trigger={(
          <button 
            type="button" 
            className="booking-links-actions-button"
            onClick={() => setDropdownOpen(prev => !prev)}
          >
            <Icon name="ellipsis-h" />
          </button>
        )}
        onClose={() => setDropdownOpen(false)}
      >
        <MenuItem
          icon="pen-to-square"
          onSelect={() => setEditModalOpen(true)}
        >
          {Strings.editActionText}
        </MenuItem>
        <MenuItem
          type="danger" 
          icon="trash-can"
          onSelect={() => setDeleteModalOpen(true)} 
        >
          {Strings.deleteActionText}
        </MenuItem>
      </Dropdown>
      <BookingLinkModal 
        isOpen={editModalOpen}
        id={id}
        url={url}
        userId={userId}
        clinics={clinics}
        clinic={clinic}
        onClose={() => setEditModalOpen(false)}
      />
      <DeleteBookingLinkModal 
        isOpen={deleteModalOpen}
        userId={userId}
        linkId={id}
        clinicId={clinic.id}
        onClose={() => setDeleteModalOpen(false)}
      />
    </>
  );
};

export default BookingLinkActions;
