import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { WordListDisplay, WordCheckList, WordLists } from '../word-lists';
import { SelectInputGroup } from '@/forms';
import Strings from './lang';

const DEFAULT_WORD_COUNT = 10;

class DelayedMemory extends Component {
  constructor(props) {
    super(props);

    const { initialScore = '' } = props;

    this.onUpdateSelected = this.onUpdateSelected.bind(this);

    this.state = {
      selected: initialScore
    };
  }

  render() {
    const { wordListNumber = 1, wordCount = DEFAULT_WORD_COUNT, language = 'en' } = this.props;
    const words = WordLists[language][wordCount][wordListNumber];
    const options = [...new Array(words.length + 1)].map((na, index) => {
      return <option key={index} value={index}>{index}</option>;
    });

    return (
      <div className="row">
        <div className="col-md-6">
          {this.state.selected === '' 
            ? (
              <WordCheckList 
                words={words} 
                trialNumber={0} 
                maxTrials={1} 
                onNext={this.onUpdateSelected} 
              />
            ) : (
              <WordListDisplay words={words} />
            )}
          
        </div>
        <div className="col-md-6">
          <SelectInputGroup
            labelText={Strings.correctResponsesLabel}
            className="form-group"
            inputProps={{
              className: 'form-control',
              value: this.state.selected
            }}
            required
            messageClassName="alert alert-danger"
            onUpdate={this.onUpdateSelected}
            touched={this.props.touched}
            valid={this.state.selected !== ''}
          >
            <option value="">Select One</option>
            { options }
          </SelectInputGroup>
        </div>
      </div>
    );
  }

  onUpdateSelected(value) {
    let score = parseInt(value, 10);
    const { onUpdate = () => {} } = this.props;

    if (isNaN(score)) {
      score = '';
    }

    this.setState({
      selected: score
    });
    
    onUpdate({ score });
  }
}

DelayedMemory.propTypes = {
  wordListNumber: PropTypes.number,
  language: PropTypes.string,
  initialScore: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  touched: PropTypes.bool
};

export default DelayedMemory;
