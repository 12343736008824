import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { withRegistrationStep } from '../step-wrappers';
import { registrationUpdateUserAsync } from '@/redux/thunks/users';
import { ErrorBanner } from '../../errors';
import Activity from '../../activity';
import RegistrationAboutPlayerForm from './registration-about-player-form';
import Strings from './lang';

const RegistrationPlayerAbout = ({
  currentUser,
  next,
  role,
  isReturn
}) => {
  const dispatch = useDispatch();
  const [activity, setActivity] = useState(false);
  const [error, setError] = useState(null);
  const subTitle = useMemo(() => {
    if (role === 'guardian') {
      return Strings.formatString(
        Strings.guardianSubTitle,
        currentUser?.person?.first_name ?? ''
      );
    }

    if (isReturn) {
      return Strings.returningSubTitle;
    }

    return null;
  }, [currentUser?.person?.first_name, isReturn, role]);

  const updateUser = useCallback((attributes) => {
    setActivity(true);
    setError(null);

    dispatch(registrationUpdateUserAsync(currentUser?.id, attributes)).then(() => {
      setActivity(false);
      next();
    }).catch(error => {
      setActivity(false);
      setError(error.message);
    });
  }, [currentUser?.id, dispatch, next]);

  return (
    <div className="registration-about">
      <h1 className="display dot text-center">
        {Strings.title}
      </h1>
      {subTitle && (
        <h2 className="text-center">{subTitle}</h2>
      )}
      <ErrorBanner error={error} />
      <Activity active={activity}>
        <RegistrationAboutPlayerForm
          user={currentUser}
          onSubmit={updateUser}
        />
      </Activity>
    </div>
  );
};

export default withRegistrationStep('about')(RegistrationPlayerAbout);
