import React, { forwardRef } from 'react';
import TextareaAutosize from 'react-textarea-autosize';

const TextareaInput = forwardRef(({ rows, minRows, ...rest }, ref) => {
  const _rows = minRows || rows || 1;

  return (
    <TextareaAutosize 
      {...rest}
      ref={ref}
      minRows={_rows}
    />
  );
});

export default TextareaInput;
