import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { If, Then } from 'react-if';
import BaselineTestCardPropTypes from './baseline-test-card-proptypes';
import { FormComponent } from '@/forms';
import { DelayedMemory } from '../test-components';
import Card from '../../card';
import { DEFAULT_USER_LANGUAGE } from '@/utilities/localization';
import HeaderStrings from '../lang';
import Strings from './lang';

const DEFAULT_WORD_COUNT = 10;

class DelayedMemoryCard extends Component {
  constructor(props) {
    super(props);

    const { 
      wordList = 1, 
      wordCount = DEFAULT_WORD_COUNT, 
      language, 
      delayedMemory = {} 
    } = props;
    const { score = '' } = delayedMemory;

    this.onSubmit = this.onSubmit.bind(this);
    this.onUpdate = this.onUpdate.bind(this);

    this.state = {
      wordList,
      wordCount,
      language: language || DEFAULT_USER_LANGUAGE,
      score,
      submitted: false
    };
  }

  render() {
    const { 
      wordList, wordCount, language, score 
    } = this.state;
    return (
      <FormComponent onSubmit={this.onSubmit}>
        <Card title={HeaderStrings.delayedMemoryHeader}>
          <div className="card card-form">
            <DelayedMemory 
              wordListNumber={wordList} 
              wordCount={wordCount}
              language={language}
              initialScore={score}
              onUpdate={this.onUpdate}
              touched={this.state.submitted}
            />
            <If condition={typeof this.props.onSubmit === 'function'}>
              <Then>
                <div className="form-footer">
                  <button type="submit" className="btn btn-primary">
                    {this.props.saveButtonText || Strings.saveButtonText}
                  </button>
                </div>
              </Then>
            </If>
          </div>
        </Card>
      </FormComponent>
    );
  }

  isValid() {
    return this.state.score !== '';
  }

  onUpdate(results) {
    const { score } = results;

    this.setState({
      score
    });
  }

  getAttributes(validate = true) {
    if (validate) {
      this.setState({ submitted: true });

      if (!this.isValid()) {
        return false;
      }
    }

    const { score } = this.state;

    const attributes = {
      delayed_memory_recall_test: {
        score
      }
    };

    return attributes;
  }

  onSubmit() {
    if (typeof this.props.onSubmit === 'function') {
      this.setState({ submitted: true });

      if (!this.isValid()) {
        return;
      }

      this.props.onSubmit(this.getAttributes(false));
    }
  }
}

DelayedMemoryCard.propTypes = {
  ...BaselineTestCardPropTypes,
  wordList: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  language: PropTypes.string,
  delayedMemory: PropTypes.object
};

export default DelayedMemoryCard;
