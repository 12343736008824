const EN = {
  rtsProtocolTitle: 'Return to Sport/Activity',
  rtlProtocolTitle: 'Return to School',
  rtwProtocolTitle: 'Return to Work',
  rtlStage1Label: 'Symptom-limited Cognitive Activity',
  rtlStage1Description: 'Patient is advised to have moderate rest and avoid physical and cognitive activity which significantly increases their symptoms.',
  rtlStage2Label: 'Light Cognitive Activity',
  rtlStage2Description: 'The patient is encouraged to increase engagement in cognitive activity (ie., reading, homework).',
  rtlStage3Label: '½ Days of School - with Restrictions',
  rtlStage3Description: 'The Patient is now permitted to return to school for a maximum of one-half day (morning or afternoon) with certain restrictions.',
  rtlStage4Label: 'Full Days of School - with Restrictions',
  rtlStage4Description: 'The patient is now permitted to attend a full day of school with certain restrictions.',
  rtlStage5Label: 'Full Days of School - No Restrictions',
  rtlStage5Description: 'The patient is now able to fully return to all pre-injury school activities with no accommodations or restrictions.',
  rtwStage1Label: 'Symptom-limited Cognitive Activity',
  rtwStage1Description: 'Patient is advised to have moderate rest and avoid physical and cognitive activity which significantly increases their symptoms.',
  rtwStage2Label: 'Light Cognitive Activity',
  rtwStage2Description: 'The patient is encouraged to increase engagement in cognitive activity (ie., reading, or even working from home).',
  rtwStage3Label: '½ Days of Work - with Restrictions',
  rtwStage3Description: 'The Patient is now permitted to return to work for a maximum of one-half day (morning or afternoon) with certain restrictions.',
  rtwStage4Label: 'Full Days of Work - with Restrictions',
  rtwStage4Description: 'The patient is now permitted to attend a full day of work with certain restrictions.',
  rtwStage5Label: 'Full Days of Work - No Restrictions',
  rtwStage5Description: 'The patient is now able to fully return to all pre-injury work activities with no accommodations or restrictions.',
  rtsStage1Label: 'Symptom-Limited Physical Activity',
  rtsStage1Description: 'Patient is advised to have moderate rest and avoid physical and cognitive activity which significantly increases their symptoms.',
  rtsStage2Label: 'Light Physical Activity',
  rtsStage2Description: 'The patient is required to be seen by the treating CCMI clinician during this stage for a Buffalo Concussion Treadmill Test.',
  rtsStage3Label: 'Sport-Specific Activity',
  rtsStage3Description: 'The patient has passed preliminary physical exertion testing and is now permitted to return to non-contact practice.',
  rtsStage4Label: 'Non-Contact Training Drills',
  rtsStage4Description: 'This stage is designed to increase the athlete\'s heart rate and provide for more challenging, competition like scenarios in a NON-CONTACT format.',
  rtsStage5Label: 'Full Contact Practice',
  rtsStage5Description: 'The athlete is cleared to return to full contact practice.',
  rtsStage6Label: 'Full Return to Contact/Discharge',
  rtsStage6Description: 'The patient has made a full recovery and is ready to fully return to sport and activities.',
  readMoreButtonText: 'Read More',
  stageSelectLabel: 'Stage',
  stageSelectErrorMessage: 'Please select a stage',
  selectStageDefaultOption: 'Select a Stage',
  previousStageLabel: 'Previous Stage',
  stageNotSetLabel: 'Not Set',
  dischargeMessage: 'This will result in this injury being automatically discharged',
  protocolToggleDisableTitle: 'Disable Protocol',
  protocolToggleEnableTitle: 'Enable Protocol',
  viewRestrictionsLabel: 'View Restrictions',
  viewSportRestrictionLabel: 'View Sport Restrictions',
  sportSelectorLabel: 'Sport Restrictions',
  sportSelectorPlaceholder: 'Select a Sport',
  protocolDisabledText: 'This recovery protocol has been disabled.'
};

export default EN;
