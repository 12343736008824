const FR = {
  headache: 'Mal de tête',
  head_pressure: 'Pression dans la tête',
  neck_pain: 'Douleurs au cou',
  nausea: 'Nausée ou vomissements',
  dizziness: 'Étourdissement',
  blurred_vision: 'Vision embrouillée',
  balance_problems: 'Troubles d’équilibre',
  light_sensitivity: 'Sensibilité à la lumière',
  noise_sensitivity: 'Sensibilité au bruit',
  feeling_slowed_down: 'Sensation de ralenti',
  feeling_in_a_fog: 'Flou',
  dont_feel_right: 'Ne se sent pas bien',
  difficulty_concentrating: 'Difficulté à se concentrer',
  difficulty_remembering: 'Difficulté à se souvenir',
  fatigue: 'Fatigue ou baisse d’énergie',
  confusion: 'Confusion',
  drowsiness: 'Somnolence',
  trouble_falling_asleep: 'Difficulté à trouver le sommeil',
  more_emotional: 'Plus émotif',
  irritability: 'Irritabilité',
  sadness: 'Tristesse',
  nervous: 'Nervosité ou anxiété',
  autonomicCategory: 'Dérégulation du système nerveux autonome',
  inflammatoryCategory: 'Inflammatoire/intestin/hormones',
  visualCategory: 'Visuel/Vestibulaire',
  neckCategory: 'Cou',
  psychCategory: 'Santé psychologique/mentale'
};
 
export default FR;
