import FlipBehaviors from './flip-behaviours';

const flip = (behaviour) => ({
  name: 'flip',
  enabled: true,
  options: {
    padding: 8,
    fallbackPlacements: behaviour
  }
});

const offsetConfig = (offset) => ({
  name: 'offset',
  enabled: true,
  options: {
    offset,
  }
});

const computeStyles = () => ({
  name: 'computeStyles',
  enabled: true,
  options: {
    adaptive: false
  }
});

const hide = () => ({
  name: 'hide',
  enabled: true
});

const preventOverflow = () => ({
  name: 'preventOverflow',
  enabled: true,
  options: {
    padding: 8,
    rootBoundary: 'viewport',
    mainAxis: false
  }
});

const getFlipBehavior = type => FlipBehaviors[type];
// eslint-disable-next-line no-magic-numbers
const defaultOffset = [0, 8];

const getModifiers = (
  placement = 'top', 
  offset = defaultOffset
) => {
  const behaviour = getFlipBehavior(placement.split('-')[0]);
  return [
    flip(behaviour), 
    offsetConfig(offset),
    computeStyles(),
    preventOverflow(),
    hide()
  ];
};

export default getModifiers;
