const UPDATE_BUFFALO_TEST = 'UPDATE_BUFFALO_TEST';

const updateBuffaloTest = (userId, test) => ({
  type: UPDATE_BUFFALO_TEST,
  userId,
  test
});

export {
  UPDATE_BUFFALO_TEST,
  updateBuffaloTest
};
