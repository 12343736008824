const EN = {
  baselineTestPageHeading: 'Baseline Test',
  confirmPatientInformationHeader: 'Confirm Patient Information',
  confirmAndContinueText: 'Confirm & Continue',
  confirmProgressInfoText: 'Please confirm the individuals name and baseline test progress.',
  confirmMedicalTitle: 'Confirm Medical History',
  submitMedicalButtonText: 'Save & Start Baseline Test',
  baselineStepProgressTitle: 'Baseline Test Progress',
  baselineContinueCardTitle: 'Continue Baseline Test Progress',
  continueTestButtonText: 'Continue Baseline Test',
  wrongPersonButtonText: 'Wrong Person?',
  skippedLabelText: 'Skipped',
  skipButtonText: 'Skip',
  unsavedFieldsWarningTitle: 'Abandon Step?',
  unsavedFieldsWarningMessage: 'Some fields have been edited and not saved. If you continue, your changes will be lost. Do you wish to continue?',
  continueButtonText: 'Continue',
  unauthorizedHeaderText: 'Unauthorized',
  unauthorizedDescriptionText: 'You do not have the proper permissions to view or edit this section of the baseline test. This section can only be edited and viewed by {0} user(s).',
  saveProgressButtonText: 'Save & Continue',
  baselineCompleteCardTitle: 'Baseline Test Complete',
  baselineCompleteInfoText: '{0}\'s baseline test is now complete. An email with instructions on what to do should a concussion occur as well as links to download the Concussion Tracker app have been sent to {1}. No further actions are required.',
  redactedNameText: 'This Patient',
  viewResultsLinkText: 'View Results',
  returnToDashboardLinkText: 'Return to Dashboard',
  patientFileLinkText: '{0}\'s File',
  stepNotFoundMessage: 'Baseline test step not found',
  loadingBaselineText: 'Loading Baseline Test ...',
  continueBaselineNotFoundError: 'No baseline found to continue',
  ageLabel: 'Age',
  unknownLabel: 'Unknown'
};

export default EN;
