import React from 'react';
import { If, Then } from 'react-if';
import AccessControl from '../access-control';
import { RoleDescriptions } from '@/utilities/user-roles';
import Strings from './lang';
import Icon from '../icon';

const AddNoteButton = ({
  visible = false,
  disabled = false,
  onClick,
}) => {
  return (
    <AccessControl
      roles={[
        RoleDescriptions.Specialist
      ]}
    >
      <If condition={visible}>
        <Then>
          <button
            type="button"
            className="btn btn-default no-print"
            onClick={onClick}
            disabled={disabled}
          >
            <Icon name="plus" />&nbsp;&nbsp;
            {Strings.startspecialistNotesLinkText}
          </button>
        </Then>
      </If>
    </AccessControl>
  );
};

export default AddNoteButton;
