import React, { forwardRef } from 'react';
import { ConcentrationCard } from '../baseline-test-cards';

const ConcentrationStep = forwardRef((
  {
    baseline = {},
    onSubmit,
    ...rest
  },
  ref
) => {
  return (
    <ConcentrationCard
      {...rest}
      ref={ref}
      timedMonthsInReverse={baseline?.test_version > 1}
      concentration={{ ...(baseline?.concentration_test || {}) }}
      onSubmit={onSubmit}
    />
  );
});

ConcentrationStep.hasChanged = (initial, current) => {
  return (initial.score || 0) !== current.score
    || (initial.months_score || '') !== (current.months_score || '')
    || (initial.months_duration ?? null) !== (current.months_duration ?? null)
    || (initial.notes || '') !== current.notes
    || (initial.number_list || '') !== current.number_list;
};

export default ConcentrationStep;
