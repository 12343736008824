const EN = {
  ongoingStatusTitle: 'Ongoing',
  dischargedStatusTitle: 'Discharged',
  lostStatusTitle: 'Lost to Follow-up',
  modalTitle: 'Update Injury Status to {0}',
  dischargedMessageText: 'This patient will have their status changed to <strong><u>{0}</u></strong>. Please ensure the date below is the <strong>exact</strong> discharged date.',
  dischargedDateLabelText: 'Discharged Date:',
  dischargedDateErrorText: 'Discharge date must be between the date of injury ({0}) and today ({1}).',
  dischargeDateConfirmText: 'By continuing you confirm that this patient was discharged on {0}.',
  ongoingLostMessageText: 'This patient will have their status changed to <strong><u>{0}</u></strong>.',
  ongoingLostAcceptLabelText: 'Are you sure you want to change this patient\'s status to {0}?',
  ongoingLostAcceptErrorText: 'You must indicate that you are sure you want to change this patient\'s status.',
  ongoingLostReasonLabelText: 'Reason:',
  ongoingLostReasonErrorText: 'Please enter the reason why you are changing this patient\'s status.',
  cancelButtonTitle: 'Cancel',
  confirmButtonTitle: 'Confirm'
};

export default EN;
