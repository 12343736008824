const isNullish = (value) => value === null || value === undefined;

const wrapArray = (arr) => {
  if (!Array.isArray(arr)) {
    return isNullish(arr) ? [] : [arr];
  }

  return arr;
};

export default wrapArray;
