import React from 'react';
import dayjs from 'dayjs';
import { If, Then, Else } from 'react-if';
import { ErrorBanner } from '../errors';
import { TestHeader } from '../test-header';
import { MedicalTestEditButton } from '../medical-test-edit';
import LastEditedView from '../last-edited-view';
import { BuffaloTestV1Table } from './v1';
import { BuffaloTestV2Table } from './v2';
import Strings from './lang';

const PreTestResults = ({
  version = 1,
  test = {}
}) => (version && version > 1 ? (
  <div className="row">
    <div className="col-md-6">
      <div className="test-header-row">
        <label>{Strings.restingHeartRateLabel}:</label>
        <span>{test.resting_heart_rate}</span>
      </div>
      <div className="test-header-row">
        <label>Resting RPE:</label>
        <span>{test.resting_borg_scale}</span>
      </div>
    </div>
    <div className="col-md-6">
      <div className="test-header-row">
        <label>Resting Symptom Well-Being:</label>
        <span>{test.resting_symptom_score}</span>
      </div>
    </div>
  </div>
) : (
  <div className="row">
    <div className="col-md-6">
      <div className="test-header-row">
        <label>{Strings.restingHeartRateLabel}:</label>
        <span>{test.resting_heart_rate}</span>
      </div>
    </div>
  </div>
));

const BuffaloTestExacerbationDisplay = ({
  version = 1,
  heartRate,
  borgScale,
  time,
  symptoms
}) => (
  <table className="table buffalo-test-exacerbation-table">
    <thead>
      <tr>
        <th className="no-action">{Strings.heartRateLabel}</th>
        <th className="no-action">{Strings.timeLabel}</th>
        {version && version > 1 ? (<th className="no-action">RPE (20pt Borg Scale)</th>) : null}
        <th className="no-action">{Strings.symptomsLabel}</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{heartRate}</td>
        <td>{time}</td>
        {version && version > 1 ? (<td>{borgScale}</td>) : null}
        <td>{symptoms}</td>
      </tr>
    </tbody>
  </table>
);

const BuffaloTestTable = ({ test }) => {
  const { buffalo_version = 1 } = test;

  if (buffalo_version && buffalo_version > 1) {
    return (
      <BuffaloTestV2Table buffalo={test} />
    );
  }

  return (
    <BuffaloTestV1Table buffalo={test} />
  );
};

const BuffaloTestView = ({
  showTestHeader = true,
  test = {},
  user = {},
  injury = {},
  error,
  onEditClick
}) => {
  const version = test.buffalo_version || 1;
  const headerClassName = showTestHeader ? 'display flex-row justify_space-between align_center' : 'bold';
  const HeaderElement = showTestHeader ? 'h1' : 'h2';

  return (
    <div className="buffalo-test-view">

      <div className="row">
        <div className="col-md-12">
          <HeaderElement className={headerClassName}>
            {Strings.title}
            {onEditClick && (
              <MedicalTestEditButton
                className="btn btn-danger btn-sm no-print"
                onClick={onEditClick}
              />
            )}
          </HeaderElement>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <ErrorBanner error={error} />
          <div className="buffalo-test-content">

            <If condition={showTestHeader}>
              <Then>
                <div className="row">
                  <div className="col-md-12">
                    <TestHeader
                      user={user}
                      injury={injury}
                      className="buffalo-test-header"
                      testDate={test.created_at || dayjs()}
                      tester={test.clinic_user}
                      clinic={test.clinic}
                      additionalNode={
                        <PreTestResults version={version} test={test} />
                      }
                    />
                  </div>
                </div>
              </Then>
              <Else>
                <div className="test-header buffalo-test-header">
                  <PreTestResults version={version} test={test} />
                </div>
              </Else>
            </If>

          </div>
        </div>
      </div>

      <LastEditedView item={test} />

      <div className="row">
        <div className="col-md-12">
          <BuffaloTestTable test={test} />
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <h2 className="bold">{Strings.symptomExacerbationHeading}</h2>
          <p>
            <strong>Were symptoms exacerbated?</strong>&nbsp;
            {test.symptom_exacerbation === true ? Strings.yesLabel : Strings.noLabel}
          </p>
          <If condition={test.symptom_exacerbation === true}>
            <Then>
              <BuffaloTestExacerbationDisplay
                version={version}
                heartRate={test.exacerbated_symptoms_heart_rate}
                borgScale={test.exacerbated_symptoms_borg_scale}
                time={test.exacerbated_symptoms_time}
                symptoms={test.exacerbated_symptoms_reported}
              />
            </Then>
          </If>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <p><strong>{Strings.overallNotesLabel}:</strong> {test.notes}</p>
        </div>
      </div>

    </div>
  );
};

export default BuffaloTestView;
