const EN = {
  title: 'Notes',
  notesLabelText: 'Notes',
  notesErrorText: 'Please enter your notes.',
  closeButtonTitle: 'Close',
  cancelButtonTitle: 'Cancel',
  submitButtonTitle: 'Save'
};

export default EN;
