const UPDATE_SOAP_NOTE = 'UPDATE_SOAP_NOTE';
const UPDATE_SOAP_NOTES = 'UPDATE_SOAP_NOTES';

const updateSoapNote = (userId, injuryId, soap) => ({
  type: UPDATE_SOAP_NOTE,
  userId,
  injuryId,
  soap
});

const updateSoapNotes = (userId, injuryId, soapNotes) => ({
  type: UPDATE_SOAP_NOTES,
  userId,
  injuryId,
  soapNotes
});

export {
  UPDATE_SOAP_NOTE,
  UPDATE_SOAP_NOTES,
  updateSoapNote,
  updateSoapNotes
};
