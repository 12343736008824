import React from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import replaceLinkParams from '@/utilities/replace-link-params';
import { ResourcesPaths } from '@/paths';
import Strings from './lang';

const createLink = (file = null, breadcrumbs = [], index = 0) => ({
  to: file 
    ? replaceLinkParams(ResourcesPaths.file.link, { fileId: file.id }) 
    : ResourcesPaths.root.link,
  state: { breadcrumbs: [...breadcrumbs.slice(0, index + 1)] } 
});

const Item = ({ 
  active = false,
  to,
  state,
  children
}) => {
  if (active) {
    return (
      <li className="active">{children}</li>
    );
  }

  return (
    <li>
      <Link
        to={to}
        state={state}
      >
        {children}
      </Link>
    </li>
  );
};

const Breadcrumbs = () => {
  const location = useLocation();
  const params = useParams();
  const { breadcrumbs = [] } = location?.state || {};

  return (
    <ol className="breadcrumb">
      <Item
        active={!params.fileId}
        {...createLink()}
      >
        {Strings.homeBreadcrumbTitle}
      </Item>
      {breadcrumbs.map((crumb, index) => {
        return (
          <Item
            key={crumb.id}
            active={crumb.id === params.fileId}
            {...createLink(crumb, breadcrumbs, index)}
          >
            {crumb.name}
          </Item>
        );
      })}
    </ol>
  );
};

export default Breadcrumbs;
