import React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Sports } from '../../sports';
import { Permissions } from '../patient-profile-utils';
import { UserSelector } from '@/redux/selectors';

const PatientSportsInfo = () => {
  const params = useParams();
  const user = useSelector(state => UserSelector.getUser(state, { userId: params.userId }));
  const currentUser = useSelector(UserSelector.getCurrentUser);

  return (
    <div className="patient-sports-view">
      <Sports
        user={user}
        allowJoinTeam={false}
        currentUser={currentUser}
        editorRoles={Permissions.makePlayerSportsEditorRoles(params.clinicId)}
      />
    </div>
  );
};

export default PatientSportsInfo;
