import OrderedSymptomKeys from './ordered-keys';

export const getSymptomLevel = (symptom) => {
  return parseInt((
    typeof symptom === 'object' && 'level' in symptom
      ? symptom.level
      : symptom
  ) ?? 0, 10);
};

const calculateSymptomsTotals = (symptoms) => {
  const totals = OrderedSymptomKeys.reduce((totals, key) => {
    const { score = 0, count = 0 } = totals;
    const symptom = symptoms[key];
    const symptomValue = getSymptomLevel(symptom);

    if (!isNaN(symptomValue) && symptomValue > 0) {
      return {
        score: score + symptomValue,
        count: count + 1
      };
    }
    
    return totals;
  }, { score: 0, count: 0 });

  totals.symptoms = OrderedSymptomKeys.length;

  return totals;
};

export default calculateSymptomsTotals;
