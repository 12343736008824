import React, { useState } from 'react';
import dayjs from 'dayjs';
import classnames from 'classnames';
import ClinicHoursEditModal from './clinic-hours-edit-modal';
import AccessControl from '../access-control';
import { EditButton } from '../buttons';
import { to12HourTime, getStatusStrings } from './utils';
import Strings from './lang';

const formatTime = (hours) => {
  const { status, open_at, close_at } = hours;
  const statuses = getStatusStrings();

  if (status === 'open') {
    return `${to12HourTime(open_at)} - ${to12HourTime(close_at)}`;
  } 
  
  return statuses[status];
};

const ClinicHoursList = ({
  hours
}) => {
  const currentDay = dayjs().weekday();

  if (!hours) {
    return (
      <div className="clinic-hours-list">
        <i>{Strings.noHoursListText}</i>
      </div>
    );
  }

  return (
    <div className="clinic-hours-list">
      {hours.map(hour => (
        <div 
          key={hour.day} 
          className={classnames(
            'clinic-hours-list-item', 
            { 'semi-bold': currentDay === hour.day }
          )}
        >
          <div>{dayjs.weekdays()[hour.day]}</div>
          <div>{formatTime(hour)}</div>
        </div>
      ))}
    </div>
  );
};

const ClinicHoursDisplay = ({ 
  hours,
  clinicId,
  editableRoles = false
}) => {
  const [editing, setEditing] = useState(false);

  return (
    <div className="clinic-hours-display">
      <div className="clinic-hours-title">
        <h3 className="semi-bold">{Strings.hoursDisplayTitle}</h3>
        <AccessControl roles={editableRoles}>
          <EditButton editing={false} onClick={() => setEditing(true)} />
        </AccessControl>
      </div>
      <ClinicHoursList hours={hours} />
      <ClinicHoursEditModal 
        isOpen={editing} 
        hours={hours}
        clinicId={clinicId}
        onClose={() => setEditing(false)} 
      />
    </div>
  );
};

export default ClinicHoursDisplay;
