const EN = {
  pageTitle: 'Post Injury Assessment',
  searchButtonText: 'Start Assessment',
  newPatientsStartButtonText: 'New Post Injury Assessment',
  activeInjuryErrorText: 'The selected patient already has an ongoing injury. You cannot start an assessment for a new injury until the current injury is discharged.',
  checkInPatientTitle: 'Patient Check-In',
  checkInPatientText: 'Check-In Patient'
};

export default EN;
