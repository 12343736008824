const EN = {
  creditsPluralizedText: 'Credits',
  dateLabel: 'Date',
  typeLabel: 'Type',
  purchaserLabel: 'Purchaser',
  patientLabel: 'Patient',
  usedAtLabel: 'Date Used',
  priceLabel: 'Price',
  invoiceDateLabel: 'Invoice Date',
  invoiceNumberLabel: 'Invoice #',
  titleLabel: 'Title',
  balanceLabel: 'Balance',
  totalLabel: 'Total',
  amountPaidLabel: 'Paid',
  datePaidLabel: 'Date Paid',
  dueDateLabel: 'Due Date',
  subtotalLabel: 'Subtotal',
  invoicesTitle: 'Invoices',
  creditsAvailableLabel: 'Credits Available',
  outstandingInvoicesTabTitle: 'Outstanding Invoices',
  paidInvoicesTabTitle: 'Paid Invoices',
  bulkCreditsTabTitle: 'Bulk Credits',
  invoiceLoadingTitle: 'Loading Invoice {0}',
  invoiceTitle: 'Invoice',
  invoiceStatusLabel: 'Status',
  invoiceLoadErrorMessage: 'Failed to load invoice: {0}',
  clinicLoadErrorMessage: 'Failed to load clinic: {0}',
  unknownErrorMessage: 'Unknown Error',
  emptyOutstandingInvoicesMessage: 'No Outstanding Invoices',
  invoicesLoadErrorMessage: 'Failed to load invoices: {0}',
  emptyPaidInvoicesMessage: 'No Paid Invoices',
  recievePaymentTitle: 'Recieve Payment',
  paymentCreatedSuccessMessage: 'Payment Created Successfully!',
  bulkImpactBaselinesLabel: 'Bulk ImPACT Baselines',
  codeUsesLabel: '{0} Code Uses',
  totalRecipientsLabel: '{0} Recipients',
  paymentsLabel: 'Payments',
  groupedImpactCodesLabel: 'ImPACT Codes',
  groupedCreditsLabel: 'Credits',
  groupedMapleConsultsLabel: 'Maple Consults',
  creditLabel: 'Credit',
  impactCodeLabel: 'ImPACT Code',
  mapleConsultLabel: 'Maple Consult',
  emptyLineItemsMessage: 'No Line Items',
  externalPatientLabel: 'External Patient',
  deleteLineItemLabel: 'Delete',
  deleteLineItemConfirmTitle: 'Delete Line Item?',
  deleteLineItemConfirmMessage: 'Are you sure you want to delete this line item?',
  deleteLineItemConfirmButton: 'Yes, Delete',
  deleteLineItemSuccessMessage: 'Line item deleted successfully!',
  deleteLineItemErrorMessage: 'Unable to delete line item: {0}',
  invoiceToHeader: 'Invoice To',
  balanceDueLabel: 'Balance Due',
  invoiceFooterInterestMessage: 'Thank you for your business. Please note that outstanding balances will be invoiced at the end of each calendar month.  Payment not received within 30 days of the invoice date, will be subject to a 15% interest charge which will be added to future invoices.',
  invoiceFooterContactMessage: 'If you have any questions or concerns regarding this invoice please contact Pamela Gonzalez-Silva at pamela@completeconcussions.com',
  activityHeaderLabel: 'Activity',
  quantityHeaderLabel: 'Qty',
  rateHeaderLabel: 'Rate',
  amountHeaderLabel: 'Amount',
  downloadPdfButtonText: 'Download PDF',
  pdfDownloadingButtonText: 'Downloading...',
  pushCreditsTabLabel: 'Push Credits',
  addLineItemTitle: 'Add Line Item',
  lineItemTypeLabel: 'Line Item Type',
  selectLineItemTypeOption: 'Select Line Item Type',
  lineItemTypeErrorMessage: 'Please select a line item type',
  amountErrorMessage: 'Enter a value greater than 0',
  patientAccountLabel: 'Patient Account #',
  postInjuryImpactFieldLabel: 'Post-Injury ImPACT?',
  postInjuryImpactLabel: 'Post-Injury',
  selectCategoryOptionLabel: 'Select Category',
  categoryLabel: 'Category',
  categoryErrorMessage: 'Please select a category',
  submitButtonText: 'Submit',
  latePaymentFeeLabel: 'Late Payment Fee',
  latePaymentFeeBulkLabel: 'Late Payment Fees',
  editLineItemLabel: 'Edit',
  editLineItemTitle: 'Edit Line Item',
  taxesAppliedLabel: 'Taxes Applied',
  noTaxesAppliedLabel: 'No Tax',
  customTaxLabel: 'Custom',
  taxCodeLabel: 'Tax Code',
  taxRateLabel: 'Tax Rate',
  descriptionLabel: 'Description',
  descriptionErrorMessage: 'Please provide a description of the line item',
  customChargeLabel: 'Custom Charge',
  noteLabel: 'Note',
  noteElementTitle: 'Comment - Click to View',
  closeDateLabel: 'Close Date',
  editCloseDateTitle: 'Edit Close Date',
  editDueDateTitle: 'Edit Due Date',
  minDateErrorMessage: 'Please enter a date greater than {0}',
  addBulkCreditsActionLabel: 'Add Bulk Credits',
  closeInvoiceActionLabel: 'Close Invoice',
  actionsButtonLabel: 'Actions',
  closeInvoiceMessage: 'If this invoice has a zero (0) balance, it will be marked as PAID when closed',
  statusOpenLabel: 'Open',
  statusClosedLabel: 'Closed',
  createButtonLabel: 'Create',
  createInvoiceTitle: 'Create Invoice',
  invoiceMemoTitle: 'Invoice Memo',
  emptyMemoText: 'No invoice memo',
  groupedPostInjuryImpactLabel: 'Post-Injury ImPACT Codes',
  taxRegistrationLabel: '{0} Registration No.: {1}',
  invoiceSummaryTitle: 'Invoice #{0} Summary',
  retryLoadInvoiceLabel: 'Retry',
  invoicePaidLabel: 'Invoice has already been paid'
};

export default EN;
