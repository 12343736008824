import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../icon';
import CommunicationStrings from '../sections/communication-actions/lang';
import Strings from './lang';

const PostInjuryCommunication = ({
  actions = {}
}) => (
  <div className="post-injury-section post-injury-communication">
    <div className="row">
      <div className="col-md-12">
        <h2 className="bold">{Strings.communicationTitle}</h2>
      </div>
    </div>
    <div className="post-injury-group">
      <div className="row">
        <div className="col-md-12">
          <ul className="list-unstyled">
            {Object.keys(actions).map(key => (
              <li key={key}>
                <Icon name={actions[key] === true ? 'check' : 'xmark'} />&nbsp;
                {CommunicationStrings[key]}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>

  </div>
);

PostInjuryCommunication.propTypes = {
  actions: PropTypes.object.isRequired
};

export default PostInjuryCommunication;
