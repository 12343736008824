import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { parseMarkdown } from '@/utilities/parse-markdown';

const SCROLL_POSITION_ALLOWANCE = 20;

class PolicyScrollBox extends Component {
  constructor(props) {
    super(props);

    this._contentElement = null;
    this._onContentScroll = this._onContentScroll.bind(this);

    this.state = {
      content: '',
      disabled: true,
      error: null
    };
  }

  componentDidMount() {
    this._fetchPolicy();
  }

  componentWillUnmount() {
    this._removeScrollListener();
  }

  render() {
    const __html = (this.state.error) ? this.state.error.message : this.state.content;
    return (
      <div className={classNames('policy-scroll-box', this.props.className)}>
        <div className="policy-content" ref={this._configureScrollListener.bind(this)}>
          <div className="policy-scroll" dangerouslySetInnerHTML={{ __html }} />
        </div>
        <div className="policy-footer">
          <p className={this.props.footerMessageClassName}>
            {this.props.footerMessage}
          </p>
          <div 
            className={classNames({
              flash: !this.state.disabled
            })}
          >
            {this.props.children(this.state.disabled)}
          </div>
        </div>
      </div>
    );
  }

  _fetchPolicy() {
    let { policyUrl } = this.props;

    // Append a cache busting query param:
    if (this.props.preventCache) {
      const timestamp = Date.now();
      policyUrl = `${policyUrl}?t=${timestamp}`;
    }

    fetch(policyUrl).then(response => {
      return response.text();
    }).then(text => {
      const content = parseMarkdown(text);
      this.setState({ content, error: null });
    }).catch(error => {
      this.setState({ error });
    });
  }

  _configureScrollListener(element) {
    if (!this._contentElement) {
      this._contentElement = element;
      this._contentElement.addEventListener('scroll', this._onContentScroll);
    }
  }

  _removeScrollListener() {
    if (this._contentElement) {
      this._contentElement.removeEventListener('scroll', this._onContentScroll);
    }
  }

  _onContentScroll() {
    if (this._contentElement) {
      const scrollElement = this._contentElement.querySelector('.policy-scroll');
      const { scrollTop, offsetHeight } = this._contentElement;
      if (scrollTop + offsetHeight >= scrollElement.offsetHeight - SCROLL_POSITION_ALLOWANCE) {
        this.setState({ disabled: false });
      }
    }
  }
}

PolicyScrollBox.propTypes = {
  className: PropTypes.string,
  footerMessage: PropTypes.string,
  footerMessageClassName: PropTypes.string,
  policyUrl: PropTypes.string.isRequired,
  preventCache: PropTypes.bool
};

export default PolicyScrollBox;
