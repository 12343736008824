const FR = {
  baselineSymptomsHeader: 'Niveaux des symptômes du test de référence',
  currentRatedSymptomsHeader: 'Niveau des symptômes actuels',
  noActiveBaselineWarning: 'Ce patient n’a pas de test de référence actif',
  symptomExertionTitleText: 'Effort : Est-ce que les symptômes s’aggravent en cas de :',
  physicalActivityLabel: 'Activité physique',
  mentalActivityLabel: 'Activité mentale',
  activityYesLabel: 'Oui',
  activityNoLabel: 'Non',
  activityHaventTriedLabel: 'N’a pas essayé',
  overallRatingLabel: 'Évaluation globale',
  overallRatingInfoText: 'Si un parent, un tuteur ou un conjoint est présent dans la pièce, demandez-lui « est-ce que [la personne] agit différemment que d’habitude ? »',
  overallRatingNormalText: '(Agit normalement)',
  overallRatingVeryDifferentText: '(Agit très différemment)',
  noValueSelectedErrorMessage: 'Veuillez choisir une valeur'
};
 
export default FR;
