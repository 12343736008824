import React, { useMemo } from 'react';
import Panel, { usePanel } from '../../panel';
import TrialsChart from './trials-chart';
import { DanaTests, getTestValidityIconProps } from '../helpers';
import Strings from '../lang';
import Icon from '../../icon';

const CognitivePanelsHeading = () => {
  return (
    <div className="section-header">
      <div className="test-stat test-type" />
      <div className="test-stat">
        {Strings.cognitiveEfficiencyLabel}
      </div>
      <div className="test-stat">
        {Strings.reactionTimeLabel} &plusmn; {Strings.standardDeviationLabel}
      </div>
      <div className="test-stat" />
    </div>
  );
};

const TestPanelHeader = ({ test }) => {
  const { collapsed } = usePanel();
  const {
    test: type,
    mean_thru,
    mean_rt,
    stdev_rt,
    is_valid,
    percent_correct,
    iteration = 1
  } = test;

  const iconProps = useMemo(() => (
    getTestValidityIconProps(type, is_valid, percent_correct, iteration)
  ), [type, is_valid, percent_correct, iteration]);

  return (
    <div className="dana-test-panel-header">
      <div className="test-stat test-type">
        <Icon name={`chevron-${collapsed ? 'right' : 'down'}`} />
        <img
          alt={test.test}
          src={`/images/dana/${type.toLowerCase()}.png`}
        />
        <div>{type}</div>
      </div>
      <div className="test-stat ce">
        {mean_thru}
      </div>
      <div className="test-stat rt">
        {mean_rt} &plusmn; {stdev_rt}
      </div>
      <div className="test-stat percent-correct">
        <Icon {...iconProps} />
      </div>
    </div>
  );
};

const average = (values = []) => values.reduce((acc, val) => acc + (val || 0), 0) / values.length;

const getTrialAverages = (trials = []) => {
  const { mal, normal } = trials.reduce((acc, trial) => {
    return {
      ...acc,
      mal: trial.malingering ? [...acc.mal, trial.response_time] : acc.mal,
      normal: !trial.malingering ? [...acc.normal, trial.response_time] : acc.normal,
    };
  }, { mal: [], normal: [] });

  return {
    malAvg: average(mal),
    normalAvg: average(normal)
  };
};

const MalingeringAverages = ({ trials = [] }) => {
  const { malAvg, normalAvg } = useMemo(() => getTrialAverages(trials), [trials]);

  return (
    <>
      <tr>
        <td>{Strings.malingeringAvgLabel}</td>
        <td align="right">{malAvg?.toFixed(2)}</td>
      </tr>
      <tr>
        <td>{Strings.nonMalingeringAvgLabel}</td>
        <td align="right">{normalAvg.toFixed(2)}</td>
      </tr>
    </>
  );
};

const TestDetails = ({ test }) => {
  const {
    num_trials,
    mean_thru,
    test: testType,
    mean_rt,
    stdev_rt,
    med_rt,
    iteration,
    mean_rt_correct,
    stdev_rt_correct,
    percent_correct,
    percent_lapse,
    percent_fast,
    trials = []
  } = test;

  return (
    <>
      {trials.length > 0 && (
        <div className="row">
          <div className="col-md-12">
            <TrialsChart 
              type={testType}
              iteration={iteration}
              trials={trials}
            />
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-md-7">
          <table className="table dana-cognitive-table">
            <tbody>
              <tr>
                <td>{Strings.cognitiveEfficiencyLabel}</td>
                <td align="right">{mean_thru}</td>
              </tr>
              <tr>
                <td>{Strings.meanReactionTimeLabel}</td>
                <td align="right">{mean_rt} &plusmn; {stdev_rt}</td>
              </tr>
              <tr>
                <td>{Strings.meanReactionTimeCorrectLabel}</td>
                <td align="right">{mean_rt_correct} &plusmn; {stdev_rt_correct}</td>
              </tr>
              <tr>
                <td>{Strings.medianReactionTimeLabel}</td>
                <td align="right">{med_rt}</td>
              </tr>
              {trials.length > 0 && testType === DanaTests.MatchToSample && (
                <MalingeringAverages trials={trials} />
              )}
            </tbody>
          </table>
        </div>
        <div className="col-md-5">
          <table className="table dana-cognitive-table">
            <tbody>
              <tr>
                <td>{Strings.numTrialsLabel}</td>
                <td align="right">{num_trials}</td>
              </tr>
              <tr>
                <td>{Strings.percentCorrectLabel}</td>
                <td align="right">{percent_correct}% ({Math.round(num_trials * (percent_correct / 100.0))})</td>
              </tr>
              <tr>
                <td>{Strings.percentLapseLabel}</td>
                <td align="right">{percent_lapse}%</td>
              </tr>
              <tr>
                <td>{Strings.percentFastLabel}</td>
                <td align="right">{percent_fast}%</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

const CognitiveTestPanel = ({ test }) => {
  return (
    <Panel>
      <Panel.Heading>
        <TestPanelHeader test={test} />
      </Panel.Heading>
      <Panel.Content>
        <TestDetails test={test} />
      </Panel.Content>
    </Panel>
  );
};

const CognitiveTestDetails = ({ tests = [] }) => {
  if (!tests.length) return null;

  return (
    <div className="dana-details-section">
      <CognitivePanelsHeading />
      <Panel.Group
        collapsible
        className="dana-panels"
      >
        {tests.map((test) => (
          <CognitiveTestPanel
            key={test.test_order}
            test={test}
          />
        ))}
      </Panel.Group>
    </div>
  );
};

export default CognitiveTestDetails;
