const EN = {
  trialColumnTitle: 'Trial',
  durationColumnTitle: 'Duration (seconds)',
  outcomeColumnTitle: 'Pass/Fail',
  trialNumberLabel: 'Trial {0}',
  fastestTimeLabel: 'Fastest Time (s)',
  averageTimeLabel: 'Average Time (s)',
  secondsLabel: 'seconds',
  startTimerLabel: 'Start Timer',
  stopTimerLabel: 'Stop Timer',
  resetTimerLabel: 'Reset Timer'
};

export default EN;
