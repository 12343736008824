import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BuffaloTestV2FormHeader from './buffalo-test-form-header';
import BuffaloTestV2TableRow from './buffalo-test-table-row';
import { createBuffaloTestTable } from './helpers';

class BuffaloTestV2Table extends Component {
  render() {
    return (
      <table className="table buffalo-test-table">
        <thead>
          <BuffaloTestV2FormHeader />
        </thead>
        <tbody>
          {this.renderBuffaloTestRows()}
        </tbody>
      </table>
    );
  }

  renderBuffaloTestRows() {
    const { buffalo = {} } = this.props;
    const { results } = buffalo;
    const { minute_intervals } = results;

    return createBuffaloTestTable(minute_intervals, ({ 
      index, 
      minute, 
      speed, 
      incline, 
      item 
    }) => (
      <BuffaloTestV2TableRow
        key={index}
        minute={minute}
        speed={speed}
        incline={incline}
        borgScale={item.borg_scale}
        symptomScore={item.symptom_score}
        symptoms={item.symptoms}
        heartRate={item.heart_rate}
      />
    ));
  }
}

BuffaloTestV2Table.propTypes = {
  buffalo: PropTypes.object
};

export default BuffaloTestV2Table;
