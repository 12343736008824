import React from 'react';
import { PatientMeta } from '../patient-meta';
import { PrintMeta, PrintButton } from '../print';
import AccessControl from '../access-control';
import { Permissions } from './patient-profile-utils';
import { BagtagModalButton } from '../bagtag-modal';
import Strings from './lang';

const PatientProfileHeader = ({
  user = {},
  clinic = {}
}) => {
  return (
    <>
      <PrintMeta patient={user} clinic={clinic} />
      <div className="patient-profile-header">
        <PatientMeta
          patient={user}
          clinicId={clinic.id}
          nameTagProps={{ className: 'display' }}
          nameRedactedText="Patient Profile"
        />
        <div className="profile-header-actions no-print">
          <AccessControl roles={Permissions.makeBagTagEditorRoles(clinic.id)}>
            <BagtagModalButton 
              user={user}
              skippable={false}
              clinicId={clinic.id}
              className="btn btn-sm btn-default" 
            >
              {Strings.assignBagTagButtonText}
            </BagtagModalButton>
          </AccessControl>
          <PrintButton />
        </div>
      </div>
    </>
  );
};

export default PatientProfileHeader;
