import { useEffect, useRef } from 'react';

export const usePrevious = (value) => {
  const previous = useRef(value);

  useEffect(() => {
    previous.current = value;
  }, [value]);

  return previous.current;
};
