import React, { useCallback } from 'react';
import classnames from 'classnames';
import { usePanel } from './panel-context';

const PanelHeading = ({ 
  sticky = null,
  children 
}) => {
  const { collapsible, collapsed, onToggle } = usePanel();
  const onClick = useCallback((e) => {
    if (e.defaultPrevented) return;
    onToggle(!collapsed);
  }, [collapsed, onToggle]);

  return (
    <div 
      className={classnames('panel-heading', {
        'collapse-toggle': collapsible,
        opened: collapsible ? !collapsed : true,
        sticky: sticky !== null && sticky >= 0
      })} 
      onClick={collapsible && onClick}
      style={{ top: sticky }}
    >
      {children}
    </div>
  );
};

export default PanelHeading;
