import { NumberValidator } from '@/forms';

const MIN_HEART_RATE = 30;
const MAX_HEART_RATE = 100;

const restingHRValidator = (hrRest) => {
  const hr = parseInt(hrRest, 10);
  return NumberValidator.test(hrRest) && (hr >= MIN_HEART_RATE && hr < MAX_HEART_RATE);
};

export {
  MIN_HEART_RATE,
  MAX_HEART_RATE,
  restingHRValidator
};
