import React from 'react';
import dayjs from 'dayjs';
import classnames from 'classnames';
import { INPUT_DATE_FORMAT } from '@/utilities/dates/formats';
import Icon from '../../icon';
import { OrderedSymptomKeys, SymptomStrings } from '@/utilities/symptom-data';
import { TEST_TYPE_LABEL } from '../utils';
import Strings from '../lang';

const StatusIcon = ({ value }) => {
  if (value < 0) return <Icon prefix="fas" name="caret-up" className="error" style={{ marginRight: 2 }} />;
  if (value > 0) return <Icon prefix="fas" name="caret-down" className="success" style={{ marginRight: 2 }} />;
  return null;
};

const RecentSymptomComparisonTable = ({
  baseline = {},
  symptoms = []
}) => {
  const hasBaseline = !!baseline?.symptoms;
  const mostRecent = symptoms?.[(symptoms?.length ?? 0) - 1];
  const initialAssessment = symptoms?.find(obj => obj.type === 'InjuryAssessment');
  
  if (!mostRecent) return null;

  return (
    <div className="recent-symptoms-comparison">
      <h2>{Strings.recentSymptomsTableTitle}</h2>
      <div className="table-responsive-md">
        <table className="table">
          <thead>
            <tr>
              <th className="no-action">{Strings.symptomColumnTitle}</th>
              <th className="no-action">
                {Strings.formatString(
                  Strings.mostRecentColumnLabel, 
                  TEST_TYPE_LABEL(mostRecent.type), 
                  dayjs(mostRecent.created_at).format(INPUT_DATE_FORMAT)
                )}
              </th>
              {!!initialAssessment && (
                <th className="no-action">{Strings.initialAssessmentLabel}</th>
              )}
              {hasBaseline && (
                <th className="no-action">{Strings.baselineLabel}</th>
              )}
            </tr>
          </thead>
          <tbody>
            {OrderedSymptomKeys.map(key => {
              const changeFromAssessment = (initialAssessment?.symptoms[key] ?? 0)
                - (mostRecent?.symptoms[key] ?? 0);
              const changeFromBaseline = (baseline?.symptoms[key] ?? 0) 
                - (mostRecent.symptoms[key] ?? 0);
              return (
                <tr 
                  key={key} 
                  className={classnames({ success: hasBaseline && changeFromBaseline >= 0 })}
                >
                  <td>{SymptomStrings[key]}</td>
                  <td>{mostRecent?.symptoms[key]}</td>
                  {!!initialAssessment && (
                    <td>
                      {initialAssessment.symptoms[key]}&nbsp;
                      (<StatusIcon value={changeFromAssessment} />
                      {Math.abs(changeFromAssessment)})
                    </td>
                  )}
                  {hasBaseline && (
                    <td>
                      {baseline.symptoms[key] ?? 0}&nbsp;
                      (<StatusIcon value={changeFromBaseline} />
                      {Math.abs(changeFromBaseline)})
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RecentSymptomComparisonTable;
