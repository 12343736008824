/*
* Converts address keys from the format used 
* by the AddressFormInputs component to the format 
* used by the API.
*/
const convertAddressKeys = (address = {}) => {
  const {
    street1 = '',
    street2 = '',
    city = '',
    state = '',
    postalCode = '',
    country = ''
  } = address;

  return {
    street_1: street1,
    street_2: street2,
    city,
    state,
    postal_code: postalCode,
    country,
  };
};

export default convertAddressKeys;
