const UPDATE_INJURY_REPORTS = 'UPDATE_INJURY_REPORTS';
const UPDATE_INJURY_REPORT = 'UPDATE_INJURY_REPORT';

const updateInjuryReports = (userId, injuryReports) => ({
  type: UPDATE_INJURY_REPORTS,
  userId,
  injuryReports
});

const updateInjuryReport = (userId, injuryReport) => ({
  type: UPDATE_INJURY_REPORT,
  userId,
  injuryReport
});

export {
  UPDATE_INJURY_REPORTS,
  updateInjuryReports,
  UPDATE_INJURY_REPORT,
  updateInjuryReport
};
