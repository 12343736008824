const FR = {
  addressSearchLabelText: 'Recherche d’adresse',
  streetLabelText: 'Rue',
  streetErrorText: 'Veuillez indiquer le numéro et le nom de rue :',
  cityLabelText: 'Ville / municipalité',
  cityErrorText: 'Veuillez indiquer la ville ou la municipalité :',
  stateLabelText: 'État / province',
  stateErrorText: 'Veuillez indiquer l’État ou la province :',
  postalCodeLabelText: 'Code postal / Zip',
  postalCodeErrorText: 'Veuillez indiquer le code postal / Zip :',
  countryLabelText: 'Pays',
  countryErrorText: 'Veuillez indiquer le pays :',
  showFieldsLinkText: 'Développer tous les champs',
  hideFieldsLinkText: 'Cacher tous les champs'
};
 
export default FR;
