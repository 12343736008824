import React, { Component } from 'react';
import { withRegistrationStep } from '../step-wrappers';
import { getCurrentRegistrationSession } from '@/utilities/registration-session';
import { RoleDescriptions } from '@/utilities/user-roles';
import { Sports } from '../../sports';
import Strings from './lang';

const getOptions = () => {
  return { headers: { Authorization: getCurrentRegistrationSession().token } };
};

class RegistrationSports extends Component {
  render() {
    const { currentUser = {} } = this.props;
    const { teams = [] } = currentUser;
    
    return (
      <div className="registration-sports">
        <h1 className="display text-center">{Strings.title}</h1>
        {teams.length === 0 && (
          <div className="alert-highlight-warning">
            <span>
              {this.getMessageText()}&nbsp;
              <button 
                type="button"
                className="edit-button" 
                onClick={this.props.next}
              >
                {Strings.skipStepText} &gt;&gt;
              </button>
            </span>
          </div>
        )}
        <Sports 
          user={currentUser} 
          currentUser={currentUser}
          editorRoles={[RoleDescriptions.Player]}
          requestOptions={getOptions()}
        />
        <div className="sports-footer">
          <button 
            type="button"
            className="btn btn-default" 
            onClick={this.props.next} 
            style={{ width: '100%' }}
          >
            {Strings.doneButtonText}
          </button>
        </div>
      </div>
    );
  }

  getMessageText() {
    const { currentUser = {}, role } = this.props;
    const { person = {} } = currentUser;

    return role === 'guardian' ? Strings.formatString(
      Strings.guardianNoSportsAlertMessage,
      person.first_name
    ) : Strings.noSportsAlertMessage;
  }
}

const RegistrationSportsStep = withRegistrationStep('sports')(RegistrationSports);

export default RegistrationSportsStep;
