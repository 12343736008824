const EN = {
  title: 'Concussion ID Cards',
  generateTabLabelText: 'Generate',
  manageTabLabelText: 'Manage',
  batchNameLabelText: 'Name',
  batchNameErrorMessageText: 'Please enter a name for this batch of Concussion ID Cards.',
  batchSizeLabelText: 'Number of Concussion ID Cards',
  batchSizeErrorMessageText: 'Please enter the number of ID Cards you want to create.',
  generateButtonText: 'Generate',
  dateTableHeadingLabelText: 'Date',
  nameTableHeadingLabelText: 'Name',
  sizeTableHeadingLabelText: 'Number of ID Cards',
  tagNumberTableHeadingLabelText: 'Concussion ID Card',
  dateUsedTableHeadingLabelText: 'Date Assigned',
  batchMetadataText: 'Batch number {0} created on {1}',
  assignedBagTagText: 'Assigned',
  filterLabelText: 'Filter ID Cards',
  exportCSVLinkText: 'Export CSV',
  actionsHeadingText: 'Quick Actions'
};

export default EN;
