import React, { useCallback, useState } from 'react';
import { FormComponent } from '@/forms';
import ClinicHoursInputTable from './clinic-hours-input-table';
import { getInitialHours } from './utils';
import Strings from './lang';

const ClinicHoursForm = ({
  hours: initialHours,
  onSubmit = () => {}
}) => {
  const [hours, setHours] = useState(() => getInitialHours(initialHours));

  const handleSubmit = useCallback(() => {
    onSubmit(hours);
  }, [hours, onSubmit]);

  return (
    <FormComponent onSubmit={handleSubmit}>
      <ClinicHoursInputTable 
        hours={hours}
        onUpdate={setHours}
      />
      <button type="submit" className="btn btn-primary">
        {Strings.saveButtonText}
      </button>
      <span className="clearfix" />
    </FormComponent>
  );
};

export default ClinicHoursForm;
