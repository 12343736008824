import React, { Component } from 'react';

class FileUploadButton extends Component {
  constructor(props) {
    super(props);

    this.fileInput = React.createRef();
    this.onSelectFiles = this.onSelectFiles.bind(this);
    this._handleUploadClick = this._handleUploadClick.bind(this);
  }

  render() {
    const { 
      className, buttonClassName, children, accept, multiple 
    } = this.props;

    return (
      <div className={className}>
        <button 
          type="button" 
          className={buttonClassName}
          onClick={this._handleUploadClick}
        >
          {children}
        </button>
        <input 
          multiple={multiple}
          ref={this.fileInput}
          type="file" 
          accept={accept}
          onChange={this.onSelectFiles}
          style={{ display: 'none' }}
        />
      </div>
    );
  }

  _handleUploadClick(e) {
    e.preventDefault();
    this.fileInput.current.click();
  }

  onSelectFiles(e) {
    const { files } = e.target;

    if (this.props.onSelectFiles) {
      this.props.onSelectFiles(files);
    }
  }
}

export default FileUploadButton;
