import React from 'react';
import classnames from 'classnames';
import Overlay from '../overlay';
import DrawerContent from './content';

const Drawer = ({
  isOpen = false,
  fade = false,
  blur = false,
  blurNode,
  position = 'left',
  appearance = 'dark',
  overlayDisabled = false,
  contentClassName,
  className,
  scrollLock = true,
  onClose = () => {},
  children
}) => {
  return (
    <Overlay
      isOpen={isOpen}
      blur={blur}
      className={classnames('drawer', className)}
      blurNode={blurNode}
      static={overlayDisabled}
      scrollLock={scrollLock}
      appearance={appearance}
      onClose={onClose}
    >
      <DrawerContent
        fade={fade}
        position={position}
        className={contentClassName}
      >
        {children}
      </DrawerContent>
    </Overlay>
  );
};

export default Drawer;
