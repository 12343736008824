import React, { useCallback } from 'react';
import classnames from 'classnames';

const Switch = ({
  toggled = false,
  disabled = false,
  title = null,
  onChange = () => {}
}) => {
  const handleChange = useCallback(e => {
    onChange(e.target.checked);
  }, [onChange]);

  return (
    <label title={title} className={classnames('switch', { toggled, disabled })}>
      <input 
        type="checkbox" 
        disabled={disabled} 
        checked={toggled} 
        onChange={handleChange} 
      />
      <div className="track">
        <div className="toggle" />
      </div>
    </label>
  );
};

export default Switch;
