import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import Modal from '../modal';
import ReferralMap from './referral-map';
import Activity from '../activity';
import { AlertBanner } from '../alert-notifications';
import { createReferralAsync } from '@/redux/thunks/referrals';
import { getCurrentClinicId } from '@/tokens';
import { ReferralsPath } from '@/paths';
import Strings from './lang';
import Icon from '../icon';

const ReferralModal = ({
  isOpen = false,
  injuryId,
  onClose = () => {},
  onComplete = () => {}
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const onReferral = useCallback(({ message, userId = null, clinicId }) => {
    setLoading(true);
    const currentClinicId = getCurrentClinicId();

    const attributes = {
      message,
      referred_to_id: userId,
      referred_to_clinic_id: clinicId,
      referrer_clinic_id: currentClinicId,
      injury_id: injuryId,
      referral_url: `${process.env.CCMI_UI_HOST}${ReferralsPath.email.link}`
    };

    dispatch(createReferralAsync(attributes)).then((referral) => {
      setLoading(false);
      onComplete(referral);
    }).catch(error => {
      setError(error.message);
      setLoading(false);
    });
  }, [dispatch, injuryId, onComplete]);

  return (
    <Modal
      blur
      animation="fade"
      isOpen={isOpen}
      onClose={onClose}
      className="referral-modal"
    >
      <Activity active={loading}>
        <ReferralMap onReferral={onReferral} />
        {error && (
          <AlertBanner 
            type="error"
            options={{
              dismissable: false,
              message: Strings.formatString(Strings.createReferralError, error)
            }}
          />
        )}
      </Activity>
      <button type="button" className="close-button" onClick={onClose}>
        <Icon name="xmark" />
      </button>
    </Modal>
  );
};

export default ReferralModal;
