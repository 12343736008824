import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { If, Then } from 'react-if';
import { DEFAULT_USER_LANGUAGE } from '@/utilities/localization';
import { DISPLAY_DATE_FORMAT } from '@/utilities/dates/formats';
import Languages from '@/utilities/languages';
import Strings from './lang';

const TestMeta = ({
  test = {},
  user = {}
}) => {
  const person = user.person || {};
  const language = person.language || DEFAULT_USER_LANGUAGE;

  return (
    <ul className="list-unstyled">
      <li>
        <strong>{Strings.testDateLabelText}</strong>&nbsp;
        {dayjs(test.created_at).format(DISPLAY_DATE_FORMAT)}
      </li>
      <li>
        <strong>{Strings.languageLabelText}</strong>&nbsp;
        {Languages[language]}
      </li>
      {test.last_updated_by && (
        <li>
          <strong>{Strings.lastEditedLabelText}</strong>&nbsp;
          {Strings.formatString(
            Strings.lastEditedByText, 
            dayjs(test.updated_at).format(DISPLAY_DATE_FORMAT), 
            test.last_updated_by
          )}
        </li>
      )}
      {!!test.archived_at && (
        <li>
          <div>
            <strong>
              {Strings.formatString(
                Strings.archivedMessage,
                dayjs(test.archived_at).format(DISPLAY_DATE_FORMAT),
                test.archived_by)}:
            </strong>
          </div>
          <div>{test.archived_reason}</div>
        </li>
      )}
    </ul>
  );
};

const BaselineMeta = ({
  test = {}, 
  compareTest = {}, 
  compare = false, 
  user = {}
}) => (
  <div className="baseline-section baseline-meta">
    <div className="row">
      <div className={(compare) ? 'col-md-6' : 'col-md-12'}>
        <TestMeta 
          test={test}
          user={user}
        />
      </div>
      <If condition={compare === true}>
        <Then>
          <div className="col-md-6">
            <TestMeta 
              test={compareTest}
              user={user}
            />
          </div>
        </Then>
      </If>
    </div>
  </div>
);

BaselineMeta.propTypes = {
  user: PropTypes.object.isRequired,
  test: PropTypes.object.isRequired,
  compareTest: PropTypes.object,
  compare: PropTypes.bool
};

export default BaselineMeta;
