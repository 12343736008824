import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import Strings from './lang';
import RoleStrings from '@/utilities/role-strings';
import { ClinicPaths, UserPaths } from '@/paths';
import replaceLinkParams from '@/utilities/replace-link-params';
import Icon from '../icon';

const SORT_KEYS = {
  LAST_NAME: 'person.last_name',
  ROLES: 'roles'
};

const ClinicUsersTable = ({
  clinic,
  users,
  sortKeyPath,
  onUpdateSortKeyPath
}) => {
  const currentUserId = useSelector(state => state.session?.current_user_id);

  const sortByLastName = useCallback(() => {
    onUpdateSortKeyPath?.(SORT_KEYS.LAST_NAME);
  }, [onUpdateSortKeyPath]);

  const sortByRoles = useCallback(() => {
    onUpdateSortKeyPath?.(SORT_KEYS.ROLES);
  }, [onUpdateSortKeyPath]);

  return (
    <table className="table users-table clinic-users-table">
      <thead>
        <tr>
          <th
            className={classNames({ highlighted: sortKeyPath === SORT_KEYS.LAST_NAME })}
            onClick={sortByLastName}
          >
            {Strings.nameHeadingText}
          </th>
          <th
            className={classNames({ highlighted: sortKeyPath === SORT_KEYS.ROLES })}
            onClick={sortByRoles}
          >
            {Strings.rolesHeadingText}
          </th>
        </tr>
      </thead>
      <tbody>
        {users.map(user => {
          const isCurrentUser = String(user.id) === String(currentUserId);
          const { person } = user;

          if (person) {
            const roles = user.roles || [];
            return (
              <tr key={user.id}>
                <td className="highlighted">
                  <div className="flex-row align_center">
                    {isCurrentUser && (
                      <><Icon name="user" className="primary" fontSize={12} />&nbsp;</>
                    )}
                    <Link
                      to={(
                        isCurrentUser
                          ? UserPaths.account.link
                          : replaceLinkParams(ClinicPaths.userProfile.link, {
                            clinicId: clinic.id,
                            userId: user.id
                          })
                      )}
                    >
                      {person.first_name} {person.last_name}
                    </Link>
                  </div>
                </td>
                <td className="highlighted">
                  {roles.map(role => RoleStrings[role]).join(', ')}
                </td>
              </tr>
            );
          }

          return null;
        })}
      </tbody>
    </table>
  );
};

export default ClinicUsersTable;
