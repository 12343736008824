const EN = {
  title: 'Physician Contact',
  guardianSubtitle: 'Enter contact information for {0}\'s primary physician',
  returningSubtitle: 'Update contact information for your primary physician',
  guardianNoPhysicianText: '{0} does not have a primary physician.',
  noPhysicianText: 'I do not have a primary physician.',
  skipStepText: 'Skip this step',
  physicianNameLabelText: 'Physician Name',
  physicianNameErrorText: 'Please enter your physician\'s name',
  phoneLabelText: 'Phone Number',
  phoneErrorText: 'Please enter your physician\'s phone number.',
  faxLabelText: 'Fax Number',
  faxErrorText: 'Please enter your physician\'s fax number.',
  addressLabelText: 'Address',
  submitButtonText: 'Submit',
  doesChildHavePhysician: 'Does {0} currently have a primary physician?',
  doYouHavePhysician: 'Do you currently have a primary physician?',
  yesLinkText: 'Yes',
  noLinkText: 'No'
};

export default EN;
