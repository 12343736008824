import React from 'react';
import Strings from './lang';

const QuickActions = ({ children }) => (
  <div className="quick-actions">
    <h3>{Strings.title}</h3>
    <div className="quick-actions-content">
      <ul className="list-unstyled">
        {children}
      </ul>
    </div>
  </div>
);

export default QuickActions;
