const EN = {
  hockey: 'Hockey',
  soccer: 'Soccer/European Football',
  football: 'American/Canadian Football',
  boxlacrosse: 'Box Lacrosse',
  fieldlacrosse: 'Field Lacrosse',
  lacrosse: 'Lacrosse',
  rugby: 'Rugby',
  basketball: 'Basketball',
  baseball: 'Baseball',
  volleyball: 'Volleyball',
  gymnastics: 'Gymnastics',
  cheerleading: 'Cheerleading',
  dancing: 'Dancing',
  mma: 'Mixed Martial Arts',
  boxing: 'Boxing',
  kickboxing: 'Kickboxing',
  cycling: 'Cycling',
  bmx: 'Mountain Biking/BMX',
  motocross: 'Motocross',
  motorcycling: 'Motorcycle Racing',
  skateboarding: 'Skateboarding',
  skiing: 'Ski/Snowboarding',
  figureskating: 'Figure Skating',
  surfing: 'Surfing',
  dodgeball: 'Dodgeball',
  curling: 'Curling',
  ringette: 'Ringette',
  wrestling: 'Wrestling',
  equestrian: 'Equestrian',
  fieldhockey: 'Field Hockey',
  cricket: 'Cricket',
  aussierules: 'Australian Rules Football',
  other: 'Other'
};

export default EN;
