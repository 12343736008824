import React from 'react';
import { RecoveryStagesView } from '../recovery-stages';
import Strings from './lang';

const SoapNoteRecoveryStageDisplay = ({ stages }) => {
  return (
    <div className="soap-note-stages-display">
      <h2>{Strings.injuryStageSectionTitle}</h2>
      <RecoveryStagesView stages={stages} />
    </div>
  );
};

export default SoapNoteRecoveryStageDisplay;
