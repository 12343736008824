const FR = {
  title: 'Blessures',
  soapLinkText: 'Créer une remarque SOAP pour la blessure {0}',
  createButtonText: 'Créer une blessure',
  dateErrorMessage: 'Veuillez indiquer une date valide avant ou égale à celle d’aujourd’hui',
  startAssessmentButtonText: 'Commencer l’évaluation',
  injuryDateLabel: 'Date de la blessure',
  injuryDateCardHeader: 'Date de la blessure',
  daysSinceInjuryConfirmationMessage: '{1}. En continuant, vous confirmez que ce patient est à {0} après la blessure',
  postInjuryAssessmentTitle: 'Nouvelle blessure'
};
 
export default FR;
