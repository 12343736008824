const ClinicPaths = {
  index: {
    route: 'clinics',
    link: '/clinics'
  },
  stats: {
    route: 'stats',
    link: '/clinics/stats'
  },
  register: {
    route: 'register',
    link: '/clinics/register'
  },
  profile: {
    route: ':clinicId',
    link: '/clinics/:clinicId'
  },
  patients: {
    route: ':clinicId/patients',
    link: '/clinics/:clinicId/patients'
  },
  referrals: {
    index: {
      route: ':clinicId/referrals',
      link: '/clinics/:clinicId/referrals'
    }
  },
  patientProfile: {
    index: {
      route: ':clinicId/patients/:userId',
      link: '/clinics/:clinicId/patients/:userId'
    },
    profile: {
      route: 'profile',
      link: '/clinics/:clinicId/patients/:userId/profile'
    },
    sports: {
      route: 'sports',
      link: '/clinics/:clinicId/patients/:userId/sports'
    },
    medical: {
      route: 'medical',
      link: '/clinics/:clinicId/patients/:userId/medical'
    },
    baselines: {
      index: {
        route: 'baselines',
        link: '/clinics/:clinicId/patients/:userId/baselines'
      },
      archived: {
        route: 'archived',
        link: '/clinics/:clinicId/patients/:userId/baselines/archived'
      },
      show: {
        route: ':baselineId',
        link: '/clinics/:clinicId/patients/:userId/baselines/:baselineId'
      }
    },
    injuries: {
      index: {
        route: 'injuries',
        link: '/clinics/:clinicId/patients/:userId/injuries'
      },
      assessment: {
        route: ':injuryId/assessment/:assessmentId',
        link: '/clinics/:clinicId/patients/:userId/injuries/:injuryId/assessment/:assessmentId'
      },
      blackhawks: {
        route: ':injuryId/blackhawks/:blackhawksId',
        link: '/clinics/:clinicId/patients/:userId/injuries/:injuryId/blackhawks/:blackhawksId'
      },
      buffalo: {
        route: ':injuryId/buffalo/:buffaloId',
        link: '/clinics/:clinicId/patients/:userId/injuries/:injuryId/buffalo/:buffaloId'
      },
      voms: {
        route: ':injuryId/voms/:vomsId',
        link: '/clinics/:clinicId/patients/:userId/injuries/:injuryId/voms/:vomsId'
      },
      ovs: {
        route: ':injuryId/ovs/:ovsId',
        link: '/clinics/:clinicId/patients/:userId/injuries/:injuryId/ovs/:ovsId'
      },
      postInjuryTest: {
        route: ':injuryId/post-injury-tests/:testId/compare/:compareContext',
        link: '/clinics/:clinicId/patients/:userId/injuries/:injuryId/post-injury-tests/:testId/compare/:compareContext'
      },
      symptomUpdates: {
        route: ':injuryId/symptom-updates',
        link: '/clinics/:clinicId/patients/:userId/injuries/:injuryId/symptom-updates'
      },
      insights: {
        route: ':injuryId/insights',
        link: '/clinics/:clinicId/patients/:userId/injuries/:injuryId/insights'
      },
      soapNotes: {
        index: {
          route: ':injuryId/soaps',
          link: '/clinics/:clinicId/patients/:userId/injuries/:injuryId/soaps'
        },
        show: {
          route: ':soapId',
          link: '/clinics/:clinicId/patients/:userId/injuries/:injuryId/soaps/:soapId'
        },
        archived: {
          route: 'archived',
          link: '/clinics/:clinicId/patients/:userId/injuries/:injuryId/soaps/archived'
        }
      },
      specialistNotes: {
        index: {
          route: ':injuryId/specialist-notes',
          link: '/clinics/:clinicId/patients/:userId/injuries/:injuryId/specialist-notes'
        },
      },
      progressReports: {
        route: ':injuryId/progress-reports',
        link: '/clinics/:clinicId/patients/:userId/injuries/:injuryId/progress-reports' 
      },
      referrals: {
        route: ':injuryId/referrals',
        link: '/clinics/:clinicId/patients/:userId/injuries/:injuryId/referrals' 
      }
      /* add all viewable tests */
    },
    danaTests: {
      route: 'dana-tests',
      link: '/clinics/:clinicId/patients/:userId/dana-tests'
    },
    balanceTests: {
      route: 'balance',
      link: '/clinics/:clinicId/patients/:userId/balance'
    },
    reported: {
      index: {
        route: 'reported',
        link: '/clinics/:clinicId/patients/:userId/reported'
      },
      show: {
        route: ':reportId',
        link: '/clinics/:clinicId/patients/:userId/reported/:reportId'
      }
    }
  },
  invoices: {
    index: {
      route: ':clinicId/invoices',
      link: '/clinics/:clinicId/invoices',
    },
    details: {
      route: ':clinicId/invoices/:invoiceId',
      link: '/clinics/:clinicId/invoices/:invoiceId'
    },
    pay: {
      route: ':clinicId/invoices/:invoiceId/pay',
      link: '/clinics/:clinicId/invoices/:invoiceId/pay'
    }
  },
  users: {
    route: ':clinicId/users',
    link: '/clinics/:clinicId/users'
  },
  userProfile: {
    route: ':clinicId/users/:userId',
    link: '/clinics/:clinicId/users/:userId'
  }
};

export default ClinicPaths;
