import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import Drawer from '../drawer';
import { ErrorBanner } from '../errors';
import Activity from '../activity';
import AddBulkCreditsForm from './add-bulk-credits-form';
import Icon from '../icon';
import { createBulkCreditsAsync } from '@/redux/thunks/clinic-invoices';

const BulkCreditsPurchaseDrawer = ({
  isOpen = false,
  title,
  clinicId,
  invoiceId,
  currency,
  createBulkCredits,
  onClose = () => {}
}) => {
  const [activity, setActivity] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = useCallback((attrs) => {
    setActivity(true);
    setError(null);

    const attributes = {
      ...attrs,
      invoiceId
    };

    createBulkCredits(clinicId, attributes).then(() => {
      setActivity(false);
      onClose();
    }).catch(error => {
      setError(error.message);
      setActivity(false);
    });
  }, [clinicId, createBulkCredits, invoiceId, onClose]);

  return (
    <Drawer
      blur
      isOpen={isOpen}
      position="right"
      overlayDisabled={activity}
      onClose={onClose}
    >
      <div className="line-item-drawer">
        <div className="drawer-close">
          <button 
            type="button" 
            className="close" 
            disabled={activity}
            onClick={onClose}
          >
            <Icon name="xmark" />
          </button>
        </div>
        <div className="line-item-drawer-content">
          <h1 className="display">
            {title}
          </h1>
          <ErrorBanner error={error} />
          <Activity active={activity}>
            <AddBulkCreditsForm 
              currency={currency}
              disabled={activity}
              onSubmit={handleSubmit}
            />
          </Activity>
        </div>
      </div>
    </Drawer>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    createBulkCredits: (clinicId, attributes) => {
      return dispatch(createBulkCreditsAsync(clinicId, attributes));
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(BulkCreditsPurchaseDrawer);
