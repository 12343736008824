const EN = {
  balanceTestTitle: 'Balance Tests',
  allTestsSelectorLabel: 'All',
  baselineTestsSelectorLabel: 'Baseline',
  postInjuryTestsSelectorLabel: 'Post-Injury',
  allPosesSelectorLabel: 'All Poses',
  overallScoreLabel: 'Overall Score',
  feetTogetherPoseLabel: 'Feet Together Pose',
  tandemRightPoseLabel: 'Tandem Right Pose',
  tandemLeftPoseLabel: 'Tandem Left Pose',
  singleLegRightPoseLabel: 'Single Leg Right Pose',
  singleLegLeftPoseLabel: 'Single Leg Left Pose',
  loadingTestsMessage: 'Loading Balance Tests',
  testsEmptyMessage: 'No Balance Tests Found',
  baselineLineLabel: 'Baseline Score ({0}%)',
  baselineUpperStddevLabel: 'Baseline Upper Std. Deviation ({0}%)',
  baselineLowerStddevLabel: 'Baseline Lower Std. Deviation ({0}%)',
  baselineTagLabel: 'Baseline',
  postInjuryTagLabel: 'Post-Injury'
};

export default EN;
