class ApiDeserializer {
  #cache;
  #keys;
  
  constructor() {
    this.#cache = new Map();
    this.#keys = new Set();
  }

  deserialize(json) {
    const { data, included, ...rest } = json || {};
    let deserializedData = {};

    if (data) {
      if (Array.isArray(data)) {
        deserializedData = data.map(obj => this.#deserializeData(obj, included));
      } else {
        deserializedData = this.#deserializeData(data, included);
      }
    }

    return {
      data: deserializedData,
      ...rest
    };
  }

  #deserializeData(data, included = []) {
    const {
      id, type, attributes = {}, relationships = {}
    } = data || {};

    if (!id) return attributes;

    const cacheKey = `${type}_${id}`;

    // prevent deserialization if already deserialized
    const cache = this.#cache.get(cacheKey);
    if (cache) return cache;

    // prevent circular references
    if (this.#keys.has(cacheKey)) return null;
    this.#keys.add(cacheKey);

    const relations = this.#mapRelations(relationships, included);
    const newData = {
      id,
      ...attributes,
      ...relations
    };

    this.#cache.set(cacheKey, newData);

    return newData;
  }

  #mapRelations(relationships, included = []) {
    const relations = {};

    Object.keys(relationships || {}).forEach(key => {
      const { data: relationData } = relationships[key];

      if (Array.isArray(relationData)) {
        relations[key] = relationData.map(relation => (
          this.#deserializeRelationship(relation, included)
        ));
      } else {
        relations[key] = this.#deserializeRelationship(relationData, included);
      }
    });

    return relations;
  }

  #deserializeRelationship(relationship, included = []) {
    const { id, type } = relationship || {};
    const includedData = included.find(include => include.id === id && include.type === type);

    if (includedData) {
      return this.#deserializeData(includedData, included);
    }

    return null;
  }
}

function deserialize(json) {
  return new ApiDeserializer().deserialize(json);
}

export default deserialize;
