const isEmpty = (obj) => {
  return Object.keys(obj).length === 0;
};

const findIncompleteSteps = (baseline, profile) => {
  return profile.filter((key) => {
    return baseline?.[key] && isEmpty(baseline[key]);
  });
};

export {
  isEmpty,
  findIncompleteSteps
};
