import { useRef, useCallback, useLayoutEffect } from 'react';

const buildNode = (className) => {
  const newNode = document.createElement('div');

  if (className) {
    newNode.setAttribute('class', className);
  }
  
  return newNode;
};

const useNodeAttribute = (node, name, attr) => {
  useLayoutEffect(() => {
    if (node) {
      if (attr) {
        node.setAttribute(name, attr);
      } else {
        node.removeAttribute(name);
      }
    }
  }, [name, attr, node]);
};

const useNodeRef = (className) => {
  const node = useRef(null);

  if (!node.current) {
    node.current = buildNode(className);
  }

  return node;
};

const useNode = (parent, className) => {
  const node = useNodeRef(className);
  const mounted = useRef(false);

  const insert = useCallback((cb = () => {}) => {
    if (node.current && !mounted.current && !node.current.parentNode) {
      parent.appendChild(node.current);
      mounted.current = true;
      cb();
    }
  }, [node, parent]);

  const destroy = useCallback((cb = () => {}) => {
    if (node.current && mounted.current && node.current.parentNode) {
      node.current.parentNode.removeChild(node.current);
      mounted.current = false;
      cb();
    }
  }, [node]);

  useNodeAttribute(node.current, 'class', className);

  return [
    node.current,
    {
      insert,
      destroy
    },
    mounted.current
  ];
};

export default useNode;
