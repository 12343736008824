const UPDATE_REGISTRATION_ROLE = 'UPDATE_REGISTRATION_ROLE';
const UPDATE_REGISTRATION_STEP = 'UPDATE_REGISTRATION_STEP';

const updateRegistrationRole = (role) => ({
  type: UPDATE_REGISTRATION_ROLE,
  role
});

const updateRegistrationStep = (step) => ({
  type: UPDATE_REGISTRATION_STEP,
  step
});

export {
  updateRegistrationRole,
  UPDATE_REGISTRATION_ROLE,
  updateRegistrationStep,
  UPDATE_REGISTRATION_STEP
};
