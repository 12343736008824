import Strings from './lang';

const FootballPositions = [
  { key: 'offensiveguard', name: Strings.football_offensiveguard },
  { key: 'offensivetackle', name: Strings.football_offensivetackle },
  { key: 'quarterback', name: Strings.football_quarterback },
  { key: 'runningback', name: Strings.football_runningback },
  { key: 'widereceiver', name: Strings.football_widereceiver },
  { key: 'tightend', name: Strings.football_tightend },
  { key: 'defensivetackle', name: Strings.football_defensivetackle },
  { key: 'defensiveend', name: Strings.football_defensiveend },
  { key: 'middlelinebacker', name: Strings.football_middlelinebacker },
  { key: 'outsidelinebacker', name: Strings.football_outsidelinebacker },
  { key: 'cornerback', name: Strings.football_cornerback },
  { key: 'safety', name: Strings.football_safety },
  { key: 'nickleback', name: Strings.football_nickleback },
  { key: 'dimeback', name: Strings.football_dimeback },
  { key: 'kicker', name: Strings.football_kicker },
  { key: 'holder', name: Strings.football_holder },
  { key: 'longsnapper', name: Strings.football_longsnapper },
  { key: 'punter', name: Strings.football_punter },
  { key: 'puntreturner', name: Strings.football_puntreturner },
  { key: 'kickreturner', name: Strings.football_kickreturner },
  { key: 'upback', name: Strings.football_upback },
  { key: 'gunner', name: Strings.football_gunner }
];

export default FootballPositions;
