const FR = {
  title: 'Rapports de blessure',
  emptyMessageText: 'Ce patient n’a jamais eu encore de blessure rapportée.',
  accountLabelText: 'COMPTE',
  birthdateLabelText: 'DATE DE NAISSANCE',
  injurydateLabelText: 'DATE INDIQUÉE',
  ageLabelText: 'ÂGE',
  genderLabelText: 'SEXE',
  bagtagLabelText: 'Numéro de carte d’identité',
  injuryInformationTitle: 'Informations sur la blessure',
  redFlagsReportedTitle: 'Alertes :',
  symptomsTitle: 'Symptômes',
  associationLabel: 'Association',
  schoolNameLabel: 'Nom de l’école',
  schoolNameQuestionLabel: 'Cette blessure est-elle survenue à l\'école?',
  lossConsciousnessLabel: 'Y a-t-il eu une perte de conscience?',
  lossConsciousnessDurationLabel: 'Durée',
  sportRelatedLabel: 'Était-ce une blessure liée au sport?',
  sportLabel: 'Quel sport?',
  swallowLabel: 'Difficulté à avaler',
  seizuresLable: 'Attaques',
  slurredSpeedLabel: 'Difficulté à parler, à articuler, ou à comprendre lorsqu’on parle',
  headacheLabel: 'Maux de tête sévères ou s’aggravant',
  confusionLabel: 'État de confusion sévère ne s’améliorant pas',
  neckPainLabel: 'Douleur sévère dans le cou ou incapacité à tourner complètement la tête dans une direction ou l’autre',
  lostConsciousnessLabel: 'Perte de conscience pendant plus de 1 minute',
  vomitingLabel: 'Vomissement',
  armsOrLegsWeaknessLabel: 'Faiblesses dans les bras ou les jambes',
  assessedText: 'Évalué',
  startAssessmentTitle: 'Commencer l’évaluation',
  noRedFlagsReported: 'Aucune alerte relevée',
  secondsText: 'secondes',
  minutesText: 'minutes',
  ongoingInjuryAssessmentMessage: 'Êtes-vous certain de vouloir commencer l’évaluation d’une blessure ? Une évaluation de la blessure sera ajoutée à la blessure actuelle de ce patient.',
  startAssessmentConfirmMessage: 'Êtes-vous certain de vouloir commencer l’évaluation d’une blessure ?',
  bessScoreLabel: 'Résultat du test BESS',
  orientationScoreLabel: 'Résultat du test d’orientation',
  memoryScoreLabel: 'Résultat du test de mémoire',
  notCompletedTestText: 'Incomplet',
  actionTakenLabel: 'Action prise',
  actionTaken_proceed: 'Suite avec évaluation',
  actionTaken_hospital: 'Envoyé à l\'hôpital',
  actionTaken_ambulance: 'Appelé une ambulance',
  reportedByLabelText: 'Rapporté par',
  injuryDescription: 'Description de la blessure, signalée en marge',
  noInjuryDescription: 'Aucune description de blessure fournie',
  hitToHeadLabelText: 'Y a-t-il eu un impact direct sur la tête?',
  hitToHeadLocationLabel: 'Emplacements d\'impact de tête',
  hitToHeadFront: 'De face',
  hitToHeadRight: 'Droite',
  hitToHeadLeft: 'La gauche',
  hitToHeadTop: 'Haut',
  hitToHeadBack: 'Arrière',
  noHeadHitLocationSelected: 'Aucun emplacement d\'impact de la tête fourni',
  notProvidedLabel: 'Pas Achevé',
  yesAnswer: 'Oui',
  noAnswer: 'Non',
  unknownLOCDurationUnit: '(unité inconnue)',
  neurocognitiveTestSectionTitle: 'Test neurocognitif'
};
 
export default FR;
