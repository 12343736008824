import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormInputGroup, NumberValidator } from '@/forms';
import Strings from './lang';

class ForcePlate extends Component {
  constructor(props) {
    super(props);

    const { initialState = {} } = props;
    const { right_foot_cop = '', left_foot_cop = '' } = initialState;

    this.state = {
      left: {
        valid: left_foot_cop !== '',
        value: left_foot_cop
      },
      right: {
        valid: right_foot_cop !== '',
        value: right_foot_cop
      }
    };
  }
  
  render() {
    const { left, right } = this.state;
    return (
      <div className="row">
        <div className="col-md-6">
          <FormInputGroup
            className="form-group"
            labelText={Strings.leftFootInputLabel}
            inputProps={{
              className: 'form-control',
              value: left.value,
              onBlur: this.onUpdate.bind(this, 'left'),
              onChange: this.onUpdate.bind(this, 'left')
            }}
            inputValid={left.valid}
            messageClassName="alert alert-danger"
            messageText={Strings.errorMessageText}
            required
            touched={this.props.touched}
          />
        </div>
        <div className="col-md-6">
          <FormInputGroup
            className="form-group"
            labelText={Strings.rightFootInputLabel}
            inputType="text"
            inputProps={{
              className: 'form-control',
              value: right.value,
              onBlur: this.onUpdate.bind(this, 'right'),
              onChange: this.onUpdate.bind(this, 'right')
            }}
            inputValid={right.valid}
            messageClassName="alert alert-danger"
            messageText={Strings.errorMessageText}
            required
            touched={this.props.touched}
          />
        </div>
      </div>
    );
  }

  onUpdate(key, e) {
    const { value } = e.target;
    const { onUpdate = () => {} } = this.props;
    const { right, left } = this.state;
    const foot = key === 'right' ? right : left;

    foot.value = value;
    foot.valid = NumberValidator.test(value) && isFinite(value) && parseFloat(value) >= 0;

    this.setState({
      [key]: foot
    });

    onUpdate({
      right_foot_cop: right.value,
      left_foot_cop: left.value
    }, left.valid && right.valid);
  }
}

ForcePlate.propTypes = {
  onUpdate: PropTypes.func,
  initialState: PropTypes.shape({
    right_foot_cop: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    left_foot_cop: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  })
};

export default ForcePlate;
