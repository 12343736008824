import React from 'react';
import Strings from '../lang';

const BuffaloTestFormHeader = () => (
  <tr>
    <th className="no-action">{Strings.timeLabel}</th>
    <th className="no-action">{Strings.inclineLabel}</th>
    <th className="no-action">{Strings.heartRateRowLabel}</th>
    <th className="no-action">{Strings.symptomsLabel}</th>
  </tr>
);

export default BuffaloTestFormHeader;
