const FR = {
  diagnosis_provided: 'Donné au patient un diagnostic',
  finding_provided: 'Donné au patient des observations pertinentes',
  education_provided: 'Donné au patient des informations sur le plan de gestion et le déroulement et le rétablissement attendu des commotions',
  app_link_provided: 'Donné au patient un lien de téléchargement de l’application de suivi pour téléphone intelligent de CCMI Concussion Tracker, pour voir de l’information sur les commotions cérébrales, le régime alimentaire à suivre, et téléverser leurs symptômes quotidiennement',
  education_short: 'Donné au patient de l’information sur les commotions cérébrales',
  app_short: 'Donné au patient de l’information sur l’application de suivi pour téléphone intelligent',
  saveButtonText: 'Sauvegarder',
  yes: 'Oui',
  no: 'Non'
};
 
export default FR;
