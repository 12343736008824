import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { useDropdown } from './context';
import Icon from '../icon';

const Item = ({ link, children }) => {
  if (link) {
    return (
      <Link to={link} className="item">
        {children}
      </Link>
    );
  }

  return (
    <div className="item">
      {children}
    </div>
  );
};

const MenuItem = ({ 
  icon = null,
  type,
  link = null,
  className,
  selected = false,
  onSelect = () => {}, 
  children,
  ...rest
}) => {
  const dropdown = useDropdown();

  return (
    <li 
      {...rest}
      className={classnames('menu-item', type, className, { selected })}
      onClick={(e) => {
        e.stopPropagation();
        dropdown.close();
        if (onSelect) onSelect();
      }}
    >
      <Item link={link}>
        {icon && <Icon name={icon} />}
        {children}
      </Item>
    </li>
  );
};

const MenuLink = ({
  icon,
  type,
  to,
  children
}) => {
  const dropdown = useDropdown();

  return (
    <li
      className={classnames('menu-item', type)}
      onClick={(e) => {
        e.stopPropagation();
        dropdown.close();
      }}
    >
      <Link to={to} className="item">
        {icon && <Icon name={icon} />}
        {children}
      </Link>
    </li>
  );
};

export {
  MenuItem,
  MenuLink
};
