const FR = {
  title: 'Symptômes donnés par le joueur',
  totalLabel: 'Total :',
  severityTotalLavel: 'Degré de sévérité',
  symptoms: 'Symptômes',
  thisPatientText: 'Ce patient',
  noSymptomsReported: '{0} n’a pas encore soumis de mis à jour sur ses symptômes.',
  accountNumberLabel: 'Numéro de compte',
  bagtagNumberLabel: 'Numéro de carte d’identité',
  dateOfBirthLabel: 'Date de naissance :',
  ageLabel: 'Âge',
  genderLabel: 'Sexe',
  dateOfInjuryLabel: 'Date de la blessure',
  languageLabel: 'Langue',
  recoveryStageLabel: 'Étape de récupération',
  maxLabel: 'maximum {0}',
  symptomSeverityLabel: 'SÉVÉRITÉ DU SYMPTÔME',
  symptomTotalsLabel: 'NO DE SYMPTÔME'
};
 
export default FR;
