const FR = {
  title: 'Évaluation post-blessure',
  acuteConcussionAssessmentTitle: 'Évaluation de commotion aigüe (0 à 14 jours)',
  chronicConcussionAssessmentTitle: 'Évaluation de commotion chronique (0 à 14 jours)',
  medicalInfoTitle: 'Antécédents médicaux',
  characteristicsTitle: 'Description de la blessure',
  redFlagsTitle: 'Alertes',
  initialSymptomsTitle: 'Symptômes initiaux',
  initialSymptomsSubtitle: '(Tels que rapportés par le patient ou l’entraîneur au moment de la blessure)',
  symptomsTitle: 'Symptômes',
  symptomExertionTitle: 'Symptômes pendant l’effort (est-ce que les symptômes s’aggravent)',
  symptomsSubtitle: '(Valeurs des résultats)',
  neurologicalTitle: 'Examen neurologique',
  diagnosisTitle: 'Diagnostic',
  recoveryStagesTitle: 'Étapes de récupération',
  followUpTitle: 'Tâche pour faire le suivi',
  communicationTitle: 'Communication du diagnostic',
  learningDisabilityLabelText: 'Trouble d’apprentissage',
  behaviouralDisorderLabelText: 'Troubles comportementaux :',
  concussionCountLabelText: 'Nombre de commotions précédentes :',
  concussionDateLabelText: 'Date de la dernière commotion :',
  injuryDateLabelText: 'Date de la blessure :',
  injuryTimeLabelText: 'Heure de la blessure :',
  injuryDescriptionLabelText: 'Description de la blessure :',
  defaultDescriptionText: 'Aucune description n’a été donnée.',
  injuryCauseLabelText: 'Cause de la blessure :',
  defaultCauseText: 'Aucune cause n’a été donnée.',
  directHeadImpactLabelText: 'Coup direct à la tête :',
  headImpactLocationLabelText: 'Emplacement du coup à la tête :',
  bodyImpactLocationLabelText: 'Emplacement du coup au corps :',
  amnesiaConsciousnessLabelText: 'Amnésie et perte de conscience :',
  retrogradeAmnesiaLabelText: 'Amnésie avant la blessure (rétrograde) :',
  anterogradeAmnesiaLabelText: 'Amnésie après la blessure (antérograde) :',
  conciousnessLabelText: 'Perte de conscience :',
  seizuresLabelText: 'Convulsions :',
  seizuresStatusPositiveText: 'Le patient a eu des convulsions.',
  seizuresStatusNegativeText: 'Le patient n’a pas eu de convulsions.',
  defaultRedFlagsText: 'Aucune alerte à signaler.',
  baselineSymptomsTitleText: 'Symptômes de référence',
  currentSymptomsTitleText: 'Symptômes actuels',
  currentSymptomsSubtitleText: '(Au moment de la présentation clinique)',
  defaultDiagnosisText: 'Aucun diagnostic n’a été donné.',
  defaultFollowUpText: 'Aucun suivi nécessaire.',
  clinicianMonitoringText: 'Surveillance/gestion par un clinicien à l’interne.',
  referralText: 'Orientation',
  referralToText: 'Orienté vers :',
  nextAppointmentDateText: 'Date du prochain rendez-vous :',
  notesLabelText: 'Remarque :',
  minutesText: '{0} minutes',
  cmText: '{0} cm',
  noneText: 'Aucun',
  impactlocation_rear: 'Arrière',
  impactlocation_front: 'Avant',
  impactlocation_left_side: 'Côté gauche',
  impactlocation_right_side: 'Côté droit',
  impactlocation_left_frontal: 'Frontal gauche',
  impactlocation_left_parietal: 'Pariétal gauche',
  impactlocation_left_temporal: 'Temporal gauche',
  impactlocation_left_occipital: 'Occipital gauche',
  impactlocation_right_frontal: 'Frontal droit',
  impactlocation_right_parietal: 'Pariétal droit',
  impactlocation_right_temporal: 'Temporal droit',
  impactlocation_right_occipital: 'Occipital droit',
  impactlocation_crown: 'Couronne',
  noInitialSymptomsProvided: 'Aucun symptôme initial donné',
  otherText: 'Autre',
  postInjuryTestTitle: 'Test post-blessure',
  noActiveBaseline: 'Ce patient n’a pas de test de référence pour faire la comparaison',
  symptomExertionPhysicalActivityLabel: 'Activité physique',
  symptomExertionMentalActivityLabel: 'Activité mentale',
  activityYesLabel: 'Oui',
  activityNoLabel: 'Non',
  activityHaventTriedLabel: 'N’a pas essayé',
  overallRatingLabel: 'Évaluation parentale globale (0 à 6)',
  overallRatingNotProvidedText: 'Aucune',
  initialManagementTitle: 'Gestion initiale',
  explanationLabel: 'Explication',
  exerciseLabel: 'Exercice',
  exerciseRestritionsLabel: 'Restrictions',
  exerciseNoRestrictionsLabel: 'Aucune restriction',
  workSchoolLabel: 'Travail/école',
  workSchoolFullOptionLabel: 'Complet',
  workSchoolModifiedOptionLabel: 'Modifié',
  therapyLabel: 'Thérapie',
  softTissueLabel: 'Tissu mou',
  spinalManipulativeLabel: 'Manipulation vertébrale',
  otherTherapyLabel: 'Autre : {0}',
  reevaluationDateLabel: 'Date de la réévaluation',
  noReevaluationDateText: 'Aucune date de réévaluation fixée',
  rehabLabel: 'Réadaptation',
  vestibularLabel: 'Équilibre/vestibulaire',
  cervicalSpineLabel: 'Colonne cervicale',
  visualTrackingLabel: 'Suivi visuel',
  sportSpecificLabel: 'Remarques pour un sport en particulier',
  neurocognitiveLabel: 'Neurocognitif',
  editText: 'Éditer'
};
 
export default FR;
