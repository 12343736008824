const EN = {
  concentrationTotal: 'Concentration Total:',
  mainTitle: 'Concentration',
  numbersTitle: 'Numbers in Reverse',
  numbersInfo: '"I\'m going to read you a string of numbers and when I\'m done, you repeat them back to me in reverse order of how I read them to you. For example, if I say 7-1-9, you would say 9-1-7"',
  monthsInReverseTitle: 'Months In Reverse',
  monthsInfo: 'Athletes are to say the months in reverse chronological order, beginning with December (1 point is issued if they are able to get the entire sequence correct, 0 points if they make any mistakes)',
  numbersErrorText: 'Numbers in Reverse section has not been completed',
  timedMonthsInfo: 'Athletes are to say the months in reverse chronological order, beginning with December (1 point is issued if they are able to get the entire sequence correct, 0 points if they make any mistakes or take longer than 30 seconds to complete)'
};

export default EN;
