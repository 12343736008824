import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Page } from '../page';
import FileList from './file-list';
import Breadcrumbs from './breadcrumbs';
import ResourcesHeader from './header';
import { resetDriveState } from '@/redux/actions/drive';
import { getDriveFolderAsync, getDriveSearchResults } from '@/redux/thunks/drive';
import { DriveSelector } from '@/redux/selectors';
import Activity from '../activity';
import { ErrorBanner } from '../errors';
import { useUnmount, useDebounceFn } from '@/hooks';
import Strings from './lang';

const SEARCH_FN_DELAY = 400;

const EmptyListsMessage = ({ visible, items, message }) => {
  const [files = [], folders = []] = items;
  return visible && !files.length && !folders.length ? (
    <p className="alert alert-info text-center">
      {message}
    </p>
  ) : null;
};

const SearchResultsView = ({
  searching = false,
  emptyVisible = false,
  results = []
}) => {
  return (
    <Activity 
      static 
      active={searching} 
      titleComponent={<h1>{Strings.searchingText}</h1>}
    >
      <EmptyListsMessage 
        visible={emptyVisible}
        items={[results]} 
        message={Strings.noSearchResultsMessage}
      />
      <FileList title={Strings.fileListTitle} files={results} />
    </Activity>
  );
};

const FilesListView = ({
  loading = false,
  error = null,
  files = [],
  folders = []
}) => {
  return (
    <>
      <Breadcrumbs />
      <Activity static active={loading} titleComponent={<h1>{Strings.loadingText}</h1>}>
        <EmptyListsMessage 
          visible={!loading && !error} 
          items={[files, folders]} 
          message={Strings.emptyFolderMessage}
        />
        <FileList title={Strings.folderListTitle} files={folders} />
        <FileList title={Strings.fileListTitle} files={files} />
      </Activity>
    </>
  );
};

const GoogleDrive = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { files, folders } = useSelector(state => DriveSelector.getFolderFiles(state, params));
  const [activity, setActivity] = useState(false);
  const [search, setSearch] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [hasSearched, setHasSearched] = useState(false);
  const [error, setError] = useState(null);

  const resetSearch = useCallback(() => {
    setSearch('');
    setSearchResults([]);
  }, []);
  
  const loadFolders = useCallback((fileId) => {
    setError(null);
    if (files.length || folders.length) return;

    setActivity(true);

    dispatch(getDriveFolderAsync(fileId)).then(() => {
      setActivity(false);
    }).catch(error => {
      setActivity(false);
      setError(error.message);
    });
  }, [dispatch, files.length, folders.length]);

  const searchFiles = useCallback((searchValue) => {
    setError(null);
    setHasSearched(false);
    if (!searchValue) return;

    setActivity(true);

    dispatch(getDriveSearchResults(searchValue)).then(files => {
      setHasSearched(true);
      setActivity(false);
      setSearchResults(files);
    }).catch(error => {
      setActivity(false);
      setHasSearched(true);
      setError(error.message);
    });
  }, [dispatch]);

  const searchDebounced = useDebounceFn(searchFiles, SEARCH_FN_DELAY);

  useEffect(() => {
    searchDebounced(search);
  }, [searchDebounced, search]);

  useEffect(() => {
    resetSearch();
    loadFolders(params.fileId);
  }, [loadFolders, params.fileId, resetSearch]);

  useUnmount(() => {
    dispatch(resetDriveState());
  });

  return (
    <Page className="drive-resources">
      <ResourcesHeader 
        searchValue={search}
        onSearchChange={(value) => {
          setSearch(value);
          if (!value) {
            setSearchResults([]);
          }
        }}
      />
      <ErrorBanner error={error} />
      {search ? (
        <SearchResultsView 
          searching={activity}
          emptyVisible={!activity && !error && hasSearched}
          results={searchResults}
        />
      ) : (
        <FilesListView 
          loading={activity}
          error={error}
          files={files}
          folders={folders}
        />
      )}
    </Page>
  );
};

export default GoogleDrive;
