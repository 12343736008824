const mergeRepeatBaselines = (injury) => {
  const {
    soap_notes = [],
    injury_assessments = [],
    repeat_baselines = []
  } = injury;

  return [
    ...repeat_baselines,
    ...soap_notes.reduce((acc, soapNote) => {
      return [
        ...acc,
        ...soapNote.baselines
      ];
    }, []),
    ...injury_assessments.reduce((acc, assessment) => {
      return [
        ...acc,
        ...assessment.baselines
      ];
    }, [])
    /** remove duplicates */
  ].filter((baseline, index, arr) => {
    return arr.findIndex(item => item.id === baseline.id) === index;
  }).sort((a, b) => {
    if (a.created_at !== b.created_at) {
      return (a.created_at > b.created_at) ? 1 : -1;
    } 
    
    return 0;
  });
};

export {
  mergeRepeatBaselines
};
