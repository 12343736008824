import React, { useCallback } from 'react';
import Strings from './lang';
import Modal from '../modal';
import SelectAmountForm from './select-amount-form';

const SelectAmountModal = ({
  isOpen = false,
  balance,
  min,
  amount,
  onClose = () => {},
  onUpdateAmount = () => {}
}) => {
  const onSubmit = useCallback((newAmount) => {
    onUpdateAmount(newAmount);
    onClose();
  }, [onClose, onUpdateAmount]);

  return (
    <Modal
      blur
      isOpen={isOpen}
      onClose={onClose}
      className="select-amount-modal"
    >
      <Modal.Header>
        <h1>{Strings.paymentAmountLabel}</h1>
      </Modal.Header>
      <Modal.Body>
        <div>
          <SelectAmountForm 
            max={balance}
            min={min}
            initialAmount={amount}
            onSubmit={onSubmit}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SelectAmountModal;
