import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { Outlet } from 'react-router-dom';
import Store from '@/redux/store';
import { AlertContainer } from '@/components/alert-notifications';
import registerFaviconNotificationWatcher from '@/utilities/favicon-notification-watcher';
import { useMount } from '@/hooks';
import CurrentUserLoader from '@/components/current-user-loader';

const AppProviders = () => {
  useMount(() => {
    registerFaviconNotificationWatcher();
  });

  return (
    <ReduxProvider store={Store}>
      <CurrentUserLoader>
        <Outlet />
        <AlertContainer />
      </CurrentUserLoader>
    </ReduxProvider>
  );
};

export default AppProviders;
