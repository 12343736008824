const FR = {
  title: 'Enregistrer une clinique',
  clinicNameLabelText: 'Nom de la clinique',
  clinicImpactCodeLabelText: 'Code ImPACT',
  clinicImpactCodeErrorText: 'Veuillez écrire le code ImPACT de la clinique',
  clinicNameErrorText: 'Veuillez écrire un nom pour la clinique',
  clinicUrlLabelText: 'Site Web',
  emailLabelText: 'Courriel :',
  phoneLabelText: 'Numéro de téléphone',
  phoneErrorText: 'Veuillez écrire un numéro de téléphone valide',
  faxLabelText: 'Numéro de fax',
  faxErrorText: 'Veuillez écrire un numéro de fax valide',
  submitButtonText: 'Sauvegarder',
  successMessageText: '{0} a été ajouté',
  addClinicLinkText: 'Ajouter une autre clinique',
  homeLinkText: 'Accueil',
  addCreditsStepTitle: 'Ajouter des crédits',
  addCreditsStepMessage: 'Souhaitez-vous ajouter des crédits à {0}?',
  skipStepButtonText: 'Pas maintenant',
  addCreditsModalTitle: 'Ajouter des Crédits Gratuits',
  addCreditsModalMessage: 'Vous êtes sur le point d\'ajouter {0} crédit à {1}. Es-tu sur de vouloir continuer?',
  addCreditsModalConfirmButtonText: 'Oui, Ajouter des Crédits',
  registrationCompleteTitle: 'Inscription complète',
  clinicProfielButtonText: '{0} Profil de la clinique',
  searchableLabelText: 'Afficher la clinique sur la carte?',
  bioLabelText: 'Bio',
  clinicHoursLabel: 'Heures de la clinique',
  addText: 'Ajouter',
  removeText: 'Retirer',
  axiaIdtProviderLabel: 'Axia IDT Provider',
  resourcesLabel: 'Ressources activées',
  clinicFeaturesLabel: 'Caractéristiques de la clinique'
};
 
export default FR;
