import React from 'react';
import PropTypes from 'prop-types';
import PatientName from './patient-name';
import Strings from './lang';

const DEFAULT_TITLE_TAG_NAME = 'h1';

const PatientMeta = ({
  clinicId,
  patient = {},
  nameTagName,
  nameTagProps,
  nameRedactedText,
  lastNameFirst
}) => (
  <div className="patient-meta">
    <PatientName
      patient={patient}
      clinicId={clinicId}
      tagName={nameTagName || DEFAULT_TITLE_TAG_NAME}
      tagProps={nameTagProps}
      redactedText={nameRedactedText}
      lastNameFirst={lastNameFirst}
    />
    <ul className="list-unstyled">
      <li><strong>{Strings.accountLabelText}</strong> {patient.account}</li>
      {patient.bag_tag ? (
        <li><strong>{Strings.bagTagLabelText}</strong> {patient.bag_tag}</li>
      ) : null}
    </ul>
  </div>
);

PatientMeta.propTypes = {
  patient: PropTypes.object.isRequired,
  clinicId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  nameTagName: PropTypes.string,
  nameTagProps: PropTypes.object,
  nameRedactedText: PropTypes.string,
  lastNameFirst: PropTypes.bool
};

export default PatientMeta;
