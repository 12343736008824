const ADD_BATCH = 'ADD_BATCH';
const UPDATE_BATCH = 'UPDATE_BATCH';
const UPDATE_BATCHES = 'UPDATE_BATCHES';

const addBatch = (batch) => ({
  type: ADD_BATCH,
  batch
});

const updateBatch = (batch) => ({
  type: UPDATE_BATCH,
  batch
});

const updateBatches = (batches) => ({
  type: UPDATE_BATCHES,
  batches
});

export {
  ADD_BATCH,
  UPDATE_BATCH,
  UPDATE_BATCHES,
  addBatch,
  updateBatch,
  updateBatches
};
