import React, { useState } from 'react';
import classnames from 'classnames';
import Popover from '../popover';
import { Money } from '@/utilities/currency';
import Strings from './lang';
import Icon from '../icon';

const PaymentDetails = ({
  payment = {}
}) => {
  const [expanded, setExpanded] = useState(false);
  const { 
    amount_cents, 
    amount_currency, 
    date_stamp,
    response_order_id,
    note,
    payer = {}
  } = payment;
  const { person = {} } = payer || {};
  const { first_name = '', last_name = '' } = person || {};

  return (
    <div className={classnames('payment-details', { expanded })}>
      <div className="details-header" onClick={() => setExpanded((value) => !value)}>
        <div>
          <Icon prefix="fas" name="caret-right" />&nbsp;&nbsp;
          <span>{date_stamp}</span>&nbsp;&nbsp;
        </div>
        <strong>{Money.format(amount_cents / 100, amount_currency)}</strong>
      </div>
      <div className="details">
        <div>
          <strong>{Strings.paidByLabel}:</strong> {first_name} {last_name}
        </div>
        {response_order_id && (
          <div>
            <strong>{Strings.paymentIdLabel}:</strong> {response_order_id}
          </div>
        )}
        {note && (
          <div>
            <strong>{Strings.noteLabel}:</strong> {note}
          </div>
        )}
      </div>
    </div>
  );
};

const Content = ({
  payments = [],
  total,
}) => {
  return (
    <div className="payments-content">
      <div className="content-header">
        <h3>{Strings.paymentsPopoverTitle}</h3>
        <div>{total}</div>
      </div>
      <div className="content-body">
        {payments.map(payment => (
          <PaymentDetails 
            key={payment.id}
            payment={payment}
          />
        ))}
      </div>
    </div>
  );
};

const PaymentsPopover = ({
  isOpen = false,
  position = 'bottom',
  payments = [],
  total,
  onClose,
  children
}) => {
  return (
    <Popover
      isOpen={isOpen}
      position={position}
      offset={[0, 2]}
      onClose={onClose}
      className="payments-popover"
      height={350}
      content={(
        <Content total={total} payments={payments} />
      )}
    >
      {children}
    </Popover>
  );
};

export default PaymentsPopover;
