import { createSelector } from 'reselect';
import { isFolder, isFolderShortcut } from '@/utilities/google-drive';

const getFiles = (state) => state.drive.files;
const getAuth = (state) => state.drive.auth;
const getFileId = (_, { fileId } = {}) => fileId;

const createSorter = (keyPath, order = 'asc') => {
  const path = keyPath.split('.');
  const getKeyValue = (record) => {
    return path.reduce((acc = {}, key = '') => {
      return acc[key];
    }, record);
  };

  const sorter = (a, b) => {
    const aValue = getKeyValue(a);
    const bValue = getKeyValue(b);
    // eslint-disable-next-line no-nested-ternary
    return aValue === bValue ? 0 : (aValue > bValue ? 1 : -1);
  };

  return (a, b) => {
    const value = sorter(a, b);
    return order === 'desc' ? (value * -1) : value;
  };
};

const getFolderFiles = createSelector(
  [getFiles, getAuth, getFileId],
  (files, auth, fileId) => {
    const id = fileId || auth.folder_id;
    return Object.values(files)
      .filter(file => (file.parents || []).indexOf(id) >= 0)
      .sort(createSorter('name', 'asc'))
      .reduce((acc, file) => {
        if (isFolder(file) || isFolderShortcut(file)) {
          acc.folders.push(file);
        } else {
          acc.files.push(file);
        }
        
        return acc;
      }, { files: [], folders: [] });
  }
);

const getFile = createSelector(
  [getFiles, getFileId],
  (files, fileId) => {
    return files[fileId];
  }
);

export default {
  getFolderFiles,
  getFile
};
