import React, { forwardRef } from 'react';
import { ForcePlateCard } from '../baseline-test-cards';

const ForcePlateStep = forwardRef((
  {
    baseline = {},
    onSubmit,
    ...rest
  },
  ref
) => {
  return (
    <ForcePlateCard
      {...rest}
      ref={ref}
      forcePlate={{ ...(baseline.force_plate_test || {}) }}
      onSubmit={onSubmit}
    />
  );
});

ForcePlateStep.hasChanged = (initial, current) => {
  return (initial.right_foot_cop || '') !== current.right_foot_cop
    || (initial.left_foot_cop || '') !== current.left_foot_cop
    || (initial.notes || '') !== current.notes;
};

export default ForcePlateStep;
