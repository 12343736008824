const EN = {
  authLinkText: 'Sign In',
  registerLinkText: 'Register',
  registerConfirmLinkText: 'Resend Confirmation',
  accountSettingsLinkText: 'Account Settings',
  signOutLinkText: 'Sign Out',
  homeLinkText: 'Home',
  languageLinkText: 'Language',
  manageClinicLinkText: 'My Clinic',
  addClinicLinkText: 'Add Clinic',
  notificationsLinkText: 'Notifications',
  supportLinkText: 'Support',
  impactCustomerCenterText: 'Customer Center Sign In',
  marketplaceLinkText: 'Marketplace',
  demoSiteButtonLabel: 'Demo Site Logins',
  demoSiteUrlLabel: 'Demo Site URL',
  emailLabel: 'Email',
  passwordLabel: 'Password'
};

export default EN;
