const splitMedicalList = (medical = []) => {
  return medical.reduce((acc, curr) => {
    if (curr.removed_at === null) {
      acc.current.push({ ...curr });
    } else {
      acc.removed.push({ ...curr });
    }

    return acc;
  }, { current: [], removed: [] });
};

const joinMedicalList = (medical = {}) => {
  return [
    ...medical.current.reduce((acc, item) => {
      return !item.id ? [
        ...acc,
        { name: item.name }
      ] : acc;
    }, []),
    ...medical.removed.reduce((acc, item) => {
      return item.new === true ? [
        ...acc,
        {
          id: item.id,
          removed_at: item.removed_at
        }
      ] : acc;
    }, [])
  ];
};

export {
  splitMedicalList,
  joinMedicalList
};
