import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { RadioInputGroup, FormComponent } from '@/forms';
import EditPlayerPersonalInfoCardForm from '../user-profile-cards/edit-player-personal-info-card-form';
import EditPlayerContactInfoCardForm from '../user-profile-cards/edit-player-contact-info-card-form';
import EditPlayerPhysicianInfoCardForm from '../user-profile-cards/edit-player-physician-info-card-form';
import Card from '../card';
import Strings from './lang';

const scrollSectionIntoView = (sectionRef) => {
  if (sectionRef && sectionRef.scrollIntoView) {
    sectionRef.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
};

class PlayerInfoFullFormCard extends Component {
  static getDerivedStateFromProps(props, state) {
    const hasPhysician = props.user?.physician_contact !== null ? 'yes' : 'no';
    return hasPhysician !== state.hasPhysician
      ? { hasPhysician }
      : null;
  }

  constructor(props) {
    super(props);

    /** holds the refs to the form components */
    this.forms = {
      personal: React.createRef(),
      contact: React.createRef(),
      physician: React.createRef()
    };

    this.onSubmit = this.onSubmit.bind(this);
  
    const { user = {} } = props;

    this.state = {
      hasPhysician: user.physician_contact !== null ? 'yes' : 'no'
    };
  }

  render() {
    const { 
      user = {}, 
      cardTitle = Strings.patientInformationTitle, 
      submitButtonText = Strings.saveButtonText 
    } = this.props;

    return (
      <Card title={cardTitle}>
        <div className="patient-info">
          <h2>{Strings.personalHeadingText}</h2>
          <EditPlayerPersonalInfoCardForm
            ref={this.forms.personal}
            user={user}
          />

          <h2>{Strings.contactHeadingText}</h2>
          <EditPlayerContactInfoCardForm
            ref={this.forms.contact}
            user={user}
          />

          <h2>{Strings.physicianHeadingText}</h2>

          <FormComponent>
            <div className="card card-form">
              <RadioInputGroup
                className="form-group"
                titleLabelText={Strings.doesPatientHavePhysicianRadioLabel}
                radioLabels={[Strings.radioOptionNo, Strings.radioOptionYes]}
                radioValues={['no', 'yes']}
                initialValue={this.state.hasPhysician}
                inputProps={{
                  className: 'form-control'
                }}
                inputValid
                onUpdate={(value) => this.onUpdateValue('hasPhysician', value)}
              />
            </div>
          </FormComponent>

          {this.state.hasPhysician === 'yes' && (
            <EditPlayerPhysicianInfoCardForm
              ref={this.forms.physician}
              user={user}
            />
          )}

          <div className="form-footer">
            <button type="button" className="btn btn-primary" onClick={this.onSubmit}>
              {submitButtonText}
            </button>
          </div>
        </div>
      </Card>
    );
  }

  onUpdateValue(key, value) {
    this.setState({
      [key]: value
    });
  }

  getFormAttributes() {
    const { personal, contact, physician } = this.forms;
    
    const personalAttributes = personal.current.getAttributes();
    if (!personalAttributes) {
      scrollSectionIntoView(personal.current);
      return null;
    }

    const contactAttributes = contact.current.getAttributes();
    if (!contactAttributes) {
      scrollSectionIntoView(contact.current);
      return null;
    }

    const physicianAttributes = this.state.hasPhysician === 'yes' ? physician.current.getAttributes() : {};
    if (!physicianAttributes) {
      scrollSectionIntoView(physician.current);
      return null;
    }

    return {
      email: contactAttributes.email,
      person_attributes: {
        ...(personalAttributes.person_attributes || {}),
        ...(contactAttributes.person_attributes || {})
      },
      emergency_contact_attributes: {
        ...(contactAttributes.emergency_contact_attributes || {})
      },
      mailing_address_attributes: {
        ...(contactAttributes.mailing_address_attributes || {})
      },
      ...physicianAttributes
    };
  }

  onSubmit() {
    const attributes = this.getFormAttributes();

    if (!attributes) {
      if (typeof this.props.onError === 'function') {
        this.props.onError();
      }
      return;
    }

    this.props.onSubmit(attributes);
  }
}

PlayerInfoFullFormCard.propTypes = {
  user: PropTypes.object,
  cardTitle: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  submitButtonText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ])
};

export default PlayerInfoFullFormCard;
