import React, { useCallback, useState } from 'react';
import Tooltip from '../../tooltip';
import Icon from '../../icon';
import Strings from '../lang';

const TOOLTIP_DURATION = 1300;

const CouponCodeDetails = ({
  code,
  onCopy = () => {}
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleCopy = useCallback(() => {
    onCopy();
    setTooltipOpen(true);

    setTimeout(() => {
      setTooltipOpen(false);
    }, TOOLTIP_DURATION);
  }, [onCopy]);

  const handleCopyToClipboard = useCallback(async () => {
    await navigator?.clipboard?.writeText(code);
    handleCopy();
  }, [code, handleCopy]);

  if (!code) return null;

  return (
    <div className="coupon-section">
      <p className="text-center text-muted">
        <small>
          <i>{Strings.couponCodeHintMessage}</i>
        </small>
      </p>
      <div className="title">
        {Strings.couponCodeLabel}
      </div>
      <Tooltip
        isOpen={tooltipOpen}
        trigger={null}
        offset={[0, 2]}
        placement="top"
        content={Strings.couponCodeCopiedMessage}
        onClose={() => setTooltipOpen(false)}
      >
        <div className="coupon-code-container">
          <div className="coupon-code">
            <div className="code">
              <strong onCopy={handleCopy}>{code}</strong>
            </div>
            <div className="clipboard-button">
              <button
                type="button"
                aria-label={Strings.copyToClipboardHintMessage}
                title={Strings.copyToClipboardHintMessage}
                className="btn btn-sm btn-light"
                onClick={handleCopyToClipboard}
              >
                <Icon fixedWidth name="clipboard" />
              </button>
            </div>
          </div>
        </div>
      </Tooltip>
    </div>
  );
};

export default CouponCodeDetails;
