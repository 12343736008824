const UPDATE_INJURY = 'UPDATE_INJURY';
const UPDATE_INJURIES = 'UPDATE_INJURIES';
const ADD_INJURY_SOAP_NOTE = 'ADD_INJURY_SOAP_NOTE';
const REMOVE_INJURY_SOAP_NOTE = 'REMOVE_INJURY_SOAP_NOTE';
const ADD_INJURY_REFERRAL = 'ADD_INJURY_REFERRAL';
const UPDATE_INJURY_REFERRAL = 'UPDATE_INJURY_REFERRAL';

const updateInjury = (userId, injury) => ({
  type: UPDATE_INJURY,
  userId,
  injury
});

const addInjurySoapNote = (userId, injuryId, soapNote) => ({
  type: ADD_INJURY_SOAP_NOTE,
  userId,
  injuryId,
  soapNote
});

const removeInjurySoapNote = (userId, injuryId, soapNoteId) => ({
  type: REMOVE_INJURY_SOAP_NOTE,
  userId,
  injuryId,
  soapNoteId
});

const updateInjuries = (userId, injuries) => ({
  type: UPDATE_INJURIES,
  userId,
  injuries
});

const addInjuryReferral = (userId, injuryId, referral) => ({
  type: ADD_INJURY_REFERRAL,
  userId,
  injuryId,
  referral
});

const updateInjuryReferral = (userId, injuryId, referral) => ({
  type: UPDATE_INJURY_REFERRAL,
  userId,
  injuryId,
  referral
});

export {
  UPDATE_INJURY,
  UPDATE_INJURIES,
  ADD_INJURY_SOAP_NOTE,
  REMOVE_INJURY_SOAP_NOTE,
  updateInjury,
  updateInjuries,
  addInjurySoapNote,
  removeInjurySoapNote,
  ADD_INJURY_REFERRAL,
  addInjuryReferral,
  UPDATE_INJURY_REFERRAL,
  updateInjuryReferral
};
