import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Card = forwardRef((
  {
    title = null, 
    className,
    actionButtons = null,
    children
  },
  ref
) => (
  <div ref={ref} className={classnames('card-container', className)}>
    <div className="card-container-header">
      {title ? <h2>{title}</h2> : <span />}
      {actionButtons}
    </div>
    <div className="card-container-body">
      {children}
    </div>
  </div>
));

Card.propTypes = {
  title: PropTypes.string,
  actionButtons: PropTypes.object
};

export default Card;
