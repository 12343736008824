import { jsonApiRead } from '@/lib/requests/jsonapi';
import { updateProgressReports } from '../actions/progress-reports';

const getProgressReportsAsync = (userId, injuryId) => {
  const options = { path: `users/${userId}/injuries/${injuryId}/progress_reports` };
  return (dispatch) => {
    return jsonApiRead({ type: 'progress_reports' }, options).then(results => {
      dispatch(updateProgressReports(userId, injuryId, results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const getProgressReportAsync = (userId, injuryId, progressId) => {
  const options = { path: `users/${userId}/injuries/${injuryId}/progress_reports/${progressId}` };
  return (dispatch) => {
    return jsonApiRead({ type: 'progress_reports' }, options).then(results => {
      dispatch(updateProgressReports(userId, injuryId, results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

export {
  getProgressReportsAsync,
  getProgressReportAsync
};
