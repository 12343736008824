import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '../icon';

class IconButton extends Component {
  render() {
    return (
      <button 
        type="button" 
        className={classNames('icon-button', this.props.className, {
          'icon-after': this.props.iconAfterText
        })}
        onClick={this.onClick.bind(this)}
        disabled={this.props.disabled}
      >
        {this.renderContent()}
      </button>
    );
  }

  renderContent() {
    if (this.props.iconAfterText) {
      return (
        <span className="icon-button-content">
          {this.renderText()}
          {this.renderIcon()}
        </span>
      );
    } 

    return (
      <span className="icon-button-content">
        {this.renderIcon()}
        {this.renderText()}
      </span>
    );
  }

  renderText() {
    if (this.props.text) {
      return (
        <span>{this.props.text}</span>
      );
    }
      
    return null;
  }

  renderIcon() {
    if (this.props.icon) {
      return (
        <Icon name={this.props.icon} />
      );
    }
      
    return null;
  }

  onClick(event) {
    if (typeof this.props.onClick === 'function') {
      this.props.onClick(event);
    }
  }
}

IconButton.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  iconAfterText: PropTypes.bool,
  text: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func
};

export default IconButton;
