const EN = {
  title: 'Medical History',
  concussionHistoryHeadingText: 'Concussion History',
  concussionDiagnosedLabelText: 'Prior to registration with CCMI, have you ever been diagnosed with a concussion?',
  concussionCountLabelText: 'How many concussions prior to registration with CCMI?',
  concussionDateLabelText: 'When was the most recent?',
  concussionDateErrorText: 'Please enter the date of most recent concussion prior to CCMI.',
  concussionCountErrorText: 'Number of concussions must be greater than or equal to 1.',
  concussionRecoveryLabelText: 'How long did it take to fully recover?',
  concussionRecoveryErrorText: 'Full recovery must be at least one day.',
  concussionSymptomsLabelText: 'Do you still experience symptoms?',
  concussionSymptomsStrengthLabelText: 'Are subsequent concussions happening with less force?',
  concussionNotesLabelText: 'Previous Concussion Notes',
  medicalPsychHistoryHeadingText: 'Medical/Psychiatric History',
  medicalConditionsLabelText: 'Medical Conditions',
  currentMedicalConditionsLabel: 'Current Medical Conditions',
  previousMedicalConditionsLabel: 'Previous Medical Conditions',
  conditionsTableHeadingText: 'Conditions',
  medicationsLabelText: 'Medications',
  currentMedicationsLabel: 'Current Medications',
  previousMedicationsLabel: 'Previous Medications',
  behaviouralDisorderLabelText: 'Have you ever been diagnosed with a behavioural disorder?',
  noneText: 'None',
  behaviouralDisorderOptionADD: 'ADD',
  behaviouralDisorderOptionADHD: 'ADHD',
  learningDisabilityLabelText: 'Have you ever been diagnosed with a learning disability?',
  headacheHistoryLabelText: 'Do you have a history of headaches?',
  headacheNotesLabelText: 'Headache Notes',
  psychiatricHxLabelText: 'Have you ever been diagnosed of:',
  anxietyLabelText: 'Anxiety',
  depressionLabelText: 'Depression',
  sleepDisorderLabelText: 'Sleep Disorder',
  otherConditionLabelText: 'Other Mental Health Condition',
  otherConditionNotesLabelText: 'Specify the Condition',
  otherConditionNotesErrorText: 'Please specify a condition',
  radioOptionYes: 'Yes',
  radioOptionNo: 'No',
  radioOptionNA: 'N/A',
  durationOptionDays: 'Days',
  durationOptionWeeks: 'Weeks',
  durationOptionMonths: 'Months',
  durationOptionYears: 'Years',
  submitButtonText: 'Save',
  yesNoOptionErrorMessage: 'Please select Yes or No',
  yesNoNaOptionErrorMessage: 'Please select Yes, No or N/A',
  selectOptionErrorMessage: 'Please select an option',
  selectOneOptionText: 'Please Select...',
  medicalListErrorMessage: 'Item already added',
  medicalListAddedText: 'added {0}',
  previousMedicalConditionsTableHeading: 'Previous Medical Conditions',
  currentMedicalConditionsListHeading: 'Current Medical Conditions',
  medicalConditionsInputPlaceholder: 'Add a Condition (Press Enter to Add)',
  previousMedicationsTableHeading: 'Previous Medications',
  currentMedicationsListHeading: 'Current Medications',
  medicationsInputPlaceholder: 'Add a Medication (Press Enter to Add)',
  undoButtonText: 'Undo',
  removedByTableHeading: 'Removed By',
  removedAtTableHeading: 'Removed At',
  clinicTableHeading: 'Clinic',
  dateTableHeading: 'Date',
  addedByTableHeading: 'Added By',
  actionTableHeading: 'Action',
  removeButtonText: 'Remove'
};

export default EN;
