import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormComponent, PasswordInputGroup } from '@/forms';
import Strings from './lang';

class PasswordUpdateForm extends Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      submitted: false,
      password: '',
      passwordValid: false,
      currentPassword: '',
      confirmPasswordValid: false
    };
  }

  render() {
    return (
      <FormComponent className="password-update-form" onSubmit={this.onSubmit}>
        <PasswordInputGroup
          className="form-group"
          labelText={Strings.currentPasswordLabelText}
          inputProps={{
            className: 'form-control',
            name: 'current-password',
            placeholder: Strings.passwordInputPlaceholder
          }}
          onUpdate={(currentPassword, confirmPasswordValid) => {
            this.setState({ currentPassword, confirmPasswordValid });
          }}
          messageText={Strings.currentPasswordErrorText}
          messageClassName="alert alert-danger"
          touched={this.state.submitted}
        />

        <PasswordInputGroup
          className="form-group"
          showPasswordScore
          showConfirmInput
          labelText={Strings.newPasswordLabelText}
          inputProps={{
            className: 'form-control',
            placeholder: Strings.passwordInputPlaceholder
          }}
          confirmLabelText={Strings.confirmPasswordLabelText}
          confirmInputProps={{
            className: 'form-control',
            name: 'confirm-password',
            placeholder: Strings.confirmPasswordInputPlaceholder
          }}
          onUpdate={(password, passwordValid) => {
            this.setState({ password, passwordValid });
          }}
          messageText={Strings.newPasswordErrorText}
          messageClassName="alert alert-danger"
          touched={this.state.submitted}
        />

        <div className="form-footer">
          <button className="btn btn-primary" type="submit">
            {Strings.updateButtonText}
          </button>
        </div>
      </FormComponent>
    );
  }

  isValid() {
    return this.state.passwordValid
      && this.state.confirmPasswordValid;
  }

  onSubmit() {
    this.setState({ submitted: true });

    if (!this.isValid()) {
      return;
    }

    const {
      password,
      currentPassword
    } = this.state;

    const attributes = {
      password,
      password_confirmation: password,
      current_password: currentPassword
    };

    if (typeof this.props.onSubmit === 'function') {
      this.props.onSubmit(attributes);
    }
  }
}

PasswordUpdateForm.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default PasswordUpdateForm;
