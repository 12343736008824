const UPDATE_TEAM_LEADERS = 'UPDATE_TEAM_LEADERS';
const UPDATE_TEAM_LEADER = 'UPDATE_TEAM_LEADER';

const updateTeamLeaders = (teamLeaders) => ({
  type: UPDATE_TEAM_LEADERS,
  teamLeaders
});

const updateTeamLeader = (id, teamLeader) => ({
  type: UPDATE_TEAM_LEADER,
  id, 
  teamLeader
});

export {
  UPDATE_TEAM_LEADERS,
  updateTeamLeaders,
  UPDATE_TEAM_LEADER,
  updateTeamLeader
};
