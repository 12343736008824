import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { 
  getVOMSTestAsync, 
  createVOMSTestAsync, 
  updateVOMSTestAsync 
} from '@/redux/thunks/voms';
import { ErrorBanner } from '../errors';
import { TestHeader } from '../test-header';
import Activity from '../activity';
import VOMSTestForm from './voms-test-form';
import Strings from './lang';

const vomsSelector = (userId, vomsId) => state => {
  const userTests = state.voms[userId] || {};
  return userTests[vomsId] || {};
};

const VOMSTest = ({
  user = {},
  injury = {},
  vomsId = null,
  soapId = null,
  assessmentId = null,
  currentClinic,
  currentUser,
  onSave = () => {}
}) => {
  const dispatch = useDispatch();
  const [activity, setActivity] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const test = useSelector(vomsSelector(user?.id, vomsId));

  const getVOMSTest = useCallback((userId, injuryId, vomsId) => {
    setLoading(true);
    setActivity(true);
    setError(null);

    dispatch(getVOMSTestAsync(userId, injuryId, vomsId)).then(() => {
      setActivity(false);
      setLoading(false);
    }).catch(error => {
      setActivity(false);
      setLoading(false);
      setError(error.message);
    });
  }, [dispatch]);

  const updateVOMSTest = useCallback((userId, injuryId, vomsId, attributes) => {
    setActivity(true);
    setError(null);

    dispatch(updateVOMSTestAsync(userId, injuryId, vomsId, attributes)).then(test => {
      setActivity(false);

      if (onSave) {
        onSave('voms', test);
      }
    }).catch(error => {
      setActivity(false);
      setError(error.message);
    });
  }, [dispatch, onSave]);

  const createVOMSTest = useCallback((userId, injuryId, attributes) => {
    const attrs = { ...attributes };

    setActivity(true);
    setError(null);

    if (soapId) {
      attrs.soap_note_id = soapId;
    } else if (assessmentId) {
      attrs.injury_assessment_id = assessmentId;
    } else {
      attrs.injury_id = injuryId;
    }

    dispatch(createVOMSTestAsync(userId, injuryId, attrs)).then(test => {
      setActivity(false);

      if (onSave) {
        onSave('voms', test);
      }
    }).catch(error => {
      setActivity(false);
      setError(error.message);
    });
  }, [dispatch, assessmentId, soapId, onSave]);

  const saveVOMSTest = useCallback((attributes) => {
    if (vomsId) {
      updateVOMSTest(user?.id, injury?.id, vomsId, attributes);
    } else {
      createVOMSTest(user?.id, injury?.id, attributes);
    }
  }, [createVOMSTest, injury?.id, updateVOMSTest, user?.id, vomsId]);

  useEffect(() => {
    if (!test?.id && user?.id && injury?.id && vomsId) {
      getVOMSTest(user?.id, injury?.id, vomsId);
    }
  }, [getVOMSTest, injury?.id, test?.id, user?.id, vomsId]);
  
  return (
    <div className="voms-test">
      <div className="row">
        <div className="col-md-12">
          <h1 className="display">{Strings.title}</h1>
          <p className="medium">{Strings.directionsText}</p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <TestHeader
            user={user}
            injury={injury}
            className="voms-test-header"
            testDate={test?.created_at || dayjs()}
            tester={test?.clinic_user || currentUser}
            clinic={test?.clinic || currentClinic}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <ErrorBanner error={error} />
          <Activity active={activity} static={loading}>
            <VOMSTestForm
              user={user}
              test={test}
              onSubmit={saveVOMSTest}
            />
          </Activity>
        </div>
      </div>
    </div>
  );
};

export default VOMSTest;
