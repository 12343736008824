import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const getDefaultOffset = () => {
  const nav = document.querySelector('.navigation-bar');
  return nav ? (nav.clientHeight || 0) : 0; 
};

/**
 * A simply component that will make its children scroll with the page (sticky).
 * Excepts 2 main props: container and offsetTop. Both are optional
 * The container prop can be either a component class or function => returning domNode (from ref).
 * If no container is provided the default container is document.body
 * 
 * The offsetTop prop tells the component how far from the top the sticky component 
 * should be affixed. If offsetTop is not previded the default is the clientHeight 
 * of the navbar using document.querySelector('.navigation-bar') or 0.
 * 
 * For styling - Injects bootstraps affix class into the child components classNames. Does not
 * render any wrapper elements around the child component. Just uses React.cloneElement to clone the
 * child with an additional style (top = offsetTop) and className = affix
 * 
 * examples: 
 * 
 * class SomeClass extends Component {
 *  render() {
 *    <div>
 *      <Sticky container={this} offsetTop={20}>
 *        <div>Im sticky within this component 20px from the top</div>
 *      </Sticky>
 *      ...
 *      ...
 *      <Sticky>
 *        <div>Im now sticky to document.body (under the navbar)</div>
 *      </Sticky>
 *      ...
 *      ...
 *      <div ref={(c) => this.container = c}>
 *        <Sticky container={() => this.container)}>
 *          <div>Im sticky within this.container (under the nav bar)</div>
 *        </Sticky>
 *        ...
 *        ...
 *      </div>
 *    </div>
 *  }
 * }
 * 
 * TODO: Add support for affix stop at bottom of container or offsetBottom from container.
 */

class Sticky extends Component {
  constructor(props) {
    super(props);

    /* insure we have access to 'this' for event listeners */
    this._handleScroll = this._handleScroll.bind(this);

    this.state = {
      affixed: false
    };
  }

  componentDidMount() {
    document.addEventListener('scroll', this._handleScroll);
    this._handleScroll();
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this._handleScroll);
  }

  render() {
    const { offsetTop = getDefaultOffset() } = this.props;
    const child = React.Children.only(this.props.children);
    const childProps = child.props;

    const style = this.state.affixed ? {
      ...childProps.style,
      top: offsetTop
    } : childProps.style;

    return React.cloneElement(child, {
      ...childProps,
      className: classnames(
        childProps.className, 
        this.props.stickyClassName, 
        { affix: this.state.affixed }
      ),
      style
    });
  }

  _handleScroll() {
    const { affixed } = this.state;
    if (this.requiresUpdate()) {
      this.setState({
        affixed: !affixed
      });
    }
  }

  /**
   * determines if the state needs updating 
   * based on position of scrolling
   * @returns {boolean} whether or not the affix requires update
   */
  requiresUpdate() {
    const containerElement = this.getContainer();
    const { offsetTop = getDefaultOffset() } = this.props;
    const { top } = containerElement.getBoundingClientRect();
    const positionTopMax = (top - offsetTop);

    if (positionTopMax <= offsetTop) {
      /* only say we need to update if the state is not already set to affixed */
      return !this.state.affixed;
    } 

    return this.state.affixed;
  }

  getContainer() {
    const container = typeof this.props.container === 'function' ? this.props.container() : this.props.container;
    return container || document.body;
  }
}

Sticky.propTypes = {
  /**
   * Ingnore PropTypes.any -> used as a workaround
   * for passing `this` into the component
   */
  container: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.func, /* must return a ref to a DOM Node*/
    PropTypes.any
  ]),
  stickyClassName: PropTypes.string,
  offsetTop: PropTypes.number
};

export default Sticky;
