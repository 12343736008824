import { UPDATE_MEDICAL_INFO } from '../actions/medical-info';

const updateMedicalInfoReducer = (state = {}, action = {}) => {
  const { medicalInfo = {} } = action;
  const { user_id } = medicalInfo;
  return (user_id) ? { ...state, [user_id]: medicalInfo } : state;
};

const medicalInfo = (state = {}, action = {}) => {
  switch (action.type) {
    case UPDATE_MEDICAL_INFO:
      return updateMedicalInfoReducer(state, action);
    default:
      return state;
  }
};

export default medicalInfo;
