import Strings from './lang';

export const MAX_RESPONSES = 13;
export const STARTING_PRACTISE_NUMBER = 93;
export const OMITTED_START_NUMBERS = [
  100,
  // eslint-disable-next-line no-magic-numbers
  ...([...new Array(5)].map((_, i) => 100 - ((i + 1) * 3))),
  // eslint-disable-next-line no-magic-numbers
  ...([...new Array(2)].map((_, i) => 100 - ((i + 1) * 7))),
];

export const FAILURE_REASONS = {
  STEP_OFF_LINE: 'STEP_OFF_LINE',
  SEPARATION: 'SEPARATION',
  TOUCH_EXAMINER: 'TOUCH_EXAMINER'
};

export const FAILURE_REASON_STRINGS = {
  [FAILURE_REASONS.STEP_OFF_LINE]: Strings.failureStepOffLine,
  [FAILURE_REASONS.SEPARATION]: Strings.failureSeparation,
  [FAILURE_REASONS.TOUCH_EXAMINER]: Strings.failureTouchEnvironment
};

export const getNumbers = (start, step, maxCount = MAX_RESPONSES) => {
  return [...new Array(maxCount)]
    .map((_, i) => start - (i * step));
};

export const generateEmptyResponses = () => {
  return [...new Array(MAX_RESPONSES)].map(() => ({
    value: '',
    correct: null
  }));
};

export const generateResponses = (start, subValue) => {
  return [...new Array(MAX_RESPONSES)].map((_, i) => ({
    value: start - (i * subValue),
    correct: null
  }));
};

export const regenerateResponses = (responses, currentIndex, subValue) => {
  const newResponses = [...responses];

  // eslint-disable-next-line no-plusplus
  for (let i = currentIndex + 1; i < newResponses.length; ++i) {
    const prev = newResponses[i - 1];
    if (prev) {
      newResponses[i].value = prev.value - subValue;
      newResponses[i].correct = null;
    }
  }

  return newResponses;
};

export const recalculateCorrectResponses = (response, currentIndex, start, step) => {
  const newResponse = [...response];

  // eslint-disable-next-line no-plusplus
  for (let i = currentIndex + 1; i < newResponse.length; ++i) {
    const prev = i - 1;
    newResponse[i].correct = newResponse[i].value === '' ? null : (
      (newResponse[prev].value - step) === newResponse[i].value
    );
  }

  return newResponse;
};

export const updateTrialResponses = (responses, value, index, start, step) => {
  const newResponse = [...responses];
  newResponse[index].value = !isNaN(value) ? value : '';

  if (newResponse[index].value === '') {
    newResponse[index].correct = null;
  } else {
    newResponse[index].correct = index === 0
      ? value === start
      : newResponse[index - 1].value - step === newResponse[index].value;
  }

  return recalculateCorrectResponses(newResponse, index, start, step);
};

export const getResponseErrors = (responses) => {
  return responses.reduce((acc, r) => (
    r.correct === null
      ? acc
      : acc + Number(!r.correct)
  ), 0);
};

export const getTrialResponseRate = (trial) => {
  const completed = trial?.responses?.filter(r => r.correct !== null)?.length;
  return ((trial?.duration || 0) / (completed || 1)) / 1000;
};

export const getAverageTrialResponseRate = (trials) => {
  const completed = trials.filter(t => t.failed !== null);
  return completed.reduce((acc, trial) => acc + getTrialResponseRate(trial), 0)
    / (completed.length ?? 1);
};
