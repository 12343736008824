import HockeyPositions from './hockey';
import SoccerPositions from './soccer';
import FootballPositions from './football';
import BaseballPositions from './baseball';
import BasketballPositions from './basketball';
import AustralianFootballPositions from './aussierules';
import FieldLacrossePositions from './fieldlacrosse';
import BoxLacrossePositions from './boxlacrosse';
import RugbyPositions from './rugby';
import FieldHockeyPositions from './fieldhockey';

const Positions = {
  hockey: HockeyPositions,
  soccer: SoccerPositions,
  football: FootballPositions,
  baseball: BaseballPositions,
  basketball: BasketballPositions,
  aussierules: AustralianFootballPositions,
  fieldlacrosse: FieldLacrossePositions,
  boxlacrosse: BoxLacrossePositions,
  ringette: HockeyPositions,
  rugby: RugbyPositions,
  fieldhockey: FieldHockeyPositions
};

export default Positions;
