import React from 'react';
import AdminInvoiceActions from './admin-invoice-actions';
import InvoicePDFDownload from './pdf';

const InvoiceActions = ({
  currency,
  clinicId,
  invoiceId,
  balance,
  invoiceStatus
}) => {
  return (
    <div className="invoice-actions">
      <div className="invoice-action">
        <InvoicePDFDownload 
          clinicId={clinicId}
          invoiceId={invoiceId}
        />
      </div>
      {invoiceStatus !== 'paid' && (
        <AdminInvoiceActions 
          status={invoiceStatus}
          currency={currency}
          clinicId={clinicId}
          invoiceId={invoiceId}
          balance={balance}
        />
      )}
    </div>
  );
};

export default InvoiceActions;
