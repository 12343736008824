import React from 'react';
import SportSelector from './sport-selector';
import { HelpModalLink } from '../help-modal';

const StageDescriptionLink = ({ 
  documentName,
  sportSelection,
  className,
  children
}) => {
  return (
    <HelpModalLink 
      className={className}
      documentName={documentName}
      extraContent={sportSelection && (
        <SportSelector 
          rootPath={documentName}
          {...sportSelection} 
        />
      )}
    >
      {children}
    </HelpModalLink>
  );
};

export default StageDescriptionLink;
