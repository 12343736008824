import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import FormInputGroup from './form-input-group';
import { EmailValidator } from '../validators/email-validator';
import Strings from './lang';

class EmailInputGroup extends Component {
  constructor(props) {
    super(props);

    const value = this.mergeInputProps().value || '';
    const valid = (value.length === 0 && !props.required) || EmailValidator.test(value);

    this.onUpdate = this.onUpdate.bind(this);

    this.state = {
      valid
    };
  }

  render() {
    return (
      <FormInputGroup
        className={classNames('email-input-group', this.props.className)}
        labelText={this.props.labelText || Strings.emailLabelText}
        labelProps={this.props.labelProps}
        inputType="email"
        inputProps={this.mergeInputProps()}
        inputValid={this.state.valid}
        messageText={this.props.messageText || Strings.emailErrorText}
        messageClassName={this.props.messageClassName}
        required={this.props.required}
        touched={this.props.touched}
      />
    );
  }

  mergeInputProps() {
    return {
      ...(this.props.inputProps || {}),
      onBlur: this.onUpdate,
      onChange: this.onUpdate
    };
  }

  onUpdate(event) {
    const { required, onUpdate } = this.props;
    const { value } = event.target;
    const valid = (value.length === 0 && !required) || EmailValidator.test(value);

    this.setState({
      valid
    });

    if (typeof onUpdate === 'function') {
      onUpdate(value, valid);
    }
  }
}

EmailInputGroup.propTypes = {
  className: PropTypes.string,
  labelText: PropTypes.string,
  labelProps: PropTypes.object,
  inputProps: PropTypes.object,
  messageText: PropTypes.string,
  messageClassName: PropTypes.string,
  required: PropTypes.bool,
  touched: PropTypes.bool,
  onUpdate: PropTypes.func
};

export default EmailInputGroup;
