import { jsonApiRead, jsonApiCreate, jsonApiUpdate } from '@/lib/requests/jsonapi';
import { getCurrentClinicId } from '@/tokens';
import { updateBlackhawksTest } from '../actions/blackhawks';

const getBlackhawksTestAsync = (userId, injuryId, blackhawksId) => {
  const options = { path: `users/${userId}/injuries/${injuryId}/blackhawks_tests/${blackhawksId}` };
  return (dispatch) => {
    return jsonApiRead({ type: 'blackhawks_tests', id: blackhawksId }, options).then(results => {
      dispatch(updateBlackhawksTest(userId, results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const createBlackhawksTestAsync = (userId, injuryId, attributes) => {
  const queryParams = { current_clinic_id: getCurrentClinicId() };
  const options = { path: `users/${userId}/injuries/${injuryId}/blackhawks_tests`, queryParams };
  return (dispatch) => {
    return jsonApiCreate({ type: 'blackhawks_tests', attributes }, options).then(results => {
      dispatch(updateBlackhawksTest(userId, results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const updateBlackhawksTestAsync = (userId, injuryId, blackhawksId, attributes) => {
  const options = { path: `users/${userId}/injuries/${injuryId}/blackhawks_tests/${blackhawksId}` };
  return (dispatch) => {
    return jsonApiUpdate({ type: 'blackhawks_tests', id: blackhawksId, attributes }, options).then(results => {
      dispatch(updateBlackhawksTest(userId, results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

export {
  getBlackhawksTestAsync,
  createBlackhawksTestAsync,
  updateBlackhawksTestAsync
};
