import React, { useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';
import Portal from '../portal';
import ScrollLock from './scroll-lock';
import EscapeListener from './escape-listener';
import BlurEffect from './blur-effect';
import Backdrop from './backdrop';
import { usePrevious } from '@/hooks';

const TIMEOUT_FALLBACK_DELAY = 300;
const OPEN_DELAY = 20;

const Overlay = ({
  isOpen = false,
  blur = false,
  blurNode,
  appearance = 'dark',
  scrollLock = true,
  static: overlayDisabled = false,
  className,
  onClose = () => {},
  children
}) => {
  const [isVisible, setIsVisible] = useState(isOpen);
  const [open, setOpen] = useState(false);
  const prevOpen = usePrevious(open);

  const onPortalMount = useCallback(() => {
    setTimeout(() => setOpen(true), OPEN_DELAY);
  }, []);

  useEffect(() => {
    if (isOpen) {
      setIsVisible(true);
    }

    if (!isOpen) {
      setOpen(false);
    }
  }, [isOpen]);

  // This is essentially a fallback in the event
  // that onTransitionEnd is not triggered. If it isn't
  // then the timeout will resolve and set isVisible to false -
  // removing the modal portal. If onTransitionEnd is triggered,
  // the timeout will be canceled.
  useEffect(() => {
    if (prevOpen && !open && isVisible) {
      const handle = setTimeout(() => setIsVisible(false), TIMEOUT_FALLBACK_DELAY);
      return () => {
        clearTimeout(handle);
      };
    }

    return undefined;
  }, [isVisible, open, prevOpen]);

  return (
    <Portal 
      visible={isVisible}
      onMount={onPortalMount}
      className={classnames(className, { open })}
    >
      <EscapeListener 
        enabled={open && !overlayDisabled} 
        onEscape={onClose} 
      />
      <BlurEffect 
        enabled={blur && isOpen}   
        node={blurNode}
      />
      <ScrollLock enabled={scrollLock && open} />
      <Backdrop 
        isOpen={isOpen}
        blurred={blur}
        appearance={appearance}
        disabled={overlayDisabled}
        onClick={onClose}
        onCloseComplete={() => setIsVisible(false)}
      />
      {children}
    </Portal>
  );
};

export default Overlay;
