import React, { forwardRef } from 'react';
import { ReactionTimeCard } from '../baseline-test-cards';

const ReactionTimeStep = forwardRef((
  {
    baseline = {},
    onSubmit,
    ...rest
  },
  ref
) => {
  return (
    <ReactionTimeCard
      ref={ref}
      reaction={{ ...(baseline.reaction_time_test || {}) }}
      onSubmit={onSubmit}
      {...rest}
    />
  );
});

ReactionTimeStep.hasChanged = (initial, current) => {
  const currentResults = current.test_results || [];
  const initialResults = initial.test_results;

  const resultsDiff = currentResults.filter(score => {
    if (!initialResults && score === '') {
      return false;
    }

    return (initial.test_results || []).indexOf(score) < 0;
  });

  return resultsDiff.length > 0
    || (initial.dominant_hand || '') !== current.dominant_hand
    || (initial.notes || '') !== current.notes;
};

export default ReactionTimeStep;
