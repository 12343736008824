import React, {
  useState, useMemo, useImperativeHandle, useCallback, useRef 
} from 'react';
import classnames from 'classnames';
import { CardElement, useElements } from '@stripe/react-stripe-js';
import FormInputMessage from './form-input-message';

const CARD_OPTIONS = {
  iconStyle: 'solid',
  style: {
    base: {
      backgroundColor: '#f6f6f6',
      iconColor: '#acacac',
      color: '#4b4b4b',
      fontWeight: 'normal',
      fontFamily: 'Calibre, sans-serif',
      fontSize: '14px',
      fontSmoothing: 'antialiased',
      '::placeholder': { color: '#acacac' },
    },
    invalid: {
      iconColor: '#da0049',
      color: '#da0049',
    },
  },
};

const useID = () => {
  // eslint-disable-next-line no-magic-numbers
  return useMemo(() => Math.random().toString(36).substr(2, 9), []);
};

const StripeCardInput = React.forwardRef(({ 
  required = false, 
  label,
  onValidStateChange = () => {}
}, ref) => {
  const id = useID();
  const previousValidState = useRef(false);
  const elements = useElements();
  const [focused, setFocused] = useState(false);
  const [error, setError] = useState(null);

  const onChange = useCallback((event) => {
    const { error, complete = false } = event;
    const isValid = !error && !!complete;

    if (previousValidState.current !== isValid) {
      onValidStateChange(isValid);
      previousValidState.current = isValid;
    }

    setError((error || {}).message || null);
  }, [onValidStateChange]);

  useImperativeHandle(ref, () => ({
    getElement: () => elements.getElement(CardElement)
  }), [elements]);

  return (
    <div className="form-input-group">
      {label && (
        <label htmlFor={id} className="uppercase">
          {label}
          {required && (
            <span className="required">*</span>
          )}
        </label>
      )}
      <div className={classnames('stripe-card-element', { focused })}>
        <CardElement 
          id={id}
          onFocus={() => setFocused(true)} 
          onBlur={() => setFocused(false)} 
          options={CARD_OPTIONS} 
          onChange={onChange}
        />
      </div>
      <FormInputMessage 
        className="alert alert-danger"
        visible={!!error}
        text={error}
      />
    </div>
  );
});

StripeCardInput.Element = CardElement;

export default StripeCardInput;
