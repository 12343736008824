import dayjs from 'dayjs';

const sortAscending = (key, a, b) => {
  if (a[key] !== b[key]) {
    return (a[key] > b[key]) ? 1 : -1;
  } 
    
  return 0;
};

const sortDescending = (key, a, b) => {
  if (a[key] !== b[key]) {
    return (a[key] < b[key]) ? 1 : -1;
  }
  
  return 0;
};

const sortDateAscending = (key, a, b) => {
  const dateA = dayjs(a[key]);
  const dateB = dayjs(b[key]);

  if (!dateA.isValid()) {
    return -1;
  } 

  if (!dateB.isValid()) {
    return 1;
  }

  if (dateA.isSame(dateB)) {
    return 0;
  } 
    
  return (dateA.isAfter(dateB)) ? 1 : -1; 
};

const sortDateDescending = (key, a, b) => {
  const dateA = dayjs(a[key]);
  const dateB = dayjs(b[key]);

  if (!dateA.isValid()) {
    return 1;
  } 
  
  if (!dateB.isValid()) {
    return -1;
  } 
  
  if (dateA.isSame(dateB)) {
    return 0;
  } 
  
  return (dateA.isBefore(dateB)) ? 1 : -1; 
};

export {
  sortAscending,
  sortDescending,
  sortDateAscending,
  sortDateDescending
};
