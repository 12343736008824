import { jsonApiRead } from '@/lib/requests/jsonapi';
import { updateClinicStatistics } from '../actions/clinic-statistics';

const getClinicStatisticsAsync = (clinicId) => {
  return (dispatch) => {
    return jsonApiRead({ type: 'clinic_statistics', id: clinicId }, { path: `clinics/${clinicId}/statistics` }).then(stats => {
      dispatch(updateClinicStatistics(clinicId, stats));
      return Promise.resolve({ clinicId, stats });
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

export {
  getClinicStatisticsAsync
};
