import React, { Component } from 'react';
import { connect } from 'react-redux';
import { If, Then, Else } from 'react-if';
import { Page } from '../page';
import { ErrorBanner } from '../errors';
import { sortOnKeyPath } from '@/utilities/sort-keypath';
import { getClinicAsync } from '@/redux/thunks/clinics';
import { ClinicPaths } from '@/paths';
import replaceLinkParams from '@/utilities/replace-link-params';
import { withRouter } from '@/utilities/router-utils';
import ClinicSearchControl from './clinic-search-control';
import Strings from './lang';
import {
  ClinicPatientsTable,
  getClinicPatientsTableSortKeyPath,
  setClinicPatientsTableSortKeyPath
} from '../clinic-patients-table';

class ClinicSearch extends Component {
  constructor(props) {
    super(props);

    const { params = {}, clinics = {}, clinicPlayersSearch = {} } = props;
    const { id = 0 } = params;
    const clinic = clinics[id] || {};
    const { results = [] } = clinicPlayersSearch[id] || {};
    const sortKeyPath = getClinicPatientsTableSortKeyPath();

    this._onSearch = this._onSearch.bind(this);
    this._onError = this._onError.bind(this);
    this._onClear = this._onClear.bind(this);
    this._updateSortKeyPath = this._updateSortKeyPath.bind(this);

    this.state = {
      id: parseInt(id, 10),
      clinic,
      results,
      sortKeyPath,
      sortedResults: sortOnKeyPath(results, sortKeyPath),
      error: null,
      searched: false
    };
  }

  componentDidMount() {
    this.getClinic();
  }

  render() {
    const { clinic } = this.state;
    return (
      <Page className="clinic-search">

        <div className="row">
          <div className="col-md-12">
            <h1 className="display">{clinic.name}</h1>
            <h2>{Strings.title}</h2>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <ErrorBanner error={this.state.error} />
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">

            <ClinicSearchControl
              clinicId={this.state.id}
              onSearch={this._onSearch}
              onError={this._onError}
              onClear={this._onClear}
            />

            <div className="clinic-search-results">
              <If condition={this.state.results.length > 0}>
                <Then>
                  <ClinicPatientsTable
                    users={this.state.sortedResults}
                    sortKeyPath={this.state.sortKeyPath}
                    showDanaBaseline={false}
                    onUpdateSortKeyPath={this._updateSortKeyPath}
                  />
                </Then>
                <Else>
                  <If condition={this.state.searched}>
                    <Then>
                      <p className="alert alert-info">
                        {Strings.searchResultsEmptyText}
                      </p>
                    </Then>
                  </If>
                </Else>
              </If>
            </div>
          </div>
        </div>
      </Page>
    );
  }

  _updateSortKeyPath(sortKeyPath) {
    const { results } = this.state;
    setClinicPatientsTableSortKeyPath(sortKeyPath);
    const sortedResults = sortOnKeyPath(results, sortKeyPath);
    this.setState({ sortKeyPath, sortedResults });
  }

  _onSearch(response) {
    const { sortKeyPath } = this.state;
    const { results = [] } = response;
    const sortedResults = sortOnKeyPath(results, sortKeyPath);

    this.setState({
      results,
      sortedResults,
      searched: true
    });

    if (results.length === 1) {
      const user = results[0];
      const path = replaceLinkParams(ClinicPaths.patientProfile.index.link, {
        userId: user.id,
        clinicId: this.state.id
      });

      this.props.router.push(path);
    }
  }

  _onError(error) {
    this.setState({
      results: [],
      sortedResults: [],
      error: error.message,
      searched: false
    });
  }

  _onClear() {
    this.setState({
      results: [],
      sortedResults: [],
      searched: false
    });
  }

  getClinic() {
    this.setState({
      error: null
    });

    this.props.getClinic(this.state.id).then(clinic => {
      this.setState({ clinic });
    }).catch(error => {
      this.setState({
        error: error.message
      });
    });
  }
}

const mapStateToProps = (state) => {
  const { clinics = {}, clinicPlayersSearch = {} } = state;
  return { clinics, clinicPlayersSearch };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getClinic: id => {
      return dispatch(getClinicAsync(id));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ClinicSearch)
);
