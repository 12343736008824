import React from 'react';
import AddBookingLinkButton from './add-booking-link-button';
import Strings from './lang';
import Icon from '../icon';

const BookingLinksEmptyView = ({ 
  userId, 
  editable, 
  clinics = [],
  message = null
}) => {
  return (
    <div className="booking-links-empty-view">
      <div className="booking-links-empty-icon">
        <Icon name="link-slash" />
      </div>
      <p className="text-center">
        {message || Strings.emptyViewMessage}
      </p>
      <AddBookingLinkButton 
        className="btn btn-primary btn-sm"
        clinics={clinics}
        userId={userId}
        visible={editable}
      />
    </div>
  );
};

export default BookingLinksEmptyView;
