const validateAddress = (address = {}) => {
  const {
    street1 = '',
    city = '',
    state = '',
    postalCode = '',
    country = ''
  } = address;

  return street1.length > 0
    && city.length > 0
    && state.length > 0
    && postalCode.length > 0
    && country.length > 0;
};

export default validateAddress;
