import { UPDATE_CLINIC_BALANCE } from '../actions/clinic-balance';

const updateClinicBalanceReducer = (action = {}) => {
  const { clinicId, clinicBalance = {} } = action;
  return { [clinicId]: clinicBalance };
};

const clinicBalance = (state = {}, action = {}) => {
  switch (action.type) {
    case UPDATE_CLINIC_BALANCE:
      return updateClinicBalanceReducer(action);
    default:
      return state;
  }
};

export default clinicBalance;
