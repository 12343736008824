import { jsonApiUpdate, jsonApiCreate } from '@/lib/requests/jsonapi';
import { updateUserPosition } from '../actions/users';

const updatePositionAsync = (userId, positionId, attributes, options = {}) => {
  return (dispatch) => {
    return jsonApiUpdate({ type: 'positions', id: positionId, attributes }, options).then(position => {
      dispatch(updateUserPosition(userId, position));
      return Promise.resolve(position);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const createPositionsAsync = (userId, attributes, options = {}) => {
  return (dispatch) => {
    return jsonApiCreate({ type: 'positions', attributes }, options).then(position => {
      dispatch(updateUserPosition(userId, position));
      return Promise.resolve(position);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

export {
  updatePositionAsync,
  createPositionsAsync
};
