import { jsonApiRead, jsonApiCreate, jsonApiUpdate } from '@/lib/requests/jsonapi';
import { getCurrentClinicId } from '@/tokens';
import { updateMedicalInfo } from '../actions/medical-info';

const createMedicalInfoAsync = (userId, attributes) => {
  const queryParams = { current_clinic_id: getCurrentClinicId() };
  const options = { path: `users/${userId}/medical_info`, queryParams };
  return (dispatch) => {
    return jsonApiCreate({ type: 'medical_infos', attributes }, options).then(results => {
      dispatch(updateMedicalInfo(results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const updateMedicalInfoAsync = (userId, id, attributes) => {
  const queryParams = { current_clinic_id: getCurrentClinicId() };
  const options = { path: `users/${userId}/medical_info`, queryParams };
  return (dispatch) => {
    return jsonApiUpdate({ type: 'medical_infos', id, attributes }, options).then(results => {
      dispatch(updateMedicalInfo(results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const getMedicalInfoAsync = (userId) => {
  const options = { path: `users/${userId}/medical_info` };
  return (dispatch) => {
    return jsonApiRead({ type: 'medical_infos' }, options).then(results => {
      dispatch(updateMedicalInfo(results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

export {
  createMedicalInfoAsync,
  updateMedicalInfoAsync,
  getMedicalInfoAsync
};
