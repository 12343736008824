import React, { useMemo, useCallback } from 'react';
import classnames from 'classnames';
import ReactSelect, { components } from '../../react-select';
import { COGNITIVE, TEST_OPTIONS } from '../helpers';

const transformSelectValueIn = (value) => {
  return TEST_OPTIONS.find((option) => option.value === value);
};

const OptionImage = ({ type, selected }) => {
  if (type === 'cognitive') {
    return null;
  }

  if (COGNITIVE.includes(type)) {
    return (
      <img
        alt={type}
        src={`/images/dana/${type.toLowerCase()}.png`}
        className={classnames('logo', { selected })}
      />
    );
  }

  return (
    <div className={classnames('logo text', { selected })}>
      {type}
    </div>
  );
};

const Option = ({
  value,
  children,
  isSelected,
  ...rest
}) => {
  return (
    <components.Option
      {...rest}
      isSelected={isSelected}
      className="dana-test-select-option"
      value={value}
    >
      <OptionImage type={value} selected={isSelected} /> {children}
    </components.Option>
  );
};

const SingleValue = ({
  data,
  children,
  ...rest
}) => {
  return (
    <components.SingleValue
      {...rest}
      className="dana-test-select-option"
      data={data}
    >
      <OptionImage type={data.value} /> {children}
    </components.SingleValue>
  );
};

const transformSelectValueOut = (value) => value.value;

const TestSelector = ({
  value = TEST_OPTIONS[0],
  onSelect = () => { }
}) => {
  const selectValue = useMemo(() => transformSelectValueIn(value), [value]);

  const handleChange = useCallback((values) => {
    const v = transformSelectValueOut(values);
    onSelect(v);
  }, [onSelect]);

  return (
    <ReactSelect
      value={selectValue}
      clearable={false}
      options={TEST_OPTIONS}
      onChange={handleChange}
      components={{
        Option,
        SingleValue
      }}
    />
  );
};

export default TestSelector;
