import { createSelector } from 'reselect';

const areIdsEqual = (id1, id2) => String(id1) === String(id2);

const getSpecialistNotes = (state) => state.specialistNotes || {};
const getUserId = (_, { userId }) => userId;
const getInjuryId = (_, { injuryId }) => injuryId;
const getNoteId = (_, { noteId }) => noteId;

const getUserSpecialistNotes = createSelector(
  [getSpecialistNotes, getUserId],
  (notes, userId) => {
    return notes[userId] || {};
  }
);

const getInjurySpecialistNotes = createSelector(
  [getUserSpecialistNotes, getInjuryId],
  (notes, injuryId) => {
    return Object.values(notes).filter(note => (
      areIdsEqual(note.injury_id, injuryId) && !note.archived_at
    ));
  }
);

const getUserSpecialistNote = createSelector(
  [getUserSpecialistNotes, getNoteId],
  (notes, id) => {
    return notes[id] || {};
  }
);

const getPreviousSpecialistNotes = createSelector(
  [getInjurySpecialistNotes, getNoteId],
  (notes, omitted) => {
    if (omitted) {
      return notes.filter(note => !areIdsEqual(note.id, omitted));
    }

    return notes;
  }
);

export default {
  getUserSpecialistNote,
  getUserSpecialistNotes,
  getInjurySpecialistNotes,
  getPreviousSpecialistNotes
};
