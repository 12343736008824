import React, { useCallback, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { If, Then } from 'react-if';
import { daysSince } from '@/utilities/dates';
import { DISPLAY_DATE_FORMAT, INPUT_DATE_FORMAT } from '@/utilities/dates/formats';
import { UserPaths, ClinicPaths } from '@/paths';
import { InjuryDiagnosis } from '../diagnosis';
import { ReferPatientButton } from '../referrals';
import LastEditedView from '../last-edited-view';
import IncompleteAssessmentModal from './incomplete-assessment-modal';
import { InjuryStatusSelector, InjuryStatusModal } from '../injury-status';
import { showAlert } from '../alert-notifications';
import { getOpenSoapNotes } from '../soap-notes/soap-utilities';
import AccessControl from '../access-control';
import { 
  makeRoleDescription, 
  RoleDescriptions, 
  RoleNames, 
  RoleResourceTypes, 
  userHasRoleMatchingOneOfDescriptions 
} from '@/utilities/user-roles';
import { getAdjustedAssessmentDate } from '@/utilities/injury-assessment';
import replaceLinkParams from '@/utilities/replace-link-params';
import Strings from './lang';
import Icon from '../icon';

const mergeTestsForSoapNotes = (soapNotes = []) => {
  return soapNotes.reduce((tests, soap) => {
    const {
      injury_assessments = [],
      baselines = [],
      blackhawks_tests = [],
      buffalo_tests = [],
      voms_tests = [],
      orthostatic_vital_signs_tests = []
    } = soap;

    return {
      ...tests,
      assessment: [...tests.assessment, ...injury_assessments],
      blackhawks: [...tests.blackhawks, ...blackhawks_tests],
      buffalo: [...tests.buffalo, ...buffalo_tests],
      voms: [...tests.voms, ...voms_tests],
      baselines: [...tests.baselines, ...baselines],
      ovs: [...tests.ovs, ...orthostatic_vital_signs_tests]
    };
  }, {
    assessment: [],
    blackhawks: [],
    buffalo: [],
    voms: [],
    baselines: [],
    ovs: []
  });
};

const hasIncompleteAssessment = (injury) => {
  const { injury_assessments = [] } = injury;
  return (injury_assessments[0] || {}).completed === false;
};

const mergeTestsForInjuryAssessments = (injuryAssessments = []) => {
  return injuryAssessments.reduce((tests, assessment) => {
    const {
      buffalo_tests = [],
      voms_tests = [],
      baselines = [],
      orthostatic_vital_signs_tests = []
    } = assessment;

    return {
      baselines: [...tests.baselines, ...baselines],
      buffalo: [...tests.buffalo, ...buffalo_tests],
      voms: [...tests.voms, ...voms_tests],
      ovs: [...tests.ovs, ...orthostatic_vital_signs_tests]
    };
  }, {
    baselines: [],
    buffalo: [],
    voms: [],
    ovs: []
  });
};

const mergeTestsForInjury = (injury = {}) => {
  const {
    injury_assessments = [],
    blackhawks_tests = [],
    buffalo_tests = [],
    voms_tests = [],
    soap_notes = [],
    repeat_baselines = []
  } = injury;

  const soapTests = mergeTestsForSoapNotes(soap_notes);
  const assessmentTests = mergeTestsForInjuryAssessments(injury_assessments);

  const tests = [
    ...mapTestsWithType([...repeat_baselines, ...soapTests.baselines, ...assessmentTests.baselines], 'baseline'),
    ...mapTestsWithType([...injury_assessments, ...soapTests.assessment], 'assessment'),
    ...mapTestsWithType([...blackhawks_tests, ...soapTests.blackhawks], 'blackhawks'),
    ...mapTestsWithType([...buffalo_tests, ...soapTests.buffalo, ...assessmentTests.buffalo], 'buffalo'),
    ...mapTestsWithType([...voms_tests, ...soapTests.voms, ...assessmentTests.voms], 'voms'),
    ...mapTestsWithType([...soapTests.ovs, ...assessmentTests.ovs], 'ovs')
  ];

  return tests.sort((a, b) => {
    if (a.test.created_at !== b.test.created_at) {
      return (a.test.created_at > b.test.created_at) ? 1 : -1;
    } 
      
    return 0;
  });
};

const mapTestsWithType = (tests, type) => {
  return tests.sort((a = {}, b = {}) => {
    if (a.created_at !== b.created_at) {
      return (a.created_at > b.created_at) ? 1 : -1;
    }
      
    return 0;
    /** filter out duplicate test instances */
  }).filter((test, index, arr) => {
    return arr.findIndex(t => t.id === test.id) === index;
  }).map((test, index) => {
    return { test, type, index };
  });
};

const pathnameForTest = (test, type, user, injury, clinicId) => {
  switch (type) {
    case 'assessment':
      return replaceLinkParams(ClinicPaths.patientProfile.injuries.assessment.link, {
        clinicId,
        userId: user.id,
        injuryId: injury.id,
        assessmentId: test.id
      });
    case 'continueAssessment': 
      return replaceLinkParams(UserPaths.injuries.fullAssessment.link, {
        userId: user.id,
        injuryId: injury.id,
        assessmentId: test.id
      });
    case 'blackhawks':
      return replaceLinkParams(ClinicPaths.patientProfile.injuries.blackhawks.link, {
        clinicId,
        userId: user.id,
        injuryId: injury.id,
        blackhawksId: test.id
      });
    case 'buffalo':
      return replaceLinkParams(ClinicPaths.patientProfile.injuries.buffalo.link, {
        clinicId,
        userId: user.id,
        injuryId: injury.id,
        buffaloId: test.id
      });
    case 'voms':
      return replaceLinkParams(ClinicPaths.patientProfile.injuries.voms.link, {
        clinicId,
        userId: user.id,
        injuryId: injury.id,
        vomsId: test.id
      });
    case 'ovs':
      return replaceLinkParams(ClinicPaths.patientProfile.injuries.ovs.link, {
        clinicId,
        userId: user.id,
        injuryId: injury.id,
        ovsId: test.id
      });
    case 'baseline':
      return replaceLinkParams(ClinicPaths.patientProfile.injuries.postInjuryTest.link, {
        clinicId,
        userId: user.id,
        injuryId: injury.id,
        testId: test.id,
        compareContext: dayjs(test.created_at).valueOf()
      });
    default:
      return null;
  }
};

const renderTestLink = (test, type, index, path, renderLink) => {
  let date = dayjs(test.created_at);

  if (type === 'assessment') {
    date = getAdjustedAssessmentDate(test.created_at);
  }

  const linkContent = Strings.formatString(
    Strings.testLinkText,
    Strings[`${type}TestName`],
    index + 1,
    date.format(DISPLAY_DATE_FORMAT)
  );

  if (renderLink) {
    return (
      <Link to={path}>{linkContent}</Link>
    );
  } 

  return (
    <span>{linkContent}</span>
  );
};

const makeCreateReferralRoles = (clinicId) => ([
  makeRoleDescription(RoleNames.Clinician, RoleResourceTypes.Clinic, clinicId),
  makeRoleDescription(RoleNames.Specialist, RoleResourceTypes.Clinic, clinicId)
]);

const makeReferralRoles = (clinicId) => ([
  ...makeCreateReferralRoles(clinicId),
  makeRoleDescription(RoleNames.ClinicFrontDesk, RoleResourceTypes.Clinic, clinicId)
]);

const ContinueAssessmentLink = ({ visible = false, path }) => {
  if (visible) {
    return (
      <span className="warning">&nbsp;
        <Icon name="triangle-exclamation" />&nbsp;
        {Strings.inCompleteAssessmentText}&nbsp;
        <AccessControl roles={[RoleDescriptions.Clinician]}>
          <Link to={path}>
            {Strings.continueAssessmentText}
          </Link>
        </AccessControl>
      </span>
    );
  }

  return null;
};

const getRecoveryDuration = (injury = {}) => {
  const injuryState = (injury.injury_state_infos || [])[0];
  let recoveryDurationString = null;
  const injuryDuration = (injuryState)
    ? daysSince(injury.injured_at, injuryState.action_date) + 1
    : 0;

  if (injury.status === 'ongoing') {
    recoveryDurationString = Strings.formatString(
      Strings.injuryStateOngoingLabelText,
      daysSince(injury.injured_at, dayjs()) + 1
    );
  } else if (injury.status === 'discharged' && injuryState !== undefined && injuryState.new_state === 'discharged') {
    recoveryDurationString = Strings.formatString(
      Strings.injuryStateDischargedLabelText,
      dayjs(injuryState.action_date).format(DISPLAY_DATE_FORMAT),
      injuryDuration
    );
  } else if (injury.status === 'lost_to_follow_up' && injuryState !== undefined && injuryState.new_state === 'lost_to_follow_up') {
    if (!injuryState.action_date) {
      recoveryDurationString = Strings.injuryStateLostLabelDefault;
    } else {
      recoveryDurationString = Strings.formatString(
        Strings.injuryStateLostLabelInfo,
        dayjs(injuryState.action_date).format(DISPLAY_DATE_FORMAT),
        injuryDuration
      );
    }
  }

  return recoveryDurationString;
};

const InjuryHistoryListItem = ({
  injuryNumber,
  injury = {},
  user = {},
  enableTestLinks = false,
  enableAssessmentLinks = false,
  clinic = {},
  currentUser,
  onUpdateInjury
}) => {
  const [statusModalOpen, setStatusModalOpen] = useState(false);
  const [assessmentModalOpen, setAssessmentModalOpen] = useState(false);
  const [nextStatus, setNextStatus] = useState(null);

  const { progress_reports = [], referrals = [] } = injury;
  const sortedTests = useMemo(() => mergeTestsForInjury(injury), [injury]);
  const recoveryDuration = useMemo(() => getRecoveryDuration(injury), [injury]);
  const openSoapNotes = useMemo(() => getOpenSoapNotes(injury.soap_notes), [injury.soap_notes]);
  const pendingReferralCount = useMemo(() => referrals.filter(({ status }) => status === 'pending').length, [referrals]);
  const isCompleteInitialAssessment = injury.status === 'ongoing' && hasIncompleteAssessment(injury);

  const injuryState = (injury.injury_state_infos || [])[0];
  const activeInjuryId = user.active_injury_id || 0;
  const injuryId = parseInt(injury.id, 10);
  const injuryIcon = injury.status !== 'ongoing'
    ? 'folder-closed'
    : 'folder-open';

  const beforeLinkToSoaps = useCallback((e) => {
    if (clinic.active === true && isCompleteInitialAssessment) {
      e.preventDefault();
      setAssessmentModalOpen(true);
    }
  }, [clinic.active, isCompleteInitialAssessment]);

  const onInjuryStatusChange = useCallback((nextStatus) => {
    if ((injury.status === 'ongoing' && nextStatus === 'discharged')
      || (injury.status === 'ongoing' && nextStatus === 'lost_to_follow_up')
      || (injury.status === 'discharged' && nextStatus === 'ongoing')
    ) {
      setNextStatus(nextStatus);
      setStatusModalOpen(true);
    } else {
      onUpdateInjury(injury.id, { status: nextStatus });
    }
  }, [injury.id, injury.status, onUpdateInjury]);

  return (
    <li key={injury.id}>

      <div className="injury-list-item-header">
        <h3>
          <Icon name={injuryIcon} />&nbsp;&nbsp;
          {Strings.formatString(
            Strings.injuryHeadingText,
            injuryNumber,
            dayjs(injury.injured_at, INPUT_DATE_FORMAT).format(DISPLAY_DATE_FORMAT)
          )}
        </h3>
        <span><em>{recoveryDuration}</em></span>
      </div>

      {/* Metadata */}
      <div className="injury-history-section injury-history-meta">

        <ul className="list-unstyled injury-status">
          <li>{Strings.injuryStatusLabelText}
            <InjuryStatusSelector
              disabled={
                !userHasRoleMatchingOneOfDescriptions(
                  currentUser,
                  [RoleDescriptions.Clinician, RoleDescriptions.Specialist]
                ) || clinic.active === false
                || (activeInjuryId > 0 && activeInjuryId !== injuryId)
              }
              status={injury.status || ''}
              onChange={onInjuryStatusChange}
            />
            <InjuryStatusModal
              isOpen={statusModalOpen}
              status={nextStatus}
              injuryDate={injury.injured_at}
              onSubmit={(attributes) => onUpdateInjury(injury.id, attributes)}
              onClose={() => setStatusModalOpen(false)}
            />
          </li>
          {(injury.status === 'lost_to_follow_up' && injuryState !== undefined && injuryState.new_state === 'lost_to_follow_up') && (
            <li>
              {Strings.formatString(
                Strings.injuryStateLostLabelText,
                injuryState.reason
              )}
            </li>
          )}
        </ul>

        {injury.status === 'ongoing' && !isCompleteInitialAssessment && (
          <AccessControl roles={[RoleDescriptions.Clinician, RoleDescriptions.Specialist]}>
            <div style={{ marginRight: 10 }}>
              <Link
                to={
                  replaceLinkParams(ClinicPaths.patientProfile.injuries.insights.link, {
                    clinicId: clinic.id,
                    userId: user.id,
                    injuryId: injury.id
                  })
                }
                className="btn btn-sm btn-primary"
              >
                <Icon name="eye" />&nbsp;&nbsp;
                Insights
              </Link>
            </div>
          </AccessControl>
        )}

        <AccessControl roles={makeReferralRoles(clinic.id)}>
          <div className="referral-section">
            {injury.status === 'ongoing' && (
              <AccessControl roles={makeCreateReferralRoles(clinic.id)}>
                <ReferPatientButton
                  injuryId={injury.id}
                  disabled={hasIncompleteAssessment(injury)}
                  disabledReason={Strings.enableReferralMessage}
                  className="btn btn-sm btn-default"
                  onComplete={() => {
                    showAlert('success', {
                      dismissable: true,
                      autoDismiss: 4000,
                      message: Strings.referralSuccessMessage
                    });
                  }}
                >
                  <Icon name="external-link" />&nbsp;&nbsp;&nbsp;
                  {Strings.referralButtonText}
                </ReferPatientButton>
              </AccessControl>
            )}
            {injury.referrals?.length > 0 && (
              <div>
                <Link
                  className="referrals-link"
                  to={replaceLinkParams(ClinicPaths.patientProfile.injuries.referrals.link, {
                    clinicId: clinic.id,
                    userId: user.id,
                    injuryId: injury.id
                  })}
                >
                  {Strings.viewReferralsLink}
                  {pendingReferralCount > 0 && (
                    <span className="badge badge-warning">{pendingReferralCount}</span>
                  )}
                </Link>
              </div>
            )}
          </div>
        </AccessControl>
      </div>

      {/* Diagnosis */}
      <AccessControl
        allowIfClinicDeactivated
        roles={[
          RoleDescriptions.Clinician,
          RoleDescriptions.Specialist,
          RoleDescriptions.ClinicFrontDesk
        ]}
      >
        <InjuryDiagnosis
          className="injury-history-section injury-history-diagnosis"
          diagnosis={injury.diagnosis || {}}
        />
      </AccessControl>

      {/* SOAP Notes, Symptoms */}
      <div className="injury-history-section injury-history-soap">
        <ul className="list-unstyled">
          <li>
            <Icon name="list" />&nbsp;
            <Link
              to={replaceLinkParams(ClinicPaths.patientProfile.injuries.soapNotes.index.link, {
                clinicId: clinic.id,
                userId: user.id,
                injuryId: injury.id
              })}
              onClick={beforeLinkToSoaps}
            >
              {Strings.clinicalNotesLinkText}
            </Link>
            <IncompleteAssessmentModal
              isOpen={assessmentModalOpen}
              onClose={() => setAssessmentModalOpen(false)}
            />
            <If condition={injury.status === 'ongoing' && openSoapNotes.length > 0}>
              <Then>
                <span className="warning">&nbsp;
                  <Icon name="triangle-exclamation" />&nbsp;
                  {Strings.formatString(
                    Strings.numberOfIncompleteNotes,
                    openSoapNotes.length
                  )}
                </span>
              </Then>
            </If>
          </li>

          <li>
            <Icon name="list" />&nbsp;
            <Link
              to={
                replaceLinkParams(
                  ClinicPaths.patientProfile.injuries.specialistNotes.index.link,
                  {
                    clinicId: clinic.id,
                    userId: user.id,
                    injuryId: injury.id
                  }
                )
              }
              onClick={beforeLinkToSoaps}
            >
              {Strings.specialistNotesLinkText}
            </Link>
          </li>
        </ul>
      </div>

      <div className="injury-history-section">
        <ul className="list-unstyled">
          <AccessControl
            allowIfClinicDeactivated
            roles={[
              RoleDescriptions.Clinician,
              RoleDescriptions.Specialist,
              RoleDescriptions.ClinicFrontDesk
            ]}
          >
            <li>
              <Icon name="sliders" />&nbsp;
              <Link
                to={replaceLinkParams(ClinicPaths.patientProfile.injuries.symptomUpdates.link, {
                  clinicId: clinic.id,
                  userId: user.id,
                  injuryId: injury.id
                })}
              >
                {Strings.symptomUpdatesLinkText}
              </Link>
            </li>
          </AccessControl>
          <AccessControl
            allowIfClinicDeactivated
            roles={[
              RoleDescriptions.Clinician,
              RoleDescriptions.Specialist,
              RoleDescriptions.ClinicFrontDesk
            ]}
          >
            <If condition={progress_reports.length > 0}>
              <Then>
                <li>
                  <Icon name="message-medical" />&nbsp;
                  <Link
                    to={replaceLinkParams(
                      ClinicPaths.patientProfile.injuries.progressReports.link,
                      {
                        clinicId: clinic.id,
                        userId: user.id,
                        injuryId: injury.id
                      }
                    )}
                  >
                    {Strings.progressReportsLinkText}
                  </Link>
                </li>
              </Then>
            </If>
          </AccessControl>
        </ul>
      </div>

      {/* Tests */}
      <If condition={sortedTests.length > 0}>
        <Then>
          <div className="injury-history-section injury-history-tests">
            <ul className="list-unstyled">
              {sortedTests.map(item => {
                const { test, type, index } = item;
                const path = pathnameForTest(test, type, user, injury, clinic.id);
                const isAssessment = type === 'assessment';
                const renderLink = isAssessment ? enableAssessmentLinks : enableTestLinks;
                return (
                  <li key={`${type}-${test.id}`}>
                    <Icon name="file-lines" /> {
                      renderTestLink(test, type, index, path, renderLink)
                    }&nbsp;
                    {isAssessment && (
                      <ContinueAssessmentLink
                        visible={test.completed === false && injury.status === 'ongoing'}
                        path={{
                          pathname: pathnameForTest(test, 'continueAssessment', user, injury),
                          query: {
                            section: 'review'
                          }
                        }}
                      />
                    )}
                    <LastEditedView inline item={test} />
                  </li>
                );
              })}
            </ul>
          </div>
        </Then>
      </If>
    </li>
  );
};

export default InjuryHistoryListItem;
