const FR = {
  title: 'Test d’examen vestibulo-oculaire moteur (VOMS)',
  directionsText: 'À l’aide de la veuille d’examen vestibulo-oculaire moteur CCMI VOMS, évaluez les symptômes de maux de tête, d’étourdissement, de nausée, de flou (échelle de 1\u201310) avant d’administrer cette évaluation (de référence), ainsi qu’après chaque élément d’évaluation.',
  symptomHeaderText: 'Test/Symptômes',
  notTestedHeaderText: 'Non testé',
  headacheHeaderText: 'Mal de tête',
  dizzinessHeaderText: 'Étourdissement',
  nauseaHeaderText: 'Nausée',
  fogginessHeaderText: 'Flou',
  distanceHeaderText: 'Distance',
  abnormalityHeaderText: 'Anomalie détectée',
  commentsHeaderText: 'Commentaires',
  scale: '(0\u201310)',
  cm: '(cm)',
  yes: 'Oui',
  no: 'Non',
  saveButtonText: 'Sauvegarder',
  symptoms_baseline: 'Symptômes (pré-VOMS) de référence',
  symptoms_smoothPursuits: 'Poursuites de l’oculaire',
  symptoms_saccadesHorizontal: 'Saccades\u2014 horizontaux',
  symptoms_saccadesVertical: 'Saccades\u2014 verticaux',
  symptoms_vorHorizontal: 'Réflexes vestibulo-oculaires\u2014 horizontaux',
  symptoms_vorVertical: 'Réflexes vestibulo-oculaires\u2014 verticaux',
  symptoms_visualMotorSensitivity: 'Test de sensibilité au mouvement visuel',
  symptoms_accomodationLeft: 'Accommodation\u2014 gauche',
  symptoms_accomodationRight: 'Accommodation\u2014 droite',
  symptoms_convergence: 'Convergence',
  abnormalityErrorMessage: 'Choisir Oui ou Non'
};
 
export default FR;
