import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { If, Then } from 'react-if';
import Strings from './lang';
import { AddressFormInputs } from '../address-form-inputs';
import convertAddressKeys from '@/utilities/convert-address-keys';
import sanitizeAddress from '@/utilities/sanitize-address';
import validateAddress from '@/utilities/validate-address';
import { EmailValidator } from '@/forms/validators/email-validator';
import {
  FormComponent,
  FormInputGroup,
  EmailInputGroup
} from '@/forms';

class EditPlayerPhysicianInfoCardForm extends Component {
  constructor(props) {
    super(props);

    const physicianContact = props.user.physician_contact || {};
    const address = sanitizeAddress(physicianContact.address || {});
    const name = physicianContact.name || '';
    const phone = physicianContact.phone || '';
    const fax = physicianContact.fax || '';
    const email = physicianContact.email || '';
    const emailValid = email.length === 0 || EmailValidator.test(email);
    this.ref = React.createRef();

    this.onSubmit = this.onSubmit.bind(this);
    this.onUpdateAddress = this.onUpdateAddress.bind(this);

    this.state = {
      submitted: false,
      name,
      address,
      addressValid: validateAddress(address),
      phone,
      fax,
      email,
      emailValid
    };
  }

  render() {
    return (
      <FormComponent ref={this.ref} className="card card-form" onSubmit={this.onSubmit}>
        <FormInputGroup
          className="form-group name-input-group"
          labelText={Strings.nameLabelText}
          inputType="text"
          inputProps={{
            className: 'form-control',
            value: this.state.name,
            onBlur: this.onUpdateInput.bind(this, 'name'),
            onChange: this.onUpdateInput.bind(this, 'name')
          }}
          inputValid={this.state.name.length > 0}
          messageText={Strings.nameErrorText}
          messageClassName="alert alert-danger"
          required
          touched={this.state.submitted}
        />

        <AddressFormInputs
          address={this.state.address}
          onUpdate={this.onUpdateAddress}
          required
          touched={this.state.submitted}
        />

        <FormInputGroup
          className="form-group phone-input-group"
          labelText={Strings.phoneNumberLabelText}
          type="tel"
          inputProps={{
            className: 'form-control',
            value: this.state.phone,
            onBlur: this.onUpdateInput.bind(this, 'phone'),
            onChange: this.onUpdateInput.bind(this, 'phone')
          }}
          inputValid={this.state.phone.length > 0}
          messageText={Strings.phoneNumberValidationMessage}
          messageClassName="alert alert-danger"
          required
          touched={this.state.submitted}
        />

        <FormInputGroup
          className="form-group phone-input-group"
          labelText={Strings.faxNumberLabelText}
          type="tel"
          inputProps={{
            className: 'form-control',
            value: this.state.fax,
            onBlur: this.onUpdateInput.bind(this, 'fax'),
            onChange: this.onUpdateInput.bind(this, 'fax')
          }}
          required={false}
          inputValid
          touched={this.state.submitted}
        />

        <EmailInputGroup
          className="form-group person-email-input-group"
          labelText={Strings.emailLabelText}
          inputProps={{
            value: this.state.email,
            className: 'form-control',
            placeholder: Strings.emailInputPlaceholder
          }}
          onUpdate={(email, emailValid) => {
            this.setState({ email, emailValid });
          }}
          messageClassName="alert alert-danger"
          required={false}
          touched={this.state.submitted}
        />

        <If condition={this.props.onSubmit !== undefined}>
          <Then>
            <div className="form-footer">
              <button className="btn btn-primary" type="submit">
                {Strings.submitButtonText}
              </button>
            </div>
          </Then>
        </If>
      </FormComponent>
    );
  }

  isValid() {
    return this.state.name.length
      && this.state.addressValid
      && (this.state.emailValid || this.state.email.length === 0)
      && this.state.phone.length;
  }

  onUpdateAddress(address, addressValid) {
    this.setState({
      address,
      addressValid
    });
  }

  onUpdateInput(key, e) {
    const { value } = e.target;
    this.setState({
      [key]: value
    });
  }

  getAttributes(validate = true) {
    if (validate) {
      this.setState({ submitted: true });

      if (!this.isValid()) {
        return false;
      }
    }

    const {
      name,
      address,
      phone,
      fax,
      email,
      emailValid
    } = this.state;

    const attributes = {
      physician_contact_attributes: {
        name,
        phone,
        fax,
        email: (emailValid) ? email : '',
        address_attributes: convertAddressKeys(address)
      }
    };

    const attrs = this.ensureNestedIds(attributes);

    return attrs;
  }

  ensureNestedIds(attributes) {
    const physician = this.props.user.physician_contact || {};
    const attrs = { ...attributes };

    if (physician.id) {
      attrs.physician_contact_attributes.id = physician.id;

      if ((physician.address || {}).id) {
        attrs.physician_contact_attributes.address_attributes.id = physician.address.id;
      }
    }

    return attrs;
  }

  onSubmit() {
    if (typeof this.props.onSubmit === 'function') {
      this.setState({ submitted: true });

      if (!this.isValid()) {
        return;
      }

      this.props.onSubmit(this.getAttributes(false));
    }
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  scrollIntoView(alignTopOrOptions = true) {
    if (this.ref.current) {
      this.ref.current.scrollIntoView(alignTopOrOptions);
    }
  }
}

EditPlayerPhysicianInfoCardForm.propTypes = {
  user: PropTypes.object.isRequired,
  onSubmit: PropTypes.func
};

export default EditPlayerPhysicianInfoCardForm;
