const EN = {
  diagnosis_provided: 'Provided patient with diagnosis',
  finding_provided: 'Provided patient with pertinent findings',
  education_provided: 'Educated the patient on a plan of management and the course and expected recovery of concussions',
  app_link_provided: 'Provided the patient with a link to download the CCMI Concussion Tracker smartphone application to view concussion information, the diet plan, and upload their symptoms on a daily basis',
  education_short: 'Provided patient with concussion education',
  app_short: 'Provided patient with smartphone application information',
  saveButtonText: 'Save',
  yes: 'Yes',
  no: 'No'
};

export default EN;
