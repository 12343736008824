import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import Popover from '../../popover';
import { getInvoiceStatusClassName, LineItemTypes } from '../utilities';
import Strings from '../lang';
import replaceLinkParams from '@/utilities/replace-link-params';
import ClinicPaths from '@/paths/clinic';

const MapleConsultDecription = ({
  lineItem = {}
}) => {
  return (
    <>
      {Strings.mapleConsultLabel}&nbsp;
      <span className="label label-light capitalize">
        {lineItem.category}
      </span>
    </>
  );
};

const CreditDescription = () => {
  return (
    <span>
      {Strings.creditLabel}
    </span>
  );
};

const CustomChargeDescription = ({
  lineItem = {}
}) => {
  return (
    <td>
      {lineItem.description}
    </td>
  );
};

const BulkImpactDescription = ({
  lineItem = {}
}) => {
  const [clickOpen, setClickOpen] = useState(false);
  const [hoverOpen, setHoverOpen] = useState(false);
  const { quantity, recipients } = lineItem;

  const handleHoverOpen = useCallback(() => setHoverOpen(true), []);
  const handleHoverClose = useCallback(() => setHoverOpen(false), []);
  
  return (
    <>
      <span>{Strings.bulkImpactBaselinesLabel}</span>&nbsp;
      <span className="label label-danger">
        {Strings.formatString(Strings.codeUsesLabel, quantity)}
      </span>&nbsp;&nbsp;
      <span
        tabIndex={0}
        onMouseOver={handleHoverOpen}
        onMouseLeave={handleHoverClose}
        onFocus={handleHoverOpen}
        onBlur={handleHoverClose}
      >
        <Popover
          isOpen={clickOpen || hoverOpen}
          placement="top"
          // eslint-disable-next-line no-magic-numbers
          offset={[0, 4]}
          onClose={() => {
            setClickOpen(false);
            setHoverOpen(false);
          }}
          content={(
            <div className="bulk-impact-baselines-popover">
              {recipients.map((recipient, index) => (
                <div key={index} className="recipient-item">
                  <strong>{recipient.name}</strong> ({recipient.email})
                </div>
              ))}
            </div>
          )}
        >
          <span 
            className="label label-light selectable"
            onClick={() => setClickOpen(prev => !prev)}
          >
            {Strings.formatString(Strings.totalRecipientsLabel, recipients.length)}
          </span>
        </Popover>
      </span>
    </>
  );
};

const ImpactDescription = ({
  lineItem = {}
}) => {
  return (
    <>
      <span>{Strings.impactCodeLabel}</span>&nbsp;&nbsp;
      {lineItem.post_injury && (
        <span className="label label-light">
          {Strings.postInjuryImpactLabel}
        </span>
      )}
    </>
  );
};

const OverduePaymentFeeDescription = ({
  lineItem = {},
  clinicId
}) => {
  return (
    <>
      <span>{Strings.latePaymentFeeLabel}</span>&nbsp;&nbsp;
      {lineItem.invoice_id && (
        <span className="label label-light">
          <Link
            to={replaceLinkParams(ClinicPaths.invoices.details.link, {
              clinicId,
              invoiceId: lineItem.invoice_id
            })}
          >
            {Strings.invoiceNumberLabel}{lineItem.invoice_title}&nbsp;
            {lineItem.invoice_status && (
              <>
                (
                <span 
                  className={classnames(
                    getInvoiceStatusClassName(lineItem.invoice_status),
                    'uppercase'
                  )}
                >
                  {lineItem.invoice_status}
                </span>
                )
              </>
            )}
          </Link>
        </span>
      )}
    </>
  );
};

const getLineItemDescription = (type, lineItem, clinicId) => {
  switch (type) {
    case LineItemTypes.MapleConsult:
      return <MapleConsultDecription lineItem={lineItem} />;
    case LineItemTypes.Credit:
      return <CreditDescription />;
    case LineItemTypes.BulkImpact:
      return <BulkImpactDescription lineItem={lineItem} />;
    case LineItemTypes.Impact:
      return <ImpactDescription lineItem={lineItem} />;
    case LineItemTypes.OverduePaymentFee:
      return <OverduePaymentFeeDescription clinicId={clinicId} lineItem={lineItem} />;
    case LineItemTypes.Custom:
      return <CustomChargeDescription lineItem={lineItem} />;
    default:
      return <span>{type}</span>;
  }
};

export default getLineItemDescription;
