import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import BookingLinkForm from './booking-link-form';
import Modal from '../modal';
import Activity from '../activity';
import { ErrorBanner } from '../errors';
import { updateUserAsync } from '@/redux/thunks/users';
import { useIsMounted } from '@/hooks';
import Strings from './lang';

const getAttributes = (id, url, clinicId) => {
  const linkAttributes = {
    url, 
    clinic_id: clinicId
  };

  if (id) {
    linkAttributes.id = id;
  }

  return {
    booking_links_attributes: [linkAttributes]
  };
};

const BookingLinkModal = ({
  id = null,
  url = '',
  userId,
  clinics = [],
  clinic = {},
  isOpen = false,
  updateUser,
  onClose = () => {}
}) => {
  const [error, setError] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const mounted = useIsMounted();
  const handleClose = submitting ? undefined : onClose;
  const newLink = !id;
  
  const handleSubmit = useCallback(({ url, clinicId }) => {
    setSubmitting(true);
    setError(null);

    const attributes = getAttributes(id, url, clinicId);

    updateUser(userId, attributes).then(() => {
      if (mounted.current) {
        setSubmitting(false);
        onClose();
      }
    }).catch(error => {
      setSubmitting(false);
      setError(error.message);
    });
  }, [id, mounted, onClose, updateUser, userId]);

  return (
    <Modal
      blur
      className="booking-link-modal"
      isOpen={isOpen}
      onClose={handleClose}
    >
      <Modal.Header>
        <h1>{newLink ? Strings.addBookingLinkModalTitle : Strings.editBookingLinkModalTitle}</h1>
      </Modal.Header>
      <Modal.Body>
        {newLink && (
          <p>
            {Strings.bookingLinkModalDescription}
          </p>
        )}
        <ErrorBanner error={error} />
        <Activity active={submitting}>
          <BookingLinkForm 
            url={url}
            clinics={clinics}
            clinic={clinic}
            cancelable={!submitting}
            onCancel={handleClose}
            onSubmit={handleSubmit}
          />
        </Activity>
      </Modal.Body>
    </Modal>
  ); 
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUser: (userId, attributes) => {
      return dispatch(updateUserAsync(userId, attributes));
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(BookingLinkModal);
