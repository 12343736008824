import React from 'react';
import PropTypes from 'prop-types';
import { If, Then } from 'react-if';
import dayjs from 'dayjs';
import { ErrorBanner } from '../errors';
import { TestHeader } from '../test-header';
import VOMSTestTable from './voms-test-table';
import { MedicalTestEditButton } from '../medical-test-edit';
import LastEditedView from '../last-edited-view';
import Strings from './lang';

const VOMSTestView = ({
  showTestHeader = true,
  user = {},
  injury = {},
  test = {},
  error,
  onEditClick
}) => {
  const headerClassName = showTestHeader ? 'display flex-row justify_space-between align_center' : 'bold';
  const HeaderElement = showTestHeader ? 'h1' : 'h2';

  return (
    <div className="voms-test-view">

      <div className="row">
        <div className="col-md-12">
          <HeaderElement className={headerClassName}>
            {Strings.title}
            {onEditClick && (
              <MedicalTestEditButton 
                className="btn btn-danger btn-sm no-print"
                onClick={onEditClick}
              />
            )}
          </HeaderElement>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <ErrorBanner error={error} />
          <div className="voms-test-content">

            <If condition={showTestHeader}>
              <Then>
                <div className="row">
                  <div className="col-md-12">
                    <TestHeader
                      user={user}
                      injury={injury}
                      className="voms-test-header"
                      testDate={test.created_at || dayjs()}
                      tester={test.clinic_user}
                      clinic={test.clinic}
                    />
                  </div>
                </div>
              </Then>
            </If>

            <LastEditedView item={test} />

            <div className="row">
              <div className="col-md-12">
                <div className="table-responsive-md">
                  <VOMSTestTable user={user} test={test} />
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

    </div>
  );
};

VOMSTestView.propTypes = {
  showTestHeader: PropTypes.bool,
  user: PropTypes.object,
  injury: PropTypes.object,
  test: PropTypes.object,
  error: PropTypes.any
};

export default VOMSTestView;
