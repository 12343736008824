import React, { Component } from 'react';
import PropTypes from 'prop-types';
import OrientationQuestion from './orientation-question';

class OrientationQuestionGroup extends Component {
  render() {
    return (
      <div className="baseline-card">
        { this.renderQuestions() }
      </div>
    );
  }

  renderQuestions() {
    const { 
      questions, answers, touched, onUpdate = () => {} 
    } = this.props;
    const labels = Object.values(answers);
    const values = Object.keys(answers);

    return Object.keys(questions).map(key => (
      <OrientationQuestion 
        key={key}
        questionText={questions[key].text}
        questionKey={key}
        answerProps={{
          labels,
          values,
        }}
        value={questions[key].value}
        onUpdate={onUpdate}
        touched={touched}
      />
    ));
  }
}

OrientationQuestionGroup.propTypes = {
  questions: PropTypes.objectOf(PropTypes.shape({
    text: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  })).isRequired,
  answers: PropTypes.objectOf(PropTypes.string).isRequired,
  onUpdate: PropTypes.func,
  touched: PropTypes.bool
};

export default OrientationQuestionGroup;
