import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { loadGoogleMapsApi } from '@/lib/google/maps-loader';

const getAutocompleteObject = (input, options) => {
  try {
    // eslint-disable-next-line no-undef
    return new google.maps.places.Autocomplete(input, options);
  } catch (e) {
    return null;
  }
};

class GoogleAddressSearchInput extends Component {
  constructor(props) {
    super(props);

    this.autocomplete = null;
    this.inputRef = React.createRef();
    this.googleLoaded = false;

    let value = '';
    if (typeof this.props.initialValue === 'string') {
      value = this.props.initialValue;
    }

    this.state = {
      value
    };
  }

  componentDidMount() {
    loadGoogleMapsApi(process.env.CCMI_GOOGLE_MAPS_API_KEY, ['places']).then(() => {
      this.googleLoaded = true;
      if (this.inputRef.current) {
        this.configureAutocomplete(this.inputRef.current, this.props.limitCountry);
      }
    });
  }

  render() {
    return (
      <input 
        type="text"
        className={classNames('google-address-search-input', this.props.className)}
        value={this.state.value}
        onChange={e => {
          this.setState({ value: e.target.value });
        }}
        ref={input => {
          if (input) {
            this.inputRef.current = input;
            this.configureAutocomplete(input, this.props.limitCountry);
          }
        }}
      />
    );
  }

  configureAutocomplete(input, limitCountry) {
    let options = { types: ['address'] };

    if (limitCountry) {
      options = {
        types: ['address'],
        componentRestrictions: limitCountry
      };
    }

    // eslint-disable-next-line no-undef
    if (this.googleLoaded && 'google' in window && google.maps && !this.autocomplete) {
      const autoComplete = getAutocompleteObject(input, options);

      if (autoComplete) {
        this.autocomplete = autoComplete;

        this.autocomplete.addListener('place_changed', () => {
          if (typeof this.props.onUpdate === 'function') {
            const place = this.autocomplete.getPlace();
            const value = place.formatted_address || place.name;
            this.setState({ value });
            this.props.onUpdate(place);
          }
        });
      }
    }
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  focus() {
    this.inputRef.current?.focus();
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  blur() {
    this.inputRef.current?.blur();
  }
}

GoogleAddressSearchInput.propTypes = {
  className: PropTypes.string,
  initialValue: PropTypes.string,
  onUpdate: PropTypes.func
};

export default GoogleAddressSearchInput;
