import React from 'react';
import classnames from 'classnames';
import { usePanel } from './panel-context';

const PanelContent = ({ children }) => {
  const panel = usePanel();

  return (
    <div 
      className={classnames('panel-body', {
        collapse: panel.collapsible,
        in: panel.collapsible && !panel.collapsed
      })}
    >
      {children}
    </div>
  );
};

export default PanelContent;
