import { useCallback, useState } from 'react';

export const useObjectState = (initial = {}) => {
  const [state, setState] = useState(initial);

  const setObjectState = useCallback((newState) => {
    setState(prev => ({
      ...prev,
      ...(
        typeof newState === 'function'
          ? newState(prev)
          : newState
      )
    }));
  }, []);

  const resetObjectState = useCallback((value = initial) => {
    setState(value);
  }, [initial]);

  return [state, setObjectState, resetObjectState];
};
