import Strings from './lang';

export const getWorkerInitial = (firstName = '') => {
  const initialChar = firstName?.charAt(0)?.toUpperCase();
  return initialChar ? `${initialChar}.` : '';
};

export const ReportTypeStrings = {
  initial: Strings.initialReportTitle,
  mid_point: Strings.midPointReportTitle,
  care_outcomes: Strings.careOutcomesReportTitle,
  supplemental: Strings.supplementalReportTitle
};

export const ClaimStatusStrings = {
  not_started: Strings.claimStatusNotStarted,
  active: Strings.claimStatusActive,
  discharged: Strings.claimStatusDischarged
};

export const BlockTreatmentTypeStrings = {
  idt_poc: Strings.idtPocBlockLabel,
  idt_poc_wh: Strings.idtPocWhBlockLabel
};

export const TreatmentTypeStrings = {
  initial_assessment: Strings.treatmentTypeInitialAssessment,
  functional_testing: Strings.treatmentTypeFunctionalTesting,
  physical_therapy: Strings.treatmentTypePhysicalTherapy,
  occupational_therapy: Strings.treatmentTypeOccupationalTherapy,
  work_hardening: Strings.treatmentTypeWorkHardening
};

export const AppointmentStatusStrings = {
  booked: Strings.apptStatusBooked,
  arrived: Strings.apptStatusArrived,
  no_showed: Strings.apptStatusNoShowed,
  cancelled: Strings.apptStatusCancelled,
  rescheduled: Strings.apptStatusRescheduled
};

export const ReportStatusStrings = {
  not_started: Strings.reportStatusNotStarted,
  in_progress: Strings.reportStatusInProgress,
  completed: Strings.reportStatusCompleted,
  pending_approval: Strings.reportStatusPendingApproval,
  approved: Strings.reportStatusApproved,
  submitted: Strings.reportStatusSubmitted
};
