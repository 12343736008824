import dayjs from 'dayjs';

/*!
 * class       : ElapsedTimer
 * description : A basic elapsed timer. On each tick,
 *               the elapsed time (as a millisecond integer) from the
 *               moment the start method was called will be returned.
 * */
class ElapsedTimer {
  constructor(interval, onTick) {
    this.interval = interval;
    this.intervalHandle = 0;
    this.callback = typeof onTick === 'function' ? onTick : (() => {});
    this.timeValue = null;
    this.duration = 0;
  }

  start() {
    this.timeValue = dayjs().valueOf();
    this.duration = 0;
    this._setInterval();
  }

  reset() {
    this.timeValue = null;
    this.duration = 0;
  }

  resume() {
    if (!this.timeValue) {
      this.start();
      return;
    }

    this.timeValue = dayjs().valueOf();
    this._setInterval();
  }

  _setInterval() {
    this.intervalHandle = setInterval(() => {
      this.tick();
    }, this.interval);
  }

  isRunning() {
    return this.intervalHandle > 0;
  }

  stop() {
    clearInterval(this.intervalHandle);
    this.intervalHandle = 0;
  }

  tick() {
    const now = dayjs().valueOf();
    this.duration += dayjs.duration(now - this.timeValue).asMilliseconds();
    this.timeValue = now;
    this.callback(this.duration);
  }
}

export default ElapsedTimer;
