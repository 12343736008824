const FR = {
  initialManagementInfoText: 'À la fin de cet examen, quelles sont les choses que vous aimeriez inclure dans ce plan de gestion ?',
  workSchoolLabel: 'Travail/école',
  workSchoolFullOptionLabel: 'Complet',
  workSchoolModifiedOptionLabel: 'Modifié',
  explanationLabel: 'Explication',
  exerciseLabel: 'Exercice',
  exerciseRestritionsLabel: 'Restrictions',
  exerciseNoRestrictionsLabel: 'Aucune restriction',
  reEvaluationDateLabel: 'Date de la réévaluation',
  invalidDateError: 'Veuillez écrire une date valide',
  rehabLabel: 'Réadaptation',
  vestibularLabel: 'Équilibre/vestibulaire',
  cervicalSpineLabel: 'Colonne cervicale',
  visualTrackingLabel: 'Suivi visuel',
  sportSpecificLabel: 'Remarques pour un sport en particulier',
  neurocognitiveLabel: 'Neurocognitif',
  therapyLabel: 'Thérapie',
  softTissueLabel: 'Tissu mou',
  spinalManipulativeLabel: 'Manipulation vertébrale',
  otherLabel: 'Autre',
  otherTherapyLabel: 'Autre forme de thérapie'
};
 
export default FR;
