import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ClinicPaths } from '@/paths';
import replaceLinkParams from '@/utilities/replace-link-params';
import { RoleDescriptions } from '@/utilities/user-roles';
import AccessControl from '../access-control';
import {
  makeFacebookUrl,
  makeTwitterUrl,
  makeInstagramUrl,
} from '@/utilities/social';
import Strings from './lang';

const ContactInfoCard = ({
  clinic = {}
}) => {
  const clinicOwner = clinic.clinic_owner || {};
  const { person } = clinicOwner;

  return (
    <div className="card card-show">
      {/* Clinic Owner should only show for admins and only if set. */}
      {clinicOwner.id && (
        <AccessControl 
          roles={[
            RoleDescriptions.SuperAdmin,
            RoleDescriptions.SalesAdmin
          ]}
        >
          <div className="card-row">
            <label>{ Strings.ownerLabelText }:</label>
            <Link 
              to={replaceLinkParams(ClinicPaths.userProfile.link, {
                clinicId: clinic.id,
                userId: clinicOwner.id
              })}
            >
              {/* There is an edge case where the Clinic Owner may not 
                  have a person record, so fall back to email. */}
              <span>
                {person 
                  ? `${person.first_name} ${person.last_name}` 
                  : clinicOwner.email}
              </span>
            </Link>
          </div>
        </AccessControl>
      )}

      <div className="card-row">
        <label>{Strings.phoneLabelText}:</label>
        <span>{clinic.phone}</span>
      </div>

      <div className="card-row">
        <label>{Strings.faxLabelText}:</label>
        <span>{clinic.fax}</span>
      </div>

      <div className="card-row">
        <label>{Strings.clinicUrlLabelText}:</label>
        <span>{clinic.website}</span>
      </div>

      <div className="card-row">
        <label>{Strings.emailLabelText}:</label>
        <span>{clinic.email}</span>
      </div>

      <div className="card-row">
        <label>{Strings.facebookLabelText}:</label>
        <a 
          href={makeFacebookUrl(clinic.facebook_id)} 
          target="_blank"
          rel="noopener noreferrer"
        >
          {clinic.facebook_id}
        </a>
      </div>

      <div className="card-row">
        <label>{ Strings.twitterLabelText }:</label>
        <a 
          href={makeTwitterUrl(clinic.twitter_id)} 
          target="_blank"
          rel="noopener noreferrer"
        >
          {clinic.twitter_id}
        </a>
      </div>

      <div className="card-row">
        <label>{ Strings.instagramLabelText }:</label>
        <a 
          href={makeInstagramUrl(clinic.instagram_id)} 
          target="_blank" 
          rel="noopener noreferrer"
        >
          {clinic.instagram_id}
        </a>
      </div>

    </div>
  );
};

ContactInfoCard.propTypes = {
  clinic: PropTypes.object.isRequired
};

export default ContactInfoCard;
