import { useRef } from 'react';

export const useLazyRef = (fn = () => { }) => {
  const ref = useRef(null);

  if (ref.current === null) {
    ref.current = fn();
  }

  return ref;
};
