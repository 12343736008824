import React from 'react';
import { Else, If, Then } from 'react-if';
import PropTypes from 'prop-types';
import BaselineSection from './baseline-section';
import Fraction from '../../fraction';
import Strings from './lang';

const MAX_SCORE = 10;
const REQUIRED_BESS_KEYS = ['feet_together_errors', 'single_leg_errors', 'tandom_errors'];
const WESS_KEYS = ['seated_errors', 'balance_disk_errors', 'wheelie_errors'];
const WESS_MAX_POSITION_SCORE = MAX_SCORE * 2;
const WESS_MAX_SCORE = WESS_MAX_POSITION_SCORE * WESS_KEYS.length;

const calculateBESSTotals = (data) => {
  return REQUIRED_BESS_KEYS.reduce((totals, key) => {
    const { errors = 0, score = 0 } = totals;
    const value = parseInt(data[key], 10);
    if (!isNaN(value)) {
      return {
        errors: errors + parseFloat(value),
        score: score + (MAX_SCORE - parseFloat(value))
      };
    } 
    return totals;
  }, {});
};

const getNumber = (num) => {
  const value = parseFloat(num);
  return isNaN(value) ? 0 : value;
};

const BESSTable = ({
  balance
}) => {
  const totals = calculateBESSTotals(balance);

  return (
    <div>
      <p>
        <strong>{Strings.dominantFootLabelText}</strong> <span className="capitalize">{balance.dominant_foot}</span>
      </p>
      <table className="table">
        <thead>
          <tr>
            <th className="no-action">{Strings.testLabelText}</th>
            <th className="no-action">{Strings.errorsLabelText}</th>
            <th className="no-action">{Strings.scoreLabelText}</th>
          </tr>
        </thead>
        <tbody>
          {REQUIRED_BESS_KEYS.map((key, index) => {
            const errors = getNumber(balance[key] || 0);
            const score = MAX_SCORE - errors;
            return (
              <tr key={index}>
                <td><strong>{Strings[key]}</strong></td>
                <td>{errors}</td>
                <td><Fraction numerator={score} denominator={MAX_SCORE} /></td>
              </tr>
            );
          })}
          {/* Totals */}
          <tr className="totals">
            <td><strong>{Strings.totalLabelText}</strong></td>
            <td><strong>{totals.errors}</strong></td>
            <td>
              <strong>
                <Fraction
                  numerator={totals.score}
                  denominator={MAX_SCORE * REQUIRED_BESS_KEYS.length}
                />
              </strong>
            </td>
          </tr>
          {/* Optional Foam Pad */}
          <tr>
            <td><strong>{Strings.foam_pad_errors}</strong></td>
            <td>{balance.foam_pad_errors}</td>
            <td>
              {balance.foam_pad_errors === ''
                ? Strings.notDoneText
                : (
                  <Fraction
                    numerator={MAX_SCORE - getNumber(balance.foam_pad_errors)}
                    denominator={MAX_SCORE}
                  />
                )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const getWessPositionScore = (values) => {
  return WESS_MAX_POSITION_SCORE - ((parseInt(values?.eyes_open, 10) || 0)
    + (parseInt(values?.eyes_closed, 10) || 0));
};

const getWessTotalScore = (balance) => {
  return WESS_KEYS.reduce((acc, key) => {
    return acc + getWessPositionScore(balance[key]);
  }, 0);
};

const getWessInputErrors = (balance, input) => {
  return WESS_KEYS.reduce((acc, key) => {
    return acc + (parseInt(balance[key]?.[input], 10) || 0);
  }, 0);
};

const WESSTable = ({
  balance = {}
}) => {
  return (
    <table className="table">
      <thead>
        <tr>
          <th className="no-action">{Strings.testLabelText}</th>
          <th className="no-action">{Strings.wessEyesOpenErrors}</th>
          <th className="no-action">{Strings.wessEyesClosedErrors}</th>
          <th className="no-action">{Strings.scoreLabelText}</th>
        </tr>
      </thead>
      <tbody>
        {WESS_KEYS.map(key => (
          <tr key={key}>
            <td>{Strings[`wess_${key}`]}</td>
            <td>{balance[key]?.eyes_open}</td>
            <td>{balance[key]?.eyes_closed}</td>
            <td>
              <Fraction
                numerator={getWessPositionScore(balance[key])}
                denominator={WESS_MAX_POSITION_SCORE}
              />
            </td>
          </tr> 
        ))}
        <tr className="totals">
          <td>
            <strong>{Strings.totalLabelText}</strong>
          </td>
          <td><strong>{getWessInputErrors(balance, 'eyes_open')}</strong></td>
          <td><strong>{getWessInputErrors(balance, 'eyes_closed')}</strong></td>
          <td>
            <strong>
              <Fraction
                numerator={getWessTotalScore(balance)}
                denominator={WESS_MAX_SCORE}
              />
            </strong>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

const BaselineBESS = ({
  data = {}
}) => {
  return (
    <BaselineSection
      className="baseline-bess"
      title={data?.is_wess ? Strings.wessTitle : Strings.bessTitle}
      notes={data.bess_notes || ''}
      sectionCompleted={Object.keys(data || {}).length > 0 && !(data || {}).not_done}
    >
      <If condition={data?.is_wess ?? false}>
        <Then>
          <WESSTable balance={data} />
        </Then>
        <Else>
          <BESSTable balance={data} />
        </Else>
      </If>
    </BaselineSection>
  );
};

BaselineBESS.propTypes = {
  data: PropTypes.object.isRequired
};

export default BaselineBESS;
