const EN = {
  title: 'Personal Information',
  guardianSubTitle: 'Update {0}\'s personal information',
  returningSubTitle: 'Update your personal information',
  firstNameLabelText: 'First Name',
  firstNameErrorText: 'Please enter your first name.',
  lastNameLabelText: 'Last Name',
  lastNameErrorText: 'Please enter your last name.',
  phoneLabelText: 'Phone',
  phoneErrorText: 'Please enter a valid phone number.',
  alternatePhoneLabelText: 'Alternate Phone',
  genderLabelText: 'Gender',
  genderValidationMessage: 'Please select your gender.',
  genderOtherLabelText: 'What is your gender?',
  genderOtherValidationMessage: 'Please enter your gender',
  languageLabelText: 'Language',
  languageValidationMessage: 'Please select your language preference.',
  birthdateLabelText: 'Date of Birth',
  birthdateConfirmationMessage: '{2}. By continuing you confirm that this patient is {0} {1} old.',
  year: 'year',
  years: 'years',
  under1: 'under 1',
  birthdateValidationMessage: 'Please enter a valid date of birth.',
  isGuardianConfirmationText: 'Are you the Parent/Guardian of this player?',
  addressLabelText: 'Address',
  bioLabelText: 'Bio',
  credentialsLabelText: 'Credentials (Press Enter to Add a Credential)',
  addCredentialInputPlaceholder: '(e.g. MD) Press enter to add a credential',
  submitButtonText: 'Save'
};

export default EN;
