const FR = {
  signInTitle: 'Connexion',
  createAccountTitle: 'Créer un compte',
  createAccountButtonTitle: 'Créer un nouveau compte',
  newPatientsInfoText: 'Quel type de compte voulez-vous?',
  personalAccountTitle: 'Compte Personnel',
  personalAccountDescription: 'Je crée ce compte pour moi-même.',
  getStartedLinkText: 'Commencer',
  guardianAccountTitle: 'Compte familial',
  guardianAccountDescription: 'Je crée cela au nom de mon enfant ou de plusieurs membres de ma famille et je souhaite que tous les comptes soient associés à une seule adresse e-mail.'
};
 
export default FR;
