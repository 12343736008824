const RedirectPaths = {
  deactivated: {
    route: 'deactivated',
    link: '/deactivated'
  },
  unconfirmed: {
    route: 'unconfirmed',
    link: '/unconfirmed'
  },
  untrained: {
    route: 'untrained',
    link: '/untrained'
  },
  unauthorized: {
    route: 'unauthorized',
    link: '/unauthorized'
  },
  mobileApp: {
    route: 'app',
    link: '/app'
  },
  unassociated: {
    route: 'unassociated',
    link: '/unassociated'
  }
};

export default RedirectPaths;
