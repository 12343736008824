const UPDATE_CLINIC_USERS = 'UPDATE_CLINIC_USERS';
const ADD_CLINIC_USER = 'ADD_CLINIC_USER';

const addClinicUser = (clinicId, user) => ({
  type: ADD_CLINIC_USER,
  clinicId,
  user
});

const updateClinicUsers = (clinicId, users) => ({
  type: UPDATE_CLINIC_USERS,
  clinicId,
  users
});

export {
  UPDATE_CLINIC_USERS,
  updateClinicUsers,
  ADD_CLINIC_USER,
  addClinicUser
};
