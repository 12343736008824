import React, { createContext, useContext } from 'react';

const PopperContext = createContext({
  update: () => { }
});

export const usePopperContext = () => useContext(PopperContext);

export const PopperProvider = ({ children, ...props }) => {
  return (
    <PopperContext.Provider value={props}>
      {children}
    </PopperContext.Provider>
  );
};
