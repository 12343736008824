const FR = {
  creditsPluralizedText: 'Crédits',
  dateLabel: 'Date',
  typeLabel: 'Type',
  purchaserLabel: 'Acheteur',
  patientLabel: 'Patient',
  usedAtLabel: 'Date utilisée',
  priceLabel: 'Prix',
  invoiceDateLabel: 'Date de Envoi',
  invoiceNumberLabel: 'Envoi #',
  titleLabel: 'Titre',
  balanceLabel: 'Balance',
  totalLabel: 'Total',
  amountPaidLabel: 'Payé',
  datePaidLabel: 'Date de paiement',
  dueDateLabel: 'Date d\'échéance',
  subtotalLabel: 'Sous-total',
  invoicesTitle: 'Factures',
  creditsAvailableLabel: 'Crédits disponibles',
  outstandingInvoicesTabTitle: 'Solde impayé',
  paidInvoicesTabTitle: 'Envoi payé',
  bulkCreditsTabTitle: 'Crédits en vrac',
  invoiceLoadingTitle: 'Chargement de l\'envoi {0}',
  invoiceTitle: 'Envoi',
  invoiceStatusLabel: 'Statut',
  invoiceLoadErrorMessage: 'Impossible de charger envoi: {0}',
  clinicLoadErrorMessage: 'Impossible de charger la clinique: {0}',
  unknownErrorMessage: 'Erreur inconnue',
  emptyOutstandingInvoicesMessage: 'Aucune envoi impayée',
  invoicesLoadErrorMessage: 'Impossible de charger les envoi: {0}',
  emptyPaidInvoicesMessage: 'Aucune envoi payé',
  recievePaymentTitle: 'Paiement reçu',
  paymentCreatedSuccessMessage: 'Paiement créé avec succès!',
  bulkImpactBaselinesLabel: 'Masse ImPACT Baselines',
  codeUsesLabel: '{0} Utilisations du code',
  totalRecipientsLabel: '{0} Destinataires',
  paymentsLabel: 'Paiements',
  groupedImpactCodesLabel: 'Codes ImPACT',
  groupedCreditsLabel: 'Crédits',
  groupedMapleConsultsLabel: 'Maple consulte',
  creditLabel: 'Crédit',
  impactCodeLabel: 'Code ImPACT',
  mapleConsultLabel: 'Maple Consult',
  emptyLineItemsMessage: 'Aucun élément de campagne',
  externalPatientLabel: 'Patient externe',
  deleteLineItemLabel: 'Supprimer l\'élément de campagne',
  deleteLineItemConfirmTitle: 'Supprimer l\'élément de campagne?',
  deleteLineItemConfirmMessage: 'Voulez-vous vraiment supprimer cet élément de campagne?',
  deleteLineItemConfirmButton: 'Oui, supprimer',
  deleteLineItemSuccessMessage: 'L\'élément de campagne a bien été supprimé!',
  deleteLineItemErrorMessage: 'Impossible de supprimer l\'élément de campagne: {0}',
  invoiceToHeader: 'Facturer à',
  balanceDueLabel: 'Solde dû',
  invoiceFooterInterestMessage: 'Merci pour votre travail. Veuillez noter que les soldes impayés seront facturés à la fin de chaque mois civil. Le paiement non reçu dans les 30 jours suivant la date de facturation, sera soumis à des frais d\'intérêt de 15% qui seront ajoutés aux futures factures.',
  invoiceFooterContactMessage: 'Si vous avez des questions ou des préoccupations concernant cette facture, veuillez contacter Pamela Gonzalez-Silva à pamela@completeconcussions.com',
  activityHeaderLabel: 'Activité',
  quantityHeaderLabel: 'Qty',
  rateHeaderLabel: 'Taux',
  amountHeaderLabel: 'Montant',
  downloadPdfButtonText: 'Télécharger le PDF',
  pdfDownloadingButtonText: 'Téléchargement...',
  pushCreditsTabLabel: 'Crédits push',
  addLineItemTitle: 'Ajouter un élément de campagne',
  lineItemTypeLabel: 'Type d\'élément de campagne',
  selectLineItemTypeOption: 'Sélectionnez le type d\'élément de campagne',
  lineItemTypeErrorMessage: 'Veuillez sélectionner un type d\'élément de campagne',
  amountErrorMessage: 'Entrez une valeur supérieure à 0',
  patientAccountLabel: 'Numéro de compte patient',
  postInjuryImpactFieldLabel: 'ImPACT après blessure?',
  postInjuryImpactLabel: 'Après la blessure',
  selectCategoryOptionLabel: 'Choisir une catégorie',
  categoryLabel: 'Catégorie',
  categoryErrorMessage: 'Veuillez sélectionner une catégorie',
  submitButtonText: 'Soumettre',
  latePaymentFeeLabel: 'Frais de retard',
  latePaymentFeeBulkLabel: 'Frais de retard',
  editLineItemLabel: 'Modifier',
  editLineItemTitle: 'Modifier l\'élément de campagne',
  taxesAppliedLabel: 'Taxes appliquées',
  noTaxesAppliedLabel: 'Pas de taxes',
  customTaxLabel: 'Douane',
  taxCodeLabel: 'Code fiscal',
  taxRateLabel: 'Taux d\'imposition',
  descriptionLabel: 'Description',
  descriptionErrorMessage: 'Veuillez fournir une description de l\'élément de campagne',
  customChargeLabel: 'Charge personnalisée',
  noteLabel: 'Remarque',
  noteElementTitle: 'Commentaire - Cliquez pour voir',
  closeDateLabel: 'Date de fermeture',
  editCloseDateTitle: 'Modifier la date de clôture',
  editDueDateTitle: 'Modifier la date d\'échéance',
  minDateErrorMessage: 'Veuillez saisir une date supérieure à {0}',
  addBulkCreditsActionLabel: 'Ajouter des crédits groupés',
  closeInvoiceActionLabel: 'Fermer la facture',
  actionsButtonLabel: 'Actions',
  closeInvoiceMessage: 'Si cette facture a un solde nul (0), elle sera marquée comme PAID une fois fermée',
  statusOpenLabel: 'Ouvert',
  statusClosedLabel: 'Fermé',
  createButtonLabel: 'Créer',
  createInvoiceTitle: 'Créer une facture',
  invoiceMemoTitle: 'Note de facturation',
  emptyMemoText: 'Pas de note de facturation',
  groupedPostInjuryImpactLabel: 'Codes ImPACT post-blessure',
  taxRegistrationLabel: 'Numéro d\'enregistrement de la {0}: {1}',
  invoiceSummaryTitle: 'Résumé de la facture #{0}',
  retryLoadInvoiceLabel: 'Recommencez',
  invoicePaidLabel: 'La facture a déjà été payée'
};

export default FR;
