const EN = {
  acuteConcussionAssessmentTitle: 'Acute Concussion Assessment (0 - 14 days)',
  chronicConcussionAssessmentTitle: 'Chronic Concussion Assessment (14 days or more)',
  medicalHistoryHeader: 'Medical History',
  postInjuryTestHeader: 'Post Injury Test',
  saveInjuryAssessmentButton: 'Save Injury Assessment',
  userInjuryError: 'Unable to retrieve user injury information',
  submittionError: 'Some sections contain errors. Please review the sections for any errors',
  assessmentTitle: 'Post Injury Assessment',
  characteristicsTabLabel: 'Injury Characteristics',
  currentSymptomsTabLabel: 'Current Symptoms',
  redFlagsTabLabel: 'Red Flags',
  medicalHistoryTabLabel: 'Medical History',
  physicalExamsTabLabel: 'Physical Exam',
  testsTabLabel: 'Special Tests',
  diagnosisAndPlanTabLabel: 'Diagnosis + Management Plan',
  reviewAndCloseTabLabel: 'Review & Close',
  closeAssessmentText: 'Close Assessment',
  confirmRouteLeaveTitle: 'Leave Assessment?',
  confirmRouteLeaveText: 'This injury assessment has not been marked as completed and will remain incomplete. To mark the injury assessment as complete, click the \'Close Assessment\' button at the bottom of the \'Review & Close\' tab.',
  wishToContinueText: 'Do you wish to continue?',
  confirmButtonTitle: 'Yes, Continue',
  cancelButtonTitle: 'No, Stay',
  sectionSavedMessage: 'Section Successfully Saved',
  standardSectionFormError: 'This section contains some errors. Please review this section',
  closeAssessmentErrorMessage: 'Some sections contain errors. Please review all sections containing a red highlighted tab',
  closedAssessmentEditSectionInfoMessage: 'This section cannot be edited once the assessment has been completed. To edit the diagnosis and/or return to sport/school/work stage, please do so through a SOAP Note.'
};

export default EN;
