import React, { useMemo, useState } from 'react';
import dayjs from 'dayjs';
import classnames from 'classnames';
import { PaymentsPopover } from '../payments';
import AccessControl from '../access-control';
import UpdateDateModal from './update-date-modal';
import { getPaidAmount, getInvoiceStatusClassName } from './utilities';
import Countries from '@/utilities/country-regions';
import { INPUT_DATE_FORMAT } from '@/utilities/dates/formats';
import { Money } from '@/utilities/currency';
import { AdminRoleDescriptions } from '@/utilities/user-roles';
import Strings from './lang';
import Icon from '../icon';

const getDate = (date) => {
  return date ? dayjs(date).format(INPUT_DATE_FORMAT) : null;
};

const InvoiceDetailRow = React.forwardRef(({
  label,
  value,
  icon,
  onEdit,
  iconClassName,
  rowClassName,
  valueClassName,
  onClick
}, ref) => {
  return (
    <div ref={ref} className={classnames('details-row', rowClassName)} onClick={onClick}>
      <div className="detail-label">
        {icon && <Icon name={icon} className={classnames('no-print', iconClassName)} />}
        {label}
      </div>
      <div className={classnames('detail-value', valueClassName)}>
        {value}
      </div>
      {onEdit && (
        <AccessControl roles={AdminRoleDescriptions}>
          <div style={{ position: 'absolute', right: 0 }}>
            <button type="button" className="edit-button bold" onClick={onEdit}>
              <Icon name="pen-to-square" />
            </button>
          </div>
        </AccessControl>
      )}
    </div>
  );
});

const PaymentsDetailsRow = ({
  payments = [],
  currency
}) => {
  const hasPayments = !!payments.length;
  const [open, setOpen] = useState(false);
  const amount = useMemo(() => Money.format(
    getPaidAmount(payments), 
    currency
  ), [currency, payments]);

  return (
    <PaymentsPopover
      isOpen={open}
      onClose={() => setOpen(false)}
      payments={payments}
      total={amount}
    >
      <InvoiceDetailRow 
        label={Strings.amountPaidLabel}
        value={amount}
        icon={hasPayments && 'circle-info'}
        iconClassName="primary"
        rowClassName={hasPayments && 'info'}
        onClick={
          hasPayments 
            ? () => setOpen(!open) 
            : undefined
        }
      />
    </PaymentsPopover>
  );
};

const InvoiceHeader = ({
  clinic = {},
  invoice = {}
}) => {
  const [closeDateOpen, setCloseDateOpen] = useState(false);
  const [dueDateOpen, setDueDateOpen] = useState(false);
  const { name, address = {}, billing_address } = clinic || {};
  const clinicAddress = billing_address || address || {};
  const {
    street_1, street_2, city, state, country, postal_code 
  } = clinicAddress;
  const {
    status = '', title, created_at, close_date, due_date, currency, payments = [], date_paid
  } = invoice || {};
  const countryObject = Countries[country] || {};

  return (
    <div className="invoice-header">
      <div className="address-column">
        <div className="semi-bold uppercase">{Strings.invoiceToHeader}</div>
        <div>{name}</div>
        <div>{street_1}{street_2 ? `, ${street_2}` : null}</div>
        <div>{city} {state} {postal_code}</div>
        <div>{countryObject.name}</div>
      </div>
      <div>
        <InvoiceDetailRow 
          label={Strings.invoiceNumberLabel}
          value={title}
        />
        <InvoiceDetailRow 
          label={Strings.invoiceStatusLabel}
          value={status.toUpperCase()}
          valueClassName={classnames(
            getInvoiceStatusClassName(status),
            'bold'
          )}
        />
        <InvoiceDetailRow 
          label={Strings.invoiceDateLabel}
          value={close_date || getDate(created_at)}
          onEdit={status === 'open' && (() => setCloseDateOpen(true))}
        />
        <PaymentsDetailsRow 
          payments={payments}
          currency={currency}
        />
        {status === 'paid' ? (
          <InvoiceDetailRow 
            label={Strings.datePaidLabel}
            value={getDate(date_paid)}
          />
        ) : (
          <InvoiceDetailRow 
            label={Strings.dueDateLabel}
            value={due_date || '----'}
            onEdit={status === 'closed' && (() => setDueDateOpen(true))}
          />
        )}
      </div>

      <UpdateDateModal 
        isOpen={closeDateOpen}
        clinicId={clinic.id}
        invoiceId={invoice.id}
        attribute="close_date"
        date={invoice.close_date}
        onClose={() => setCloseDateOpen(false)}
      />

      <UpdateDateModal 
        isOpen={dueDateOpen}
        clinicId={clinic.id}
        invoiceId={invoice.id}
        attribute="due_date"
        minDate={invoice.close_date}
        date={invoice.due_date}
        onClose={() => setDueDateOpen(false)}
      />
    </div>
  );
};

export default InvoiceHeader;
