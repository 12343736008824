import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InvoiceSelector } from '@/redux/selectors';
import { getInvoicesAsync } from '@/redux/thunks/clinic-invoices';
import InvoicesTable from './invoices-table';
import { useMount } from '@/hooks';
import ErrorBanner from '../errors/error-banner';
import Strings from './lang';

const PAID_FILTER_KEY = 'paid';

const PaidInvoices = ({
  clinicId,
  onInvoiceClick
}) => {
  const dispatch = useDispatch();
  const invoices = useSelector((state) => InvoiceSelector.getPaidInvoices(state, { clinicId }));
  const [error, setError] = useState(null);

  const getPaidInvoices = useCallback(() => {
    dispatch(getInvoicesAsync(clinicId, PAID_FILTER_KEY)).catch(error => {
      setError(
        Strings.formatString(
          Strings.invoicesLoadErrorMessage,
          error.message || Strings.unknownErrorMessage
        )
      );
    });
  }, [clinicId, dispatch]);

  useMount(() => {
    if (!invoices.length) {
      getPaidInvoices();
    }
  });

  return (
    <div>
      <ErrorBanner error={error} />
      {invoices.length ? (
        <InvoicesTable 
          paid
          invoices={invoices} 
          onInvoiceClick={onInvoiceClick}
        />
      ) : (
        <h2 className="text-center">
          {Strings.emptyPaidInvoicesMessage}
        </h2>
      )}
    </div>
  );
};

export default PaidInvoices;
