import React from 'react';
import PropTypes from 'prop-types';
import { If, Then, Else } from 'react-if';
import ChecklistItem from '../../helpers/checklist-item';
import Strings from './lang';

const PostInjuryFollowUp = ({
  data = {}
}) => (
  <div className="post-injury-section post-injury-follow-up">

    <div className="row">
      <div className="col-md-12">
        <h2 className="bold">{Strings.followUpTitle}</h2>
      </div>
    </div>

    <div className="post-injury-group">
      <If condition={data.none !== true}>
        <Then>
          <ul className="list-unstyled">
            {data.clinician === true && (
              <ChecklistItem checked>
                {Strings.clinicianMonitoringText}
              </ChecklistItem>
            )}
            {data.referral === true && (
              <ChecklistItem checked>
                {(data.referral) 
                  ? (<span><strong>{Strings.referralToText}</strong> {data.referral_to}</span>) 
                  : Strings.referralText}
              </ChecklistItem>
            )}
          </ul>
        </Then>
        <Else>
          <div className="row">
            <div className="col-md-12">
              {Strings.defaultFollowUpText}
            </div>
          </div>
        </Else>
      </If>
    </div>

  </div>
);

PostInjuryFollowUp.propTypes = {
  data: PropTypes.object.isRequired
};

export default PostInjuryFollowUp;
