import React from 'react';
import { Authorization } from '../authorization';
import { PatientInjuriesInfo } from '@/components/patient-profile';
import { PostInjuryAssessmentDisplay } from '@/components/post-injury-assessment';
import { BlackhawksTestDisplay } from '@/components/blackhawks-test';
import { BuffaloTestDisplay } from '@/components/buffalo-test';
import { VOMSTestDisplay } from '@/components/voms';
import { OrthostaticVitalSignsTestDisplay } from '@/components/ovs-tests';
import { SymptomsUpdates } from '@/components/symptom-updates';
import InjuryInsights from '@/components/injury-insights';
import { PostInjuryTestDisplayContainer } from '@/components/baseline';
import { ProgressReports } from '@/components/progress-update';
import { InjuryReferralsList } from '@/components/referrals';
import { SpecialistNotesList } from '@/components/specialist-notes';
import { SoapNotesArchivedList, SoapNotesDisplay, SoapNotesList } from '@/components/soap-notes';
import { AdminRoleDescriptions, RoleDescriptions } from '@/utilities/user-roles';
import PageNotFound from '../not-found-page';

const PatientInjuriesRoutes = [
  {
    index: true,
    element: (
      <Authorization
        allowRoles={[
          ...AdminRoleDescriptions,
          RoleDescriptions.ClinicFrontDesk,
          RoleDescriptions.Clinician,
          RoleDescriptions.Specialist,
          RoleDescriptions.ClinicStaff
        ]}
      >
        <PatientInjuriesInfo />
      </Authorization>
    )
  },
  {
    path: ':injuryId/*',
    children: [
      {
        path: 'assessment/:assessmentId',
        element: (
          <Authorization
            allowRoles={[
              RoleDescriptions.Clinician,
              RoleDescriptions.Specialist
            ]}
          >
            <PostInjuryAssessmentDisplay />
          </Authorization>
        )
      },
      {
        path: 'blackhawks/:blackhawksId',
        element: (
          <Authorization
            allowRoles={[
              RoleDescriptions.Clinician,
              RoleDescriptions.Specialist,
              RoleDescriptions.ClinicFrontDesk
            ]}
          >
            <BlackhawksTestDisplay />
          </Authorization>
        )
      },
      {
        path: 'buffalo/:buffaloId',
        element: (
          <Authorization
            allowRoles={[
              RoleDescriptions.Clinician,
              RoleDescriptions.Specialist,
              RoleDescriptions.ClinicFrontDesk
            ]}
          >
            <BuffaloTestDisplay />
          </Authorization>
        )
      },
      {
        path: 'voms/:vomsId',
        element: (
          <Authorization
            allowRoles={[
              RoleDescriptions.Clinician,
              RoleDescriptions.Specialist,
              RoleDescriptions.ClinicFrontDesk
            ]}
          >
            <VOMSTestDisplay />
          </Authorization>
        )
      },
      {
        path: 'ovs/:ovsId',
        element: (
          <Authorization
            allowRoles={[
              RoleDescriptions.Clinician,
              RoleDescriptions.Specialist,
              RoleDescriptions.ClinicFrontDesk
            ]}
          >
            <OrthostaticVitalSignsTestDisplay />
          </Authorization>
        )
      },
      {
        path: 'post-injury-tests/:testId/compare/:compareContext',
        element: (
          <Authorization
            allowRoles={[
              RoleDescriptions.Clinician,
              RoleDescriptions.Specialist,
              RoleDescriptions.ClinicFrontDesk
            ]}
          >
            <PostInjuryTestDisplayContainer />
          </Authorization>
        )
      },
      {
        path: 'progress-reports',
        element: (
          <Authorization
            allowRoles={[
              RoleDescriptions.Clinician,
              RoleDescriptions.Specialist,
              RoleDescriptions.ClinicFrontDesk
            ]}
          >
            <ProgressReports />
          </Authorization>
        )
      },
      {
        path: 'symptom-updates',
        element: (
          <Authorization
            allowRoles={[
              RoleDescriptions.Clinician,
              RoleDescriptions.Specialist,
              RoleDescriptions.ClinicFrontDesk
            ]}
          >
            <SymptomsUpdates />
          </Authorization>
        )
      },
      {
        path: 'insights',
        element: (
          <Authorization
            allowRoles={[
              RoleDescriptions.Clinician,
              RoleDescriptions.Specialist
            ]}
          >
            <InjuryInsights />
          </Authorization>
        )
      },
      {
        path: 'referrals',
        element: (
          <Authorization
            allowRoles={[
              RoleDescriptions.Clinician,
              RoleDescriptions.Specialist,
              RoleDescriptions.ClinicFrontDesk
            ]}
          >
            <InjuryReferralsList />
          </Authorization>
        )
      },
      {
        path: 'specialist-notes',
        element: (
          <Authorization
            allowRoles={[
              RoleDescriptions.Clinician,
              RoleDescriptions.Specialist,
              RoleDescriptions.ClinicFrontDesk
            ]}
          >
            <SpecialistNotesList />
          </Authorization>
        )
      },
      {
        path: 'soaps/*',
        element: (
          <Authorization
            allowRoles={[
              RoleDescriptions.Clinician,
              RoleDescriptions.Specialist,
              RoleDescriptions.ClinicStaff,
              RoleDescriptions.ClinicFrontDesk
            ]}
          />
        ),
        children: [
          {
            index: true,
            element: <SoapNotesList />
          },
          {
            path: 'archived',
            element: (
              <Authorization
                allowRoles={[
                  RoleDescriptions.Clinician,
                  RoleDescriptions.Specialist,
                ]}
              >
                <SoapNotesArchivedList />
              </Authorization>
            )
          },
          {
            path: ':soapId',
            element: (
              <Authorization
                allowRoles={[
                  RoleDescriptions.Clinician,
                  RoleDescriptions.Specialist,
                  RoleDescriptions.ClinicFrontDesk
                ]}
              >
                <SoapNotesDisplay />
              </Authorization>
            )
          },
          {
            path: '*',
            element: <PageNotFound />
          }
        ]
      },
      {
        path: '*',
        element: <PageNotFound />
      }
    ]
  },
  {
    path: '*',
    element: <PageNotFound />
  }
];

export default PatientInjuriesRoutes;
