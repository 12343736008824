const ResourcesPaths = {
  route: 'resources(/:fileId)',
  root: {
    link: '/resources',
  },
  file: {
    link: '/resources/:fileId'
  },
  courses: {
    index: {
      route: '/resources/courses',
      link: '/resources/courses',
    },
    course: {
      route: ':courseId',
      link: '/resources/courses/:courseId'
    }
  }
};

export default ResourcesPaths;
