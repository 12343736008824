const UPDATE_OVS_TEST = 'UPDATE_OVS_TEST';

const updateOVSTest = (userId, injuryId, test) => ({
  type: UPDATE_OVS_TEST,
  userId,
  injuryId,
  test
});

export {
  UPDATE_OVS_TEST,
  updateOVSTest
};
