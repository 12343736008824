const groupItems = (items = [], numGroups = 2) => {
  const itemsPerGroup = Math.ceil(items.length / numGroups);
  return Array.from(Array(numGroups)).map((na, index) => {
    const start = index * itemsPerGroup;
    const end = start + itemsPerGroup;
    return items.slice(start, end);
  });
};

export default groupItems;
