const UPDATE_CLINIC = 'UPDATE_CLINIC';
const UPDATE_CLINICS = 'UPDATE_CLINICS';
const UPDATE_CLINICS_META_KEY = 'UPDATE_CLINICS_META_KEY';

const updateClinic = (clinic) => ({
  type: UPDATE_CLINIC,
  clinic
});

const updateClinics = (clinics) => ({
  type: UPDATE_CLINICS,
  clinics
});

const updateClinicsMetaKey = (key, value) => ({
  type: UPDATE_CLINICS_META_KEY,
  key,
  value
});

export {
  UPDATE_CLINIC,
  UPDATE_CLINICS,
  UPDATE_CLINICS_META_KEY,
  updateClinic,
  updateClinics,
  updateClinicsMetaKey
};
