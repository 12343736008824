import React from 'react';
import Select, { components } from 'react-select';

// --primary-color
const PRIMARY_COLOR = '#2196F1';
// Bootstrap colors
const FOCUSED_CONTROL_BOX_SHADOW = `0 0 0 2px ${PRIMARY_COLOR}`;
const FOCUSED_CONTROL_BORDER_COLOR = 'transparent';
const CONTROL_BACKGROUND = '#f6f6f6';

const FONT_COLOR = '#4b4b4b';
const CONTROL_BORDER_COLOR = 'rgba(96, 96, 96, 0.125)';
const PLACEHOLDER_COLOR = '#acacac';
const MULTIVALUE_BACKGROUND = '#dadada';
// --light-grey-content-color
const HOVER_OPTION_BACKGROUND = 'rgba(33, 150, 241, 0.35)';

const getOptionBackground = ({ isSelected, isFocused }) => {
  if (isSelected) return PRIMARY_COLOR;
  if (isFocused) return HOVER_OPTION_BACKGROUND;
  return null;
};

const MIN_INPUT_HEIGHT = 42;

const getStyles = (styles = {}) => {
  return {
    container: (provided, state) => ({
      ...provided,
      height: !state.isMulti && MIN_INPUT_HEIGHT,
      minHeight: state.isMulti && MIN_INPUT_HEIGHT,
      marginBottom: 5
    }),
    control: (provided, state) => ({
      ...provided,
      borderRadius: 8,
      borderColor: state.isFocused ? FOCUSED_CONTROL_BORDER_COLOR : CONTROL_BORDER_COLOR,
      backgroundColor: CONTROL_BACKGROUND,
      boxShadow: state.isFocused 
        ? FOCUSED_CONTROL_BOX_SHADOW
        : null,
      '&:hover': {
        borderColor: CONTROL_BORDER_COLOR
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: PLACEHOLDER_COLOR
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '8px 15px'
    }),
    multiValue: (provided) => ({
      ...provided,
      overflow: 'hidden',
      borderRadius: '999em',
      backgroundColor: MULTIVALUE_BACKGROUND,
      paddingLeft: 5,
      '&& > *': {
        color: FONT_COLOR
      }
    }),
    input: (provided) => ({
      ...provided,
      margin: 0,
      padding: 0
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 1000
    }),
    option: (provided, state) => ({
      ...provided,
      '&:hover': {
        backgroundColor: state.isSelected ? null : HOVER_OPTION_BACKGROUND
      },
      cursor: 'pointer',
      backgroundColor: getOptionBackground(state)
    }),
    ...styles
  };
};

const ReactSelect = ({
  id,
  value,
  name,
  disabled,
  options,
  isMulti,
  styles,
  onChange,
  ...rest
}) => {
  return (
    <Select 
      inputId={id}
      isMulti={isMulti}
      value={value}
      options={options}
      name={name}
      className="react-select"
      onChange={onChange}
      styles={getStyles(styles)}
      isDisabled={disabled}
      {...rest}
    />
  );
};

export default ReactSelect;
export { components, getStyles };
