import React from 'react';
import { Authorization } from '../authorization';
import { AdminAndClinicRoleDescriptions, AdminRoleDescriptions } from '@/utilities/user-roles';
import {
  AdminMarketplaceProducts,
  MarketplaceProductEdit,
  MarketplaceProducts,
  MarketplaceProductListing,
  MarketplacePartnerProfile
} from '@/components/marketplace';
import PageNotFound from '../not-found-page';

const MarketplaceRoutes = [
  {
    path: '*',
    element: <Authorization allowUntrained allowRoles={AdminAndClinicRoleDescriptions} />,
    children: [
      {
        index: true,
        element: (
          <Authorization allowRoles={AdminRoleDescriptions}>
            <AdminMarketplaceProducts />
          </Authorization>
        )
      },
      {
        path: 'products/*',
        children: [
          {
            index: true,
            element: <MarketplaceProducts />
          },
          {
            path: 'new',
            element: (
              <Authorization allowRoles={AdminRoleDescriptions}>
                <MarketplaceProductEdit />
              </Authorization>
            )
          },
          {
            path: ':productId/*',
            children: [
              {
                index: true,
                element: <MarketplaceProductListing />
              },
              {
                path: 'edit',
                element: (
                  <Authorization allowRoles={AdminRoleDescriptions}>
                    <MarketplaceProductEdit />
                  </Authorization>
                )
              },
              {
                path: '*',
                element: <PageNotFound />
              }
            ]
          },
          {
            path: '*',
            element: <PageNotFound />
          }
        ]
      },
      {
        path: 'partners/:partnerId',
        element: <MarketplacePartnerProfile />
      },
      {
        path: '*',
        element: <PageNotFound />
      }
    ]
  }
];

export default MarketplaceRoutes;
