import React from 'react';
import { Page } from '../page';
import { CCMI_TRAINING_LINK, CCMI_TRAINING_TARGET } from './constants';
import Strings from './lang';

const Untrained = () => (
  <Page className="untrained" hideNavigationContent>
    <div className="row">
      <div className="col-md-12">
        <h1 className="display">{Strings.title}</h1>
      </div>
    </div>
    <div className="row">
      <div className="col-md-12">
        <p>{Strings.message}</p>
        <p>
          <a href={CCMI_TRAINING_LINK} target={CCMI_TRAINING_TARGET}>
            {Strings.trainingLinkText}
          </a>
        </p>
      </div>
    </div>
  </Page>
);

export default Untrained;
