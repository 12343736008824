import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import StatusBadge from './status-badge';
import FinalReportView from './final-report-view';
import ReportActionButton from './report-action-button';
import { ReportTypeStrings, BlockTreatmentTypeStrings } from '../utils';
import Strings from '../lang';
import { getCurrentClinicId } from '@/tokens';
import {
  RoleNames,
  RoleResourceTypes,
  makeRoleDescription,
  userHasRoleMatchingOneOfDescriptions
} from '@/utilities/user-roles';
import { UserSelector } from '@/redux/selectors';
import Icon from '../../icon';

const ReportsTable = ({ reports }) => {
  const currentUser = useSelector(UserSelector.getCurrentUser);
  const canStartReport = useMemo(() => {
    const clinicId = getCurrentClinicId();
    return userHasRoleMatchingOneOfDescriptions(currentUser, [
      makeRoleDescription(RoleNames.Clinician, RoleResourceTypes.Clinic, clinicId),
      makeRoleDescription(RoleNames.Specialist, RoleResourceTypes.Clinic, clinicId),
      makeRoleDescription(RoleNames.IDTClinician, RoleResourceTypes.Clinic, clinicId),
    ]);
  }, [currentUser]);
  return (
    <table className="table idt-reports-table">
      <thead>
        <tr>
          <th>{Strings.reportTypeColumnLabel}</th>
          <th>{Strings.blockTypeColumnLabel}</th>
          <th>{Strings.dueDateColumnLabel}</th>
          <th>{Strings.reportStatusColumnLabel}</th>
          <th>{Strings.finalReportTableColumn}</th>
          {canStartReport && <th />}
        </tr>
      </thead>
      <tbody>
        {reports.map(report => (
          <tr key={report.key}>
            <td>{ReportTypeStrings[report.type]}{report.is_wh ? ` (${Strings.treatmentTypeWorkHardening})` : ''}</td>
            <td>{report.block_type ? `${BlockTreatmentTypeStrings[report.block_type]} (${report.block_number})` : '--'}</td>
            <td>{report.due_date}</td>
            <td><StatusBadge status={report.status} /></td>
            <td>
              <FinalReportView
                reportStatus={report.status}
                document={report.document}
                claimId={report.claim_id}
                blockId={report.block_id}
              />
            </td>
            {canStartReport && (
              <td>
                <div className="report-action-cell">
                  <ReportActionButton report={report} />
                  {(report.comments_count ?? 0) > 0 && (
                    <div className="comment-indicator">
                      <Icon name="comment" className="primary" />&nbsp;<span>{report.comments_count}</span>
                    </div>
                  )}
                </div>
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ReportsTable;
