import React from 'react';

/**
 * Wraps a component in React.forwardRef and passes the reference
 * through to the wrapped component as a forwardRef prop.
 * @param {React.Component} WrappedComponent the component to forward the reference to
 * @returns {React.ForwardRefExoticComponent<React.RefAttributes<any>} Reference forwarded component
 */
const refForwardable = (WrappedComponent) => (
  React.forwardRef((props, ref) => (
    <WrappedComponent forwardRef={ref} {...props} />
  ))
);

export default refForwardable;
