import Favico from 'favico.js';
import Store from '@/redux/store';
import { UserSelector } from '@/redux/selectors';

const favicon = new Favico({
  position: 'up'
});

let previousNotificationsCount = 0;

const notificationWatcher = () => {
  const user = UserSelector.getCurrentUser(Store.getState()) || {};

  if (user && user.unread_notification_count !== previousNotificationsCount) {
    favicon.badge(user.unread_notification_count || 0);
    previousNotificationsCount = user.unread_notification_count;
  }
};

const registerFaviconNotificationWatcher = () => {
  Store.subscribe(notificationWatcher);
};

export default registerFaviconNotificationWatcher;
