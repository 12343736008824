const EN = {
  convertToFamilyAccountBannerMessage: 'Need to add multiple family members to this account?',
  convertToFamilyAccountBannerAction: 'Convert your account to a Family Account',
  convertToFamilyAccountConfirmTitle: 'Convert your account to a Family Account?',
  convertToFamilyAccountConfirmMessage: 'By confirming, this account will become a family member under your new Family Account. You will then be able to add additional family members. Would you like to proceed?',
  accountConvertedSuccessBannerTitle: 'Your account has been successfully converted to a Family Account!',
  accountConvertFailedMessage: 'Failed to convert your account to a family account: {0}'
};

export default EN;
