import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormComponent, RadioInputGroup, FormInputGroup } from '@/forms';
import BuffaloTestFormRow from './buffalo-test-form-row';
import BuffaloTestFormHeader from './buffalo-test-form-header';
import Strings from '../lang';
import RestingHeartRateInput from '../../resting-heart-rate-input';
import { 
  MIN_HEART_RATE, 
  MAX_HEART_RATE, 
  heartRateValidator, 
  timeValidator 
} from '../helpers';
import {
  MIN_INTERVAL,
  MAX_INTERVAL,
  COOL_DOWN,
  getBuffaloTestState
} from './buffalo-data';

const getCompleteRowValue = (intervals) => {
  return intervals.reduceRight((acc, curr, index) => {
    if (curr.heart_rate !== '' || curr.symptoms !== '') {
      acc.push({
        time: index,
        heart_rate: curr.heart_rate,
        symptoms: curr.symptoms
      });
    }

    return acc;
  }, []);
};

class BuffaloTestForm extends Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
    this.onUpdateRestingHR = this.onUpdateRestingHR.bind(this);
    this.onSymptomExacerbationChange = this.onSymptomExacerbationChange.bind(this);

    this.state = {
      ...getBuffaloTestState(props),
      submitted: false,
      validRestingHR: false
    };
  }

  render() {
    const { buffalo, validRestingHR } = this.state;
    const {
      resting_heart_rate,
      symptom_exacerbation,
      exacerbated_symptoms_heart_rate,
      exacerbated_symptoms_time,
      exacerbated_symptoms_reported,
      notes
    } = buffalo;

    return (
      <div>
        <div className="row buffalo-test-instructions-quote">
          <div className="col-md-12">
            <p className="text-muted">{Strings.buffaloInfoText}</p>
          </div>
        </div>
        <FormComponent className="buffalo-test-form" onSubmit={this.onSubmit}>
          <div>

            <RestingHeartRateInput
              value={resting_heart_rate}
              touched={this.state.submitted}
              required
              onUpdate={this.onUpdateRestingHR}
              testName={Strings.title}
            />

            {/* hide test until resting heart rate valid */}
            {validRestingHR && (
              <div>
                <div className="row buffalo-instructions">
                  <div className="col-md-6">
                    <small className="bold">{Strings.warmUpText}</small>
                  </div>
                  <div className="col-md-6">
                    <small className="bold">{Strings.startTestText}</small>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <table className="table buffalo-table">
                      <thead>
                        <BuffaloTestFormHeader />
                      </thead>
                      <tbody>
                        {this.renderBuffaloTestRows()}
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="cool-down-row">
                      <p>{Strings.coolDownInfoText}</p>
                    </div>
                  </div>
                </div>

                <div className="text-center">
                  <p><strong>{Strings.monitorPatientInfoText}</strong></p>
                </div>

                <div className="row symptom-exacerbation">

                  {/* Symptom Exacerbation */}
                  <div className="col-md-4">
                    <RadioInputGroup
                      titleLabelText={
                        <span>{Strings.symptomExacerbationLabel} <span className="required">*</span></span>
                      }
                      radioLabels={[Strings.yesLabel, Strings.noLabel]}
                      radioValues={['true', 'false']}
                      initialValue={String(symptom_exacerbation)}
                      inputValid={this.state.submitted === true ? symptom_exacerbation !== '' : true}
                      messageClassName="alert alert-danger"
                      messageText={Strings.symptomExacerbationErrorMessage}
                      onUpdate={this.onSymptomExacerbationChange}
                    />
                  </div>

                  {/* Hidden fields. Only visible when symptom exacerbation is true */}
                  <div className="col-md-12">
                    {String(symptom_exacerbation) === 'true' && (
                      <div className="row">

                        {/* Symptom Exacerbation Heart Rate */}
                        <div className="col-md-4">
                          <FormInputGroup
                            className="form-group"
                            labelText={Strings.heartRateLabel}
                            inputType="text"
                            inputProps={{
                              className: 'form-control',
                              value: exacerbated_symptoms_heart_rate,
                              onChange: this.onUpdateInput.bind(this, 'exacerbated_symptoms_heart_rate')
                            }}
                            required
                            messageClassName="alert alert-danger"
                            messageText={Strings.formatString(
                              Strings.invalidHeartRateMessage, 
                              MIN_HEART_RATE, 
                              MAX_HEART_RATE
                            )}
                            inputValid={heartRateValidator(exacerbated_symptoms_heart_rate)}
                            touched={this.state.submitted}
                          />
                        </div>

                        {/* SymptomExacerbation Time */}
                        <div className="col-md-4">
                          <FormInputGroup
                            className="form-group"
                            labelText={Strings.timeLabel}
                            inputType="text"
                            inputProps={{
                              className: 'form-control',
                              value: exacerbated_symptoms_time,
                              onChange: this.onUpdateInput.bind(this, 'exacerbated_symptoms_time')
                            }}
                            required
                            messageClassName="alert alert-danger"
                            messageText={Strings.formatString(
                              Strings.exacerbationTimeErrorMessage, 
                              MIN_INTERVAL, 
                              MAX_INTERVAL
                            )}
                            inputValid={timeValidator(exacerbated_symptoms_time, MAX_INTERVAL)}
                            touched={this.state.submitted}
                          />
                        </div>

                        {/* Symptom Exacerbation symptoms */}
                        <div className="col-md-4">
                          <FormInputGroup
                            className="form-group"
                            labelText={Strings.symptomsLabel}
                            inputType="text"
                            inputProps={{
                              className: 'form-control',
                              value: exacerbated_symptoms_reported,
                              onChange: this.onUpdateInput.bind(this, 'exacerbated_symptoms_reported')
                            }}
                            required
                            messageClassName="alert alert-danger"
                            messageText={Strings.exacerbationSymptomsErrorMessage}
                            inputValid={exacerbated_symptoms_reported !== ''}
                            touched={this.state.submitted}
                          />
                        </div>

                      </div>
                    )}
                  </div>
                </div>

                {/* Overall notes */}
                <FormInputGroup
                  className="form-group"
                  labelText={Strings.overallNotesLabel}
                  inputType="textarea"
                  inputProps={{
                    rows: 10,
                    className: 'form-control',
                    value: notes,
                    onChange: this.onUpdateInput.bind(this, 'notes')
                  }}
                  required={false}
                  inputValid
                />

                <button type="submit" className="btn btn-primary">{Strings.saveButtonText}</button>
              </div>
            )}
          </div>
        </FormComponent>
      </div>
    );
  }

  renderBuffaloTestRows() {
    const { results } = this.state.buffalo;
    const { minute_intervals } = results;

    return minute_intervals.map((item, index) => {
      let inclineText = index;
      if (index === COOL_DOWN) {
        inclineText = Strings.coolDownText;
      } else if (index === MAX_INTERVAL) {
        inclineText = Strings.stopTestText;
      }

      return (
        <BuffaloTestFormRow
          key={index}
          minute={index}
          incline={inclineText}
          symptoms={item.symptoms}
          heartRate={item.heart_rate}
          heartRateErrorMessage={Strings.formatString(
            Strings.invalidHeartRateMessage, 
            MIN_HEART_RATE, 
            MAX_HEART_RATE
          )}
          submitted={this.state.submitted}
          heartRateValid={item.heart_rate !== '' ? heartRateValidator(item.heart_rate) : true}
          onHeartRateChange={(e) => this.onChange('heart_rate', index, e)}
          onSymptomChange={(e) => this.onChange('symptoms', index, e)}
        />
      );
    });
  }

  onUpdateRestingHR(value, valid) {
    const { buffalo } = this.state;
    buffalo.resting_heart_rate = value;
    this.setState({
      buffalo,
      validRestingHR: valid
    });
  }

  onUpdateInput(key, e) {
    const { buffalo } = this.state;
    const { value } = e.target;
    this.setState({
      buffalo: {
        ...buffalo,
        [key]: value
      }
    });
  }

  onChange(key, index, e) {
    const { buffalo } = this.state;
    const { value } = e.target;
    const { results } = buffalo;
    results.minute_intervals[index][key] = value;

    this.setState({
      buffalo: {
        ...buffalo,
        results
      }
    });
  }

  onSymptomExacerbationChange(value) {
    const { buffalo } = this.state;
    const { results } = buffalo;
    let {
      symptom_exacerbation,
      exacerbated_symptoms_heart_rate,
      exacerbated_symptoms_time,
      exacerbated_symptoms_reported
    } = buffalo;
    let matchedItems = [];

    symptom_exacerbation = value;

    /** convert boolean to string */
    if (String(symptom_exacerbation) === 'true') {
      matchedItems = getCompleteRowValue(results.minute_intervals);
    } else {
      exacerbated_symptoms_heart_rate = '';
      exacerbated_symptoms_time = '';
      exacerbated_symptoms_reported = '';
    }

    if (matchedItems.length > 0) {
      const lastItem = matchedItems[0];
      exacerbated_symptoms_heart_rate = lastItem.heart_rate;
      exacerbated_symptoms_reported = lastItem.symptoms;
      exacerbated_symptoms_time = lastItem.time;
    }

    this.setState({
      buffalo: {
        ...buffalo,
        symptom_exacerbation,
        exacerbated_symptoms_heart_rate,
        exacerbated_symptoms_time,
        exacerbated_symptoms_reported
      }
    });
  }

  isValid() {
    const {
      symptom_exacerbation,
      exacerbated_symptoms_heart_rate,
      exacerbated_symptoms_time,
      exacerbated_symptoms_reported,
      resting_heart_rate,
      results
    } = this.state.buffalo;
    const { minute_intervals } = results;
    const invalidIntervals = minute_intervals.filter(item => {
      return item.heart_rate !== '' ? !heartRateValidator(item.heart_rate) : false;
    });

    if (invalidIntervals.length > 0) {
      return false;
    }

    if (symptom_exacerbation === '') {
      return false;
    }

    if (!heartRateValidator(resting_heart_rate)) {
      return false;
    }

    if (String(symptom_exacerbation) === 'true' 
      && (!heartRateValidator(exacerbated_symptoms_heart_rate) 
        || !timeValidator(exacerbated_symptoms_time, MAX_INTERVAL) 
        || exacerbated_symptoms_reported === ''
      )
    ) {
      return false;
    }

    return true;
  }

  onSubmit() {
    this.setState({ submitted: true });

    if (!this.isValid()) {
      return;
    }

    const attributes = {
      ...this.state.buffalo,
      buffalo_version: 1
    };

    this.props.onSubmit(attributes);
  }
}

/* eslint-disable react/no-unused-prop-types */
BuffaloTestForm.propTypes = {
  buffalo: PropTypes.object,
  onSubmit: PropTypes.func
};

export default BuffaloTestForm;
