import Strings from './lang';

const FieldLacrossePositions = [
  { key: 'attackmen', name: Strings.fieldlacrosse_attackmen },
  { key: 'midfielder', name: Strings.fieldlacrosse_midfielder },
  { key: 'defensemen', name: Strings.fieldlacrosse_defensemen },
  { key: 'goalie', name: Strings.fieldlacrosse_goalie },
];

export default FieldLacrossePositions;
