import React from 'react';
import PropTypes from 'prop-types';
import { FormComponent } from '@/forms';
import Card from '../../../card';
import SharedStrings from '../lang';

const AssessmentSectionForm = ({
  title,
  className,
  onSubmit,
  children
}) => (
  <Card title={title}>
    <div className="card card-form">
      <FormComponent className={className} onSubmit={onSubmit}>
        {children}
        <div className="form-footer">
          <button type="submit" className="btn btn-primary">
            {SharedStrings.saveAndContinueButtonText}
          </button>
        </div>
      </FormComponent>
    </div>
  </Card>
);

AssessmentSectionForm.propTypes = {
  title: PropTypes.string,
  onSubmit: PropTypes.func
};

export default AssessmentSectionForm;
