const PasswordsPaths = {
  index: {
    route: 'password',
    link: '/password'
  },
  reset: {
    index: {
      route: 'reset',
      link: '/password/reset'
    },
    token: {
      route: 'reset/:token',
      link: '/password/reset/'
    }
  }
};

export default PasswordsPaths;
