import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import Drawer from '../drawer';
import TaxItemForm from './tax-item-form';
import Activity from '../activity';
import { ErrorBanner } from '../errors';
import Strings from './lang';

const TaxItemDrawer = ({
  isOpen = false,
  taxItem = null,
  error = null,
  submitting = false,
  onAddItem = () => {},
  onUpdateItem = () => {},
  onClose = () => {}
}) => {
  const handleSubmit = useCallback((attributes) => {
    if (taxItem && taxItem.id) {
      onUpdateItem({ id: taxItem.id, ...attributes });
    } else {
      onAddItem(attributes);
    }
  }, [onAddItem, onUpdateItem, taxItem]);

  return (
    <Drawer
      blur
      isOpen={isOpen}
      position="right"
      onClose={onClose}
    >
      <div className="tax-drawer-container">
        <h1 className="text-center">
          {taxItem ? Strings.updateTaxRateTitle : Strings.addTaxRateTitle}
        </h1>
        <ErrorBanner error={error} />
        <Activity active={submitting}>
          <TaxItemForm 
            taxRate={taxItem}
            onSubmit={handleSubmit}  
          />
        </Activity>
      </div>
    </Drawer>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { taxes } = state;
  const taxItem = taxes[ownProps.selectedItemId] || null;
  return { taxItem };
};

export default connect(
  mapStateToProps
)(TaxItemDrawer);
