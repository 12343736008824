export const findAddressComponentsOfType = (type, components = []) => {
  return components.filter(c => {
    return c.types.indexOf(type) >= 0;
  });
};

export const findFirstAddressComponentOfType = (type, components = []) => {
  const results = findAddressComponentsOfType(type, components);
  return (results.length) ? results[0] : null;
};
