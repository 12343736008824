import React, { forwardRef } from 'react';
import { DelayedMemoryCard } from '../baseline-test-cards';
import { DEFAULT_USER_LANGUAGE } from '@/utilities/localization';

const DEFAULT_WORD_COUNT = 5;

const DelayedMemoryStep = forwardRef((
  {
    user = {},
    baseline = {},
    onSubmit,
    ...rest
  },
  ref
) => {
  const {
    immediate_memory_test = {},
    delayed_memory_recall_test = {}
  } = baseline || {};

  const {
    word_list = 1,
    word_count = DEFAULT_WORD_COUNT
  } = immediate_memory_test;

  return (
    <DelayedMemoryCard
      {...rest}
      ref={ref}
      language={user?.person?.language || DEFAULT_USER_LANGUAGE}
      wordList={word_list}
      wordCount={word_count}
      delayedMemory={{ ...(delayed_memory_recall_test || {}) }}
      onSubmit={onSubmit}
    />
  );
});

DelayedMemoryStep.hasChanged = (initial, current) => {
  const initialScore = !isNaN(initial.score) ? initial.score : '';
  return initialScore !== current.score;
};

export default DelayedMemoryStep;
