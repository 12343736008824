import { UPDATE_BASELINE_TEST } from '../actions/baseline-tests';

const updateBaselineTestReducer = (state = {}, action = {}) => {
  const { baseline = {} } = action;
  const { id } = baseline;
  return (id) ? { ...state, [id]: baseline } : state;
};

const baselineTests = (state = {}, action = {}) => {
  switch (action.type) {
    case UPDATE_BASELINE_TEST:
      return updateBaselineTestReducer(state, action);
    default:
      return state;
  }
};

export default baselineTests;
