// This is a very loose pattern that is intended to match
// any email with the format something@somthing.something
const EmailPattern = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;

const EmailValidator = {
  test(value) {
    return EmailPattern.test(value);
  }
};

export {
  EmailValidator,
  EmailPattern
};
