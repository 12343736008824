const acuteQueryableKeys = [
  'characteristics',
  'symptoms',
  'red-flags',
  'medical-history',
  'exams',
  'tests',
  'diagnosis-plan',
  'review'
];

const chronicQueryableKeys = acuteQueryableKeys.filter(key => key !== 'red-flags');

const requiredChronicAssessmentKeys = [
  'characteristics',
  'current_rated_symptoms',
  'medical_info',
  'diagnosis',
  'return_to_activities'
];

const requiredAcuteAssessmentKeys = Array.from(requiredChronicAssessmentKeys);
requiredAcuteAssessmentKeys.splice(2, 0, 'red_flags');

const mapAttributeToSection = (attr) => {
  let section = 'characteristics';
  switch (attr) {
    case 'characteristics':
      section = 'characteristics';
      break;
    case 'current_rated_symptoms':
      section = 'symptoms';
      break;
    case 'red_flags':
      section = 'red-flags';
      break;
    case 'medical_info':
      section = 'medical-history';
      break;
    case 'diagnosis': /** intentional fall through => same section */
    case 'return_to_activities':
      section = 'diagnosis-plan';
      break;
    default:
      break;
  }

  return section;
};

const getQueryKey = (query, type = 'acute') => {
  const assessmentKeys = type === 'acute' ? acuteQueryableKeys : chronicQueryableKeys;

  if (query.section && assessmentKeys.indexOf(query.section) >= 0) {
    return query.section;
  }

  return 'characteristics';
};

export {
  requiredChronicAssessmentKeys,
  requiredAcuteAssessmentKeys,
  mapAttributeToSection,
  getQueryKey
};
