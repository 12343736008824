const FR = {
  clinicTypeLabel: 'Type de clinique',
  selectOptionLabel: 'Sélectionnez le type de clinique',
  primaryLabel: 'Réhabilitation et Traitement',
  medicalLabel: 'Médical',
  baselineLabel: 'Baseline Testing',
  specialistLabel: 'Traitement Spécialisé',
  errorMessage: 'Veuillez sélectionner le type de clinique'
};
 
export default FR;
