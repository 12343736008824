const FACEBOOK_URL = 'https://www.facebook.com';
const INSTAGRAM_URL = 'https://www.instagram.com';
const TWITTER_URL = 'https://twitter.com';

const isLink = (id) => {
  // starts with https:// or www.
  return /https?:\/\/|(www.)/.test(id);
};

const makeSocialUrl = (idOrUrl, prefix) => {
  if (!idOrUrl) return null;
  if (!isLink(idOrUrl)) return `${prefix}/${idOrUrl}`;
  return idOrUrl;
};

const makeInstagramUrl = (idOrUrl) => {
  return makeSocialUrl(idOrUrl, INSTAGRAM_URL);
};

const makeFacebookUrl = (idOrUrl) => {
  return makeSocialUrl(idOrUrl, FACEBOOK_URL);
};

const makeTwitterUrl = (idOrUrl) => {
  return makeSocialUrl(idOrUrl, TWITTER_URL);
};

const FACEBOOK_URL_REGEX = /^https?:\/\/((business|www)\.)?facebook.com\/.+/;
const INSTAGRAM_URL_REGEX = /^https?:\/\/(www\.)?instagram.com\/.+/;
const TWITTER_URL_REGEX = /^https?:\/\/(www\.)?twitter.com\/.+/;

export {
  makeFacebookUrl,
  makeTwitterUrl,
  makeInstagramUrl,
  FACEBOOK_URL_REGEX,
  INSTAGRAM_URL_REGEX,
  TWITTER_URL_REGEX
};
