const UPDATE_REFERRAL = 'UPDATE_REFERRAL';
const UPDATE_REFERRALS = 'UPDATE_REFERRALS';

const updateReferral = (referral) => ({
  type: UPDATE_REFERRAL,
  referral
});

const updateReferrals = (referrals) => ({
  type: UPDATE_REFERRALS,
  referrals
});

export {
  UPDATE_REFERRAL,
  updateReferral,
  UPDATE_REFERRALS,
  updateReferrals
};
