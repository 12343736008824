import React, { Component } from 'react';
import { connect } from 'react-redux';
import { If, Then } from 'react-if';
import dayjs from 'dayjs';
import { getCurrentClinicId } from '@/tokens';
import replaceLinkParams from '@/utilities/replace-link-params';
import { UserPaths, BaselineTestPaths } from '@/paths';
import { updateUserAsync } from '@/redux/thunks/users';
import { Page } from '../../page';
import BagtagModal from '../../bagtag-modal';
import BaselineInProgessModal from './baseline-in-progress-modal';
import { RegisteredStartTestCard, TestSearchResultsCard } from '../../start-test-cards';
import AccessControl from '../../access-control';
import Activity from '../../activity';
import {
  userHasRoleMatchingDescription, 
  makeRoleDescription, 
  RoleDescriptions, 
  RoleNames, 
  RoleResourceTypes, 
  userHasRoleMatchingOneOfDescriptions
} from '@/utilities/user-roles';
import { UserSelector } from '@/redux/selectors';
import { withRouter } from '@/utilities/router-utils';
import Strings from './lang';

class StartBaseline extends Component {
  constructor(props) {
    super(props);

    const { search, clinicId, currentUser = {} } = props;
    const { results = [] } = search;

    this.onSearch = this.onSearch.bind(this);
    this.onError = this.onError.bind(this);
    this.onClear = this.onClear.bind(this);
    this.onUserSelect = this.onUserSelect.bind(this);

    this.state = {
      selectedUser: {},
      searchResults: results,
      searched: false,
      error: null,
      activity: false,
      baselineInProgessModalOpen: false,
      bagtagModalOpen: false,
      clinicId,
      currentUser
    };
  }

  render() {
    // eslint-disable-next-line prefer-destructuring
    const isBaselineTester = this.isBaselineTester;
    const searchedUser = (
      this.state.searched
      && this.state.searchResults.length === 1
      && this.state.searchResults[0]
    ) || {};

    return (
      <Page className="start-baseline">

        <div className="row">
          <div className="col-md-12">
            <div className="start-baseline-header">
              <h1 className="display">{Strings.pageTitle}</h1>
              <AccessControl roles={[RoleDescriptions.Clinician, RoleDescriptions.BaselineTester]}>
                <div>
                  <button 
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => {
                      this.props.router.push(BaselineTestPaths.continue.link);
                    }}
                  >
                    {Strings.testInProgressButtonText}
                  </button>
                </div>
              </AccessControl>
            </div>
          </div>
        </div>

        <Activity active={this.state.activity}>
          <div className="row">
            <div className="col-md-12">

              <RegisteredStartTestCard
                cardTitle={!isBaselineTester ? Strings.checkInPatientTitle : null}
                emptySearch={this.state.searched && this.state.searchResults.length === 0}
                selectedUser={searchedUser}
                errorMessage={this.state.error}
                clinicId={this.state.clinicId}
                searchButtonText={
                  isBaselineTester ? Strings.searchButtonText : Strings.checkInPatientText
                }
                onSearch={this.onSearch}
                onError={this.onError}
                onClear={this.onClear}
                activeInjuryErrorText={Strings.activeInjuryErrorText}
              />

              <If condition={this.state.searched && this.state.searchResults.length > 1}>
                <Then>
                  <TestSearchResultsCard
                    results={this.state.searchResults}
                    clinicId={this.state.clinicId}
                    startTestButtonText={isBaselineTester 
                      ? Strings.searchButtonText 
                      : Strings.checkInPatientText}
                    onUserSelect={this.onUserSelect}
                  />
                </Then>
              </If>
            </div>

          </div>
        </Activity>
        <BagtagModal 
          skippable
          isOpen={this.state.bagtagModalOpen}
          user={this.state.selectedUser}
          clinicId={this.state.clinicId}
          onSkip={() => this.routeToBaseline(this.state.selectedUser, false)}
          onAssigned={() => this.routeToBaseline(this.state.selectedUser, true)}
          onCancel={() => this.setState({ bagtagModalOpen: false })}
        />   
        <BaselineInProgessModal 
          isOpen={this.state.baselineInProgessModalOpen}
          user={this.state.selectedUser}
          onClose={() => this.setState({ baselineInProgessModalOpen: false })}
          onStartNew={() => this.routeToBaseline(this.state.selectedUser, false)}
          onContinue={() => this.onContinueBaseline(this.state.selectedUser)}
        />
      </Page>
    );
  }

  get isBaselineTester() {
    return userHasRoleMatchingOneOfDescriptions(
      this.state.currentUser, 
      [RoleDescriptions.Clinician, RoleDescriptions.BaselineTester]
    );
  }

  onSearch(response) {
    const { results = [] } = response;

    this.setState({
      selectedUser: {},
      searchResults: results,
      searched: true
    });

    if (results.length === 1) {
      const user = results[0];

      if (!user.active_injury_id) {
        this.onUserSelect(user);
      } 
    }
  }

  onUserSelect(user) {
    if (!user) return;

    if (user.baseline_in_progress_id && this.isBaselineTester) {
      this.setState({ selectedUser: user, baselineInProgessModalOpen: true });
    } else if (!user.bag_tag) {
      this.invokeAssignBagtagModal(user);
    } else {
      this.onBeforeRouteToBaseline(user);
    }
  }

  onBeforeRouteToBaseline(user) {
    const belongsToCurrentClinic = userHasRoleMatchingDescription(user, makeRoleDescription(
      RoleNames.Player,
      RoleResourceTypes.Clinic,
      this.state.clinicId
    ));

    if (!belongsToCurrentClinic) {
      this.setState({
        error: null,
        activity: true
      });

      this.props.updateUser(user.id, { current_clinic_id: this.state.clinicId }).then(() => {
        this.setState({
          activity: false
        });

        this.routeToBaseline(user, false);
      }).catch(error => {
        this.setState({
          activity: false,
          error: error.message
        });
      });
    } else {
      this.routeToBaseline(user, false);
    }
  }

  routeToBaseline(user, bagtagAssigned) {
    const { id, person = {} } = user;
    const userLastUpdated = person.updated_at;
    let pathname = '';

    if (this.isBaselineTester) {
      /* assume that if a bagtag was assigned then the user is new so confirm their
       * personal information. The other scenerio is for users that have not had their
       * personal information updated within the past year.
       */
      if (bagtagAssigned || dayjs(userLastUpdated).diff(dayjs(), 'years') >= 1) {
        pathname = replaceLinkParams(UserPaths.baselines.steps.personal.link, {
          userId: id,
          baselineId: 'new'
        });
      } else {
        pathname = replaceLinkParams(UserPaths.baselines.steps.medical.link, {
          userId: id,
          baselineId: 'new'
        });
      }
    } else {
      /* all front desk staff will go to the baseline patient check in */
      pathname = replaceLinkParams(BaselineTestPaths.checkInPatient.link, {
        userId: id
      });
    }

    this.props.router.push(pathname);
  }

  invokeAssignBagtagModal(user) {
    this.setState({ selectedUser: user, bagtagModalOpen: true });
  }

  onContinueBaseline(user) {
    const { id, baseline_in_progress_id } = user;
    if (!user.baseline_in_progress_id) return;

    this.props.router.push(replaceLinkParams(UserPaths.baselines.steps.continue.link, {
      userId: id,
      baselineId: baseline_in_progress_id
    }));
  }

  onError(error) {
    this.setState({
      error: error.message
    });
  }

  onClear() {
    this.setState({
      searchResults: [],
      searched: false,
      error: null
    });
  }
}

const mapStateToProps = (state) => {
  const { clinicPlayersSearch = {} } = state;
  const clinicId = getCurrentClinicId() || 0;
  const search = clinicPlayersSearch[clinicId] || {};
  const currentUser = UserSelector.getCurrentUser(state) || {};
  return { search, clinicId, currentUser };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUser: (userId, attributes) => {
      return dispatch(updateUserAsync(userId, attributes));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(StartBaseline)
);
