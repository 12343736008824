import { createContext, useContext } from 'react';

const PanelContext = createContext({});
const PanelGroupContext = createContext({});

const usePanel = () => useContext(PanelContext);
const usePanelGroup = () => useContext(PanelGroupContext);

export {
  usePanel,
  usePanelGroup,
  PanelContext,
  PanelGroupContext
};
