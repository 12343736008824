const FR = {
  title: 'Gérer les patients',
  dashboardLabel: 'Dashboard',
  patientsSectionTitle: 'Patients',
  ongoingInjuriesSectionTitle: 'Blessures actuelles',
  searchButtonText: 'Rechercher',
  clearButtonText: 'Effacer',
  accountRetrievalInputPlaceholder: 'Écrire le courriel',
  accountRetrievalLabel: 'Récupérer le compte',
  accountRetrievalSuccessText: 'Un courriel a été envoyé à l\'adresse du patient afin de confirmer',
  accountRetrievalSuccessTextWithEmail: 'Un courriel a été envoyé à l\'adresse {0} afin de confirmer',
  adminStatsLinkText: 'Voir toutes les statistiques de toutes les cliniques',
  accountRecoveryHelpText: 'Si le patient a perdu sa carte d\'identité ou son numéro de compte, veuillez écrire l’adresse courriel associée au compte afin de l\'authentifier.',
  invalidEmailMessage: 'Veuillez écrire une adresse courriel valide',
  cancelButtonText: 'Annuler',
  recoveryButtonText: 'Récupération du compte par courriel',
  myPatientsTabDescription: 'Cette section affiche tous les patients avec lesquels vous étiez le clinicien le plus récent à vous engager.',
  noMyPatientsText: 'Actuellement, aucun patient ne répond à ces critères',
  noPatientsWithInjuriesInfoText: 'Il n\'y a actuellement aucun patient avec des blessures',
  noPatientsUninjuredText: 'Il n\'y a actuellement aucun patient indemne',
  noPatientsText: 'Il n\'y a actuellement aucun patient dans cette clinique',
  noPatientsSearchText: 'Aucun résultat pour votre recherche',
  exportPatientText: 'Patients D\'exportation',
  ongoingTabLabel: 'Tout en cours ({0})',
  clinicianOngoingTabLabel: 'Mon cours ({0})',
  uninjuredTabLabel: 'Indemne ({0})',
  allPatientsTabLabel: 'Tous les patients ({0})',
  statisticsTabLabel: 'Stats de la clinique',
  referralsTabLabel: 'Références'
};
 
export default FR;
