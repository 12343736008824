import React, {
  useCallback,
  useEffect,
  useState,
  useRef
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { StripePayment } from '../payments';
import InvoiceSummarySidebar from './invoice-summary-sidebar';
import { InvoiceSelector, UserSelector } from '@/redux/selectors';
import { getInvoiceAsync } from '@/redux/thunks/clinic-invoices';
import Activity from '../activity';
import Icon from '../icon';
import { DEFAULT_USER_LANGUAGE } from '@/utilities/localization';
import { addInvoicePayment } from '@/redux/actions/clinic-invoices';
import { INPUT_DATE_FORMAT } from '@/utilities/dates/formats';
import Strings from './lang';

const createPaymentObject = (result, currentUser) => ({
  amount_cents: result.amount,
  amount_currency: result.currency.toUpperCase(),
  date_stamp: dayjs.unix(result.created).format(INPUT_DATE_FORMAT),
  manual_adjustment: false,
  note: null,
  payer: currentUser,
  response_order_id: result.id
});

const ErrorView = ({ error, onRetry }) => {
  return (
    <div className="error-view">
      <div className="error-view-icon">
        <Icon name="circle-exclamation" />
      </div>
      <h2>{Strings.formatString(Strings.invoiceLoadErrorMessage, error)}</h2>
      <button type="button" className="edit-button" onClick={onRetry}>
        <Icon name="arrows-rotate" />
        {Strings.retryLoadInvoiceLabel}
      </button>
    </div>
  );
};

const InvoicePaidView = () => {
  return (
    <div className="paid-invoice-view">
      <div className="paid-invoice-icon">
        <Icon name="credit-card" />
      </div>
      <h2>{Strings.invoicePaidLabel}</h2>
    </div>
  );
};

const InvoicePayment = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const currentUser = useSelector(UserSelector.getCurrentUser);
  const invoice = useSelector(state => InvoiceSelector.getClinicInvoice(state, params));
  const lineItems = useSelector(state => InvoiceSelector.getLineItemSummary(state, params));

  const invoiceStatus = useRef(invoice.status);
  const [loading, setLoading] = useState(!invoice.id);
  const [error, setError] = useState(null);
  const { currency, owing } = invoice || {};

  const locale = currentUser?.person?.language || DEFAULT_USER_LANGUAGE;

  const loadInvoice = useCallback(() => {
    setLoading(true);
    setError(null);

    dispatch(getInvoiceAsync(params?.clinicId, params?.invoiceId)).then(invoice => {
      invoiceStatus.current = invoice.status;
      setLoading(false);
    }).catch(error => {
      setLoading(false);
      setError(error.message);
    });
  }, [dispatch, params?.clinicId, params?.invoiceId]);

  const onPaymentSuccess = useCallback((result) => {
    const payment = createPaymentObject(result, currentUser);
    dispatch(addInvoicePayment(params?.clinicId, params?.invoiceId, payment));
  }, [currentUser, dispatch, params?.clinicId, params?.invoiceId]);

  useEffect(() => {
    if (!invoice.id) {
      loadInvoice();
    }
  }, [invoice.id, loadInvoice]);

  if (error) {
    return (
      <div className="invoice-payment-page">
        <ErrorView error={error} onRetry={loadInvoice} />
      </div>
    );
  }

  return (
    <div className="invoice-payment-page">
      <Activity active={loading} static>
        <div className="invoice-payment-container">
          <div className="payment-section">
            <div className="logo">
              <img src="/images/logo/color-full.svg" alt="Logo" />
            </div>
            <div className="stripe-payment-container">
              {invoiceStatus.current === 'paid' ? (
                <InvoicePaidView />
              ) : (
                <StripePayment 
                  locale={locale}
                  balance={owing}
                  currency={currency}
                  clinicId={params?.clinicId}
                  invoiceId={params?.invoiceId}
                  onPaymentSuccess={onPaymentSuccess}
                />
              )}
            </div>
          </div>
          <InvoiceSummarySidebar 
            invoice={invoice}
            lineItems={lineItems}
          />
        </div>
      </Activity>
    </div>
  );
};

export default InvoicePayment;
