import React from 'react';
import Strings from './lang';

const makePlyometricsStageData = (options = {}) => {
  const {
    title = '',
    heart_rate = '',
    symptoms = '',
    directions = [],
    rest = ''
  } = options;

  return {
    title,
    heart_rate,
    symptoms,
    directions,
    rest
  };
};

const getPlyometricsHurdleHopsStageData = (fullTest) => {
  const title = Strings.plyometricsHurdleHopsStageLabelText;
  const directions = (fullTest 
    ? Strings.plyometricsHurdleHopsStageDirectionsText 
    : Strings.plyometricsHurdleStepsStageDirectionsText
  ).split('\n').concat(
    <strong>{Strings.plyometricsHurdleHopsStageRepeatText}</strong>
  );
  const rest = Strings.plyometricsHurdleHopsStageRestText;
  return makePlyometricsStageData({ title, directions, rest });
};

const getPlyometricsBurpeesStageData = (fullTest) => {
  const title = Strings.plyometricsBurpeesStageLabelText;
  const directions = (fullTest 
    ? Strings.plyometricsBurpeesStageDirectionsText 
    : Strings.plyometricsBurpeesStageModifiedDirectionsText
  ).split('\n').concat(
    <strong>{Strings.plyometricsBurpeesStageRepeatText}</strong>
  );
  const rest = Strings.plyometricsBurpeesStageRestText;
  return makePlyometricsStageData({ title, directions, rest });
};

const getPlyometricsBoxJumpsStageData = (fullTest) => {
  const title = Strings.plyometricsBoxJumpsStageLabelText;
  const directions = (fullTest 
    ? Strings.plyometricsBoxJumpsStageDirectionsText 
    : Strings.plyometricsBoxJumpsModifiedStageDirectionsText
  ).split('\n').concat(
    <strong>{Strings.plyometricsBoxJumpsStageRepeatText}</strong>
  );
  const rest = Strings.plyometricsBoxJumpsStageRestText;
  return makePlyometricsStageData({ title, directions, rest });
};

const getPlyometricsRotaryJumpsStageData = (fullTest) => {
  const title = Strings.plyometricsRotaryJumpsStageLabelText;
  const directions = Strings.plyometricsRotaryJumpsStageDirectionsText
    .split('\n')
    .concat(
      <strong>
        {fullTest 
          ? Strings.plyometricsRotaryJumpsStageRepeatText 
          : Strings.plyometricsRotaryJumpsModifiedStageRepeatText}
      </strong>
    );
  const rest = Strings.plyometricsRotaryJumpsStageRestText;
  return makePlyometricsStageData({ title, directions, rest });
};

const getPlyometricsData = (fullTest) => {
  return {
    hurdle_hops: getPlyometricsHurdleHopsStageData(fullTest),
    burpees: getPlyometricsBurpeesStageData(fullTest),
    box_jumps: getPlyometricsBoxJumpsStageData(fullTest),
    rotary_jumps: getPlyometricsRotaryJumpsStageData(fullTest)
  };
};

export {
  getPlyometricsData
};
