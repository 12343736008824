import React from 'react';
import { MobileLinks } from '@/utilities/mobile-detector';

const MobileAppLink = (props) => {
  const { platform } = props;
  const mobile = MobileLinks[platform];
  return (
    <a 
      target="_blank" 
      rel="noopener noreferrer"
      className={`app-link ${props.platform}`} 
      href={mobile.link}
    >
      <img src={mobile.img} alt={`${platform} store`} />
    </a>
  );
};

const IOSLink = () => (
  <MobileAppLink platform="ios" />
);

const AndroidLink = () => (
  <MobileAppLink platform="android" />
);

const AppLinks = {
  iOS: IOSLink,
  Android: AndroidLink
};

export {
  AppLinks,
  MobileAppLink
};
