const FR = {
  voms: 'Test vestibulo-oculaire moteur (VOMS)',
  buffalo: 'Test de Buffalo',
  blackhawks: 'Test des Blackhawks',
  ovs: 'Test orthostatique des signes vitaux',
  soapTab: 'clinique',
  diagnosisTab: 'Diagnostic',
  postInjury: 'Test post-blessure',
  cervical_soft_tissue: 'Thérapie des tissus mous',
  cervical_manipulation: 'Manipulation',
  cervical_mobilization: 'Mobilisations',
  vestibular_balance_rehab: 'Réadaptation vestibulaire/d’équilibre',
  visual_rehab: 'Réadaptation visuelle',
  education_reassurance: 'Instruction/Apaisement',
  home_exercise: 'Programme d\'exercices à domicile',
  other_treatment: 'Autre traitement offert',
  injuryStageInfo: 'Étape à laquelle le patient se trouvera lors de la prochaine visite',
  reloadSoapTitle: 'Recharger les remarques de clinique',
  reloadButtonText: 'Recharger',
  reloadModalInfoText: 'Cliquer sur Recharger pour recharger le {0} complété dans les remarques de clinique',
  symptomsTitle: 'Symptômes',
  symptomsTotalText: 'NO DE SYMPTÔME',
  symptomsSeverityText: 'SÉVÉRITÉ DU SYMPTÔME',
  saveSectionButtonText: 'Sauvegarder la section et continuer',
  soapNotesTitle: 'Remarques cliniques',
  saveSoapButtonText: 'Sauvegarder et fermer les remarques de clinique',
  notesSectionTitle: 'Remarques',
  injuryStageSectionTitle: 'Étape de récupération',
  recoveryStageFormTitle: 'Définir les étapes de récupération',
  changeDiagnosisButtonText: 'Changer le diagnostic',
  enterNotesPlaceholderText: 'Écrire les remarques ici',
  subjectiveLabel: 'Remarques subjectives',
  subjectiveNavLabel: 'Subjectives',
  objectiveLabel: 'Remarques objectives',
  objectiveNavLabel: 'Objectives',
  assessmentLabel: 'Remarques d’évaluation',
  assessmentNavLabel: 'Évaluation',
  planLabel: 'Notes sur le traitement et le plan',
  planNavLabel: 'Treatment & Plan',
  defaultDiagnosis: 'Aucun diagnostic',
  removeSymptomsText: 'Enlever un résultat du symptôme',
  addSymptomScoreText: 'Ajouter un résultat du symptôme',
  soapNotesListSubtitle: 'Remarques de clinique pour la blessure en date du {0}',
  soapNotesListEmptyMessage: 'Aucune remarque de clinique n’a été créée pour cette blessure.',
  startSoapNotesLinkText: 'Nouvelle note clinique',
  actionsHeadingText: 'Actions rapides',
  performTestDropdownTab: 'Effectuer le test',
  reviewAndCloseNoteTab: 'Vérifier et fermer la remarque',
  reviewNoteTab: 'Vérifier les remarques',
  noSymptomsRecordedText: 'Aucun résultat de symptôme enregistré',
  closeNoteButtonText: 'Fermer les remarques de clinique',
  currentRecoveryStageLabel: 'Étape de récupération actuelle',
  previousRecoveryStageLabel: 'Étape de récupération précédente',
  noPreviousRecoveryStageText: 'Aucune étape de récupération précédente',
  noRecoveryStage: 'Aucune étape de récupération actuelle',
  confirmSymptomsIncompleteTitle: 'Symptômes incomplets',
  confirmSymptomsIncompleteText: 'Vous n’avez pas encore complété un Résultat de symptôme. Voulez-vous continuer?',
  confirmRouteLeaveTitle: 'Laisser clinique Note?',
  confirmRouteLeaveText: 'Cette remarque de clinique n’a pas été fermée et restera donc ouverte. Voulez-vous continuer?',
  incompleteText: 'Incomplet',
  continueNoteText: 'Continuer la remarque',
  confirmButtonTitle: 'Oui, continuer',
  cancelButtonTitle: 'Non, rester',
  currentDiagnosisLabel: 'Diagnostic actuel:',
  soapNoteModalHeader: 'Remarques de clinique de {0}',
  noPreviousSoapsMessage: 'Aucune remarque de clinique antérieure',
  soapNoteHistoryTitle: 'Historique des notes clinique',
  previousSoapsDropdownButtonText: 'Recharger les remarques de clinique antérieures',
  injuryDiagnosisHeaderLabel: 'Diagnostic de la blessure',
  diagnosisProvidedHeaderLabel: 'Diagnostic fourni',
  recoveryStagePriorToVisitLabel: 'Étape de récupération avant le rendez-vous',
  sectionSavedMessage: 'Section enregistrée avec succès',
  standardSectionFormError: 'Cette section contient des erreurs. Veuillez revoir cette section',
  openAllButtonText: 'Ouvre tout',
  closeAllButtonText: 'Ferme tout',
  soapPanelHeaderText: 'clinique {0} - {1}',
  editText: 'Modifier',
  archiveText: 'Effacer',
  unarchiveText: 'Restaurer',
  createdByLabel: 'Créé par {0}',
  unknownNameLabel: 'Inconnu',
  testPassedText: 'Passé',
  testFailedText: 'Échoué',
  buffaloTestBadgeTitle: 'Buffalo Treadmill Tester - {0}',
  blackhawksTestBadgeTitle: 'Blackhawks Tester - {0}',
  vomsTestBadgeTitle: 'VOMS Tester',
  postInjuryTestBadgeTitle: 'Post-Injury Tester',
  symptomScoreBadgeTitle: 'Score de gravité des symptômes - {0}',
  seeBelowText: 'voir ci-dessous',
  failedWithHeartRateText: 'Échec aux HR {0} BPM',
  diagnosisChangedText: 'Diagnostic changé à',
  noPreviousSoapNotesMessage: 'Aucune note clinique précédente',
  soapListErrorMessage: 'Erreur lors du chargement des notes clinique',
  archivedByMessage: 'Supprimé par {0} le {1}',
  confirmArchiveSoapNoteTitle: 'Supprimer la note clinique',
  confirmArchiveSoapNoteMessage: 'En sélectionnant «Confirmer», cette note clinique sera supprimée du dossier patient pour cette blessure. Tous les scores de symptômes enregistrés, les tests effectués et les changements d\'étape de récupération dans cette note clinique seront également supprimés du dossier des patients. Vous pouvez annuler cette action à partir de la section Notes clinique archivées',
  archivedSuccessMessage: 'Note clinique supprimée avec succès!',
  archivedErrorMessage: 'Échec de la suppression de la note clinique: {0}',
  unarchiveSuccessMessage: 'Note clinique restaurée avec succès!',
  unarchivedErrorMessage: 'Échec de la restauration de la note clinique: {0}',
  archivedSoapNotesEmptyMessage: 'Aucune note clinique n\'a été supprimée pour cette blessure',
  archivedSoapNotesListTitle: 'Notes cliniques supprimées',
  archivedActionText: 'Remarques supprimées',
  noteCreatorIndicatorText: 'Note clinique créée par vous',
  openNotesAlertTitle: '{0} note(s) cliniques ouvertes',
  openNotesAlertMessage: 'Vous avez actuellement une ou plusieurs notes cliniques ouvertes:',
  completeOpenNotesMessage: 'Veuillez compléter les notes avant de commencer une nouvelle.',
  closeButtonText: 'Fermer'
};
 
export default FR;
