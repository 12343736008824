const EN = {
  dateLabelText: 'Date',
  dateErrorText: 'Please enter a valid date.',
  emailLabelText: 'Email',
  emailErrorText: 'Please enter a valid email address.',
  genderLabelText: 'Gender',
  genderErrorText: 'Please select your gender.',
  genderNoValueText: 'Select Gender',
  languageLabelText: 'Language',
  languageErrorText: 'Please select your preferred language.',
  languageSelectValueText: 'Please Select',
  passwordLabelText: 'Password',
  passwordErrorText: 'Please enter your password.',
  confirmPasswordLabelText: 'Confirm Password',
  confirmPasswordErrorText: 'Your passwords must match.',
  passwordScore0Text: 'Very Weak',
  passwordScore1Text: 'Weak',
  passwordScore2Text: 'Moderate',
  passwordScore3Text: 'Strong',
  passwordScore4Text: 'Very Strong',
  phoneLabelText: 'Phone',
  phoneErrorText: 'Please enter a valid phone number.',
  urlLabelText: 'Website',
  urlErrorText: 'Please enter a valid website URL.',
  selectLabelText: 'Select',
  selectErrorText: 'Please select an option.',
  numberLabelText: 'Number',
  numberErrorText: 'Please enter a valid number',
  countryLabelText: 'Country',
  countryErrorText: 'Please select a country.',
  guardianNoValueText: 'Are you a guardian?',
  guardianYesLabel: 'Yes',
  guardianNoLabel: 'No',
  guardianErrorText: 'Please select a section',
  guardianLabelText: 'Guardian',
  countrySelectDefaultOptionText: 'Select Country',
  regionSelectDefaultOptionText: 'Select Region'
};

export default EN;
