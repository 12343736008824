const UPDATE_SYMPTOMS_UPDATES = 'UPDATE_SYMPTOMS_UPDATES';

const updateSymptomsUpdates = (userId, injuryId, symptomsUpdatesList) => ({
  type: UPDATE_SYMPTOMS_UPDATES,
  userId,
  injuryId,
  symptomsUpdatesList
});

export {
  UPDATE_SYMPTOMS_UPDATES,
  updateSymptomsUpdates
};
