import { UPDATE_SPECIALIST_NOTE, UPDATE_SPECIALIST_NOTES } from '../actions/specialist-notes';

const updateSpecialistNoteReducer = (state = {}, action = {}) => {
  const { userId, injuryId, note = {} } = action;
  const { id } = note;
  const userState = state[userId] || {};
  const nextUserState = (id) ? { ...userState, [id]: { ...note, injuryId } } : userState;
  return (userId) ? { ...state, [userId]: nextUserState } : state;
};

const updateSpecialistNotesReducer = (state = {}, action = {}) => {
  const { userId, injuryId, notes = [] } = action;
  const nextUserState = notes.reduce((userState, note) => {
    const { id = 0 } = note;
    return (id) ? { ...userState, [id]: { ...note, injuryId } } : userState;
  }, state[userId] || {});

  return (userId) ? { ...state, [userId]: nextUserState } : state;
};

const specialistNotes = (state = {}, action = {}) => {
  switch (action.type) {
    case UPDATE_SPECIALIST_NOTE:
      return updateSpecialistNoteReducer(state, action);
    case UPDATE_SPECIALIST_NOTES:
      return updateSpecialistNotesReducer(state, action);
    default:
      return state;
  }
};

export default specialistNotes;
