import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { confirmation } from '../../confirmation';
import { StatusStrings, StatusLabelType } from './utils';
import { DISPLAY_DATE_FORMAT } from '@/utilities/dates/formats';
import { UserSelector } from '@/redux/selectors';
import Strings from '../lang';
import Icon from '../../icon';

const ReferralCardHeader = ({
  status,
  date,
  referrer,
  onCancel
}) => {
  const currentUser = useSelector(UserSelector.getCurrentUser);

  const handleCancel = useCallback(() => {
    confirmation(Strings.cancelModalMessage, {
      title: Strings.cancelModalTitle,
      onConfirm: onCancel
    });
  }, [onCancel]);

  return (
    <div className="referral-card-header">
      <div className="referral-date">
        {dayjs(date).format(DISPLAY_DATE_FORMAT)}
      </div>
      <div className="referral-status">
        <span className={`label label-${StatusLabelType[status]}`}>
          {StatusStrings[status]}
        </span>
        {status === 'pending' && referrer.id === currentUser.id && (
          <button type="button" className="btn-cancel" onClick={handleCancel}>
            <Icon name="xmark" />&nbsp;
            {Strings.cancelReferralButtonText}
          </button>
        )}
      </div>
    </div>
  );
};

export default ReferralCardHeader;
