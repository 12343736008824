import React, { useMemo } from 'react';
import ReferralsList from './referrals-list';
import Tabs from '../tabs';
import Strings from './lang';

const groupReferrals = (referrals = []) => {
  return referrals.reduce((acc, referral) => {
    const { [referral.status]: refs = [] } = acc;

    return {
      ...acc,
      [referral.status]: [
        ...refs,
        referral
      ]
    };
  }, {
    pending: [],
    accepted: [],
    rejected: [],
    resolved: [],
    cancelled: []
  });
};

const TabHeader = ({ label, type, count }) => {
  return (
    <div className="referral-status">
      <span>{label}</span>
      <span className={`badge badge-${type}`}>
        {count}
      </span>
    </div>
  );
};

const Referrals = ({ referrals, emptyMessage }) => {
  if (!referrals.length) {
    return (
      <p>{emptyMessage}</p>
    );
  }

  return (
    <ReferralsList
      colums={1}
      linksEnabled={false}
      referrals={referrals}
    />
  );
};

const ReferralTabs = ({ referrals = [], linksEnabled = true }) => {
  const grouped = useMemo(() => groupReferrals(referrals), [referrals]);

  return (
    <Tabs
      vertical
      className="referrals-tabs"
      items={[
        {
          key: 'pending',
          label: (
            <TabHeader
              label={Strings.pendingTabLabel}
              type="warning"
              count={grouped.pending.length}
            />
          ),
          component: (
            <Referrals
              referrals={grouped.pending}
              linksEnabled={linksEnabled}
              emptyMessage={Strings.formatString(
                Strings.emptyTabContent,
                Strings.pendingTabLabel.toLowerCase()
              )}
            />
          )
        },
        {
          key: 'accepted',
          label: (
            <TabHeader
              label={Strings.acceptedTabLabel}
              type="success"
              count={grouped.accepted.length}
            />
          ),
          component: (
            <Referrals
              referrals={grouped.accepted}
              linksEnabled={linksEnabled}
              emptyMessage={Strings.formatString(
                Strings.emptyTabContent,
                Strings.acceptedTabLabel.toLowerCase()
              )}
            />
          )
        },
        {
          key: 'resolved',
          label: (
            <TabHeader
              label={Strings.resolvedTabLabel}
              type="default"
              count={grouped.resolved.length}
            />
          ),
          component: (
            <Referrals
              referrals={grouped.resolved}
              linksEnabled={linksEnabled}
              emptyMessage={Strings.formatString(
                Strings.emptyTabContent,
                Strings.resolvedTabLabel.toLowerCase()
              )}
            />
          )
        },
        {
          key: 'rejected',
          label: (
            <TabHeader
              label={Strings.rejectedTabLabel}
              type="error"
              count={grouped.rejected.length}
            />
          ),
          component: (
            <Referrals
              referrals={grouped.rejected}
              linksEnabled={linksEnabled}
              emptyMessage={Strings.formatString(
                Strings.emptyTabContent,
                Strings.rejectedTabLabel.toLowerCase()
              )}
            />
          )
        },
        {
          key: 'cancelled',
          label: (
            <TabHeader
              label={Strings.cancelledTabLabel}
              type="error"
              count={grouped.cancelled.length}
            />
          ),
          component: (
            <Referrals
              referrals={grouped.cancelled}
              linksEnabled={linksEnabled}
              emptyMessage={Strings.formatString(
                Strings.emptyTabContent,
                Strings.cancelledTabLabel.toLowerCase()
              )}
            />
          )
        }
      ]}
    />
  );
};

export default ReferralTabs;
