import {
  jsonApiRead,
  jsonApiCreate,
  jsonApiUpdate
} from '@/lib/requests/jsonapi';
import { updateUser, updateCurrentUserId } from '../actions/users';
import { updateGuardianAuth } from '../actions/guardians-account';
import { CONFIRMATION_URL } from '@/utilities/urls';
import { setRegistrationSession, getCurrentRegistrationSession } from '@/utilities/registration-session';

const getCurrentUserAsync = (options = {}) => {
  return (dispatch) => {
    return jsonApiRead({ type: 'users' }, { path: 'account', ...options }).then(user => {
      dispatch(updateUser(user));
      dispatch(updateCurrentUserId(user.id));
      return Promise.resolve(user);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const getUserAsync = (id, options = {}) => {
  return (dispatch) => {
    return jsonApiRead({ type: 'users', id }, options).then(user => {
      dispatch(updateUser(user));
      return Promise.resolve(user);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const createUserAsync = (userAttributes = {}, options = {}) => {
  // Inject the confirmation url into all user create calls:
  const attributes = { ...userAttributes, confirmation_url: CONFIRMATION_URL };
  return (dispatch) => {
    return jsonApiCreate({ type: 'users', attributes }, options).then(user => {
      if (options.registrationAuth) {
        setRegistrationSession(user);
      }
      
      if (userAttributes.role === 'guardian') {
        dispatch(updateGuardianAuth(user));
      }
      
      dispatch(updateUser(user));
      return Promise.resolve(user);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const updateUserAsync = (id, userAttributes = {}, options = {}) => {
  // Inject the confirmation url into all user update calls:
  const attributes = { ...userAttributes, confirmation_url: CONFIRMATION_URL };
  return (dispatch) => {
    return jsonApiUpdate({ type: 'users', id, attributes }, options).then(user => {
      if (userAttributes.role === 'guardian') {
        dispatch(updateGuardianAuth(user));
      }

      dispatch(updateUser(user));

      return Promise.resolve(user);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const activateUserAsync = (id, attributes = {}) => {
  return (dispatch) => {
    return jsonApiUpdate({ type: 'users', id, attributes }, { path: `users/${id}/activate` }).then(user => {
      dispatch(updateUser(user));
      return Promise.resolve(user);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const completeRegistrationAsync = (isReturn, email, options = {}) => {
  const queryParams = { email };
  const path = isReturn ? 'users/renewal' : 'users/completion';
  return () => {
    return jsonApiCreate({ type: 'users' }, { path, queryParams, ...options }).then(() => {
      return Promise.resolve();
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const removeUserFromClinicAsync = (clinicId, attributes) => {
  return (dispatch) => {
    return jsonApiCreate({ type: 'clinics', attributes }, { path: `clinics/${clinicId}/remove_user` }).then(user => {
      dispatch(updateUser(user));
      return Promise.resolve(user);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const registrationUpdateUserAsync = (id, userAttributes = {}) => {
  const options = { headers: { Authorization: getCurrentRegistrationSession().token } };
  return (dispatch) => {
    return dispatch(updateUserAsync(id, userAttributes, options));
  };
};

const decoupleChildAccountAsync = (id, attributes, options = {}) => {
  return (dispatch) => {
    return jsonApiUpdate({ type: 'users', id, attributes }, { path: `users/${id}/decouple`, ...options }).then((user) => {
      dispatch(updateUser(user));
      return Promise.resolve(user);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const convertToGuardianAsync = (attributes = {}, options = {}) => {
  const opts = { path: 'users/convert/guardian', ...options };
  return (dispatch) => {
    return jsonApiCreate({ type: 'users', attributes }, opts).then(user => {
      setRegistrationSession(user);
      dispatch(updateGuardianAuth(user));
      dispatch(updateUser(user));
      return Promise.resolve(user);
    });
  };
};

export {
  getUserAsync,
  getCurrentUserAsync,
  createUserAsync,
  updateUserAsync,
  activateUserAsync,
  completeRegistrationAsync,
  removeUserFromClinicAsync,
  registrationUpdateUserAsync,
  decoupleChildAccountAsync,
  convertToGuardianAsync
};
