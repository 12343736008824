import React, { useMemo } from 'react';
import { Dot } from 'recharts';
import Icon from '../../icon';

const ICON_SIZE = 14;

const isSuspectTest = (value, mean, stdev) => {
  const lower = (mean - (stdev * 2));

  return !isNaN(lower) && value < lower;
};

const DotIcon = ({
  cx,
  cy,
  payload,
  mean,
  stdev,
  value,
  ...rest
}) => {
  const isSuspect = useMemo(() => (
    isSuspectTest(value, mean, stdev)
  ), [mean, stdev, value]);

  if (payload.post_injury) {
    return (
      <Icon
        prefix="fas"
        name={isSuspect ? 'triangle-exclamation' : 'bolt'}
        x={cx - (ICON_SIZE / 2)}
        y={cy - (ICON_SIZE / 2)}
        color="#DB231F"
        height={ICON_SIZE}
        width={ICON_SIZE}
        style={{ zIndex: 10 }}
      />
    );
  }

  return (
    <Dot
      value={value}
      cx={cx}
      cy={cy}
      payload={payload}
      {...rest}
    />
  );
};

export default DotIcon;
