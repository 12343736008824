const UPDATE_DANA_BATTERY = 'UPDATE_DANA_BATTERY';

const updateDanaBattery = (userId, battery) => {
  return {
    type: UPDATE_DANA_BATTERY,
    userId,
    battery
  };
};

export {
  UPDATE_DANA_BATTERY,
  updateDanaBattery
};
