import React from 'react';
import Card from '../card';
import BookingLinkActions from './booking-link-actions';
import Strings from './lang';

const BookingLink = ({ 
  link, 
  userId,
  clinics, 
  editable 
}) => {
  const { url, clinic = {} } = link || {};
  return (
    <Card className="booking-link-card">
      <div className="booking-link-card-title">{clinic.name}</div>
      {editable && (
        <div className="booking-link-actions">
          <BookingLinkActions 
            userId={userId}
            clinics={clinics}
            link={link}
          />
        </div>
      )}
      <div>
        <span>{Strings.urlInputLabel}:</span>&nbsp;
        <a 
          href={url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {url}
        </a>
      </div>
    </Card>
  );
};

export default BookingLink;
