import React from 'react';
import PropTypes from 'prop-types';
import { If, Then, Else } from 'react-if';
import Languages from '@/utilities/languages';
import { PatientName } from '../patient-meta';
import GenderStrings from '@/utilities/gender';
import Strings from './lang';

const PlayerPersonalInfoCard = ({
  user = {}, 
  clinicId = 0
}) => {
  const person = user.person || {};
  return (
    <div className="card card-show">

      <div className="card-row">
        <label>{ Strings.nameLabelText }:</label>
        <PatientName patient={user} clinicId={clinicId} />
      </div>

      <div className="card-row">
        <label>{ Strings.birthdateLabelText }:</label>
        <span>{ person.birthday }</span>
      </div>

      <div className="card-row">
        <label>{ Strings.genderLabelText }:</label>
        <If condition={person.gender !== 'unspecified'}>
          <Then>
            <span>{ GenderStrings[person.gender] }</span>
          </Then>
          <Else>
            <span>{ person.gender_other }</span>
          </Else>
        </If>
      </div>

      <div className="card-row">
        <label>{ Strings.accountNumberLabelText }:</label>
        <span>{ user.account }</span>
      </div>

      {user.bag_tag ? (
        <div className="card-row">
          <label>{ Strings.bagTagLabelText }:</label>
          <span>{ user.bag_tag }</span>
        </div>
      ) : null}

      <div className="card-row">
        <label>{ Strings.languageLabelText }:</label>
        <span>{ Languages[person.language] }</span>
      </div>

    </div>
  );
};

PlayerPersonalInfoCard.propTypes = {
  user: PropTypes.object.isRequired,
  clinicId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired
};

export default PlayerPersonalInfoCard;
