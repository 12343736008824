import React, { useMemo } from 'react';
import classnames from 'classnames';

const BodyRow = ({
  index = 0,
  columns,
  item,
  isSelected = false,
  selectable = false,
  onSelect
}) => {
  const props = useMemo(() => (
    selectable ? {
      tabIndex: 0,
      role: 'button',
      className: classnames({ selected: isSelected }),
      onKeyPress: (e) => e.key === 'Enter' && onSelect(item, index),
      onClick: () => onSelect(item, index)
    } : {}
  ), [index, isSelected, item, onSelect, selectable]);

  return (
    <tr {...props}>
      {columns.map(column => (
        <td key={column.key}>
          {column.render
            ? column.render(item[column.key], index, item)
            : item[column.key]}
        </td>
      ))}
    </tr>
  );
};

const DataTable = ({
  className,
  columns = [],
  selected,
  keyExtractor = () => null,
  data = [],
  onSelect,
}) => {
  const selectable = !!onSelect && typeof onSelect === 'function';

  return (
    <table className={classnames('table', 'data-table', className, { selectable })}>
      <thead>
        <tr>
          {columns.map(column => (
            <th key={column.key} className="no-action">
              {column.label}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => {
          const key = keyExtractor(item, index) || index;

          return (
            <BodyRow 
              key={key}
              columns={columns}
              item={item}
              index={index}
              selectable={selectable}
              isSelected={selected === key}
              onSelect={onSelect}
            />
          );
        })}
      </tbody>
    </table>
  );
};

export default DataTable;
