import React, { useCallback, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { If, Then, Else } from 'react-if';
import { createSessionAsync } from '@/redux/thunks/sessions';
import { getRememberedUsers, removeRememberedUser } from '@/utilities/email-storage';
import HTTPStatusCodes from '@/utilities/http-status-codes';
import {
  DashboardPaths,
  AuthenticationPaths,
  RegistrationPaths,
  ClinicUserOnboardingPaths
} from '@/paths';
import { Page } from '../page';
import { ErrorBanner, ErrorStrings } from '../errors';
import AuthenticationForm from './authentication-form';
import RememberedUsersAuthentication from './remembered-users-authentication';
import Activity from '../activity';
import { RoleDescriptions, userHasRoleMatchingOneOfDescriptions } from '@/utilities/user-roles';
import Strings from './lang';

/**
 * To Disable any 'Remember Me' functionality (remembered users list and remember me button), 
 * set this variable to false,
 */
const REMEMBER_ME_ENABLED = true;
const CCMI_URL = 'https://completeconcussions.com/';

const isProfileComplete = (user) => {
  const isClinician = userHasRoleMatchingOneOfDescriptions(
    user,
    [RoleDescriptions.Clinician, RoleDescriptions.Specialist, RoleDescriptions.IDTClinician]
  );

  if (
    (!user?.person?.first_name || !user?.person?.last_name)
    || (isClinician && !user?.clinical_info?.profession)
  ) {
    return false;
  }

  return true;
};

const next = (user, navigate, location) => {
  const { nextPathname } = location?.state || {};

  if (!nextPathname && !isProfileComplete(user)) {
    navigate(ClinicUserOnboardingPaths.index.link, {
      replace: true,
      state: {
        profileIncomplete: true,
        nextPathname: user.clinics?.length > 1
          ? AuthenticationPaths.clinics.link
          : DashboardPaths.index.link
      }
    });
  } else if (user.clinics && user.clinics.length > 1) {
    navigate(AuthenticationPaths.clinics.link, { state: { nextPathname }, replace: true });
  } else if (nextPathname) {
    navigate(nextPathname, { replace: true });
  } else {
    navigate(DashboardPaths.index.link, { replace: true });
  }
};

const Authentication = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [rememberedUsers, setRememberedUsers] = useState(() => getRememberedUsers());
  const [newLogin, setNewLogin] = useState(rememberedUsers.length === 0);
  const [activity, setActivity] = useState(false);
  const [error, setError] = useState(null);

  const authenticate = useCallback((attributes, rememberEmail = false) => {
    setActivity(true);
    setError(null);

    dispatch(createSessionAsync(attributes, { rememberEmail })).then((user) => {
      setActivity(false);
      next(user, navigate, location);
    }).catch(error => {
      let { message } = error;
      if (error.status === HTTPStatusCodes.Unauthorized) {
        message = ErrorStrings.error401Signin;
      }

      setActivity(false);
      setError(message);
    });
  }, [dispatch, location, navigate]);

  const onNewLogin = useCallback(() => {
    setError(null);
    setNewLogin(true);
  }, []);

  const onRemoveRememberedUser = useCallback((user) => {
    if (removeRememberedUser(user)) {
      const users = getRememberedUsers();
      setRememberedUsers(users);
      setNewLogin(users.length === 0);
      setError(null);
    }
  }, []);

  return (
    <Page
      hideNavigationBar
      showHeaderImage
      hideBannerIfClinicDeactivated
      className="authentication"
    >
      <div className="row">
        <div className="col-md-4 col-md-offset-4">
          <h1 className="display dot">{Strings.title}</h1>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4 col-md-offset-4 patient-registration-info">
          <small>
            {Strings.areYouAPatientText}&nbsp;
            <Link to={RegistrationPaths.index.link} className="semi-bold">
              {Strings.registerHereLinkText}
            </Link>
          </small>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4 col-md-offset-4">
          <ErrorBanner error={error} />
          <Activity active={activity}>
            <If condition={!newLogin && REMEMBER_ME_ENABLED}>
              <Then>
                <RememberedUsersAuthentication
                  rememberedUsers={rememberedUsers}
                  onRemoveUser={onRemoveRememberedUser}
                  onAuthenticate={authenticate}
                  onNewLogin={onNewLogin}
                />
              </Then>
              <Else>
                <AuthenticationForm
                  displayRememberMe={REMEMBER_ME_ENABLED}
                  onSubmit={authenticate}
                />
              </Else>
            </If>
          </Activity>
          <p className="authentication-sales">
            <small
              dangerouslySetInnerHTML={{
                // Set the link tags as strings and inject as HTML to avoid escaing:
                __html: Strings.formatString(Strings.salesText, `<a href="${CCMI_URL}" target="_blank">`, '</a>')
              }}
            />
          </p>
        </div>
      </div>
    </Page>
  );
};

export default Authentication;
