import React, {
  useEffect, useState, useRef, useCallback 
} from 'react';
import ConfirmationModal from './confirmation-modal';
import confirmation, { SHOW_EVENT } from './events';

const useConfirmationEventListener = (cb = () => {}) => {
  useEffect(() => {
    document.addEventListener(SHOW_EVENT, cb);
    return () => {
      document.removeEventListener(SHOW_EVENT, cb);
    };
  }, [cb]);
};

const Confirmation = () => {
  const [open, setOpen] = useState(false);
  const options = useRef({});

  const handleEvent = useCallback((e) => {
    e.stopImmediatePropagation();

    const { options: opts = {} } = e.detail || {};
    if (!open) {
      options.current = opts || {};
      setOpen(true);
    }
  }, [open]);

  const handleCancel = useCallback(() => {
    const { onCancel = () => {} } = options.current;
    setOpen(false);
    onCancel();
  }, []);

  const handleConfirm = useCallback(() => {
    const { onConfirm = () => {} } = options.current;
    setOpen(false);
    onConfirm();
  }, []);

  useConfirmationEventListener(handleEvent);

  return (
    <ConfirmationModal 
      {...options.current}
      isOpen={open}
      onConfirm={handleConfirm}
      onCancel={handleCancel}
      onClose={() => setOpen(false)}
    />
  );
};

export default Confirmation;
export { confirmation, ConfirmationModal };
