import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import Modal from '../modal';
import Activity from '../activity';
import { ErrorBanner } from '../errors';
import ClinicInviteUserForm from './clinic-invite-user-form';
import { showAlert } from '../alert-notifications';
import RoleStrings from '@/utilities/role-strings';
import Strings from './lang';
import { createInviteAsync } from '@/redux/thunks/invites';
import Icon from '../icon';

const showInviteSuccessMessage = (user, email, role) => {
  const { person = {} } = user || {};

  showAlert('success', {
    autoDismiss: 6000, /** 6 seconds */
    dismissable: true,
    message: person ? Strings.formatString(
      Strings.clinicUserRoleAddedSuccessMessage,
      `${person.first_name} ${person.last_name}`,
      RoleStrings[role]
    ) : Strings.formatString(
      Strings.clinicUserInviteSuccessMessage,
      email
    )
  });
};

const InviteClinicUserModal = ({ 
  isOpen = false, 
  clinic,
  onClose = () => {}
}) => {
  const dispatch = useDispatch();
  const [activity, setActivity] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = useCallback((attributes) => {
    setActivity(true);
    setError(null);

    dispatch(createInviteAsync(attributes)).then(user => {
      setActivity(false);
      showInviteSuccessMessage(user, attributes.email, attributes.role);
      onClose();
    }).catch(error => {
      setError(error.message);
      setActivity(false);
    });
  }, [dispatch, onClose]);

  return (
    <Modal
      blur
      className="invite-clinic-user-modal"
      isOpen={isOpen}
      onClose={activity ? undefined : onClose}
    >
      <Modal.Header>
        {!activity && (
          <button type="button" className="close" onClick={onClose}>
            <Icon name="xmark" />
          </button>
        )}
        <h1>{Strings.title}</h1>
      </Modal.Header>
      <Modal.Body>
        <ErrorBanner error={error} />
        <Activity active={activity}>
          <ClinicInviteUserForm 
            clinic={clinic}
            onSubmit={handleSubmit}
          />
        </Activity>
      </Modal.Body>
    </Modal>
  );
};

export default InviteClinicUserModal;
