import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BaselineStep from './baseline-step';
import Activity from '../../activity';
import { ErrorBanner } from '../../errors';

class BaselineStepWizard extends Component {
  constructor(props) {
    super(props);

    const {  
      initialStep,
      steps = []
    } = this.props;

    this.node = React.createRef();

    this.state = {
      steps,
      activity: false,
      activeStep: initialStep,
      error: null
    };
  }

  render() {
    const { activeStep, activity, steps } = this.state;
    const ActiveComponent = steps[activeStep].component;
    return (
      <Activity active={activity} static>
        <div ref={this.node} className="baseline-step-wizard steps-hidden">
          <div className="baseline-step-wizard-test">
            <ErrorBanner error={this.state.error} />
            <BaselineStep
              injectProps={this.props.injectProps}
              component={ActiveComponent}
              onContinue={(attributes) => this.onContinue(steps[activeStep].step, attributes)}
              onPrevious={() => this.onPreviousTest(steps[activeStep].step)}
              previousDisabled={activeStep === 0}
            />
          </div>
        </div>
      </Activity>
    );
  }

  onContinue(curStep, attributes) {
    this.setState({ activity: true });
    const nextStep = this.canContinue() 
      ? this.state.steps[this.state.activeStep + 1].step 
      : curStep;
    this.props.onStepAllowedToContinuePromise(curStep, attributes, nextStep).then(() => {
      this.next();
      this.setState({ activity: false });
    }).catch(error => {
      this.setState({
        error: error.message,
        activity: false
      });
    });
  }

  canContinue() {
    const { activeStep, steps } = this.state;
    return (activeStep + 1) < steps.length;
  }

  canGoBack() {
    return this.state.activeStep >= 1;
  }

  next() {
    this.scrollToTop();
    let { activeStep } = this.state;

    if (this.canContinue()) {
      activeStep += 1;
    } else {
      this.props.onStepsCompleted();
    }

    this.setState({
      activeStep
    });
  }

  onPreviousTest(curStep) {
    const prevStep = this.canGoBack() ? this.state.steps[this.state.activeStep - 1].step : curStep;
    this.prev();
    if (typeof this.props.onPrevious === 'function') {
      this.props.onPrevious(curStep, prevStep);
    }
  }

  prev() {
    this.scrollToTop();
    let { activeStep } = this.state;

    if (this.canGoBack()) {
      activeStep -= 1;
    } 

    this.setState({
      activeStep
    });
  }

  scrollToTop() {
    if (this.node.current) {
      this.node.current.scrollIntoView();
    }
  }
}

BaselineStepWizard.propTypes = {
  injectProps: PropTypes.object,
  steps: PropTypes.arrayOf(PropTypes.shape({
    step: PropTypes.string,
    component: PropTypes.any
  })),
  initialStep: PropTypes.number,
  onStepAllowedToContinuePromise: PropTypes.func.isRequired,
  onStepsCompleted: PropTypes.func.isRequired
};

export default BaselineStepWizard;
