const EN = {
  title: 'Clinics',
  creditsRemainingTitle: 'Credits Available',
  filterLabelText: 'Filter',
  emptyClinicsMessage: 'No clinics matched your search.',
  totalBalanceTitleLabel: 'Net Outstanding',
  currencyMixWarningText: 'May be a mixture of CAD and USD',
  invoicesTitleLabel: 'Invoices Outstanding',
  overdueInvoicesLabel: '{0} overdue',
  searchInputPlaceholder: 'Search by Clinic Name',
  activeItemLabel: 'Active',
  deactivatedItemLabel: 'Deactivated',
  clinicBalanceError: 'Error retrieving clinic balances: {0}'
};

export default EN;
