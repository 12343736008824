import React from 'react';
import PropTypes from 'prop-types';
import { If, Then, Else } from 'react-if';
import classNames from 'classnames';
import Icon from '../icon';

const ActivityIndicator = ({
  titleComponent
}) => (
  <div className="activity-indicator-container">
    <If condition={titleComponent !== undefined}>
      <Then>{titleComponent}</Then>
    </If>
    <Icon
      spinPulse
      prefix="fad"
      name="loader"
      className="activity-indicator"
    />
  </div>
);

const Activity = ({
  titleComponent,
  active = false,
  static: isStatic = false,
  children,
  className,
  style
}) => (
  <div className={classNames('activity', className)} style={style}>
    <div
      className={classNames('activity-container', {
        active,
        static: isStatic
      })}
    >
      <If condition={active && isStatic}>
        <Then>
          <ActivityIndicator
            titleComponent={titleComponent}
          />
        </Then>
        <Else>
          <>
            {children}
          </>
        </Else>
      </If>
    </div>
    {active && (
      <div
        className={classNames('activity-overlay', {
          visible: active && !isStatic
        })}
      >
        <ActivityIndicator
          titleComponent={titleComponent}
        />
      </div>
    )}
  </div>
);

Activity.propTypes = {
  active: PropTypes.bool.isRequired,
  static: PropTypes.bool,
  titleComponent: PropTypes.element
};

export default Activity;
