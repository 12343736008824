import React from 'react';
import { ErrorBanner } from '../../errors';
import Strings from './lang';

const StepNotFound = () => {
  return (
    <ErrorBanner error={Strings.stepNotFoundMessage} />
  );
};

export default StepNotFound;
