import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getInjuriesAsync, updateInjuryAsync } from '@/redux/thunks/injuries';
import { getUserAsync } from '@/redux/thunks/users';
import { Permissions } from '../patient-profile-utils';
import { ClinicSelector, InjurySelector, UserSelector } from '@/redux/selectors';
import { userHasRoleMatchingOneOfDescriptions, RoleDescriptions } from '@/utilities/user-roles';
import { sortDescending } from '@/utilities/sort';
import { InjuryHistory } from '../../injury-history';
import Activity from '../../activity';
import { ErrorBanner } from '../../errors';
import Strings from './lang';

const countableDiagnosisKeys = [
  'concussion_with_unconsciousness',
  'concussion_without_unconsciousness',
  'post_concussion_syndrome'
];

/**************************************************************************************************
 * NOTE - If this page is ever altered where medical info and injuries are not loaded on the same *
 * page or pagination is introduced to the injuries index component, the concussion count feature *
 * will need to be altered and API support will be required.                                      *
 **************************************************************************************************/

const getConcussionCount = (props) => {
  const { medicalInfo = {}, injuries = {} } = props;
  let previous = Strings.noneText;

  /** 
   * no injuries (either not an patient profile or 
   * hasn't been injured). Either way, 
   * no display is needed 
   */
  if (!injuries.length) {
    return null;
  }

  /** determine if there is a medical history and if a concussion has ever been diagnosed */
  if (medicalInfo && medicalInfo.concussion_ever_diagnosed) {
    previous = parseInt(medicalInfo.concussion_history.how_many, 10);
  }

  const withCCMI = injuries.reduce((acc, cur) => {
    const diagnosis = cur.diagnosis || {};
    return Object.keys(diagnosis).filter(key => {
      return diagnosis[key] === true && countableDiagnosisKeys.includes(key);
    }).length > 0 ? acc + 1 : acc;
  }, 0);

  const total = withCCMI + (!isNaN(previous) ? previous : 0);

  return {
    previous,
    withCCMI,
    total
  };
};

const PatientInjuriesInfo = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [activity, setActivity] = useState(false);
  const [error, setError] = useState(null);
  const clinic = useSelector(state => ClinicSelector.getClinic(state, { id: params.clinicId }));
  const currentUser = useSelector(UserSelector.getCurrentUser);
  const user = useSelector(state => UserSelector.getUser(state, { userId: params.userId }));
  const injuries = useSelector(state => (
    Object.values(InjurySelector.getUserInjuries(state, { userId: params.userId }))
      .sort(sortDescending.bind(null, 'injured_at'))
  ));
  const medicalInfo = useSelector(state => state.medicalInfo[params.userId]);
  const concussionCount = useMemo(() => (
    getConcussionCount({ injuries, medicalInfo })
  ), [injuries, medicalInfo]);

  const updateInjury = useCallback((injuryId, attributes) => {
    setActivity(true);
    setError(null);

    dispatch(updateInjuryAsync(params.userId, injuryId, attributes)).then(() => {
      return Promise.all([
        dispatch(getInjuriesAsync(params.userId)),
        dispatch(getUserAsync(params.userId))
      ]);
    }).then(() => {
      setActivity(false);
    }).catch(error => {
      setActivity(false);
      setError(error.message);
    });
  }, [dispatch, params.userId]);

  return (
    <Activity active={activity}>
      <div>
        <ErrorBanner error={error} />
        <InjuryHistory
          injuries={injuries}
          user={user}
          clinic={clinic}
          currentUser={currentUser}
          enableTestLinks={userHasRoleMatchingOneOfDescriptions(
            currentUser,
            Permissions.makeTestViewerRoles(params.clinicId)
          )}
          enableAssessmentLinks={userHasRoleMatchingOneOfDescriptions(
            currentUser,
            [RoleDescriptions.Clinician, RoleDescriptions.Specialist]
          )}
          onUpdateInjury={updateInjury}
          concussionCount={concussionCount}
        />
      </div>
    </Activity>
  );
};

export default PatientInjuriesInfo;
