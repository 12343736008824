import React, { useState } from 'react';
import Modal from '../../../../modal';
import DualTaskTrial from './trial';

const TrialModalBody = ({
  title,
  start,
  step,
  onDone,
  onClose
}) => {
  const [started, setStarted] = useState(false);

  return (
    <>
      <Modal.Header onClose={started ? null : onClose}>
        <h1>{title}</h1>
      </Modal.Header>
      <Modal.Body>
        <DualTaskTrial
          title={title}
          start={start}
          step={step}
          onStart={() => setStarted(true)}
          onReset={() => setStarted(false)}
          onClose={onClose}
          onDone={onDone}
        />
      </Modal.Body>
    </>
  );
};

const DualTaskTrialWalkthroughModal = ({
  title,
  start,
  step,
  isOpen = false,
  onClose,
  onDone
}) => {
  return (
    <Modal
      blur
      animation="fade"
      className="dual-task-trial-modal"
      isOpen={isOpen}
    >
      <TrialModalBody
        title={title}
        start={start}
        step={step}
        onClose={onClose}
        onDone={onDone}
      />
    </Modal>
  );
};

export default DualTaskTrialWalkthroughModal;
