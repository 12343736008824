import React, { useState } from 'react';
import dayjs from 'dayjs';
import { Else, If, Then } from 'react-if';
import Icon from '../../icon';
import { DocumentsUploadButton } from '../documents-upload';
import { DISPLAY_DATE_TIME_FORMAT } from '@/utilities/dates/formats';
import Strings from '../lang';
import { getReadableFileSize } from '@/utilities/files';
import Modal from '../../modal';

const DocumentModal = ({
  isOpen = false,
  url,
  fileName,
  onClose
}) => {
  return (
    <Modal
      blur
      fullscreen
      className="idt-document-modal full"
      isOpen={isOpen}
      onClose={onClose}
    >
      <Modal.Body>
        <iframe
          src={url}
          title={fileName}
          width="100%"
          height="100%"
        />
        <button type="button" className="btn-close" onClick={onClose}>
          <Icon name="xmark" />
        </button>
      </Modal.Body>
    </Modal>
  );
};

const DocumentLink = ({ fileName, url }) => {
  const [opened, setOpened] = useState(false);

  return (
    <>
      <button type="button" className="idt-document-list-link" onClick={() => setOpened(true)}>
        <Icon name="file-pdf" />&nbsp;
        {fileName}
      </button>
      <DocumentModal
        isOpen={opened}
        fileName={fileName}
        url={url}
        onClose={() => setOpened(false)}
      />
    </>
  );
};

const DocumentRow = ({ document }) => {
  return (
    <tr>
      <td width="55%">
        <DocumentLink fileName={document.file_name} url={document.url} />
      </td>
      <td align="right">
        <span className="text-muted">
          {dayjs(document.created_at).format(DISPLAY_DATE_TIME_FORMAT)}
        </span>
      </td>
      <td align="right">
        <span className="text-muted">
          {getReadableFileSize(document.file_size)}
        </span>
      </td>
    </tr>
  );
};

const ClaimDocumentsTab = ({ claimId, status, documents }) => {
  return (
    <section className="claim-section documents-section">
      <div className="claim-section-header">
        <div className="flex-row align_center">
          <Icon name="file-pdf" />
          <div className="claim-section-title">
            {Strings.documentsTitle} ({documents?.length ?? 0})
          </div>
        </div>
        {status !== 'discharged' && (
          <DocumentsUploadButton claimId={claimId} />
        )}
      </div>
      <div className="claim-section-content">
        <If condition={(documents?.length ?? 0) > 0}>
          <Then>
            <table width="100%" className="table idt-documents-table">
              <thead>
                <tr>
                  <th className="no-action">{Strings.fileNameColumnHeader}</th>
                  <th className="no-action">{Strings.updatedAtColumnHeader}</th>
                  <th className="no-action">{Strings.fileSizeColumnHeader}</th>
                </tr>
              </thead>
              <tbody>
                {documents.map(doc => (
                  <DocumentRow
                    key={doc.id}
                    document={doc}
                  />
                ))}
              </tbody>
            </table>
          </Then>
          <Else>
            <div>{Strings.emptyDocumentsMessage}</div>
          </Else>
        </If>
      </div>
    </section>
  );
};

export default ClaimDocumentsTab;
