import React from 'react';
import { Link } from 'react-router-dom';

const Item = ({
  active = false,
  link,
  children
}) => {
  if (active) {
    return (
      <li className="active">{children}</li>
    );
  }

  return (
    <li>
      <Link to={link}>
        {children}
      </Link>
    </li>
  );
};

const Breadcrumbs = ({
  items = []
}) => {
  return (
    <ol className="breadcrumb">
      {items.map(item => (
        <Item
          key={item.key}
          link={item.link}
          active={item.active}
        >
          {item.label}
        </Item>
      ))}
    </ol>
  );
};

export default Breadcrumbs;
