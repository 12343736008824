import React, { forwardRef, useMemo } from 'react';
import { BalanceTestingCard } from '../baseline-test-cards';

const getBalanceProps = (values, postInjury, userBaselineValues) => {
  let showTestSelector = true;
  const baselineCompleted = Object.keys(values || {}).length > 0
    && !values?.not_done;
  const userBaselineCompleted = Object.keys(userBaselineValues || {}).length > 0
    && !userBaselineValues?.not_done;

  if (baselineCompleted || (postInjury && userBaselineCompleted)) {
    showTestSelector = false;
  }

  const isWess = values?.is_wess
    || (postInjury && userBaselineValues?.is_wess) || false;

  return { showTestSelector, isWess };
};

const BalanceTestStep = forwardRef((
  {
    baseline,
    postInjury = false,
    userBaseline,
    onSubmit,
    ...rest
  },
  ref
) => {
  const { isWess, showTestSelector } = useMemo(() => (
    getBalanceProps(
      baseline?.balance_test,
      postInjury,
      userBaseline?.balance_test
    )
  ), [baseline?.balance_test, postInjury, userBaseline?.balance_test]);

  return (
    <BalanceTestingCard
      {...rest}
      ref={ref}
      isWess={isWess}
      balance={{ ...(baseline?.balance_test) }}
      showTestSelector={showTestSelector}
      onSubmit={onSubmit}
    />
  );
});

BalanceTestStep.hasChanged = (initial, current) => {
  if (current?.is_wess) {
    return (initial?.seated_errors?.eyes_open || '') !== current?.seated_errors?.eyes_open
      || (initial?.seated_errors?.eyes_closed || '') !== current?.seated_errors?.eyes_closed
      || (initial?.balance_disk_errors?.eyes_open || '') !== current?.balance_disk_errors?.eyes_open
      || (initial?.balance_disk_errors?.eyes_closed || '') !== current?.balance_disk_errors?.eyes_closed
      || (initial?.wheelie_errors?.eyes_open || '') !== current?.wheelie_errors?.eyes_open
      || (initial?.wheelie_errors?.eyes_closed || '') !== current?.wheelie_errors?.eyes_closed;
  }

  return (initial.dominant_foot || '') !== current.dominant_foot
    || (initial.feet_together_errors || '') !== current.feet_together_errors
    || (initial.single_leg_errors || '') !== current.single_leg_errors
    || (initial.foam_pad_errors || '') !== current.foam_pad_errors
    || (initial.tandom_errors || '') !== current.tandom_errors
    || (initial.bess_notes || '') !== current.bess_notes;
};

export default BalanceTestStep;
