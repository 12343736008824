const UPDATE_USER_BASELINE_TESTS = 'UPDATE_USER_BASELINE_TESTS';
const UPDATE_USER_BASELINE_TEST = 'UPDATE_USER_BASELINE_TEST';
const REMOVE_USER_BASELINE_TEST = 'REMOVE_USER_BASELINE_TEST';
const ADD_USER_BASELINE_TEST = 'ADD_USER_BASELINE_TEST';

const updateUserBaselineTests = (userId, baselines) => ({
  type: UPDATE_USER_BASELINE_TESTS,
  userId,
  baselines
});

const updateUserBaselineTest = (userId, baseline) => ({
  type: UPDATE_USER_BASELINE_TEST,
  userId,
  baseline
});

const removeUserBaselineTest = (userId, baselineId) => ({
  type: REMOVE_USER_BASELINE_TEST,
  userId,
  baselineId
});

const addUserBaselineTest = (userId, baseline) => ({
  type: ADD_USER_BASELINE_TEST,
  userId,
  baseline
});

export {
  UPDATE_USER_BASELINE_TESTS,
  updateUserBaselineTests,
  UPDATE_USER_BASELINE_TEST,
  updateUserBaselineTest,
  REMOVE_USER_BASELINE_TEST,
  removeUserBaselineTest,
  ADD_USER_BASELINE_TEST,
  addUserBaselineTest
};
