import dayjs from 'dayjs';
import { INPUT_DATE_FORMAT } from '@/utilities/dates/formats';
import { LineItemTypes } from '../utilities';

const EMPTY_COLUMN_PLACEHOLDER = '----';

const getDate = (date) => {
  return dayjs(date).format(INPUT_DATE_FORMAT);
};

const defaultDate = () => dayjs().toString();

const LineItemValidators = {
  [LineItemTypes.Credit]: () => true,
  [LineItemTypes.Impact]: () => true,
  [LineItemTypes.OverduePaymentFee]: () => true,
  [LineItemTypes.Custom]: (attributes) => {
    return !!attributes.description;
  },
  [LineItemTypes.MapleConsult]: (attributes) => {
    return !!attributes.category;
  },
};

export {
  EMPTY_COLUMN_PLACEHOLDER,
  getDate,
  defaultDate,
  LineItemValidators
};
