const EN = {
  nameHeadingText: 'Name',
  teamsHeadingText: 'Teams',
  statusHeadingText: 'Status',
  actionHeadingText: 'Action',
  activatedStatusText: 'Active',
  deactivatedStatusText: 'Disabled',
  activateActionText: 'Activate Account',
  deactivateActionText: 'Deactivate Account'
};

export default EN;
