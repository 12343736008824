import React from 'react';
import PropTypes from 'prop-types';
import { If, Then } from 'react-if';
import symptomLabels from './symptom-labels';
import ScoreView from '../score-view';
import Strings from './lang';
import { getTotals, getSeverity, MAX_SEVERITY } from './symptom-utilities';
import { AddNoteButton } from '../buttons';

const SymptomLevelsShowCard = ({
  symptoms = {}
}) => {
  return (
    <div className="baseline-card">
      {Object.keys(symptomLabels).map((key, index) => {
        const symptom = symptoms[key] || {};
        return (
          <div key={index} className="baseline-card-row">
            <label className="baseline-row-label">{symptomLabels[key]}</label>
            <label className="severity-view">{symptom.level ?? null}</label>
            <div className="symptom-add-note">
              <If condition={!!symptom.notes}>
                <Then>
                  <AddNoteButton 
                    note={symptom.notes}
                    title={Strings.formatString(Strings.notesModalTitle, symptomLabels[key])}
                    submitButtonTitle={Strings.doneButtonText}
                    editable={false}
                  />
                </Then>
              </If>
            </div>
          </div>
        );
      })}
      <ScoreView
        label={Strings.severityScoreLabel}
        score={getSeverity(symptoms)}
        outOf={MAX_SEVERITY * Object.keys(symptomLabels).length}
      />
      <ScoreView
        label={Strings.symptomTotalsLabel}
        score={getTotals(symptoms)}
        outOf={Object.keys(symptomLabels).length}
      />
    </div>
  );
};

SymptomLevelsShowCard.propTypes = {
  symptoms: PropTypes.object
};

export default SymptomLevelsShowCard;
