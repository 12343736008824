import React from 'react';
import PropTypes from 'prop-types';
import BaselineSection from './baseline-section';
import Strings from './lang';

const BaselineForcePlate = ({
  data = {}
}) => {
  const { 
    left_foot_cop = 0, right_foot_cop = 0, notes = '', not_done = false 
  } = data;

  return (
    <BaselineSection
      className="baseline-force-plate"
      title={Strings.forcePlateTitle}
      sectionCompleted={Object.keys(data || {}).length > 0 && !not_done}
      notes={notes}
    >
      <table className="table">
        <thead>
          <tr>
            <th className="no-action">{Strings.footLabelText}</th>
            <th className="no-action">{Strings.copLabelText}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><strong>{Strings.leftLabelText}</strong></td>
            <td>{left_foot_cop}</td>
          </tr>
          <tr>
            <td><strong>{Strings.rightLabelText}</strong></td>
            <td>{right_foot_cop}</td>
          </tr>
        </tbody>
      </table>
    </BaselineSection>
  );
};

BaselineForcePlate.propTypes = {
  data: PropTypes.object.isRequired
};

export default BaselineForcePlate;
