const EN = {
  patientInformationHeader: 'Patient Information',
  injuryInformationHeader: 'Injury Information',
  patientLabel: 'Patient',
  accountLabel: 'Account',
  bagTagLabel: 'ID Card',
  birthdateLabel: 'Birthdate',
  ageLabel: 'Age',
  sexLabel: 'Sex',
  cityLabel: 'City / Town',
  regionLabel: 'Region',
  phoneLabel: 'Phone',
  injuryDateLabel: 'Injury Date',
  assessmentDateLabel: 'Assessment Date',
  daysSinceInjuryLabel: 'Days Since Injury',
  practitionerLabel: 'Practitioner',
  clinicLabelText: 'Clinic',
  phoneLabelText: 'Phone',
  faxLabelText: 'Fax',
  injuryDateErrorMessage: 'Invalid Date. Date must be on or before the assessment date ({0})',
  assessmentDateErrorMessage: 'Invalid Date. Date must be between the injury date ({0}) and today ({1})',
  saveButtonText: 'Save',
  editButtonText: 'Edit',
  cancelButtonText: 'Cancel'
};

export default EN;
