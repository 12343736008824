/* eslint-disable no-magic-numbers */
const KingDevickAnswerKeys = {
  version: {
    1: {
      card: {
        1: [
          [2, 5, 8, 0, 7],
          [3, 7, 9, 4, 6],
          [5, 3, 1, 6, 4],
          [7, 9, 7, 3, 5],
          [1, 5, 4, 9, 2],
          [6, 5, 5, 7, 3],
          [3, 1, 8, 6, 4],
          [5, 3, 7, 5, 2]
        ],
        2: [
          [3, 7, 5, 9, 0],
          [2, 5, 7, 4, 6],
          [1, 4, 7, 6, 3],
          [7, 9, 3, 9, 0],
          [4, 5, 2, 1, 7],
          [5, 3, 7, 4, 8],
          [7, 4, 6, 5, 2],
          [9, 0, 2, 3, 6]
        ],
        3: [
          [5, 4, 1, 8, 0],
          [4, 6, 3, 5, 9],
          [7, 5, 4, 2, 7],
          [3, 2, 6, 9, 4],
          [1, 4, 5, 1, 3],
          [9, 3, 4, 8, 5],
          [5, 1, 6, 3, 1],
          [4, 3, 5, 2, 7]
        ]
      }
    },
    2: {
      card: {
        1: [
          [6, 7, 4, 2, 5],
          [6, 0, 5, 7, 9],
          [4, 0, 8, 2, 3],
          [5, 3, 6, 8, 7],
          [1, 8, 3, 0, 6],
          [3, 1, 4, 8, 5],
          [4, 2, 7, 8, 5],
          [3, 7, 4, 8, 1]
        ],
        2: [
          [7, 0, 1, 8, 4],
          [8, 2, 0, 7, 9],
          [7, 5, 6, 2, 9],
          [8, 4, 3, 6, 1],
          [5, 1, 0, 7, 4],
          [1, 3, 0, 2, 6],
          [8, 3, 5, 6, 0],
          [0, 7, 5, 6, 7]
        ],
        3: [
          [1, 9, 7, 4, 8],
          [8, 6, 4, 2, 7],
          [7, 3, 9, 6, 5],
          [2, 9, 3, 7, 2],
          [5, 1, 7, 6, 2],
          [9, 4, 0, 2, 8],
          [1, 9, 7, 8, 0],
          [3, 8, 5, 3, 6]
        ]
      }
    }
  }
};

export default KingDevickAnswerKeys;
