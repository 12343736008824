import React from 'react';
import ClinicHoursInputRow from './clinic-hours-input-row';
import Strings from './lang';

const ClinicHoursInputTable = ({
  hours = [],
  onUpdate = () => {}
}) => {
  return (
    <table className="table clinic-hours-input-table">
      <thead>
        <tr>
          <th className="no-action">{Strings.dayTableHeading}</th>
          <th className="no-action">{Strings.statusTableHeading}</th>
          <th className="no-action">{Strings.openTableHeading}</th>
          <th className="no-action">{Strings.closeTableHeading}</th>
        </tr>
      </thead>
      <tbody>
        {hours.map((item, index) => (
          <ClinicHoursInputRow 
            key={item.day}
            hours={item}
            copyRowDisabled={(index + 1) === hours.length}
            onUpdate={(row) => {
              const newHours = [...hours];
              newHours[index] = { ...row };
              onUpdate(newHours);
            }}
            onCopyRowDown={() => {
              const newHours = [...hours];
              const copied = newHours[index];
              newHours[index + 1] = { 
                ...copied, 
                day: newHours[index + 1].day 
              };

              onUpdate(newHours);
            }}
          />
        ))}
      </tbody>
    </table>
  );
};

export default ClinicHoursInputTable;
