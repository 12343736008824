import React, { useState } from 'react';
import classnames from 'classnames';
import Modal from '../../modal';
import Strings from '../lang';
import Icon from '../../icon';

const BorgScaleImage = () => (
  <div className="borg-scale-image">
    <img
      src="/images/buffalo-test/borg_scale_chart.png"
      alt="Borg Scale"
      style={{ width: '45%' }}
    />
  </div>
);

const SymptomScoreImage = () => (
  <img
    src="/images/buffalo-test/symptom_score_scale_chart.png"
    width="100%"
    height="100%"
    alt="Symptom Scale"
  />
);

const HelpModal = ({
  title,
  inverted = false,
  children
}) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <button
        type="button"
        tabIndex={-1}
        className={classnames('help-button', { inverted })}
        onClick={() => setOpen(true)}
      >
        <Icon name="circle-info" />
      </button>
      <Modal
        blur
        isOpen={open}
        onClose={() => setOpen(false)}
      >
        <Modal.Header>
          <h1>{title}</h1>
        </Modal.Header>
        <Modal.Body>
          {children}
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-sm btn-primary"
            onClick={() => setOpen(false)}
          >
            Done
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const BorgScaleHelpModalLink = ({ inverted = false }) => (
  <HelpModal
    inverted={inverted}
    title={Strings.borgScaleLabel}
  >
    <BorgScaleImage />
  </HelpModal>
);

const SymptomScoreHelpModalLink = ({ inverted = false }) => (
  <HelpModal
    inverted={inverted}
    title={Strings.symptomScaleLabel}
  >
    <SymptomScoreImage />
  </HelpModal>
);

export {
  BorgScaleHelpModalLink,
  SymptomScoreHelpModalLink
};
