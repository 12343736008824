import React, { useCallback, useState } from 'react';
import Popover from '../../popover';
import Icon from '../../icon';

const AppointmentNotePopover = ({ notes }) => {
  const [clickOpen, setClickOpen] = useState(false);
  const [hoverOpen, setHoverOpen] = useState(false);

  const handleHoverOpen = useCallback(() => setHoverOpen(true), []);
  const handleHoverClose = useCallback(() => setHoverOpen(false), []);

  if (!notes) return null;

  return (
    <div
      tabIndex={0}
      onMouseOver={handleHoverOpen}
      onMouseLeave={handleHoverClose}
      onFocus={handleHoverOpen}
      onBlur={handleHoverClose}
    >
      <Popover
        isOpen={clickOpen || hoverOpen}
        placement="top"
        // eslint-disable-next-line no-magic-numbers
        offset={[0, 4]}
        onClose={() => {
          setClickOpen(false);
          setHoverOpen(false);
        }}
        content={(
          <div style={{ padding: 10, whiteSpace: 'pre-wrap' }}>
            {notes}
          </div>
        )}
      >
        <span
          className="primary"
          onClick={() => setClickOpen(prev => !prev)}
        >
          <Icon name="comment" />
        </span>
      </Popover>
    </div>
  );
};

export default AppointmentNotePopover;
