import React from 'react';
import dayjs from 'dayjs';
import { DISPLAY_DATE_FORMAT } from '@/utilities/dates/formats';
import {
  OrderedSymptomKeys, 
  SymptomStrings, 
  calculateSymptomsTotals, 
  MAX_SYMPTOM_SEVERITY 
} from '@/utilities/symptom-data';
import Strings from './lang';

const SymptomUpdatesPrintDisplay = ({ symptomsUpdates = [] }) => {
  return (
    <div className="print-only">
      {symptomsUpdates.map(update => {
        const { id, symptoms, created_at } = update;
        const totals = calculateSymptomsTotals(symptoms);

        return (
          <div key={id} style={{ paddingBottom: 15, pageBreakInside: 'avoid' }}>
            <h3>
              <strong>{dayjs(created_at).format(DISPLAY_DATE_FORMAT)}</strong>
            </h3>
            <hr style={{ marginTop: 0 }} />
            <div className="row">
              {OrderedSymptomKeys.map(key => (
                <div key={key} className="col-md-4">
                  {SymptomStrings[key]} 
                  <span style={{ float: 'right' }}>
                    <strong>{symptoms[key].level}</strong>
                    <sub>/{MAX_SYMPTOM_SEVERITY}</sub>
                  </span>
                </div>
              ))}
              <div className="score-view col-md-12">
                <label>{Strings.symptomSeverityLabel}:</label> 
                <span className="score-view-score-display">{totals.score}</span>
                <small>/{MAX_SYMPTOM_SEVERITY * OrderedSymptomKeys.length}</small>
              </div>
              <div className="score-view col-md-12">
                <label>{Strings.symptomTotalsLabel}: </label>
                <span className="score-view-score-display">{totals.count}</span>
                <small>/{OrderedSymptomKeys.length}</small>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default SymptomUpdatesPrintDisplay;
