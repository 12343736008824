import dayjs from 'dayjs';
import subDays from 'date-fns/subDays';
import subMonths from 'date-fns/subMonths';
import subYears from 'date-fns/subYears';
import Strings from './lang';

const TODAY = new Date();

const RangeStrings = {
  '7_days': Strings.last7DayRangeOptionLabel,
  '30_days': Strings.last30DayRangeOptionLabel,
  '3_months': Strings.last3MonthsRangeOptionLabel,
  '6_months': Strings.last6MonthsRangeOptionLabel,
  '1_year': Strings.last1YearRangeOptionLabel,
  '2_years': Strings.last2YearsRangeOptionLabel,
  custom: Strings.customRangeOptionLabel
};

const createRange = (key, start, end = TODAY) => ({
  label: RangeStrings[key],
  range: () => ({
    startDate: start,
    endDate: end,
    rangeKey: key
  }),
  isSelected: ({ rangeKey }) => rangeKey === key
});

const isRangeValid = ({ startDate, endDate }) => {
  return dayjs(startDate).isValid() 
    && dayjs(endDate).isValid();
};

/* eslint-disable no-magic-numbers */
const Ranges = [
  createRange('7_days', subDays(TODAY, 7)),
  createRange('30_days', subDays(TODAY, 30)),
  createRange('3_months', subMonths(TODAY, 3)),
  createRange('6_months', subMonths(TODAY, 6)),
  createRange('1_year', subYears(TODAY, 1)),
  createRange('2_years', subYears(TODAY, 2)),
  createRange('custom', null, new Date('')),
];

const INITIAL_RANGE_DEFINITION = Ranges[2]; // 3_months
const initialRange = INITIAL_RANGE_DEFINITION.range();

export {
  Ranges,
  initialRange,
  isRangeValid,
  RangeStrings
};
