import classNames from 'classnames';
import React from 'react';
import { TabKeys, TabLabels } from './utils';
import Icon from '../icon';

const DrawerHeader = ({
  tab = TabKeys.SoapNotes,
  onTabChange = () => {},
  onClose
}) => {
  return (
    <div className="notes-drawer-header">
      <div className="tabs">
        <div className="tabs-bar">
          <div className="container">
            <div className="header-actions">
              <div>
                {Object.keys(TabKeys).map(key => {
                  const selected = tab === TabKeys[key];
                  return (
                    <button
                      key={key}
                      type="button"
                      className={classNames('tab-button', { selected })}
                      onClick={() => {
                        if (!selected) {
                          onTabChange(TabKeys[key]);
                        }
                      }}
                    >
                      {TabLabels[key]}
                    </button>
                  );
                })}
              </div>
              <button type="button" className="btn-close" onClick={onClose}>
                <Icon name="xmark" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DrawerHeader;
