const FR = {
  title: 'Mise à jour des rapports de progression',
  dateReportedLabel: 'Mise à jour de la progression :',
  progressReportListSubtitle: 'Mise à jour des rapports de progression pour la blessure datée du {0}',
  progressUpdateMessageText: 'Ce patient n’a jamais eu de rapport de progression pour cette blessure.',
  progressReportCardTitle: 'Mise à jour du rapport de progression - {0}',
  patientLabel: 'Patient',
  reportedDurationLabel: 'Rapporté',
  reportedByLabel: 'Rapporté par',
  descriptionLabel: 'Description',
  selfReportedText: 'Autorapporté',
  notSelfReportedText: '{0} ({1})'
};
 
export default FR;
