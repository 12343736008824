const FR = {
  numberListLabel: 'Liste de nombres',
  correct: 'Exact',
  incorrect: 'Faux',
  reset: 'Recommencer',
  numbersComplete: 'Test des nombres terminé.',
  multipleErrors: 'La personne a eu 2 erreurs consécutives.',
  numbersScore: 'Résultat du test de nombres inversés:',
  defaultListSelectText: 'Veuillez choisir la liste pour commencer',
  numbersTestInstructionText: 'Choisissez une liste de nombres pour commencer le test. Appuyez sur les boutons sous la liste de nombres afin de modifier les réponses exactes(chèque vert)/fausses(rouge x) Appuyez sur la flèche de retour pour annuler la dernière réponse. Suivez les nombres surlignés en bleu.',
  numbersCompleteWithErrors: 'Le patient a commis deux (2) erreurs consécutives.',
  numbersIncompleteError: 'Veuillez remplir la section des nombres inversés.'
};
 
export default FR;
