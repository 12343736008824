import React, { 
  forwardRef, 
  useCallback,
  useState 
} from 'react';
import { DefinedRange } from 'react-date-range';
import { usePopperContext } from '../popper';
import DateRange from './date-range';
import { Ranges } from './ranges';

const DateRangeMenu = forwardRef(({
  range,
  onSelectRange = () => {}
}, ref) => {
  const [isCustom, setIsCustom] = useState(() => range.rangeKey === 'custom');
  const { update } = usePopperContext();

  const handleRangeChange = useCallback((item) => {
    const selection = item[range.key];
    const customRange = selection.rangeKey === 'custom';
    setIsCustom(customRange);

    if (customRange) {
      update();
    } else {
      onSelectRange(selection);
    }
  }, [onSelectRange, range.key, update]);

  const handleRangeApply = useCallback((range) => {
    onSelectRange(range);
    setIsCustom(false);
  }, [onSelectRange]);

  return (
    <div ref={ref} className="date-range-menu">
      <DefinedRange
        onChange={handleRangeChange}
        ranges={[range]}
        inputRanges={[]}
        rangeColors={['#2196F1']}
        staticRanges={Ranges}
      />
      {isCustom && (
        <DateRange
          range={range}
          onApply={handleRangeApply}
        />
      )}
    </div>
  );
});

export default DateRangeMenu;
