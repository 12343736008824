const UPDATE_INJURY_ASSESSMENT = 'UPDATE_INJURY_ASSESSMENT';

const updateInjuryAssessment = (userId, assessment) => ({
  type: UPDATE_INJURY_ASSESSMENT,
  userId,
  assessment
});

export {
  UPDATE_INJURY_ASSESSMENT,
  updateInjuryAssessment
};
