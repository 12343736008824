/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState } from 'react';
import { Page } from '../../page';
import { FunctionTestingVideosConfig } from './functional-testing-videos';
import { OutcomeMeasuresConfig } from './outcome-measures';
import Icon from '../../icon';
import Strings from '../lang';

const ICON_NAMES = {
  pdf: 'file-pdf',
  word: 'file-word'
};

const IdtDocument = ({ fileName, type, src }) => {
  const downloadable = type !== 'pdf';

  return (
    <a
      className="idt-downloadable-document"
      href={src}
      target={!downloadable ? '_blank' : '_top'}
      rel="noreferrer noopener"
      download={downloadable}
    >
      <div className="content">
        <div className="flex-row align_center" style={{ gap: 5 }}>
          <Icon name={ICON_NAMES[type]} />
          {fileName}
        </div>
        {downloadable && (
          <div className="download-indicator">
            <Icon name="download" />
          </div>
        )}
      </div>
    </a>
  );
};

const IdtVideoDropdown = ({
  name,
  time,
  fileName
}) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="idt-video-dropdown">
      <div className="idt-video-header" onClick={() => setOpen(prev => !prev)}>
        <Icon name={open ? 'caret-down' : 'caret-right'} prefix="fas" />
        {name} ({time})
      </div>
      {open && (
        <div className="idt-video-container">
          <video width="225" height="400" controls>
            <source src={`https://static.completeconcussions.com/videos/idt/functional-testing/${fileName}`} />
          </video>
        </div>
      )}
    </div>
  );
};

const IdtResources = () => {
  return (
    <Page>
      <h1 className="display">
        {Strings.idtResourcesLabel}
      </h1>
      <div className="idt-resources-section">
        <h2>{Strings.outcomeMeasuresHeader}</h2>
        <div className="row">
          {OutcomeMeasuresConfig.map((config, index) => (
            <div className="col-md-6" key={index} style={{ marginBottom: 15 }}>
              <IdtDocument
                fileName={config.name}
                src={`https://static.completeconcussions.com/${config.filePath}`}
                type={config.type}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="idt-resources-section">
        <h2>{Strings.functionalTestingHeader}</h2>
        <div className="row">
          <div className="col-md-6">
            <IdtDocument
              fileName="Functional Testing Interpretation and Considerations.docx"
              src="https://static.completeconcussions.com/documents/idt/Functional+Testing+Interpretation+and+Considerations.docx"
              type="word"
            />
          </div>
        </div>
        <div className="idt-videos-list">
          {FunctionTestingVideosConfig.map((config, index) => (
            <div>
              <IdtVideoDropdown key={index} {...config} />
            </div>
          ))}
        </div>
      </div>
    </Page>
  );
};

export default IdtResources;
