import React from 'react';
import classnames from 'classnames';
import ClinicServiceKeys from './services';
import Strings from './lang';

const serviceClassNames = {
  [ClinicServiceKeys.BaselineTesting]: 'primary',
  [ClinicServiceKeys.ConcussionTreatment]: 'warning',
  [ClinicServiceKeys.ReturnToPlay]: 'success'
};

const ClinicServicesTags = ({ services = [] }) => {
  return (
    <div className="tag-group">
      {services.map(service => (
        <div key={service} className={classnames('tag', serviceClassNames[service])}>
          {Strings[`${service}_label`]}
        </div>
      ))}
    </div>
  );
};
export default ClinicServicesTags;
