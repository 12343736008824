import React, { useCallback, useState } from 'react';
import ReferralModal from './referral-modal';

const ReferPatientButton = ({
  injuryId,
  className,
  disabled,
  disabledReason,
  onComplete = () => {},
  children
}) => {
  const [opened, setOpened] = useState(false);
  const handleComplete = useCallback((referral) => {
    setOpened(false);
    onComplete(referral);
  }, [onComplete]);

  return (
    <>
      <button 
        type="button" 
        disabled={disabled} 
        title={disabled ? disabledReason : null}
        className={className} 
        onClick={disabled ? undefined : () => setOpened(true)}
      >
        {children}
      </button> 
      <ReferralModal 
        isOpen={opened}
        injuryId={injuryId}
        onClose={() => setOpened(false)}
        onComplete={handleComplete}
      />
    </>
  );
};

export default ReferPatientButton;
