import React from 'react';
import classnames from 'classnames';
import { getSeverity, MAX_SEVERITY } from '../symptoms';
import { ovsResultTypeString } from '../ovs-tests';
import Strings from './lang';
import Icon from '../icon';

const TestBadge = ({
  label,
  testType,
  indicator = null,
  indicatorClassName,
  title
}) => (
  <div className={classnames('panel-header-test-badge', testType)} title={title}>
    <div className="test-badge-label">
      {label}
    </div>
    {indicator ? (
      <div className={classnames('test-badge-indicator', indicatorClassName)}>{indicator}</div>
    ) : null}
  </div>
);

const BuffaloTestBadge = ({
  buffalo
}) => {
  if (!buffalo) {
    return null;
  }

  const passed = buffalo.symptom_exacerbation === false;
  const icon = passed ? 'check' : 'xmark';
  const indicatorColor = passed ? 'success' : 'error';
  const title = passed ? Strings.testPassedText : Strings.testFailedText;

  return (
    <TestBadge 
      label="BT" 
      testType="buffalo"
      indicator={(<span><Icon name={icon} /></span>)} 
      indicatorClassName={indicatorColor}
      title={Strings.formatString(
        Strings.buffaloTestBadgeTitle,
        title
      )}
    />  
  );
};

const BlackhawksTestBadge = ({
  blackhawks
}) => {
  if (!blackhawks) {
    return null;
  }

  const passed = blackhawks.cooldown_result === true;
  const icon = passed ? 'check' : 'xmark';
  const indicatorColor = passed ? 'success' : 'error';
  const title = passed ? Strings.testPassedText : Strings.testFailedText;

  return (
    <TestBadge 
      label="BH" 
      testType="blackhawks"
      indicator={(<span><Icon name={icon} /></span>)} 
      indicatorClassName={indicatorColor}
      title={Strings.formatString(
        Strings.blackhawksTestBadgeTitle,
        title
      )}
    />  
  );
};

const VomsTestBadge = ({
  voms
}) => !!voms && (
  <TestBadge 
    label="V" 
    testType="voms"
    title={Strings.vomsTestBadgeTitle}
  />  
);

const OVSTestBadge = ({
  ovs
}) => {
  if (!ovs) {
    return null;
  }

  const isNormal = ovs.result === 'normal';
  const icon = isNormal ? 'check' : 'triangle-exclamation';
  const indicatorColor = isNormal ? 'success' : 'error';

  return (
    <TestBadge
      label="OVS"
      testType="ovs"
      title={`${Strings.ovs} (${ovsResultTypeString[ovs.result]})`}
      indicator={(<span><Icon name={icon} /></span>)}
      indicatorClassName={indicatorColor}
    />
  );
};

const PostInjuryTestBadge = ({
  postInjury
}) => !!postInjury && (
  <TestBadge 
    label="PI" 
    testType="baseline"
    title={Strings.postInjuryTestBadgeTitle}
  />  
);

export const SymptomScoreBadge = ({
  symptoms
}) => {
  const totalSymptoms = Object.keys(symptoms).length;
  const hasSymptoms = totalSymptoms > 0;

  if (!symptoms || !hasSymptoms) {
    return null;
  }

  const severity = getSeverity(symptoms);

  return (
    <TestBadge 
      label="SS" 
      testType="symptoms"
      indicator={(<span className="bold">{severity}</span>)}
      indicatorClassName="success"
      title={Strings.formatString(
        Strings.symptomScoreBadgeTitle,
        `${severity}/${totalSymptoms * MAX_SEVERITY}`
      )}
    />
  );
};

const SoapNoteTestBadges = ({ soap = {} }) => {
  const { 
    baselines = [], 
    buffalo_tests = [], 
    voms_tests = [], 
    orthostatic_vital_signs_tests = [],
    blackhawks_tests = [], 
    symptoms_scores = {} 
  } = soap;
  const baseline = baselines[0];
  const buffalo = buffalo_tests[0];
  const blackhawks = blackhawks_tests[0];
  const ovs = orthostatic_vital_signs_tests[0];
  const voms = voms_tests[0];

  return (
    <div className="soap-panel-header-test-badges no-print">
      <SymptomScoreBadge symptoms={symptoms_scores} />
      <BuffaloTestBadge buffalo={buffalo} />
      <BlackhawksTestBadge blackhawks={blackhawks} />
      <VomsTestBadge voms={voms} />
      <OVSTestBadge ovs={ovs} />
      <PostInjuryTestBadge postInjury={baseline} />
    </div>
  );
};

export default SoapNoteTestBadges;
