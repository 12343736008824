import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Strings from './lang';

const passwordScoreStrings = [
  Strings.passwordScore0Text,
  Strings.passwordScore1Text,
  Strings.passwordScore2Text,
  Strings.passwordScore3Text,
  Strings.passwordScore4Text
];

const PasswordScore = ({
  score
}) => (
  <div className={classNames('password-score', `score-${score}`)}>
    <div className="password-score-value" />
    <span>{passwordScoreStrings[score]}</span>
  </div>
);

PasswordScore.propTypes = {
  score: PropTypes.number.isRequired
};

export default PasswordScore;
