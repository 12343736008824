import React, { useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Card from '../../card';
import { DashboardPaths, ClinicPaths } from '@/paths';
import replaceLinkParams from '@/utilities/replace-link-params';
import { resolvePatientName } from '../../patient-meta';
import AccessControl from '../../access-control';
import { RoleDescriptions } from '@/utilities/user-roles';
import { BaselineSelector, UserSelector } from '@/redux/selectors';
import { getCurrentClinicId } from '@/tokens';
import Strings from './lang';

const BaselineTestCompleteStep = () => {
  const params = useParams();
  const clinicId = useMemo(() => getCurrentClinicId(), []);
  const user = useSelector(state => UserSelector.getUser(state, params));
  const baseline = useSelector(state => BaselineSelector.getBaseline(state, params));
  const currentUser = useSelector(UserSelector.getCurrentUser);

  const name = resolvePatientName({
    user: currentUser,
    patient: user,
    clinicId,
    redactedText: Strings.redactedNameText
  });

  return (
    <div className="container">
      <Card title={Strings.baselineCompleteCardTitle}>
        <div className="card card-show">

          <div className="row">
            <div className="col-md-12">
              <p>
                {Strings.formatString(
                  Strings.baselineCompleteInfoText,
                  name,
                  user.email || ''
                )}
              </p>
            </div>
          </div>

          <div className="row">
            <AccessControl roles={[RoleDescriptions.Clinician, RoleDescriptions.BaselineTester]}>
              <div className="col-md-8">
                <Link
                  className="btn btn-primary"
                  to={replaceLinkParams(ClinicPaths.patientProfile.profile.link, {
                    clinicId,
                    userId: user.id
                  })}
                >
                  {Strings.formatString(
                    Strings.patientFileLinkText,
                    name
                  )}
                </Link>
                <Link
                  className="btn btn-primary"
                  to={replaceLinkParams(ClinicPaths.patientProfile.baselines.show.link, {
                    clinicId,
                    userId: user.id,
                    baselineId: baseline.id
                  })}
                >
                  {Strings.viewResultsLinkText}
                </Link>
              </div>
            </AccessControl>

            <div className="col-md-4">
              <Link className="btn btn-primary" to={DashboardPaths.index.link}>
                {Strings.returnToDashboardLinkText}
              </Link>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default BaselineTestCompleteStep;
