import React from 'react';
import PropTypes from 'prop-types';
import { If, Then } from 'react-if';
import dayjs from 'dayjs';
import { DISPLAY_DATE_FORMAT } from '@/utilities/dates/formats';
import Strings from './lang';

const BagTagTable = ({
  bagTags = []
}) => (
  <table className="table bag-tags-table">
    <thead>
      <tr>
        <th className="no-action">
          {Strings.tagNumberTableHeadingLabelText}
        </th>
        <th className="no-action">
          {Strings.dateUsedTableHeadingLabelText}
        </th>
      </tr>
    </thead>
    <tbody>
      {bagTags.map(tag => {
        if (tag) {
          const user = tag.user || {};
          return (
            <tr key={tag.id}>
              <td>{tag.number}</td>
              <td>
                <If condition={user.id !== undefined && tag.used_at !== null}>
                  <Then>
                    <div>{dayjs(tag.used_at).format(DISPLAY_DATE_FORMAT)}</div>
                  </Then>
                </If>
              </td>
            </tr>
          );
        } 
          
        return null;
      })}
    </tbody>
  </table>
);

BagTagTable.propTypes = {
  bagTags: PropTypes.array.isRequired
};

export default BagTagTable;
