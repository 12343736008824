import React, { Component } from 'react';
import classNames from 'classnames';
import FormInputGroup from './form-input-group';
import Strings from './lang';

class SelectInputGroup extends Component {
  constructor(props) {
    super(props);

    const value = this.mergeInputProps().value || '';
    const valid = value.length > 0 || !props.required;

    this.onUpdate = this.onUpdate.bind(this);

    this.state = {
      valid
    };
  }

  render() {
    return (
      <FormInputGroup
        className={classNames('role-input-group', this.props.className)}
        labelText={this.props.labelText || Strings.selectLabelText}
        labelAccessory={this.props.labelAccessory}
        noLabel={this.props.noLabel || false}
        labelProps={this.props.labelProps}
        inputType="select"
        inputProps={this.mergeInputProps()}
        inputValid={this.props.valid || this.state.valid}
        messageText={this.props.messageText || Strings.selectErrorText}
        messageClassName={this.props.messageClassName}
        required={this.props.required}
        touched={this.props.touched}
      >
        {this.props.children}
      </FormInputGroup>
    );
  }

  mergeInputProps() {
    return {
      ...(this.props.inputProps || {}),
      onBlur: this.onUpdate,
      onChange: this.onUpdate 
    };
  }

  onUpdate(event) {
    const { required, onUpdate } = this.props;
    const { value } = event.target;
    const valid = value.length > 0 || !required;

    this.setState({
      valid
    });

    if (typeof onUpdate === 'function') {
      onUpdate(value, valid);
    }
  }
}

SelectInputGroup.propTypes = FormInputGroup.propTypes;

export default SelectInputGroup;
