import React, { useCallback, useRef } from 'react';
import Icon from '../../icon';
import Strings from '../lang';

const filterFileTypes = (files) => files.filter(file => file.type === 'application/pdf');

const FileDropzone = ({ onDrop = () => {} }) => {
  const inputRef = useRef();

  const handleClick = useCallback(() => {
    inputRef.current.click();
  }, []);

  const handleSelect = useCallback((e) => {
    const { files } = e.target;
    onDrop(Array.from(files));
  }, [onDrop]);

  const handleDrop = useCallback((e) => {
    e.stopPropagation();
    e.preventDefault();

    const { files } = e.dataTransfer;
    onDrop(filterFileTypes(Array.from(files)));
  }, [onDrop]);

  const handleDragOver = useCallback((e) => {
    e.stopPropagation();
    e.preventDefault();
    e.dataTransfer.dropEffect = 'copy';
  }, []);

  const handleDragLeave = useCallback((e) => {
    e.stopPropagation();
    e.preventDefault();
  }, []);

  return (
    <div
      className="idt-document-dropzone"
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onClick={handleClick}
    >
      <div className="dropzone-inner">
        <Icon name="file-pdf" />
        <div className="description">{Strings.dropzoneLabel}</div>
      </div>
      <input
        ref={inputRef}
        type="file"
        multiple
        accept="application/pdf"
        style={{ display: 'none' }}
        onChange={handleSelect}
      />
    </div>
  );
};

export default FileDropzone;
