const FR = {
  last7DayRangeOptionLabel: 'Les 7 derniers jours',
  last30DayRangeOptionLabel: 'Les 30 derniers jours',
  last3MonthsRangeOptionLabel: '3 derniers mois',
  last6MonthsRangeOptionLabel: '6 derniers mois',
  last1YearRangeOptionLabel: '1 dernière année',
  last2YearsRangeOptionLabel: '2 dernières années',
  customRangeOptionLabel: 'Gamme personnalisée'
};
 
export default FR;
