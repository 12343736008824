const EN = {
  correctAnswer: 'Correct',
  requiredError: 'Please select a value',
  incorrectAnswer: 'Incorrect',
  yesAnswerLabel: 'Yes',
  noAnswerLabel: 'No',
  monthsInReverseScoreLabel: 'Did the patient correctly recite the months in reverse order within the time limit?'
};

export default EN;
