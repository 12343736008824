const EN = {
  registerTitle: 'Thank You for Registering',
  returningTitle: 'Thank You for Renewing Your Account',
  registerHeaderMarkdown: '## Congratulations {0} {1}! You have successfully registered to have your pre-season baseline test or concussion assessment performed through Complete Concussion Management Inc.\r\n\r\nPlease bring the following account number to your registered clinic for your appointment.',
  returningHeaderMarkdown: '## Congratulations {0} {1}! You have successfully renewed your account with Complete Concussion Management Inc.\r\n\r\nYou can continue to use the following account number for your appointments.',
  accountNumberLabelText: 'Your Account Number:',
  childAccountNumberLabelText: 'Account Number:',
  clinicAssignedLabelText: 'Your Clinic:',
  childClinicAssignedLabelText: 'Clinic:',
  clinicUnassignedLabelText: 'Your clinic has not yet been assigned.',
  childClinicUnassignedLabelText: 'Clinic has not yet been assigned.',
  contactLinkText: 'Please find the nearest clinic to you.',
  footerMarkdown: '## Baseline Test Information:\r\n\r\nThis comprehensive testing will take roughly 30 minutes to perform and may require you to complete a secondary portion at home(depending on your age). If this testing is to be conducted through your team or organization, please await instructions from your coach and/or organization as to your testing times.\r\n\r\nPlease arrive at least 20 minutes prior to your scheduled test time with your account number dressed in athletic attire. Once your test is completed, your results will be stored on the Complete Concussion Management Inc.<sup>TM</sup> (CCMI) Database.  Should you receive a concussion, or suspected concussion, please contact the nearest CCMI recognized clinic as soon as possible for a full assessment and return to school and return to play management.\r\n\r\n## Concussion Assessment Information:\r\n\r\nIf you already have a concussion or suspected concussion, please bring any pertinent medical information as well as any results of previous testing/diagnosis (MRI, CT Scan, or emergency department reports) that you can obtain prior to your appointment.\r\n\r\nSincerely,\r\n\r\n**The Complete Concussion Management Inc.<b><sup>TM</sup></b> Team**\r\n\r\n',
  footerLinkText: 'CCMI Website'
};

export default EN;
