import React from 'react';
import PropTypes from 'prop-types';
import Strings from './lang';

const PasswordUpdateMessage = ({
  onComplete
}) => (
  <div className="password-update-message">
    <p className="alert alert-success">{Strings.updatePasswordSuccessMessage}</p>
    <button 
      type="button" 
      className="btn btn-primary" 
      onClick={onComplete}
    >
      {Strings.updateDoneButtonText}
    </button>
  </div>
);

PasswordUpdateMessage.propTypes = {
  onComplete: PropTypes.func.isRequired
};

export default PasswordUpdateMessage;
