import React from 'react';
import classNames from 'classnames';

const ProgressDot = ({ highlighted = false }) => (
  <div className={classNames('dot', { highlighted })} />
);

const ProgressDots = ({
  step = 0, 
  total = 0
}) => {
  if (step === 0 || total <= 1) {
    return null;
  }

  return (
    <div className="progress-dots">
      {[...new Array(total)].map((_, index) => (
        <ProgressDot key={index} highlighted={step === (index + 1)} />
      ))}
    </div>
  );
};

export default ProgressDots;
