const FR = {
  genericTitle: 'Erreur',
  genericmessage: 'Une erreur s’est produite',
  e404Title: 'Aucun résultat',
  e404Message: 'La ressource que vous recherchez n’existe pas',
  homeLinkText: 'Revenir',
  error401Signin: 'Email et / ou mot de passe invalide',
  error401Password: 'Mot de passe incorrect',
  errorSubTitle: 'Une erreur inattendue s\'est produite qui nous a empêché de vous montrer cette page.',
  errorDescription: 'Les détails de cette erreur ont été signalés et nous en avons été informés. Si vous souhaitez fournir une explication plus détaillée sur la façon dont cette erreur s\'est produite, veuillez remplir le formulaire de commentaires en cliquant sur le bouton "Soumettre des commentaires" ci - dessous.',
  errorFeedbackTitle: 'Retour d\'erreur',
  errorFeedbackSubtitle: 'Aidez-nous à retrouver cette erreur en nous faisant savoir ce qui s\'est passé.',
  errorFeedbackSubtitle2: 'Tout commentaire est grandement apprécié!',
  errorFeedbackSubmitButton: 'Soumettre des commentaires',
  actionHome: 'Rentrer chez soi',
  actionRefresh: 'Rafraîchir la page',
  actionSubmitFedback: 'Soumettre des commentaires'
};
 
export default FR;
