const EN = {
  title: 'Orthostatic Vital Signs Test',
  subTitle: 'Modified Active Standing Test',
  supineLabel: 'Supine',
  seatedLabel: 'Seated',
  standingLabel: 'Standing',
  submitText: 'Submit',
  positionLabel: 'Position',
  heartRateInputLabel: 'Heart Rate',
  heartRateInputErrorMessage: 'Please enter a valid heart rate (> 0)',
  bpInputLabel: 'Blood Pressure (SBP/DBP)',
  bpSystolicLabel: 'Systolic',
  bpSystolicInputErrorMessage: 'Please enter a valid systolic value (> 0)',
  bpDiastolicLabel: 'Diastolic',
  bpDiastolicInputErrorMessage: 'Please enter a valid diastolic value (> 0)',
  resultNormal: 'Normal',
  resultOrthostaticHypotension: 'Orthostatic Hypotension',
  resultOrthostaticTachycardia: 'Orthostatic Tachycardia',
  resultBorderlineOrthostaticTachycardia: 'Borderline Orthostatic Tachycardia',
  resultHypovolemia: 'Hypovolemia',
  resultLabel: 'Result',
  supineInstructions: 'Take measurements after 2 minutes lying supine',
  seatedInstructions: 'Take measurements after 2 minutes in a upright seated position',
  standingInstructions: 'Take measurements after 2 minutes standing unsupported',
  stepLabel: 'Step',
  testInstructionsTitle: 'Instructions',
  testInstructions: 'This is the modified Active Standing Test which was proposed by Pearson et al., 2022 as a measurement for orthostatic intolerances in concussed patients. This 3 part test requires a patient to be in 3 different positions, each for 2 minutes: Lying Supine, Sitting Upright, and Standing. After 2 minutes in each position the patients heart rate and blood pressure(using an automated sphygmomanometer) should be recorded in the boxes below.',
  showResultsCalculationLabel: 'Show Result Calculations',
  hideResultsCalculationLabel: 'Hide Result Calculations'
};

export default EN;
