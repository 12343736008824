import React from 'react';
import ReactPlayer from 'react-player';

const VideoPlayer = ({
  url,
  width,
  height,
  light,
  playing,
  config,
  previewPlayIcon,
  controls = true,
  style,
  onStart,
  onPause,
  onPreviewClick,
  className
}) => {
  if (!url) return null;

  return (
    <ReactPlayer
      stopOnUnmount
      url={url}
      width={width}
      height={height}
      light={light}
      playing={playing}
      controls={controls}
      playIcon={previewPlayIcon}
      onClickPreview={onPreviewClick}
      onStart={onStart}
      config={config}
      onPause={onPause}
      style={style}
      className={className}
    />
  );
};

export default VideoPlayer;
