import React from 'react';
import classnames from 'classnames';
import Popper from '../popper';
import { MenuItem, MenuLink } from './menu-item';
import { DropdownProvider } from './context';

const Dropdown = ({
  isOpen = false,
  trigger,
  className,
  fixed = false,
  portal = false,
  placement = 'bottom',
  offset,
  onClose = () => {},
  children
}) => {
  return (
    <DropdownProvider value={{ close: onClose }}>
      <Popper
        isOpen={isOpen}
        offset={offset}
        portal={portal}
        className={classnames('dropdown', className)}
        portalClassName="dropdown-portal"
        fixed={fixed}
        onClose={onClose}
        placement={placement}
        content={(
          <ul className="menu">
            {children}
          </ul>
        )}
      >
        {trigger}
      </Popper>
    </DropdownProvider>
  );
};

export default Dropdown;
export { MenuItem, MenuLink };
