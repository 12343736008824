import { UPDATE_NOTIFICATION, UPDATE_NOTIFICATIONS } from '../actions/notifications';

const updateNotificationReducer = (state = {}, action = {}) => {
  const { userId, notification = {} } = action;
  const { id = 0 } = notification;
  const userState = state[userId] || {};
  const nextUserState = (id) ? { ...userState, [id]: notification } : userState;
  return (userId) ? { ...state, [userId]: nextUserState } : state;
};

const updateNotificationsReducer = (state = {}, action = {}) => {
  const { userId, notifications = [] } = action;
  const nextUserState = notifications.reduce((userState, notification) => {
    const { id = 0 } = notification;
    return (id) ? { ...userState, [id]: notification } : userState;
  }, state[userId] || {});

  return (userId) ? { ...state, [userId]: nextUserState } : state;
};

const notifications = (state = {}, action = {}) => {
  switch (action.type) {
    case UPDATE_NOTIFICATION:
      return updateNotificationReducer(state, action);
    case UPDATE_NOTIFICATIONS:
      return updateNotificationsReducer(state, action);
    default:
      return state;
  }
};

export default notifications;
