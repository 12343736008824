import React, { useCallback, useMemo } from 'react';
import dayjs from 'dayjs';
import { DISPLAY_DATE_TIME_FORMAT } from '@/utilities/dates/formats';
import { BaselineTestLabels, getBaselineProfile } from '../baseline-utilities';
import Strings from './lang';

const getCurrentStep = (version, incompleteTests = []) => {
  return getBaselineProfile(version).find(key => incompleteTests.includes(key));
};

const UserBaselineRow = ({ baseline, onSelect = () => {} }) => {
  const { 
    user, 
    created_at, 
    updated_at, 
    incomplete_tests,
    test_version = 2
  } = baseline;
  const { person = {}, account } = user || {};
  const { first_name, last_name } = person || {};
  const currentStep = useMemo(() => (
    getCurrentStep(test_version, incomplete_tests)
  ), [incomplete_tests, test_version]);

  const handleKeyPress = useCallback((e) => {
    if (e.key === 'Enter') onSelect();
  }, [onSelect]);

  return (
    <tr 
      tabIndex={0} 
      onKeyPress={handleKeyPress}
      onClick={onSelect}
    >
      <td><strong className="primary">{first_name} {last_name}</strong></td>
      <td>{account}</td>
      <td>{BaselineTestLabels[currentStep] || null}</td>
      <td>{dayjs(created_at).format(DISPLAY_DATE_TIME_FORMAT)}</td>
      <td>{dayjs(updated_at).format(DISPLAY_DATE_TIME_FORMAT)}</td>
    </tr>
  );
};

const UserBaselinesTable = ({ baselines, onSelect }) => {
  if (!baselines.length) {
    return (
      <p className="alert alert-info text-center">
        {Strings.emptyResultsMessage} 
      </p>
    );
  }

  return (
    <div className="table-responsive-md">
      <table className="table continue-baseline-table">
        <thead>
          <tr>
            <th className="no-action">{Strings.tableNameLabel}</th>
            <th className="no-action">{Strings.tableAccountLabel}</th>
            <th className="no-action">{Strings.tableCurrentStepLabel}</th>
            <th className="no-action">{Strings.tableStartedAtLabel}</th>
            <th className="no-action">{Strings.tableUpdatedAtLabel}</th>
          </tr>
        </thead>
        <tbody>
          {baselines.map(baseline => (
            <UserBaselineRow 
              key={baseline.id}
              baseline={baseline} 
              onSelect={() => onSelect(baseline)}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UserBaselinesTable;
