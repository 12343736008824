const BAGTAG_REGEX = /[\d]+-.+/;

const filterBagtagInput = (input) => {
  if (BAGTAG_REGEX.test(input)) {
    return input.toUpperCase();
  }

  return input;
};

export default filterBagtagInput;
