const EN = {
  title: 'Confirm Your Account',
  emailLabelText: 'Email',
  emailInputPlaceholder: 'user@example.com',
  confirmButtonText: 'Send Confirmation Email',
  termsErrorText: 'You must accept the terms \u0026 conditions to register',
  confirmationPendingText: 'Please wait while we confirm your account',
  confirmationSuccessText: 'Your account has been confirmed',
  confirmationFailedText: 'We could not confirm your account',
  authenticationLinkText: 'Sign In',
  resendLinkText: 'Resend Confirmation Email',
  resendRequestSuccessText: 'We have resent your confirmation link. Please check your email.'
};

export default EN;
