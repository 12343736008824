import { UPDATE_CLINIC_STATISTICS } from '../actions/clinic-statistics';

const updateClinicStatisticsReducer = (state = {}, action = {}) => {
  const { clinicId = 0, stats = [] } = action;
  return (clinicId) ? { ...state, [clinicId]: stats } : state;
};

const clinicStatistics = (state = {}, action = {}) => {
  switch (action.type) {
    case UPDATE_CLINIC_STATISTICS:
      return updateClinicStatisticsReducer(state, action);
    default:
      return state;
  }
};

export default clinicStatistics;
