import { useCallback, useEffect, useState } from 'react';

const addListener = (mql, handler) => {
  if (mql.addEventListener) {
    mql.addEventListener('change', handler);
  } else {
    mql.addListener(handler);
  }
};

const removeListener = (mql, handler) => {
  if (mql.removeEventListener) {
    mql.removeEventListener('change', handler);
  } else {
    mql.removeListener(handler);
  }
};

export const useMediaQuery = (query) => {
  const [match, setMatch] = useState(false);

  const handleChange = useCallback((event) => {
    setMatch(event.matches);
  }, []);

  useEffect(() => {
    const mql = matchMedia(query);
    setMatch(mql.matches);
    addListener(mql, handleChange);
    return () => {
      removeListener(mql, handleChange);
    };
  }, [handleChange, query]);

  return match;
};

export const useBreakpoint = (size = 0) => {
  return useMediaQuery(`(max-width: ${size}px)`);
};
