const FR = {
  trialColumnTitle: 'Essais',
  durationColumnTitle: 'Durée (seconds)',
  outcomeColumnTitle: 'Réussite/échec',
  trialNumberLabel: 'Essai {0}',
  fastestTimeLabel: 'Temps le plus rapide (s)',
  averageTimeLabel: 'Temps moyen (s)',
  secondsLabel: 'seconds',
  startTimerLabel: 'Démarrer la minuterie',
  stopTimerLabel: 'Arrêter la minuterie',
  resetTimerLabel: 'Réinitialiser la minuterie'
};
 
export default FR;
