import Strings from './lang';

/*!
 * Object container for all default symptom values. Intended to be
 * injected in as a prop to the SymptomInputGroup component.
 */

const symptomLabels = {
  headache: Strings.headache,
  head_pressure: Strings.headPressure,
  neck_pain: Strings.neckPain,
  nausea: Strings.nausea,
  dizziness: Strings.dizziness,
  blurred_vision: Strings.blurredVision,
  balance_problems: Strings.balance,
  light_sensitivity: Strings.lightSensitive,
  noise_sensitivity: Strings.noiseSensitive,
  feeling_slowed_down: Strings.slowedDown,
  feeling_in_a_fog: Strings.inAFog,
  dont_feel_right: Strings.dontFeelRight,
  difficulty_concentrating: Strings.concentration,
  difficulty_remembering: Strings.remembering,
  fatigue: Strings.fatigue,
  confusion: Strings.confusion,
  drowsiness: Strings.drowsiness,
  trouble_falling_asleep: Strings.fallingAsleep,
  more_emotional: Strings.emotional,
  irritability: Strings.irritability,
  sadness: Strings.sadness,
  nervous: Strings.nervous
};

export default symptomLabels;
