const EN = {
  personalCardTitle: 'Personal',
  firstNameLabelText: 'First Name',
  firstNameErrorText: 'Please enter your first name.',
  lastNameLabelText: 'Last Name',
  lastNameErrorText: 'Please enter your last name.',
  nameLabelText: 'Name',
  nameValidationMessage: 'Please enter emergency contact name.',
  birthdateLabelText: 'Date of Birth',
  birthdateValidationMessage: 'Please enter a valid date of birth.',
  genderLabelText: 'Gender',
  genderValidationMessage: 'Please select your gender.',
  genderOtherLabelText: 'What is your gender?',
  genderOtherValidationMessage: 'Please enter your gender',
  accountNumberLabelText: 'Account Number',
  trainingDateLabelText: 'Trained At',
  trainingDateErrorMessage: 'Please enter a valid date of training',
  accountNumberValidationMessage: 'Please enter your account number.',
  bagTagLabelText: 'Concussion ID Card',
  bagTagValidationMessage: 'Please enter your ID card number.',
  languageLabelText: 'Language',
  languageValidationMessage: 'Please enter your language.',
  addressLabelText: 'Address',
  cityLabelText: 'City / Town',
  regionLabelText: 'State / Province',
  countryLabelText: 'Country',
  postalCodeLabelText: 'Zip / Postal Code',
  phoneNumberLabelText: 'Phone Number',
  faxNumberLabelText: 'Fax Number',
  phoneNumberValidationMessage: 'Please enter a valid phone number.',
  faxNumberValidationMessage: 'Please enter a valid fax number.',
  emailLabelText: 'Email',
  emailValidationMessage: 'Please enter your email.',
  emergencyContactLabelText: 'Emergency Contact',
  emergencyContactEmailLabelText: 'Emergency Contact Email',
  emergencyContactPhoneLabelText: 'Emergency Contact Phone',
  emergencyContactNameValidationMessage: 'Please enter your emergency contact\'s name.',
  emergencyContactRelationshipLabelText: 'Emergency Contact Relationship',
  emergencyContactRelationshipValidationMessage: 'Please enter your relationship to your emergency contact person.',
  organizationLabelText: 'Organization',
  sportLabelText: 'Sport',
  levelLabelText: 'Level',
  teamLeadersLabelText: 'Team Leaders (Coach/Trainer/Teacher)',
  positionLabelText: 'Position(s)',
  addAnotherLinkText: 'Add Another',
  credentialsInputLabelText: 'Credentials (Press Enter to Add a Credential)',
  credentialsLabelText: 'Credentials',
  credentialInputPlaceholder: 'Please use acronyms e.g. D.C., M.D., etc... (Press Enter to Add)',
  bioLabelText: 'Bio',
  bioErrorText: 'Please enter your bio.',
  clinicUrlLabelText: 'Website',
  submitButtonText: 'Save',
  birthdateConfirmationMessage: '{2}. By continuing you confirm that this patient is {0} {1} old.',
  under1: 'under 1',
  year: 'year',
  years: 'years',
  professionLabelText: 'Profession',
  servicesLabelText: 'Treatment Services',
  servicesPlaceholder: 'Select all that apply',
  defaultProfessionPlaceholder: 'Select Profession',
  professionErrorMessage: 'Please select a profession'
};

export default EN;
