const FR = {
  dateLabelText: 'Date',
  dateErrorText: 'Veuillez écrire une date valide.',
  emailLabelText: 'Courriel :',
  emailErrorText: 'Veuillez écrire une adresse courriel valide',
  genderLabelText: 'Sexe',
  genderErrorText: 'Veuillez choisir votre sexe.',
  genderNoValueText: 'Choisissez le sexe',
  languageLabelText: 'Langue',
  languageErrorText: 'Veuillez choisir votre langue de préférence.',
  languageSelectValueText: 'Veuillez choisir',
  passwordLabelText: 'Mot de passe',
  passwordErrorText: 'Veuillez indiquer votre mot de passe.',
  confirmPasswordLabelText: 'Confirmez le mot de passe :',
  confirmPasswordErrorText: 'Vos mots de passe doivent être identiques.',
  passwordScore0Text: 'Très faible',
  passwordScore1Text: 'Faible',
  passwordScore2Text: 'Moyen',
  passwordScore3Text: 'Fort',
  passwordScore4Text: 'Très fort',
  phoneLabelText: 'Numéro de téléphone',
  phoneErrorText: 'Veuillez écrire un numéro de téléphone valide',
  urlLabelText: 'Site Web',
  urlErrorText: 'Veuillez indiquer une URL de site Web valide',
  selectLabelText: 'Choisir',
  selectErrorText: 'Veuillez choisir une option.',
  numberLabelText: 'Nombre',
  numberErrorText: 'Veuillez écrire un nombre valide',
  countryLabelText: 'Pays',
  countryErrorText: 'Veuillez choisir un pays.',
  guardianNoValueText: 'Êtes-vous un tuteur ?',
  guardianYesLabel: 'Oui',
  guardianNoLabel: 'Non',
  guardianErrorText: 'Veuillez choisir une section',
  guardianLabelText: 'Gardien',
  countrySelectDefaultOptionText: 'Choisissez le pays',
  regionSelectDefaultOptionText: 'Choisissez une région'
};
 
export default FR;
