import React, { useMemo, useState } from 'react';
import Modal from '../../modal';
import ProductFilters from './product-filters';
import Icon from '../../icon';
import Strings from '../lang';

const ProductFiltersModal = ({
  isOpen = false,
  filters = {},
  category = null,
  productCount = 0,
  brands = [],
  onFilterChange = () => {},
  onClose = () => {}
}) => {
  return (
    <Modal
      fullScreen
      isOpen={isOpen}
      onClose={onClose}
    >
      <Modal.Header onClose={onClose}>
        <h2>{Strings.productFiltersTitle}</h2>
      </Modal.Header>
      <Modal.Body>
        <p className="text-center">
          {Strings.formatString(
            productCount === 1 
              ? Strings.productResultsCountLabel 
              : Strings.productResultsPluralCountLabel, 
            productCount
          )}
        </p>
        <ProductFilters 
          filters={filters}
          brands={brands}
          category={category}
          onChange={onFilterChange}
        />
      </Modal.Body>
      <Modal.Footer>
        <button 
          type="button" 
          className="btn btn-primary btn-block" 
          onClick={onClose}
        >
          {Strings.viewResultsButtonLabel}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

const ProductFiltersModalButton = ({
  filters = {},
  productCount = 0,
  category = null,
  brands = [],
  onFilterChange,
}) => {
  const [open, setOpen] = useState(false);
  const filterCount = useMemo(() => {
    return [category, ...brands].filter(Boolean).length;
  }, [brands, category]);

  return (
    <div className="product-filters-modal-button">
      <button type="button" className="btn btn-primary btn-sm" onClick={() => setOpen(true)}>
        <Icon name="filter-list" />&nbsp;&nbsp;
        {Strings.filtersLabel} {filterCount > 0 ? `(${filterCount})` : ''}
      </button>
      <ProductFiltersModal 
        isOpen={open}
        filters={filters}
        brands={brands}
        productCount={productCount}
        category={category}
        onFilterChange={onFilterChange}
        onClose={() => setOpen(false)}
      />
    </div>
  );
};

export default ProductFiltersModalButton;
