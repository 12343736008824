const EN = {
  resourcesTitle: 'Resources',
  emptyFolderMessage: 'This folder appears to be empty',
  homeBreadcrumbTitle: 'Home',
  folderListTitle: 'Folders',
  fileListTitle: 'Files',
  noSearchResultsMessage: 'No resources found for your search',
  loadingText: 'Loading Files',
  searchingText: 'Searching Files',
  searchPlaceholder: 'Search Resources',
  clearSearchTitle: 'Clear search value and results',
  courseVideosLink: 'Course Videos'
};

export default EN;
