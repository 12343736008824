import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowRightLong,
  faArrowRotateLeft,
  faArrowRotateRight,
  faArrowsRotate,
  faArrowTurnDownLeft,
  faArrowUp,
  faBadgePercent,
  faBan,
  faBars,
  faBell,
  faBookmark,
  faBolt,
  faBoxArchive,
  faCalendarDay,
  faCalendarLinesPen,
  faCalendarRange,
  faCaretDown,
  faCaretRight,
  faChartLine,
  faChartMixed,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircleArrowRight,
  faCircleCheck,
  faCircleExclamation,
  faCircleInfo,
  faCircleQuestion,
  faCircleXmark,
  faCircleUser,
  faClipboard,
  faClock,
  faCodePullRequest,
  faComment,
  faCreditCard,
  faDownload,
  faEarthAmerica,
  faEllipsisH,
  faEllipsisV,
  faEnvelope,
  faExclamation,
  faExternalLink,
  faEye,
  faEyeSlash,
  faFileExcel,
  faFileInvoiceDollar,
  faFileLines,
  faFileMedical,
  faFilePdf,
  faFilePen,
  faFilesMedical,
  faFileWord,
  faFilterList,
  faFolderMagnifyingGlass,
  faFolderClosed,
  faFolderOpen,
  faFutbol,
  faGear,
  faHouseMedical,
  faHouseMedicalFlag,
  faImages,
  faKeyboard,
  faLanguage,
  faLinkSlash,
  faList,
  faListCheck,
  faListOl,
  faLock,
  faLockOpen,
  faMagnifyingGlass,
  faMagnifyingGlassLocation,
  faMapLocationDot,
  faMessageMedical,
  faMinus,
  faPaperPlane,
  faPenToSquare,
  faPlay,
  faPlus,
  faPlusCircle,
  faRectangleList,
  faRedo,
  faRepeat,
  faRightFromBracket,
  faSliders,
  faSpinner,
  faSquareCaretRight,
  faStop,
  faTags,
  faTrashCan,
  faTriangleExclamation,
  faUndo,
  faUpload,
  faUser,
  faUsers,
  faUserDoctor,
  faUserHelmetSafety,
  faUsersMedical,
  faXmark,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faBadgeCheck,
  faBold,
  faBolt as faBoltSolid,
  faCaretDown as faCaretDownSolid,
  faCaretRight as faCaretRightSolid,
  faCaretUp as faCaretUpSolid,
  faCircleCheck as faCircleCheckSolid,
  faCirclePlus as faCirclePlusSolid,
  faEraser,
  faGridRound,
  faIndent,
  faItalic,
  faList as faListSolid,
  faListOl as faListOlSolid,
  faOutdent,
  faQuoteRight,
  faStrikethrough,
  faTriangleExclamation as faTriangleExclamationSolid
} from '@fortawesome/pro-solid-svg-icons';
import {
  faLoader
} from '@fortawesome/pro-duotone-svg-icons';

const registerIcons = () => {
  library.add(
    faArrowDown,
    faArrowLeft,
    faArrowRight,
    faArrowRightLong,
    faArrowRotateLeft,
    faArrowRotateRight,
    faArrowsRotate,
    faArrowTurnDownLeft,
    faArrowUp,
    faBadgeCheck,
    faBadgePercent,
    faBan,
    faBars,
    faBell,
    faBookmark,
    faBold,
    faBolt,
    faBoltSolid,
    faBoxArchive,
    faCalendarDay,
    faCalendarLinesPen,
    faCalendarRange,
    faCaretDown,
    faCaretDownSolid,
    faCaretRight,
    faCaretRightSolid,
    faCaretUpSolid,
    faChartLine,
    faChartMixed,
    faCheck,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faCircleArrowRight,
    faCircleCheck,
    faCircleCheckSolid,
    faCircleExclamation,
    faCircleInfo,
    faCirclePlusSolid,
    faCircleQuestion,
    faCircleUser,
    faCircleXmark,
    faClipboard,
    faClock,
    faCodePullRequest,
    faComment,
    faCreditCard,
    faDownload,
    faEarthAmerica,
    faEllipsisH,
    faEllipsisV,
    faEnvelope,
    faEraser,
    faExclamation,
    faExternalLink,
    faEye,
    faEyeSlash,
    faFileExcel,
    faFileInvoiceDollar,
    faFileLines,
    faFileMedical,
    faFilePdf,
    faFilePen,
    faFilesMedical,
    faFileWord,
    faFilterList,
    faFolderMagnifyingGlass,
    faFolderClosed,
    faFolderOpen,
    faFutbol,
    faGear,
    faGridRound,
    faHouseMedical,
    faHouseMedicalFlag,
    faImages,
    faIndent,
    faItalic,
    faKeyboard,
    faLanguage,
    faLinkSlash,
    faList,
    faListCheck,
    faListOl,
    faListOlSolid,
    faListSolid,
    faLoader,
    faLock,
    faLockOpen,
    faMagnifyingGlass,
    faMagnifyingGlassLocation,
    faMapLocationDot,
    faMessageMedical,
    faMinus,
    faOutdent,
    faPaperPlane,
    faPenToSquare,
    faPlay,
    faPlus,
    faPlusCircle,
    faQuoteRight,
    faRectangleList,
    faRedo,
    faRepeat,
    faRightFromBracket,
    faSliders,
    faSpinner,
    faSquareCaretRight,
    faStop,
    faStrikethrough,
    faTags,
    faTrashCan,
    faTriangleExclamation,
    faTriangleExclamationSolid,
    faUndo,
    faUpload,
    faUser,
    faUsers,
    faUserDoctor,
    faUserHelmetSafety,
    faUsersMedical,
    faXmark
  );
};

export default registerIcons;
