import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Else, If, Then } from 'react-if';
import BaselineTestCardPropTypes from '../baseline-test-card-proptypes';
import BESSFormCard from './bess-card';
import WESSCardForm from './wess-card';
import Card from '../../../card';
import BalanceTestTypeSelector from './balance-test-type-selector';
import HeaderStrings from '../../lang';
import { AddNoteButton } from '../../../buttons';
import Strings from '../lang';

class BalanceTestingCard extends Component {
  constructor(props) {
    super(props);

    const { balance = {}, isWess } = props;

    this.onSubmit = this.onSubmit.bind(this);
    this.testRef = React.createRef();

    this.state = {
      is_wess: isWess ?? (balance?.is_wess || false),
      bess_notes: balance.bess_notes || ''
    };
  }

  render() {
    const showTestSelector = this.props.showTestSelector ?? !(
      Object.keys(this.props.balance || {}).length > 0 && !this.props.balance?.no_done
    );

    return (
      <Card
        title={HeaderStrings.balanceTestingHeader}
        actionButtons={(
          <AddNoteButton
            note={this.state.bess_notes}
            title={
              Strings.formatString(
                Strings.abStractNotesTitle,
                HeaderStrings.balanceTestingHeader
              )
            }
            submitButtonTitle={Strings.addNoteButtonText}
            onSave={(notes) => {
              this.setState({ bess_notes: notes });
            }}
          />
        )}
      >
        <div className="balance-baseline-card">
          <div className="card-section balance-test-type-header">
            <h3>{this.state.is_wess ? Strings.wessTitle : Strings.bessTitle}</h3>
            {showTestSelector && (
              <BalanceTestTypeSelector
                isWess={this.state.is_wess}
                onToggle={toggled => this.setState({ is_wess: toggled })}
              />
            )}
          </div>
          <If condition={this.state.is_wess}>
            <Then>
              <WESSCardForm
                ref={this.testRef}
                balance={this.props.balance}
                saveButtonText={this.props.saveButtonText}
                showSubmitButton={typeof this.props.onSubmit === 'function'}
                onSubmit={this.onSubmit}
              />
            </Then>
            <Else>
              <BESSFormCard
                ref={this.testRef}
                balance={this.props.balance}
                saveButtonText={this.props.saveButtonText}
                showSubmitButton={typeof this.props.onSubmit === 'function'}
                onSubmit={this.onSubmit}
              />
            </Else>
          </If>
        </div>
      </Card>
    );
  }

  getAttributes(validate = true) {
    const attributes = this.testRef.current.getAttributes(validate);
    
    return attributes && {
      balance_test: {
        ...attributes,
        is_wess: this.state.is_wess,
        bess_notes: this.state.bess_notes
      }
    };
  }

  onSubmit() {
    const attributes = this.getAttributes(false);
    if (attributes && typeof this.props.onSubmit === 'function') {
      this.props.onSubmit(attributes);
    }
  }
}

BalanceTestingCard.propTypes = {
  ...BaselineTestCardPropTypes,
  balance: PropTypes.object
};

export default BalanceTestingCard;
