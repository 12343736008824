import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SymptomInputGroup from './symptom-input-group';
import { MAX_SEVERITY, getSeverity, getTotals } from './symptom-utilities';
import ScoreView from '../score-view';
import Strings from './lang';

const maxSeverity = 6;

const getScores = (symptoms) => {
  return {
    score: getSeverity(symptoms), 
    total: getTotals(symptoms)
  };
};

/*!
 * A higher level component that controls the overall state of the symptom levels.
 */
class SymptomLevels extends Component {
  constructor(props) {
    super(props);

    const { symptoms = {} } = props;
    const { score, total } = getScores(symptoms);

    this.onUpdate = this.onUpdate.bind(this);
    this.onUpdateNotes = this.onUpdateNotes.bind(this);

    this.state = {
      symptoms,
      severityScore: score,
      symptomTotal: total
    };
  }

  render() {
    const { type = 'radio' } = this.props;
    const numSymptoms = Object.keys(this.state.symptoms).length;

    return (
      <div className={this.props.className}>
        <SymptomInputGroup
          type={type}
          groupClassName={this.props.groupClassName}
          inputClassName={this.props.inputClassName}
          symptoms={this.state.symptoms}
          maxSeverity={maxSeverity}
          required={this.props.required}
          onUpdate={this.onUpdate}
          touched={this.props.touched}
          onUpdateNotes={this.onUpdateNotes}
        />
        <ScoreView
          label={Strings.severityScoreLabel}
          score={this.state.severityScore}
          outOf={MAX_SEVERITY * numSymptoms}
        />
        <ScoreView
          label={Strings.symptomTotalsLabel}
          score={this.state.symptomTotal}
          outOf={numSymptoms}
        />
      </div>
    );
  }

  onUpdate(name, value) {
    const { symptoms } = this.state;
    const { onUpdate = () => {} } = this.props;
    const newSymptoms = { ...symptoms };

    newSymptoms[name].level = parseInt(value, 10);
    const { score, total } = getScores(newSymptoms);
    this.setState({
      symptoms: newSymptoms,
      symptomTotal: total,
      severityScore: score
    });

    onUpdate(newSymptoms);
  }

  onUpdateNotes(name, notes) {
    const { symptoms } = this.state;
    const { onUpdate = () => {} } = this.props;

    const newSymptoms = { ...symptoms };
    newSymptoms[name].notes = notes;

    this.setState({ symptoms: newSymptoms });

    onUpdate(symptoms);
  }
}

SymptomLevels.propTypes = {
  type: PropTypes.oneOf(['radio', 'select']),
  symptoms: PropTypes.objectOf(
    PropTypes.shape({
      level: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      notes: PropTypes.string
    })
  ),
  required: PropTypes.bool,
  touched: PropTypes.bool,
  groupClassName: PropTypes.string,
  onUpdate: PropTypes.func
};

export default SymptomLevels;
