const getInvoiceStatusClassName = (status) => {
  switch (status) {
    case 'open':
      return 'primary';
    case 'closed':
      return 'yellow';
    case 'overdue':
      return 'red';
    case 'paid':
    default:
      return 'green';
  }
};

export {
  getInvoiceStatusClassName
};
