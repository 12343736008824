const EN = {
  baselineSymptomsHeader: 'Baseline Test Symptom Levels',
  currentRatedSymptomsHeader: 'Current Rated Symptoms',
  noActiveBaselineWarning: 'This patient has no active baseline test',
  symptomExertionTitleText: 'Exertion: Do symptoms get worse with:',
  physicalActivityLabel: 'Physical Activity',
  mentalActivityLabel: 'Mental Activity',
  activityYesLabel: 'Yes',
  activityNoLabel: 'No',
  activityHaventTriedLabel: 'Haven\'t Tried',
  overallRatingLabel: 'Overall Rating',
  overallRatingInfoText: 'If a parent, guardian, or spouse is in the room, ask "how different is the person acting compared to their usual self?"',
  overallRatingNormalText: '(Normal)',
  overallRatingVeryDifferentText: '(Very Different)',
  noValueSelectedErrorMessage: 'Please select a value'
};

export default EN;
