const FR = {
  baselineTestPageHeading: 'Test de référence',
  confirmPatientInformationHeader: 'Confirmer les informations sur le patient',
  confirmAndContinueText: 'Confirmer et continuer',
  confirmProgressInfoText: 'Veuillez confirmer les noms des personnes et la progression du test de référence',
  confirmMedicalTitle: 'Confirmer les antécédents médicaux',
  submitMedicalButtonText: 'Enregistrer et commencer le test de référence',
  baselineStepProgressTitle: 'Progression du test de référence',
  baselineContinueCardTitle: 'Continuer la progression du test de référence',
  continueTestButtonText: 'Continuer le test de référence',
  wrongPersonButtonText: 'Mauvaise personne?',
  skippedLabelText: 'Sauté',
  skipButtonText: 'Sauter',
  unsavedFieldsWarningTitle: 'Abandonner l\'étape?',
  unsavedFieldsWarningMessage: 'Certains champs ont été modifiés sans être enregistrés. Si vous continuez, tous les changements seront perdus. Voulez-vous continuer?',
  continueButtonText: 'Continuer',
  unauthorizedHeaderText: 'Accès interdit',
  unauthorizedDescriptionText: 'Vous ne disposez pas des permissions requises pour voir ou modifier cette section du test de référence Cette section ne peut être vue et modifiée que par {0} utilisateurs',
  saveProgressButtonText: 'Sauvegarder et continuer',
  baselineCompleteCardTitle: 'Test de référence terminé',
  baselineCompleteInfoText: 'Le test de référence de {0} est maintenant terminé. Un courriel avec les instructions à suivre en cas de concussion, ainsi qu\'avec les liens de téléchargement de l’appli de suivi Concussion Tracker a été envoyé à l\'adresse : {1}. Aucune autre action requise.',
  redactedNameText: 'Ce patient',
  viewResultsLinkText: 'Voir les résultats',
  returnToDashboardLinkText: 'Retourner au tableau de bord',
  patientFileLinkText: 'Dossier de {0}',
  stepNotFoundMessage: 'étape de test de référence introuvable',
  loadingBaselineText: 'Chargement du test de référence ...',
  continueBaselineNotFoundError: 'Aucune référence trouvée pour continuer',
  ageLabel: 'Âge',
  unknownLabel: 'Inconnu'
};
 
export default FR;
