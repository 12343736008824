const EN = {
  title: 'DANA Tests',
  allCognitiveTestsLabel: 'All Cognitive Tests',
  srtLabel: 'Simple Reaction Time',
  prtLabel: 'Procedural Reaction Time',
  csLabel: 'Code Substitution',
  spLabel: 'Spatial Processing',
  gngLabel: 'Go No Go',
  mtsLabel: 'Match To Sample',
  msLabel: 'Memory Search',
  phq8Label: 'Patient Health Questionnaire 8',
  phq9Label: 'Patient Health Questionnaire 9',
  isiLabel: 'Insomnia Severity Index',
  gad7Label: 'Generalized Anxiety Disorder 7',
  reactionTimeLabel: 'Reaction Time',
  cognitiveEfficiencyLabel: 'Cognitive Efficiency',
  percentCorrectLabel: 'Percent Correct',
  surveyScoreLabel: 'Survey Score',
  baselineLabel: 'Baseline',
  postInjuryLabel: 'Post-Injury',
  phqStage1Description: 'None/Minimal Depression',
  phqStage2Description: 'Mild Depression',
  phqStage3Description: 'Moderate Depression',
  phqStage4Description: 'Moderately Severe Depression',
  phqStage5Description: 'Severe Depression',
  isiStage1Description: 'No clinically significant insomnia',
  isiStage2Description: 'Subthreshold insomnia',
  isiStage3Description: 'Moderate Severity Clinical Insomnia',
  isiStage4Description: 'Severe Clinical Insomnia',
  gad7Stage1Description: 'Minimal Anxiety',
  gad7Stage2Description: 'Mild Anxiety',
  gad7Stage3Description: 'Moderate Anxiety',
  gad7Stage4Description: 'Severe Anxiety',
  loadingMessage: 'Loading',
  batteryDetailsTitle: 'Neurocognitive Test',
  quickTestLabel: 'Quick Test',
  fullTestLabel: 'Full Test',
  sidelineLabel: 'Sideline',
  testsEmptyText: 'No DANA Tests Found',
  selectListTestMessage: 'Select a test from the list to view full test details',
  descriptionLabel: 'Description',
  meanReactionTimeLabel: 'Mean Reaction Time',
  meanReactionTimeCorrectLabel: 'Mean Reaction Time Correct',
  standardDeviationLabel: 'Standard Deviation',
  medianReactionTimeLabel: 'Median Reaction Time',
  numTrialsLabel: 'Number of Trials',
  percentLapseLabel: 'Percent Lapse',
  percentFastLabel: 'Percent Fast',
  phqQuestion1: 'Little interest or pleasure in doing things.',
  phqQuestion2: 'Feeling down, depressed, or hopeless.',
  phqQuestion3: 'Trouble falling or staying asleep, or sleeping too much.',
  phqQuestion4: 'Feeling tired or having little energy.',
  phqQuestion5: 'Poor appetite or over-eating.',
  phqQuestion6: 'Feeling bad about yourself — or that you are a failure or have let yourself or your family down.',
  phqQuestion7: 'Trouble concentrating on things, such as reading the newspaper or watching television.',
  phqQuestion8: 'Moving or speaking so slowly that other people could have noticed. Or the opposite — being so fidgety or restless that you have been moving around a lot more than usual.',
  phqQuestion9: 'Thoughts that you would be better off dead, or of hurting yourself in some way.',
  phqQuestion10: 'How difficult have these problems made it for you to do your work, take care of things at home, or get along with other people?',
  psychAnswerNotAtAll: 'Not At All',
  psychAnswerSeveralDays: 'Several days',
  psychAnswerMoreThanHalfDays: 'More than half the days',
  psychAnswerNearlyEveryDay: 'Nearly every day',
  psychAnswerNone: 'None',
  psychAnswerMild: 'Mild',
  psychAnswerModerate: 'Moderate',
  psychAnswerSevere: 'Severe',
  psychAnswerVerySevere: 'Very Severe',
  psychAnswerALittle: 'A little',
  psychAnswerSomewhat: 'Somewhat',
  psychAnswerMuch: 'Much',
  psychAnswerVeryMuch: 'Very much',
  psychAnswerNotDifficult: 'Not difficult at all',
  psychAnswerSomewhatDifficult: 'Somewhat difficult',
  psychAnswerVeryDifficult: 'Very difficult',
  psychAnswerExtremelyDifficult: 'Extremely difficult',
  psychAnswerVerySatisfied: 'Very satisfied',
  psychAnswerSatisfied: 'Satisfied',
  psychAnswerModeratelySatisfied: 'Moderately satisfied',
  psychAnswerDissatisfied: 'Dissatisfied',
  psychAnswerVeryDissatisfied: 'Very dissatisfied',
  isiQuestion1: 'Difficulty falling asleep?',
  isiQuestion2: 'Difficulty staying asleep?',
  isiQuestion3: 'Problem waking up too early?',
  isiQuestion4: 'How satisfied/dissatisfied are you with your current sleep pattern?',
  isiQuestion5: 'How noticeable to others do you think your sleep problem is in terms of impairing the quality of your life?',
  isiQuestion6: 'How worried/distressed are you about your current sleep problem?',
  isiQuestion7: 'To what extent do you consider your sleep problem to interfere with your daily functioning (e.g., daytime fatigue, mood, ability to function at work/daily chores, concentration, memory, mood, etc.) currently?',
  gad7Question1: 'Feeling nervous, anxious or on edge',
  gad7Question2: 'Not being able to stop or control worrying',
  gad7Question3: 'Worrying too much about different things',
  gad7Question4: 'Trouble relaxing',
  gad7Question5: 'Being so restless that it is hard to sit still',
  gad7Question6: 'Becoming easily annoyed or irritable',
  gad7Question7: 'Feeling afraid as if something awful might happen',
  gad7QuestionSummary: 'How difficult have these problems made it for you to do your work, take care of things at home, or get along with other people?',
  trialsAcceptableDescription: 'Test Passed',
  trialsUnacceptableDescription: 'Below average number of trials correct',
  trialsIncompleteDescription: 'Test Failed',
  baselineReferenceLineText: 'Baseline',
  firstUpperStdReferenceLineText: '1st Upper',
  firstLowerStdReferenceLineText: '1st Lower',
  secondUpperStdReferenceLineText: '2nd Upper',
  secondLowerStdReferenceLineText: '2nd Lower',
  rtDifferenceMetricLabel: 'Reaction Time Diff.',
  rtDifferenceChartLabel: '{0}2 - {0}1 RT Difference',
  rtDifferenceStdevChartLabel: '{0}1 Std. Dev.',
  allTestsSelectorLabel: 'All',
  baselineTestsSelectorLabel: 'Baselines',
  postInjuryTestsSelectorLabel: 'Post-Injury',
  responseTimeLabel: 'Response Time',
  malingeringLabel: 'Malingering',
  correctLabel: 'Correct',
  incorrectLabel: 'Incorrect',
  lapsedLabel: 'Lapsed',
  fastLabel: 'Too Fast',
  trialLabel: 'Trial {0}',
  invalidText: 'Invalid',
  malingeringAvgLabel: 'Malingering Avg.',
  nonMalingeringAvgLabel: 'Non-Malingering Avg.',
  validTestTabLabel: 'Valid',
  invalidTestTabLabel: 'Invalid',
  summaryReportButton: 'Summary Report',
  downloadingText: 'Downloading',
  inMillisecondsLabel: 'in milliseconds',
  responsesPerMinuteLabel: 'responses per minute',
  summaryReportSubtitle: 'DANA Tests performed between {0} and {1}',
  cognitiveTestTableColumnLabel: 'Cognitive Test',
  psychTestTableColumnLabel: 'Psychological Survey',
  summaryLabel: 'Summary',
  reportGeneratedByLabel: 'Generated by {0} {1} on {2}',
  reportPageIndicator: 'Page {0}/{1}',
  patientLabel: 'Patient',
  dateOfBirthLabel: 'Date of Birth',
  phoneLabel: 'Phone',
  patientInfoTitle: 'Patient Information',
  nameLabel: 'Name',
  accountLabel: 'Account',
  genderLabel: 'Gender',
  genderNotProvided: 'Not Provided',
  languageLabel: 'Language',
  unknownLabel: 'Unknown',
  baselineQuickTestTitle: 'DANA Baseline Quick Test',
  baselineFullTestTitle: 'DANA Baseline Full Test',
  postInjuryQuickTestTitle: 'DANA Post-Injury Quick Test',
  postInjuryFullTestTitle: 'DANA Post-Injury Full Test'
};

export default EN;
