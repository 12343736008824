const FR = {
  profileTabLabel: 'Profil',
  sportsTabLabel: 'Sports',
  medicalInfoTabLabel: 'Informations médicales',
  baselineHistoryTabLabel: 'Informations de référence',
  injuryHistoryTabLabel: 'Antécédents de blessures',
  injuryReportsTabLabel: 'Rapports de blessure',
  managePatientsTitleText: 'Gérer les patients',
  dashboardTitleText: 'Dashboard',
  accountDeactivatedBannerMessage: 'Ce compte a été désactivé',
  loadingPatientProfileText: 'Chargement du dossier du patient',
  assignBagTagButtonText: 'Attribuer la carte d’identité',
  sendImpactTestButtonText: 'Envoyer le test ImPACT de base',
  danaTestTabLabel: 'Tests DANA',
  unauthorizedTitle: 'Non autorisé',
  unauthorizedMessage: 'Vous n\'êtes pas autorisé à consulter le dossier de ce patient',
  patientNotFoundTitle: 'Non trouvé',
  patientNotFoundMessage: 'Patient non trouvé'
};
 
export default FR;
