import React, { useCallback, useState } from 'react';
import Modal from '../../modal';
import { AppointmentStatusStrings } from '../utils';
import { FormComponent, FormInputGroup } from '@/forms';
import Strings from '../lang';

const AppointmentStatusForm = ({ onSubmit }) => {
  const [notes, setNotes] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const isValid = notes.trim().length > 0;

  const handleSubmit = useCallback(() => {
    setSubmitted(true);

    if (isValid) {
      onSubmit({
        notes
      });
    }
  }, [isValid, notes, onSubmit]);

  return (
    <FormComponent onSubmit={handleSubmit}>
      <FormInputGroup
        required
        className="form-group"
        touched={submitted}
        labelText={Strings.notesLabel}
        inputType="textarea"
        inputProps={{
          className: 'form-control',
          value: notes,
          rows: 5,
          maxRows: 10,
          onChange: (e) => setNotes(e.target.value)
        }}
        messageText={Strings.notesErrorMessage}
        messageClassName="alert alert-danger"
        inputValid={notes.trim().length > 0}
      />
      <div className="form-footer">
        <button type="submit" className="btn btn-primary" disabled={!isValid}>
          {Strings.updateApptStatusModalButton}
        </button>
      </div>
    </FormComponent>
  );
};

const AppointmentStatusModal = ({
  open,
  status,
  onSubmit,
  onClose
}) => {
  const handleSubmit = useCallback(({ notes }) => {
    onSubmit({
      status,
      notes
    });
  }, [onSubmit, status]);

  return (
    <Modal
      blur
      isOpen={open}
      onClose={onClose}
    >
      <Modal.Header onClose={onClose}>
        <h2>{Strings.updateApptStatusModalTitle}</h2>
      </Modal.Header>
      <Modal.Body>
        <p
          dangerouslySetInnerHTML={{
            __html: Strings.formatString(
              Strings.updateApptStatusModalMessage,
              AppointmentStatusStrings[status] ?? ''
            )
          }}
        />
        <AppointmentStatusForm onSubmit={handleSubmit} />
      </Modal.Body>
    </Modal>
  );
};

export default AppointmentStatusModal;
