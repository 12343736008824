const EN = {
  injuryDateLabelText: 'Date of Injury',
  assessmentDateLabelText: 'Assessment Date',
  practitionerLabelText: 'Practitioner',
  clinicLabelText: 'Clinic',
  accountLabelText: 'Account',
  bagtagLabelText: 'ID Card Number',
  birthdateLabelText: 'Date of Birth',
  ageLabelText: 'Age',
  genderLabelText: 'Gender',
  daysSinceInjuryLabelText: 'Days Since Injury',
  cityLabelText: 'City / Town',
  regionLabelText: 'Province or State',
  phoneLabelText: 'Phone',
  faxLabelText: 'Fax',
  noAssessments: 'No Initial Assessment Found',
  notApplicable: 'N/A',
  testDateLabel: 'Date Performed',
  languageLabelText: 'Language',
  notSpecifiedText: 'Not Specified'
};

export default EN;
