const EN = {
  coursesTitle: 'Course Videos',
  resourcesLabel: 'Resources',
  coursesLabel: 'Courses',
  videosLabel: 'Videos',
  videoCount: '{0} Videos',
  moduleLabel: 'Module'
};

export default EN;
