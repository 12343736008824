const FR = {
  title: 'Sports',
  guardianNoSportsAlertMessage: 'Si {0} ne joue pas dans un sport organisé,',
  noSportsAlertMessage: 'Si vous ne jouez pas dans des sports organisés,',
  skipStepText: 'Passer cette étape',
  confirmMessage: 'Est-ce que vous pratiquez un sport dans une ligue organisée ?',
  childConfirmMessage: 'Est-ce que {0} pratique un sport dans une ligue organisée ?',
  yesLinkText: 'Oui',
  noLinkText: 'Non',
  doneButtonText: 'Terminé'
};
 
export default FR;
