import Strings from './lang';
import SportsKeys from './keys';
import Positions from './positions';

const tokenize = (str) => str.replace(/\W/g, '').toLowerCase();

const makeSport = (name) => {
  const key = tokenize(name);
  return {
    key,
    name: Strings[key] || name,
    positions: Positions[key] || []
  };
};

const getSport = (name) => {
  const key = tokenize(name);
  return Sports.find(sport => {
    return sport.key === key;
  }) || makeSport(name);
};

const validateSport = (name) => /^[a-zA-Z\s]+$/.test(name);

const Sports = SportsKeys.map(makeSport);

export {
  Sports,
  SportsKeys,
  getSport,
  validateSport
};
