const FR = {
  ongoingStatusTitle: 'En cours',
  dischargedStatusTitle: 'A reçu sa sortie',
  lostStatusTitle: 'Omission du suivi',
  modalTitle: 'Modifier le statut de la blessure pour {0}',
  dischargedMessageText: 'Ce patient verra son statut changé pour <strong><u>{0}</u></strong>. Veuillez vérifier que la date ci-dessous corresponde <strong><u>exactement</u></strong> à la date de sortie.',
  dischargedDateLabelText: 'Date de sortie :',
  dischargedDateErrorText: 'La date de sortie doit être entre la date de la blessure ({0}) et aujourd’hui ({1}).',
  dischargeDateConfirmText: 'En continuant, vous confirmez que ce patient a reçu son avis de sortie le {0}.',
  ongoingLostMessageText: 'Ce patient verra son statut changé pour <strong><u>{0}</u></strong>.',
  ongoingLostAcceptLabelText: 'Êtes-vous certain de vouloir changer le statut de ce patient à {0} ?',
  ongoingLostAcceptErrorText: 'Vous devez indiquer si vous êtes certain de vouloir changer le statut de ce patient.',
  ongoingLostReasonLabelText: 'Raison :',
  ongoingLostReasonErrorText: 'Veuillez indiquer la motivation pour changer le statut de ce patient.',
  cancelButtonTitle: 'Annuler',
  confirmButtonTitle: 'Confirmer'
};
 
export default FR;
