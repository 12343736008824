import { 
  UPDATE_INJURY, 
  UPDATE_INJURIES, 
  ADD_INJURY_SOAP_NOTE,
  REMOVE_INJURY_SOAP_NOTE,
  ADD_INJURY_REFERRAL,
  UPDATE_INJURY_REFERRAL
} from '../actions/injuries';

const buildInjuryReferral = ({
  id,
  status,
  created_at,
  updated_at
}) => ({
  id,
  status,
  created_at,
  updated_at
});

const addInjuryReferralReducer = (state = {}, action = {}) => {
  const { userId, injuryId, referral } = action;
  const { [userId]: userInjuries = {} } = state;
  const { [injuryId]: injury } = userInjuries;

  return injury ? {
    ...state,
    [userId]: {
      ...userInjuries,
      [injuryId]: {
        ...injury,
        referrals: [
          ...(injury.referrals || []),
          buildInjuryReferral(referral)
        ]
      }
    }
  } : state;
};

const updateInjuryReferralReducer = (state = {}, action = {}) => {
  const { userId, injuryId, referral } = action;
  const { [userId]: userInjuries = {} } = state;
  const { [injuryId]: injury } = userInjuries;

  if (injury) {
    const injuryReferrals = [...(injury.referrals || [])].map(item => {
      if (String(item.id) === String(referral.id)) {
        return { ...item, ...buildInjuryReferral(referral) };
      }

      return item;
    });

    return {
      ...state,
      [userId]: {
        ...userInjuries,
        [injuryId]: {
          ...injury,
          referrals: injuryReferrals
        }
      }
    };
  }

  return state;
};

const updateInjuryReducer = (state = {}, action = {}) => {
  const { userId, injury = {} } = action;
  const { id } = injury;
  const userState = state[userId] || {};
  const nextUserState = (id) ? { ...userState, [id]: injury } : userState;
  return (userId) ? { ...state, [userId]: nextUserState } : state;
};

const updateInjuriesReducer = (state = {}, action = {}) => {
  const { userId, injuries = [] } = action;
  const nextUserState = injuries.reduce((userState, injury) => {
    const { id = 0 } = injury;
    return (id) ? { ...userState, [id]: injury } : userState;
  }, state[userId] || {});

  return (userId) ? { ...state, [userId]: nextUserState } : state;
};

const removeInjurySoapNoteReducers = (state = {}, action = {}) => {
  const { userId, injuryId, soapNoteId } = action;
  const userInjuries = state[userId] || {};
  const injury = userInjuries[injuryId] || {};
  const soapNotes = injury.soap_notes || [];
  const index = soapNotes.findIndex(soap => soap.id === soapNoteId);

  if (index >= 0) {
    soapNotes.splice(index, 1);
  }

  return soapNotes.length ? {
    ...state,
    [userId]: {
      ...userInjuries,
      [injuryId]: {
        ...injury,
        soap_notes: soapNotes
      }
    }
  } : state;
};

const addInjurySoapNoteReducer = (state = {}, action = {}) => {
  const { userId, injuryId, soapNote } = action;
  const userInjuries = state[userId] || {};
  const injury = userInjuries[injuryId];
  const soapNotes = injury.soap_notes || [];

  return injury ? {
    ...state,
    [userId]: {
      ...userInjuries,
      [injuryId]: {
        ...injury,
        soap_notes: [
          ...soapNotes,
          soapNote
        ]
      }
    }
  } : state;
};

const injuries = (state = {}, action = {}) => {
  switch (action.type) {
    case UPDATE_INJURY:
      return updateInjuryReducer(state, action);
    case UPDATE_INJURIES:
      return updateInjuriesReducer(state, action);
    case ADD_INJURY_SOAP_NOTE:
      return addInjurySoapNoteReducer(state, action);
    case REMOVE_INJURY_SOAP_NOTE:
      return removeInjurySoapNoteReducers(state, action);
    case ADD_INJURY_REFERRAL:
      return addInjuryReferralReducer(state, action);
    case UPDATE_INJURY_REFERRAL:
      return updateInjuryReferralReducer(state, action);
    default:
      return state;
  }
};

export default injuries;
