import React from 'react';
import classNames from 'classnames';
import Strings from '../lang';

const FormattedCurrency = ({ value, currency }) => {
  const formatter = new Intl.NumberFormat(navigator?.language, { style: 'currency', currency, currencyDisplay: 'narrowSymbol' }); 

  return (
    <span>{formatter.format(value)} {currency}</span>
  );
};

const PriceSection = ({
  title,
  value,
  valueClassName,
  discount,
  currency
}) => {
  return (
    <div className="price-section">
      <div className="heading">
        {title}
      </div>
      <div className={classNames('price', valueClassName)}>
        <strong>
          <FormattedCurrency value={value} currency={currency} />
        </strong>&nbsp;
      </div>
      {discount && (
        <label className="label label-danger">
          {Strings.formatString(Strings.productPriceDiscountPercentLabel, discount)}
        </label>
      )}
    </div>
  );
};

const ProductPriceDetails = ({
  price,
  discount
}) => {
  const priceValue = (price?.value ?? 0) / 100.0;
  const discounted = priceValue - (priceValue * (discount / 100.0));

  return (
    <div className="product-price-details">
      <PriceSection 
        title={Strings.retailPriceLabel}
        value={priceValue}
        valueClassName={!!discount && 'strikethrough'}
        currency={price?.currency}
      />
      {!!discount && (
        <PriceSection
          title={Strings.networkDiscountPriceLabel}
          value={discounted}
          valueClassName="danger"
          discount={discount}
          currency={price?.currency}
        />
      )}
    </div>
  );
};

export default ProductPriceDetails;
