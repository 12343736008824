import React from 'react';
import PropTypes from 'prop-types';
import { If, Then } from 'react-if';
import { Navigation } from '../navigation';

const Header = ({
  hideNavigationBar,
  hideNavigationContent,
  showLanguageSelector,
  showHeaderImage
}) => (
  <header>
    <If condition={hideNavigationBar !== true}>
      <Then>
        <Navigation 
          hideContent={hideNavigationContent} 
          showLanguageSelector={showLanguageSelector} 
        />
      </Then>
    </If>
    <If condition={showHeaderImage === true}>
      <Then>
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-md-offset-4">
              <div className="header-logo">
                <img 
                  className="img-responsive" 
                  src="/images/logo/color-full-animated.svg" 
                  alt="CCMI Logo"
                />
              </div>
            </div>
          </div>
        </div>
      </Then>
    </If>
  </header>
);

Header.propTypes = {
  showHeaderImage: PropTypes.bool,
  hideNavigationBar: PropTypes.bool,
  hideNavigationContent: PropTypes.bool
};

export default Header;
