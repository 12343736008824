import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Strings from './lang';
import Icon from '../icon';
import { useSessionContext } from '../current-user-loader';

const LogoutLink = ({
  className
}) => {
  const { logout } = useSessionContext();

  return (
    <button 
      type="button"
      className={classNames('logout-link', className)}
      onClick={logout}
    >
      <Icon name="right-from-bracket" fixedWidth />
      {Strings.logoutText}
    </button>
  );
};

LogoutLink.propTypes = {
  className: PropTypes.string
};

export default LogoutLink;
