import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ClinicPaths } from '@/paths';
import { ClinicSelector, UserSelector } from '@/redux/selectors';
import replaceLinkParams from '@/utilities/replace-link-params';
import { 
  makeRoleDescription, 
  RoleNames, 
  RoleResourceTypes, 
  userHasRoleMatchingDescription, 
  userHasRoleMatchingOneOfDescriptions 
} from '@/utilities/user-roles';
import Strings from '../lang';

export const StatusLabelType = {
  pending: 'warning',
  accepted: 'success',
  resolved: 'default inverted',
  rejected: 'danger',
  cancelled: 'danger'
};

export const StatusStrings = {
  pending: Strings.pendingStatusLabel,
  accepted: Strings.acceptedStatusLabel,
  resolved: Strings.resolvedStatusLabel,
  rejected: Strings.rejectedStatusLabel,
  cancelled: Strings.cancelledStatusLabel
};

export const matchId = (id1, id2) => {
  return String(id1) === String(id2);
};

export const usePatientLink = (patient) => {
  const currentClinic = useSelector(ClinicSelector.getCurrentClinic);
  const currentUser = useSelector(UserSelector.getCurrentUser);
  const sharesClinic = useMemo(() => (
    userHasRoleMatchingDescription(
      patient,
      makeRoleDescription(
        RoleNames.Player,
        RoleResourceTypes.Clinic,
        currentClinic?.id
      )
    ) && userHasRoleMatchingOneOfDescriptions(
      currentUser,
      [
        makeRoleDescription(
          RoleNames.ClinicFrontDesk,
          RoleResourceTypes.Clinic,
          currentClinic?.id
        ),
        makeRoleDescription(
          RoleNames.Clinician,
          RoleResourceTypes.Clinic,
          currentClinic?.id
        ),
        makeRoleDescription(
          RoleNames.Specialist,
          RoleResourceTypes.Clinic,
          currentClinic?.id
        )
      ]
    )
  ), [patient, currentClinic?.id, currentUser]);

  return sharesClinic ? replaceLinkParams(ClinicPaths.patientProfile.index.link, {
    clinicId: currentClinic?.id,
    userId: patient.id
  }) : null;
};
