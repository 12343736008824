import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import dayjs from 'dayjs';
import classnames from 'classnames';
import { SymptomCategoryStrings } from '@/utilities/symptom-data';
import Dropdown, { MenuItem } from '../../dropdown';
import { DISPLAY_DATE_FORMAT } from '@/utilities/dates/formats';
import { TEST_TYPE_LABEL } from '../utils';
import Popover from '../../popover';
import Markdown from '../../markdown';
import Icon from '../../icon';
import { getCurrentUserLanguage } from '@/utilities/localization';
import Strings from '../lang';

const getMarkdownPath = (category) => {
  const lang = getCurrentUserLanguage();
  const time = Date.now();
  const rootPath = `/documents/help/symptom-categories/${category}/${lang}`;

  return `${rootPath}/index.markdown?${time}`;
};

const addCategoryRank = (categories) => {
  const data = [...categories];

  categories.forEach((category, i) => {
    let rank = i + 1;

    if (i > 0) {
      const prev = data[i - 1];

      if (category.score === prev.score) {
        rank = prev.rank;
      }
    }
    
    data[i].rank = rank;
  });

  return data;
};

const InfoPopover = () => {
  const [open, setOpen] = useState(false);

  return (
    <Popover
      isOpen={open}
      placement="auto-start"
      content={Strings.symptomCategoriesDescription}
      onClose={() => setOpen(false)}
    >
      <button 
        type="button"
        className="info-toggle"
        aria-label={Strings.viewDescriptionLabel}
        onClick={() => setOpen(prev => !prev)}
      >
        <Icon name="circle-info" />
      </button>
    </Popover>
  );
};

const getSelectorLabel = (value) => {
  const date = dayjs(value.created_at).format(DISPLAY_DATE_FORMAT);
  const type = TEST_TYPE_LABEL(value.type);
  return `${date} (${type})`;
};

const SymptomIndexSelector = ({ selected, symptoms, onSelect }) => {
  const [open, setOpen] = useState(false);
  const items = useMemo(() => symptoms.slice(0).reverse(), [symptoms]);
  const current = symptoms[selected];

  return (
    <Dropdown
      isOpen={open}
      trigger={(
        <button 
          type="button" 
          className={classnames('btn btn-sm btn-light', { selected: open })}
          onClick={() => setOpen(prev => !prev)}
        >
          {getSelectorLabel(current)}
          <Icon prefix="fas" name="caret-down" />
        </button>
      )}
      onClose={() => setOpen(false)}
    >
      {items.map((s, i) => {
        const index = (symptoms.length - 1) - i;
        return (
          <MenuItem
            key={s.id}
            selected={selected === index}
            onSelect={() => onSelect(index)}
          >
            {getSelectorLabel(s)}
          </MenuItem>
        );
      })}
    </Dropdown>
  );
};

const TopSymptomCategories = ({ symptoms }) => {
  const contentRef = useRef();
  const [symptomIndex, setSymptomIndex] = useState(symptoms.length - 1);
  const categories = useMemo(() => (
    addCategoryRank(symptoms[symptomIndex].categories)
  ), [symptomIndex, symptoms]);
  const [categoryIndex, setCategoryIndex] = useState(0);
  const selectedCategory = categories[categoryIndex];

  const onSelectSymptomIndex = useCallback((i) => {
    setCategoryIndex(0);
    setSymptomIndex(i);
  }, []);

  useEffect(() => {
    contentRef.current?.scrollTo({ top: 0 });
  }, [symptomIndex, categoryIndex]);

  if (!categories) {
    return null;
  }

  return (
    <div className="symptom-categories-container">
      <div className="flex-row align_center justify_space-between categories-header wrap">
        <div className="flex-row align_baseline">
          <h2>{Strings.topSymptomCategoriesTitle}</h2>
          <InfoPopover />
        </div>
        <SymptomIndexSelector 
          selected={symptomIndex}
          symptoms={symptoms}
          onSelect={onSelectSymptomIndex}
        />
      </div>
      <div className="symptom-categories">
        <div className="symptom-categories-list">
          {categories.map((c, i) => {
            return (
              <div 
                key={c.category} 
                className={classnames('symptom-category-item', { selected: categoryIndex === i })}
                role="button"
                aria-label={SymptomCategoryStrings(c.category)}
                tabIndex={0}
                onKeyPress={() => setCategoryIndex(i)}
                onClick={() => setCategoryIndex(i)}
              >
                <div className="category-details">
                  <div className={classnames('rank', { first: c.rank === 1 })}>
                    #{c.rank}
                  </div>
                  <div className="item-label">
                    {SymptomCategoryStrings(c.category)}
                  </div>
                </div>
                <div className="score-overview">
                  <div className="score-overview-label">
                    {Strings.scoreLabel}
                  </div>
                  <div className="score-overview-value">
                    {c.score}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="symptom-category-description">
          <div className="title">{SymptomCategoryStrings(selectedCategory.category)}</div>
          <div className="content-container">
            <div ref={contentRef} className="content">
              <Markdown.Remote 
                path={getMarkdownPath(selectedCategory.category)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopSymptomCategories;
