import React, { createContext, useContext } from 'react';

export const LockoutContext = createContext({
  locked: false,
  lock: () => undefined
});

export const useLockout = () => useContext(LockoutContext);

export const LockoutProvider = ({ children, ...rest }) => {
  return (
    <LockoutContext.Provider value={rest}>
      <>
        {children}
      </>
    </LockoutContext.Provider>
  );
};
