import { useCallback, useRef } from 'react';
import { useUnmount } from './lifecycle';

export const useDebounceFn = (fn, delay = 1) => {
  const timeout = useRef(null);

  const clear = useCallback(() => {
    if (timeout.current) {
      clearTimeout(timeout.current);
      timeout.current = null;
    }
  }, []);

  const debounceFn = useCallback((...args) => {
    clear();
    timeout.current = setTimeout(() => (
      fn(...args)
    ), delay);
  }, [fn, delay, clear]);

  useUnmount(clear);

  return delay > 0 ? debounceFn : fn;
};
