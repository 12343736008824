const FR = {
  title: 'Connexion',
  resetLinkText: 'Oublié votre mot de passe?',
  emailLabelText: 'Courriel',
  emailInputPlaceholder: 'nom@exemple.com',
  passwordLabelText: 'Mot de passe',
  passwordInputPlaceholder: 'motdepasse123',
  rememberEmailLabelText: 'Se souvenir de moi',
  submitButtonText: 'Connexion',
  logoutText: 'Déconnexion',
  clinicsTitle: 'Bienvenue',
  clinicsMessage: 'À partir de quelle clinique travaillez-vous, aujourd\'hui?',
  clinicsButtonText: 'Terminer',
  salesText: 'Le portail de connexion est approuvé pour les cliniques de Complete Concussion Management et leurs partenaires. Pour plus d’information, communiquez avec {0}CCMI{1}.',
  switchAccountMessageText: 'Connecté en tant que {0}.',
  switchAccountLinkText: 'Cliquez ici pour changer de compte.',
  areYouAPatientText: 'Êtes-vous un patient?',
  registerHereLinkText: 'Connectez-vous ou créez un compte ici',
  newSignInText: 'Nouvelle connexion'
};

export default FR;
