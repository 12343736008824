const FR = {
  headache: 'Mal de tête',
  headPressure: 'Pression dans la tête',
  neckPain: 'Douleurs au cou',
  nausea: 'Nausée ou vomissements',
  dizziness: 'Étourdissement',
  blurredVision: 'Vision embrouillée',
  balance: 'Troubles d’équilibre',
  lightSensitive: 'Sensibilité à la lumière',
  noiseSensitive: 'Sensibilité au bruit',
  slowedDown: 'Sensation de ralenti',
  inAFog: 'Sensation « d’être dans le brouillard »',
  dontFeelRight: 'Ne se sent pas bien',
  concentration: 'Difficulté à se concentrer',
  remembering: 'Difficulté à se souvenir',
  fatigue: 'Fatigue ou baisse d’énergie',
  confusion: 'Confusion',
  drowsiness: 'Somnolence',
  fallingAsleep: 'Difficulté à trouver le sommeil',
  emotional: 'Plus émotif',
  irritability: 'Irritabilité',
  sadness: 'Tristesse',
  nervous: 'Nervosité ou anxiété',
  severityScoreLabel: 'Sévérité du symptôme :',
  symptomTotalsLabel: 'Total de symptômes :',
  requiredError: '{0} est requis',
  defaultSymptomsLabel: 'Symptômes',
  otherSymptomsLabel: 'Autres symptômes',
  notesModalTitle: '{0} Remarques :',
  doneButtonText: 'Terminé',
  selectScoreText: 'Choisir le résultat',
  addNoteButtonText: 'Ajouter une remarque'
};
 
export default FR;
