const FR = {
  correctAnswer: 'Exact',
  requiredError: 'Veuillez choisir une valeur',
  incorrectAnswer: 'Faux',
  yesAnswerLabel: 'Oui',
  noAnswerLabel: 'Non',
  monthsInReverseScoreLabel: 'Le patient a-t-il correctement récité les mois dans l\'ordre inverse dans le temps imparti?'
};
 
export default FR;
