import { ADD_CLINIC_IDT_CLAIMS } from '../actions/clinic-idt-claims';

const addClinicIdtClaimsReducer = (state = {}, action = {}) => {
  const { clinicId, claims = [] } = action;
  const { [clinicId]: clinicClaims = {} } = state;

  const newClinicClaims = claims.reduce((acc, claim) => {
    return {
      ...acc,
      [claim.id]: {
        ...(acc[claim.id] || {}),
        ...claim
      }
    };
  }, clinicClaims);

  return { ...state, [clinicId]: newClinicClaims };
};

const clinicIdtClaims = (state = {}, action = {}) => {
  switch (action.type) {
    case ADD_CLINIC_IDT_CLAIMS:
      return addClinicIdtClaimsReducer(state, action);
    default:
      return state;
  }
};

export default clinicIdtClaims;
