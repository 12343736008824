const UserPaths = {
  index: {
    route: 'users',
    link: '/users'
  },
  account: {
    route: 'account',
    link: '/account'
  },
  injuries: {
    index: {
      route: ':userId/injuries',
      link: '/users/:userId/injuries'
    },
    new: {
      route: 'new',
      link: '/users/:userId/injuries/new'
    },
    fullAssessment: {
      route: ':injuryId/assessment/:assessmentId/full',
      link: '/users/:userId/injuries/:injuryId/assessment/:assessmentId/full'
    },
    injuryReportAssessment: {
      route: ':injuryId/assessment/:assessmentId/injury-report/:reportId/full',
      link: '/users/:userId/injuries/:injuryId/assessment/:assessmentId/injury-report/:reportId/full'
    },
    soapNote: {
      route: ':injuryId/soaps/:soapId/notes',
      link: '/users/:userId/injuries/:injuryId/soaps/:soapId/notes'
    },
    specialistNote: {
      new: {
        route: ':injuryId/specialist-notes/new',
        link: '/users/:userId/injuries/:injuryId/specialist-notes/new'
      },
      edit: {
        route: ':injuryId/specialist-notes/:noteId',
        link: '/users/:userId/injuries/:injuryId/specialist-notes/:noteId'
      }
    }
  },
  accountRetrieval: {
    route: 'recover',
    link: '/recover'
  },
  baselines: {
    index: {
      route: ':userId/baselines',
      link: '/users/:userId/baselines'
    },
    detail: {
      route: ':baselineId',
      link: '/users/:userId/baselines/:baselineId'
    },
    test: {
      route: 'test',
      link: '/users/:userId/baselines/test'
    },
    steps: {
      index: {
        route: ':baselineId/steps',
        link: '/users/:userId/baselines/:baselineId/steps'
      },
      personal: {
        route: 'personal',
        link: '/users/:userId/baselines/:baselineId/steps/personal'
      },
      medical: {
        route: 'medical',
        link: '/users/:userId/baselines/:baselineId/steps/medical'
      },
      symptoms: {
        route: 'symptoms',
        link: '/users/:userId/baselines/:baselineId/steps/symptoms'
      },
      concentration: {
        route: 'concentration',
        link: '/users/:userId/baselines/:baselineId/steps/concentration'
      },
      memory: {
        route: 'memory',
        link: '/users/:userId/baselines/:baselineId/steps/memory'
      },
      orientation: {
        route: 'orientation',
        link: '/users/:userId/baselines/:baselineId/steps/orientation'
      },
      kingDevick: {
        route: 'king-devick',
        link: '/users/:userId/baselines/:baselineId/steps/king-devick'
      },
      balance: {
        route: 'balance',
        link: '/users/:userId/baselines/:baselineId/steps/balance'
      },
      forcePlate: {
        route: 'force-plate',
        link: '/users/:userId/baselines/:baselineId/steps/force-plate'
      },
      tandemGait: {
        route: 'tandem-gait',
        link: '/users/:userId/baselines/:baselineId/steps/tandem-gait'
      },
      dualTask: {
        route: 'dual-task',
        link: '/users/:userId/baselines/:baselineId/steps/dual-task'
      },
      gripStrength: {
        route: 'grip-strength',
        link: '/users/:userId/baselines/:baselineId/steps/grip-strength'
      },
      reaction: {
        route: 'reaction',
        link: '/users/:userId/baselines/:baselineId/steps/reaction'
      },
      delayedMemory: {
        route: 'delayed-memory',
        link: '/users/:userId/baselines/:baselineId/steps/delayed-memory'
      },
      continue: {
        route: 'continue',
        link: '/users/:userId/baselines/:baselineId/steps/continue'
      },
      complete: {
        route: 'complete',
        link: '/users/:userId/baselines/:baselineId/steps/complete'
      }
    },
    edit: {
      route: ':baselineId/edit',
      link: '/users/:userId/baselines/:baselineId/edit'
    }
  },
  leaders: {
    route: 'leaders',
    link: '/users/leaders'
  },
  leaderProfile: {
    route: 'leaders/:leaderId',
    link: '/users/leaders/:leaderId'
  }
};

export default UserPaths;
