import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import DownloadPDFButton from './download';
import { ClinicSelector, InvoiceSelector } from '@/redux/selectors';

const createFileName = (invoice) => {
  const { title = '', close_date } = invoice;
  const date = close_date ? `_${close_date}` : '';
  return `ccmi_invoice${date}_${title}.pdf`;
};

const InvoicePDFDownload = ({
  clinicId, 
  invoiceId
}) => {
  const clinic = useSelector(state => ClinicSelector.getClinic(state, { id: clinicId }));
  const invoice = useSelector(state => (
    InvoiceSelector.getClinicInvoice(state, { clinicId, invoiceId })
  ));
  const lineItems = useSelector(state => (
    InvoiceSelector.getLineItemSummary(state, { clinicId, invoiceId })
  ));
  const fileName = useMemo(() => createFileName(invoice), [invoice]);

  return (
    <DownloadPDFButton
      className="btn btn-sm btn-default no-print"
      fileName={fileName}
      documentProps={{
        clinic,
        invoice,
        lineItems
      }}
    />
  );
};

export default InvoicePDFDownload;
