import React, { forwardRef } from 'react';
import { TandemGaitCard } from '../baseline-test-cards';

const TandemGaitStep = forwardRef((
  {
    user,
    baseline,
    onSubmit,
    ...rest
  },
  ref
) => {
  return (
    <TandemGaitCard
      {...rest}
      ref={ref}
      user={user}
      tandemGait={{ ...(baseline?.tandem_gait_test) }}
      onSubmit={onSubmit}
    />
  );
});

TandemGaitStep.hasChanged = (initial, current) => {
  const curTrials = current.trials || [];
  const initialTrials = initial.trials;

  return curTrials.some((trial, i) => (
    trial?.duration !== (initialTrials?.[i]?.duration ?? '')
    || trial?.failed !== (initialTrials?.[i]?.failed ?? false)
  ));
};

export default TandemGaitStep;
