import React, { useMemo, useCallback } from 'react';
import ReactSelect from '../../react-select';
import { CHART_SELECTOR_OPTIONS } from '../utils';

const transformSelectValueIn = (value) => {
  return CHART_SELECTOR_OPTIONS.find((option) => option.value === value);
};

const transformSelectValueOut = (value) => value.value;

const ChartSelector = ({
  value,
  onChange
}) => {
  const selectValue = useMemo(() => transformSelectValueIn(value), [value]);

  const handleChange = useCallback((values) => {
    const v = transformSelectValueOut(values);
    onChange(v);
  }, [onChange]);

  return (
    <ReactSelect
      value={selectValue}
      clearable={false}
      options={CHART_SELECTOR_OPTIONS}
      onChange={handleChange}
    />
  );
};

export default ChartSelector;
