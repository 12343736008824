import React from 'react';
import BookingLink from './booking-link-item';
import AddBookingLinkButton from './add-booking-link-button';

const BookingLinksList = ({ 
  links = [], 
  userId, 
  editable,
  clinics = [] 
}) => {
  return (
    <div className="booking-links-list">
      <div className="actions-header">
        <AddBookingLinkButton 
          clinics={clinics} 
          userId={userId}
          visible={editable}
          className="btn btn-primary btn-sm"
          disabled={!clinics.length}
        />
      </div>
      {links.map(link => (
        <BookingLink 
          key={link.id}
          userId={userId} 
          link={link} 
          editable={editable}
          clinics={clinics} 
        />
      ))}
    </div>
  );
};

export default BookingLinksList;
