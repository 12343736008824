import React from 'react';
import isBaselineExpired from '@/utilities/is-baseline-expired';
import Strings from './lang';
import Icon from '../icon';

const DEFAULT_ICON_SIZE = 20;

const PlayerBaselineBadge = ({ 
  date,
  size = DEFAULT_ICON_SIZE
}) => {
  if (!date || isBaselineExpired(date)) {
    return null;
  }

  return (
    <div
      title={Strings.baselineBadgeTitle}
      className="baseline-badge"
    >
      <img 
        src="/images/logo/color.svg" 
        height={size}
        width={size}
        alt="CCMI Logo"
      />
      <div className="badge-icons">
        <Icon prefix="fas" name="badge-check" />
      </div>
    </div>
  );
};

export default PlayerBaselineBadge;
