import React from 'react';
import BookingLinks from '../../booking-links';
import Strings from './lang';

const BookingLinksStep = ({ user = {}, onSubmit }) => {
  return (
    <>
      <h1 className="text-center">{Strings.bookingTitle}</h1>
      <div className="registration-booking-links-container">
        <BookingLinks 
          userId={user.id} 
        />
      </div>
      <div className="pull-right">
        <button 
          type="button" 
          className="btn btn-primary" 
          onClick={() => onSubmit()}
        >
          {Strings.submitButtonText}
        </button>
      </div>
    </>
  );
};

export default BookingLinksStep;
