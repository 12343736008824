const ReferralsPath = {
  show: {
    link: '/referrals/:referralId',
    route: '/referrals/:referralId'
  },
  email: {
    link: '/referrals'
  }
};

export default ReferralsPath;
