/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
/* eslint-enable no-unused-vars */

import classNames from 'classnames';
import { If, Then, Else } from 'react-if';
import Strings from './lang';

const BaselineSection = ({
  title = '', 
  notes = '', 
  sectionCompleted = true,
  className,
  children
}) => (
  <div className={classNames('baseline-section', className)}>
    <h2 className="bold">{title}</h2>
    <If condition={sectionCompleted}>
      <Then>
        <div>
          {children}
          <If condition={notes !== null && notes.length > 0}>
            <Then>
              <p className="notes">
                <strong>{Strings.notesLabelText}</strong> {notes}
              </p>
            </Then>
          </If>
        </div>
      </Then>
      <Else>
        <p className="text-muted">
          <i>{Strings.notCompletedText}</i>
        </p>
      </Else>
    </If>
  </div>
);

BaselineSection.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  notes: PropTypes.string
};

export default BaselineSection;
