import React from 'react';
import Modal from '../modal';
import Strings from './lang';

const IncompleteAssessmentModal = ({
  isOpen,
  onClose
}) => {
  return (
    <Modal
      blur
      isOpen={isOpen}
      onClose={onClose}
    >
      <Modal.Header>
        <h1>{Strings.incompleteAssessmentModalTitle}</h1>
      </Modal.Header>
      <Modal.Body>
        <div className="confirm-modal-content">
          {Strings.incompleteAssessmentModalInfo}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-sm btn-default"
          onClick={onClose}
        >
          {Strings.okayButtonText}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default IncompleteAssessmentModal;
