import { createSelector } from 'reselect';

const getClaimsState = (state) => state.clinicIdtClaims;
const getClinicId = (_, { clinicId }) => clinicId;
const getStatus = (_, { status }) => status;

const getClaims = createSelector(
  [getClaimsState, getClinicId],
  (claims = {}, clinicId = 0) => {
    return Object.values(claims[clinicId] || {});
  }
);

const getClaimsByStatus = createSelector(
  [getClaims, getStatus],
  (claims = [], status = null) => {
    return claims.filter(claim => claim.status === status);
  }
);

export default {
  getClaims,
  getClaimsByStatus
};
