import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { UserSelector } from '@/redux/selectors';
import { removeAuthData } from '@/tokens';

const ProtectedRoute = ({ children }) => {
  const location = useLocation();
  const currentUser = useSelector(UserSelector.getCurrentUser);

  if (!currentUser) {
    removeAuthData();
    
    return (
      <Navigate
        to="/login"
        state={{ nextPathname: location?.pathname }}
      />
    );
  }

  return children ?? <Outlet />;
};

export default ProtectedRoute;
