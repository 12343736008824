const FR = {
  taxRateTitle: 'Les taux d\'imposition',
  addTaxRateButton: 'Ajouter le taux d\'imposition',
  confirmDeleteTaxRateTitle: 'Confirmer supprimer taux d\'imposition',
  confirmDeleteTaxRateMessage: 'Voulez-vous vraiment supprimer ce taux d’imposition pour {0}, {1}?',
  addTaxRateTitle: 'Ajouter le taux d\'imposition',
  updateTaxRateTitle: 'Mettre à jour le taux d\'imposition',
  countryLabel: 'Pays',
  countryErrorMessage: 'Choisissez un pays',
  regionLabel: 'Région',
  regionErrorMessage: 'Sélectionnez une région',
  taxCadeLabel: 'Code fiscal',
  taxCodeErrorMessage: 'Entrez un code de taxe (e.g. HST)',
  taxRateLabel: 'Taux d\'imposition (%)',
  taxRateErrorMessage: 'Entrez le taux de taxe (%)',
  submitButtonText: 'Soumettre',
  lastUpdatedLabel: 'Dernière mise à jour',
  actionsLabel: 'Actes'
};

export default FR;
