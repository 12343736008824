import React, { 
  useEffect, 
  useLayoutEffect, 
  useRef, 
  useState 
} from 'react';
import VideoPlayer from '../video-player';

const LazyVideoPlayer = ({ url }) => {
  const [visible, setVisible] = useState(false);
  const containerRef = useRef();
  const observerRef = useRef();

  useLayoutEffect(() => {
    const container = containerRef.current;

    const cb = (entries) => {
      const entry = entries[0];

      if (entry.isIntersecting) {
        setVisible(true);
      }
    };

    observerRef.current = new IntersectionObserver(cb, {
      rootMargin: '0px',
      threshold: 0.0
    });

    observerRef.current.observe(container);

    return () => {
      if (observerRef.current) {
        observerRef.current.unobserve(container);
        observerRef.current.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    if (visible && observerRef.current && containerRef.current) {
      observerRef.current.unobserve(containerRef.current);
      observerRef.current.disconnect();
      observerRef.current = null;
    }
  }, [visible]);

  return (
    <div ref={containerRef} className="video-container">
      {visible && (
        <VideoPlayer
          url={url}
          height="100%"
          width="100%"
          controls
        />
      )}
    </div>
  );
};

const vimeoUrl = (id, hash) => {
  const url = `https://vimeo.com/${id}`;

  // Hash is only explicitly required for
  // unlisted vimeo videos
  if (hash) {
    return `${url}/${hash}`;
  }

  return url;
};

const VimeoVideo = ({ id, hash, name }) => {
  const url = vimeoUrl(id, hash);

  return (
    <div className="vimeo-video">
      <LazyVideoPlayer url={url} />
      <p className="text-center">
        {name}
      </p>
    </div>
  );
};

export default VimeoVideo;
