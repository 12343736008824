import React, { Component } from 'react';
import { connect } from 'react-redux';
import { If, Then } from 'react-if';
import dayjs from 'dayjs';
import { getUserAsync } from '@/redux/thunks/users';
import { getInjuryAsync } from '@/redux/thunks/injuries';
import { getActiveBaselineTestAsync, getBaselineBeforeDateAsync } from '@/redux/thunks/baseline-tests';
import { getSoapNoteAsync, updateSoapNoteAsync } from '@/redux/thunks/soap-notes';
import replaceLinkParams from '@/utilities/replace-link-params';
import { getCurrentClinicId } from '@/tokens';
import { ClinicPaths } from '@/paths';
import Tabs from '../tabs';
import { Page } from '../page';
import { ErrorBanner } from '../errors';
import Activity from '../activity';
import { confirmation } from '../confirmation';
import { TestHeader } from '../test-header';
import { InjuryDiagnosis } from '../diagnosis';
import AccessControl from '../access-control';
import { RoleDescriptions, userHasRoleMatchingDescription } from '@/utilities/user-roles';
import { withRouter } from '@/utilities/router-utils';
import { showAlert } from '../alert-notifications';
import {
  SubjectiveNotesForm,
  AssessmentNotesForm,
  PlanNotesForm,
  RecoveryProtocolForm
} from './note-forms';
import SoapObjectiveSection from './soap-objective-section';
import SoapNotesFormDisplay from './soap-notes-form-display';
import SoapNotesHistoryButton from './soap-notes-history-button';
import { InjurySelector, SoapNoteSelector, UserSelector } from '@/redux/selectors';
import Strings from './lang';

/**
 * This is a high level component that is in charge of 
 * fetching, updating, and creating SOAP data. Since the SOAP 
 * note has the ability to launch various tests within a new 
 * window (which requires user action, i.e click link, in older broswers),
 * the SOAP note is created on page load. To avoid someone refreshing the 
 * page multiple times and creating multiple SOAP notes, on SOAP note 
 * creation the id is stored in the location state. On page load the soapId 
 * is checked for first in the router params, if not found -> checked for 
 * in location state -> if not found then it is created and stored in location state. 
 */

/**
 * TODO: 
 * 1. Allow ability to change Diagnosis (modal with start reassessment button).
 * 2. Add current injury stage to test header and injury stage section. 
 *    (find last soap note and show stage or show initial stage from initial assessment)
 * 3. Add post injury test and blackhawks test.
 */

const determineDiagnosis = (soap, injury) => {
  if (soap.closed === false && Object.keys(soap.diagnosis || {}).length > 0) {
    return soap.diagnosis;
  }
  
  return injury.diagnosis;
};

const getQueryKey = (query) => {
  return query.section || 'subjective';
};

const onError = (message) => {
  showAlert('error', {
    dismissable: true,
    autoDismiss: 3000,
    message: message || Strings.standardSectionFormError
  });
};

const onSectionSaveSuccess = () => {
  showAlert('success', {
    dismissable: true,
    autoDismiss: 3000,
    message: Strings.sectionSavedMessage
  });
};

class SoapNotes extends Component {
  static getDerivedStateFromProps(props, state) {
    const newKey = getQueryKey(props.queryParams);

    if (state.selectedKey !== newKey) {
      return {
        selectedKey: newKey
      };
    }

    return null;
  }

  constructor(props) {
    super(props);

    const { queryParams = {} } = props;
    
    this.routerWillLeave = this.routerWillLeave.bind(this);
    this.saveDiagnosis = this.saveDiagnosis.bind(this);
    this.loadSoapNote = this.loadSoapNote.bind(this);
    this.onTabClick = this.onTabClick.bind(this);

    this.onSaveSubjectiveSection = this.checkSymptomsScoresCompleted.bind(this, 'objective');
    this.onSaveObjectiveSection = this.onSaveSection.bind(this, 'assessment');
    this.onSaveAssessmentSection = this.onSaveSection.bind(this, 'plan');
    this.onSavePlanSection = this.onSaveSection.bind(this, 'recovery-stages');
    this.onSaveProtocolSection = this.onSaveSection.bind(this, 'review');

    this.state = {
      error: null,
      activity: true,
      loading: true,
      selectedKey: getQueryKey(queryParams),
      submitted: false,
      userClosed: false
    };
  }

  componentDidMount() {
    const { router = {} } = this.props;
    router.setRouteLeaveHook(this.routerWillLeave);

    this.loadSoapNoteData();
  }

  render() {
    const { 
      user, 
      injury, 
      soap,  
      currentUser, 
      currentClinic,
      previousStage
    } = this.props;
    const diagnosis = determineDiagnosis(soap, injury); 

    return (
      <Page>
        <Activity active={this.state.activity} static={this.state.loading}>
          <div>
            <h1 className="display">{Strings.soapNotesTitle}</h1>
            
            <TestHeader
              className="soap-notes-header"
              user={user}
              injury={injury}
              testDate={soap.created_at || dayjs()}
              tester={soap.clinic_user}
              clinic={soap.clinic}
            />

            <AccessControl roles={[RoleDescriptions.Clinician]}>
              <div className="row">
                <div className="col-md-6">
                  <If condition={!soap.closed}>
                    <Then>
                      <InjuryDiagnosis
                        editable
                        diagnosis={diagnosis || {}}
                        headingText={Strings.currentDiagnosisLabel}
                        injuryDate={injury.injured_at}
                        onDiagnosisSave={this.saveDiagnosis}
                      />
                    </Then>
                  </If>
                </div>
                <div className="col-md-2 pull-right">
                  <SoapNotesHistoryButton 
                    user={user} 
                    injury={injury}
                    soapId={soap.id}
                    clinicId={currentClinic.id}
                  />
                </div>
              </div>
            </AccessControl>

            <ErrorBanner error={this.state.error} />

            <Tabs
              items={[{
                key: 'subjective',
                label: Strings.subjectiveNavLabel,
                component: (
                  <SubjectiveNotesForm
                    symptoms={soap.symptoms_scores}
                    notes={soap.notes}
                    onError={onError}
                    onSubmit={this.onSaveSubjectiveSection}
                  />
                )
              }, {
                key: 'objective',
                label: Strings.objectiveNavLabel,
                component: (
                  <SoapObjectiveSection
                    soap={soap}
                    user={user}
                    injury={injury}
                    baseline={this.state.baseline}
                    currentUser={currentUser}
                    currentClinic={currentClinic}
                    onError={onError}
                    onSaveNote={this.onSaveObjectiveSection}
                    onTestSaved={this.loadSoapNote}
                  />
                )
              }, {
                key: 'assessment',
                label: Strings.assessmentNavLabel,
                component: (
                  <AssessmentNotesForm
                    notes={soap.notes}
                    onError={onError}
                    onSubmit={this.onSaveAssessmentSection}
                  />
                )
              }, {
                key: 'plan',
                label: Strings.planNavLabel,
                component: (
                  <PlanNotesForm
                    notes={soap.notes}
                    submitted={this.state.submitted}
                    onError={onError}
                    onSubmit={this.onSavePlanSection}
                  />
                )
              }, {
                key: 'recovery-stages',
                label: Strings.injuryStageSectionTitle,
                component: (
                  <RecoveryProtocolForm 
                    stages={soap.recovery_protocol_stage}
                    previousStages={previousStage}
                    submitted={this.state.submitted}
                    canDischarge={
                      userHasRoleMatchingDescription(currentUser, RoleDescriptions.Clinician)
                    }
                    onSubmit={this.onSaveProtocolSection}
                  />
                )
              }, {
                key: 'review',
                label: soap.closed === true 
                  ? Strings.reviewNoteTab 
                  : Strings.reviewAndCloseNoteTab,
                tabClassName: soap.closed === true ? 'alert success' : 'alert warning',
                component: (
                  <div>
                    <SoapNotesFormDisplay
                      soap={soap}
                      userId={user?.id}
                      clinicId={currentClinic.id}
                    />
                    {!soap.closed && (
                      <button type="button" className="btn btn-primary" onClick={this.closeSoapNote.bind(this)}>
                        {Strings.closeNoteButtonText}
                      </button>
                    )}
                  </div>
                )
              }]}
              selectedKey={this.state.selectedKey}
              onSelectTab={this.onTabClick}
            />
          </div>
        </Activity>
      </Page>
    );
  }

  routerWillLeave(routeParams) {
    const { soap } = this.props;
    const { userClosed } = this.state;

    /** these routes will override the route intercept as /login might mean they have logged out */
    if (routeParams.pathname === '/' || routeParams.pathname === '/login') {
      return false;
    }

    const isClinician = userHasRoleMatchingDescription(
      this.props.currentUser,
      RoleDescriptions.Clinician
    );

    if (!userClosed && !soap.closed && isClinician) {
      this.confirmOpenNote(routeParams);
      return true;
    }

    return false;
  }

  confirmOpenNote() {
    confirmation(Strings.confirmRouteLeaveText, {
      title: Strings.confirmRouteLeaveTitle,
      confirmButtonTitle: Strings.confirmButtonTitle,
      cancelButtonTitle: Strings.cancelButtonTitle,
      onConfirm: () => {
        this.setState({ 
          userClosed: true 
        }, () => { 
          this.props.router.blocker.proceed?.();
        });
      },
      onCancel: () => {
        this.props.router.blocker.reset?.();
        this.querySection('review');
      }
    });
  }

  isValidToCloseNote() {
    const { soap } = this.props;
    
    if (!soap.recovery_protocol_stage) {
      return {
        valid: false,
        show: 'recovery-stages'
      };
    }

    return { valid: true };
  }

  saveDiagnosis(attributes) {
    const { user, injury, soap } = this.props;

    this.setState({
      activity: true,
      error: null
    });

    this.props.updateSoapNote(user.id, injury.id, soap.id, attributes).then(() => {
      this.setState({ activity: false });
    }).catch(error => {
      this.setState({
        error: error.message,
        activity: false
      });
    });
  }

  onTabClick(key) {
    this.querySection(key);
  }

  checkSymptomsScoresCompleted(nextKey, attributes) {
    const { symptoms_scores = {} } = attributes;

    if (Object.keys(symptoms_scores).length === 0) {
      confirmation(Strings.confirmSymptomsIncompleteText, {
        title: Strings.confirmSymptomsIncompleteTitle,
        confirmButtonTitle: Strings.confirmButtonTitle,
        cancelButtonTitle: Strings.cancelButtonTitle,
        onConfirm: () => {
          this.onSaveSection(nextKey, attributes);
        }
      });
    } else {
      this.onSaveSection(nextKey, attributes);
    }
  }

  loadSoapNoteData() {
    const { userId = 0, injuryId = 0, soapId = 0 } = this.props.params;
    this.setState({
      activity: true,
      loading: true,
      error: null
    });

    Promise.all([
      this.props.getUser(userId),
      this.props.getInjury(userId, injuryId),
      this.props.getSoapNote(userId, injuryId, soapId)
    ]).then(([user,, soap]) => {
      const postInjuryTest = (soap.baselines || [])[0];
      let baseline;
      
      if (postInjuryTest) {
        baseline = postInjuryTest.baseline_comparison;
      } else {
        const beforeDate = soap.created_at || dayjs().format();
        baseline = this.props.getBaselineTestBeforeDate(user.id, beforeDate);
      }

      return Promise.all([
        soap,
        baseline
      ]);
    }).then(([soap, baseline]) => {
      this.setState({ 
        baseline,
        activity: false, 
        loading: false,
        userClosed: soap.closed || false
      });
    }).catch(error => {
      this.setState({
        error: error.message,
        activity: false, 
        loading: false
      });
    });
  }

  loadSoapNote() {
    const { user, injury, soap } = this.props;
    this.setState({ error: null, activity: true });

    this.props.getSoapNote(user.id, injury.id, soap.id).then(() => {
      this.setState({ 
        activity: false 
      });
    }).catch(error => {
      this.setState({
        error: error.message,
        activity: false
      });
    });
  }

  onSaveSection(nextKey, attributes) {
    this.setState({ activity: true, error: null });

    const { user, injury, soap } = this.props;

    this.props.updateSoapNote(user.id, injury.id, soap.id, attributes).then(() => {
      this.setState({ activity: false });
      onSectionSaveSuccess();
      this.querySection(nextKey);
    }).catch(error => {
      this.setState({
        error: error.message,
        activity: false
      });
    });
  }

  querySection(nextSection) {
    const { router = {} } = this.props;
    router.setQueryParams({
      section: nextSection
    });
  }

  closeSoapNote() {
    this.setState({ submitted: true });

    const closable = this.isValidToCloseNote();
    if (closable.valid === false) {
      this.querySection(closable.show);
      onError();
      return;
    }

    const { user, injury, soap } = this.props;

    if (soap.closed) {
      this.routeToInjuryHistory();
      return;
    }

    this.setState({
      activity: true,
      error: null
    });

    this.props.updateSoapNote(user.id, injury.id, soap.id, { closed: true }).then(() => {
      this.setState({ 
        activity: false, 
        userClosed: true
      }, () => {
        this.routeToInjuryHistory();
      });
    }).catch(error => {
      this.setState({
        error: error.message,
        activity: false
      });
    });
  }

  routeToInjuryHistory() {
    const { user, injury } = this.props;
    this.props.router.push(
      replaceLinkParams(ClinicPaths.patientProfile.injuries.soapNotes.index.link, {
        clinicId: getCurrentClinicId(),
        userId: user.id,
        injuryId: injury.id
      })
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { params = {} } = ownProps;
  const { userId, injuryId, soapId } = params;
  const user = UserSelector.getUser(state, { userId });
  const injury = InjurySelector.getUserInjury(state, { userId, injuryId });
  const soap = SoapNoteSelector.getUserSoapNote(state, { userId, injuryId, soapId });
  const currentUser = UserSelector.getCurrentUser(state) ?? {};
  const clinicId = getCurrentClinicId() || 0;
  const currentClinic = (currentUser.clinics || []).find(clinic => clinic.id === clinicId) || {};
  const recoveryStages = InjurySelector.getInjuryRecoveryStages(state, { userId, injuryId }) || [];
  const currentStage = soap.recovery_protocol_stage || {};
  const previousStage = recoveryStages.slice().reverse().find(stage => (
    stage.id !== currentStage.id && stage.created_at < soap.created_at
  ));
  
  return { 
    user, 
    injury, 
    soap, 
    currentUser, 
    currentClinic,
    previousStage
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUser: (userId) => {
      return dispatch(getUserAsync(userId));
    },
    getInjury: (userId, injuryId) => {
      return dispatch(getInjuryAsync(userId, injuryId));
    },
    getSoapNote: (userId, injuryId, soapId) => {
      return dispatch(getSoapNoteAsync(userId, injuryId, soapId));
    },
    updateSoapNote: (userId, injuryId, soapId, attributes) => {
      return dispatch(updateSoapNoteAsync(userId, injuryId, soapId, attributes));
    },
    getActiveBaseline: (userId) => {
      return dispatch(getActiveBaselineTestAsync(userId));
    },
    getBaselineTestBeforeDate: (userId, beforeDate) => {
      return dispatch(getBaselineBeforeDateAsync(userId, beforeDate));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SoapNotes)
);
