import React, { forwardRef } from 'react';
import { OrientationCard } from '../baseline-test-cards';

const OrientationStep = forwardRef((
  {
    baseline,
    onSubmit,
    ...rest
  },
  ref
) => {
  return (
    <OrientationCard
      {...rest}
      ref={ref}
      orientation={{ ...(baseline?.cognitive_assessment_test || {}) }}
      onSubmit={onSubmit}
    />
  );
});

const setDefaults = (initial) => {
  return {
    sac_standard_assessment_score: !isNaN(initial.sac_standard_assessment_score)
      ? initial.sac_standard_assessment_score
      : 0,
    sac_notes: initial.sac_notes || '',
    what_month: !isNaN(initial.what_month) ? initial.what_month : '',
    what_date: !isNaN(initial.what_date) ? initial.what_date : '',
    what_day: !isNaN(initial.what_day) ? initial.what_day : '',
    what_time: !isNaN(initial.what_time) ? initial.what_time : '',
    what_year: !isNaN(initial.what_year) ? initial.what_year : ''
  };
};

OrientationStep.hasChanged = (initial, current) => {
  const initialState = setDefaults(initial);

  return initialState.sac_standard_assessment_score !== current.sac_standard_assessment_score
    || initialState.sac_notes !== current.sac_notes
    || initialState.what_month !== current.what_month
    || initialState.what_date !== current.what_date
    || initialState.what_day !== current.what_day
    || initialState.what_time !== current.what_time
    || initialState.what_year !== current.what_year;
};

export default OrientationStep;
