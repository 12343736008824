import React, { useState } from 'react';
import ClaimDetails from './claim-details';
import WorkerDetails from './worker-details';
import ClaimDocumentsTab from './documents-tab';
import InitialAppointmentBanner from './initial-appointment-banner';
import Tabs from '../../tabs';
import Strings from '../lang';
import { ClaimStatusStrings } from '../utils';
import ClaimAppointmentsTab from './appointments-tab';
import ReportsTab from './reports-tab';

const StatusLabel = ({ status }) => {
  const str = ClaimStatusStrings[status];

  if (status === 'not_started') {
    return (
      <div className="label label-danger">{str}</div>
    );
  }

  if (status === 'active') {
    return (
      <div className="label label-success">{str}</div>
    );
  }

  if (status === 'discharged') {
    return (
      <div className="label label-default">{str}</div>
    );
  }

  return null;
};

const ClaimDisplay = ({ claim }) => {
  const [tabKey, setTabKey] = useState('appointments');

  if (!claim?.id) {
    return null;
  }

  return (
    <div className="idt-claim">
      <div className="idt-claim-header">
        <h1 className="display">
          {Strings.formatString(Strings.claimTitle, claim.claim_number)}
        </h1>
        <StatusLabel status={claim.status} />
      </div>
      <InitialAppointmentBanner
        visible={claim.status === 'not_started'}
        referralDate={claim.referral_date}
        claimId={claim.id}
      />
      <div className="row">
        <div className="col-md-4">
          <ClaimDetails claim={claim} />
        </div>
        <div className="col-md-8">
          <WorkerDetails worker={claim.worker} />
        </div>
      </div>
      <Tabs
        selectedKey={tabKey}
        onSelectTab={setTabKey}
        items={[
          {
            key: 'appointments',
            label: Strings.appointmentsTabLabel,
            component: (
              <ClaimAppointmentsTab
                claimId={claim.id}
                status={claim.status}
                blocks={claim.treatment_blocks}
              />
            )
          },
          {
            key: 'reports',
            label: Strings.reportsTab,
            component: (
              <ReportsTab claim={claim} />
            )
          },
          {
            key: 'documents',
            label: Strings.documentsTabLabel,
            component: (
              <ClaimDocumentsTab
                claimId={claim.id}
                status={claim.status}
                documents={claim.documents}
              />
            )
          }
        ]}

      />
    </div>
  );
};

export default ClaimDisplay;
