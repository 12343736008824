const EN = {
  title: 'Injury Reports',
  emptyMessageText: 'This patient has never had an injury reported remotely.',
  accountLabelText: 'ACCOUNT',
  birthdateLabelText: 'DATE OF BIRTH',
  injurydateLabelText: 'DATE REPORTED',
  ageLabelText: 'AGE',
  genderLabelText: 'GENDER',
  bagtagLabelText: 'ID Card Number',
  injuryInformationTitle: 'Injury Information',
  redFlagsReportedTitle: 'Red Flags Reported',
  symptomsTitle: 'Symptom Scores',
  associationLabel: 'Association',
  schoolNameLabel: 'School Name',
  schoolNameQuestionLabel: 'Did this injury happen at School?',
  lossConsciousnessLabel: 'Was there a loss of consciousness?',
  lossConsciousnessDurationLabel: 'Length of LOC',
  sportRelatedLabel: 'Was this a sports related injury?',
  sportLabel: 'Which Sport?',
  swallowLabel: 'Difficulty swallowing',
  seizuresLable: 'Seizures',
  slurredSpeedLabel: 'Difficulty speaking, slurring of words, or difficulty understanding speech',
  headacheLabel: 'Severe or worsening headache',
  confusionLabel: 'Extreme confusion not improving',
  neckPainLabel: 'Extreme neck pain or inability to turn the head all the way in either direction',
  lostConsciousnessLabel: 'Loss of consciousness for more than 1 minute',
  vomitingLabel: 'Vomiting',
  armsOrLegsWeaknessLabel: 'Weakness in arms or legs',
  assessedText: 'Assessed',
  startAssessmentTitle: 'Start Assessment',
  noRedFlagsReported: 'No Red Flags Reported',
  secondsText: 'seconds',
  minutesText: 'minutes',
  ongoingInjuryAssessmentMessage: 'Are you sure you want to start an injury assessment? An injury assessment will be added to this patients ongoing injury.',
  startAssessmentConfirmMessage: 'Are you sure you want to start an injury assessment?',
  bessScoreLabel: 'BESS Score',
  orientationScoreLabel: 'Orientation Score',
  memoryScoreLabel: 'Memory Score',
  notCompletedTestText: 'Not Completed',
  actionTakenLabel: 'Action Taken',
  actionTaken_proceed: 'Continued With Assessment',
  actionTaken_hospital: 'Sent To The Hospital',
  actionTaken_ambulance: 'Called An Ambulance',
  reportedByLabelText: 'Reported By',
  injuryDescription: 'Injury description as reported from sideline',
  noInjuryDescription: 'No injury description provided',
  hitToHeadLabelText: 'Was there direct impact to the head?',
  hitToHeadLocationLabel: 'Head Impact Locations',
  hitToHeadFront: 'Front',
  hitToHeadRight: 'Right',
  hitToHeadLeft: 'Left',
  hitToHeadTop: 'Top',
  hitToHeadBack: 'Back',
  noHeadHitLocationSelected: 'No head impact locations provided',
  notProvidedLabel: 'Not Provided',
  yesAnswer: 'Yes',
  noAnswer: 'No',
  unknownLOCDurationUnit: '(unknown unit)',
  neurocognitiveTestSectionTitle: 'Neurocognitive Test'
};

export default EN;
