import React from 'react';
import { CCMI_TRAINING_LINK, CCMI_TRAINING_TARGET } from './constants';
import Strings from './lang';

const UntrainedAlert = () => (
  <div className="alert alert-info alert-untrained">
    <p>{Strings.message}</p>
    <p>
      <a href={CCMI_TRAINING_LINK} target={CCMI_TRAINING_TARGET}>
        {Strings.trainingLinkText}
      </a>
    </p>
  </div>
);

export default UntrainedAlert;
