import { 
  DRIVE_UPDATE_AUTH, 
  DRIVE_UPDATE_FILES, 
  DRIVE_UPDATE_FILE,
  DRIVE_RESET_STATE
} from '../actions/drive';

const initialState = {
  auth: {},
  files: {}
};

const updateFileReducer = (state, file = {}) => {
  const { id } = file;
  return id ? {
    ...state, 
    files: {
      ...state.files,
      [id]: file
    }
  } : state;
};

const updateFilesReducer = (state, resource = {}) => {
  const { files = [] } = resource;
  const fileState = files.reduce((acc, file) => {
    return {
      ...acc,
      [file.id]: file
    };
  }, {});

  return {
    ...state,
    files: {
      ...state.files,
      ...fileState
    }
  };
};

const drive = (state = initialState, action = {}) => {
  switch (action.type) {
    case DRIVE_UPDATE_AUTH:
      return {
        ...state,
        auth: action.payload
      };
    case DRIVE_UPDATE_FILES:
      return updateFilesReducer(state, action.payload);
    case DRIVE_UPDATE_FILE:
      return updateFileReducer(state, action.payload);
    case DRIVE_RESET_STATE:
      return initialState;
    default:
      return state;
  }
};

export default drive;
