import React from 'react';

const GRID_SIZE = 12;

const InjuryInformationRow = ({
  children
}) => {
  const childCount = React.Children.count(children);
  return (
    <div className="row injury-information-row">
      {React.Children.map(children, (child, index) => (
        <div key={index} className={`col-md-${Math.floor(GRID_SIZE / childCount)}`}>
          {React.cloneElement(child)}
        </div>
      ))}
    </div>
  );
};

export default InjuryInformationRow;
