import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import SymptomRadioInput from './symptom-radio-input';
import SymptomSelectInput from './symptom-select-input';
import symptomLabels from './symptom-labels';

const defaultSeverity = 6;

const getSymptomInputComponent = (type) => {
  switch (type) {
    case 'select':
      return SymptomSelectInput;
    case 'radio': /*! intentional fall through as radio is default type */
    default:
      return SymptomRadioInput;
  }
};

/*!
 * Controls rendering of each individual symptom. Also controls which type of SymptomInput
 * component to render as a symptom severity selector (radio, select)
 * It is a stateless component as all state is passed up to parent.
 */
class SymptomInputGroup extends Component {
  render() {
    return (
      <div className={classnames('symptom-level-group', this.props.groupClassName)}>
        {this.renderSymptoms()}
      </div>
    );
  }

  renderSymptoms() {
    const { 
      symptoms, type, maxSeverity = defaultSeverity, onUpdate = () => {}, onUpdateNotes = () => {} 
    } = this.props;
    const values = [...new Array(maxSeverity + 1)].map((na, index) => {
      return index.toString();
    });
    const SymptomInputComponent = getSymptomInputComponent(type);

    return Object.keys(symptoms).map(symptom => (
      <SymptomInputComponent
        key={symptom}
        name={symptom}
        className={this.props.inputClassName}
        maxSeverity={maxSeverity}
        label={symptomLabels[symptom]}
        value={symptoms[symptom].level}
        notes={symptoms[symptom].notes}
        required={this.props.required}
        values={values}
        touched={this.props.touched}
        onUpdate={onUpdate}
        onUpdateNotes={onUpdateNotes}
      />
    ));
  }
}

SymptomInputGroup.propTypes = {
  type: PropTypes.oneOf(['radio', 'select']),
  symptoms: PropTypes.objectOf(
    PropTypes.shape({
      level: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      notes: PropTypes.string
    })
  ).isRequired,
  required: PropTypes.bool,
  onUpdate: PropTypes.func,
  maxSeverity: PropTypes.number,
  onUpdateNotes: PropTypes.func
};

export default SymptomInputGroup;
