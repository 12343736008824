import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ReactionTimeTrial, MAX_SELECT_NUMBER, SELECT_NUMBER_STEP } from './reaction-time-trial';
import { SelectInputGroup } from '@/forms';
import calculateReactionTime from './reaction-time-calculator';
import ScoreView from '../../../score-view';
import Strings from './lang';

const NUMBER_OF_TRIALS = 5;
const timeDecimalMax = 3;

const createDefaultTrials = () => {
  return [...new Array(NUMBER_OF_TRIALS)].fill('');
};

const calculateAverageDistance = (trials) => {
  const avgDist = trials.reduce((acc, cur) => {
    return acc + (cur !== '' ? parseFloat(cur) : 0);
  }, 0);

  return avgDist / trials.length;
};

class ReactionTime extends Component {
  constructor(props) {
    super(props);
    const { initialState = {} } = props;
    const { trials = createDefaultTrials(), dominant_hand = '' } = initialState;
    const trialsD = [...(trials.length > 0 ? trials : createDefaultTrials())];

    this.onDominantHandUpdate = this.onDominantHandUpdate.bind(this);

    this.state = {
      trials: trialsD,
      dominant_hand,
      averageDistance: calculateAverageDistance(trialsD)
    };
  }

  componentDidMount() {
    const { dominant_hand, trials } = this.state;
    const { onUpdate = () => {} } = this.props;
    onUpdate({ trials, dominant_hand });
  }

  render() {
    const { averageDistance } = this.state;
    return (
      <div className="baseline-card">
        <div className="card-section">
          {this.renderDominantHandSelector()}
        </div>
        {this.renderTrials()}
        <ScoreView
          label={Strings.averageDistanceLabel}
          score={averageDistance}
        />
        <ScoreView
          label={Strings.averageTimeLabel}
          score={calculateReactionTime(averageDistance).toFixed(timeDecimalMax)}
        />  
      </div>
    );
  }

  renderDominantHandSelector() {
    const { dominant_hand } = this.state;
    return (
      <SelectInputGroup
        className="form-group"
        labelText={Strings.dominantHandLabel}
        inputProps={{
          className: 'form-control',
          value: dominant_hand
        }}
        required
        messageClassName="alert alert-danger"
        messageText={Strings.dominantHandErrorMsg}
        valid={dominant_hand !== ''}
        touched={this.props.touched}
        onUpdate={this.onDominantHandUpdate}
      >
        <option value="">{Strings.defaultOption}</option>
        <option value="left">{Strings.leftHandOption}</option>
        <option value="right">{Strings.rightHandOption}</option>
      </SelectInputGroup>
    );
  }

  renderTrials() {
    const { trials } = this.state;

    return trials.map((value, index) => {
      return (
        <ReactionTimeTrial
          trialLabel={Strings.formatString(Strings.trialLabel, index + 1)}
          key={index}
          value={value}
          maxSelectNumber={MAX_SELECT_NUMBER}
          selectNumberStep={SELECT_NUMBER_STEP}
          reactionTime={calculateReactionTime(value).toFixed(timeDecimalMax)}
          onUpdate={(value) => this.onTrialUpdate(index, value)}
          touched={this.props.touched}
        />
      );
    });
  }

  onDominantHandUpdate(value) {
    const { onUpdate = () => {} } = this.props;
    const { trials } = this.state;
    let { dominant_hand } = this.state;

    dominant_hand = value;

    this.setState({
      dominant_hand
    });

    onUpdate({
      dominant_hand,
      trials
    });
  }

  onTrialUpdate(trialNumber, value) {
    const { dominant_hand, trials } = this.state;
    let { averageDistance } = this.state;
    const { onUpdate = () => {} } = this.props;
    trials[trialNumber] = value;

    averageDistance = calculateAverageDistance(trials);

    this.setState({
      trials,
      averageDistance
    });

    onUpdate({ trials, dominant_hand });
  }
}

ReactionTime.propTypes = {
  initialState: PropTypes.shape({
    trials: PropTypes.array,
    dominant_hand: PropTypes.string
  }),
  onUpdate: PropTypes.func,
  touched: PropTypes.bool
};

export default ReactionTime;
