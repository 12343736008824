const SymptomKeys = {
  Headache: 'headache',
  HeadPressure: 'head_pressure',
  NeckPain: 'neck_pain',
  Nausea: 'nausea',
  Dizziness: 'dizziness',
  BlurredVision: 'blurred_vision',
  BalanceProblems: 'balance_problems',
  LightSensitivity: 'light_sensitivity',
  NoiseSensitivity: 'noise_sensitivity',
  FeelingSlowedDown: 'feeling_slowed_down',
  FeelingInAFog: 'feeling_in_a_fog',
  DontFeelRight: 'dont_feel_right',
  DifficultyConcentrating: 'difficulty_concentrating',
  DifficultyRemembering: 'difficulty_remembering',
  Fatigue: 'fatigue',
  Confusion: 'confusion',
  Drowsiness: 'drowsiness',
  TroubleFallingAsleep: 'trouble_falling_asleep',
  MoreEmotional: 'more_emotional',
  Irritability: 'irritability',
  Sadness: 'sadness',
  Nervous: 'nervous'
};

export default SymptomKeys;
