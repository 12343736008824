const defaultRedFlags = {
  none: false,
  severe_headache: false,
  seizures: false,
  focal_neurological_signs: false,
  visibly_drowsy: false,
  recurring_vomiting: false,
  slurred_speech: false,
  cant_recognize_people_places: false,
  increasing_confusion_irritability: false,
  weakness: false,
  fail_ct_rules: false,
  behaviour_change: false,
  fail_cervical_spine_rules: false,
  decreasing_conscious: false,
  sixty_plus_years: false,
  intoxicated: false,
  skull_fracture: false,
  glascow_coma: false
};

export default defaultRedFlags;
