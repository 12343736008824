import React from 'react';
import classnames from 'classnames';
import Strings from '../lang';

const HeaderColumn = ({
  sortKey,
  sortedBy,
  onSort = () => {},
  children
}) => (
  <th
    className={classnames({ 
      highlighted: !!sortedBy && sortKey === sortedBy,
      'no-action': !sortedBy 
    })}
    onClick={() => !!sortedBy && onSort(sortedBy)}
  >
    {children}
  </th>
);

const LineItemsHeader = ({
  sortKey,
  onUpdateSortKey = () => {}
}) => {
  return (
    <tr>
      <HeaderColumn
        sortedBy="created_at"
        sortKey={sortKey}
        onSort={onUpdateSortKey}
      >
        {Strings.dateLabel}
      </HeaderColumn>
      <HeaderColumn
        sortedBy="type"
        sortKey={sortKey}
        onSort={onUpdateSortKey}
      >
        {Strings.typeLabel}
      </HeaderColumn>
      <HeaderColumn
        sortedBy="buyer"
        sortKey={sortKey}
        onSort={onUpdateSortKey}
      >
        {Strings.purchaserLabel}
      </HeaderColumn>
      <HeaderColumn
        sortedBy="patient_account"
        sortKey={sortKey}
        onSort={onUpdateSortKey}
      >
        {Strings.patientLabel}
      </HeaderColumn>
      <HeaderColumn
        sortedBy="used_at"
        sortKey={sortKey}
        onSort={onUpdateSortKey}
      >
        {Strings.usedAtLabel}
      </HeaderColumn>
      <HeaderColumn>
        {Strings.priceLabel}
      </HeaderColumn>
    </tr>
  );
};

export default LineItemsHeader;
