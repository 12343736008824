import React, { useState } from 'react';
import { EditButton } from '../buttons';
import EditClinicFeaturesModal from './edit-clinic-features';
import Icon from '../icon';
import Strings from './lang';

const ClinicFeatureItem = ({ label, enabled, children }) => {
  return (
    <li className="clinic-feature-item">
      <Icon fixedWidth name={enabled ? 'check' : 'xmark'} className={enabled ? 'success' : 'red'} />
      <div>{label}</div>
      {children}
    </li>
  );
};

const ClinicMapLinks = ({ clinic }) => {
  const { address } = clinic;
  const { latitude, longitude } = address || {};

  if (!clinic.searchable || !latitude || !longitude) return null;

  return (
    <span>
      (<a href={`${process.env.CCMI_MAPS_HOST}/?lat=${latitude}&lng=${longitude}&z=15`} target="_blank" rel="noopener">location</a> | <a href={`${process.env.CCMI_MAPS_HOST}/${clinic.slug}`} target="_blank" rel="noopener">profile</a>)
    </span>
  );
};

const ClinicFeatures = ({ clinic }) => {
  const [editing, setEditing] = useState(false);

  return (
    <div className="clinic-features-display">
      <div className="clinic-features-title">
        <h3 className="semi-bold">{Strings.featuresTitle}</h3>
        <EditButton editing={false} onClick={() => setEditing(true)} />
      </div>
      <ul className="clinic-features-list">
        <ClinicFeatureItem
          label={Strings.clinicMapLabel}
          enabled={clinic.searchable}
        >
          <ClinicMapLinks clinic={clinic} />
        </ClinicFeatureItem>
        <ClinicFeatureItem
          label={Strings.axiaIdtProviderLabel}
          enabled={clinic.idt_provider}
        />
        <ClinicFeatureItem
          label={Strings.resourcesEnabledLabel}
          enabled={clinic.resources_enabled}
        />
      </ul>
      <EditClinicFeaturesModal
        clinic={clinic}
        opened={editing}
        onClose={() => setEditing(false)}
      />
    </div>
  );
};

export default ClinicFeatures;
