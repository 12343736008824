import React from 'react';
import PropTypes from 'prop-types';
import Strings from './lang';

const ClinicianContactInfoCard = ({
  user = {}
}) => {
  const person = user.person || {};
  const email = user.email || '';

  return (
    <div className="card card-show">

      <div className="card-row">
        <label>{ Strings.phoneNumberLabelText }:</label>
        <span>{ person.phone_number }</span>
      </div>

      <div className="card-row">
        <label>{ Strings.emailLabelText }:</label>
        <span>{ email }</span>
      </div>  

    </div>
  );
};

ClinicianContactInfoCard.propTypes = {
  user: PropTypes.object.isRequired
};

export default ClinicianContactInfoCard;
