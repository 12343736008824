import React, { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import Activity from '../activity';
import { ErrorBanner } from '../errors';
import ReferralTabs from './referral-tabs';
import { InjurySelector, ReferralSelector } from '@/redux/selectors';
import { getInjuryReferralsAsync } from '@/redux/thunks/referrals';
import { useMount } from '@/hooks';
import { DISPLAY_DATE_FORMAT } from '@/utilities/dates/formats';
import Strings from './lang';

const InjuryReferralsList = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const referrals = useSelector(state => (
    ReferralSelector.getInjuryReferrals(state, { injuryId: params.injuryId })
  ));
  const injury = useSelector(state => (
    InjurySelector.getUserInjury(state, { userId: params.userId, injuryId: params.injuryId })
  ));

  const [loading, setLoading] = useState(!referrals.length);
  const [error, setError] = useState(null);

  const getInjuryReferrals = useCallback(() => {
    setLoading(true);
    setError(null);

    dispatch(getInjuryReferralsAsync(params.userId, params.injuryId)).then(() => {
      setLoading(false);
    }).catch(error => {
      setError(error.message);
      setLoading(false);
    });
  }, [dispatch, params.injuryId, params.userId]);

  useMount(() => {
    getInjuryReferrals();
  });
  
  return (
    <div>
      <h2>
        {Strings.formatString(
          Strings.injuryReferralsTitle, 
          dayjs(injury.injured_at).format(DISPLAY_DATE_FORMAT)
        )}
      </h2>
      <Activity active={loading} static={!referrals.length}>
        <ErrorBanner error={error} />
        <ReferralTabs 
          referrals={referrals}
          linksEnabled={false}
        />
      </Activity>
    </div>
  );
};

export default InjuryReferralsList;
