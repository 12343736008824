import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { SelectInputGroup } from '@/forms';
import Strings from './lang';

const MAXIMUM_OPTIONS = 100;

const GripStrengthTrial = ({
  trialLabel, 
  inputProps, 
  touched,
  onUpdate = () => {}
}) => {
  const options = useMemo(() => [...new Array(MAXIMUM_OPTIONS)].map((n, index) => (
    <option key={index} value={index}>{index}</option>
  )), []);

  const onRightUpdated = useCallback((value) => {
    onUpdate('right', value);
  }, [onUpdate]);

  const onLeftUpdated = useCallback((value) => {
    onUpdate('left', value);
  }, [onUpdate]);

  return (
    <div className="baseline-card-row">
      <label>{trialLabel}</label>
      <SelectInputGroup
        required
        labelText={Strings.rightHandLabel}
        className="form-group baseline-select-input"
        inputProps={{
          className: 'form-control',
          value: inputProps.rightValue
        }}
        onUpdate={onRightUpdated}
        touched={touched}
        messageClassName="alert alert-danger"
        valid={inputProps.rightValue !== ''}
      >
        <option value="">{Strings.defaultOption}</option>
        {options}
      </SelectInputGroup>
      <SelectInputGroup
        labelText={Strings.leftHandLabel}
        className="form-group baseline-select-input"
        inputProps={{
          className: 'form-control',
          value: inputProps.leftValue
        }}
        onUpdate={onLeftUpdated}
        touched={touched}
        messageClassName="alert alert-danger"
        valid={inputProps.leftValue !== ''}
        required
      >
        <option value="">{Strings.defaultOption}</option>
        {options}
      </SelectInputGroup>
    </div>
  );
};

GripStrengthTrial.propTypes = {
  trialLabel: PropTypes.string,
  inputProps: PropTypes.object,
  onUpdate: PropTypes.func,
  touched: PropTypes.bool
};

export default GripStrengthTrial;
