const FR = {
  title: 'Attribuer la carte d’identité Concussion (c\'est-à-dire l’étiquette)',
  bagTagLabelText: 'Veuillez écrire le numéro de la carte d’identité de la concussion que vous désirez attribuer au patient ci-dessous :',
  bagTagErrorText: 'Veuillez écrire le numéro de la carte d’identité de la concussion que vous désirez attribuer à ce patient',
  confirmBagTagLabelText: 'Écrivez à nouveau le numéro de la carte d’identité de la concussion que vous désirez attribuer :',
  confirmBagTagErrorText: 'Veuillez écrire à nouveau le numéro de la carte d’identité de la concussion que vous désirez attribuer à ce patient ',
  reasonLabelText: 'Raison de l’attribution d’une nouvelle carte d’identité :',
  reasonErrorText: 'Veuillez choisir l’une des raisons suivantes afin d\'attribuer une nouvelle carte d’identité de concussion à ce patient.',
  reasonOptionLost: 'Perdue',
  reasonOptionStolen: 'Volée',
  reasonOptionDamaged: 'Endommagée',
  reasonOptionOther: 'Autre',
  assignLabelText: 'Êtes-vous sûr(e) de vouloir attribuer une nouvelle carte d’identité de la concussion à ce patient?',
  assignErrorText: 'Veuillez confirmer que vous voulez vraiment attribuer une nouvelle carte d’identité de la concussion à ce patient',
  termsLabelText: 'J\'accepte les termes de l\'attribution d’une nouvelle carte d’identité de la concussion',
  termsErrorText: 'Vous devez accepter les termes de l’attribution d’une nouvelle carte d’identité de la concussion',
  terms: 'En acceptant les termes de l’attribution d’une nouvelle carte d’identité de la concussion, vous acceptez que cette décision soit permanente. Vous acceptez également que cette action remplacera le numéro de la carte d’identité actuelle, s’il existe, et que ce numéro ne pourra plus être utilisé.',
  cancelButtonTitle: 'Annuler',
  assignButtonTitle: 'Attribuer la carte d’identité',
  skipAssignButtonTitle: 'Sauter'
};
 
export default FR;
