import React from 'react';
import classnames from 'classnames';
import Icon from '../icon';

export const StatusView = ({
  icon,
  statusColor,
  iconClassName,
  title,
  message
}) => {
  return (
    <div className="status-view">
      <Icon name={icon} className={classnames('status-icon', iconClassName)} />
      {title && <h2 className={statusColor}>{title}</h2>}
      {message && <div>{message}</div>}
    </div>
  );
};

export const ErrorView = ({ title, error }) => {
  return (
    <StatusView
      icon="circle-exclamation"
      iconClassName="error"
      statusColor="error"
      title={title}
      message={error}
    />
  );
};

export const EmptyView = ({ message }) => {
  return (
    <StatusView
      icon="ban"
      iconClassName="light"
      title={message}
    />
  );
};
