import React from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { DISPLAY_DATE_FORMAT } from '@/utilities/dates/formats';
import { getWorkerInitial } from '../utils';
import Strings from '../lang';

const DischargedClaimsTable = ({ claims = [] }) => {
  return (
    <table className="table">
      <thead>
        <tr>
          <th className="no-action">{Strings.claimNumberColumn}</th>
          <th className="no-action">{Strings.workerNameColumn}</th>
          <th className="no-action">{Strings.dischargedDateColumn}</th>
        </tr>
      </thead>
      <tbody>
        {claims.map(claim => (
          <tr key={claim.id}>
            <td>
              <Link to={`../${claim.id}`}>
                <strong>{claim.claim_number}</strong>
              </Link>
            </td>
            <td>{getWorkerInitial(claim.worker.first_name)} {claim.worker.last_name}</td>
            <td>{dayjs(claim.discharged_at).format(DISPLAY_DATE_FORMAT)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default DischargedClaimsTable;
