import React from 'react';
import PropTypes from 'prop-types';
import { If, Then } from 'react-if';
import { getCountryName, getCountryRegionName } from '@/utilities/country-regions';
import Strings from './lang';

const PlayerContactInfoCard = ({
  user = {}
}) => {
  const person = user.person || {};
  const mailing_address = user.mailing_address || {};
  const emergency_contact = user.emergency_contact || {};

  return (
    <div className="card card-show">

      <div className="card-row">
        <label>{ Strings.addressLabelText }:</label>
        <span>{ mailing_address.street_1 }</span>
      </div>

      <If condition={mailing_address.street_2 != null && mailing_address.street_2.length > 0}>
        <Then>
          <div className="card-row">
            <label />
            <span>{ mailing_address.street_2 }</span>
          </div>
        </Then>
      </If>

      <div className="card-row">
        <label>{ Strings.cityLabelText }:</label>
        <span>{ mailing_address.city }</span>
      </div>

      <div className="card-row">
        <label>{ Strings.regionLabelText }:</label>
        <span>{ getCountryRegionName(mailing_address.country, mailing_address.state) }</span>
      </div>

      <div className="card-row">
        <label>{ Strings.countryLabelText }:</label>
        <span>{ getCountryName(mailing_address.country) }</span>
      </div>

      <div className="card-row">
        <label>{ Strings.postalCodeLabelText }:</label>
        <span>{ mailing_address.postal_code }</span>
      </div>

      <div className="card-row">
        <label>{ Strings.phoneNumberLabelText }:</label>
        <span>{ person.phone_number }</span>
      </div>

      <div className="card-row">
        <label>{ Strings.emailLabelText }:</label>
        <span>{ user.email }</span>
      </div>

      <div className="card-row">
        <label>{ Strings.emergencyContactLabelText }:</label>
        <span>{ emergency_contact.name }</span>
      </div>

      <div className="card-row">
        <label>{ Strings.emergencyContactPhoneLabelText }:</label>
        <span>{ emergency_contact.phone_number }</span>
      </div>

      <div className="card-row">
        <label>{ Strings.emergencyContactEmailLabelText }:</label>
        <span>{ emergency_contact.email }</span>
      </div>

      <div className="card-row">
        <label>{ Strings.emergencyContactRelationshipLabelText }:</label>
        <span>{ emergency_contact.relationship }</span>
      </div>

    </div>
  );
};

PlayerContactInfoCard.propTypes = {
  user: PropTypes.object.isRequired
};

export default PlayerContactInfoCard;
