import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { If, Then, Else } from 'react-if';
import { SymptomLevelsShowCard } from '../../../symptoms';
import CurrentSymptomLevelsForm from './current-symptom-levels-form';
import Card from '../../../card';
import Activity from '../../../activity';
import { ErrorBanner } from '../../../errors';
import Strings from './lang';

class CurrentSymptomsCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activity: false,
      error: null
    };
  }

  render() {
    const { baseline = {} } = this.props;
    const { pre_existing_symptoms = {} } = baseline;
    const hasSymptoms = Object.keys(pre_existing_symptoms).length > 0;

    return (
      <div className="row">

        <ErrorBanner error={this.state.error} />
        <Activity active={this.state.activity} static>     
          <If condition={hasSymptoms}>
            <Then>
              <div className="col-md-4">
                <Card title={Strings.baselineSymptomsHeader}>
                  <SymptomLevelsShowCard
                    symptoms={pre_existing_symptoms}
                  />
                </Card>      
              </div>
            </Then>
            <Else>
              <div className="col-md-12">
                <p className="alert alert-warning">
                  {Strings.noActiveBaselineWarning}
                </p>
              </div>
            </Else>
          </If>
        </Activity>

        <div className={hasSymptoms ? 'col-md-8' : 'col-md-12'}>

          <Card title={Strings.currentRatedSymptomsHeader}>
            <CurrentSymptomLevelsForm
              symptomLevels={this.props.currentSymptoms}
              submitted={this.props.submitted}
              onError={this.props.onError}
              onSubmit={this.props.onSubmit}
            />     
          </Card>

        </div>
      </div>
    );
  }
}

CurrentSymptomsCard.propTypes = {
  currentSymptoms: PropTypes.object,
  baseline: PropTypes.object,
  onSubmit: PropTypes.func
};

export default CurrentSymptomsCard;
