const UPDATE_ALL_CLINICS_STATISTICS = 'UPDATE_ALL_CLINICS_STATISTICS';

const updateAllClinicsStatistics = (stats) => ({
  type: UPDATE_ALL_CLINICS_STATISTICS,
  stats
});

export {
  UPDATE_ALL_CLINICS_STATISTICS,
  updateAllClinicsStatistics
};
