import React from 'react';
import PropTypes from 'prop-types';
import Strings from './lang';

const WordListDisplay = ({
  words,
  className
}) => {
  if (words && words.length > 0) {
    return (
      <ul className={className}>
        {words.map((word, index) => (
          <li key={index}>{word}</li>
        ))}
      </ul>
    );
  }

  return (
    <p className={className}>
      <i>{Strings.noListSelectedText}</i>
    </p>
  );
};

WordListDisplay.propTypes = {
  words: PropTypes.array.isRequired
};

export default WordListDisplay;
