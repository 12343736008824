import React, { useState, useEffect, useRef } from 'react';
import { parseMarkdown } from '@/utilities/parse-markdown';

const Markdown = ({ children, ...props }) => {
  const [content, setContent] = useState(null);

  useEffect(() => {
    setContent(
      children ? parseMarkdown(children, props) : null
    );
  }, [children, props]);

  return (
    <div dangerouslySetInnerHTML={{ __html: content }} />
  );
};

const loadMarkdown = (path) => {
  return fetch(path).then(response => {
    return response.ok 
      ? response.text()
      : Promise.resolve(response.statusText);
  }).then(text => {
    return Promise.resolve(text);
  }).catch(error => {
    return Promise.resolve(error.message);
  });
};

const RemoteMarkdown = ({ path, ...props }) => {
  const content = useRef(null);
  const [, setLoading] = useState(false);

  useEffect(() => {
    if (path) {
      setLoading(true);

      loadMarkdown(path).then(text => {
        content.current = text;
        setLoading(false);
      });
    }
  }, [path]);

  return (
    <Markdown {...props}>
      {content.current}
    </Markdown>
  );
};

Markdown.Remote = RemoteMarkdown;

export default Markdown;
