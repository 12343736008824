import React, { useCallback, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import Activity from '../activity';
import { Page } from '../page';
import { TestHeader } from '../test-header';
import { ErrorBanner } from '../errors';
import Card from '../card';
import Editor from '@/editor';
import SymptomsCard from './symptoms-card';
import NotesHistoryButton from './notes-history-button';
import RecoveryStageCard from './recovery-stage-card';
import { InjuryDiagnosis } from '../diagnosis';
import { FormComponent } from '@/forms';
import {
  InjurySelector,
  SpecialistNoteSelector,
  UserSelector
} from '@/redux/selectors';
import { getUserAsync } from '@/redux/thunks/users';
import { getInjuryAsync } from '@/redux/thunks/injuries';
import { getSpecialistNoteAsync, updateSpecialistNoteAsync, createSpecialistNoteAsync } from '@/redux/thunks/specialist-notes';
import { getCurrentClinicId } from '@/tokens';
import { ClinicPaths } from '@/paths';
import { useMount, useObjectState } from '@/hooks';
import replaceLinkParams from '@/utilities/replace-link-params';
import Strings from './lang';

const getCurrentClinic = (currentUser) => {
  const clinicId = getCurrentClinicId();
  return currentUser?.clinics?.find(clinic => clinic.id === clinicId);
};

const afterSubmitRouting = (navigate, params, noteId, clinicId) => {
  navigate(`../${noteId}`, { replace: true, relative: true });

  setTimeout(() => {
    navigate(
      replaceLinkParams(ClinicPaths.patientProfile.injuries.specialistNotes.index.link, {
        clinicId,
        userId: params.userId,
        injuryId: params.injuryId
      })
    );
  });
};

const buildAttributes = (state, note) => {
  const attributes = { ...state };

  if (!note?.id && attributes.recovery_protocol_stage) {
    attributes.recovery_protocol_stage_attributes = {
      ...attributes.recovery_protocol_stage
    };

    if (note?.recovery_protocol_stage?.id) {
      attributes.recovery_protocol_stage_attributes.id = note.recovery_protocol_stage.id;
    }
  }

  delete attributes.recovery_protocol_stage;

  return attributes;
};

const getStateFromNote = (note) => {
  const {
    symptoms_scores = {},
    notes = '',
    diagnosis = {},
    recovery_protocol_stage = null
  } = note || {};

  return {
    symptoms_scores,
    notes,
    diagnosis,
    recovery_protocol_stage
  };
};

const DiagnosisControl = ({
  noteId,
  diagnosis,
  injury,
  onSave
}) => {
  const hasDiagnosis = useMemo(() => Object.keys(diagnosis || {}).length > 0, [diagnosis]);
  const _diagnosis = useMemo(() => (
    hasDiagnosis ? diagnosis : injury.diagnosis
  ), [diagnosis, hasDiagnosis, injury.diagnosis]);

  // if no diagnosis set and is updating note,
  // do not show diagnosis control
  if (noteId && !hasDiagnosis) return null;

  return (
    <InjuryDiagnosis
      editable={!noteId}
      diagnosis={_diagnosis}
      headingText={`${Strings.diagnosisLabel}:`}
      injuryDate={injury.injured_at}
      onDiagnosisSave={onSave}
    />
  );
};

const SpecialistNotes = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const currentUser = useSelector(UserSelector.getCurrentUser);
  const currentClinic = useMemo(() => getCurrentClinic(currentUser), [currentUser]);
  const user = useSelector(state => (
    UserSelector.getUser(state, params)
  ));
  const injury = useSelector(state => (
    InjurySelector.getUserInjury(state, params)
  ));
  const note = useSelector((state) => (
    params.noteId ? SpecialistNoteSelector.getUserSpecialistNote(state, params) : null
  ));

  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [state, setState] = useObjectState(() => getStateFromNote(note), []);

  const loadData = useCallback(() => {
    setLoading(true);
    const userRequest = user?.id ? Promise.resolve() : dispatch(getUserAsync(params.userId));
    const injuryRequest = injury?.id && injury?.recovery_protocol_stage !== undefined
      ? Promise.resolve()
      : dispatch(getInjuryAsync(params.userId, params.injuryId));
    const noteRequest = note?.id || !params.noteId
      ? Promise.resolve()
      : dispatch(getSpecialistNoteAsync(params.userId, params.injuryId, params.noteId));

    Promise.all([
      userRequest,
      injuryRequest,
      noteRequest
    ]).then(([, , note]) => {
      if (note) {
        setState(getStateFromNote(note));
      }

      setLoading(false);
    }).catch(error => {
      setError(error.message);
      setLoading(false);
    });
  }, [
    dispatch,
    user?.id,
    injury?.id,
    injury?.recovery_protocol_stage,
    note?.id,
    params,
    setState
  ]);

  const onSubmit = useCallback(() => {
    setSubmitting(true);

    const attributes = buildAttributes(state, note);
    const request = note?.id
      ? updateSpecialistNoteAsync(params.userId, params.injuryId, params.noteId, attributes)
      : createSpecialistNoteAsync(params.userId, params.injuryId, attributes);

    dispatch(request).then((note) => {
      setSubmitting(false);
      afterSubmitRouting(navigate, params, note.id, currentClinic.id);
    }).catch(error => {
      setError(error.message);
      setSubmitting(false);
    });
  }, [state, note, params, dispatch, navigate, currentClinic.id]);

  useMount(() => {
    loadData();
  });

  return (
    <Page className="specialist-notes">
      <Activity active={submitting || loading} static={loading}>
        <h1 className="display">{Strings.specialistNotesTitle}</h1>
        <TestHeader
          user={user ?? {}}
          injury={injury}
          testDate={note?.created_at ?? dayjs()}
          tester={note?.clinic_user ?? currentUser}
          clinic={note?.clinic ?? currentClinic}
        />
        <ErrorBanner error={error} />
        <div className="row">
          <div className="col-md-6">
            <DiagnosisControl
              noteId={note?.id}
              injury={injury}
              diagnosis={state.diagnosis}
              onSave={setState}
            />
          </div>
          <div className="col-md-6">
            <div className="pull-right">
              <NotesHistoryButton
                clinicId={currentClinic.id}
                user={user}
                injury={injury}
                noteId={note?.id}
              />
            </div>
          </div>
        </div>
        <FormComponent onSubmit={onSubmit}>
          <SymptomsCard
            symptoms={state.symptoms_scores}
            locked={!!note?.id && Object.keys(note?.symptoms_scores || {}).length > 0}
            onUpdate={(symptoms) => setState({ symptoms_scores: symptoms })}
          />
          <Card title={Strings.notesSectionTitle}>
            <Editor
              value={state.notes}
              namespace="specialist-notes"
              placeholder={Strings.notesPlaceholder}
              onChange={(notes) => setState({ notes })}
            />
          </Card>
          <RecoveryStageCard
            editable={!note?.id}
            currentStages={note?.id
              ? note?.recovery_protocol_stage
              : state?.recovery_protocol_stage || injury.recovery_protocol_stage}
            previousStages={injury.recovery_protocol_stage}
            onUpdate={stages => {
              setState((prev) => {
                let recovery_protocol_stage = null;

                if (stages) {
                  recovery_protocol_stage = { 
                    ...(prev.recovery_protocol_stage || {}), 
                    ...stages 
                  };
                }

                return { recovery_protocol_stage };
              });
            }}
          />
          <button
            type="submit"
            disabled={!state.notes}
            className="btn btn-primary"
          >
            {Strings.submitButtonText}
          </button>
        </FormComponent>
      </Activity>
    </Page>
  );
};

export default SpecialistNotes;
