import React, { useCallback, useMemo, useState } from 'react';
import { ImageCropper, ImageCropperOptions } from '../../image-cropper';
import { 
  FormComponent, 
  FormInputGroup, 
  LanguageInputGroup, 
  FormListInputGroup,
  SelectInputGroup,
  MultiSelectInputGroup
} from '@/forms';
import { ProfessionStrings, servicesSelectOptions } from '@/utilities/clinical-info';
import { useObjectState } from '@/hooks';
import { RoleDescriptions, userHasRoleMatchingOneOfDescriptions } from '@/utilities/user-roles';
import { getInterfaceLanguage } from '@/utilities/localization';
import Strings from './lang';

const getAttributesFromUser = (user) => {
  const { person, clinical_info } = user || {};
  const { 
    first_name, 
    last_name, 
    phone_number,
    language,
    credentials,
    bio
  } = person || {};
  const { profession = '', services = [] } = clinical_info || {};

  return {
    first_name: first_name || '',
    last_name: last_name || '',
    phone_number: phone_number || '',
    language: language || getInterfaceLanguage(),
    bio: bio || '',
    photo_filename: null,
    photo_base64: null,
    credentials: credentials || { items: [] },
    profession,
    services
  };
};

const isValid = ({ 
  first_name, 
  last_name, 
  phone_number, 
  language,
  profession
}, isClinician) => {
  return !!first_name 
    && !!last_name 
    && !!phone_number 
    && !!language 
    && (isClinician ? !!profession : true);
};

const buildAttributes = (user, attributes, isClinician) => {
  const { person, clinical_info } = user;
  const { profession, services, ...personAttrs } = attributes;
  const attrs = {
    person_attributes: personAttrs
  };

  if (person && person.id) {
    attrs.person_attributes.id = person.id;
  }

  if (isClinician) {
    attrs.clinical_info_attributes = { profession, services };
    if (clinical_info && clinical_info.id) {
      attrs.clinical_info_attributes.id = clinical_info.id;
    }
  }

  return attrs;
};

const RegistrationAboutForm = ({ 
  user, 
  touched = false,
  onSubmit 
}) => {
  const [attributes, setAttributes] = useObjectState(() => getAttributesFromUser(user));
  const [submitted, setSubmitted] = useState(touched);
  const isClinician = useMemo(() => (
    userHasRoleMatchingOneOfDescriptions(
      user, 
      [RoleDescriptions.Clinician, RoleDescriptions.Specialist, RoleDescriptions.IDTClinician]
    )
  ), [user]);
  const valid = useMemo(() => isValid(attributes, isClinician), [attributes, isClinician]);

  const handleSubmit = useCallback(() => {
    setSubmitted(false);

    if (!valid) return;

    const attrs = buildAttributes(user, attributes, isClinician);

    onSubmit(attrs);
  }, [attributes, isClinician, onSubmit, user, valid]);

  return (
    <FormComponent autoComplete="off" onSubmit={handleSubmit}>
      <ImageCropper 
        className="avatar-image-cropper"
        inputName="avatar-image"
        chooseButtonClassName="btn btn-default"
        cropButtonClassName="btn btn-primary"
        cropperOptions={ImageCropperOptions}
        onCrop={(photo_filename, photo_base64) => {
          setAttributes({
            photo_filename, 
            photo_base64
          });
        }}
      />
      <div className="row">
        <div className="col-md-6">
          <FormInputGroup
            required
            className="form-group"
            labelText={Strings.firstNameLabelText}
            inputType="text"
            inputProps={{
              className: 'form-control',
              name: 'first_name',
              autoComplete: 'off',
              value: attributes.first_name,
              onChange: ({ target: { value } }) => {
                setAttributes({ 
                  first_name: value 
                });
              }
            }}
            messageText={Strings.firstNameErrorText}
            messageClassName="alert alert-danger"
            inputValid={attributes.first_name.length > 0}
            touched={submitted}
          />
        </div>
        <div className="col-md-6">
          <FormInputGroup
            required
            className="form-group"
            labelText={Strings.lastNameLabelText}
            inputType="text"
            inputProps={{
              className: 'form-control',
              name: 'last_name',
              autoComplete: 'off',
              value: attributes.last_name,
              onChange: ({ target: { value } }) => {
                setAttributes({ 
                  last_name: value 
                });
              }
            }}
            messageText={Strings.lastNameErrorText}
            messageClassName="alert alert-danger"
            inputValid={attributes.last_name.length > 0}
            touched={submitted}
          />
        </div>
      </div>

      {isClinician && (
        <>
          <SelectInputGroup
            className="form-group"
            labelText={Strings.professionLabelText}
            inputProps={{
              className: 'form-control',
              name: 'profession',
              value: attributes.profession
            }}
            required
            messageClassName="alert alert-danger"
            messageText={Strings.professionErrorMessage}
            valid={attributes.profession !== ''}
            touched={submitted}
            onUpdate={(profession) => setAttributes({ profession })}
          >
            <option value="">-- {Strings.professionPlaceholder} --</option>
            {Object.keys(ProfessionStrings).map(key => (
              <option key={key} value={key}>{ProfessionStrings[key]}</option>
            ))}
          </SelectInputGroup>

          <MultiSelectInputGroup 
            name="services"
            label={Strings.treatmentServicesLabelText}
            value={attributes.services}
            options={servicesSelectOptions}
            touched={submitted}
            placeholder={Strings.treatmentServicesPlaceholder}
            onChange={(services) => setAttributes({ services })}
          />
          <FormListInputGroup
            className="form-group credentials-input-group"
            inputClassName="form-control"
            labelText={Strings.credentialsLabelText}
            inputProps={{
              className: 'form-control',
              name: 'credentials',
              initialItems: attributes.credentials.items || [],
              placeholder: Strings.addCredentialInputPlaceholder,
              onUpdate: (items) => setAttributes({ credentials: { items } })
            }}
          />
        </>
      )}
      
      <FormInputGroup
        className="form-group phone-input-group"
        labelText={Strings.phoneLabelText}
        type="tel"
        inputProps={{
          className: 'form-control',
          name: 'phone_number',
          value: attributes.phone_number,
          onChange: ({ target: { value } }) => {
            setAttributes({ 
              phone_number: value 
            });
          }
        }}
        inputValid={attributes.phone_number.length > 0}
        messageText={Strings.phoneErrorText}
        messageClassName="alert alert-danger"
        required
        touched={submitted}
      />

      <LanguageInputGroup
        className="form-group"
        labelText={Strings.languageLabelText}
        inputProps={{
          className: 'form-control',
          name: 'language',
          value: attributes.language
        }}
        onUpdate={language => {
          setAttributes({
            language
          });
        }}
        messageText={Strings.languageValidationMessage}
        messageClassName="alert alert-danger"
        required
        touched={submitted}
      />

      <FormInputGroup
        inputValid
        className="form-group bio-input-group"
        labelText={Strings.bioLabelText}
        inputType="textarea"
        inputProps={{
          name: 'bio',
          className: 'form-control',
          rows: 10,
          value: attributes.bio,
          onChange: ({ target: { value } }) => {
            setAttributes({ 
              bio: value 
            });
          }
        }}
        touched={submitted}
      />
      <button type="submit" className="btn btn-primary" disabled={!valid}>
        {Strings.submitButtonText}
      </button>
    </FormComponent>
  );
};

export default RegistrationAboutForm;
