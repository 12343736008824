import React from 'react';
import { 
  MAX_INCLINE,
  MIN_SPEED,
  SPEED_INCREMENT,
  MAX_CONSTANT_SPEED_MINUTE,
  MINUTE_INTERVAL_OFFSET
} from './buffalo-data';
import Strings from '../lang';

const START_SPEED = 2.5;
const BORG_SCALE_MAX_OPTIONS = 15;
const BORG_SCALE_OPTIONS_OFFSET = 6;
const SYMPTOM_SCORE_MAX_OPTIONS = 11;
const SYMPTOM_SCORE_OPTIONS_OFFSET = 0;

const BorgScaleIndicatorText = {
  6: Strings.borgScaleLevel6Label,
  7: Strings.borgScaleLevel7Label,
  9: Strings.borgScaleLevel9Label,
  11: Strings.borgScaleLevel11Label,
  13: Strings.borgScaleLevel13Label,
  15: Strings.borgScaleLevel15Label,
  17: Strings.borgScaleLevel17Label,
  19: Strings.borgScaleLevel19Label,
  20: Strings.borgScaleLevel20Label
};

const getBorgScaleOptions = () => {
  return [...new Array(BORG_SCALE_MAX_OPTIONS)].map((_, index) => {
    const value = index + BORG_SCALE_OPTIONS_OFFSET;
    let text = BorgScaleIndicatorText[value] || null;

    if (text) {
      text = `(${text})`;
    }

    return (
      <option key={index} value={value}>{value} {text}</option>
    );
  });
};

const getSymptomScoreOptions = () => {
  return [...new Array(SYMPTOM_SCORE_MAX_OPTIONS)].map((_, index) => {
    const value = index + SYMPTOM_SCORE_OPTIONS_OFFSET;
    return (
      <option key={index} value={value}>{value}</option>
    );
  });
};

const createBuffaloTestTable = (intervals, renderRow) => {
  return intervals.map((item, index) => {
    const minuteValue = index + MINUTE_INTERVAL_OFFSET;
    let minute = `@${minuteValue}:00`;
    let incline = index - 1;
    let speed = MIN_SPEED;

    if (index === 0) {
      minute = Strings.warmUpRowText;
      incline = 0;
      speed = START_SPEED;
    }
    
    if (incline > MAX_INCLINE) {
      incline = MAX_INCLINE;
    }

    if (minuteValue > MAX_CONSTANT_SPEED_MINUTE) {
      speed += ((minuteValue - MAX_CONSTANT_SPEED_MINUTE) * SPEED_INCREMENT);
    }

    return renderRow({
      minute,
      item,
      index,
      speed,
      incline
    });
  });
};

export {
  getBorgScaleOptions,
  getSymptomScoreOptions,
  createBuffaloTestTable
};
