import React from 'react';
import dayjs from 'dayjs';
import { TestHeader } from '../test-header';
import OrthostaticVitalSignsTestTable from './ovs-test-table';
import OrthostaticVitalSignsTestResultView from './ovs-test-result-view';
import { MedicalTestEditButton } from '../medical-test-edit';
import LastEditedView from '../last-edited-view';
import Strings from './lang';

const OrthostaticVitalSignsTestView = ({
  showTestHeader = true,
  showResultsCalculations = true,
  user,
  injury,
  test,
  onEditClick
}) => {
  const headerClassName = showTestHeader ? 'display flex-row justify_space-between align_center' : 'bold';
  const HeaderElement = showTestHeader ? 'h1' : 'h2';

  return (
    <div className="ovs-test">
      <div className="ovs-test-header">
        <HeaderElement className={headerClassName}>
          {Strings.title}
          {onEditClick && (
            <MedicalTestEditButton
              className="btn btn-danger btn-sm no-print"
              onClick={onEditClick}
            />
          )}
        </HeaderElement>
        <h6><i>{Strings.subTitle}</i></h6>
      </div>
      {showTestHeader && (
        <TestHeader
          user={user}
          injury={injury}
          className="ovs-test-header"
          testDate={test.created_at || dayjs()}
          tester={test.clinic_user}
          clinic={test.clinic}
        />
      )}

      <LastEditedView item={test} />

      <OrthostaticVitalSignsTestResultView
        test={test}
        showCalculations={showResultsCalculations}
      />

      <div className="table-responsive-md">
        <OrthostaticVitalSignsTestTable test={test} />
      </div>

    </div>
  );
};

export default OrthostaticVitalSignsTestView;
