import React from 'react';
import InjuryInformationRow from './injury-information-row';
import InjuryInformationItem from './injury-information-item';
import Strings from '../lang';

const InjuryInformation = ({ children }) => (
  <div className="injury-report-information">
    <div className="row">
      <div className="col-md-12">
        <h2 className="bold">{Strings.injuryInformationTitle}</h2>
      </div>
    </div>
    {children}
  </div>
);

InjuryInformation.Row = InjuryInformationRow;
InjuryInformation.Item = InjuryInformationItem;

export default InjuryInformation;
