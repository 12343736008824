const EN = {
  notificationMessageText: 'You have 1 unread notification.',
  notificationsMessageText: 'You have {0} unread notifications.',
  viewButtonTitle: 'View',
  doneButtonTitle: 'Done',
  markAsReadButtonText: 'Mark as Read',
  notificationsModalTitle: 'Notifications'
};

export default EN;
