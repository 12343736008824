import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import useAuthorizedRoute from './use-authorized-route';

const AuthorizedRoute = ({
  allowDeactivated,
  allowUnconfirmed,
  allowUntrained,
  allowNonClinicUser,
  allowRoles,
  allowRolesForDeactivatedUser,
  allowRolesMatchingResourceId,
  resourceIdParam,
  children
}) => {
  const redirect = useAuthorizedRoute({
    allowDeactivated,
    allowUnconfirmed,
    allowUntrained,
    allowNonClinicUser,
    allowRoles,
    allowRolesForDeactivatedUser,
    allowRolesMatchingResourceId,
    resourceIdParam,
  });

  if (redirect) {
    return (
      <Navigate
        replace
        to={redirect.path}
        state={redirect.state}
      />
    );
  }

  return children ?? <Outlet />;
};

export default AuthorizedRoute;
