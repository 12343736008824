import { RoleDescriptions, userHasRoleMatchingDescription } from '@/utilities/user-roles';
import checkUserTrainingExpiry from '@/utilities/user-training-expiry';

const getVideosCount = (modules) => {
  return modules.reduce((acc, module) => acc + module.videos.length, 0);
};

export default {
  'physical-therapists': {
    name: 'Concussion Management for Physical Therapists (PT, DC, AT)',
    img: 'pt-course-graphic.png',
    permitted: (user) => (
      user.active 
        && userHasRoleMatchingDescription(user, RoleDescriptions.Clinician)
        && checkUserTrainingExpiry(user)
    ),
    modules: [{
      name: 'Introduction to Concussion & International Consensus Statements',
      module: 1,
      videos: [{
        id: '999212383',
        name: 'Introduction & Epidemiology'
      }, {
        id: '999215038',
        name: 'Neurology Overview'
      }, {
        id: '999215205',
        name: 'International Consensus Statements'
      }, {
        id: '999220750',
        name: 'Concussion Prevention & Recovery Optimization'
      }, {
        id: '999221029',
        name: 'Concussion Problems & Proposed Solutions'
      }]
    }, {
      name: 'Biomechanics & Pathophysiology of Concussion',
      module: 2,
      videos: [{
        id: '999265702',
        name: 'The Neurometabolic Cascade of Concussion'
      }, {
        id: '999266144',
        name: 'Biomechanics of Concussion'
      }, {
        id: '999266530',
        name: 'Autonomic Dysregulation & Cerebral Blood Flow'
      }, {
        id: '999268432',
        name: 'Vulnerability, Physiologic Recovery, & Second Impact Syndrome'
      }, {
        id: '999268784',
        name: 'Subconcussive Impacts'
      }, {
        id: '999269277',
        name: 'Multiple Concussions'
      }]
    }, {
      name: 'Acute Neuro-Endocrine Aspects of Concussion & Sleep Dysregulation',
      module: 3,
      videos: [{
        id: '999280554',
        name: 'Acute Immuno-Endocrine Aspects of Concussions'
      }, {
        id: '999281147',
        name: 'Importance of Sleep'
      }]
    }, {
      name: 'Comprehensive Baseline Testing',
      module: 4,
      videos: [{
        id: '999546168',
        name: 'Baseline Testing Evidence Review'
      }, {
        id: '999547347',
        name: 'Complete Concussions Baseline Test Battery'
      }, {
        id: '999547710',
        name: 'Testing Preparation'
      }, {
        id: '904615801',
        name: 'Baseline Testing Patient Registration & Check-in'
      }, {
        id: '999547959',
        name: 'Station 1 - Overview'
      }, {
        id: '904615611',
        name: 'Station 1 - Practical'
      }, {
        id: '999548378',
        name: 'Station 2 - Overview'
      }, {
        id: '904615483',
        name: 'Station 2 - Practical'
      }, {
        id: '999548906',
        name: 'Optional Tests - Overview'
      }, {
        id: '904615377',
        name: 'Optional Tests - Practical'
      }, {
        id: '234535379',
        name: 'Making a Reaction Time Stick',
        hash: '3d5a05c1cf'
      }]
    }, {
      name: 'Neurocognitive & Postural Sway Test Administration & Interpretation',
      module: 5,
      videos: [{
        id: '999553414',
        name: 'Concussion Tracker Application'
      }, {
        id: '999554825',
        name: 'DANA Test Protocols & Evidence'
      }, {
        id: '999556876',
        name: 'Baseline Neurocognitive Test Interpretation'
      }, {
        id: '644846187',
        name: 'DANA Baseline Test Interpretation',
        hash: 'b7b4c0fc2a'
      }, {
        id: '999557383',
        name: 'Post-Injury DANA Test'
      }, {
        id: '644842634',
        name: 'DANA Post-Injury Test Interpretation',
        hash: '9d2f92fcfb'
      }, {
        id: '999557596',
        name: 'Postural Sway Test'
      }]
    }, {
      name: 'Assessment of Acute Concussion',
      module: 6,
      videos: [{
        id: '999558471',
        name: 'On-Field and Sideline Evaluation',
      }, {
        id: '999559844',
        name: 'Clinical Evaluation of Acute Concussion'
      }, {
        id: '999561134',
        name: 'Neurological Examination'
      }, {
        id: '999562295',
        name: 'Vestibular OculoMotor Screen (VOMS)'
      }, {
        id: '999562592',
        name: 'Special Imaging, Testing, and Concussion Biomarkers'
      }, {
        id: '999564219',
        name: 'Education and Prognosis for Acute Concussion'
      }, {
        id: '368917371',
        name: 'Another ACUTE Patient Education Drawing Example - What is concussion?'
      }, {
        id: '999566292',
        name: 'Nutrition for Acute mTBI'
      }, {
        id: '999566930',
        name: 'Nutrition for Acute mTBI - Part 2'
      }, {
        id: '999567640',
        name: 'Acute Supplementation (Part 1) - Foundational Nutrients'
      }, {
        id: '999568465',
        name: 'Acute Supplementation (Part 2) - Interventional Nutrients'
      }]
    }, {
      name: 'Management of Acute Concussion',
      module: 7,
      videos: [{
        id: '999572635',
        name: 'Management of Acute Concussion'
      }, {
        id: '999573785',
        name: 'Return to School and Work'
      }, {
        id: '999574827',
        name: 'Return to Driving'
      }, {
        id: '999575247',
        name: 'Return to Sport and Physical Activity'
      }, {
        id: '999576914',
        name: 'Discharge Instructions and Other Considerations'
      }]
    }, {
      name: 'Pathophysiology of Persistent Concussion Symptoms (PCS)',
      module: 8,
      videos: [{
        id: '999589578',
        name: 'Overview of PCS'
      }, {
        id: '999590543',
        name: 'Theory 1 - Autonomic Dysregulation (Physiologic PCS)'
      }, {
        id: '999591289',
        name: 'Theory 2 - Metabolic, Inflammatory, Gut, & Hormones'
      }, {
        id: '999592228',
        name: 'Addressing The Metabolic Aspects of PCS'
      }, {
        id: '999596187',
        name: 'Theory 4 - Cervical Spine Dysfunction'
      }, {
        id: '999598018',
        name: 'Theory 5 - Mental Health and Psychological Overlay'
      }, {
        id: '999635011',
        name: 'Putting the Pieces Together'
      }]
    }, {
      name: 'Treatment & Rehabilitation of Persistent Concussion Symptoms',
      module: 9,
      videos: [{
        id: '999635341',
        name: 'Assessment and Treatment of Persistent Concussion Symptoms'
      }, {
        id: '999636061',
        name: 'The Concussion Fix Program'
      }, {
        id: '999636342',
        name: 'Visit 1.1 - History & Physical Examination'
      }, {
        id: '999637324',
        name: 'Visit 1.1 - Assessment & Treatment of Orthostatic Intolerance Issues'
      }, {
        id: '999637901',
        name: 'Visit 1.1 - Balancing the ANS via The Vagus Nerve'
      }, {
        id: '999638270',
        name: 'Visit 1.1 - Assessment and Treatment of BPPV'
      }, {
        id: '999639720',
        name: 'Visit 1.2 - Patient Education & Reassurance'
      }, {
        id: '999640236',
        name: 'Visit 1.2 - Nutrition and Supplementation for PCS'
      }, {
        id: '999654234',
        name: 'Visit 1.2 - Pharmaceutical Options for mTBI'
      }, {
        id: '999640879',
        name: 'Visit 2 - Physical Exertion Testing & Subsymptom Threshold Exercise'
      }, {
        id: '1000404899',
        name: 'Visit 3 - Visual/Vestibular and Cervical Spine Assessment & Initial Management'
      }, {
        id: '999643509',
        name: 'Assessment & Treatment of the Cervical Spine'
      }, {
        id: '999646867',
        name: 'Ongoing Care and Considerations'
      }, {
        id: '999649930',
        name: 'Assessment & Treatment of Vestibular Disorders in Concussion'
      }, {
        id: '999652461',
        name: 'Assessment & Treatment of visual disorders in PCS'
      }]
    }, {
      name: 'Chronic Traumatic Encephalopathy (CTE)',
      module: 10,
      videos: [{
        id: '1000432019',
        name: 'Chronic Traumatic Encephalopathy'
      }]
    }, {
      name: 'Impacting Your Community and Your Practice',
      module: 11,
      videos: [{
        id: '1000422822',
        name: 'Impacting Your Community and Your Practice'
      }],
    }],
    get videosCount() {
      return getVideosCount(this.modules);
    }
  },
  'ot-slp-cognitive-rehab': {
    name: 'Concussion Management for OT, SLP, and Cognitive Rehabilitation Professionals',
    img: 'ot-course-graphic.png',
    permitted: () => false,
    modules: [{
      name: 'Introduction, Epidemiology, and Concussion Prevention',
      module: 1,
      videos: [{
        id: '673750961',
        name: 'Intro & Epidemiology of Concussion',
        hash: 'a9e920fcd4'
      }, {
        id: '673748477',
        name: 'Concussion Prevention & Recovery Optimization',
        hash: '51d8fb3e3f'
      }, {
        id: '673739166',
        name: 'Concussion Problems & Proposed Solutions',
        hash: '93d6bf5a9a'
      }]
    }, {
      name: 'Pathophysiology of Concussion',
      module: 2,
      videos: [{
        id: '676366772',
        name: 'The \'Neurometabolic Cascade\' of Concussion',
        hash: 'd4135b734f'
      }, {
        id: '676365056',
        name: 'Blood Flow & Autonomic Nervous System Dysregulation',
        hash: '12d87bffc7'
      }, {
        id: '465850994',
        name: 'Vulnerability, Physiologic Recovery & Second Impact Syndrome',
        hash: '73d856904e'
      }]
    }, {
      name: 'Baseline & Neurocognitive Test Administration & Interpretation',
      module: 3,
      videos: [{
        id: '678875922',
        name: 'Baseline Testing Evidence',
        hash: '69cbc6e360'
      }, {
        id: '678882344',
        name: 'CCMI Baseline Battery',
        hash: 'b9eb330ae8'
      }, {
        id: '644846187',
        name: 'DANA Baseline Interpretation',
        hash: 'b7b4c0fc2a'
      }, {
        id: '644842634',
        name: 'DANA Post-Injury Interpretation',
        hash: '9d2f92fcfb'
      }]
    }, {
      name: 'Acute Concussion Assessment & Management',
      module: 4,
      videos: [{
        id: '678880778',
        name: 'Acute Concussion Assesment',
        hash: 'cead8380a1'
      }, {
        id: '469920056',
        name: 'Vestibular Oculomotor Screen (VOMS) Testing',
        hash: '4ee1e7c57c'
      }, {
        id: '280586233',
        name: 'VOMS Practical Assessment',
        hash: '7551f00f74'
      }, {
        id: '678906675',
        name: 'Management of Acute Concussion',
        hash: '2fc0a910b3'
      }]
    }, {
      name: 'Persistent Concussion Symptoms (PCS) & CTE',
      module: 5,
      videos: [{
        id: '678877315',
        name: 'Persistent Concussion Symptoms (PCS)',
        hash: '7c8741c360'
      }, {
        id: '678894814',
        name: 'Chronic Traumatic Encephalopathy (CTE)',
        hash: '9d43e79ff4'
      }]
    }, {
      name: 'Active Rehabilitation',
      module: 6,
      videos: [{
        id: '734483282',
        name: 'Active Rehabilitation',
        hash: '8b5f9606fb'
      }]
    }, {
      name: 'Management of Energy & Fatigue',
      module: 7,
      videos: [{
        id: '726871100',
        name: 'Energy & Fatigue',
        hash: 'c7fe91d521'
      }]
    }, {
      name: 'Sleep',
      module: 8,
      videos: [{
        id: '727760614',
        name: 'Sleep',
        hash: '57e9e5ded2'
      }]
    }, {
      name: 'Cognitive Rehabilitation',
      module: 9,
      videos: [{
        id: '741241527',
        name: 'Cognitive Rehabilitation',
        hash: 'a8462dec5b'
      }, {
        id: '731375189',
        name: 'Memory',
        hash: 'd026b36d43'
      }, {
        id: '741294529',
        name: 'Word Finding & Organization',
        hash: '07ebc4768a'
      }, {
        id: '741287847',
        name: 'Attention & Decision Making',
        hash: 'f52e628341'
      }]
    }, {
      name: 'Return to Work',
      module: 10,
      videos: [{
        id: '728908703',
        name: 'Return to Work - Part 1',
        hash: '2cd9f3c1cb'
      }, {
        id: '728912713',
        name: 'Return to Work - Part 2',
        hash: '9d453a7fe1'
      }]
    }, {
      name: 'Return to School',
      module: 11,
      videos: [{
        id: '729010220',
        name: 'Return to School',
        hash: '14fe3c031d'
      }]
    }, {
      name: 'Ergonomics',
      module: 12,
      videos: [{
        id: '729310976',
        name: 'Ergonomics',
        hash: '014dc419ae'
      }]
    }, {
      name: 'Rest, Relaxation, & Mood',
      module: 13,
      videos: [{
        id: '729732789',
        name: 'Rest, Relaxation, & Mood',
        hash: 'd18523d08f'
      }]
    }, {
      name: 'Physical Symptom Management & Return to Driving',
      module: 14,
      videos: [{
        id: '729711097',
        name: 'Other Common Symptoms',
        hash: 'b3baa49ed8'
      }]
    }],
    get videosCount() {
      return getVideosCount(this.modules);
    }
  },
  'physicians-specialists': {
    name: 'Concussion Management for Physicians & Specialists',
    img: 'physicians-course-graphic.png',
    permitted: () => false,
    modules: [{
      name: 'Introduction, Epidemiology, and Concussion Prevention',
      module: 1,
      videos: [{
        id: '673750961',
        name: 'Intro & Epidemiology of Concussion',
        hash: 'a9e920fcd4'
      }, {
        id: '673748477',
        name: 'Concussion Prevention & Recovery Optimization',
        hash: '51d8fb3e3f'
      }, {
        id: '673739166',
        name: 'Concussion Problems & Proposed Solutions',
        hash: '93d6bf5a9a'
      }]
    }, {
      name: 'Pathophysiology of Concussion',
      module: 2,
      videos: [{
        id: '676366772',
        name: 'The \'Neurometabolic Cascade\' of Concussion',
        hash: 'd4135b734f'
      }, {
        id: '676365056',
        name: 'Blood Flow & Autonomic Nervous System Dysregulation',
        hash: '12d87bffc7'
      }, {
        id: '465850994',
        name: 'Vulnerability, Physiologic Recovery & Second Impact Syndrome',
        hash: '73d856904e'
      }]
    }, {
      name: 'Baseline & Neurocognitive Test Administration & Interpretation',
      module: 3,
      videos: [{
        id: '678875922',
        name: 'Baseline Testing Evidence',
        hash: '69cbc6e360'
      }, {
        id: '678882344',
        name: 'CCMI Baseline Battery',
        hash: 'b9eb330ae8'
      }, {
        id: '644846187',
        name: 'DANA Baseline Interpretation',
        hash: 'b7b4c0fc2a'
      }, {
        id: '644842634',
        name: 'DANA Post-Injury Interpretation',
        hash: '9d2f92fcfb'
      }]
    }, {
      name: 'Acute Concussion Assessment & Management',
      module: 4,
      videos: [{
        id: '678880778',
        name: 'Acute Concussion Assesment',
        hash: 'cead8380a1'
      }, {
        id: '469920056',
        name: 'Vestibular Oculomotor Screen (VOMS) Testing',
        hash: '4ee1e7c57c'
      }, {
        id: '280586233',
        name: 'VOMS Practical Assessment',
        hash: '7551f00f74'
      }, {
        id: '678906675',
        name: 'Management of Acute Concussion',
        hash: '2fc0a910b3'
      }]
    }, {
      name: 'Persistent Concussion Symptoms (PCS) & CTE',
      module: 5,
      videos: [{
        id: '678877315',
        name: 'Persistent Concussion Symptoms (PCS)',
        hash: '7c8741c360'
      }, {
        id: '678894814',
        name: 'Chronic Traumatic Encephalopathy (CTE)',
        hash: '9d43e79ff4'
      }]
    }],
    get videosCount() {
      return getVideosCount(this.modules);
    }
  }
};
