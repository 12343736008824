const FR = {
  registeredPatientsBorderTitle: 'Patients enregistrés',
  newPatientsBorderTitle: 'Nouveaux patients',
  registeredPatientInfoText: 'Si le patient a déjà été enregistré chez CCMI par le passé, cherchez-les ci-dessous :',
  newPatientsInfoText: 'S’il s’agit d’un patient venant pour la première fois, enregistrez-les en passant par <strong>{0}</strong> ou téléchargez l’appli <strong>Concussion Tracker</strong> en passant par l’App Store ou la boutique Google Play.',
  forgotAccountLinkText: 'Oublié votre no de compte ?',
  searchResultsHeadingText: 'Résultats de la recherche',
  searchResultsMessageText: 'Vos résultats de recherche contiennent plusieurs utilisateurs. Veuillez en choisir un :',
  searchResultOngoingInjuryText: 'Inéligible - Blessure continue actuelle'
};
 
export default FR;
