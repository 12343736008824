import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FormComponent, CheckboxInputGroup } from '@/forms';
import AccessControl from '../../access-control';
import Modal from '../../modal';
import { ErrorBanner } from '../../errors';
import { RoleDescriptions } from '@/utilities/user-roles';
import { showAlert } from '../../alert-notifications';
import { approveFinalReportAsync } from '@/redux/thunks/idt-claims';
import Icon from '../../icon';
import Tooltip from '../../tooltip';
import Strings from '../lang';

const PDFViewer = ({ document }) => {
  return (
    <iframe
      title={document.file_name}
      height="100%"
      width="100%"
      src={document.url}
    />
  );
};

const ApprovalForm = ({
  reportId,
  claimId,
  blockId,
  onComplete
}) => {
  const dispatch = useDispatch();
  const [consent, setConsent] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const onSubmit = useCallback(() => {
    setLoading(true);
    setError(null);

    dispatch(approveFinalReportAsync(reportId, claimId, blockId)).then(() => {
      setLoading(false);
      onComplete();
    }).catch(error => {
      setError(error.message);
      setLoading(false);
    });
  }, [blockId, claimId, dispatch, onComplete, reportId]);

  return (
    <div>
      <ErrorBanner error={error} />
      <FormComponent onSubmit={onSubmit}>
        <div className="approval-form-banner">
          <CheckboxInputGroup
            inputValid
            className="form-group"
            labelText={Strings.approveReportConsentLabel}
            inputProps={{
              className: 'form-control',
              checked: consent,
              disabled: loading,
              onChange: e => {
                setConsent(e.target.checked);
              }
            }}
          />
          <button type="submit" className="btn btn-primary" disabled={!consent}>
            {loading && <Icon spinPulse prefix="fad" name="loader" style={{ marginRight: 5 }} />}
            <span>{Strings.approveReportButton}</span>
          </button>
        </div>
      </FormComponent>
    </div>
  );
};

const ReportViewModal = ({
  open,
  document,
  reportStatus,
  claimId,
  blockId,
  fileName,
  onClose
}) => {
  const handleComplete = useCallback(() => {
    onClose();
    setTimeout(() => {
      showAlert('success', {
        message: Strings.approveReportSuccessMessage,
        ismissable: true,
        autoDismiss: 3500,
      });
    // eslint-disable-next-line no-magic-numbers
    }, 200);
  }, [onClose]);

  return (
    <Modal
      blur
      fullScreen
      isOpen={open}
      className="idt-document-modal"
      onClose={onClose}
    >
      <Modal.Body>
        <div className="document-container">
          <div className="frame-container">
            <PDFViewer
              document={document}
              fileName={fileName}
            />
          </div>
          {reportStatus === 'pending_approval' && (
            <AccessControl
              allowUntrained
              roles={[
                RoleDescriptions.Clinician,
                RoleDescriptions.Specialist,
                RoleDescriptions.IDTClinician
              ]}
            >
              <div className="footer-container">
                <ApprovalForm
                  reportId={document.attachable_id}
                  claimId={claimId}
                  blockId={blockId}
                  onComplete={handleComplete}
                />
              </div>
            </AccessControl>
          )}
        </div>
        <button type="button" className="btn-close" onClick={onClose}>
          <Icon name="xmark" />
        </button>
      </Modal.Body>
    </Modal>
  );
};

const FinalReportView = ({
  claimId,
  blockId,
  reportStatus,
  document
}) => {
  const [open, setOpen] = useState(false);

  if (!document) return '--';

  return (
    <>
      <Tooltip
        portal
        placement="top"
        delay={600}
        offset={[0, 1]}
        content={(
          <span style={{ fontSize: 14 }}>
            {document.file_name}
          </span>
        )}
      >
        <button
          type="button"
          className="idt-final-report-link"
          onClick={() => setOpen(true)}
        >
          {Strings.viewFinalReportPdf}
        </button>
      </Tooltip>
      <ReportViewModal
        open={open}
        claimId={claimId}
        blockId={blockId}
        reportStatus={reportStatus}
        document={document}
        onClose={() => setOpen(false)}
      />
    </>
  );
};

export default FinalReportView;
