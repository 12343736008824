import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Page } from '../page';
import { useMount } from '@/hooks';
import { getIdtClaimAsync } from '@/redux/thunks/idt-claims';
import Activity from '../activity';
import { ErrorBanner } from '../errors';
import ClaimDisplay from './claim-display';

const IdtClaim = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const claim = useSelector(state => state.idtClaims[params.claimId]) ?? {};

  const getClaim = useCallback((id) => {
    setLoading(true);

    dispatch(getIdtClaimAsync(id)).then(() => {
      setLoading(false);
    }).catch((error) => {
      setError(error.message);
      setLoading(false);
    });
  }, [dispatch]);

  useMount(() => {
    getClaim(params.claimId);
  });

  return (
    <Page>
      <ErrorBanner error={error} />
      <Activity active={loading} static>
        <ClaimDisplay
          claim={claim}
        />
      </Activity>
    </Page>
  );
};

export default IdtClaim;
