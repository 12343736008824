import { CountryRegionData } from 'react-country-region-selector';

const Countries = CountryRegionData.reduce((acc, country) => ({
  ...acc,
  [country[1]]: {
    code: country[1],
    name: country[0],
    regions: country[2].split('|').reduce((acc, province) => {
      const prov = province.split('~');
      return {
        ...acc,
        [prov[1]]: {
          code: prov[1],
          name: prov[0]
        }
      };
    }, {})
  }
}), {});

export const getCountryRegionName = (country, region) => {
  return Countries[country]?.regions?.[region]?.name || region;
};

export const getCountryName = (country) => {
  return Countries[country]?.name || country;
};

export default Countries;
