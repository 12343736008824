import Strings from './lang';

const HockeyPositions = [
  { key: 'goalie', name: Strings.hockey_goalie },
  { key: 'defensemen', name: Strings.hockey_defensemen },
  { key: 'rightwing', name: Strings.hockey_rightwing },
  { key: 'leftwing', name: Strings.hockey_leftwing },
  { key: 'center', name: Strings.hockey_center }
];

export default HockeyPositions;
