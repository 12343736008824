import React, { useCallback, useState } from 'react';
import Popover from '../../popover';
import Icon from '../../icon';
import Strings from '../lang';

const NotePopover = ({ note }) => {
  const [clickOpen, setClickOpen] = useState(false);
  const [hoverOpen, setHoverOpen] = useState(false);

  const handleHoverOpen = useCallback(() => setHoverOpen(true), []);
  const handleHoverClose = useCallback(() => setHoverOpen(false), []);

  if (!note) return null;

  return (
    <span
      tabIndex={0}
      onMouseOver={handleHoverOpen}
      onMouseLeave={handleHoverClose}
      onFocus={handleHoverOpen}
      onBlur={handleHoverClose}
    >
      <Popover
        isOpen={clickOpen || hoverOpen}
        placement="top"
        // eslint-disable-next-line no-magic-numbers
        offset={[0, 4]}
        onClose={() => {
          setClickOpen(false);
          setHoverOpen(false);
        }}
        content={(
          <div style={{ padding: 10 }}>
            {note}
          </div>
        )}
      >
        <span 
          className="primary" 
          title={Strings.noteElementTitle}
          onClick={() => setClickOpen(prev => !prev)}
          style={{ paddingRight: 5, paddingLeft: 5, cursor: 'pointer' }}
        >
          <Icon name="comment" />
        </span>
      </Popover>
    </span>
  );
};

export default NotePopover;
