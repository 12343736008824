import { jsonApiCreate, jsonApiRead, jsonApiUpdate } from '@/lib/requests/jsonapi';
import { getCurrentClinicId } from '@/tokens';
import { updateSpecialistNote, updateSpecialistNotes } from '../actions/specialist-notes';

const getSpecialistNoteAsync = (userId, injuryId, noteId) => {
  const options = { path: `users/${userId}/injuries/${injuryId}/specialist_notes/${noteId}` };
  return (dispatch) => {
    return jsonApiRead({ type: 'specialist_notes', id: noteId }, options).then(results => {
      dispatch(updateSpecialistNote(userId, injuryId, results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const getSpecialistNotesAsync = (userId, injuryId) => {
  const options = { path: `users/${userId}/injuries/${injuryId}/specialist_notes` };
  return (dispatch) => {
    return jsonApiRead({ type: 'specialist_notes' }, options).then(results => {
      dispatch(updateSpecialistNotes(userId, injuryId, results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const createSpecialistNoteAsync = (userId, injuryId, attrs) => {
  const options = { path: `users/${userId}/injuries/${injuryId}/specialist_notes` };
  const attributes = { ...attrs };
  if (!attributes.clinic_id) {
    attributes.clinic_id = getCurrentClinicId();
  }

  return (dispatch) => {
    return jsonApiCreate({ type: 'specialist_notes', attributes }, options).then(results => {
      dispatch(updateSpecialistNote(userId, injuryId, results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const updateSpecialistNoteAsync = (userId, injuryId, noteId, attributes) => {
  const options = { path: `users/${userId}/injuries/${injuryId}/specialist_notes/${noteId}` };
  return (dispatch) => {
    return jsonApiUpdate({ type: 'specialist_notes', id: noteId, attributes }, options).then(results => {
      dispatch(updateSpecialistNote(userId, injuryId, results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

export {
  getSpecialistNoteAsync,
  getSpecialistNotesAsync,
  createSpecialistNoteAsync,
  updateSpecialistNoteAsync
};
