import React, { useMemo } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { UserSelector } from '@/redux/selectors';
import { getCurrentClinicId } from '@/tokens';
import { userHasAdminRole } from '@/utilities/user-roles';

const getCurrentClinic = (currentUser, clinicId) => {
  return currentUser?.clinics?.find(clinic => String(clinic.id) === String(clinicId));
};

const FEATURES = {
  resources: (clinic) => !!clinic.resources_enabled,
  idt: (clinic) => !!clinic.idt_provider
};

const ClinicFeatureBlock = ({ feature, children }) => {
  const currentUser = useSelector(UserSelector.getCurrentUser);
  const currentClinic = useMemo(() => (
    getCurrentClinic(currentUser, getCurrentClinicId())
  ), [currentUser]);

  const permitted = userHasAdminRole(currentUser)
    || (!!currentClinic && (FEATURES[feature]?.(currentClinic) ?? false));

  if (!permitted) {
    return (
      <Navigate to="/unauthorized" replace />
    );
  }

  return children ? <>{children}</> : <Outlet />;
};

export default ClinicFeatureBlock;
