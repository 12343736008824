import React from 'react';
import RegistrationAboutForm from './about';
import BookingLinksStep from './booking-links';

const PERSONAL_STEP = 1;
const BOOKING_STEP = 2;

const RegistrationSteps = ({ 
  step = 1, 
  touched = false,
  user = {}, 
  onSubmit = () => {} 
}) => {
  if (step === PERSONAL_STEP) {
    return (
      <RegistrationAboutForm 
        user={user}
        touched={touched}
        onSubmit={onSubmit}
      />
    );
  }

  if (step === BOOKING_STEP) {
    return (
      <BookingLinksStep 
        user={user}
        onSubmit={onSubmit}
      />
    );
  }

  return null;
};

RegistrationSteps.PERSONAL_STEP = PERSONAL_STEP;
RegistrationSteps.BOOKING_STEP = BOOKING_STEP;

export default RegistrationSteps;
