import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  RoleNames,
  RoleResourceTypes,
  makeRoleDescription,
  userHasAdminRole,
  userHasIDTRole,
  userHasRoleMatchingDescription
} from '@/utilities/user-roles';
import { UserSelector } from '@/redux/selectors';

const isClinicOwner = (user, clinicId) => (
  userHasRoleMatchingDescription(
    user,
    makeRoleDescription(RoleNames.ClinicOwner, RoleResourceTypes.Clinic, clinicId)
  )
);

const isClinician = (user, clinicId) => (
  userHasRoleMatchingDescription(
    user,
    makeRoleDescription(RoleNames.Clinician, RoleResourceTypes.Clinic, clinicId)
  )
);

const isFrontDesk = (user, clinicId) => (
  userHasRoleMatchingDescription(
    user,
    makeRoleDescription(RoleNames.ClinicFrontDesk, RoleResourceTypes.Clinic, clinicId)
  )
);

const isSpecialist = (user, clinicId) => (
  userHasRoleMatchingDescription(
    user,
    makeRoleDescription(RoleNames.Specialist, RoleResourceTypes.Clinic, clinicId)
  )
);

const getAdminInvitableRoles = (clinic) => {
  return [
    RoleNames.ClinicOwner,
    RoleNames.Clinician,
    RoleNames.Specialist,
    RoleNames.BaselineTester,
    RoleNames.ClinicFrontDesk,
    RoleNames.ClinicStaff,
    ...(clinic.idt_provider ? [
      RoleNames.IDTClinician,
      RoleNames.IDTFrontDesk
    ] : [])
  ];
};

const getClinicOwnerInvitableRoles = (clinic) => {
  return [
    RoleNames.Clinician,
    RoleNames.ClinicFrontDesk,
    RoleNames.ClinicStaff,
    ...(clinic.idt_provider ? [
      RoleNames.IDTClinician,
      RoleNames.IDTFrontDesk
    ] : [])
  ];
};

const getClinicianInvitableRoles = (clinic) => {
  return [
    RoleNames.ClinicFrontDesk,
    RoleNames.ClinicStaff,
    ...(clinic.idt_provider ? [
      RoleNames.IDTClinician,
      RoleNames.IDTFrontDesk
    ] : [])
  ];
};

const getFrontDeskInvitableRoles = (clinic) => {
  return [
    RoleNames.ClinicFrontDesk,
    RoleNames.ClinicStaff,
    ...(clinic.idt_provider ? [
      RoleNames.IDTClinician,
      RoleNames.IDTFrontDesk
    ] : [])
  ];
};

const getSpecialistInvitableRoles = (clinic) => {
  return [
    RoleNames.ClinicFrontDesk,
    RoleNames.ClinicStaff,
    ...(clinic.idt_provider ? [
      RoleNames.IDTClinician,
      RoleNames.IDTFrontDesk
    ] : [])
  ];
};

const getIDTUserInvitableRoles = (clinic) => {
  return clinic.idt_provider ? [
    RoleNames.IDTClinician,
    RoleNames.IDTFrontDesk
  ] : [];
};

const getInvitableRoles = (user, clinic) => {
  if (userHasAdminRole(user)) {
    return getAdminInvitableRoles(clinic);
  }

  if (isClinicOwner(user, clinic.id)) {
    return getClinicOwnerInvitableRoles(clinic);
  }

  if (isClinician(user, clinic.id)) {
    return getClinicianInvitableRoles(clinic);
  }

  if (isSpecialist(user, clinic.id)) {
    return getSpecialistInvitableRoles(clinic);
  }

  if (isFrontDesk(user, clinic.id)) {
    return getFrontDeskInvitableRoles(clinic);
  }

  if (userHasIDTRole(user)) {
    return getIDTUserInvitableRoles(clinic);
  }

  return [];
};

export const useClinicInvitableRoles = (clinic) => {
  const currentUser = useSelector(UserSelector.getCurrentUser);
  return useMemo(() => (
    getInvitableRoles(currentUser, clinic)
  ), [currentUser, clinic]);
};
