const EN = {
  rightHandLabel: 'Right Hand',
  leftHandLabel: 'Left Hand',
  numberInputErrorMessage: 'Please enter a valid number between 0 and 100',
  trialLabel: 'Trial {0}',
  rightHandAverageLabel: 'Right Hand Average:',
  leftHandAverageLabel: 'Left Hand Average:',
  defaultOption: 'Select One'
};

export default EN;
