import React, { useCallback } from 'react';
import { If, Then } from 'react-if';
import classnames from 'classnames';
import { SymptomLevels } from '../../symptoms';
import Strings from '../lang';
import Icon from '../../icon';

const SoapSymptoms = ({
  symptoms = {},
  completed,
  submitted,
  onUpdate = () => {}
}) => {
  const handleUpdate = useCallback((completed, symptomsScores) => {
    const newSymptoms = !symptomsScores ? symptoms : symptomsScores;
    onUpdate({
      symptoms: newSymptoms,
      completed
    });
  }, [onUpdate, symptoms]);

  return (
    <div className="soap-symptoms-section">
      <div className="soap-symptoms-btn">
        <button
          type="button"
          className={classnames('btn btn-sm', completed ? 'btn-danger' : 'btn-secondary')}
          onClick={() => handleUpdate(!completed, null)}
        >
          {
            completed ? (
              <span><Icon name="xmark" /> {Strings.removeSymptomsText}</span>
            ) : (
              <span><Icon name="plus" /> {Strings.addSymptomScoreText}</span>
            )
          }
        </button>
      </div>
      <If condition={completed === true}>
        <Then>
          <SymptomLevels
            type="select"
            groupClassName="row"
            inputClassName="col-md-3"
            required={false}
            symptoms={symptoms}
            onUpdate={(symptoms) => handleUpdate(true, symptoms)}
            touched={submitted}
          />
        </Then>
      </If>
    </div> 
  );
};

export default SoapSymptoms;
