import React from 'react';
import PropTypes from 'prop-types';
import { DEFAULT_USER_LANGUAGE } from '@/utilities/localization';
import InjuryReportInformationSection from './injury-report-information-section';
import InjuryReportRedflagsSection from './injury-report-redflags-section';
import InjuryReportTestSection from './injury-report-test-section';
import DanaBatteryResultsSection from './dana-battery-results';
import SymptomsList from '../baseline/user-baseline-detail/symptoms-list';

const InjuryReportSection = ({
  injuryReport = {}, 
  language = DEFAULT_USER_LANGUAGE
}) => {
  const { 
    symptoms = {}, 
    dana_battery = null,
    injury_information = {},
    red_flags = {}
  } = injuryReport;
  
  return (
    <div>
      <InjuryReportInformationSection injuryInformation={injury_information || {}} />
      <InjuryReportRedflagsSection injuryRedflags={red_flags || {}} />

      <div className="user-baseline-detail">
        <SymptomsList symptoms={symptoms || {}} columns={3} />
        <DanaBatteryResultsSection battery={dana_battery} />
        <InjuryReportTestSection injuryReport={injuryReport} language={language} />
      </div>

    </div>
  );
};

InjuryReportSection.propTypes = {
  injuryReport: PropTypes.object,
  language: PropTypes.string
};

export default InjuryReportSection;
