import { getInterfaceLanguage } from './localization';

const Platforms = {
  iOS: 'ios',
  Android: 'android'
};

const MobileLinks = {
  [Platforms.iOS]: {
    link: 'https://itunes.apple.com/ca/app/concussion-tracker/id1166290027?mt=8',
    img: `https://static.completeconcussions.com/mail/images/app-store/appstore-${getInterfaceLanguage()}.png`,
    deep: 'ccmi://ccmi'
  },
  [Platforms.Android]: {
    link: 'https://play.google.com/store/apps/details?id=com.completeconcussions.concussiontracker',
    img: `https://play.google.com/intl/en_us/badges/images/generic/${getInterfaceLanguage()}_badge_web_generic.png`,
    deep: 'CCMI://'
  }
};

const mobileDetector = () => {
  const agent = navigator.userAgent || navigator.vendor || window.opera;

  if (/android/i.test(agent)) {
    return Platforms.Android;
  }

  if (/iPhone|iPod/.test(agent) && !window.MSStream) {
    return Platforms.iOS;
  }

  return null;
};

export {
  Platforms,
  MobileLinks,
  mobileDetector
};
