import React, { useState, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Modal from '../../modal';
import Activity from '../../activity';
import { ErrorBanner } from '../../errors';
import { ClinicSearchControl } from '../../clinic-search';
import SearchStrings from '../../clinic-search/lang';
import { UserPaths } from '@/paths';
import replaceLinkParams from '@/utilities/replace-link-params';
import { getUserBaselineTestsAsync } from '@/redux/thunks/user-baseline-tests';
import Strings from './lang';
import { getCurrentClinicId } from '@/tokens';
import Icon from '../../icon';

const continueToBaselineTest = (userId, baselines, navigate) => {
  const baseline = baselines[0] || {};
  let state = {};
  let link = replaceLinkParams(UserPaths.baselines.steps.medical.link, {
    userId,
    baselineId: 'new'
  });

  if (baseline.id && !baseline.complete) {
    link = replaceLinkParams(UserPaths.baselines.steps.continue.link, {
      userId,
      baselineId: baseline.id
    });

    state = {
      referrer: window.location.pathname
    };
  }

  navigate(link, { state });
};

const ContinueBaselineModal = ({
  isOpen = false,
  getUserBaselines,
  onClose = () => { }
}) => {
  const navigate = useNavigate();
  const [activity, setActivity] = useState(false);
  const [error, setError] = useState(null);
  const [resultsEmpty, setResultsEmpty] = useState(false);
  const clinicId = useMemo(() => getCurrentClinicId(), []);

  const getBaselines = useCallback((user) => {
    if (!user.baseline_date) {
      continueToBaselineTest(user.id, [], navigate);
    }

    setActivity(true);

    getUserBaselines(user.id).then(baselines => {
      setActivity(false);
      continueToBaselineTest(user.id, baselines, navigate);
    }).catch(error => {
      setActivity(false);
      setError(error.message);
    });
  }, [getUserBaselines, navigate]);

  const onSearch = useCallback(({ results }) => {
    const patientLength = results.length;

    if (patientLength === 1) {
      const user = results[0];
      getBaselines(user);
    } else {
      setResultsEmpty(true);
    }
  }, [getBaselines]);

  const onError = useCallback((error) => {
    setError(error.message);
  }, []);

  const handleClose = useCallback(() => {
    setError(null);
    setResultsEmpty(false);
    onClose();
  }, [onClose]);

  return (
    <Modal
      blur
      isOpen={isOpen}
      onClose={handleClose}
    >
      <Modal.Header>
        <button type="button" className="close" onClick={handleClose}>
          <Icon name="xmark" />
        </button>
        <h1>{Strings.continueBaselineModalTitleText}</h1>
      </Modal.Header>
      <Modal.Body>
        <Activity active={activity}>
          <div className="text-muted">{Strings.continueBaselineModalInfoText}</div>
          <ErrorBanner error={error} />
          {resultsEmpty && (
            <p className="alert alert-info">
              {SearchStrings.searchResultsEmptyText}
            </p>
          )}
          <ClinicSearchControl
            className="start-test-search"
            onSearch={onSearch}
            onError={onError}
            clinicId={clinicId}
            searchButtonText={Strings.continueBaselineButtonText}
          />
        </Activity>
      </Modal.Body>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserBaselines: (id) => {
      return dispatch(getUserBaselineTestsAsync(id));
    }
  };
};

const ConnectedContinueBaselineModal = connect(
  null,
  mapDispatchToProps
)(ContinueBaselineModal);

export default ConnectedContinueBaselineModal;

export const ContinueBaselineModalButton = ({
  disabled = false,
  className,
  children
}) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <button
        type="button"
        disabled={disabled}
        className={className}
        onClick={() => setOpen(true)}
      >
        {children}
      </button>
      <ConnectedContinueBaselineModal
        isOpen={open}
        onClose={() => setOpen(false)}
      />
    </>
  );
};
