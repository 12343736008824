const FR = {
  title: 'Aperçu des blessures',
  overviewTabLabel: 'Aperçu',
  symptomsTabLabel: 'Symptômes',
  injuryAssessmentLabel: 'Évaluation des blessures',
  soapNoteLabel: 'Note clinique',
  specialistNoteLabel: 'Remarque du spécialiste',
  symptomUpdateLabel: 'Autodéclaré',
  injuryDurationCardTitle: 'Durée de la blessure',
  injuryDurationCardMessage: 'Blessé {0}',
  daysDurationLabel: 'journées',
  recoveryDurationCardTitle: 'Durée de récupération',
  recoveryDurationCardMessage: 'Évalué {0}',
  assessmentTypeCardTitle: 'Type d\'évaluation initiale',
  assessmentTypeCardMessage: '{0} jours entre la blessure et l\'évaluation',
  totalVisitsCardTitle: 'Nombre total de visites',
  visitsUnitLabel: 'visites',
  acuteText: 'Aigu',
  subacuteText: 'Subaigu',
  chronicText: 'Chronique',
  closeButtonText: 'Proche',
  currentLabel: 'Courant',
  viewHistoryButtonText: 'Voir l\'historique',
  recoveryStageHistoryTitle: 'Historique de l\'étape de récupération',
  currentRecoveryStageCardTitle: 'Étapes de récupération actuelles',
  diagnosisHistoryTitle: 'Historique du diagnostic',
  currentDiagnosisCardTitle: 'Diagnostic actuel',
  symptomSelectorPlaceholder: 'Sélectionnez le symptôme',
  symptomSeverityLabel: 'Gravité des symptômes',
  symptomTotalLabel: 'Totaux des symptômes',
  individualSymptomsLabel: 'Symptômes individuels',
  viewDescriptionLabel: 'Afficher la description',
  topSymptomCategoriesTitle: 'Principales catégories de symptômes',
  scoreLabel: 'Score',
  baselineReferenceLabel: 'Référence ({0})',
  symptomColumnTitle: 'Symptôme',
  severityColumnTitle: 'Gravité',
  diffAbbrColumnTitle: 'Diff.',
  diffColumnDescription: 'Modification du dernier score de symptômes',
  diffColumnTitle: 'Différence',
  topSymptomsMetricLabel: 'Principaux symptômes',
  topSymptomsEmptyLabel: 'Aucun symptôme principal disponible',
  mostImprovedMetricLabel: 'Le plus amélioré',
  mostImprovedEmptyLabel: 'Aucun symptôme ne s\'est amélioré depuis l\'évaluation initiale',
  mostIncreasedMetricLabel: 'Le plus augmenté',
  mostIncreasedEmptyLabel: 'Aucun symptôme n\'a augmenté depuis l\'évaluation initiale',
  recentSymptomsTableTitle: 'Comparaison des symptômes récents',
  mostRecentColumnLabel: 'Le plus récent ({0}: {1})',
  initialAssessmentLabel: 'Évaluation initiale',
  baselineLabel: 'Examen de référence',
  symptomsChartTitle: 'Tableau des symptômes',
  symptomCategoriesDescription: 'Une moyenne pondérée de la gravité des symptômes allant de 0 à 6. Chaque symptôme reçoit un poids spécifique en fonction de la pertinence de ce symptôme par rapport à une catégorie. Ces catégories ne sont que des suggestions (ou des aperçus) des principaux facteurs pouvant causer des symptômes de persistance.',
  rtlRiskCardTitle: 'Risque de retour au travail/à l\'école',
  lowRiskLabel: 'Bas',
  lowRiskDescription: 'Ce patient a une forte probabilité de retourner au travail/à l\'école dans les 6 mois',
  moderateRiskLabel: 'Modéré',
  moderateRiskDescription: 'Ce patient a un risque modéré de ne pas retourner au travail/à l\'école dans les 6 mois',
  highRiskLabel: 'Haut',
  highRiskDescription: 'Ce patient présente un risque élevé de ne pas retourner au travail/à l\'école dans les 6 mois.Veuillez impliquer l\'ergothérapie, les services de santé mentale, l\'éducation et la réassurance pour optimiser leur rétablissement et améliorer leur probabilité de retour au travail/ à l\'école.',
  noteLabel: 'Noter',
  riskDisclaimerMessage: 'Si ce patient est déjà retourné au travail/à l\'école, veuillez ignorer',
  sourceLabel: 'Source'
};
 
export default FR;
