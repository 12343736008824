const ImageCropperOptions = {
  responsive: true,
  aspectRatio: 1,
  dragMode: 'move',
  background: false,
  guides: false,
  autoCrop: true,
  autoCropArea: 1.0,
  style: {
    width: '100%',
    height: '200px'
  }
};

export default ImageCropperOptions;
