const EN = {
  voms: 'VOMS Test',
  buffalo: 'Buffalo Test',
  blackhawks: 'Blackhawks Test',
  soapTab: 'Clinical',
  diagnosisTab: 'Diagnosis',
  postInjury: 'Post Injury Test',
  cervical_soft_tissue: 'Soft Tissue Therapy',
  cervical_manipulation: 'Manipulation',
  cervical_mobilization: 'Mobilizations',
  vestibular_balance_rehab: 'Vestibular/Balance Rehab',
  visual_rehab: 'Visual Rehab',
  education_reassurance: 'Education/Reassurance',
  other_treatment: 'Other Treatment Provided',
  injuryStageInfo: 'Stage the patient is to be placed in following visit',
  reloadSoapTitle: 'Reload Clinical Note',
  reloadButtonText: 'Reload',
  reloadModalInfoText: 'Click Reload to reload the completed {0} into the clinical note',
  symptomsTitle: 'Symptoms',
  symptomsTotalText: 'SYMPTOM NUMBER',
  symptomsSeverityText: 'SYMPTOM SEVERITY',
  saveSectionButtonText: 'Save Section & Continue',
  soapNotesTitle: 'Clinical Notes',
  saveSoapButtonText: 'Save & Close Clinical Note',
  notesSectionTitle: 'Notes',
  injuryStageSectionTitle: 'Recovery Stages',
  recoveryStageFormTitle: 'Set Recovery Stages',
  changeDiagnosisButtonText: 'Change Diagnosis',
  enterNotesPlaceholderText: 'Enter Notes Here',
  subjectiveLabel: 'Subjective Notes',
  subjectiveNavLabel: 'Subjective',
  objectiveLabel: 'Objective Notes',
  objectiveNavLabel: 'Objective',
  assessmentLabel: 'Assessment Notes',
  assessmentNavLabel: 'Assessment',
  planLabel: 'Plan Notes',
  planNavLabel: 'Plan',
  defaultDiagnosis: 'No Diagnosis Provided',
  removeSymptomsText: 'Remove Symptom Score',
  addSymptomScoreText: 'Add Symptom Score',
  soapNotesListSubtitle: 'Clinical Notes for injury dated {0}',
  soapNotesListEmptyMessage: 'No Clinical notes have been created for this injury.',
  startSoapNotesLinkText: 'New Clinical Note',
  actionsHeadingText: 'Quick Actions',
  performTestDropdownTab: 'Perform Test',
  reviewAndCloseNoteTab: 'Review & Close Note',
  reviewNoteTab: 'Review Notes',
  noSymptomsRecordedText: 'No Symptom Scores Recorded',
  closeNoteButtonText: 'Close Clinical Note',
  currentRecoveryStageLabel: 'Current Recovery Stage',
  previousRecoveryStageLabel: 'Previous Recovery Stage',
  noPreviousRecoveryStageText: 'No Previous Recovery Stage',
  noRecoveryStage: 'No Recovery Stage Present',
  confirmSymptomsIncompleteTitle: 'Symptoms Incomplete',
  confirmSymptomsIncompleteText: 'You have not completed a Symptom Score, do you wish to continue?',
  confirmRouteLeaveTitle: 'Leave Clinical Note?',
  confirmRouteLeaveText: 'This Clinical note has not been closed and will remain open, do you wish to continue?',
  incompleteText: 'Incomplete',
  continueNoteText: 'Continue Note',
  confirmButtonTitle: 'Yes, Continue',
  cancelButtonTitle: 'No, Stay',
  currentDiagnosisLabel: 'Current Diagnosis:',
  soapNoteModalHeader: 'Clinical Note from {0}',
  noPreviousSoapsMessage: 'No Previous Clinical Notes',
  soapNoteHistoryTitle: 'Clinical Note History',
  previousSoapsDropdownButtonText: 'Previous Clinical Notes',
  injuryDiagnosisHeaderLabel: 'Injury Diagnosis',
  diagnosisProvidedHeaderLabel: 'Diagnosis Provided',
  recoveryStagePriorToVisitLabel: 'Recovery Stage Prior to Visit',
  sectionSavedMessage: 'Section Successfully Saved',
  standardSectionFormError: 'This section contains some errors. Please review this section',
  openAllButtonText: 'Open All',
  closeAllButtonText: 'Close All',
  soapPanelHeaderText: 'Clinical Note {0} - {1}',
  editText: 'Edit',
  archiveText: 'Archive',
  unarchiveText: 'Unarchive',
  createdByLabel: 'Created by {0}',
  unknownNameLabel: 'Unknown',
  testPassedText: 'Passed',
  testFailedText: 'Failed',
  buffaloTestBadgeTitle: 'Buffalo Treadmill Test - {0}',
  blackhawksTestBadgeTitle: 'Blackhawks Test - {0}',
  vomsTestBadgeTitle: 'VOMS Test',
  postInjuryTestBadgeTitle: 'Post-Injury Test',
  symptomScoreBadgeTitle: 'Symptom Severity Score - {0}',
  seeBelowText: 'see below',
  failedWithHeartRateText: 'Failed at HR {0} BPM',
  diagnosisChangedText: 'Diagnosis Changed to',
  noPreviousSoapNotesMessage: 'No Previous Clinical Notes',
  noPreviousSpecialistNotesMessage: 'No previous Physician/Specialist notes',
  soapListErrorMessage: 'Error Loading Clinical Notes',
  specialistListErrorMessage: 'Error loading Physician/Specialist notes',
  archivedByMessage: 'Archived by {0} on {1}',
  confirmArchiveSoapNoteTitle: 'Archive Clinical Note',
  confirmArchiveSoapNoteMessage: 'By selecting "Confirm", this Clinical Note will be removed from the patients file. Any symptom scores recorded, testing performed and/or recovery stage changes in this Clinical Note will also be removed from the patients file.',
  archivedSuccessMessage: 'Clinical Note Archived Successfully!',
  archivedErrorMessage: 'Failed to archive Clinical Note: {0}',
  unarchiveSuccessMessage: 'Clinical Note Unarchived Successfully!',
  unarchivedErrorMessage: 'Failed to unarchive Clinical Note: {0}',
  archivedSoapNotesEmptyMessage: 'No Clinical notes have been archived for this injury',
  archivedSoapNotesListTitle: 'Archived Clinical Notes',
  archivedActionText: 'Archived',
  soapNotesTabLabel: 'Clinicial Notes',
  specialistNotesTabLabel: 'Physician/Specialist Notes'
};

export default EN;
