import Strings from './lang';

const RugbyPositions = [
  { key: 'loosehead', name: Strings.rugby_loosehead },
  { key: 'hooker', name: Strings.rugby_hooker },
  { key: 'tighthead', name: Strings.rugby_tighthead },
  { key: 'lock', name: Strings.rugby_lock },
  { key: 'blindsideflank', name: Strings.rugby_blindsideflank },
  { key: 'opensideflank', name: Strings.rugby_opensideflank },
  { key: 'numbereight', name: Strings.rugby_numbereight },
  { key: 'scrumhalf', name: Strings.rugby_scrumhalf },
  { key: 'flyhalf', name: Strings.rugby_flyhalf },
  { key: 'insidecenter', name: Strings.rugby_insidecenter },
  { key: 'outsidecenter', name: Strings.rugby_outsidecenter },
  { key: 'rightwing', name: Strings.rugby_rightwing },
  { key: 'leftwing', name: Strings.rugby_leftwing },
  { key: 'fullback', name: Strings.rugby_fullback }
];

export default RugbyPositions;
