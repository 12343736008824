import React, { useMemo } from 'react';
import RoleStrings from '@/utilities/role-strings';
import Strings from './lang';

const UnauthorizedView = ({ permissions }) => {
  const permissableRoles = useMemo(() => (
    (permissions || []).map(role => RoleStrings[role.roleName]).join(', ')
  ), [permissions]);

  return (
    <div>
      <h2>{Strings.unauthorizedHeaderText}</h2>
      <div>
        {Strings.formatString(
          Strings.unauthorizedDescriptionText,
          permissableRoles
        )}
      </div>
    </div>
  );
};

export default UnauthorizedView;
