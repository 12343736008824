import { UPDATE_INJURY_ASSESSMENT } from '../actions/injury-assessments';

const updateInjuryAssessmentsReducer = (state = {}, action = {}) => {
  const { userId, assessment = {} } = action;
  const { id } = assessment;

  const userState = state[userId] || {};
  const newAssessmentState = (id) ? { ...userState, [id]: assessment } : userState;

  return (userId) ? { ...state, [userId]: newAssessmentState } : state;
};

const injuryAssessments = (state = {}, action = {}) => {
  switch (action.type) {
    case UPDATE_INJURY_ASSESSMENT:
      return updateInjuryAssessmentsReducer(state, action);
    default:
      return state;
  }
};

export default injuryAssessments;
