const EN = {
  title: 'Progress Update Reports',
  dateReportedLabel: 'Progress Update: ',
  progressReportListSubtitle: 'Progress Update Reports for injury dated {0}',
  progressUpdateMessageText: 'This patient has never had a progress update reported remotely for this injury.',
  progressReportCardTitle: 'Progress Update Report - {0}',
  patientLabel: 'Patient',
  reportedDurationLabel: 'Reported',
  reportedByLabel: 'Reported By',
  descriptionLabel: 'Description',
  selfReportedText: 'Self Reported',
  notSelfReportedText: '{0} ({1})'
};

export default EN;
