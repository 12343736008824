const EN = {
  nameHeadingText: 'Name',
  creditsHeadingText: 'Credits Available',
  balanceHeadingText: 'Clinic Balance',
  activeText: 'Active',
  inactiveText: 'Inactive',
  statusHeadingText: 'Status',
  servicesHeadingText: 'Services'
};

export default EN;
