const FR = {
  baselineTestCardTitleText: 'Test de référence',
  baselineTestCardSubtitleText: 'Disponible seulement pour les patients sans blessure',
  clinicSearchCardTitleText: 'Chercher des patients',
  clinicPatientsCardTitleText: 'Gérer les patients',
  teamLeadersCardTitleText: 'Gérer les chefs d’équipe',
  resourcesCardTitleText: 'Ressources',
  findClinicCardTitleText: 'Trouver une clinique CCMI',
  cardButtonTitle: 'Voir',
  startCardButtonTitle: 'Commencer',
  postInjuryAssessmentCardTitleText: 'Évaluation post-blessure',
  postInjuryAssessmentCardSubtitleText: '',
  clinicsCardTitleText: 'Gérer les cliniques',
  clinicsStatsCardTitleText: 'Statistiques de la clinique',
  accountBalanceTitleText: 'Solde du compte',
  accountBalanceCreditsText: 'Crédits : {0}',
  accountBalanceBalanceOwingText: 'Solde dû : {0}',
  manageBagTagsTitleText: 'Gérer les cartes d’identité',
  continueBaselineCardTitle: 'Continuer le test de référence',
  frontDeskCheckInText: 'Enregistrer le patient',
  taxRatesCardTitle: 'Les taux d\'imposition',
  overdueInvoiceLabel: 'Facture en retard',
  resumeTestButtonText: 'Reprendre le test',
  dashboardPreviewContinueBaseline: 'Continuer le test de référence',
  dashboardPreviewPostInjury: 'Évaluation post-blessure',
  dashboardPreviewHomeText: 'Dashboard'
};
 
export default FR;
