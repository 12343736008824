const FR = {
  title: 'Tests DANA',
  allCognitiveTestsLabel: 'Tous les tests cognitifs',
  srtLabel: 'Simple Reaction Time',
  prtLabel: 'Procedural Reaction Time',
  csLabel: 'Code Substitution',
  spLabel: 'Spatial Processing',
  gngLabel: 'Go No Go',
  mtsLabel: 'Match To Sample',
  msLabel: 'Memory Search',
  phq8Label: 'Patient Health Questionnaire 8',
  phq9Label: 'Patient Health Questionnaire 9',
  isiLabel: 'Insomnia Severity Index',
  gad7Label: 'Generalized Anxiety Disorder 7',
  reactionTimeLabel: 'Temps de réaction',
  cognitiveEfficiencyLabel: 'Efficacité cognitive',
  percentCorrectLabel: 'Pourcentage correct',
  surveyScoreLabel: 'Score de l\'enquête',
  baselineLabel: 'Test de Référence',
  postInjuryLabel: 'Post-blessure',
  phqStage1Description: 'Dépression minimale',
  phqStage2Description: 'Dépression bénin',
  phqStage3Description: 'Dépression modérée',
  phqStage4Description: 'Dépression modérément sévère',
  phqStage5Description: 'Dépression sévère',
  isiStage1Description: 'Pas d\'insomnie cliniquement significative',
  isiStage2Description: 'Insomnie sous le seuil',
  isiStage3Description: 'Insomnie clinique de gravité modérée',
  isiStage4Description: 'Insomnie clinique sévère',
  gad7Stage1Description: 'Anxiété minimale',
  gad7Stage2Description: 'Anxiété légère',
  gad7Stage3Description: 'Anxiété modérée',
  gad7Stage4Description: 'Anxiété sévère',
  loadingMessage: 'Chargement',
  batteryDetailsTitle: 'Test neurocognitif',
  quickTestLabel: 'Test rapide',
  fullTestLabel: 'Test complet',
  sidelineLabel: 'Sideline',
  testsEmptyText: 'Aucun test DANA trouvé',
  selectListTestMessage: 'Sélectionnez un test dans la liste pour afficher tous les détails du test',
  descriptionLabel: 'Description',
  meanReactionTimeLabel: 'Temps de réaction moyen',
  meanReactionTimeCorrectLabel: 'Mean Reaction Time Correct',
  standardDeviationLabel: 'Écart-type',
  medianReactionTimeLabel: 'Temps de réaction médian',
  numTrialsLabel: 'Nombre d\'essais',
  percentLapseLabel: 'Pourcentage de déchéance',
  percentFastLabel: 'Pourcentage rapide',
  phqQuestion1: 'Peu d\'intérêt ou de plaisir à faire les choses.',
  phqQuestion2: 'Se sentir déprimé, déprimé ou désespéré.',
  phqQuestion3: 'Difficulté à s\'endormir ou à rester endormi, ou trop dormir.',
  phqQuestion4: 'Se sentir fatigué ou avoir peu d\'énergie.',
  phqQuestion5: 'Manque d\'appétit ou suralimentation.',
  phqQuestion6: 'Vous sentir mal dans votre peau - ou que vous êtes un échec ou que vous vous êtes laissé tomber, vous ou votre famille.',
  phqQuestion7: 'Difficulté à se concentrer sur des choses, comme lire le journal ou regarder la télévision.',
  phqQuestion8: 'Bouger ou parler si lentement que d\'autres personnes auraient pu le remarquer.Ou le contraire – être si agité ou agité que vous avez beaucoup plus bougé que d\'habitude.',
  phqQuestion9: 'Pensées que vous feriez mieux de mourir, ou de vous blesser d\'une manière ou d\'une autre.',
  phqQuestion10: 'Dans quelle mesure ces problèmes vous ont-ils rendu difficile de faire votre travail, de vous occuper de choses à la maison ou de vous entendre avec d\'autres personnes?',
  psychAnswerNotAtAll: 'Pas du tout',
  psychAnswerSeveralDays: 'Plusieurs jours',
  psychAnswerMoreThanHalfDays: 'Plus de la moitié des jours',
  psychAnswerNearlyEveryDay: 'Presque tous les jours',
  psychAnswerNone: 'Rien',
  psychAnswerMild: 'Doux',
  psychAnswerModerate: 'Modérer',
  psychAnswerSevere: 'Sévère',
  psychAnswerVerySevere: 'Très sévère',
  psychAnswerALittle: 'Un peu',
  psychAnswerSomewhat: 'Quelque peu',
  psychAnswerMuch: 'Beaucoup',
  psychAnswerVeryMuch: 'Tres Beaucoup',
  psychAnswerNotDifficult: 'Pas difficile du tout',
  psychAnswerSomewhatDifficult: 'Un peu difficile',
  psychAnswerVeryDifficult: 'Très difficile',
  psychAnswerExtremelyDifficult: 'Extrêmement difficile',
  psychAnswerVerySatisfied: 'Très satisfait',
  psychAnswerSatisfied: 'Satisfait',
  psychAnswerModeratelySatisfied: 'Moyennement satisfait',
  psychAnswerDissatisfied: 'Mécontent',
  psychAnswerVeryDissatisfied: 'Très insatisfait',
  isiQuestion1: 'Difficulté à s\'endormir ?',
  isiQuestion2: 'Difficulté à rester endormi ?',
  isiQuestion3: 'Problème de réveil trop tôt ?',
  isiQuestion4: 'Dans quelle mesure êtes-vous satisfait/insatisfait de votre rythme de sommeil actuel ?',
  isiQuestion5: 'Dans quelle mesure pensez-vous que votre problème de sommeil est perceptible aux yeux des autres en termes de dégradation de la qualité de votre vie ?',
  isiQuestion6: 'Dans quelle mesure êtes-vous inquiet/affligé par votre problème de sommeil actuel ?',
  isiQuestion7: 'Dans quelle mesure considérez-vous que votre problème de sommeil interfère actuellement avec votre fonctionnement quotidien ? (ex., fatigue diurne, humeur, capacité de fonctionner au travail/tâches quotidiennes, concentration, mémoire, humeur, etc.)',
  gad7Question1: 'Se sentir nerveux, anxieux ou nerveux',
  gad7Question2: 'Ne pas être capable d\'arrêter ou de contrôler ses inquiétudes',
  gad7Question3: 'Trop s\'inquiéter pour différentes choses',
  gad7Question4: 'Difficulté à se détendre',
  gad7Question5: 'Être si agité qu\'il est difficile de rester assis',
  gad7Question6: 'Devenir facilement agacé ou irritable',
  gad7Question7: 'Avoir peur comme si quelque chose de terrible pouvait arriver',
  gad7QuestionSummary: 'Dans quelle mesure ces problèmes vous ont-ils rendu difficile de faire votre travail, de vous occuper des choses à la maison ou de vous entendre avec d\'autres personnes?',
  trialsAcceptableDescription: 'Test réussi',
  trialsUnacceptableDescription: 'Nombre d\'essais inférieur à la moyenne correct',
  trialsIncompleteDescription: 'Test échoué',
  baselineReferenceLineText: 'Test de Référence',
  firstUpperStdReferenceLineText: '1er supérieur',
  firstLowerStdReferenceLineText: '1er inférieur',
  secondUpperStdReferenceLineText: '2e supérieur',
  secondLowerStdReferenceLineText: '2e inférieur',
  rtDifferenceMetricLabel: 'Diff. de temps de réaction',
  rtDifferenceChartLabel: '{0}2 - {0}1 Différence RT',
  rtDifferenceStdevChartLabel: '{0}1 Std. Dév.',
  allTestsSelectorLabel: 'Tout',
  baselineTestsSelectorLabel: 'Références',
  postInjuryTestsSelectorLabel: 'Post-blessure',
  responseTimeLabel: 'Temps de réponse',
  malingeringLabel: 'Malingering',
  correctLabel: 'Correct',
  incorrectLabel: 'Incorrect',
  lapsedLabel: 'Périmé',
  fastLabel: 'Trop vite',
  trialLabel: 'Essai {0}',
  invalidText: 'Invalide',
  malingeringAvgLabel: 'Moyenne Malingering',
  nonMalingeringAvgLabel: 'Moyenne Non-Malingering',
  validTestTabLabel: 'Valide',
  invalidTestTabLabel: 'Invalide',
  summaryReportButton: 'Rapport sommaire',
  downloadingText: 'Téléchargement',
  inMillisecondsLabel: 'en millisecondes',
  responsesPerMinuteLabel: 'réponses par minute',
  summaryReportSubtitle: 'Tests DANA effectués entre {0} et {1}',
  cognitiveTestTableColumnLabel: 'Test cognitif',
  psychTestTableColumnLabel: 'Enquête psychologique',
  summaryLabel: 'Résumé',
  reportGeneratedByLabel: 'Généré par {0} {1} le {2}',
  reportPageIndicator: 'Page {0}/{1}',
  patientLabel: 'Patient',
  dateOfBirthLabel: 'Né(e)',
  phoneLabel: 'Téléphone',
  patientInfoTitle: 'Détails du Patient',
  nameLabel: 'Nom',
  accountLabel: 'Compte',
  genderLabel: 'Genre',
  genderNotProvided: 'Non fourni',
  languageLabel: 'Langue',
  unknownLabel: 'Inconnu',
  baselineQuickTestTitle: 'Test rapide de référence DANA',
  baselineFullTestTitle: 'Test complet de référence DANA',
  postInjuryQuickTestTitle: 'Test rapide post-blessure DANA',
  postInjuryFullTestTitle: 'Test complet post-blessure DANA'
};
 
export default FR;
