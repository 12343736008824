/****************/
/* Role Filters */
/****************/

const makeRoleDescription = (roleName, resourceType = null, resourceId = 0) => ({
  roleName,
  resourceType,
  resourceId
});

const findRoleByDescription = (roles, roleDescription) => roles.find(role => {
  return (role.name === roleDescription.roleName)
    && (role.resource_type === roleDescription.resourceType 
        || roleDescription.resourceType === null)
    && (parseInt(role.resource_id, 10) === parseInt(roleDescription.resourceId, 10)
        || !roleDescription.resourceId);
});

const userHasRoleMatchingDescription = (user, roleDescription) => {
  return findRoleByDescription(user.roles || [], roleDescription) !== undefined;
};

const userHasRoleMatchingOneOfDescriptions = (user, roleDescriptions = []) => {
  return roleDescriptions.filter(description => {
    return userHasRoleMatchingDescription(user, description);
  }).length > 0;
};

const userHasAdminRole = (user) => {
  return userHasRoleMatchingOneOfDescriptions(user, [
    RoleDescriptions.SuperAdmin,
    RoleDescriptions.SalesAdmin
  ]);
};

const userHasAdminOrClinicRole = (user) => {
  return userHasRoleMatchingOneOfDescriptions(user, AdminAndClinicRoleDescriptions);
};

const userHasIDTRole = (user) => {
  return userHasRoleMatchingOneOfDescriptions(user, IDTRoleDescriptions);
};

const userHasRoleRequiringTraining = (user) => {
  return userHasRoleMatchingOneOfDescriptions(user, TrainingRoleDescriptions);
};

const resolveRoleDescriptionsWithResourceIdParam = (roleDescriptions, params = {}, paramName = '') => {
  const resourceId = params[paramName];
  if (resourceId) {
    return roleDescriptions.map(description => {
      return makeRoleDescription(description.roleName, description.resourceType, resourceId);
    });
  } 

  return [];
};

/******************/
/* Role Constants */
/******************/

const RoleNames = {
  SuperAdmin: 'super_admin',
  SalesAdmin: 'sales_admin',
  ClinicOwner: 'clinic_owner',
  Clinician: 'clinician',
  Specialist: 'specialist',
  ClinicStaff: 'clinic_staff',
  ClinicFrontDesk: 'front_desk',
  BaselineTester: 'baseline_tester',
  Leader: 'leader',
  Guardian: 'guardian',
  Player: 'player',
  IDTClinician: 'idt_clinician',
  IDTFrontDesk: 'idt_front_desk'
};

const RoleResourceTypes = {
  Clinic: 'Clinic',
  Team: 'Team'
};

/*********************/
/* Role Descriptions */
/*********************/

const RoleDescriptions = {
  SuperAdmin: makeRoleDescription(RoleNames.SuperAdmin),
  SalesAdmin: makeRoleDescription(RoleNames.SalesAdmin),
  ClinicOwner: makeRoleDescription(RoleNames.ClinicOwner, RoleResourceTypes.Clinic),
  Clinician: makeRoleDescription(RoleNames.Clinician, RoleResourceTypes.Clinic),
  Specialist: makeRoleDescription(RoleNames.Specialist, RoleResourceTypes.Clinic),
  ClinicStaff: makeRoleDescription(RoleNames.ClinicStaff, RoleResourceTypes.Clinic),
  ClinicFrontDesk: makeRoleDescription(RoleNames.ClinicFrontDesk, RoleResourceTypes.Clinic),
  BaselineTester: makeRoleDescription(RoleNames.BaselineTester, RoleResourceTypes.Clinic),
  IDTClinician: makeRoleDescription(RoleNames.IDTClinician, RoleResourceTypes.Clinic),
  IDTFrontDesk: makeRoleDescription(RoleNames.IDTFrontDesk, RoleResourceTypes.Clinic),
  Leader: makeRoleDescription(RoleNames.Leader, RoleResourceTypes.Team),
  Guardian: makeRoleDescription(RoleNames.Guardian),
  // Player may have a resource type of Team or Clinic, so allow any type
  Player: makeRoleDescription(RoleNames.Player)
};

const NoResourceTypeRoleDescriptions = {
  ClinicOwner: makeRoleDescription(RoleNames.ClinicOwner),
  Clinician: makeRoleDescription(RoleNames.Clinician),
  Specialist: makeRoleDescription(RoleNames.Specialist),
  ClinicStaff: makeRoleDescription(RoleNames.ClinicStaff),
  ClinicFrontDesk: makeRoleDescription(RoleNames.ClinicFrontDesk),
  BaselineTester: makeRoleDescription(RoleNames.BaselineTester)
};

const NoResourceTypeClinicUserRoles = [
  NoResourceTypeRoleDescriptions.ClinicFrontDesk,
  NoResourceTypeRoleDescriptions.Clinician,
  NoResourceTypeRoleDescriptions.Specialist,
  NoResourceTypeRoleDescriptions.ClinicOwner,
  NoResourceTypeRoleDescriptions.ClinicStaff,
  NoResourceTypeRoleDescriptions.BaselineTester,
];

const AdminRoleDescriptions = [
  RoleDescriptions.SuperAdmin,
  RoleDescriptions.SalesAdmin
];

const ClinicRoleDescriptions = [
  RoleDescriptions.ClinicOwner,
  RoleDescriptions.Clinician,
  RoleDescriptions.Specialist,
  RoleDescriptions.ClinicStaff,
  RoleDescriptions.ClinicFrontDesk,
  RoleDescriptions.BaselineTester
];

const makeAllClinicRoleDescriptions = (clinicId) => (
  ClinicRoleDescriptions.map(description => (
    makeRoleDescription(description.roleName, description.resourceType, clinicId)
  ))
);

const IDTRoleDescriptions = [
  RoleDescriptions.IDTClinician,
  RoleDescriptions.IDTFrontDesk
];

const AdminAndClinicRoleDescriptions = [
  ...AdminRoleDescriptions,
  ...ClinicRoleDescriptions
];

const TrainingRoleDescriptions = [
  RoleDescriptions.Clinician
];

const AllRoleDescriptions = Object.values(RoleDescriptions);

/******************/
/* Role Hierarchy */
/******************/

const RoleHierarchy = [
  RoleNames.Player,
  RoleNames.Leader,
  RoleNames.ClinicStaff,
  RoleNames.BaselineTester,
  RoleNames.ClinicFrontDesk,
  RoleNames.Specialist,
  RoleNames.Clinician,
  RoleNames.ClinicOwner,
  RoleNames.SalesAdmin,
  RoleNames.SuperAdmin
];

// Returns an integer representing the user's index in the role hierarchy.
// A larger value indicates superiority.
// -1 indicates that the user does not have a role matching the input parameters.
const roleHierarchyLevelForUser = (user, resourceType = null, resourceId = 0) => {
  if (userHasRoleMatchingDescription(user, RoleDescriptions.SuperAdmin)) {
    return RoleHierarchy.indexOf(RoleNames.SuperAdmin);
  } 
  
  if (userHasRoleMatchingDescription(user, RoleDescriptions.SalesAdmin)) {
    return RoleHierarchy.indexOf(RoleNames.SalesAdmin);
  } 
    
  return RoleHierarchy.reduce((maxIndex, roleName, index) => {
    const roleDescription = makeRoleDescription(roleName, resourceType, resourceId);
    const match = userHasRoleMatchingDescription(user, roleDescription);
    return (match && index > maxIndex) ? index : maxIndex;
  }, -1);
};

const userHasRoleSuperiorityOverUser = (user1, user2, resourceType = null, resourceId = 0) => {
  const user1Level = roleHierarchyLevelForUser(user1, resourceType, resourceId);
  const user2Level = roleHierarchyLevelForUser(user2, resourceType, resourceId);
  return user1Level >= 0 && user1Level > user2Level;
};

export {
  makeRoleDescription,
  findRoleByDescription,
  userHasRoleMatchingDescription,
  userHasRoleMatchingOneOfDescriptions,
  userHasAdminRole,
  userHasAdminOrClinicRole,
  userHasRoleRequiringTraining,
  userHasIDTRole,
  resolveRoleDescriptionsWithResourceIdParam,
  RoleNames,
  RoleResourceTypes,
  RoleDescriptions,
  NoResourceTypeRoleDescriptions,
  NoResourceTypeClinicUserRoles,
  AdminRoleDescriptions,
  ClinicRoleDescriptions,
  IDTRoleDescriptions,
  AdminAndClinicRoleDescriptions,
  TrainingRoleDescriptions,
  AllRoleDescriptions,
  RoleHierarchy,
  roleHierarchyLevelForUser,
  userHasRoleSuperiorityOverUser,
  makeAllClinicRoleDescriptions
};
