import React from 'react';
import PropTypes from 'prop-types';
import BaselineSection from './baseline-section';
import Fraction from '../../fraction';
import Strings from './lang';

const DEFAULT_WORD_COUNT = 5;

const BaselineDelayedMemory = ({
  data = {}
}) => {
  const { 
    score = -1, word_count = DEFAULT_WORD_COUNT, not_done = false 
  } = data;

  return (
    <BaselineSection
      className="baseline-delayed-memory"
      title={Strings.delayedMemoryTitle}
      sectionCompleted={!not_done && score >= 0}
    >
      <p>
        <strong>
          {Strings.scoreLabelText}
        </strong>&nbsp;
        <Fraction 
          numerator={score} 
          denominator={word_count} 
        />
      </p>
    </BaselineSection>
  );
};

BaselineDelayedMemory.propTypes = {
  data: PropTypes.object.isRequired
};

export default BaselineDelayedMemory;
