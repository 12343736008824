import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import SymptomsColumn from './symptoms-column';
import SymptomsUpdateColumn from './symptoms-update-column';
import { INPUT_DATE_FORMAT } from '@/utilities/dates/formats';

const SymptomsUpdatesTable = ({
  symptomsUpdates = []
}) => (
  <div className="symptoms-update-table no-print">
    <div className="symptom-keys">
      <SymptomsColumn />
    </div>
    <div className="symptoms-scores">
      <div className="submitted-update-scores">
        {symptomsUpdates.map((symptoms, index) => (
          <SymptomsUpdateColumn 
            key={index} 
            header={dayjs(symptoms.created_at).format(INPUT_DATE_FORMAT)} 
            symptoms={symptoms.symptoms} 
          />
        ))}
      </div>
    </div>
  </div>
);

SymptomsUpdatesTable.propTypes = {
  symptomsUpdates: PropTypes.array
};

export default SymptomsUpdatesTable;
