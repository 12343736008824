import { UPDATE_TEAM_LEADERS, UPDATE_TEAM_LEADER } from '../actions/team-leaders';

const updateTeamLeaderReducer = (state = {}, action = {}) => {
  const { id, teamLeader } = action;
  const newState = [...state];
  const index = newState.findIndex(leader => String(leader.id) === String(id));
  if (index >= 0) {
    newState[index] = { ...newState[index], ...teamLeader };
  } else {
    newState.push(teamLeader);
  }

  return newState;
};

const teamLeaders = (state = [], action = {}) => {
  switch (action.type) {
    case UPDATE_TEAM_LEADERS:
      return action.teamLeaders || [];
    case UPDATE_TEAM_LEADER:
      return updateTeamLeaderReducer(state, action);
    default:
      return state;
  }
};

export default teamLeaders;
