import React from 'react';
import PropTypes from 'prop-types';
import { If, Then, Else } from 'react-if';
import Card from '../card';
import ClinicianPhotoCard from '../user-profile-cards/clinician-photo-card';
import TeamLeaderPersonalCard from '../user-profile-cards/team-leader-personal-card';
import TeamLeaderContactCard from '../user-profile-cards/team-leader-contact-card';
import EditTeamLeaderContactCardForm from '../user-profile-cards/edit-team-leader-contact-card-form';
import EditTeamLeaderPersonalCardForm from '../user-profile-cards/edit-team-leader-personal-card-form';
import AccessControl from '../access-control';
import { EditButton } from '../buttons';
import { ErrorBanner } from '../errors';
import Strings from './lang';

const SectionError = ({ section, error = {} }) => {
  return error && error.section === section && error.message.length > 0 
    && <ErrorBanner error={error.message} />;
};

const ShowTeamLeaderProfile = ({
  user = {}, 
  editorRoles = [], 
  sectionError = {}, 
  editPersonal = false, 
  editContact = false,
  onClickEdit,
  onSubmitEdit
}) => (
  <div className="profile team-leader">
    <Card title={Strings.photoHeadingText}>
      <ClinicianPhotoCard user={user} />
    </Card>

    <SectionError section="personal" error={sectionError} />
    <Card
      title={Strings.personalHeadingText}
      actionButtons={(
        <AccessControl roles={editorRoles}>
          <EditButton
            editing={editPersonal}
            onClick={() => onClickEdit('editPersonal')}
          />
        </AccessControl>
      )}
    >
      <If condition={editPersonal}>
        <Then>
          <EditTeamLeaderPersonalCardForm
            user={user}
            onSubmit={(attributes) => onSubmitEdit('personal', attributes)}
          />
        </Then>
        <Else>
          <TeamLeaderPersonalCard
            user={user}
          />
        </Else>
      </If>
    </Card>

    <SectionError section="contact" error={sectionError} />
    <Card
      title={Strings.contactHeadingText}
      actionButtons={(
        <AccessControl roles={editorRoles}>
          <EditButton
            editing={editContact}
            onClick={() => onClickEdit('editContact')}
          />
        </AccessControl>
      )}
    >
      <If condition={editContact}>
        <Then>
          <EditTeamLeaderContactCardForm
            user={user}
            onSubmit={(attributes) => onSubmitEdit('contact', attributes)}
          />
        </Then>
        <Else>
          <TeamLeaderContactCard 
            user={user}
          />
        </Else>
      </If>
    </Card>
  </div>
);

ShowTeamLeaderProfile.propTypes = {
  user: PropTypes.object,
  editPersonal: PropTypes.bool,
  editContact: PropTypes.bool,
  onClickEdit: PropTypes.func.isRequired
};

export default ShowTeamLeaderProfile;
