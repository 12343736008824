const FR = {
  resourcesTitle: 'Ressources',
  emptyFolderMessage: 'Ce dossier semble être vide',
  homeBreadcrumbTitle: 'Accueil',
  folderListTitle: 'Dossiers',
  fileListTitle: 'Des dossiers',
  noSearchResultsMessage: 'Aucune ressource trouvée pour votre recherche',
  loadingText: 'Chargement de fichiers',
  searchingText: 'Recherche de fichiers',
  searchPlaceholder: 'Rechercher des ressources',
  clearSearchTitle: 'Effacer la valeur et les résultats de la recherche',
  courseVideosLink: 'Vidéos de cours'
};
 
export default FR;
