import { jsonApiRead } from '@/lib/requests/jsonapi';
import { updateClinicPlayersSearch } from '../actions/clinic-players-search';

const searchClinicPlayersAsync = (attributes = {}) => {
  return (dispatch) => {
    return jsonApiRead({ type: 'clinics', attributes }, { path: 'search/clinics/players' }).then(results => {
      dispatch(updateClinicPlayersSearch(attributes, results));
      return Promise.resolve({ attributes, results });
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

export {
  searchClinicPlayersAsync
};
