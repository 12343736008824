const FR = {
  trialLabel: 'Essai no {0}',
  unitLabel: 'cm',
  averageDistanceLabel: 'Distance moyenne (cm)',
  averageTimeLabel: 'Temps de réaction moyen (s)',
  dominantHandLabel: 'Main dominante',
  leftHandOption: 'Gauche',
  rightHandOption: 'Droit',
  defaultOption: 'Choisir une option',
  dominantHandErrorMsg: 'Veuillez choisir une main dominante',
  reactionTimeResultText: '{0} secondes'
};
 
export default FR;
