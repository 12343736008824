const EN = {
  genericTitle: 'Error',
  genericmessage: 'Something went wrong.',
  e404Title: 'Not Found',
  e404Message: 'The resource you requested does not exist.',
  homeLinkText: 'Go Home',
  error401Signin: 'Invalid email and/or password',
  error401Password: 'Invalid Password',
  errorSubTitle: 'An unexpected error occurred which has prevented us from showing you this page.',
  errorDescription: 'The details of this error have been reported and we have been notified. If you wish to provide a more detailed explaination about how this error occurred, please fill out the feedback form by clicking the "Submit Feedback" button below.',
  errorFeedbackTitle: 'Error Feedback',
  errorFeedbackSubtitle: 'Help us to track down this error by letting us know what happened.',
  errorFeedbackSubtitle2: 'Any feedback is greatly appreciated!',
  errorFeedbackSubmitButton: 'Submit Feedback',
  actionHome: 'Go Home',
  actionRefresh: 'Refresh Page',
  actionSubmitFedback: 'Submit Feedback'
};

export default EN;
