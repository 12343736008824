import { jsonApiRead } from '@/lib/requests/jsonapi';
import Drive from '@/lib/requests/drive-api';
import { updateDriveAuth, updateDriveFiles } from '../actions/drive';
import HTTPStatusCodes from '@/utilities/http-status-codes';

const driveAuth = () => {
  return (dispatch, getState) => {
    const { drive } = getState();
    const { auth = {} } = drive;

    if (auth.access_token) {
      return Promise.resolve(auth);
    }

    return jsonApiRead({ type: 'drive' }, { path: 'drive/auth' }).then(auth => {
      dispatch(updateDriveAuth(auth));
      return Promise.resolve(auth);
    });
  };
};

const getDriveFolderAsync = (fileId, retry = true) => {
  return dispatch => {
    return dispatch(driveAuth()).then(auth => {
      return Drive(auth).folder(fileId || auth.folder_id);
    }).then(files => {
      dispatch(updateDriveFiles(files));
      return Promise.resolve(files);
    }).catch(error => {
      /**
       * If an unauthorized response is recieved. Remove the drive credentials, refetch 
       * the credentials and try the request again. If it fails a second time, reject
       * the error and display for user. 
       */
      if (error.code === HTTPStatusCodes.Unauthorized && retry) {
        dispatch(updateDriveAuth({}));
        return dispatch(getDriveFolderAsync(fileId, false));
      }

      return Promise.reject(error);
    });
  };
};

const getDriveSearchResults = (searchTerm, retry = true) => {
  return dispatch => {
    return dispatch(driveAuth()).then(auth => {
      return Drive(auth).search(searchTerm);
    }).then(results => {
      return Promise.resolve(results.files);
    }).catch(error => {
      /**
       * If an unauthorized response is recieved. Remove the drive credentials, refetch 
       * the credentials and try the request again. If it fails a second time, reject
       * the error and display for user. 
       */
      if (error.code === HTTPStatusCodes.Unauthorized && retry) {
        dispatch(updateDriveAuth({}));
        return dispatch(getDriveSearchResults(searchTerm, false));
      }

      return Promise.reject(error);
    });
  };
};

export {
  getDriveFolderAsync,
  getDriveSearchResults
};
