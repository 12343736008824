import { UPDATE_CLINIC_USERS, ADD_CLINIC_USER } from '../actions/clinic-users';

const addClinicUserReducer = (state = {}, action = {}) => {
  const { clinicId, user } = action;
  const { [clinicId]: users = [] } = state;

  return user && clinicId ? {
    ...state,
    [clinicId]: [...users, user]
  } : state;
};

const updateClinicUsersReducer = (state = {}, action = {}) => {
  const { clinicId = 0, users = [] } = action;
  return (clinicId) ? { ...state, [clinicId]: users } : state;
};

const clinicUsers = (state = {}, action = {}) => {
  switch (action.type) {
    case UPDATE_CLINIC_USERS:
      return updateClinicUsersReducer(state, action);
    case ADD_CLINIC_USER:
      return addClinicUserReducer(state, action);
    default:
      return state;
  }
};

export default clinicUsers;
