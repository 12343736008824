import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { If, Then } from 'react-if';
import { DISPLAY_DATE_FORMAT, INPUT_DATE_FORMAT } from '@/utilities/dates/formats';
import { getSport } from '@/utilities/sports';
import Strings from './lang';

const renderImpactLocations = (impactLocation = {}) => {
  const checkedLocations = Object.keys(impactLocation).filter(key => (
    impactLocation[key] === true
  )).sort().map(key => {
    return Strings[`impactlocation_${key}`];
  });
  
  if (checkedLocations.length === 0) {
    return Strings.noneText;
  }

  return checkedLocations.join(', ');
};

const PostInjuryCharacteristics = ({
  injury = {}, 
  characteristics = {}
}) => {
  const {
    cause = Strings.defaultCauseText,
    cause_sport = '',
    cause_sport_other = '',
    cause_other = ''
  } = characteristics;
  const amnesia = characteristics.amnesia || {};

  return (
    <div className="post-injury-section post-injury-characteristics">

      <div className="row">
        <div className="col-md-12">
          <h2 className="bold">{Strings.characteristicsTitle}</h2>
        </div>
      </div>

      <div className="post-injury-group">
        <div className="row">
          <div className="col-md-6">
            <strong>{Strings.injuryDateLabelText}</strong> {
              dayjs(injury.injured_at, INPUT_DATE_FORMAT).format(DISPLAY_DATE_FORMAT)
            }
          </div>
        </div>
      </div>

      <div className="post-injury-group">
        <div className="row">
          <div className="col-md-12">
            <strong>{Strings.injuryDescriptionLabelText}</strong> 
            <div className="multiline-text-display">
              {characteristics.description || Strings.defaultDescriptionText}
            </div>
          </div>
        </div>
      </div>

      <div className="post-injury-group">
        <div className="row">
          <div className="col-md-6">
            <strong>
              {Strings.injuryCauseLabelText}
            </strong>&nbsp;
            <span>{cause}</span> 
            {cause_sport.length > 0 && (
              <span>&nbsp;&mdash;&nbsp;{getSport(cause_sport).name}</span>
            )}
            {cause_sport_other.length > 0 && (
              <span>&nbsp;&mdash;&nbsp;{cause_sport_other}</span>
            )}
            {cause_other.length > 0 && (
              <span>&nbsp;&mdash;&nbsp;{cause_other}</span>
            )}
          </div>
          <div className="col-md-6">
            <strong>{Strings.directHeadImpactLabelText}</strong>&nbsp;
            <span className="capitalize">
              {characteristics.direct_head_impact}
            </span>
          </div>
        </div>
      </div>

      <div className="post-injury-group">
        <div className="row">
          <div className="col-md-6">
            <strong>{Strings.headImpactLocationLabelText}</strong>&nbsp;
            <span>
              {renderImpactLocations(characteristics.head_impact_location)}
            </span>
          </div>
          <div className="col-md-6">
            <strong>{Strings.bodyImpactLocationLabelText}</strong>&nbsp;
            <span>
              {renderImpactLocations(characteristics.body_impact_location)}
            </span>
          </div>
        </div>
      </div>

      <div className="post-injury-group post-injury-table-group">
        <div className="row">
          <div className="col-md-12">
            <strong>{Strings.amnesiaConsciousnessLabelText}</strong>
            <table className="table">
              <thead>
                <tr>
                  <th className="no-action">{Strings.retrogradeAmnesiaLabelText}</th>
                  <th className="no-action">{Strings.anterogradeAmnesiaLabelText}</th>
                  <th className="no-action">{Strings.conciousnessLabelText}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {
                      (amnesia.none !== true && amnesia.retrograde)
                        ? Strings.formatString(Strings.minutesText, amnesia.minutes_retrograde)
                        : Strings.noneText
                    }
                  </td>
                  <td>
                    {
                      (amnesia.none !== true && amnesia.anterograde)
                        ? Strings.formatString(Strings.minutesText, amnesia.minutes_anterograde)
                        : Strings.noneText
                    }
                  </td>
                  <td>
                    {(characteristics.loss_of_consciousness === 'yes')
                      ? Strings.formatString(
                        Strings.minutesText, 
                        characteristics.minutes_unconscious
                      ) : Strings.noneText}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="post-injury-group">
        <div className="row">
          <div className="col-md-12">
            <strong>{Strings.seizuresLabelText}</strong> {
              (characteristics.seizures === 'yes')
                ? Strings.seizuresStatusPositiveText
                : Strings.seizuresStatusNegativeText
            }
          </div>
        </div>
        <If condition={characteristics.seizures === 'yes' && (characteristics.seizures_notes || '').length > 0}>
          <Then>
            <div className="row">
              <div className="col-md-12">
                <strong>{Strings.notesLabelText}</strong> {characteristics.seizures_notes}
              </div>
            </div>
          </Then>
        </If>
      </div>

    </div>
  );
};

PostInjuryCharacteristics.propTypes = {
  injury: PropTypes.object.isRequired,
  characteristics: PropTypes.object.isRequired
};

export default PostInjuryCharacteristics;
