import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { If, Then, Else } from 'react-if';
import {
  generateResponses,
  getResponseErrors,
  getTrialResponseRate,
  regenerateResponses
} from '../utils';
import ElapsedTimer from '@/utilities/timers/elapsed-timer';
import { useLazyRef, useUnmount } from '@/hooks';
import NumberSelector from './number-selector';
import KeyboardControls from './keyboard-controls';
import TrialComplete from './trial-complete';
import Strings from '../lang';

const TIMER_INTERVAL = 10;

const STATES = {
  NOT_STARTED: 0,
  PLAYING: 1,
  STOPPED: 2
};

const ResponsesTable = ({
  responses = [],
  activeIndex,
  onSelect
}) => {
  return (
    <table className="table" style={{ tableLayout: 'fixed' }}>
      <tbody>
        <tr>
          {responses.map((r, i) => (
            <td
              key={`${i}_${r.value}`}
              onClick={() => r.correct !== null && onSelect(i)}
              className={classNames({
                danger: r.correct === false,
                success: r.correct
              })}
            >
              {r.value}
              {activeIndex === i && (
                <div className="active-indicator" />
              )}
            </td>
          ))}
        </tr>
      </tbody>
    </table>
  );
};

const formatInputValue = (timerValue = 0) => {
  return (timerValue / 1000).toFixed(2);
};

const TRIAL_STATE = {
  NOT_STARTED: 0,
  STARTED: 1,
  STOPPED: 2,
  COMPLETE: 3,
};

const DualTaskTrial = ({
  start,
  step,
  onStart,
  onReset,
  onDone
}) => {
  const [responses, setResponses] = useState(() => generateResponses(start, step));
  const [duration, setDuration] = useState(0);
  const [, setTimerState] = useState(STATES.NOT_STARTED);
  const [trialState, setTrialState] = useState(TRIAL_STATE.NOT_STARTED);
  const [activeIndex, setActiveIndex] = useState(-1);
  const completedTrials = useMemo(() => {
    return responses.filter(r => r.correct !== null);
  }, [responses]);
  const errors = useMemo(() => {
    return getResponseErrors(completedTrials);
  }, [completedTrials]);

  const onTimerTick = useCallback((msDuration) => {
    setDuration(msDuration);
  }, []);

  const timer = useLazyRef(() => (
    new ElapsedTimer(TIMER_INTERVAL, onTimerTick)
  ));

  const onStartTrial = useCallback(() => {
    setActiveIndex(0);

    if (timer.current.isRunning()) {
      timer.current.stop();
    }

    timer.current.reset();
    setDuration(0);

    timer.current.start();
    setTimerState(STATES.PLAYING);
    setTrialState(TRIAL_STATE.STARTED);

    if (onStart) {
      onStart();
    }
  }, [onStart, timer]);

  const handleReset = useCallback(() => {
    timer.current.reset();
    setResponses(generateResponses(start, step));
    setTrialState(TRIAL_STATE.NOT_STARTED);
    setDuration(0);
    setActiveIndex(0);

    if (onReset) {
      onReset();
    }
  }, [onReset, start, step, timer]);

  const onStop = useCallback(() => {
    timer.current.stop();
    setTrialState(TRIAL_STATE.STOPPED);
    setTimerState(STATES.STOPPED);
  }, [timer]);

  const updateResponses = useCallback((value, index) => {
    setResponses(prev => {
      const newResponses = [...prev];
      const newResponse = { ...newResponses[index] };
      const isCorrect = value === newResponse?.value;

      newResponse.value = value;
      newResponse.correct = isCorrect;

      newResponses[index] = newResponse;

      return regenerateResponses(newResponses, index, step);
    });
  }, [step]);

  const updateActiveIndex = useCallback((index) => {
    let nextIndex = index + 1;

    if (nextIndex >= responses.length) {
      nextIndex = -1;
      timer.current.stop();
      setTrialState(TRIAL_STATE.COMPLETE);
      setTimerState(STATES.STOPPED);
    }

    setActiveIndex(nextIndex);
  }, [responses.length, timer]);

  const onNext = useCallback((value) => {
    updateResponses(value, activeIndex);
    updateActiveIndex(activeIndex);
  }, [activeIndex, updateActiveIndex, updateResponses]);

  const handleComplete = useCallback((failedAttributes) => {
    const newResponses = responses.filter(r => r.correct !== null);

    const attributes = {
      ...failedAttributes,
      duration,
      errors: getResponseErrors(newResponses),
      responses: newResponses
    };

    onDone(attributes);
  }, [duration, onDone, responses]);

  useUnmount(() => {
    if (timer.current.isRunning()) {
      timer.current.stop();
    }
  });

  return (
    <div className="trial-walkthrough-container">
      <If condition={[TRIAL_STATE.COMPLETE, TRIAL_STATE.STOPPED].includes(trialState)}>
        <Then>
          <TrialComplete
            isStopped={trialState === TRIAL_STATE.STOPPED}
            completed={completedTrials.length}
            errors={errors}
            responseRate={getTrialResponseRate({ responses, duration })}
            duration={duration}
            totalTrials={responses.length}
            onReset={handleReset}
            onDone={handleComplete}
          />
        </Then>
        <Else>
          <div className="trial-walkthrough">
            <div className="timer-display">
              {formatInputValue(duration)}
            </div>
            <div className="responses-table">
              <ResponsesTable
                activeIndex={activeIndex}
                responses={responses}
                onSelect={setActiveIndex}
              />
            </div>
            <div className="trial-steps">
              <If condition={trialState === TRIAL_STATE.NOT_STARTED}>
                <Then>
                  <div className="start-trial-action">
                    <button type="button" className="btn btn-primary" onClick={onStartTrial}>
                      {Strings.startButton}
                    </button>
                  </div>
                </Then>
                <Else>
                  <NumberSelector
                    key={responses[activeIndex]?.value ?? 0}
                    value={responses[activeIndex]?.value ?? 0}
                    disabled={trialState !== TRIAL_STATE.STARTED}
                    onStop={onStop}
                    onSelect={onNext}
                  />
                </Else>
              </If>
              <KeyboardControls />
            </div>
          </div>
        </Else>
      </If>
    </div>
  );
};

export default DualTaskTrial;
