import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const UserClinicsInfoCard = ({
  clinic = {},
  className
}) => (
  <div className={classNames('card card-preview', className)}>

    <div className="card-preview-column image-column">
      <img src={clinic.photo_url} alt="clinic" className="img-responsive" />
    </div>

    <div className="card-preview-column">
      <h2>{ clinic.name }</h2>
      <div className="card-row">
        <a href={`tel:${clinic.phone}`}>{clinic.phone}</a>
      </div>
      <div className="card-row">
        <a href={`mailto:${clinic.email}`}>{clinic.email}</a>
      </div>
    </div>

  </div>
);

UserClinicsInfoCard.propTypes = {
  className: PropTypes.string,
  clinic: PropTypes.object.isRequired
};

export default UserClinicsInfoCard;
