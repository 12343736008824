import { createSelector } from 'reselect';

const getUsers = (state) => state.users;
const getUserId = (_, { userId }) => userId;
const getRoles = (_, { roles }) => roles;
const getCurrentUserId = (state) => state.session?.current_user_id; 

const getUser = createSelector(
  [getUsers, getUserId],
  (users, userId) => {
    return users[userId];
  }
);

const getCurrentUser = createSelector(
  [getUsers, getCurrentUserId],
  (users, userId) => {
    return users[userId];
  }
);

const getClinicsWithRole = createSelector(
  [getUser, getRoles],
  (user, roleNames = []) => {
    const { clinics = [], roles = [] } = user || {};
    return roles.reduce((acc, role) => {
      if (role.resource_type === 'Clinic' && roleNames.includes(role.name)) {
        const clinic = clinics.find(clinic => String(clinic.id) === String(role.resource_id));
        return clinic ? [...acc, clinic] : acc;
      }

      return acc;
    }, []);
  }
);

const getAvailableBookingLinkClinics = createSelector(
  [getUser, getClinicsWithRole],
  (user = {}, clinics = []) => {
    const { booking_links = [] } = user || {};
    if (!booking_links.length) return clinics;

    return clinics.reduce((acc, clinic) => {
      const link = booking_links.find(link => String(link.clinic_id) === String(clinic.id));
      if (link) return acc;
      return [...acc, clinic];
    }, []);
  }
);

const getUserBookingLinks = createSelector(
  [getUser],
  (user = {}) => {
    const { booking_links = [], clinics = [] } = user;
    
    return booking_links.reduce((acc, link) => {
      const clinic = clinics.find(clinic => String(link.clinic_id) === String(clinic.id));

      return clinic ? [...acc, {
        id: link.id,
        clinic,
        url: link.url
      }] : acc;
    }, []);
  }
);

export default {
  getUser,
  getCurrentUser,
  getUserBookingLinks,
  getClinicsWithRole,
  getAvailableBookingLinkClinics
};
