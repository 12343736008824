import React, { useCallback } from 'react';
import { RecoveryStageSelectors } from '../../../recovery-stages';

const RecoveryStages = ({ 
  stages = {},
  className,
  submitted,
  canDischarge,
  previousStages = {},
  onUpdate = () => {}
}) => {
  const {
    rts_stage,
    rtl_stage,
    rtw_stage,
    id
  } = stages || {};

  const handleChange = useCallback((key, value) => {
    const attributes = {
      rts_stage,
      rtl_stage,
      rtw_stage,
      [key]: value
    };

    if (id) {
      attributes.id = id;
    }

    onUpdate(
      attributes,
      (attributes.rts_stage !== '' && attributes.rtl_stage !== '' && attributes.rtw_stage !== '')
    );
  }, [id, onUpdate, rtl_stage, rts_stage, rtw_stage]);

  return (
    <div className={className}>
      <RecoveryStageSelectors 
        rtsStage={rts_stage}
        rtlStage={rtl_stage}
        rtwStage={rtw_stage}
        submitted={submitted}
        canDischarge={canDischarge}
        previousStages={previousStages}
        onRtsStageChange={(value) => handleChange('rts_stage', value)}
        onRtlStageChange={(value) => handleChange('rtl_stage', value)}
        onRtwStageChange={(value) => handleChange('rtw_stage', value)}
      />
    </div>
  );
};

export default RecoveryStages;
