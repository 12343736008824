import React from 'react';
import PropTypes from 'prop-types';

const ClinicianPhotoCard = ({
  user = {}
}) => {
  const person = user.person || {};
  return (
    <div className="card card-show">

      <div className="card-row image-row">
        <img src={person.photo_url} alt="clinic" className="img-responsive" />
      </div>

    </div>
  );
};

ClinicianPhotoCard.propTypes = {
  user: PropTypes.object.isRequired
};

export default ClinicianPhotoCard;
