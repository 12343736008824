import React from 'react';
import { SelectInputGroup } from '@/forms';
import Strings from './lang';

const WESSTest = ({
  maxErrors,
  value,
  onUpdate = () => { },
  required = false,
  testLabel,
  touched
}) => {
  return (
    <div className="baseline-card-row">
      <label className="baseline-row-label">{testLabel}</label>
      <SelectInputGroup
        className="form-group"
        labelText={Strings.eyesOpenErrorsLabel}
        inputProps={{
          className: 'form-control',
          value: value.eyes_open
        }}
        required={required}
        valid={value.eyes_open !== ''}
        messageClassName="alert alert-danger"
        touched={touched}
        onUpdate={(newValue) => {
          const eyesOpen = parseInt(newValue, 10);
          onUpdate({ ...value, eyes_open: !isNaN(eyesOpen) ? eyesOpen : '' });
        }}
      >
        <option value="">{required ? Strings.defaultSelect : Strings.notDoneSelect}</option>
        {[...new Array(maxErrors + 1)].map((_, index) => (
          <option key={index} value={index}>{index}</option>
        ))}
      </SelectInputGroup>
      <SelectInputGroup
        className="form-group"
        labelText={Strings.eyesClosedErrorsLabel}
        inputProps={{
          className: 'form-control',
          value: value.eyes_closed
        }}
        required={required}
        valid={value.eyes_closed !== ''}
        messageClassName="alert alert-danger"
        touched={touched}
        onUpdate={(newValue) => {
          const eyesClosed = parseInt(newValue, 10);
          onUpdate({ ...value, eyes_closed: !isNaN(eyesClosed) ? eyesClosed : '' });
        }}
      >
        <option value="">{required ? Strings.defaultSelect : Strings.notDoneSelect}</option>
        {[...new Array(maxErrors + 1)].map((_, index) => (
          <option key={index} value={index}>{index}</option>
        ))}
      </SelectInputGroup>
    </div>
  );
};

export default WESSTest;
