import React, { useMemo } from 'react';
import { STARTING_PRACTISE_NUMBER, getNumbers } from './utils';
import Strings from './lang';

const MAX_NUMBERS = 8;

const PracticeSection = ({
  step,
  isChild = false
}) => {
  const numbers = useMemo(() => getNumbers(STARTING_PRACTISE_NUMBER, step, MAX_NUMBERS), [step]);

  return (
    <div className="test-trials-container">
      <div className="trial-section-title">
        {Strings.practiceTitle}
      </div>
      <p>
        {Strings.promptStartText}&nbsp;
        <i className="primary-dark">&quot;{Strings.practicePrompt}&quot;</i>&nbsp;
        {Strings.practiceNote}
      </p>
      <p>
        {!isChild && (
          <><span>{Strings.practice7CountingTrouble}</span>&nbsp;</>
        )}
        <span>{Strings.practice3CountingTrouble}</span>
      </p>
      <table className="table practise-trial-table">
        <tbody>
          <tr>
            {numbers.map(n => (
              <td key={n}>{n}</td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default PracticeSection;
