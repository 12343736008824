import React from 'react';
import PropTypes from 'prop-types';
import AccessControl from '../access-control';
import { EditButton } from '../buttons';
import { MedicalInfo } from '../medical-info';
import Strings from './lang';

const ShowPlayerMedical = ({
  editorRoles,
  editing,
  onClickEditButton,
  user,
  medical,
  onEditError,
  onUpdate
}) => (
  <div className="profile player">

    <div className="card-container">
      <div className="card-container-header">

        <h2>{Strings.medicalHeadingText}</h2>

        <AccessControl roles={editorRoles}>
          <EditButton
            editing={editing}
            onClick={onClickEditButton}
          />
        </AccessControl>

      </div>
      <div className="card-container-body">

        <MedicalInfo
          formClassName="card card-form"
          user={user}
          medical={medical}
          show={!editing}
          onError={onEditError}
          onSubmit={onUpdate}
        />

      </div>
    </div>

  </div>
);

ShowPlayerMedical.propTypes = {
  editorRoles: PropTypes.arrayOf(PropTypes.object).isRequired,
  user: PropTypes.object.isRequired,
  editing: PropTypes.bool.isRequired,
  onClickEditButton: PropTypes.func.isRequired,
  onEditError: PropTypes.func,
  onUpdate: PropTypes.func
};

export default ShowPlayerMedical;
