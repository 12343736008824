const EN = {
  title: 'Buffalo Treadmill Test',
  saveButtonText: 'Save Buffalo Treadmill Test',
  symptomsLabel: 'Symptoms',
  bpmPlaceHolderText: 'bpm',
  timeLabel: 'Time (minutes)',
  heartRateRowLabel: 'Heart Rate (bpm)',
  inclineLabel: 'Incline (%)',
  heartRateLabel: 'Heart rate at which symptoms exacerbated (bpm)',
  restingHeartRateLabel: 'Resting Heart Rate',
  symptomExacerbationLabel: 'Symptom Exacerbation?',
  symptomExacerbationHeading: 'Symptom Exacerbation',
  yesLabel: 'Yes',
  noLabel: 'No',
  coolDownText: 'Cool Down',
  stopTestText: 'Stop Test',
  buffaloInfoText: '"This is a physical exertion protocol using a treadmill designed to measure your physiologic recovery status and your ability to safely return to non-contact exercise. This station will have you walk on a treadmill at 3.3mph. After each minute, the treadmill incline will increase by 1% and you will be asked if you are experiencing any increase in your symptoms and what your physical exertion rating is (Borg Scale 6-20). You will be wearing a heart rate monitor and will be asked each minute for your heart rate. The test will be stopped if you experience an exacerbation of your symptoms (i.e., if you feel any headaches, dizziness, blurred vision, neck pain or any other symptoms of your injury you are to let us know). If you are a chronic patient (beyond 14 days) you require a worsening of your overall condition by 3 or more points in order to fail the test.  You will start out with a brief 2 minute warm-up to familiarize yourself with the treadmill. Then the test will begin and will take approximately 15-20 minutes followed by a 4 minute cool down. Do you have any questions?"',
  buffaloV2InfoText: '"This is a physical exertion protocol using a treadmill. This station will have you walk on a treadmill at 3.3mph. After each minute, the treadmill incline will increase by 1%. You will be wearing a heart rate monitor and will be asked each minute for your heart rate, rate of perceived exertion, as well as your overall condition score (0-10 scale). The test will be stopped at symptom exacerbation ie. If you feel any headaches, dizziness, blurred vision, neck pain or any other symptoms that cause your overall well-being score to increase by 3 or more points.  You may ask to stop the test at any time due to physical exhaustion or feeling very unsteady. You will also be asked each minute if you are experiencing any change in your health condition. You will start out with a brief 2 minute warm-up to familiarize yourself with the treadmill. Then the test will begin and will take approximately 25 minutes followed by a 4-minute cool down. Do you have any questions?"',
  warmUpText: 'Warm-up - 2.5mph for 2 minutes',
  startTestText: 'Test start - 3.3mph @ 0% incline',
  symptomExacerbationErrorMessage: 'Please select if symptoms were exacerbated',
  exacerbationHRErrorMessage: 'Please enter the heart rate at exacerbation',
  exacerbationTimeErrorMessage: 'Please enter the time of exacerbation (between {0} and {1})',
  exacerbationSymptomsErrorMessage: 'Please enter the symptoms at exacerbation',
  coolDownInfoText: 'Cool Down - 0% incline, 2.5 mph for 4 mins',
  invalidHeartRateMessage: 'Please enter a valid bpm (between {0} and {1})',
  overallNotesLabel: 'Overall Notes',
  monitorPatientInfoText: 'Monitor subject for at least 15 mins following cool down or until their heart rate has returned to their pre-test resting level',
  restingInputInfoText: 'Have the patient sit quietly for at least 2 minutes before taking your pre-test reading',
  restingBorgScaleHeaderLabel: 'Resting RPE',
  restingBorgScaleInputLabel: 'Resting RPE (20pt Borg Scale)',
  restingSymptomWellBeingLabel: 'Resting Overall Well-Being Score',
  speedLabel: 'Speed',
  borgScaleLabel: 'RPE (20 pt Borg Scale)',
  borgScaleInfoText: 'Stop at 18-20',
  symptomScaleLabel: 'Overall Well-Being Score (0-10 scale)',
  symptomsNotesLabel: 'Notes/Specific Symptoms',
  buffaloTableNewRowButtonText: 'Add New Row',
  warmUpRowText: 'Warm Up (2 mins)',
  borgScaleLevel6Label: 'No Exertion At All',
  borgScaleLevel7Label: 'Extremely Light',
  borgScaleLevel9Label: 'Very Light',
  borgScaleLevel11Label: 'Light',
  borgScaleLevel13Label: 'Somewhat Hard',
  borgScaleLevel15Label: 'Hard (heavy)',
  borgScaleLevel17Label: 'Very Hard',
  borgScaleLevel19Label: 'Extremely Hard',
  borgScaleLevel20Label: 'Maximal Exersion'
};

export default EN;
