import React from 'react';
import {
  ResponsiveContainer,
  LineChart,
  Line,
  Dot,
  Tooltip
} from 'recharts';
import { TEST_COLORS } from '../helpers';
import Strings from '../lang';
import Icon from '../../icon';

const CHART_HEIGHT = 80;
const ICON_SIZE = 12;

const Responses = {
  Correct: 'CORRECT',
  Incorrect: 'INCORRECT',
  Lapsed: 'LAPSED',
  Fast: 'FAST'
};

const ResponseStrings = {
  [Responses.Correct]: Strings.correctLabel,
  [Responses.Incorrect]: Strings.incorrectLabel,
  [Responses.Lapsed]: Strings.lapsedLabel,
  [Responses.Fast]: Strings.fastLabel
};

const TooltipContent = ({ 
  name, 
  malingering, 
  response, 
  color, 
  response_time 
}) => {
  return (
    <div>
      <div>
        {malingering && (
          <span className="label label-info">
            {Strings.malingeringLabel}
          </span>
        )}
        {response && response !== Responses.Correct && ResponseStrings[response] && (
          <span className="label label-danger">
            {ResponseStrings[response]}
          </span>
        )}
      </div>
      <div style={{ color }}>
        {name}: {response_time?.toFixed(2)}
      </div>
    </div>
  );
};

const TrialTooltip = ({
  payload: payloads,
  label
}) => {
  return (
    <div className="dana-test-chart-tooltip">
      <div className="bold">
        {Strings.formatString(Strings.trialLabel, label + 1)}
      </div>
      {payloads.map(({ name, color, payload }, index) => (
        <TooltipContent 
          key={index}
          name={name}
          color={color}
          {...payload}
        />
      ))}
    </div>
  );
};

const TrialDot = (props) => {
  const { payload, fill, stroke } = props;
  const { malingering, response } = payload || {};
  let fillColor = fill;

  if (malingering) {
    fillColor = stroke;
  }

  if (response && response !== Responses.Correct) {
    return (
      <Icon
        name="xmark"
        x={props.cx - (ICON_SIZE / 2)}
        y={props.cy - (ICON_SIZE / 2)}
        height={ICON_SIZE}
        width={ICON_SIZE}
        color={stroke}
        style={{ zIndex: 10 }}
      />
    );
  }

  return <Dot {...props} r={2.5} fill={fillColor} />;
};

const TrialsChart = ({ type, trials, iteration = 1 }) => {
  const lineColor = TEST_COLORS[type]?.[(iteration || 1) - 1];

  return (
    <ResponsiveContainer width="100%" height={CHART_HEIGHT}>
      <LineChart data={trials}>
        <Line
          type="monotone"
          dataKey="response_time"
          stroke={lineColor}
          strokeWidth={1}
          name={Strings.responseTimeLabel}
          dot={<TrialDot />}
          activeDot={{
            r: 4,
            stroke: lineColor
          }}
        />
        <Tooltip content={<TrialTooltip />} />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default TrialsChart;
