import React, { forwardRef } from 'react';
import { GripStrengthCard } from '../baseline-test-cards';

const GripStrengthStep = forwardRef((
  {
    baseline = {},
    onSubmit,
    ...rest
  },
  ref
) => {
  return (
    <GripStrengthCard
      {...rest}
      ref={ref}
      gripStrength={{ ...(baseline.grip_strength_test || {}) }}
      onSubmit={onSubmit}
    />
  );
});

GripStrengthStep.hasChanged = (initial, current) => {
  const left = current.left_hand || [];
  const right = current.right_hand || [];

  const leftDiff = left.filter(score => {
    if (!initial.left_hand && score === '') {
      return false;
    }

    return (initial.left_hand || []).indexOf(score) < 0;
  });

  const rightDiff = right.filter(score => {
    if (!initial.right_hand && score === '') {
      return false;
    }

    return (initial.right_hand || []).indexOf(score) < 0;
  });

  return leftDiff.length > 0
    || rightDiff.length > 0
    || (initial.notes || '') !== current.notes;
};

export default GripStrengthStep;
