import SymptomKeys from './keys';
import Strings from './lang';

export const SymptomCategories = {
  Autonomic: 'autonomic',
  Inflammatory: 'inflammatory',
  Visual: 'visual',
  Neck: 'neck',
  Psych: 'psych'
};

export const SymptomCategoryStrings = (key) => ({
  [SymptomCategories.Autonomic]: Strings.autonomicCategory,
  [SymptomCategories.Inflammatory]: Strings.inflammatoryCategory,
  [SymptomCategories.Visual]: Strings.visualCategory,
  [SymptomCategories.Neck]: Strings.neckCategory,
  [SymptomCategories.Psych]: Strings.psychCategory
}[key]);

export const SymptomCategorySymptoms = {
  [SymptomCategories.Autonomic]: {
    [SymptomKeys.Headache]: 3,
    [SymptomKeys.HeadPressure]: 2,
    [SymptomKeys.Nausea]: 4,
    [SymptomKeys.Dizziness]: 4,
    [SymptomKeys.BlurredVision]: 2,
    [SymptomKeys.BalanceProblems]: 3,
    [SymptomKeys.LightSensitivity]: 5,
    [SymptomKeys.NoiseSensitivity]: 5,
    [SymptomKeys.FeelingInAFog]: 3,
    [SymptomKeys.DontFeelRight]: 3,
    [SymptomKeys.DifficultyConcentrating]: 4,
    [SymptomKeys.DifficultyRemembering]: 3,
    [SymptomKeys.Fatigue]: 5,
    [SymptomKeys.Confusion]: 3,
    [SymptomKeys.Drowsiness]: 5,
    [SymptomKeys.TroubleFallingAsleep]: 4,
    [SymptomKeys.MoreEmotional]: 1,
    [SymptomKeys.Irritability]: 3,
    [SymptomKeys.Sadness]: 1,
    [SymptomKeys.Nervous]: 4
  },
  [SymptomCategories.Inflammatory]: {
    [SymptomKeys.Headache]: 4,
    [SymptomKeys.HeadPressure]: 3,
    [SymptomKeys.NeckPain]: 4,
    [SymptomKeys.Nausea]: 5,
    [SymptomKeys.LightSensitivity]: 3,
    [SymptomKeys.NoiseSensitivity]: 3,
    [SymptomKeys.FeelingSlowedDown]: 4,
    [SymptomKeys.FeelingInAFog]: 5,
    [SymptomKeys.DontFeelRight]: 2,
    [SymptomKeys.DifficultyConcentrating]: 5,
    [SymptomKeys.DifficultyRemembering]: 5,
    [SymptomKeys.Fatigue]: 5,
    [SymptomKeys.Drowsiness]: 5,
    [SymptomKeys.TroubleFallingAsleep]: 2,
    [SymptomKeys.MoreEmotional]: 3,
    [SymptomKeys.Irritability]: 1,
    [SymptomKeys.Sadness]: 2,
    [SymptomKeys.Nervous]: 3
  },
  [SymptomCategories.Visual]: {
    [SymptomKeys.Nausea]: 4,
    [SymptomKeys.Dizziness]: 5,
    [SymptomKeys.BlurredVision]: 5,
    [SymptomKeys.BalanceProblems]: 5,
    [SymptomKeys.LightSensitivity]: 4,
    [SymptomKeys.DontFeelRight]: 4
  },
  [SymptomCategories.Neck]: {
    [SymptomKeys.Headache]: 5,
    [SymptomKeys.HeadPressure]: 5,
    [SymptomKeys.NeckPain]: 5,
    [SymptomKeys.Nausea]: 3,
    [SymptomKeys.Dizziness]: 3,
    [SymptomKeys.BlurredVision]: 2,
    [SymptomKeys.BalanceProblems]: 3,
    [SymptomKeys.DontFeelRight]: 4,
    [SymptomKeys.Irritability]: 2,
    [SymptomKeys.Nervous]: 2
  },
  [SymptomCategories.Psych]: {
    [SymptomKeys.Nausea]: 3,
    [SymptomKeys.Dizziness]: 2,
    [SymptomKeys.LightSensitivity]: 3,
    [SymptomKeys.NoiseSensitivity]: 3,
    [SymptomKeys.FeelingSlowedDown]: 3,
    [SymptomKeys.FeelingInAFog]: 2,
    [SymptomKeys.DontFeelRight]: 3,
    [SymptomKeys.DifficultyConcentrating]: 4,
    [SymptomKeys.DifficultyRemembering]: 4,
    [SymptomKeys.Fatigue]: 5,
    [SymptomKeys.Confusion]: 3,
    [SymptomKeys.Drowsiness]: 1,
    [SymptomKeys.TroubleFallingAsleep]: 4,
    [SymptomKeys.MoreEmotional]: 5,
    [SymptomKeys.Irritability]: 5,
    [SymptomKeys.Sadness]: 5,
    [SymptomKeys.Nervous]: 5
  }
};
