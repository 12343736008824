import { jsonApiRead } from '@/lib/requests/jsonapi';
import { updateClinicBalance } from '../actions/clinic-balance';

const getClinicBalanceAsync = (clinicId) => {
  const options = { path: `clinics/${clinicId}/balances` };
  return (dispatch) => {
    return jsonApiRead({ type: 'balances' }, options).then(results => {
      dispatch(updateClinicBalance(clinicId, results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

export {
  getClinicBalanceAsync
};
