import React, { useCallback, useMemo, useState } from 'react';
import { If, Then } from 'react-if';
import { useDispatch } from 'react-redux';
import { registrationUpdateUserAsync } from '@/redux/thunks/users';
import { ErrorBanner } from '../../errors';
import Activity from '../../activity';
import RegistrationPhysicianForm from './registration-physician-form';
import { withRegistrationStep } from '../step-wrappers';
import Strings from './lang';

const addIdsToNestedAttributes = (attributes, physician) => {
  const new_attributes = { ...attributes };
  if ((physician || {}).id) {
    new_attributes.physician_contact_attributes.id = physician.id;

    if ((physician.address || {}).id) {
      new_attributes.physician_contact_attributes.address_attributes.id = physician.address.id;
    }
  }

  return new_attributes;
};

const RegistrationPhysician = ({
  currentUser,
  next,
  role,
  isReturn
}) => {
  const dispatch = useDispatch();
  const [activity, setActivity] = useState(false);
  const [error, setError] = useState(null);
  const subTitle = useMemo(() => {
    if (role === 'guardian') {
      return Strings.formatString(
        Strings.guardianSubtitle,
        currentUser?.person?.first_name ?? ''
      );
    }

    if (isReturn) {
      return Strings.returningSubtitle;
    }

    return null;
  }, [currentUser?.person?.first_name, isReturn, role]);

  const hasPhysician = !!currentUser.physician_contact;

  const updateUser = useCallback((attributes) => {
    setActivity(true);
    setError(null);

    const { id, physician_contact } = currentUser;
    let new_attributes = { ...attributes };
    if (physician_contact) {
      new_attributes = addIdsToNestedAttributes(attributes, physician_contact);
    }

    dispatch(registrationUpdateUserAsync(id, new_attributes)).then(() => {
      setActivity(false);
      next();
    }).catch(error => {
      setActivity(false);
      setError(error.message);
    });
  }, [currentUser, dispatch, next]);

  return (
    <div className="registration-physician">
      <h1 className="display dot text-center">{Strings.title}</h1>
      {subTitle && (
        <h2 className="text-center">{subTitle}</h2>
      )}
      <If condition={!hasPhysician}>
        <Then>
          <div className="alert-highlight-warning">
            <span>
              {role === 'guardian' ? Strings.formatString(
                Strings.guardianNoPhysicianText,
                currentUser?.person?.first_name ?? ''
              ) : Strings.noPhysicianText}&nbsp;
              <button
                type="button"
                className="edit-button"
                onClick={next}
              >
                {Strings.skipStepText} &gt;&gt;
              </button>
            </span>
          </div>
        </Then>
      </If>
      <ErrorBanner error={error} />
      <Activity active={activity}>
        <RegistrationPhysicianForm
          physician={currentUser.physician_contact || {}}
          onSubmit={updateUser}
        />
      </Activity>
    </div>
  );
};

export default withRegistrationStep('physician')(RegistrationPhysician);
