const EN = {
  addBookingLinkButtonText: 'Add Booking Link',
  editActionText: 'Edit',
  deleteActionText: 'Delete',
  clinicInputText: 'Clinic',
  clinicInputErrorText: 'Please select a clinic',
  selectClinicOption: 'Select Clinic',
  urlInputLabel: 'Booking Link URL',
  cancelButtonText: 'Cancel',
  submitButtonText: 'Submit',
  deleteButtonText: 'Delete',
  addBookingLinkModalTitle: 'Add Booking Link',
  editBookingLinkModalTitle: 'Edit Booking Link',
  bookingLinkModalDescription: 'Booking link url should be a direct link to your personal online booking page for the selected clinic.',
  emptyViewMessage: 'No booking links',
  deleteBookingLinkModalTitle: 'Delete Booking Link?',
  deleteBookingLinkConfirmMessage: 'Are you sure you want to delete this booking link?'
};

export default EN;
