const EN = {
  title: 'Set Your Password',
  passwordLabelText: 'Password',
  passwordInputPlaceholder: 'password123',
  confirmPasswordLabelText: 'Confirm Password',
  confirmPasswordInputPlaceholder: 'password123',
  submitButtonText: 'Accept'
};

export default EN;
