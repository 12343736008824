import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState
} from 'react';
import { If, Then } from 'react-if';
import { BalanceTesting } from '../../test-components';
import { FormComponent } from '@/forms';
import { HelpModalLink } from '../../../help-modal';
import Strings from '../lang';

const getBESSState = (balance) => {
  const {
    dominant_foot = '',
    tandom_errors = '',
    feet_together_errors = '',
    single_leg_errors = '',
    foam_pad_errors = ''
  } = balance || {};

  return {
    dominant_foot,
    feet_together_errors,
    single_leg_errors,
    foam_pad_errors,
    tandom_errors,
  };
};

const BESSFormCard = forwardRef((
  {
    balance,
    saveButtonText,
    showSubmitButton = false,
    onSubmit
  },
  ref
) => {
  const [balanceState, setBalanceState] = useState(() => getBESSState(balance));
  const [submitted, setSubmitted] = useState(false);

  const isValid = useMemo(() => {
    const {
      dominant_foot, feet_together_errors, single_leg_errors, tandom_errors
    } = balanceState;

    return dominant_foot !== ''
      && feet_together_errors !== ''
      && single_leg_errors !== ''
      && tandom_errors !== '';
  }, [balanceState]);

  const onUpdate = useCallback(values => {
    setBalanceState(values);
  }, []);

  const getAttributes = useCallback((validate = true) => {
    if (validate) {
      setSubmitted(true);

      if (!isValid) {
        return false;
      }
    }

    return { ...balanceState };
  }, [balanceState, isValid]);

  const handleSubmit = useCallback(() => {
    if (typeof onSubmit === 'function') {
      setSubmitted(true);

      if (!isValid) {
        return;
      }

      onSubmit(getAttributes(false));
    }
  }, [getAttributes, isValid, onSubmit]);

  useImperativeHandle(ref, () => ({
    getAttributes
  }), [getAttributes]);

  return (
    <FormComponent onSubmit={handleSubmit}>
      <div className="baseline-card">
        <div className="card-section">
          <p className="text-muted">{Strings.balanceTestingInfoText}</p>
        </div>
        <div className="card-section">
          <HelpModalLink
            className="edit-button"
            documentName="balance-testing-errors"
          >
            {Strings.balanceTestingErrorsInfoText}
          </HelpModalLink>
        </div>
        <BalanceTesting
          initialState={balanceState}
          onUpdate={onUpdate}
          touched={submitted}
        />
        <If condition={showSubmitButton}>
          <Then>
            <div className="card form-footer">
              <button type="submit" className="btn btn-primary">
                {saveButtonText || Strings.saveButtonText}
              </button>
            </div>
          </Then>
        </If>
      </div>
    </FormComponent>
  );
});

export default BESSFormCard;
