const FR = {
  photoHeadingText: 'Photo',
  activeClinicHeadingText: 'Clinique actuelle',
  personalHeadingText: 'Personnel',
  contactHeadingText: 'Communiquer',
  physicianHeadingText: 'Docteur',
  clinicsHeadingText: 'Cliniques',
  medicalHeadingText: 'Médical',
  profileTabLabel: 'Profil',
  sportsTabLabel: 'Sports',
  medicalInfoTabLabel: 'Informations médicales',
  baselineHistoryTabLabel: 'Informations de référence',
  injuryHistoryTabLabel: 'Antécédents de blessures',
  injuryReportsTabLabel: 'Rapports de blessure',
  updatePasswordTabLabel: 'Mettre à jour le mot de passe',
  assignBagTagButtonText: 'Attribuer la carte d’identité',
  patientNameRedactedText: 'Profil du patient',
  assignClinicModalTitle: 'Clinique présentement assignée',
  assignClinicModalMessage: 'Êtes-vous certain de vouloir assigner {0} en tant que clinique actuelle de {1} ?',
  assignClinicModalConfirmButtonTitle: 'Assigner',
  unassignedClinicMessageText: 'La clinique actuelle de {0} n’a pas encore été assignée.',
  unassignedClinicRedactedText: 'Ce patient',
  changeClinicLabel: 'Changer de clinique',
  newInjuryLinkText: 'Nouvelle blessure',
  activateUserButtonText: 'Activer le compte',
  activateUserModalTitle: 'Activer le compte',
  activateUserModalMessage: 'Êtes-vous certain de vouloir activer le compte de {0} ?',
  activateUserModalConfirmButtonTitle: 'Activer',
  deactivateUserButtonText: 'Désactiver le compte',
  deactivateUserModalTitle: 'Désactiver le compte',
  deactivateUserModalMessage: 'Êtes-vous certain de vouloir désactiver le compte de {0} ?',
  deactivateUserModalConfirmButtonTitle: 'Désactiver',
  modalNameRedactedText: 'ce patient',
  accountDeactivatedBannerMessage: 'Ce compte a été désactivé',
  noneText: 'Aucun',
  saveButtonText: 'Sauvegarder',
  patientInformationTitle: 'Informations sur le patient',
  radioOptionNo: 'Non',
  radioOptionYes: 'Oui',
  doesPatientHavePhysicianRadioLabel: 'Est-ce que ce patient a un médecin principal ?',
  removeUserButtonText: 'Enlever de la clinique',
  removeUserModalTitle: 'Enlever de la clinique',
  confirmRemoveUserMessage: 'Est-ce que vous êtes certain de vouloir enlever {0} en tant qu’utilisateur de cette clinique ?',
  removeUserSuccessMessage: '{0} a été enlevé de {1}',
  trainingExpiryLabelText: 'Expiration de la formation CCMI',
  trainingExpiredText: 'expiré {0}',
  trainingExpiringText: 'expire {0}',
  bookingTabLabel: 'Liens de réservation',
  clinicalInfoTitle: 'Professionnel',
};
 
export default FR;
