import React, { Component } from 'react';

class FileDropArea extends Component {
  constructor(props) {
    super(props);

    this._onDragOver = this._onDragOver.bind(this);
    this._onDrop = this._onDrop.bind(this);
    this._onDragOut = this._onDragOut.bind(this);
  }

  render() {
    return (
      <div
        className={this.props.className}
        onDragOver={this._onDragOver}
        onDrop={this._onDrop}
        onDragLeave={this._onDragOut}
      >
        {this.props.children}
      </div>
    );
  }

  _onDragOver(e) {
    const { onDragOver = (() => {}), disabled = false } = this.props;
    const dropEffect = disabled ? 'none' : 'copy';

    e.stopPropagation();
    e.preventDefault();
    e.dataTransfer.dropEffect = dropEffect;
    
    if (!disabled) {
      onDragOver(e);
    }
  }

  _onDragOut(e) {
    e.stopPropagation();
    e.preventDefault();
    const { onDragOut = (() => {}), disabled = false } = this.props;

    if (!disabled) {
      onDragOut(e);
    }
  }

  _onDrop(e) {
    e.stopPropagation();
    e.preventDefault();

    const { onFilesDrop = (() => {}), disabled = false } = this.props;
    const { files } = e.dataTransfer;

    if (!disabled) {
      onFilesDrop(files);
    }
  }
}

export default FileDropArea;
