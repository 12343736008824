import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import Icon from '../../icon';
import { showAlert } from '../../alert-notifications';
import { startIdtInitialReportAsync, startIdtBlockReportAsync } from '@/redux/thunks/idt-claims';
import ReportEditModal from './report-edit-modal';
import { confirmation } from '../../confirmation';
import ReportViewModal from './report-view-modal';
import Strings from '../lang';

const ReportStartButton = ({
  report,
  onStart
}) => {
  const dispatch = useDispatch();
  const [starting, setStarting] = useState(false);

  const handleStart = useCallback(() => {
    setStarting(true);
    const { claim_id, block_id } = report;

    dispatch(
      report.type === 'initial'
        ? startIdtInitialReportAsync(claim_id)
        : startIdtBlockReportAsync(claim_id, block_id)
    ).then((report) => {
      setStarting(false);
      onStart(report.id);
    }).catch(error => {
      setStarting(false);
      showAlert('error', {
        dismissable: true,
        autoDismiss: 8000,
        message: Strings.formatString(Strings.startReportErrorMessage, error.message)
      });
    });
  }, [dispatch, onStart, report]);

  const onBeforeStart = useCallback(() => {
    if (report.show_alert) {
      confirmation(Strings.startReportConfirmationMessage, {
        title: Strings.startReportConfirmationTitle,
        confirmButtonTitle: Strings.confirmButtonLabel,
        onConfirm: handleStart
      });
    } else {
      handleStart();
    }
  }, [handleStart, report.show_alert]);

  return (
    <button type="button" disabled={starting || !report.can_create} className="edit-button" onClick={onBeforeStart}>
      {starting && <Icon spinPulse prefix="fad" name="loader" />}
      {Strings.startReportButton}
    </button>
  );
};

const ContinueReportButton = ({ onClick }) => {
  return (
    <button type="button" className="edit-button" onClick={onClick}>
      {Strings.continueReportButton}
    </button>
  );
};

const MODAL_OPEN_DELAY = 200;

const ReportEditableButton = ({ report }) => {
  const [editModalOpen, setEditModalOpen] = useState(false);

  const openModal = useCallback(() => {
    setTimeout(() => {
      setEditModalOpen(true);
    }, MODAL_OPEN_DELAY);
  }, []);

  return (
    <>
      {report.status === 'not_started'
        ? <ReportStartButton report={report} onStart={openModal} />
        : <ContinueReportButton onClick={openModal} />}
      <ReportEditModal
        isOpen={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        reportId={report.report_id}
        claimId={report.claim_id}
        blockId={report.block_id}
      />
    </>
  );
};

const ViewReportButton = ({ report }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <button type="button" className="edit-button" onClick={() => setOpen(true)}>
        {Strings.viewReportButton}
      </button>
      <ReportViewModal
        isOpen={open}
        reportId={report.report_id}
        onClose={() => setOpen(false)}
      />
    </>
  );
};

const ReportActionButton = ({
  report
}) => {
  if (report.status === 'not_started' || report.status === 'in_progress') {
    return (
      <ReportEditableButton report={report} />
    );
  }

  return (
    <ViewReportButton report={report} />
  );
};

export default ReportActionButton;
