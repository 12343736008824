import { UPDATE_PROGRESS_REPORTS } from '../actions/progress-reports';

const updateProgressReportsReducer = (state = {}, action = {}) => {
  const { userId, injuryId, progressReportsList = {} } = action;
  const userState = state[userId] || {};
  const nextUserState = (injuryId) ? { ...userState, [injuryId]: progressReportsList } : userState;
  return (userId) ? { ...state, [userId]: nextUserState } : state;
};

const progressReports = (state = {}, action = {}) => {
  switch (action.type) {
    case UPDATE_PROGRESS_REPORTS:
      return updateProgressReportsReducer(state, action);
    default:
      return state;
  }
};

export default progressReports;
