import React from 'react';
import { RecoveryStagesView } from '../../recovery-stages';
import Strings from './lang';

const PostInjuryReturn = ({
  stages = {}
}) => {
  return (
    <div className="post-injury-section post-injury-return">
      <h2 className="bold">{Strings.recoveryStagesTitle}</h2>
      <RecoveryStagesView stages={stages} />
    </div>
  );
};

export default PostInjuryReturn;
