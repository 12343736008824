import {
  jsonApiCreate,
  jsonApiRead,
  jsonApiUpdate,
  multipartFileUpload
} from '@/lib/requests/jsonapi';
import {
  updateIdtClaim,
  addIdtClaimAppointment,
  updateIdtClaimAppointment,
  addIdtInitialReport,
  addIdtBlockReport,
  setClaimReportApproved,
  addClaimDocument
} from '../actions/idt-claims';

const getIdtClaimAsync = (claimId) => {
  const options = { path: `idt/claims/${claimId}` };
  return (dispatch) => {
    return jsonApiRead({ type: 'idt_claims' }, options).then(result => {
      dispatch(updateIdtClaim(result.id, result));
      return Promise.resolve(result);
    });
  };
};

const activateIdtClaimAsync = (claimId, attributes) => {
  const options = { path: `idt/claims/${claimId}/activate` };
  return (dispatch) => {
    return jsonApiUpdate({ type: 'idt_claims', attributes }, options).then(result => {
      dispatch(updateIdtClaim(result.id, result));
      return Promise.resolve(result);
    });
  };
};

const createIdtClaimAppointmentAsync = (claimId, blockId, attributes) => {
  const options = { path: `idt/claims/${claimId}/blocks/${blockId}/appointments` };
  return (dispatch) => {
    return jsonApiCreate({ type: 'idt_appointments', attributes }, options).then(result => {
      dispatch(addIdtClaimAppointment(claimId, blockId, result));
      return Promise.resolve(result);
    });
  };
};

const updateIdtClaimAppointmentAsync = (claimId, blockId, apptId, attributes) => {
  const options = { path: `idt/claims/${claimId}/blocks/${blockId}/appointments/${apptId}` };
  return (dispatch) => {
    return jsonApiUpdate({ type: 'idt_appointments', attributes }, options).then(result => {
      dispatch(updateIdtClaimAppointment(claimId, blockId, result));
      return Promise.resolve(result);
    });
  };
};

const startIdtInitialReportAsync = (claimId) => {
  const options = { path: `/idt/claims/${claimId}/report` };
  return (dispatch) => {
    return jsonApiCreate({ type: 'idt_reports' }, options).then(result => {
      dispatch(addIdtInitialReport(claimId, result));
      return Promise.resolve(result);
    });
  };
};

const startIdtBlockReportAsync = (claimId, blockId) => {
  const options = { path: `/idt/claims/${claimId}/blocks/${blockId}/report` };
  return (dispatch) => {
    return jsonApiCreate({ type: 'idt_reports' }, options).then(result => {
      dispatch(addIdtBlockReport(claimId, blockId, result));
      return Promise.resolve(result);
    });
  };
};

const approveFinalReportAsync = (reportId, claimId, blockId) => {
  const options = { path: `/idt/reports/${reportId}/approve` };
  return (dispatch) => {
    return jsonApiUpdate({ type: 'idt_reports' }, options).then(result => {
      dispatch(setClaimReportApproved(claimId, blockId));
      return Promise.resolve(result);
    });
  };
};

const uploadClaimDocumentAsync = (claimId, file) => {
  const formData = new FormData();
  formData.append('file', file);

  return (dispatch) => {
    return multipartFileUpload(`/idt/claims/${claimId}/documents`, formData).then(document => {
      dispatch(addClaimDocument(claimId, document));
      return Promise.resolve(document);
    });
  };
};

export {
  getIdtClaimAsync,
  activateIdtClaimAsync,
  createIdtClaimAppointmentAsync,
  updateIdtClaimAppointmentAsync,
  startIdtInitialReportAsync,
  startIdtBlockReportAsync,
  approveFinalReportAsync,
  uploadClaimDocumentAsync
};
