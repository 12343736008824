import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { If, Then, Else } from 'react-if';
import { DISPLAY_DATE_FORMAT } from '@/utilities/dates/formats';
import CompareSymptomsList from '../../baseline/user-baseline-detail/compare-symptoms-list';
import SymptomsList from '../../baseline/user-baseline-detail/symptoms-list';
import Strings from './lang';

const PostInjurySymptoms = ({
  baseline = {}, 
  currentSymptoms = {}
}) => (
  <div className="post-injury-section post-injury-symptoms">

    <div className="row">
      <div className="col-md-12">
        <h2 className="bold">{Strings.symptomsTitle} <small>{Strings.symptomsSubtitle}</small></h2>
      </div>
    </div>

    <If condition={!baseline.pre_existing_symptoms}>
      <Then>
        <div>
          <p className="alert alert-info">{Strings.noActiveBaseline}</p>
          <SymptomsList showTitle={false} symptoms={currentSymptoms} />
        </div>
      </Then>
      <Else>
        <CompareSymptomsList
          leftSymptoms={baseline.pre_existing_symptoms || {}}
          rightSymptoms={currentSymptoms}
          leftTitleElement={(
            <h3 className="bold">
              {Strings.baselineSymptomsTitleText}&nbsp;
              <small>
                ({dayjs(baseline.created_at).format(DISPLAY_DATE_FORMAT)})
              </small>
            </h3>
          )}
          rightTitleElement={(
            <h3 className="bold">
              {Strings.currentSymptomsTitleText}&nbsp;
              <small>
                {Strings.currentSymptomsSubtitleText}
              </small>
            </h3>
          )}
        />
      </Else>
    </If>
  </div>
);

PostInjurySymptoms.propTypes = {
  baseline: PropTypes.object.isRequired,
  currentSymptoms: PropTypes.object.isRequired
};

export default PostInjurySymptoms;
