const FR = {
  authLinkText: 'Connexion',
  registerLinkText: 'Enregistrer',
  registerConfirmLinkText: 'Renvoyer la confirmation',
  accountSettingsLinkText: 'Paramètres du compte',
  signOutLinkText: 'Déconnexion',
  homeLinkText: 'Accueil',
  languageLinkText: 'Langue',
  manageClinicLinkText: 'Ma clinique',
  addClinicLinkText: 'Ajouter une clinique',
  notificationsLinkText: 'Messages',
  supportLinkText: 'Support',
  impactCustomerCenterText: 'Connexion au centre client',
  marketplaceLinkText: 'Magasin',
  demoSiteButtonLabel: 'Connexions au site de démo',
  demoSiteUrlLabel: 'URL du site de démonstration',
  emailLabel: 'E-mail',
  passwordLabel: 'Mot de passe'
};
 
export default FR;
