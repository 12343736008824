const FR = {
  pageTitle: 'Test de référence',
  searchButtonText: 'Commencer le test de référence',
  newPatientsStartButtonText: 'Nouveau test de référence',
  testInProgressButtonText: 'Test en cours?',
  activeInjuryErrorText: 'Le patient sélectionné a actuellement une blessure. Si vous souhaitez faire un test « post-blessure », veuillez voir d\'abord la remarque de SOAP du patient. Vous ne pouvez pas commencer un test de référence pré-blessure avec un patient ayant actuellement une blessure.',
  checkInPatientText: 'Enregistrer le patient',
  checkInPatientTitle: 'Enregistrement du patient',
  baselineInProgressModalTitle: 'Continuer test de référence en cours',
  baselineInProgressModalMessage: 'Ce patient a actuellement un test de référence en cours à partir du {0}. Souhaitez-vous continuer leur test de référence ou commencer un nouveau test de référence.',
  startNewTestButtonText: 'Commencer un nouveau test',
  continueBaselineButtonText: 'Continuer le test'
};
 
export default FR;
