import { getStationaryBikeData } from './blackhawks-stationary-bike-data';
import { getPlyometricsData } from './blackhawks-plyometrics-data';
import Strings from './lang';

const BLACKHAWKS_STATIONARY_BIKE_KEYS = Object.keys(getStationaryBikeData());
const BLACKHAWKS_PLYOMETRICS_KEYS = Object.keys(getPlyometricsData());

const filterBlackhawksStageData = (data) => {
  return ['symptoms', 'heart_rate'].reduce((allData, key) => {
    return { ...allData, [key]: data[key] };
  }, {});
};

const getBlackhawksTestState = (props) => {
  const { blackhawks = {} } = props;
  const {
    duration = 0,
    full_test = false,
    bike_type = '',
    resting_heart_rate = '',
    test_data = {},
    max_tension_levels,
    cooldown_result
  } = blackhawks;

  const {
    full_plyometrics = {},
    stationary_bike_rest = {},
    fail_point = { symptoms: '', heart_rate: '', stage: '' },
    notes,
    max_tension_levels: tension_levels
  } = test_data;

  const tensionLevels = tension_levels || max_tension_levels;

  // Merge stationary bike data:
  const stationaryBikeData = getStationaryBikeData(full_test, bike_type, tensionLevels);
  const stationaryBikeMerged = BLACKHAWKS_STATIONARY_BIKE_KEYS.reduce((allData, key) => {
    const baseData = stationaryBikeData[key] || [];
    const restoredData = test_data[key] || [];
    return {
      ...allData,
      [key]: baseData.map((item, index) => {
        const restoredItem = restoredData[index] || {};
        return { ...item, ...restoredItem };
      })
    };
  }, {});

  // Merge plyometrics data:
  const plyometricsData = getPlyometricsData(full_test);
  const plyometricsMerged = BLACKHAWKS_PLYOMETRICS_KEYS.reduce((allData, key) => {
    const baseData = plyometricsData[key] || {};
    const restoredData = full_plyometrics[key] || {};
    return {
      ...allData,
      [key]: { ...baseData, ...restoredData }
    };
  }, {});

  // Convert cooldown result to a string:
  let cooldown = '';
  if (cooldown_result !== undefined) {
    cooldown = (cooldown_result) ? 'pass' : 'fail';
  }

  return {
    duration,
    full_test,
    bike_type,
    resting_heart_rate,
    max_tension_levels: tensionLevels,
    notes,
    ...stationaryBikeMerged,
    stationary_bike_rest: {
      heart_rate: '',
      symptoms: '',
      ...stationary_bike_rest
    },
    full_plyometrics: plyometricsMerged,
    cooldown_result: cooldown,
    failPoint: fail_point
  };
};

const getBlackhawksDataFromProps = (props) => {
  const {
    location = {},
    params = {},
    user = {},
    injuries = {},
    blackhawks = {}
  } = props;

  const { state = {} } = location;
  const { testType = '', bikeType = '' } = state;
  const { userId = 0, injuryId = 0, blackhawksId = 0 } = params;

  const userInjuries = injuries[userId] || {};
  const injury = userInjuries[injuryId] || {};
  const userTests = blackhawks[userId] || {};
  const test = userTests[blackhawksId] || {};

  return { 
    user, injury, test, testType, bikeType 
  };
};

const findFailPoint = (data) => {
  return filterPylometricSymptoms({ ...data.full_plyometrics }) 
    || filterStationaryStageSymptoms([{ ...data.stationary_bike_rest }], 'phase_1_stage_rest') 
    || filterStationaryStageSymptoms([...data.stationary_bike_stage_3], 'phase_1_stage_3') 
    || filterStationaryStageSymptoms([...data.stationary_bike_stage_2], 'phase_1_stage_2') 
    || filterStationaryStageSymptoms([...data.stationary_bike_stage_1], 'phase_1_stage_1') 
    || { stage: 'phase_1_stage_1', symptoms: '', heart_rate: '' };
};

const filterPylometricSymptoms = (plyo) => {
  const keysReversed = Object.keys(plyo || {}).reverse();

  for (let i = 0; i < keysReversed.length; i += 1) {
    const key = keysReversed[i];
    if (plyo[key].symptoms !== '' || plyo[key].heart_rate !== '') {
      return {
        stage: `phase_2_${key}`,
        symptoms: plyo[key].symptoms,
        heart_rate: plyo[key].heart_rate
      };
    }
  }

  return null;
};

const filterStationaryStageSymptoms = (stage, stageKey) => {
  const stages = Array.isArray(stage) ? stage : [stage];
  const found = stages.reverse().filter(item => item.symptoms !== '' || item.heart_rate !== '')[0];
  return (found) ? {
    stage: stageKey,
    symptoms: found.symptoms,
    heart_rate: found.heart_rate
  } : null;
};

const stageOptions = {
  stationary: {
    phase_1_stage_1: Strings.failStageOptionStationaryStage1,
    phase_1_stage_2: Strings.failStageOptionStationaryStage2,
    phase_1_stage_3: Strings.failStageOptionStationaryStage3,
    phase_1_stage_rest: Strings.failStageOptionStationaryStageRest
  },
  plyometrics: {
    phase_2_hurdle_hops: Strings.failStageOptionHurdleHops,
    phase_2_burpees: Strings.failStageOptionBurpees,
    phase_2_box_jumps: Strings.failStageOptionBoxJumps,
    phase_2_rotary_jumps: Strings.failStageOptionRotaryJumps
  }
};

export {
  BLACKHAWKS_STATIONARY_BIKE_KEYS,
  BLACKHAWKS_PLYOMETRICS_KEYS,
  filterBlackhawksStageData,
  getBlackhawksTestState,
  getBlackhawksDataFromProps,
  findFailPoint,
  stageOptions
};
