import React from 'react';
import { connect } from 'react-redux';
import LineItemsHeader from './line-items-header';
import LineItemsTableRow from './line-items-table-row';
import LineItemsFooter from './line-items-footer';
import { InvoiceSelector } from '@/redux/selectors';
import Strings from '../lang';

const LineItemsTable = ({
  lineItems = [],
  clinicId,
  subTotal = null,
  total = null,
  taxes = [],
  balance = null,
  payments = [],
  invoiceStatus,
  invoiceId,
  currency = 'CAD',
  sortKey,
  onUpdateSortKey = () => {},
  onDeleteItem
}) => {
  if (!lineItems.length) {
    return (
      <h2 className="text-center">
        {Strings.emptyLineItemsMessage}
      </h2>
    );
  }

  return (
    <table className="table line-items-table">
      <thead>
        <LineItemsHeader 
          sortKey={sortKey}
          onUpdateSortKey={onUpdateSortKey}
        />
      </thead>
      <tbody>
        {lineItems.map(item => (
          <LineItemsTableRow 
            key={item.id}
            clinicId={clinicId}
            invoiceId={invoiceId}
            invoiceStatus={invoiceStatus}
            currency={currency}
            lineItem={item}
            onDeleteItem={onDeleteItem}
          />
        ))}
        <LineItemsFooter 
          currency={currency}
          payments={payments}
          subTotal={subTotal}
          total={total}
          taxes={taxes}
          balance={balance}
        />
      </tbody>
    </table>
  );
};

const mapStateToProps = (state, ownProps) => {
  const lineItems = InvoiceSelector.getGroupedLineItems(state, ownProps);

  return { lineItems };
};

export default connect(
  mapStateToProps
)(LineItemsTable);
