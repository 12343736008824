const EN = {
  title: 'Continue Baseline Test',
  actionGroupLabel: 'Don’t see who you’re looking for?',
  refreshListButton: 'Refresh List',
  searchPatientsButton: 'Search Patients',
  tableHeader: 'Patients with baseline tests currently in progress:',
  tableNameLabel: 'Name',
  tableAccountLabel: 'Account No.',
  tableCurrentStepLabel: 'Current Step',
  tableStartedAtLabel: 'Started At',
  tableUpdatedAtLabel: 'Updated At',
  emptyResultsMessage: 'No patients found with a baseline test in progress.',
  continueBaselineModalInfoText: 'Please enter the concussion ID card number of the individual below to continue their baseline test progress.',
  continueBaselineModalTitleText: 'Continue Baseline Test',
  continueBaselineButtonText: 'Continue Test'
};
  
export default EN;
