import React, { useCallback } from 'react';
import Modal from '../../modal';
import Strings from '../lang';
import PartnerForm from '../partners/partner-form';

const CreatePartnerModal = ({
  isOpen = false,
  initialName = '',
  onClose,
  onCreate
}) => {
  const onSubmit = useCallback((attributes) => {
    onCreate(attributes);
    onClose();
  }, [onClose, onCreate]);

  return (
    <Modal
      className="partnership-modal"
      isOpen={isOpen}
      onClose={onClose}
    >
      <Modal.Header onClose={onClose}>
        <h2>{Strings.createPartnerModalTitle}</h2>
      </Modal.Header>
      <Modal.Body>
        <PartnerForm 
          initialState={{ name: initialName }}
          submitButtonText={Strings.addPartnershipLabel}
          onSubmit={onSubmit}
        />
      </Modal.Body>
    </Modal>
  );
};

export default CreatePartnerModal;
