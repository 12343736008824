const SportsKeys = [
  'hockey',
  'soccer',
  'football',
  'fieldlacrosse',
  'boxlacrosse',
  'rugby',
  'basketball',
  'baseball',
  'volleyball',
  'gymnastics',
  'cheerleading',
  'dancing',
  'mma',
  'boxing',
  'kickboxing',
  'cycling',
  'bmx',
  'motocross',
  'motorcycling',
  'skateboarding',
  'skiing',
  'figureskating',
  'surfing',
  'dodgeball',
  'curling',
  'ringette',
  'wrestling',
  'equestrian',
  'aussierules',
  'cricket',
  'fieldhockey'
];

export default SportsKeys;
