import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '../icon';

const ChecklistItem = ({
  checked = false, 
  children, 
  className
}) => (
  <li className={classNames('checklist-item', className, { checked })}>
    <Icon name={checked ? 'check' : 'xmark'} /> {children}
  </li>
);

ChecklistItem.propTypes = {
  checked: PropTypes.bool.isRequired
};

export default ChecklistItem;
