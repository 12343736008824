import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState
} from 'react';
import { If, Then } from 'react-if';
import { WESSTesting } from '../../test-components';
import { FormComponent } from '@/forms';
import { HelpModalLink } from '../../../help-modal';
import Strings from '../lang';

const getWESSState = (balance) => {
  const {
    seated_errors = { eyes_open: '', eyes_closed: '' },
    balance_disk_errors = { eyes_open: '', eyes_closed: '' },
    wheelie_errors = { eyes_open: '', eyes_closed: '' }
  } = balance || {};

  return {
    seated_errors,
    balance_disk_errors,
    wheelie_errors
  };
};

const WESSCardForm = forwardRef((
  {
    balance = {},
    showSubmitButton = false,
    saveButtonText,
    onSubmit
  },
  ref
) => {
  const [submitted, setSubmitted] = useState(false);
  const [balanceState, setBalanceState] = useState(() => getWESSState(balance), [balance]);

  const isValid = useMemo(() => {
    return Object.keys(balanceState).every(key => {
      return balanceState[key].eyes_open !== ''
        && balanceState[key].eyes_closed !== '';
    });
  }, [balanceState]);

  const getAttributes = useCallback((validate = true) => {
    if (validate) {
      setSubmitted(true);

      if (!isValid) {
        return false;
      }
    }

    return { ...balanceState };
  }, [balanceState, isValid]);

  const handleSubmit = useCallback(() => {
    if (typeof onSubmit === 'function') {
      setSubmitted(true);

      if (!isValid) {
        return;
      }

      onSubmit(getAttributes(false));
    }
  }, [getAttributes, isValid, onSubmit]);

  const onUpdate = useCallback((values) => {
    setBalanceState(values);
  }, []);

  useImperativeHandle(ref, () => ({
    getAttributes
  }), [getAttributes]);

  return (
    <FormComponent onSubmit={handleSubmit}>
      <div className="baseline-card">
        <div className="card-section">
          <p className="text-muted">
            {Strings.wessBalanceInfo}
          </p>
        </div>
        <div className="card-section">
          <HelpModalLink
            className="edit-button"
            documentName="wess-testing-errors"
          >
            {Strings.balanceTestingErrorsInfoText}
          </HelpModalLink>
        </div>
        <WESSTesting
          wess={balanceState}
          onUpdate={onUpdate}
          touched={submitted}
        />
        <If condition={showSubmitButton}>
          <Then>
            <div className="card form-footer">
              <button type="submit" className="btn btn-primary">
                {saveButtonText || Strings.saveButtonText}
              </button>
            </div>
          </Then>
        </If>
      </div>
    </FormComponent>
  );
});

export default WESSCardForm;
