const ACCELERATION = 9.81;
const M_TO_CM = 100;

const calculateReactionTime = (d, units = 'cm') => {
  let a;

  switch (units) {
    case 'm':
      a = ACCELERATION; 
      break;
    case 'cm': /* intentional fall through, default is cm */
    default:
      a = ACCELERATION * M_TO_CM; /* 9.81 m/s2 * 100 cm/m => 981 cm/s2 */
  }

  return Math.sqrt((2 * d) / a);
};

export default calculateReactionTime;
