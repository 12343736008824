import { createSelector } from 'reselect';

const getReferrals = (state) => state.referrals;
const getReferralId = (_, params) => params.id;
const getInjuryId = (_, params) => params.injuryId;
const getReferredToClinicId = (_, params) => params.referredToClinicId;

const getInjuryReferrals = createSelector(
  [getReferrals, getInjuryId],
  (referrals, injuryId) => {
    return Object.values(referrals).filter(referral => (
      String(referral.injury_id) === String(injuryId)
    ));
  }
);

const getReferredToClinicReferrals = createSelector(
  [getReferrals, getReferredToClinicId],
  (referrals, referredToClinicId) => {
    return Object.values(referrals).filter(referral => (
      String(referral.referred_to_clinic?.id) === String(referredToClinicId)
    ));
  }
);

const getOpenReferredToClinicReferrals = createSelector(
  [getReferredToClinicReferrals],
  (referrals) => {
    return referrals.filter(referral => (
      ['pending', 'accepted'].includes(referral.status)
    ));
  }
);

const getReferral = createSelector(
  [getReferrals, getReferralId],
  (referrals, id) => {
    return referrals[id];
  }
);

export default {
  getInjuryReferrals,
  getOpenReferredToClinicReferrals,
  getReferredToClinicReferrals,
  getReferral
};
