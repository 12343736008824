const FR = {
  title: 'Chefs d’équipe',
  activateUserModalTitle: 'Activer le compte',
  activateUserModalMessage: 'Êtes-vous certain de vouloir activer le compte de {0} ?',
  activateUserModalConfirmButtonTitle: 'Activer',
  deactivateUserModalTitle: 'Désactiver le compte',
  deactivateUserModalMessage: 'Êtes-vous certain de vouloir désactiver le compte de {0} ?',
  deactivateUserModalConfirmButtonTitle: 'Désactiver',
  accountDeactivatedBannerMessage: 'Ce compte a été désactivé',
  deactivateUserButtonText: 'Désactiver le compte',
  activateUserButtonText: 'Activer le compte',
  coachOrTrainerText: 'Coach/Trainer'
};
 
export default FR;
