import React from 'react';
import Strings from './lang';

const categoryToString = (type) => (({
  primary: Strings.primaryLabel,
  medical: Strings.medicalLabel,
  baseline: Strings.baselineLabel,
  specialist: Strings.specialistLabel
})[type]);

const ClinicTypeCardRow = ({ category }) => {
  return (
    <div className="card-row">
      <label>{Strings.clinicTypeLabel}:</label>
      <span>{categoryToString(category)}</span>
    </div>
  );
};

export default ClinicTypeCardRow;
