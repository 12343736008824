import React from 'react';
import { ReferenceLine } from 'recharts';
import Strings from '../lang';

const getReferenceLines = (baseline, showStddev = true) => {
  if (!baseline?.value) return null;

  let deviationLines = [];

  if (showStddev) {
    const stddev = baseline.stddev && parseFloat(baseline.stddev);
    const upper = baseline.value + stddev;
    const lower = baseline.value - stddev;
  
    deviationLines = stddev && stddev > 0 ? [
      <ReferenceLine
        key="stddev_upper"
        y={upper}
        stroke="#DB231F"
        strokeOpacity={0.3}
        strokeDasharray="20 3"
        label={{
          position: 'insideBottomLeft',
          fontSize: 8,
          value: Strings.formatString(
            Strings.baselineUpperStddevLabel,
            upper?.toFixed(1)
          )
        }}
      />,
      <ReferenceLine
        key="stddev_lower"
        y={lower}
        stroke="#DB231F"
        strokeOpacity={0.3}
        strokeDasharray="20 3"
        label={{
          position: 'insideBottomLeft',
          fontSize: 8,
          value: Strings.formatString(
            Strings.baselineLowerStddevLabel,
            lower?.toFixed(1)
          )
        }}
      />,
    ] : [];
  }

  return [
    <ReferenceLine
      key="mean"
      y={baseline.value}
      stroke="#0C1826"
      strokeOpacity={0.3}
      strokeDasharray="20 3"
      label={{
        position: 'insideBottomLeft',
        fontSize: 8,
        value: Strings.formatString(
          Strings.baselineLineLabel,
          baseline?.value?.toFixed(1)
        )
      }}
    />,
    ...deviationLines
  ];
};

export default getReferenceLines;
