import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { If, Then, Else } from 'react-if';
import RegistrationConfirmRequestForm from './registration-confirm-request-form';
import { Page } from '../../page';
import { ErrorBanner } from '../../errors';
import Activity from '../../activity';
import { AuthenticationPaths } from '@/paths';
import { jsonApiUpdate } from '@/lib/requests/jsonapi';
import Strings from './lang';

const RegistrationConfirmRequest = () => {
  const [requested, setRequested] = useState(false);
  const [activity, setActivity] = useState(false);
  const [error, setError] = useState(null);

  const requestConfirmation = useCallback((attributes) => {
    setActivity(true);
    setError(null);

    const resource = { type: 'users', attributes };

    jsonApiUpdate(resource, { path: 'users/resend_confirmation' }).then(() => {
      setRequested(true);
      setActivity(false);
    }).catch(error => {
      setActivity(false);
      setError(error.message);
    });
  }, []);

  return (
    <Page className="registration-confirm-request" hideNavigationContent>
      <div className="row">
        <div className="col-md-12">
          <h1 className="display">{Strings.title}</h1>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <ErrorBanner error={error} />
          <If condition={requested}>
            <Then>
              <div>
                <p className="alert alert-success">{Strings.resendRequestSuccessText}</p>
                <ul className="list-unstyled">
                  <li>
                    <Link
                      to={AuthenticationPaths.index.link}
                    >
                      {Strings.authenticationLinkText}
                    </Link>
                  </li>
                </ul>
              </div>
            </Then>
            <Else>
              <Activity active={activity}>
                <RegistrationConfirmRequestForm
                  onSubmit={requestConfirmation}
                />
              </Activity>
            </Else>
          </If>
        </div>
      </div>
    </Page>
  );
};

export default RegistrationConfirmRequest;
