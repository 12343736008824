import React, { useCallback, useState } from 'react';
import { FormComponent, SelectInputGroup, MultiSelectInputGroup } from '@/forms';
import { ProfessionStrings, servicesSelectOptions } from '@/utilities/clinical-info';
import Strings from './lang';

const EditClinicianClinicalInfoCardForm = ({ 
  user = {}, 
  submitButtonText,
  onSubmit = () => {} 
}) => {
  const clinicalInfo = user.clinical_info || {};
  
  const [profession, setProfession] = useState(() => clinicalInfo.profession || '');
  const [services, setServices] = useState(() => clinicalInfo.services || []);
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = useCallback(() => {
    setSubmitted(true);

    if (!profession) return;
    
    const attributes = {
      profession,
      services
    };

    if (clinicalInfo.id) {
      attributes.id = clinicalInfo.id;
    }

    onSubmit({ clinical_info_attributes: attributes });
  }, [clinicalInfo.id, onSubmit, profession, services]);

  return (
    <FormComponent
      autoComplete="off"
      className="card card-form"
      onSubmit={handleSubmit}
    >
      <SelectInputGroup
        className="form-group"
        labelText={Strings.professionLabelText}
        inputProps={{
          className: 'form-control',
          name: 'profession',
          value: profession
        }}
        required
        messageClassName="alert alert-danger"
        messageText={Strings.professionErrorMessage}
        valid={profession !== ''}
        touched={submitted}
        onUpdate={setProfession}
      >
        <option value="">-- {Strings.defaultProfessionPlaceholder} --</option>
        {Object.keys(ProfessionStrings).map(key => (
          <option key={key} value={key}>{ProfessionStrings[key]}</option>
        ))}
      </SelectInputGroup>

      <MultiSelectInputGroup 
        name="services"
        label={Strings.servicesLabelText}
        value={services}
        placeholder={Strings.servicesPlaceholder}
        options={servicesSelectOptions}
        touched={submitted}
        onChange={setServices}
      />

      <div className="form-footer">
        <button className="btn btn-primary" type="submit">
          {submitButtonText || Strings.submitButtonText}
        </button>
      </div>
    </FormComponent>
  );
};

export default EditClinicianClinicalInfoCardForm;
