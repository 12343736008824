import React, { Component } from 'react';
import { connect } from 'react-redux';
import { If, Then, Else } from 'react-if';
import dayjs from 'dayjs';
import { getUserAsync } from '@/redux/thunks/users';
import { getInjuryAsync } from '@/redux/thunks/injuries';
import { getInjuryAssessmentAsync, updateInjuryAssessmentAsync } from '@/redux/thunks/injury-assessments';
import { getActiveBaselineTestAsync, getBaselineBeforeDateAsync } from '@/redux/thunks/baseline-tests';
import { getCurrentClinicId } from '@/tokens';
import replaceLinkParams from '@/utilities/replace-link-params';
import { ClinicPaths } from '@/paths';
import { confirmation } from '../../confirmation';
import { showAlert } from '../../alert-notifications';
import Tabs from '../../tabs';
import { daysSince } from '@/utilities/dates';
import { Page } from '../../page';
import Activity from '../../activity';
import { ErrorBanner } from '../../errors';
import { 
  getQueryKey, 
  mapAttributeToSection, 
  requiredAcuteAssessmentKeys, 
  requiredChronicAssessmentKeys 
} from './utils';
import PostInjuryDiagnosis from '../display/post-injury-diagnosis';
import PostInjuryFollowUp from '../display/post-injury-follow-up';
import PostInjuryInitialManagement from '../display/post-injury-initial-management';
import PostInjuryReturn from '../display/post-injury-return';
import PostInjuryCommunication from '../display/post-injury-communication';
import { 
  InjuryAssessmentHeader,
  InjuryCharacteristicsForm,
  RedFlagsForm,
  AssessmentMedicalHistoryForm,
  CurrentSymptomsCard,
  DiagnosisPlanOfManagementForm,
  NeuroExamsForm,
  SpecialTests
} from '../sections';
import { PostInjuryAssessmentView } from '../display';
import { withRouter } from '@/utilities/router-utils';
import { RoleDescriptions, userHasRoleMatchingDescription } from '@/utilities/user-roles';
import Strings from './lang';
import { UserSelector } from '@/redux/selectors';

const SCROLL_OFFSET = 80;
const MAX_ACUTE_DAYS = 14;

const onSectionSaveSuccess = () => {
  showAlert('success', {
    dismissable: true,
    autoDismiss: 3000,
    message: Strings.sectionSavedMessage
  });
};

const onError = (message) => {
  showAlert('error', {
    dismissable: true,
    autoDismiss: 3000,
    message: message || Strings.standardSectionFormError
  });
};

const getAssessmentType = (injury, assessment) => {
  return daysSince(
    injury.injured_at || dayjs(),
    assessment.created_at
  ) > MAX_ACUTE_DAYS ? 'chronic' : 'acute';
};

class PostInjuryAssessment extends Component {
  constructor(props) {
    super(props);

    const { 
      injuries = {}, 
      injuryAssessments = {},
      users = {}, 
      params = {},
      clinicId = 0,
      currentUser = {}
    } = props;

    const { userId = 0, injuryId = 0, assessmentId = 0 } = params;
    const user = users[userId] || {};
    const userInjuries = injuries[userId] || {};
    const injury = userInjuries[injuryId] || {};
    const userAssessments = injuryAssessments[userId] || {};
    const assessment = userAssessments[assessmentId] || {};
    const currentClinic = (currentUser.clinics || []).find(clinic => clinic.id === clinicId) || {};

    this.routerWillLeave = this.routerWillLeave.bind(this);
    this.onTabClick = this.onTabClick.bind(this);
    this.loadAssessment = this.loadAssessment.bind(this);
    this.tabBar = React.createRef();

    this.state = {
      clinicId,
      user,
      currentUser,
      currentClinic,
      assessment,
      injury,
      baseline: {},
      loadActivity: true,
      activity: false,
      error: null,
      submitted: false,
      userClosed: false,
      incompleteAttributes: []
    };
  }

  componentDidMount() {
    const { params = {}, router = {} } = this.props;
    const { userId = 0, injuryId = 0, assessmentId = 0 } = params;

    if (userId && injuryId && assessmentId) {
      this.initialize(userId, injuryId, assessmentId);
    }

    router.setRouteLeaveHook(this.routerWillLeave);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.queryParams?.section !== this.props.queryParams?.section) {
      this.scrollToTabBar();
    }
  }

  render() {  
    const type = this.getAssessmentType();
    const { 
      assessment, 
      user, 
      injury, 
      currentUser, 
      currentClinic, 
      incompleteAttributes, 
      submitted, 
      baseline 
    } = this.state;
    const selectedKey = getQueryKey(this.props.queryParams, type);
   
    return (
      <Page>
        <h1 className="display">{Strings.assessmentTitle}</h1>
        <h2>
          {type === 'acute'
            ? Strings.acuteConcussionAssessmentTitle
            : Strings.chronicConcussionAssessmentTitle}
        </h2>

        <Activity active={this.state.loadActivity} static>
          <div>
            <InjuryAssessmentHeader
              user={user}
              injury={injury}
              assessment={assessment}
              tester={assessment.clinic_user}
              clinic={assessment.clinic}
              onSaveDate={(attributes) => this.onSubmitSection(null, true, attributes)}
            />

            <ErrorBanner error={this.state.error} />

            <Activity active={this.state.activity}>
              <Tabs
                ref={this.tabBar}
                className="injury-assessment-tabs"
                selectedKey={selectedKey}
                onSelectTab={this.onTabClick}
                items={[{
                  key: 'characteristics',
                  label: Strings.characteristicsTabLabel,
                  tabClassName: submitted && incompleteAttributes.includes('characteristics') ? 'alert error' : null,
                  component: (
                    <InjuryCharacteristicsForm
                      characteristics={assessment.characteristics}
                      submitted={submitted}
                      type={type}
                      onError={onError}
                      onSubmit={(attributes) => this.onSubmitSection('symptoms', false, attributes)}
                    />
                  )
                }, {
                  key: 'symptoms',
                  label: Strings.currentSymptomsTabLabel,
                  tabClassName: submitted && incompleteAttributes.includes('current_rated_symptoms') ? 'alert error' : null,
                  component: (
                    <CurrentSymptomsCard
                      user={user}
                      submitted={submitted}
                      baseline={baseline}
                      currentSymptoms={assessment.current_rated_symptoms}
                      onError={onError}
                      onSubmit={(attributes) => {
                        this.onSubmitSection(type === 'acute' ? 'red-flags' : 'medical-history', false, attributes);  
                      }}
                    />
                  )
                }, {
                  key: 'red-flags',
                  label: Strings.redFlagsTabLabel,
                  hide: type !== 'acute',
                  tabClassName: submitted && incompleteAttributes.includes('red_flags') ? 'alert error' : null,
                  component: (
                    <RedFlagsForm
                      submitted={submitted}
                      redFlags={assessment.red_flags}
                      onError={onError}
                      onSubmit={(attributes) => this.onSubmitSection('medical-history', false, attributes)}
                    />
                  )
                }, {
                  key: 'medical-history',
                  label: Strings.medicalHistoryTabLabel,
                  tabClassName: submitted && incompleteAttributes.includes('medical_info') ? 'alert error' : null,
                  component: (
                    <AssessmentMedicalHistoryForm
                      user={user}
                      currentUser={this.state.currentUser}
                      currentClinic={this.state.currentClinic}
                      submitted={submitted}
                      assessmentId={assessment.id}
                      medicalHistory={assessment.medical_info || {}}
                      medicalInfoId={(assessment.medical_info || {}).id}
                      onError={onError}
                      onSubmit={(attributes) => this.onSubmitSection('exams', false, attributes)}
                    />
                  )
                }, {
                  key: 'exams',
                  label: Strings.physicalExamsTabLabel,
                  component: (
                    <NeuroExamsForm
                      submitted={submitted}
                      neuroExam={assessment.neurological_examination}
                      onError={onError}
                      onSubmit={(attributes) => {
                        let next = 'tests';
                        if (this.isSpecialist) {
                          next = 'diagnosis-plan';
                        }
                        this.onSubmitSection(next, false, attributes);
                      }}
                    />
                  )
                }, {
                  key: 'tests',
                  label: Strings.testsTabLabel,
                  hide: this.isSpecialist,
                  component: (
                    <SpecialTests
                      assessment={assessment}
                      user={user}
                      injury={injury}
                      baseline={baseline}
                      currentUser={currentUser}
                      currentClinic={currentClinic}
                      clinicId={currentClinic.id}
                      onTestSaved={this.loadAssessment}
                      onContinue={() => this.querySection('diagnosis-plan')}
                    />
                  )
                }, {
                  key: 'diagnosis-plan',
                  label: Strings.diagnosisAndPlanTabLabel,
                  tabClassName: submitted 
                    && (incompleteAttributes.includes('diagnosis') || incompleteAttributes.includes('return_to_activities')) 
                    ? 'alert error' 
                    : null,
                  component: (
                    <If condition={!!assessment.completed}>
                      <Then>
                        <div className="row">
                          <div className="col-md-12">
                            <p className="alert alert-info">
                              {Strings.closedAssessmentEditSectionInfoMessage}
                            </p>
                          </div>

                          <div className="col-md-12">
                            <PostInjuryDiagnosis
                              diagnosis={assessment.diagnosis || {}}
                            />
                          </div>

                          <div className="col-md-12">
                            <PostInjuryFollowUp
                              data={assessment.follow_up_action_plan || {}}
                            />
                          </div>

                          {type === 'chronic' && (
                            <div className="col-md-12">
                              <PostInjuryInitialManagement
                                initialManagement={assessment.initial_management || {}}
                              />
                            </div>
                          )}

                          <div className="col-md-12">
                            <PostInjuryReturn
                              stages={assessment.recovery_protocol_stage || {}}
                            />
                          </div>

                          <div className="col-md-12">
                            <PostInjuryCommunication
                              actions={assessment.actions || {}}
                            />
                          </div>
                        </div>
                      </Then>
                      <Else>
                        <DiagnosisPlanOfManagementForm
                          submitted={submitted}
                          type={type}
                          dischargePermitted={
                            userHasRoleMatchingDescription(
                              currentUser, 
                              RoleDescriptions.Clinician
                            )
                          }
                          assessment={assessment}
                          onError={onError}
                          onSubmit={(attributes) => this.onSubmitSection('review', false, attributes)}
                        />
                      </Else>
                    </If>
                  )
                }, {
                  key: 'review',
                  label: Strings.reviewAndCloseTabLabel,
                  tabClassName: assessment.completed === true ? 'alert success' : 'alert warning',
                  component: (
                    <div className="post-injury-assessment-display">
                      <PostInjuryAssessmentView
                        className="post-injury-assessment-content"
                        user={user}
                        injury={injury}
                        assessment={assessment}
                        baseline={baseline}
                        type={type}
                      />
                      <button type="button" className="btn btn-primary" onClick={this.closeAssessment.bind(this)}>
                        {Strings.closeAssessmentText}
                      </button>
                    </div>
                  )
                }]}
              />
            </Activity>
          </div>
        </Activity>
        
      </Page>
    );
  }

  get isSpecialist() {
    return userHasRoleMatchingDescription(this.state.currentUser, RoleDescriptions.Specialist);
  }

  scrollToTabBar() {
    const rect = this.tabBar.current.getBoundingClientRect();

    if (rect.top < 0) {
      const scroll = rect.top + window.pageYOffset;
      window.scrollTo(0, scroll - SCROLL_OFFSET);
    }
  }

  routerWillLeave(routeParams) {
    const { userClosed, assessment } = this.state;

    /** these routes will override the route intercept as /login might mean they have logged out */
    if (routeParams.pathname === '/' || routeParams.pathname === '/login') {
      return false;
    }

    /** TODO: add in roles (not trigger if not clinician) */
    if (!userClosed && !assessment.completed) {
      this.confirmIncompleteAssessment();
      return true;
    }

    return false;
  }

  confirmIncompleteAssessment() {
    const message = (
      <div>
        <p>{Strings.confirmRouteLeaveText}</p>
        <p>{Strings.wishToContinueText}</p>
      </div>
    );

    confirmation(message, {
      title: Strings.confirmRouteLeaveTitle,
      onfirmButtonTitle: Strings.confirmButtonTitle,
      cancelButtonTitle: Strings.cancelButtonTitle,
      onConfirm: () => {
        this.setState({ 
          userClosed: true 
        }, () => { 
          this.props.router.blocker?.proceed?.();
        });
      },
      onCancel: () => {
        this.props.router.blocker?.reset?.();
        this.querySection('review');
      }
    });
  }

  initialize(userId, injuryId, assessmentId) {
    this.setState({
      loadActivity: true
    });

    Promise.all([
      this.props.getUser(userId),
      this.props.getInjury(userId, injuryId),
      this.props.getInjuryAssessment(userId, injuryId, assessmentId)
    ]).then(results => {
      const assessment = results[2] || {};
      const assessmentDate = assessment.created_at || dayjs().format();

      return Promise.all([
        ...results,
        this.props.getBaselineTestBeforeDate(userId, assessmentDate)
      ]);
    }).then(([user, injury, assessment, baseline]) => {
      this.setState({ 
        user, 
        injury, 
        assessment,
        baseline,
        incompleteAttributes: this.getInCompleteAttributes(assessment),
        loadActivity: false,
      });
    }).catch(() => {
      this.setState({ 
        loadActivity: false
      });
    });
  }

  onSubmitSection(nextKey, reloadInjury, attributes) {
    this.setState({ activity: true, error: null });
    const { userId = 0, injuryId = 0, assessmentId = 0 } = this.props.params;

    this.props.updateInjuryAssessment(
      userId, 
      injuryId, 
      assessmentId, 
      attributes
    ).then((assessment) => {
      this.setState({
        assessment,
        incompleteAttributes: this.getInCompleteAttributes(assessment)
      });

      if (nextKey) {
        this.querySection(nextKey);
      }

      if (reloadInjury) {
        return this.props.getInjury(userId, injuryId);
      }

      return Promise.resolve(this.state.injury);
    }).then(injury => {
      onSectionSaveSuccess();
      this.setState({
        injury,
        activity: false
      });
    }).catch(error => {
      onError(error.message);
      this.setState({
        activity: false,
        error: error.message
      });
    });
  }

  getAssessmentType() {
    return getAssessmentType(
      this.state.injury, 
      this.state.assessment
    );
  }

  querySection(key) {
    const { router = {}, queryParams } = this.props;
    router.setQueryParams({
      ...(queryParams || {}),
      section: key
    });
  }

  loadAssessment() {
    this.setState({ activity: true, error: null });
    const { userId = 0, injuryId = 0, assessmentId = 0 } = this.props.params;

    this.props.getInjuryAssessment(userId, injuryId, assessmentId).then(assessment => {
      this.setState({
        assessment,
        activity: false
      });
    }).catch(error => {
      this.setState({
        activity: false,
        error: error.message
      });
    });
  }

  onTabClick(key) {
    this.querySection(key);
  }

  getInCompleteAttributes(assessment) {
    const type = this.getAssessmentType();
    const keys = type === 'chronic' ? requiredChronicAssessmentKeys : requiredAcuteAssessmentKeys;

    return keys.filter(key => Object.keys(assessment[key] || {}).length === 0);
  }

  closeAssessment() {
    const { assessment } = this.state;
    const incompleteAttributes = this.getInCompleteAttributes(assessment);
    const { userId = 0, injuryId = 0, assessmentId = 0 } = this.props.params;

    this.setState({
      submitted: true,
      incompleteAttributes
    });

    if (incompleteAttributes.length > 0) {
      this.querySection(mapAttributeToSection(incompleteAttributes[0]));
      onError(Strings.closeAssessmentErrorMessage);
      return;
    }

    if (this.state.assessment && this.state.assessment.completed) {
      this.routeToInjuryHistory(this.state.clinicId, userId);
      return;
    }

    this.setState({ activity: true, error: null });

    const attributes = {
      completed: true
    };

    this.props.updateInjuryAssessment(
      userId, 
      injuryId, 
      assessmentId, 
      attributes
    ).then(assessment => {
      this.setState({ activity: false, assessment }, () => {
        this.routeToInjuryHistory(this.state.clinicId, userId);
      });
    }).catch(error => {
      this.setState({
        error: error.message,
        activity: false
      });
    });
  }

  routeToInjuryHistory(clinicId, userId) {
    this.props.router.push(replaceLinkParams(ClinicPaths.patientProfile.injuries.index.link, {
      clinicId,
      userId
    }));
  }
}

const mapStateToProps = (state) => {
  const clinicId = getCurrentClinicId();
  const currentUser = UserSelector.getCurrentUser(state);
  const { users = {}, injuries = {}, injuryAssessments = {} } = state;
  return { 
    users, injuries, injuryAssessments, clinicId, currentUser 
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getInjury: (userId, injuryId) => {
      return dispatch(getInjuryAsync(userId, injuryId));
    },
    getInjuryAssessment: (userId, injuryId, assessmentId) => {
      return dispatch(getInjuryAssessmentAsync(userId, injuryId, assessmentId));
    },
    updateInjuryAssessment: (userId, injuryId, assessmentId, attributes) => {
      return dispatch(updateInjuryAssessmentAsync(userId, injuryId, assessmentId, attributes));
    },
    getUser: (id) => {
      return dispatch(getUserAsync(id));
    },
    getActiveBaseline: (userId) => {
      return dispatch(getActiveBaselineTestAsync(userId));
    },
    getBaselineTestBeforeDate: (userId, beforeDate) => {
      return dispatch(getBaselineBeforeDateAsync(userId, beforeDate));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PostInjuryAssessment)
);
