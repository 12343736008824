import React, { useState } from 'react';
import BookingLinkModal from './booking-link-modal';
import Strings from './lang';
import Icon from '../icon';

const AddBookingLinkButton = ({ 
  userId, 
  disabled = false, 
  visible = true,
  clinics,
  className
}) => {
  const [open, setOpen] = useState(false);

  return visible ? (
    <>
      <button 
        type="button" 
        disabled={disabled}
        className={className}
        onClick={() => setOpen(true)}
      >
        <Icon name="plus" />&nbsp;
        {Strings.addBookingLinkButtonText}
      </button>
      <BookingLinkModal 
        isOpen={open}
        userId={userId}
        clinics={clinics}
        onClose={() => setOpen(false)}
      />
    </>
  ) : null;
};

export default AddBookingLinkButton;
