import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

class NavigationDropdown extends Component {
  constructor(props) {
    super(props);

    this.onBodyClick = this.onBodyClick.bind(this);

    this.state = {
      expanded: false
    };
  }

  componentDidMount() {
    document.querySelector('body').addEventListener('click', this.onBodyClick);
  }

  componentWillUnmount() {
    document.querySelector('body').removeEventListener('click', this.onBodyClick);
  }

  render() {
    return (
      <li 
        className={classnames('navigation-dropdown', { expanded: this.state.expanded })}
        onClick={this.toggleExpanded.bind(this)}
      >
        <div className="navigation-dropdown-header">  
          {this.renderHeader()}
        </div>  
        <ul>
          {this.props.children}
        </ul>
      </li>
    );
  }

  renderHeader() {
    const { header } = this.props;

    if (typeof header === 'function') {
      return header(this.state.expanded);
    }

    return header;
  }

  toggleExpanded() {
    const { expanded } = this.state;
    this.setState({ expanded: !expanded }, () => {
      if (typeof this.props.onToggle === 'function') {
        this.props.onToggle(this.state.expanded);
      }
    });
  }

  onBodyClick(e) {
    // Hide the navigation if any element other than the dropdown header was clicked.
    // This ensures that the navigation dropdown closes when the user clicks elsewhere
    // and in situations where the clicked item does not trigger a route change.
    const { classList } = e.target;
    if (!classList.contains('navigation-dropdown-header') && this.state.expanded) {
      this.setState({ expanded: false });
    }
  }
}

NavigationDropdown.propTypes = {
  header: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  onToggle: PropTypes.func
};

export default NavigationDropdown;
