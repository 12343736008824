import dayjs from 'dayjs';
import { jsonApiCreate } from '@/lib/requests/jsonapi';
import { updateUser, updateCurrentUserId } from '../actions/users';
import { updateGuardianAuth } from '../actions/guardians-account';
import { setActivity, setAuthToken, updateCurrentClinicForUser } from '@/tokens';
import { setRegistrationSession } from '@/utilities/registration-session';
import { setRememberedUser, removeRememberedUser } from '@/utilities/email-storage';

const createSessionAsync = (attributes = {}, options = {}) => {
  return (dispatch) => {
    return jsonApiCreate({ type: 'sessions', attributes }, options).then(authUser => {
      const { rememberEmail } = options;
      const newOption = { ...options };
      
      if (rememberEmail !== undefined) {
        if (rememberEmail) {
          setRememberedUser(authUser);
        } else {
          removeRememberedUser(authUser);
        }
      }

      if (newOption.registrationAuth) {
        setRegistrationSession(authUser);
      } else {
        setAuthToken(authUser.auth_token);
        setActivity(dayjs().valueOf());
        updateCurrentClinicForUser(authUser);
        dispatch(updateCurrentUserId(authUser.id));
      }

      const { auth_token, ...user } = authUser;
      
      if (user.children) {
        dispatch(updateGuardianAuth(user));
      }

      dispatch(updateUser(user));

      return Promise.resolve(user);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

export {
  createSessionAsync
};
