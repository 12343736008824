import React, { useCallback, useEffect, useState } from 'react';
import { CheckboxInputGroup } from '@/forms';
import Strings from './lang';

const buildSelectedWords = (words) => words.reduce((acc, word) => {
  acc[word] = false;
  return acc;
}, {});

const WordCheckList = ({
  className,
  maxTrials,
  trialNumber,
  words = [],
  onNext
}) => {
  const [selectedWords, setSelectedWords] = useState(() => buildSelectedWords(words));

  const onUpdate = useCallback((word, { target }) => {
    const { checked } = target;

    setSelectedWords(prev => ({
      ...prev,
      [word]: checked
    }));
  }, []);

  const onNextClick = useCallback(() => {
    const total = Object.values(selectedWords)
      .filter(Boolean)
      .length;

    onNext(total);
  }, [selectedWords, onNext]);

  useEffect(() => {
    setSelectedWords(
      buildSelectedWords(words)
    );
  }, [words, trialNumber]);

  return words.length ? (
    <div>
      <ul>
        {Object.keys(selectedWords).map((word, index) => (
          <CheckboxInputGroup
            key={index}
            className="form-group"
            labelText={selectedWords[word] ? <s><b>{word}</b></s> : <b>{word}</b>}
            labelClassName="bold"
            inputProps={{
              className: 'form-control',
              checked: selectedWords[word],
              onChange: (e) => onUpdate(word, e)
            }}
            inputValid
          />
        ))}
      </ul>
      <div>
        <button type="button" className="btn btn-default" onClick={onNextClick}>
          {(trialNumber + 1) >= maxTrials
            ? Strings.doneTrialsText
            : Strings.nextTrialText}
        </button>
      </div>
    </div>
  ) : (
    <p className={className}>
      <i>{Strings.noListSelectedText}</i>
    </p>
  );
};

export default WordCheckList;
