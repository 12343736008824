import React from 'react';
import dayjs from 'dayjs';
import classnames from 'classnames';
import DanaLabels from '../dana-labels';
import Icon from '../../icon';
import { BatteryType } from '../helpers';
import { DISPLAY_DATE_FORMAT } from '@/utilities/dates/formats';
import Strings from '../lang';

const BatteryTypeStrings = {
  [BatteryType.Quick]: Strings.quickTestLabel,
  [BatteryType.Full]: Strings.fullTestLabel
};

const LabelTypes = {
  [BatteryType.Quick]: 'label-warning',
  [BatteryType.Full]: 'label-default'
};

const BatteryDetailsHeader = ({
  battery = {},
  onClose
}) => {
  const { created_at, battery_type, is_valid } = battery;

  return (
    <div className="battery-details-header">
      <div className="title-container">
        <h3>
          {Strings.batteryDetailsTitle}
          <span className={classnames('label', LabelTypes[battery_type], 'inverted')}>
            {BatteryTypeStrings[battery_type]}
          </span>
          {is_valid === false && (
            <span className="label label-danger inverted">
              {Strings.invalidText}
            </span>
          )}
        </h3>
        {onClose && (
          <button
            type="button"
            className="close-button"
            onClick={onClose}
          >
            <Icon name="xmark" />
          </button>
        )}
      </div>
      <div>
        <small>{dayjs(created_at).format(DISPLAY_DATE_FORMAT)}</small>
      </div>
      <DanaLabels battery={battery} />
    </div>
  );
};

export default BatteryDetailsHeader;
