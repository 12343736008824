const FR = {
  title: 'Contact en cas d’urgence',
  guardianSubTitle: 'L\'information de contact d\'urgence de {0}',
  returningSubTitle: 'Mettez à jour vos coordonnées de contact d\'urgence',
  nameLabelText: 'Nom de la personne',
  nameErrorText: 'Veuillez indiquer le nom de la personne à joindre en cas d’urgence.',
  phoneLabelText: 'No de téléphone',
  phoneErrorText: 'Veuillez indiquer le no de téléphone à joindre en cas d’urgence.',
  relationshipLabelText: 'Lien avec la personne',
  relationshipErrorText: 'Veuillez indiquer le lien avec la personne à joindre en cas d’urgence.',
  submitButtonText: 'Sauvegarder',
  emergencyContactEmailLabelText: 'Courriel',
  emailInputPlaceholder: 'Courriel en cas d’urgence'
};
 
export default FR;
