import React from 'react';
import classnames from 'classnames';
import Icon from '../../icon';

const StatCard = ({
  type = 'default',
  icon = null,
  title,
  value = null,
  unit = null,
  subText = null,
  children
}) => {
  return (
    <div className={classnames('insight-stat-card', type)}>
      {icon && (
        <div className="stat-icon">
          <Icon name={icon} />
        </div>
      )}
      <div className="stat-details">
        <div className="stat-title">
          {title}
        </div>
        {value !== null && (
          <div className="stat-value">
            {value} <span className="stat-unit">{unit}</span>
          </div>
        )}
        {subText && (
          <div className="sub-text">
            <i>{subText}</i>
          </div>
        )}
        {children}
      </div>
    </div>
  );
};

export default StatCard;
