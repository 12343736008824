import { useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { UserSelector } from '@/redux/selectors';
import { getRedirectRoute } from './get-redirect-route';

const useAuthorizedRoute = (options = {}) => {
  const user = useSelector(UserSelector.getCurrentUser);
  const location = useLocation();
  const params = useParams();

  return useMemo(() => {
    return getRedirectRoute(user, { location, params }, options);
  }, [location, options, params, user]);
};

export default useAuthorizedRoute;
