import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const InjuryInformationItem = ({
  displayValueIn,
  className,
  label,
  value,
  childCondition,
  children
}) => {
  const Element = displayValueIn || 'span';
  return (
    <div className={classnames('injury-information-item', className)}>
      <label>{label}</label>
      <Element>{value}</Element>
      {childCondition && children}
    </div>
  );
};

InjuryInformationItem.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string
};

export default InjuryInformationItem;
