const FR = {
  personalCardTitle: 'Personnel',
  firstNameLabelText: 'Prénom',
  firstNameErrorText: 'Veuillez indiquer votre prénom.',
  lastNameLabelText: 'Nom de famille',
  lastNameErrorText: 'Veuillez indiquer votre nom de famille.',
  nameLabelText: 'Nom',
  nameValidationMessage: 'Veuillez indiquer le nom de la personne à joindre en cas d’urgence.',
  birthdateLabelText: 'Date de naissance :',
  birthdateValidationMessage: 'Veuillez écrire une date de naissance valide.',
  genderLabelText: 'Sexe',
  genderValidationMessage: 'Veuillez choisir votre sexe.',
  genderOtherLabelText: 'Quel est votre sexe ?',
  genderOtherValidationMessage: 'Veuillez indiquer votre sexe.',
  accountNumberLabelText: 'Numéro de compte',
  trainingDateLabelText: 'Formé à',
  trainingDateErrorMessage: 'Veuillez entrer une date de formation valide',
  accountNumberValidationMessage: 'Veuillez écrire votre no de compte.',
  bagTagLabelText: 'Carte d’identité de la concussion',
  bagTagValidationMessage: 'Veuillez écrire votre no de carte d’identité.',
  languageLabelText: 'Langue',
  languageValidationMessage: 'Veuillez indiquer votre langue.',
  addressLabelText: 'Adresse',
  cityLabelText: 'Ville/municipalité',
  regionLabelText: 'État/ province',
  countryLabelText: 'Pays',
  postalCodeLabelText: 'Code postal/Zip',
  phoneNumberLabelText: 'No de téléphone',
  faxNumberLabelText: 'No de fax',
  phoneNumberValidationMessage: 'Veuillez écrire un numéro de téléphone valide',
  faxNumberValidationMessage: 'Veuillez écrire un numéro de fax valide',
  emailLabelText: 'Courriel',
  emailValidationMessage: 'Veuillez indiquer votre courriel.',
  emergencyContactLabelText: 'Contact en cas d’urgence',
  emergencyContactEmailLabelText: 'Courriel en cas d’urgence',
  emergencyContactPhoneLabelText: 'No de téléphone en cas d’urgence',
  emergencyContactNameValidationMessage: 'Veuillez indiquer le nom de la personne à joindre en cas d’urgence.',
  emergencyContactRelationshipLabelText: 'Lien avec la personne à joindre en cas d’urgence',
  emergencyContactRelationshipValidationMessage: 'Veuillez indiquer le lien avec la personne à joindre en cas d’urgence.',
  organizationLabelText: 'Organisation',
  sportLabelText: 'Sport',
  levelLabelText: 'Niveau',
  teamLeadersLabelText: 'Chefs d’équipe (entraîneur/accompagnateur/enseignant)',
  positionLabelText: 'Position(s)',
  addAnotherLinkText: 'Ajouter un autre',
  credentialsInputLabelText: 'Qualifications (appuyez sur Entrée pour ajouter une qualification)',
  credentialsLabelText: 'Qualifications',
  credentialInputPlaceholder: 'Veuillez utiliser des acronymes e.g. D.C., M.D., etc. (Appuyez sur Entrée pour ajouter)',
  bioLabelText: 'Biographie',
  bioErrorText: 'Veuillez indiquer votre biographie.',
  clinicUrlLabelText: 'Site Web',
  submitButtonText: 'Sauvegarder',
  birthdateConfirmationMessage: '{2}. En continuant, vous confirmez que ce patient est âgé de {0} {1}.',
  under1: 'moins de 1',
  year: 'an',
  years: 'ans',
  professionLabelText: 'Métier',
  servicesLabelText: 'Services de traitement',
  servicesPlaceholder: 'Sélectionnez tout ce qui s\'y rapporte',
  defaultProfessionPlaceholder: 'Sélectionnez une profession',
  professionErrorMessage: 'Veuillez sélectionner une profession'
};
 
export default FR;
