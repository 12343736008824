/* eslint-disable no-unused-vars */
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import Modal from '../../modal';
import Icon from '../../icon';
import FileDropzone from './file-dropzone';
import uniqueId from '@/utilities/unique-id';
import { uploadClaimDocumentAsync } from '@/redux/thunks/idt-claims';
import { getReadableFileSize } from '@/utilities/files';
import Strings from '../lang';

const FileIcon = ({ file }) => {
  if (file.loading) {
    return <Icon name="loader" prefix="fad" spinPulse />;
  }

  if (file.error) {
    return <Icon name="xmark-circle" className="error" />;
  }

  return <Icon name="check-circle" className="success" />;
};

const ModalContent = ({ claimId, onClose }) => {
  const [data, setData] = useState({});
  const dispatch = useDispatch();
  const files = useMemo(() => Object.values(data), [data]);
  const uploading = useMemo(() => files.some(file => file.loading), [files]);

  const uploadFile = useCallback((file) => {
    const config = {
      id: uniqueId(),
      loading: true,
      error: null,
      fileName: file.name,
      file,
      status: 'loading'
    };

    setData(prev => ({
      ...prev,
      [config.id]: config
    }));

    dispatch(uploadClaimDocumentAsync(claimId, file)).then(() => {
      setData(prev => ({
        ...prev,
        [config.id]: {
          ...prev[config.id],
          loading: false,
          status: 'success'
        }
      }));
    }).catch(error => {
      setData(prev => ({
        ...prev,
        [config.id]: {
          ...prev[config.id],
          loading: false,
          error: error.message,
          status: 'error'
        }
      }));
    });
  }, [claimId, dispatch]);

  const handleDrop = useCallback((files) => {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < files.length; ++i) {
      uploadFile(files[i]);
    }
  }, [uploadFile]);

  const onRemoveDocument = useCallback((key) => {
    setData(prev => {
      const newData = { ...prev };
      if (newData[key]) {
        delete newData[key];
      }

      return newData;
    });
  }, []);

  return (
    <>
      <Modal.Header>
        <h2>{Strings.uploadDocumentLabel}</h2>
      </Modal.Header>
      <Modal.Body>
        <FileDropzone onDrop={handleDrop} />
        {files.length > 0 && (
          <div className="idt-documents-upload-list">
            <div className="upload-title">
              {uploading ? Strings.uploadingFilesLabel : Strings.uploadedFilesLabel}
            </div>
            <table className="idt-documents-upload-table">
              <tbody>
                {files.map(file => (
                  <tr key={file.id}>
                    <td width="30"><FileIcon file={file} /></td>
                    <td>
                      <div style={{ fontWeight: 500, lineHeight: 1 }}>{file.fileName}</div>
                      {!!file.error && (
                        <div className="error" style={{ lineHeight: 1 }}>
                          <small>{file.error}</small>
                        </div>
                      )}
                    </td>
                    <td width="100" align="right">
                      <div className="text-muted">{getReadableFileSize(file.file.size)}</div>
                    </td>
                    {!!file.error && (
                      <td width="60" align="right">
                        <button type="button" className="trash-btn" onClick={() => onRemoveDocument(file.id)}>
                          <Icon name="trash-alt" />
                        </button>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-primary"
          disabled={uploading}
          onClick={onClose}
        >
          {uploading ? Strings.pleaseWaitUploadingLabel : Strings.closeButtonLabel}
        </button>
      </Modal.Footer>
    </>
  );
};

const DocumentsUploadModal = ({
  claimId,
  isOpen = false,
  onClose = () => {}
}) => {
  return (
    <Modal
      blur
      isOpen={isOpen}
      className="idt-documents-upload-modal"
      onClose={onClose}
    >
      <ModalContent
        claimId={claimId}
        onClose={onClose}
      />
    </Modal>
  );
};

export default DocumentsUploadModal;
