import React, { Component } from 'react';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import { updateBaselineTestAsync, getBaselineTestAsync } from '@/redux/thunks/baseline-tests';
import { getUserAsync } from '@/redux/thunks/users';
import { getCurrentClinicId } from '@/tokens';
import { DISPLAY_DATE_FORMAT } from '@/utilities/dates/formats';
import { Page } from '../../page';
import Activity from '../../activity';
import PlayerBadge from '../../player-badge';
import { showAlert } from '../../alert-notifications';
import { withRouter } from '@/utilities/router-utils';
import Strings from './lang';
import {
  BalanceTestingCard,
  ConcentrationCard,
  DelayedMemoryCard,
  DualTaskTestCard,
  ForcePlateCard,
  GripStrengthCard,
  ImmediateMemoryCard,
  KingDevickCard,
  OrientationCard,
  ReactionTimeCard,
  SymptomLevelsCard,
  TandemGaitCard
} from '../baseline-test-cards';
import { ageAt } from '@/utilities/dates';

const MIN_DUAL_TASK_AGE = 12;
const DEFAULT_WORD_COUNT = 5;

class EditBaselineTest extends Component {
  constructor(props) {
    super(props);

    const { baselineTests = {}, users = {}, params = {} } = props;
    const { userId = 0, baselineId = 0 } = params;
    const baseline = baselineTests[baselineId] || {};
    const user = users[userId] || {};

    this.updateBaseline = this.updateBaseline.bind(this);

    this.state = {
      user,
      baseline,
      loading: true,
      activity: true
    };
  }

  componentDidMount() {
    const { userId = 0, baselineId = 0 } = this.props.params;

    if (userId && baselineId) {
      this.getUserAndBaseline(userId, baselineId);
    }
  }

  render() {
    const { baseline, user } = this.state;
    const { immediate_memory_test = {} } = baseline;
    const { person = {} } = user;
    return (
      <Page>
        <Activity active={this.state.activity} static={this.state.loading}>
          <div>

            <h1 className="display">{Strings.title}</h1>

            <PlayerBadge user={this.state.user} clinicId={this.props.clinicId} />

            <div className="caption">Baseline date: {dayjs(baseline.created_at).format(DISPLAY_DATE_FORMAT)}</div>

            <SymptomLevelsCard
              symptomLevels={baseline.pre_existing_symptoms}
              onSubmit={this.updateBaseline}
            />

            <OrientationCard
              orientation={baseline.cognitive_assessment_test}
              onSubmit={this.updateBaseline}
            />

            <ImmediateMemoryCard
              editing
              user={user}
              immediateMemory={immediate_memory_test}
              language={person.language}
              onSubmit={this.updateBaseline}
            />

            <ConcentrationCard
              timedMonthsInReverse={(
                'months_duration' in (baseline.concentration_test || {})
                  && baseline.concentration_test?.months_duration !== null
              )}
              concentration={baseline.concentration_test}
              onSubmit={this.updateBaseline}
            />

            <KingDevickCard
              kingDevick={baseline.king_devick_test}
              onSubmit={this.updateBaseline}
            />

            <BalanceTestingCard
              balance={baseline.balance_test}
              onSubmit={this.updateBaseline}
            />

            <ForcePlateCard
              forcePlate={baseline.force_plate_test}
              onSubmit={this.updateBaseline}
            />

            {baseline?.test_version > 1 && (
              <>
                <TandemGaitCard
                  tandemGait={baseline.tandem_gait_test}
                  onSubmit={this.updateBaseline}
                />

                <DualTaskTestCard
                  dualTask={baseline.dual_task_test}
                  isChild={ageAt(person?.birthday, baseline?.created_at) >= MIN_DUAL_TASK_AGE}
                  onSubmit={this.updateBaseline}
                />
              </>
            )}

            <ReactionTimeCard
              reaction={baseline.reaction_time_test}
              onSubmit={this.updateBaseline}
            />

            <GripStrengthCard
              gripStrength={baseline.grip_strength_test}
              onSubmit={this.updateBaseline}
            />

            <DelayedMemoryCard
              delayedMemory={baseline.delayed_memory_recall_test}
              wordList={immediate_memory_test.word_list}
              wordCount={immediate_memory_test.word_count || DEFAULT_WORD_COUNT}
              language={person.language}
              onSubmit={this.updateBaseline}
            />

          </div>
        </Activity>
      </Page>
    );
  }

  getUserAndBaseline(userId, testId) {
    this.setState({
      loading: true,
      activity: true
    });

    Promise.all([
      this.props.getUser(userId),
      this.props.getBaselineTest(userId, testId)
    ]).then(([user, baseline]) => {
      this.setState({
        user,
        baseline,
        loading: false,
        activity: false
      });
    }).catch(() => {
      this.setState({
        loading: false,
        activity: false
      });
    });
  }

  updateBaseline(attributes) {
    if (!attributes) {
      return;
    }

    const { userId = 0, baselineId = 0 } = this.props.params;

    this.setState({ activity: true });

    this.props.updateBaselineTest(userId, baselineId, attributes).then(baseline => {
      this.setState({ baseline, activity: false });
      showAlert('success', {
        dismissable: true,
        autoDismiss: 3000,
        message: Strings.sectionSavedAlertText
      });
    }).catch(() => {
      this.setState({ activity: false });
      showAlert('error', {
        dismissable: true,
        autoDismiss: 4000,
        message: Strings.sectionErrorAlertText
      });
    });
  }
}

const mapStateToProps = (state) => {
  const clinicId = getCurrentClinicId();
  const { baselineTests = {}, users = {} } = state;
  return { clinicId, baselineTests, users };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUser: (userId) => {
      return dispatch(getUserAsync(userId));
    },
    getBaselineTest: (userId, testId) => {
      return dispatch(getBaselineTestAsync(userId, testId));
    },
    updateBaselineTest: (userId, testId, attributes) => {
      return dispatch(updateBaselineTestAsync(userId, testId, attributes));
    }
  };
};

const ConnectedEditBaselineTest = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditBaselineTest);

const RoutableEditBaselineTest = withRouter(ConnectedEditBaselineTest);

export default RoutableEditBaselineTest;
