const FR = {
  timeoutLockTitle: 'Connexion',
  timeoutLockMessage: 'Vous avez été déconnecté à la suite d’une période d’inactivité Veuillez vous connecter pour continuer',
  manualLockTitle: 'Verrouillé',
  manualLockMessage: 'Le système a été verrouillé Veuillez vous connecter pour continuer',
  lockoutLinkText: 'Verrouiller l’écran',
  switchAccountsText: 'Changer de compte',
  notUserText: 'Pas {0}?',
  passwordPlaceholder: 'Mot de passe'
};
 
export default FR;
