import React from 'react';
import OverviewStats from './overview-stats';
import SummaryCards from './summary-cards';
import RTLRisk from './rtl-risk';

const InjuryOverviewStats = ({ injury, latestSymptoms }) => {
  return (
    <div>
      <OverviewStats injury={injury} />
      <SummaryCards injury={injury} />
      <RTLRisk symptoms={latestSymptoms} />
    </div>
  );
};

export default InjuryOverviewStats;
