import React from 'react';
import Icon from '../../icon';
import Strings from '../lang';

const ProductNotFoundView = ({ onReturn }) => {
  return (
    <div className="product-not-found-view">
      <div className="header-icon">
        <Icon name="link-slash" />
      </div>
      <h1>{Strings.productNotFoundTitle}</h1>
      <p>{Strings.productNotFoundMessage}</p>
      <button type="button" className="btn btn-primary" onClick={onReturn}>
        {Strings.productNotFoundBackButton}
      </button>
    </div>
  );
};

export default ProductNotFoundView;
