import React from 'react';
import { isFolder } from '@/utilities/google-drive';
import Thumbnail from './thumbnail';

const thumbnailBaseUrl = 'https://drive-thirdparty.googleusercontent.com/16/type';
const getShortcutFolderIconUrl = (mimeType) => `${thumbnailBaseUrl}/${mimeType}+shared`;

const getIcon = (icon, mimeType) => {
  if (isFolder({ mimeType })) {
    return getShortcutFolderIconUrl(mimeType);
  }

  return icon;
};

const FileView = ({
  mimeType = '',
  thumbnail = null,
  icon = null,
  fileName
}) => {
  return (
    <div className="file-view">
      <Thumbnail 
        src={thumbnail}
        icon={icon}
        mimeType={mimeType}
        fileName={fileName}
      />
      <div className="file-details">
        <div className="file-icon">
          <img referrerPolicy="no-referrer" src={getIcon(icon, mimeType)} alt="file type icon" />
        </div>
        <div className="file-name">{fileName}</div>
      </div>
    </div>
  );
};

export default FileView;
