import React from 'react';
import Modal from '../../modal';
import DanaBatteryDetails from '../battery-details';

const DanaBatteryModal = ({
  isOpen = false,
  batteryId,
  userId,
  onClose
}) => {
  return (
    <Modal
      blur
      isOpen={isOpen}
      className="dana-battery-details-modal"
      onClose={onClose}
    >
      <Modal.Body>
        <DanaBatteryDetails
          userId={userId}
          batteryId={batteryId}
          onClose={onClose}
        />
      </Modal.Body>
    </Modal>
  );
};

export default DanaBatteryModal;
