import React from 'react';
import ScrollToTop from 'react-scroll-up';
import Strings from './lang';
import Icon from '../icon';

const ScrollTop = () => (
  <ScrollToTop showUnder={250} duration={400}>
    <div className="scroll-to-top">
      <div className="scroll-icon">
        <Icon name="chevron-up" />
      </div>
      <p>{Strings.topText}</p>
    </div>
  </ScrollToTop>
);
export default ScrollTop;
