const defaultSymptoms = {
  headache: {
    level: '',
    notes: ''
  },
  head_pressure: {
    level: '',
    notes: ''
  },
  neck_pain: {
    level: '',
    notes: ''
  },
  nausea: {
    level: '',
    notes: ''
  },
  dizziness: {
    level: '',
    notes: ''
  },
  blurred_vision: {
    level: '',
    notes: ''
  },
  balance_problems: {
    level: '',
    notes: ''
  },
  light_sensitivity: {
    level: '',
    notes: ''
  },
  noise_sensitivity: {
    level: '',
    notes: ''
  },
  feeling_slowed_down: {
    level: '',
    notes: ''
  },
  feeling_in_a_fog: {
    level: '',
    notes: ''
  },
  dont_feel_right: {
    level: '',
    notes: ''
  },
  difficulty_concentrating: {
    level: '',
    notes: ''
  },
  difficulty_remembering: {
    level: '',
    notes: ''
  },
  fatigue: {
    level: '',
    notes: ''
  },
  confusion: {
    level: '',
    notes: ''
  },
  drowsiness: {
    level: '',
    notes: ''
  },
  trouble_falling_asleep: {
    level: '',
    notes: ''
  },
  more_emotional: {
    level: '',
    notes: ''
  },
  irritability: {
    level: '',
    notes: ''
  },
  sadness: {
    level: '',
    notes: ''
  },
  nervous: {
    level: '',
    notes: ''
  }
};

const makeDefaultSymptoms = (defaultValue = '') => {
  return Object.keys(defaultSymptoms).reduce((acc, key) => {
    return {
      ...acc,
      [key]: {
        ...(defaultSymptoms[key] || {}),
        level: defaultValue
      }
    };
  }, {});
};

export default defaultSymptoms;
export {
  makeDefaultSymptoms
};
