import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import PanelGroup from './panel-group';
import PanelHeading from './panel-heading';
import PanelContent from './panel-content';
import { PanelContext, PanelGroupContext, usePanel } from './panel-context';

class Panel extends Component {
  static contextType = PanelGroupContext;

  constructor(props) {
    super(props);

    this._onToggle = this._onToggle.bind(this);

    this.state = {
      collapsed: true
    };
  }

  componentDidMount() {
    const { subscribe = () => {} } = this.context || {};
    subscribe(this._onToggle);
  }

  render() {
    const mergedProps = this.mergeContextWithProps();

    return (
      <PanelContext.Provider value={this.getContextValue()}>
        <div 
          className={classnames(
            'panel',
            `panel-${mergedProps.type}`,
            (mergedProps.collapsedClassName && this.state.collapsed ? mergedProps.collapsedClassName : ''),
            this.props.className
          )}
        >
          {this.props.children}
        </div>
      </PanelContext.Provider>
    );
  }

  getContextValue() {
    const { collapsible } = this.props;

    return {
      collapsible: collapsible !== undefined
        ? collapsible
        : (this.context.collapsible || false),
      collapsed: this.state.collapsed,
      onToggle: this._onToggle
    };
  }

  mergeContextWithProps() {
    const {
      type: panelType,
      ...context
    } = this.context || {};

    const {
      type = (panelType || 'default'),
      ...props
    } = this.props;

    return {
      ...props,
      ...context,
      type
    };
  }

  _onToggle(collapsed) {
    const { disabled: groupDisabled } = this.context || {};
    const { disabled } = this.props;
    const isDisabled = disabled !== undefined ? disabled : groupDisabled;

    if (!isDisabled) {
      this.setState({
        collapsed
      });
    }
  }
}

Panel.propTypes = {
  type: PropTypes.string,
  collapsible: PropTypes.bool
};

Panel.Group = PanelGroup;
Panel.Heading = PanelHeading;
Panel.Content = PanelContent;

export default Panel;
export { usePanel };
