import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { If, Then, Else } from 'react-if';
import { resolvePatientName } from '../patient-meta';
import AccessControl from '../access-control';
import { EditButton } from '../buttons';
import { ActiveClinicSelect } from '../clinic-select';
import PlayerPersonalInfoCard from '../user-profile-cards/player-personal-info-card';
import EditPlayerPersonalInfoCardForm from '../user-profile-cards/edit-player-personal-info-card-form';
import PlayerContactInfoCard from '../user-profile-cards/player-contact-info-card';
import EditPlayerContactInfoCardForm from '../user-profile-cards/edit-player-contact-info-card-form';
import PlayerPhysicianInfoCard from '../user-profile-cards/player-physician-info-card';
import EditPlayerPhysicianInfoCardForm from '../user-profile-cards/edit-player-physician-info-card-form';
import UserClinicsInfoCard from '../user-profile-cards/user-clinics-info-card';
import Strings from './lang';

class ShowPlayerProfile extends Component {
  constructor(props) {
    super(props);

    this.changeClinic = this.changeClinic.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      showDropdown: false
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.user.current_clinic !== prevProps.user.current_clinic) {
      this.closeClinicsDropdown();
    }
  }

  render() {
    const { user = {}, editorRoles = [] } = this.props;
    const currentClinic = user.current_clinic || {};

    return (
      <div className="profile player">

        <div className="card-container">
          <div className="card-container-header">
            <h2>{ Strings.activeClinicHeadingText }</h2>
          </div>
          <div className="card-container-body">
            <AccessControl roles={editorRoles}>
              <div className="card card-show card-active-clinic-select">
                <If condition={this.state.showDropdown}>
                  <Then>
                    <ActiveClinicSelect
                      user={this.props.user}
                      onSelectClinic={this.props.onSelectCurrentClinic}
                      changeClinic={this.changeClinic}
                    />
                  </Then>
                  <Else>
                    <button 
                      type="button"
                      className="btn btn-clinic btn-secondary" 
                      onClick={this.changeClinic}
                    >
                      {Strings.changeClinicLabel}
                    </button>
                  </Else>
                </If>
              </div>
            </AccessControl>
            <If condition={currentClinic.id !== undefined}>
              <Then>
                <UserClinicsInfoCard clinic={currentClinic} />
              </Then>
              <Else>
                <div className="card card-show">
                  {
                    Strings.formatString(
                      Strings.unassignedClinicMessageText,
                      resolvePatientName({
                        user: this.props.currentUser,
                        patient: this.props.user,
                        clinicId: this.props.clinicId,
                        redactedText: Strings.unassignedClinicRedactedText
                      })
                    )
                  }
                </div>
              </Else>
            </If>
          </div>
        </div>

        <div className="card-container">
          <div className="card-container-header">

            <h2>{ Strings.personalHeadingText }</h2>

            <AccessControl roles={editorRoles}>
              <EditButton
                editing={this.props.editPersonal}
                onClick={this.props.onClickPersonalEditButton}
              />
            </AccessControl>

          </div>
          <div className="card-container-body">

            <If condition={this.props.editPersonal}>
              <Then>
                <EditPlayerPersonalInfoCardForm
                  user={this.props.user}
                  onSubmit={this.onSubmit}
                />
              </Then>
              <Else>
                <PlayerPersonalInfoCard
                  user={this.props.user}
                  clinicId={this.props.clinicId}
                />
              </Else>
            </If>

          </div>
        </div>

        <div className="card-container">
          <div className="card-container-header">

            <h2>{ Strings.contactHeadingText }</h2>

            <AccessControl roles={editorRoles}>
              <EditButton
                editing={this.props.editContact}
                onClick={this.props.onClickContactEditButton}
              />
            </AccessControl>

          </div>
          <div className="card-container-body">

            <If condition={this.props.editContact}>
              <Then>
                <EditPlayerContactInfoCardForm
                  user={this.props.user}
                  onSubmit={this.onSubmit}
                />
              </Then>
              <Else>
                <PlayerContactInfoCard user={this.props.user} />
              </Else>
            </If>

          </div>
        </div>

        <div className="card-container">
          <div className="card-container-header">

            <h2>{ Strings.physicianHeadingText }</h2>

            <AccessControl roles={editorRoles}>
              <EditButton
                editing={this.props.editPhysician}
                onClick={this.props.onClickPhysicianEditButton}
              />
            </AccessControl>

          </div>
          <div className="card-container-body">

            <If condition={this.props.editPhysician}>
              <Then>
                <EditPlayerPhysicianInfoCardForm
                  user={this.props.user}
                  onSubmit={this.onSubmit}
                />
              </Then>
              <Else>
                <PlayerPhysicianInfoCard user={this.props.user} />
              </Else>
            </If>

          </div>
        </div>
      </div>
    );
  }

  closeClinicsDropdown() {
    this.setState({ showDropdown: false });
  }

  onSubmit(attributes) {
    this.props.onSubmit(attributes);
  }

  changeClinic() {
    this.setState(prev => ({ showDropdown: !prev.showDropdown }));
  }
}

ShowPlayerProfile.propTypes = {
  editorRoles: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentUser: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  clinicId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  editPersonal: PropTypes.bool.isRequired,
  editContact: PropTypes.bool.isRequired,
  editPhysician: PropTypes.bool.isRequired,
  onClickPersonalEditButton: PropTypes.func.isRequired,
  onClickContactEditButton: PropTypes.func.isRequired,
  onClickPhysicianEditButton: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSelectCurrentClinic: PropTypes.func
};

export default ShowPlayerProfile;
