const EN = {
  specialistNotesTitle: 'Physician/Specialist Note',
  specialistNotesListSubtitle: 'Physician/Specialist notes for injury dated {0}',
  specialistNotesListEmptyMessage: 'No Physician/Specialist notes have been created for this injury.',
  startspecialistNotesLinkText: 'Add New Note',
  openAllPanelsLabel: 'Open All',
  closeAllPanelsLabel: 'Close All',
  notePanelHeading: '{0} Note - {1}',
  defaultSpecialistType: 'Physician/Specialist',
  editButonText: 'Edit',
  noSymptomsRecordedText: 'No Symptom Scores Recorded',
  symptomsSectionTitle: 'Symptoms',
  symptomsTotalText: 'Symptoms Total',
  symptomsSeverityText: 'Symptom Severity',
  diagnosisChangedLabel: 'Diagnosis changed to',
  notesSectionTitle: 'Notes',
  recoveryStagesSectionTitle: 'Recovery Stages',
  recoveryStagesNotChangedText: 'Recovery stages not changed',
  lastUpdatedText: 'Last Updated',
  lastUpdatedByText: 'Last Updated By',
  noteHistoryButtonText: 'Note History',
  diagnosisLabel: 'Diagnosis',
  submitButtonText: 'Submit',
  notesPlaceholder: 'Enter Notes Here ...',
  addSymptomScoreButton: 'Add Symptom Score',
  removeSymptomScoreButton: 'Remove',
  noteCreatedByLabel: 'Created by {0}',
  noteCreatorIconTitle: 'This note was created by you',
  recoveryStagesNotUpdatedLabel: 'Recovery stages have not been updated'
};

export default EN;
