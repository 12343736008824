import { createContext, useContext } from 'react';

export const BaselineStepsContext = createContext({
  next: () => { },
  step: null,
  index: -1,
  profile: []
});

export const useBaselineSteps = () => useContext(BaselineStepsContext);
