import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { If, Then, Else } from 'react-if';
import RememberedUsersList from './remembered-users-list';
import RememberedUserAuthenticationForm from './remembered-user-authentication-form';
import Strings from './lang';
import Icon from '../icon';

class RememberedUsersAuthentication extends Component {
  constructor(props) {
    super(props);

    const { portal = 'clinician' } = props;

    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      selectedUser: {},
      portal
    };
  }

  render() {
    const { selectedUser } = this.state;
    const isUserSelected = Object.keys(selectedUser).length > 0;
    return (
      <div className="users-authentication">
        <If condition={isUserSelected}>
          <Then>
            <div className="users-authentication-form">
              <div className="user-meta">
                <button type="button" className="btn list-return-button" onClick={() => this.setState({ selectedUser: {} })}>
                  <Icon name="arrow-left" />
                </button>
                <div className="user-avatar">
                  <img 
                    src={selectedUser.photo_url} 
                    alt={`${selectedUser.first_name} ${selectedUser.last_name}`} 
                  />
                </div>
                <div className="user-info">
                  <h3 className="bold">{selectedUser.first_name} {selectedUser.last_name}</h3>
                  <p>{selectedUser.email}</p>
                </div>
              </div>
              <RememberedUserAuthenticationForm onSubmit={this.onSubmit} />
            </div>
          </Then>
          <Else>
            <div>
              <RememberedUsersList 
                rememberedUsers={this.props.rememberedUsers} 
                onRemoveUser={this.props.onRemoveUser}
                onUserSelected={(user) => this.setState({ selectedUser: user })}
              />
              <div className="new-login-button">
                <button type="button" className="edit-button" onClick={this.props.onNewLogin}>
                  <Icon name="plus" /> {Strings.newSignInText}
                </button>
              </div>
            </div>
          </Else>
        </If>
        
      </div>
    );
  }

  onSubmit(password, rememberEmail) {
    const { selectedUser, portal } = this.state;
    const { email } = selectedUser;

    this.props.onAuthenticate({ email, password, portal }, rememberEmail);
  }
}

RememberedUsersAuthentication.propTypes = {
  rememberedUsers: PropTypes.array,
  onAuthenticate: PropTypes.func,
  onNewLogin: PropTypes.func
};

export default RememberedUsersAuthentication;
