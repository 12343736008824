import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const NumberRow = ({
  numbers,
  active
}) => (
  <div className={classnames('number-row', { 'active-number-row': active })}>
    <span>{numbers.join(' \u2013 ')}</span>
  </div>
);

const NumberList = ({
  activeList = false,
  activeIndex,
  numbers = []
}) => (
  <div className="number-list">
    {numbers.map((row, index) => (
      <NumberRow 
        key={index}
        active={activeList && activeIndex === index}
        numbers={row}
      />
    ))}
  </div>
);

const NumberLists = ({
  numbers = {}, 
  activeIndex = 0, 
  activeList = 1,
  className
}) => (
  <div className={className}>
    {Object.keys(numbers).map(key => (
      <NumberList 
        key={key}
        activeList={activeList === parseInt(key, 10)}
        activeIndex={activeIndex}
        numbers={numbers[key]}
      />
    ))}
  </div>
);

NumberLists.propTypes = {
  numbers: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number))),
  activeIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  activeList: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default NumberLists;
