import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { CountdownTimer } from '../../../timer-components';

const MAX_SECONDS = 20;

const BalanceTestTimer = ({
  seconds = MAX_SECONDS
}) => {
  const onTimeComplete = useCallback(() => {
    const dingSound = new Audio('/audio/ding.mp3');

    if (dingSound.canPlayType('audio/mp3')) {
      dingSound.play().catch(() => {});
    }
  }, []);

  return (
    <div className="bess-timer-container">
      <CountdownTimer 
        className="bess-timer"
        precision={0}
        seconds={seconds} 
        onCompleted={onTimeComplete}
      />
    </div>
  );
};

BalanceTestTimer.propTypes = {
  seconds: PropTypes.number
};

export default BalanceTestTimer;
