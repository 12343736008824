const policyUrlForRoleName = (name, language) => {
  const time = Date.now();
  const baseUrl = '/documents/policies';
  switch (name) {
    case 'clinician':
    case 'clinic_staff':
    case 'front_desk':
      return `${baseUrl}/privacy-clinician/${language}/index.markdown?${time}`;
    case 'leader':
      return `${baseUrl}/privacy-leader/${language}/index.markdown?${time}`;
    case 'player':
      return `${baseUrl}/privacy-player/${language}/index.markdown?${time}`;
    default:
      return `${baseUrl}/privacy.markdown`;
  }
};

export default policyUrlForRoleName;
