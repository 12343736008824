const FR = {
  pageTitle: 'Évaluation post-blessure',
  searchButtonText: 'Commencer l’évaluation',
  newPatientsStartButtonText: 'Nouvelle évaluation de la blessure',
  activeInjuryErrorText: 'Le patient sélectionné a présentement une blessure. Vous ne pouvez pas commencer une évaluation pour une nouvelle blessure avant d’avoir signé la décharge pour la blessure actuelle.',
  checkInPatientTitle: 'Enregistrement du patient',
  checkInPatientText: 'Enregistrer le patient'
};
 
export default FR;
