import React, { useCallback, useState } from 'react';
import Dropdown, { MenuItem } from '../dropdown';
import Icon from '../icon';

/* eslint-disable no-magic-numbers */
export const PER_PAGE_KEYS = [
  25,
  50,
  100,
  'all'
];
/* eslint-enable no-magic-numbers */

const PerPageSelector = ({ value, onSelect }) => {
  const [open, setOpen] = useState(false);

  const handleSelect = useCallback((value) => {
    setOpen(false);
    onSelect(value);
  }, [onSelect]);

  return (
    <Dropdown
      isOpen={open}
      placement="bottom-end"
      onClose={() => setOpen(false)}
      trigger={(
        <button 
          type="button" 
          className="btn btn-light btn-sm"
          style={{ marginLeft: 10 }}
          onClick={() => setOpen(prev => !prev)}
        >
          Per Page: <span className="bold capitalize">{value}</span>
          &nbsp;&nbsp;&nbsp;<Icon name="chevron-down" />
        </button>
      )}
    >
      {PER_PAGE_KEYS.map(key => (
        <MenuItem
          key={key}
          type="primary"
          selected={String(key) === String(value)}
          onSelect={() => handleSelect(key)}
        >
          <span className="capitalize">{key}</span>
        </MenuItem>
      ))}
    </Dropdown>
  );
};

export default PerPageSelector;
