const DRIVE_UPDATE_AUTH = 'DRIVE_UPDATE_AUTH';
const DRIVE_UPDATE_FILES = 'DRIVE_UPDATE_FILES';
const DRIVE_UPDATE_FILE = 'DRIVE_UPDATE_FILE';
const DRIVE_RESET_STATE = 'DRIVE_RESET_STATE';

const updateDriveAuth = (auth = {}) => {
  return {
    type: DRIVE_UPDATE_AUTH,
    payload: auth
  };
};

const updateDriveFiles = (files = []) => {
  return {
    type: DRIVE_UPDATE_FILES,
    payload: files
  };
};

const updateDriveFile = (file) => {
  return {
    type: DRIVE_UPDATE_FILE,
    payload: file
  };
};

const resetDriveState = () => {
  return {
    type: DRIVE_RESET_STATE
  };
};

export {
  updateDriveAuth,
  DRIVE_UPDATE_AUTH,
  updateDriveFiles,
  DRIVE_UPDATE_FILES,
  updateDriveFile,
  DRIVE_UPDATE_FILE,
  resetDriveState,
  DRIVE_RESET_STATE
};
