import React, { useCallback, useMemo, useState } from 'react';
import {
  FormComponent,
  EmailInputGroup,
  SelectInputGroup,
  FormInputGroup
} from '@/forms';
import { useClinicInvitableRoles } from './invitable-roles';
import { useObjectState } from '@/hooks';
import RoleStrings from '@/utilities/role-strings';
import Strings from './lang';

const initialFormState = {
  email: '',
  emailValid: false,
  firstName: '',
  lastName: '',
  role: ''
};

const ClinicInviteUserForm = ({
  clinic,
  onSubmit
}) => {
  const [formState, setFormState] = useObjectState(initialFormState);
  const [submitted, setSubmitted] = useState(false);
  const invitableRoles = useClinicInvitableRoles(clinic);
  const isValid = useMemo(() => (
    formState.emailValid
      && formState.firstName?.length
      && formState.lastName?.length
      && formState.role?.length
  ), [formState]);

  const handleSubmit = useCallback(() => {
    setSubmitted(true);

    if (!isValid) {
      return;
    }

    const {
      email, 
      emailValid, 
      role, 
      firstName, 
      lastName
    } = formState;

    const attributes = {
      email: (emailValid) ? email : '',
      role,
      clinic_id: clinic.id,
      person_attributes: {
        first_name: firstName,
        last_name: lastName
      }
    };

    if (onSubmit) {
      onSubmit(attributes);
    }
  }, [clinic?.id, formState, isValid, onSubmit]);

  return (
    <FormComponent className="clinic-invite-user-form" onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-md-6">
          <FormInputGroup
            required
            className="form-group"
            labelText={Strings.firstNameLabelText}
            inputType="text"
            inputProps={{
              className: 'form-control',
              value: formState.firstName,
              onChange: (e) => setFormState({ firstName: e.target.value })
            }}
            messageText={Strings.firstNameValidationMessage}
            messageClassName="alert alert-danger"
            inputValid={formState.firstName.length > 0}
            touched={submitted}
          />
        </div>
        <div className="col-md-6">
          <FormInputGroup
            required
            className="form-group"
            labelText={Strings.lastNameLabelText}
            inputType="text"
            inputProps={{
              className: 'form-control',
              value: formState.lastName,
              onChange: (e) => setFormState({ lastName: e.target.value })
            }}
            messageText={Strings.lastNameValidationMessage}
            messageClassName="alert alert-danger"
            inputValid={formState.lastName.length > 0}
            touched={submitted}
          />
        </div>
      </div>

      <EmailInputGroup
        className="form-group"
        labelText={Strings.emailLabelText}
        inputProps={{
          className: 'form-control'
        }}
        onUpdate={(email, emailValid) => {
          setFormState({ email, emailValid });
        }}
        messageClassName="alert alert-danger"
        required
        touched={submitted}
      />

      <SelectInputGroup
        className="form-group role-input-group"
        labelText={Strings.roleLabelText}
        inputProps={{
          className: 'form-control',
          value: formState.role
        }}
        onUpdate={role => {
          setFormState({ role });
        }}
        messageText={Strings.roleValidationMessage}
        messageClassName="alert alert-danger"
        required
        touched={submitted}
      >
        <option value="">{Strings.selectRoleOptionText}</option>
        {invitableRoles.map(role => (
          <option key={role} value={role}>{RoleStrings[role]}</option>
        ))}
      </SelectInputGroup>

      <div className="form-footer">
        <button className="btn btn-primary" type="submit" disabled={!isValid}>
          {Strings.submitButtonText}
        </button>
      </div>
    </FormComponent>
  );
};

export default ClinicInviteUserForm;
