import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const FormInputMessage = ({
  visible = false,
  className,
  text
}) => visible && text && (
  <div className={classNames('form-input-message', className)}>
    {text}
  </div>
);

FormInputMessage.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  visible: PropTypes.bool
};

export default FormInputMessage;
