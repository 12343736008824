import React from 'react';
import classNames from 'classnames';
import Switch from '../../../switch';

const BalanceTestTypeSelector = ({
  isWess = false,
  onToggle
}) => {
  return (
    <div className="balance-type-selector">
      <div className="balance-type-switch">
        <span
          className={classNames('type-label', isWess ? null : 'bold')}
          onClick={() => onToggle(false)}
        >
          BESS
        </span>
        <Switch
          toggled={isWess}
          onChange={onToggle}
        />
        <span
          className={classNames('type-label', isWess ? 'bold' : null)}
          onClick={() => onToggle(true)}
        >
          WESS
        </span>
      </div>
    </div>
  );
};

export default BalanceTestTypeSelector;
