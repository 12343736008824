import React from 'react';
import PropTypes from 'prop-types';

const BuffaloTestTableRow = ({
  minute,
  incline,
  heartRate,
  symptoms
}) => (
  <tr>
    <td>{minute}:00</td>
    <td>{incline}</td>
    <td>{heartRate}</td>
    <td>{symptoms}</td>
  </tr>
);

BuffaloTestTableRow.propTypes = {
  minute: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  incline: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  heartRate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  symptoms: PropTypes.string
};

export default BuffaloTestTableRow;
