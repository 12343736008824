const FR = {
  balanceTestTitle: 'Essais d\'équilibre',
  allTestsSelectorLabel: 'Tout',
  baselineTestsSelectorLabel: 'Référence',
  postInjuryTestsSelectorLabel: 'Post-blessure',
  allPosesSelectorLabel: 'Toutes les postures',
  overallScoreLabel: 'Score global',
  feetTogetherPoseLabel: 'Pose des pieds ensemble',
  tandemRightPoseLabel: 'Pose droite en tandem',
  tandemLeftPoseLabel: 'Pose tandem gauche',
  singleLegRightPoseLabel: 'Pose droite à une jambe',
  singleLegLeftPoseLabel: 'Pose à une jambe gauche',
  loadingTestsMessage: 'Chargement des tests d\'équilibre',
  testsEmptyMessage: 'Aucun test d\'équilibre trouvé',
  baselineLineLabel: 'Note de référence ({0}%)',
  baselineUpperStddevLabel: 'Référence supérieure Std. Déviation ({0}%)',
  baselineLowerStddevLabel: 'Référence inférieure Std. Déviation ({0}%)',
  baselineTagLabel: 'Référence',
  postInjuryTagLabel: 'Post-blessure'
};
 
export default FR;
