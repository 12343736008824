import React from 'react';
import PropTypes from 'prop-types';
import { If, Then } from 'react-if';

const BaselineDetailRow = ({
  testProps = {},
  compareProps = {},
  compare = false,
  component: ChildComponent
}) => (
  <div className="row">
    <div className="col-md-12">
      <div className="baseline-detail-row">
        <div className="row">
          <div className={compare ? 'col-md-6' : 'col-md-12'}>
            <ChildComponent {...testProps} />
          </div>
          <If condition={compare}>
            <Then>
              <div className="col-md-6">
                <ChildComponent {...compareProps} />
              </div>
            </Then>
          </If>
        </div>
      </div>
    </div>
  </div>
);

BaselineDetailRow.propTypes = {
  component: PropTypes.func.isRequired,
  testProps: PropTypes.object,
  compareProps: PropTypes.object,
  compare: PropTypes.bool,
  compareVisible: PropTypes.bool
};

export default BaselineDetailRow;
