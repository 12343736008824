const FR = {
  scoreTotalLabel: 'Total pour la mémoire',
  listSelectLabel: 'Liste de mots',
  trialLabel: 'Essai',
  defaultOption: 'Choisir une option',
  usedText: 'utilisé',
  defaultListOption: 'Choisir la liste',
  selectListErrorMessage: 'Veuillez choisir une liste de mots'
};
 
export default FR;
