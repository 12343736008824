const FR = {
  specialistNotesTitle: 'Note du médecin/spécialiste',
  specialistNotesListSubtitle: 'Notes du médecin/spécialiste pour blessure datées du {0}',
  specialistNotesListEmptyMessage: 'Aucune note de médecin/spécialiste n\'a été créée pour cette blessure.',
  startspecialistNotesLinkText: 'Ajouter une nouvelle note',
  openAllPanelsLabel: 'Ouvre tout',
  closeAllPanelsLabel: 'Ferme tout',
  notePanelHeading: 'Note du {0} - {1}',
  defaultSpecialistType: 'Médecin/Spécialiste',
  editButonText: 'Éditer',
  noSymptomsRecordedText: 'Aucun score de symptôme enregistré',
  symptomsSectionTitle: 'Symptômes',
  symptomsTotalText: 'Symptômes Ensemble',
  symptomsSeverityText: 'Gravité des symptômes',
  diagnosisChangedLabel: 'Diagnostic changé en',
  notesSectionTitle: 'Remarques',
  recoveryStagesSectionTitle: 'Étapes de récupération',
  recoveryStagesNotChangedText: 'Étapes de récupération inchangées',
  lastUpdatedText: 'Dernière mise à jour',
  lastUpdatedByText: 'Dernière mise à jour par',
  noteHistoryButtonText: 'Historique des notes',
  diagnosisLabel: 'Diagnostic',
  submitButtonText: 'Soumettre',
  notesPlaceholder: 'Entrez les notes ici ...',
  addSymptomScoreButton: 'Ajouter un score de symptôme',
  removeSymptomScoreButton: 'Retirer',
  noteCreatedByLabel: 'Créé par {0}',
  noteCreatorIconTitle: 'Cette note a été créée par vous',
  recoveryStagesNotUpdatedLabel: 'Les étapes de récupération n\'ont pas été mises à jour'
};
 
export default FR;
