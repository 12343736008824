const EN = {
  title: 'Clinic Statistics',
  totalPatientsLabelText: 'Total Patients:',
  expiredBaselinesLabelText: 'Expired Baseline Tests:',
  acuteLabelText: 'Acute:',
  subacuteLabelText: 'Subacute:',
  chronicLabelText: 'Chronic:',
  dayValueText: '{0} Day',
  daysValueText: '{0} Days',
  currentOpenInjuriesHeadingText: 'Current Open Injuries',
  averageRecoveryTimeHeadingText: 'Average Recovery Time',
  baselinesHeadingText: 'Baseline Tests by Calendar Year',
  injuriesHeadingText: 'Injuries Diagnosed by Calendar Year',
  totalBaselineText: 'Total Baselines:',
  totalInjuriesText: 'Total Diagnosed Injuries:',
  allStatsSubtitle: 'All Clinics'
};

export default EN;
