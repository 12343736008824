import { useEffect } from 'react';
import getRootHandle from '@/utilities/get-root-handle';

const BLUR_CLASSNAME = 'blur-content';

const BlurEffect = ({ 
  enabled = false, 
  node = getRootHandle()
}) => {
  useEffect(() => {
    if (node && enabled && !node.classList.contains(BLUR_CLASSNAME)) {
      node.classList.add(BLUR_CLASSNAME);
      return () => {
        node.classList.remove(BLUR_CLASSNAME);
      };
    }

    return undefined;
  }, [enabled, node]);

  return null;
};

export default BlurEffect;
