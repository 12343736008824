const FR = {
  generalHeadingText: 'Général',
  contactHeadingText: 'Communiquer',
  addressHeadingText: 'Adresse',
  billingAddressHeadingText: 'Adresse de facturation',
  actionsHeadingText: 'Actions rapides',
  usersActionHeadingText: 'Gérer les utilisateurs',
  addUserActionHeadingText: 'Ajouter un utilisateur',
  patientsActionHeadingText: 'Gérer les patients',
  bagtagsActionHeadingText: 'Gérer les numéros de cartes d’identité Concussion',
  editButtonText: 'Modifier',
  cancelButtonText: 'Annuler',
  activateClinicButtonText: 'Activer la clinique',
  activateClinicModalTitle: 'Activer la clinique',
  activateClinicModalMessage: 'Êtes-vous certain de vouloir activer {0} ?',
  activateClinicModalConfirmButtonTitle: 'Activer',
  deactivateClinicButtonText: 'Désactiver la clinique',
  deactivateClinicModalTitle: 'Désactiver la clinique',
  deactivateClinicModalMessage: 'Êtes-vous certain de vouloir désactiver {0} ?',
  deactivateClinicModalConfirmButtonTitle: 'Désactiver',
  viewAccountHeadingText: 'Solde du compte',
  creditsRemainingText: 'Crédits Restants',
  enableMapleButtonText: 'Activer Maple',
  mapleEnabledSuccessMessage: 'Maple a été activé pour {0}',
  disabledMapleButtonText: 'Désactiver Maple',
  mapleDisabledSuccessMessage: 'Maple a été désactivé pour {0}',
  editCreditPriceLabel: 'Modifier le prix du crédit',
  defaultPriceCheckboxLabel: 'Prix ​​par défaut',
  defaultLabel: 'Défaut',
  creditPriceLabel: 'Prix ​​du crédit',
  creditPriceErrorMessage: 'Veuillez saisir un prix supérieur ou égal à 0',
  submitButtonText: 'Soumettre',
  featuresTitle: 'Fonctionnalité',
  editFeaturesTitle: 'Modifier les fonctionnalités de la clinique',
  clinicMapLabel: 'Carte de la clinique',
  searchableInputLabelText: 'Afficher la clinique sur la carte?',
  axiaIdtProviderLabel: 'Fournisseur Axia IDT',
  resourcesEnabledLabel: 'Ressources activées',
  featuresUpdatedMessage: 'Fonctionnalités de la clinique mises à jour avec succès'
};
 
export default FR;
