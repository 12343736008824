import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { DISPLAY_DATE_TIME_FORMAT } from '@/utilities/dates/formats';
import BaselineMeta from './baseline-meta';
import ComparableUserBaselineView from './comparable-user-baseline-view';
import { FormInputMessage } from '@/forms';
import { DEFAULT_USER_LANGUAGE } from '@/utilities/localization';
import Strings from './lang';

const ActiveBaselineCompare = ({
  baseline = {}, 
  compareTest = {}, 
  user = {}, 
  showTitle = true,
  className
}) => {
  const person = user.person || {};
  const language = person.language || DEFAULT_USER_LANGUAGE;
  let compare = true;
  let noBaseline = false;
  let test = baseline;
  let testCompare = compareTest;

  if (Object.keys(baseline || {}).length === 0) {
    compare = false;
    noBaseline = true;
    test = compareTest;
    testCompare = undefined;
  }

  return (
    <div className={className}>
      {showTitle && (
        <div className="row">
          <div className="col-md-12">
            <h1 className="display">{person.first_name} {person.last_name}</h1>
            <h2>{(compare) ? Strings.compareTitle : Strings.reviewTitle}</h2>
          </div>
        </div>
      )}
      <div className="user-baseline-content">
        <BaselineMeta
          user={user}
          test={test}
          compareTest={testCompare}
          compare={compare}
        />
      </div>

      <FormInputMessage
        text={
          Strings.formatString(
            Strings.noActiveBaseline,
            dayjs(test.created_at).format(DISPLAY_DATE_TIME_FORMAT)
          )
        }
        visible={noBaseline}
        className="alert alert-info"
      />

      <ComparableUserBaselineView
        compare={compare}
        compareTest={testCompare}
        test={test}
        language={language}
      />
    </div>
  );
};

ActiveBaselineCompare.propTypes = {
  user: PropTypes.object,
  baseline: PropTypes.object,
  compareTest: PropTypes.object
};

export default ActiveBaselineCompare;
