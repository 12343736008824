import React, { useMemo } from 'react';
import { getBulkItemDescription, getPaidAmount } from './utilities';
import { Money } from '@/utilities/currency';
import Strings from './lang';

const LineItemsSummary = ({ lineItems = [] }) => {
  return (
    <ul className="summary-line-items">
      {lineItems.map((item, index) => (
        <li key={index} className="item">
          <div className="item-row">
            <div className="semi-bold">{getBulkItemDescription(item.type, item.description)}</div>
            <div>{item.amount}</div>
          </div>
          <div className="text-muted">
            {item.qty} @ {item.rate}
          </div>
        </li>
      ))}
    </ul>
  );
};

const InvoiceSummaryFooter = ({
  subtotal,
  taxes = [],
  total,
  currency,
  payments = [],
  balance
}) => {
  const paidAmount = useMemo(() => (
    Money.format(getPaidAmount(payments), currency)
  ), [currency, payments]);

  return (
    <div className="summary-footer">
      <div className="footer-row">
        <div className="row-label bold">{Strings.subtotalLabel}</div>
        <div className="row-value">{subtotal}</div>
      </div>
      {taxes.map(tax => (
        <div key={tax.description} className="footer-row">
          <div className="row-label">{tax.description}</div>
          <div className="row-value">{tax.formatted}</div>
        </div>
      ))}
      <div className="footer-row">
        <div className="row-label bold">{Strings.totalLabel}</div>
        <div className="row-value">{total}</div>
      </div>
      <div className="footer-row">
        <div className="row-label bold">{Strings.amountPaidLabel}</div>
        <div className="row-value">{paidAmount}</div>
      </div>
      <div className="footer-row large">
        <div className="row-label bold">{Strings.balanceLabel}</div>
        <div className="row-value">{balance}</div>
      </div>
    </div>
  );
};

const InvoiceSummarySidebar = ({ 
  invoice = {}, 
  lineItems 
}) => {
  const {
    title,
    owing,
    amount,
    total,
    taxes = [],
    payments = [],
    currency
  } = invoice || {};

  return (
    <div className="invoice-summary-sidebar">
      <div className="summary-header">
        <h1>{Strings.formatString(Strings.invoiceSummaryTitle, title)}</h1>
      </div>
      <LineItemsSummary lineItems={lineItems} />
      <InvoiceSummaryFooter 
        subtotal={amount}
        taxes={taxes}
        total={total}
        currency={currency}
        payments={payments}
        balance={owing}
      />
    </div>
  );
};

export default InvoiceSummarySidebar;
