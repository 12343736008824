import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormInputGroup } from '@/forms';
import { restingHRValidator, MIN_HEART_RATE, MAX_HEART_RATE } from '@/utilities/resting-hr-validator';
import Strings from './lang';

class RestingHeartRateInput extends Component {
  constructor(props) {
    super(props);

    const { value = '' } = props;
    const valid = restingHRValidator(value);

    this.state = {
      valid
    };
  }

  componentDidMount() {
    const valid = restingHRValidator(this.props.value || '');

    this.setState({
      valid
    });

    if (valid) {
      this.props.onUpdate(this.props.value, valid);
    }
  }

  render() {
    return (
      <div className={this.props.className}>
        {!this.state.valid && (
          <div className="section section-primary">
            <p>
              {Strings.formatString(
                Strings.startTestInfoText, 
                this.props.testName, 
                MIN_HEART_RATE,
                MAX_HEART_RATE
              )}
            </p>
          </div>
        )}

        <div className="row">
          <div className="col-md-4">
            <FormInputGroup
              className="form-group"
              labelText={Strings.restingHeartRateLabel}
              inputType="text"
              inputProps={{
                className: 'form-control',
                value: this.props.value || '',
                onBlur: this.onUpdate.bind(this),
                onChange: this.onUpdate.bind(this)
              }}
              required={this.props.required}
              messageClassName="alert alert-danger"
              messageText={Strings.formatString(
                Strings.restingHRErrorMessage, 
                MIN_HEART_RATE, 
                MAX_HEART_RATE
              )}
              inputValid={this.state.valid}
              touched={this.props.touched}
            />
          </div>
        </div>
      </div>
    );
  }

  onUpdate(e) {
    const { value } = e.target;
    const valid = restingHRValidator(value);

    this.setState({
      valid
    });

    this.props.onUpdate(value, valid);
  }
}

RestingHeartRateInput.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  testName: PropTypes.string,
  touched: PropTypes.bool,
  required: PropTypes.bool
};

export default RestingHeartRateInput;
