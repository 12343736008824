import React from 'react';
import ReferralCardDetails from './details';
import ReferralCardHeader from './header';
import ReferralCardFooter from './footer';

const ReferralCard = ({ 
  referral = {}, 
  linksEnabled = true, 
  onAccept,
  onReject,
  onCancel = () => {} 
}) => {
  const { 
    status, 
    message,
    referrer,
    created_at 
  } = referral;

  return (
    <div className="referral-card">
      <ReferralCardHeader 
        status={status}
        date={created_at}
        referrer={referrer}
        onCancel={() => onCancel(referral.id)}
      />
      <ReferralCardDetails 
        referral={referral}
        linksEnabled={linksEnabled}
        onAccept={onAccept}
        onReject={onReject}
      />
      <ReferralCardFooter message={message} />
    </div>
  );
};

export default ReferralCard;
