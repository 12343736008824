import { useCallback, useEffect } from 'react';

export const useClickOutside = (ref, handler, active) => {
  const handleClick = useCallback((event) => {
    const { target } = event;

    if (event.defaultPrevented) return;

    if (target && ref.current && !ref.current.contains(target)) {
      handler(target);
    }
  }, [handler, ref]);

  useEffect(() => {
    if (active) {
      window.addEventListener('click', handleClick);

      return () => {
        window.removeEventListener('click', handleClick);
      };
    }

    return undefined;
  }, [active, handleClick]);
};
