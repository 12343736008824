import currencyFormatter from 'currency-formatter';

const mapCountryCodeToCurrency = (country) => {
  switch (country) {
    case 'CA':
      return 'CAD';
    default: 
      return 'USD';
  }
};

const getClinicCurrency = (clinic = {}) => {
  const { address = {} } = clinic || {};
  const { country } = address || {};
  return mapCountryCodeToCurrency(country);
};

const Money = {
  format(amount, currency) {
    return currencyFormatter.format(amount, { code: currency });
  },
  unformat(amount, currency) {
    return currencyFormatter.unformat(amount, { code: currency });
  },
  add(currency, ...values) {
    return this.format(
      values.reduce((acc, value) => (
        acc + this.unformat(value, currency)
      ), 0.0),
      currency
    );
  },
  subtract(currency, ...values) {
    const [first, ...rest] = values;
    return this.format(
      rest.reduce((acc, value) => (
        acc - this.unformat(value, currency)
      ), this.unformat(first, currency)),
      currency
    );
  }
};

export {
  mapCountryCodeToCurrency,
  getClinicCurrency,
  Money
};
