import React, { useState } from 'react';
import { DateRange } from 'react-date-range';
import { isRangeValid } from './ranges';
import RangeView from './range-view';

const defaultCustomRange = {
  startDate: null,
  endDate: new Date(''),
  rangeKey: 'custom'
};

const setInitialRange = (range) => {
  if (range.rangeKey !== 'custom') {
    return { ...range, ...defaultCustomRange };
  }

  return range;
};

const DateRangeCalendar = ({ range: propsRange, onApply }) => {
  const [range, setRange] = useState(() => setInitialRange(propsRange));

  return (
    <div>
      <DateRange 
        onChange={item => setRange(item[range.key])}
        ranges={[range]}
        months={2}
        maxDate={new Date()}
        showDateDisplay={false}
        rangeColors={['#DB64E0']}
        direction="horizontal"
      />
      <div className="date-range-footer">
        <RangeView range={range} />
        <button 
          type="button" 
          className="btn btn-sm btn-primary" 
          disabled={!isRangeValid(range)}
          onClick={() => {
            onApply(range);
          }}
        >
          Apply
        </button>
      </div>
    </div>
  );
};

export default DateRangeCalendar;
