const FR = {
  title: 'Confirmer votre compte',
  emailLabelText: 'Courriel :',
  emailInputPlaceholder: 'nom@exemple.com',
  confirmButtonText: 'Envoyer le courriel de confirmation',
  termsErrorText: 'Vous devez accepter les termes\u0026 pour vous enregistrer.',
  confirmationPendingText: 'Veuillez patienter pendant que nous confirmons votre compte.',
  confirmationSuccessText: 'Votre compte a été confirmé',
  confirmationFailedText: 'Nous n’avons pu confirmer votre compte',
  authenticationLinkText: 'Connexion',
  resendLinkText: 'Renvoyer le courriel de confirmation',
  resendRequestSuccessText: 'Nous vous avons envoyé de nouveau le lien de confirmation. Veuillez vérifier vos courriels.'
};
 
export default FR;
