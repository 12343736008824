import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { FormComponent } from '@/forms';
import BloodPressureInputGroup from './bp-input-group';
import HeartRateInputGroup from './hr-input-group';
import Strings from './lang';
import Icon from '../icon';

const getStateFromTest = (test) => {
  return {
    supine: { 
      hr: test?.supine_hr || '',
      bp_systolic: test?.supine_bp_systolic || '',
      bp_diastolic: test?.supine_bp_diastolic || ''
    },
    seated: {
      hr: test?.seated_hr || '',
      bp_systolic: test?.seated_bp_systolic || '',
      bp_diastolic: test?.seated_bp_diastolic || ''
    },
    standing: {
      hr: test?.standing_hr || '',
      bp_systolic: test?.standing_bp_systolic || '',
      bp_diastolic: test?.standing_bp_diastolic || ''
    }
  };
};

const convertAttributes = (attrs) => {
  const { supine, seated, standing } = attrs;

  return {
    supine_hr: supine.hr,
    supine_bp_systolic: supine.bp_systolic,
    supine_bp_diastolic: supine.bp_diastolic,
    seated_hr: seated.hr,
    seated_bp_systolic: seated.bp_systolic,
    seated_bp_diastolic: seated.bp_diastolic,
    standing_hr: standing.hr,
    standing_bp_systolic: standing.bp_systolic,
    standing_bp_diastolic: standing.bp_diastolic
  };
};

const OVSTestStep = ({
  step,
  title,
  description,
  values = {},
  onUpdate,
  submitted = false,
  isComplete = false
}) => {
  return (
    <div className="ovs-test-step">
      <div className="ovs-test-step-header">
        <div className={classNames('ovs-test-step-index', { complete: isComplete })}>
          {isComplete ? <Icon name="check" /> : <span>{step}</span>}
        </div>
        <div className="ovs-test-step-title">
          <span className="step">{Strings.stepLabel} {step}:</span> {title}
        </div>
      </div>
      <div className="ovs-test-step-section">
        <p className="text-muted">
          {description}
        </p>
        <div className="row">
          <div className="col-md-6">
            <HeartRateInputGroup
              heartRate={values.hr}
              touched={submitted}
              onUpdate={hr => {
                onUpdate({ ...values, hr });
              }}
            />
          </div>
          <div className="col-md-6">
            <BloodPressureInputGroup
              submitted={submitted}
              systolic={values.bp_systolic}
              diastolic={values.bp_diastolic}
              onUpdateSystolic={bp_systolic => {
                onUpdate({
                  ...values,
                  bp_systolic
                });
              }}
              onUpdateDiastolic={bp_diastolic => {
                onUpdate({
                  ...values,
                  bp_diastolic
                });
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const isSectionComplete = (values) => {
  return !isNaN(values.hr) && values.hr > 0
    && !isNaN(values.bp_systolic) && values.bp_systolic > 0
    && !isNaN(values.bp_diastolic) && values.bp_diastolic > 0;
};

const OrthostaticVitalSignsTestForm = ({
  test = {},
  onSubmit = () => {}
}) => {
  const [attrs, setAttrs] = useState(() => getStateFromTest(test));
  const [submitted, setSubmitted] = useState(false);
  const isSupineComplete = useMemo(() => isSectionComplete(attrs.supine), [attrs.supine]);
  const isSeatedComplete = useMemo(() => isSectionComplete(attrs.seated), [attrs.seated]);
  const isStandingComplete = useMemo(() => isSectionComplete(attrs.standing), [attrs.standing]);

  const isValid = isSupineComplete && isSeatedComplete && isStandingComplete;

  const handleSubmit = useCallback(() => {
    setSubmitted(true);

    if (!isValid) {
      return;
    }

    onSubmit(
      convertAttributes(attrs)
    );
  }, [attrs, isValid, onSubmit]);

  return (
    <FormComponent className="ovs-form" onSubmit={handleSubmit}>
      <div className="ovs-test-steps">
        <OVSTestStep
          step={1}
          title={Strings.supineLabel}
          description={Strings.supineInstructions}
          values={attrs.supine}
          submitted={submitted}
          isComplete={isSupineComplete}
          onUpdate={supine => setAttrs(a => ({ ...a, supine }))}
        />
        <OVSTestStep
          step={2}
          title={Strings.seatedLabel}
          description={Strings.seatedInstructions}
          values={attrs.seated}
          submitted={submitted}
          isComplete={isSeatedComplete}
          onUpdate={seated => setAttrs(a => ({ ...a, seated }))}
        />
        <OVSTestStep
          step={3}
          title={Strings.standingLabel}
          description={Strings.standingInstructions}
          values={attrs.standing}
          submitted={submitted}
          isComplete={isStandingComplete}
          onUpdate={standing => setAttrs(a => ({ ...a, standing }))}
        />
      </div>
      <div className="form-footer">
        <button
          type="submit"
          disabled={!isValid}
          className="btn btn-primary"
        >
          {Strings.submitText}
        </button>
      </div>
    </FormComponent>
  );
};

export default OrthostaticVitalSignsTestForm;
