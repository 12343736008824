import React from 'react';
import Strings from './lang';

const BlackhawksStationaryBikeStageHeaderRow = () => (
  <tr>
    <th className="no-action">{Strings.stageTimeHeaderText}</th>
    <th className="no-action">{Strings.tensionLevelsHeaderText}</th>
    <th className="no-action">{Strings.suggestedRpmHeaderText}</th>
    <th className="no-action">{Strings.heartRateHeaderText}</th>
    <th className="no-action">{Strings.symptomsHeaderText}</th>
  </tr>
);

export default BlackhawksStationaryBikeStageHeaderRow;
