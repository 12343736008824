import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { If, Then } from 'react-if';
import Cropper from 'react-cropper';
import FileReaderInput from 'react-file-reader-input';
import classNames from 'classnames';
import { validImageTypes, isValidImageType } from './image-types';
import Strings from './lang';

class ImageCropper extends Component {
  constructor(props) {
    super(props);

    // The `ready` and `cropstart` props require a consistent reference,
    // so we must bind it in the constructor:
    this.onConfirmCrop = this.onConfirmCrop.bind(this);
    this.onChooseFile = this.onChooseFile.bind(this);
    this.cropper = React.createRef();

    this.state = {
      file: null,
      src: ''
    };
  }

  render() {
    return (
      <div className={classNames('image-cropper', this.props.className)}>
        <div className="image-cropper-content">
          <Cropper 
            ref={this.cropper}
            {...this.mergeCropperOptions()}
            className="image-cropper-view"
            src={this.state.src}
          />
        </div>
        <div className="image-cropper-controls">

          <FileReaderInput 
            as="url" 
            accept={validImageTypes.join(',')}
            onChange={this.onChooseFile}
          >
            <button type="button" className={classNames('image-cropper-choose', this.props.chooseButtonClassName)}>
              {this.props.chooseButtonText || Strings.chooseButtonText}
            </button>
          </FileReaderInput>

          <If condition={this.props.manualCrop === true}>
            <Then>
              <button 
                type="button" 
                className={classNames('image-cropper-crop', this.props.cropButtonClassName)}
                onClick={this.onConfirmCrop}
                disabled={!this.state.file}
              >
                {this.props.cropButtonText || Strings.cropButtonText}
              </button>
            </Then>
          </If>

        </div>
      </div>
    );
  }

  mergeCropperOptions() {
    const { cropperOptions = {} } = this.props;

    if (this.props.manualCrop) {
      return cropperOptions;
    } 

    return { 
      ...cropperOptions,
      ready: this.onConfirmCrop,
      cropend: this.onConfirmCrop
    };
  }

  onChooseFile(e, result) {
    const [progress, file] = result[0];
    const fileReader = progress.target;

    // Abort if the file could not be read:
    if (fileReader.error) {
      if (typeof this.props.onFileReadError === 'function') {
        this.props.onFileReadError(fileReader.error);
      }
      return;
    }

    // Abort if the file is not a valid image:
    if (!isValidImageType(file.type)) {
      if (typeof this.props.onFileTypeError === 'function') {
        this.props.onFileTypeError(file.type, validImageTypes);
      }
      return;
    }

    // File has loaded:
    if (fileReader.readyState === 2) {
      this.setState({
        file,
        src: fileReader.result
      });
    }
  }

  onConfirmCrop() {
    const { file } = this.state;

    if (file) {
      const { cropper } = this.cropper.current || {};
      // Get the cropped image:
      const dataURI = cropper?.getCroppedCanvas()?.toDataURL();
      if (typeof this.props.onCrop === 'function') {
        this.props.onCrop(file, dataURI);
      }
    }
  }
}

ImageCropper.propTypes = {
  className: PropTypes.string,
  inputName: PropTypes.string.isRequired,
  chooseButtonText: PropTypes.string,
  chooseButtonClassName: PropTypes.string,
  cropButtonText: PropTypes.string,
  cropButtonClassName: PropTypes.string,
  cropperOptions: PropTypes.object,
  onCrop: PropTypes.func,
  onFileReadError: PropTypes.func,
  onFileTypeError: PropTypes.func,
  manualCrop: PropTypes.bool
};

export default ImageCropper;
