import React from 'react';
import PropTypes from 'prop-types';
import { RadioInputGroup } from '@/forms';
import symptomInputPropTypes from './symptom-input-proptypes';
import { AddNoteButton } from '../buttons';
import Strings from './lang';

/*!
 * Component is used in the baseline/repeat baseline SymptomLevels component.
 * Renders a RadioInputGroup as the symptom severity selector.
 * The lowest level component in the symptoms hierarchy. 
 * Stateless component: all state gets passed up to parents and then
 * passed back in as props.
 */
const SymptomRadioInput = ({
  label,
  name,
  value,
  values,
  touched,
  notes,
  onUpdate = () => {},
  onUpdateNotes = () => {}
}) => {
  return (
    <div className="baseline-card-row">
      <RadioInputGroup
        titleLabelProps={{
          className: 'baseline-row-label'
        }}
        titleLabelText={label}
        radioValues={values}
        initialValue={value.toString()}
        className="baseline-radio-input"
        inputValid={value !== ''}
        touched={touched}
        messageText={Strings.formatString(Strings.requiredError, label)}
        messageClassName="alert alert-danger"
        onUpdate={(value) => onUpdate(name, value)}
      />
      <div className="symptom-add-note">
        <AddNoteButton 
          note={notes}
          title={Strings.formatString(Strings.notesModalTitle, label)}
          submitButtonTitle={Strings.addNoteButtonText}
          onSave={(notes) => {
            onUpdateNotes(name, notes);
          }}
        />
      </div>
    </div>
  );
};

SymptomRadioInput.propTypes = {
  ...symptomInputPropTypes,
  onUpdateNotes: PropTypes.func
};

export default SymptomRadioInput;
