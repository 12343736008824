import React from 'react';
import { BorgScaleHelpModalLink, SymptomScoreHelpModalLink } from './buffalo-test-help-modal-links';
import Strings from '../lang';

const BuffaloTestV2FormHeader = () => (
  <tr>
    <th className="no-action">{Strings.timeLabel}</th>
    <th className="no-action">{Strings.speedLabel}</th>
    <th className="no-action">{Strings.inclineLabel}</th>
    <th className="no-action">{Strings.heartRateRowLabel}</th>
    <th className="no-action">{Strings.borgScaleLabel} <strong>{Strings.borgScaleInfoText}</strong>&nbsp;&nbsp;<BorgScaleHelpModalLink /></th>
    <th className="no-action">{Strings.symptomScaleLabel}&nbsp;&nbsp;<SymptomScoreHelpModalLink /></th>
    <th className="no-action">{Strings.symptomsNotesLabel}</th>
  </tr>
);

export default BuffaloTestV2FormHeader;
