import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Fraction = ({
  numerator,
  denominator,
  className
}) => (
  <span className={classNames('fraction', className)}>
    <sup>{numerator}</sup>&#8260;<sub>{denominator}</sub>
  </span>
);

Fraction.propTypes = {
  className: PropTypes.string,
  numerator: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  denominator: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
};

export default Fraction;
