import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import FormInputGroup from './form-input-group';
import PasswordScore from './password-score';
import { PasswordValidator } from '../validators/password-validator';
import Strings from './lang';

class PasswordInputGroup extends Component {
  constructor(props) {
    super(props);

    this.zxcvbn = null;
    this.onUpdate = this.onUpdate.bind(this);
    this.onConfirmUpdate = this.onConfirmUpdate.bind(this);

    this.state = {
      zxcvbnLoaded: false,
      value: '',
      confirmValue: '',
      valid: false,
      confirmValid: false,
      score: 0
    };
  }

  componentDidMount() {
    if (this.props.showPasswordScore) {
      import(/* webpackPreload: true */ 'zxcvbn').then(module => {
        this.zxcvbn = module.default;
        this.setState({ zxcvbnLoaded: true });
      });
    }
  }

  render() {
    const {
      required = true,
      className,
      labelText = Strings.passwordLabelText,
      labelProps,
      messageText = Strings.passwordErrorText,
      messageClassName,
      touched
    } = this.props;

    return (
      <div className="password-group-container">
        <FormInputGroup
          required={required}
          className={classNames('password-input-group', className)}
          labelText={labelText}
          labelProps={labelProps}
          inputType="password"
          inputProps={this.mergeInputProps()}
          inputValid={this.state.valid}
          messageText={messageText}
          messageClassName={messageClassName}
          touched={touched}
        />
        {this.renderConfirmPassword()}
        {this.renderPasswordScore()}
      </div>
    );
  }

  renderConfirmPassword() {
    if (!this.props.showConfirmInput) {
      return null;
    }

    return (
      <FormInputGroup
        required
        className={classNames('confirm-password-input-group', this.props.className)}
        labelText={this.props.confirmLabelText || Strings.confirmPasswordLabelText}
        labelProps={this.props.confirmLabelProps}
        inputType="password"
        inputProps={this.mergeConfirmInputProps()}
        inputValid={this.state.confirmValid}
        messageText={this.props.confirmMessageText || Strings.confirmPasswordErrorText}
        messageClassName={this.props.messageClassName}
        touched={this.props.touched}
      />
    );
  }

  renderPasswordScore() {
    const { zxcvbnLoaded, value, score } = this.state;

    if (!this.props.showPasswordScore || !zxcvbnLoaded || !this.zxcvbn || value.length === 0) {
      return null;
    }

    return (
      <PasswordScore score={score} />
    );
  }

  mergeInputProps() {
    return {
      ...(this.props.inputProps || {}),
      onBlur: this.onUpdate,
      onChange: this.onUpdate
    };
  }

  mergeConfirmInputProps() {
    return {
      ...(this.props.confirmInputProps || {}),
      onBlur: this.onConfirmUpdate,
      onChange: this.onConfirmUpdate
    };
  }

  onUpdate(event) {
    const { value } = event.target;
    const valid = PasswordValidator.test(value, this.props.showConfirmInput);
    const score = this._calculatePasswordScore(value);
    let { confirmValid } = this.state;
    let reportValid = valid;

    if (this.props.showConfirmInput) {
      confirmValid = (value === this.state.confirmValue);
      reportValid = (valid && confirmValid);
    }

    this.setState({
      value,
      valid,
      confirmValid,
      score
    });

    if (typeof this.props.onUpdate === 'function') {
      this.props.onUpdate(value, reportValid);
    }
  }

  onConfirmUpdate(event) {
    const { value } = this.state;
    const confirmValue = event.target.value;
    const confirmValid = (confirmValue === value);
    const reportValid = (this.state.valid && confirmValid);

    this.setState({
      confirmValue,
      confirmValid
    });

    if (typeof this.props.onUpdate === 'function') {
      this.props.onUpdate(value, reportValid);
    }
  }

  _calculatePasswordScore(password) {
    if (this.props.showPasswordScore && this.state.zxcvbnLoaded && this.zxcvbn) {
      try {
        const result = this.zxcvbn(password);
        return result.score;
      } catch (e) {
        return 0;
      }
    } else {
      return 0;
    }
  }
}

PasswordInputGroup.propTypes = {
  labelText: PropTypes.string,
  labelProps: PropTypes.object,
  inputProps: PropTypes.object,
  messageText: PropTypes.string,
  messageClassName: PropTypes.string,
  confirmLabelText: PropTypes.string,
  confirmLabelProps: PropTypes.object,
  confirmInputProps: PropTypes.object,
  confirmMessageText: PropTypes.string,
  showConfirmInput: PropTypes.bool,
  showPasswordScore: PropTypes.bool,
  touched: PropTypes.bool,
  onUpdate: PropTypes.func
};

export default PasswordInputGroup;
