const EN = {
  title: 'Team Leaders',
  activateUserModalTitle: 'Activate Account',
  activateUserModalMessage: 'Are you sure you want to activate {0}\'s account?',
  activateUserModalConfirmButtonTitle: 'Activate',
  deactivateUserModalTitle: 'Deactivate Account',
  deactivateUserModalMessage: 'Are you sure you want to deactivate {0}\'s account?',
  deactivateUserModalConfirmButtonTitle: 'Deactivate',
  accountDeactivatedBannerMessage: 'This account has been deactivated',
  deactivateUserButtonText: 'Deactivate Account',
  activateUserButtonText: 'Activate Account',
  coachOrTrainerText: 'Coach/Trainer'
};

export default EN;
