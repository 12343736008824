import React, { Component } from 'react';
import classNames from 'classnames';
import FormInputGroup from './form-input-group';
import { NumberValidator } from '../validators/number-validator';
import Strings from './lang';

class NumberInputGroup extends Component {
  constructor(props) {
    super(props);

    const value = this.mergeInputProps().value || 0;
    const valid = NumberValidator.test(value) || (value.length === 0 && !props.required);

    this.onUpdate = this.onUpdate.bind(this);

    this.state = {
      valid
    };
  }

  render() {
    return (
      <FormInputGroup
        className={classNames('number-input-group', this.props.className)}
        labelText={this.props.labelText || Strings.numberLabelText}
        labelProps={this.props.labelProps}
        inputType="number"
        inputProps={this.mergeInputProps()}
        inputValid={this.state.valid}
        messageText={this.props.messageText || Strings.numberErrorText}
        messageClassName={this.props.messageClassName}
        required={this.props.required}
        touched={this.props.touched}
      />
    );
  }

  mergeInputProps() {
    return {
      ...(this.props.inputProps || {}),
      onBlur: this.onUpdate,
      onChange: this.onUpdate 
    };
  }

  onUpdate(event) {
    const { required, onUpdate, inputProps } = this.props;
    const { min = Number.MIN_SAFE_INTEGER, max = Number.MAX_SAFE_INTEGER } = inputProps;
    const { value } = event.target;
    const valueParsed = parseFloat(value);
    const valid = (NumberValidator.test(value) && (valueParsed >= min && valueParsed <= max)) 
      || (value.length === 0 && !required);

    this.setState({
      valid
    });

    if (typeof onUpdate === 'function') {
      onUpdate(value, valid);
    }
  }
}

NumberInputGroup.propTypes = FormInputGroup.propTypes;

export default NumberInputGroup;
