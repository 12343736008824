import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const FormComponent = React.forwardRef(({
  className = '', 
  autoComplete,
  formProps = {}, 
  onSubmit,
  children
}, ref) => {
  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();

    if (typeof onSubmit === 'function') {
      onSubmit(e);
    }
  }, [onSubmit]);

  return (
    <form 
      ref={ref}
      autoComplete={autoComplete}
      className={classNames('form', className)}
      onSubmit={handleSubmit}
      {...formProps}
    >
      {children}
    </form>
  );
});

FormComponent.propTypes = {
  className: PropTypes.string,
  onSubmit: PropTypes.func,
  formProps: PropTypes.object
};

export default FormComponent;
