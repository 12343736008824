const EN = {
  concussion_with_unconsciousness: 'Concussion with loss of consciousness',
  concussion_without_unconsciousness: 'Concussion without loss of consciousness',
  post_concussion_syndrome: 'Post Concussion Syndrome',
  chronic_whiplash: 'Chronic Whiplash',
  whiplash: 'Whiplash (WAD)',
  none: 'No Diagnosis at this time',
  other: 'Other... (details in notes)',
  notes: 'Notes',
  physiologic: 'Physiologic PCS',
  cervicogenic: 'Cervicogenic PCS',
  vestibulo_ocular: 'Vestibulo-Ocular PCS',
  unknown_origin: 'PCS of Unknown Origin',
  diagnosisLabel: 'Diagnosis',
  diagnosisErrorMessage: 'Must select a diagnosis',
  noDiagnosisFound: 'No Diagnosis Provided',
  diagnosisNotesLabelText: 'Diagnosis Notes:',
  diagnosisHeadingText: 'Diagnosis:',
  editButtonText: 'Edit',
  defaultDiagnosisText: 'None provided',
  diagnosisModalHeaderText: 'Change Injury Diagnosis',
  saveButtonText: 'Save',
  cancelButtonText: 'Cancel'
};

export default EN;
