import React, { useCallback, useEffect, useState } from 'react';
import { FormComponent, FormInput } from '@/forms';
import Icon from '../icon';
import { useDebounceFn } from '@/hooks';
import Strings from './lang';

const SearchForm = ({ 
  value, 
  delay = 0,
  onChange = () => { } 
}) => {
  const [search, setSearch] = useState(value);

  const onReset = useCallback(() => {
    if (value) {
      onChange('');
    }
  }, [value, onChange]);

  const debouncedOnChange = useDebounceFn(onChange, delay);

  const handleChange = useCallback((e) => {
    const { value = '' } = e.target || {};
    setSearch(value);
    debouncedOnChange(value);
  }, [debouncedOnChange]);

  useEffect(() => {
    setSearch(value);
  }, [value]);

  return (
    <FormComponent className="clinics-search-form">
      <label>
        <FormInput
          className="form-control"
          type="text"
          inputProps={{
            value: search,
            placeholder: Strings.searchInputPlaceholder,
            onChange: handleChange
          }}
        />
        <button
          type="button"
          onClick={onReset}
        >
          <Icon name="xmark" />
        </button>
      </label>
    </FormComponent>
  );
};

export default SearchForm;
