import React from 'react';
import { Link } from 'react-router-dom';
import { Page } from '../page';
import { RegistrationPaths } from '@/paths';
import Strings from './lang';

const Unconfirmed = () => (
  <Page className="unconfirmed" hideNavigationContent>
    <div className="row">
      <div className="col-md-12">
        <h1 className="display">{Strings.title}</h1>
      </div>
    </div>
    <div className="row">
      <div className="col-md-12">
        <p>{Strings.message}</p>
        <p>
          <Link to={RegistrationPaths.confirm.index.link}>
            {Strings.confirmLinkText}
          </Link>
        </p>
      </div>
    </div>
  </Page>
);

export default Unconfirmed;
