const UPDATE_MEDICAL_INFO = 'UPDATE_MEDICAL_INFO';

const updateMedicalInfo = (medicalInfo) => ({
  type: UPDATE_MEDICAL_INFO,
  medicalInfo
});

export {
  UPDATE_MEDICAL_INFO,
  updateMedicalInfo
};
