const EN = {
  dominantFootLabel: 'Dominant Foot',
  leftFootOption: 'Left',
  rightFootOption: 'Right',
  defaultOption: 'Select One',
  dominantFootErrorMsg: 'Please select the dominant foot',
  errorsInputLabel: 'Errors',
  feetTogetherTestLabel: 'Feet Together',
  singleLegTestLabel: 'Single Leg Stance (Non-Dominant Foot)',
  tandemTestLabel: 'Tandem Stance (Dominant Foot in Front)',
  foamTextLabel: 'Foam Pad (Non-Dominant Foot) (Optional - will not be added to totals)',
  bessTotalScoreLabel: 'BESS Totals:',
  defaultSelect: 'Select One',
  notDoneSelect: 'Not Done',
  wessTotalScoreLabel: 'WESS Totals:',
  wessSeatedLabel: 'Seated',
  wessBalanceDiskLabel: 'Balance Disk',
  wessWheelieLabel: 'Wheelie',
  eyesOpenErrorsLabel: 'Eyes Open Errors',
  eyesClosedErrorsLabel: 'Eyes Closed Errors'
};

export default EN;
