import { ADD_BATCH, UPDATE_BATCH, UPDATE_BATCHES } from '../actions/batches';

const addBatchReducer = (state = {}, action = {}) => {
  const { batch = {} } = action;
  const { id = 0 } = batch;

  return (id) ? { ...state, [id]: batch } : state;
};

const updateBatchReducer = (state = {}, action = {}) => {
  const { batch = {} } = action;
  const { id = 0 } = batch;

  return (id) ? { ...state, [id]: batch } : state;
};

const updateBatchesReducer = (state = {}, action = {}) => {
  const { batches = [] } = action;
  return (batches) ? { 
    ...state, 
    ...batches.reduce((acc, cur) => {
      return {
        ...acc,
        [cur.id]: cur
      };
    }, {})
  } : state;
};

const batches = (state = {}, action = {}) => {
  switch (action.type) {
    case ADD_BATCH:
      return addBatchReducer(state, action);
    case UPDATE_BATCH:
      return updateBatchReducer(state, action);
    case UPDATE_BATCHES:
      return updateBatchesReducer(state, action);
    default:
      return state;
  }
};

export default batches;
