const FR = {
  title: 'Test du tapis roulant de Buffalo',
  saveButtonText: 'Enregistrer le test du tapis roulant de Buffalo',
  symptomsLabel: 'Symptômes',
  bpmPlaceHolderText: 'BPM',
  timeLabel: 'Durée (minutes)',
  heartRateRowLabel: 'Fréquence cardiaque (BPM)',
  inclineLabel: 'Inclinaison (%)',
  heartRateLabel: 'Fréquence cardiaque à laquelle les symptômes se sont aggravés (BPM)',
  restingHeartRateLabel: 'Fréquence cardiaque au repos',
  symptomExacerbationLabel: 'Aggravation des symptômes ?',
  symptomExacerbationHeading: 'Aggravation des symptômes',
  yesLabel: 'Oui',
  noLabel: 'Non',
  coolDownText: 'Retour au calme',
  stopTestText: 'Arrêter le test',
  buffaloInfoText: '"Ceci est un protocole d’effort physique sur tapis roulant, conçu pour mesurer votre état de récupération physiologique et votre habileté à revenir de façon sécuritaire aux exercices sans contact. Cette station vous fera marcher sur un tapis roulant à une vitesse de 5,3 km/h (3,3 mi/h) Après chaque minute, le tapis roulant se mettra à incliner légèrement de 1 % et on vous demandera si vous ressentez une augmentation de vos symptômes et quel est votre niveau d’effort physique (entre 6 et 20 sur l’échelle de Borg). Vous aurez un moniteur de fréquence cardiaque et on vous demandera votre fréquence cardiaque chaque minute. Le test sera interrompu si vous ressentez une aggravation de votre symptôme (par exemple, si vous avez des maux de tête, de l’étourdissement, de la vision embrouillée, des douleurs au cou ou tout autre symptôme de votre blessure, faites-nous-le savoir). Si vous êtes un patient chronique (de plus de 14 jours), afin d’échouer à ce test, vous devez avoir une aggravation de votre état général par 3 points ou plus.  Vous allez commencer avec un court échauffement de 2 minutes afin de vous familiariser avec ce tapis roulant. Ensuite, le test durera de 15 à 20 minutes, suivi d’une période de retour au calme de 4 minutes. Avez-vous des questions ? "',
  buffaloV2InfoText: '"Il s\'agit d\'un protocole d\'effort physique utilisant un tapis roulant. Cette station vous fera marcher sur un tapis roulant à 3,3 mph. Après chaque minute, l\'inclinaison du tapis roulant augmentera de 1%. Vous porterez un moniteur de fréquence cardiaque et on vous demandera à chacun minute pour votre fréquence cardiaque, le taux d\'effort perçu, ainsi que votre score d\'état général (échelle 0-10) .Le test sera arrêté à l\'exacerbation des symptômes, c\'est-à-dire si vous ressentez des maux de tête, des étourdissements, une vision trouble, d\'autres symptômes qui font augmenter votre score global de bien-être de 3 points ou plus. Vous pouvez demander d\'arrêter le test à tout moment en raison de l\'épuisement physique ou d\'une sensation très instable. On vous demandera également chaque minute si vous rencontrez un changement de votre état de santé. Vous commencerez par un bref échauffement de 2 minutes pour vous familiariser avec le tapis roulant. Ensuite, le test commencera et prendra environ 25 minutes, suivi d\'un refroidissement de 4 minutes. Avez-vous des questions? "',
  warmUpText: 'Échauffement — 4,02 km/h (2,5 mi/h) pendant 2 minutes',
  startTestText: 'Début du test — 5,3 km/h (3,3 mi/h) à 0 % d’inclinaison',
  symptomExacerbationErrorMessage: 'Veuillez choisir si les symptômes se sont aggravés',
  exacerbationHRErrorMessage: 'Veuillez indiquer la fréquence cardiaque où l’aggravation a eu lieu',
  exacerbationTimeErrorMessage: 'Veuillez indiquer le moment où s’est produite l’aggravation (entre {0} et {1})',
  exacerbationSymptomsErrorMessage: 'Veuillez indiquer les symptômes lorsque l’aggravation a eu lieu',
  coolDownInfoText: 'Retour au calme — 0 % d’inclinaison, 4,02 km/h (2,5 mi/h) pendant 4 minutes',
  invalidHeartRateMessage: 'Veuillez indiquer un nombre valide de battements par minute (entre {0} et {1})',
  overallNotesLabel: 'Remarques générales',
  monitorPatientInfoText: 'Surveillez le patient pendant au moins 15 minutes après la période de retour au calme, ou jusqu’à ce que leur fréquence cardiaque soit revenue à son niveau d’avant le test',
  restingInputInfoText: 'Demandez au patient de s\'asseoir tranquillement pendant au moins 2 minutes avant de faire la lecture pré-test',
  restingBorgScaleHeaderLabel: 'RPE Repos',
  restingBorgScaleInputLabel: 'RPE Repos (20pt Borg Échelle)',
  restingSymptomWellBeingLabel: 'Score de bien-être global au repos',
  speedLabel: 'La vitesse',
  borgScaleLabel: 'RPE (20pt Borg Échelle)',
  borgScaleInfoText: 'Arrêté à 18-20',
  symptomScaleLabel: 'Note globale de bien-être (échelle de 0 à 10)',
  symptomsNotesLabel: 'Notes/symptômes spécifiques',
  buffaloTableNewRowButtonText: 'Nouvelle rangée',
  warmUpRowText: 'Échauffement (2 mins)',
  borgScaleLevel6Label: 'Pas d\'effort du tout',
  borgScaleLevel7Label: 'Extrêmement léger',
  borgScaleLevel9Label: 'Très léger',
  borgScaleLevel11Label: 'Léger',
  borgScaleLevel13Label: 'Un peu dur',
  borgScaleLevel15Label: 'Dur (lourd)',
  borgScaleLevel17Label: 'Très dur',
  borgScaleLevel19Label: 'Extrêmement difficile',
  borgScaleLevel20Label: 'Exersion maximale'
};
 
export default FR;
