const UPDATE_CLINIC_PATIENTS = 'UPDATE_CLINIC_PATIENTS';

const updateClinicPatients = (clinicId, users) => ({
  type: UPDATE_CLINIC_PATIENTS,
  clinicId,
  users
});

export {
  UPDATE_CLINIC_PATIENTS,
  updateClinicPatients
};
