import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from '../reducers';

const middlewares = [thunk];

if (process.env.NODE_ENV === 'development') {
  middlewares.push(
    // eslint-disable-next-line global-require
    require('redux-logger').createLogger({
      duration: true,
      collapsed: true,
      diff: true
    })
  );
}

const Store = createStore(
  rootReducer,
  applyMiddleware(...middlewares)
);

export default Store;
