import React, { useCallback } from 'react';
import dayjs from 'dayjs';
import { FormInput } from '@/forms';
import Icon from '../icon';
import { makeTimeOptions, getStatusStrings } from './utils';
import Strings from './lang';

const defaultOpenTime = '08:00';
const defaultCloseTime = '17:00';

const StatusOptions = () => {
  const statuses = getStatusStrings();

  return (
    <>
      {Object.keys(statuses).map(status => (
        <option key={status} value={status}>{statuses[status]}</option>
      ))}
    </>
  );
}; 

const ClinicHoursInputRow = ({
  hours,
  copyRowDisabled = false,
  onUpdate = () => {},
  onCopyRowDown
}) => {
  const handleStatusUpdate = useCallback((e) => {
    const { value } = e.target;
    const newHours = { ...hours, status: value };

    if (value === 'open') {
      newHours.open_at = defaultOpenTime;
      newHours.close_at = defaultCloseTime;
    } else {
      newHours.open_at = null;
      newHours.close_at = null;
    }

    onUpdate(newHours);
  }, [hours, onUpdate]);

  const handleOpenChange = useCallback((e) => {
    const { value } = e.target;
    const newHours = { ...hours, open_at: value };
    onUpdate(newHours);
  }, [hours, onUpdate]);

  const handleCloseChange = useCallback((e) => {
    const { value } = e.target;
    const newHours = { ...hours, close_at: value };
    onUpdate(newHours);
  }, [hours, onUpdate]);

  return (
    <tr>
      <td>
        {dayjs.weekdays()[hours.day]}
      </td>
      <td>
        <FormInput 
          type="select" 
          className="form-control" 
          inputProps={{
            value: hours.status, 
            onChange: handleStatusUpdate
          }}
        >
          <StatusOptions />
        </FormInput>
      </td>
      <td>
        <FormInput 
          type="select" 
          className="form-control" 
          inputProps={{
            disabled: hours.status !== 'open',
            value: hours.open_at || '', 
            onChange: handleOpenChange
          }}
        >
          <option value="">--</option>
          {makeTimeOptions(hours.close_at, false)}
        </FormInput>
      </td>
      <td>
        <FormInput 
          type="select" 
          className="form-control" 
          inputProps={{
            disabled: hours.status !== 'open',
            value: hours.close_at || '', 
            onChange: handleCloseChange
          }}
        >
          <option value="">--</option>
          {makeTimeOptions(hours.open_at, true)}
        </FormInput>
      </td>
      <td>
        {!copyRowDisabled && (
          <button 
            type="button" 
            className="hours-copy-btn"
            onClick={onCopyRowDown}
            title={Strings.copyRowTitleText}
          >
            <Icon name="arrow-down" />
          </button>
        )}
      </td>
    </tr>
  );
};

export default ClinicHoursInputRow;
