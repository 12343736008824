import React from 'react';
import Strings from './lang';

const PanelControls = ({ panelRef }) => {
  return (
    <div className="no-print">
      <button
        type="button"
        className="btn btn-primary btn-sm"
        onClick={() => panelRef?.current?.openAll()}
      >
        {Strings.openAllPanelsLabel}
      </button>
      <button
        type="button"
        className="btn btn-primary btn-sm"
        onClick={() => panelRef?.current?.closeAll()}
      >
        {Strings.closeAllPanelsLabel}
      </button>
    </div>
  );
};

export default PanelControls;
