const FR = {
  patientInformationHeader: 'Informations du patient',
  injuryInformationHeader: 'Informations sur la blessure',
  patientLabel: 'Patient',
  accountLabel: 'Compte',
  bagTagLabel: 'Carte d’identité',
  birthdateLabel: 'Date de naissance',
  ageLabel: 'Âge',
  sexLabel: 'Sexe',
  cityLabel: 'Ville/municipalité',
  regionLabel: 'Région',
  phoneLabel: 'Numéro de téléphone',
  injuryDateLabel: 'Date de la blessure',
  assessmentDateLabel: 'Date d’évaluation',
  daysSinceInjuryLabel: 'Jours depuis la blessure',
  practitionerLabel: 'Praticien',
  clinicLabelText: 'Clinique',
  phoneLabelText: 'Numéro de téléphone',
  faxLabelText: 'Numéro de fax',
  injuryDateErrorMessage: 'Date invalide. La date doit être la même que celle de l’évaluation ({0}) ou avant',
  assessmentDateErrorMessage: 'Date invalide. La date doit être entre la date de la blessure ({0}) et aujourd’hui ({1}).',
  saveButtonText: 'Sauvegarder',
  editButtonText: 'Modifier',
  cancelButtonText: 'Annuler'
};
 
export default FR;
