import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { isFolder, isFolderShortcut } from '@/utilities/google-drive';
import { ResourcesPaths } from '@/paths';
import replaceLinkParams from '@/utilities/replace-link-params';

const createDriveWebViewLink = (fileId) => {
  return `https://drive.google.com/file/d/${fileId}/view?usp=drivesdk`;
};

const DRIVE_URL_REGEX = /^https?:\/\/drive\.google\.com/g;

const getExternalFileLink = (file) => {
  // docx, xlsx, csv, pptx files will try to open in
  // google docs, google sheets etc... and users will
  // automatically request edit permissions. So if the
  // url for the webViewLink does not start with a google 
  // drive link, then create a drive url with the given file
  // ID
  if (!DRIVE_URL_REGEX.test(file.webViewLink)) {
    return createDriveWebViewLink(file.id);
  }

  return file.webViewLink;
};

const createLink = (file, breadcrumbs) => {
  const fileId = isFolderShortcut(file)
    ? file.shortcutDetails?.targetId
    : file.id;

  return {
    to: replaceLinkParams(ResourcesPaths.file.link, {
      fileId
    }),
    state: {
      breadcrumbs: [...breadcrumbs, { name: file.name, id: fileId }]
    }
  };
};

const FileLink = ({
  file = {},
  children,
}) => {
  const location = useLocation();
  const { breadcrumbs = [] } = location?.state || {};
  const external = !isFolder(file) && !isFolderShortcut(file);

  if (external) {
    return (
      <a 
        href={getExternalFileLink(file)} 
        className="resource-link"
        target="_blank" 
        rel="noopener noreferrer"
        title={file.name}
      >
        {children}
      </a>
    );
  }

  return (
    <Link 
      {...createLink(file, breadcrumbs)}
      className="resource-link"
      title={file.name}
    >
      {children}
    </Link>
  );
};

export default FileLink;
