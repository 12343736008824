import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import AccessControl from '../../access-control';
import ImageSlider from './image-slider';
import Markdown from '../../markdown';
import AdminActions from './admin-action';
import ProductNotFoundView from './not-found-view';
import ProductPriceDetails from './product-price-details';
import { AdminRoleDescriptions } from '@/utilities/user-roles';
import { getProductAsync, updateProductAsync } from '@/redux/thunks/products';
import { sendEvent } from '../utils';
import { Page } from '../../page';
import Activity from '../../activity';
import { showAlert } from '../../alert-notifications/alert';
import HTTPStatusCodes from '@/utilities/http-status-codes';
import { ErrorBanner } from '../../errors';
import CouponCodeDetails from './coupon-code';
import { MarketplacePaths } from '@/paths';
import CategoryTags from './category-tags';
import replaceLinkParams from '@/utilities/replace-link-params';
import Icon from '../../icon';
import Strings from '../lang';

const productSelector = (slug) => (state) => {
  return Object.values(state.products).find(p => p.slug === slug);
};

const showPublishChangeMessage = (published) => {
  showAlert('success', {
    autoDismiss: 4000,
    dismissable: true,
    message: published
      ? Strings.publishedSuccessMessage
      : Strings.unpublishedSuccessMessage
  });
};

const PartnerLink = ({ partner }) => {
  if (!partner?.id) return null;

  return (
    <div>
      By&nbsp;
      <Link 
        to={replaceLinkParams(MarketplacePaths.partners.show.link, { partnerId: partner.slug })}
      >
        {partner.name}
      </Link>
    </div>
  );
};

const PublishedIndicator = ({ published }) => {
  const icon = published ? 'check' : 'xmark';
  const labelType = published ? 'success' : 'danger';

  return (
    <AccessControl roles={AdminRoleDescriptions}>
      <div className={`label label-${labelType}`}>
        <Icon name={icon} />&nbsp;
        {published ? Strings.publishedLabel : Strings.notPublishedLabel}
      </div>
    </AccessControl>
  );
};

const MarketplaceProductListing = () => {
  const navigate = useNavigate();
  const params = useParams();
  const product = useSelector(productSelector(params.productId));
  const [error, setError] = useState(null);
  const [productUnpublishedError, setProductUnpublishedError] = useState(false);
  const [loading, setLoading] = useState(!product?.id);
  const dispatch = useDispatch();

  const getProduct = useCallback((id) => {
    setLoading(true);
    setError(null);

    dispatch(getProductAsync(id)).then(product => {
      sendEvent(product.id, 'view');
      setLoading(false);
    }).catch(error => {
      if ([HTTPStatusCodes.Gone, HTTPStatusCodes.NotFound].includes(error.status)) {
        setProductUnpublishedError(true);
      } else {
        setError(error.message);
      }

      setLoading(false);
    });
  }, [dispatch]);

  const onPublishChange = useCallback((published) => {
    setLoading(true);
    setError(null);

    dispatch(updateProductAsync(product.slug, { published })).then(() => {
      setLoading(false);
      showPublishChangeMessage(published);
    }).catch(error => {
      setError(error.message);
      setLoading(false);
    });
  }, [dispatch, product?.slug]);

  const onCopyCoupon = useCallback(() => {
    sendEvent(product.id, 'coupon.copy');
  }, [product?.id]);

  const onLinkClick = useCallback(() => {
    sendEvent(product.id, 'link.click');
  }, [product?.id]);

  useEffect(() => {
    if (params.productId) {
      getProduct(params.productId);
    }
  }, [getProduct, params.productId]);

  if (productUnpublishedError) {
    return (
      <Page className="product-listing-page">
        <ProductNotFoundView 
          onReturn={() => navigate(MarketplacePaths.products.index.link, { replace: true })} 
        />
      </Page>
    );
  }

  return (
    <Page className="product-listing-page">
      <Activity active={loading} static={!product?.id}>
        <div className="flex-row justify_end">
          {!!product?.id && (
            <AccessControl roles={AdminRoleDescriptions}>
              <AdminActions 
                product={product} 
                onPublishChange={onPublishChange}
              />
            </AccessControl>
          )}
        </div>
        <ErrorBanner error={error} />
        
        <div className="product-listing">
          <ImageSlider
            videoUrl={product?.video_url}
            images={product?.images}
          />
          <div className="product-title">
            <PublishedIndicator published={product?.published} />
            <h1>{product?.name}</h1>
            <PartnerLink partner={product?.mp_partner} />
            <CategoryTags categories={product?.categories} />
          </div>
          <div className="product-details-container">
            <ProductPriceDetails price={product?.price} discount={product?.discount} />
            <CouponCodeDetails 
              code={product?.coupon_code} 
              onCopy={onCopyCoupon} 
            />
            <a 
              href={product?.link} 
              target="_blank"
              rel="noopener"
              className="btn btn-block btn-secondary"
              onClick={onLinkClick}
            >
              {Strings.productLinkButton}
            </a>
          </div>
          <div className="product-description">
            <h2>{Strings.descriptionSectionTitle}</h2>
            <Markdown html={false}>
              {product?.description}
            </Markdown>
          </div>
        </div>
      </Activity>
    </Page>
  );
};

export default MarketplaceProductListing;
