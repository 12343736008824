import React, { Component } from 'react';
import classnames from 'classnames';
import { If, Then } from 'react-if';
import { connect } from 'react-redux';
import { getUserAsync, updateUserAsync, activateUserAsync } from '@/redux/thunks/users';
import Activity from '../activity';
import AccessControl from '../access-control';
import { ErrorBanner } from '../errors';
import { Page } from '../page';
import { ShowTeamLeaderProfile } from '../user-profile';
import { RoleDescriptions } from '@/utilities/user-roles';
import { withRouter } from '@/utilities/router-utils';
import QuickActions from '../quick-actions';
import { confirmation } from '../confirmation';
import Strings from './lang';
import Icon from '../icon';
import { UserSelector } from '@/redux/selectors';

class TeamLeaderProfile extends Component {
  constructor(props) {
    super(props);

    const { leader = {} } = props;

    this.onClickEdit = this.onClickEdit.bind(this);
    this.onSubmitEdit = this.onSubmitEdit.bind(this);

    this.state = {
      leader,
      loading: true,
      activity: false,
      error: null,
      sectionError: {},
      editPersonal: false,
      editContact: false
    };
  }

  componentDidMount() {
    if (!this.state.leader.id) {
      this.getLeader();
    } else {
      this.setState({ loading: false });
    }
  }

  render() {
    const { currentUser = {} } = this.props;
    const { leader } = this.state;
    const { person = {} } = leader;

    return (
      <Page className={classnames('user-profile', { 'inactive-profile': leader.active === false })}>

        <If condition={leader.active === false}>
          <Then>
            <div className="account-deactivated-banner">
              <div className="banner-content">
                <Icon name="circle-info" /> {Strings.accountDeactivatedBannerMessage}
              </div>
            </div>
          </Then>
        </If>

        <Activity active={this.state.activity} static={this.state.loading}>
          <div className="row">
            <div className="col-md-12">
              <h1 className="display">{`${person.first_name} ${person.last_name}`}</h1>
              <p>{Strings.coachOrTrainerText}</p>
            </div>
            <div className="col-md-12">
              <ErrorBanner error={this.state.error} />
            </div>
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-8">
                  <ShowTeamLeaderProfile 
                    user={leader}
                    editorRoles={[
                      RoleDescriptions.SuperAdmin,
                      RoleDescriptions.SalesAdmin
                    ]}
                    sectionError={this.state.sectionError}
                    editPersonal={this.state.editPersonal}
                    editContact={this.state.editContact}
                    onClickEdit={this.onClickEdit}
                    onSubmitEdit={this.onSubmitEdit}
                  />
                </div>
                <div className="col-md-4">
                  <QuickActions>
                    <If condition={leader.id !== currentUser.id}>
                      <Then>
                        <AccessControl 
                          roles={[
                            RoleDescriptions.SuperAdmin,
                            RoleDescriptions.SalesAdmin
                          ]}
                        >
                          <li>
                            <button 
                              type="button"
                              className="btn btn-default" 
                              onClick={() => {
                                this.confirmActivateUser(!leader.active);
                              }}
                            >
                              {(leader.active) 
                                ? Strings.deactivateUserButtonText 
                                : Strings.activateUserButtonText}
                            </button>
                          </li>
                        </AccessControl>
                      </Then>
                    </If>
                  </QuickActions>
                </div>
              </div>
            </div>
          </div>
        </Activity>
      </Page>
    );
  }

  onClickEdit(key) {
    const { [key]: editSection } = this.state;

    this.setState({
      sectionError: {},
      [key]: !editSection
    });
  }

  confirmActivateUser(active) {
    const { leader = {} } = this.state;
    const { person = {} } = leader;

    const message = Strings.formatString(
      (active)
        ? Strings.activateUserModalMessage
        : Strings.deactivateUserModalMessage,
      `${person.first_name} ${person.last_name}`
    );

    const title = active
      ? Strings.activateUserModalTitle
      : Strings.deactivateUserModalTitle;

    const confirmButtonTitle = active
      ? Strings.activateUserModalConfirmButtonTitle
      : Strings.deactivateUserModalConfirmButtonTitle;

    confirmation(message, {
      title,
      confirmButtonTitle,
      onConfirm: () => {
        this.activateUser(active);
      }
    });
  }

  activateUser(active) {
    this.setState({
      activity: true,
      error: null
    });

    this.props.activateUser(this.props.leaderId, { active }).then(leader => {
      this.setState({
        activity: false,
        leader
      });
    }).catch(error => {
      this.setState({
        activity: false,
        error: error.message
      });
    });
  }

  getLeader() {
    const { leaderId } = this.props;

    this.setState({ error: null, activity: true });

    this.props.getLeader(leaderId).then(leader => {
      this.setState({
        leader,
        loading: false,
        activity: false
      });
    }).catch(error => {
      this.setState({
        error: error.message,
        loading: false,
        activity: false
      });
    });
  }

  onSubmitEdit(section, attributes) {
    const { leaderId } = this.props;
    this.setState({ activity: true, sectionError: {} });

    this.props.updateUser(leaderId, attributes).then(leader => {
      this.setState({ 
        leader, 
        activity: false, 
        editContact: false, 
        editPersonal: false 
      });
    }).catch(error => {
      this.setState({ 
        sectionError: {
          section,
          message: error.message
        },
        activity: false,
      });
    });
  }
}

const mapStateToProps = (state, ownProps) => {
  const { users = {} } = state;
  const currentUser = UserSelector.getCurrentUser(state) || {};
  const { leaderId } = ownProps.params || {};
  const leader = users[leaderId] || {};

  return { leader, leaderId, currentUser };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLeader: (id) => {
      return dispatch(getUserAsync(id));
    },
    updateUser: (id, attributes) => {
      return dispatch(updateUserAsync(id, attributes));
    },
    activateUser: (id, attributes) => {
      return dispatch(activateUserAsync(id, attributes));
    },
  };
};

const ConnectedTeamLeaderProfile = connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamLeaderProfile);

export default withRouter(ConnectedTeamLeaderProfile);
