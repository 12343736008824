import React from 'react';
import ProductCard from './product-card';
import Strings from '../lang';

const ProductsList = ({ loading, products = [] }) => {
  if (!loading && !products.length) {
    return (
      <p className="text-center">
        {Strings.noProductsFoundLabel}
      </p>
    );
  }

  return (
    <div className="products-grid">
      {products.map(product => (
        <ProductCard
          key={product.id}
          product={product}
        />
      ))}
    </div>
  );
};

export default ProductsList;
