const resolveKeyPath = (obj, keyPath) => {
  return keyPath.split('.').reduce((value, key) => {
    return value ? value[key] : undefined;
  }, obj);
};

const sortOnKeyPath = (arr, keyPath, placeholder = '') => {
  return arr.map((value, index) => {
    const resolvedValue = resolveKeyPath(value, keyPath) || placeholder;
    return { index, value: resolvedValue };
  }).sort((a, b) => {
    const aValue = typeof a.value === 'string' ? a.value.toLowerCase() : a.value;
    const bValue = typeof b.value === 'string' ? b.value.toLowerCase() : b.value;
    if (aValue === bValue) return 0;
    if (aValue > bValue) return 1;
    return -1;
  }).map(element => {
    return arr[element.index];
  });
};

const filterOnKeyPath = (arr, search, keyPath, placeholder = '') => {
  return arr.map((value, index) => {
    const resolvedValue = resolveKeyPath(value, keyPath) || placeholder;
    return { index, value: resolvedValue };
  }).filter(obj => {
    return obj.value.toLowerCase().indexOf(search.toLowerCase()) >= 0;
  }).map(element => {
    return arr[element.index];
  });
};

export {
  resolveKeyPath,
  sortOnKeyPath,
  filterOnKeyPath
};
