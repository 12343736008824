import React, { useCallback, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import Modal from '../../modal';
import { createIdtClaimAppointmentAsync } from '@/redux/thunks/idt-claims';
import { showAlert } from '../../alert-notifications';
import { ErrorBanner } from '../../errors';
import AppointmentForm from './appointment-form';
import { BlockTreatmentTypeStrings } from '../utils';
import Icon from '../../icon';
import { DISPLAY_DATE_FORMAT } from '@/utilities/dates/formats';
import Strings from '../lang';

const ModalContent = ({ claimId, block, onClose }) => {
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const allowInitialVisit = useMemo(() => {
    return block?.order === 1 && !block?.appointments?.some(appt => appt.treatment_type === 'initial_assessment' && (appt.status === 'booked' || appt.status === 'arrived'));
  }, [block?.appointments, block?.order]);

  const onSubmit = useCallback((data) => {
    setError(null);
    setSubmitting(true);

    const attributes = {
      virtual: data.virtual,
      treatment_type: data.treatment,
      appt_at: data.appt,
      providers: data.providers
    };

    dispatch(createIdtClaimAppointmentAsync(claimId, block.id, attributes)).then(() => {
      setSubmitting(false);
      onClose();
      showAlert('success', {
        dismissable: true,
        autoDismiss: 3000,
        message: Strings.appointmentAddedSuccessMessage
      });
    }).catch(error => {
      setError(error.message);
      setSubmitting(false);
    });
  }, [block, claimId, dispatch, onClose]);

  return (
    <>
      <Modal.Header onClose={submitting ? undefined : onClose}>
        <div className="appt-modal-header">
          <h2>
            {Strings.addAppointmentText}&nbsp;
            ({BlockTreatmentTypeStrings[block.treatment_type]} {block.block_number})
          </h2>
          <div className="block-duration-label">
            {dayjs(block.start_date).format(DISPLAY_DATE_FORMAT)}&nbsp;
            <Icon name="arrow-right-long" />&nbsp;
            {dayjs(block.end_date).format(DISPLAY_DATE_FORMAT)}
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <ErrorBanner error={error} />
        <AppointmentForm
          allowInitialVisit={allowInitialVisit}
          minApptDate={dayjs(block.start_date).startOf('day')}
          maxApptDate={dayjs(block.end_date).endOf('day')}
          submitting={submitting}
          onSubmit={onSubmit}
        />
      </Modal.Body>
    </>
  );
};

const AddAppointmentModal = ({
  isOpen,
  claimId,
  block,
  onClose
}) => {
  return (
    <Modal
      blur
      className="claim-appointment-modal"
      isOpen={isOpen}
    >
      <ModalContent
        claimId={claimId}
        block={block}
        onClose={onClose}
      />
    </Modal>
  );
};

export default AddAppointmentModal;
