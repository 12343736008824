import React, { useCallback, useState } from 'react';
import { If, Then, Else } from 'react-if';
import Card from '../card';
import { EditButton } from '../buttons';
import { 
  RecoveryStagesView, 
  RecoveryStageSelectors
} from '../recovery-stages';
import Strings from './lang';

const getInitialStages = (current) => {
  return {
    rts_stage: current?.rts_stage === undefined ? '' : (current.rts_stage ?? null),
    rtl_stage: current?.rtl_stage === undefined ? '' : (current.rtl_stage ?? null),
    rtw_stage: current?.rtw_stage === undefined ? '' : (current.rtw_stage ?? null),
  };
};

const RecoveryStageCard = ({
  editable = false,
  currentStages,
  previousStages,
  onUpdate = () => {}
}) => {
  const [editing, setEditing] = useState(false);

  const handleEdit = useCallback(() => {
    if (editing) {
      onUpdate(null);
    } else {
      onUpdate(getInitialStages(currentStages));
    }

    setEditing(prev => !prev);
  }, [currentStages, editing, onUpdate]);

  return (
    <Card 
      title={Strings.recoveryStagesSectionTitle}
      actionButtons={editable ? (
        <EditButton 
          editing={editing} 
          onClick={handleEdit}
        />
      ) : null}
    >
      <If condition={editing}>
        <Then>
          <RecoveryStageSelectors
            canDischarge
            rtsStage={currentStages?.rts_stage}
            rtlStage={currentStages?.rtl_stage}
            rtwStage={currentStages?.rtw_stage}
            previousStages={previousStages}
            onRtsStageChange={(value) => onUpdate({ rts_stage: value })}
            onRtlStageChange={(value) => onUpdate({ rtl_stage: value })}
            onRtwStageChange={(value) => onUpdate({ rtw_stage: value })}
          />
        </Then>
        <Else>
          {currentStages ? (
            <RecoveryStagesView stages={currentStages} />
          ) : (
            <p className="text-muted">
              {Strings.recoveryStagesNotUpdatedLabel}
            </p>
          )}
        </Else>
      </If>
    </Card>
  );
};

export default RecoveryStageCard;
