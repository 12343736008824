import React from 'react';
import dayjs from 'dayjs';
import { SymptomScoreBadge } from '../soap-notes/soap-note-test-badges';
import { ProfessionStrings } from '@/utilities/clinical-info';
import { DISPLAY_DATE_FORMAT } from '@/utilities/dates/formats';
import Strings from './lang';
import Icon from '../icon';

const CreatorName = ({ creator }) => {
  const { person = {} } = creator || {};
  const { first_name, last_name } = person || {};
  return (
    <div className="text-muted">
      <small>
        <i>{Strings.formatString(Strings.noteCreatedByLabel, `${first_name} ${last_name}`)}</i>
      </small>
    </div>
  );
};

const PanelHeader = ({
  note,
  isNoteCreator = false,
  editable = true,
  onEdit,
}) => {
  return (
    <div className="panel-header-container">
      <div className="header-row">
        <div className="header-title">
          {isNoteCreator && (
            <Icon name="user-doctor" className="primary" title={Strings.noteCreatorIconTitle} />
          )}
          <h2>
            {Strings.formatString(
              Strings.notePanelHeading,
              ProfessionStrings[note.specialist_type] || Strings.defaultSpecialistType,
              dayjs(note.created_at).format(DISPLAY_DATE_FORMAT)
            )}
          </h2>
          <SymptomScoreBadge 
            symptoms={note.symptoms_scores} 
          />
        </div>
        {isNoteCreator && editable && (
          <button type="button" className="edit-button" onClick={onEdit}>
            <Icon name="pen-to-square" />
            {Strings.editButonText}
          </button>
        )}
      </div>
      {!isNoteCreator && (
        <CreatorName creator={note.clinic_user} />
      )}
    </div>
  );
};

export default PanelHeader;
