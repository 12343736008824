import React, {
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import ReactPaginate from 'react-paginate';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { getClinicsAsync } from '@/redux/thunks/clinics';
import { ClinicsTable } from '../clinics-table';
import ClinicSearchFilters from './clinic-search-filters';
import { PER_PAGE_KEYS } from './per-page-selector';
import ClinicBalances from './clinic-balances';
import { Page } from '../page';
import { ErrorBanner } from '../errors';
import Activity from '../activity';
import isEmpty from '@/utilities/is-empty';
import Strings from './lang';

const DEFAULT_PER_PAGE = PER_PAGE_KEYS[1];

const filterParams = (params) => (
  Object.keys(params).reduce((acc, key) => {
    if (isEmpty(params[key])) return acc;

    switch (key) {
      case 'page':
        return params[key] !== 1 ? { ...acc, [key]: params[key] } : acc;
      case 'per_page':
        return params[key] !== DEFAULT_PER_PAGE ? { ...acc, [key]: params[key] } : acc;
      case 'active':
        return params[key] === true ? acc : { ...acc, [key]: params[key] };
      default:
        return { ...acc, [key]: params[key] };
    }
  }, {})
);

const formatPageParams = (query) => {
  const { 
    page = 1, 
    active,
    per_page = DEFAULT_PER_PAGE,
    name,
    ...rest 
  } = Object.fromEntries(query || {});

  return {
    page: parseInt(page, 10) || 1,
    active: name ? null : (active !== 'false'),
    per_page: per_page === 'all' 
      ? per_page 
      : (parseInt(per_page, 10) || DEFAULT_PER_PAGE),
    name,
    ...rest
  };
};

const Clinics = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [clinics, setClinics] = useState([]);
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(!clinics.length);
  const [error, setError] = useState(null);
  const pageParams = useMemo(() => formatPageParams(searchParams), [searchParams]);

  const loadClinics = useCallback((query = {}) => {
    setLoading(true);

    dispatch(getClinicsAsync(query)).then(({ clinics, pagination }) => {
      setClinics(clinics);
      setPagination(pagination);
      setLoading(false);
    }).catch(error => {
      setError(error.message);
      setLoading(false);
    });
  }, [dispatch]);

  const updateParams = useCallback((params) => {
    const newParams = { ...pageParams, page: 1, ...params };
    const query = filterParams(newParams);
    
    if (query.name) {
      delete query.active;
    }

    setSearchParams(query);
    
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [pageParams, setSearchParams]);

  const onPageChange = useCallback(({ selected }) => {
    const newPage = selected + 1;
    if (newPage !== pageParams.page) {
      updateParams({ page: newPage });
    }
  }, [pageParams.page, updateParams]);

  useEffect(() => {
    loadClinics(pageParams);
  }, [loadClinics, pageParams]);

  return (
    <Page className="clinics">
      <h1>{Strings.title}</h1>
      <ClinicBalances />
      <ErrorBanner error={error} />
      <ClinicSearchFilters 
        filters={pageParams}
        pagination={pagination}
        onUpdate={updateParams}
      />
      <Activity active={loading} static={!clinics.length}>
        {clinics.length > 0 ? (
          <ClinicsTable clinics={clinics} />
        ) : (
          <p>{Strings.emptyClinicsMessage}</p>
        )}
        {pagination.total_pages > 1 && (
          <div className="clinics-pagination-container">
            <ReactPaginate
              pageCount={pagination.total_pages}
              forcePage={pagination.page - 1}
              pageRangeDisplayed={4}
              marginPagesDisplayed={2}
              containerClassName="pagination"
              activeClassName="active"
              onPageChange={onPageChange}
            />
          </div>
        )}
      </Activity>
    </Page>
  );
};

export default Clinics;
