import { sortDateDescending } from '@/utilities/sort';
import {
  UPDATE_USER_BASELINE_TESTS,
  UPDATE_USER_BASELINE_TEST,
  ADD_USER_BASELINE_TEST,
  REMOVE_USER_BASELINE_TEST
} from '../actions/user-baseline-tests';

const userBaselineTestsReducer = (state = {}, action = {}) => {
  const { userId = 0, baselines = {} } = action;
  return (userId) ? { ...state, [userId]: baselines } : state;
};

const removeUserBaselineTestReducer = (state = {}, action = {}) => {
  const { userId, baselineId } = action;
  const { [userId]: userBaselineTests } = state;
  const newBaselines = [...userBaselineTests];

  const baselineIndex = newBaselines?.findIndex(baseline => (
    String(baseline.id) === String(baselineId)
  ));

  if (baselineIndex >= 0) {
    newBaselines.splice(baselineIndex, 1);
  }

  return {
    ...state,
    [userId]: newBaselines
  };
};

const addUserBaselineTestReducer = (state = {}, action = {}) => {
  const { userId, baseline } = action;
  const { [userId]: userBaselineTests } = state;
  const newBaselines = [...userBaselineTests, baseline].sort(sortDateDescending.bind(null, 'created_at'));

  return {
    ...state,
    [userId]: newBaselines
  };
};

const updateUserBaselineTestReducer = (state = {}, action = {}) => {
  const { userId, baseline } = action;
  const { [userId]: userBaselineTests = [] } = state;
  const newBaselines = [...(userBaselineTests || [])];
  const index = newBaselines.findIndex(b => String(b.id) === String(baseline.id));

  if (index >= 0) {
    newBaselines[index] = {
      ...(newBaselines[index] || {}),
      ...baseline
    };
  }

  return {
    ...state,
    [userId]: newBaselines
  };
};

const userBaselineTests = (state = {}, action = {}) => {
  switch (action.type) {
    case UPDATE_USER_BASELINE_TESTS:
      return userBaselineTestsReducer(state, action);
    case ADD_USER_BASELINE_TEST:
      return addUserBaselineTestReducer(state, action);
    case UPDATE_USER_BASELINE_TEST:
      return updateUserBaselineTestReducer(state, action);
    case REMOVE_USER_BASELINE_TEST:
      return removeUserBaselineTestReducer(state, action);
    default:
      return state;
  }
};

export default userBaselineTests;
