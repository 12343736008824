import React from 'react';
import { FormInput, FormInputMessage } from '@/forms';
import { getBorgScaleOptions, getSymptomScoreOptions } from './helpers';
import Strings from '../lang';

const BuffaloTestV2FormRow = ({
  minute,
  speed,
  incline,
  heartRate,
  onHeartRateChange,
  heartRateErrorMessage,
  heartRateValid,
  borgScale,
  onBorgScaleChange,
  symptomScore,
  onSymptomScoreChange,
  symptoms,
  onSymptomChange
}) => (
  <tr>
    <td>{minute}</td>
    <td>{speed.toFixed(1)}</td>
    <td>{incline}%</td>
    <td className="col-md-2">
      <FormInput 
        type="text" 
        className="form-control" 
        inputProps={{
          placeholder: Strings.bpmPlaceHolderText,
          value: heartRate,
          onChange: onHeartRateChange
        }} 
      />
      <FormInputMessage
        className="alert alert-danger"
        text={heartRateErrorMessage}
        visible={!heartRateValid}
      />
    </td>
    <td className="col-md-2">
      <FormInput 
        type="select" 
        className="form-control" 
        inputProps={{
          value: borgScale, 
          onChange: onBorgScaleChange
        }}
      >
        <option value="">--</option>
        {getBorgScaleOptions()}
      </FormInput>
    </td>
    <td className="col-md-2">
      <FormInput 
        type="select" 
        className="form-control" 
        inputProps={{
          value: symptomScore, 
          onChange: onSymptomScoreChange
        }}
      >
        <option value="">--</option>
        {getSymptomScoreOptions()}
      </FormInput>
    </td>
    <td>
      <FormInput 
        type="text" 
        className="form-control" 
        inputProps={{
          placeholder: Strings.symptomsLabel,
          value: symptoms,
          onChange: onSymptomChange
        }} 
      />
    </td>
  </tr>
);

export default BuffaloTestV2FormRow;
