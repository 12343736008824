const EN = {
  signInTitle: 'Sign In',
  title: 'Register',
  reTitle: 'Re-Register',
  personalAccountTitle: 'Individual Account',
  guardianAccountTitle: 'Family Account',
  emailLabelText: 'Email',
  emailInputPlaceholder: 'user@example.com',
  passwordLabelText: 'Password',
  passwordErrorText: 'Passwords must be at least 6 characters long.',
  passwordInputPlaceholder: 'password123',
  confirmPasswordLabelText: 'Confirm Password',
  confirmPasswordInputPlaceholder: 'password123',
  termsLabelText: 'I accept the CCMI Privacy Policy',
  termsErrorText: 'You must accept the CCMI Privacy Policy to register.',
  reTermsErrorText: 'You must accept the CCMI Privacy Policy to re-register.',
  scrollMessageText: 'Please read to the end of the CCMI Privacy Policy to enable the checkbox.',
  returningMessageText: 'Please sign in with your existing Complete Concussion Management account to register.',
  reReturningMessageText: 'Please sign in with your existing Complete Concussion Management account to re-register.',
  resetLinkText: 'Forgot password?',
  submitButtonText: 'Register',
  guardianCheckboxText: 'I am a parent/guardian and want to register my children.',
  reSubmitButtonText: 'Re-Register',
  guardianModalTitle: 'Are you a Parent or Guardian?',
  noButtonText: 'No',
  yesButtonText: 'Yes',
  guardianModalMessage: 'Are you a parent or guardian who is registering on behalf of your children/dependants? If so, click Yes. If not, click No',
  emailTakenErrorTitle: 'Email Address {0} Already Taken',
  emailTakenErrorMessage: 'To update your information or add family members, please sign into your account.',
  signIntoAccountButtonText: 'Sign Into My Account'
};

export default EN;
