import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ErrorBanner } from '../../errors';
import Strings from './lang';

const getComponentAttributes = (reference) => {
  if (typeof reference.getAttributes === 'function') {
    return reference.getAttributes();
  }

  return {};
};

class BaselineStep extends Component {
  constructor(props) {
    super(props);

    this.activeComponent = React.createRef();

    this.state = {
      error: null
    };
  }

  render() {
    const { 
      previousDisabled = false, 
      component: ActiveComponent, 
      injectProps 
    } = this.props;

    return (
      <div>
        <ErrorBanner error={this.state.error} />
        <ActiveComponent {...injectProps} ref={this.activeComponent} />
        <div>
          <button 
            type="button" 
            className={classNames('btn btn-default', {
              hide: previousDisabled
            })}
            onClick={this.onPrevious.bind(this)}
          >
            {Strings.previousStepButtonText}
          </button>
          <button 
            type="button" 
            className="btn btn-primary pull-right" 
            onClick={this.onContinue.bind(this)}
          >
            {Strings.continueButtonText}
          </button>
        </div>
      </div>
    );
  }

  onContinue() {
    const componentAttributes = getComponentAttributes(this.activeComponent.current);

    this.setState({
      error: null
    });

    this._callOnContinue(componentAttributes);
  }

  _callOnContinue(results) {
    if (results === false) {
      return;
    }

    this.props.onContinue(results);
  }

  onPrevious() {
    this.setState({
      error: null
    });
    
    this.props.onPrevious();
  }
}

BaselineStep.propTypes = {
  injectProps: PropTypes.object,
  component: PropTypes.any.isRequired,
  onContinue: PropTypes.func.isRequired,
  onPrevious: PropTypes.func.isRequired,
  previousDisabled: PropTypes.bool
};

export default BaselineStep;
