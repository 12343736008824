import {
  RoleNames,
  RoleResourceTypes,
  makeRoleDescription,
  userHasRoleMatchingOneOfDescriptions
} from '@/utilities/user-roles';
import Strings from './lang';

const userHasPermissionToViewPatientName = (user, clinicId = 0) => {
  return (clinicId > 0) && userHasRoleMatchingOneOfDescriptions(user, [
    makeRoleDescription(RoleNames.Clinician, RoleResourceTypes.Clinic, clinicId),
    makeRoleDescription(RoleNames.Specialist, RoleResourceTypes.Clinic, clinicId),
    makeRoleDescription(RoleNames.BaselineTester, RoleResourceTypes.Clinic, clinicId),
    makeRoleDescription(RoleNames.ClinicFrontDesk, RoleResourceTypes.Clinic, clinicId),
    makeRoleDescription(RoleNames.ClinicStaff, RoleResourceTypes.Clinic, clinicId)
  ]);
};

const resolvePatientName = ({
  user,
  patient,
  clinicId = 0,
  redactedText = Strings.redactedText,
  lastNameFirst = false
}) => {
  const person = patient?.person || {};
  const firstName = person.first_name || '';
  const lastName = person.last_name || '';
  const namesText = (lastNameFirst) ? `${lastName}, ${firstName}` : `${firstName} ${lastName}`;
  return userHasPermissionToViewPatientName(user, clinicId) ? namesText : redactedText;
};

export {
  userHasPermissionToViewPatientName,
  resolvePatientName
};
