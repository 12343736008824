import Strings from './lang';

export const TabKeys = {
  SoapNotes: 'soap',
  SpecialistNotes: 'specialist'
};

export const TabLabels = {
  SoapNotes: Strings.soapNotesTabLabel,
  SpecialistNotes: Strings.specialistNotesTabLabel
};
