import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import FormInputGroup from './form-input-group';
import FormInputMessage from './form-input-message';

const getDefaultState = (props) => {
  const value = props.initialValue || null;
  return { value };
};

class RadioInputGroup extends Component {
  static getDerivedStateFromProps(props, state) {
    const { touched, inputProps } = props;
    let changed = false;
    const newState = {};

    if (!!inputProps?.value && state.value !== inputProps?.value) {
      changed = true;
      newState.value = inputProps?.value;
    }
    
    if (touched !== state._propTouched) {
      changed = true;
      newState.touched = touched;
      newState._propTouched = touched;
    }

    return changed ? newState : null;
  }

  constructor(props) {
    super(props);

    const { touched } = props;

    this.onChange = this.onChange.bind(this);

    this.state = {
      ...getDefaultState(props),
      touched,
      _propTouched: touched
    };
  }

  render() {
    return (
      <div className="radio-group-container">
        <label {...this.props.titleLabelProps}>
          {this.props.titleLabelText}
        </label>
        {this.renderInputGroups()}
        <FormInputMessage
          className={this.props.messageClassName}
          visible={this.state.touched && !this.props.inputValid}
          text={this.props.messageText}
        />
      </div>
    );
  }

  renderInputGroups() {
    const values = this.props.radioValues || [];
    const labels = this.props.radioLabels || [];

    return values.map((value, index) => {
      return (
        <FormInputGroup
          inputValid
          key={index}
          className={classNames('radio-input-group', this.props.className)}
          labelText={labels[index] || value}
          labelProps={this.props.labelProps}
          inputType="radio"
          inputProps={this.mergeInputPropsWithValue(value)}
          labelAfterInput={this.props.labelAfterInput}
        />
      );
    });
  }

  onChange(e) {
    const { value } = e.target;
    
    this.setState({ value });

    if (typeof this.props.onUpdate === 'function') {
      this.props.onUpdate(value);
    }
  }

  mergeInputPropsWithValue(value) {
    return {
      ...(this.props.inputProps || {}),
      value,
      checked: value === this.state.value,
      onChange: this.onChange
    };
  }
}

RadioInputGroup.propTypes = {
  labelText: PropTypes.string,
  labelProps: PropTypes.object,
  inputProps: PropTypes.object,
  radioLabels: PropTypes.array,
  radioValues: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool
    ])
  ).isRequired,
  initialValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ]),
  messageClassName: PropTypes.string,
  messageText: PropTypes.string,
  inputValid: PropTypes.bool,
  onUpdate: PropTypes.func,
  labelAfterInput: PropTypes.bool
};

export default RadioInputGroup;
