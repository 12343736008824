import React from 'react';
import { MobileAppOverlay, MobileDetector } from '../../mobile';

const MobileAppAd = () => (
  <MobileDetector>
    {(isMobile, platform) => {
      return isMobile ? (
        <MobileAppOverlay
          isMobile
          platform={platform}
        />
      ) : null;
    }}
  </MobileDetector>
);

export default MobileAppAd;
