import React, { useCallback, useState } from 'react';
import classnames from 'classnames';
import { FileDropArea, FileUploadButton } from '../../file-upload';
import Strings from '../lang';
import Icon from '../../icon';

const convertToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

async function getFilesData(fileList) {
  const data = [];

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < fileList.length; ++i) {
    const file = fileList[i];
    const filename = file.name;
    try {
      // eslint-disable-next-line no-await-in-loop
      const url = await convertToBase64(file);
      data.push({ filename, url });
    } catch (e) {
      // do nothing
      // eslint-disable-next-line no-console
      console.log(e);
    }
  }

  return data;
}

const DropArea = ({ onAddImages, children }) => {
  const [dragState, setDragState] = useState(null);

  const setDragOverState = useCallback(() => {
    setDragState('drag-over');
  }, []);

  const resetDragState = useCallback(() => {
    setDragState(null);
  }, []);

  const handleAddImages = useCallback((files) => {
    resetDragState();
    onAddImages(files);
  }, [onAddImages, resetDragState]);

  return (
    <FileDropArea
      className={classnames('product-images-dropzone', dragState)}
      onFilesDrop={handleAddImages}
      onDragOver={setDragOverState}
      onDragOut={resetDragState}
    >
      {children}
    </FileDropArea>
  );
};

const UploadButton = ({
  onAddImages
}) => {
  return (
    <FileUploadButton
      multiple
      accept="image/png, image/jpeg, image/webp"
      className="product-images-upload-btn"
      buttonClassName="btn btn-primary btn-sm"
      onSelectFiles={onAddImages}
    >
      <Icon name="plus" />&nbsp;&nbsp;
      {Strings.addImagesButton}
    </FileUploadButton>
  );
};

const EmptyView = ({ onAddImages }) => {
  return (
    <div className="empty-files-view">
      <div className="empty-icon">
        <Icon name="images" />
      </div>
      <p className="text-center">{Strings.imagesDropAreaMessage}</p>
      <UploadButton onAddImages={onAddImages} />
    </div>
  );
};

const ProductImage = ({ url, onDelete }) => {
  return (
    <div className="product-image">
      <img src={url} alt="Product" />
      <button type="button" className="destroy" onClick={onDelete}>
        <Icon name="xmark" />
      </button>
    </div>
  );
};

const ImagesList = ({ images, onDelete = () => {} }) => {
  return (
    <div className="images-list">
      {images.map((image, index) => {
        if (image._destroy) return null;

        return (
          <ProductImage 
            key={image.id || `${image.filename}_${index}`}
            url={image.url}
            onDelete={() => {
              onDelete(image, index);
            }}
          />
        );
      })}
    </div>
  );
};

const ProductImages = ({
  images = [],
  onAddImages = () => {},
  onDeleteImage = () => {}
}) => {
  const handleNewImages = useCallback(async (files) => {
    const newImages = await getFilesData(files);
    onAddImages(newImages);
  }, [onAddImages]);

  return (
    <DropArea onAddImages={handleNewImages}>
      {!images.length ? (
        <EmptyView onAddImages={handleNewImages} />
      ) : (
        <div className="images-list-container">
          <ImagesList 
            images={images}
            onDelete={onDeleteImage}
          />
          <div className="add-images-btn-container">
            <UploadButton onAddImages={handleNewImages} />
          </div>
        </div>
      )}
    </DropArea>
  );
};

export default ProductImages;
