import React from 'react';
import { Page } from '../../page';
import Strings from '../lang';

const ProductsEmptyPage = () => {
  return (
    <Page className="marketplace">
      <div className="flex-row align_center justify_center">
        <div className="products-empty">
          <h1 className="text-center">
            {Strings.productsEmptyPageTitle}
          </h1>
          <div className="text-center text-muted">
            {Strings.productsEmptyPageMessage}
          </div>
        </div>
      </div>
    </Page>
  );
};

export default ProductsEmptyPage;
