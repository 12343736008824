import React, { useMemo } from 'react';
import { If, Then, Else } from 'react-if';
import { getRedFlagsSectionData } from './injury-report-data';
import ChecklistItem from '../helpers/checklist-item';
import Strings from './lang';

const actionKeys = [
  'proceed',
  'ambulance',
  'hospital'
];

const getCheckedRedFlags = (redFlags) => {
  return Object.keys(redFlags).filter(key => redFlags[key] === true || redFlags[key] === 'yes');
};

const redFlagsString = getRedFlagsSectionData();

const ActionTaken = ({ action }) => {
  if (action) {
    return (
      <div>
        <label>{Strings.actionTakenLabel}:&nbsp;</label>
        {actionKeys.indexOf(action) >= 0
          ? <span>{Strings[`actionTaken_${action}`]}</span>
          : <span className="capitalize">{action}</span>}
      </div>
    );
  }

  return null;
};

const InjuryReportRedflagsSection = ({
  injuryRedflags = {}
}) => {
  const redFlagsInfo = useMemo(() => getCheckedRedFlags(injuryRedflags || {}), [injuryRedflags]);
  return (
    <div className="injury-report-redflags-display">
      <div className="row">
        <div className="col-md-12">
          <h2 className="bold">{Strings.redFlagsReportedTitle}</h2>
        </div>
      </div>

      <If condition={redFlagsInfo.length === 0}>
        <Then>
          <div className="row">
            <div className="col-md-12">
              {Strings.noRedFlagsReported}
            </div>
          </div>
        </Then>
        <Else>
          <ul className="row list-unstyled">
            {redFlagsInfo.map((key, index) => (
              <ChecklistItem key={index} className="col-md-6" checked>
                {redFlagsString[key]}
              </ChecklistItem>
            ))}
          </ul>
        </Else>
      </If>

      <ActionTaken action={injuryRedflags?.action_taken} />
    </div>
  );
};

export default InjuryReportRedflagsSection;
