const EN = {
  title: 'Post Injury Test',
  baselineTitle: 'Baseline Test \u2014 {0}',
  postInjuryTestTitle: 'Post Injury Test {0} \u2014 {1}',
  noActiveBaseline: 'This patient has no active baseline test before {0} to compare',
  selectTestsInfoText: 'Please select the tests that you wish to perform. If the patient has already completed a post injury please UNSELECT all tests which have been successfully completed (i.e., passed) on first attempts.  Note: only select tests which you wish to perform at this time.',
  continueToTest: 'Continue',
  uncheckedBoxesMessage: 'Any checkboxes that are initially unchecked indicate that the test was not completed as part of the patient\'s baseline',
  baselineTestNotCompletedTitle: 'Not completed as part of patient\'s baseline test'
};

export default EN;
