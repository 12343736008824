import React, {
  forwardRef, 
  Children,
  cloneElement
} from 'react';
import Container from './container';
import usePopper from './use-popper';
import { PopperProvider, usePopperContext } from './context';

const RefResolver = forwardRef(({ 
  children 
}, ref) => {
  const child = Children.only(children);
  return cloneElement(child, {
    ref
  });
});

const Popper = ({
  content,
  portal = false,
  isOpen,
  placement,
  offset,
  fixed,
  zIndex = 100,
  portalClassName,
  closeOnClickOutside = true,
  className,
  onClose = () => {},
  children
}) => {
  const [refs, styles, attributes, update] = usePopper({
    isOpen,
    placement,
    offset,
    fixed,
    closeOnClickOutside,
    onClose
  });

  return (
    <PopperProvider
      styles={styles}
      attributes={attributes}
      update={update}
    >
      {children && (
        <RefResolver ref={refs.target}>
          {children}
        </RefResolver>
      )}
      <Container
        isOpen={isOpen} 
        portal={portal}
        className={portalClassName}
      >
        <div 
          ref={refs.popper} 
          className={className}
          style={{ ...styles.popper, zIndex }} 
          {...attributes.popper}
        >
          {content}
        </div>
      </Container>
    </PopperProvider>
  );
};

export default Popper;
export { usePopperContext };
