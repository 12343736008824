const FR = {
  clinician: 'Clinicien',
  clinic_staff: 'Personnel clinique',
  front_desk: 'Réception',
  leader: 'Chef d’équipe',
  player: 'Patient',
  baseline_tester: 'Baseline Tester',
  clinic_owner: 'Propriétaire de la clinique',
  specialist: 'Médecin/Spécialiste',
  idt_clinician: 'IDT Clinicien',
  idt_front_desk: 'IDT Réception'
};
 
export default FR;
