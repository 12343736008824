const MIN_LENGTH = 5;

const OrganizationValidator = {
  test(value) {
    if ((value.length === 0) 
      || (value.startsWith(' ')) 
      || (value.endsWith(' ')) 
      || (value.length < MIN_LENGTH) 
      || (!value.includes(' '))
    ) {
      return false;
    }

    return true;
  }
};

const getDefaultPositionForSport = (sport) => {
  return (sport.positions.length === 0) ? 'other' : '';
};

const resolvePositionValue = (position, otherPosition) => {
  return (position === 'other') ? otherPosition : position;
};

const resolvePositionName = (sport, position, otherPosition) => {
  if (position === 'other') {
    return otherPosition;
  }

  const foundPosition = sport.positions.find(p => {
    return p.key === position;
  });

  return (foundPosition) ? foundPosition.name : position;
};

const groupTeamsByOrganization = (teams = [], defaultOrganization = '') => {
  return teams.reduce((acc, team) => {
    const organizationId = (team.organization && team.organization.id) || 0;
    const organizationName = (team.organization && team.organization.name) || defaultOrganization;
    return {
      ...acc,
      [organizationId]: {
        name: organizationName,
        teams: [
          ...((acc[organizationId] || {}).teams || []),
          team
        ]
      }
    };
  }, {});
};

export {
  OrganizationValidator,
  getDefaultPositionForSport,
  resolvePositionValue,
  resolvePositionName,
  groupTeamsByOrganization
};
