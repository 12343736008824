import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Icon from '../icon';

const DashboardCard = ({
  leftBadge,
  icon = null,
  rightBadge,
  helpBadge,
  title,
  subtitle,
  className,
  secondSubtitle,
  disabled,
  action = null,
  onClickButton,
  buttonTitle
}) => (
  <div className={classNames('dashboard-card', className)}>
    <div className="dashboard-card-header">
      <div className="dashboard-card-badge">
        {leftBadge}
      </div>
      <div className="dashboard-card-circle">
        {typeof icon === 'string' ? (
          <Icon name={icon} />
        ) : icon}
      </div>
      <div className="dashboard-card-badge">
        {rightBadge}
      </div>
    </div>
    <div className="dashboard-card-content">
      <div className="dashboard-card-badge">
        {helpBadge}
      </div>
      <h1>{title}</h1>
      <p>{subtitle}</p>
      <p>{secondSubtitle}</p>
    </div>
    <div className="dashboard-card-footer">
      {action || (
        <button 
          type="button"
          className="btn btn-primary"
          disabled={disabled}
          onClick={onClickButton}
        >
          {buttonTitle}
        </button>
      )}
    </div>
  </div>
);

DashboardCard.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  secondSubtitle: PropTypes.string,
  buttonTitle: PropTypes.string,
  disabled: PropTypes.bool,
  onClickButton: PropTypes.func,
  leftBadge: PropTypes.element,
  rightBadge: PropTypes.element,
  helpBadge: PropTypes.element
};

export default DashboardCard;
