import React, {
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Link,
  useLocation,
  useNavigate,
  useParams
} from 'react-router-dom';
import { If, Then, Else } from 'react-if';
import { Page } from '../page';
import Tabs from '../tabs';
import ClinicStatistics from './clinic-statistics';
import ExportPatientsButton from './export-patients';
import { AccountRetrievalModalButton } from './account-retrieval-modal';
import { ClinicSearchControl } from '../clinic-search';
import PatientsTable from './patients-table';
import { userHasPermissionToViewPatientName } from '../patient-meta';
import Activity from '../activity';
import { ErrorBanner } from '../errors';
import AccessControl from '../access-control';
import { ReferralsList } from '../referrals';
import { ClinicSelector, UserSelector, ReferralSelector } from '@/redux/selectors';
import { getClinicAsync } from '@/redux/thunks/clinics';
import { getClinicPatientsAsync } from '@/redux/thunks/clinic-patients';
import { ClinicPaths, DashboardPaths } from '@/paths';
import { useMount, useIsMounted } from '@/hooks';
import {
  userHasRoleMatchingOneOfDescriptions,
  RoleDescriptions,
  RoleResourceTypes,
  RoleNames,
  makeRoleDescription
} from '@/utilities/user-roles';
import { showAlert } from '../alert-notifications';
import replaceLinkParams from '@/utilities/replace-link-params';
import { getClinicReferralsAsync } from '@/redux/thunks/referrals';
import Strings from './lang';

const filterHiddenTabs = (item) => !item.hide;

const makePatientExporterRoles = (clinicId) => {
  return [
    makeRoleDescription(RoleNames.Clinician, RoleResourceTypes.Clinic, clinicId),
    makeRoleDescription(RoleNames.ClinicFrontDesk, RoleResourceTypes.Clinic, clinicId)
  ];
};

const makePatientsWithoutOngoingInjuriesViewRoles = (clinicId) => {
  return [
    RoleDescriptions.SuperAdmin,
    RoleDescriptions.SalesAdmin,
    makeRoleDescription(RoleNames.Clinician, RoleResourceTypes.Clinic, clinicId),
    makeRoleDescription(RoleNames.Specialist, RoleResourceTypes.Clinic, clinicId),
    makeRoleDescription(RoleNames.ClinicFrontDesk, RoleResourceTypes.Clinic, clinicId),
    makeRoleDescription(RoleNames.ClinicOwner, RoleResourceTypes.Clinic, clinicId)
  ];
};

const makeAllPatientsViewRoles = (clinicId) => {
  return [
    ...makePatientsWithoutOngoingInjuriesViewRoles(clinicId),
    makeRoleDescription(RoleNames.BaselineTester, RoleResourceTypes.Clinic, clinicId),
  ];
};

const makeClinicianOngoingViewRoles = (clinicId) => {
  return [
    makeRoleDescription(RoleNames.Clinician, RoleResourceTypes.Clinic, clinicId),
    makeRoleDescription(RoleNames.Specialist, RoleResourceTypes.Clinic, clinicId)
  ];
};

const makeReferralViewerRoles = (clinicId) => {
  return [
    makeRoleDescription(RoleNames.Clinician, RoleResourceTypes.Clinic, clinicId),
    makeRoleDescription(RoleNames.Specialist, RoleResourceTypes.Clinic, clinicId),
    makeRoleDescription(RoleNames.ClinicFrontDesk, RoleResourceTypes.Clinic, clinicId)
  ];
};

const makeOngoingViewRoles = (clinicId) => {
  return [
    makeRoleDescription(RoleNames.ClinicOwner, RoleResourceTypes.Clinic, clinicId),
    makeRoleDescription(RoleNames.Clinician, RoleResourceTypes.Clinic, clinicId),
    makeRoleDescription(RoleNames.Specialist, RoleResourceTypes.Clinic, clinicId),
    makeRoleDescription(RoleNames.ClinicStaff, RoleResourceTypes.Clinic, clinicId),
    makeRoleDescription(RoleNames.ClinicFrontDesk, RoleResourceTypes.Clinic, clinicId)
  ];
};

const TabKeys = {
  MyPatients: 'my_patients',
  Referrals: 'referrals',
  Ongoing: 'ongoing',
  Uninjured: 'uninjured',
  All: 'all',
  Stats: 'stats'
};

const ClinicPatients = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { pathname, state } = useLocation();
  const mounted = useIsMounted();
  const [searched, setSearched] = useState(() => !!state?.searched);
  const currentUser = useSelector(UserSelector.getCurrentUser);
  const searchResults = useSelector(state => (
    ClinicSelector.getSearchResults(state, { id: params?.clinicId })
  ));
  const clinic = useSelector(state => ClinicSelector.getClinic(state, { id: params?.clinicId }));
  const patients = useSelector(state => (
    ClinicSelector.getPatients(state, { id: params?.clinicId })
  ));
  const ongoing = useSelector(state => (
    ClinicSelector.getOngoingPatients(state, { id: params?.clinicId })
  ));
  const uninjured = useSelector(state => (
    ClinicSelector.getUninjuredPatients(state, { id: params?.clinicId })
  ));
  const clinicianOngoing = useSelector(state => (
    ClinicSelector.getClinicianOngoingPatients(state, { id: params?.clinicId })
  ));
  const referrals = useSelector(state => (
    ReferralSelector.getOpenReferredToClinicReferrals(state, {
      referredToClinicId: params?.clinicId
    })
  ));
  
  const [loading, setLoading] = useState(() => !patients?.length);
  const [error, setError] = useState(null);
  const [searchError, setSearchError] = useState(null);

  const uninjuredVisible = useMemo(() => (
    userHasRoleMatchingOneOfDescriptions(
      currentUser,
      makePatientsWithoutOngoingInjuriesViewRoles(params?.clinicId)
    )
  ), [currentUser, params?.clinicId]);

  const referralsVisible = useMemo(() => (
    userHasRoleMatchingOneOfDescriptions(
      currentUser,
      makeReferralViewerRoles(params?.clinicId)
    )
  ), [currentUser, params?.clinicId]);
  
  const referralBadgeNumber = useMemo(() => (
    referrals.filter(referral => referral.status === 'pending').length
  ), [referrals]);

  const viewNamePermissions = useMemo(() => (
    userHasPermissionToViewPatientName(currentUser, params?.clinicId)
  ), [currentUser, params?.clinicId]);

  const myOngoingVisible = useMemo(() => (
    userHasRoleMatchingOneOfDescriptions(
      currentUser,
      makeClinicianOngoingViewRoles(params?.clinicId)
    )
  ), [currentUser, params?.clinicId]);

  const ongoingVisible = useMemo(() => (
    userHasRoleMatchingOneOfDescriptions(
      currentUser, 
      makeOngoingViewRoles(params?.clinicId)
    )
  ), [currentUser, params?.clinicId]);

  const allPatientVisible = useMemo(() => (
    userHasRoleMatchingOneOfDescriptions(
      currentUser,
      makeAllPatientsViewRoles(params?.clinicId)
    )
  ), [currentUser, params?.clinicId]);

  const loadPatientsAndReferrals = useCallback(() => {
    setLoading(!patients?.length);
    setError(null);

    Promise.allSettled([
      dispatch(getClinicPatientsAsync(params?.clinicId)),
      referralsVisible
        ? dispatch(getClinicReferralsAsync(params?.clinicId, true))
        : Promise.resolve([])
    ]).then(results => {
      if (mounted.current) {
        setLoading(false);
      }
      const errors = results.filter(result => result.status === 'rejected');

      if (errors.length) {
        return Promise.reject(new Error(errors.map(e => e.reason.message).join(', ')));
      }

      return undefined;
    }).catch(error => {
      if (mounted.current) {
        setError(error.message);
        setLoading(false);
      }
    });
  }, [dispatch, mounted, params?.clinicId, patients?.length, referralsVisible]);

  const onSearch = useCallback((response) => {
    const { results = [] } = response || {};

    if (results.length === 1) {
      const [user] = results;
      const path = replaceLinkParams(ClinicPaths.patientProfile.index.link, {
        userId: user.id,
        clinicId: params?.clinicId
      });

      navigate(path);
    } else {
      setSearched(true);
    }
  }, [params?.clinicId, navigate]);

  const onSearchClear = useCallback(() => {
    setSearched(false);

    if (!patients?.length) {
      loadPatientsAndReferrals();
    }
  }, [loadPatientsAndReferrals, patients?.length]);

  useMount(() => {
    if (!clinic?.id) {
      dispatch(getClinicAsync(params?.clinicId)).catch(() => { });
    }

    if (!searched) {
      loadPatientsAndReferrals();
    }
  });

  useEffect(() => {
    if (searched && !!state?.searched) {
      navigate(pathname, { replace: true });
    }
  }, [navigate, pathname, searched, state?.searched]);

  return (
    <Page className="clinic-patients">
      <ol className="breadcrumb">
        <li>
          <Link to={DashboardPaths.index.link}>
            {Strings.dashboardLabel}
          </Link>
        </li>
        <li className="active">
          {Strings.title}
        </li>
      </ol>
      <div className="row">
        <div className="col-md-12">
          <h1 className="display">{clinic?.name}</h1>
          <h2>{Strings.title}</h2>
        </div>
      </div>
      <div className="search-header">
        <div className="search-control">
          <ClinicSearchControl
            compact
            clinicId={params?.clinicId}
            onSearch={onSearch}
            onError={(error) => setSearchError(error.message)}
            onClear={onSearchClear}
          />
        </div>
        <div className="actions">
          <AccountRetrievalModalButton
            className="btn btn-default btn-sm"
            clinicId={params?.clinicId}
            onSuccess={(message) => {
              showAlert('success', {
                autoDismiss: 5000,
                dismissable: true,
                message
              });
            }}
          >
            {Strings.accountRetrievalLabel}
          </AccountRetrievalModalButton>
          <AccessControl roles={makePatientExporterRoles(params?.clinicId)}>
            <If condition={!searched && patients?.length > 0}>
              <Then>
                <ExportPatientsButton
                  patients={patients}
                  className="btn btn-light btn-sm"
                />
              </Then>
            </If>
          </AccessControl>
        </div>
      </div>
      <ErrorBanner error={error || searchError} />
      <If condition={searched}>
        <Then>
          <PatientsTable 
            patients={searchResults}
            clinicId={params?.clinicId}
            viewNames={viewNamePermissions}
            showDanaBaseline={false}
            emptyMessage={Strings.noPatientsSearchText}
          />
        </Then>
        <Else>
          <Activity static active={loading} titleComponent={<h2>Loading Patients</h2>}>
            <Tabs
              renderOne
              className="patient-tabs"
              items={[
                {
                  key: TabKeys.MyPatients,
                  label: Strings.formatString(
                    Strings.clinicianOngoingTabLabel,
                    clinicianOngoing?.length ?? 0
                  ),
                  hide: !myOngoingVisible,
                  component: (
                    <div>
                      <p className="text-muted text-center">
                        <i>{Strings.myPatientsTabDescription}</i>
                      </p>
                      <PatientsTable
                        showInjuryStage
                        patients={clinicianOngoing}
                        clinicId={params?.clinicId}
                        viewNames={viewNamePermissions}
                        emptyMessage={Strings.noMyPatientsText}
                      />
                    </div>
                  )
                },
                {
                  key: TabKeys.Referrals,
                  tabClassName: 'referrals-tab',
                  label: (
                    <>
                      <span>{Strings.referralsTabLabel}</span>
                      <span className="badge badge-warning">{referralBadgeNumber}</span>
                    </>
                  ),
                  hide: !referrals.length || !referralsVisible,
                  component: (
                    <ReferralsList referrals={referrals} showReferredToLink={false} />
                  )
                },
                {
                  key: TabKeys.Ongoing,
                  label: Strings.formatString(
                    Strings.ongoingTabLabel, 
                    ongoing?.length ?? 0
                  ),
                  hide: !ongoingVisible,
                  component: (
                    <PatientsTable 
                      showInjuryStage
                      patients={ongoing}
                      clinicId={params?.clinicId}
                      viewNames={viewNamePermissions}
                      emptyMessage={Strings.noPatientsWithInjuriesInfoText}
                    />
                  )
                },
                {
                  key: TabKeys.Uninjured,
                  label: Strings.formatString(
                    Strings.uninjuredTabLabel,
                    uninjured?.length ?? 0
                  ),
                  hide: !uninjuredVisible,
                  component: (
                    <PatientsTable 
                      patients={uninjured}
                      clinicId={params?.clinicId}
                      viewNames={viewNamePermissions}
                      emptyMessage={Strings.noPatientsUninjuredText}
                    />
                  )
                },
                {
                  key: TabKeys.All,
                  label: Strings.formatString(
                    Strings.allPatientsTabLabel,
                    patients?.length ?? 0
                  ),
                  hide: !allPatientVisible,
                  component: (
                    <PatientsTable 
                      showInjuryStage={ongoingVisible}
                      patients={patients}
                      clinicId={params?.clinicId}
                      viewNames={viewNamePermissions}
                      emptyMessage={Strings.noPatientsText}
                    />
                  )
                },
                {
                  key: TabKeys.Stats,
                  label: Strings.statisticsTabLabel,
                  tabClassName: 'right',
                  component: (
                    <ClinicStatistics clinicId={params?.clinicId} />
                  )
                }
              ].filter(filterHiddenTabs)}
            />
          </Activity>
        </Else>
      </If>
    </Page>
  );
};

export default ClinicPatients;
