import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AddressFormInputs } from '../../address-form-inputs';
import convertAddressKeys from '@/utilities/convert-address-keys';
import sanitizeAddress from '@/utilities/sanitize-address';
import validateAddress from '@/utilities/validate-address';
import Strings from './lang';
import {
  FormComponent,
  FormInputGroup
} from '@/forms';
import { getCurrentUserLanguage, DEFAULT_USER_LANGUAGE } from '@/utilities/localization';

class GuardianRegistrationAboutPlayerForm extends Component {
  constructor(props) {
    super(props);
    const person = props.user.person || {};
    const address = sanitizeAddress(props.user.mailing_address || {});
    const firstName = person.first_name || '';
    const lastName = person.last_name || '';
    const phone = person.phone_number || '';

    this.onSubmit = this.onSubmit.bind(this);
    this.onUpdateAddress = this.onUpdateAddress.bind(this);
    
    this.state = {
      submitted: false,
      firstName,
      lastName,
      phone,
      address,
      addressValid: validateAddress(address)
    };
  }

  render() {
    return (
      <FormComponent className="registration-about-form player" onSubmit={this.onSubmit}>

        <FormInputGroup
          className="form-group first-name-input-group"
          labelText={Strings.guardianFirstNameLabel}
          inputType="text"
          inputProps={{
            className: 'form-control',
            value: this.state.firstName,
            onBlur: this.onUpdateFirstName.bind(this),
            onChange: this.onUpdateFirstName.bind(this)
          }}
          inputValid={this.state.firstName.length > 0}
          messageText={Strings.firstNameErrorText}
          messageClassName="alert alert-danger"
          required
          touched={this.state.submitted}
        />

        <FormInputGroup
          className="form-group last-name-input-group"
          labelText={Strings.guardianLastNameLabel}
          inputType="text"
          inputProps={{
            className: 'form-control',
            value: this.state.lastName,
            onBlur: this.onUpdateLastName.bind(this),
            onChange: this.onUpdateLastName.bind(this)
          }}
          inputValid={this.state.lastName.length > 0}
          messageText={Strings.lastNameErrorText}
          messageClassName="alert alert-danger"
          required
          touched={this.state.submitted}
        />

        <FormInputGroup
          className="form-group phone-input-group"
          labelText={Strings.guardianPhoneLabel}
          type="tel"
          inputProps={{
            className: 'form-control',
            value: this.state.phone,
            onBlur: this.onUpdatePhone.bind(this),
            onChange: this.onUpdatePhone.bind(this)
          }}
          inputValid={this.state.phone.length > 0}
          messageText={Strings.phoneErrorText}
          messageClassName="alert alert-danger"
          required
          touched={this.state.submitted}
        />

        <AddressFormInputs
          address={this.state.address}
          onUpdate={this.onUpdateAddress}
          required
          touched={this.state.submitted}
        />

        <div className="form-footer">
          <button className="btn btn-primary" type="submit">
            {Strings.submitButtonText}
          </button>
        </div>
      </FormComponent>
    );
  }

  isValid() {
    return this.state.firstName.length
      && this.state.lastName.length
      && this.state.addressValid;
  }

  onUpdateFirstName(e) {
    const { value } = e.target;
    this.setState({
      firstName: value
    });
  }

  onUpdateLastName(e) {
    const { value } = e.target;
    this.setState({
      lastName: value
    });
  }

  onUpdatePhone(e) {
    const { value } = e.target;
    this.setState({
      phone: value
    });
  }

  onUpdateAddress(address, addressValid) {
    this.setState({
      address,
      addressValid
    });
  }

  onSubmit() {
    this.setState({ submitted: true });

    if (!this.isValid()) {
      return;
    }

    const {
      firstName,
      lastName,
      phone,
      address
    } = this.state;

    const attributes = {
      person_attributes: {
        first_name: firstName,
        last_name: lastName,
        phone_number: phone,
        language: getCurrentUserLanguage() || DEFAULT_USER_LANGUAGE
      },
      mailing_address_attributes: convertAddressKeys(address)
    };
    
    if (typeof this.props.onSubmit === 'function') {
      this.props.onSubmit(attributes);
    }
  }
}

GuardianRegistrationAboutPlayerForm.propTypes = {
  user: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default GuardianRegistrationAboutPlayerForm;
