import dayjs from 'dayjs';
import UserActivity from './user-activity';
import UserActivityDefaults from './user-activity-defaults';

export const activityLasped = (
  lastActivity,
  inactivityDuration = UserActivityDefaults.inactivityTime
) => {
  const now = dayjs().valueOf();
  const duration = dayjs.duration(now - lastActivity).asMilliseconds();

  return duration > inactivityDuration;
};

export {
  UserActivity,
  UserActivityDefaults
};
