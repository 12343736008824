import React from 'react';
import Strings from '../lang';

const TestTypeLabel = ({
  postInjury = false
}) => {
  if (postInjury) {
    return (
      <span className="label label-danger inverted">
        {Strings.postInjuryTagLabel}
      </span>
    );
  }

  return (
    <span className="label label-light">
      {Strings.baselineTagLabel}
    </span>
  );
};

export default TestTypeLabel;
