import React, { Component } from 'react';
import { connect } from 'react-redux';
import { If, Then } from 'react-if';
import dayjs from 'dayjs';
import { getCurrentClinicId } from '@/tokens';
import { Page } from '../../page';
import { RegisteredStartTestCard, TestSearchResultsCard } from '../../start-test-cards';
import { UserPaths, PostInjuryAssessmentPaths } from '@/paths';
import replaceLinkParams from '@/utilities/replace-link-params';
import BagtagModal from '../../bagtag-modal';
import { userHasRoleMatchingDescription, RoleDescriptions } from '@/utilities/user-roles';
import { withRouter } from '@/utilities/router-utils';
import Strings from './lang';
import { UserSelector } from '@/redux/selectors';

class StartPostInjuryAssessment extends Component {
  constructor(props) {
    super(props);

    const { search, clinicId, currentUser } = props;
    const { results = [] } = search;

    this.onSearch = this.onSearch.bind(this);
    this.onError = this.onError.bind(this);
    this.onClear = this.onClear.bind(this);
    this.onUserSelect = this.onUserSelect.bind(this);

    this.state = {
      currentUser,
      selectedUser: {},
      searchResults: results,
      searched: false,
      bagtagModalOpen: false,
      error: null,
      clinicId
    };
  }

  render() {
    const searchedUser = (
      this.state.searched
      && this.state.searchResults.length === 1
      && this.state.searchResults[0]
    ) || {};

    return (
      <Page className="start-post-injury">

        <div className="row">
          <div className="col-md-12">
            <h1 className="display">{Strings.pageTitle}</h1>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <RegisteredStartTestCard
              cardTitle={!this.isClinician ? Strings.checkInPatientTitle : null}
              emptySearch={this.state.searched && this.state.searchResults.length === 0}
              selectedUser={searchedUser}
              errorMessage={this.state.error}
              clinicId={this.state.clinicId}
              searchButtonText={this.isClinician 
                ? Strings.searchButtonText 
                : Strings.checkInPatientText}
              onSearch={this.onSearch}
              onError={this.onError}
              onClear={this.onClear}
              activeInjuryErrorText={Strings.activeInjuryErrorText}
            />

            <If condition={this.state.searched && this.state.searchResults.length > 1}>
              <Then>
                <TestSearchResultsCard
                  results={this.state.searchResults}
                  clinicId={this.state.clinicId}
                  startTestButtonText={this.isClinician 
                    ? Strings.searchButtonText 
                    : Strings.checkInPatientText}
                  onUserSelect={this.onUserSelect}
                />
              </Then>
            </If>
          </div>
        </div>
        <BagtagModal 
          skippable
          isOpen={this.state.bagtagModalOpen}
          user={this.state.selectedUser}
          clinicId={this.state.clinicId}
          onSkip={() => this.routeToNewInjury(this.state.selectedUser, true)}
          onAssigned={() => this.routeToNewInjury(this.state.selectedUser, true)}
          onCancel={() => this.setState({ bagtagModalOpen: false })}
        />
      </Page>
    );
  }

  get isClinician() {
    const { currentUser } = this.state;
    return userHasRoleMatchingDescription(currentUser, RoleDescriptions.Clinician);
  }

  onSearch(response) {
    const { results = [] } = response;

    this.setState({
      selectedUser: {},
      searchResults: results,
      searched: true
    });

    if (results.length === 1) {
      const user = results[0];
      if (!user.active_injury_id) {
        this.onUserSelect(user);
      }
    }
  }

  onUserSelect(user) {
    if (user && !user.bag_tag) {
      this.invokeAssignBagtagModal(user);
    } else {
      this.routeToNewInjury(user, false);
    }
  }

  invokeAssignBagtagModal(user) {
    this.setState({ selectedUser: user, bagtagModalOpen: true });
  }

  routeToNewInjury(user, bagtagAssigned) {
    const { id, person = {} } = user;
    const userLastUpdated = person.updated_at;
    let pathname = '';

    if (this.isClinician) {
      /* assume that if a bagtag was assigned then the user is new so confirm their
       * personal information. The other scenerio is for users that have not had their
       * personal information updated within the past year.
       */
      if (bagtagAssigned || dayjs(userLastUpdated).diff(dayjs(), 'years') >= 1) {
        pathname = replaceLinkParams(PostInjuryAssessmentPaths.checkInPatient.link, {
          userId: id,
        });
      } else {
        pathname = replaceLinkParams(UserPaths.injuries.new.link, {
          userId: id
        });
      }
    } else {
      /* all front desk staff will go to the post injury patient check in */
      pathname = replaceLinkParams(PostInjuryAssessmentPaths.checkInPatient.link, {
        userId: id,
      });
    }

    this.props.router.push(pathname);
  }

  onError(error) {
    this.setState({
      error: error.message
    });
  }

  onClear() {
    this.setState({
      searchResults: [],
      searched: false,
      error: null
    });
  }
}

const mapStateToProps = (state) => {
  const { clinicPlayersSearch = {} } = state;
  const currentUser = UserSelector.getCurrentUser(state);
  const clinicId = getCurrentClinicId() || 0;
  const search = clinicPlayersSearch[clinicId] || {};
  return { search, clinicId, currentUser };
};

const ConnectedStartPostInjuryAssessment = connect(
  mapStateToProps
)(StartPostInjuryAssessment);

const RoutableStartPostInjuryAssessment = withRouter(ConnectedStartPostInjuryAssessment);

export default RoutableStartPostInjuryAssessment;
