import { UPDATE_CURRENT_USER_ID } from '../actions/users';

const updateCurrentUserIdReducer = (state = {}, action = {}) => {
  const { id } = action;

  return {
    ...state,
    current_user_id: id
  };
};

const session = (state = {}, action = {}) => {
  switch (action.type) {
    case UPDATE_CURRENT_USER_ID:
      return updateCurrentUserIdReducer(state, action);
    default:
      return state;
  }
};

export default session;
