import React, { useMemo, useState } from 'react';
import { BulkCreditPrice, CreditPrice } from './price';
import BulkCreditPurchaseModal from './bulk-credits-purchase-modal';
import { Money, getClinicCurrency } from '@/utilities/currency';
import Strings from './lang';

const ProductCard = ({
  count,
  price,
  currency,
  basePrice,
  clinicId,
  onPurchased
}) => {
  const [open, setOpen] = useState(false);
  const cost = Money.format(price, currency);
  const saved = Money.format((count * basePrice) - (count * price), currency);
  const discount = Math.round(((basePrice - price) / basePrice) * 100);

  return (
    <div className="product-card">
      <div className="product-details">
        <div className="product-heading">{count}</div>
        <div className="text-muted detail-item">
          {cost} / {Strings.creditLabel}
        </div>
        <div className="success detail-item bold">
          {Strings.formatString(
            Strings.discountLabel,
            discount || 0
          )}
        </div>
        <div className="text-muted detail-item">
          {Strings.savingsLabel} <strong>{saved}</strong>
        </div>
      </div>
      <button 
        type="button" 
        className="btn btn-sm btn-success"
        onClick={() => setOpen(true)}
      >
        {Strings.formatString(
          Strings.purchaseCreditsTitle,
          count
        )}
      </button>
      <BulkCreditPurchaseModal 
        isOpen={open}
        clinicId={clinicId}
        quantity={count}
        currency={currency}
        onPurchased={onPurchased}
        onClose={() => setOpen(false)}
      />
    </div>
  );
};

const BulkCreditProducts = ({ clinic = {}, onPurchased = () => {} }) => {
  const currency = useMemo(() => getClinicCurrency(clinic), [clinic]);
  const creditPrices = BulkCreditPrice[currency];
  const basePrice = CreditPrice[currency];

  return (
    <div>
      <h1 className="text-center primary">
        {Strings.bulkPurchaseHeading}
      </h1>
      <div className="text-center">
        {Strings.bulkPurchaseSubheading}
      </div>
      <div className="text-center">
        {Strings.formatString(
          Strings.bulkPurchaseFullPriceText,
          Money.format(basePrice, currency),
          currency
        )}
      </div>
      <div className="credit-products">
        {creditPrices.map((credit, index) => (
          <ProductCard 
            key={index}
            clinicId={clinic.id}
            currency={currency}
            basePrice={basePrice}
            onPurchased={onPurchased}
            {...credit}
          />
        ))}
      </div>
    </div>
  );
};

export default BulkCreditProducts;
