const FR = {
  title: 'Cartes d’identité Concussion',
  generateTabLabelText: 'Créer',
  manageTabLabelText: 'Gestion',
  batchNameLabelText: 'Nom',
  batchNameErrorMessageText: 'Veuillez écrire un nom pour ce groupe de cartes d’identité Concussion',
  batchSizeLabelText: 'Nombre de cartes d’identité Concussion',
  batchSizeErrorMessageText: 'Veuillez indiquer la quantité de cartes d’identité Concussion que vous voulez créer',
  generateButtonText: 'Créer',
  dateTableHeadingLabelText: 'Date',
  nameTableHeadingLabelText: 'Nom',
  sizeTableHeadingLabelText: 'Quantité de cartes d’identité',
  tagNumberTableHeadingLabelText: 'Carte d’identité de la concussion',
  dateUsedTableHeadingLabelText: 'Date prévue',
  batchMetadataText: 'Numéro de lot {0} créé le {1}',
  assignedBagTagText: 'Attribué',
  filterLabelText: 'Filtre de cartes d’identité',
  exportCSVLinkText: 'Exporter le fichier CSV',
  actionsHeadingText: 'Actions rapides'
};
 
export default FR;
