import { UPDATE_OVS_TEST } from '../actions/ovs-tests';

const updateOVSTestReducer = (state = {}, action = {}) => {
  const { userId, test = {} } = action;
  const { id } = test;
  const userState = state[userId] || {};
  const nextUserState = (id) ? { ...userState, [id]: test } : userState;
  return (userId) ? { ...state, [userId]: nextUserState } : state;
};

const ovsTests = (state = {}, action = {}) => {
  switch (action.type) {
    case UPDATE_OVS_TEST:
      return updateOVSTestReducer(state, action);
    default:
      return state;
  }
};

export default ovsTests;
