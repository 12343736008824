import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import Select from '../react-select';
import { UserSelector } from '@/redux/selectors';

const getClinicOptions = (clinics, currentClinic) => {
  const clinicOptions = clinics.map(clinic => ({ 
    value: clinic.id, 
    label: clinic.name
  }));

  if (currentClinic?.id && !clinicOptions.find(option => option.value === currentClinic.id)) {
    clinicOptions.push({ value: currentClinic.id, label: currentClinic.name });
  }

  return clinicOptions.sort((a, b) => {
    if (a.label !== b.label) {
      return (a.label > b.label) ? 1 : -1;
    }

    return 0;
  });
};

const ActiveClinicSelect = ({
  user = {},
  onSelectClinic
}) => {
  const currentUser = useSelector(UserSelector.getCurrentUser);
  const { current_clinic: currentClinic = {} } = user || {};
  const options = useMemo(() => (
    getClinicOptions(currentUser.clinics, currentClinic)
  ), [currentClinic, currentUser.clinics]);

  const currentValue = useMemo(() => (
    options.find(option => option.value === currentClinic?.id)
  ), [currentClinic?.id, options]);

  return (
    <Select
      value={currentValue}
      options={options}
      onChange={onSelectClinic}
    />
  );
};

export default ActiveClinicSelect;
