const EN = {
  marketplaceTitle: 'Complete Concussions Marketplace',
  adminProductsTitle: 'Marketplace Products',
  createProductLabel: 'Create Product',
  productCreatedMessage: 'Product created successfully!',
  productUpdatedMessage: 'Product updated successfully!',
  editProductLabel: 'Edit Product',
  noProductsFoundLabel: 'No Products Found',
  searchProductNameLabel: 'Search Product Name',
  nameTableColumnLabel: 'Name',
  partnerTableColumnLabel: 'Partner',
  statusTableColumnLabel: 'Status',
  publishedLabel: 'Published',
  notPublishedLabel: 'Not Published',
  partnerNameLabel: 'Partner Name',
  partnerNameErrorMessage: 'Please enter the partner name',
  partnerWebsiteUrlLabel: 'Website URL',
  partnerDescriptionLabel: 'Partnership Description',
  descriptionPlaceholder: 'Description',
  descriptionSectionTitle: 'Description',
  submitButtonLabel: 'Submit',
  editDetailsButton: 'Edit Details',
  editPartnershipModalTitle: 'Update Partnership',
  partnerUpdatedSuccessMessage: 'Partnership Updated Successfully!',
  productsTabLabel: 'Products',
  detailsTabLabel: 'Details',
  viewProfileLabel: 'View Profile',
  createPartnerModalTitle: 'Create New Partnership',
  addPartnershipLabel: 'Add Partnership',
  productInformationSectionTitle: 'Product Information',
  productNameLabel: 'Product Name',
  productNameErrorMessage: 'Please enter the product name',
  productCategoriesLabel: 'Product Categories',
  productCategoriesPlaceholder: 'Select Product Categories',
  productUrlLabel: 'Product URL/Affiliate Link',
  productDescriptionLabel: 'Product Description',
  pricingSectionTitle: 'Pricing Information',
  discountLabel: 'CCMI Discount (%)',
  discountPlaceholder: 'Discount Percentage',
  couponCodeLabel: 'Coupon Code',
  productVideoSectionTitle: 'Product Video',
  productVideoPlaceholder: 'Video URL (Vimeo, Youtube or Facebook)',
  productVideoErrorMessage: 'Please enter a valid video url',
  productImagesSectionTitle: 'Product Images',
  addImagesButton: 'Add Images',
  imagesDropAreaMessage: 'Drag & drop images in the this area or use the button below.',
  productImagesHintMessage: 'At least 1 product image is required',
  publishProductLabel: 'Publish Product',
  publishProductModalMessage: 'Publishing a product will make the item visible on the marketplace. Are you sure you want to publish this product?',
  publishProductModalButton: 'Publish',
  publishedSuccessMessage: 'Product successfully published!',
  unpublishProductLabel: 'Unpublish Product',
  unpublishProductModalMessage: 'Unpublishing a product will remove the item from the marketplace. Are you sure you want to unpublish this product?',
  unpublishProductModalButton: 'Unpublish',
  unpublishedSuccessMessage: 'Product has been successfully unpublished',
  partnershipSelectLabel: 'Partnership',
  partnershipSelectPlaceholder: 'Select or Create a Partnership',
  retailPriceLabel: 'Retail Price',
  retailPriceErrorMessage: 'Please enter the product price',
  adminProductOptionsButton: 'Options',
  couponCodeHintMessage: 'Use the coupon code below in order to claim your CCMI Network Discount.',
  couponCodeCopiedMessage: 'Coupon Code Copied!',
  copyToClipboardHintMessage: 'Copy to clipboard',
  changeImageSlideLabel: 'Change to item {0}',
  productLinkButton: 'Get Product',
  productNotFoundTitle: 'Product Not Found',
  productNotFoundMessage: 'We\'re sorry, the product you\'re looking for either does not exist or has been removed from the Complete Concussions Marketplace.',
  productNotFoundBackButton: 'Back to Marketplace',
  productPriceDiscountPercentLabel: 'Save {0}%',
  productPriceSaveLabel: 'save {0}',
  networkDiscountPriceLabel: 'CCMI Network Discount',
  productFiltersTitle: 'Product Filters',
  productResultsCountLabel: '{0} Result',
  productResultsPluralCountLabel: '{0} Results',
  viewResultsButtonLabel: 'View Results',
  filtersLabel: 'Filters',
  categoriesFilterLabel: 'Categories',
  allCategoryLabel: 'All',
  allProductsTitle: 'All Products',
  brandsFilterLabel: 'Brands',
  productsEmptyPageTitle: 'No Products Available Yet!',
  productsEmptyPageMessage: 'Come back soon to get exclusive deals on products from our CCMI Network partnerships. This page will be updated once products become available.',
  assessmentToolsCategory: 'Assessment Tools',
  rehabToolsCategory: 'Rehab Tools',
  visionTherapyCategory: 'Vision Therapy',
  vestibularTherapyCategory: 'Vestibular Therapy',
  vrHeadsetCategory: 'Virtual Reality Headsets',
  medicalSuppliesCategory: 'Medical Supplies',
  neckRehabCategory: 'Neck Rehab',
  clinicalSoftwareCategory: 'Clinical Software',
  sportsEquipementCategory: 'Sports Equipment',
  miscellaneousCategory: 'Miscellaneous',
  heartRateMonitorsCategory: 'Heart Rate Monitors',
  fitnessEquipmentCategory: 'Fitness Equipment'
};

export default EN;
