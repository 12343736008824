import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import Modal from '../modal';
import { ErrorBanner } from '../errors';
import { CheckboxInputGroup, FormComponent } from '@/forms';
import { updateClinicAsync } from '@/redux/thunks/clinics';
import Icon from '../icon';
import { showAlert } from '../alert-notifications/alert';
import Strings from './lang';

const isDirty = (clinic, fields) => {
  return clinic.searchable !== fields.searchable
    || clinic.idt_provider !== fields.idtProvider
    || clinic.resources_enabled !== fields.resources;
};

const EditClinicFeatures = ({ clinic, onClose }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [fields, setFields] = useState(() => ({
    searchable: clinic.searchable,
    idtProvider: clinic.idt_provider,
    resources: clinic.resources_enabled
  }));
  const dirty = useMemo(() => isDirty(clinic, fields), [clinic, fields]);

  const handleSubmit = useCallback(() => {
    if (!dirty) {
      onClose();
      return;
    }

    setLoading(true);
    setError(null);

    const attributes = {
      searchable: fields.searchable,
      idt_provider: fields.idtProvider,
      resources_enabled: fields.resources
    };

    dispatch(updateClinicAsync(clinic.id, attributes)).then(() => {
      setLoading(false);
      onClose();
      
      showAlert('success', {
        dismissable: true,
        autoDismiss: 3000,
        message: Strings.featuresUpdatedMessage
      });
    }).catch(error => {
      setLoading(false);
      setError(error.message);
    });
  }, [
    clinic.id,
    dirty,
    dispatch,
    fields,
    onClose
  ]);

  return (
    <div>
      <ErrorBanner error={error} />
      <FormComponent onSubmit={handleSubmit}>
        <CheckboxInputGroup
          inputValid
          className="form-group"
          labelText={Strings.searchableInputLabelText}
          inputProps={{
            className: 'form-control',
            checked: fields.searchable,
            onChange: (e) => setFields(prev => ({ ...prev, searchable: e.target.checked }))
          }}
        />
        <CheckboxInputGroup
          inputValid
          className="form-group"
          labelText={Strings.axiaIdtProviderLabel}
          inputProps={{
            className: 'form-control',
            checked: fields.idtProvider,
            onChange: (e) => setFields(prev => ({ ...prev, idtProvider: e.target.checked }))
          }}
        />
        <CheckboxInputGroup
          inputValid
          className="form-group"
          labelText={Strings.resourcesEnabledLabel}
          inputProps={{
            className: 'form-control',
            checked: fields.resources,
            onChange: (e) => setFields(prev => ({ ...prev, resources: e.target.checked }))
          }}
        />
        <div className="form-footer">
          <button type="submit" className="btn btn-primary" disabled={loading || !dirty}>
            {loading && <><Icon fixedWidth spinPulse prefix="fad" name="loader" />&nbsp;</>}
            {Strings.submitButtonText}
          </button>
        </div>
      </FormComponent>
    </div>
  );
};

const EditClinicFeaturesModal = ({
  clinic,
  opened = false,
  onClose
}) => {
  return (
    <Modal
      isOpen={opened}
      onClose={onClose}
    >
      <Modal.Header onClose={onClose}>
        <h2>{Strings.editFeaturesTitle}</h2>
      </Modal.Header>
      <Modal.Body>
        <EditClinicFeatures
          clinic={clinic}
          onClose={onClose}
        />
      </Modal.Body>
    </Modal>
  );
};

export default EditClinicFeaturesModal;
