import { FACEBOOK_URL_REGEX, INSTAGRAM_URL_REGEX, TWITTER_URL_REGEX } from '@/utilities/social';

// Pattern modified from https://gist.github.com/dperini/729294
const UrlPattern = /^(?:(?:https?):\/\/)?(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i;

const UrlValidator = {
  test(value) {
    return UrlPattern.test(value);
  }
};

const SocialUrlValidator = {
  facebook: {
    test(value) {
      return FACEBOOK_URL_REGEX.test(value);
    }
  },
  instagram: {
    test(value) {
      return INSTAGRAM_URL_REGEX.test(value);
    }
  },
  twitter: {
    test(value) {
      return TWITTER_URL_REGEX.test(value);
    }
  }
};

export {
  UrlValidator,
  SocialUrlValidator,
  UrlPattern
};
