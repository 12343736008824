import React from 'react';
import dayjs from 'dayjs';
import { isRangeValid, RangeStrings } from './ranges';
import Icon from '../icon';

const RangeView = ({
  range,
  label
}) => {
  if (!range.rangeKey || !isRangeValid(range)) {
    return label ? (<span>{label}</span>) : null;
  }

  if (range.rangeKey === 'custom') {
    return (
      <span className="date-range-view">
        <span>{dayjs(range.startDate).format('MMM D, YYYY')}</span>
        <Icon name="caret-right" />
        <span>{dayjs(range.endDate).format('MMM D, YYYY')}</span>
      </span>
    );
  }

  return (
    <span className="date-range-view">
      <span>{RangeStrings[range.rangeKey]}</span>
    </span>
  );
};

export default RangeView;
