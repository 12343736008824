const EN = {
  title: 'Injuries',
  soapLinkText: 'Create SOAP Note for injury {0}',
  createButtonText: 'Create Injury',
  dateErrorMessage: 'Please enter a valid date prior to or equal to today\'s date',
  startAssessmentButtonText: 'Start Assessment',
  injuryDateLabel: 'Injury Date',
  injuryDateCardHeader: 'Date of Injury',
  daysSinceInjuryConfirmationMessage: '{1}. By continuing, you agree that this patient is {0} days post injury',
  postInjuryAssessmentTitle: 'New Injury'
};

export default EN;
