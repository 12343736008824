const MAX_INCLINE = 15;
const MIN_SPEED = 3.3;
const SPEED_INCREMENT = 0.4;
const MAX_CONSTANT_SPEED_MINUTE = 18;
const MAX_V2_INTERVAL = 20;
const MINUTE_INTERVAL_OFFSET = 2;

const defaultV2Results = {
  heart_rate: '',
  symptoms: '',
  borg_scale: '',
  symptom_score: ''
};

const getDefaultV2Results = (propResults) => {
  const { minute_intervals = [] } = propResults;
  return [...new Array(minute_intervals.length || (MAX_V2_INTERVAL + 1))].map((na, index) => {
    return {
      ...defaultV2Results, ...(minute_intervals[index] || {})
    };
  });
};

const getBuffaloTestV2State = (props) => {
  const { buffalo = {} } = props;
  const {
    symptom_exacerbation = '',
    exacerbated_symptoms_heart_rate = '',
    exacerbated_symptoms_time = '',
    exacerbated_symptoms_reported = '',
    exacerbated_symptoms_borg_scale = '',
    resting_heart_rate = '',
    resting_borg_scale = '',
    resting_symptom_score = '',
    testing_equipement = '',
    notes = '',
    results = {}
  } = buffalo;

  return {
    buffalo: {
      symptom_exacerbation,
      exacerbated_symptoms_heart_rate,
      exacerbated_symptoms_time,
      exacerbated_symptoms_reported,
      exacerbated_symptoms_borg_scale,
      resting_heart_rate,
      resting_borg_scale,
      resting_symptom_score,
      testing_equipement,
      notes,
      results: {
        minute_intervals: [...getDefaultV2Results(results)]
      }
    }
  };
};

export {
  getBuffaloTestV2State,
  MAX_INCLINE,
  MIN_SPEED,
  SPEED_INCREMENT,
  MAX_CONSTANT_SPEED_MINUTE,
  MAX_V2_INTERVAL,
  MINUTE_INTERVAL_OFFSET,
  defaultV2Results
};
