const EN = {
  generalHeadingText: 'General',
  contactHeadingText: 'Contact',
  addressHeadingText: 'Address',
  billingAddressHeadingText: 'Billing Address',
  actionsHeadingText: 'Quick Actions',
  usersActionHeadingText: 'Manage Users',
  addUserActionHeadingText: 'Add a User',
  patientsActionHeadingText: 'Manage Patients',
  bagtagsActionHeadingText: 'Manage Concussion ID Card Numbers',
  editButtonText: 'Edit',
  cancelButtonText: 'Cancel',
  activateClinicButtonText: 'Activate Clinic',
  activateClinicModalTitle: 'Activate Clinic',
  activateClinicModalMessage: 'Are you sure you want to activate {0}?',
  activateClinicModalConfirmButtonTitle: 'Activate',
  deactivateClinicButtonText: 'Deactivate Clinic',
  deactivateClinicModalTitle: 'Deactivate Clinic',
  deactivateClinicModalMessage: 'Are you sure you want to deactivate {0}?',
  deactivateClinicModalConfirmButtonTitle: 'Deactivate',
  viewAccountHeadingText: 'Account Balance',
  creditsRemainingText: 'Credits Remaining',
  enableMapleButtonText: 'Enable Maple',
  mapleEnabledSuccessMessage: 'Maple has been enabled for {0}',
  disabledMapleButtonText: 'Disable Maple',
  mapleDisabledSuccessMessage: 'Maple has been disabled for {0}',
  editCreditPriceLabel: 'Edit Credit Price',
  defaultPriceCheckboxLabel: 'Default Price',
  defaultLabel: 'Default',
  creditPriceLabel: 'Credit Price',
  creditPriceErrorMessage: 'Please enter a price greater than or equal to 0',
  submitButtonText: 'Submit',
  featuresTitle: 'Features',
  editFeaturesTitle: 'Edit Clinic Features',
  clinicMapLabel: 'Clinic Map',
  searchableInputLabelText: 'Show Clinic On Map?',
  axiaIdtProviderLabel: 'Axia IDT Provider',
  resourcesEnabledLabel: 'Resources Enabled',
  featuresUpdatedMessage: 'Clinic features updated successfully'
};

export default EN;
