const FR = {
  saveAndContinueButtonText: 'Sauvegarder et continuer',
  diagnosisAndPlanTitle: 'Diagnostic et Plan de gestion',
  diagnosisHeader: 'Diagnostic',
  followUpActionHeader: 'Tâche pour faire le suivi',
  initialManagementHeader: 'Gestion initiale',
  recoveryStagesHeader: 'Étapes de récupération',
  communicationActionHeader: 'Communication du diagnostic, Rapport des observations, Plan de gestion et Instruction du patient'
};
 
export default FR;
