import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { withRegistrationStep } from '../step-wrappers';
import { createAddChildAsync } from '@/redux/thunks/guardians-account';
import { ErrorBanner } from '../../errors';
import Activity from '../../activity';
import GuardianRegisterChildForm from './guardian-register-child-form';
import Strings from './lang';

const GuardianRegisterChild = ({
  currentUser,
  next
}) => {
  const dispatch = useDispatch();
  const [activity, setActivity] = useState(false);
  const [error, setError] = useState(null);

  const onSubmit = useCallback((attributes) => {
    setActivity(true);
    setError(null);

    dispatch(createAddChildAsync(currentUser, attributes)).then((user) => {
      setActivity(false);
      next(user.id);
    }).catch(error => {
      setActivity(false);
      setError(error.message);
    });
  }, [currentUser, dispatch, next]);

  return (
    <div className="registration-child-about">
      <h1 className="display dot text-center">
        {Strings.childAccountTitle}
      </h1>
      <ErrorBanner error={error} />
      <Activity active={activity}>
        <GuardianRegisterChildForm
          user={currentUser}
          onSubmit={onSubmit}
        />
      </Activity>
    </div>
  );
};

export default withRegistrationStep('about')(GuardianRegisterChild);
