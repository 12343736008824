import React from 'react';
import PropTypes from 'prop-types';

const NumberRow = ({
  numberArray, 
  joinCharacter = ' ', 
  ElementType = 'div', 
  className
}) => (
  <ElementType className={className}>
    {numberArray.join(joinCharacter)}
  </ElementType>
);

NumberRow.propTypes = {
  ElementType: PropTypes.node,
  className: PropTypes.string,
  joinCharacter: PropTypes.string,
  numberArray: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired
};

export default NumberRow;
