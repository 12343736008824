import React, { useCallback, useMemo } from 'react';
import classnames from 'classnames';
import Select from '../../react-select';
import { SymptomOptions, MetricOptions, METRIC_KEYS } from './utils';
import Strings from '../lang';

const MetricSelector = ({
  value,
  onSelect = () => { }
}) => {
  return (
    <div className="btn-group">
      {MetricOptions().map(option => (
        <button
          key={option.value}
          type="button"
          className={classnames('btn btn-sm btn-light', { selected: value === option.value })}
          onClick={() => onSelect(option.value)}
        >
          {option.label}
        </button>
      ))}
    </div>
  );
};

const SymptomSelector = ({
  value,
  onChange
}) => {
  const selectValue = useMemo(() => SymptomOptions.find(option => option.value === value), [value]);

  const handleChange = useCallback((option) => {
    onChange(option.value);
  }, [onChange]);

  return (
    <Select
      value={selectValue}
      options={SymptomOptions}
      placeholder={Strings.symptomSelectorPlaceholder}
      onChange={handleChange}
    />
  );
};

const ChartMetricSelector = ({
  metric,
  onChange
}) => {
  const isIndividual = metric !== METRIC_KEYS.Severity && metric !== METRIC_KEYS.Totals;

  return (
    <div className="chart-metric-selector">
      <MetricSelector
        value={isIndividual ? 'individual' : metric}
        onSelect={onChange}
      />
      {isIndividual && (
        <SymptomSelector
          value={metric}
          onChange={onChange}
        />
      )}
    </div>
  );
};

export default ChartMetricSelector;
