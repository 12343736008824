import React from 'react';
import PropTypes from 'prop-types';
import Strings from './lang';

const workSchoolStatusString = {
  full: Strings.workSchoolFullOptionLabel,
  modified: Strings.workSchoolModifiedOptionLabel
};

const excerciseRestrictionStrings = {
  true: Strings.exerciseRestritionsLabel,
  false: Strings.exerciseNoRestrictionsLabel
};

const therapyStrings = {
  soft_tissue: Strings.softTissueLabel,
  spinal_manipulative: Strings.spinalManipulativeLabel
};

const rehabStrings = {
  cervical_spine: Strings.cervicalSpineLabel,
  neurocognitive: Strings.neurocognitiveLabel,
  sport_specific: Strings.sportSpecificLabel,
  vestibular: Strings.vestibularLabel,
  visual_tracking: Strings.visualTrackingLabel
};

const buildTherapyStrings = (therapy = {}) => {
  const strs = Object.keys(therapy || {}).reduce((acc, key) => {
    if (therapy[key] === true && key !== 'other') {
      return [
        ...acc,
        therapyStrings[key]
      ];
    }
    return acc;
  }, []);

  if (therapy.other === true) {
    strs.push(Strings.formatString(
      Strings.otherTherapyLabel,
      therapy.other_therapy
    ));
  }

  return strs.join(', ');
};

const buildRehabString = (rehab) => {
  return Object.keys(rehab || {}).reduce((acc, key) => {
    if (rehab[key] === true) {
      return [
        ...acc,
        rehabStrings[key]
      ];
    }
    return acc;
  }, []).join(', ');
};

const PostInjuryInitialManagement = ({
  initialManagement = {}
}) => {
  const { 
    exercise = {}, reevaluation_date = '', rehab = {}, therapy = {}, work_school = {} 
  } = initialManagement;
  return (
    <div className="post-injury-section post-injury-initial-management">
      <div className="row">
        <div className="col-md-12">
          <h2 className="bold">{Strings.initialManagementTitle}</h2>
        </div>
      </div>
      <div className="post-injury-group">
        <div className="row">
          <div className="col-md-3">
            <div>
              <strong>{Strings.workSchoolLabel}:</strong>&nbsp;
              <span>{workSchoolStatusString[work_school.status]}</span>
            </div>
            <div>
              <strong>{Strings.explanationLabel}:</strong> <span>{work_school.explanation}</span>
            </div>
          </div>
          <div className="col-md-3">
            <div>
              <strong>{Strings.exerciseLabel}:</strong>&nbsp;
              <span>{excerciseRestrictionStrings[exercise.restrictions]}</span>
            </div>
            <div>
              <strong>{Strings.explanationLabel}:</strong> <span>{exercise.explanation}</span>
            </div>
          </div>
          <div className="col-md-3">
            <strong>{Strings.therapyLabel}:</strong> <span>{buildTherapyStrings(therapy)}</span>
          </div>
          <div className="col-md-3">
            <div>
              <strong>{Strings.rehabLabel}:</strong> <span>{buildRehabString(rehab)}</span>
            </div>
            <div>
              <strong>{Strings.explanationLabel}:</strong> <span>{rehab.explanation}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="post-injury-group">
        <div className="row">
          <div className="col-md-6">
            <strong>{Strings.reevaluationDateLabel}:</strong>&nbsp;
            <span>
              {reevaluation_date.length > 0 
                ? reevaluation_date 
                : Strings.noReevaluationDateText}
            </span>
          </div>
        </div>
      </div>

    </div>
  );
};

PostInjuryInitialManagement.propTypes = {
  initialManagement: PropTypes.object
};

export default PostInjuryInitialManagement;
