import React, { useCallback, useState } from 'react';
import LineItemFormFields from './line-item-form-fields';
import LineItemTaxFields from './line-item-tax-fields';
import { FormComponent, SelectInputGroup, FormInputGroup } from '@/forms';
import { Money } from '@/utilities/currency';
import { CreditPrice } from '../../bulk-credits/price';
import { LineItemTypes } from '../utilities';
import { LineItemValidators } from './utils';
import Strings from '../lang';

const AMOUNT_CENTS_MULTIPLIER = 100;
const DEFAULT_IMPACT_COST = 10.00;

const getLineItemAttributesFromProps = (type, lineItem = {}) => {
  const { 
    patient_account: account = '', 
    used_at: activation_date = '', 
    post_injury = false,
    category = '',
    description = ''
  } = lineItem;
  switch (type) {
    case LineItemTypes.Credit:
      return { account, activation_date };
    case LineItemTypes.Impact:
      return { account, post_injury };
    case LineItemTypes.MapleConsult:
      return { account, category };
    case LineItemTypes.Custom:
      return { description };
    default:
      return {};
  }
};

const getDefaultAmount = (type, amount, currency) => {
  if (amount) {
    return Money.unformat(amount || 0, currency);
  }

  switch (type) {
    case LineItemTypes.Credit:
      return CreditPrice[currency];
    case LineItemTypes.Impact:
      return DEFAULT_IMPACT_COST;
    case LineItemTypes.MapleConsult:
    default:
      return 0;
  }
};

const getInitialTaxes = (lineItem, currency) => {
  const { tax_rate, tax_code, tax } = lineItem;

  return {
    tax_rate,
    tax_code,
    tax_cents: Money.unformat(tax, currency) * 100
  };
};

const isValid = (type, amount, attributes) => {
  const floatAmount = parseFloat(amount);
  return Object.values(LineItemTypes).indexOf(type) >= 0
    && (!isNaN(floatAmount) && amount >= 0)
    && LineItemValidators[type](attributes);
};

const LineItemForm = ({
  lineItem = {},
  currency = 'CAD',
  onSubmit
}) => {
  const [itemType, setItemType] = useState(lineItem.type || '');
  const [note, setNote] = useState(lineItem.note || '');
  const [amount, setAmount] = useState(getDefaultAmount(itemType, lineItem.amount, currency));
  const [attributes, setAttributes] = useState(getLineItemAttributesFromProps(itemType, lineItem));
  const [submitted, setSubmitted] = useState(false);
  const [taxes, setTaxes] = useState(getInitialTaxes(lineItem, currency));
  const editting = !!lineItem && !!lineItem.id;

  const handleSubmit = useCallback(() => {
    const attrs = {
      note: note || null,
      line_itemable_type: itemType,
      cost_cents: parseFloat(amount) * AMOUNT_CENTS_MULTIPLIER,
      line_itemable_attributes: attributes,
      ...taxes
    };

    if (lineItem.id) {
      attrs.id = lineItem.id;
      attrs.line_itemable_attributes = {
        id: lineItem.itemId,
        ...(attrs.line_itemable_attributes || {})
      };
    }

    setSubmitted(true);

    if (isValid(itemType, amount, attributes)) {
      onSubmit(attrs);
    }
  }, [
    amount, 
    note,
    attributes, 
    itemType, 
    lineItem.id, 
    lineItem.itemId, 
    taxes,
    onSubmit
  ]);

  return (
    <FormComponent onSubmit={handleSubmit}>
      <SelectInputGroup
        required
        touched={submitted}
        labelText={Strings.lineItemTypeLabel}
        inputProps={{
          className: 'form-control',
          value: itemType,
          disabled: editting
        }}
        onUpdate={(value) => {
          setItemType(value);
          setAmount(getDefaultAmount(value, null, currency));
          setAttributes({});
        }}
        messageClassName="alert alert-danger"
        messageText={Strings.lineItemTypeErrorMessage}
        inputValid={itemType !== ''}
      >
        <option value="">{Strings.selectLineItemTypeOption}</option>
        <option value={LineItemTypes.Credit}>{Strings.creditLabel}</option>
        <option value={LineItemTypes.Impact}>{Strings.impactCodeLabel}</option>
        <option value={LineItemTypes.MapleConsult}>{Strings.mapleConsultLabel}</option>
        <option value={LineItemTypes.OverduePaymentFee}>{Strings.latePaymentFeeLabel}</option>
        <option value={LineItemTypes.Custom}>{Strings.customChargeLabel}</option>
      </SelectInputGroup>
      {itemType !== '' && (
        <>
          <LineItemFormFields 
            type={itemType}
            amount={amount}
            submitted={submitted}
            currency={currency}
            attributes={attributes}
            onUpdateAmount={setAmount}
            onUpdateAttributes={setAttributes}
          />
          {editting && (
            <LineItemTaxFields 
              taxRate={taxes.tax_rate}
              taxCode={taxes.tax_code}
              onUpdate={(code, rate) => {
                const parsedRate = parseFloat(rate) || 0;
                setTaxes({
                  tax_code: code,
                  tax_rate: parsedRate,
                  tax_cents: Math.round((amount * (parsedRate / 100.0)) * 100)
                });
              }}
            />
          )}
          <FormInputGroup 
            inputValid
            inputType="textarea"
            className="form-group"
            labelText={Strings.noteLabel}
            inputProps={{
              className: 'form-control',
              value: note,
              rows: 3,
              onChange: (e) => setNote(e.target.value)
            }}
          />
          <button 
            type="submit" 
            className="btn btn-primary"
            disabled={!isValid(itemType, amount, attributes)}
          >
            {Strings.submitButtonText}
          </button>
        </>
      )}
    </FormComponent>
  );
};

export default LineItemForm;
