const UPDATE_GUARDIAN_AUTH = 'UPDATE_GUARDIAN_AUTH';
const DELETE_GUARDIAN_AUTH = 'DELETE_GUARDIAN_AUTH';

const updateGuardianAuth = (user) => ({
  type: UPDATE_GUARDIAN_AUTH,
  user
});

const deleteGuardianAuth = () => ({
  type: DELETE_GUARDIAN_AUTH
});

export {
  updateGuardianAuth,
  deleteGuardianAuth,
  UPDATE_GUARDIAN_AUTH,
  DELETE_GUARDIAN_AUTH
};
