const FR = {
  hockey: 'Hockey',
  soccer: 'Soccer/European Football',
  football: 'American/Canadian Football',
  rugby: 'Rugby',
  basketball: 'Basketball',
  baseball: 'Baseball',
  volleyball: 'Volleyball',
  gymnastics: 'Gymnastique',
  cheerleading: 'Claque',
  dancing: 'Danse',
  mma: 'Arts martiaux mixtes',
  boxing: 'Boxe',
  kickboxing: 'Kickboxing',
  cycling: 'Cyclisme',
  bmx: 'Vélo de montagne/BMX',
  motocross: 'Motocross',
  motorcycling: 'Course en motocyclette',
  skateboarding: 'Planche à roulettes',
  skiing: 'Ski/Planche à neige',
  figureskating: 'Patin artistique',
  surfing: 'Surf',
  dodgeball: 'Ballon prisonnier',
  curling: 'Curling',
  ringette: 'Ringuette',
  wrestling: 'Lutte',
  equestrian: 'Sports équestres',
  fieldhockey: 'Hockey sur Gazon',
  boxlacrosse: 'Lacrosse Enclos',
  fieldlacrosse: 'Lacrosse de Champ',
  lacrosse: 'Lacrosse',
  cricket: 'Cricket',
  aussierules: 'Football Australien',
  other: 'Autre'
};

export default FR;
