import Strings from './lang';

const LIFECYCLE_MAX_TENSION = 20;
const MONARK_MAX_TENSION = 7;

const BlackhawksBikeTensionData = {
  full: {
    1: {
      lifecycle: [
        { factor: 0.55 }, 
        { factor: 0.6 }, 
        { factor: 0.65 }, 
        { factor: 0.7 }, 
        { factor: 0.75 }, 
        { factor: 0.8 }
      ],
      monark: [
        { factor: 0.21, round: 1 }, 
        { factor: 0.28, round: 1 }, 
        { factor: 0.36, round: 1 }, 
        { factor: 0.43, round: 1 }, 
        { factor: 0.5, round: 1 }, 
        { factor: 0.57, round: 1 }
      ]
    },
    2: {
      lifecycle: [
        { factor: 0.85, plusMinus: 1 },
        { factor: 0.55 }
      ],
      monark: [
        { factor: 0.64, round: 1, plusMinus: 0.5 },
        { factor: 0.21, round: 1 }
      ]
    },
    3: {
      lifecycle: [
        { factor: 0.85, plusMinus: 1 },
        { factor: 0.55 }
      ],
      monark: [
        { factor: 0.64, round: 1, plusMinus: 0.5 },
        { factor: 0.21, round: 1 }
      ]
    }
  },
  modified: {
    1: {
      lifecycle: [
        { factor: 0.35 }, 
        { factor: 0.4 }, 
        { factor: 0.45 }, 
        { factor: 0.5 }, 
        { factor: 0.55 }, 
        { factor: 0.6 }
      ],
      monark: [
        { factor: 0.07, round: 1 }, 
        { factor: 0.14, round: 1 }, 
        { factor: 0.21, round: 1 }, 
        { factor: 0.28, round: 1 }, 
        { factor: 0.36, round: 1 }, 
        { factor: 0.43, round: 1 }
      ]
    },
    2: {
      lifecycle: [
        { factor: 0.65, plusMinus: 1 },
        { factor: 0.35 }
      ],
      monark: [
        { factor: 0.5, round: 1, plusMinus: 0.5 },
        { factor: 0.07, round: 1 }
      ]
    },
    3: {
      lifecycle: [
        { factor: 0.65, plusMinus: 1 },
        { factor: 0.35 }
      ],
      monark: [
        { factor: 0.5, round: 1, plusMinus: 0.5 },
        { factor: 0.07, round: 1 }
      ]
    }
  }
};

const makeStationaryBikeSegment = (options = {}) => {
  const {
    time = ':30',
    tension = '1.5',
    rpm = '90 - 95'
  } = options;

  return { 
    time, tension, rpm, heart_rate: '', symptoms: '' 
  };
};

const getMaximumTension = (bikeType, maximumTension) => {
  switch (bikeType) {
    case 'lifecycle':
      return LIFECYCLE_MAX_TENSION;
    case 'monark':
      return MONARK_MAX_TENSION;
    default:
      return maximumTension;
  }
};

const makeTensionValueFromBikeType = (testType, bikeType, stage, index, maximumTension) => {
  let tensionMultipliers = BlackhawksBikeTensionData[testType][stage];
  const maxTension = getMaximumTension(bikeType, maximumTension);

  if (bikeType === 'custom') {
    // always use the lifecycle multipliers for custom bike tensions
    tensionMultipliers = tensionMultipliers.lifecycle;
  } else {
    tensionMultipliers = tensionMultipliers[bikeType] || [];
  }

  const tensionData = tensionMultipliers[index] || {};
  let tensionLevel = tensionData.factor * maxTension;
  tensionLevel = parseFloat(tensionLevel.toFixed(tensionData.round || 0));

  if (tensionData.plusMinus) {
    tensionLevel = `${tensionLevel - tensionData.plusMinus} \u2013 ${tensionLevel + tensionData.plusMinus}`;
  }

  return tensionLevel;
};

/***********/
/* Stage 1 */
/***********/
/* eslint-disable no-magic-numbers */
const getStationaryBikeStage1Segments = (fullTest, bikeType, maxTension) => {
  const testType = fullTest ? 'full' : 'modified';
  const initialRPM = fullTest ? '90' : '80';
  const middleRPM = fullTest ? '90 - 95' : '80 - 85'; 
  const finalRPM = fullTest ? '80' : '70';

  return [
    makeStationaryBikeSegment({
      time: '3:00',
      tension: makeTensionValueFromBikeType(testType, bikeType, 1, 0, maxTension),
      rpm: Strings.formatString(
        Strings.stationaryBikeStageWarmUpRpm,
        initialRPM
      )
    }),
    makeStationaryBikeSegment({ 
      tension: makeTensionValueFromBikeType(testType, bikeType, 1, 1, maxTension), 
      rpm: middleRPM 
    }),
    makeStationaryBikeSegment({ 
      tension: makeTensionValueFromBikeType(testType, bikeType, 1, 2, maxTension), 
      rpm: middleRPM 
    }),
    makeStationaryBikeSegment({ 
      tension: makeTensionValueFromBikeType(testType, bikeType, 1, 3, maxTension), 
      rpm: middleRPM 
    }),
    makeStationaryBikeSegment({ 
      tension: makeTensionValueFromBikeType(testType, bikeType, 1, 4, maxTension), 
      rpm: middleRPM 
    }),
    makeStationaryBikeSegment({ 
      tension: makeTensionValueFromBikeType(testType, bikeType, 1, 5, maxTension), 
      rpm: middleRPM 
    }),
    makeStationaryBikeSegment({ 
      tension: makeTensionValueFromBikeType(testType, bikeType, 1, 0, maxTension), 
      rpm: middleRPM 
    }),
    makeStationaryBikeSegment({ 
      tension: makeTensionValueFromBikeType(testType, bikeType, 1, 1, maxTension), 
      rpm: middleRPM 
    }),
    makeStationaryBikeSegment({ 
      tension: makeTensionValueFromBikeType(testType, bikeType, 1, 2, maxTension), 
      rpm: middleRPM 
    }),
    makeStationaryBikeSegment({ 
      tension: makeTensionValueFromBikeType(testType, bikeType, 1, 3, maxTension), 
      rpm: middleRPM 
    }),
    makeStationaryBikeSegment({ 
      tension: makeTensionValueFromBikeType(testType, bikeType, 1, 4, maxTension), 
      rpm: middleRPM 
    }),
    makeStationaryBikeSegment({ 
      tension: makeTensionValueFromBikeType(testType, bikeType, 1, 5, maxTension), 
      rpm: middleRPM 
    }),
    makeStationaryBikeSegment({
      time: '1:30',
      tension: makeTensionValueFromBikeType(testType, bikeType, 1, 0, maxTension),
      rpm: finalRPM
    })
  ];
};

/***********/
/* Stage 2 */
/***********/

const getStationaryBikeStage2Segments = (fullTest, bikeType, maxTension) => {
  const testType = fullTest ? 'full' : 'modified';
  const maxTensionString = Strings.formatString(
    Strings.stationaryBikeStageMaxTension,
    makeTensionValueFromBikeType(testType, bikeType, 2, 0, maxTension)
  );
  const rpmValue = fullTest ? '80' : '70';
  const tension = makeTensionValueFromBikeType(testType, bikeType, 2, 1, maxTension);

  return [
    makeStationaryBikeSegment({
      time: ':20',
      tension: maxTensionString,
      rpm: Strings.stationaryBikeStageMaxRpm
    }),
    makeStationaryBikeSegment({
      time: ':20',
      tension,
      rpm: rpmValue
    }),
    makeStationaryBikeSegment({
      time: ':20',
      tension: maxTensionString,
      rpm: Strings.stationaryBikeStageMaxRpm
    }),
    makeStationaryBikeSegment({
      time: ':20',
      tension,
      rpm: rpmValue
    }),
    makeStationaryBikeSegment({
      time: ':20',
      tension: maxTensionString,
      rpm: Strings.stationaryBikeStageMaxRpm
    }),
    makeStationaryBikeSegment({
      time: ':20',
      tension,
      rpm: rpmValue
    }),
    makeStationaryBikeSegment({
      time: ':20',
      tension: maxTensionString,
      rpm: Strings.stationaryBikeStageMaxRpm
    }),
    makeStationaryBikeSegment({
      time: ':40',
      tension,
      rpm: rpmValue
    }),
    makeStationaryBikeSegment({
      time: '0:40',
      tension: Strings.stationaryBikeStageStopped,
      rpm: Strings.stationaryBikeStageStopped
    })
  ];
};

/***********/
/* Stage 3 */
/***********/

const getStationaryBikeStage3Segments = (fullTest, bikeType, maxTension) => {
  const testType = fullTest ? 'full' : 'modified';
  const maxTensionString = Strings.formatString(
    Strings.stationaryBikeStageMaxTension,
    makeTensionValueFromBikeType(testType, bikeType, 3, 0, maxTension)
  );

  return [
    makeStationaryBikeSegment({
      time: ':10',
      tension: maxTensionString,
      rpm: Strings.stationaryBikeStageMaxRpm
    }),
    makeStationaryBikeSegment({
      time: ':10',
      tension: Strings.stationaryBikeStageStopped,
      rpm: Strings.stationaryBikeStageStopped
    }),
    makeStationaryBikeSegment({
      time: ':10',
      tension: maxTensionString,
      rpm: Strings.stationaryBikeStageMaxRpm
    }),
    makeStationaryBikeSegment({
      time: ':10',
      tension: Strings.stationaryBikeStageStopped,
      rpm: Strings.stationaryBikeStageStopped
    }),
    makeStationaryBikeSegment({
      time: ':10',
      tension: maxTensionString,
      rpm: Strings.stationaryBikeStageMaxRpm
    }),
    makeStationaryBikeSegment({
      time: ':10',
      tension: Strings.stationaryBikeStageStopped,
      rpm: Strings.stationaryBikeStageStopped
    }),
    makeStationaryBikeSegment({
      time: ':10',
      tension: maxTensionString,
      rpm: Strings.stationaryBikeStageMaxRpm
    }),
    makeStationaryBikeSegment({
      time: ':10',
      tension: Strings.stationaryBikeStageStopped,
      rpm: Strings.stationaryBikeStageStopped
    }),
    makeStationaryBikeSegment({
      time: '0:40',
      tension: makeTensionValueFromBikeType(testType, bikeType, 3, 1, maxTension),
      rpm: '80 - 85'
    })
  ];
};
/* eslint-enable no-magic-numbers */

const getStationaryBikeData = (fullTest, bikeType, maxTension) => {
  return {
    stationary_bike_stage_1: getStationaryBikeStage1Segments(fullTest, bikeType, maxTension),
    stationary_bike_stage_2: getStationaryBikeStage2Segments(fullTest, bikeType, maxTension),
    stationary_bike_stage_3: getStationaryBikeStage3Segments(fullTest, bikeType, maxTension)
  };
};

export {
  getStationaryBikeStage1Segments,
  getStationaryBikeStage2Segments,
  getStationaryBikeStage3Segments,
  getStationaryBikeData
};
