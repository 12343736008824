import React, { useCallback, useState } from 'react';
import dayjs from 'dayjs';
import { daysSince } from '@/utilities/dates';
import { FormComponent, DateInputGroup, FormInputMessage } from '@/forms';
import Card from '../card';
import { INPUT_DATE_FORMAT, DISPLAY_DATE_FORMAT } from '@/utilities/dates/formats';
import DateStrings from '@/utilities/dates/lang';
import Strings from './lang';

const CreateInjuryForm = ({ onSubmit }) => {
  const [submitted, setSubmitted] = useState(false);
  const [date, setDate] = useState({ value: '', valid: false });
  const daysSinceInjury = daysSince(date.value, dayjs());
  const dateInjury = dayjs(date.value, INPUT_DATE_FORMAT).format(DISPLAY_DATE_FORMAT);

  const handleSubmit = useCallback(() => {
    setSubmitted(true);

    if (!date.valid) {
      return;
    }

    onSubmit(date.value);
  }, [date.valid, date.value, onSubmit]);

  const onUpdateInjuryDate = useCallback((value, valid) => {
    setDate({ value, valid });
  }, []);

  return (
    <FormComponent onSubmit={handleSubmit}>
      <Card title={Strings.injuryDateCardHeader}>
        <div className="card card-form">
          <DateInputGroup
            className="form-group"
            labelText={Strings.injuryDateLabel}
            inputType="date"
            textInputProps={{
              className: 'form-control',
              placeholder: DateStrings.placeholder
            }}
            dateInputProps={{
              className: 'form-control'
            }}
            initialValue={date.value}
            maxDate={dayjs()}
            onUpdate={onUpdateInjuryDate}
            messageText={Strings.dateErrorMessage}
            messageClassName="alert alert-danger"
            required
            touched={submitted}
          />
          <FormInputMessage
            className="alert alert-info"
            visible={!isNaN(daysSinceInjury) && date.valid}
            text={Strings.formatString(
              Strings.daysSinceInjuryConfirmationMessage,
              daysSinceInjury,
              dateInjury
            )}
          />
          <div className="form-footer">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={!date.valid}
            >
              {Strings.startAssessmentButtonText}
            </button>
          </div>
        </div>
      </Card>
    </FormComponent>
  );
};

export default CreateInjuryForm;
