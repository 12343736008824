import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ChecklistItem from '../helpers/checklist-item';
import Strings from './lang';

class DiagnosisChecklistView extends Component {
  render() {
    const { list = [], diagnosis = {} } = this.props;
    return (
      <div className={this.props.className}>
        <ul className="list-unstyled">
          {
            list.map(key => {
              if (key === 'post_concussion_syndrome' && diagnosis.post_concussion_syndrome) {
                const diagnosisPCS = diagnosis.diagnosis_pcs || {};   
                const pcs = Object.keys(diagnosisPCS).reduce((acc, cur) => {
                  return diagnosisPCS[cur] ? [
                    ...acc,
                    Strings[cur]
                  ] : acc;
                }, []);

                return (
                  <ChecklistItem key={key} checked>
                    {Strings[key]}{pcs.length > 0 
                      ? <i className="text-muted">:&nbsp;{pcs.join(', ')}</i> 
                      : ''}
                  </ChecklistItem>
                );
              }

              return (
                <ChecklistItem key={key} checked>
                  {Strings[key]}
                </ChecklistItem>
              );
            })
          }
        </ul>
      </div>
    );
  }
}

DiagnosisChecklistView.propTypes = {
  list: PropTypes.array,
  diagnosis: PropTypes.object
};

export default DiagnosisChecklistView;
