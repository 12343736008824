import React from 'react';
import dayjs from 'dayjs';
import Icon from '../../icon';
import { DISPLAY_DATE_FORMAT } from '@/utilities/dates/formats';
import Strings from '../lang';

const ClaimDetails = ({ claim }) => {
  return (
    <section className="claim-section">
      <div className="claim-section-header">
        <Icon name="file-medical" />
        <div className="claim-section-title">{Strings.claimDetailsTitle}</div>
      </div>
      <div className="claim-section-content">
        <div className="claim-section-row">
          <label>{Strings.claimNumberColumn}:</label>
          <span>{claim.claim_number}</span>
        </div>
        <div className="claim-section-row">
          <label>{Strings.referalDateColumn}:</label>
          <span>{dayjs(claim.referral_date).format(DISPLAY_DATE_FORMAT)}</span>
        </div>
      </div>
    </section>
  );
};

export default ClaimDetails;
