import React, { useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { If, Then, Else } from 'react-if';
import classNames from 'classnames';
import Icon from '../../icon';
import { AppointmentsTable, AddAppointmentModal } from '../appointments';
import { BlockTreatmentTypeStrings } from '../utils';
import { DISPLAY_DATE_FORMAT } from '@/utilities/dates/formats';
import Fraction from '../../fraction';
import Strings from '../lang';

const getIsAfterMidPoint = (block) => {
  if (block.status !== 'approved') return false;

  const start = dayjs(block.start_date);
  const end = dayjs(block.end_date);

  return dayjs().isAfter(
    start.add(
      end.diff(start, 'days') / 2,
      'days'
    )
  );
};

const ClaimBlock = ({
  claimId,
  claimStatus,
  block
}) => {
  const [addApptModalOpen, setAddApptModalOpen] = useState(false);
  const apptCount = useMemo(() => {
    return block.appointments?.filter(appt => (
      appt.status === 'booked' || appt.status === 'arrived'
    ))?.length;
  }, [block.appointments]); 
  const otApptCount = useMemo(() => {
    return block.appointments?.filter(appt => (
      (appt.status === 'booked' || appt.status === 'arrived') && appt.treatment_type === 'occupational_therapy'
    ))?.length;
  }, [block.appointments]);
  const isBlockPastMidPoint = useMemo(() => getIsAfterMidPoint(block), [block]);

  return (
    <>
      <section className="claim-section">
        <div className="claim-section-header">
          <div className="fa block-order">
            {block.order}
          </div>
          <div className="claim-section-title">
            <div>{BlockTreatmentTypeStrings[block.treatment_type]} {block.block_number}</div>
            {claimStatus === 'active' && (
              <div className="block-duration-label">
                {dayjs(block.start_date).format(DISPLAY_DATE_FORMAT)}&nbsp;
                <Icon name="arrow-right-long" />&nbsp;
                {dayjs(block.end_date).format(DISPLAY_DATE_FORMAT)}
              </div>
            )}
          </div>
          <If condition={block.status === 'requested'}>
            <Then>
              <div className="error">
                <Icon name="code-pull-request" />&nbsp;
                {Strings.blockRequestedLabel}
              </div>
            </Then>
            <Else>
              <div className="claim-block-details">
                {claimStatus === 'active' && (
                  <div className="claim-section-action">
                    <button type="button" className="edit-button" onClick={() => setAddApptModalOpen(true)}>
                      <Icon name="plus" />
                      {Strings.addAppointmentText}
                    </button>
                  </div>
                )}
                {block.progress_status === 'completed' && (
                  <div className="success">
                    <Icon name="check-circle" />&nbsp;
                    {Strings.completedText}
                  </div>
                )}
              </div>
            </Else>
          </If>
        </div>
        <div className="claim-section-content">
          <If condition={claimStatus === 'not_started'}>
            <Then>
              <div className="text-muted text-center">
                {Strings.initialApptRequiredMessage}
              </div>
            </Then>
            <Else>
              <div>
                <AppointmentsTable
                  claimId={claimId}
                  blockId={block.id}
                  appointments={block.appointments}
                />
                {block.status === 'approved' && (
                  <div className="required-appt-indicators">
                    <div
                      className={classNames('ot-required-indicator', { warn: otApptCount <= 0 && isBlockPastMidPoint })}
                    >
                      {Strings.otApptsIndicatorLabel}:&nbsp;
                      <Fraction numerator={otApptCount} denominator={1} />
                    </div>
                    <div className="required-appt-indicator">
                      {Strings.requiredAppointmentsLabel}:&nbsp;
                      <Fraction numerator={apptCount} denominator={block.required_appointments} />
                    </div>
                  </div>
                )}
              </div>
            </Else>
          </If>
        </div>
      </section>
      <AddAppointmentModal
        isOpen={addApptModalOpen}
        block={block}
        claimId={claimId}
        onClose={() => setAddApptModalOpen(false)}
      />
    </>
  );
};

const ClaimAppointmentsTab = ({ claimId, status, blocks = [] }) => {
  return (
    <div className="idt-claim-treatment-blocks">
      {blocks.map(block => (
        <ClaimBlock
          key={block.id}
          block={block}
          claimId={claimId}
          claimStatus={status}
        />
      ))}
    </div>
  );
};

export default ClaimAppointmentsTab;
