const FR = {
  photoValidationText: 'Veuillez téléverser une image pour la clinique',
  nameLabelText: 'Nom',
  nameValidationMessage: 'Veuillez écrire un nom pour la clinique',
  impactCodeLabelText: 'Code ImPACT',
  impactCodeValidationMessage: 'Veuillez écrire le code ImPACT de la clinique',
  phoneValidationMessage: 'Veuillez écrire un numéro de téléphone valide',
  faxValidationMessage: 'Veuillez écrire un numéro de fax valide',
  ownerLabelText: 'Propriétaire de la clinique',
  phoneLabelText: 'Numéro de téléphone',
  faxLabelText: 'Numéro de fax',
  clinicUrlLabelText: 'Site Web',
  emailLabelText: 'Courriel :',
  facebookLabelText: 'Page Facebook',
  twitterLabelText: 'Compte Twitter',
  instagramLabelText: 'Compte Instagram',
  addressLabelText: 'Adresse',
  address2LabelText: 'Adresse 2',
  cityLabelText: 'Ville/municipalité',
  regionLabelText: 'État/ province',
  countryLabelText: 'Pays',
  postalCodeLabelText: 'Code postal',
  submitButtonText: 'Sauvegarder',
  yesText: 'Oui',
  noText: 'Non',
  bioLabelText: 'Bio',
  socialUrlErrorMessage: 'Veuillez saisir une URL de profil {0} valide (par exemple, {1})'
};
 
export default FR;
