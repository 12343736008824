import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { PasswordsPaths } from '@/paths';
import { FormComponent, PasswordInputGroup, CheckboxInputGroup } from '@/forms';
import Strings from './lang';

class RememberedUserAuthenticationForm extends Component {
  constructor(props) {
    super(props);

    this.passwordRef = React.createRef();

    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      submitted: false,
      password: '',
      passwordValid: false,
      rememberEmail: true
    };
  }

  componentDidMount() {
    if (this.passwordRef.current) {
      this.passwordRef.current.focus();
    }
  }

  render() {
    return (
      <FormComponent className="authentication-form" onSubmit={this.onSubmit}>
        <PasswordInputGroup
          className="form-group"
          labelText={Strings.passwordLabelText}
          inputProps={{
            className: 'form-control',
            placeholder: Strings.passwordInputPlaceholder,
            value: this.state.password,
            ref: this.passwordRef
          }}
          onUpdate={(password, passwordValid) => {
            this.setState({ password, passwordValid });
          }}
          messageClassName="alert alert-danger"
          touched={this.state.submitted}
        />

        <CheckboxInputGroup
          inputValid
          className="form-group remember-email-input-group"
          labelText={Strings.rememberEmailLabelText}
          inputProps={{
            className: 'form-control',
            checked: this.state.rememberEmail,
            onChange: e => {
              this.setState({
                rememberEmail: e.target.checked
              });
            }
          }}
        />

        <div className="form-footer">
          <Link to={PasswordsPaths.reset.index.link}>
            {Strings.resetLinkText}
          </Link>
          <button type="submit" className="btn btn-primary">
            {Strings.submitButtonText}
          </button>
        </div>
        
      </FormComponent>
    );
  }

  isValid() {
    return this.state.passwordValid;
  }

  onSubmit() {
    this.setState({ submitted: true });

    if (!this.isValid()) {
      return;
    }

    const { password, rememberEmail } = this.state;

    this.passwordRef.current.blur();
    
    // Clear the password field and reset validation:
    this.setState({
      password: '',
      passwordValid: false,
      submitted: false
    });

    this.props.onSubmit(password, rememberEmail);
  }
}

RememberedUserAuthenticationForm.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default RememberedUserAuthenticationForm;
