import React, { forwardRef } from 'react';
import { SymptomLevelsCard } from '../baseline-test-cards';

const SymptomScoresStep = forwardRef((
  {
    baseline,
    onSubmit,
    ...rest
  },
  ref
) => {
  return (
    <SymptomLevelsCard
      {...rest}
      ref={ref}
      defaultSymptomsValue={0}
      symptomLevels={{ ...(baseline?.pre_existing_symptoms || {}) }}
      onSubmit={onSubmit}
    />
  );
});

SymptomScoresStep.hasChanged = (initial, current) => {
  const ownSymptomKeys = Object.keys(current);

  return ownSymptomKeys.some(key => {
    const currentSymptom = current[key] || {};
    const initialSymptom = initial[key] || {};
    const initialLevel = !isNaN(initialSymptom.level) ? initialSymptom.level : '';
    return (initialLevel !== currentSymptom.level)
      || (initialSymptom.notes || '') !== currentSymptom.notes;
  });
};

export default SymptomScoresStep;
