import React from 'react';
import PropTypes from 'prop-types';
import { MAX_SYMPTOM_SEVERITY } from '@/utilities/symptom-data';
import Fraction from '../../fraction';
import Strings from './lang';

const SymptomsTotalScore = ({
  totals
}) => (
  <div className="symptom">
    <div className="symptom-meta">
      <h3>{Strings.totalScoreLabelText}</h3>
      <h3>
        <Fraction 
          numerator={totals.score} 
          denominator={totals.symptoms * MAX_SYMPTOM_SEVERITY} 
        />
      </h3>
    </div>
  </div>
);

SymptomsTotalScore.propTypes = {
  totals: PropTypes.shape({
    score: PropTypes.number,
    count: PropTypes.number,
    symptoms: PropTypes.number
  }).isRequired
};

export default SymptomsTotalScore;
