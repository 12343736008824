import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { If, Then, Else } from 'react-if';
import dayjs from 'dayjs';
import { DISPLAY_DATE_TIME_FORMAT, DISPLAY_DATE_FORMAT } from '@/utilities/dates/formats';
import { DEFAULT_USER_LANGUAGE } from '@/utilities/localization';
import { TestHeader } from '../../test-header';
import BaselineMeta from '../user-baseline-detail/baseline-meta';
import UserBaselineTestSelect from '../user-baseline-detail/user-baseline-test-select';
import ComparableUserBaselineView from '../user-baseline-detail/comparable-user-baseline-view';
import Strings from './lang';

const PostInjuryTestTitle = ({
  nestedDisplay,
  children
}) => {
  let HeaderType = 'h1';
  let className = 'display';

  if (nestedDisplay) {
    HeaderType = 'h2';
    className = 'bold';
  }

  return (
    <HeaderType className={className}>
      {children}
    </HeaderType>
  );
};

const getInjuryTestNumber = (testNumber, tests, testId, showTestNumber, showSelector) => {
  let number = '';
  if (showTestNumber) {
    number = showSelector || tests 
      ? (tests.findIndex(test => test.id === testId) + 1) 
      : testNumber;

    number = number <= 0 ? 1 : number;
  }

  return number;
};

const PostInjuryTestDisplay = ({
  user = {}, 
  injury = {}, 
  test = {}, 
  allTests,
  baseline = {}, 
  showHeader = true, 
  showSelector = true, 
  testNumber = 1, 
  nestedDisplay = false,
  onTestSelected = () => {},
  showTestNumber = true
}) => {
  const hasBaseline = Object.keys(baseline || {}).length > 0;
  const person = user.person || {};
  const language = person.language || DEFAULT_USER_LANGUAGE;
  const injuryTestNumber = useMemo(() => (
    getInjuryTestNumber(testNumber, allTests, test.id, showTestNumber, showSelector)
  ), [allTests, showSelector, showTestNumber, test.id, testNumber]);

  return (
    <div className="user-baseline-detail">

      <div className="row">
        <div className="col-md-12">
          <PostInjuryTestTitle nestedDisplay={nestedDisplay}>
            {Strings.title}
          </PostInjuryTestTitle>
        </div>
      </div>

      <If condition={showHeader}>
        <Then>
          <div className="row">
            <div className="col-md-12">
              <TestHeader
                user={user}
                injury={injury}
                className="baseline-test-header"
                testDate={test.created_at || dayjs()}
                tester={test.clinic_user}
                clinic={test.clinic}
              />
            </div>
          </div>
        </Then>
      </If>

      <div className="row">
        <If condition={hasBaseline}>
          <Then>
            <div className="col-md-6">
              <h2>
                {Strings.formatString(
                  Strings.baselineTitle,
                  dayjs(baseline.created_at).format(DISPLAY_DATE_TIME_FORMAT)
                )}
              </h2>
            </div>
          </Then>
        </If>

        <div className="col-md-6">
          <If condition={showSelector}>
            <Then>
              <UserBaselineTestSelect
                tests={allTests || []}
                value={test.id}
                onChange={onTestSelected}
              />
            </Then>
            <Else>
              <h2>
                {Strings.formatString(
                  Strings.postInjuryTestTitle,
                  injuryTestNumber,
                  dayjs(test.created_at).format(DISPLAY_DATE_TIME_FORMAT)
                )}
              </h2>
            </Else>
          </If>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <BaselineMeta
            user={user}
            test={hasBaseline ? baseline : test}
            compareTest={test}
            compare={hasBaseline}
          />
        </div>
      </div>

      {!hasBaseline && (
        <div className="alert alert-info">
          <p>
            {Strings.formatString(
              Strings.noActiveBaseline,
              dayjs(test.created_at).format(DISPLAY_DATE_FORMAT)
            )}
          </p>
        </div>
      )}

      <ComparableUserBaselineView
        compare={hasBaseline}
        compareTest={test}
        test={hasBaseline ? baseline : test}
        language={language}
      />

    </div>
  );
};

PostInjuryTestDisplay.propTypes = {
  test: PropTypes.object,
  baseline: PropTypes.object,
  showHeader: PropTypes.bool,
  showSelector: PropTypes.bool,
  testNumber: PropTypes.number,
  onTestSelected: PropTypes.func,
};

export default PostInjuryTestDisplay;
