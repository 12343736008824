import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { If, Then, Else } from 'react-if';
import { getInjuryReportsAsync } from '@/redux/thunks/injury-reports';
import InjuryReportListTable from './injury-report-list-table';
import Activity from '../activity';
import { ErrorBanner } from '../errors';
import Strings from './lang';

const injuryReportsSelector = (userId) => (state) => {
  return Object.values(state.injuryReports[userId] || {});
};

const InjuryReports = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [activity, setActivity] = useState(false);
  const [error, setError] = useState(null);
  const injuryReports = useSelector(injuryReportsSelector(params.userId));

  const getInjuryReports = useCallback((userId) => {
    setActivity(true);
    setError(null);

    dispatch(getInjuryReportsAsync(userId)).then(() => {
      setActivity(false);
    }).catch(error => {
      setActivity(false);
      setError(error.message);
    });
  }, [dispatch]);

  useEffect(() => {
    if (params.userId) {
      getInjuryReports(params.userId);
    }
  }, [getInjuryReports, injuryReports.length, params.userId]);

  return (
    <div className="user-injury-reports">
      <div className="row">
        <div className="col-md-12">
          <h2>{Strings.title}</h2>
        </div>
      </div>
      <ErrorBanner error={error} />
      <Activity active={activity}>
        <div className="row">
          <div className="col-md-12">
            <If condition={injuryReports.length > 0}>
              <Then>
                <InjuryReportListTable
                  userId={params.userId}
                  clinicId={params.clinicId}
                  injuryReports={injuryReports}
                />
              </Then>
              <Else>
                <p>{Strings.emptyMessageText}</p>
              </Else>
            </If>
          </div>
        </div>
      </Activity>
    </div>
  );
};

export default InjuryReports;
