import React from 'react';
import PropTypes from 'prop-types';
import Fraction from '../../fraction';
import Strings from './lang';

const SymptomsTotalCount = ({
  totals
}) => (
  <div className="symptom">
    <div className="symptom-meta">
      <h3>{Strings.totalSymptomsLabelText}</h3>
      <h3><Fraction numerator={totals.count} denominator={totals.symptoms} /></h3>
    </div>
  </div>
);

SymptomsTotalCount.propTypes = {
  totals: PropTypes.shape({
    score: PropTypes.number,
    count: PropTypes.number,
    symptoms: PropTypes.number
  }).isRequired
};

export default SymptomsTotalCount;
