const FR = {
  dominantFootLabel: 'Pied dominant',
  leftFootOption: 'Gauche',
  rightFootOption: 'Droit',
  defaultOption: 'Choisir un pied',
  dominantFootErrorMsg: 'Veuillez choisir le pied dominant',
  errorsInputLabel: 'Erreurs',
  feetTogetherTestLabel: 'Pieds ensemble',
  singleLegTestLabel: 'Posture sur un pied (pied non dominant)',
  tandemTestLabel: 'Posture sur deux pieds (pied dominant à l\'avant)',
  foamTextLabel: 'Coussinet (pied non dominant) (facultatif; ne sera pas ajouté aux totaux)',
  bessTotalScoreLabel: 'Totaux de BESS:',
  defaultSelect: 'Choisir une option',
  notDoneSelect: 'Incomplet',
  wessTotalScoreLabel: 'Totaux WESS:',
  wessSeatedLabel: 'Assise',
  wessBalanceDiskLabel: 'Disque d\'équilibre',
  wessWheelieLabel: 'Wheelie',
  eyesOpenErrorsLabel: 'Erreurs yeux ouverts',
  eyesClosedErrorsLabel: 'Erreurs les yeux fermés'
};
 
export default FR;
