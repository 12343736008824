const FR = {
  coursesTitle: 'Vidéos de cours',
  resourcesLabel: 'Ressources',
  coursesLabel: 'Cours',
  videosLabel: 'Vidéos',
  videoCount: '{0} Vidéos',
  moduleLabel: 'Module'
};
 
export default FR;
