import { useCallback, useState } from 'react';

const getModule = (module = {}) => (
  module.default ? module.default : module
);

const makeFileName = (name = '') => {
  return name.endsWith('.pdf') ? name : `${name}.pdf`;
};

const pdfCache = new Map();

const downloadBlob = (filename, blob) => {
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  link.href = url;
  link.download = makeFileName(filename);
  link.onclick = () => {
    if (window.navigator.msSaveBlob) {
      window.navigator.msSaveBlob(blob, filename);
    }
  };
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};

export const usePdfDownload = (filename, loader) => {
  const [loading, setLoading] = useState(false);

  const download = useCallback((props = {}) => {
    setLoading(true);

    return new Promise(resolve => {
      const cache = pdfCache.get(filename);

      if (cache) {
        resolve(cache);
        return;
      }

      loader()
        .then(module => getModule(module).renderToBlob(props))
        .then(blob => resolve(blob));
    }).then(blob => {
      pdfCache.set(filename, blob);
      downloadBlob(filename, blob);
      setLoading(false);
      return Promise.resolve();
    }).catch(error => {
      setLoading(false);
      return Promise.reject(error);
    });
  }, [filename, loader]);

  return {
    download,
    loading
  };
};
