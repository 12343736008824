const EN = {
  athleteInformationHeader: 'Athlete Information',
  confirmAthleteHeading: 'Personal Information',
  medicalHistoryHeading: 'Medical History',
  clinicianAssessmentHeader: 'Clinician Assessment',
  symptomLevelsHeader: 'Symptom Score',
  orientationHeader: 'Orientation',
  immediateMemoryHeader: 'Immediate Memory',
  concentrationHeader: 'Concentration',
  kingDevickHeader: 'King-Devick',
  balanceHeader: 'Balance',
  balanceTestingHeader: 'Balance Testing',
  tandemGaitHeader: 'Timed Tandem Gait',
  dualTaskHeader: 'Dual Task Tandem Gait',
  forcePlateHeader: 'Force Plate',
  reactionAndStrengthHeader: 'Reaction Time & Strength',
  reactionTimeHeader: 'Reaction Time',
  gripStrengthHeader: 'Grip Strength',
  delayedMemoryHeader: 'Delayed Memory Recall'
};

export default EN;
