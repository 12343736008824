import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import classnames from 'classnames';
import { If, Then, Else } from 'react-if';
import { ErrorBanner } from '../errors';
import { TestHeader } from '../test-header';
import BlackhawksTestContent from './blackhawks-test-content';
import { MedicalTestEditButton } from '../medical-test-edit';
import LastEditedView from '../last-edited-view';
import Strings from './lang';

const BlackhawksTestHeaderDetails = ({
  test = {},
  maxTensionLevels
}) => (
  <div>
    <div className="row">
      <div className="col-md-6">
        <div className="test-header-row">
          <label>{Strings.testTypeLabelText}:</label>
          <span>
            {(test.full_test) ? Strings.fullTestLabelText : Strings.modifiedTestLabelText}
          </span>
        </div>
      </div>
      <div className="col-md-6">
        <div className="test-header-row">
          <label>{Strings.bikeTypeLabelText}:</label>
          <span>{Strings[`${test.bike_type}BikeLabelText`] || Strings.formatString(Strings.customBikeHeaderLabel, maxTensionLevels || '')}</span>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-md-6">
        <div className="test-header-row">
          <label>{Strings.testDurationLabelText}:</label>
          <span>{dayjs.duration(test.duration).format('H:mm:ss')} (h:m:s)</span>
        </div>
      </div>
      <div className="col-md-6">
        <div className="test-header-row">
          <label>{Strings.restingHeartRateLabelText}:</label>
          <span>{test.resting_heart_rate}</span>
        </div>
      </div>
    </div>
  </div>
);

const BlackhawksTestView = ({
  user = {},
  injury = {},
  test = {},
  showTestHeader = true,
  error,
  className,
  onEditClick
}) => {
  const { test_data = {} } = test || {};
  const headerClassName = showTestHeader ? 'display flex-row justify_space-between align_center' : 'bold';
  const HeaderElement = showTestHeader ? 'h1' : 'h2';

  return (
    <div className={classnames('blackhawks-test-view', className)}>
      <div className="row">
        <div className="col-md-12">
          <HeaderElement className={headerClassName}>
            {Strings.testTitle}
            {onEditClick && (
              <MedicalTestEditButton 
                className="btn btn-danger btn-sm no-print"
                onClick={onEditClick}
              />
            )}
          </HeaderElement>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <ErrorBanner error={error} />
          <div className="blackhawks-test-content">
            <If condition={showTestHeader}>
              <Then>
                <div className="row">
                  <div className="col-md-12">
                    <TestHeader
                      user={user}
                      injury={injury}
                      className="blackhawks-test-header"
                      testDate={test.created_at || dayjs()}
                      tester={test.clinic_user}
                      clinic={test.clinic}
                      additionalNode={(
                        <BlackhawksTestHeaderDetails 
                          test={test}
                          maxTensionLevels={test_data.max_tension_levels}
                        />
                      )}
                    />
                  </div>
                </div>
              </Then>
              <Else>
                <div className="test-header blackhawks-test-header">
                  <BlackhawksTestHeaderDetails 
                    test={test}
                    maxTensionLevels={test_data.max_tension_levels}
                  />
                </div>
              </Else>
            </If>

            <LastEditedView item={test} />

            <div className="row">
              <div className="col-md-12">
                <BlackhawksTestContent blackhawks={test} />
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

BlackhawksTestView.propTypes = {
  user: PropTypes.object,
  injury: PropTypes.object,
  test: PropTypes.object,
  error: PropTypes.any
};

export default BlackhawksTestView;
