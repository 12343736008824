import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import Panel from '../panel';
import SoapNoteView from './soap-note-view';
import SoapPanelHeader from './soap-panel-header';
import { UserSelector } from '@/redux/selectors';

const SoapPanels = React.forwardRef(({
  soapNotes = [], 
  actionable = true,
  sticky,
  user = {}, 
  injury = {}, 
  clinicId = 0, 
  disablePanels = false,
  onArchive,
  onUnarchive
}, ref) => {
  const currentUser = useSelector(UserSelector.getCurrentUser);

  return (
    <div className="soap-panels">
      <Panel.Group collapsible ref={ref} disabled={disablePanels}>
        {soapNotes.map((soap, index) => (
          <Panel 
            key={index} 
            collapsedClassName={classnames('no-print', { disabled: disablePanels })}
          >
            <Panel.Heading sticky={sticky}>
              <SoapPanelHeader 
                soap={soap} 
                actionable={actionable}
                currentUser={currentUser}
                injuryId={injury.id} 
                userId={user.id} 
                itemNumber={index + 1} 
                onArchive={onArchive}
                onUnarchive={onUnarchive}
              />
            </Panel.Heading>
            <Panel.Content>
              <SoapNoteView
                user={user}
                injury={injury}
                soap={soap}
                clinicId={clinicId}
              />
            </Panel.Content>
          </Panel>
        ))}
      </Panel.Group>
    </div>
  );
});

SoapPanels.propTypes = {
  user: PropTypes.object,
  soapNotes: PropTypes.array,
  injury: PropTypes.object
};

export default SoapPanels;
