import Strings from '../lang';

const BaselineTestLabels = {
  personal: Strings.confirmAthleteHeading,
  medical: Strings.medicalHistoryHeading,
  pre_existing_symptoms: Strings.symptomLevelsHeader,
  cognitive_assessment_test: Strings.orientationHeader,
  immediate_memory_test: Strings.immediateMemoryHeader,
  concentration_test: Strings.concentrationHeader,
  king_devick_test: Strings.kingDevickHeader,
  balance_test: Strings.balanceTestingHeader,
  force_plate_test: Strings.forcePlateHeader,
  tandem_gait_test: Strings.tandemGaitHeader,
  dual_task_test: Strings.dualTaskHeader,
  reaction_time_test: Strings.reactionTimeHeader,
  grip_strength_test: Strings.gripStrengthHeader,
  delayed_memory_recall_test: Strings.delayedMemoryHeader
};

export default BaselineTestLabels;
