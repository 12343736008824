import Strings from '../lang';

export * from './invoice-status';
export * from './permissions';
export * from './line-items';
export { default as getPaidAmount } from './get-paid-amount';

export const TaxRegistration = {
  CA: {
    description: 'GST/HST',
    number: '816003249'
  }
};

export const getTaxRegistrationText = (clinic = {}) => {
  const { address = {} } = clinic || {};
  const { country } = address;
  const taxRegistration = TaxRegistration[country];

  return taxRegistration ? Strings.formatString(
    Strings.taxRegistrationLabel,
    taxRegistration.description,
    taxRegistration.number
  ) : null;
};
