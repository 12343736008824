import React, { Component } from 'react';
import { FormComponent, FormInputGroup } from '@/forms';
import Strings from './lang';

class TaxItemForm extends Component {
  constructor(props) {
    super(props);

    const { taxRate = {} } = props;
    const { 
      country = '', 
      state = '', 
      rate = 0, 
      code = '' 
    } = taxRate || {};

    this.onSubmit = this.onSubmit.bind(this);
    this.onUpdateCountry = this.onUpdateValue.bind(this, 'country');
    this.onUpdateState = this.onUpdateValue.bind(this, 'state');
    this.onUpdateCode = this.onUpdateField.bind(this, 'code');
    this.onUpdateRate = this.onUpdateField.bind(this, 'rate');

    this.state = {
      country,
      state,
      rate,
      code,
      submitted: false
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.country !== prevState.country) {
      this.onUpdateState('');
    }
  } 

  render() {
    return (
      <FormComponent onSubmit={this.onSubmit}>
        <FormInputGroup
          required
          className="form-group country-input-group"
          labelText={Strings.countryLabel}
          inputType="country"
          inputProps={{
            className: 'form-control',
            value: this.state.country,
            onChange: this.onUpdateCountry,
          }}
          messageText={Strings.countryErrorMessage}
          messageClassName="alert alert-danger"
          inputValid={this.state.country.length > 0}
          touched={this.state.submitted}
        />

        <FormInputGroup
          required
          touched={this.state.submitted}
          className="form-group state-input-group"
          labelText={Strings.regionLabel}
          inputType="region"
          inputProps={{
            className: 'form-control',
            country: this.state.country,
            value: this.state.state,
            onChange: this.onUpdateState
          }}
          messageText={Strings.regionErrorMessage}
          messageClassName="alert alert-danger"
          inputValid={this.state.state.length > 0}
        />

        <FormInputGroup
          required
          className="form-group"
          labelText={Strings.taxCadeLabel}
          inputType="text"
          inputProps={{
            className: 'form-control',
            value: this.state.code,
            onChange: this.onUpdateCode
          }}
          messageText={Strings.taxCodeErrorMessage}
          messageClassName="alert alert-danger"
          inputValid={this.state.code.length > 0}
          touched={this.state.submitted}
        />

        <FormInputGroup
          required
          className="form-group"
          labelText={Strings.taxRateLabel}
          inputType="text"
          inputProps={{
            className: 'form-control',
            value: this.state.rate,
            onChange: this.onUpdateRate
          }}
          messageText={Strings.taxRateErrorMessage}
          messageClassName="alert alert-danger"
          inputValid={parseFloat(this.state.rate) > 0}
          touched={this.state.submitted}
        />

        <button 
          type="submit" 
          disabled={!this.isValid}
          className="btn btn-primary"
        >
          {Strings.submitButtonText}
        </button> 
      </FormComponent>
    );
  }

  get isValid() {
    return this.state.country.length > 0
      && this.state.state.length > 0
      && this.state.code.length > 0
      && parseFloat(this.state.rate) > 0;
  }

  onUpdateValue(field, value) {
    this.setState({
      [field]: value
    });
  }

  onUpdateField(field, e) {
    this.setState({
      [field]: e.target.value
    });
  }

  onSubmit() {
    const { onSubmit = () => {} } = this.props;
    const { 
      state, 
      country, 
      rate, 
      code 
    } = this.state;

    this.setState({ submitted: true });

    if (!this.isValid) {
      return;
    }

    onSubmit({
      country,
      state,
      rate: parseFloat(rate),
      code
    });
  }
}

export default TaxItemForm;
