import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import FormListInput from './form-list-input';
import FormInputMessage from './form-input-message';

class FormListInputGroup extends Component {
  constructor(props) {
    super(props);

    this.onBlur = this.onBlur.bind(this);
    this.onChange = this.onChange.bind(this);

    this.state = {
      touched: false
    };
  }

  render() {
    return (
      <div className={classNames('form-list-input-group', this.props.className)}>
        <label {...this.props.labelProps}>
          <span>{this.props.labelText}</span>
        </label>
        <FormListInput
          className={this.props.inputClassName}
          {...this.mergeInputProps()}
        />
        <FormInputMessage
          className={this.props.messageClassName}
          visible={this.state.touched && !this.props.inputValid}
          text={this.props.messageText}
        />
      </div>
    );
  }

  mergeInputProps() {
    return {
      ...(this.props.inputProps || {}),
      onBlur: this.onBlur,
      onChange: this.onChange
    };
  }

  onBlur(event) {
    this.setState({ touched: true });

    const { inputProps = {} } = this.props;
    if (typeof inputProps.onBlur === 'function') {
      inputProps.onBlur(event);
    }
  }

  onChange(event) {
    this.setState({ touched: false });

    const { inputProps = {} } = this.props;
    if (typeof inputProps.onChange === 'function') {
      inputProps.onChange(event);
    }
  }
}

FormListInputGroup.propTypes = {
  className: PropTypes.string,
  labelText: PropTypes.string,
  labelProps: PropTypes.object,
  inputClassName: PropTypes.string,
  inputProps: PropTypes.object,
  messageClassName: PropTypes.string,
  messageText: PropTypes.string,
  inputValid: PropTypes.bool
};

export default FormListInputGroup;
