const FR = {
  neuroExamsHeader: 'Examen physique',
  unremarkableLabel: 'Sans particularité',
  remarkableLabel: 'Particularité',
  notDoneLabel: 'Incomplet',
  notesLabel: 'Remarques',
  visualTrackingTitle: 'Suivi visuel',
  vomsButtonText: 'Effectuer le test vestibulo-oculaire moteur (VOMS)',
  accomadationLabel: 'Distance de l’accommodation',
  accomadationNormal: 'Normale = moins de 10 cm',
  distanceErrorMessage: 'Veuillez écrire un nombre valide',
  convergenceLabel: 'Distance de convergence',
  converganceNormal: 'Normale = moins de 5 cm',
  cranialNerveScreenTitle: 'Examen du nerf crânien',
  pronatorDriftTitle: 'Examen de la dérive du pronateur',
  cerebellarTitle: 'Examen cérébelleux',
  upperLimbNeuroTitle: 'Test neurologique des membres supérieurs',
  lowerLimbNeuroTitle: 'Test neurologique des membres inférieurs',
  cervicalSpineTitle: 'Examen de la colonne cervicale',
  saveButtonText: 'Sauvegarder'
};
 
export default FR;
