import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { ErrorBanner } from '../errors';
import { Page } from '../page';
import Activity from '../activity';
import PlayerBadge from '../player-badge';
import CreateInjuryForm from './create-injury-form';
import { RecoveryProtocols } from '../recovery-stages';
import { createInjuryAssessmentAsync } from '@/redux/thunks/injury-assessments';
import { getUserAsync } from '@/redux/thunks/users';
import { UserSelector } from '@/redux/selectors';
import { getCurrentClinicId } from '@/tokens';
import { UserPaths } from '@/paths';
import replaceLinkParams from '@/utilities/replace-link-params';
import { useMount } from '@/hooks';
import Strings from './lang';

const CreateUserInjury = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const clinicId = useMemo(() => getCurrentClinicId(), []);
  const user = useSelector(state => UserSelector.getUser(state, params));
  const [activity, setActivity] = useState(false);
  const [error, setError] = useState(null);

  const getUser = useCallback((userId) => {
    setActivity(true);
    setError(null);

    dispatch(getUserAsync(userId)).then(() => {
      setActivity(false);
    }).catch(error => {
      setActivity(false);
      setError(error.message);
    });
  }, [dispatch]);

  const createAssessment = useCallback((attributes) => {
    setActivity(true);
    setError(null);

    dispatch(createInjuryAssessmentAsync(params.userId, attributes)).then((assessment) => {
      setActivity(false);

      navigate(
        replaceLinkParams(UserPaths.injuries.fullAssessment.link, {
          userId: params.userId,
          injuryId: assessment.injury_id,
          assessmentId: assessment.id
        })
      );
    }).catch(error => {
      setActivity(false);
      setError(error.message);
    });
  }, [dispatch, navigate, params.userId]);

  const onSubmit = useCallback(date => {
    const attributes = {
      user_id: user.id,
      clinic_id: clinicId,
      injured_at: dayjs(date).toISOString(),
      recovery_protocol_stage_attributes: {
        rts_stage: RecoveryProtocols.rts.stages[0].value,
        rtl_stage: RecoveryProtocols.rtl.stages[0].value,
        rtw_stage: RecoveryProtocols.rtw.stages[0].value
      }
    };

    createAssessment(attributes);
  }, [clinicId, createAssessment, user?.id]);

  useMount(() => {
    if (!user?.id && params.userId) {
      getUser(params.userId);
    }
  });

  return (
    <Page>
      <h1 className="display">{Strings.postInjuryAssessmentTitle}</h1>
      <Activity active={activity}>
        {!!user?.id && (
          <PlayerBadge
            user={user}
            clinicId={clinicId}
          />
        )}
        <ErrorBanner error={error} />
        <CreateInjuryForm onSubmit={onSubmit} />
      </Activity>
    </Page>
  );
};

export default CreateUserInjury;
