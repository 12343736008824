import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { If, Then, Else } from 'react-if';
import { ActiveClaimsTable, NewClaimsTable } from './claims-tables';
import IdtClaimsLayout from './idt-claims-layout';
import { ErrorBanner } from '../errors';
import Activity from '../activity';
import { getCurrentClinicId } from '@/tokens';
import { useMount } from '@/hooks';
import { ClinicIDTClaimsSelector } from '@/redux/selectors';
import { getClinicIdtClaimsAsync } from '@/redux/thunks/clinic-idt-claims';
import Strings from './lang';

const IdtClaims = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const clinicId = useMemo(() => getCurrentClinicId(), []);
  const newClaims = useSelector((state) => (
    ClinicIDTClaimsSelector.getClaimsByStatus(state, { clinicId, status: 'not_started' })
  )) ?? [];
  const activeClaims = useSelector((state) => (
    ClinicIDTClaimsSelector.getClaimsByStatus(state, { clinicId, status: 'active' })
  )) ?? [];

  const getClaims = useCallback((clinicId) => {
    setLoading(true);
    setError(null);

    Promise.all([
      dispatch(getClinicIdtClaimsAsync(clinicId, 'active')),
      dispatch(getClinicIdtClaimsAsync(clinicId, 'not_started'))
    ]).then(() => {
      setLoading(false);
    }).catch((error) => {
      setLoading(false);
      setError(error.message);
    });
  }, [dispatch]);

  useMount(() => {
    getClaims(clinicId);
  });

  return (
    <IdtClaimsLayout showDischargedLink>
      <ErrorBanner error={error} />
      <Activity active={loading} static={!(activeClaims.length || newClaims.length)}>
        <If condition={!!activeClaims.length || !!newClaims.length}>
          <Then>
            <div>
              {newClaims.length > 0 && (
                <div className="new-claims-container">
                  <h2>{Strings.newClaims}</h2>
                  <NewClaimsTable claims={newClaims} />
                </div>
              )}

              <div>
                <h2>{Strings.activeClaims}</h2>
                <ActiveClaimsTable claims={activeClaims} />
              </div>
            </div>
          </Then>
          <Else>
            <div>
              {Strings.noClaimsFoundMessage}
            </div>
          </Else>
        </If>
      </Activity>
    </IdtClaimsLayout>
  );
};

export default IdtClaims;
