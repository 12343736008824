import { jsonApiRead } from '@/lib/requests/jsonapi';
import { updateTeamLeaders } from '../actions/team-leaders';

const getTeamLeadersAsync = () => {
  return (dispatch) => {
    return jsonApiRead({ type: 'users' }, { path: 'users/leaders' }).then(teamLeaders => {
      dispatch(updateTeamLeaders(teamLeaders));
      return Promise.resolve(teamLeaders);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

export {
  getTeamLeadersAsync
};
