import React from 'react';
import { ProductCategories } from '../utils';
import ProductFiltersModalButton from './product-filters-modal';
import Strings from '../lang';

const ProductsListHeader = ({
  category,
  brands,
  filters,
  productCount = 0,
  onFilterChange
}) => {
  return (
    <div className="products-list-header">
      <h2>
        {category === null 
          ? Strings.allProductsTitle 
          : ProductCategories[category]} ({productCount || 0})
      </h2>
      <ProductFiltersModalButton
        category={category}
        brands={brands}
        filters={filters}
        productCount={productCount}
        onFilterChange={onFilterChange}
      />
    </div>
  );
};

export default ProductsListHeader;
