const EN = {
  saveAndContinueButtonText: 'Save & Continue',
  diagnosisAndPlanTitle: 'Diagnosis & Plan of Management',
  diagnosisHeader: 'Diagnosis',
  followUpActionHeader: 'Follow-up Action Plan',
  initialManagementHeader: 'Initial Management',
  recoveryStagesHeader: 'Recovery Stages',
  communicationActionHeader: 'Communication of Diagnosis, Report of Findings, Plan of Management & Patient Education'
};

export default EN;
