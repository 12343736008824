const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';
const UPDATE_NOTIFICATIONS = 'UPDATE_NOTIFICATIONS';

const updateNotification = (userId, notification) => ({
  type: UPDATE_NOTIFICATION,
  userId,
  notification
});

const updateNotifications = (userId, notifications) => ({
  type: UPDATE_NOTIFICATIONS,
  userId,
  notifications
});

export {
  UPDATE_NOTIFICATION,
  updateNotification,
  UPDATE_NOTIFICATIONS,
  updateNotifications
};
