const EN = {
  title: 'Manage Patients',
  dashboardLabel: 'Dashboard',
  patientsSectionTitle: 'Patients',
  ongoingInjuriesSectionTitle: 'Ongoing Injuries',
  searchButtonText: 'Search',
  clearButtonText: 'Clear',
  accountRetrievalInputPlaceholder: 'Enter Email',
  accountRetrievalLabel: 'Account Retrieval',
  accountRetrievalSuccessText: 'An email has been sent to this patient\'s email for confirmation.',
  accountRetrievalSuccessTextWithEmail: 'An email has been sent to {0} for confirmation.',
  adminStatsLinkText: 'View stats for all clinics',
  accountRecoveryHelpText: 'If the patient has lost their ID card or account number please enter the email associated with their account for authentication.',
  invalidEmailMessage: 'Please enter a valid email',
  cancelButtonText: 'Cancel',
  recoveryButtonText: 'Email Account Recovery',
  myPatientsTabDescription: 'This section displays all the patients that you were the most recent clinician to engage with.',
  noMyPatientsText: 'Currently no patients meet this criteria',
  noPatientsWithInjuriesInfoText: 'There are currently no patients at this clinic with ongoing injuries',
  noPatientsUninjuredText: 'There are currently no uninjured patients at this clinic',
  noPatientsText: 'There are currently no patients at this clinic',
  noPatientsSearchText: 'No Results for your search',
  exportPatientText: 'Export Patients',
  ongoingTabLabel: 'All Ongoing ({0})',
  clinicianOngoingTabLabel: 'My Patients ({0})',
  uninjuredTabLabel: 'Uninjured ({0})',
  allPatientsTabLabel: 'All Patients ({0})',
  statisticsTabLabel: 'Clinic Stats',
  referralsTabLabel: 'Referrals'
};

export default EN;
