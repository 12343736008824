import { UPDATE_REFERRAL, UPDATE_REFERRALS } from '../actions/referrals';

const updateReferralReducer = (state = {}, action = {}) => {
  const { referral } = action;
  const { [referral.id]: stateReferral = {} } = state;

  return {
    ...state,
    [referral.id]: {
      ...stateReferral,
      ...referral
    }
  };
};

const updateReferralsReducer = (state = {}, action = {}) => {
  const { referrals = [] } = action;

  return referrals.reduce((acc, referral) => (
    updateReferralReducer(acc, { referral })
  ), state);
};

const referrals = (state = {}, action = {}) => {
  switch (action.type) {
    case UPDATE_REFERRAL:
      return updateReferralReducer(state, action);
    case UPDATE_REFERRALS:
      return updateReferralsReducer(state, action);
    default:
      return state;
  }
};

export default referrals;
