import React, { useCallback, useMemo, useState } from 'react';
import { FormComponent, FormInput } from '@/forms';
import Icon from '../icon';
import Strings from './lang';

const defaultFilter = () => true;

const Filterable = ({
  items = [],
  filter = defaultFilter,
  children
}) => {
  const [text, setText] = useState('');
  const filteredItems = useMemo(() => (
    text 
      ? items.filter((item) => filter(text, item))
      : items
  ), [items, text, filter]);

  const onFilterChange = useCallback((e) => {
    const { value } = e.target;
    setText(value);
  }, []);

  const onResetFilter = useCallback(() => {
    setText('');
  }, []);

  return (
    <div className="row">
      <div className="col-md-12">
        <FormComponent className="filter-form">
          <label>
            <span>{Strings.filterLabelText}</span>
            <FormInput
              className="form-control"
              type="text"
              inputProps={{
                value: text,
                onChange: onFilterChange
              }}
            />
            <button
              type="button"
              onClick={onResetFilter}
            >
              <Icon name="xmark" />
            </button>
          </label>
        </FormComponent>
      </div>
      <div className="col-md-12">
        {children(filteredItems)}
      </div>
    </div>
  );
};

export default Filterable;
