import dayjs from 'dayjs';
import UserActivityDefaults from './user-activity-defaults';

class UserActivity {
  constructor(options = {}) {
    this._options = options;
    this._listeners = [];
    this._lastActivityTime = dayjs().valueOf();
    this._lastActivityTimerId = 0;
    this._updateLastActivityTime = this._updateLastActivityTime.bind(this);
  }

  addListener(listener) {
    const index = this._listeners.indexOf(listener);
    if (index === -1) {
      this._listeners.push(listener);
    }
  }

  removeListener(listener) {
    const index = this._listeners.indexOf(listener);
    if (index >= 0) {
      const listeners = [...this._listeners];
      listeners.splice(index, 1);
      this._listeners = listeners;
    }
  }

  startListening() {
    this._updateLastActivityTime();
    this._startTimer();

    const { events = UserActivityDefaults.events } = this._options;
    events.forEach(event => {
      document.addEventListener(event, this._updateLastActivityTime);
    });
  }

  stopListening() {
    this._stopTimer();

    const { events = UserActivityDefaults.events } = this._options;
    events.forEach(event => {
      document.removeEventListener(event, this._updateLastActivityTime);
    });
  }

  _startTimer() {
    const { interval = UserActivityDefaults.interval } = this._options;
    this._lastActivityTimerId = setInterval(this._sendLastActivityTime.bind(this), interval);
  }

  _stopTimer() {
    clearInterval(this._lastActivityTimerId);
  }

  _updateLastActivityTime() {
    this._lastActivityTime = dayjs().valueOf();
  }

  _sendLastActivityTime() {
    const lastActivity = this._lastActivityTime;
    this._lastActivityTime = null;
    this._callListeners(lastActivity);
  }

  _callListeners(lastActivity) {
    this._listeners.forEach(listener => {
      if (typeof listener === 'function') {
        listener(lastActivity);
      }
    });
  }
}

export default UserActivity;
