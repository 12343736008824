const FR = {
  clinicReferralsTitle: 'Références',
  referralTitle: 'Référence',
  injuryReferralsTitle: 'Références pour blessure en date du {0}',
  createReferralError: 'Erreur lors de la création de la référence: {0}',
  pendingTabLabel: 'En attente',
  acceptedTabLabel: 'Accepté',
  resolvedTabLabel: 'Résolu',
  rejectedTabLabel: 'Rejeté',
  cancelledTabLabel: 'Annulé',
  emptyTabContent: 'Aucune référence {0}',
  referralAcceptedAlert: 'Référence Accepté',
  referralRejectedAlert: 'Référence Rejeté',
  referralCancelledAlert: 'Référence Annulé',
  acceptReferralButton: 'Accepter',
  rejectReferralButton: 'Rejeter',
  rejectModalTitle: 'Rejeter Référence',
  rejectModalMessage: 'Veuillez fournir une raison pour rejeter cette référence. Ce message ne sera vu que par le clinicien/médecin référent.',
  acceptModalTitle: 'Accepter Référence',
  acceptModalMessage: 'Veuillez fournir tous les détails ou instructions pour le patient, tels que la date et l\'heure du rendez- vous, les articles à apporter, etc.',
  cancelButtonText: 'Annuler',
  professionRequestText: 'La recommandation est pour un {0}, veuillez réserver le cas échéant.',
  orSeparatorText: 'ou',
  referredByText: 'Référencé par',
  fromSeparatorText: 'à',
  viewProfileLinkText: 'Voir le profil',
  referrerNoteLabel: 'Remarque du référent',
  hideText: 'Cacher',
  showText: 'Spectacle',
  cancelReferralButtonText: 'Annuler Référence',
  cancelModalTitle: 'Annuler Référence',
  cancelModalMessage: 'Voulez-vous vraiment annuler ce référence?',
  pendingStatusLabel: 'En attendant l\'examen',
  acceptedStatusLabel: 'Accepté',
  rejectedStatusLabel: 'Rejeté',
  cancelledStatusLabel: 'Annulé',
  resolvedStatusLabel: 'Résolu',
  switchClinicsMessage: 'Vous devez être connecté sous {0} pour accepter/refuser la recommandation.',
  switchClinicsButton: 'Changer de clinique',
  patientLabel: 'Patient:'
};

export default FR;
