import React from 'react';
import Portal from '../portal';

const Container = ({
  portal = false,
  isOpen = false,
  className,
  children
}) => {
  if (portal) {
    return (
      <Portal visible={isOpen} className={className}>
        {children}
      </Portal>
    );
  }

  return isOpen ? (<>{children}</>) : null;
};

export default Container;
