import { useMemo } from 'react';
import { getCurrentClinicId } from '@/tokens';
import {
  AdminRoleDescriptions,
  AdminAndClinicRoleDescriptions,
  makeAllClinicRoleDescriptions,
  makeRoleDescription,
  RoleNames,
  RoleResourceTypes,
  RoleDescriptions
} from '@/utilities/user-roles';

const useDashboardPermissions = () => {
  const clinicId = useMemo(() => getCurrentClinicId(), []);

  return useMemo(() => {
    const allClinicRoles = makeAllClinicRoleDescriptions(clinicId);
    
    return {
      baselineTest: [
        makeRoleDescription(RoleNames.Clinician, RoleResourceTypes.Clinic, clinicId),
        makeRoleDescription(RoleNames.BaselineTester, RoleResourceTypes.Clinic, clinicId),
        makeRoleDescription(RoleNames.ClinicFrontDesk, RoleResourceTypes.Clinic, clinicId),
      ],
      startBaseline: [
        makeRoleDescription(RoleNames.Clinician, RoleResourceTypes.Clinic, clinicId),
        makeRoleDescription(RoleNames.BaselineTester, RoleResourceTypes.Clinic, clinicId)
      ],
      continueBaselineCard: [
        makeRoleDescription(RoleNames.ClinicStaff, RoleResourceTypes.Clinic, clinicId)
      ],
      resumeBaselineBadge: [
        makeRoleDescription(RoleNames.Clinician, RoleResourceTypes.Clinic, clinicId),
        makeRoleDescription(RoleNames.BaselineTester, RoleResourceTypes.Clinic, clinicId)
      ],
      postInjury: [
        makeRoleDescription(RoleNames.Clinician, RoleResourceTypes.Clinic, clinicId),
        makeRoleDescription(RoleNames.ClinicFrontDesk, RoleResourceTypes.Clinic, clinicId),
      ],
      searchPatients: AdminAndClinicRoleDescriptions,
      manangePatients: allClinicRoles,
      clinicsIndex: AdminRoleDescriptions,
      teamLeaders: AdminRoleDescriptions,
      bagTags: AdminRoleDescriptions,
      clinicStats: AdminRoleDescriptions,
      taxRates: AdminRoleDescriptions,
      resources: [
        ...AdminRoleDescriptions,
        RoleDescriptions.ClinicFrontDesk,
        RoleDescriptions.ClinicStaff,
        RoleDescriptions.Clinician,
        RoleDescriptions.Specialist,
        RoleDescriptions.ClinicOwner
      ],
      axia: [
        makeRoleDescription(RoleNames.Clinician, RoleResourceTypes.Clinic, clinicId),
        makeRoleDescription(RoleNames.Specialist, RoleResourceTypes.Clinic, clinicId),
        makeRoleDescription(RoleNames.ClinicFrontDesk, RoleResourceTypes.Clinic, clinicId),
        makeRoleDescription(RoleNames.IDTClinician, RoleResourceTypes.Clinic, clinicId),
        makeRoleDescription(RoleNames.IDTFrontDesk, RoleResourceTypes.Clinic, clinicId)
      ],
      findClinic: allClinicRoles,
      accountBalance: [
        makeRoleDescription(RoleNames.ClinicOwner, RoleResourceTypes.Clinic, clinicId),
        makeRoleDescription(RoleNames.Clinician, RoleResourceTypes.Clinic, clinicId),
        makeRoleDescription(RoleNames.Specialist, RoleResourceTypes.Clinic, clinicId),
        makeRoleDescription(RoleNames.ClinicFrontDesk, RoleResourceTypes.Clinic, clinicId),
        makeRoleDescription(RoleNames.BaselineTester, RoleResourceTypes.Clinic, clinicId)
      ]
    };
  }, [clinicId]);
};

export default useDashboardPermissions;
