import React from 'react';
import classnames from 'classnames';
import Overlay from '../overlay';
import Dialog from './dialog';
import Icon from '../icon';

const Header = ({ children, onClose }) => {
  return (
    <div className="dialog-header">
      {onClose && (
        <button type="button" className="close" onClick={onClose}>
          <Icon name="xmark" />
        </button>
      )}
      {children}
    </div>
  );
};

const Body = ({ className, children }) => {
  return (
    <div className={classnames('dialog-body', className)}>
      {children}
    </div>
  );
};

const Footer = ({ children }) => {
  return (
    <div className="dialog-footer">
      {children}
    </div>
  );
};

const Modal = ({
  isOpen = false,
  blur = false,
  blurNode,
  className,
  animation = 'slide-bottom',
  scrollLock = true,
  appearance = 'dark',
  fullScreen = false,
  static: overlayDisabled = false,
  onClose = () => {},
  children
}) => {
  return (
    <Overlay
      isOpen={isOpen}
      blur={blur}
      static={overlayDisabled}
      scrollLock={scrollLock}
      appearance={appearance}
      blurNode={blurNode}
      className={classnames('modal', className)}
      onClose={onClose}
    >
      <Dialog full={fullScreen} animation={animation}>
        {children}
      </Dialog>
    </Overlay>
  );
};

Modal.Header = Header;
Modal.Body = Body;
Modal.Footer = Footer;

export default Modal;
