const EN = {
  last7DayRangeOptionLabel: 'Last 7 Days',
  last30DayRangeOptionLabel: 'Last 30 Days',
  last3MonthsRangeOptionLabel: 'Last 3 Months',
  last6MonthsRangeOptionLabel: 'Last 6 Months',
  last1YearRangeOptionLabel: 'Last 1 Year',
  last2YearsRangeOptionLabel: 'Last 2 Years',
  customRangeOptionLabel: 'Custom Range'
};

export default EN;
