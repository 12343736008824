import React, { Component } from 'react';
import PropTypes from 'prop-types';
import GripStrengthTrial from './grip-strength-trial';
import ScoreView from '../../../score-view';
import Strings from './lang';

const NUMBER_OF_TRIALS = 3;
const GRIP_STRENGTH_DECIMAL_PLACES = 3;

const createDefaultTrials = () => {
  return [...new Array(NUMBER_OF_TRIALS)].fill('');
};

const calculateAverageGrip = (arr) => {
  const avg = arr.reduce((acc, cur) => {
    return acc + (cur !== '' ? parseInt(cur, 10) : 0);
  }, 0);

  return avg / NUMBER_OF_TRIALS;
};

class GripStrength extends Component {
  constructor(props) {
    super(props);

    const { initialState = {} } = props;

    const { left_hand = createDefaultTrials(), right_hand = createDefaultTrials() } = initialState;

    const left = [...(left_hand.length > 0 ? left_hand : createDefaultTrials())];
    const right = [...(right_hand.length > 0 ? right_hand : createDefaultTrials())];

    this.state = {
      trials: {
        left,
        right
      },
      averages: {
        left: calculateAverageGrip(left_hand),
        right: calculateAverageGrip(right_hand)
      }
    };
  }

  componentDidMount() {
    const { onUpdate = () => {} } = this.props;
    const { trials } = this.state;
    onUpdate({
      right_hand: trials.right,
      left_hand: trials.left
    });
  }

  render() {
    return (
      <div className="baseline-card">
        { this.renderTrials() }
        <ScoreView
          label={Strings.rightHandAverageLabel}
          score={this.state.averages.right.toFixed(GRIP_STRENGTH_DECIMAL_PLACES)}
        />
        <ScoreView
          label={Strings.leftHandAverageLabel}
          score={this.state.averages.left.toFixed(GRIP_STRENGTH_DECIMAL_PLACES)}
        />
      </div>
    );
  }

  renderTrials() {
    const { trials } = this.state;

    return [...new Array(NUMBER_OF_TRIALS)].map((_, index) => (
      <GripStrengthTrial
        key={index}
        trialLabel={Strings.formatString(Strings.trialLabel, index + 1)}
        trialNumber={index}
        inputProps={{
          leftValue: trials.left[index],
          rightValue: trials.right[index]
        }}
        onUpdate={(hand, value) => this.onUpdateTrial(index, hand, value)}
        touched={this.props.touched}
      />
    ));
  }

  onUpdateTrial(number, hand, value) {
    const { onUpdate = () => {} } = this.props;
    const { trials, averages } = this.state;
    
    trials[hand][number] = value; 

    averages[hand] = calculateAverageGrip(trials[hand]);

    this.setState({
      trials,
      averages
    });

    onUpdate({
      right_hand: trials.right,
      left_hand: trials.left
    });
  }
}

GripStrength.propTypes = {
  onUpdate: PropTypes.func,
  initialState: PropTypes.shape({
    left_hand: PropTypes.array,
    right_hand: PropTypes.array
  }),
  touched: PropTypes.bool
};

export default GripStrength;
