import { useEffect } from 'react';

const ScrollLock = ({ enabled = false }) => {
  useEffect(() => {
    if (enabled && !document.body.classList.contains('scroll-lock')) {
      document.body.classList.add('scroll-lock');
      return () => {
        document.body.classList.remove('scroll-lock');
      };
    }
    
    return undefined;
  }, [enabled]);

  return null;
};

export default ScrollLock;
