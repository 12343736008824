const EN = {
  pageTitle: 'Baseline Test',
  searchButtonText: 'Start Baseline Test',
  newPatientsStartButtonText: 'New Patient Baseline Test',
  testInProgressButtonText: 'Test in Progress?',
  activeInjuryErrorText: 'The selected Patient has an ongoing injury. If you are attempting to do a "Post-Injury" test then please access the patient SOAP note. You cannot start a pre-injury baseline test on someone with an ongoing injury.',
  checkInPatientText: 'Check-In Patient',
  checkInPatientTitle: 'Patient Check-In',
  baselineInProgressModalTitle: 'Continue Baseline in Progress',
  baselineInProgressModalMessage: 'This patient currently has a baseline in progress from {0}. Would you like to continue their baseline or start a new baseline.',
  startNewTestButtonText: 'Start New Baseline',
  continueBaselineButtonText: 'Continue Baseline'
};

export default EN;
