const EN = {
  title: 'Invite a Clinic User',
  acceptTitle: 'Join a Clinic',
  emailLabelText: 'Email',
  firstNameLabelText: 'First Name',
  lastNameLabelText: 'Last Name',
  roleLabelText: 'Role',
  roleValidationMessage: 'Please select the user\'s role.',
  firstNameValidationMessage: 'First Name is Required',
  lastNameValidationMessage: 'Last Name is Required',
  roleNoOptionText: 'Please Select',
  roleClinicianOptionText: 'Clinician',
  selectRoleOptionText: 'Please Select',
  submitButtonText: 'Invite',
  clinicUserInviteSuccessMessage: 'Invitation sent successfully! An email was sent to {0} with a link to register.',
  clinicUserRoleAddedSuccessMessage: '{0} has been added as a {1} user of this clinic'
};

export default EN;
