const EN = {
  title: 'Baseline Test History',
  emptyMessageText: 'This patient has never had a baseline test performed.',
  baselineListItemTitle: 'Baseline {0} - {1}',
  performedByMessage: 'Performed at {0} by {1} {2}',
  archivedByMessage: 'Deleted on {0} by {1} for the following reason',
  archivedTestsTitle: 'Deleted Baseline Tests',
  archivedListEmptyMessage: 'No baselines have been deleted',
  unarchivedSuccessMessage: 'Baseline test has been restored successfully!',
  continueTest: 'Continue Baseline',
  editBaseline: 'Edit Baseline',
  archiveBaselineAction: 'Delete Baseline',
  unarchiveBaselineAction: 'Restore Baseline',
  expiredLabel: 'Expired',
  incompleteLabel: 'Incomplete',
  completedLabel: 'Completed',
  archivedLabel: 'Deleted',
  archivedSuccessMessage: 'Baseline test has been deleted successfully!',
  archivedBaselinesText: 'Deleted Baselines'
};

export default EN;
