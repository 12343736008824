import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useLockout } from './context';
import Strings from './lang';
import Icon from '../icon';

const LockoutLink = ({
  className
}) => {
  const lockout = useLockout();
  const onClick = useCallback((e) => {
    e.preventDefault();
    lockout.lock();
  }, [lockout]);

  return (
    <button
      type="button"
      className={classNames('lockout-link', className)}
      onClick={onClick}
    >
      <Icon name="lock" fixedWidth />
      {Strings.lockoutLinkText}
    </button>
  );
};

LockoutLink.propTypes = {
  className: PropTypes.string
};

export default LockoutLink;
