const FR = {
  notificationMessageText: 'Vous avez 1 message non lu.',
  notificationsMessageText: 'Vous avez {0} messages non lus',
  viewButtonTitle: 'Voir',
  doneButtonTitle: 'Terminé',
  markAsReadButtonText: 'Marquer comme lu(s)',
  notificationsModalTitle: 'Messages'
};
 
export default FR;
