import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { If, Then } from 'react-if';
import { NumberInputGroup, FormInputMessage } from '@/forms';
import NumberRow from '../../../number-row';
import Icon from '../../../icon';
import { StopWatch, TIMER_STATES } from '../../../timer-components';
import Strings from './lang';

const CARD_EVENTS = {
  TIMER_START: 'timer.start',
  TIMER_STOP: 'timer.stop',
  TIMER_DONE: 'timer.done',
  TIMER_RESET: 'timer.reset',
  ERRORS_CHANGE: 'errors.change',
  CARD_RESTART: 'card.restart'
};

class KingDevickTestCard extends Component {
  constructor(props) {
    super(props);
    const { timerProps = {}, errorProps = {} } = props;

    this.stopWatch = React.createRef();
    this.onResetCard = this.onResetCard.bind(this);
    this.onChange = this.onChange.bind(this);

    this.onStart = this.onTimerEvent.bind(this, 'start');
    this.onStop = this.onTimerEvent.bind(this, 'stop');
    this.onReset = this.onTimerEvent.bind(this, 'reset');
    this.onDone = this.onTimerEvent.bind(this, 'done');

    this.state = {
      errorValue: errorProps.errors || 0,
      timerState: timerProps.timerState || TIMER_STATES.NOT_STARTED,
      time: timerProps.elapsedTime || 0
    };
  }

  render() {
    const { 
      cardNumber, hasWarning = false, warningMessage, disabled = false, valid = true 
    } = this.props;
    return (
      <div className="kd-card">
        <If condition={disabled}>
          <Then>
            <div className="disable-overlay" />
          </Then>
        </If>
        <div className="kd-card-title">
          <label>{ Strings.formatString(Strings.card, cardNumber + 1) }</label>
        </div>  
        <div className="kd-card-top">
          <If condition={this.state.timerState === TIMER_STATES.COMPLETED}>
            <Then>
              <button type="button" className="edit-button" onClick={this.onResetCard}>
                <Icon name="undo" /> {Strings.restartCard}
              </button>
            </Then>
          </If>
          <FormInputMessage
            visible={hasWarning === true}
            text={warningMessage}
            className="alert alert-info"
          />
          <FormInputMessage
            visible={this.props.touched && !valid}
            text={Strings.kingDevickCardIncompleteText}
            className="alert alert-danger"
          />
        </div>
        <div className="kd-card-content">
          <div className="kd-content-left">
            <div className="kd-key">
              {this.renderAnswerKey()}
            </div>
          </div>
          <div className="kd-content-right">
            <StopWatch
              className="kd-content-right"
              ref={this.stopWatch}
              timerProps={{
                timerState: this.state.timerState,
                elapsedTime: this.state.time
              }}
              onDoneRenderableNode={(
                <Icon name="check-circle" size="4x" className="success" />
              )}
              onStart={this.onStart}
              onStop={this.onStop}
              onReset={this.onReset}
              onDone={this.onDone}
            />
            <div className="kd-error-input">
              <NumberInputGroup
                className="form-group"
                labelText={Strings.errorsInputLabel}
                inputProps={{
                  min: 0,
                  step: 1,
                  value: this.props.errorProps.errors,
                  className: 'form-control'
                }}
                messageText={Strings.numberInputErrorMessage}
                messageClassName="alert alert-danger"
                required={!(hasWarning === true)}
                onUpdate={this.onChange}
                touched={this.props.touched}
              />       
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderAnswerKey() {
    return this.props.answerKey.map((row, index) => {
      return (
        <NumberRow
          key={index}
          ElementType="p"
          joinCharacter=" - "
          numberArray={row}
        />
      );
    });
  }

  onChange(numberValue) {
    const { cardNumber, onUpdate = () => {} } = this.props;

    this.setState({
      errorValue: numberValue
    });

    onUpdate(CARD_EVENTS.ERRORS_CHANGE, cardNumber, numberValue);
  }

  onTimerEvent(eventType, tState, elapsed) {
    let { timerState, time } = this.state;
    const { cardNumber, onUpdate = () => {} } = this.props;

    timerState = tState;
    time = elapsed;

    this.setState({
      timerState,
      time
    });

    switch (eventType) {
      case 'start':
        onUpdate(CARD_EVENTS.TIMER_START, cardNumber);
        break;
      case 'stop':
        onUpdate(CARD_EVENTS.TIMER_STOP, cardNumber);
        break;
      case 'reset':
        onUpdate(CARD_EVENTS.TIMER_RESET, cardNumber);
        break;
      case 'done':
        onUpdate(CARD_EVENTS.TIMER_DONE, cardNumber, time);
        break;
      default:
        break;
    }
  }
  
  onResetCard() {
    let { errorValue } = this.state;
    const { cardNumber, onUpdate = () => {} } = this.props;
    errorValue = '';
    this.setState({
      errorValue
    });
    onUpdate(CARD_EVENTS.CARD_RESTART, cardNumber);
    this.stopWatch.current.reset();
  }
}

KingDevickTestCard.propTypes = {
  cardNumber: PropTypes.number.isRequired,
  answerKey: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.number)
  ).isRequired,
  hasWarning: PropTypes.bool,
  warningMessage: PropTypes.string,
  timerProps: PropTypes.shape({
    elapsedTime: PropTypes.number,
    timerState: PropTypes.number
  }),
  errorProps: PropTypes.shape({
    errors: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }),
  onUpdate: PropTypes.func,
  touched: PropTypes.bool
};

export {
  KingDevickTestCard,
  CARD_EVENTS
};
