import React, { useEffect, useMemo, useRef } from 'react';
import { useKeenSlider } from 'keen-slider/react';
import classNames from 'classnames';
import Icon from '../../../../icon';

const ITEM_HEIGHT = 30;
const DEFAULT_HEIGHT = 400;

const NumberWheel = ({
  number = 0,
  max = 1,
  height = DEFAULT_HEIGHT,
  onNumberChange = () => { }
}) => {
  const initial = useRef(max - (number ?? 0));
  const SLIDES_PER_VIEW = Math.round(height / ITEM_HEIGHT);
  const itemHeight = height / SLIDES_PER_VIEW;
  const slides = useMemo(() => [...new Array(max + 1)].map((_, i) => i).reverse(), [max]);

  const [sliderRef, slider] = useKeenSlider({
    loop: false,
    initial: initial.current,
    vertical: true,
    rubberband: true,
    mode: 'snap',
    slides: {
      number: max + 1,
      origin: 'center',
      perView: SLIDES_PER_VIEW
    },
    slideChanged(s) {
      onNumberChange(max - s.track.details.rel);
    }
  });

  useEffect(() => {
    if (slider.current && (max - slider.current.track.details.rel) !== number) {
      slider.current.moveToIdx(max - number);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [number]);

  return (
    <div className="number-slider" style={{ height }}>
      <div ref={sliderRef} className="keen-slider">
        {slides.map(i => (
          <div
            key={i}
            className={classNames('keen-slider__slide', { active: number === i })}
            onClick={() => {
              if (number !== i) {
                onNumberChange(i);
              }
            }}
          >
            {i}
          </div>
        ))}
      </div>
      <div
        className="selection-indicator-container"
        style={{
          height: itemHeight,
          top: itemHeight * (SLIDES_PER_VIEW / 2)
        }}
      >
        <div className="selection-indicator" />
      </div>
      <div className="shadow-top" style={{ height: itemHeight * 2 }} />
      <div className="shadow-bottom" style={{ height: itemHeight * 2 }} />
    </div>
  );
};

const NumberSlider = ({
  number,
  onNumberChange
}) => {
  return (
    <div className="number-slider-container">
      <button
        type="button"
        className="increment"
        onClick={() => onNumberChange(prev => prev + 1)}
      >
        <Icon name="plus" />
      </button>
      <NumberWheel
        max={100}
        height={240}
        number={number}
        onNumberChange={onNumberChange}
      />
      <button
        type="button"
        className="decrement"
        onClick={() => onNumberChange(prev => prev - 1)}
      >
        <Icon name="minus" />
      </button>
    </div>
  );
};

export default NumberSlider;
