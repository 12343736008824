import { useKeenSlider } from 'keen-slider/react';
import 'keen-slider/keen-slider.min.css';

export function MutationPlugin(slider) {
  const observer = new MutationObserver((mutations) => {
    mutations.forEach(() => {
      slider.update();
    });
  });

  const config = { childList: true };

  slider.on('created', () => {
    observer.observe(slider.container, config);
  });

  slider.on('destroyed', () => {
    observer.disconnect();
  });
}

export function ThumbnailPlugin(mainRef) {
  return (slider) => {
    function removeActive() {
      slider.slides.forEach((slide) => {
        slide.classList.remove('active');
      });
    }

    function addActive(idx) {
      slider.slides[idx].classList.add('active');
    }

    function addClickEvents() {
      slider.slides.forEach((slide, idx) => {
        slide.addEventListener('click', () => {
          if (mainRef.current) mainRef.current.moveToIdx(idx);
        });
      });
    }

    function setActiveSlide(main) {
      removeActive();
      const next = main.animator.targetIdx || 0;
      addActive(main.track.absToRel(next));
      slider.moveToIdx(next);
    }

    slider.on('created', () => {
      if (!mainRef.current) return;
      addActive(slider.track.details.rel);
      addClickEvents();
      mainRef.current.on('animationStarted', (main) => {
        setActiveSlide(main);
      });
    });

    slider.on('updated', () => {
      addClickEvents();
      setActiveSlide(mainRef.current);
    });
  };
}

export default useKeenSlider;
