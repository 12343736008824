import React, {
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import {
  useParams,
  generatePath,
  useLocation,
  useNavigate,
  Link,
  Outlet
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { If, Then, Else } from 'react-if';
import classNames from 'classnames';
import { Page } from '../page';
import Icon from '../icon';
import { buildTabsFromRoles, Permissions } from './patient-profile-utils';
import { ClinicSelector, UserSelector } from '@/redux/selectors';
import { useMount } from '@/hooks';
import { getClinicAsync } from '@/redux/thunks/clinics';
import { ClinicPaths, DashboardPaths } from '@/paths';
import Activity from '../activity';
import ScrollTop from '../scroll-top';
import Tabs from '../tabs';
import { PatientName } from '../patient-meta';
import PatientProfileHeader from './patient-profile-header';
import {
  RoleDescriptions,
  userHasAdminRole,
  userHasRoleMatchingDescription,
  userHasRoleMatchingOneOfDescriptions
} from '@/utilities/user-roles';
import { getUserAsync } from '@/redux/thunks/users';
import { ErrorBanner, ErrorPage } from '../errors';
import { getMedicalInfoAsync } from '@/redux/thunks/medical-info';
import { getActiveInjuryAsync, getInjuriesAsync } from '@/redux/thunks/injuries';
import HTTPStatusCodes from '@/utilities/http-status-codes';
import Strings from './lang';

const Breadcrumbs = ({
  user,
  clinicId
}) => {
  return (
    <ol className="breadcrumb">
      <li>
        <Link
          to={DashboardPaths.index.link}
        >
          {Strings.dashboardTitleText}
        </Link>
      </li>
      <li>
        <Link
          to={generatePath(ClinicPaths.patients.link, {
            clinicId
          })}
        >
          {Strings.managePatientsTitleText}
        </Link>
      </li>
      <li className="active">
        <PatientName
          patient={user || {}}
          clinicId={clinicId}
          redactedText={user?.account}
        />
      </li>
    </ol>
  );
};

const getPatientTabsConfig = (patient, params, currentUser) => {
  const tabs = buildTabsFromRoles(params.userId, params.clinicId, currentUser);

  if (patient?.unassessed_injury_report) {
    /* add one to account for index 0 being marked as false */
    const reportedTab = tabs.findIndex(tab => tab.key === 'reported');

    if (reportedTab >= 0) {
      tabs[reportedTab].tabClassName = 'alert error';
    }
  }

  return tabs;
};

const getPatientErrorTitle = (error) => {
  switch (error?.status) {
    case HTTPStatusCodes.Unauthorized:
      return `401: ${Strings.unauthorizedTitle}`;
    case HTTPStatusCodes.NotFound:
      return `404: ${Strings.patientNotFoundTitle}`;
    default:
      return 'An Error Occurred';
  }
};

const PatientProfileLayout = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [patientError, setPatientError] = useState(null);
  const [error, setError] = useState(null);
  const currentUser = useSelector(UserSelector.getCurrentUser);
  const isAdmin = useMemo(() => userHasAdminRole(currentUser), [currentUser]);
  const patient = useSelector(state => UserSelector.getUser(state, params));
  const clinic = useSelector(state => ClinicSelector.getClinic(state, { id: params.clinicId }));
  const tabs = useMemo(() => (
    getPatientTabsConfig(patient, params, currentUser)
  ), [currentUser, params, patient]);

  const getClinic = useCallback(() => {
    if (clinic?.id) return;
    dispatch(getClinicAsync(params.clinicId)).catch(() => {});
  }, [clinic?.id, dispatch, params.clinicId]);

  const getPatient = useCallback((userId) => {
    return isAdmin
      ? Promise.resolve({})
      : dispatch(getUserAsync(userId)).then(user => {
        if (!userHasRoleMatchingDescription(user, RoleDescriptions.Player)) {
          return Promise.reject({
            status: 404,
            message: Strings.patientNotFoundMessage
          });
        }

        return Promise.resolve(user);
      });
  }, [dispatch, isAdmin]);

  const getPatientFileInfo = useCallback((userId, clinicId) => {
    setLoading(true);
    setError(null);
    setPatientError(null);

    getPatient(userId).then(() => {
      const requests = [];

      tabs.forEach(tab => {
        switch (tab.key) {
          case 'medical':
            requests.push(dispatch(getMedicalInfoAsync(userId)));
            break;
          case 'injuries':
            /**
             * If has permission to view all then fetch all otherwise fetch active,
             * Users will no permission -> this tab is never called
             */
            if (userHasRoleMatchingOneOfDescriptions(
              currentUser,
              Permissions.makePlayerInjuriesViewerRoles(clinicId)
            )) {
              requests.push(dispatch(getInjuriesAsync(userId)));
            } else {
              requests.push(dispatch(getActiveInjuryAsync(userId)));
            }
            break;
          default:
            requests.push(Promise.resolve({}));
            break;
        }
      });

      Promise.all(requests).then(() => {
        setLoading(false);
      }).catch((e) => {
        setLoading(false);
        setError(e.message);
      });
    }).catch((e) => {
      setLoading(false);
      setPatientError({
        status: e.status,
        message: e.status === HTTPStatusCodes.Unauthorized
          ? Strings.unauthorizedMessage
          : e.message
      });
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, dispatch, getPatient]);

  useEffect(() => {
    const baseRoute = generatePath(ClinicPaths.patientProfile.index.link, params);

    if (baseRoute === location.pathname && tabs[0]) {
      setTimeout(() => {
        navigate(tabs[0].route, { replace: true });
      }, 1);
    }
  }, [location.pathname, navigate, params, tabs]);

  useMount(getClinic);

  useMount(() => {
    if (params.userId) {
      getPatientFileInfo(params.userId, params.clinicId);
    }
  });

  return (
    <Page className={classNames('user-profile', { 'inactive-profile': patient?.active === false })}>
      <If condition={!!patientError}>
        <Then>
          <ErrorPage
            title={getPatientErrorTitle(patientError)}
            message={patientError?.message}
          />
        </Then>
        <Else>
          <>
            {patient?.active === false && (
              <div className="account-deactivated-banner no-print">
                <div className="banner-content">
                  <Icon name="circle-info" />&nbsp;&nbsp;{Strings.accountDeactivatedBannerMessage}
                </div>
              </div>
            )}
            <Activity
              static
              active={loading}
              titleComponent={<h1>{Strings.loadingPatientProfileText}</h1>}
            >
              <Breadcrumbs
                user={patient}
                clinicId={params.clinicId}
              />

              <PatientProfileHeader
                user={patient}
                clinic={clinic}
              />

              <ErrorBanner error={error} />

              <Tabs.Routable
                className="patient-profile-tabs"
                disableContextMenu
                tabs={tabs}
              >
                <div className="component-view">
                  <Outlet />
                </div>
                <ScrollTop />
              </Tabs.Routable>
            </Activity>
          </>
        </Else>
      </If>
    </Page>
  );
};

export default PatientProfileLayout;
