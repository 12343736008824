import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Note from './note';
import SoapSection from './soap-section';
import { buildNotes } from '../soap-utilities';
import Strings from '../lang';

class AssessmentNotesForm extends Component {
  constructor(props) {
    super(props);
    const { notes = {} } = props;

    this.onSubmit = this.onSubmit.bind(this);
    this.onUpdateNotes = this.onUpdateNotes.bind(this);

    this.state = {
      aNotes: notes.a || ''
    };
  }

  render() {
    return (
      <SoapSection title={Strings.assessmentLabel} onSubmitSection={this.onSubmit}>
        <Note
          label={Strings.assessmentLabel}
          namespace="soap-assessment-notes"
          note={this.state.aNotes}
          onUpdate={this.onUpdateNotes}
        />
      </SoapSection>
    );
  }

  onUpdateNotes(value) {
    this.setState({
      aNotes: value
    });
  }

  onSubmit() {
    const { notes = {} } = this.props;
    const { aNotes } = this.state;
    const attributes = {
      notes: {
        ...buildNotes(notes),
        a: aNotes
      }
    };

    this.props.onSubmit(attributes);   
  }
}

AssessmentNotesForm.propTypes = {
  notes: PropTypes.object,
  onSubmit: PropTypes.func
};

export default AssessmentNotesForm;
