const FR = {
  title: 'Statistiques de la clinique',
  totalPatientsLabelText: 'Total de patients :',
  expiredBaselinesLabelText: 'Tests de référence expirés :',
  acuteLabelText: 'Aigu :',
  subacuteLabelText: 'Subaigu :',
  chronicLabelText: 'Chronique :',
  dayValueText: '{0} jour',
  daysValueText: '{0} jours',
  currentOpenInjuriesHeadingText: 'Blessures actuelles présentement ouvertes',
  averageRecoveryTimeHeadingText: 'Temps de récupération moyen',
  baselinesHeadingText: 'Tests de référence par année civile',
  injuriesHeadingText: 'Blessures diagnostiquées par année civile',
  totalBaselineText: 'Total des lignes de base:',
  totalInjuriesText: 'Total des blessures diagnostiquées:',
  allStatsSubtitle: 'Toutes les cliniques'
};
 
export default FR;
