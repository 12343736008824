import React, { useMemo } from 'react';
import dayjs from 'dayjs';
import StatCard from './stat-card';
import { DISPLAY_DATE_FORMAT, INPUT_DATE_FORMAT } from '@/utilities/dates/formats';
import Strings from '../lang';

const MAX_ACUTE_DURATION = 10;
const MAX_SUBACUTE_DURATION = 30;

const getInjuryType = (diff) => {
  if (diff <= MAX_ACUTE_DURATION) return Strings.acuteText;
  if (diff > MAX_ACUTE_DURATION && diff <= MAX_SUBACUTE_DURATION) return Strings.subacuteText;
  return Strings.chronicText;
};

const OverviewStats = ({
  injury = {}
}) => {
  const {
    injured_at,
    injury_assessments = [],
    soap_notes = [],
    specialist_notes = []
  } = injury;
  const injuryDuration = useMemo(() => dayjs().diff(dayjs(injured_at, INPUT_DATE_FORMAT), 'days'), [injured_at]);
  const recoveryDuration = useMemo(() => dayjs().diff(dayjs(injury_assessments[0]?.created_at, INPUT_DATE_FORMAT), 'days'), [injury_assessments]);
  const assessmentDiff = injuryDuration - recoveryDuration;
  const injuryType = getInjuryType(assessmentDiff);

  return (
    <div className="insights-stat-cards">
      <StatCard
        type="danger"
        icon="clock"
        title={Strings.injuryDurationCardTitle}
        value={injuryDuration}
        unit={Strings.daysDurationLabel}
        subText={Strings.formatString(
          Strings.injuryDurationCardMessage, 
          dayjs(injured_at, INPUT_DATE_FORMAT).format(DISPLAY_DATE_FORMAT)
        )}
      />
      <StatCard
        type="warning"
        icon="clock"
        title={Strings.recoveryDurationCardTitle}
        value={recoveryDuration}
        unit={Strings.daysDurationLabel}
        subText={Strings.formatString(
          Strings.recoveryDurationCardMessage,
          dayjs(injury_assessments[0]?.created_at, INPUT_DATE_FORMAT).format(DISPLAY_DATE_FORMAT)
        )}

      />
      <StatCard
        type="secondary"
        icon="bolt"
        title={Strings.assessmentTypeCardTitle}
        value={injuryType}
        subText={Strings.formatString(
          Strings.assessmentTypeCardMessage,
          assessmentDiff
        )}
      />
      <StatCard
        type="primary"
        icon="user-doctor"
        title={Strings.totalVisitsCardTitle}
        value={soap_notes.length + injury_assessments.length + specialist_notes.length}
        unit={Strings.visitsUnitLabel}
      />
    </div>
  );
};

export default OverviewStats;
