const EN = {
  typeLabel: 'Type',
  dateLabel: 'Date',
  paidByLabel: 'Paid By',
  totalLabel: 'Total',
  paymentApprovedTitle: 'Payment Confirmation',
  paymentApprovedEmailText: 'Thank you for your payment. An email with your confirmation details is on the way.',
  paymentApprovedInvoiceText: 'Invoice details',
  paymentApprovedBackButtonText: 'Back to Dashboard',
  paymentApprovedConfirmationNumText: 'Confirmation Number',
  paymentFailTitle: 'Sorry, order payment failed.',
  paymentInvoiceNumberLabel: 'Invoice Number',
  paymentCardHolderLabel: 'Card Holder',
  paymentCardLabel: 'Card',
  backToDashboardBtnText: 'Back to Dashboard',
  backToCurrentBalanceBtnText: 'Back to Current Balance',
  expiredCardErrorMessage: 'Your payment was declined because your card has expired. Please use a different card.',
  cardCancelledErrorMessage: 'The debit transaction has not been approved. Please use a different card.',
  exceedsRefundErrorMessage: 'The amount of this transaction is greater than your refund limit. Please use a different card.',
  invalidCardErrorMessage: 'Your payment was declined. Please use another payment method.',
  cardNotSupportedErrorMessage: 'Your card is not supported. Please use another payment method.',
  paymentAmountLabel: 'Payment Amount',
  fullPaymentOption: 'Full Amount',
  minimumPaymentOption: 'Minimum Amount',
  customPaymentOption: 'Custom Amount',
  customPaymentLabel: 'Between {0} and {1}',
  amountLabel: 'Amount',
  customPaymentErrorMessage: 'Please enter between {0} and {1}',
  updateAmountButtonText: 'Update',
  payNowButtonText: 'Pay Now',
  continueButtonText: 'Continue',
  paymentDrawerTitle: 'Make a Payment',
  selectPaymentOptionLabel: 'Select an Option',
  balanceText: 'Balance',
  paymentsTableTitle: 'Payments',
  orderIdColumnHeader: 'Order ID',
  paymentAdjustmentText: 'Payment Adjustment',
  normalPaymentText: 'Payment',
  recievePaymentDrawerTitle: 'Recieve Payment',
  recievePaymentAmountError: 'Amount must be less than {0} and greater than 0',
  noteLabel: 'Note',
  submitButtonText: 'Submit',
  currentBalanceLabel: 'Current Balance',
  paymentsPopoverTitle: 'Received Payments',
  paymentIdLabel: 'Payment ID',
  editAmountLabel: 'Edit Payment Amount',
  paymentFailedErrorMessage: 'Payment Failed: {0}',
  retryPaymentButtonText: 'Try Again',
  paymentSuccessEmailMessage: 'A receipt has been emailed to the email address we have on file for you. If you did not receive a receipt and would like one, please contact <strong>Pamela Gonzalez-Silva</strong> at <a href="mailto:pamela@completeconcussions.com">pamela@completeconcussions.com</a>',
  paymentSuccessTitle: 'Payment Approved!',
  paymentSuccessFooterMessage: 'Thank you for your business!',
  dashboardLinkText: 'Dashboard',
  invoicesLinkText: 'Invoices',
  creditCardInputLabel: 'Credit Card',
  billingNameInputLabel: 'Name on Card',
  billingNameErrorMessage: 'Please enter the name on the front of the card',
  payAmountButtonText: 'Pay {0} {1}'
};

export default EN;
