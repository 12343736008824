const MS_PER_S = 1000;
const S_PER_MIN = 60;
const MAX_DURATION_MINUTES = 20;

const UserActivityDefaults = {
  interval: 1000,
  inactivityTime: MS_PER_S * S_PER_MIN * MAX_DURATION_MINUTES,
  events: ['scroll', 'mousemove', 'keyup']
};

export default UserActivityDefaults;
