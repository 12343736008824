import Strings from './lang';

const BasketballPositions = [
  { key: 'pointguard', name: Strings.basketball_pointguard },
  { key: 'shootingguard', name: Strings.basketball_shootingguard },
  { key: 'smallforward', name: Strings.basketball_smallforward },
  { key: 'center', name: Strings.basketball_center },
  { key: 'powerforward', name: Strings.basketball_powerforward }
];

export default BasketballPositions;
