import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import RecievePaymentForm from './recieve-payment-form';
import Activity from '../activity';
import Drawer from '../drawer';
import { ErrorBanner } from '../errors';
import { createPaymentAdjustmentAsync } from '@/redux/thunks/clinic-invoices';
import Strings from './lang';
import { Money } from '@/utilities/currency';
import Icon from '../icon';

const RecievePaymentDrawer = ({
  isOpen = false,
  clinicId,
  invoiceId,
  currency,
  createPayment,
  balance,
  onClose = () => {}
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const handleSubmit = useCallback((attributes) => {
    setSubmitting(true);
    setError(null);

    createPayment(clinicId, invoiceId, attributes).then(() => {
      setSubmitting(false);
      onClose(true);
    }).catch(error => {
      setSubmitting(false);
      setError(error.message);
    });
  }, [clinicId, createPayment, invoiceId, onClose]);

  return (
    <Drawer
      blur
      position="right"
      isOpen={isOpen}
      overlayDisabled={submitting}
      onClose={onClose}
    >
      <div className="recieve-payment-drawer">
        <div className="drawer-close">
          <button 
            type="button" 
            className="close" 
            onClick={onClose}
          >
            <Icon name="xmark" />
          </button>
        </div>
        <h1 className="display">
          {Strings.recievePaymentDrawerTitle}
        </h1>
        <p>{Strings.currentBalanceLabel}: {Money.format(balance, currency)} {currency}</p>
        <ErrorBanner error={error} />
        <Activity active={submitting}>
          <RecievePaymentForm 
            balance={balance}
            onSubmit={handleSubmit}
          />
        </Activity>
      </div>
    </Drawer>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    createPayment: (clinicId, invoiceId, attributes) => {
      return dispatch(createPaymentAdjustmentAsync(clinicId, invoiceId, attributes));
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(RecievePaymentDrawer);
