import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SelectInputGroup, RadioInputGroup } from '@/forms';
import { WordLists, WordListDisplay, WordCheckList } from '../word-lists';
import ScoreView from '../../../score-view';
import Strings from './lang';

const NUMBER_OF_TRIALS = 3;
const MAX_WORD_COUNT = 10;
const MIN_WORD_COUNT = 5;
const DEFAULT_WORDS_LENGTH = 5;

const getTrialNumber = (trials) => trials.findIndex(trail => trail === '');

const createDefaultTrials = () => {
  return new Array(NUMBER_OF_TRIALS).fill('');
};

const getScore = (trials) => trials.reduce((acc, cur) => {
  return acc + (!isNaN(parseInt(cur, 10)) ? parseInt(cur, 10) : 0);
}, 0);

class ImmediateMemory extends Component {
  constructor(props) {
    super(props);

    const { initialState, language = 'en' } = props;
    const { listNumber = '', trials = createDefaultTrials(), wordCount } = initialState;

    const trialsD = [...(trials.length > 0 ? trials : createDefaultTrials())];
    const currentTrial = getTrialNumber(trialsD);

    this.onWordListChange = this.onWordListChange.bind(this);
    this.updateWordCount = this.updateWordCount.bind(this);
    this.onNextTrial = this.onNextTrial.bind(this);

    this.state = {
      listNumber,
      wordCount,
      currentTrial,
      words: WordLists[language][wordCount][listNumber] || [],
      memoryScore: 0,
      trials: trialsD
    };
  }
  
  componentDidMount() {
    const { trials, listNumber, wordCount } = this.state;
    const { onUpdate = () => {} } = this.props;

    const score = getScore(trials);

    this.setState({
      trials,
      memoryScore: score
    });

    onUpdate({ trials, listNumber, wordCount });
  }

  render() {
    const { postInjury = false } = this.props;
    return (
      <div className="immediate-memory-score">
        {!postInjury && (
          <div className="im-word-count">
            <RadioInputGroup 
              className="form-group"
              titleLabelText="Number of Words"
              radioLabels={['10 Words', '5 Words']}
              radioValues={[MAX_WORD_COUNT.toString(), MIN_WORD_COUNT.toString()]}
              inputProps={{
                className: 'form-control',
                value: this.state.wordCount?.toString()
              }}
              initialValue={this.state.wordCount?.toString()}
              inputValid
              onUpdate={this.updateWordCount}
            />
          </div>
        )}
        
        <div className="im-content">
          <div className="im-content-left">
            <div>
              {this.renderWordListSelector()}
            </div>
            <div className="im-word-list">
              {this.state.currentTrial < 0 
                ? <WordListDisplay words={this.state.words} /> 
                : (
                  <WordCheckList 
                    trialNumber={this.state.currentTrial} 
                    maxTrials={this.state.trials.length} 
                    words={this.state.words} 
                    onNext={this.onNextTrial} 
                  />
                )}
            </div>
          </div>
          <div className="im-trials">
            { this.renderTrials() }
          </div>
        </div>
        <ScoreView
          label={Strings.scoreTotalLabel}
          score={this.state.memoryScore}
          outOf={NUMBER_OF_TRIALS * (parseInt(this.state.wordCount, 10) || DEFAULT_WORDS_LENGTH)}
        />
      </div>
    );
  }

  renderWordListSelector() {
    const { language, usedWordLists = [] } = this.props;
    const { wordCount, listNumber } = this.state;
    return (
      <SelectInputGroup
        labelText={Strings.listSelectLabel}
        className="form-group"
        inputProps={{
          className: 'form-control',
          value: listNumber
        }}
        messageClassName="alert alert-danger"
        messageText={Strings.selectListErrorMessage}
        required
        touched={this.props.touched}
        valid={listNumber !== ''}
        onUpdate={this.onWordListChange}
      >
        <option value="">{Strings.defaultListOption}</option>
        { Object.keys(WordLists[language][wordCount]).map((key, index) => {
          const disabled = usedWordLists.includes(parseInt(key, 10));
          return (
            <option key={index} value={key} disabled={disabled}>
              {`${key} ${disabled ? `(${Strings.usedText})` : ''}`}
            </option>
          );
        })}
      </SelectInputGroup>
    );
  }

  renderTrials() {
    const { currentTrial, trials, words } = this.state;

    const trialOptions = (
      words.map((word, index) => {
        return <option key={index} value={index + 1}>{index + 1}</option>;
      })
    );

    return trials.map((value, index) => {
      return (
        <SelectInputGroup
          key={index}
          labelText={`${Strings.trialLabel} ${index + 1}`}
          className="form-group"
          inputProps={{
            disabled: currentTrial === -1 ? false : currentTrial < index,
            className: 'form-control',
            value
          }}
          messageClassName="alert alert-danger"
          required
          touched={this.props.touched}
          valid={value !== ''}
          onUpdate={(value) => this.onTrialChange(index, value)}
        >
          <option value="">{Strings.defaultOption}</option>
          {trialOptions.length > 0 && <option value={0}>{0}</option>}
          {trialOptions}         
        </SelectInputGroup>
      );
    }); 
  }

  updateWordCount(value) {
    let { 
      trials, listNumber, words, wordCount, memoryScore, currentTrial
    } = this.state;
    const { onUpdate = () => {}, onUpdateWordCount } = this.props;

    wordCount = parseInt(value, 10);
    memoryScore = 0;
    trials = createDefaultTrials();
    listNumber = '';
    words = [];
    currentTrial = 0;

    this.setState({
      wordCount,
      listNumber,
      memoryScore,
      trials,
      words,
      currentTrial
    });

    if (typeof onUpdateWordCount === 'function') {
      onUpdateWordCount(value);
    }

    onUpdate({ listNumber, trials, wordCount });
  }

  onWordListChange(value) {
    const { wordCount } = this.state;
    /* reset any state and add the new wordlist number in */
    let { 
      trials, listNumber, words, memoryScore, currentTrial 
    } = this.state;
    const { language = 'en', onUpdate = () => {} } = this.props;

    trials = createDefaultTrials();
    listNumber = parseInt(value, 10);
    words = WordLists[language][wordCount][value] || [];
    memoryScore = 0;
    currentTrial = 0;

    this.setState({
      listNumber,
      words,
      memoryScore,
      trials,
      currentTrial
    });

    onUpdate({ trials, listNumber, wordCount });
  }

  onTrialChange(trialNumber, value) {
    const { trials, listNumber, wordCount } = this.state;
    let { currentTrial } = this.state;
    const { onUpdate = () => {} } = this.props;

    trials[trialNumber] = value;
    currentTrial = getTrialNumber(trials);

    const score = getScore(trials);

    this.setState({
      trials,
      currentTrial,
      memoryScore: score
    });

    onUpdate({ trials, listNumber, wordCount });
  }

  onNextTrial(total) {
    const { trials, listNumber, wordCount } = this.state;
    let { currentTrial, memoryScore } = this.state;
    const { onUpdate = () => {} } = this.props;
    trials[currentTrial] = total;
    currentTrial = getTrialNumber(trials);

    memoryScore = getScore(trials);

    this.setState({ trials, currentTrial, memoryScore });

    onUpdate({ trials, listNumber, wordCount });
  }
}

ImmediateMemory.propTypes = {
  language: PropTypes.string,
  initialState: PropTypes.shape({
    trials: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
    listNumber: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  touched: PropTypes.bool,
  usedWordLists: PropTypes.array,
  onUpdate: PropTypes.func
};

export default ImmediateMemory;
