const EN = {
  nameHeadingText: 'Name',
  statusHeadingText: 'Status',
  renewalHeadingText: 'Renewal',
  accountHeadingText: 'Account #',
  baselineExpiryHeadingText: 'Baseline Test Expiry',
  injuryStageHeadingText: 'Injury Stage (Days)',
  injuryStageChronicText: 'Chronic ({0})',
  injuryStageSubacuteText: 'Subacute ({0})',
  injuryStageAcuteText: 'Acute ({0})'
};

export default EN;
