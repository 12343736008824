import WordLists from './word-lists';
import WordCheckList from './word-check-list';
import WordListDisplay from './word-list-display';

const DEFAULT_MAX_WORD_LISTS = 30;

const getWordList = (language, listNumber, count) => {
  const localizedWordLists = WordLists[language] || {};
  const wordList = localizedWordLists[count][listNumber] || [];
  return wordList.join(', ');
};

const getUnusableWordLists = (language, wordLists, currentList, wordCount) => {
  let unuseable = [];
  const MAX_WORD_LISTS = Object.keys(WordLists[language][wordCount] || {}).length 
    || DEFAULT_MAX_WORD_LISTS;
  const iteration = Math.floor(wordLists.length / MAX_WORD_LISTS) + 1;

  if (wordLists.length > 0) {
    for (let i = 1; i <= MAX_WORD_LISTS; i += 1) {
      const count = wordLists.filter(list => parseInt(list, 10) === i).length;
      if (count >= iteration && String(currentList) !== String(i)) {
        unuseable.push(i);
      }
    }
  
    if (unuseable.length >= MAX_WORD_LISTS) {
      unuseable = [];
    }
  }

  return unuseable;
};

export {
  WordLists,
  WordListDisplay,
  WordCheckList,
  getWordList,
  getUnusableWordLists
};
