import React from 'react';
import { ReferenceLine } from 'recharts';
import Strings from '../lang';

const getStats = (stat, metric) => {
  const {
    [metric]: avg,
    [`stdev_${metric}`]: stddev
  } = stat || {};
  const average = parseFloat(avg) ?? null;
  const deviation = parseFloat(stddev) ?? null;

  return {
    avg: average,
    stddev: {
      upper1: (deviation > 0 && parseFloat(average + deviation)) || 0,
      upper2: (deviation > 0 && parseFloat(average + deviation + deviation)) || 0,
      lower1: (deviation > 0 && parseFloat(average - deviation)) || 0,
      lower2: (deviation > 0 && parseFloat(average - deviation - deviation)) || 0
    }
  };
};

const getAvgStdReferenceLines = (stat, metric) => {
  const { avg, stddev } = getStats(stat, metric);

  return [
    stddev.upper2 > 0 && (
      <ReferenceLine
        key="mean_max_2"
        y={stddev.upper2}
        stroke="#DB231F"
        strokeDasharray="20 3"
        label={{
          position: 'insideBottomLeft',
          fontSize: 8,
          value: `${Strings.secondUpperStdReferenceLineText} - ${stddev.upper2.toFixed(2)}`
        }}
      />
    ),
    stddev.upper1 > 0 && (
      <ReferenceLine
        key="mean_max_1"
        y={stddev.upper1}
        stroke="#FD9F37"
        strokeDasharray="10 5"
        opacity={0.9}
        label={{
          position: 'insideBottomLeft',
          fontSize: 8,
          value: `${Strings.firstUpperStdReferenceLineText} - ${stddev.upper1.toFixed(2)}`
        }}
      />
    ),
    avg !== null && avg >= 0 && (
      <ReferenceLine
        key="mean"
        y={avg}
        stroke="#0C1826"
        opacity={0.7}
        strokeDasharray="5 5"
        label={{
          position: 'insideBottomLeft',
          fontSize: 8,
          fontWeight: 'bolder',
          value: `${Strings.baselineReferenceLineText} - ${avg.toFixed(2)}`
        }}
      />
    ),
    stddev.lower1 > 0 && (
      <ReferenceLine
        key="mean_min_1"
        y={stddev.lower1}
        stroke="#FD9F37"
        strokeDasharray="10 5"
        opacity={0.9}
        label={{
          position: 'insideTopLeft',
          fontSize: 8,
          value: `${Strings.firstLowerStdReferenceLineText} - ${stddev.lower1.toFixed(2)}`
        }}
      />
    ),
    stddev.lower2 > 0 && (
      <ReferenceLine
        key="mean_min_2"
        y={stddev.lower2}
        stroke="#DB231F"
        strokeDasharray="20 3"
        label={{
          position: 'insideTopLeft',
          fontSize: 8,
          value: `${Strings.secondLowerStdReferenceLineText} - ${stddev.lower2.toFixed(2)}`
        }}
      />
    )
  ].filter(Boolean);
};

export const getYAxisReferenceLines = (baseline, metric) => {
  if (!baseline) return null;

  return getAvgStdReferenceLines(baseline[baseline.length === 2 ? 1 : 0], metric);
};
