import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Icon from '../icon';

const ENTER_KEY_CODE = 13;

const MedicalListInput = ({
  inputProps,
  onAdd
}) => {
  const onKeyPress = useCallback((e) => {
    if (e.which === ENTER_KEY_CODE) {
      e.preventDefault();
      onAdd();
    }
  }, [onAdd]);

  return (
    <div className="input-group">
      <input
        type="text"
        {...inputProps}
        onKeyPress={onKeyPress}
      />
      <div className="input-group-btn">
        <button type="button" className="btn btn-primary" onClick={onAdd}>
          <Icon name="plus" />
        </button>
      </div>
    </div>
  );
};

MedicalListInput.propTypes = {
  inputProps: PropTypes.object,
  onAdd: PropTypes.func
};

export default MedicalListInput;
