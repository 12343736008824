import React from 'react';
import PropTypes from 'prop-types';
import { If, Then } from 'react-if';

const ScoreView = ({
  label,
  score,
  outOf
}) => (
  <div className="score-view">
    <label>{label}</label> 
    <span className="score-view-score">{score}</span>
    <If condition={outOf !== undefined}>
      <Then>
        <small>{`/${outOf}`}</small>
      </Then>
    </If>
  </div>
);

ScoreView.propTypes = {
  label: PropTypes.string.isRequired,
  score: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  outOf: PropTypes.number
};

export default ScoreView;
