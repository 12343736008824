const EN = {
  headache: 'Headache',
  headPressure: 'Pressure in head',
  neckPain: 'Neck Pain',
  nausea: 'Nausea or Vomiting',
  dizziness: 'Dizziness',
  blurredVision: 'Blurred Vision',
  balance: 'Balance Problems',
  lightSensitive: 'Sensitivity to Light',
  noiseSensitive: 'Sensitivity to Noise',
  slowedDown: 'Feeling Slowed Down',
  inAFog: 'Feeling Like \'In a Fog\'',
  dontFeelRight: 'Don\'t Feel Right',
  concentration: 'Difficulty Concentrating',
  remembering: 'Difficulty Remembering',
  fatigue: 'Fatigue or Low Energy',
  confusion: 'Confusion',
  drowsiness: 'Drowsiness',
  fallingAsleep: 'Trouble Falling Asleep',
  emotional: 'More Emotional',
  irritability: 'Irritability',
  sadness: 'Sadness',
  nervous: 'Nervous or Anxious',
  severityScoreLabel: 'Symptom Severity:',
  symptomTotalsLabel: 'Symptom Total:',
  requiredError: '{0} is required',
  defaultSymptomsLabel: 'Symptoms',
  otherSymptomsLabel: 'Other Symptoms',
  notesModalTitle: '{0} Notes',
  doneButtonText: 'Done',
  selectScoreText: 'Select Score',
  addNoteButtonText: 'Add Note'
};

export default EN;
