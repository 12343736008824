import React from 'react';

const LexicalEditor = React.lazy(() => import(/* webpackChunkName: 'editor' */ './editor'));

const Editor = (props) => {
  return (
    <React.Suspense fallback={<div>Loading...</div>}>
      <LexicalEditor {...props} />
    </React.Suspense>
  );
};

export default Editor;
