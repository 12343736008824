import { UPDATE_CLINIC_PLAYERS_SEARCH } from '../actions/clinic-players-search';

const updateClinicPlayersSearchReducer = (state = {}, action = {}) => {
  const { attributes = {}, results = [] } = action;
  const { clinic_id = 0 } = attributes;
  return (clinic_id) ? { ...state, [clinic_id]: { attributes, results } } : state;
};

const clinicPlayersSearch = (state = {}, action = {}) => {
  switch (action.type) {
    case UPDATE_CLINIC_PLAYERS_SEARCH:
      return updateClinicPlayersSearchReducer(state, action);
    default:
      return state;
  }
};

export default clinicPlayersSearch;
