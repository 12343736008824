const FR = {
  addBookingLinkButtonText: 'Ajouter un lien de réservation',
  editActionText: 'Éditer',
  deleteActionText: 'Supprimer',
  clinicInputText: 'Clinique',
  clinicInputErrorText: 'Veuillez sélectionner une clinique',
  selectClinicOption: 'Sélectionnez une clinique',
  urlInputLabel: 'URL du lien de réservation',
  cancelButtonText: 'Annuler',
  submitButtonText: 'Soumettre',
  deleteButtonText: 'Supprimer',
  addBookingLinkModalTitle: 'Ajouter un lien de réservation',
  editBookingLinkModalTitle: 'Modifier le lien de réservation',
  bookingLinkModalDescription: 'L\'URL du lien de réservation doit être un lien direct vers votre page de réservation en ligne personnelle pour la clinique sélectionnée.',
  emptyViewMessage: 'Aucun lien de réservation',
  deleteBookingLinkModalTitle: 'Supprimer le lien de réservation?',
  deleteBookingLinkConfirmMessage: 'Voulez-vous vraiment supprimer ce lien de réservation?'
};

export default FR;
