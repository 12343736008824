const UPDATE_TAXES = 'UPDATE_TAXES';
const UPDATE_TAX_ITEM = 'UPDATE_TAX_ITEM';
const REMOVE_TAX_ITEM = 'REMOVE_TAX_ITEM';

const updateTaxes = (taxes) => ({
  type: UPDATE_TAXES,
  taxes
});

const updateTaxItem = (taxId, taxItem) => ({
  type: UPDATE_TAX_ITEM,
  taxId, 
  taxItem
});

const removeTaxItem = (taxId) => ({
  type: REMOVE_TAX_ITEM,
  taxId
});

export {
  updateTaxes,
  UPDATE_TAXES,
  updateTaxItem,
  UPDATE_TAX_ITEM,
  removeTaxItem,
  REMOVE_TAX_ITEM
};
