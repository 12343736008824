import React from 'react';
import classnames from 'classnames';
import Strings from './lang';

const ActiveSelector = ({ value, onSelect }) => {
  return (
    <div className="btn-group">
      <button
        type="button"
        className={classnames('btn btn-sm btn-light', { selected: value === true })}
        onClick={() => value !== true && onSelect(true)}
      >
        {Strings.activeItemLabel}
      </button>
      <button
        type="button"
        className={classnames('btn btn-sm btn-light', { selected: value === false })}
        onClick={() => value !== false && onSelect(false)}
      >
        {Strings.deactivatedItemLabel}
      </button>
    </div>
  );
};

export default ActiveSelector;
