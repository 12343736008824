/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
/* eslint-enable no-unused-vars */

import classNames from 'classnames';
import { If, Then } from 'react-if';
import { FormInputGroup } from '@/forms';
import { findFirstAddressComponentOfType } from '@/lib/google/address-parser';
import validateAddress from '@/utilities/validate-address';
import Strings from './lang';

class AddressFormInputs extends Component {
  constructor(props) {
    super(props);

    const { address = {} } = props;

    this.toggleExpanded = this.toggleExpanded.bind(this);

    const {
      street1 = '',
      street2 = '',
      city = '',
      state = '',
      postalCode = '',
      country = ''
    } = address;

    this.state = {
      expanded: true,
      street1,
      street2,
      city,
      state,
      postalCode,
      country
    };
  }

  render() {
    return (
      <div 
        className={classNames(
          'address-form-inputs', 
          this.props.className, 
          { expanded: this.state.expanded }
        )}
      >
        <FormInputGroup
          className="form-group address-search-input-group"
          labelText={Strings.addressSearchLabelText}
          labelAccessory={(
            <span className="address-toggle">
              <button type="button" className="btn-link" onClick={this.toggleExpanded}>
                <small>
                  {(this.state.expanded) ? Strings.hideFieldsLinkText : Strings.showFieldsLinkText}
                </small>
              </button>
            </span>
          )}
          inputType="address"
          inputProps={{
            className: 'form-control',
            onUpdate: this.onSearchAddress.bind(this),
            limitCountry: this.props.limitCountry
          }}
          inputValid
          required={!this.state.expanded}
        />

        <If condition={this.state.expanded || this.props.touched}>
          <Then>
            <div className="fields">

              <FormInputGroup
                className="form-group street-1-input-group"
                labelText={Strings.streetLabelText}
                inputType="text"
                inputProps={{
                  className: 'form-control',
                  value: this.state.street1,
                  onChange: this.onUpdateField.bind(this, 'street1')
                }}
                messageText={Strings.streetErrorText}
                messageClassName="alert alert-danger"
                inputValid={this.isFieldValid('street1')}
                required={this.props.required || false}
                touched={this.props.touched || false}
              />

              <FormInputGroup
                className="form-group street-2-input-group"
                inputType="text"
                inputProps={{
                  className: 'form-control',
                  value: this.state.street2,
                  onChange: this.onUpdateField.bind(this, 'street2')
                }}
                inputValid
              />

              <FormInputGroup
                className="form-group city-input-group"
                labelText={Strings.cityLabelText}
                inputType="text"
                inputProps={{
                  className: 'form-control',
                  value: this.state.city,
                  onChange: this.onUpdateField.bind(this, 'city')
                }}
                messageText={Strings.cityErrorText}
                messageClassName="alert alert-danger"
                inputValid={this.isFieldValid('city')}
                required={this.props.required || false}
                touched={this.props.touched || false}
              />

              <FormInputGroup
                className="form-group country-input-group"
                labelText={Strings.countryLabelText}
                inputType="country"
                inputProps={{
                  className: 'form-control',
                  value: this.state.country,
                  onChange: this.onUpdateSelect.bind(this, 'country'),
                  countryWhitelist: this.props.countryWhitelist
                }}
                messageText={Strings.countryErrorText}
                messageClassName="alert alert-danger"
                inputValid={this.isFieldValid('country')}
                required={this.props.required || false}
                touched={this.props.touched || false}
              />

              <FormInputGroup
                className="form-group state-input-group"
                labelText={Strings.stateLabelText}
                inputType="region"
                inputProps={{
                  className: 'form-control',
                  country: this.state.country,
                  value: this.state.state,
                  onChange: this.onUpdateSelect.bind(this, 'state')
                }}
                messageText={Strings.stateErrorText}
                messageClassName="alert alert-danger"
                inputValid={this.isFieldValid('state')}
                required={this.props.required || false}
                touched={this.props.touched || false}
              />

              <FormInputGroup
                className="form-group postal-code-input-group"
                labelText={Strings.postalCodeLabelText}
                inputType="text"
                inputProps={{
                  className: 'form-control',
                  value: this.state.postalCode,
                  onChange: this.onUpdateField.bind(this, 'postalCode')
                }}
                messageText={Strings.postalCodeErrorText}
                messageClassName="alert alert-danger"
                inputValid={this.isFieldValid('postalCode')}
                required={this.props.required || false}
                touched={this.props.touched || false}
              />
            </div>
          </Then>
        </If>

      </div>
    );
  }

  toggleExpanded(e) {
    const { expanded } = this.state;
    e.preventDefault();
    this.setState({
      expanded: !expanded
    });
  }

  onSearchAddress(results) {
    const { address_components } = results;
    if (!address_components) {
      return;
    }

    const numberComponent = findFirstAddressComponentOfType('street_number', address_components);
    const number = (numberComponent) ? numberComponent.long_name : '';

    const routeComponent = findFirstAddressComponentOfType('route', address_components);
    const route = (routeComponent) ? routeComponent.long_name : '';

    const localityComponent = findFirstAddressComponentOfType('locality', address_components);
    const city = (localityComponent) ? localityComponent.long_name : '';

    const adminAreaComponent = findFirstAddressComponentOfType('administrative_area_level_1', address_components);
    const state = (adminAreaComponent) ? adminAreaComponent.short_name : '';

    const countryComponent = findFirstAddressComponentOfType('country', address_components);
    const country = (countryComponent) ? countryComponent.short_name : '';

    const postalCodeComponent = findFirstAddressComponentOfType('postal_code', address_components);
    const postalCode = (postalCodeComponent) ? postalCodeComponent.long_name : '';

    const street1 = (number) ? `${number} ${route}` : route;
    const address = { 
      street1, 
      city, 
      state, 
      country, 
      postalCode 
    };

    this.setState({ expanded: true, ...address });
    this.onUpdate(address);
  }

  onUpdateField(field, e) {
    const { value } = e.target;
    const updated = { [field]: value };

    const {
      street1,
      street2,
      city,
      state,
      country,
      postalCode
    } = this.state;

    const address = {
      street1,
      street2,
      city,
      state,
      country,
      postalCode,
      ...updated
    };

    this.setState(updated);
    this.onUpdate(address);
  }

  onUpdateSelect(field, value) {
    const updated = { [field]: value };

    const {
      street1,
      street2,
      city,
      state,
      country,
      postalCode
    } = this.state;

    const address = {
      street1,
      street2,
      city,
      state,
      country,
      postalCode,
      ...updated
    };

    this.setState(updated);
    this.onUpdate(address);
  }

  isFieldValid(field) {
    const value = this.state[field] || '';
    return value.length > 0 || this.props.required !== true;
  }

  onUpdate(address) {
    if (typeof this.props.onUpdate === 'function') {
      this.props.onUpdate(address, validateAddress(address));
    }
  }
}

AddressFormInputs.propTypes = {
  address: PropTypes.object,
  required: PropTypes.bool,
  touched: PropTypes.bool,
  className: PropTypes.string,
  limitCountry: PropTypes.object,
  countryWhitelist: PropTypes.array,
  onUpdate: PropTypes.func
};

export default AddressFormInputs;
