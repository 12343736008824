const validImageTypes = [
  'image/jpeg',
  'image/jpg',
  'image/png'
];

const isValidImageType = (fileType) => {
  if (typeof fileType === 'string') {
    return validImageTypes.indexOf(fileType.toLowerCase()) >= 0;
  } 
  
  return false;
};

export {
  validImageTypes,
  isValidImageType
};
