const FR = {
  title: 'Créer votre mot de passe',
  passwordLabelText: 'Mot de passe',
  passwordInputPlaceholder: 'motdepasse123',
  confirmPasswordLabelText: 'Confirmer votre mot de passe',
  confirmPasswordInputPlaceholder: 'motdepasse123',
  submitButtonText: 'Accepter'
};
 
export default FR;
