import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const MAX_COLUMNS = 12;

const buildColumnizedArray = (keys, count) => {
  const safeCount = (count < 1) ? 1 : count;
  const cols = [...new Array(safeCount)];
  const split = Math.ceil(keys.length / safeCount);

  return cols.map((na, index) => {
    return keys.slice(index * split, (index * split) + split);
  });
};

const ColumnArraySplit = ({
  array = [], 
  columns = 2,
  className,
  children
}) => {
  const columnizedArray = useMemo(() => buildColumnizedArray(array, columns), [array, columns]);

  return (
    <div className={classnames('row', className)}>
      {columnizedArray.map((column, index) => (
        <div key={index} className={`col-md-${MAX_COLUMNS / (columns || 1)}`}>
          {column.map(key => children(key))}
        </div>
      ))}
    </div>
  );
};

ColumnArraySplit.propTypes = {
  array: PropTypes.array,
  columns: PropTypes.number,
  children: PropTypes.func.isRequired
};

export default ColumnArraySplit;
