const EN = {
  trialLabel: 'Trial {0}',
  unitLabel: 'cm',
  averageDistanceLabel: 'Average Distance (cm)',
  averageTimeLabel: 'Average Reaction Time (s)',
  dominantHandLabel: 'Dominant Hand',
  leftHandOption: 'Left',
  rightHandOption: 'Right',
  defaultOption: 'Select One',
  dominantHandErrorMsg: 'Please select a dominant hand',
  reactionTimeResultText: '{0} seconds'
};

export default EN;
