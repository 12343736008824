const getSessionStorageItem = (key) => {
  try {
    return sessionStorage.getItem(key);
  } catch (e) {
    return null;
  }
};

const setSessionStorageItem = (key, value) => {
  try {
    sessionStorage.setItem(key, value);
    return true;
  } catch (e) {
    return false;
  }
};

const removeSessionStorageItem = (key) => {
  try {
    sessionStorage.removeItem(key);
    return true;
  } catch (e) {
    return false;
  }
};

export {
  getSessionStorageItem,
  setSessionStorageItem,
  removeSessionStorageItem
};
