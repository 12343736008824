import {
  getSessionStorageItem,
  setSessionStorageItem,
  removeSessionStorageItem
} from './session-storage';

const REGISTRATION_SESSION_DATA = 'com.ccmi.registration.session';
const REGISTRATION_RETURNING_KEY = 'com.ccmi.registration.returning';
const REGISTRATION_EMAIL_SENT = 'com.ccmi.registeration.email';

const getRegistrationReturning = () => {
  const returning = getSessionStorageItem(REGISTRATION_RETURNING_KEY);
  return parseInt(returning, 10) === 1;
};

const setRegistrationReturning = (returning) => {
  const value = (returning) ? 1 : 0;
  return setSessionStorageItem(REGISTRATION_RETURNING_KEY, value);
};

const removeRegistrationReturning = () => removeSessionStorageItem(REGISTRATION_RETURNING_KEY);

const createRegistrationSessionData = (user = {}) => {
  const authUser = user.id;
  const currentUser = user.id;
  const authData = [{
    token: user.auth_token,
    id: user.id
  }];

  if (user.children && user.children.length > 0) {
    user.children.forEach(child => {
      authData.push({
        token: child.auth_token,
        id: child.id
      });
    });
  }

  return {
    user: currentUser,
    auth_user: authUser,
    data: authData
  };
};

const setRegistrationSession = (user = {}) => {
  const session = createRegistrationSessionData(user);
  return setRegistrationSessionData(session);
};

const setRegistrationSessionData = (session) => {
  return setSessionStorageItem(REGISTRATION_SESSION_DATA, JSON.stringify(session));
};

const getRegistrationSessionData = () => {
  return JSON.parse(getSessionStorageItem(REGISTRATION_SESSION_DATA) || '{}');
};

const removeRegistrationSessionData = () => {
  return removeSessionStorageItem(REGISTRATION_SESSION_DATA);
};

const getRegistrationCurrentUser = () => {
  const sessions = getRegistrationSessionData();
  return sessions.user;
};

const getRegistrationCurrentUserSession = () => {
  const sessions = getRegistrationSessionData();
  if (sessions.user && sessions.data) {
    return sessions.data.find(session => session.id === sessions.user);
  }

  return null;
};

const getCurrentRegistrationSession = () => {
  const sessions = getRegistrationSessionData();
  const currentId = sessions.auth_user;
  if (currentId) {
    return sessions.data.find(session => session.id === currentId) || null;
  }

  return null;
};

const addRegistrationSession = (user = {}, switchContext = false) => {
  const sessions = getRegistrationSessionData();
  if (sessions.data) {
    sessions.data.push({
      token: user.auth_token,
      id: user.id
    });

    if (switchContext) {
      sessions.auth_user = user.id;
    }
  }

  return setRegistrationSessionData(sessions);
};

const switchRegistrationAuthContext = (authUser) => {
  const sessions = getRegistrationSessionData();
  sessions.auth_user = authUser;
  return setRegistrationSessionData(sessions);
};

const setRegistrationEmailSent = (setTo = true) => {
  const status = setTo ? 1 : 0;
  return setSessionStorageItem(REGISTRATION_EMAIL_SENT, status);
};

const getRegistrationEmailSent = () => {
  const status = getSessionStorageItem(REGISTRATION_EMAIL_SENT);
  return status && status === 1;
};

const removeRegistrationEmailSent = () => removeSessionStorageItem(REGISTRATION_EMAIL_SENT);

const removeAllRegistrationSessionData = () => {
  removeRegistrationReturning();
  removeRegistrationSessionData();
  removeRegistrationEmailSent();
};

export {
  getRegistrationReturning,
  setRegistrationReturning,
  removeRegistrationReturning,
  setRegistrationSession,
  getCurrentRegistrationSession,
  addRegistrationSession,
  removeRegistrationSessionData,
  switchRegistrationAuthContext,
  getRegistrationCurrentUser,
  getRegistrationCurrentUserSession,
  setRegistrationEmailSent,
  getRegistrationEmailSent,
  removeRegistrationEmailSent,
  removeAllRegistrationSessionData
};
