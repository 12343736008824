const BaselineTestPaths = {
  index: {
    route: 'baseline',
    link: '/baseline'
  },
  start: {
    route: 'start',
    link: '/baseline/start'
  },
  continue: {
    route: 'continue',
    link: '/baseline/continue'
  },
  checkInPatient: {
    route: 'start/check-in/:userId',
    link: '/baseline/start/check-in/:userId'
  }
};

export default BaselineTestPaths;
