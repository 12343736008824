const FR = {
  nameHeadingText: 'Nom',
  creditsHeadingText: 'Crédits disponibles',
  balanceHeadingText: 'Solde de la clinique',
  activeText: 'Actif ',
  inactiveText: 'Inactif',
  statusHeadingText: 'Statut',
  servicesHeadingText: 'Services'
};
 
export default FR;
