const EN = {
  archiveModalTitle: 'Delete Baseline Test',
  archiveModalMessage: 'Deleting this baseline test will remove the test from the patients baseline history. If you wish to continue, please provide the reason why you are deleting this baseline test.',
  unarchiveModalTitle: 'Restore Baseline Test',
  unarchiveModalMessage: 'Restoring this baseline test will make the test visible on the patient\'s Baseline History. Are you sure you want to restore this baseline test?',
  reasonLabel: 'Reason',
  reasonErrorMessage: 'Please provide the reason that you are deleting this baseline',
  confirmUnarchiveButton: 'Restore',
  confirmArchiveButton: 'Delete',
  cancelButton: 'Cancel'
};

export default EN;
