import Strings from './lang';

const ovsResultTypeString = {
  normal: Strings.resultNormal,
  orthostatic_hypotension: Strings.resultOrthostaticHypotension,
  orthostatic_tachycardia: Strings.resultOrthostaticTachycardia,
  borderline_orthostatic_tachycardia: Strings.resultBorderlineOrthostaticTachycardia,
  hypovolemia: Strings.resultHypovolemia
};

export {
  ovsResultTypeString
};
