import { createSelector } from 'reselect';

const areIdsEqual = (id1, id2) => String(id1) === String(id2);

const getSoapNotes = (state) => state.soapNotes || {};
const getUserId = (_, { userId }) => userId;
const getInjuryId = (_, { injuryId }) => injuryId;
const getSoapNoteId = (_, { soapId }) => soapId;

const getUserSoapNotes = createSelector(
  [getSoapNotes, getUserId],
  (soapNotes, userId) => {
    return soapNotes[userId] || {};
  }
);

const getInjurySoapNotes = createSelector(
  [getUserSoapNotes, getInjuryId],
  (soapNotes, injuryId) => {
    return Object.values(soapNotes).filter(soap => (
      areIdsEqual(soap.injury_id, injuryId) && !soap.archived_at
    ));
  }
);

const getIncompleteSoapNotes = createSelector(
  [getInjurySoapNotes],
  (soapNotes) => {
    return soapNotes.filter(soap => !soap.complete);
  }
);

const getInjuryArchivedSoapNotes = createSelector(
  [getUserSoapNotes, getInjuryId],
  (soapNotes, injuryId) => {
    return Object.values(soapNotes).filter(soap => (
      areIdsEqual(soap.injury_id, injuryId) && !!soap.archived_at
    ));
  }
);

const getUserSoapNote = createSelector(
  [getUserSoapNotes, getSoapNoteId],
  (soapNotes, soapId) => {
    return soapNotes[soapId] || {};
  }
);

const getPreviousSoapNotes = createSelector(
  [getInjurySoapNotes, getSoapNoteId],
  (soaps, omitted) => {
    if (omitted) {
      return soaps.filter(soap => !areIdsEqual(soap.id, omitted));
    }
    
    return soaps;
  }
);

export default {
  getUserSoapNote,
  getUserSoapNotes,
  getInjurySoapNotes,
  getInjuryArchivedSoapNotes,
  getIncompleteSoapNotes,
  getPreviousSoapNotes
};
