import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Strings from './lang';

const BlackhawksPlyometricsStageDisplay = ({
  className,
  stageTitle,
  directions,
  heartRate,
  symptoms,
  rest
}) => (
  <div className={classNames('blackhawks-plyometrics-stage', className)}>
    <div className="row">
      <div className="col-md-12">
        <h3 className="bold">{stageTitle}</h3>

        <ul className="list-unstyled directions">
          {directions.map((direction, index) => (
            <li key={index}>{direction}</li>
          ))}
        </ul>

        <div className="row">
          <div className="col-md-12">
            <strong>{Strings.heartRateLabelText}</strong> <span>{heartRate}</span>
          </div>
          <div className="col-md-12">
            <strong>{Strings.symptomsLabelText}</strong> <span>{symptoms}</span>
          </div>
        </div>

        <ul className="list-unstyled rest-direction">
          <li><strong>{rest}</strong></li>
        </ul>

      </div>
    </div>
  </div>
);

BlackhawksPlyometricsStageDisplay.propTypes = {
  className: PropTypes.string,
  stageTitle: PropTypes.string.isRequired,
  directions: PropTypes.arrayOf(PropTypes.node).isRequired,
  rest: PropTypes.string.isRequired,
  heartRate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  symptoms: PropTypes.string.isRequired
};

export default BlackhawksPlyometricsStageDisplay;
