import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icon from '../icon';

const SHOW_ANIMATION_DURATION = 100;
const HIDE_ANIMATION_DURATION = 200;

/**
 * OPTIONS:
 * dismissable: if true, alert can be dismissed by clicking on banner or on close button.
 * autoDismiss: Time that the alert will show for (in milliseconds). 
 *              If 0, alert will be persistant unless dismissed by user
 *              (If dismissable is true)
 * message: Message to display in alert
 */

const defaultOptions = {
  dismissable: true,
  autoDismiss: 0,
  centered: false,
  message: ''
};

const getIconType = (type) => {
  switch (type) {
    case 'success':
      return 'circle-check';
    case 'error':
      return 'circle-exclamation';
    case 'warning':
      return 'triangle-exclamation';
    case 'info':
    default:
      return 'circle-info';
  }
};

class AlertBanner extends Component {
  constructor(props) {
    super(props);

    const { type = 'info', options = {} } = props;
    /** handle to the timeout for autoDismiss options */
    this.timeout = null;
    this.dismiss = this.dismiss.bind(this);

    this.state = {
      type,
      options: { ...defaultOptions, ...options },
      visible: false
    };
  }

  componentDidMount() {
    const { options } = this.state;

    if (options.autoDismiss) {
      this.timeout = setTimeout(() => this.dismiss(), options.autoDismiss);
    }

    /** timeout for animation */
    setTimeout(() => {
      this.setState({
        visible: true
      });
    }, SHOW_ANIMATION_DURATION);
  }

  render() {
    const { type, options } = this.state;
    return (
      <div
        role="alert"
        className={classnames('alert-banner', type, options.className, {
          visible: this.state.visible,
          clickable: options.dismissable,
          centered: options.centered
        })}
        onClick={options.dismissable && this.dismiss}
      >
        <div className="alert-banner-message">
          <Icon name={getIconType(this.state.type)} /> {options.message}
        </div>
        {options.dismissable && (
          <button type="button" className="close" onClick={this.dismiss}>
            <Icon name="xmark" />
          </button>
        )}
      </div>
    );
  }

  dismiss() {
    const { options } = this.state;

    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }

    this.setState({
      visible: false
    }, () => {
      setTimeout(() => {
        this.props.onDismiss(options.data);
      }, HIDE_ANIMATION_DURATION);
    });
  }
}

AlertBanner.propTypes = {
  type: PropTypes.oneOf([
    'success',
    'info',
    'warning',
    'error'
  ]).isRequired,
  options: PropTypes.object,
  onDismiss: PropTypes.func.isRequired
};

export default AlertBanner;
