import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';
import refForwardable from '@/utilities/ref-forwardable';

const RoutableTabs = ({
  tabs = [], 
  disableContextMenu = false,
  forwardRef,
  children,
  className
}) => {
  const linkProps = {};

  if (disableContextMenu) {
    linkProps.onContextMenu = (e) => e.preventDefault();
  }

  return (
    <div ref={forwardRef} className={classnames('tabs', className)}>
      <div className="tabs-bar">
        {tabs.map((tab, index) => {
          return (
            <NavLink 
              key={index}
              to={tab.route} 
              className={({ isActive }) => classnames('tab-button', tab.tabClassName, { selected: isActive })} 
              {...linkProps}
            >
              <span className="tab-label">{tab.label}</span>
            </NavLink>
          );
        })}
      </div>
      <div className="tabs-content">
        {children}
      </div>
    </div>
  );
};

RoutableTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      route: PropTypes.string,
      tabClassName: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
      ])
    })
  ).isRequired
};

export default refForwardable(RoutableTabs);
