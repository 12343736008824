import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormComponent, RadioInputGroup, NumberInputGroup } from '@/forms';
import Strings from './lang';

class BlackhawksStartForm extends Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      testType: '',
      bikeType: '',
      submitted: false,
      maxTension: ''
    };
  }

  render() {
    const { testType, bikeType } = this.state;

    return (
      <FormComponent className="blackhawks-start-form" onSubmit={this.onSubmit}>

        <div className="row">
          <div className="col-md-6">

            <h2 className="bold">{Strings.testTypeHeadingText}</h2>

            <RadioInputGroup
              className="form-group test-type-input-group"
              labelAfterInput
              radioLabels={[Strings.fullTestLabelText, Strings.modifiedTestLabelText]}
              radioValues={['full', 'modified']}
              initialValue={testType}
              inputProps={{
                className: 'form-control',
                name: 'test-type'
              }}
              inputValid={testType.length > 0}
              messageText={Strings.testTypeErrorText}
              messageClassName="alert alert-danger"
              onUpdate={value => {
                this.setState({ testType: value });
              }}
              touched={this.state.submitted}
            />

          </div>
          <div className="col-md-6">

            <h2 className="bold">{Strings.bikeTypeHeadingText}</h2>

            <RadioInputGroup
              className="form-group bike-type-input-group"
              labelAfterInput
              radioLabels={[
                Strings.monarkBikeLabelText, 
                Strings.lifecycleBikeLabelText, 
                Strings.customBikeLabel
              ]}
              radioValues={['monark', 'lifecycle', 'custom']}
              initialValue={bikeType}
              inputProps={{
                className: 'form-control',
                name: 'bike-type'
              }}
              inputValid={bikeType.length > 0}
              messageText={Strings.bikeTypeErrorText}
              messageClassName="alert alert-danger"
              onUpdate={value => {
                this.setState({ bikeType: value });
              }}
              touched={this.state.submitted}
            />

            {bikeType === 'custom' && (
              <NumberInputGroup 
                className="form-group"
                labelText={Strings.customBikeMaxTensionLabel}
                inputProps={{
                  min: 1,
                  step: 1,
                  value: this.state.maxTension,
                  className: 'form-control'
                }}
                messageText={Strings.customBikeMaxTensionErrorMessage}
                messageClassName="alert alert-danger"
                required={bikeType === 'custom'}
                onUpdate={(value) => {
                  this.setState({ maxTension: parseInt(value, 10) });
                }}
                touched={this.state.submitted}
              />
            )}
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="form-footer">
              <button type="submit" className="btn btn-primary">
                {Strings.startButtonText}
              </button>
            </div>
          </div>
        </div>

      </FormComponent>
    );
  }

  isValid() {
    return this.state.testType.length > 0
      && this.state.bikeType.length > 0
      && (this.state.bikeType !== 'custom' || this.state.maxTension > 0);
  }

  onSubmit() {
    this.setState({ submitted: true });

    if (!this.isValid()) {
      return;
    }

    const { testType = '', bikeType = '', maxTension } = this.state;

    const attributes = {
      testType,
      bikeType
    };

    if (bikeType === 'custom') {
      attributes.maxTension = maxTension;
    }

    this.props.onSubmit(attributes);
  }
}

BlackhawksStartForm.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default BlackhawksStartForm;
