import React from 'react';
import classnames from 'classnames';

const DrawerContent = ({
  position,
  className,
  fade = false,
  children
}) => (
  <div 
    className={
      classnames(
        'drawer-content', 
        className, 
        position,
        { fade }
      )
    }
  >
    {children}
  </div>
);

export default DrawerContent;
