const UPDATE_IDT_CLAIM = 'UPDATE_IDT_CLAIM';
const UPDATE_IDT_CLAIM_APPOINTMENT = 'UPDATE_IDT_CLAIM_APPOINTMENT';
const ADD_IDT_CLAIM_APPOINTMENT = 'ADD_IDT_CLAIM_APPOINTMENT';
const ADD_IDT_CLAIM_INITIAL_REPORT = 'ADD_IDT_CLAIM_INITIAL_REPORT';
const ADD_IDT_CLAIM_BLOCK_REPORT = 'ADD_IDT_CLAIM_BLOCK_REPORT';
const SET_CLAIM_REPORT_COMPLETED = 'SET_CLAIM_REPORT_COMPLETED';
const SET_CLAIM_REPORT_APPROVED = 'SET_CLAIM_REPORT_APPROVED';
const ADD_CLAIM_DOCUMENT = 'ADD_CLAIM_DOCUMENT';

const updateIdtClaim = (claimId, claim) => ({
  type: UPDATE_IDT_CLAIM,
  claimId,
  claim
});

const updateIdtClaimAppointment = (claimId, blockId, appointment) => ({
  type: UPDATE_IDT_CLAIM_APPOINTMENT,
  claimId,
  blockId,
  appointment
});

const addIdtClaimAppointment = (claimId, blockId, appointment) => ({
  type: ADD_IDT_CLAIM_APPOINTMENT,
  claimId,
  blockId,
  appointment
});

const addIdtInitialReport = (claimId, report) => ({
  type: ADD_IDT_CLAIM_INITIAL_REPORT,
  claimId,
  report
});

const addIdtBlockReport = (claimId, blockId, report) => ({
  type: ADD_IDT_CLAIM_BLOCK_REPORT,
  claimId,
  blockId,
  report
});

const setClaimReportCompleted = (claimId, blockId) => ({
  type: SET_CLAIM_REPORT_COMPLETED,
  claimId,
  blockId
});

const setClaimReportApproved = (claimId, blockId) => ({
  type: SET_CLAIM_REPORT_APPROVED,
  claimId,
  blockId
});

const addClaimDocument = (claimId, document) => ({
  type: ADD_CLAIM_DOCUMENT,
  claimId,
  document
});

export {
  UPDATE_IDT_CLAIM,
  updateIdtClaim,
  UPDATE_IDT_CLAIM_APPOINTMENT,
  updateIdtClaimAppointment,
  ADD_IDT_CLAIM_APPOINTMENT,
  addIdtClaimAppointment,
  ADD_IDT_CLAIM_INITIAL_REPORT,
  addIdtInitialReport,
  ADD_IDT_CLAIM_BLOCK_REPORT,
  addIdtBlockReport,
  SET_CLAIM_REPORT_COMPLETED,
  setClaimReportCompleted,
  SET_CLAIM_REPORT_APPROVED,
  setClaimReportApproved,
  ADD_CLAIM_DOCUMENT,
  addClaimDocument
};
