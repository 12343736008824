const EN = {
  redFlagsHeader: 'Red Flags',
  redFlagsReported: 'Reported Red Flags',
  redFlagsInfoText: 'Does the patient have any of the following that would warrant an emergency referral',
  none: 'None',
  severe_headache: 'Severe or Worsening Headache',
  seizures: 'Seizures',
  focal_neurological_signs: 'Focal Neurologic Signs',
  visibly_drowsy: 'Looks Very Drowsy/Can\'t Be Awakened',
  recurring_vomiting: 'Vomiting More than 2x Since Injury',
  slurred_speech: 'Slurred Speech',
  cant_recognize_people_places: 'Can\'t Recognize People or Places',
  increasing_confusion_irritability: 'Increasing Confusion or Irritability',
  weakness: 'Weakness or Numbness in Arm/Legs',
  fail_ct_rules: 'Failure of Canadian CT Head Rules',
  behaviour_change: 'Unusual Behaviour Change',
  fail_cervical_spine_rules: 'Failure of Canadian Cervical Spine Rules',
  decreasing_conscious: 'Decreasing Consciousness',
  sixty_plus_years: 'Patient Older than 60',
  intoxicated: 'Intoxicated at Time of Injury',
  skull_fracture: 'Signs of Skull Fracture',
  glascow_coma: 'Glascow Coma Scale Less Than 15 at More Than 2 Hours After Injury',
  errorMessage: 'Must select either none or one of the options'
};

export default EN;
