const FR = {
  title: 'Antécédents de blessures',
  injuryHeadingText: 'Blessure {0}\u2014 {1}',
  injuryStatusLabelText: 'Statut :',
  injuryStateOngoingLabelText: 'En récupération depuis {0} jour(s)',
  injuryStateDischargedLabelText: 'Dispensé le {0} (temps total de récupération : {1} jour(s))',
  injuryStateLostLabelInfo: 'Omission de suivi à partir du {0} ({1} - total de jours de récupération inconnu)',
  injuryStateLostLabelText: 'Motivation de l’omission de suivi : {0}',
  injuryStateLostLabelDefault: 'Perdu au suivi (récupération totale des jours inconnus)',
  injuryDurationLabelText: 'Durée de la blessure :',
  soapNotesLinkText: 'Remarques de SOAP',
  clinicalNotesLinkText: 'Remarques cliniques',
  specialistNotesLinkText: 'Notes du médecin/spécialiste',
  symptomUpdatesLinkText: 'Symptômes donnés par le joueur',
  testLinkText: '{0} {1}\u2014 {2}',
  assessmentTestName: 'Évaluation de la blessure',
  blackhawksTestName: 'Test des Blackhawks',
  buffaloTestName: 'Test de Buffalo',
  ovsTestName: 'Test orthostatique des signes vitaux',
  vomsTestName: 'Test vestibulo-oculaire moteur (VOMS)',
  baselineTestName: 'Test post-blessure',
  emptyMessageText: 'Ce patient n’a rapporté aucune blessure',
  numberOfIncompleteNotes: '{0} remarque(s) incomplète(s)',
  day: '1 jour',
  days: '{0} jours',
  inCompleteAssessmentText: 'Évaluation terminée',
  continueAssessmentText: 'Continuer l’évaluation',
  incompleteAssessmentModalTitle: 'Évaluation préliminaire incomplète',
  okayButtonText: 'OK',
  incompleteAssessmentModalInfo: 'L’évaluation préliminaire de cette blessure n’a pas été complétée Veuillez compléter l’évaluation avant d’écrire vos remarques de SOAP',
  totalConcussionLabel: 'Total de commotions',
  totalConcussionsDescription: '{0} rapportée(s) précédemment, {1} avec CCMI',
  progressReportsLinkText: 'Rapports de progression',
  newInjuryLinkText: 'Nouvelle blessure',
  referralButtonText: 'Référer le patient',
  viewReferralsLink: 'Voir les références',
  referralSuccessMessage: 'Référence créée avec succès',
  enableReferralMessage: 'Effectuer l\'évaluation initiale des blessures afin de permettre l\'aiguillage des patients'
};
 
export default FR;
