import React, { useCallback, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import BatteryDetailsHeader from './details-header';
import DanaBatteryDetails from './battery-details';
import Legend from './legend';
import Activity from '../../activity';
import { getDanaBattery } from '@/redux/thunks/dana';

const BatteryDetails = ({
  batteryId,
  userId,
  battery,
  getBattery,
  onClose
}) => {
  const [loading, setLoading] = useState(!battery);
  const [, setError] = useState(null);
  const { tests = [] } = battery || {};

  const loadBattery = useCallback(() => {
    if (!userId || !batteryId) return;

    setLoading(true);
    setError(null);

    getBattery(userId, batteryId).then(() => {
      setLoading(false);
    }).catch(error => {
      setLoading(false);
      setError(error.message);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [batteryId, userId]);

  useEffect(() => {
    if (!battery) {
      loadBattery();
    }
  }, [battery, loadBattery]);

  return (
    <Activity active={loading}>
      <div className="dana-battery-details">
        <BatteryDetailsHeader
          battery={battery}
          onClose={onClose}
        />
        <DanaBatteryDetails tests={tests} />
        <Legend />
      </div>
    </Activity>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { danaBatteries } = state;
  const userBatteries = danaBatteries[ownProps.userId] || {};
  const battery = userBatteries[ownProps.batteryId];

  return { battery };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBattery: (userId, batteryId) => {
      return dispatch(getDanaBattery(userId, batteryId));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BatteryDetails);
