const UPDATE_CLINIC_PLAYERS_SEARCH = 'UPDATE_CLINIC_PLAYERS_SEARCH';

const updateClinicPlayersSearch = (attributes, results = []) => ({
  type: UPDATE_CLINIC_PLAYERS_SEARCH,
  attributes,
  results
});

export {
  UPDATE_CLINIC_PLAYERS_SEARCH,
  updateClinicPlayersSearch
};
