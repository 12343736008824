const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
const UPDATE_PRODUCTS = 'UPDATE_PRODUCTS';
const REMOVE_PRODUCT = 'REMOVE_PRODUCT';

const updateProduct = (product) => ({
  type: UPDATE_PRODUCT,
  product
});

const updateProducts = (products) => ({
  type: UPDATE_PRODUCTS,
  products
});

const removeProduct = (productId) => ({
  type: REMOVE_PRODUCT,
  productId
});

export {
  UPDATE_PRODUCT,
  updateProduct,
  UPDATE_PRODUCTS,
  updateProducts,
  REMOVE_PRODUCT,
  removeProduct
};
