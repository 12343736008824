import React from 'react';
import { getPsychOutcomeDescription } from '../helpers';
import DanaLabels from '../dana-labels';

const TestValue = ({
  name,
  color,
  dataKey,
  payload
}) => {
  const { [dataKey]: value, iteration = 1 } = payload;
  const description = getPsychOutcomeDescription(name, value);

  return (
    <div key={name} style={{ color }}>
      {name}{iteration > 1 ? ` (${iteration})` : ''}: {value} {description ? ` (${description})` : null}
    </div>
  );
};

const TestTooltip = ({ 
  active, 
  payload: payloads, 
  label 
}) => {
  if (active && payloads && payloads.length) {
    const test = payloads[0].payload;

    return (
      <div className="dana-test-chart-tooltip">
        <div className="bold">{label}</div>
        <DanaLabels battery={test} />
        {payloads.map((payload, index) => (
          <TestValue key={`${payload.name}:${index}`} {...payload} />
        ))}
      </div>
    );
  }

  return null;
};

export default TestTooltip;
