import React from 'react';
import PropTypes from 'prop-types';
import { PatientName } from '../patient-meta';

const PlayerBadge = ({
  user = {}, 
  clinicId = 0, 
  className = ''
}) => (
  <div className={className}>
    <PatientName patient={user} clinicId={clinicId} />
    <span>{` \u2013 ${user.bag_tag || user.account}`}</span>
  </div>
);

PlayerBadge.propTypes = {
  user: PropTypes.object,
  clinicId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired
};

export default PlayerBadge;
