const randomNumber = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;
const randomNumberArray = (length, min, max) => [...new Array(length)].map(() => {
  return randomNumber(min, max);
});

const randomUniqueNumberArray = (length, min, max, exclude = []) => {
  const arr = [];

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < length; ++i) {
    const fullArr = [...exclude, ...arr];

    let number = randomNumber(min, max);
    while (fullArr.includes(number)) {
      number = randomNumber(min, max);
    }

    arr.push(number);
  }

  return arr;
};

export {
  randomNumber,
  randomNumberArray,
  randomUniqueNumberArray
};
