const FR = {
  signInTitle: 'Connexion',
  title: 'Enregistrer',
  personalAccountTitle: 'Compte Personnel',
  guardianAccountTitle: 'Compte Familial',
  reTitle: 'Réenregistrer',
  emailLabelText: 'Courriel :',
  emailInputPlaceholder: 'nom@exemple.com',
  passwordLabelText: 'Mot de passe',
  passwordErrorText: 'Les mots de passe doivent avoir au moins 6 caractères.',
  passwordInputPlaceholder: 'motdepasse123',
  confirmPasswordLabelText: 'Confirmez le mot de passe :',
  confirmPasswordInputPlaceholder: 'motdepasse123',
  termsLabelText: 'J’accepte la Politique de confidentialité de CCMI',
  termsErrorText: 'Vous devez accepter la Politique de confidentialité de CCMI pour vous enregistrer.',
  reTermsErrorText: 'Vous devez accepter la Politique de confidentialité de CCMI pour vous réenregistrer.',
  scrollMessageText: 'Veuillez lire la Politique de confidentialité de CCMI jusqu’à la fin pour rendre la case à cocher disponible.',
  returningMessageText: 'Veuillez vous connecter avec votre compte de Complete Concussion Management existant pour vous enregistrer.',
  reReturningMessageText: 'Veuillez vous connecter avec votre compte de Complete Concussion Management existant pour vous réenregistrer.',
  resetLinkText: 'Oublié votre mot de passe ?',
  submitButtonText: 'Enregistrer',
  guardianCheckboxText: 'Je suis le parent/tuteur et je veux enregistrer mon enfant.',
  reSubmitButtonText: 'Réenregistrer',
  guardianModalTitle: 'Êtes-vous un parent ou un tuteur ?',
  noButtonText: 'Non',
  yesButtonText: 'Oui',
  guardianModalMessage: 'Êtes-vous un parent ou un tuteur qui enregistre son enfant/sa personne à charge ? Si oui, cochez Oui. Si non, cochez Non.',
  emailTakenErrorTitle: 'Email {0} déjà pris',
  emailTakenErrorMessage: 'Pour mettre à jour vos informations ou ajouter des membres de votre famille, veuillez vous connecter à votre compte.',
  signIntoAccountButtonText: 'Connectez-vous à mon compte'
};
 
export default FR;
