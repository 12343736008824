const getVOMSTestState = (props) => {
  const { test = {} } = props;

  const {
    baseline_symptoms = {},
    smooth_pursuits = {},
    saccades_horizontal = {},
    saccades_vertical = {},
    vor_horizontal = {},
    vor_vertical = {},
    visual_motor_sensitivity_test = {},
    accomodation_left = {},
    accomodation_right = {},
    convergence = {}
  } = test;

  return {
    symptoms: {
      baseline: { ...defaultSymptomState, ...baseline_symptoms },
      smoothPursuits: { ...defaultSymptomState, ...smooth_pursuits },
      saccadesHorizontal: { ...defaultSymptomState, ...saccades_horizontal },
      saccadesVertical: { ...defaultSymptomState, ...saccades_vertical },
      vorHorizontal: { ...defaultSymptomState, ...vor_horizontal },
      vorVertical: { ...defaultSymptomState, ...vor_vertical },
      visualMotorSensitivity: { ...defaultSymptomState, ...visual_motor_sensitivity_test },
      accomodationLeft: { ...defaultSymptomState, ...accomodation_left },
      accomodationRight: { ...defaultSymptomState, ...accomodation_right },
      convergence: { ...defaultSymptomState, ...convergence }
    }
  };
};

const defaultSymptomState = {
  not_tested: false,
  headache: 0,
  dizziness: 0,
  nausea: 0,
  fogginess: 0,
  distance: 0,
  abnormality: '',
  comments: ''
};

const symptomHasDistance = (symptom) => {
  return [
    'accomodationLeft',
    'accomodationRight',
    'convergence'
  ].includes(symptom);
};

const symptomDistanceValue = (distance) => {
  /**
   * If distance is not 0 and not present (null/undefined) then default to empty string.
   * This makes it so that empty strings and zero numbers are acceptable values.
   */
  return (distance !== 0 && !distance) ? '' : distance;
};

const symptomHelpDocuments = {
  baseline: 'voms-baseline',
  smoothPursuits: 'voms-smooth-pursuits',
  saccadesHorizontal: 'voms-saccades-horizontal',
  saccadesVertical: 'voms-saccades-vertical',
  vorHorizontal: 'voms-vor-horizontal',
  vorVertical: 'voms-vor-vertical',
  visualMotorSensitivity: 'voms-vms',
  accomodationLeft: 'voms-accomodation-left',
  accomodationRight: 'voms-accomodation-right',
  convergence: 'voms-convergence'
};

export {
  getVOMSTestState,
  symptomHasDistance,
  symptomDistanceValue,
  symptomHelpDocuments
};
