const FR = {
  football_offensiveguard: 'Garde offensif',
  football_offensivetackle: 'Plaqueur offensif',
  football_quarterback: 'Quart arrière',
  football_runningback: 'Porteur de ballon',
  football_widereceiver: 'Ailier éloigné',
  football_tightend: 'Ailier rapproché',
  football_defensivetackle: 'Plaqueur défensif',
  football_defensiveend: 'Ailier défensif',
  football_middlelinebacker: 'Secondeur de centre',
  football_outsidelinebacker: 'Secondeur extérieur',
  football_cornerback: 'Demi de coin',
  football_safety: 'Maraudeur',
  football_nickleback: 'Nickelback',
  football_dimeback: 'Dimeback',
  football_kicker: 'Botteur',
  football_holder: 'Teneur',
  football_longsnapper: 'Spécialiste des longues remises',
  football_punter: 'Botteur de dégagement',
  football_puntreturner: 'Retourneur',
  football_kickreturner: 'Retourneur de botté',
  football_upback: 'Protecteur de retourneur',
  football_gunner: 'Gunner',
  hockey_goalie: 'Gardien de but',
  hockey_defensemen: 'Défenseur',
  hockey_rightwing: 'Aile droite',
  hockey_leftwing: 'Aile gauche',
  hockey_center: 'Centre',
  soccer_keeper: 'Gardien',
  soccer_defence: 'Défense',
  soccer_midfield: 'Milieu de terrain',
  soccer_forward: 'Avant',
  soccer_center: 'Centre',
  baseball_firstbase: 'First Baseman',
  baseball_secondbase: 'Second Baseman',
  baseball_thirdbase: 'Third Baseman',
  baseball_shortstop: 'Short Stop',
  baseball_catcher: 'Catcher',
  baseball_pitcher: 'Pitcher',
  baseball_leftfield: 'Left Fielder',
  baseball_rightfield: 'Right Fielder',
  baseball_centerfield: 'Center Fielder',
  basketball_pointguard: 'Point Guard',
  basketball_shootingguard: 'Shooting Guard',
  basketball_smallforward: 'Small Forward',
  basketball_center: 'Center',
  basketball_powerforward: 'Power Forward',
  aussierules_leftbackpocket: 'Left Back Pocket',
  aussierules_fullback: 'Full Back',
  aussierules_rightbackpocket: 'Right Back Pocket',
  aussierules_lefthalfback: 'Left Half Back Flank',
  aussierules_centerhalfback: 'Center Half Back',
  aussierules_righthalfback: 'Right Half Back Flank',
  aussierules_leftwing: 'Left Wing',
  aussierules_center: 'Center',
  aussierules_rightwing: 'Right Wing',
  aussierules_lefthalfforward: 'Left Half Forward Flank',
  aussierules_centerhalfforward: 'Center Half Forward',
  aussierules_righthalfforward: 'Right Half Forward Flank',
  aussierules_rightforwardpocket: 'Right Forward Pocket',
  aussierules_fullforward: 'Full Forward',
  aussierules_leftfowardpocket: 'Left Forward Pocket',
  aussierules_ruckman: 'Ruckman',
  aussierules_ruckrover: 'Ruck Rover',
  aussierules_rover: 'Rover',
  fieldlacrosse_attackmen: 'Attackman',
  fieldlacrosse_midfielder: 'Midfielder',
  fieldlacrosse_defensemen: 'Defenseman',
  fieldlacrosse_goalie: 'Goalie',
  boxlacrosse_creasemen: 'Creasemen',
  boxlacrosse_cornermen: 'Cornermen',
  boxlacrosse_pointmen: 'Pointmen',
  boxlacrosse_goalie: 'Goalie',
  rugby_loosehead: 'Loose-Head Prop',
  rugby_hooker: 'Hooker',
  rugby_tighthead: 'Tight-Head Prop',
  rugby_lock: 'Lock',
  rugby_blindsideflank: 'Blind-Side Flank',
  rugby_opensideflank: 'Open-Side Flank',
  rugby_numbereight: 'Number 8',
  rugby_scrumhalf: 'Scrum Half',
  rugby_flyhalf: 'Fly Half',
  rugby_insidecenter: 'Inside Center',
  rugby_outsidecenter: 'Outside Center',
  rugby_rightwing: 'Right Wing',
  rugby_leftwing: 'Left Wing',
  rugby_fullback: 'Full Back',
  fieldhockey_fullback: 'Full Back',
  fieldhockey_halfback: 'Half Back',
  fieldhockey_sweeper: 'Sweeper',
  fieldhockey_goalie: 'Goalie',
  fieldhockey_midfield: 'Midfield/Inner',
};
 
export default FR;
