const FR = {
  scrollMessageText: 'Veuillez lire la Politique de confidentialité de CCMI jusqu’à la fin pour rendre la case à cocher disponible.',
  consentErrorMessage: 'Cet élément de consentement est requis pour participer au programme CCMI. Pour plus d\'informations sur cet article, veuillez cliquer sur le bouton ci-dessous.',
  playerConsentHeadingText: 'À la lumière de ce qui précède, CCMI demande votre consentement à participer au programme et à permettre à CCMI de recueillir, d’utiliser et de divulguer vos renseignements personnels sur la santé aux fins du programme.',
  playerConsentText_1: 'Je comprends qu’en m’inscrivant au programme, je vais participer à un réseau de renseignements sur la santé, dans lequel CCMI reçoit et détient mes renseignements personnels sur la santé afin de les rendre accessibles à ma clinique primaire et (avec ma permission) à d’autres Cliniques Participantes.',
  playerConsentText_2: 'J’autorise CCMI à accorder à toute clinique participante l’accès à mon dossier dans le programme si je fournis à cette clinique mon numéro de carte d’identité de commotion ou mon Numéro de Compte, et j’y consens.',
  playerConsentText_3: 'J’autorise ces cliniques participantes à divulguer mes renseignements personnels sur la santé à CCMI aux fins énoncées dans le présent avis et consentement, et j’y consens.',
  playerConsentText_4: 'Je consens à ce CCMI anonymise mes renseignements personnels afin qu’ils soient utilisés à des fins de recherche.',
  playerConsentMoreInfoButtonText: 'Pourquoi est-ce nécessaire?'
};
 
export default FR;
