const EN = {
  registeredPatientsBorderTitle: 'Registered Patients',
  newPatientsBorderTitle: 'New Patients',
  registeredPatientInfoText: 'If the patient has previously registered for CCMI at any time, search for them below:',
  newPatientsInfoText: 'If this is a first time patient, have them register through <strong>{0}</strong> or download the <strong>Concussion Tracker</strong> app through the App Store or Google Play store.',
  forgotAccountLinkText: 'Forgot Account Number?',
  searchResultsHeadingText: 'Search Results',
  searchResultsMessageText: 'Multiple users match your search results. Please choose one:',
  searchResultOngoingInjuryText: 'Ineligible - Current Ongoing Injury'
};

export default EN;
