const FR = {
  version1: 'Version 1',
  version2: 'Version 2',
  card: 'Carte no {0}',
  reset: 'Recommencer',
  restartCard: 'Réinitialiser la carte',
  totalTimeLabel: 'Total de temps :',
  totalErrorsLabel: 'Total d\'erreurs :',
  numberInputErrorMessage: 'Veuillez écrire un nombre valide',
  errorsInputLabel: 'Erreurs',
  versionLabel: 'Version',
  kingDevickCardIncompleteText: 'Cette carte est incomplète',
  thirdCardWarningMessage: 'Cette personne fait partie du groupe d’âge pouvant éprouver des difficultés avec cette carte. Si la personne ne peut pas compléter la carte, réinitialisez-la (si elle a une valeur) et continuez'
};
 
export default FR;
