import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BalanceTestTimer from './balance-test-timer';
import { SelectInputGroup } from '@/forms';
import Strings from './lang';
import BESSTest from './bess-test';
import ScoreView from '../../../score-view';

const MAX_ERRORS = 10;
const MAX_TRIALS = 3;
const MAX_SCORE = MAX_ERRORS * MAX_TRIALS;

const calculateBalanceTotal = (bess) => {
  return Object.keys(bess || {}).reduce((acc, key) => {
    const value = parseInt(bess[key], 10);
    if (key === 'foam_pad_errors' || isNaN(value)) {
      return acc;
    }

    return acc - value;
  }, MAX_SCORE);
};

class BalanceTesting extends Component {
  constructor(props) {
    super(props);

    const { initialState = {} } = props;
    const { 
      dominant_foot = '', 
      tandom_errors = '', 
      feet_together_errors = '', 
      single_leg_errors = '', 
      foam_pad_errors = ''
    } = initialState;

    this.onDominantFootUpdate = this.onDominantFootUpdate.bind(this);
    this.onUpdateFootTogether = this.onUpdateBalanceTest.bind(this, 'feet_together_errors');
    this.onUpdateSingleLeg = this.onUpdateBalanceTest.bind(this, 'single_leg_errors');
    this.onUpdateTandom = this.onUpdateBalanceTest.bind(this, 'tandom_errors');
    this.onUpdateFoamPad = this.onUpdateBalanceTest.bind(this, 'foam_pad_errors');

    this.state = {
      dominant_foot,
      BESS: {
        feet_together_errors,
        single_leg_errors,
        tandom_errors,
        foam_pad_errors
      },
      total: MAX_SCORE
    };
  }

  componentDidMount() {
    let { total } = this.state;

    total = calculateBalanceTotal(this.state.BESS);

    this.setState({
      total
    });
  }

  render() {
    return (
      <div className="balance-testing">
        <div className="balance-header-row">
          { this.renderDominantFootSelector() }
          <BalanceTestTimer />
        </div>
        { this.renderBESSTests() }
        <ScoreView
          label={Strings.bessTotalScoreLabel}
          score={this.state.total}
          outOf={MAX_SCORE}
        />
      </div>
    );
  }

  renderBESSTests() {
    const { BESS } = this.state;
    return (
      <div className="baseline-card">
        <BESSTest
          testLabel={Strings.feetTogetherTestLabel}
          maxErrors={MAX_ERRORS}
          value={BESS.feet_together_errors}
          onUpdate={this.onUpdateFootTogether}
          touched={this.props.touched}
          required
        />
        <BESSTest
          testLabel={Strings.singleLegTestLabel}
          maxErrors={MAX_ERRORS}
          value={BESS.single_leg_errors}
          onUpdate={this.onUpdateSingleLeg}
          touched={this.props.touched}
          required
        />
        <BESSTest
          testLabel={Strings.tandemTestLabel}
          maxErrors={MAX_ERRORS}
          value={BESS.tandom_errors}
          onUpdate={this.onUpdateTandom}
          touched={this.props.touched}
          required
        />
        <BESSTest
          testLabel={Strings.foamTextLabel}
          maxErrors={MAX_ERRORS}
          value={BESS.foam_pad_errors}
          onUpdate={this.onUpdateFoamPad}
          touched={this.props.touched}
          required
        />
      </div>
    );
  }

  renderDominantFootSelector() {
    const { dominant_foot } = this.state;
    return (
      <SelectInputGroup
        className="form-group"
        labelText={Strings.dominantFootLabel}
        inputProps={{
          className: 'form-control',
          value: dominant_foot
        }}
        required
        messageClassName="alert alert-danger"
        messageText={Strings.dominantFootErrorMsg}
        valid={dominant_foot !== ''}
        touched={this.props.touched}
        onUpdate={this.onDominantFootUpdate}
      >
        <option value="">{Strings.defaultOption}</option>
        <option value="left">{Strings.leftFootOption}</option>
        <option value="right">{Strings.rightFootOption}</option>
      </SelectInputGroup>
    );
  }

  onUpdateBalanceTest(key, value) {
    const { BESS, dominant_foot } = this.state;
    let { total } = this.state;
    const { onUpdate = () => {} } = this.props;
    BESS[key] = value;

    if (key !== 'foam_pad_errors') {
      total = calculateBalanceTotal(BESS);
    }

    this.setState({
      BESS,
      total
    });

    onUpdate({ dominant_foot, ...BESS });
  }

  onDominantFootUpdate(value) {
    const { BESS } = this.state;
    let { dominant_foot } = this.state;
    const { onUpdate = () => {} } = this.props;

    dominant_foot = value;

    this.setState({
      dominant_foot
    });

    onUpdate({ dominant_foot, ...BESS });
  }
}

BalanceTesting.propTypes = {
  onUpdate: PropTypes.func,
  initialState: PropTypes.shape({
    dominant_foot: PropTypes.string, 
    tandom_errors: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), 
    feet_together_errors: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), 
    single_leg_errors: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), 
    foam_pad_errors: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  }),
  touched: PropTypes.bool
};

export default BalanceTesting;
