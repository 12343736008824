import { UPDATE_GUARDIAN_AUTH, DELETE_GUARDIAN_AUTH } from '../actions/guardians-account';

const updateGuardianAuthReducer = (state = {}, action = {}) => {
  const { user = {} } = action;
  return { ...state, user };
};

const guardiansAccount = (state = {}, action = {}) => {
  switch (action.type) {
    case UPDATE_GUARDIAN_AUTH:
      return updateGuardianAuthReducer(state, action);
    case DELETE_GUARDIAN_AUTH:
      return {};
    default:
      return state;
  }
};

export default guardiansAccount;
