const EN = {
  injuryCharacteristicsHeader: 'Injury Characteristics',
  injuryDescriptionLabelText: 'Injury Description',
  injuryDescriptionPlaceHolderText: 'Describe the injury event',
  directHitHeadLabelText: 'Direct Hit to Head',
  yesLabel: 'Yes',
  noLabel: 'No',
  unknownLabel: 'Unknown',
  unsureLabel: 'Unsure',
  locationOfImpactLabelText: 'Location of Impact',
  loiBodyLabelText: 'Body',
  loiFrontLabel: 'Front',
  loiBackLabel: 'Back',
  loiRightSideLabel: 'Right Side',
  loiLeftSideLabel: 'Left Side',
  loiHeadLabelText: 'Head',
  loiRightFrontalLabel: 'Right Frontal',
  loiLeftFrontalLabel: 'Left Frontal',
  loiRightTemporalLabel: 'Right Temporal',
  loiLeftTemporalLabel: 'Left Temporal',
  loiRightParietalLabel: 'Right Parietal',
  loiLeftParietalLabel: 'Left Parietal',
  loiRightOccipitalLabel: 'Right Occipital',
  loiLeftOccipitalLabel: 'Left Occipital',
  loiCrownLabel: 'Crown',
  lossOfConsciousnessLabelText: 'Loss of Consciousness',
  timeInMinutesLabelText: 'Time (in minutes)',
  notesLabel: 'Notes',
  seizuresLabelText: 'Seizures',
  causeOfInjuryLabelText: 'Cause of Injury',
  causeSportLabel: 'Sport',
  causeMVALabel: 'MVA',
  causeFallLabel: 'Fall',
  causeAssaultLabel: 'Assault',
  causeOtherLabel: 'Other',
  otherSportLabel: 'Other Sport',
  otherCauseOfInjuryLabel: 'Other Cause of Injury',
  sportHockey: 'Hockey',
  sportSoccer: 'Soccer',
  sportFootball: 'Football',
  defaultSport: 'Select One',
  sportSelectLabel: 'Select Sport',
  amnesiaOfEventLabel: 'Amnesia of Event',
  noneLabel: 'None',
  retrogradeAmnesiaLabel: 'Amnesia Before Injury (Retrograde)',
  anterogradeAmnesiaLabel: 'Amnesia Following Injury (Anterograde)',
  retrogradeLabel: 'Retrograde',
  anterogradeLabel: 'Anterograde',
  timelineLabelText: 'Timeline: Were multiple concussions experience within a short period of time?',
  initialSymptomsLabel: 'Initial Symptoms at Time of Injury',
  noValueSelectedErrorMessage: 'Please select a value',
  descriptionErrorMessage: 'Please enter the description of injury',
  invalidNumberErrorMessage: 'Please enter a valid number',
  requiredTextError: 'This field is required'
};

export default EN;
