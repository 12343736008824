const FR = {
  title: 'Cliniques',
  creditsRemainingTitle: 'Crédits disponibles',
  filterLabelText: 'Filtrer',
  emptyClinicsMessage: 'Aucune clinique n’a été trouvée',
  totalBalanceTitleLabel: 'Encours net',
  currencyMixWarningText: 'Peut être un mélange de CAD et USD',
  invoicesTitleLabel: 'Factures impayées',
  overdueInvoicesLabel: '{0} retard',
  searchInputPlaceholder: 'Recherche par nom de clinique',
  activeItemLabel: 'Actif',
  deactivatedItemLabel: 'Désactivé',
  clinicBalanceError: 'Erreur lors de la récupération des soldes de la clinique: {0}'
};
 
export default FR;
