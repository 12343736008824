import React from 'react';
import PropTypes from 'prop-types';
import Strings from './lang';

const activityStrings = {
  yes: Strings.activityYesLabel,
  no: Strings.activityNoLabel,
  havent_tried: Strings.activityHaventTriedLabel
};

const PostInjurySymptomExertion = ({
  exertion = {}
}) => {
  const { physical_activity = '', mental_activity = '', overall_rating = '' } = exertion;

  return (
    <div className="post-injury-section post-injury-symptom-exertion">
      <div className="row">
        <div className="col-md-12">
          <h2 className="bold">{Strings.symptomExertionTitle}</h2>
        </div>
      </div>
      <div className="post-injury-group">
        <div className="row">
          <div className="col-md-4">
            <strong>{Strings.symptomExertionPhysicalActivityLabel}:</strong>&nbsp;
            <span className="capitalize">{activityStrings[physical_activity]}</span>
          </div>
          <div className="col-md-4">
            <strong>{Strings.symptomExertionMentalActivityLabel}:</strong>&nbsp;
            <span className="capitalize">{activityStrings[mental_activity]}</span>
          </div>
          <div className="col-md-4">
            <strong>{Strings.overallRatingLabel}:</strong>&nbsp;
            <span>
              {overall_rating.length > 0 
                ? overall_rating 
                : Strings.overallRatingNotProvidedText}
            </span>
          </div>  
        </div>
      </div>
    </div>
  );
};

PostInjurySymptomExertion.propTypes = {
  exertion: PropTypes.shape({
    physical_activity: PropTypes.string,
    mental_activity: PropTypes.string,
    overall_rating: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  })
};

export default PostInjurySymptomExertion;
