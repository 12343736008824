import React, { useMemo } from 'react';
import { MAX_INTERVAL, COOL_DOWN, getBuffaloTestState } from './buffalo-data';
import BuffaloTestFormHeader from './buffalo-test-form-header';
import BuffaloTestTableRow from './buffalo-test-table-row';
import Strings from '../lang';

const BuffaloTestBody = ({ intervals }) => {
  return (
    <tbody>
      {intervals.map((item, index) => {
        let inclineText = index;
        if (index === COOL_DOWN) {
          inclineText = Strings.coolDownText;
        } else if (index === MAX_INTERVAL) {
          inclineText = Strings.stopTestText;
        }

        return (
          <BuffaloTestTableRow
            key={index}
            minute={index}
            incline={inclineText}
            symptoms={item.symptoms}
            heartRate={item.heart_rate}
          />
        );
      })}
    </tbody>
  );
};

const BuffaloTestTable = (props) => {
  const { buffalo } = useMemo(() => getBuffaloTestState(props), [props]);

  return (
    <table className="table buffalo-test-table">
      <thead>
        <BuffaloTestFormHeader />
      </thead>
      <BuffaloTestBody 
        intervals={buffalo?.results?.minute_intervals} 
      />
    </table>
  );
};

export default BuffaloTestTable;
