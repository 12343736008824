const EN = {
  reviewTitle: 'Review Baseline Test',
  compareTitle: 'Compare Baseline Tests',
  baselineTitle: 'Baseline Test \u2014 {0}',
  testName: 'Post Injury Test {0} \u2014 {1}',
  symptomsTitle: 'Symptoms',
  cognitiveAssessmentTitle: 'Orientation',
  memoryTitle: 'Memory',
  concentrationTitle: 'Concentration',
  kingDevickTitle: 'King-Devick',
  bessTitle: 'BESS',
  wessTitle: 'WESS',
  forcePlateTitle: 'Force Plate (COP)',
  tandemGaitTitle: 'Timed Tandem Gait',
  dualTaskTitle: 'Dual Task Tandem Gait',
  reactionTitle: 'Reaction Time',
  gripStrengthTitle: 'Grip Strength',
  delayedMemoryTitle: 'Delayed Memory',
  testDateLabelText: 'Test Date:',
  languageLabelText: 'Language:',
  notesLabelText: 'Notes:',
  totalScoreLabelText: 'Total Symptom Severity',
  totalSymptomsLabelText: 'Total Symptoms',
  wordListLabelText: 'Word List {0}:',
  trialLabelText: 'Trial {0}:',
  averageLabelText: 'Average:',
  averageShortLabelText: 'Avg.',
  scoreLabelText: 'Score:',
  numbersScoreLabelText: 'Numbers in Reverse Score:',
  monthsScoreLabelText: 'Months in Reverse Score:',
  versionLabelText: 'Version:',
  cardLabelText: 'Card:',
  secondsLabelText: 'Time (Seconds):',
  errorsLabelText: 'Errors:',
  totalLabelText: 'Total:',
  testLabelText: 'Test:',
  dominantHandLabelText: 'Dominant Hand:',
  handLabelText: 'Hand:',
  dominantFootLabelText: 'Dominant Foot:',
  footLabelText: 'Foot:',
  leftLabelText: 'Left',
  rightLabelText: 'Right',
  copLabelText: 'Center of Pressure:',
  distanceLabelText: 'Distance (cm):',
  reactionTimeLabelText: 'Reaction Time (s):',
  whatDateLabelText: 'What Date?',
  whatTimeLabelText: 'What Time?',
  whatDayLabelText: 'What Day?',
  whatMonthLabelText: 'What Month?',
  whatYearLabelText: 'What Year?',
  wess_seated_errors: 'Seated',
  wess_balance_disk_errors: 'Balance Disk',
  wess_wheelie_errors: 'Wheelie',
  wessEyesOpenErrors: 'Eyes Open Errors:',
  wessEyesClosedErrors: 'Eyes Closed Errors:',
  feet_together_errors: 'Feet Together',
  single_leg_errors: 'Single Leg Stance (Non-Dominant Foot)',
  tandom_errors: 'Tandem Stance (Dominant Foot in Front)',
  foam_pad_errors: 'Foam Pad (Non-Dominant Foot) (Optional)',
  noActiveBaseline: 'This patient has no active baseline test before {0} to compare',
  notDoneText: 'Not Done',
  baselineExpiredText: 'This patients\' baseline has expired and is no longer valid.',
  notCompletedText: 'Section not completed',
  editBaselineButtonText: 'Edit Baseline Test',
  lastEditedLabelText: 'Last Edited:',
  lastEditedByText: '{0} by {1}',
  continueBaselineText: 'Continue Baseline',
  archiveTestText: 'Delete Baseline',
  archivedTagText: 'Deleted',
  unarchiveTestText: 'Restore Baseline',
  archivedMessage: 'Deleted on {0} by {1} for the following reason',
  archivedSuccessMessage: 'Baseline test has been deleted successfully!',
  unarchivedSuccessMessage: 'Baseline test has been restored successfully!',
  secondsLabel: 'seconds',
  trialColumnLabel: 'Trial',
  durationSecondsColumnLabel: 'Duration (s)',
  passFailColumnLabel: 'Pass/Fail',
  passedText: 'Passed',
  failedText: 'Failed',
  fastestTimeLabel: 'Fastest Time',
  startIntegerLabel: 'Start Integer',
  reasonLabel: 'reason',
  attemptedLabel: 'Attempted',
  correctLabel: 'Correct',
  errorsLabel: 'Errors',
  subtractionValueLabel: 'Subtraction Value',
  unableToCompleteLabel: 'Patient was unable to complete test',
  dualTaskCognitiveAccuracyLabel: 'Cog. Accuracy',
  dualTaskResponseRateLabel: 'Resp. Rate'
};

export default EN;
