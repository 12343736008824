const EN = {
  title: 'Register a Clinic',
  clinicNameLabelText: 'Clinic Name',
  clinicImpactCodeLabelText: 'ImPACT Code',
  clinicImpactCodeErrorText: 'Please enter the clinic ImPACT Code.',
  clinicNameErrorText: 'Please enter the clinic name.',
  clinicUrlLabelText: 'Website',
  emailLabelText: 'Email',
  phoneLabelText: 'Phone',
  phoneErrorText: 'Please enter a valid phone number.',
  faxLabelText: 'Fax',
  faxErrorText: 'Please enter a valid fax number.',
  submitButtonText: 'Save',
  successMessageText: '{0} has been added.',
  addClinicLinkText: 'Add another clinic',
  homeLinkText: 'Home',
  addCreditsStepTitle: 'Add Credits',
  addCreditsStepMessage: 'Did you want to add credits to {0}?',
  skipStepButtonText: 'Not now, Skip',
  addCreditsModalTitle: 'Add Complimentary Credits',
  addCreditsModalMessage: 'You are about to add {0} credit(s) to {1}. Are you sure you want to continue?',
  addCreditsModalConfirmButtonText: 'Yes, Add Credits',
  registrationCompleteTitle: 'Registration Complete',
  clinicProfielButtonText: '{0} Clinic\'s Profile',
  searchableLabelText: 'Show Clinic On Map?',
  bioLabelText: 'Bio',
  clinicHoursLabel: 'Clinic Hours',
  addText: 'Add',
  removeText: 'Remove',
  axiaIdtProviderLabel: 'Axia IDT Provider',
  resourcesLabel: 'Resources Enabled',
  clinicFeaturesLabel: 'Clinic Features'
};

export default EN;
