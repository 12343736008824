import dayjs from 'dayjs';
import { findIncompleteSteps } from './find-incomplete-steps';
import { BaselineProfileV1, BaselineProfile, getBaselineProfile } from './baseline-profile';
import BaselineTestLabels from './baseline-test-labels';

const isBaselineContinuable = (baseline = {}) => {
  return !baseline.complete && dayjs().subtract(1, 'month').isBefore(dayjs(baseline.created_at));
};

export {
  BaselineProfileV1,
  BaselineProfile,
  getBaselineProfile,
  BaselineTestLabels,
  findIncompleteSteps,
  isBaselineContinuable
};
