import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { RadioInputGroup, FormInputGroup } from '@/forms';
import Strings from './lang';

class NeuroExamTest extends Component {
  render() {
    return (
      <div className="neuro-exam-test">
        <h3>{this.props.title}</h3>
        <hr />
        <div className="row">
          <div className="col-md-6">
            <RadioInputGroup
              className="form-group"
              radioLabels={[
                Strings.unremarkableLabel, 
                Strings.remarkableLabel, 
                Strings.notDoneLabel
              ]}
              radioValues={['unremarkable', 'remarkable', 'not_done']}
              initialValue={this.props.status}
              inputProps={{
                className: 'form-control'
              }}
              inputValid
              onUpdate={(value) => this.props.onUpdate('status', value)}
            />
          </div>
          {this.props.additionalNode || null}
        </div>
        <FormInputGroup 
          className="form-group"
          labelText={Strings.notesLabel} 
          inputType="textarea"
          inputProps={{
            rows: 5,
            className: 'form-control',
            value: this.props.notes,
            onBlur: this.onUpdateNotes.bind(this),
            onChange: this.onUpdateNotes.bind(this)
          }}
          inputValid
        />
      </div>
    );
  }

  onUpdateNotes(e) {
    const { value } = e.target;

    this.props.onUpdate('notes', value);
  }
}

NeuroExamTest.propTypes = {
  title: PropTypes.string,
  status: PropTypes.string,
  notes: PropTypes.string,
  onUpdate: PropTypes.func.isRequired
};

export default NeuroExamTest;
