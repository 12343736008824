import React from 'react';

const ErrorBanner = ({
  error
}) => (error ? (
  <div className="error alert alert-danger">
    {error}
  </div>
) : null);

export default ErrorBanner;
