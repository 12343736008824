const EN = {
  title: 'Player Submitted Symptoms',
  totalLabel: 'Total',
  severityTotalLavel: 'Severity Score',
  symptoms: 'Symptoms',
  thisPatientText: 'This patient',
  noSymptomsReported: '{0} has not submitted any symptom updates yet.',
  accountNumberLabel: 'Account Number',
  bagtagNumberLabel: 'ID Card Number',
  dateOfBirthLabel: 'Date of Birth',
  ageLabel: 'Age',
  genderLabel: 'Gender',
  dateOfInjuryLabel: 'Date of Injury',
  languageLabel: 'Language',
  recoveryStageLabel: 'Recovery Stage',
  maxLabel: 'max {0}',
  symptomSeverityLabel: 'SYMPTOM SEVERITY',
  symptomTotalsLabel: 'SYMPTOM TOTALS'
};

export default EN;
