import React, { useState, useCallback } from 'react';
import SoapSection from './soap-section';
import { RecoveryStageSelectors } from '../../recovery-stages';
import Strings from '../lang';

const isValid = (rtl, rts, rtw) => rts !== '' && rtl !== '' && rtw !== '';

const buildAttributes = (id, rtl, rts, rtw) => {
  const attributes = {
    recovery_protocol_stage_attributes: {
      rtl_stage: rtl || null,
      rts_stage: rts || null,
      rtw_stage: rtw || null
    }
  };

  if (id) {
    attributes.recovery_protocol_stage_attributes.id = id;
  }

  return attributes;
};

const getInitialStage = (stage = null, prev = null) => {
  if (stage !== null) {
    return stage;
  }

  if (prev === null) {
    return null;
  }

  return '';
};

const RecoveryProtocolForm = ({
  stages,
  previousStages = {},
  canDischarge = false,
  submitted: propsSubmitted = false,
  onSubmit = () => {}
}) => {
  const {
    rtl_stage, rts_stage, rtw_stage, id
  } = stages || {};
  const { rtl_stage: prevRtl, rts_stage: prevRts, rtw_stage: prevRtw } = previousStages || {};
  const [rtsStage, setRtsStage] = useState(() => getInitialStage(rts_stage, prevRts));
  const [rtlStage, setRtlStage] = useState(() => getInitialStage(rtl_stage, prevRtl));
  const [rtwStage, setRtwStage] = useState(() => getInitialStage(rtw_stage, prevRtw));
  const [submitted, setSubmitted] = useState(propsSubmitted);

  const handleSubmit = useCallback(() => {
    setSubmitted(true);

    if (!isValid(rtlStage, rtsStage, rtwStage)) return;

    const attributes = buildAttributes(id, rtlStage, rtsStage, rtwStage);
    onSubmit(attributes);
  }, [id, onSubmit, rtlStage, rtsStage, rtwStage]);

  return (
    <SoapSection title={Strings.recoveryStageFormTitle} onSubmitSection={handleSubmit}>
      <RecoveryStageSelectors 
        rtsStage={rtsStage}
        rtlStage={rtlStage}
        rtwStage={rtwStage}
        submitted={submitted || propsSubmitted}
        canDischarge={canDischarge}
        previousStages={previousStages}
        onRtsStageChange={setRtsStage}
        onRtlStageChange={setRtlStage}
        onRtwStageChange={setRtwStage}
      />
    </SoapSection>
  );
};

export default RecoveryProtocolForm;
