import {
  UPDATE_TAXES,
  UPDATE_TAX_ITEM, 
  REMOVE_TAX_ITEM
} from '../actions/taxes';

const updateTaxesReducer = (state, taxes = []) => {
  return taxes.reduce((acc, taxItem) => updateTaxItemReducer(acc, taxItem.id, taxItem), state);
};

const updateTaxItemReducer = (state, taxId, taxItem) => {
  return {
    ...state,
    [taxId]: taxItem
  };
};

const removeTaxItemReducer = (state, taxId) => {
  const newState = { ...state };
  delete newState[taxId];
  return newState;
};

const taxes = (state = {}, action = {}) => {
  switch (action.type) {
    case UPDATE_TAXES:
      return updateTaxesReducer(state, action.taxes);
    case UPDATE_TAX_ITEM:
      return updateTaxItemReducer(state, action.taxId, action.taxItem);
    case REMOVE_TAX_ITEM:
      return removeTaxItemReducer(state, action.taxId);
    default:
      return state;
  }
};

export default taxes;
