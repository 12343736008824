import React from 'react';
import PropTypes from 'prop-types';
import Strings from './lang';

const TeamLeaderContactCard = ({
  user = {}
}) => {
  const { email = '' } = user;
  return (
    <div className="card card-show">
      <div className="card-row">
        <label>{Strings.emailLabelText}:</label>
        <span>{email}</span>
      </div>
    </div>
  );
};

TeamLeaderContactCard.propTypes = {
  user: PropTypes.object
};

export default TeamLeaderContactCard;
