import React from 'react';
import PropTypes from 'prop-types';
import { If, Then } from 'react-if';
import { MAX_SYMPTOM_SEVERITY } from '@/utilities/symptom-data';
import Fraction from '../../fraction';
import Strings from './lang';

const SymptomItem = ({
  title = '', 
  symptom = {}
}) => {
  const { level = 0, notes = '' } = symptom;

  return (
    <div className="symptom">
      <div className="symptom-meta">
        <h3>{title}</h3>
        <h3><Fraction numerator={level} denominator={MAX_SYMPTOM_SEVERITY} /></h3>
      </div>
      <If condition={notes.length > 0}>
        <Then>
          <p className="notes">
            <strong>{Strings.notesLabelText}</strong> {notes}
          </p>
        </Then>
      </If>
    </div>
  );
};

SymptomItem.propTypes = {
  title: PropTypes.string.isRequired,
  symptom: PropTypes.object.isRequired
};

export default SymptomItem;
