import React from 'react';
import Strings from './lang';

const isSideline = (type) => type === 'InjuryReport';

const DanaLabels = ({
  battery = {}
}) => {
  const { post_injury, testable_type } = battery;

  return (
    <div className="dana-label-group">
      {post_injury ? (
        <span className="label label-danger inverted">
          {Strings.postInjuryLabel}
        </span>
      ) : (
        <span className="label label-light">
          {Strings.baselineLabel}
        </span>
      )}
      {isSideline(testable_type) && (
        <span className="label label-primary inverted">
          {Strings.sidelineLabel}
        </span>
      )}
    </div>
  );
};

export default DanaLabels;
