import React, { useMemo } from 'react';
import StageSelector from './stage-selector';
import Switch from '../switch';
import Strings from './lang';

const ProtocolHeader = ({ 
  title,
  toggled,
  disabled,
  onToggle = () => {}
}) => {
  return (
    <div className="selector-header">
      <Switch 
        toggled={toggled}
        title={toggled 
          ? Strings.protocolToggleDisableTitle 
          : Strings.protocolToggleEnableTitle}
        disabled={disabled}
        onChange={onToggle}
      />
      <h1>{title}</h1>
    </div>
  );
};

const RecoveryStageSelector = ({
  stage,
  protocol,
  enabled,
  previousStage,
  onToggle = () => {},
  finalStageEnabled,
  toggleDisabled,
  touched,
  onChange
}) => {
  const previousStageIndex = useMemo(() => (
    protocol.stages.findIndex(s => s.value === previousStage)
  ), [previousStage, protocol.stages]);
  const previous = protocol.stages[previousStageIndex] || {};

  return (
    <div className="protocol-stage">
      <ProtocolHeader 
        title={protocol.label}
        disabled={toggleDisabled}
        toggled={enabled}
        onToggle={onToggle}
      />
      {enabled ? (
        <div>
          <div className="previous-stage">
            <small>
              <strong>{Strings.previousStageLabel}:</strong>&nbsp;
              {previous.label || Strings.stageNotSetLabel}
            </small>
          </div>
          <StageSelector 
            protocol={protocol}
            touched={touched}
            finalStageEnabled={finalStageEnabled}
            value={stage}
            onChange={onChange}
          />
        </div>
      ) : (
        <p className="protocol-disabled-text text-center text-muted">
          <small><i>{Strings.protocolDisabledText}</i></small>
        </p>
      )}
    </div>
  );
};

export default RecoveryStageSelector;
