import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { If, Then, Else } from 'react-if';
import { FormComponent } from '@/forms';
import { getSport } from '@/utilities/sports';
import { EditButton } from '../buttons';
import AccessControl from '../access-control';
import { resolvePositionValue, resolvePositionName } from './sports-utilities';
import SportsTeamPositionInputs from './sports-team-position-inputs';
import Strings from './lang';
import Icon from '../icon';

const resolvePosition = (sport, position = '') => {
  const secondaryPositionObj = sport.positions.find(p => {
    return p.key === position;
  });

  if (secondaryPositionObj) {
    return secondaryPositionObj.key;
  }

  if (position && position.length > 0) {
    return 'other';
  }

  return '';
};

const getStateFromProps = (props) => {
  const { team = {} } = props;
  const city = team.city || '';
  const state = team.state || '';
  const country = team.country || '';
  const organization = team.organization || {};
  const teamSport = team.sport || {};
  const sport = getSport(teamSport.name || '');
  const position = team.position || {};
  const { primary = '', secondary = '' } = position;

  const primaryPosition = resolvePosition(sport, primary);
  const otherPrimaryPosition = (primaryPosition === 'other') ? primary : '';

  const secondaryPosition = resolvePosition(sport, secondary);
  const otherSecondaryPosition = (secondaryPosition === 'other') ? secondary : '';

  return {
    submitted: false,
    team,
    city,
    state,
    country,
    organization,
    sport,
    primaryPosition,
    otherPrimaryPosition,
    secondaryPosition,
    otherSecondaryPosition
  };
};

class SportsTeamListItem extends Component {
  constructor(props) {
    super(props);

    this.updatePositions = this.updatePositions.bind(this);
    this.onUpdateSelect = this.onUpdateSelect.bind(this);
    this.onUpdateField = this.onUpdateField.bind(this);
    this.onUpdateEditing = this.onUpdateEditing.bind(this);
    this.onUpdateLeaving = this.onUpdateLeaving.bind(this);

    this.state = {
      ...getStateFromProps(props)
    };
  }

  render() {
    const {
      disableEditing = false,
      isEditing = false,
      isLeaving = false
    } = this.props;

    const {
      submitted,
      team,
      city,
      state,
      country,
      organization,
      sport,
      primaryPosition,
      otherPrimaryPosition,
      secondaryPosition,
      otherSecondaryPosition
    } = this.state;

    return (
      <li className="sports-team-list-item" onClick={this.onClick.bind(this)}>

        <h3>{team.name}</h3>

        <ul className="list-unstyled">
          <If condition={organization.name !== undefined && organization.name.length > 0}>
            <Then>
              <li className="organization">
                <small>{organization.name}</small>
              </li>
            </Then>
          </If>

          <li><small>{city}, {state} {country}</small></li>

          <If condition={sport.name !== undefined && sport.name.length > 0}>
            <Then>
              <li className="sport">
                <small>{sport.name}</small>
              </li>
            </Then>
          </If>

          <If condition={isEditing === true}>
            <Then>
              <FormComponent className="sports-team-positions-form" onSubmit={this.updatePositions}>
                <SportsTeamPositionInputs
                  sport={sport}
                  primaryPosition={primaryPosition}
                  otherPrimaryPosition={otherPrimaryPosition}
                  secondaryPosition={secondaryPosition}
                  otherSecondaryPosition={otherSecondaryPosition}
                  onUpdateSelect={this.onUpdateSelect}
                  onUpdateField={this.onUpdateField}
                  submitted={submitted}
                />
              </FormComponent>
            </Then>
            <Else>
              <div className="sports-team-positions">

                <If condition={primaryPosition.length > 0}>
                  <Then>
                    <li className="position">
                      <small>
                        {Strings.primaryPositionLabelText}:&nbsp;
                        {resolvePositionName(sport, primaryPosition, otherPrimaryPosition)}
                      </small>
                    </li>
                  </Then>
                </If>

                <If condition={secondaryPosition.length > 0}>
                  <Then>
                    <li className="position">
                      <small>
                        {Strings.secondaryPositionLabelText}:&nbsp;
                        {resolvePositionName(sport, secondaryPosition, otherSecondaryPosition)}
                      </small>
                    </li>
                  </Then>
                </If>

              </div>
            </Else>
          </If>

          <If condition={isLeaving === true}>
            <Then>
              <div className="sports-team-list-item-delete">
                <p className="alert alert-danger">
                  {Strings.formatString(Strings.confirmLeaveTeamMessageText, team.name)}
                </p>
                <div className="sports-team-list-item-controls">
                  <button 
                    type="button"
                    className="btn btn-danger" 
                    onClick={this.leaveTeam.bind(this)}
                  >
                    {Strings.deleteButtonText}
                  </button>
                  <button 
                    type="button"
                    className="edit-button" 
                    onClick={this.onUpdateLeaving.bind(this)}
                  >
                    <Icon name="ban" />
                    <span>{Strings.cancelButtonText}</span>
                  </button>
                </div>
              </div>
            </Then>
          </If>

          <If condition={isLeaving !== true}>
            <Then>
              <AccessControl 
                currentUser={this.props.currentUser} 
                roles={this.props.editorRoles || []}
              >
                <div className="sports-team-list-item-controls">
                  <EditButton 
                    editing={isEditing} 
                    disabled={disableEditing} 
                    onClick={this.onUpdateEditing} 
                  />
                  {isEditing && (
                    <button 
                      type="button" 
                      className="btn btn-primary" 
                      onClick={this.updatePositions.bind(this)}
                    >
                      {Strings.saveButtonText}
                    </button>
                  )}
                  {
                    !isEditing && (
                      <button 
                        type="button" 
                        className="edit-button" 
                        disabled={disableEditing} 
                        onClick={this.onUpdateLeaving}
                      >
                        <Icon name="right-from-bracket" />
                        <span>{Strings.deleteButtonText}</span>
                      </button>
                    )
                  }
                </div>
              </AccessControl>
            </Then>
          </If>

        </ul>

      </li>
    );
  }

  onUpdateField(field, e) {
    const { value } = e.target;
    this.setState({ [field]: value });
  }

  onUpdateSelect(field, value) {
    this.setState({ [field]: value });
  }

  onUpdateEditing() {
    const { isEditing = false, onStartEditing, onCancelEditing } = this.props;
    if (isEditing) {
      if (typeof onCancelEditing === 'function') {
        onCancelEditing();
      }
    } else if (typeof onStartEditing === 'function') {
      onStartEditing();
    }
  }

  onUpdateLeaving() {
    const { isLeaving = false, onStartLeaving, onCancelLeaving } = this.props;
    if (isLeaving) {
      if (typeof onCancelLeaving === 'function') {
        onCancelLeaving();
      }
    } else if (typeof onStartLeaving === 'function') {
      onStartLeaving();
    }
  }

  onClick() {
    const { team = {}, editing = false, onClick } = this.props;
    if (typeof onClick === 'function' && !editing) {
      onClick(team);
    }
  }

  isValid() {
    const {
      primaryPosition,
      otherPrimaryPosition,
      secondaryPosition,
      otherSecondaryPosition
    } = this.state;

    const primaryPositionValid = resolvePositionValue(
      primaryPosition,
      otherPrimaryPosition
    ).length > 0;

    const secondaryPositionValue = resolvePositionValue(
      secondaryPosition,
      otherSecondaryPosition
    );

    const secondaryPositionValid = secondaryPosition.length === 0 || secondaryPositionValue.length > 0 || (secondaryPosition === 'other' && otherSecondaryPosition.length > 0);

    return primaryPositionValid && secondaryPositionValid;
  }

  updatePositions() {
    this.setState({ submitted: true });

    if (!this.isValid()) {
      return;
    }

    const {
      team = {},
      primaryPosition,
      otherPrimaryPosition,
      secondaryPosition,
      otherSecondaryPosition
    } = this.state;

    const resolvedPrimaryPosition = resolvePositionValue(
      primaryPosition,
      otherPrimaryPosition
    );

    const resolvedSecondaryPosition = resolvePositionValue(
      secondaryPosition,
      otherSecondaryPosition
    );

    const { position } = team;
    const positionId = (position || {}).id || 0;

    const attributes = {
      primary: resolvedPrimaryPosition,
      secondary: resolvedSecondaryPosition
    };

    if (typeof this.props.onCommitEditing === 'function') {
      this.props.onCommitEditing(positionId, attributes, team.id);
    }
  }

  leaveTeam() {
    const { team = {} } = this.state;
    const teamId = team.id || 0;

    if (typeof this.props.onCommitLeaving === 'function') {
      this.props.onCommitLeaving(teamId);
    }
  }
}

SportsTeamListItem.propTypes = {
  team: PropTypes.object.isRequired,
  isEditing: PropTypes.bool,
  disableEditing: PropTypes.bool,
  editorRoles: PropTypes.arrayOf(PropTypes.object),
  onClick: PropTypes.func,
  onStartEditing: PropTypes.func,
  onCancelEditing: PropTypes.func,
  onCommitEditing: PropTypes.func,
  onStartLeaving: PropTypes.func,
  onCancelLeaving: PropTypes.func,
  onCommitLeaving: PropTypes.func
};

export default SportsTeamListItem;
