import React, { useState } from 'react';
import Strings from '../lang';
import Icon from '../../icon';

const ReferralMessage = ({ children }) => {
  const [collapsed, setCollapsed] = useState(true);

  return (
    <div className="referral-message">
      <div className="message-label">
        {Strings.referrerNoteLabel}:&nbsp;
        <button type="button" className="btn-link" onClick={() => setCollapsed(prev => !prev)}>
          {collapsed ? Strings.showText : Strings.hideText}&nbsp;
          <Icon name={`chevron-${collapsed ? 'right' : 'down'}`} />
        </button>
      </div>
      {!collapsed && (
        <div className="note">
          {children}
        </div>
      )}
    </div>
  );
};

const ReferralFooter = ({ message }) => {
  return (
    <div className="referral-card-footer">
      {message?.length > 0 && (
        <ReferralMessage>
          {message}
        </ReferralMessage>
      )}
    </div>
  );
};

export default ReferralFooter;
