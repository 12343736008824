import React, { useState } from 'react';
import { InviteClinicUserModal } from '../clinic-invite-user';
import Strings from './lang';
import Icon from '../icon';

const InviteUserButton = ({ clinic }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <button 
        type="button" 
        className="btn btn-primary btn-sm"
        onClick={() => setOpen(true)}
      >
        <Icon name="plus" />&nbsp;&nbsp;
        {Strings.inviteUserButton}
      </button>
      <InviteClinicUserModal 
        isOpen={open}
        clinic={clinic}
        onClose={() => setOpen(false)}
      />
    </>
  );
};

export default InviteUserButton;
