import { UPDATE_SYMPTOMS_UPDATES } from '../actions/symptoms-updates';

const updateSymptomsUpdatesReducer = (state = {}, action = {}) => {
  const { userId, injuryId, symptomsUpdatesList = {} } = action;
  const userState = state[userId] || {};
  const nextUserState = (injuryId) ? { ...userState, [injuryId]: symptomsUpdatesList } : userState;
  return (userId) ? { ...state, [userId]: nextUserState } : state;
};

const symptomsUpdates = (state = {}, action = {}) => {
  switch (action.type) {
    case UPDATE_SYMPTOMS_UPDATES:
      return updateSymptomsUpdatesReducer(state, action);
    default:
      return state;
  }
};

export default symptomsUpdates;
