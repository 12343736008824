import React, {
  useState, 
  useCallback, 
  useMemo
} from 'react';
import RecoveryProtocols from './protocols';
import RecoveryStageSelector from './recovery-stage-selector';
import { willDischarge } from './utils';
import Strings from './lang';

const getEnabledStages = (stages = {}) => {
  return Object.keys(stages).filter(key => (stages[key] ?? null) !== null);
};

const RecoveryStageSelectors = ({
  rtsStage,
  rtlStage,
  rtwStage = null,
  submitted,
  canDischarge,
  onRtlStageChange = () => {},
  onRtsStageChange = () => {},
  onRtwStageChange = () => {},
  previousStages = {}
}) => {
  const stages = useMemo(() => (
    { rts: rtsStage, rtl: rtlStage, rtw: rtwStage }
  ), [rtlStage, rtsStage, rtwStage]);

  const [enabledStages, setEnabledStages] = useState(() => getEnabledStages(stages));
  const dischargable = useMemo(() => willDischarge(stages), [stages]);

  const handleToggle = useCallback((key, toggled) => {
    setEnabledStages(prev => {
      const cp = prev.filter(item => item !== key);
      return toggled ? [...cp, key] : cp;
    });
  }, []);

  const handleRtsToggle = useCallback((toggled) => {
    handleToggle('rts', toggled);
    onRtsStageChange(toggled ? '' : null);
  }, [handleToggle, onRtsStageChange]);

  const handleRtlToggle = useCallback((toggled) => {
    handleToggle('rtl', toggled);
    onRtlStageChange(toggled ? '' : null);
  }, [handleToggle, onRtlStageChange]);

  const handleRtwToggle = useCallback((toggled) => {
    handleToggle('rtw', toggled);
    onRtwStageChange(toggled ? '' : null);
  }, [handleToggle, onRtwStageChange]);

  return (
    <div>
      <div className="row">
        <div className="col-md-6">
          <RecoveryStageSelector 
            protocol={RecoveryProtocols.rts}
            stage={stages.rts}
            previousStage={previousStages.rts_stage}
            enabled={enabledStages.includes('rts')}
            touched={submitted}
            toggleDisabled={enabledStages.includes('rts') && enabledStages.length <= 1}
            finalStageEnabled={canDischarge}
            onToggle={handleRtsToggle}
            onChange={onRtsStageChange}
          />
        </div>
        <div className="col-md-6">
          <RecoveryStageSelector 
            protocol={RecoveryProtocols.rtl}
            stage={stages.rtl}
            previousStage={previousStages.rtl_stage}
            enabled={enabledStages.includes('rtl')}
            touched={submitted}
            toggleDisabled={enabledStages.includes('rtl') && enabledStages.length <= 1}
            finalStageEnabled={canDischarge}
            onToggle={handleRtlToggle}
            onChange={onRtlStageChange}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <RecoveryStageSelector
            protocol={RecoveryProtocols.rtw}
            stage={stages.rtw}
            previousStage={previousStages.rtw_stage}
            enabled={enabledStages.includes('rtw')}
            touched={submitted}
            toggleDisabled={enabledStages.includes('rtw') && enabledStages.length <= 1}
            finalStageEnabled={canDischarge}
            onToggle={handleRtwToggle}
            onChange={onRtwStageChange}
          />
        </div>
      </div>
      {dischargable && (
        <p className="alert alert-info text-center">
          {Strings.dischargeMessage}
        </p>
      )}
    </div>
  );
};

export default RecoveryStageSelectors;
