import React from 'react';
import Card from '../card';
import AccessControl from '../access-control';
import { EditButton } from '../buttons';

const ClinicCard = ({
  title,
  roles,
  editing,
  onEditToggle,
  children
}) => {
  return (
    <Card
      title={title}
      actionButtons={(
        <AccessControl roles={roles}>
          <EditButton 
            editing={editing} 
            onClick={onEditToggle} 
          />
        </AccessControl>
      )}
    >
      {children}
    </Card>
  );
};

export default ClinicCard;
