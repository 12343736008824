const EN = {
  emptySportsMessageText: 'You haven\'t added any teams yet.',
  teamSearchFormTitle: 'Add a Team',
  selectTeamFormTitle: 'Select Team & Positions',
  sportLabelText: 'Sport',
  sportErrorText: 'Please choose your sport',
  otherSportLabelText: 'Other Sport',
  otherSportErrorText: 'Please enter a valid sport (letters and spaces only).',
  countryLabelText: 'Country',
  countryErrorText: 'Please choose your country.',
  regionLabelText: 'State / Province',
  regionErrorText: 'Please choose your state or province.',
  cityLabelText: 'City / Town',
  cityErrorText: 'Please enter your city.',
  teamLabelText: 'Team',
  teamErrorText: 'Please choose your team.',
  newTeamNameLabelText: 'Team Name',
  newTeamNameErrorText: 'Please enter your team\'s name.',
  newTeamOrganizationLabelText: 'Organization/Association (no acronyms)',
  newTeamLevelLabelText: 'Team Level (e.g. Peewee AA)',
  newTeamLevelErrorText: 'Please enter your team\'s level.',
  primaryPositionLabelText: 'Primary Position',
  otherPrimaryPositionLabelText: 'Other Primary Position',
  secondaryPositionLabelText: 'Secondary Position',
  otherSecondaryPositionLabelText: 'Other Secondary Position',
  positionErrorText: 'Please select your primary position',
  otherPositionErrorText: 'Please enter a valid position (letters and spaces only).',
  searchButtonText: 'Find Teams',
  saveButtonText: 'Save',
  deleteButtonText: 'Leave Team',
  cancelButtonText: 'Cancel',
  sportSelectOptionText: 'Select a Sport',
  teamSelectOptionText: 'Select a Team',
  positionSelectOptionText: 'Select a Position',
  addTeamOptionText: 'Add a New Team',
  otherOptionText: 'Other',
  userTeamsListHeadingText: 'Teams',
  suggestedTeamsHeadingText: 'Suggested Teams',
  suggestedTeamsMessageText: 'We found teams similar to the one you entered. Were you looking for one of these?',
  ignoreSuggestionHeadingText: 'No thanks, use the team I entered:',
  confirmLeaveTeamMessageText: 'Are you sure you want to leave the {0} team?',
  invalidOrganizationMessage: 'Please enter the full name of the organization/association. Acronyms are not permitted.',
  requestedTeamJoinMessage: 'Your request to join this Team ({0}) has been sent to the team Leader for approval.',
  noOganizationDefaultLabel: 'No Organization',
  organizationPlaceholder: 'Please enter the full name; no acronyms'
};

export default EN;
