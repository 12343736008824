import React, { useState } from 'react';
import classnames from 'classnames';
import AdminLineItemActions from './admin-line-item-actions';
import NotePopover from './note-popover';
import getLineItemDescription from './line-item-descriptions';
import { EMPTY_COLUMN_PLACEHOLDER, getDate, defaultDate } from './utils';
import { getBulkItemDescription } from '../utilities';
import { Money } from '@/utilities/currency';
import Icon from '../../icon';

const BulkItemRow = ({
  bulkId,
  type,
  purchaser = null,
  items = [],
  total = null,
  date = defaultDate(),
  clinicId,
  invoiceId,
  currency = 'CAD',
  onDeleteItem
}) => {
  const [rowExpanded, setRowExpanded] = useState(false);
  return (
    <>
      <tr 
        key={bulkId} 
        className={classnames(
          'expandable-row', 
          'no-print', 
          { opened: rowExpanded }
        )} 
        onClick={() => setRowExpanded(!rowExpanded)}
      >
        <td>
          <div className="table-row-toggle">
            <Icon prefix="fas" name={rowExpanded ? 'caret-down' : 'caret-right'} />
          </div>
          {getDate(date)}
        </td>
        <td>
          <span>
            {getBulkItemDescription(type)}&nbsp;&nbsp;
            <span className="label label-warning">
              {items.length}
            </span>
          </span>
        </td>
        <td>{purchaser}</td>
        <td>{EMPTY_COLUMN_PLACEHOLDER}</td>
        <td>{EMPTY_COLUMN_PLACEHOLDER}</td>
        <td>{Money.format(total, currency)}</td>
      </tr>
      {rowExpanded && items.map(item => (
        <LineItemsTableRow 
          nested
          key={item.id}
          expanded={false}
          clinicId={clinicId}
          invoiceId={invoiceId}
          lineItem={item}
          currency={currency}
          onDeleteItem={onDeleteItem}
        />
      ))}
    </>
  );
};

const getDateUsed = (usedAt, createdAt) => {
  if (usedAt === undefined) {
    return getDate(createdAt);
  }

  return usedAt ? getDate(usedAt) : null;
};

const LineItemsTableRow = ({
  clinicId,
  invoiceId,
  nested = false,
  lineItem = {}, 
  invoiceStatus,
  currency = 'CAD',
  onDeleteItem
}) => {
  const [rowFocus, setRowFocus] = useState(false);
  const { 
    buyer = '',
    type,
    patient_account,
    used_at,
    isBulk = false,
    items = [],
    created_at = null,
    amount = null
  } = lineItem;

  if (isBulk) {
    return (
      <BulkItemRow
        type={type}
        bulkId={lineItem.id}
        items={items}
        purchaser={buyer}
        total={amount}
        date={created_at}
        currency={currency}
        clinicId={clinicId}
        invoiceId={invoiceId}
        onDeleteItem={onDeleteItem}
      />
    );
  }

  return (
    <tr className={classnames({ focused: rowFocus })} align={nested ? 'right' : null}>
      <td>  
        <AdminLineItemActions 
          clinicId={clinicId}
          invoiceId={invoiceId}
          lineItem={lineItem} 
          currency={currency}
          visible={invoiceStatus !== 'paid'}
          onFocus={() => setRowFocus(true)}
          onBlur={() => setRowFocus(false)}
          onDelete={onDeleteItem}
        />
        {getDate(created_at)}
      </td>
      <td>
        {getLineItemDescription(type, lineItem, clinicId)}
        <NotePopover note={lineItem.note} />
      </td>
      <td>{buyer}</td>
      <td>{patient_account}</td>
      <td>{getDateUsed(used_at, created_at)}</td>
      <td>{amount}</td>
    </tr>
  );
};

export default LineItemsTableRow;
