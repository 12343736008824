import React, {
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, unstable_useBlocker as useBlocker } from 'react-router-dom';
import AccessControl from '../../access-control';
import UnauthorizedView from './unauthorized-view';
import Activity from '../../activity';
import { ErrorBanner } from '../../errors';
import { useBaselineSteps } from './context';
import { confirmation } from '../../confirmation';
import { BaselineSelector, UserSelector } from '@/redux/selectors';
import { updateBaselineTestAsync } from '@/redux/thunks/baseline-tests';
import Strings from './lang';
import { AuthenticationPaths } from '@/paths';

const ignoredNextPaths = ['/', AuthenticationPaths.index.link];

const showLeaveConfirmModal = (onConfirm, onCancel) => {
  confirmation(Strings.unsavedFieldsWarningMessage, {
    title: Strings.unsavedFieldsWarningTitle,
    confirmButtonTitle: Strings.continueButtonText,
    onConfirm,
    onCancel
  });
};

const withBaselineStep = (Component) => {
  const Wrapper = () => {
    const ref = useRef();
    const params = useParams();
    const dispatch = useDispatch();
    const [activity, setActivity] = useState(false);
    const [error, setError] = useState(null);
    const [saved, setSaved] = useState(false);
    const user = useSelector(state => UserSelector.getUser(state, params));
    const currentUser = useSelector(UserSelector.getCurrentUser);
    const baseline = useSelector(state => BaselineSelector.getBaseline(state, params));
    const {
      step,
      next,
      profile,
      index
    } = useBaselineSteps();
    const isSkippable = step?.skippable
      && (!Object.keys(baseline?.[step.key] || {}).length > 0 || baseline?.[step.key]?.not_done);

    const updateBaseline = useCallback((attributes) => {
      setActivity(true);
      setError(null);

      if (!profile[index + 1]) {
        // eslint-disable-next-line no-param-reassign
        attributes.complete = true;
      }

      dispatch(
        updateBaselineTestAsync(params.userId, params.baselineId, attributes)
      ).then((baseline) => {
        setSaved(true);
        setActivity(false);
        next(baseline);
      }).catch(error => {
        setActivity(false);
        setError(error.message);
      });
    }, [dispatch, index, next, params.baselineId, params.userId, profile]);

    const onSkip = useCallback(() => {
      if (step?.key) {
        updateBaseline({ [step.key]: { not_done: true } });
      }
    }, [step?.key, updateBaseline]);

    const blocker = useBlocker(!!Component.hasChanged && ((props) => {
      if (!ignoredNextPaths.includes(props.nextLocation.pathname) && !saved) {
        return Component.hasChanged(
          baseline[step?.key] || {},
          ref.current?.getAttributes(false)?.[step?.key] || {}
        );
      }

      return false;
    }));

    useEffect(() => {
      if (blocker.state === 'blocked') {
        showLeaveConfirmModal(
          () => setTimeout(() => blocker.proceed(), 1),
          () => setTimeout(() => blocker.reset(), 1)
        );
      }
    }, [blocker]);

    return (
      <AccessControl
        roles={step.permissions}
        fallback={<UnauthorizedView permissions={step.permissions} />}
      >
        <Activity active={activity}>
          <ErrorBanner error={error} />
          <div className="baseline-step-header">
            {isSkippable && (
              <button
                type="button"
                className="btn btn-sm btn-warning"
                onClick={onSkip}
              >
                {Strings.skipButtonText}
              </button>
            )}
          </div>
          <Component
            ref={ref}
            user={user}
            baseline={baseline}
            currentUser={currentUser}
            next={next}
            saveButtonText={Strings.saveProgressButtonText}
            onSubmit={updateBaseline}
          />
        </Activity>
      </AccessControl>
    );
  };

  return Wrapper;
};

export default withBaselineStep;
