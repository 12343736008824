import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { PASSWORD_RESET_URL } from '@/utilities/urls';
import { FormComponent, EmailInputGroup } from '@/forms';
import Strings from './lang';

class PasswordResetRequestForm extends Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      submitted: false,
      email: '',
      emailValid: false
    };
  }

  render() {
    return (
      <FormComponent className="password-reset-request-form" onSubmit={this.onSubmit}>
        <EmailInputGroup
          className="form-group"
          labelText={Strings.emailLabelText}
          inputProps={{
            className: 'form-control',
            placeholder: Strings.emailInputPlaceholder,
            value: this.state.email,
            disabled: this.state.activity
          }}
          onUpdate={(email, emailValid) => {
            this.setState({ email, emailValid });
          }}
          messageClassName="alert alert-danger"
          required
          touched={this.state.submitted}
        />

        <div className="form-footer">
          <Link to={this.props.nextLocation}>
            {this.props.nextLinkText}
          </Link>
          <button
            className="btn btn-primary"
            type="submit"
            disabled={!this.state.emailValid}
          >
            {Strings.resetButtonText}
          </button>
        </div>
      </FormComponent>
    );
  }

  isValid() {
    return this.state.emailValid;
  }

  onSubmit() {
    this.setState({ submitted: true });

    if (!this.isValid()) {
      return;
    }

    const { email } = this.state;
    const { onSubmit } = this.props;

    const attributes = {
      email,
      reset_url: this.props.resetUrl || PASSWORD_RESET_URL
    };

    if (typeof onSubmit === 'function') {
      onSubmit(attributes);
    }
  }
}

PasswordResetRequestForm.propTypes = {
  nextLocation: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]).isRequired,
  nextLinkText: PropTypes.string.isRequired,
  resetUrl: PropTypes.string,
  onSubmit: PropTypes.func
};

export default PasswordResetRequestForm;
