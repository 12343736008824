const SESSION_SIGNOUT = 'SESSION_SIGNOUT';

const sessionSignout = () => ({
  type: SESSION_SIGNOUT
});

export {
  SESSION_SIGNOUT,
  sessionSignout
};
