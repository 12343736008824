import React from 'react';
import PropTypes from 'prop-types';
import { If, Then } from 'react-if';
import classNames from 'classnames';
import Icon from '../icon';

const NavigationDropdownHeader = ({
  user = {}, 
  organization = {}, 
  expanded = false
}) => {
  const { unread_notification_count = 0 } = user;
  const person = user.person || {};
  return ( 
    <div className="navigation-dropdown-header-inner">
      <div 
        className={classNames('user-avatar', {
          'has-notifications': unread_notification_count > 0
        })}
      >
        <img src={person.photo_url} alt={`${person.first_name} ${person.last_name}`} />
        <If condition={unread_notification_count > 0}>
          <Then>
            <div className="notification-badge">
              <span>{unread_notification_count}</span>
            </div>
          </Then>
        </If>
      </div>
      <div className="user-metadata">
        <div className="name">{person.first_name} {person.last_name}</div>
        <div className="organization">{organization.name}</div>
      </div>
      <Icon name={expanded ? 'xmark' : 'chevron-down'} />
    </div> 
  );
};

NavigationDropdownHeader.propTypes = {
  user: PropTypes.object.isRequired,
  organization: PropTypes.object,
  expanded: PropTypes.bool.isRequired
};

export default NavigationDropdownHeader;
