import React, { Component } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { If, Then } from 'react-if';
import { DEFAULT_USER_LANGUAGE } from '@/utilities/localization';
import { age, minBirthdate } from '@/utilities/dates';
import { INPUT_DATE_FORMAT, DISPLAY_DATE_FORMAT } from '@/utilities/dates/formats';
import Strings from './lang';
import {
  FormComponent,
  FormInputGroup,
  GenderInputGroup,
  DateInputGroup,
  LanguageInputGroup,
} from '@/forms';

class EditPlayerPersonalInfoCardForm extends Component {
  constructor(props) {
    super(props);

    const person = props.user.person || {};
    const first_name = person.first_name || '';
    const last_name = person.last_name || '';
    const birthday = person.birthday || '';
    const gender = person.gender || '';
    const genderOther = person.gender_other || '';
    const language = person.language || DEFAULT_USER_LANGUAGE;
    const showOtherGender = genderOther !== '';

    this.ref = React.createRef();
    this.onSubmit = this.onSubmit.bind(this);
    this.onUpdateBirthdate = this.onUpdateBirthdate.bind(this);
    this.onUpdateGender = this.onUpdateGender.bind(this);

    this.state = {
      submitted: false,
      firstName: first_name,
      lastName: last_name,
      birthdate: birthday,
      gender,
      genderOther,
      showOtherGender,
      language
    };
  }

  render() {
    const userAge = age(this.state.birthdate);
    const interpretedDOB = dayjs(
      this.state.birthdate, 
      INPUT_DATE_FORMAT
    ).format(DISPLAY_DATE_FORMAT);

    return (
      <FormComponent ref={this.ref} className="card card-form" onSubmit={this.onSubmit}>
        <FormInputGroup
          className="form-group first-name-input-group"
          labelText={Strings.firstNameLabelText}
          inputType="text"
          inputProps={{
            className: 'form-control',
            value: this.state.firstName,
            onBlur: this.onUpdateInput.bind(this, 'firstName'),
            onChange: this.onUpdateInput.bind(this, 'firstName')
          }}
          inputValid={this.state.firstName.length > 0}
          messageText={Strings.firstNameErrorText}
          messageClassName="alert alert-danger"
          required
          touched={this.state.submitted}
        />

        <FormInputGroup
          className="form-group last-name-input-group"
          labelText={Strings.lastNameLabelText}
          inputType="text"
          inputProps={{
            className: 'form-control',
            value: this.state.lastName,
            onBlur: this.onUpdateInput.bind(this, 'lastName'),
            onChange: this.onUpdateInput.bind(this, 'lastName')
          }}
          inputValid={this.state.lastName.length > 0}
          messageText={Strings.lastNameErrorText}
          messageClassName="alert alert-danger"
          required
          touched={this.state.submitted}
        />

        <DateInputGroup
          className="form-group"
          labelText={Strings.birthdateLabelText}
          inputType="date"
          textInputProps={{
            className: 'form-control',
            placeholder: 'January 1, 2000'
          }}
          dateInputProps={{
            className: 'form-control'
          }}
          initialValue={this.state.birthdate}
          minDate={minBirthdate()}
          onUpdate={this.onUpdateBirthdate}
          messageText={Strings.birthdateValidationMessage}
          messageClassName="alert alert-danger"
          required
          touched={this.state.submitted}
        />

        <If condition={!isNaN(userAge)}>
          <Then>
            <p className="alert alert-info">
              {Strings.formatString(
                Strings.birthdateConfirmationMessage,
                (userAge >= 1) ? userAge : Strings.under1,
                (userAge > 1) ? Strings.years : Strings.year,
                interpretedDOB
              )}
            </p>
          </Then>
        </If>

        <GenderInputGroup
          className="form-group"
          labelText={Strings.genderLabelText}
          inputType="select"
          inputProps={{
            className: 'form-control',
            value: this.state.gender
          }}
          onUpdate={this.onUpdateGender}
          messageText={Strings.genderValidationMessage}
          messageClassName="alert alert-danger"
          required
          touched={this.state.submitted}
        />
        
        <If condition={this.state.showOtherGender === true}>
          <Then>
            <FormInputGroup
              className="form-group"
              labelText={Strings.genderOtherLabelText}
              inputType="text"
              inputProps={{
                className: 'form-control',
                value: this.state.genderOther,
                onBlur: this.onUpdateInput.bind(this, 'genderOther'),
                onChange: this.onUpdateInput.bind(this, 'genderOther')
              }}
              inputValid={this.state.genderOther.length > 0}
              messageText={Strings.genderOtherValidationMessage}
              messageClassName="alert alert-danger"
              touched={this.state.submitted}
            />
          </Then>
        </If>

        <LanguageInputGroup
          className="form-group"
          labelText={Strings.languageLabelText}
          inputProps={{
            className: 'form-control',
            value: this.state.language
          }}
          onUpdate={language => {
            this.setState({ language });
          }}
          messageText={Strings.languageValidationMessage}
          messageClassName="alert alert-danger"
          required
          touched={this.state.submitted}
        />

        <If condition={this.props.onSubmit !== undefined}>
          <Then>
            <div className="form-footer">
              <button className="btn btn-primary" type="submit">
                {Strings.submitButtonText}
              </button>
            </div>
          </Then>
        </If>
      </FormComponent>
    );
  }

  isValid() {
    return this.state.firstName.length
      && this.state.lastName.length
      && this.state.birthdate
      && this.state.gender.length
      && this.state.language.length;
  }

  onUpdateInput(key, e) {
    const { value } = e.target;
    this.setState({
      [key]: value
    });
  }

  onUpdateBirthdate(birthdate) {
    this.setState({
      birthdate
    });
  }

  onUpdateGender(gender) {
    this.setState({
      gender,
      showOtherGender: (gender === 'unspecified')
    });
  }

  getAttributes(validate = true) {
    if (validate) {
      this.setState({ submitted: true });

      if (!this.isValid()) {
        return false;
      }
    }

    const {
      firstName,
      lastName,
      birthdate,
      gender,
      genderOther,
      language
    } = this.state;

    const attributes = {
      person_attributes: {
        first_name: firstName,
        last_name: lastName,
        birthday: dayjs(birthdate).format(INPUT_DATE_FORMAT),
        gender,
        gender_other: genderOther,
        language
      }
    };

    return attributes;
  }

  onSubmit() {
    if (typeof this.props.onSubmit === 'function') {
      this.setState({ submitted: true });

      if (!this.isValid()) {
        return;
      }

      this.props.onSubmit(this.getAttributes(false));
    }
  }
  
  // eslint-disable-next-line react/no-unused-class-component-methods
  scrollIntoView(alignTopOrOptions = true) {
    if (this.ref.current) {
      this.ref.current.scrollIntoView(alignTopOrOptions);
    }
  }
}

EditPlayerPersonalInfoCardForm.propTypes = {
  user: PropTypes.object.isRequired,
  onSubmit: PropTypes.func
};

export default EditPlayerPersonalInfoCardForm;
