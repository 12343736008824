const UPDATE_VOMS_TEST = 'UPDATE_VOMS_TEST';

const updateVOMSTest = (userId, injuryId, test) => ({
  type: UPDATE_VOMS_TEST,
  userId,
  injuryId,
  test
});

export {
  UPDATE_VOMS_TEST,
  updateVOMSTest
};
