import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { age, daysSince } from '@/utilities/dates';
import { INPUT_DATE_FORMAT, DISPLAY_DATE_FORMAT, DISPLAY_DATE_TIME_FORMAT } from '@/utilities/dates/formats';
import { ClinicPaths } from '@/paths';
import replaceLinkParams from '@/utilities/replace-link-params';
import { getAdjustedAssessmentDate } from '@/utilities/injury-assessment';
import Strings from './lang';
import GenderStrings from '@/utilities/gender';
import LanguageStrings from '@/utilities/languages';

const getAssessmentDate = (injury) => {
  const { injury_assessments = [] } = injury;

  return injury_assessments.length > 0
    ? getAdjustedAssessmentDate(injury_assessments[0].created_at).format(DISPLAY_DATE_FORMAT)
    : Strings.noAssessments;
};

const NameWrapper = (props) => {
  if (props.nameLink) {
    return (
      <Link to={props.to}>{props.children}</Link>
    );
  }

  return (
    <span>{props.children}</span>
  );
};

const TestHeader = ({
  user = {}, 
  injury = {}, 
  testDate = '', 
  tester = {}, 
  clinic = {}, 
  nameLink = true, 
  className,
  additionalNode = null
}) => {
  const person = user?.person || {};
  const practitioner = tester?.person || {};
  const clinicAddress = clinic?.address || {};
  const birthdate = useMemo(() => dayjs(person.birthday, INPUT_DATE_FORMAT), [person.birthday]);
  const injuryDate = useMemo(() => (
    dayjs(injury?.injured_at, INPUT_DATE_FORMAT)
  ), [injury?.injured_at]);
  const assessmentDate = useMemo(() => getAssessmentDate(injury), [injury]);

  return (
    <section className={classNames('test-header', className)}>

      <div className="row">
        <div className="col-md-12">
          <h2>
            <NameWrapper 
              nameLink={nameLink} 
              to={replaceLinkParams(ClinicPaths.patientProfile.index.link, {
                clinicId: clinic?.id,
                userId: user?.id
              })}
            >
              {person.first_name} {person.last_name}
            </NameWrapper>
          </h2>
        </div>
      </div>

      <div className="row">

        <div className="col-md-6">
          <div className="test-header-row">
            <label>{Strings.injuryDateLabelText}:</label>
            <span>
              {(injury.id) 
                ? injuryDate.format(DISPLAY_DATE_FORMAT) 
                : Strings.notApplicable}
            </span>
          </div>
          <div className="test-header-row">
            <label>{Strings.assessmentDateLabelText}:</label>
            <span>{assessmentDate}</span>
          </div>
        </div>

        <div className="col-md-6">
          <div className="test-header-row">
            <label>{Strings.practitionerLabelText}:</label>
            <span>{practitioner.first_name} {practitioner.last_name}</span>
          </div>
          <div className="test-header-row">
            <label>{Strings.clinicLabelText}:</label>
            <div className="header-clinic-address">
              <span>{clinic?.name}</span>
              <span>{clinicAddress.street_1}</span>
              <span>{clinicAddress.street_2}</span>
              <span>{clinicAddress.city}, {clinicAddress.state} {clinicAddress.postal_code}</span>
              <span>{clinicAddress.country}</span>
              <span><span className="bold">{Strings.phoneLabelText}</span> {clinic?.phone}</span>
              <span><span className="bold">{Strings.faxLabelText}</span> {clinic?.fax}</span>
            </div>
          </div>
        </div>

      </div>

      <div className="row">
        <div className="col-md-6">
          <div className="test-header-row">
            <label>{Strings.accountLabelText}:</label>
            <span>{user?.account}</span>
          </div>
        </div>
        <div className="col-md-6">
          <div className="test-header-row">
            <label>{Strings.bagtagLabelText}:</label>
            <span>{user?.bag_tag}</span>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <div className="test-header-row">
            <label>{Strings.birthdateLabelText}:</label>
            <span>{birthdate.format(DISPLAY_DATE_FORMAT)}</span>
          </div>
        </div>
        <div className="col-md-6">
          <div className="test-header-row">
            <label>{Strings.ageLabelText}:</label>
            <span>{age(birthdate) || null}</span>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <div className="test-header-row">
            <label>{Strings.genderLabelText}:</label>
            <span>{GenderStrings[person.gender]}</span>
          </div>
        </div>
        <div className="col-md-6">
          <div className="test-header-row">
            <label>{Strings.languageLabelText}:</label>
            <span>{LanguageStrings[person.language] || Strings.notSpecifiedText}</span>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <div className="test-header-row">
            <label>{Strings.testDateLabel}:</label>
            <span>{dayjs(testDate).format(DISPLAY_DATE_TIME_FORMAT)}</span>
          </div>
        </div>
        <div className="col-md-6">
          <div className="test-header-row">
            <label>{Strings.daysSinceInjuryLabelText}:</label>
            <span>{(injury.id) ? daysSince(injuryDate, testDate) : Strings.notApplicable}</span>
          </div>
        </div>
      </div>

      {additionalNode}
      <hr />
    </section>
  );
};

export default TestHeader;
