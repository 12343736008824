const EN = {
  patientInfoConfirmedMessage: '{0}\'s information has been confirmed',
  redactedReplacementText: 'the patient',
  accountNumberLabel: 'Account Number',
  bagtagNumberLabel: 'Concussion ID Number',
  confirmInformationTitle: 'Confirm Patient Information',
  patientCheckInTitle: 'Patient Check-in for {0}',
  patientCheckInBaselineTitle: 'Baseline Test',
  patientCheckInInjuryTitle: 'Injury Assessment',
  patientInformationCardHeader: 'Patient Information',
  confirmAndContinueButtonText: 'Confirm & Continue',
  checkInPatientButtonText: 'Check-In Patient',
  checkInErrorMessageText: 'This patients\' information contains 1 or more invalid fields. Please review the information.'
};

export default EN;
