import React, { useMemo } from 'react';
import { getRtlRiskSymptomCategories } from '@/utilities/symptom-data';
import Strings from '../lang';

const getRiskLabel = (risk) => ({
  low: Strings.lowRiskLabel,
  moderate: Strings.moderateRiskLabel,
  high: Strings.highRiskLabel
}[risk]);

const getRiskDescription = (risk) => ({
  low: Strings.lowRiskDescription,
  moderate: Strings.moderateRiskDescription,
  high: Strings.highRiskDescription
}[risk]);

const RTLRisk = ({ symptoms }) => {
  const { risk } = useMemo(() => getRtlRiskSymptomCategories(symptoms), [symptoms]);

  if (!symptoms) return null;

  return (
    <div className="rtl-risk-container">
      <div className="rtl-risk-stat">
        <h2>{Strings.rtlRiskCardTitle}</h2>
        <div className="risk-details">
          <div className="risk">
            <img src={`/images/risk-meter/${risk}.svg`} alt={`${risk} risk meter`} />
            <div className="risk-label">{getRiskLabel(risk)}</div>
          </div>
          <div className="description">
            {getRiskDescription(risk)}
          </div>
        </div>
        <div className="footer">
          <span>
            <strong>{Strings.noteLabel}:</strong> {Strings.riskDisclaimerMessage}
          </span>
          <a href="https://pubmed.ncbi.nlm.nih.gov/34046212/" rel="noopener noreferrer" target="_blank">
            <i>{Strings.sourceLabel}: Yue et al.</i>
          </a>
        </div>
      </div>
    </div>
  );
};

export default RTLRisk;
