import dispatchCustomEvent from '@/utilities/custom-event';

export const SHOW_EVENT = 'confirmation.show';

const confirmation = (message = '', options = {}) => {
  dispatchCustomEvent(SHOW_EVENT, { options: { ...options, message } });
};

confirmation.async = (message = '', options = {}) => {
  return new Promise((resolve, reject) => {
    confirmation(message, {
      ...options,
      onConfirm: resolve,
      onCancel: reject
    });
  });
};

export default confirmation;
