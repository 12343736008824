const PasswordPattern = /^.{6,}$/;

const PasswordValidator = {
  test(value, newPassword = false) {
    return newPassword
      ? PasswordPattern.test(value)
      : value.length > 0;
  }
};

export {
  PasswordValidator,
  PasswordPattern
};
