const EN = {
  assignClinicModalTitle: 'Assign Current Clinic',
  assignClinicModalMessage: 'Are you sure you want to make {0} {1}\'s current clinic?',
  assignClinicModalConfirmButtonTitle: 'Assign',
  modalNameRedactedText: 'this patient',
  noneText: 'None',
  noMedicalHistoryInfoText: '{0} has no medical history information.',
  patientText: 'Patient',
  createMedicalInfoButtonText: 'Add Medical Info'
};

export default EN;
