import React, {
  useCallback,
  useMemo,
  useState
} from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
  unstable_useBlocker as useBlocker
} from 'react-router-dom';

export const withRouter = (Component) => {
  const Wrapper = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [leaveHooks, setLeaveHooks] = useState([]);
    const [search, setSearch] = useSearchParams();
    const params = useParams();
    const queryParams = useMemo(() => Object.fromEntries(search), [search]);

    const push = useCallback((to, state) => {
      navigate(to, { state });
    }, [navigate]);

    const replace = useCallback((to, state) => {
      navigate(to, { state, replace: true });
    }, [navigate]);

    const goBack = useCallback(() => {
      navigate(-1);
    }, [navigate]);

    const setQueryParams = useCallback((query, options = {}) => {
      setSearch(query, {
        preventScrollReset: true,
        ...(options || {})
      });
    }, [setSearch]);

    const setRouteLeaveHook = useCallback((cb) => {
      setLeaveHooks(prev => ([...prev, cb]));
    }, []);

    const blocker = useBlocker(leaveHooks.length > 0 && ((args) => {
      if (args.currentLocation.pathname !== args.nextLocation.pathname) {
        return leaveHooks.filter(hook => (
          hook({
            ...args.nextLocation,
            action: args.historyAction
          })
        )).length > 0;
      }

      return false;
    }));

    const router = useMemo(() => ({
      push,
      replace,
      goBack,
      blocker,
      setQueryParams,
      setRouteLeaveHook
    }), [goBack, push, replace, setRouteLeaveHook, setQueryParams, blocker]);

    return (
      <Component
        {...props}
        router={router}
        location={location}
        params={params}
        queryParams={queryParams}
      />
    );
  };

  hoistNonReactStatics(Wrapper, Component);

  return Wrapper;
};
