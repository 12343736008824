const FR = {
  acuteConcussionAssessmentTitle: 'Évaluation de commotion aigüe (0 à 14 jours)',
  chronicConcussionAssessmentTitle: 'Évaluation de commotion chronique (0 à 14 jours)',
  medicalHistoryHeader: 'Historique médical',
  postInjuryTestHeader: 'Test post-blessure',
  saveInjuryAssessmentButton: 'Sauvegarder l’évaluation de la blessure',
  userInjuryError: 'Information sur la blessure de l’utilisateur indisponible',
  submittionError: 'Certaines sections contiennent des erreurs. Veuillez vérifier les erreurs dans les sections',
  assessmentTitle: 'Évaluation post-blessure',
  characteristicsTabLabel: 'Caractéristiques de la blessure',
  currentSymptomsTabLabel: 'Symptômes actuels',
  redFlagsTabLabel: 'Alertes',
  medicalHistoryTabLabel: 'Historique médical',
  physicalExamsTabLabel: 'Examen physique',
  testsTabLabel: 'Tests particuliers',
  diagnosisAndPlanTabLabel: 'Diagnostic + plan de gestion',
  reviewAndCloseTabLabel: 'Vérifier et fermer',
  closeAssessmentText: 'Fermer l’évaluation',
  confirmRouteLeaveTitle: 'Évaluation de sortie?',
  confirmRouteLeaveText: 'Cette évaluation de la blessure n’a pas été complétée et sera considérée comme incomplète Afin d’identifier cette blessure comme étant complète, cliquez sur le bouton\'Fermer l’évaluation\'en bas de l’onglet\'Vérifier et fermer\'',
  wishToContinueText: 'Voulez-vous continuer ?',
  confirmButtonTitle: 'Oui',
  cancelButtonTitle: 'Non, rester',
  sectionSavedMessage: 'Section enregistrée avec succès',
  standardSectionFormError: 'Cette section contient des erreurs. Veuillez revoir cette section',
  closeAssessmentErrorMessage: 'Certaines sections contiennent des erreurs. Veuillez revoir toutes les sections contenant un onglet en surbrillance rouge',
  closedAssessmentEditSectionInfoMessage: 'Cette section ne peut pas être modifiée une fois l\'évaluation terminée. Pour éditer le diagnostic et / ou revenir à l\'étape d\'apprentissage / de jeu, veuillez le faire via une note SOAP.'
};
 
export default FR;
