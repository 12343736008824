import React from 'react';
import PropTypes from 'prop-types';
import Strings from './lang';

const ConcussionCountDisplay = ({
  total,
  previous,
  withCCMI
}) => (
  <div className="concussion-count">
    <p>{Strings.totalConcussionLabel}:
      <strong className="count-total primary">{total}</strong>
      ({
        Strings.formatString(
          Strings.totalConcussionsDescription,
          previous,
          withCCMI
        )
      })
    </p>
  </div>
);

ConcussionCountDisplay.propTypes = {
  previous: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  withCCMI: PropTypes.number,
  total: PropTypes.number
};

export default ConcussionCountDisplay;
