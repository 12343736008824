import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState
} from 'react';
import { If, Then } from 'react-if';
import { FormComponent } from '@/forms';
import Card from '../../card';
import { AddNoteButton } from '../../buttons';
import { DualTaskTest } from '../test-components';
import HeaderStrings from '../lang';
import { randomUniqueNumberArray } from '@/utilities/random-number';
import { OMITTED_START_NUMBERS } from '../test-components/dual-task';
import Strings from './lang';

const DEFAULT_STEP = 7;
const DEFAULT_CHILD_STEP = 3;
const TRIAL_COUNT = 3;
const MIN_START_NUMBER = 85;
const MAX_START_NUMBER = 100;

const generateTrials = () => {
  const randomStartNumbers = randomUniqueNumberArray(
    TRIAL_COUNT,
    MIN_START_NUMBER,
    MAX_START_NUMBER,
    OMITTED_START_NUMBERS
  );

  return randomStartNumbers.map(start => ({
    start,
    failed: null,
    failed_reasons: [],
    errors: '',
    duration: '',
    responses: null
  }));
};

const DualTaskTestCard = forwardRef((
  {
    dualTask = {},
    isChild = false,
    showPractise = true,
    saveButtonText,
    onSubmit
  },
  ref
) => {
  const submittable = typeof onSubmit === 'function';
  const [submitted, setSubmitted] = useState(false);
  const [notes, setNotes] = useState(() => dualTask?.notes ?? '');
  const [unableToComplete, setUnableToComplete] = useState(dualTask?.unable_to_complete ?? false);
  const [step, setStep] = useState(() => (
    dualTask?.step ?? (isChild ? DEFAULT_CHILD_STEP : DEFAULT_STEP)
  ));
  const [trials, setTrials] = useState(() => (
    JSON.parse(JSON.stringify(dualTask?.trials ?? generateTrials()))
  ));

  const isValid = useMemo(() => {
    return unableToComplete || trials.every(trial => (
      trial.duration !== ''
        && !!trial.responses
        && trial.errors !== ''
    ));
  }, [trials, unableToComplete]);

  const onTrialChange = useCallback((index, trial) => {
    setTrials(prev => {
      const newTrials = [...prev];
      newTrials[index] = { ...newTrials[index], ...trial };
      return newTrials;
    });
  }, []);

  const onStepChange = useCallback((value) => {
    setStep(value);
    setTrials(prev => prev.map(v => ({
      ...v,
      errors: 0,
      duration: '',
      responses: null
    })));
  }, []);

  const getAttributes = useCallback((validate = true) => {
    if (validate) {
      setSubmitted(true);

      if (!isValid) {
        return false;
      }
    }

    const attributes = {
      dual_task_test: {
        notes,
        step,
        trials,
        unable_to_complete: unableToComplete
      }
    };

    return attributes;
  }, [isValid, notes, step, trials, unableToComplete]);

  const handleSubmit = useCallback(() => {
    if (submittable) {
      setSubmitted(true);

      if (!isValid) {
        return;
      }

      onSubmit(getAttributes(false));
    }
  }, [getAttributes, isValid, onSubmit, submittable]);

  useImperativeHandle(ref, () => ({
    getAttributes
  }), [getAttributes]);

  return (
    <FormComponent onSubmit={handleSubmit}>
      <Card
        title={HeaderStrings.dualTaskHeader}
        actionButtons={(
          <AddNoteButton
            note={notes}
            title={
              Strings.formatString(
                Strings.abStractNotesTitle,
                HeaderStrings.dualTaskHeader
              )
            }
            submitButtonTitle={Strings.addNoteButtonText}
            onSave={setNotes}
          />
        )}
      >
        <div className="card card-form">
          <DualTaskTest
            trials={trials}
            step={step}
            isChild={isChild}
            submitted={submitted}
            unableToComplete={unableToComplete}
            showPractise={showPractise}
            onStepChange={onStepChange}
            onTrialChange={onTrialChange}
            onUnableToCompleteChange={setUnableToComplete}
          />
          {submitted && !isValid && (
            <p className="alert alert-danger">
              {Strings.dualTaskSubmitError}
            </p>
          )}
          <If condition={submittable}>
            <Then>
              <div className="form-footer">
                <button type="submit" className="btn btn-primary">
                  {saveButtonText || Strings.saveButtonText}
                </button>
              </div>
            </Then>
          </If>
        </div>
      </Card>
    </FormComponent>
  );
});

export default DualTaskTestCard;
