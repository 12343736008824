import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import Modal from '../modal';
import {
  FormComponent,
  SelectInputGroup,
  FormInputGroup,
  DateInputGroup
} from '@/forms';
import Activity from '../activity';
import ErrorBanner from '../errors/error-banner';
import { DISPLAY_DATE_FORMAT } from '@/utilities/dates/formats';
import { createInvoiceAsync } from '@/redux/thunks/clinic-invoices';
import Strings from './lang';

const getDefaultCloseDate = () => {
  return dayjs().endOf('month').format('YYYY-MM-DD');
};

const CreateInvoiceForm = ({
  onSubmit = () => { }
}) => {
  const [submitted, setSubmitted] = useState(false);
  const [status, setStatus] = useState('open');
  const [closeDate, setCloseDate] = useState(getDefaultCloseDate());
  const [dueDate, setDueDate] = useState(null);
  const [note, setNote] = useState('');

  const handleSubmit = useCallback(() => {
    setSubmitted(true);

    const attributes = {
      status,
      note,
      close_date: closeDate
    };

    if (status === 'closed') {
      attributes.due_date = dueDate;
    }

    onSubmit(attributes);
  }, [closeDate, dueDate, note, onSubmit, status]);

  return (
    <FormComponent onSubmit={handleSubmit}>
      <SelectInputGroup
        valid
        className="form-group"
        labelText={Strings.invoiceStatusLabel}
        inputProps={{
          className: 'form-control',
          value: status
        }}
        onUpdate={(value) => {
          if (value === 'closed') {
            setCloseDate(dayjs().format('YYYY-MM-DD'));
            // eslint-disable-next-line no-magic-numbers
            setDueDate(dayjs().add(30, 'days').format('YYYY-MM-DD'));
          } else if (value === 'open') {
            setCloseDate(getDefaultCloseDate());
            setDueDate(null);
          }
          setStatus(value);
        }}
      >
        <option value="open">{Strings.statusOpenLabel}</option>
        <option value="closed">{Strings.statusClosedLabel}</option>
      </SelectInputGroup>
      {status === 'open' && (
        <DateInputGroup
          required
          className="form-group"
          labelText={Strings.closeDateLabel}
          inputType="date"
          textInputProps={{
            className: 'form-control'
          }}
          dateInputProps={{
            className: 'form-control'
          }}
          initialValue={closeDate}
          minDate={dayjs()}
          touched={submitted}
          onUpdate={(value) => setCloseDate(value)}
          messageText={Strings.formatString(
            Strings.minDateErrorMessage,
            dayjs().format(DISPLAY_DATE_FORMAT)
          )}
          messageClassName="alert alert-danger"
        />
      )}
      {status === 'closed' && (
        <DateInputGroup
          required
          className="form-group"
          labelText={Strings.dueDateLabel}
          inputType="date"
          textInputProps={{
            className: 'form-control'
          }}
          dateInputProps={{
            className: 'form-control'
          }}
          initialValue={dueDate}
          minDate={dayjs()}
          touched={submitted}
          onUpdate={(value) => setDueDate(value)}
          messageText={Strings.formatString(
            Strings.minDateErrorMessage,
            dayjs().format(DISPLAY_DATE_FORMAT)
          )}
          messageClassName="alert alert-danger"
        />
      )}
      <FormInputGroup
        inputValid
        inputType="textarea"
        className="form-group"
        labelText={Strings.noteLabel}
        inputProps={{
          className: 'form-control',
          value: note,
          rows: 5,
          onChange: (e) => setNote(e.target.value)
        }}
      />
      <button
        type="submit"
        className="btn btn-primary"
      >
        {Strings.createButtonLabel}
      </button>
    </FormComponent>
  );
};

const CreateInvoiceModel = ({
  isOpen,
  clinicId,
  onClose = () => { },
  createInvoice
}) => {
  const [activity, setActivity] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = useCallback((attributes) => {
    setActivity(true);
    setError(null);

    createInvoice(clinicId, attributes).then((invoice) => {
      setActivity(false);
      onClose(invoice);
    }).catch(error => {
      setActivity(false);
      setError(error.message);
    });
  }, [clinicId, createInvoice, onClose]);

  return (
    <Modal
      blur
      static={activity}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Modal.Header onClose={activity ? null : onClose}>
        <h2 className="bold">
          {Strings.createInvoiceTitle}
        </h2>
      </Modal.Header>
      <Modal.Body>
        <ErrorBanner error={error} />
        <Activity active={activity}>
          <CreateInvoiceForm onSubmit={handleSubmit} />
        </Activity>
      </Modal.Body>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    createInvoice: (clinicId, attributes) => {
      return dispatch(createInvoiceAsync(clinicId, attributes));
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(CreateInvoiceModel);
