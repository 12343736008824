import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { TestHeader } from '../test-header';
import SoapNotesFormDisplay from './soap-notes-form-display';
import LastEditedView from '../last-edited-view';

const SoapNoteView = ({
  user, 
  injury, 
  soap, 
  clinicId
}) => (
  <div className="soap-content">
    <div className="row">
      <div className="col-md-12">
        <TestHeader
          user={user}
          injury={injury}
          className="soap-notes-header"
          testDate={soap.created_at || dayjs()}
          tester={soap.clinic_user}
          clinic={soap.clinic}
        />
      </div>
    </div>

    <LastEditedView item={soap} />

    <div className="row">
      <div className="col-md-12">
        <SoapNotesFormDisplay
          soap={soap}
          userId={user.id}
          clinicId={clinicId}
        />
      </div>
    </div>

  </div>
);

SoapNoteView.propTypes = {
  user: PropTypes.object,
  injury: PropTypes.object,
  soap: PropTypes.object,
  clinicId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default SoapNoteView;
