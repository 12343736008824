import React from 'react';
import { Link } from 'react-router-dom';
import Strings from './lang';
import replaceLinkParams from '@/utilities/replace-link-params';
import { ClinicPaths } from '@/paths';

const InvoicePaymentBanner = ({ 
  clinicId,
  invoiceId,
  balance, 
  currency 
}) => {
  return (
    <div className="invoice-payment-banner no-print">
      <div className="container">
        <div className="payment-balance">
          <div>
            <small className="caption text-muted">
              {Strings.balanceText}
            </small>
          </div>
          <h4 className="bold">{balance || '--'} {currency}</h4>
        </div>
        <Link
          className="btn btn-success"
          to={replaceLinkParams(ClinicPaths.invoices.pay.link, { clinicId, invoiceId })}
        >
          {Strings.payNowButtonText}
        </Link>
      </div>
    </div>
  );
};

export default InvoicePaymentBanner;
