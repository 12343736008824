import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { DISPLAY_DATE_FORMAT, INPUT_DATE_FORMAT } from '@/utilities/dates/formats';
import { age } from '@/utilities/dates';
import GenderStrings from '@/utilities/gender';
import LanguageStrings from '@/utilities/languages';
import Strings from './lang';

const SymptomUpdatesHeader = ({
  user = {}, 
  injury = {}
}) => {
  const { person = {} } = user;

  return (
    <div className="test-header">

      <div className="row">
        <div className="col-md-12">
          <h2>{`${person.first_name || ''} ${person.last_name || ''}`}</h2>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <div className="test-header-row">
            <label>{Strings.accountNumberLabel}:</label>
            <span>{user.account}</span>
          </div>
        </div>
        <div className="col-md-6">
          <div className="test-header-row">
            <label>{Strings.bagtagNumberLabel}:</label>
            <span>{user.bag_tag}</span>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <div className="test-header-row">
            <label>{Strings.dateOfBirthLabel}:</label>
            <span>{dayjs(person.birthday).format(DISPLAY_DATE_FORMAT)}</span>
          </div>
        </div>
        <div className="col-md-6">
          <div className="test-header-row">
            <label>{Strings.ageLabel}:</label>
            <span>{age(person.birthday)}</span>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <div className="test-header-row">
            <label>{Strings.genderLabel}:</label>
            <span>{GenderStrings[person.gender]}</span>
          </div>
        </div>
        <div className="col-md-6">
          <div className="test-header-row">
            <label>{Strings.languageLabel}:</label>
            <span>{LanguageStrings[person.language]}</span>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <div className="test-header-row">
            <label>{Strings.dateOfInjuryLabel}:</label>
            <span>{dayjs(injury.injured_at, INPUT_DATE_FORMAT).format(DISPLAY_DATE_FORMAT)}</span>
          </div>
        </div>
      </div>
      
    </div>
  );
};

SymptomUpdatesHeader.propTypes = {
  user: PropTypes.object,
  injury: PropTypes.object
};

export default SymptomUpdatesHeader;
