const FR = {
  title: 'Continuer le test de référence',
  actionGroupLabel: 'Vous ne voyez pas qui vous recherchez?',
  refreshListButton: 'Rafraîchir la liste',
  searchPatientsButton: 'Rechercher des patients',
  tableHeader: 'Patients dont les tests de référence sont actuellement en cours:',
  tableNameLabel: 'Nom',
  tableAccountLabel: 'N° de compte.',
  tableCurrentStepLabel: 'Étape actuelle',
  tableStartedAtLabel: 'Commencé à',
  tableUpdatedAtLabel: 'Mis à jour à',
  emptyResultsMessage: 'Aucun patient trouvé avec un test de référence en cours.',
  continueBaselineModalInfoText: 'Veuillez indiquer le numéro de carte d’identité de la concussion de la personne ci-dessous pour continuer le test de référence.',
  continueBaselineModalTitleText: 'Continuer le test de référence',
  continueBaselineButtonText: 'Continuer le test'
};
 
export default FR;
