import React, { useCallback } from 'react';
import { usePdfDownload } from '@/hooks';
import Strings from '../lang';
import Icon from '../../icon';

const DownloadPDFButton = ({
  fileName,
  className,
  documentProps = {}
}) => {
  const { download, loading } = usePdfDownload(
    fileName, 
    () => import(/* webpackChunkName: "invoice~pdf" */'./document')
  );

  const onDownload = useCallback(() => {
    download(documentProps);
  }, [documentProps, download]);

  return (
    <button 
      type="button" 
      disabled={loading}
      className={className} 
      onClick={onDownload}
    >
      {loading ? (
        <>
          {Strings.pdfDownloadingButtonText}&nbsp;&nbsp;
          <Icon prefix="fad" name="loader" spinPulse />
        </>
      ) : (
        <>
          <Icon name="file-pdf" />&nbsp;&nbsp;
          {Strings.downloadPdfButtonText}
        </>
      )}
    </button>
  );
};

export default DownloadPDFButton;
