import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { If, Then } from 'react-if';
import { AddressFormInputs } from '../address-form-inputs';
import convertAddressKeys from '@/utilities/convert-address-keys';
import sanitizeAddress from '@/utilities/sanitize-address';
import validateAddress from '@/utilities/validate-address';
import { EmailValidator } from '@/forms/validators/email-validator';
import Strings from './lang';
import {
  FormComponent,
  EmailInputGroup,
  FormInputGroup
} from '@/forms';

class EditPlayerContactInfoCardForm extends Component {
  constructor(props) {
    super(props);

    const person = props.user.person || {};
    const address = sanitizeAddress(props.user.mailing_address || {});
    const personPhone = person.phone_number || '';
    const personEmail = props.user.email || '';
    const emergencyContact = props.user.emergency_contact || {};
    const emergencyContactName = emergencyContact.name || '';
    const emergencyContactPhone = emergencyContact.phone_number || '';
    const emergencyContactEmail = emergencyContact.email || '';
    const emergencyContactRelationship = emergencyContact.relationship || '';
    const personEmailValid = personEmail.length === 0 || EmailValidator.test(personEmail);
    const emergencyContactEmailValid = emergencyContactEmail.length === 0 
      || EmailValidator.test(emergencyContactEmail);

    this.ref = React.createRef();
    this.onSubmit = this.onSubmit.bind(this);
    this.onUpdateAddress = this.onUpdateAddress.bind(this);

    this.state = {
      submitted: false,
      address,
      addressValid: validateAddress(address),
      personPhone,
      personEmail,
      personEmailValid,
      emergencyContactName,
      emergencyContactPhone,
      emergencyContactEmail,
      emergencyContactEmailValid,
      emergencyContactRelationship
    };
  }

  render() {
    return (
      <FormComponent ref={this.ref} className="card card-form" onSubmit={this.onSubmit}>
        <AddressFormInputs
          address={this.state.address}
          onUpdate={this.onUpdateAddress}
          required
          touched={this.state.submitted}
        />

        <FormInputGroup
          className="form-group phone-input-group"
          labelText={Strings.phoneNumberLabelText}
          type="tel"
          inputProps={{
            className: 'form-control',
            value: this.state.personPhone,
            onBlur: this.onUpdateInput.bind(this, 'personPhone'),
            onChange: this.onUpdateInput.bind(this, 'personPhone')
          }}
          inputValid={this.state.personPhone.length > 0}
          messageText={Strings.phoneNumberValidationMessage}
          messageClassName="alert alert-danger"
          required
          touched={this.state.submitted}
        />

        <FormInputGroup
          className="form-group emergency-contact-name-input-group"
          labelText={Strings.emergencyContactLabelText}
          inputType="text"
          inputProps={{
            className: 'form-control',
            value: this.state.emergencyContactName || '',
            onBlur: this.onUpdateInput.bind(this, 'emergencyContactName'),
            onChange: this.onUpdateInput.bind(this, 'emergencyContactName')
          }}
          inputValid={this.state.emergencyContactName.length > 0}
          messageText={Strings.emergencyContactNameValidationMessage}
          messageClassName="alert alert-danger"
          required
          touched={this.state.submitted}
        />

        <FormInputGroup
          className="form-group phone-input-group"
          labelText={Strings.emergencyContactPhoneLabelText}
          type="tel"
          inputProps={{
            className: 'form-control',
            value: this.state.emergencyContactPhone,
            onBlur: this.onUpdateInput.bind(this, 'emergencyContactPhone'),
            onChange: this.onUpdateInput.bind(this, 'emergencyContactPhone')
          }}
          inputValid={this.state.emergencyContactPhone.length > 0}
          messageText={Strings.phoneNumberValidationMessage}
          messageClassName="alert alert-danger"
          required
          touched={this.state.submitted}
        />

        <EmailInputGroup
          className="form-group emergency-contact-email-input-group"
          labelText={Strings.emergencyContactEmailLabelText}
          inputProps={{
            value: this.state.emergencyContactEmail,
            className: 'form-control',
            placeholder: Strings.emailInputPlaceholder
          }}
          onUpdate={(emergencyContactEmail, emergencyContactEmailValid) => {
            this.setState({ emergencyContactEmail, emergencyContactEmailValid });
          }}
          messageClassName="alert alert-danger"
          required={false}
          touched={this.state.submitted}
        />

        <FormInputGroup
          className="form-group relationship-input-group"
          labelText={Strings.emergencyContactRelationshipLabelText}
          inputType="text"
          inputProps={{
            className: 'form-control',
            value: this.state.emergencyContactRelationship || '',
            onBlur: this.onUpdateInput.bind(this, 'emergencyContactRelationship'),
            onChange: this.onUpdateInput.bind(this, 'emergencyContactRelationship')
          }}
          inputValid={this.state.emergencyContactRelationship.length > 0}
          messageText={Strings.emergencyContactRelationshipValidationMessage}
          messageClassName="alert alert-danger"
          required
          touched={this.state.submitted}
        />

        <If condition={this.props.onSubmit !== undefined}>
          <Then>
            <div className="form-footer">
              <button className="btn btn-primary" type="submit">
                {Strings.submitButtonText}
              </button>
            </div>
          </Then>
        </If>
        
      </FormComponent>
    );
  }

  isValid() {
    return this.state.addressValid
      && this.state.personPhone.length
      && this.state.personEmailValid
      && this.state.emergencyContactName.length
      && this.state.emergencyContactPhone.length
      && (this.state.emergencyContactEmailValid || this.state.emergencyContactEmail.length === 0)
      && this.state.emergencyContactRelationship.length;
  }

  onUpdateAddress(address, addressValid) {
    this.setState({
      address,
      addressValid
    });
  }

  onUpdateInput(key, e) {
    const { value } = e.target;
    this.setState({
      [key]: value
    });
  }

  getAttributes(validate = true) {
    if (validate) {
      this.setState({ submitted: true });

      if (!this.isValid()) {
        return false;
      }
    }

    const {
      address,
      personPhone,
      personEmail,
      personEmailValid,
      emergencyContactName,
      emergencyContactPhone,
      emergencyContactEmail,
      emergencyContactEmailValid,
      emergencyContactRelationship
    } = this.state;

    const attributes = {
      email: (personEmailValid) ? personEmail : '',
      mailing_address_attributes: convertAddressKeys(address),
      person_attributes: {
        phone_number: personPhone,
      },
      emergency_contact_attributes: {
        name: emergencyContactName,
        phone_number: emergencyContactPhone,
        email: (emergencyContactEmailValid) ? emergencyContactEmail : '',
        relationship: emergencyContactRelationship
      }
    };

    return attributes;
  }

  onSubmit() {
    if (typeof this.props.onSubmit === 'function') {
      this.setState({ submitted: true });

      if (!this.isValid()) {
        return;
      }

      this.props.onSubmit(this.getAttributes(false));
    }
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  scrollIntoView(alignTopOrOptions = true) {
    if (this.ref.current) {
      this.ref.current.scrollIntoView(alignTopOrOptions);
    }
  }
}

EditPlayerContactInfoCardForm.propTypes = {
  user: PropTypes.object.isRequired,
  onSubmit: PropTypes.func
};

export default EditPlayerContactInfoCardForm;
