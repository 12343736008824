const EN = {
  numberListLabel: 'Number List',
  correct: 'Correct',
  incorrect: 'Incorrect',
  reset: 'Reset',
  numbersComplete: 'Numbers complete.',
  multipleErrors: 'The individual had 2 consecutive errors',
  numbersScore: 'Numbers in Reverse score:',
  defaultListSelectText: 'Select List to Start',
  numbersTestInstructionText: 'Select a number list to start the test. Use the buttons below the number list to control correct(green check)/incorrect(red x) responses. Use the back arrow button to undo the last response. Follow the blue highlighted numbers.',
  numbersCompleteWithErrors: 'Patient has scored two (2) consecutive errors.',
  numbersIncompleteError: 'Please complete the numbers in reverse section'
};

export default EN;
