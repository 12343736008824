import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { withRegistrationStep } from '../step-wrappers';
import { registrationUpdateUserAsync } from '@/redux/thunks/users';
import { ErrorBanner } from '../../errors';
import Activity from '../../activity';
import GuardianRegistrationAboutPlayerForm from './guardian-register-about-form';
import Strings from './lang';

const GuardianRegistrationAbout = ({
  currentUser,
  next,
  isReturn
}) => {
  const dispatch = useDispatch();
  const [activity, setActivity] = useState(false);
  const [error, setError] = useState(null);

  const updateUser = useCallback((attributes) => {
    setActivity(true);
    setError(null);

    dispatch(registrationUpdateUserAsync(currentUser.id, attributes)).then(() => {
      setActivity(false);
      next();
    }).catch(error => {
      setActivity(false);
      setError(error.message);
    });
  }, [currentUser.id, dispatch, next]);

  return (
    <div className="registration-guardian-about">
      <h1 className="display dot text-center">{Strings.title}</h1>
      <h2 className="text-center">
        {isReturn ? Strings.guardianReturningSubTitle : Strings.guardianAboutSubTitle}
      </h2>
      <ErrorBanner error={error} />
      <Activity active={activity}>
        <GuardianRegistrationAboutPlayerForm
          user={currentUser}
          onSubmit={updateUser}
        />
      </Activity>
    </div>
  );
};

export default withRegistrationStep('about')(GuardianRegistrationAbout);
