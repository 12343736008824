const FR = {
  title: 'Test orthostatique des signes vitaux',
  subTitle: 'Test debout actif modifié',
  supineLabel: 'Couché',
  seatedLabel: 'Assise',
  standingLabel: 'Debout',
  submitText: 'Soumettre',
  positionLabel: 'Position',
  heartRateInputLabel: 'Rythme cardiaque',
  heartRateInputErrorMessage: 'Veuillez entrer une fréquence cardiaque valide (> 0)',
  bpInputLabel: 'Pression artérielle (SBP/DBP)',
  bpSystolicLabel: 'Systolique',
  bpSystolicInputErrorMessage: 'Veuillez entrer une valeur systolique valide (> 0)',
  bpDiastolicLabel: 'Diastolique',
  bpDiastolicInputErrorMessage: 'Veuillez entrer une valeur diastolique valide (> 0)',
  resultNormal: 'Normal',
  resultOrthostaticHypotension: 'Hypotension orthostatique',
  resultOrthostaticTachycardia: 'Tachycardie orthostatique',
  resultBorderlineOrthostaticTachycardia: 'Tachycardie orthostatique limite',
  resultHypovolemia: 'Hypovolémie',
  resultLabel: 'Résultat',
  supineInstructions: 'Prendre des mesures après 2 minutes en décubitus dorsal',
  seatedInstructions: 'Prendre les mesures après 2 minutes en position assise droite',
  standingInstructions: 'Prendre des mesures après 2 minutes debout sans support',
  stepLabel: 'Marcher',
  testInstructionsTitle: 'Instructions',
  testInstructions: 'Il s\'agit du test Active Standing modifié qui a été proposé par Pearson et al., 2022 comme mesure des intolérances orthostatiques chez les patients commotionnés.Debout et Debout. Après 2 minutes dans chaque position, la fréquence cardiaque et la tension artérielle du patient(à l\'aide d\'un sphygmomanomètre automatisé) doivent être enregistrées dans les cases ci-dessous.',
  showResultsCalculationLabel: 'Afficher les calculs de résultats',
  hideResultsCalculationLabel: 'Masquer les calculs de résultats'
};
 
export default FR;
