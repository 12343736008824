import React, { useCallback, useState } from 'react';
import { Case, Switch } from 'react-if';
import { FormInput } from '@/forms';
import { useLazyRef, useUnmount } from '@/hooks';
import ElapsedTimer from '@/utilities/timers/elapsed-timer';
import Strings from './lang';
import Icon from '../../../icon';

const TIMER_INTERVAL = 10;

const STATES = {
  NOT_STARTED: 'NOT_STARTED',
  STARTED: 'STARTED',
  STOPPED: 'STOPPED'
};

const TimerControls = ({
  state,
  disabled = false,
  onStart,
  onStop,
  onReset
}) => {
  return (
    <div className="timer-controls">
      <Switch>
        <Case condition={state === STATES.NOT_STARTED}>
          <button
            type="button"
            aria-label={Strings.startTimerLabel}
            title={Strings.startTimerLabel}
            disabled={disabled}
            className="btn btn-sm btn-primary"
            onClick={onStart}
          >
            <Icon name="play" />
          </button>
        </Case>
        <Case condition={state === STATES.STARTED}>
          <button
            type="button"
            aria-label={Strings.stopTimerLabel}
            title={Strings.stopTimerLabel}
            disabled={disabled}
            className="btn btn-sm btn-default"
            onClick={onStop}
          >
            <Icon name="stop" />
          </button>
        </Case>
        <Case condition={state === STATES.STOPPED}>
          <button
            type="button"
            aria-label={Strings.resetTimerLabel}
            title={Strings.resetTimerLabel}
            disabled={disabled}
            className="btn btn-sm btn-warning"
            onClick={onReset}
          >
            <Icon name="repeat" />
          </button>
        </Case>
      </Switch>
    </div>
  );
};

const formatInputValue = (isTimer, timerValue, inputValue) => {
  if (isTimer) {
    return (timerValue / 1000).toFixed(2);
  }

  return inputValue !== '' ? (inputValue / 1000) : '';
};

const TimerInput = ({
  value,
  disabled = false,
  onChange,
  onStart,
  onStop
}) => {
  const [timerState, setTimerState] = useState(() => (value ? STATES.STOPPED : STATES.NOT_STARTED));
  const [isTimer, setIsTimer] = useState(false);
  const [timerValue, setTimerValue] = useState(value || 0);

  const onTimerTick = useCallback((msDuration) => {
    setTimerValue(msDuration);
  }, []);

  const timer = useLazyRef(() => (
    new ElapsedTimer(TIMER_INTERVAL, onTimerTick)
  ));

  const handleStart = useCallback(() => {
    setTimerState(STATES.STARTED);
    setIsTimer(true);
    timer.current.start();
    onStart();
  }, [onStart, timer]);

  const handleStop = useCallback(() => {
    timer.current.stop();
    setTimerState(STATES.STOPPED);
    onStop();
    onChange(timerValue);
  }, [onChange, onStop, timer, timerValue]);

  const handleReset = useCallback(() => {
    setTimerState(STATES.NOT_STARTED);
    setIsTimer(false);
    setTimerValue(0);
    onChange('');
  }, [onChange]);

  const handleChange = useCallback((e) => {
    const value = e.target.value ? (e.target.valueAsNumber * 1000) : '';
    setTimerValue(0);
    setIsTimer(false);
    setTimerState(value === '' ? STATES.NOT_STARTED : STATES.STOPPED);

    if (!isNaN(value) && value >= 0) {
      onChange(value);
    }
  }, [onChange]);

  useUnmount(() => {
    if (timer.current.isRunning()) {
      timer.current.stop();
    }
  });

  return (
    <div className="tandem-gait-timer-input">
      <div className="input-group">
        <FormInput
          type="number"
          className="form-control" 
          inputProps={{
            value: formatInputValue(isTimer, timerValue, value),
            step: 0.01,
            min: 0.0,
            placeholder: '- -',
            disabled: disabled || timer.current.isRunning(),
            onChange: handleChange
          }}
        />
        <span className="input-group-addon">
          {Strings.secondsLabel}
        </span>
      </div>
      <TimerControls
        state={timerState}
        disabled={disabled}
        onStart={handleStart}
        onStop={handleStop}
        onReset={handleReset}
      />
    </div>
  );
};

export default TimerInput;
