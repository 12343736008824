import { UPDATE_CLINIC, UPDATE_CLINICS, UPDATE_CLINICS_META_KEY } from '../actions/clinics';

const initialState = {
  meta: {
    indexFetched: false
  }
};

const updateClinicReducer = (state = {}, action = {}) => {
  const { clinic = {} } = action;
  const { id } = clinic;
  const stateClinic = state[id] || {};

  return (id) ? { 
    ...state, 
    [id]: {
      ...stateClinic,
      ...clinic 
    }
  } : state;
};

const updateClinicsReducer = (state = {}, action = {}) => {
  const { clinics = [] } = action;
  return clinics.reduce((allClinics, clinic) => {
    return updateClinicReducer(allClinics, { clinic });
  }, state);
};

const updateClinicsMetaKeyReducer = (state = {}, action = {}) => {
  const { key, value } = action;
  const { meta = {} } = state;
  return {
    ...state,
    meta: {
      ...meta,
      [key]: value
    }
  };
};

const clinics = (state = initialState, action = {}) => {
  switch (action.type) {
    case UPDATE_CLINIC:
      return updateClinicReducer(state, action);
    case UPDATE_CLINICS:
      return updateClinicsReducer(state, action);
    case UPDATE_CLINICS_META_KEY:
      return updateClinicsMetaKeyReducer(state, action);
    default:
      return state;
  }
};

export default clinics;
