import React from 'react';
import Panel from '../panel';
import PanelHeader from './panel-header';
import SpecialistNotesDisplay from './specialist-notes-display';

const SpecialistNotesPanels = React.forwardRef(({
  notes = [],
  injury,
  user,
  currentUser,
  actionable = true,
  sticky = true,
  onEdit
}, ref) => {
  return (
    <Panel.Group ref={ref} collapsible>
      {notes.map((note, index) => {
        const isNoteCreator = !!currentUser?.id && currentUser?.id === note?.clinic_user?.id;
        return (
          <Panel 
            key={note.id} 
            className="specialist-note-panel"
            collapsedClassName="no-print"
          >
            <Panel.Heading sticky={sticky}>
              <PanelHeader 
                note={note} 
                number={index + 1} 
                editable={actionable}
                isNoteCreator={isNoteCreator}
                currentUser={currentUser}
                onEdit={() => onEdit(note.id)}
              />
            </Panel.Heading>
            <Panel.Content>
              <SpecialistNotesDisplay 
                note={note} 
                user={user}
                injury={injury}
              />
            </Panel.Content>
          </Panel>
        );
      })}
    </Panel.Group>
  );
});

export default SpecialistNotesPanels;
