import { getLocalStorageItem, setLocalStorageItem } from '@/utilities/local-storage';

const CLINIC_PATIENTS_TABLE_SORT_KEYPATH_KEY = 'com.completeconcussions.clinic.patients.sort.key.path';

const getClinicPatientsTableSortKeyPath = () => {
  return getLocalStorageItem(CLINIC_PATIENTS_TABLE_SORT_KEYPATH_KEY) || '';
};

const setClinicPatientsTableSortKeyPath = (keyPath = '') => {
  return setLocalStorageItem(CLINIC_PATIENTS_TABLE_SORT_KEYPATH_KEY, keyPath);
};

export {
  getClinicPatientsTableSortKeyPath,
  setClinicPatientsTableSortKeyPath
};
