import React from 'react';
import PropTypes from 'prop-types';
import BaselineSection from './baseline-section';
import Strings from './lang';

const CARD_TIME_DIVISOR = 1000;
const FLOAT_FIXED_VALUE = 2;

const calculateCardsTotals = (cards) => {
  return cards.reduce((totals, card) => {
    const { milliseconds = 0, errors = 0 } = totals;
    return {
      milliseconds: milliseconds + parseFloat(card.milliseconds),
      errors: errors + parseFloat(card.errors)
    };
  }, {});
};

const convertToFormattedSeconds = (ms) => {
  return (ms / CARD_TIME_DIVISOR).toFixed(FLOAT_FIXED_VALUE);
};

const BaselineKingDevick = ({
  data = {}
}) => {
  const { 
    version = 1, cards = [], notes = '', not_done = false 
  } = data;
  const totals = calculateCardsTotals(cards);

  return (
    <BaselineSection
      className="baseline-king-devick"
      title={Strings.kingDevickTitle}
      notes={notes}
      sectionCompleted={Object.keys(data || {}).length > 0 && !not_done}
    >
      <p>
        <strong>{Strings.versionLabelText}</strong> {version}
      </p>
      <table className="table">
        <thead>
          <tr>
            <th className="no-action">{Strings.cardLabelText}</th>
            <th className="no-action">{Strings.secondsLabelText}</th>
            <th className="no-action">{Strings.errorsLabelText}</th>
          </tr>
        </thead>
        <tbody>
          {cards.map((card, index) => {
            return (
              <tr key={index}>
                <td><strong>{index + 1}</strong></td>
                <td>{convertToFormattedSeconds(card.milliseconds)}</td>
                <td>{card.errors}</td>
              </tr>
            );
          })}
          <tr className="totals">
            <td><strong>{Strings.totalLabelText}</strong></td>
            <td><strong>{convertToFormattedSeconds(totals.milliseconds)}</strong></td>
            <td><strong>{totals.errors}</strong></td>
          </tr>
        </tbody>
      </table>
    </BaselineSection>
  );
};

BaselineKingDevick.propTypes = {
  data: PropTypes.object.isRequired
};

export default BaselineKingDevick;
