import React from 'react';
import PropTypes from 'prop-types';
import NeuroStrings from '../sections/neuro-exams/lang';
import Strings from './lang';

const isEmptyValue = (value) => {
  return value === null || value === undefined || value === '';
};

const resolveStatusString = (status = 'not_done') => {
  switch (status) {
    case 'not_done':
      return NeuroStrings.notDoneLabel;
    case 'remarkable':
      return NeuroStrings.remarkableLabel;
    case 'unremarkable':
      return NeuroStrings.unremarkableLabel;
    default:
      return status;
  }
};

const renderNeurologicalResult = (title = null, result = {}, children = null) => {
  return (
    <div className="neurological-result">
      <div className="neurological-result-meta">
        <h3>{title}</h3>
        <h3><small>{resolveStatusString(result.status)}</small></h3>
      </div>
      <div className="notes">
        {children}
        {result.notes && result.notes.length > 0 
          && <p><strong>{NeuroStrings.notesLabel}:</strong> {result.notes}</p>}
      </div>
    </div>
  );
};

const PostInjuryNeurological = ({
  results = {}
}) => {
  const visualTracking = results.visual_tracking || {};
  return (
    <div className="post-injury-section post-injury-neurological">

      <div className="row">
        <div className="col-md-12">
          <h2 className="bold">{Strings.neurologicalTitle}</h2>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          {renderNeurologicalResult(
            NeuroStrings.cranialNerveScreenTitle, 
            results.cranial_nerve_screen
          )}
        </div>
        <div className="col-md-6">
          {renderNeurologicalResult(
            NeuroStrings.pronatorDriftTitle, 
            results.pronator_drift_testing
          )}
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          {renderNeurologicalResult(NeuroStrings.cerebellarTitle, results.cerebellar_testing)}
        </div>
        <div className="col-md-6">
          {renderNeurologicalResult(NeuroStrings.cervicalSpineTitle, results.cervical_spine_exam)}
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          {renderNeurologicalResult(
            NeuroStrings.upperLimbNeuroTitle, 
            results.upper_limb_neurological_testing
          )}
        </div>
        <div className="col-md-6">
          {renderNeurologicalResult(
            NeuroStrings.lowerLimbNeuroTitle, 
            results.lower_limb_neurological_testing
          )}
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          {renderNeurologicalResult(
            NeuroStrings.visualTrackingTitle, 
            visualTracking, 
            (
              <div className="row">
                <div className="col-md-6">
                  <strong>{NeuroStrings.convergenceLabel}:</strong>&nbsp;
                  {!isEmptyValue(visualTracking.convergence_distance_cm)
                    ? Strings.formatString(Strings.cmText, visualTracking.convergence_distance_cm) 
                    : NeuroStrings.notDoneLabel}
                </div>
                <div className="col-md-6">
                  <strong>{NeuroStrings.accomadationLabel}:</strong>&nbsp;
                  {!isEmptyValue(visualTracking.accomodation_distance_cm) 
                    ? Strings.formatString(Strings.cmText, visualTracking.accomodation_distance_cm) 
                    : NeuroStrings.notDoneLabel}
                </div>           
              </div>
            )
          )}
        </div>
      </div>

    </div>
  );
};

PostInjuryNeurological.propTypes = {
  results: PropTypes.object.isRequired
};

export default PostInjuryNeurological;
