const DateValidator = {
  test(value, min, max) {
    let valid = value.isValid();

    if (min && min.isValid() && value.isBefore(min)) {
      valid = false;
    }

    if (max && max.isValid() && value.isAfter(max)) {
      valid = false;
    }

    return valid;
  }
};

export default DateValidator;
