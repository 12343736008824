import { NumberValidator } from '@/forms';
import { 
  defaultBodyImpact, 
  defaultHeadImpact, 
  defaultAmnesia, 
  defaultInitialSymptoms, 
  defaultTimeline 
} from './defaults';

const getDefaultState = (props) => {
  const { characteristics = {} } = props;
  const {
    description = '',
    direct_head_impact = '',
    loss_of_consciousness = '',
    minutes_unconscious = '',
    cause = '',
    cause_sport = '',
    cause_other = '',
    cause_sport_other = '',
    seizures = '',
    seizures_notes = '',
    timeline = {},
    body_impact_location = {},
    head_impact_location = {},
    amnesia = {},
    initial_symptoms = {}
  } = characteristics;

  return {
    description,
    direct_head_impact,
    loss_of_consciousness,
    minutes_unconscious,
    cause,
    cause_sport,
    cause_other,
    cause_sport_other,
    seizures,
    seizures_notes,
    timeline: { ...defaultTimeline, ...timeline },
    body_impact_location: { ...defaultBodyImpact, ...body_impact_location },
    head_impact_location: { ...defaultHeadImpact, ...head_impact_location },
    amnesia: { ...defaultAmnesia, ...amnesia },
    initial_symptoms: { ...defaultInitialSymptoms, ...initial_symptoms }
  };
};

const validateInjuryCause = (causeState) => {
  const { 
    cause, cause_sport, cause_other, cause_sport_other 
  } = causeState;

  if (cause === '') {
    return false;
  }

  if (cause === 'Sport' && cause_sport === '') {
    return false;
  }

  if (cause_sport === 'Other' && cause_sport_other === '') {
    return false;
  }

  if (cause === 'Other' && cause_other === '') {
    return false;
  }

  return true;
};

const validateLOC = (loc) => {
  const { loss_of_consciousness, minutes_unconscious } = loc;

  if (loss_of_consciousness === '') {
    return false;
  }

  if (loss_of_consciousness === 'Yes' && !NumberValidator.test(minutes_unconscious)) {
    return false;
  }

  return true;
};

const validateAmnesia = (amnesia) => {
  const { 
    none, retrograde, anterograde, minutes_retrograde, minutes_anterograde 
  } = amnesia;

  if (!none && !retrograde && !anterograde) {
    return false;
  }

  if (retrograde && !NumberValidator.test(minutes_retrograde)) {
    return false;
  }

  if (anterograde && !NumberValidator.test(minutes_anterograde)) {
    return false;
  }

  return true;
};

export {
  getDefaultState,
  validateInjuryCause,
  validateLOC,
  validateAmnesia
};
