const ProfessionStrings = {
  athletic_therapist: 'Athletic Therapist',
  athletic_trainer: 'Athletic Trainer',
  chiropractor: 'Chiropractor',
  doctor_of_osteopathy: 'Doctor of Osteopathy',
  doctor_of_physical_therapy: 'Doctor of Physical Therapy',
  kinesiologist: 'Kinesiologist',
  medical_doctor: 'Medical Doctor',
  massage_therapist: 'Massage Therapist',
  mental_health_professional: 'Mental Health Professional',
  naturopathic_doctor: 'Naturopathic Doctor',
  neurologist: 'Neurologist',
  neuropsychologist: 'Neuropsychologist',
  neurosurgeon: 'Neurosurgeon',
  neuro_optometrist: 'Neuro-Optometrist',
  nurse_practitioner: 'Nurse Practitioner',
  occupational_therapist: 'Occupational Therapist',
  osteopathic_practitioner: 'Osteopathic Practitioner',
  physician_assistant: 'Physician Assistant',
  physical_therapist: 'Physical Therapist',
  physiotherapist: 'Physiotherapist',
  psychiatrist: 'Psychiatrist',
  psychologist: 'Psychologist',
  psychotherapist: 'Psychotherapist',
  registered_nurse: 'Registered Nurse',
  social_worker: 'Social Worker',
  speech_language_pathologist: 'Speech Language Pathologist',
  sports_massage_therapist: 'Sports Massage Therapist',
  sports_medicine_physician: 'Sports Medicine Physician',
  sports_physiotherapist: 'Sports Physiotherapist',
  sports_specialist_chiropractor: 'Sports-Specialist Chiropractor',
  sports_therapist: 'Sports Therapist',
};

const ClinicalServiceStrings = {
  active_release_therapy: 'Active Release Therapy', 
  acupuncture: 'Acupuncture/Dryneedling',
  cognitive_assessment: 'Cognitive Assessment',
  cognitive_rehab: 'Cognitive Rehab',
  dietary_advice: 'Dietary/Supplement Interventions/Advice',
  exercise_rehab: 'Exercise Rehab',
  functional_medicine: 'Functional/Naturopathic Medicine',
  myofascial_release: 'Myofascial Release', 
  neck_manipulation: 'Neck Manipulation', 
  neck_rehab: 'Neck Rehab', 
  neuropsychological_assessment: 'Neuropsychological Assessment',
  vestibular_rehab: 'Vestibular Rehab',
  vision_rehab: 'Vision Rehab'
};

const getClinicalServiceStrings = (services = []) => {
  return services.map(service => ClinicalServiceStrings[service]).filter(Boolean).join(', ');
};

const servicesSelectOptions = Object.keys(ClinicalServiceStrings).map(key => ({
  value: key,
  label: ClinicalServiceStrings[key]
}));

export {
  ProfessionStrings,
  ClinicalServiceStrings,
  getClinicalServiceStrings,
  servicesSelectOptions
};
