import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SymptomLevels, defaultSymptoms, mergeSymptoms } from '../../../symptoms';
import { FormComponent, RadioInputGroup } from '@/forms';
import Strings from './lang';
import SharedStrings from '../lang';

const defaultExertion = {
  physical_activity: '',
  mental_activity: '',
  overall_rating: ''
};

class CurrentSymptomLevelsForm extends Component {
  constructor(props) {
    super(props);

    const { symptomLevels = {} } = props;
    const { worse_with_exertion = { ...defaultExertion }, ...symptoms } = symptomLevels;

    this.onSubmit = this.onSubmit.bind(this);
    this.onUpdateSymptoms = this.onUpdateSymptoms.bind(this);

    this.state = {
      symptoms: {
        ...mergeSymptoms(defaultSymptoms, { ...symptoms })
      },
      worse_with_exertion,
      submitted: false
    };
  }

  componentDidMount() {
    this._callOnUpdate();
  }

  render() {
    const submitted = (this.state.submitted || this.props.submitted);
    return (
      <FormComponent onSubmit={this.onSubmit}>
        <SymptomLevels
          type="radio"
          className="baseline-card"
          symptoms={this.state.symptoms} 
          onUpdate={this.onUpdateSymptoms}
          touched={submitted}
        />
        <div className="card card-form">
          <h4>{Strings.symptomExertionTitleText}</h4>
          <div className="row">

            <div className="col-md-6">
              <RadioInputGroup
                titleLabelText={
                  <span className="bold">{Strings.physicalActivityLabel} <span className="required">*</span></span>
                }
                className="form-group"
                radioLabels={[
                  Strings.activityYesLabel, 
                  Strings.activityNoLabel, 
                  Strings.activityHaventTriedLabel
                ]}
                radioValues={['yes', 'no', 'havent_tried']}
                initialValue={this.state.worse_with_exertion.physical_activity}
                inputProps={{
                  className: 'form-control'
                }}
                required
                touched={submitted}
                inputValid={this.state.worse_with_exertion.physical_activity !== ''}
                messageClassName="alert alert-danger"
                messageText={Strings.noValueSelectedErrorMessage}
                onUpdate={(value) => this.onUpdateExertion('physical_activity', value)}
              />
            </div>

            <div className="col-md-6">
              <RadioInputGroup
                titleLabelText={
                  <span className="bold">{Strings.mentalActivityLabel} <span className="required">*</span></span>
                }
                className="form-group"
                radioLabels={[
                  Strings.activityYesLabel, 
                  Strings.activityNoLabel, 
                  Strings.activityHaventTriedLabel
                ]}
                radioValues={['yes', 'no', 'havent_tried']}
                initialValue={this.state.worse_with_exertion.mental_activity}
                inputProps={{
                  className: 'form-control'
                }}
                required
                touched={submitted}
                inputValid={this.state.worse_with_exertion.mental_activity !== ''}
                messageClassName="alert alert-danger"
                messageText={Strings.noValueSelectedErrorMessage}
                onUpdate={(value) => this.onUpdateExertion('mental_activity', value)}
              />
            </div>

          </div>

          <div>

            <label className="bold uppercase">{Strings.overallRatingLabel}</label>
            <p className="text-muted"><i>{Strings.overallRatingInfoText}</i></p>
            <div className="overall-rating">
              <div className="rating-left">{Strings.overallRatingNormalText}</div>
              <RadioInputGroup
                className="form-group"
                radioValues={['0', '1', '2', '3', '4', '5', '6']}
                initialValue={this.state.worse_with_exertion.overall_rating}
                inputProps={{
                  className: 'form-control'
                }}
                required={false}
                inputValid
                onUpdate={(value) => this.onUpdateExertion('overall_rating', value)}
              />
              <div className="rating-right">{Strings.overallRatingVeryDifferentText}</div>
            </div>

          </div>

          <div className="form-footer">
            <button type="submit" className="btn btn-primary">{SharedStrings.saveAndContinueButtonText}</button>
          </div>

        </div>
      </FormComponent>
    );
  }

  onUpdateExertion(key, value) {
    const { worse_with_exertion } = this.state;
    worse_with_exertion[key] = value;
    this.setState({
      worse_with_exertion
    }, this._callOnUpdate.bind(this));
  }
  
  onUpdateSymptoms(symptoms) {
    this.setState({
      symptoms
    }, this._callOnUpdate.bind(this));
  }

  isValid() {
    const { worse_with_exertion, symptoms } = this.state;

    if (Object.keys(symptoms).some(key => isNaN(parseInt(symptoms[key].level, 10)))) {
      return false;
    }

    if (Object.keys(worse_with_exertion).some(key => worse_with_exertion[key] === '' && key !== 'overall_rating')) {
      return false;
    }

    return true;
  }

  getAttributes() {
    const { symptoms, worse_with_exertion } = this.state;

    return {
      ...symptoms,
      worse_with_exertion
    };
  }

  onSubmit() {
    this.setState({ submitted: true });

    if (!this.isValid()) {
      this.onError();
      return;
    }

    const attributes = {
      current_rated_symptoms: {
        ...this.getAttributes()
      }
    };

    this.props.onSubmit(attributes);
  }

  _callOnUpdate() {
    if (typeof this.props.onUpdate === 'function') {
      this.props.onUpdate(this.getAttributes(), this.isValid());
    }
  }

  onError() {
    if (typeof this.props.onError === 'function') {
      this.props.onError();
    }
  }
}

CurrentSymptomLevelsForm.propTypes = {
  symptomLevels: PropTypes.object,
  submitted: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onUpdate: PropTypes.func
};

export default CurrentSymptomLevelsForm;
