import React, { Component } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { If, Then, Else } from 'react-if';
import { DISPLAY_DATE_FORMAT } from '@/utilities/dates/formats';
import { splitMedicalList } from './medical-info-utilities';
import MedicalListTable from './medical-list-table';
import RemovedItemsTable from './removed-items-table';
import Strings from './lang';

class PlayerMedicalInfoCard extends Component {
  constructor(props) {
    super(props);

    const { medicalInfo = {} } = props;

    const {
      medications = [],
      medical_conditions = [],
      concussion_ever_diagnosed = false,
      concussion_history = {},
      medical_history = {}
    } = medicalInfo;

    const otherCondition = !!medical_history.other_mental_health_diagnosed 
      && !!medical_history.other_mental_health_diagnosed.length;

    this.state = {
      medications: { ...splitMedicalList(medications) },
      medicalConditions: { ...splitMedicalList(medical_conditions) },
      concussionDiagnosed: (concussion_ever_diagnosed) ? 'yes' : 'no',
      concussionCount: concussion_history.how_many || 0,
      concussionDate: concussion_history.most_recent || '',
      concussionRecoveryDuration: concussion_history.recovery_time_count || 0,
      concussionRecoveryDurationUnit: concussion_history.recovery_time_units || 'days',
      concussionSymptoms: concussion_history.still_have_symptoms || 'no',
      concussionSymptomsStrength: concussion_history.subsequent_concussions_easier || 'no',
      concussionNotes: concussion_history.notes || '',
      behaviouralDisorder: medical_history.behavioural_disorder || 'none',
      learningDisability: medical_history.learning_disability || 'no',
      headacheHx: medical_history.headache_history || 'no',
      headacheNotes: medical_history.headache_notes || '',
      anxiety: medical_history.anxiety_diagnosed || false,
      depression: medical_history.depression_diagnosed || false,
      sleepDisorder: medical_history.sleep_disorder_diagnosed || false,
      otherCondition,
      otherConditionNotes: medical_history.other_mental_health_diagnosed || ''
    };
  }

  render() {
    return (
      <div className="card card-show">

        <div className="card-row">
          <label>{ Strings.concussionDiagnosedLabelText }:</label>
          <span className="capitalize">{ this.state.concussionDiagnosed }</span>
        </div>

        <If condition={this.state.concussionDiagnosed === 'yes'}>
          <Then>
            <div className="concussion-diagnosed">

              <div className="card-row">
                <label>{ Strings.concussionCountLabelText }:</label>
                <span>{ this.state.concussionCount }</span>
              </div>

              <div className="card-row">
                <label>{ Strings.concussionDateLabelText }:</label>
                <span>{dayjs(this.state.concussionDate).format(DISPLAY_DATE_FORMAT)}</span>
              </div>

              <div className="card-row">
                <label>{ Strings.concussionRecoveryLabelText }:</label>
                <span className="capitalize">{ this.state.concussionRecoveryDuration } { this.state.concussionRecoveryDurationUnit }</span>
              </div>

              <div className="card-row">
                <label>{ Strings.concussionSymptomsLabelText }:</label>
                <span className="capitalize">{ this.state.concussionSymptoms }</span>
              </div>

              <div className="card-row">
                <label>{ Strings.concussionSymptomsStrengthLabelText }:</label>
                <span className="capitalize">{ this.state.concussionSymptomsStrength }</span>
              </div>

              <div className="card-row">
                <label>{ Strings.concussionNotesLabelText }:</label>
                <span>{ this.state.concussionNotes }</span>
              </div>

            </div>
          </Then>
        </If>

        <div className="card-row card-table-row">
          <label>{Strings.currentMedicalConditionsLabel}:</label>
          <If condition={this.state.medicalConditions.current.length > 0}>
            <Then>
              <MedicalListTable
                removable={false}
                items={this.state.medicalConditions.current}
                nameLabel={Strings.conditionsTableHeadingText}
              />
            </Then>
            <Else>
              <span>{Strings.noneText}</span>
            </Else>
          </If>
        </div>

        {this.state.medicalConditions.removed.length > 0 && (
          <div className="card-row card-table-row">
            <label>{Strings.previousMedicalConditionsLabel}:</label>
            <RemovedItemsTable
              nameLabel={Strings.conditionsTableHeadingText}
              items={this.state.medicalConditions.removed}
            />
          </div>
        )}

        <div className="card-row card-table-row">
          <label>{Strings.currentMedicationsLabel}:</label>
          <If condition={this.state.medications.current.length > 0}>
            <Then>
              <MedicalListTable
                removable={false}
                items={this.state.medications.current}
                nameLabel={Strings.medicationsLabelText}
              />
            </Then>
            <Else>
              <span>{Strings.noneText}</span>
            </Else>
          </If>
        </div>

        {this.state.medications.removed.length > 0 && (
          <div className="card-row card-table-row">
            <label>{Strings.previousMedicationsLabel}:</label>
            <RemovedItemsTable
              nameLabel={Strings.medicationsLabelText}
              items={this.state.medications.removed}
            />
          </div>
        )}

        <div className="card-row">
          <label>{ Strings.behaviouralDisorderLabelText }:</label>
          <span className="uppercase">{ this.state.behaviouralDisorder }</span>
        </div>

        <div className="card-row">
          <label>{ Strings.learningDisabilityLabelText }:</label>
          <span className="capitalize">{ this.state.learningDisability }</span>
        </div>

        <div className="card-row">
          <label>{ Strings.headacheHistoryLabelText }:</label>
          <span className="capitalize">{ this.state.headacheHx }</span>
        </div>

        <div className="card-row">
          <label>{ Strings.headacheNotesLabelText }:</label>
          <span>{ this.state.headacheNotes }</span>
        </div>

        <div className="card-row">
          <label>{ Strings.anxietyLabelText }:</label>
          <span className="capitalize">{ this.state.anxiety ? 'yes' : 'no' }</span>
        </div>

        <div className="card-row">
          <label>{ Strings.depressionLabelText }:</label>
          <span className="capitalize">{ this.state.depression ? 'yes' : 'no' }</span>
        </div>

        <div className="card-row">
          <label>{ Strings.sleepDisorderLabelText }:</label>
          <span className="capitalize">{ this.state.sleepDisorder ? 'yes' : 'no' }</span>
        </div>

        <If condition={this.state.otherCondition}>
          <Then>
            <div className="card-row">
              <label>{ Strings.otherConditionLabelText }:</label>
              <span>{ this.state.otherConditionNotes }</span>
            </div>
          </Then>
        </If>

      </div>
    );
  }
}

PlayerMedicalInfoCard.propTypes = {
  medicalInfo: PropTypes.object.isRequired
};

export default PlayerMedicalInfoCard;
