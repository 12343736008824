import React, { forwardRef } from 'react';
import { KingDevickCard } from '../baseline-test-cards';

const KingDevickStep = forwardRef((
  {
    user = {},
    baseline = {},
    onSubmit,
    ...rest
  },
  ref
) => {
  return (
    <KingDevickCard
      ref={ref}
      birthday={user?.person?.birthday}
      kingDevick={{ ...(baseline?.king_devick_test || {}) }}
      onSubmit={onSubmit}
      {...rest}
    />
  );
});

KingDevickStep.hasChanged = (initial, current) => {
  let cardChange = false;
  const currentCards = current.cards || [];

  for (let i = 0; i < currentCards.length; i += 1) {
    const initialCard = (initial.cards || [])[i] || {};
    const ownCard = currentCards[i] || {};

    if ((initialCard.errors || 0) !== ownCard.errors
      || (initialCard.milliseconds || 0) !== ownCard.milliseconds
    ) {
      cardChange = true;
      break;
    }
  }

  return cardChange
    || (initial.notes || '') !== current.notes
    || (initial.version || 1) !== current.version;
};

export default KingDevickStep;
