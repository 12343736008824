const FR = {
  assignClinicModalTitle: 'Clinique présentement assignée',
  assignClinicModalMessage: 'Êtes-vous certain de vouloir assigner {0} en tant que clinique actuelle de {1} ?',
  assignClinicModalConfirmButtonTitle: 'Assigner',
  modalNameRedactedText: 'ce patient',
  noneText: 'Aucun',
  noMedicalHistoryInfoText: '{0} n\'a aucune information d\'antécédents médicaux.',
  patientText: 'Patient',
  createMedicalInfoButtonText: 'Ajouter des infos médicales'
};
 
export default FR;
