import {
  jsonApiRead,
  jsonApiCreate,
  jsonApiUpdate
} from '@/lib/requests/jsonapi';

import { updateClinic, updateClinics } from '../actions/clinics';

const getClinicAsync = (id) => {
  return (dispatch) => {
    return jsonApiRead({ type: 'clinics', id }).then(clinic => {
      dispatch(updateClinic(clinic));
      return Promise.resolve(clinic);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const createClinicAsync = (attributes = {}) => {
  return (dispatch) => {
    return jsonApiCreate({ type: 'clinics', attributes }).then(clinic => {
      dispatch(updateClinic(clinic));
      return Promise.resolve(clinic);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const updateClinicAsync = (id, attributes = {}) => {
  return (dispatch) => {
    return jsonApiUpdate({ type: 'clinics', id, attributes }).then(clinic => {
      dispatch(updateClinic(clinic));
      return Promise.resolve(clinic);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const activateClinicAsync = (id, attributes = {}) => {
  return (dispatch) => {
    return jsonApiUpdate({ type: 'clinics', id, attributes }, { path: `clinics/${id}/activate` }).then(clinic => {
      dispatch(updateClinic(clinic));
      return Promise.resolve(clinic);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const activateMapleAsync = (id, attributes) => {
  return (dispatch) => {
    return jsonApiUpdate({ type: 'clinic', id, attributes }, { path: `clinics/${id}/activate_maple` }).then(clinic => {
      dispatch(updateClinic(clinic));
      return Promise.resolve(clinic);
    });
  };
};

const getClinicsAsync = (filters = {}) => {
  const queryParams = { page: 1, active: true, ...filters };

  return (dispatch) => {
    return jsonApiRead({ type: 'clinics' }, { path: 'clinics', queryParams }).then(({ data, meta }) => {
      dispatch(updateClinics(data));
      return Promise.resolve({ 
        clinics: data, 
        pagination: meta 
      });
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

export {
  getClinicAsync,
  createClinicAsync,
  updateClinicAsync,
  activateClinicAsync,
  activateMapleAsync,
  getClinicsAsync
};
