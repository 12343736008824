import React, { useMemo } from 'react';
import {
  ResponsiveContainer,
  Area,
  AreaChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ReferenceLine,
} from 'recharts';
import dayjs from 'dayjs';
import TestTooltip from './tooltip';
import Icon from '../../icon';
import { DATA_PADDING } from './utils';
import Strings from '../lang';

const ICON_SIZE = 14;

const isSuspectTest = (value, stdevRt1, stdevRt2 = 0) => {
  return value > stdevRt2 && value > stdevRt1;
};

const LineIcon = ({
  cx,
  cy,
  payload,
  stdev
}) => {
  if (payload.post_injury) {
    const isSuspect = isSuspectTest(payload.mean_rt_diff, payload.stdev_rt, stdev);
    return (
      <Icon
        prefix="fas"
        name={isSuspect ? 'triangle-exclamation' : 'bolt'}
        x={cx - (ICON_SIZE / 2)}
        y={cy - (ICON_SIZE / 2)}
        color="#DB231F"
        height={ICON_SIZE}
        width={ICON_SIZE}
        style={{ zIndex: 10 }}
      />
    );
  }

  return null;
};

const getDifference = (tests) => {
  const [first = [], second = []] = tests || [];
  return first.map((item) => {
    const secondItem = second.find(i => i.dana_battery_id === item.dana_battery_id);
    let value = null;

    if (secondItem) {
      value = parseFloat((secondItem.mean_rt - item.mean_rt)?.toFixed(2));
    }

    return {
      mean_rt_diff: value,
      stdev_rt: item.stdev_rt,
      post_injury: item.post_injury,
      lower_stdev_rt: item.stdev_rt * -1,
      dana_battery_id: item.dana_battery_id,
      date: dayjs(item.created_at).format('YYYY-MM-DD')
    };
  });
};

const getReferenceLines = (stat, series) => {
  return stat > 0 ? [
    <ReferenceLine
      y={stat}
      key={stat}
      stroke="#DB231F"
      strokeDasharray="10 3"
      label={{
        position: 'insideBottomLeft',
        fontSize: 10,
        value: `${series}2 ${Strings.firstUpperStdReferenceLineText}: ${stat.toFixed(2)}`
      }}
    />,
    <ReferenceLine
      y={-stat}
      key={-stat}
      stroke="#DB231F"
      strokeDasharray="10 3"
      label={{
        position: 'insideTopLeft',
        fontSize: 10,
        value: `${series}2 ${Strings.firstLowerStdReferenceLineText}: -${stat.toFixed(2)}`
      }}
    />
  ] : null;
};

const getGradientOffset = (data) => {
  const dataMax = Math.max(...data.map((i) => i.mean_rt_diff));
  const dataMin = Math.min(...data.map((i) => i.mean_rt_diff));

  if (dataMax <= 0) {
    return 0;
  }
  if (dataMin >= 0) {
    return 1;
  }

  return dataMax / (dataMax - dataMin);
};

const DanaRTDifferenceChart = ({
  series,
  tests = [],
  baseline,
  onDotClick
}) => {
  const data = useMemo(() => getDifference(tests), [tests]);
  const stat = baseline?.[1]?.stdev_mean_rt ?? 0;
  const gradientOffset = useMemo(() => getGradientOffset(data), [data]);
  const maxStd = useMemo(() => Math.max(...data.map(i => i.stdev_rt)), [data]);

  return (
    <ResponsiveContainer width="100%" height={450}>
      <AreaChart data={data}>
        <defs>
          <linearGradient id="diffAreaColors" x1="0" y1="0" x2="0" y2="1">
            <stop offset={gradientOffset} stopColor="#DB231F" stopOpacity={0.7} />
            <stop offset={gradientOffset} stopColor="#3CBE3F" stopOpacity={1} />
          </linearGradient>
        </defs>
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip content={<TestTooltip />} />
        <Legend />
        <Area
          connectNulls
          dataKey="mean_rt_diff"
          type="monotone"
          fill="url(#diffAreaColors)"
          stroke="url(#diffAreaColors)"
          fillOpacity={0.4}
          strokeWidth={1.3}
          isAnimationActive={false}
          name={Strings.formatString(Strings.rtDifferenceChartLabel, series)}
          dot={<LineIcon stdev={stat} />}
          activeDot={{
            r: 6,
            fill: '#fff',
            stroke: '#000',
            cursor: 'pointer',
            onClick: onDotClick
          }}
        />
        <Area
          connectNulls
          type="monotone"
          dataKey="stdev_rt"
          name={Strings.formatString(Strings.rtDifferenceStdevChartLabel, series)}
          isAnimationActive={false}
          dot={null}
          activeDot={null}
          style={{ pointerEvents: 'none' }}
          stroke="#85293D"
          fill="#fff"
          fillOpacity={0.4}
        />
        <XAxis
          dataKey="date"
          type="category"
          allowDuplicatedCategory={false}
          style={{ fontSize: 12 }}
        />
        <YAxis
          dataKey="mean_rt_diff"
          type="number"
          tickFormatter={(value) => Math.round(value)}
          domain={[
            (min) => Math.min(min, -stat) - DATA_PADDING,
            (max) => Math.max(stat, maxStd, max) + DATA_PADDING
          ]}
        />
        <ReferenceLine
          y={0}
          key={0}
          stroke="#000"
          label={{
            value: 0,
            position: 'insideBottomLeft',
          }}
        />
        {getReferenceLines(stat, series)}
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default DanaRTDifferenceChart;
