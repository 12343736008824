import React, { useMemo } from 'react';
import {
  OrderedSymptomKeys,
  SymptomStrings,
  calculateSymptomsTotals
} from '@/utilities/symptom-data';
import SymptomItem from './symptom-item';
import SymptomsTotalScore from './symptoms-total-score';
import SymptomsTotalCount from './symptoms-total-count';
import ColumnArraySplit from '../../column-array-split';
import Strings from './lang';

const SymptomsList = ({ symptoms, columns = 2, showTitle = true }) => {
  const totals = useMemo(() => calculateSymptomsTotals(symptoms || {}), [symptoms]);

  return (
    <div className="symptoms-list">
      {showTitle && (
        <h2 className="bold">{Strings.symptomsTitle}</h2>
      )}
      <ColumnArraySplit array={OrderedSymptomKeys} columns={columns}>
        {(key) => {
          const symptom = symptoms[key];
          if (symptom) {
            return (
              <SymptomItem
                key={key}
                title={SymptomStrings[key]}
                symptom={symptom}
              />
            );
          }

          return null;
        }}
      </ColumnArraySplit>

      <SymptomsTotalScore totals={totals} />
      <SymptomsTotalCount totals={totals} />
    </div>
  );
};

export default SymptomsList;
