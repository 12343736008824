import React from 'react';
import PropTypes from 'prop-types';
import { If, Then, Else } from 'react-if';
import classnames from 'classnames';
import { symptomLabels } from '../symptoms';
import Strings from './lang';
import { getSeverity, getTotals, MAX_SEVERITY } from './soap-utilities';
import { OrderedSymptomKeys } from '@/utilities/symptom-data';
import Fraction from '../fraction';

const SoapSectionSymptomDisplay = ({
  symptoms_scores
}) => {
  const totalSymptoms = Object.keys(symptoms_scores || {}).length;

  return (
    <div className={classnames('soap-symptom-display', { 'has-symptoms': totalSymptoms > 0 })}>
      <div className="row">
        <div className="col-md-12">
          <h2>{Strings.symptomsTitle}</h2>
        </div>
      </div>
      <div className="row">
        <If condition={totalSymptoms > 0}>
          <Then>
            <div>
              {OrderedSymptomKeys.map((key, index) => {
                return (
                  <div key={index} className="col-md-4">
                    <div>
                      {symptomLabels[key]}: 
                      <Fraction 
                        className="soap-symptom-score-display"
                        numerator={symptoms_scores[key]} 
                        denominator={MAX_SEVERITY}
                      />
                    </div>
                  </div>
                );
              })}
              <div className="score-view col-md-12">
                <label>{Strings.symptomsSeverityText}:</label> 
                <span className="score-view-score-display">{getSeverity(symptoms_scores)}</span>
                <small>/{MAX_SEVERITY * totalSymptoms}</small>
              </div>
              <div className="score-view col-md-12">
                <label>{Strings.symptomsTotalText}: </label>
                <span className="score-view-score-display">{getTotals(symptoms_scores)}</span>
                <small>/{totalSymptoms}</small>
              </div>
            </div>
          </Then>
          <Else>
            <div className="col-md-12 text-muted">
              {Strings.noSymptomsRecordedText}
            </div>
          </Else>
        </If>
      
      </div>
      
    </div>
  );
};

SoapSectionSymptomDisplay.propTypes = {
  symptoms_scores: PropTypes.object
};

export default SoapSectionSymptomDisplay;
