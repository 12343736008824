const PostInjuryAssessmentPaths = {
  index: {
    route: 'post-injury',
    link: '/post-injury'
  },
  startAssessment: {
    route: 'start',
    link: '/post-injury/start'
  },
  assessment: {
    route: 'assessment/:account',
    link: '/post-injury/assessment/'
  },
  checkInPatient: {
    route: 'start/check-in/:userId',
    link: '/post-injury/start/check-in/:userId'
  }
};

export default PostInjuryAssessmentPaths;
