const EN = {
  title: 'Emergency Contact',
  guardianSubTitle: '{0}\'s emergency contact information',
  returningSubTitle: 'Update your emergency contact information',
  nameLabelText: 'Contact Name',
  nameErrorText: 'Please enter your emergency contact\'s name.',
  phoneLabelText: 'Phone Number',
  phoneErrorText: 'Please enter your emergency contact\'s phone number.',
  relationshipLabelText: 'Relationship',
  relationshipErrorText: 'Please enter your relationship to your emergency contact.',
  submitButtonText: 'Save',
  emergencyContactEmailLabelText: 'Contact Email',
  emailInputPlaceholder: 'Emergency Contact Email'
};

export default EN;
