import React, { useCallback } from 'react';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import Icon from '../../icon';
import { usePdfDownload } from '@/hooks';
import { INPUT_DATE_FORMAT } from '@/utilities/dates/formats';
import { ClinicSelector, UserSelector } from '@/redux/selectors';
import Strings from '../lang';

const generateReportFilename = (user, startDate, endDate) => {
  const start = dayjs(startDate).format(INPUT_DATE_FORMAT);
  const end = dayjs(endDate).format(INPUT_DATE_FORMAT);

  return `dana-tests_${user.account}_${start}_${end}${process.env.NODE_ENV === 'development' ? `_${dayjs().unix()}` : ''}`;
};

const SummaryReportDownloadButton = ({
  user,
  batteries = [],
  dateRange
}) => {
  const clinic = useSelector(ClinicSelector.getCurrentClinic);
  const currentUser = useSelector(UserSelector.getCurrentUser);
  const { download, loading } = usePdfDownload(
    generateReportFilename(user, dateRange.startDate, dateRange.endDate),
    () => import(/* webpackChunkName: "dana-tests~pdf" */'./document')
  );

  const onDownload = useCallback(() => {
    download({
      batteries,
      clinic,
      dateRange,
      currentUser,
      user
    });
  }, [
    batteries,
    clinic,
    currentUser,
    dateRange,
    download,
    user
  ]);

  return (
    <button
      type="button"
      className="btn btn-light btn-sm"
      disabled={loading}
      onClick={onDownload}
    >
      {loading ? (
        <>
          {Strings.downloadingText}&nbsp;&nbsp;
          <Icon prefix="fad" name="loader" spinPulse />
        </>
      ) : (
        <>
          <Icon name="file-pdf" />&nbsp;
          {Strings.summaryReportButton}
        </>
      )}
      
    </button>
  );
};

export default SummaryReportDownloadButton;
