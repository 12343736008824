import { useEffect, useCallback } from 'react';

const EscapeKey = 'Escape';

const EscapeListener = ({ enabled = false, onEscape = () => {} }) => {
  const handleKeyPress = useCallback((e) => {
    if (enabled && e.key === EscapeKey) {
      onEscape();
    }
  }, [enabled, onEscape]);

  useEffect(() => {
    if (enabled) {
      window.addEventListener('keydown', handleKeyPress);
      return () => {
        window.removeEventListener('keydown', handleKeyPress);
      };
    }
    
    return undefined;
  }, [enabled, handleKeyPress]);

  return null;
};

export default EscapeListener;
