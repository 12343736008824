import React, { createContext, useContext, useMemo } from 'react';

const RegistrationContext = createContext({
  next: () => {},
  role: null
});

export const useRegistrationContext = () => useContext(RegistrationContext);

export const RegistrationProvider = ({
  next,
  role,
  children,
  ...rest
}) => {
  const context = useMemo(() => ({ ...rest, next, role }), [next, rest, role]);

  return (
    <RegistrationContext.Provider value={context}>
      {children}
    </RegistrationContext.Provider>
  );
};
