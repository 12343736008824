import React, { useMemo } from 'react';
import { If, Then } from 'react-if';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import dayjs from 'dayjs';
import replaceLinkParams from '@/utilities/replace-link-params';
import { age } from '@/utilities/dates';
import { DISPLAY_DATE_FORMAT } from '@/utilities/dates/formats';
import { ClinicPaths } from '@/paths';
import { PatientName } from '../patient-meta';
import Strings from './lang';
import Icon from '../icon';

const BASELINE_EXPIRY_WARNING_DAYS = 30;
const MAX_ACUTE_INJURY_DAYS = 10;
const MAX_SUBACUTE_INJURY_DAYS = 30;

const classForBaselineExpiryDate = (expiryDate) => {
  if (!expiryDate.isValid()) {
    return 'highlighted';
  }

  if (dayjs().isAfter(expiryDate)) {
    return 'red';
  }

  if (dayjs().add(BASELINE_EXPIRY_WARNING_DAYS, 'days').isAfter(expiryDate)) {
    return 'yellow';
  }

  return 'green';
};

const injuryStageTextForOngoingInjuryDate = (date) => {
  const injuryDate = dayjs(date);

  if (!date || !injuryDate.isValid()) {
    return '--';
  }

  const injuryAge = age(injuryDate, 'days');

  if (injuryAge >= MAX_SUBACUTE_INJURY_DAYS) {
    return Strings.formatString(Strings.injuryStageChronicText, injuryAge);
  }

  if (injuryAge > MAX_ACUTE_INJURY_DAYS && injuryAge < MAX_SUBACUTE_INJURY_DAYS) {
    return Strings.formatString(Strings.injuryStageSubacuteText, injuryAge);
  }

  return Strings.formatString(Strings.injuryStageAcuteText, injuryAge);
};

const DanaBaselineCountItem = ({ count }) => {
  if (!count) return '--';

  return (
    <div className="dana-baseline-indicators">
      <div className={classNames('indicator-item', { green: count >= 1 })}>
        <Icon prefix="fas" name="circle-check" />
      </div>
      <div className={classNames('indicator-item', { green: count >= 2 })}>
        <Icon prefix="fas" name="circle-check" />
      </div>
      {count > 2 && (
        <div className="indicator-item green">
          <Icon name="circle-plus" />
        </div>
      )}
    </div>
  );
};

const PatientTableRow = ({
  patient = {},
  viewNames = false,
  clinicId,
  showInjuryStage = false,
  showDanaBaseline = true
}) => {
  const { account, baseline_date, ongoing_injury_date } = patient || {};
  const baselineExpiryDate = useMemo(() => dayjs(baseline_date).add(1, 'years'), [baseline_date]);
  const baselineExpiryClass = useMemo(() => (
    classForBaselineExpiryDate(baselineExpiryDate)
  ), [baselineExpiryDate]);
  const injuryStageText = useMemo(() => (
    injuryStageTextForOngoingInjuryDate(ongoing_injury_date)
  ), [ongoing_injury_date]);

  return (
    <tr key={patient.id}>
      <If condition={viewNames}>
        <Then>
          <td className="highlighted">
            <Link
              to={replaceLinkParams(ClinicPaths.patientProfile.index.link, {
                userId: patient.id,
                clinicId
              })}
            >
              <PatientName
                patient={patient}
                clinicId={clinicId}
                lastNameFirst
              />
            </Link>
          </td>
        </Then>
      </If>

      <td className="highlighted">
        <Link
          to={replaceLinkParams(ClinicPaths.patientProfile.index.link, {
            userId: patient.id,
            clinicId
          })}
        >
          {account}
        </Link>
      </td>

      <td className={baselineExpiryClass}>
        <strong>{(baselineExpiryDate.isValid()) ? baselineExpiryDate.format(DISPLAY_DATE_FORMAT) : '--'}</strong>
      </td>

      {showDanaBaseline && (
        <td className="dana-baseline-count">
          <DanaBaselineCountItem count={patient.dana_baseline_count} />
        </td>
      )}

      <If condition={showInjuryStage === true}>
        <Then>
          <td className="highlighted">
            {injuryStageText}
          </td>
        </Then>
      </If>
    </tr>
  );
};

export default PatientTableRow;
