import React from 'react';
import { Link } from 'react-router-dom';
import { Page } from '../page';
import Strings from './lang';
import Icon from '../icon';

const IdtClaimsLayout = ({ showDischargedLink = false, children }) => {
  return (
    <Page>
      <div className="flex-row align_center justify_space-between">
        <h1 className="display">{Strings.claimsTitle}</h1>
        <div className="flex-row align_center">
          <Link to="resources" className="btn btn-default btn-sm">
            <Icon name="files-medical" />&nbsp;
            {Strings.idtResourcesLabel}
          </Link>
          {showDischargedLink && (
            <Link to="discharged" className="btn btn-primary btn-sm">
              {Strings.dischargedClaims}
            </Link>
          )}
        </div>
      </div>
      {children}
    </Page>
  );
};

export default IdtClaimsLayout;
