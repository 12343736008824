const FR = {
  athleteInformationHeader: 'Informations sur l\'athlète',
  confirmAthleteHeading: 'Informations personnelles',
  medicalHistoryHeading: 'Historique médical',
  clinicianAssessmentHeader: 'Évaluation clinique',
  symptomLevelsHeader: 'Résultat de symptômes',
  orientationHeader: 'Orientation',
  immediateMemoryHeader: 'Mémoire immédiate',
  concentrationHeader: 'Concentration',
  kingDevickHeader: 'Test de King-Devick',
  balanceHeader: 'Équilibre',
  balanceTestingHeader: 'Test de l\'équilibre',
  tandemGaitHeader: 'Démarche tandem chronométrée',
  dualTaskHeader: 'Démarche tandem à double tâche',
  forcePlateHeader: 'Plaque de compression',
  reactionAndStrengthHeader: 'Temps de réaction et force',
  reactionTimeHeader: 'Temps de réaction',
  gripStrengthHeader: 'Force de préhension',
  delayedMemoryHeader: 'Test de mémoire différée'
};
 
export default FR;
