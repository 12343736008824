import { createSelector } from 'reselect';
import { sortDescending } from '@/utilities/sort';

const getBaselinesIndex = (state) => state.userBaselines;
const getBaselines = (state) => state.baselineTests;
const getUserId = (_, { userId }) => userId;
const getBaselineId = (_, { baselineId }) => baselineId;
const getBeforeDate = (_, { date }) => date;

const getUserBaselinesIndex = createSelector(
  [getBaselinesIndex, getUserId],
  (baselines, userId) => {
    return baselines[userId];
  }
);

const getUserBaselines = createSelector(
  [getBaselines, getUserId],
  (baselines, userId) => {
    return Object.values(baselines || {}).reduce((acc, baseline) => {
      return String(baseline?.user_id) === String(userId)
        ? [...acc, baseline]
        : acc;
    }, []);
  }
);

const getBaseline = createSelector(
  [getBaselines, getBaselineId],
  (baselines, id) => {
    return baselines[id];
  }
);

const getUserBaselinesBeforeDate = createSelector(
  [getUserBaselines, getBeforeDate],
  (baselines, date) => {
    return date 
      ? baselines
        .filter(baseline => baseline?.created_at <= date)
        .sort(sortDescending.bind(null, 'created_at'))
      : [];
  }
);

export default {
  getUserBaselinesIndex,
  getUserBaselines,
  getUserBaselinesBeforeDate,
  getBaseline
};
