const FR = {
  none: 'Aucun suivi nécessaire.',
  clinician: 'Surveillance/gestion par un clinicien à l’interne.',
  nextAppointmentDateLabel: 'Date du prochain rendez-vous :',
  referral: 'Orientation',
  referralToLabel: 'Orienté vers qui',
  actionLabel: 'Tâche pour faire le suivi',
  invalidDateMessage: 'Veuillez indiquer une date ultérieure à celle d’aujourd’hui',
  invalidReferralToMessage: 'Veuillez indiquer vers qui et à quelle date le patient sera orienté.'
};
 
export default FR;
