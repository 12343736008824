import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import useSlider, { ThumbnailPlugin, MutationPlugin } from './slider';
import VideoPlayer from '../../video-player';
import Strings from '../lang';
import Icon from '../../icon';

const DirectionButton = ({ direction, onClick }) => {
  return (
    <button 
      type="button" 
      className="slider-nav"
      onClick={onClick}
    >
      <Icon fixedWidth name={`chevron-${direction}`} />
    </button>
  );
};

const VideoThumbnail = ({ url }) => {
  return (
    <div className="video-thumb">
      <VideoPlayer 
        light 
        width="100%" 
        height="100%"
        url={url}
        previewPlayIcon={(
          <div className="play-icon">
            <Icon name="play" />
          </div>
        )}
      />
    </div>
  );
};

const Previews = ({
  mainRef,
  videoUrl,
  images = []
}) => {
  const [thumbnailRef] = useSlider(
    {
      initial: 0,
      slides: {
        spacing: 15,
        perView: 8
      },
      breakpoints: {
        '(max-width: 992px)': {
          slides: {
            spacing: 15,
            perView: 6
          }
        },
        '(max-width: 798px)': {
          slides: {
            spacing: 15,
            perView: 8
          }
        },
        '(max-width: 591px)': {
          slides: {
            spacing: 15,
            perView: 4
          }
        }
      },
    },
    [MutationPlugin, ThumbnailPlugin(mainRef)]
  );

  return (
    <div ref={thumbnailRef} className="keen-slider preview">
      {!!videoUrl && (
        <div className="keen-slider__slide">
          <VideoThumbnail url={videoUrl} />
        </div>
      )}
      {images.map(image => (
        <div key={image.id} className="keen-slider__slide">
          <img src={image.small} alt="" />
        </div>
      ))}
    </div>
  );
};

const ImageSlider = ({ videoUrl = null, images = [] }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [videoStarted, setVideoStarted] = useState(false);
  const videoPlaying = useMemo(() => {
    return videoStarted && !!videoUrl && currentSlide === 0;
  }, [currentSlide, videoStarted, videoUrl]);

  const [sliderRef, instanceRef] = useSlider({
    initial: 0,
    loop: true,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
  }, [MutationPlugin]);

  useEffect(() => {
    if (loaded) {
      instanceRef.current?.update();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded]);

  if (images.length + Number(!!videoUrl) === 0) return null;

  return (
    <div className="image-slider">
      <div className="navigation-container">
        {loaded && instanceRef.current && (
          <DirectionButton 
            direction="left" 
            onClick={() => {
              instanceRef.current?.prev();
            }}
          />
        )}
        <div ref={sliderRef} className="keen-slider main">
          {videoUrl && (
            <div className="keen-slider__slide">
              <VideoPlayer
                light
                controls
                key={videoUrl}
                height="100%"
                width="100%"
                url={videoUrl}
                playing={videoPlaying}
                previewPlayIcon={(
                  <div className="play-icon">
                    <Icon name="play" />
                  </div>
                )}
                onPreviewClick={() => setVideoStarted(true)}
              />
            </div>
          )}
          {images.map(image => (
            <div key={image.id} className="keen-slider__slide">
              <img src={image.large} alt="" />
            </div>
          ))}
        </div>
        {loaded && instanceRef.current && (
          <DirectionButton 
            direction="right" 
            onClick={() => {
              instanceRef.current?.next();
            }}
          />
        )}
        {loaded && instanceRef.current && (
          <div className="slide-dots">
            {[
              ...Array(instanceRef.current.track.details.slides.length).keys(),
            ].map((idx) => {
              return (
                <button
                  key={idx}
                  type="button"
                  title={Strings.formatString(Strings.changeImageSlideLabel, idx + 1)}
                  aria-label={Strings.formatString(Strings.changeImageSlideLabel, idx + 1)}
                  onClick={() => {
                    instanceRef.current?.moveToIdx(idx);
                  }}
                  className={classNames('slide-dot', {
                    active: currentSlide === idx
                  })}
                />
              );
            })}
          </div>
        )}
      </div>
      <Previews 
        mainRef={instanceRef} 
        videoUrl={videoUrl} 
        images={images} 
      />
    </div>
  );
};

export default ImageSlider;
