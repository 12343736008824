const FR = {
  monthQuestion: 'Quel mois sommes-nous?',
  dateQuestion: 'Quelle est la date d\'aujourd’hui?',
  dayOfWeekQuestion: 'Quel jour de la semaine sommes-nous?',
  yearQuestion: 'En quelle année sommes-nous?',
  timeQuestion: 'Quelle heure est-il (à une heure près)?',
  answerCorrect: 'Exact',
  answerIncorrect: 'Faux',
  scoreTitle: 'Total du test d’orientation :',
  requiredError: 'Cette question est requise'
};
 
export default FR;
