export const collectClaimReportInfo = (claim = {}) => {
  return [
    {
      key: 0,
      type: 'initial',
      claim_id: claim.id,
      due_date: claim.report_due_date,
      status: claim.initial_report?.status || 'not_started',
      document: claim.initial_report?.document,
      report_id: claim.initial_report?.id,
      can_create: claim.status === 'active',
      comments_count: claim.initial_report?.comments_count ?? 0,
      show_alert: false
    },
    ...claim.treatment_blocks.filter(block => block.status === 'approved').map((block, index) => ({
      key: index + 1,
      type: block.report?.report_type ?? block.report_type,
      claim_id: claim.id,
      due_date: block.report_due_date,
      status: block.report?.status || 'not_started',
      comments_count: block.report?.comments_count ?? 0,
      report_id: block.report?.id,
      can_create: claim.status === 'active',
      document: block.report?.document,
      show_alert: block.progress_status === 'not_started' && !!block.start_date,
      block_type: block.treatment_type,
      block_number: block.block_number,
      block_id: block.id
    }))
  ];
};
