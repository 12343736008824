export const FunctionTestingVideosConfig = [
  {
    name: 'Introduction to Functional Testing',
    fileName: 'Introduction+to+Functional+Testing.MOV',
    time: '1:27'
  },
  {
    name: 'Dynamic Bending',
    fileName: 'Dynamic+Bending.MOV',
    time: '0:49'
  },
  {
    name: 'Dynamic Lifting Floor to Waist',
    fileName: 'Dynamic+Lifting+Floor+to+Waist.MOV',
    time: '2:05'
  },
  {
    name: 'Dynamic Lifting Waist to Above Shoulder',
    fileName: 'Dynamic+Lifting+Waist+to+Above+Shoulder.MOV',
    time: '1:10'
  },
  {
    name: 'Dynamic Lifting Waist to Shoulder',
    fileName: 'Dynamic+Lifting+Waist+to+Shoulder.MOV',
    time: '1:19'
  },
  {
    name: 'Floor Level Reaching Crouching',
    fileName: 'Floor+Level+Reaching+Crouching.MOV',
    time: '0:39'
  },
  {
    name: 'Floor Level Reaching Kneeling',
    fileName: 'Floor+Level+Reaching+Kneeling.MOV',
    time: '0:51'
  },
  {
    name: 'Grip Strength',
    fileName: 'Grip+Strength.MOV',
    time: '1:59'
  },
  {
    name: 'Gross and Fine Dexterity',
    fileName: 'Gross+and+Fine+Dexterity.MOV',
    time: '3:11'
  },
  {
    name: 'Reaching',
    fileName: 'Reaching.MOV',
    time: '2:00'
  },
  {
    name: 'Walking',
    fileName: 'Walking.MOV',
    time: '0:52'
  }
];
