import React from 'react';
import classnames from 'classnames';

const MetricSelector = ({
  value,
  metrics = [],
  onSelect = () => {}
}) => {
  if (!metrics) return null;

  return (
    <div className="btn-group dana-metric-group">
      {metrics.map(metric => (
        <button 
          key={metric.value}
          type="button" 
          className={classnames('btn btn-sm btn-light', { selected: value === metric.value })}
          onClick={() => onSelect(metric.value)}
        >
          {metric.label}
        </button>
      ))}
    </div>
  );
};

export default MetricSelector;
