import { UPDATE_BLACKHAWKS_TEST } from '../actions/blackhawks';

const updateBlackhawksTestReducer = (state = {}, action = {}) => {
  const { userId, test = {} } = action;
  const { id } = test;
  const userState = state[userId] || {};
  const nextUserState = (id) ? { ...userState, [id]: test } : userState;
  return (userId) ? { ...state, [userId]: nextUserState } : state;
};

const blackhawks = (state = {}, action = {}) => {
  switch (action.type) {
    case UPDATE_BLACKHAWKS_TEST:
      return updateBlackhawksTestReducer(state, action);
    default:
      return state;
  }
};

export default blackhawks;
