const EN = {
  version1: 'Version 1',
  version2: 'Version 2',
  card: 'Card {0}',
  reset: 'Reset',
  restartCard: 'Reset Card',
  totalTimeLabel: 'Total Time:',
  totalErrorsLabel: 'Total Errors:',
  numberInputErrorMessage: 'Please enter a valid number',
  errorsInputLabel: 'Errors',
  versionLabel: 'Version',
  kingDevickCardIncompleteText: 'This card is incomplete',
  thirdCardWarningMessage: 'This individual is in the age group that may have difficulty with this card. If the individual cannot complete the card, reset the card (if any values) and continue'
};

export default EN;
