const FR = {
  nameHeadingText: 'Nom',
  teamsHeadingText: 'Équipes',
  statusHeadingText: 'Statut',
  actionHeadingText: 'Action',
  activatedStatusText: 'Actif',
  deactivatedStatusText: 'Désactivé',
  activateActionText: 'Activer le compte',
  deactivateActionText: 'Désactiver le compte'
};
 
export default FR;
