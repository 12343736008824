const HTTPStatusCodes = {
  OK: 200,
  Created: 201,
  NoContent: 204,
  Unauthorized: 401,
  NotFound: 404,
  Gone: 410,
  UnprocessableEntity: 422,
  InternalServerError: 500,
  NotImplemented: 501
};

export default HTTPStatusCodes;
