const FR = {
  instructions: 'Placez une ligne de 3 mètres de long sur le sol/surface ferme avec du ruban athlétique. La tâche sera chronométrée.',
  practiceTitle: 'Pratique à double tâche',
  promptStartText: 'Dire',
  practicePrompt: 'Maintenant, je vais vous demander de compter à rebours à haute voix par 7. Par exemple, si nous commencions à 100, vous diriez - 100, 93, 86, 79. Entraînons-nous à compter. En commençant par 93, comptez à rebours par sept jusqu\'à ce que je dise « arrêt ».',
  practiceNote: 'Notez que cette pratique consiste uniquement à compter à rebours.',
  practice7CountingTrouble: 'Si le patient a du mal à compter à rebours par sept, modifiez la valeur de soustraction ci-dessus à « 3 » et réessayez.',
  practice3CountingTrouble: 'Si le patient a du mal à compter par trois à rebours, marquez le test comme « Impossible de terminer » et continuez.',
  trialsTitle: 'Performance cognitive à double tâche',
  trialsPrompt: 'Bien. Maintenant, je vais vous demander de marcher du talon aux pieds tout en comptant à rebours à haute voix. Es-tu prêt? Le numéro par lequel commencer est {0}. Aller!',
  startTrialButton: 'Commencer',
  editTrialButton: 'Modifier',
  tableColumnTrial: 'Procès',
  tableColumnStartNumber: 'Début Entier',
  tableColumnPassFail: 'Réussite/Échec (raison)',
  tableColumnAttempted: 'Tentative',
  tableColumnErrors: 'Erreurs',
  tableColumnCorrect: 'Correct',
  tableColumnTime: 'Temps (s)',
  durationLabel: 'Durée',
  trialLabel: 'Procès {0}',
  passedText: 'Passé',
  failureQuestion: 'Le patient a-t-il commis l\'une des erreurs de marche suivantes ?',
  failureStepOffLine: 'Je suis sorti de la ligne',
  failureSeparation: 'Séparation entre le talon et la pointe',
  failureTouchEnvironment: 'Touché l\'examinateur/ l\'environnement',
  updateButton: 'Mise à jour',
  editModalTitle: 'Modifier Procès {0}',
  correctNumberColumnLabel: 'Numéro correct',
  responseNumberColumnLabel: 'Réponse',
  nextButton: 'Suivant',
  stopButton: 'Arrêt',
  startButton: 'Commencer',
  doneButton: 'Fait',
  resetTrialButton: 'Réinitialiser Procès',
  trialCompleteTitle: 'Procès terminé',
  trialStoppedTitle: 'Procès Arrêté',
  unableToCompleteLabel: 'Le patient est incapable de terminer',
  totalErrors: 'Erreurs totales',
  avgTime: 'Moyenne. Temps (s)',
  fastestTime: 'Temps le plus rapide (s)',
  subtractionValueLabel: 'Valeur de soustraction',
  confirmStepChangeTitle: 'Confirmer le changement de valeur de soustraction',
  confirmStepChangeMessage: 'La modification de cette valeur réinitialisera tous le procès. Voulez-vous continuer?',
  confirmStepChangeButton: 'Confirmer',
  keyControlsTitle: 'Commandes du clavier',
  keyArrowUpControlText: '(Flèche vers le haut) - Incrémenter le numéro.',
  keyArrowDownControlText: '(Flèche vers le bas) - Numéro de décrémentation.',
  keyEnterControlText: '(Touche Entrée) - Sélectionnez le numéro actuel et passez au numéro suivant.',
  tandemGaitErrorsMessage: 'Demandez au patient de se tenir pieds joints à une extrémité de la ligne. Le patient doit alors marcher rapidement du talon aux orteils jusqu\'au bout de la bande, faire demi-tour et revenir aussi vite qu\'il le peut sans séparer ses pieds ni sortir de la ligne. Le patient <strong>échoue à l\'essai</strong> s\'il commet l\'une des erreurs suivantes: <strong>sort de la ligne</strong>, <strong>a une séparation entre le talon et l\'orteil</strong>, ou <strong>s\'ils touchent ou attrapent l\'examinateur ou un objet</strong>. Si le patient échoue à l\'essai, arrêtez le chronomètre, marquez l\'essai comme ayant échoué et passez à l\'essai suivant.',
  cognitiveAccuracyLabel: 'Précision cognitive',
  averageResponseRateLabel: 'Moy. Taux de réponse',
  responseRateLabel: 'Taux de réponse'
};
 
export default FR;
