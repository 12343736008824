import { confirmation } from '../../confirmation';
import Strings from './lang';

const confirmUnarchive = (onConfirm) => {
  confirmation(Strings.unarchiveModalMessage, {
    title: Strings.unarchiveModalTitle,
    confirmButtonTitle: Strings.confirmUnarchiveButton,
    onConfirm
  });
};

export default confirmUnarchive;
