import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import Modal from '../modal';
import { ErrorBanner } from '../errors';
import Activity from '../activity';
import { FormComponent, DateInputGroup } from '@/forms';
import { DISPLAY_DATE_FORMAT } from '@/utilities/dates/formats';
import { updateInvoiceAsync } from '@/redux/thunks/clinic-invoices';
import Strings from './lang';

const DateInputLabel = {
  close_date: Strings.closeDateLabel,
  due_date: Strings.dueDateLabel
};

const ModalTitle = {
  close_date: Strings.editCloseDateTitle,
  due_date: Strings.editDueDateTitle
};

const UpdateDateForm = ({
  initialDate,
  attribute,
  minDate = dayjs(),
  submitting,
  onSubmit = () => { }
}) => {
  const [date, setDate] = useState(initialDate || '');
  const [dateValid, setDateValid] = useState(date !== '');
  const [submitted, setSubmitted] = useState(false);
  const handleSubmit = useCallback(() => {
    setSubmitted(true);
    const attributes = {
      [attribute]: date
    };

    if (dateValid) {
      onSubmit(attributes);
    }
  }, [attribute, date, dateValid, onSubmit]);

  return (
    <FormComponent onSubmit={handleSubmit}>
      <DateInputGroup
        required
        className="form-group"
        labelText={DateInputLabel[attribute]}
        inputType="date"
        textInputProps={{
          className: 'form-control'
        }}
        dateInputProps={{
          className: 'form-control'
        }}
        initialValue={date}
        minDate={dayjs(minDate)}
        touched={submitted}
        onUpdate={(value, isValid) => {
          setDate(value);
          setDateValid(isValid);
        }}
        messageText={Strings.formatString(
          Strings.minDateErrorMessage,
          dayjs(minDate).format(DISPLAY_DATE_FORMAT)
        )}
        messageClassName="alert alert-danger"
      />
      <button
        type="submit"
        className="btn btn-primary"
        disabled={submitting || !dateValid}
      >
        {Strings.submitButtonText}
      </button>
    </FormComponent>
  );
};

const UpdateDateModal = ({
  isOpen = false,
  attribute,
  date,
  minDate,
  clinicId,
  invoiceId,
  onClose = () => { },
  updateInvoice
}) => {
  const [activity, setActivity] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = useCallback((attributes) => {
    setActivity(true);

    updateInvoice(clinicId, invoiceId, attributes).then(() => {
      setActivity(false);
      onClose();
    }).catch(error => {
      setActivity(false);
      setError(error.message);
    });
  }, [clinicId, invoiceId, onClose, updateInvoice]);

  return (
    <Modal
      blur
      static={activity}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Modal.Header onClose={activity ? null : onClose}>
        <h2 className="bold">
          {ModalTitle[attribute]}
        </h2>
      </Modal.Header>
      <Modal.Body>
        <ErrorBanner error={error} />
        <Activity active={activity}>
          <UpdateDateForm
            attribute={attribute}
            initialDate={date}
            minDate={minDate}
            submitting={activity}
            onSubmit={handleSubmit}
          />
        </Activity>
      </Modal.Body>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateInvoice: (clinicId, invoiceId, attributes) => {
      return dispatch(updateInvoiceAsync(clinicId, invoiceId, attributes));
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(UpdateDateModal);
