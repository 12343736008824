import React, { Component } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { KingDevickTestCard, CARD_EVENTS } from './king-devick-test-card';
import KingDevickAnswerKeys from './king-devick-answer-keys';
import { SelectInputGroup } from '@/forms';
import { TIMER_STATES } from '../../../timer-components';
import ScoreView from '../../../score-view';
import Strings from './lang';

const KD_THIRD_CARD_AGE_MIN = 10;
const KD_MAX_TRIALS = 3;

const getTimeAndErrors = (cards) => {
  const milliseconds = cards.reduce((acc, cur) => {
    return acc + cur.milliseconds;
  }, 0);

  const errors = cards.reduce((acc, cur) => {
    return acc + ((isNaN(cur.errors) || cur.errors === '') ? 0 : parseFloat(cur.errors));
  }, 0);

  return { 
    milliseconds, 
    errors
  };
};

const getCardValues = (cards) => {
  return cards.map((card) => {
    return {
      milliseconds: card.milliseconds,
      errors: card.errors
    };
  });
};

const createDefaultCards = () => {
  return [...new Array(KD_MAX_TRIALS)].map(() => {
    return { 
      milliseconds: 0, 
      errors: 0 
    };
  });
};

class KingDevick extends Component {
  constructor(props) {
    super(props);

    const { initialState = {} } = props;
    const { cards = createDefaultCards(), version = 1 } = initialState;

    const cardsD = [...(cards.length > 0 ? cards : createDefaultCards())];

    const newCards = JSON.parse(JSON.stringify(cardsD)).map(card => ({
      ...card,
      timerState: card.milliseconds > 0 ? TIMER_STATES.COMPLETED : TIMER_STATES.NOT_STARTED
    }));

    this.onVersionChange = this.onVersionChange.bind(this);
    this.onCardEvent = this.onCardEvent.bind(this);

    this.state = {
      cards: newCards, 
      version,
      totalTime: 0,
      totalErrors: 0
    };
  }

  componentDidMount() {
    /* merge defaults with state */
    const { cards, version } = this.state;
    const { onUpdate = () => {} } = this.props;

    const { milliseconds, errors } = getTimeAndErrors(cards);
    this.setState({
      cards,
      totalTime: milliseconds,
      totalErrors: errors
    });

    onUpdate({ cards, version });
  }

  render() {
    return (
      <div className="king-devick-test">
        <div>
          {this.renderVersionSelector()}
        </div>
        <div className="kd-card-group">
          {this.renderCards()}
        </div>
        <div className="kd-summary">
          <ScoreView 
            label={Strings.totalTimeLabel}
            score={dayjs(this.state.totalTime).format('mm:ss.SSS')}
          />
          <ScoreView
            label={Strings.totalErrorsLabel}
            score={this.state.totalErrors}
          />
        </div>
      </div>
    );
  }

  renderVersionSelector() {
    return (
      <SelectInputGroup
        labelText={Strings.versionLabel}
        className="form-group"
        inputProps={{
          className: 'form-control',
          value: this.state.version
        }}
        onUpdate={this.onVersionChange}
      >
        <option value={1}>{Strings.version1}</option>
        <option value={2}>{Strings.version2}</option>
      </SelectInputGroup>
    );
  }

  renderCards() {
    const answerKeys = KingDevickAnswerKeys.version[this.state.version].card;
    const { patientAge = 0 } = this.props;
    const { cards } = this.state;
    const activeTimer = cards.findIndex((card) => (
      card.timerState === TIMER_STATES.RUNNING || card.timerState === TIMER_STATES.STOPPED
    ));

    return cards.map((card, index) => {
      let isValid = true;
      if (card.errors === '' || card.seconds === 0) {
        isValid = false;
      }

      if (index === 2 && patientAge <= KD_THIRD_CARD_AGE_MIN) {
        isValid = true;
      }

      return (
        <KingDevickTestCard
          key={index}
          cardNumber={index}
          disabled={(activeTimer !== -1 && activeTimer !== index)}
          answerKey={answerKeys[index + 1]}
          errors={card.errors}
          hasWarning={patientAge <= KD_THIRD_CARD_AGE_MIN && index === 2}
          warningMessage={Strings.thirdCardWarningMessage}
          timerProps={{
            elapsedTime: card.milliseconds,
            timerState: card.timerState || TIMER_STATES.NOT_STARTED,
          }}
          errorProps={{
            errors: card.errors
          }}
          onUpdate={this.onCardEvent}
          touched={this.props.touched}
          valid={isValid}
        />
      );
    });
  }

  onCardEvent(type, cardNumber, value) {
    const { cards } = this.state;
    const { version } = this.state;
    const { onUpdate = () => {} } = this.props;

    switch (type) {
      case CARD_EVENTS.TIMER_START:
        cards[cardNumber].timerState = TIMER_STATES.RUNNING;
        break;
      case CARD_EVENTS.TIMER_DONE:
        cards[cardNumber].timerState = TIMER_STATES.COMPLETED;
        cards[cardNumber].milliseconds = value;
        break;
      case CARD_EVENTS.TIMER_STOP:
        cards[cardNumber].timerState = TIMER_STATES.STOPPED;
        break;
      case CARD_EVENTS.ERRORS_CHANGE:
        cards[cardNumber].errors = value;
        break;
      case CARD_EVENTS.CARD_RESTART:
        cards[cardNumber].timerState = TIMER_STATES.NOT_STARTED;
        cards[cardNumber].errors = 0;
        cards[cardNumber].milliseconds = 0;
        break;
      default:
        break;
    }

    const { milliseconds, errors } = getTimeAndErrors(cards);

    this.setState({
      cards,
      totalTime: milliseconds, 
      totalErrors: errors
    });

    const cardValues = getCardValues(cards);

    onUpdate({ cards: cardValues, version }); // temporary true isValid parameter
  }

  onVersionChange(value) {
    const { onUpdate = () => {} } = this.props;
    let { version } = this.state;
    const { cards } = this.state;

    version = parseInt(value, 10);
    this.setState({
      version
    });

    const cardValues = getCardValues(cards);

    onUpdate({ cards: cardValues, version }); // temporary true isValid parameter
  }
}

KingDevick.propTypes = {
  initialState: PropTypes.shape({
    cards: PropTypes.arrayOf(PropTypes.shape({
      milliseconds: PropTypes.number,
      errors: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    })),
    version: PropTypes.number
  }),
  patientAge: PropTypes.number,
  onUpdate: PropTypes.func,
  touched: PropTypes.bool
};

export {
  KingDevick,
  KD_THIRD_CARD_AGE_MIN
};
