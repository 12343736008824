import React from 'react';
import Icon from '../../../../icon';
import Strings from '../lang';

const KeyboardControls = () => {
  return (
    <div className="trial-keyboard-controls">
      <div className="controls-title">
        {Strings.keyControlsTitle}
      </div>
      <ul className="list-unstyled">
        <li>
          <Icon fixedWidth name="arrow-up" />
          <span>{Strings.keyArrowUpControlText}</span>
        </li>
        <li>
          <Icon fixedWidth name="arrow-down" />
          <span>{Strings.keyArrowDownControlText}</span>
        </li>
        <li>
          <Icon fixedWidth name="arrow-turn-down-left" />
          <span>{Strings.keyEnterControlText}</span>
        </li>
      </ul>
    </div>
  );
};

export default KeyboardControls;
