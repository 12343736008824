const EN = {
  symptomLevelsInfo: 'Ask the athlete to rate their symptoms on a score from 0 to 6. Inquire further as to the nature of any reported symptoms and whether they are normal for that individual',
  orientationInfoText: '1 point for each correct answer, 0 for an incorrect answer',
  immediateMemoryStep1InfoText: '"I\'m going to test your memory. I will read you a list of words and when I\'m done, repeat back as many words as you can, in any order."',
  immediateMemoryStep2InfoText: '"I\'m going to repeat the same list again. Repeat back as many words as you can, in any order, even if you said the word before."',
  step1LabelText: 'Step 1',
  step2LabelText: 'Step 2 & 3',
  kingDevickDoneInfoText: 'Select "Done" when you are complete with the timer and ready to move on to the next timer',
  saveButtonText: 'Save',
  abStractNotesTitle: '{0} Notes',
  kingDevickInfoText: 'The patient must read each card as fast as they can without errors. If an error is committed, stop, reset the timer and start over. Record only the time of a successful trial (i.e., no errors), as well as the number of times the test had to be re-started for each card.',
  balanceTestingInfoText: 'Direct the patient to remove shoes and socks, roll up pant legs above the ankle (if applicable), and remove any ankle taping (if applicable). This test will consist of three (3) twenty (20) second tests, each with a different stance.',
  balanceTestingErrorsInfoText: 'Click here to view possible errors',
  reactionTimeInfoText: 'Test the patient\'s non-dominant hand. Give three (3) practice trials then perform five (5) successive trials and record to the nearest 1/2 cm.',
  forcePlateInfoText: 'Using the Footmaxx gait scanner, stand on one foot in the middle of the scanner. Select "Add Scan" and click "Postural Scan". Once prompted, select "Foot Tested", then set "Time" to 25 seconds and set "Ignore First 5 Seconds". Once the patient is set with their hands on their hips and eyes open, have them lift their non-stance leg and begin the scan.',
  gripStrengthInfoText: 'Please measure in Kg!',
  immediateMemoryPatientLanguageText: 'This Patient has chosen {0}{1}{2} as their primary language preference',
  addNoteButtonText: 'Add Note',
  usedWordListsFetchError: 'Unable to get previously used word lists',
  timedTandemGaitInfo: 'Place a 38mm wide, 3-meter long line on the floor/firm surface with athletic tape. The task is timed. Please complete all 3 trials.',
  timedTandemGaitInstructions: 'Have the patient stand feet together at one end of the line. The patient must then walk heel-to-toe quickly to the end of the tape, turn around and come back as fast as they can without separating their feet or stepping off the line. The patient <strong>fails the trial</strong> if they perform any of the following errors: <strong>steps off the line</strong>, <strong>has separation between their heel and toe</strong>, or <strong>if they touch of grab the examiner or an object</strong>. If the patient fails the trial, stop the timer, mark the trial as failed and move to the next trial.',
  dualTaskSubmitError: 'Please complete all trials. If the patient cannot complete the test, check the "Patient unable to complete" option above.',
  tandemGaitSubmitError: 'Please complete all trials. If the patient cannot complete a trial, mark it as failed.',
  wessBalanceInfo: 'This test will consist of 6 conditions of 20 seconds each: 3 positions (seated, balance disk, and wheelie), each with 2 visual inputs (eyes open and eyes closed).',
  bessTitle: 'Balance Error Scoring System',
  wessTitle: 'Wheelchair Error Scoring System',
  balanceTestType: 'Balance Test Type'
};

export default EN;
