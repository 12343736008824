const UPDATE_CLINIC_INVOICE = 'UPDATE_CLINIC_INVOICE';
const UPDATE_CLINIC_INVOICES = 'UPDATE_CLINIC_INVOICES';
const ADD_INVOICE_LINE_ITEM = 'ADD_INVOICE_LINE_ITEM';
const UPDATE_INVOICE_LINE_ITEM = 'UPDATE_INVOICE_LINE_ITEM';
const DELETE_INVOICE_LINE_ITEM = 'DELETE_INVOICE_LINE_ITEM';
const ADD_INVOICE_PAYMENT = 'ADD_INVOICE_PAYMENT';

const updateClinicInvoice = (clinicId, invoice) => ({
  type: UPDATE_CLINIC_INVOICE,
  clinicId,
  invoice
});

const updateClinicInvoices = (clinicId, invoices) => ({
  type: UPDATE_CLINIC_INVOICES,
  clinicId,
  invoices
});

const addInvoiceLineItem = (clinicId, invoiceId, lineItem) => ({
  type: ADD_INVOICE_LINE_ITEM,
  clinicId, 
  invoiceId, 
  lineItem
});

const updateInvoiceLineItem = (clinicId, invoiceId, lineItem) => ({
  type: UPDATE_INVOICE_LINE_ITEM,
  clinicId, 
  invoiceId, 
  lineItem
});

const deleteInvoiceLineItem = (clinicId, invoiceId, lineItem) => ({
  type: DELETE_INVOICE_LINE_ITEM,
  clinicId, 
  invoiceId, 
  lineItem
});

const addInvoicePayment = (clinicId, invoiceId, payment) => ({
  type: ADD_INVOICE_PAYMENT,
  clinicId, 
  invoiceId, 
  payment
});

export {
  UPDATE_CLINIC_INVOICE,
  updateClinicInvoice,
  UPDATE_CLINIC_INVOICES,
  updateClinicInvoices,
  ADD_INVOICE_LINE_ITEM,
  addInvoiceLineItem,
  UPDATE_INVOICE_LINE_ITEM,
  updateInvoiceLineItem,
  DELETE_INVOICE_LINE_ITEM,
  deleteInvoiceLineItem,
  ADD_INVOICE_PAYMENT,
  addInvoicePayment
};
