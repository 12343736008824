import React from 'react';
import { CheckboxInputGroup } from '@/forms';
import { FAILURE_REASONS, FAILURE_REASON_STRINGS } from './utils';
import Strings from './lang';

const FailureInputItems = ({
  reasons = [],
  onReasonsChange
}) => {
  return (
    <div className="dual-task-failure-input-items">
      <div className="text-center title">
        {Strings.failureQuestion}
      </div>
      <div className="inline-checkboxes">
        {Object.values(FAILURE_REASONS).map(key => (
          <CheckboxInputGroup
            key={key}
            className="form-group"
            labelText={FAILURE_REASON_STRINGS[key]}
            inputProps={{
              className: 'form-control',
              checked: reasons.includes(key),
              onChange: (e) => onReasonsChange(key, e.target.checked)
            }}
            inputValid
            touched={false}
          />
        ))}
      </div>
    </div>
  );
};

export default FailureInputItems;
