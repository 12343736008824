import { updateDanaBattery } from '../actions/dana-batteries';
import { jsonApiRead } from '@/lib/requests/jsonapi';

const getDanaBattery = (userId, batteryId) => {
  return (dispatch) => {
    const options = { path: `users/${userId}/dana_batteries/${batteryId}` };
    return jsonApiRead({ type: 'dana_batteries' }, options).then(battery => {
      dispatch(updateDanaBattery(userId, battery));
      return Promise.resolve(battery);
    });
  };
};

export {
  getDanaBattery
};
