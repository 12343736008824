const UPDATE_SPECIALIST_NOTE = 'UPDATE_SPECIALIST_NOTE';
const UPDATE_SPECIALIST_NOTES = 'UPDATE_SPECIALIST_NOTES';

const updateSpecialistNote = (userId, injuryId, note) => ({
  type: UPDATE_SPECIALIST_NOTE,
  userId,
  injuryId,
  note
});

const updateSpecialistNotes = (userId, injuryId, notes) => ({
  type: UPDATE_SPECIALIST_NOTES,
  userId,
  injuryId,
  notes
});

export {
  UPDATE_SPECIALIST_NOTE,
  UPDATE_SPECIALIST_NOTES,
  updateSpecialistNote,
  updateSpecialistNotes
};
