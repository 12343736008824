const EN = {
  headache: 'Headache',
  head_pressure: 'Pressure in head',
  neck_pain: 'Neck Pain',
  nausea: 'Nausea or Vomiting',
  dizziness: 'Dizziness',
  blurred_vision: 'Blurred Vision',
  balance_problems: 'Balance Problems',
  light_sensitivity: 'Sensitivity to Light',
  noise_sensitivity: 'Sensitivity to Noise',
  feeling_slowed_down: 'Feeling Slowed Down',
  feeling_in_a_fog: 'Fogginess',
  dont_feel_right: 'Don\'t Feel Right',
  difficulty_concentrating: 'Difficulty Concentrating',
  difficulty_remembering: 'Difficulty Remembering',
  fatigue: 'Fatigue or Low Energy',
  confusion: 'Confusion',
  drowsiness: 'Drowsiness',
  trouble_falling_asleep: 'Trouble Falling Asleep',
  more_emotional: 'More Emotional',
  irritability: 'Irritability',
  sadness: 'Sadness',
  nervous: 'Nervous or Anxious',
  autonomicCategory: 'Autonomic Nervous System Dysregulation',
  inflammatoryCategory: 'Inflammatory/Gut/Hormones',
  visualCategory: 'Visual/Vestibular',
  neckCategory: 'Neck',
  psychCategory: 'Psychological/Mental Health'
};

export default EN;
