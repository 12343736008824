import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import RoutableTabs from './routable-tabs';
import TabContent from './tab-content';

const getSelectedKey = (items, key) => {
  const item = items.find(item => item.key === key && !item.hide);

  if (!item) {
    return (items.find(item => !item.hide) || {}).key || key;
  }

  return key;
};

class Tabs extends Component {
  static getDerivedStateFromProps(props, state) {
    const { selectedKey = (props.items[0] || {}).key } = props;
    if (selectedKey !== state._propSelectedKey) {
      return { selectedKey, _propSelectedKey: selectedKey };
    }

    return null;
  }

  constructor(props) {
    super(props);

    const { selectedKey, items = [] } = props;
    const selected = selectedKey || (items[0] || {}).key;

    this.tabContainer = React.createRef();

    this.state = {
      selectedKey: selected,
      _propSelectedKey: selected
    };
  }

  componentDidMount() {
    const { selectedKey } = this.state;
    const { items = [] } = this.props;
    const selected = getSelectedKey(items, selectedKey);

    if (selected !== selectedKey) {
      this.setSelectedKey(selected);
    }
  }

  render() {
    const { heading = null, items, vertical = false } = this.props;
    
    return (
      <div 
        ref={this.tabContainer} 
        className={classNames('tabs', this.props.className, { vertical })}
      >
        <div className="tabs-bar">
          {heading && (
            <div className="tab-heading">
              {heading}
            </div>
          )}
          {items.map((item, index) => {
            if (item.hide !== true) {
              return item.customTabElement 
                ? React.cloneElement(item.customTabElement, { key: index }) 
                : (
                  <button
                    key={index}
                    type="button"
                    className={classNames('tab-button', item.tabClassName, {
                      selected: item.key === this.state.selectedKey
                    })}
                    onClick={this.setSelectedKey.bind(this, item.key)}
                  >
                    {item.label}
                  </button>
                );
            }
              
            return null;
          })}
        </div>
        <TabContent 
          items={items}
          selected={this.state.selectedKey}
          renderOne={this.props.renderOne}
        />
      </div>
    );
  }

  setSelectedKey(key) {
    this.setState({ selectedKey: key });

    if (typeof this.props.onSelectTab === 'function') {
      this.props.onSelectTab(key);
    }
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  getBoundingClientRect() {
    return this.tabContainer.current.getBoundingClientRect();
  }
}

Tabs.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    label: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node
    ]),
    component: PropTypes.element.isRequired,
    hide: PropTypes.bool,
    tabClassName: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object
    ]),
    customTabElement: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.node
    ])
  })).isRequired,
  selectedKey: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  onSelectTab: PropTypes.func
};

Tabs.Routable = RoutableTabs;

export default Tabs;
