import React, { useCallback, useMemo } from 'react';
import WESSTest from './wess-test';
import ScoreView from '../../../score-view';
import BalanceTestTimer from './balance-test-timer';
import Strings from './lang';

const MAX_ERRORS = 10;
const MAX_SCORE = MAX_ERRORS * 3 * 2;

const WESSTesting = ({
  wess,
  touched = false,
  onUpdate
}) => {
  const handleUpdate = useCallback((value, key) => {
    onUpdate({ ...wess, [key]: value });
  }, [onUpdate, wess]);
  
  const score = useMemo(() => {
    return Object.keys(wess || {}).reduce((acc, key) => {
      const values = wess[key];
      return acc - (
        (parseInt(values.eyes_open, 10) || 0) + (parseInt(values.eyes_closed, 10) || 0)
      );
    }, MAX_SCORE);
  }, [wess]);

  return (
    <div className="balance-testing">
      <div className="balance-header-row">
        <div style={{ flex: 1 }} />
        <BalanceTestTimer />
      </div>
      <div className="baseline-card wess-testing-card">
        <WESSTest
          testLabel={Strings.wessSeatedLabel}
          maxErrors={MAX_ERRORS}
          value={wess.seated_errors}
          onUpdate={(values) => handleUpdate(values, 'seated_errors')}
          touched={touched}
          required
        />
        <WESSTest
          testLabel={Strings.wessBalanceDiskLabel}
          maxErrors={MAX_ERRORS}
          value={wess.balance_disk_errors}
          onUpdate={(values) => handleUpdate(values, 'balance_disk_errors')}
          touched={touched}
          required
        />
        <WESSTest
          testLabel={Strings.wessWheelieLabel}
          maxErrors={MAX_ERRORS}
          value={wess.wheelie_errors}
          onUpdate={(values) => handleUpdate(values, 'wheelie_errors')}
          touched={touched}
          required
        />
      </div>
      <ScoreView
        label={Strings.wessTotalScoreLabel}
        score={score}
        outOf={MAX_SCORE}
      />
    </div>
  );
};

export default WESSTesting;
