const EN = {
  title: 'Post Injury Assessment',
  acuteConcussionAssessmentTitle: 'Acute Concussion Assessment (0 - 14 days)',
  chronicConcussionAssessmentTitle: 'Chronic Concussion Assessment (14 days or more)',
  medicalInfoTitle: 'Previous Medical History',
  characteristicsTitle: 'Injury Description',
  redFlagsTitle: 'Red Flags',
  initialSymptomsTitle: 'Initial Symptoms',
  initialSymptomsSubtitle: '(As reported by the patient or coach/trainer at the time of injury)',
  symptomsTitle: 'Symptoms',
  symptomExertionTitle: 'Symptoms Exertion (Do symptoms get worse with)',
  symptomsSubtitle: '(Scored Values)',
  neurologicalTitle: 'Neurological Examination',
  diagnosisTitle: 'Diagnosis',
  recoveryStagesTitle: 'Recovery Stages',
  followUpTitle: 'Follow-up Action',
  communicationTitle: 'Communication of Diagnosis',
  learningDisabilityLabelText: 'Learning disability:',
  behaviouralDisorderLabelText: 'Behavioural Disorders:',
  concussionCountLabelText: 'Number of previous concussions:',
  concussionDateLabelText: 'Date of last concussion:',
  injuryDateLabelText: 'Date of Injury:',
  injuryTimeLabelText: 'Time of Injury:',
  injuryDescriptionLabelText: 'Injury Description:',
  defaultDescriptionText: 'No description was provided.',
  injuryCauseLabelText: 'Cause of Injury:',
  defaultCauseText: 'No cause was provided.',
  directHeadImpactLabelText: 'Direct Impact to Head:',
  headImpactLocationLabelText: 'Head Impact Location:',
  bodyImpactLocationLabelText: 'Body Impact Location:',
  amnesiaConsciousnessLabelText: 'Amnesia & Loss of Consciousness:',
  retrogradeAmnesiaLabelText: 'Amnesia Before Injury (Retrograde):',
  anterogradeAmnesiaLabelText: 'Amnesia After Injury (Anterograde):',
  conciousnessLabelText: 'Loss of Consciousness:',
  seizuresLabelText: 'Seizures:',
  seizuresStatusPositiveText: 'The patient has reported seizures.',
  seizuresStatusNegativeText: 'The patient has not reported seizures.',
  defaultRedFlagsText: 'No red flags were reported.',
  baselineSymptomsTitleText: 'Baseline Symptoms',
  currentSymptomsTitleText: 'Current Symptoms',
  currentSymptomsSubtitleText: '(At time of clinical presentation)',
  defaultDiagnosisText: 'No diagnosis was provided.',
  defaultFollowUpText: 'No follow-up needed.',
  clinicianMonitoringText: 'Clinician in-house monitoring/management',
  referralText: 'Referral',
  referralToText: 'Referral to:',
  nextAppointmentDateText: 'Date of next appointment:',
  notesLabelText: 'Notes:',
  minutesText: '{0} minutes',
  cmText: '{0} cm',
  noneText: 'None',
  impactlocation_rear: 'Back',
  impactlocation_front: 'Front',
  impactlocation_left_side: 'Left Side',
  impactlocation_right_side: 'Right Side',
  impactlocation_left_frontal: 'Left Frontal',
  impactlocation_left_parietal: 'Left Parietal',
  impactlocation_left_temporal: 'Left Temporal',
  impactlocation_left_occipital: 'Left Occipital',
  impactlocation_right_frontal: 'Right Frontal',
  impactlocation_right_parietal: 'Right Parietal',
  impactlocation_right_temporal: 'Right Temporal',
  impactlocation_right_occipital: 'Right Occipital',
  impactlocation_crown: 'Crown',
  noInitialSymptomsProvided: 'No Initial Symptoms Provided',
  otherText: 'Other',
  postInjuryTestTitle: 'Post Injury Test',
  noActiveBaseline: 'This patient has no active baseline to compare to',
  symptomExertionPhysicalActivityLabel: 'Physical Activity',
  symptomExertionMentalActivityLabel: 'Mental Activity',
  activityYesLabel: 'Yes',
  activityNoLabel: 'No',
  activityHaventTriedLabel: 'Haven\'t Tried',
  overallRatingLabel: 'Parent Overall Rating (0-6)',
  overallRatingNotProvidedText: 'Not Provided',
  initialManagementTitle: 'Initial Management',
  explanationLabel: 'Explanation',
  exerciseLabel: 'Exercise',
  exerciseRestritionsLabel: 'Restrictions',
  exerciseNoRestrictionsLabel: 'No Restrictions',
  workSchoolLabel: 'Work/School',
  workSchoolFullOptionLabel: 'Full',
  workSchoolModifiedOptionLabel: 'Modified',
  therapyLabel: 'Therapy',
  softTissueLabel: 'Soft Tissue',
  spinalManipulativeLabel: 'Spinal Manipulative',
  otherTherapyLabel: 'Other: {0}',
  reevaluationDateLabel: 'Re-Evaluation Date',
  noReevaluationDateText: 'No Re-Evaluation Date Provided',
  rehabLabel: 'Rehab',
  vestibularLabel: 'Balance/Vestibular',
  cervicalSpineLabel: 'Cervical Spine',
  visualTrackingLabel: 'Visual Tracking',
  sportSpecificLabel: 'Sport Specific',
  neurocognitiveLabel: 'Neurocognitive',
  editText: 'Edit'
};

export default EN;
