const EN = {
  scrollMessageText: 'Please read to the end of the CCMI Privacy Policy to enable the consents.',
  consentErrorMessage: 'This consent item is required to participate in the CCMI Program. For more information on this item, please click the button below.',
  playerConsentHeadingText: 'In light of the above, CCMI requests your consent to participate in the Program, and to permit CCMI to collect, use and disclose your personal health information for the purposes of the program.',
  playerConsentText_1: 'I understand that by registering for the program, I will be participating in a health information network, in which CCMI will receive and hold my personal health information for the purposes of making it available to my Primary Clinic and (with my permission) other specific Participating Clinics. ',
  playerConsentText_2: 'I consent and authorize CCMI to grant any Participating Clinic access to my Program record if I provide that clinic with my Concussion ID Card Number and/or Account Number. ',
  playerConsentText_3: 'I consent and authorize those Participating Clinics to disclose my personal health information to CCMI for the purposes set out in this notice and consent. ',
  playerConsentText_4: 'I consent to CCMI rendering my personal health information anonymous for the purposes of using that anonymous information for research. ',
  playerConsentMoreInfoButtonText: 'Why is this required?'
};

export default EN;
