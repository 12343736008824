import React from 'react';
import classnames from 'classnames';
import Popper from '../popper';

const Popover = ({
  isOpen, 
  content, 
  offset,
  children, 
  portal = false,
  dismissable = true, 
  onClose, 
  placement, 
  className
}) => (
  <Popper
    isOpen={isOpen}
    placement={placement}
    offset={offset}
    portal={portal}
    className="popover-container"
    closeOnClickOutside={dismissable}
    onClose={onClose}
    content={(
      <div className={classnames('popover-content', className)}>
        {content}
      </div>
    )}
  >
    {children}
  </Popper>
);

Popover.defaultProps = {
  isOpen: false,
  dismissable: true,
  position: ['top', 'bottom'],
  color: '#fff'
};

export default Popover;
