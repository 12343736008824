import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { DISPLAY_DATE_TIME_FORMAT } from '@/utilities/dates/formats';
import Strings from './lang';

const UserBaselineTestSelect = ({
  value,
  tests,
  onChange
}) => (
  <select 
    className="form-control"
    value={value}
    onChange={e => {
      if (typeof onChange === 'function') {
        onChange(e.target.value);
      }
    }}
  >
    {tests.map((test, index) => (
      <option key={test.id} value={test.id}>
        {Strings.formatString(
          Strings.testName,
          (index + 1),
          dayjs(test.created_at).format(DISPLAY_DATE_TIME_FORMAT)
        )}
      </option>
    ))}
  </select>
);

UserBaselineTestSelect.propTypes = {
  tests: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
};

export default UserBaselineTestSelect;
