import React, { useCallback, useMemo } from 'react';
import { FormInput } from '@/forms';
import INJURY_STATUSES from './injury-statuses';

const getValidInjuryStatuses = currentStatus => {
  switch (currentStatus) {
    case 'discharged':
      return INJURY_STATUSES.filter(item => {
        return item.status !== 'lost_to_follow_up';
      });
    case 'lost_to_follow_up':
      return INJURY_STATUSES.filter(item => {
        return item.status !== 'discharged';
      });
    default:
      return INJURY_STATUSES;
  }
};

const InjuryStatusSelector = ({
  disabled = false,
  status = '',
  onChange
}) => {
  const validStatuses = useMemo(() => getValidInjuryStatuses(status), [status]);

  const handleChange = useCallback(e => {
    if (onChange) {
      onChange(e.target.value);
    }
  }, [onChange]);

  return (
    <FormInput
      className="form-control injury-status-selector"
      type="select"
      inputProps={{
        disabled,
        value: status,
        onChange: handleChange
      }}
    >
      {validStatuses.map(item => {
        return (
          <option key={item.status} value={item.status}>
            {item.title}
          </option>
        );
      })}
    </FormInput>
  );
};

export default InjuryStatusSelector;
