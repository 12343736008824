import React, { useCallback, useMemo, useState } from 'react';
import PatientsTableHeader from './patients-table-header';
import PatientTableRow from './patients-table-row';
import {
  getClinicPatientsTableSortKeyPath,
  setClinicPatientsTableSortKeyPath
} from './clinic-patients-table-sort-keypath';
import { sortOnKeyPath } from '@/utilities/sort-keypath';

const ClinicPatientsTable = ({
  users = [],
  clinicId,
  showDanaBaseline = true,
  showInjuryStage = false,
  nameViewPermission
}) => {
  const [sortKey, setSortKey] = useState(() => getClinicPatientsTableSortKeyPath());

  const filteredUsers = useMemo(() => (
    sortKey 
      ? sortOnKeyPath(users, sortKey)
      : users
  ), [sortKey, users]);

  const updateSortKey = useCallback((key) => {
    setSortKey(key);
    setClinicPatientsTableSortKeyPath(key);
  }, []);

  return (
    <div className="table-responsive-md">
      <table className="table users-table clinic-patients-table">
        <PatientsTableHeader 
          sortKey={sortKey}
          onUpdateSortKey={updateSortKey}
          showInjuryStage={showInjuryStage}
          showDanaBaseline={showDanaBaseline}
          viewNames={nameViewPermission}
        />
        <tbody>
          {filteredUsers?.map(user => (
            <PatientTableRow 
              key={user.id}
              patient={user}
              clinicId={clinicId}
              showInjuryStage={showInjuryStage}
              showDanaBaseline={showDanaBaseline}
              viewNames={nameViewPermission}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ClinicPatientsTable;
