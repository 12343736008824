import { UPDATE_BUFFALO_TEST } from '../actions/buffalo';

const updateBuffaloTestReducer = (state = {}, action = {}) => {
  const { userId, test = {} } = action;
  const { id } = test;
  const userState = state[userId] || {};
  const nextUserState = (id) ? { ...userState, [id]: test } : userState;
  return (userId) ? { ...state, [userId]: nextUserState } : state;
};

const buffalo = (state = {}, action = {}) => {
  switch (action.type) {
    case UPDATE_BUFFALO_TEST:
      return updateBuffaloTestReducer(state, action);
    default:
      return state;
  }
};

export default buffalo;
