const EN = {
  signInTitle: 'Sign In',
  createAccountTitle: 'Create an Account',
  createAccountButtonTitle: 'Create New Account',
  newPatientsInfoText: 'Which type of account would you like?',
  personalAccountTitle: 'Individual Account',
  personalAccountDescription: 'I am creating this account for myself.',
  getStartedLinkText: 'Get Started',
  guardianAccountTitle: 'Family Account',
  guardianAccountDescription: 'I am creating this on behalf of my child or multiple family members and want to have all accounts under 1 email address.'
};

export default EN;
