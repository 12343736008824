const ADD_CLINIC_IDT_CLAIMS = 'ADD_CLINIC_IDT_CLAIMS';

const addClinicIdtClaims = (clinicId, claims) => ({
  type: ADD_CLINIC_IDT_CLAIMS,
  clinicId,
  claims
});

export {
  ADD_CLINIC_IDT_CLAIMS,
  addClinicIdtClaims
};
