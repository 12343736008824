const FR = {
  title: 'Information pour les parents/tuteurs',
  guardiansEditBtn: 'Modifier',
  guardiansAddBtn: 'Ajouter',
  guardiansDoneBtn: 'Terminé',
  guardiansDecoupleBtn: 'Découper le Compte',
  guardianDecoupleInfoTitle: 'Enlève l\'enfant de la garde des tuteurs et leur fait leur propre compte',
  guardiansRemoveBtn: 'Enlever',
  guardianNextBtn: 'Suivant',
  guardianDropTitle: 'Enregistrer',
  guardianLabelText: 'Êtes-vous un tuteur ?',
  guardianErrorText: 'Veuillez choisir une section',
  firstNameLabelText: 'Prénom',
  guardianFirstNameLabel: 'Prénom du parent/tuteur',
  firstNameErrorText: 'Veuillez indiquer votre prénom.',
  lastNameLabelText: 'Nom de famille',
  guardianLastNameLabel: 'Nom de famille du parent/tuteur',
  lastNameErrorText: 'Veuillez indiquer votre nom de famille.',
  phoneLabelText: 'Numéro de téléphone',
  guardianPhoneLabel: 'No de téléphone du parent/tuteur',
  phoneErrorText: 'Veuillez écrire un numéro de téléphone valide',
  alternatePhoneLabelText: 'Autre téléphone',
  genderLabelText: 'Sexe',
  genderValidationMessage: 'Veuillez choisir votre sexe.',
  genderOtherLabelText: 'Quel est votre sexe ?',
  genderOtherValidationMessage: 'Veuillez indiquer votre sexe.',
  languageLabelText: 'Langue',
  languageValidationMessage: 'Veuillez choisir votre langue de préférence.',
  birthdateLabelText: 'Date de naissance :',
  birthdateConfirmationMessage: '{2}. En continuant, vous confirmez que ce patient est âgé de {0} {1}.',
  year: 'an',
  years: 'ans',
  under1: 'moins de 1',
  birthdateValidationMessage: 'Veuillez écrire une date de naissance valide.',
  isGuardianConfirmationText: 'Êtes-vous le parent/tuteur de ce joueur ?',
  addressLabelText: 'Adresse',
  bioLabelText: 'Biographie',
  credentialsLabelText: 'Qualifications',
  submitButtonText: 'Sauvegarder',
  relationLabelText: 'Lien avec la personne',
  childAccountTitle: 'Ajouter un membre de la famille',
  guardianListTitle: 'Membres de la famille',
  guardianAboutSubTitle: 'Entrez vos informations personnelles',
  guardianReturningSubTitle: 'Mettez à jour vos informations personnelles',
  decoupleChildModalTitle: 'Découper le Compte',
  decoupleChildModalDescription: 'Vous êtes sur le point de découpler {0} de votre compte. Cela signifie que {0} sera supprimé de votre compte et donné un compte personnel. Veuillez entrer une nouvelle adresse email pour {0} ci-dessous.',
  emailLabelText: 'Email',
  cancelButtonText: 'Annuler',
  decoupleSubmitButtonText: 'Soumettre',
  addExistingAccountButtonText: 'Ajouter un compte existant',
  addExistingAccountModalTitle: 'Ajouter un compte existant?',
  accountNumberLabel: 'Numéro de compte',
  accountPasswordLabel: 'Mot de passe du compte',
  addExisitingAccountInfoText: 'Souhaitez-vous ajouter un compte individuel existant en tant que membre de la famille?',
  addExisitingAccountModalAddButton: 'Ajouter',
  addExisitingAccountModalMessage: 'Pour ajouter un compte existant à ce compte familial, veuillez saisir le numéro de <strong>compte et le mot de passe du compte que vous essayez d\'ajouter.</strong> Veuillez noter que seuls les compte PERSONNEL existants peuvent être ajoutés (c\'est-à-dire que les comptes associés à un compte FAMILIAL existant ne peuvent pas être ajoutés à cette famille).',
  accountNumberErrorMessage: 'S\'il vous plaît entrer un numéro de compte valide',
  addAccountNotFoundErrorMessage: 'Impossible de trouver un compte individuel avec le numéro de compte {0}.',
  addAccountUnauthorizedErrorMessage: 'Mot de passe incorrect pour le numéro de compte {0}.',
  emailTakenErrorMessage: 'Cette adresse e-mail a été prise. Veuillez utiliser une adresse e-mail différente'
};
 
export default FR;
