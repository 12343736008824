const NumberPattern = /(\d+(\.\d+)?)/;

const NumberValidator = {
  test(value) {
    return NumberPattern.test(value) && isFinite(value);
  }
};

export {
  NumberPattern,
  NumberValidator
};
