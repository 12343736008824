const EN = {
  initialManagementInfoText: 'Given your full examination, what things would you like to include in the plan of management?',
  workSchoolLabel: 'Work/School',
  workSchoolFullOptionLabel: 'Full',
  workSchoolModifiedOptionLabel: 'Modified',
  explanationLabel: 'Explanation',
  exerciseLabel: 'Exercise',
  exerciseRestritionsLabel: 'Restrictions',
  exerciseNoRestrictionsLabel: 'No Restrictions',
  reEvaluationDateLabel: 'Re-Evaluation Date',
  invalidDateError: 'Please enter a valid date',
  rehabLabel: 'Rehab',
  vestibularLabel: 'Balance/Vestibular',
  cervicalSpineLabel: 'Cervical Spine',
  visualTrackingLabel: 'Visual Tracking',
  sportSpecificLabel: 'Sport Specific',
  neurocognitiveLabel: 'Neurocognitive',
  therapyLabel: 'Therapy',
  softTissueLabel: 'Soft Tissue',
  spinalManipulativeLabel: 'Spinal Manipulative',
  otherLabel: 'Other',
  otherTherapyLabel: 'Other Therapy'
};

export default EN;
