import LocalizedStrings from 'react-localization';
import dayjs from 'dayjs';
import { getLocalStorageItem, setLocalStorageItem } from './local-storage';
import Store from '@/redux/store';
import { UserSelector } from '@/redux/selectors';

const LANGUAGE_KEY = 'com.completeconcussions.language';
const LANGUAGES = ['en', 'fr'];
const DEFAULT_USER_LANGUAGE = 'en';

const getInterfaceLanguage = () => {
  const language = getLocalStorageItem(LANGUAGE_KEY)?.toLowerCase();
  return LANGUAGES.includes(language)
    ? language
    : DEFAULT_USER_LANGUAGE;
};

const setInterfaceLanguage = (language) => setLocalStorageItem(LANGUAGE_KEY, language);

const getCurrentUserLanguage = () => {
  const currentUser = UserSelector.getCurrentUser(Store.getState()) || {};
  const person = currentUser.person || {};
  const language = person.language || getInterfaceLanguage();
  return language;
};

const setLanguageSettings = (strings, language) => {
  strings.setLanguage(language);
  dayjs.locale(language);
};

const createUserLocalizedStrings = (languages) => {
  const strings = new LocalizedStrings(languages);
  setLanguageSettings(strings, getCurrentUserLanguage());

  Store.subscribe(() => {
    setLanguageSettings(strings, getCurrentUserLanguage());
  });

  return strings;
};

export {
  DEFAULT_USER_LANGUAGE,
  getInterfaceLanguage,
  setInterfaceLanguage,
  getCurrentUserLanguage,
  createUserLocalizedStrings
};
