import React, { useState } from 'react';
import classNames from 'classnames';
import Drawer from '../drawer';
import RoleStrings from '@/utilities/role-strings';
import {
  AdminAndClinicRoleDescriptions,
  RoleResourceTypes,
  makeRoleDescription,
  userHasRoleMatchingDescription
} from '@/utilities/user-roles';
import Strings from './lang';
import Icon from '../icon';
import AccessControl from '../access-control';

const DEMO_SITE_URL = 'https://stage.completeconcussions.com';

const Logins = [{
  role: 'clinic_owner',
  email: 'clinicowner@testclinic.com',
  password: 'Pswd123'
}, {
  role: 'clinician',
  email: 'clinician@testclinic.com',
  password: 'Pswd123'
}, {
  role: 'specialist',
  email: 'phy-spec@testclinic.com',
  password: 'Pswd123'
}, {
  role: 'baseline_tester',
  email: 'baselinetester@testclinic.com',
  password: 'Pswd123'
}, {
  role: 'clinic_staff',
  email: 'clinicstaff@testclinic.com',
  password: 'Pswd123'
}, {
  role: 'front_desk',
  email: 'frontdesk@testclinic.com',
  password: 'Pswd123'
}];

const DemoSiteLoginsDrawer = ({
  open,
  user,
  clinic,
  onClose
}) => {
  return (
    <Drawer
      blur
      isOpen={open}
      position="top"
      className="demo-site-logins-drawer"
      onClose={onClose}
    >
      <div className="demo-site-logins-drawer-content">
        <h1 className="text-center">
          {Strings.demoSiteUrlLabel}: <a href={DEMO_SITE_URL} target="_blank" rel="noopener noreferrer">{DEMO_SITE_URL}</a>
        </h1>
        <div className="demo-site-logins">
          {Logins.map((login, index) => (
            <div
              key={index}
              className={classNames(
                'demo-site-login-item', 
                { 
                  highlight: userHasRoleMatchingDescription(
                    user,
                    makeRoleDescription(login.role, RoleResourceTypes.Clinic, clinic.id)
                  ) 
                }
              )}
            >
              <div className="role-heading"> {RoleStrings[login.role]}</div>
              <div><strong>{Strings.emailLabel}:</strong> {login.email}</div>
              <div><strong>{Strings.passwordLabel}:</strong> {login.password}</div>
            </div>
          ))}
        </div>
        <button type="button" className="close" onClick={onClose}>
          <Icon name="xmark" />
        </button>
      </div>
    </Drawer>
  );
};

const DemoSiteLoginsButton = ({ user, clinic }) => {
  const [open, setOpen] = useState(false);

  return (
    <AccessControl roles={AdminAndClinicRoleDescriptions}>
      <button type="button" onClick={() => setOpen(true)}>
        <Icon name="external-link" fixedWidth />
        {Strings.demoSiteButtonLabel}
      </button>
      <DemoSiteLoginsDrawer
        open={open}
        user={user}
        clinic={clinic}
        onClose={() => setOpen(false)}
      />
    </AccessControl>
  );
};

export default DemoSiteLoginsButton;
