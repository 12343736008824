import React from 'react';
import Strings from './lang';

const ClinicServicesCardDisplay = ({ services = [] }) => {
  return (
    <div className="card-row">
      <label>{Strings.servicesLabel}:</label>
      <span>{services.map(service => Strings[`${service}_label`]).join(', ')}</span>
    </div>
  );
};

export default ClinicServicesCardDisplay;
