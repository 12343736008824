const EN = {
  title: 'Injury Insights',
  overviewTabLabel: 'Overview',
  symptomsTabLabel: 'Symptoms',
  injuryAssessmentLabel: 'Injury Assessment',
  soapNoteLabel: 'Clinical Note',
  specialistNoteLabel: 'Specialist Note',
  symptomUpdateLabel: 'Self-Reported',
  injuryDurationCardTitle: 'Injury Duration',
  injuryDurationCardMessage: 'Injured {0}',
  daysDurationLabel: 'days',
  recoveryDurationCardTitle: 'Recovery Duration',
  recoveryDurationCardMessage: 'Assessed {0}',
  assessmentTypeCardTitle: 'Initial Assessment Type',
  assessmentTypeCardMessage: '{0} days from injury to assessment',
  totalVisitsCardTitle: 'Total Visits',
  visitsUnitLabel: 'visits',
  acuteText: 'Acute',
  subacuteText: 'Subacute',
  chronicText: 'Chronic',
  closeButtonText: 'Close',
  currentLabel: 'Current',
  viewHistoryButtonText: 'View History',
  recoveryStageHistoryTitle: 'Recovery Stage History',
  currentRecoveryStageCardTitle: 'Current Recovery Stages',
  diagnosisHistoryTitle: 'Diagnosis History',
  currentDiagnosisCardTitle: 'Current Diagnosis',
  symptomSelectorPlaceholder: 'Select Symptom',
  symptomSeverityLabel: 'Symptom Severity',
  symptomTotalLabel: 'Symptom Totals',
  individualSymptomsLabel: 'Individual Symptoms',
  viewDescriptionLabel: 'View Description',
  topSymptomCategoriesTitle: 'Top Symptom Categories',
  scoreLabel: 'Score',
  baselineReferenceLabel: 'Baseline ({0})',
  symptomColumnTitle: 'Symptom',
  severityColumnTitle: 'Severity',
  diffAbbrColumnTitle: 'Diff.',
  diffColumnDescription: 'Change in latest symptom score',
  diffColumnTitle: 'Difference',
  topSymptomsMetricLabel: 'Top Symptoms',
  topSymptomsEmptyLabel: 'No top symptoms available',
  mostImprovedMetricLabel: 'Most Improved',
  mostImprovedEmptyLabel: 'No symptoms have improved from the initial assessment',
  mostIncreasedMetricLabel: 'Most Increased',
  mostIncreasedEmptyLabel: 'No symptoms have increased from the initial assessment',
  recentSymptomsTableTitle: 'Recent Symptoms Comparison',
  mostRecentColumnLabel: 'Most Recent ({0}: {1})',
  initialAssessmentLabel: 'Initial Assessment',
  baselineLabel: 'Baseline',
  symptomsChartTitle: 'Symptoms Chart',
  symptomCategoriesDescription: 'A weighted average of symptom severities ranging from 0 to 6. Each symptom is given a specific weight based on how relevant that symptom is to a category. These categories are merely suggestions (or insights) for the main factors that may be causing persistance symptoms.',
  rtlRiskCardTitle: 'Return to Work/School Risk',
  lowRiskLabel: 'Low',
  lowRiskDescription: 'This patient has a high likelihood of returning to work/school within 6 months',
  moderateRiskLabel: 'Moderate',
  moderateRiskDescription: 'This patient has a moderate risk of not returning to work/school within 6 months',
  highRiskLabel: 'High',
  highRiskDescription: 'This patient is at high risk of not returning to work/school within 6 months.  Please involve occupational therapy, mental health services, and education and reassurance to optimize their recovery and improve their return to work/school likelihood.',
  noteLabel: 'Note',
  riskDisclaimerMessage: 'If this patient has already returned to work/school, please disregard',
  sourceLabel: 'Source'
};

export default EN;
