import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { If, Then, Else } from 'react-if';
import SportsTeamListItem from './sports-team-list-item';
import Strings from './lang';

class SportsTeamList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editTeamId: 0,
      leaveTeamId: 0
    };
  }

  render() {
    const { teams = [] } = this.props;
    return (
      <If condition={teams.length > 0}>
        <Then>
          <ul className={classNames('list-unstyled sports-team-list', this.props.className)}>
            {teams.map(team => (
              <SportsTeamListItem
                key={team.id || 0}
                team={team}
                isEditing={this.state.editTeamId === team.id}
                isLeaving={this.state.leaveTeamId === team.id}
                disableEditing={
                  // Require that the user cancels or completes an editing or leaving
                  // action on one team before editing or leaving another team:
                  (this.state.editTeamId !== team.id && this.state.editTeamId > 0) 
                  || (this.state.leaveTeamId !== team.id && this.state.leaveTeamId > 0)
                }
                currentUser={this.props.currentUser}
                editorRoles={this.props.editorRoles}
                onStartEditing={() => this.setEditing(team.id)}
                onCancelEditing={() => this.setEditing(0)}
                onCommitEditing={this.props.onUpdatePosition}
                onStartLeaving={() => this.setLeaving(team.id)}
                onCancelLeaving={() => this.setLeaving(0)}
                onCommitLeaving={this.props.onLeaveTeam}
                onClick={this.props.onSelectTeam}
              />
            ))}
          </ul>
        </Then>
        <Else>
          <ul className="list-unstyled list-empty">
            <li>{(this.props.hideEmptyMessage) ? '' : Strings.emptySportsMessageText}</li>
          </ul>
        </Else>
      </If>
    );
  }

  setEditing(editTeamId) {
    // Prevent editing and leaving UI from appearing on multiple teams:
    this.setState({
      editTeamId,
      leaveTeamId: 0
    });
  }

  setLeaving(leaveTeamId) {
    // Prevent editing and leaving UI from appearing on multiple teams:
    this.setState({
      leaveTeamId,
      editTeamId: 0
    });
  }
}

SportsTeamList.propTypes = {
  className: PropTypes.string,
  teams: PropTypes.array.isRequired,
  onSelectTeam: PropTypes.func,
  onUpdatePosition: PropTypes.func,
  onLeaveTeam: PropTypes.func,
  editorRoles: PropTypes.arrayOf(PropTypes.object),
  hideEmptyMessage: PropTypes.bool
};

export default SportsTeamList;
