import Strings from './lang';

const SoccerPositions = [
  { key: 'keeper', name: Strings.soccer_keeper },
  { key: 'defence', name: Strings.soccer_defence },
  { key: 'midfield', name: Strings.soccer_midfield },
  { key: 'forward', name: Strings.soccer_forward },
  { key: 'center', name: Strings.soccer_center }
];

export default SoccerPositions;
