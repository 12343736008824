import React from 'react';
import PropTypes from 'prop-types';
import { If, Then, Else } from 'react-if';
import BaselineCognitiveAssessment from './baseline-cognitive-assessment';
import BaselineMemory from './baseline-memory';
import BaselineConcentration from './baseline-concentration';
import BaselineKingDevick from './baseline-king-devick';
import BaselineBESS from './baseline-bess';
import BaselineForcePlate from './baseline-force-plate';
import BaselineTandemGait from './baseline-tandem-gait';
import BaselineDualTask from './baseline-dual-task';
import BaselineReaction from './baseline-reaction';
import BaselineGripStrength from './baseline-grip-strength';
import BaselineDelayedMemory from './baseline-delayed-memory';
import BaselineDetailRow from './baseline-detail-row';
import SymptomsList from './symptoms-list';
import CompareSymptomsList from './compare-symptoms-list';

const ComparableUserBaselineView = ({
  showSymptomsList = true,
  compare = false,
  compareTest = {},
  test = {},
  language
}) => (
  <div className="comparable-baseline-view">
    <If condition={showSymptomsList}>
      <Then>
        <div className="row">
          <div className="col-md-12">
            <If condition={compare}>
              <Then>
                <CompareSymptomsList
                  leftSymptoms={test.pre_existing_symptoms || {}}
                  rightSymptoms={compareTest.pre_existing_symptoms || {}}
                />
              </Then>
              <Else>
                <SymptomsList symptoms={test.pre_existing_symptoms || {}} />
              </Else>
            </If>
          </div>
        </div>
      </Then>
    </If>

    <BaselineDetailRow
      component={BaselineCognitiveAssessment}
      testProps={{
        data: test.cognitive_assessment_test || {}
      }}
      compareProps={{
        data: compareTest.cognitive_assessment_test || {}
      }}
      compare={compare}
    />

    <BaselineDetailRow
      component={BaselineMemory}
      testProps={{
        data: test.immediate_memory_test || {},
        language
      }}
      compareProps={{
        data: compareTest.immediate_memory_test || {},
        language
      }}
      compare={compare}
    />

    <BaselineDetailRow
      component={BaselineConcentration}
      testProps={{
        data: test.concentration_test || {}
      }}
      compareProps={{
        data: compareTest.concentration_test || {}
      }}
      compare={compare}
    />

    <BaselineDetailRow
      component={BaselineKingDevick}
      testProps={{
        data: test.king_devick_test || {}
      }}
      compareProps={{
        data: compareTest.king_devick_test || {}
      }}
      compare={compare}
    />

    <BaselineDetailRow
      component={BaselineBESS}
      testProps={{
        data: test.balance_test || {}
      }}
      compareProps={{
        data: compareTest.balance_test || {}
      }}
      compare={compare}
    />

    <BaselineDetailRow
      component={BaselineForcePlate}
      testProps={{
        data: test.force_plate_test || {}
      }}
      compareProps={{
        data: compareTest.force_plate_test || {}
      }}
      compare={compare}
    />

    {(test?.test_version > 1 || compareTest?.test_version > 1) && (
      <>
        <BaselineDetailRow
          component={BaselineTandemGait}
          testProps={{
            data: test.tandem_gait_test || {}
          }}
          compareProps={{
            data: compareTest.tandem_gait_test || {}
          }}
          compare={compare}
        />
        
        <BaselineDetailRow
          component={BaselineDualTask}
          testProps={{
            data: test.dual_task_test || {}
          }}
          compareProps={{
            data: compareTest.dual_task_test || {}
          }}
          compare={compare}
        />
      </>
    )}

    <BaselineDetailRow
      component={BaselineReaction}
      testProps={{
        data: test.reaction_time_test || {}
      }}
      compareProps={{
        data: compareTest.reaction_time_test || {}
      }}
      compare={compare}
    />

    <BaselineDetailRow
      component={BaselineGripStrength}
      testProps={{
        data: test.grip_strength_test || {}
      }}
      compareProps={{
        data: compareTest.grip_strength_test || {}
      }}
      compare={compare}
    />

    <BaselineDetailRow
      component={BaselineDelayedMemory}
      testProps={{
        data: {
          ...(test.delayed_memory_recall_test || {}),
          word_count: (test.immediate_memory_test || {}).word_count
        } 
      }}
      compareProps={{
        data: {
          ...(compareTest.delayed_memory_recall_test || {}),
          word_count: (compareTest.immediate_memory_test || {}).word_count
        }
      }}
      compare={compare}
    />
  </div>
);

ComparableUserBaselineView.propTypes = {
  showSymptomsList: PropTypes.bool,
  compare: PropTypes.bool,
  test: PropTypes.object,
  compareTest: PropTypes.object
};

export default ComparableUserBaselineView;
