const FR = {
  reviewTitle: 'Revoir le test de référence',
  compareTitle: 'Comparer les tests de référence',
  baselineTitle: 'Test de référence \u2014 {0}',
  testName: 'Test post-blessure {0} \u2014 {1}',
  symptomsTitle: 'Symptômes',
  cognitiveAssessmentTitle: 'Test d\'orientation',
  memoryTitle: 'Test de la mémoire',
  concentrationTitle: 'Test de la concentration',
  kingDevickTitle: 'Test King-Devick',
  bessTitle: 'Test BESS',
  wessTitle: 'Test WESS',
  forcePlateTitle: 'Test Force Plate (COP)',
  tandemGaitTitle: 'Démarche tandem chronométrée',
  dualTaskTitle: 'Démarche tandem à double tâche',
  reactionTitle: 'Temps de réaction',
  gripStrengthTitle: 'Force de préhension',
  delayedMemoryTitle: 'Test de mémoire différée',
  testDateLabelText: 'Date du test:',
  languageLabelText: 'Langue:',
  notesLabelText: 'Remarque:',
  totalScoreLabelText: 'Total de sévérité des symptômes',
  totalSymptomsLabelText: 'Total des symptômes',
  wordListLabelText: 'Liste de mots {0}:',
  trialLabelText: 'Essai {0}:',
  averageLabelText: 'Moyenne:',
  averageShortLabelText: 'Moy.',
  scoreLabelText: 'Résultat:',
  numbersScoreLabelText: 'Résultat du test de nombres inversés:',
  monthsScoreLabelText: 'Résultat du test de mois inversés:',
  versionLabelText: 'Version:',
  cardLabelText: 'Carte:',
  secondsLabelText: 'Temps (secondes):',
  errorsLabelText: 'Erreurs:',
  totalLabelText: 'Total:',
  testLabelText: 'Test:',
  dominantHandLabelText: 'Main dominante:',
  handLabelText: 'Main:',
  dominantFootLabelText: 'Pied dominant:',
  footLabelText: 'Pied:',
  leftLabelText: 'Gauche',
  rightLabelText: 'Droit',
  copLabelText: 'Centre de pression:',
  distanceLabelText: 'Distance (cm):',
  reactionTimeLabelText: 'Temps de réaction (s):',
  whatDateLabelText: 'Quelle est la date?',
  whatTimeLabelText: 'Quelle heure est-il?',
  whatDayLabelText: 'Quel jour sommes-nous?',
  whatMonthLabelText: 'Quel mois sommes-nous?',
  whatYearLabelText: 'Quelle année sommes-nous?',
  wess_seated_errors: 'Assise',
  wess_balance_disk_errors: 'Disque d\'équilibre',
  wess_wheelie_errors: 'Wheelie',
  wessEyesOpenErrors: 'Erreurs yeux ouverts:',
  wessEyesClosedErrors: 'Erreurs yeux fermés:',
  feet_together_errors: 'Pieds ensemble',
  single_leg_errors: 'Posture sur un pied (pied non dominant)',
  tandom_errors: 'Posture sur deux pieds (pied dominant à l\'avant)',
  foam_pad_errors: 'Avec coussinet (pied non dominant) (facultatif)',
  noActiveBaseline: 'Ce patient n\'a pas de test de référence actif d\'avant {0} pour faire la comparaison',
  notDoneText: 'Incomplet',
  baselineExpiredText: 'Le test de référence de ce patient est expiré et n\'est donc plus valide.',
  notCompletedText: 'Section incomplète',
  editBaselineButtonText: 'Modifier le test de référence',
  lastEditedLabelText: 'Dernière édition:',
  lastEditedByText: '{0} par {1}',
  continueBaselineText: 'Continuer le test',
  archiveTestText: 'Effacer',
  archivedTagText: 'Supprimé',
  unarchiveTestText: 'Restaurer',
  archivedMessage: 'Supprimé le {0} par {1} pour la raison suivante',
  archivedSuccessMessage: 'Le test de référence a été supprimé avec succès!',
  unarchivedSuccessMessage: 'Le test de référence a été restauré avec succès!',
  secondsLabel: 'seconds',
  trialColumnLabel: 'Procès',
  durationSecondsColumnLabel: 'Durée (s)',
  passFailColumnLabel: 'Réussite/Échec',
  passedText: 'Passé',
  failedText: 'Échoué',
  fastestTimeLabel: 'Temps le plus rapide',
  startIntegerLabel: 'Début Entier',
  reasonLabel: 'raison',
  attemptedLabel: 'Tentative',
  correctLabel: 'Correct',
  errorsLabel: 'Erreurs',
  subtractionValueLabel: 'Valeur de soustraction',
  unableToCompleteLabel: 'Le patient n\'a pas pu terminer le test',
  dualTaskCognitiveAccuracyLabel: 'Précision cognitive',
  dualTaskResponseRateLabel: 'Taux de réponse'
};
 
export default FR;
