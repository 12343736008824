import React, { useCallback, useRef, useState } from 'react';
import { 
  FormComponent, 
  StripeCardInput,
  FormInputGroup
} from '@/forms';
import { Money } from '@/utilities/currency';
import Strings from './lang';

const StripePaymentForm = ({ 
  amount,
  currency,
  onSubmit = () => {}
}) => {
  const elementRef = useRef();
  const [cardValid, setCardValid] = useState(false);
  const [billingName, setBillingName] = useState('');

  const isValid = useCallback(() => {
    return cardValid && !!billingName;
  }, [billingName, cardValid]);

  const handleSubmit = useCallback(() => {
    const card = elementRef.current.getElement();

    onSubmit({
      card,
      billing_details: { name: billingName }
    });
  }, [billingName, onSubmit]);

  return (
    <FormComponent className="payment-form" onSubmit={handleSubmit}>
      <StripeCardInput 
        required 
        ref={elementRef}
        label={Strings.creditCardInputLabel}
        onValidStateChange={setCardValid}
      />
      <FormInputGroup
        required
        className="form-group"
        labelText={Strings.billingNameInputLabel}
        inputType="text"
        inputProps={{
          className: 'form-control',
          value: billingName,
          onChange: (e) => setBillingName(e.target.value) 
        }}
        messageText={Strings.billingNameErrorMessage}
        messageClassName="alert alert-danger"
        inputValid={!!billingName}
      />
      <button 
        type="submit" 
        className="btn btn-primary"
        disabled={!isValid()}
      >
        {Strings.formatString(
          Strings.payAmountButtonText,
          Money.format(amount, currency),
          currency
        )}
      </button>
    </FormComponent>
  );
};

export default StripePaymentForm;
