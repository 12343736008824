import dispatchCustomEvent from '@/utilities/custom-event';

const ALERT_SHOW = 'Alert.show';

const showAlert = (type, options = {}) => {
  dispatchCustomEvent(ALERT_SHOW, {
    type, options
  });
};

export {
  ALERT_SHOW,
  showAlert
};
