import React, { useMemo } from 'react';
import { If, Then, Else } from 'react-if';
import { filterAndGroupCheckedDiagnosisKeys } from './diagnosis-utilities';
import DiagnosisChecklistView from './diagnosis-checklist-view';
import Strings from './lang';

const DiagnosisView = ({
  diagnosis = {},
  showNotes = true,
  className
}) => {
  const { checkedKeys, groupedKeys } = useMemo(() => (
    filterAndGroupCheckedDiagnosisKeys(diagnosis)
  ), [diagnosis]);
  const hasDiagnosis = diagnosis.none !== true && checkedKeys.length > 0;

  return (
    <div className={className}>
      <If condition={hasDiagnosis}>
        <Then>
          <div className="row">
            {
              groupedKeys.map((group, index) => {
                return (
                  <DiagnosisChecklistView
                    key={index}
                    className="col-md-6"
                    list={group}
                    diagnosis={diagnosis}
                  />
                );
              })
            }
          </div>
        </Then>
        <Else>
          <div className="row">
            <div className="col-md-12">
              {Strings.defaultDiagnosisText}
            </div>
          </div>
        </Else>
      </If>

      <If condition={showNotes && diagnosis.notes?.length > 0}>
        <Then>
          <div className="row">
            <div className="col-md-12">
              <p><strong>{Strings.diagnosisNotesLabelText}</strong> {diagnosis.notes}</p>
            </div>
          </div>
        </Then>
      </If>
    </div>
  );
};

export default DiagnosisView;
