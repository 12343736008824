import Strings from './lang';

const questions = {
  month: {
    text: Strings.monthQuestion,
    value: ''
  }, 
  date: {
    text: Strings.dateQuestion,
    value: ''
  }, 
  dayOfWeek: {
    text: Strings.dayOfWeekQuestion,
    value: ''
  }, 
  year: {
    text: Strings.yearQuestion,
    value: ''
  },
  time: {
    text: Strings.timeQuestion,
    value: ''
  }
};

const questionAnswers = {
  0: Strings.answerIncorrect,
  1: Strings.answerCorrect
};

export {
  questions, 
  questionAnswers
};
