import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import Modal from '../modal';
import Activity from '../activity';
import Icon from '../icon';
import NotificationItem from './notification-item';
import { ErrorBanner } from '../errors';
import { updateNotificationAsync, getNotificationsAsync } from '@/redux/thunks/notifications';
import Strings from './lang';
import { UserSelector } from '@/redux/selectors';

const NotificationModal = ({
  isOpen = false,
  currentUser,
  notifications,
  getNotifications,
  updateNotification,
  onClose = () => { }
}) => {
  const [activity, setActivity] = useState(false);
  const [error, setError] = useState(null);

  const fetchNotifications = useCallback(() => {
    setActivity(true);
    setError(null);

    getNotifications(currentUser.id).then(() => {
      setActivity(false);
    }).catch(error => {
      setActivity(false);
      setError(error.message);
    });
  }, [currentUser.id, getNotifications]);

  const markAsRead = useCallback((notificationId) => {
    setActivity(true);
    setError(null);

    const attributes = { notification_id: notificationId, read: true };

    updateNotification(currentUser.id, notificationId, attributes).then(() => {
      setActivity(false);
      if (notifications.length - 1 <= 0) {
        onClose();
      }
    }).catch(error => {
      setActivity(false);
      setError(error.message);
    });
  }, [currentUser.id, notifications.length, onClose, updateNotification]);

  useEffect(() => {
    if (isOpen) {
      fetchNotifications();
    }
  }, [fetchNotifications, isOpen]);

  return (
    <Modal
      blur
      isOpen={isOpen}
      onClose={onClose}
    >
      <Modal.Header>
        <button type="button" className="close" onClick={onClose}>
          <Icon name="xmark" />
        </button>
        <h1>{Strings.notificationsModalTitle} ({notifications.length})</h1>
      </Modal.Header>
      <Modal.Body>
        <div className="notifications">
          <ErrorBanner error={error} />
          <Activity active={activity} static={notifications.length === 0}>
            <ul className="notifications-list">
              {notifications.map(notification => (
                <NotificationItem
                  key={notification.id}
                  notification={notification}
                  onDismiss={markAsRead}
                  onNavigate={onClose}
                />
              ))}
            </ul>
          </Activity>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-primary" onClick={onClose}>
          {Strings.doneButtonTitle}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

const sortNotifications = (a, b) => {
  if (a.created_at !== b.created_at) {
    return (a.created_at > b.created_at) ? 1 : -1;
  }

  return 0;
};

const mapStateToProps = (state) => {
  const { notifications = {} } = state;
  const currentUser = UserSelector.getCurrentUser(state) || {};
  const userNotifications = Object.values(notifications[currentUser.id] || {})
    .filter(n => n.read === false)
    .sort(sortNotifications);

  return {
    currentUser,
    notifications: userNotifications
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getNotifications: (userId) => {
      return dispatch(getNotificationsAsync(userId));
    },
    updateNotification: (userId, notificationId, attributes) => {
      return dispatch(updateNotificationAsync(userId, notificationId, attributes));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationModal);
