import SymptomKeys from './keys';

const OrderedSymptomKeys = [
  SymptomKeys.Headache,
  SymptomKeys.HeadPressure,
  SymptomKeys.NeckPain,
  SymptomKeys.Nausea,
  SymptomKeys.Dizziness,
  SymptomKeys.BlurredVision,
  SymptomKeys.BalanceProblems,
  SymptomKeys.LightSensitivity,
  SymptomKeys.NoiseSensitivity,
  SymptomKeys.FeelingSlowedDown,
  SymptomKeys.FeelingInAFog,
  SymptomKeys.DontFeelRight,
  SymptomKeys.DifficultyConcentrating,
  SymptomKeys.DifficultyRemembering,
  SymptomKeys.Fatigue,
  SymptomKeys.Confusion,
  SymptomKeys.Drowsiness,
  SymptomKeys.TroubleFallingAsleep,
  SymptomKeys.MoreEmotional,
  SymptomKeys.Irritability,
  SymptomKeys.Sadness,
  SymptomKeys.Nervous
];

export default OrderedSymptomKeys;
