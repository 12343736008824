import React from 'react';
import PropTypes from 'prop-types';

const BuffaloTestV2TableRow = ({
  minute,
  speed,
  incline,
  heartRate,
  borgScale,
  symptomScore,
  symptoms
}) => (
  <tr>
    <td>{minute}</td>
    <td>{speed.toFixed(1)}</td>
    <td>{incline}%</td>
    <td>{heartRate}</td>
    <td>{borgScale}</td>
    <td>{symptomScore}</td>
    <td>{symptoms}</td>
  </tr>
);

BuffaloTestV2TableRow.propTypes = {
  minute: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  speed: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  incline: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  heartRate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  borgScale: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  symptomScore: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  symptoms: PropTypes.string
};

export default BuffaloTestV2TableRow;
