import React from 'react';
import PropTypes from 'prop-types';
import { If, Then, Else } from 'react-if';
import BaselineSection from './baseline-section';
import Fraction from '../../fraction';
import Icon from '../../icon';
import Strings from './lang';

const renderStatusIcon = (value) => (
  <Icon name={value > 0 ? 'check' : 'xmark'} />
);

const isLegacyVersion = (orientation = {}) => {
  const { 
    what_date, what_time, what_day, what_month, what_year 
  } = orientation;

  return (what_date === '' || what_date === undefined) 
    && (what_time === '' || what_time === undefined) 
    && (what_day === '' || what_day === undefined) 
    && (what_month === '' || what_month === undefined) 
    && (what_year === '' || what_year === undefined);
};

const BaselineCognitiveAssessment = ({
  data = {}, 
  score
}) => {
  const legacyDisplay = isLegacyVersion(data);
  const scoreValue = score !== undefined ? score : data.sac_standard_assessment_score;

  return (
    <BaselineSection
      className="baseline-cognitive-assessment"
      title={Strings.cognitiveAssessmentTitle}
      notes={data.sac_notes || ''}
      sectionCompleted={Object.keys(data || {}).length > 0 && !(data || {}).not_done}
    >
      <If condition={!legacyDisplay}>
        <Then>
          <table className="table">
            <thead>
              <tr>
                <th className="no-action">{Strings.whatDateLabelText}</th>
                <th className="no-action">{Strings.whatTimeLabelText}</th>
                <th className="no-action">{Strings.whatDayLabelText}</th>
                <th className="no-action">{Strings.whatMonthLabelText}</th>
                <th className="no-action">{Strings.whatYearLabelText}</th>
                <th className="no-action"><strong>{Strings.totalLabelText}</strong></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{renderStatusIcon(data.what_date)}</td>
                <td>{renderStatusIcon(data.what_time)}</td>
                <td>{renderStatusIcon(data.what_day)}</td>
                <td>{renderStatusIcon(data.what_month)}</td>
                <td>{renderStatusIcon(data.what_year)}</td>
                <td className="total"><Fraction numerator={scoreValue} denominator={5} /></td>
              </tr>
            </tbody>
          </table>
        </Then>
        <Else>
          <p>
            <strong>{Strings.scoreLabelText}</strong> {scoreValue}
          </p>
        </Else>
      </If>
    </BaselineSection>
  );
};

BaselineCognitiveAssessment.propTypes = {
  data: PropTypes.object.isRequired
};

export default BaselineCognitiveAssessment;
