import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { If, Then, Else } from 'react-if';
import { getSymptomUpdatesAsync } from '@/redux/thunks/symptoms-updates';
import SymptomUpdatesHeader from './symptom-updates-header';
import { ErrorBanner } from '../errors';
import Activity from '../activity';
import SymptomsUpdatesTable from './symptoms-updates-table';
import SymptomsUpdatesPrintDisplay from './symptom-updates-print-display';
import Strings from './lang';
import { useMount } from '@/hooks';
import { InjurySelector, UserSelector } from '@/redux/selectors';

const symptomUpdatesSelector = (userId, injuryId) => state => {
  const symptomsUser = state.symptomsUpdates[userId] || {};
  return symptomsUser[injuryId] || [];
};

const SymptomsUpdates = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [activity, setActivity] = useState(false);
  const [error, setError] = useState(null);
  const user = useSelector(state => UserSelector.getUser(state, { userId: params.userId }));
  const injury = useSelector(state => (
    InjurySelector.getUserInjury(state, { userId: params.userId, injuryId: params.injuryId })
  ));
  const symptomsUpdates = useSelector(symptomUpdatesSelector(params.userId, params.injuryId));

  const getSymptoms = useCallback((userId, injuryId) => {
    setActivity(true);
    setError(null);

    dispatch(getSymptomUpdatesAsync(userId, injuryId)).then(() => {
      setActivity(false);
    }).catch(error => {
      setActivity(false);
      setError(error.message);
    });
  }, [dispatch]);

  useMount(() => {
    if (params.userId && params.injuryId) {
      getSymptoms(params.userId, params.injuryId);
    }
  });

  return (
    <div className="patient-symptom-updates">

      <div className="row">
        <div className="col-md-12">
          <h1 className="display">{Strings.title}</h1>
        </div>
      </div>

      <Activity active={activity} static>
        <div>

          <div className="row">
            <div className="col-md-12">
              <SymptomUpdatesHeader
                user={user}
                injury={injury}
              />
            </div>
          </div>

          <ErrorBanner error={error} />

          <div className="row">
            <div className="col-md-12">
              <If condition={symptomsUpdates.length > 0}>
                <Then>
                  <>
                    <SymptomsUpdatesTable
                      symptomsUpdates={symptomsUpdates}
                    />
                    <SymptomsUpdatesPrintDisplay
                      symptomsUpdates={symptomsUpdates}
                    />
                  </>
                </Then>
                <Else>
                  <p className="text-muted text-center">
                    <i>
                      {Strings.formatString(
                        Strings.noSymptomsReported,
                        user?.person?.first_name || Strings.thisPatientText
                      )}
                    </i>
                  </p>
                </Else>
              </If>

            </div>
          </div>

        </div>
      </Activity>
    </div>
  );
};

export default SymptomsUpdates;
