import React from 'react';
import NameSearchForm from './name-search-form';

const ProductFilters = ({
  name,
  onChange = () => {}
}) => {
  return (
    <div className="admin-product-filters">
      <NameSearchForm 
        value={name}
        onChange={(name) => onChange({ name })}
      />
    </div>
  );
};

export default ProductFilters;
