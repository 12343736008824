const EN = {
  title: 'Sports Information',
  guardianNoSportsAlertMessage: 'If {0} does not play in any organized sports,',
  noSportsAlertMessage: 'If you do not play in any organized sports,',
  skipStepText: 'Skip this step',
  confirmMessage: 'Do you play sports in an organized league?',
  childConfirmMessage: 'Does {0} play sports in an organized league?',
  yesLinkText: 'Yes',
  noLinkText: 'No',
  doneButtonText: 'Finish'
};

export default EN;
