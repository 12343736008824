const UPDATE_CLINIC_BALANCE = 'UPDATE_CLINIC_BALANCE';

const updateClinicBalance = (clinicId, clinicBalance) => ({
  type: UPDATE_CLINIC_BALANCE,
  clinicId,
  clinicBalance
});

export {
  UPDATE_CLINIC_BALANCE,
  updateClinicBalance
};
