import React from 'react';
import { HeadingRow, TrialRow } from './trial-row';
import Strings from './lang';

const TrialsTable = ({ step, trials = [], onTrialChange }) => {
  return (
    <div className="test-trials-container">
      <div className="trial-section-title">
        {Strings.trialsTitle}
      </div>
      <p>
        {Strings.promptStartText}&nbsp;
        <i className="primary-dark">&quot;{Strings.formatString(Strings.trialsPrompt, trials[0]?.start)}&quot;</i>
      </p>
      <p className="alert alert-info" dangerouslySetInnerHTML={{ __html: Strings.tandemGaitErrorsMessage }} />
      <div className="dual-task-trials-grid-container">
        <HeadingRow />
        {trials.map((trial, i) => (
          <TrialRow
            key={i}
            step={step}
            trial={trial}
            number={i + 1}
            onTrialChange={(attributes) => onTrialChange(i, attributes)}
          />
        ))}
      </div>
    </div>
  );
};

export default TrialsTable;
