import React from 'react';
import Strings from './lang';
import Icon from '../icon';

const PaymentFailed = ({ error, onRetry }) => {
  return (
    <div className="payment-status-view">
      <div className="payment-status-icon">
        <Icon name="circle-exclamation" className="error" />
      </div>
      <h2>{Strings.formatString(Strings.paymentFailedErrorMessage, error.message)}</h2>
      <button type="button" className="edit-button" onClick={onRetry}>
        <Icon name="arrows-rotate" />
        {Strings.retryPaymentButtonText}
      </button>
    </div>
  );
};

export default PaymentFailed;
