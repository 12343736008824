import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { acceptInviteAsync } from '@/redux/thunks/invites';
import { ClinicUserOnboardingPaths } from '@/paths';
import { Page } from '../page';
import { ErrorBanner } from '../errors';
import Activity from '../activity';
import InviteAcceptForm from './invite-accept-form';
import Strings from './lang';

const InviteAccept = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activity, setActivity] = useState(false);
  const [error, setError] = useState(null);

  const onAcceptInvite = useCallback((attributes) => {
    setActivity(true);
    setError(null);

    dispatch(acceptInviteAsync(attributes)).then(() => {
      setActivity(false);
      navigate(ClinicUserOnboardingPaths.index.link, { replace: true });
    }).catch(error => {
      setActivity(false);
      setError(error.message);
    });
  }, [dispatch, navigate]);

  return (
    <Page className="clinic-invite-user-accept" hideNavigationContent showLanguageSelector>
      <div className="row">
        <div className="col-md-6 col-md-offset-3">
          <h1 className="display text-center">{Strings.title}</h1>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 col-md-offset-3">
          <ErrorBanner error={error} />
          <Activity active={activity}>
            <InviteAcceptForm
              token={params.token}
              onSubmit={onAcceptInvite}
            />
          </Activity>
        </div>
      </div>
    </Page>
  );
};

export default InviteAccept;
