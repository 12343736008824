const UPDATE_BLACKHAWKS_TEST = 'UPDATE_BLACKHAWKS_TEST';

const updateBlackhawksTest = (userId, test) => ({
  type: UPDATE_BLACKHAWKS_TEST,
  userId,
  test
});

export {
  UPDATE_BLACKHAWKS_TEST,
  updateBlackhawksTest
};
