import SymptomScoresStep from './symptom-scores-step';
import OrientationStep from './orientation-step';
import ImmediateMemoryStep from './immediate-memory-step';
import ConcentrationStep from './concentration-step';
import KingDevickStep from './king-devick-step';
import BalanceTestStep from './balance-test-step';
import ForcePlateStep from './force-plate-step';
import TandemGaitStep from './tandem-gait-step';
import DualTaskTestStep from './dual-task-step';
import ReactionTimeStep from './reaction-time-step';
import GripStrengthStep from './grip-strength-step';
import DelayedMemoryStep from './delayed-memory-step';

const StepComponents = {
  pre_existing_symptoms: SymptomScoresStep,
  cognitive_assessment_test: OrientationStep,
  immediate_memory_test: ImmediateMemoryStep,
  concentration_test: ConcentrationStep,
  king_devick_test: KingDevickStep,
  balance_test: BalanceTestStep,
  force_plate_test: ForcePlateStep,
  tandem_gait_test: TandemGaitStep,
  dual_task_test: DualTaskTestStep,
  reaction_time_test: ReactionTimeStep,
  grip_strength_test: GripStrengthStep,
  delayed_memory_recall_test: DelayedMemoryStep
};

export default StepComponents;

export {
  SymptomScoresStep,
  OrientationStep,
  ImmediateMemoryStep,
  ConcentrationStep,
  KingDevickStep,
  BalanceTestStep,
  ForcePlateStep,
  TandemGaitStep,
  DualTaskTestStep,
  ReactionTimeStep,
  GripStrengthStep,
  DelayedMemoryStep
};
