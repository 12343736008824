import React from 'react';
import PropTypes from 'prop-types';
import { PatientName } from '../patient-meta';
import Strings from './lang';

const TestSearchResultsCard = ({
  onUserSelect = () => {},
  results,
  clinicId,
  allowInjured = false,
  startTestButtonText = Strings.startTestButtonText
}) => (
  <div className="card-container">

    <div className="card-container-header">
      <h2>{Strings.searchResultsHeadingText}</h2>
    </div>

    <div className="card-container-body">
      <div className="card card-show">
        <p>{Strings.searchResultsMessageText}</p>
        <table className="table">
          <tbody>
            {results.map(user => (
              <tr key={user.id}>
                <td>
                  <PatientName patient={user} clinicId={clinicId} />
                </td>
                <td>
                  {user.active_injury_id && !allowInjured ? (
                    <span className="error">
                      {Strings.searchResultOngoingInjuryText}
                    </span>
                  ) : (
                    <button type="button" className="edit-button" onClick={() => onUserSelect(user)}>
                      {startTestButtonText}
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  </div>
);

TestSearchResultsCard.propTypes = {
  results: PropTypes.array.isRequired,
  clinicId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  startTestButtonText: PropTypes.string,
  onUserSelect: PropTypes.func
};

export default TestSearchResultsCard;
