const EN = {
  monthQuestion: 'What month is it?',
  dateQuestion: 'What is the date today?',
  dayOfWeekQuestion: 'What is the day of the week?',
  yearQuestion: 'What year is it?',
  timeQuestion: 'What time is it right now (within an hour)?',
  answerCorrect: 'Correct',
  answerIncorrect: 'Incorrect',
  scoreTitle: 'Orientation Total:',
  requiredError: 'This question is required'
};

export default EN;
