import React from 'react';
import { loadStripe as stripeLoader } from '@stripe/stripe-js/pure';
import { Elements } from '@stripe/react-stripe-js';
import { DEFAULT_USER_LANGUAGE } from '@/utilities/localization';

let stripe = null;

const loadStripe = (locale = DEFAULT_USER_LANGUAGE) => {
  if (!stripe) {
    stripeLoader.setLoadParameters({ advancedFraudSignals: false });
    stripe = stripeLoader(process.env.CCMI_STRIPE_API_PUBLISHABLE_KEY, { locale });
  }

  return stripe;
};

const StripeProvider = ({ locale, children }) => {
  return (
    <Elements stripe={loadStripe(locale)}>
      <>
        {children}
      </>
    </Elements>
  );
};

const withStripe = (Component) => {
  return ({ locale, ...rest }) => {
    return (
      <StripeProvider locale={locale}>
        <Component {...rest} />
      </StripeProvider>
    );
  };
};

export default withStripe;
export { StripeProvider, loadStripe };
