export const OutcomeMeasuresConfig = [
  {
    name: 'Generalized Anxiety Disorder 7-item (GAD-7).pdf',
    filePath: 'documents/idt/Generalized+Anxiety+Disorder+7-item+(GAD-7).pdf',
    type: 'pdf'
  },
  {
    name: 'Lower Extremity Functional Scale.pdf',
    filePath: 'documents/idt/Lower+Extremity+Functional+Scale.pdf',
    type: 'pdf'
  },
  {
    name: 'Neck Disability Index.pdf',
    filePath: 'documents/idt/Neck+Disability+Index.pdf',
    type: 'pdf'
  },
  {
    name: 'Oswestry Low Back Pain Disability Questionnaire.pdf',
    filePath: 'documents/idt/Oswestry+Low+Back+Pain+Disability+Questionnaire.pdf',
    type: 'pdf'
  },
  {
    name: 'Pain Self Efficacy Questionnaire.pdf',
    filePath: 'documents/idt/Pain+Self+Efficacy+Questionnaire.pdf',
    type: 'pdf'
  },
  {
    name: 'Patient Health Questionnaire (PHQ-9).pdf',
    filePath: 'documents/idt/Patient+Health+Questionnaire+(PHQ-9).pdf',
    type: 'pdf'
  },
  {
    name: 'QuickDASH.pdf',
    filePath: 'documents/idt/QuickDASH.pdf',
    type: 'pdf'
  }
];
