const FR = {
  registerTitle: 'Merci de vous être enregistré',
  returningTitle: 'Merci d’avoir renouvelé votre compte',
  registerHeaderMarkdown: '## Félicitations {0} {1} ! Vous avez réussi à vous enregistrer pour obtenir votre test de référence ou votre évaluation de commotion cérébrale de pré-saison effectuée par Complete Concussion Management inc.\r\n\r\nVeuillez apporter à votre rendez-vous en clinique le numéro de compte suivant.',
  returningHeaderMarkdown: '## Félicitations {0} {1} ! Vous avez réussi à renouveler votre compte avec Complete Concussion Management inc.\r\n\r\nPour vos rendez-vous, vous pouvez continuer à utiliser le numéro de compte suivant.',
  accountNumberLabelText: 'Votre no de compte :',
  childAccountNumberLabelText: 'No de compte :',
  clinicAssignedLabelText: 'Votre clinique :',
  childClinicAssignedLabelText: 'Clinique :',
  clinicUnassignedLabelText: 'Votre clinique n’a pas encore été assignée.',
  childClinicUnassignedLabelText: 'La clinique n’a pas encore été assignée.',
  contactLinkText: 'Veuillez trouver la clinique la plus près de chez vous.',
  footerMarkdown: '## Information du test de référence : \r\n\r\nCe test exhaustif prendra environ 30 minutes à faire et peut nécessiter de remplir une portion secondaire à la maison (selon votre âge). Si ce test est passé par l’intermédiaire d’une équipe ou d’une organisation, veuillez attendre les indications de votre entraîneur ou de votre organisation concernant l’heure et la date du test. \r\n\r\nVeuillez vous présenter au moins 20 minutes avant votre heure indiquée pour le test avec votre no de compte habillé en tenue sportive. Une fois le test complété, vos résultats seront enregistrés dans la base de données Complete Concussion Management inc.<sup>TM</sup> (CCMI).  En cas de commotion cérébrale, ou de soupçon de commotion cérébrale, veuillez communiquer dès que possible avec la clinique CCMI reconnue la plus près, pour obtenir une évaluation complète et revenir à la gestion du jeu. \r\n\r\n##Information sur l’évaluation de commotion cérébrale : \r\n\r\n##Si vous avez déjà reçu une commotion ou que vous croyez en avoir déjà reçu une, veuillez apporter toute information médicale pertinente ainsi que tout résultat de test/diagnostic précédent (MRL, scan CT, ou rapports d’urgence) disponible avant votre rendez-vous.\r\n\r\n##Cordialement,\r\n\r\n##L’équipe Complete Concussion Management inc.<b><sup>MD</sup></b>**\r\n\r\n',
  footerLinkText: 'Site Web CCMI'
};
 
export default FR;
