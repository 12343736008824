import React from 'react';
import { Authorization } from '../authorization';
import { AdminRoleDescriptions, RoleDescriptions } from '@/utilities/user-roles';
import { CourseVideos, CoursesList } from '@/components/course-videos';
import GoogleDrive from '@/components/google-drive';
import PageNotFound from '../not-found-page';

const ResourcesRoutes = [
  {
    path: 'courses/*',
    element: (
      <Authorization
        allowRoles={[
          ...AdminRoleDescriptions,
          RoleDescriptions.Clinician
        ]}
      />
    ),
    children: [
      {
        index: true,
        element: <CoursesList />
      },
      {
        path: ':courseId',
        element: <CourseVideos />
      },
      {
        path: '*',
        element: <PageNotFound />
      }
    ]
  },
  {
    path: ':fileId?',
    element: (
      <Authorization
        allowRoles={[
          ...AdminRoleDescriptions,
          RoleDescriptions.Clinician,
          RoleDescriptions.ClinicOwner,
          RoleDescriptions.Specialist,
          RoleDescriptions.ClinicFrontDesk,
          RoleDescriptions.ClinicStaff
        ]}
      >
        <GoogleDrive />
      </Authorization>
    )
  },
  {
    path: '*',
    element: <PageNotFound />
  }
];

export default ResourcesRoutes;
