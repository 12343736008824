import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Note from './note';
import SoapSymptoms from './soap-symptoms';
import SoapSection from './soap-section';
import { buildNotes, buildSymptomScores } from '../soap-utilities';
import Strings from '../lang';

class SubjectiveNotesForm extends Component {
  constructor(props) {
    super(props);

    const { symptoms = {}, notes = {} } = props;
    const symptomScoresCompleted = Object.keys(symptoms || {}).length > 0;

    this.onSubmit = this.onSubmit.bind(this);
    this.onUpdateSymptoms = this.onUpdateSymptoms.bind(this);
    this.onUpdateNotes = this.onUpdateNotes.bind(this);

    this.state = {
      symptoms_scores: {
        ...buildSymptomScores(symptoms)
      },
      sNotes: notes.s || '',
      symptomScoresCompleted
    };
  }

  render() {
    return (
      <SoapSection title={Strings.subjectiveLabel} onSubmitSection={this.onSubmit}>
        <SoapSymptoms
          symptoms={this.state.symptoms_scores}
          onUpdate={this.onUpdateSymptoms}
          completed={this.state.symptomScoresCompleted}
        />
        <Note
          label={Strings.subjectiveLabel}
          namespace="soap-subjective-notes"
          note={this.state.sNotes}
          onUpdate={this.onUpdateNotes}
        />
      </SoapSection>
    );
  }

  onUpdateNotes(value) {
    this.setState({
      sNotes: value
    });
  }

  onUpdateSymptoms(state) {
    const { symptoms, completed } = state;

    this.setState({
      symptoms_scores: symptoms,
      symptomScoresCompleted: completed
    });
  }

  onSubmit() {
    const { notes = {} } = this.props;

    const attributes = {
      symptoms_scores: this.state.symptomScoresCompleted ? {
        ...this.getSymptoms()
      } : {},
      notes: {
        ...buildNotes(notes),
        s: this.state.sNotes
      }
    };

    this.props.onSubmit(attributes);
  }

  getSymptoms() {
    const { symptoms_scores } = this.state;

    return Object.keys(symptoms_scores).reduce((acc, curr) => {
      acc[curr] = symptoms_scores[curr].level;
      return acc;
    }, {});
  }
}

SubjectiveNotesForm.propTypes = {
  symptoms: PropTypes.object,
  notes: PropTypes.object,
  onSubmit: PropTypes.func
};

export default SubjectiveNotesForm;
