import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import Modal from '../../modal';
import AddExistingChildForm from './add-existing-child-form';
import Activity from '../../activity';
import { ErrorBanner } from '../../errors';
import { addExistingChildAsync } from '@/redux/thunks/guardians-account';
import HTTPStatusCodes from '@/utilities/http-status-codes';
import Strings from './lang';

const getErrorMessage = (error, account) => {
  if (error.status === HTTPStatusCodes.NotFound) {
    return Strings.formatString(
      Strings.addAccountNotFoundErrorMessage,
      account || ''
    );
  }

  if (error.status === HTTPStatusCodes.Unauthorized) {
    return Strings.formatString(
      Strings.addAccountUnauthorizedErrorMessage,
      account || ''
    );
  }

  return error.message;
};

const ExistingAccountModal = ({
  isOpen,
  user = {},
  addExisitingChild,
  onClose = () => { }
}) => {
  const [activity, setActivity] = useState(false);
  const [error, setError] = useState(null);

  const handleClose = useCallback(() => {
    setError(null);
    onClose();
  }, [onClose]);

  const onAddChild = useCallback((account, password) => {
    const attributes = { password, account };

    setActivity(true);
    setError(null);

    addExisitingChild(user.id, attributes).then(() => {
      setActivity(false);
      onClose();
    }).catch(error => {
      setActivity(false);
      setError(getErrorMessage(error, account));
    });
  }, [addExisitingChild, onClose, user.id]);

  return (
    <Modal
      blur
      static={activity}
      isOpen={isOpen}
      onClose={handleClose}
      className="add-existing-account-modal"
    >
      <Modal.Header onClose={activity ? null : handleClose}>
        <h1>{Strings.addExistingAccountModalTitle}</h1>
      </Modal.Header>
      <Modal.Body>
        <ErrorBanner error={error} />
        <Activity active={activity}>
          <p dangerouslySetInnerHTML={{ __html: Strings.addExisitingAccountModalMessage }} />
          <AddExistingChildForm
            onSubmit={onAddChild}
          />
        </Activity>
      </Modal.Body>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addExisitingChild: (parentId, attributes) => {
      return dispatch(addExistingChildAsync(parentId, attributes));
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(ExistingAccountModal);
