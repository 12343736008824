const UPDATE_CLINIC_STATISTICS = 'UPDATE_CLINIC_STATISTICS';

const updateClinicStatistics = (clinicId, stats) => ({
  type: UPDATE_CLINIC_STATISTICS,
  clinicId,
  stats
});

export {
  UPDATE_CLINIC_STATISTICS,
  updateClinicStatistics
};
