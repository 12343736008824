import Strings from './lang';

const BoxLacrossePositions = [
  { key: 'creasemen', name: Strings.boxlacrosse_creasemen },
  { key: 'cornermen', name: Strings.boxlacrosse_cornermen },
  { key: 'pointmen', name: Strings.boxlacrosse_pointmen },
  { key: 'goalie', name: Strings.boxlacrosse_goalie }
];

export default BoxLacrossePositions;
