/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
/* eslint-enable no-unused-vars */

import AlertBanner from './alert-banner';
import { ALERT_SHOW } from './alert';

const defaultState = {
  alerts: {}
};

class AlertContainer extends Component {
  constructor(props) {
    super(props);

    this._onShowAlert = this._onShowAlert.bind(this);

    this.state = {
      ...defaultState
    };
  }

  componentDidMount() {
    document.addEventListener(ALERT_SHOW, this._onShowAlert);
  }

  componentWillUnmount() {
    document.removeEventListener(ALERT_SHOW, this._onShowAlert);
  }

  render() {
    return (
      <div className="alert-container">
        {Object.keys(this.state.alerts).map(key => {
          const alert = this.state.alerts[key];
          return (
            <AlertBanner 
              key={key}
              type={alert.type} 
              options={alert.options} 
              onDismiss={() => this._onDismiss(key)}
            />
          );
        })}
      </div>
    );
  }

  _onShowAlert(e) {
    const { alerts } = this.state;
    const { type, options = {} } = e.detail || {};
    const key = `${type}_${new Date().valueOf()}`;

    this.setState({
      alerts: {
        ...alerts,
        [key]: {
          type,
          options
        }
      }
    });
  }

  _onDismiss(key, data) {
    const { alerts } = this.state;
    const alert = { ...alerts[key] } || {};

    if (typeof (alert.options || {}).onDismiss === 'function') {
      alert.options.onDismiss(data);
    }

    if (alerts[key]) {
      delete alerts[key];
    }

    this.setState({
      alerts
    });
  }
}

export default AlertContainer;
