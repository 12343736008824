import React, { useMemo } from 'react';
import classnames from 'classnames';
import RecoveryProtocols from './protocols';
import StageDescriptionLink from './stage-description-link';

const RecoveryStageView = ({
  stage,
  showDescription = true,
  protocol
}) => {
  const stageIndex = useMemo(() => (
    protocol.stages.findIndex(s => s.value === stage)
  ), [protocol.stages, stage]);
  const protocolStage = protocol.stages[stageIndex];

  if (!protocolStage) return null;

  return (
    <div className="recovery-stage-view">
      <div className={classnames('stage-number', protocolStage.bgClass)}>
        {stageIndex + 1}
      </div>
      <div>
        <div className="caption primary">{protocol.label}</div>
        <div className="stage-label">{protocolStage.label}</div>
        {showDescription && protocolStage.document && (
          <div className="no-print">
            <StageDescriptionLink
              className="caption btn-read-more semi-bold"
              documentName={protocolStage.document}
              sportSelection={protocolStage.selector}
            >
              <i>View Description</i>
            </StageDescriptionLink>
          </div>
        )}
      </div>
    </div>
  );
};

const RecoveryStagesView = ({
  stages = {},
  description = true
}) => {
  const { rts_stage, rtl_stage, rtw_stage } = stages || {};
  return (
    <div className="recovery-stages-view">
      <RecoveryStageView 
        protocol={RecoveryProtocols.rts}
        stage={rts_stage} 
        showDescription={description}
      />
      <RecoveryStageView 
        protocol={RecoveryProtocols.rtl}
        stage={rtl_stage} 
        showDescription={description}
      />
      <RecoveryStageView
        protocol={RecoveryProtocols.rtw}
        stage={rtw_stage}
        showDescription={description}
      />
    </div>
  );
};

export default RecoveryStagesView;
