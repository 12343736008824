const MarketplacePaths = {
  index: {
    route: '/marketplace',
    link: '/marketplace'
  },
  products: {
    index: {
      route: 'products',
      link: '/marketplace/products'
    },
    product: {
      index: {
        route: ':productId',
        link: '/marketplace/products/:productId'
      },
      edit: {
        route: 'edit',
        link: '/marketplace/products/:productId/edit'
      }
    },
    create: {
      route: 'new',
      link: '/marketplace/products/new'
    }
  },
  partners: {
    index: {
      route: 'partners',
      link: '/marketplace/partners'
    },
    show: {
      route: ':partnerId',
      link: '/marketplace/partners/:partnerId'
    }
  }
};

export default MarketplacePaths;
