import './dayjs-setup';
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './app';
import { initSentry } from '@/utilities/sentry';
import getRootHandle from '@/utilities/get-root-handle';

initSentry();

createRoot(getRootHandle()).render(<App />);
