import React, { useState, useCallback, useEffect } from 'react';
import { Else, If, Then } from 'react-if';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import OrthostaticVitalSignsTest from './ovs-test';
import OrthostaticVitalSignsTestView from './ovs-test-view';
import { UserSelector, InjurySelector } from '@/redux/selectors';
import { getOVSTestAsync } from '@/redux/thunks/ovs-tests';
import Activity from '../activity';

const ovsSelector = (userId, testId) => (state) => {
  const userTests = state.ovsTests[userId] || {};
  return userTests[testId] || {};
};

const OrthostaticVitalSignsTestDisplay = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [activity, setActivity] = useState(false);
  const [error, setError] = useState(null);
  const [editing, setEditing] = useState(false);

  const user = useSelector(state => UserSelector.getUser(state, { userId: params.userId }));
  const injury = useSelector(state => (
    InjurySelector.getUserInjury(state, { userId: params.userId, injuryId: params.injuryId })
  ));
  const test = useSelector(ovsSelector(params.userId, params.ovsId));

  const onEdit = useCallback(() => {
    setEditing(true);
  }, []);

  const onTestSaved = useCallback(() => {
    setEditing(false);
    window.scrollTo(0, 0);
  }, []);

  const getOVSTest = useCallback((userId, injuryId, ovsId) => {
    setActivity(true);
    setError(null);

    dispatch(getOVSTestAsync(userId, injuryId, ovsId)).then(() => {
      setActivity(false);
    }).catch(error => {
      setActivity(false);
      setError(error.message);
    });
  }, [dispatch]);

  useEffect(() => {
    if (!test?.id && params.userId && params.injuryId && params.ovsId) {
      getOVSTest(params.userId, params.injuryId, params.ovsId);
    }
  }, [getOVSTest, params.ovsId, params.injuryId, params.userId, test?.id]);

  return (
    <div className="ovs-test-display">
      <Activity active={activity}>
        <If condition={editing}>
          <Then>
            <OrthostaticVitalSignsTest
              user={user}
              injury={injury}
              testId={test?.id}
              onSave={onTestSaved}
            />
          </Then>
          <Else>
            <OrthostaticVitalSignsTestView
              user={user}
              injury={injury}
              test={test}
              error={error}
              onEditClick={onEdit}
            />
          </Else>
        </If>
      </Activity>
    </div>
  );
};

export default OrthostaticVitalSignsTestDisplay;
