import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  Link,
  useLocation,
  useParams
} from 'react-router-dom';
import PlayerBadge from '../../player-badge';
import Card from '../../card';
import { BaselineSelector, UserSelector } from '@/redux/selectors';
import {
  getBaselineProfile,
  findIncompleteSteps,
  BaselineTestLabels
} from '../baseline-utilities';
import { StepRoutesConfig } from './steps-routes';
import { getCurrentClinicId } from '@/tokens';
import { DashboardPaths } from '@/paths';
import Strings from './lang';
import { ErrorBanner } from '../../errors';

const StepItem = ({
  step,
  isIncomplete,
  isActive,
  isSkipped
}) => {
  if (isIncomplete || isActive) {
    return (
      <div>{BaselineTestLabels[step]}</div>
    );
  }

  return (
    <div className="text-muted">
      <s>{BaselineTestLabels[step]}</s> {isSkipped && (<i className="warning">{Strings.skippedLabelText}</i>)}
    </div>
  );
};

const IncompleteStepsView = ({
  className,
  steps = [],
  incomplete = [],
  activeStep = 0,
  baseline
}) => {
  return (
    <div className={className}>
      {steps.map((step, index) => {
        return (
          <StepItem
            key={index}
            isActive={step === steps[activeStep]}
            isIncomplete={incomplete.indexOf(step) >= 0}
            isSkipped={baseline?.[step]?.not_done === true}
            step={step}
          />
        );
      })}
    </div>
  );
};

const BaselineContinueProgress = () => {
  const params = useParams();
  const location = useLocation();
  const clinicId = useMemo(() => getCurrentClinicId(), []);
  const user = useSelector(state => UserSelector.getUser(state, params));
  const baseline = useSelector(state => BaselineSelector.getBaseline(state, params));
  const profile = getBaselineProfile(baseline?.test_version);
  const incomplete = findIncompleteSteps(baseline, profile);
  const activeStep = incomplete[0] || 'complete';
  const { referrer } = location?.state || {};

  if (!baseline) {
    return (
      <ErrorBanner error={Strings.continueBaselineNotFoundError} />
    );
  }

  return (
    <div className="container">
      <Card title={Strings.baselineContinueCardTitle}>
        <div className="card card-show">
          <p className="text-muted">{Strings.confirmProgressInfoText}</p>
          <PlayerBadge user={user} clinicId={clinicId} />
          <h2><strong>{Strings.baselineStepProgressTitle}</strong></h2>
          <IncompleteStepsView
            steps={profile}
            baseline={baseline}
            activeStep={activeStep}
            incomplete={incomplete}
          />
        </div>
      </Card>
      <Link className="btn btn-default" to={referrer || DashboardPaths.index.link}>
        {Strings.wrongPersonButtonText}
      </Link>
      <Link
        className="btn btn-primary"
        to={`../${StepRoutesConfig[activeStep]?.path || activeStep}`}
        relative="path"
      >
        {Strings.continueTestButtonText}
      </Link>
    </div>
  );
};

export default BaselineContinueProgress;
