const EN = {
  photoValidationText: 'Please upload a clinic picture.',
  nameLabelText: 'Name',
  nameValidationMessage: 'Please enter the clinic name.',
  impactCodeLabelText: 'Impact Code',
  impactCodeValidationMessage: 'Please enter the clinic impact code.',
  phoneValidationMessage: 'Please enter a valid phone number.',
  faxValidationMessage: 'Please enter a valid fax number.',
  ownerLabelText: 'Clinic Owner',
  phoneLabelText: 'Phone',
  faxLabelText: 'Fax',
  clinicUrlLabelText: 'Website',
  emailLabelText: 'Email',
  facebookLabelText: 'Facebook',
  twitterLabelText: 'Twitter',
  instagramLabelText: 'Instagram',
  addressLabelText: 'Address',
  address2LabelText: 'Address 2',
  cityLabelText: 'City / Town',
  regionLabelText: 'State / Province',
  countryLabelText: 'Country',
  postalCodeLabelText: 'Postal Code',
  submitButtonText: 'Save',
  yesText: 'Yes',
  noText: 'No',
  bioLabelText: 'Bio',
  socialUrlErrorMessage: 'Please enter a valid {0} profile url (e.g. {1})'
};

export default EN;
