import { UPDATE_ALL_CLINICS_STATISTICS } from '../actions/all-clinics-statistics';

const allClinicsStatistics = (state = {}, action = {}) => {
  switch (action.type) {
    case UPDATE_ALL_CLINICS_STATISTICS:
      return action.stats || {};
    default:
      return state;
  }
};

export default allClinicsStatistics;
