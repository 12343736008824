const FR = {
  concussion_with_unconsciousness: 'Concussion avec perte de conscience',
  concussion_without_unconsciousness: 'Concussion sans perte de conscience',
  post_concussion_syndrome: 'Syndrome de post-concussion',
  chronic_whiplash: 'Coup de fouet cervical chronique',
  whiplash: 'Troubles associés à l’entorse cervicale (TAEC)',
  none: 'Aucun diagnostic disponible',
  other: 'Autre (voir les remarques)',
  notes: 'Remarques',
  physiologic: 'Syndrome post-commotion cérébrale physiologique',
  cervicogenic: 'Syndrome post-commotion cérébrale cervicogène',
  vestibulo_ocular: 'Syndrome post-commotion cérébrale vestibulo-oculaire',
  unknown_origin: 'Syndrome post-commotion cérébrale d’origine inconnue',
  diagnosisLabel: 'Diagnostic',
  diagnosisErrorMessage: 'Vous devez choisir un diagnostic',
  noDiagnosisFound: 'Aucun diagnostic',
  diagnosisNotesLabelText: 'Remarques sur le diagnostic :',
  diagnosisHeadingText: 'Diagnostic :',
  editButtonText: 'Modifier',
  defaultDiagnosisText: 'Aucun diagnostic',
  diagnosisModalHeaderText: 'Modifier le diagnostic de la blessure',
  saveButtonText: 'Sauvegarder',
  cancelButtonText: 'Annuler'
};
 
export default FR;
