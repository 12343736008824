import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { buildURI, configureFilename } from './utils';
import Activity from '../activity';
import { ErrorBanner } from '../errors';
import Strings from './lang';

const buildLink = (uri, filename) => {
  const link = document.createElement('a');
  link.download = filename;
  link.href = uri;

  return link;
};

const triggerDownload = (link) => {
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

class CSVDownloadLink extends Component {
  constructor(props) {
    super(props);

    this.onRequest = this.onRequest.bind(this);

    this.state = {
      error: null,
      activity: false,
      uri: null
    };
  }

  render() {
    return (
      <div className={this.props.className}>
        <ErrorBanner error={this.state.error} />
        <Activity active={this.state.activity}>
          <button 
            type="button" 
            className={this.props.linkClassName} 
            onClick={this.onRequest}
          >
            {this.props.children || Strings.exportCSVText}
          </button>
        </Activity>
      </div>
    );
  }

  onRequest() {
    this.setState({
      activity: true,
      error: null
    });

    const { filename } = this.props;

    this.getURI().then(uri => {
      const link = buildLink(uri, configureFilename(filename));
      triggerDownload(link);

      this.setState({ uri, activity: false });
    }).catch(error => {
      this.setState({ 
        error: error.message, 
        activity: false 
      });
    });
  }

  getURI() {
    const { uri } = this.state;
    const { 
      headers = [], data = [], separator = ',', onRequest, cellFormatter 
    } = this.props;

    if (uri) {
      return Promise.resolve(uri);
    }

    if (data && data.length > 0) {
      return Promise.resolve(buildURI(headers, data, separator, cellFormatter));
    }

    if (typeof onRequest === 'function') {
      return onRequest().then(fetchedData => {
        return Promise.resolve(buildURI(headers, fetchedData, separator, cellFormatter));
      });
    }

    return Promise.reject(new Error(Strings.noDataToExportError));
  }
}

CSVDownloadLink.propTypes = {
  filename: PropTypes.string,
  headers: PropTypes.array,
  data: PropTypes.arrayOf(PropTypes.array),
  separator: PropTypes.string,
  onRequest: PropTypes.func
};

export default CSVDownloadLink;
