import React, { useState } from 'react';
import dayjs from 'dayjs';
import { ActiveClaimModal } from '../activate-claim';
import Strings from '../lang';

const InitialAppointmentBanner = ({ visible, claimId, referralDate }) => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      {visible && (
        <div className="initial-appt-banner">
          <div className="banner-content">
            <div className="title">
              {Strings.initialApptBannerTitle}
            </div>
            <div className="message">
              {Strings.initialApptBannerMessage}
            </div>
          </div>
          <button type="button" className="btn btn-primary" onClick={() => setModalOpen(true)}>
            {Strings.setInitialApptButton}
          </button>
        </div>
      )}
      <ActiveClaimModal
        isOpen={modalOpen}
        claimId={claimId}
        minDate={dayjs(referralDate)}
        onClose={() => setModalOpen(false)}
      />
    </>
  );
};

export default InitialAppointmentBanner;
