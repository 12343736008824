import React from 'react';
import PropTypes from 'prop-types';
import calculateReactionTime from '../test-components/reaction-time/reaction-time-calculator';
import BaselineSection from './baseline-section';
import Strings from './lang';

const FIXED_DECIMAL_PLACES = 3;

const prepareTestResults = (results = []) => {
  const rows = results.map(distance => {
    return {
      distance: parseFloat(distance),
      time: calculateReactionTime(distance)
    };
  });

  const averages = {
    distance: rows.reduce((sum, row) => (sum + row.distance), 0) / rows.length,
    time: rows.reduce((sum, row) => (sum + row.time), 0) / rows.length
  };

  return { rows, averages };
};

const BaselineReaction = ({
  data = {}
}) => {
  const { 
    dominant_hand = '', test_results = [], notes = '', not_done = false 
  } = data;
  const results = prepareTestResults(test_results);

  return (
    <BaselineSection
      className="baseline-reaction"
      title={Strings.reactionTitle}
      notes={notes}
      sectionCompleted={Object.keys(data || {}).length > 0 && !not_done}
    >
      <p>
        <strong>{Strings.dominantHandLabelText}</strong> <span className="capitalize">{dominant_hand}</span>
      </p>
      <table className="table">
        <thead>
          <tr>
            <th className="no-action">{Strings.testLabelText}</th>
            <th className="no-action">{Strings.distanceLabelText}</th>
            <th className="no-action">{Strings.reactionTimeLabelText}</th>
          </tr>
        </thead>
        <tbody>
          {results.rows.map((row, index) => {
            return (
              <tr key={index}>
                <td><strong>{index + 1}</strong></td>
                <td>{row.distance}</td>
                <td>{row.time.toFixed(FIXED_DECIMAL_PLACES)}</td>
              </tr>
            );
          })}
          <tr className="totals">
            <td><strong>{Strings.averageLabelText}</strong></td>
            <td><strong>{results.averages.distance}</strong></td>
            <td><strong>{results.averages.time.toFixed(FIXED_DECIMAL_PLACES)}</strong></td>
          </tr>
        </tbody>
      </table>
    </BaselineSection>
  );
};

BaselineReaction.propTypes = {
  data: PropTypes.object.isRequired
};

export default BaselineReaction;
