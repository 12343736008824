import React from 'react';
import classNames from 'classnames';
import { getAuthToken } from '@/tokens';
import Modal from '../../modal';
import Icon from '../../icon';

const ReportViewModal = ({
  isOpen,
  reportId,
  onClose
}) => {
  return (
    <Modal
      blur
      fullScreen
      isOpen={isOpen}
      className={classNames('idt-embed-modal', 'report-view-modal')}
      onClose={onClose}
    >
      <Modal.Body>
        <iframe
          title="View IDT Report"
          src={`${process.env.CCMI_AXIA_HOST}/embed/idt-reports/v1/reports/${reportId}/view#access_token=${getAuthToken()}`}
          width="100%"
          height="100%"
        />
        <button type="button" className="modal-close-button" onClick={onClose}>
          <Icon name="xmark" />
        </button>
      </Modal.Body>
    </Modal>
  );
};

export default ReportViewModal;
