const MAX_SEVERITY = 6;

const mergeSymptoms = (defaultSymptoms, symptoms) => {
  let newSymptoms = { ...symptoms };
  let target = {};

  if (Object.keys(symptoms).length === 0) {
    newSymptoms = { ...defaultSymptoms };
  }
  
  Object.keys(defaultSymptoms).forEach(key => {
    target = {
      ...target, 
      [key]: { ...defaultSymptoms[key], ...newSymptoms[key] }
    };
  });

  return target;
};

const getSeverity = (symptoms) => {
  return Object.keys(symptoms).reduce((acc, cur) => {
    let value = symptoms[cur].level !== undefined ? symptoms[cur].level : symptoms[cur];
    value = parseInt(value, 10);
    return acc + (value || 0);
  }, 0);
};

const getTotals = (symptoms) => {
  return Object.keys(symptoms).reduce((acc, cur) => {
    const symptom = parseInt(
      symptoms[cur].level !== undefined ? symptoms[cur].level : symptoms[cur], 
      10
    );
    const val = symptom || 0;
    if (val > 0) {
      return acc + 1;
    }

    return acc;
  }, 0);
};

export {
  mergeSymptoms,
  getSeverity,
  getTotals,
  MAX_SEVERITY
};
