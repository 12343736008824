import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import BlackhawksStationaryBikeStageHeaderRow from './blackhawks-stationary-bike-stage-header-row';
import BlackhawksStationaryBikeStageTableRow from './blackhawks-stationary-bike-stage-table-row';

const BlackhawksStationaryBikeStage = ({
  stageData = [], 
  additionalRows = [],
  stageTitle,
  recoveryTitle,
  touched,
  isBlackhawksStationaryBikeDisplay,
  identifier,
  onChangeSegmentHeartRate,
  onChangeSegmentSymptoms
}) => {
  const testData = stageData.slice(0, -1);
  const recoveryData = stageData.slice(-1);

  const handleSegmentHeartRateChange = useCallback((index, e) => {
    if (typeof onChangeSegmentHeartRate === 'function') {
      onChangeSegmentHeartRate(identifier, index, e);
    }
  }, [onChangeSegmentHeartRate, identifier]);

  const handleSegmentSymptomsChange = useCallback((index, e) => {
    if (typeof onChangeSegmentSymptoms === 'function') {
      onChangeSegmentSymptoms(identifier, index, e);
    }
  }, [onChangeSegmentSymptoms, identifier]);

  return (
    <div className="table-responsive-md">
      <table className="table">
        <thead>
          <BlackhawksStationaryBikeStageHeaderRow />
        </thead>
        <tbody>
          <tr>
            <td colSpan="5">
              <strong>{stageTitle}</strong>
            </td>
          </tr>
          {testData.map((segment, index) => (
            <BlackhawksStationaryBikeStageTableRow
              key={index}
              index={index}
              time={segment.time}
              tension={segment.tension}
              rpm={segment.rpm}
              heartRate={segment.heart_rate}
              symptoms={segment.symptoms}
              onChangeHeartRate={handleSegmentHeartRateChange}
              onChangeSymptoms={handleSegmentSymptomsChange}
              isDisplayText={isBlackhawksStationaryBikeDisplay}
              touched={touched}
            />
          ))}
          <tr>
            <td colSpan="5">
              <strong>{recoveryTitle}</strong>
            </td>
          </tr>
          {recoveryData.map((segment, index) => (
            <BlackhawksStationaryBikeStageTableRow
              key={testData.length + index}
              index={testData.length + index}
              time={segment.time}
              tension={segment.tension}
              rpm={segment.rpm}
              heartRate={segment.heart_rate}
              symptoms={segment.symptoms}
              onChangeHeartRate={handleSegmentHeartRateChange}
              onChangeSymptoms={handleSegmentSymptomsChange}
              isDisplayText={isBlackhawksStationaryBikeDisplay}
              touched={touched}
            />
          ))}
          {additionalRows}
        </tbody>
      </table>
    </div>
  );
};

const segmentShape = {
  time: PropTypes.string.isRequired,
  tension: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  rpm: PropTypes.string.isRequired,
  heart_rate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  symptoms: PropTypes.string
};

BlackhawksStationaryBikeStage.propTypes = {
  identifier: PropTypes.string.isRequired,
  stageTitle: PropTypes.string.isRequired,
  recoveryTitle: PropTypes.string.isRequired,
  stageData: PropTypes.arrayOf(PropTypes.shape(segmentShape)).isRequired,
  onChangeSegmentHeartRate: PropTypes.func,
  onChangeSegmentSymptoms: PropTypes.func,
  isBlackhawksStationaryBikeDisplay: PropTypes.bool,
  touched: PropTypes.bool,
  additionalRows: PropTypes.node
};

export default BlackhawksStationaryBikeStage;
