export const BaselineProfileV1 = [
  'personal',
  'medical',
  'pre_existing_symptoms',
  'cognitive_assessment_test',
  'immediate_memory_test',
  'concentration_test',
  'king_devick_test',
  'balance_test',
  'force_plate_test',
  'reaction_time_test',
  'grip_strength_test',
  'delayed_memory_recall_test'
];

export const BaselineProfile = [
  'personal',
  'medical',
  'pre_existing_symptoms',
  'cognitive_assessment_test',
  'immediate_memory_test',
  'concentration_test',
  'king_devick_test',
  'balance_test',
  'force_plate_test',
  'tandem_gait_test',
  'dual_task_test',
  'reaction_time_test',
  'grip_strength_test',
  'delayed_memory_recall_test'
];

export const getBaselineProfile = (version = 2) => {
  switch (version) {
    case 1:
      return BaselineProfileV1;
    default: 
      return BaselineProfile;
  }
};

export default BaselineProfile;
