const CreditPrice = {
  CAD: 30.00,
  USD: 20.00
};

const BulkCreditPrice = {
  CAD: [
    { count: 250, price: 28.00 },
    { count: 500, price: 25.00 },
    { count: 1000, price: 23.00 },
    { count: 2000, price: 20.00 }
  ],
  USD: [
    { count: 250, price: 18.50 },
    { count: 500, price: 17.50 },
    { count: 1000, price: 16.00 },
    { count: 2000, price: 15.00 }
  ]
};

export {
  CreditPrice,
  BulkCreditPrice
};
