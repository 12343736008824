import React from 'react';

const PrintButton = () => (
  <button 
    type="button" 
    className="btn btn-primary btn-sm" 
    onClick={window.print} 
  >
    Print
  </button>
);

export default PrintButton;
