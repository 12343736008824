import React, { useMemo } from 'react';
import { Link, NavLink } from 'react-router-dom';
import classNames from 'classnames';
import { 
  BagTagPaths,
  BaselineTestPaths, 
  ClinicPaths, 
  DashboardPaths, 
  PostInjuryAssessmentPaths, 
  ResourcesPaths, 
  TaxPaths, 
  UserPaths
} from '@/paths';
import AccessControl from '../access-control';
import useDashboardPermissions from './permissions';
import { getCurrentClinicId } from '@/tokens';
import replaceLinkParams from '@/utilities/replace-link-params';
import Strings from './lang';
import Icon from '../icon';

const DashboardItem = ({ href, label, icon }) => {
  return (
    <div className="dashboard-item">
      <NavLink
        to={href}
        className={({ isActive }) => classNames('dashboard-link', isActive && 'active')}
      >
        <div className="icon">
          <Icon name={icon} />
        </div>
        <div className="title">{label}</div>
      </NavLink>
    </div>
  );
};

const DashboardPreview = () => {
  const clinicId = useMemo(() => getCurrentClinicId(), []);
  const permissions = useDashboardPermissions();

  return (
    <div className="dashboard-preview">
      <div className="preview-content">
        <AccessControl roles={permissions.baselineTest}>
          <DashboardItem 
            label={Strings.baselineTestCardTitleText}
            icon="chart-line"
            href={BaselineTestPaths.start.link}
          />
        </AccessControl>
        <AccessControl 
          roles={[
            ...permissions.resumeBaselineBadge, 
            ...permissions.continueBaselineCard
          ]}
        >
          <DashboardItem
            label={Strings.dashboardPreviewContinueBaseline}
            icon="circle-arrow-right"
            href={BaselineTestPaths.continue.link}
          />
        </AccessControl>
        <AccessControl roles={permissions.postInjury}>
          <DashboardItem 
            label={Strings.dashboardPreviewPostInjury} 
            icon="bolt"
            href={PostInjuryAssessmentPaths.startAssessment.link}
          />
        </AccessControl>
        <AccessControl roles={permissions.manangePatients}>
          <DashboardItem 
            label={Strings.clinicPatientsCardTitleText} 
            icon="users-medical"
            href={replaceLinkParams(ClinicPaths.patients.link, { clinicId })}
          />
        </AccessControl>
        <AccessControl roles={permissions.clinicsIndex}>
          <DashboardItem
            label={Strings.clinicsCardTitleText}
            icon="house-medical"
            href={ClinicPaths.index.link}
          />
        </AccessControl>
        <AccessControl roles={permissions.teamLeaders}>
          <DashboardItem
            label={Strings.teamLeadersCardTitleText}
            icon="futbol"
            href={UserPaths.leaders.link}
          />
        </AccessControl>
        <AccessControl roles={permissions.bagTags}>
          <DashboardItem
            label={Strings.manageBagTagsTitleText}
            icon="tags"
            href={BagTagPaths.index.link}
          />
        </AccessControl>
        <AccessControl roles={permissions.clinicStats}>
          <DashboardItem
            label={Strings.clinicsStatsCardTitleText}
            icon="chart-mixed"
            href={ClinicPaths.stats.link}
          />
        </AccessControl>

        <AccessControl roles={permissions.taxRates}>
          <DashboardItem
            label={Strings.taxRatesCardTitle}
            icon="badge-percent"
            href={TaxPaths.index.link}
          />
        </AccessControl>
        <AccessControl roles={permissions.resources}>
          <DashboardItem 
            label={Strings.resourcesCardTitleText} 
            icon="folder-magnifying-glass"
            href={ResourcesPaths.root.link}
          />
        </AccessControl>
        <AccessControl roles={permissions.accountBalance}>
          <DashboardItem 
            label={Strings.accountBalanceTitleText} 
            icon="file-invoice-dollar"
            href={replaceLinkParams(ClinicPaths.invoices.index.link, { clinicId })}
          />
        </AccessControl>
      </div>
      <div className="preview-footer">
        <Link to={DashboardPaths.index.link} className="btn btn-secondary btn-sm">
          {Strings.dashboardPreviewHomeText}
        </Link>
      </div>
    </div>
  );
};

export default DashboardPreview;
