import React from 'react';
import classnames from 'classnames';
import Strings from './lang';

const TypeSelector = ({
  value,
  onSelect = () => { }
}) => {
  return (
    <div className="btn-group">
      <button
        type="button"
        className={classnames('btn btn-sm btn-light', { selected: value === null })}
        onClick={() => onSelect(null)}
      >
        {Strings.allTestsSelectorLabel}
      </button>
      <button
        type="button"
        className={classnames('btn btn-sm btn-light', { selected: value === false })}
        onClick={() => onSelect(false)}
      >
        {Strings.baselineTestsSelectorLabel}
      </button>
      <button
        type="button"
        className={classnames('btn btn-sm btn-light', { selected: value === true })}
        onClick={() => onSelect(true)}
      >
        {Strings.postInjuryTestsSelectorLabel}
      </button>
    </div>
  );
};

export default TypeSelector;
