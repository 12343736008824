import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useParams, useNavigate } from 'react-router-dom';
import { RegistrationSteps, findCurrentStepIndex } from './helpers';
import ProgressDots from '../../progress-dots';
import { RegistrationProvider } from './context';
import { RegistrationPaths } from '@/paths';
import { updateRegistrationRole } from '@/redux/actions/registration';
import Store from '@/redux/store';
import { 
  getRegistrationCurrentUser, 
  getCurrentRegistrationSession, 
  switchRegistrationAuthContext,
  setRegistrationEmailSent 
} from '@/utilities/registration-session';
import { sortAscending } from '@/utilities/sort';
import replaceLinkParams from '@/utilities/replace-link-params';
import { useMount } from '@/hooks';

const STEPS = RegistrationSteps.child;

const getMembers = (guardianId) => {
  const { users } = Store.getState();
  const guardian = users[guardianId] || {};
  return guardian?.children?.sort(sortAscending.bind(null, 'id'));
};

const checkAboutStepParams = (nextChildParam, step, guardianId, members) => {
  if (step === 'about' && nextChildParam === 'add') {
    const currentSession = getCurrentRegistrationSession() || {};
    const authUser = currentSession.id;

    if (authUser && authUser !== guardianId) {
      return members.findIndex(child => child && child.id === authUser);
    }
  }

  return null;
};

const ChildRegistrationRouteHandler = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { step } = useSelector(state => state.registration || {});
  const guardianId = useMemo(() => getRegistrationCurrentUser(), []);
  const stepIndex = useMemo(() => findCurrentStepIndex(STEPS, step), [step]);

  useMemo(() => {
    const members = getMembers(guardianId);
    if (params?.child === 'add') {
      switchRegistrationAuthContext(getRegistrationCurrentUser());
    } else if (!isNaN(parseInt(params?.child, 10))) {
      switchRegistrationAuthContext(members[params?.child].id);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onRegistrationFinished = useCallback(() => {
    navigate(RegistrationPaths.guardian.children.link);
  }, [navigate]);

  const onNext = useCallback(() => {
    const stepData = STEPS[stepIndex + 1];

    if (step === 'about' && params.child === 'add') {
      setRegistrationEmailSent(false);
      const members = getMembers(guardianId);
      const childIndex = checkAboutStepParams(params.child, step, guardianId, members);
      switchRegistrationAuthContext(members[childIndex].id);
      navigate(replaceLinkParams(RegistrationPaths.guardian.child.index.link, {
        child: childIndex
      }), { replace: true });

      navigate(replaceLinkParams(stepData.path, {
        child: childIndex
      }));
    } else if (stepData) {
      navigate(replaceLinkParams(stepData.path, {
        child: params.child
      }));
    } else {
      onRegistrationFinished();
    }
  }, [
    guardianId,
    navigate,
    onRegistrationFinished,
    params.child,
    step,
    stepIndex
  ]);

  useMount(() => {
    dispatch(updateRegistrationRole('guardian'));
  });

  return (
    <div className="row">
      <div className="col-md-12">
        <ProgressDots step={stepIndex} total={STEPS.length} />
      </div>
      <div className="col-md-12">
        <RegistrationProvider
          next={onNext}
          role="guardian"
          {...params}
        >
          <Outlet />
        </RegistrationProvider>
      </div>
    </div>
  );
};

export default ChildRegistrationRouteHandler;
