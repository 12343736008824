import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/browser';
import { UserSelector } from '@/redux/selectors';
import Store from '@/redux/store';

const SENTRY_DSN = 'https://16f99a3ae3f7487b87c50e1300a9d768@o1078076.ingest.sentry.io/6100794';

const getUser = () => {
  return UserSelector.getCurrentUser(Store.getState());
};

export const initSentry = () => {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [new BrowserTracing()],
    environment: process.env.CCMI_ENV,
    release: process.env.CCMI_VERSION,
    enabled: process.env.NODE_ENV !== 'development',
    tracesSampleRate: 0.01,
    beforeSend(event) {
      const { id } = getUser() || {};
      
      return {
        ...event,
        user: {
          id: id ?? null
        }
      };
    },
  });
};

export const showSentryFeedbackDialog = (eventId, options = {}) => {
  const { person, email } = getUser() || {};

  Sentry.showReportDialog({
    ...options,
    eventId,
    dsn: SENTRY_DSN,
    user: {
      name: person
        ? `${person.first_name} ${person.last_name}` : '',
      email
    }
  });
};
