import React, { useState, useMemo, useCallback } from 'react';
import { sortDescending } from '@/utilities/sort';
import HistoryModal from './history-modal';
import {
  RTLProgressIndicator,
  RTSProgressIndicator,
  RTWProgressIndicator,
  RecoveryStagesView
} from '../../recovery-stages';
import { DiagnosisView } from '../../diagnosis';
import Strings from '../lang';

const getDiagnosisFromObjects = (type, arr) => {
  return arr.reduce((acc, item) => {
    return Object.keys(item.diagnosis || {}).length > 0 
      ? [
        ...acc, 
        { 
          id: item.id, 
          type, 
          diagnosis: item.diagnosis, 
          date: item.diagnosed_at || item.created_at 
        }
      ] : acc;
  }, []);
};

const getDiagnosisHistory = (injury) => {
  const { injury_assessments = [], soap_notes = [], specialist_notes = [] } = injury;
  
  return [
    ...getDiagnosisFromObjects('InjuryAssessment', injury_assessments),
    ...getDiagnosisFromObjects('SoapNote', soap_notes),
    ...getDiagnosisFromObjects('SpecialistNote', specialist_notes),
  ].sort(sortDescending.bind(null, 'date'));
};

const getRecoveryStagesFromObjects = (type, arr) => {
  return arr.reduce((acc, item) => {
    return item.recovery_protocol_stage
      ? [
        ...acc,
        {
          id: item.id,
          type,
          recoveryStage: item.recovery_protocol_stage,
          date: item.created_at
        }
      ] : acc;
  }, []);
};

const getRecoveryStageHistory = (injury) => {
  const { injury_assessments = [], soap_notes = [], specialist_notes = [] } = injury;
  
  return [
    ...getRecoveryStagesFromObjects('InjuryAssessment', injury_assessments),
    ...getRecoveryStagesFromObjects('SoapNote', soap_notes),
    ...getRecoveryStagesFromObjects('SpecialistNote', specialist_notes),
  ].sort(sortDescending.bind(null, 'date'));
};

const SummaryCard = ({
  title,
  historyEnabled = true,
  onViewHistory,
  children
}) => {
  return (
    <div className="summary-card">
      <div className="summary-header">
        <div className="summary-title">
          {title}
        </div>
        {historyEnabled && (
          <button
            type="button"
            className="view-history"
            onClick={onViewHistory}
          >
            {Strings.viewHistoryButtonText}
          </button>
        )}
      </div>
      <div className="summary-content">
        {children}
      </div>
    </div>
  );
};

const SummaryCards = ({ injury }) => {
  const { recovery_protocol_stage = {}, diagnosis } = injury || {};
  const [recoveryHistoryOpen, setRecoveryHistoryOpen] = useState(false);
  const [diagnosisHistoryOpen, setDiagnosisHistoryOpen] = useState(false);
  const diagnosisHistory = useMemo(() => getDiagnosisHistory(injury), [injury]);
  const recoveryStageHistory = useMemo(() => getRecoveryStageHistory(injury), [injury]);

  const renderRecoveryStageItem = useCallback(item => (
    <RecoveryStagesView
      stages={item.recoveryStage}
      description={false}
    />
  ), []);

  const renderDiagnosisItem = useCallback(item => (
    <DiagnosisView diagnosis={item.diagnosis} />
  ), []);

  return (
    <>
      <div className="insights-summary-cards">
        <SummaryCard 
          title={Strings.currentRecoveryStageCardTitle}
          historyEnabled={recoveryStageHistory.length > 1}
          onViewHistory={() => setRecoveryHistoryOpen(true)}
        >
          <RTSProgressIndicator 
            stage={recovery_protocol_stage?.rts_stage}
          />
          <RTLProgressIndicator 
            stage={recovery_protocol_stage?.rtl_stage}
          />
          <RTWProgressIndicator
            stage={recovery_protocol_stage?.rtw_stage}
          />
        </SummaryCard>
        <SummaryCard
          title={Strings.currentDiagnosisCardTitle}
          historyEnabled={diagnosisHistory.length > 1}
          onViewHistory={() => setDiagnosisHistoryOpen(true)}
        >
          <DiagnosisView 
            diagnosis={diagnosis} 
            showNotes={false} 
          />
        </SummaryCard>
      </div>
      <HistoryModal
        isOpen={recoveryHistoryOpen}
        title={Strings.recoveryStageHistoryTitle}
        items={recoveryStageHistory}
        renderItem={renderRecoveryStageItem}
        onClose={() => setRecoveryHistoryOpen(false)}
      />
      <HistoryModal
        isOpen={diagnosisHistoryOpen}
        title={Strings.diagnosisHistoryTitle}
        items={diagnosisHistory}
        renderItem={renderDiagnosisItem}
        onClose={() => setDiagnosisHistoryOpen(false)}
      />
    </>
  );
};

export default SummaryCards;
