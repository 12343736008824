const EN = {
  taxRateTitle: 'Tax Rates',
  addTaxRateButton: 'Add Tax Rate',
  confirmDeleteTaxRateTitle: 'Confirm Delete Tax Rate',
  confirmDeleteTaxRateMessage: 'Are you sure you want to delete this tax rate for {0}, {1}?',
  addTaxRateTitle: 'Add Tax Rate',
  updateTaxRateTitle: 'Update Tax Rate',
  countryLabel: 'Country',
  countryErrorMessage: 'Select a Country',
  regionLabel: 'Region',
  regionErrorMessage: 'Select a region',
  taxCadeLabel: 'Tax Code',
  taxCodeErrorMessage: 'Enter a tax code (e.g. HST)',
  taxRateLabel: 'Tax Rate (%)',
  taxRateErrorMessage: 'Enter the tax rate (%)',
  submitButtonText: 'Submit',
  lastUpdatedLabel: 'Last Updated',
  actionsLabel: 'Actions'
};

export default EN;
