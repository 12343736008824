import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormInputGroup, SelectInputGroup, RadioInputGroup } from '@/forms';
import { Sports } from '@/utilities/sports';
import Strings from './lang';

class CauseOfInjury extends Component {
  constructor(props) {
    super(props);

    const { injury_cause } = props;

    this.onUpdateCause = this.onUpdateCause.bind(this);

    this.state = {
      ...injury_cause
    };
  }

  render() {
    return (
      <div>
        <RadioInputGroup
          titleLabelText={
            <span className="bold">{Strings.causeOfInjuryLabelText} <span className="required">*</span></span>
          }
          className="form-group"
          radioLabels={[
            Strings.causeSportLabel, 
            Strings.causeMVALabel, 
            Strings.causeFallLabel, 
            Strings.causeAssaultLabel, 
            Strings.causeOtherLabel
          ]}
          radioValues={['Sport', 'MVA', 'Fall', 'Assault', 'Other']}
          initialValue={this.state.cause}
          inputProps={{
            className: 'form-control'
          }}
          required
          touched={this.props.touched}
          inputValid={this.state.cause !== ''}
          messageClassName="alert alert-danger"
          messageText={Strings.noValueSelectedErrorMessage}
          onUpdate={this.onUpdateCause}
        />
        {this.state.cause === 'Sport' && (
          <SelectInputGroup 
            className="form-group"
            labelText={Strings.sportSelectLabel}
            inputProps={{
              className: 'form-control',
              value: this.state.cause_sport,
            }}
            inputValid={this.state.cause_sport !== ''}
            required
            onUpdate={(e) => this.onUpdateValue('cause_sport', e)}
            messageClassName="alert alert-danger"
            touched={this.props.touched}
          >
            <option value="">{Strings.sportSelectLabel}</option>
            {Sports.map(item => <option key={item.key} value={item.key}>{item.name}</option>)}
            <option value="Other">{Strings.causeOtherLabel}</option>
          </SelectInputGroup>
        )}
        {this.state.cause_sport === 'Other' && (
          <FormInputGroup 
            className="form-group"
            labelText={Strings.otherSportLabel} 
            inputType="text"
            inputProps={{
              className: 'form-control',
              value: this.state.cause_sport_other,
              onBlur: this.onUpdateValue.bind(this, 'cause_sport_other'),
              onChange: this.onUpdateValue.bind(this, 'cause_sport_other')
            }}
            inputValid={this.state.cause_sport_other !== ''}
            messageClassName="alert alert-danger"
            messageText={Strings.requiredTextError}
            required
            touched={this.props.touched}
          />
        )}
        {this.state.cause === 'Other' && (
          <FormInputGroup 
            className="form-group"
            labelText={Strings.otherCauseOfInjuryLabel} 
            inputType="text"
            inputProps={{
              className: 'form-control',
              value: this.state.cause_other,
              placeholder: Strings.otherCauseOfInjuryLabel,
              onBlur: this.onUpdateValue.bind(this, 'cause_other'),
              onChange: this.onUpdateValue.bind(this, 'cause_other')
            }}
            inputValid={this.state.cause_other !== ''}
            messageClassName="alert alert-danger"
            messageText={Strings.requiredTextError}
            required
            touched={this.props.touched}
          />
        )}
      </div>
    );
  }

  onUpdateCause(value) {
    let { 
      cause, 
      cause_sport, 
      cause_other, 
      cause_sport_other 
    } = this.state;

    cause = value;

    if (cause !== 'Sport') {
      cause_sport = '';
      cause_sport_other = '';
    }

    if (cause !== 'Other') {
      cause_other = '';
    }

    this.setState({
      cause, cause_sport, cause_other, cause_sport_other
    });

    this.props.onUpdate({ 
      cause, cause_sport, cause_other, cause_sport_other 
    });
  }

  onUpdateValue(key, e) {
    const { cause } = this.state;
    let { cause_sport, cause_other, cause_sport_other } = this.state;

    if (key === 'cause_sport') {
      cause_sport = e;
      if (cause_sport !== 'Other') {
        cause_sport_other = '';
      }
    } else if (key === 'cause_other') {
      const { value } = e.target;
      cause_other = value;
    } else if (key === 'cause_sport_other') {
      const { value } = e.target;
      cause_sport_other = value;
    }

    this.setState({
      cause_sport,
      cause_other,
      cause_sport_other
    });

    this.props.onUpdate({
      cause,
      cause_other,
      cause_sport,
      cause_sport_other
    });
  }
}

CauseOfInjury.propTypes = {
  injury_cause: PropTypes.shape({
    cause: PropTypes.string,
    cause_sport: PropTypes.string,
    cause_other: PropTypes.string
  }),
  onUpdate: PropTypes.func,
  touched: PropTypes.bool
};

export default CauseOfInjury;
