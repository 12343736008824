const EN = {
  scoreTotalLabel: 'Memory Total',
  listSelectLabel: 'Word List',
  trialLabel: 'Trial',
  defaultOption: 'Select One',
  usedText: 'used',
  defaultListOption: 'Select List',
  selectListErrorMessage: 'Please Select a Word List'
};

export default EN;
