const FR = {
  title: 'Docteur à joindre',
  guardianSubtitle: 'Entrez les coordonnées du médecin principal de {0}',
  returningSubtitle: 'Mettre à jour les informations de contact pour votre médecin principal',
  guardianNoPhysicianText: '{0} n\'a pas de médecin principal.',
  noPhysicianText: 'Je n\'ai pas de médecin principal.',
  skipStepText: 'Passer cette étape',
  physicianNameLabelText: 'Nom du docteur',
  physicianNameErrorText: 'Veuillez indiquer le nom du docteur',
  phoneLabelText: 'No de téléphone',
  phoneErrorText: 'Veuillez indiquer le no de téléphone du docteur.',
  faxLabelText: 'No de fax',
  faxErrorText: 'Veuillez indiquer le no de fax du docteur.',
  addressLabelText: 'Adresse',
  submitButtonText: 'Envoyer',
  doesChildHavePhysician: 'Est-ce que {0} a un médecin principal ?',
  doYouHavePhysician: 'Est-ce que vous avez un médecin principal ?',
  yesLinkText: 'Oui',
  noLinkText: 'Non'
};
 
export default FR;
