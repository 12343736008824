/* eslint-disable no-console */
const isDebug = process.env.CCMI_DEBUG === 'true';

class Logger {
  #logs;

  constructor() {
    this.#logs = [];
  }

  log(...args) {
    this.#logs.push(args);
  }

  flush(collapsed = true, ...title) {
    if (isDebug) {
      let group = console.groupCollapsed;
      if (!collapsed) {
        group = console.group;
      } 

      group(...title);
      this.#logs.map(log => console.log(...log));
      console.groupEnd();
    }
  }
}

export default Logger;
