import React, { useState } from 'react';
import AccessControl from '../access-control';
import { LineItemDrawer } from './line-items';
import CloseInvoiceModal from './close-invoice-modal';
import { RecievePaymentDrawer } from '../payments';
import { BulkCreditsPurchaseDrawer } from '../bulk-credits';
import { AdminRoleDescriptions } from '@/utilities/user-roles';
import { showAlert } from '../alert-notifications';
import Dropdown, { MenuItem } from '../dropdown';
import Strings from './lang';
import Icon from '../icon';

const showPaymentSuccessAlert = () => {
  showAlert('success', {
    dismissable: true,
    autoDismiss: 3000,
    message: Strings.paymentCreatedSuccessMessage
  });
};

const ActionsMenu = ({
  status,
  balance,
  onAddLineItem,
  onReceivePayment,
  onAddBulkCredits,
  onCloseInvoice
}) => {
  return (
    <>
      <MenuItem onSelect={onAddLineItem}>
        {Strings.addLineItemTitle}  
      </MenuItem>
      <MenuItem onSelect={onAddBulkCredits}>
        {Strings.addBulkCreditsActionLabel}
      </MenuItem>
      {balance > 0 && (
        <MenuItem onSelect={onReceivePayment}>
          {Strings.recievePaymentTitle}
        </MenuItem>
      )}
      {(status === 'open' || status === 'overdue') && (
        <MenuItem type="danger" onSelect={onCloseInvoice}>
          {Strings.closeInvoiceActionLabel}
        </MenuItem>
      )}
    </>
  );
};

const AdminInvoiceActions = ({
  status,
  currency,
  clinicId,
  invoiceId,
  balance
}) => {
  const [actionsOpen, setActionsOpen] = useState(false);
  const [paymentDrawerOpen, setPaymentDrawerOpen] = useState(false);
  const [lineItemDrawerOpen, setLineItemDrawerOpen] = useState(false);
  const [closeModalOpen, setCloseModalOpen] = useState(false);
  const [bulkCreditDrawerOpen, setBulkCreditDrawerOpen] = useState(false);

  return (
    <AccessControl roles={AdminRoleDescriptions}>
      <div className="invoice-action">
        <Dropdown
          isOpen={actionsOpen}
          placement="bottom-end"
          trigger={(
            <button 
              type="button" 
              className="btn btn-sm btn-primary" 
              onClick={() => setActionsOpen(!actionsOpen)}
            >
              {Strings.actionsButtonLabel}&nbsp;&nbsp;
              <Icon name="chevron-down" />
            </button>
          )}
          onClose={() => setActionsOpen(false)}
        >
          <ActionsMenu 
            status={status}
            balance={balance}
            onAddLineItem={() => {
              setActionsOpen(false);
              setLineItemDrawerOpen(true);
            }}
            onAddBulkCredits={() => {
              setActionsOpen(false);
              setBulkCreditDrawerOpen(true);
            }}
            onCloseInvoice={() => {
              setActionsOpen(false);
              setCloseModalOpen(true);
            }}
            onReceivePayment={() => {
              setActionsOpen(false);
              setPaymentDrawerOpen(true);
            }}
          />
        </Dropdown>
        
        <LineItemDrawer 
          clinicId={clinicId}
          invoiceId={invoiceId}
          currency={currency}
          isOpen={lineItemDrawerOpen}
          onClose={() => setLineItemDrawerOpen(false)}
        />
        <BulkCreditsPurchaseDrawer 
          isOpen={bulkCreditDrawerOpen}
          title={Strings.addBulkCreditsActionLabel}
          currency={currency}
          clinicId={clinicId}
          invoiceId={invoiceId}
          onClose={() => setBulkCreditDrawerOpen(false)}
        />
        <RecievePaymentDrawer 
          isOpen={paymentDrawerOpen}
          currency={currency}
          clinicId={clinicId}
          invoiceId={invoiceId}
          balance={balance}
          onClose={(submitted) => {
            setPaymentDrawerOpen(false);
            // submitted could have a non boolean value
            if (submitted === true) {
              showPaymentSuccessAlert();
            }
          }}
        />
        <CloseInvoiceModal 
          isOpen={closeModalOpen}
          clinicId={clinicId}
          invoiceId={invoiceId}
          onClose={() => setCloseModalOpen(false)}
        />
      </div>
    </AccessControl>
  );
};

export default AdminInvoiceActions;
