const FR = {
  redFlagsHeader: 'Alertes',
  redFlagsReported: 'Alertes signalées',
  redFlagsInfoText: 'Est-ce que l’un des éléments suivants pourrait justifier une orientation d’urgence du patient ?',
  none: 'Aucun',
  severe_headache: 'Maux de tête graves ou s’aggravant',
  seizures: 'Convulsions',
  focal_neurological_signs: 'Signes neurologiques focaux',
  visibly_drowsy: 'Semble très somnolent/Ne peut pas être réveillé',
  recurring_vomiting: 'Vomit 2 fois plus depuis la blessure',
  slurred_speech: 'Trouble d’élocution',
  cant_recognize_people_places: 'Ne peut pas reconnaître les gens ou les endroits',
  increasing_confusion_irritability: 'Confusion ou irritabilité croissante',
  weakness: 'Faiblesse ou engourdissement dans les bras/jambes',
  fail_ct_rules: 'Échec aux règlements Canadian CT Head Rules',
  behaviour_change: 'Changement inhabituel de comportement',
  fail_cervical_spine_rules: 'Échec aux règlements Canadian Cervical Spine Rules',
  decreasing_conscious: 'Conscience déclinante',
  sixty_plus_years: 'Patient de plus de 60 ans',
  intoxicated: 'En état d’ébriété au moment de la blessure',
  skull_fracture: 'Signes de fractures du crâne',
  glascow_coma: 'Résultat de moins de 15 à l’échelle de coma de Glasgow pendant plus de 2 heures après la blessure',
  errorMessage: 'Vous ne devez choisir qu’une ou aucune de ces options'
};
 
export default FR;
