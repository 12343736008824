const FR = {
  patientInfoConfirmedMessage: 'L’information de {0} a été confirmée',
  redactedReplacementText: 'le patient',
  accountNumberLabel: 'Numéro de compte',
  bagtagNumberLabel: 'Numéro d’identité Concussion',
  confirmInformationTitle: 'Confirmer les informations sur le patient',
  patientCheckInTitle: 'Enregistrement du patient pour {0}',
  patientCheckInBaselineTitle: 'Test de référence',
  patientCheckInInjuryTitle: 'Évaluation de la blessure',
  patientInformationCardHeader: 'Informations du patient',
  confirmAndContinueButtonText: 'Confirmer et continuer',
  checkInPatientButtonText: 'Enregistrer le patient',
  checkInErrorMessageText: 'Les informations de ce patient contiennent un ou plusieurs champs non valides. S\'il vous plaît examiner les informations.'
};
 
export default FR;
