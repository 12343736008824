import React from 'react';
import classnames from 'classnames';

const Backdrop = ({ 
  isOpen, 
  blurred = false,
  disabled = false,
  appearance = 'dark',
  onClick = () => {},
  onCloseComplete = () => {}
}) => {
  return (
    <div 
      className={classnames('overlay', appearance, { blurred })}
      onClick={disabled ? undefined : onClick}
      onTransitionEnd={() => !isOpen && onCloseComplete()}
    />
  );
};

export default Backdrop;
