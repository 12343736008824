import React, { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import Icon from '../../icon';
import { StepRoutesConfig } from './steps-routes';
import { BaselineTestLabels } from '../baseline-utilities';
import { age } from '@/utilities/dates';
import BaselineHeaderStrings from '../lang';
import Strings from './lang';

const SidebarSections = [
  {
    title: BaselineHeaderStrings.athleteInformationHeader,
    steps: ['personal', 'medical']
  },
  {
    title: BaselineHeaderStrings.clinicianAssessmentHeader,
    steps: [
      'pre_existing_symptoms',
      'cognitive_assessment_test',
      'immediate_memory_test',
      'concentration_test',
      'king_devick_test'
    ]
  },
  {
    title: BaselineHeaderStrings.balanceHeader,
    steps: [
      'balance_test',
      'force_plate_test',
      'tandem_gait_test',
      'dual_task_test'
    ]
  },
  {
    title: BaselineHeaderStrings.reactionAndStrengthHeader,
    steps: [
      'reaction_time_test',
      'grip_strength_test',
      'delayed_memory_recall_test'
    ]
  }
];

const getPreviousRoute = (current, profile = []) => {
  const index = profile.indexOf(current?.key);
  let prevStep;

  if (index > 0) {
    prevStep = profile[index - 1];
  }

  return StepRoutesConfig[prevStep] || {};
};

const isStepCompleted = (step, baseline) => {
  if (step === 'personal' || (step === 'medical' && baseline?.id)) return true;
  return !!Object.keys(baseline?.[step] || {}).length;
};

const isStepDisabled = (baseline, step, current, previous) => {
  if (
    current?.key === step
    || step === 'personal'
    || step === 'medical'
    || (step === 'pre_existing_symptoms' && baseline.id)
    || isStepCompleted(step, baseline)
    || isStepCompleted(previous?.key, baseline)
  ) {
    return false;
  }

  return true;
};

const PatientInformation = ({ patient }) => {
  const { person, account, bag_tag } = patient;
  const {
    first_name,
    last_name,
    photo_url,
    birthday
  } = person || {};

  return person ? (
    <div className="baseline-user-info">
      <div className="profile-info">
        <img
          alt={`${first_name} ${last_name}`}
          src={photo_url}
          className="profile-image"
        />
        <div>
          <div><strong>{first_name} {last_name}</strong></div>
          <div><small>{bag_tag || account}</small></div>
          <div>
            <small>
              <strong>{Strings.ageLabel}:</strong> {age(birthday) || Strings.unknownLabel}
            </small>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

const StepLink = ({
  to,
  children,
  disabled,
  onClick
}) => {
  return (
    <li>
      {disabled ? (
        <div className="step text-muted">
          {children}
        </div>
      ) : (
        <NavLink
          to={to}
          className={({ isActive }) => classNames('step', { active: isActive })}
          onClick={onClick}
        >
          {children}
        </NavLink>
      )}
    </li>
  );
};

const SectionStep = ({
  step,
  currentStep,
  baseline,
  profile,
  onClick
}) => {
  const route = StepRoutesConfig[step];
  const previous = useMemo(() => getPreviousRoute(route, profile), [profile, route]);
  const disabled = useMemo(() => (
    isStepDisabled(baseline, step, currentStep, previous)
  ), [baseline, currentStep, previous, step]);
  const completed = useMemo(() => isStepCompleted(step, baseline), [baseline, step]);
  const skipped = !!(baseline[step] || {}).not_done;

  return (
    <StepLink
      to={route.path}
      disabled={disabled}
      onClick={onClick}
    >
      <span>{BaselineTestLabels[step]}</span>
      <span>
        {skipped && <i className="warning skipped">{Strings.skippedLabelText}</i>}
        <span className="complete-icon">
          {completed && <Icon name="circle-check" className="success" />}
        </span>
      </span>
    </StepLink>
  );
};

const Section = ({
  title,
  steps,
  currentStep,
  profile,
  baseline = {},
  onSelect
}) => { 
  if (!steps.length) {
    return null;
  }

  return (
    <li className="sidebar-section">
      <div className="section-title">
        {title}
      </div>
      <ul className="section-steps">
        {steps.map(step => (
          <SectionStep
            key={step}
            step={step}
            profile={profile}
            baseline={baseline}
            currentStep={currentStep}
            onClick={onSelect}
          />
        ))}
      </ul>
    </li>
  );
};

const BaselineSidebar = ({
  user = {},
  profile = [],
  baseline,
  currentStep,
  onSelect
}) => {
  return (
    <div className="baseline-sidebar">
      <PatientInformation patient={user} />
      <ul className="sidebar-sections">
        {SidebarSections.map((section, index) => (
          <Section
            key={index}
            title={section.title}
            profile={profile}
            steps={section.steps.filter(step => profile.includes(step))}
            currentStep={currentStep}
            baseline={baseline}
            onSelect={onSelect}
          />
        ))}
      </ul>
    </div>
  );
};

export default BaselineSidebar;
