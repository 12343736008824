const EN = {
  clinicTypeLabel: 'Clinic Type',
  selectOptionLabel: 'Select Clinic Type',
  primaryLabel: 'Rehab & Treatment',
  medicalLabel: 'Medical',
  baselineLabel: 'Baseline Testing',
  specialistLabel: 'Specialized Treatment',
  errorMessage: 'Please select the clinic type'
};

export default EN;
