import React, { useState } from 'react';
import Drawer from '../drawer';
import DrawerHeader from './drawer-header';
import SoapNotesView from './soap-notes-view';
import SpecialistNotesView from './specialist-notes-view';
import { TabKeys } from './utils';

const NoteTypes = {
  Soap: 'soap',
  Specialist: 'specialist'
};

const NotesHistoryDrawer = ({
  isOpen = false,
  user = {},
  injury = {},
  clinicId,
  noteType = NoteTypes.Soap,
  noteId,
  onClose
}) => {
  const [tab, setTab] = useState(TabKeys.SoapNotes);

  return (
    <Drawer
      isOpen={isOpen}
      position="bottom"
      onClose={onClose}
    >
      <div className="notes-drawer">
        <DrawerHeader
          tab={tab}
          onTabChange={setTab}
          onClose={onClose}
        />
        <div className="container notes-drawer-content">
          {tab === TabKeys.SoapNotes && (
            <SoapNotesView 
              clinicId={clinicId}
              user={user}
              injury={injury}
              noteId={noteType === NoteTypes.Soap ? noteId : null}
            />
          )}

          {tab === TabKeys.SpecialistNotes && (
            <SpecialistNotesView 
              clinicId={clinicId}
              user={user}
              injury={injury}
              noteId={noteType === NoteTypes.Specialist ? noteId : null}
            />
          )}
        </div>
      </div>
    </Drawer>
  );
};

NotesHistoryDrawer.NoteTypes = NoteTypes;

export default NotesHistoryDrawer;
