import { UPDATE_INJURY_REPORT, UPDATE_INJURY_REPORTS } from '../actions/injury-reports';

const injuryReportReducer = (state = {}, action = {}) => {
  const { userId = 0, injuryReport = {} } = action;
  const userState = state[userId] || {};
  const newState = { ...userState, [injuryReport.id]: injuryReport };
  return (userId) ? { ...state, [userId]: newState } : state;
};

const injuryReportsReducer = (state = {}, action = {}) => {
  const { userId = 0, injuryReports = [] } = action;
  const userState = injuryReports.reduce((userState, injuryReport) => {
    return { ...userState, [injuryReport.id]: injuryReport };
  }, state[userId] || {});
  return (userId) ? { ...state, [userId]: userState } : state;
};

const injuryReports = (state = {}, action = {}) => {
  switch (action.type) {
    case UPDATE_INJURY_REPORT:
      return injuryReportReducer(state, action);
    case UPDATE_INJURY_REPORTS:
      return injuryReportsReducer(state, action);
    default:
      return state;
  }
};

export default injuryReports;
