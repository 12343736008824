import React, {
  useCallback,
  useEffect,
  useState
} from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { If, Then, Else } from 'react-if';
import { ClinicPaths } from '@/paths';
import replaceLinkParams from '@/utilities/replace-link-params';
import BaselineListItem from './baseline-list-item';
import { ErrorBanner } from '../../errors';
import AccessControl from '../../access-control';
import QuickActions from '../../quick-actions';
import Activity from '../../activity';
import Icon from '../../icon';
import { archiveBaselineTestAsync } from '@/redux/thunks/baseline-tests';
import { getUserBaselineTestsAsync } from '@/redux/thunks/user-baseline-tests';
import { RoleDescriptions } from '@/utilities/user-roles';
import { showAlert } from '../../alert-notifications';
import Strings from './lang';

const baselinesSelector = (userId) => state => state.userBaselineTests[userId];

const UserBaselinesList = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [activity, setActivity] = useState(false);
  const [error, setError] = useState(null);
  const baselines = useSelector(baselinesSelector(params.userId));

  const getBaselines = useCallback((userId) => {
    setActivity(true);
    setError(null);

    dispatch(getUserBaselineTestsAsync(userId)).then(() => {
      setActivity(false);
    }).catch(error => {
      setError(error);
      setActivity(false);
    });
  }, [dispatch]);

  const archiveBaseline = useCallback((id, reason) => {
    setActivity(true);
    setError(null);

    dispatch(archiveBaselineTestAsync(params.userId, id, reason)).then(() => {
      setActivity(false);
      showAlert('success', {
        dismissable: true,
        autoDismiss: 3500,
        message: Strings.archivedSuccessMessage,
      });
    }).catch(error => {
      setError(error.message);
      setActivity(false);
    });
  }, [dispatch, params.userId]);

  useEffect(() => {
    if (params.userId && !baselines?.length) {
      getBaselines(params.userId);
    }
  }, [baselines?.length, getBaselines, params.userId]);

  return (
    <div className="user-baselines">
      <div className="user-baselines-container">
        <Activity active={activity} static={!baselines?.length}>
          <ErrorBanner error={error} />
          <h2>{Strings.title}</h2>
          <If condition={baselines?.length > 0}>
            <Then>
              <ul className="user-baselines-list">
                {baselines?.map((baseline, index) => (
                  <li key={baseline.id}>
                    <BaselineListItem
                      baseline={baseline}
                      number={baselines.length - index}
                      link={
                        replaceLinkParams(
                          ClinicPaths.patientProfile.baselines.show.link,
                          { ...params, baselineId: baseline.id }
                        )
                      }
                      onArchive={archiveBaseline}
                    />
                  </li>
                ))}
              </ul>
            </Then>
            <Else>
              <p>{Strings.emptyMessageText}</p>
            </Else>
          </If>
        </Activity>
      </div>
      <AccessControl roles={[RoleDescriptions.Clinician, RoleDescriptions.BaselineTester]}>
        <QuickActions>
          <li>
            <Link 
              className="btn btn-default" 
              to={
                replaceLinkParams(
                  ClinicPaths.patientProfile.baselines.archived.link, 
                  params
                )
              }
            >
              <Icon name="box-archive" />&nbsp;&nbsp;
              {Strings.archivedBaselinesText}
            </Link>
          </li>
        </QuickActions>
      </AccessControl>
    </div>
  );
};

export default UserBaselinesList;
