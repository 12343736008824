import React from 'react';
import Strings from '../lang';
import Icon from '../../icon';

const Legend = () => {
  return (
    <div className="dana-info-legend">
      <div className="legend-item">
        <Icon name="check" />
        <span>{Strings.trialsAcceptableDescription}</span>
      </div>
      <div className="legend-item">
        <Icon name="minus" />
        <span>{Strings.trialsUnacceptableDescription}</span>
      </div>
      <div className="legend-item">
        <Icon name="xmark" />
        <span>{Strings.trialsIncompleteDescription}</span>
      </div>
    </div>
  );
};

export default Legend;
