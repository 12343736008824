const CSV_FILE_REGEX = /.+\.csv$/;

/* eslint-disable no-useless-escape */
const defaultItemFormat = (row) => {
  return `\"${row}\"`;
};
/* eslint-enable no-useless-escape */

const csvJoin = (headers, data, separator, formatCell) => {
  const formatedHeader = headers?.length > 0 ? headers.map(defaultItemFormat) : null;
  const formatedRows = data?.map(row => (
    row.map((item, index) => (
      formatCell 
        ? formatCell(item, headers[index]) 
        : defaultItemFormat(item)
    )).join(separator)
  ));

  return (formatedHeader ? [formatedHeader, ...formatedRows] : formatedRows).join('\n');
};

const buildURI = (headers = [], data = [], separator = ',', formatCell = null) => {
  return encodeURI(`data:application/csv;charset=utf-8,\uFEFF${csvJoin(headers, data, separator, formatCell)}`);
};

const configureFilename = (filename) => {
  if (!filename) return 'default.csv';

  if (!filename.match(CSV_FILE_REGEX)) {
    return `${filename}.csv`;
  }

  return filename;
};

export {
  buildURI,
  configureFilename
};
