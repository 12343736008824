import React, { useCallback, useMemo, useState } from 'react';
import {
  FormComponent,
  SelectInputGroup,
  CheckboxInputGroup
} from '@/forms';
import ProviderInputList from './provider-input-list';
import Icon from '../../icon';
import AppointmentDatetimeInput from './appointment-datetime-input';
import Strings from '../lang';

const AppointmentForm = ({
  allowInitialVisit,
  submitting,
  minApptDate,
  maxApptDate,
  onSubmit
}) => {
  const [submitted, setSubmitted] = useState(false);
  const [appt, setAppt] = useState({ value: '', valid: false });
  const [treatment, setTreatment] = useState('');
  const [virtual, setVirtual] = useState(false);
  const [providers, setProviders] = useState([{ name: '', designation: '' }]);
  const isValid = useMemo(() => {
    return appt.valid && treatment !== '';
  }, [appt.valid, treatment]);

  const handleSubmit = useCallback(() => {
    setSubmitted(true);

    if (isValid) {
      onSubmit?.({
        appt: appt.value,
        treatment,
        virtual,
        providers
      });
    }
  }, [appt.value, providers, isValid, onSubmit, treatment, virtual]);

  return (
    <FormComponent className="claim-appt-form" onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-md-6">
          <AppointmentDatetimeInput
            appt={appt}
            minDate={minApptDate}
            maxDate={maxApptDate}
            disabled={submitting}
            touched={submitted}
            onChange={setAppt}
          />
        </div>
        <div className="col-md-6">
          <SelectInputGroup
            className="form-group"
            labelText={Strings.treatmentTypeLabel}
            inputProps={{
              disabled: submitting,
              className: 'form-control',
              value: treatment
            }}
            required
            messageClassName="alert alert-danger"
            messageText={Strings.treatmentTypeErrorMessage}
            valid={treatment !== ''}
            touched={submitted}
            onUpdate={setTreatment}
          >
            <option value="">{Strings.selectTreatmentType}</option>
            {allowInitialVisit && (
              <option value="initial_assessment">{Strings.treatmentTypeInitialAssessment}</option>
            )}
            <option value="functional_testing">{Strings.treatmentTypeFunctionalTesting}</option>
            <option value="physical_therapy">{Strings.treatmentTypePhysicalTherapy}</option>
            <option value="occupational_therapy">{Strings.treatmentTypeOccupationalTherapy}</option>
            <option value="work_hardening">{Strings.treatmentTypeWorkHardening}</option>
          </SelectInputGroup>
        </div>
      </div>
      <CheckboxInputGroup
        inputValid
        className="form-group"
        labelText={Strings.virtualApptCheckboxLabel}
        inputProps={{
          disabled: submitting,
          className: 'form-control',
          checked: virtual,
          onChange: e => {
            setVirtual(e.target.checked);
          }
        }}
      />
      <ProviderInputList
        disabled={submitting}
        touched={submitted}
        providers={providers}
        onChange={setProviders}
      />
      <div className="form-footer">
        <button type="submit" className="btn btn-primary" disabled={submitting || !isValid}>
          {submitting && <><Icon spinPulse prefix="fad" name="loader" />&nbsp;</>}
          {Strings.submitButton}
        </button>
      </div>
    </FormComponent>
  );
};

export default AppointmentForm;
