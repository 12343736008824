const FR = {
  concentrationTotal: 'Total de concentration :',
  mainTitle: 'Concentration',
  numbersTitle: 'Nombres inversés',
  numbersInfo: '" Je vais vous lire une série de nombres et lorsque j\'aurai terminé, répétez-les-moi dans l’ordre inverse de celui où je vous les ai donnés. Par exemple, si je dis 7, 1, 9, vous me dites 9, 1, 7 ".',
  monthsInReverseTitle: 'Mois inversés',
  monthsInfo: 'Les athlètes doivent nommer les mois en sens inverse, commençant avec le mois de décembre (1 point si tous les mois sont nommés dans le bon ordre inversé, 0 point en cas d’erreur)',
  numbersErrorText: 'Le test des nombres inversés n’est pas terminé',
  timedMonthsInfo: 'Les athlètes doivent dire les mois dans l\'ordre chronologique inverse, en commençant par décembre(1 point est attribué s\'ils sont capables d\'obtenir la séquence complète correcte, 0 point s\'ils font des erreurs ou mettent plus de 30 secondes à terminer).'
};
 
export default FR;
