import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { resolvePatientName } from './resolve-patient-name';
import { UserSelector } from '@/redux/selectors';
import Strings from './lang';

const DEFAULT_TAG_NAME = 'span';

const PatientName = ({
  patient = {},
  clinicId = 0,
  redactedText = Strings.redactedText,
  lastNameFirst = false,
  tagName = DEFAULT_TAG_NAME,
  tagProps = {}
}) => {
  const currentUser = useSelector(UserSelector.getCurrentUser) || {};
  // Assign `tagName` to an uppercase variable to stop
  // React from interpreting it as an HTML tag:
  const Tag = tagName;

  return (
    <Tag {...tagProps}>
      {resolvePatientName({
        user: currentUser,
        patient,
        clinicId,
        redactedText,
        lastNameFirst
      })}
    </Tag>
  );
};

PatientName.propTypes = {
  patient: PropTypes.object.isRequired,
  clinicId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  tagName: PropTypes.string,
  tagProps: PropTypes.object,
  redactedText: PropTypes.string,
  lastNameFirst: PropTypes.bool
};

export default PatientName;
