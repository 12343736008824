import React, { Component } from 'react';
import classnames from 'classnames';
import { PanelGroupContext } from './panel-context';

class PanelGroup extends Component {
  constructor(props) {
    super(props);

    this.childSubscribe = this.childSubscribe.bind(this);
    this.subscriptions = [];
  }

  render() {
    const { className, children } = this.props;

    return (
      <PanelGroupContext.Provider value={this.getContextValue()}>
        <div className={classnames('panel-group', className)}>
          {children}
        </div>
      </PanelGroupContext.Provider>
    );
  }

  getContextValue() {
    const {
      type = 'default',
      collapsible = false,
      disabled = false
    } = this.props;

    return {
      type,
      collapsible,
      disabled,
      subscribe: this.childSubscribe
    };
  }

  childSubscribe(cb) {
    this.subscriptions.push(cb);
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  openAll() {
    this.subscriptions.forEach((cb) => {
      cb(false);
    });
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  closeAll() {
    this.subscriptions.forEach((cb) => {
      cb(true);
    });
  }
}

export default PanelGroup;
