import React, { useCallback, useState } from 'react';
import ConcentrationNumbers from './numbers';
import { MonthsInReverse, MonthsInReverseTimed } from './months-in-reverse';
import ScoreView from '../../../score-view';
import Strings from './lang';

const numberScoreMax = 4;
const monthsScoreMax = 1;

const getMonthsScore = (score) => {
  return isNaN(parseInt(score, 10)) ? '' : parseInt(score, 10);
};

const BaselineConcentration = ({
  initialState = {},
  touched = false,
  timedMonthsInReverse = true,
  onUpdate = () => {}
}) => {
  const [numbersScore, setNumbersScore] = useState(initialState?.numbersScore ?? 0);
  const [monthsScore, setMonthsScore] = useState(() => getMonthsScore(initialState?.monthsScore));
  const [monthsDuration, setMonthsDuration] = useState(initialState?.monthsDuration ?? null);
  const [numberList, setNumberList] = useState(initialState?.numberList ?? '');
  const [numbersCompleted, setNumbersCompleted] = useState(numbersScore > 0);

  const onChangeNumbersScore = useCallback((numberList, score, completed) => {
    setNumbersScore(score);
    setNumbersCompleted(completed);
    setNumberList(numberList);

    onUpdate({
      numbersScore: score,
      monthsScore,
      monthsDuration,
      numbersCompleted: completed,
      numberList
    });
  }, [monthsDuration, monthsScore, onUpdate]);

  const onChangeMonthsScore = useCallback((score) => {
    setMonthsScore(score);

    onUpdate({
      numbersScore,
      monthsScore: score,
      monthsDuration,
      numbersCompleted,
      numberList
    });
  }, [monthsDuration, numberList, numbersCompleted, numbersScore, onUpdate]);

  const onChangeDuration = useCallback((duration) => {
    setMonthsDuration(duration);

    onUpdate({
      numbersScore,
      monthsScore,
      monthsDuration: duration,
      numbersCompleted,
      numberList
    });
  }, [monthsScore, numberList, numbersCompleted, numbersScore, onUpdate]);

  return (
    <div className="baseline-card">
      <div className="card-section">
        <div>{Strings.numbersTitle}</div>
        <p className="text-muted">{Strings.numbersInfo}</p>
        <ConcentrationNumbers
          initialScore={numbersScore}
          initialList={numberList}
          onUpdate={onChangeNumbersScore}
          touched={touched}
        />
      </div>
      <div className="card-section">
        <div>{Strings.monthsInReverseTitle}</div>
        <p className="text-muted">
          {timedMonthsInReverse ? Strings.timedMonthsInfo : Strings.monthsInfo}
        </p>
        {timedMonthsInReverse ? (
          <MonthsInReverseTimed
            score={monthsScore}
            duration={monthsDuration}
            onDurationChange={onChangeDuration}
            onScoreChange={onChangeMonthsScore}
            touched={touched}
          />
        ) : (
          <MonthsInReverse
            score={monthsScore}
            touched={touched}
            onScoreChange={onChangeMonthsScore}
          />
        )}
      </div>
      <ScoreView
        label={Strings.concentrationTotal}
        score={numbersScore + getMonthsScore(monthsScore)}
        outOf={numberScoreMax + monthsScoreMax}
      />
    </div>
  );
};

export default BaselineConcentration;
