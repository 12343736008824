import React from 'react';
import Strings from './lang';

const PanelControls = ({
  visible = true,
  panelRef
}) => {
  return visible ? (
    <div className="no-print">
      <button 
        type="button" 
        className="btn btn-primary btn-sm" 
        onClick={() => panelRef?.current?.openAll()}
      >
        {Strings.openAllButtonText}
      </button>
      <button 
        type="button" 
        className="btn btn-primary btn-sm" 
        onClick={() => panelRef?.current?.closeAll()}
      >
        {Strings.closeAllButtonText}
      </button>
    </div>
  ) : null;
};

export default PanelControls;
